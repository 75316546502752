import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useEffect, useState, useContext } from "react";
import { restaurantServices } from "services";
import { drawersMenu, drawersStaff } from "static/staticData";
import { useParams } from "react-router";
import {
  DrawerStaffContainer,
  DrawerRolesContainer,
  StaffSpace,
} from "./style";
// import { PhotoUploader } from "components/Elements/PhotoUploader";
import NoAvatar from "assets/img/noAvatar.png";
import { FaChevronLeft, FaTrash } from "react-icons/fa";
import { colors } from "theme";
import DrawerRemoveStaff from "../DrawerRemoveStaff";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import DrawerStaffVerifyEmail from "../DrawerStaffEmailStatus";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { PhotoUploaderFormData } from "components/Elements/PhotoUploaderFormData";

export default function DrawerStaff({
  type = "Add",
  isActive = false,
  callBack = () => {},
  closePanel = () => {},
}) {
  const { id } = useParams();
  const {
    postStaff,
    setPostStaff,
    openDrawer,
    openDrawers,
    closeDrawer,
    staffRemoveInsideEditOption,
    setStaffRemoveInsideEditOption,
  } = useContext(RestaurantsContext);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (
      (postStaff.first_name &&
        postStaff.last_name &&
        postStaff.phone_number &&
        postStaff.email &&
        postStaff.role_slug &&
        postStaff?.serving_status === true) ||
      postStaff?.serving_status === false
      // postStaff.status
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [postStaff]);

  const onUploadSuccess = (asset) => {
    setPostStaff({ ...postStaff, avatar: asset });
  };

  const onDone = async () => {
    if (type === "Add") {
      const payload = { ...postStaff, avatar: postStaff?.avatar?.id };
      const result = await restaurantServices.addStaff(id, payload);
      if (result.status === 200) {
        // await fetchRestaurant(id);
      }
    } else {
      const staffPutFormData = new FormData();
      if(!postStaff?.avatar || typeof postStaff.avatar !== "string") {
        staffPutFormData.append("asset", postStaff.avatar);
      }
      postStaff.first_name && staffPutFormData.append("first_name", postStaff.first_name);
      postStaff.last_name && staffPutFormData.append("last_name", postStaff.last_name);
      postStaff.phone_number && staffPutFormData.append("phone_number", postStaff.phone_number);
      postStaff.email && staffPutFormData.append("email", postStaff.email);
      postStaff.role_slug && staffPutFormData.append("role_slug", postStaff.role_slug)
      // postStaff.status && staffPutFormData.append("status", postStaff.status)

      customAxios
        .put(`/staff/${postStaff?.id}?restaurant_id=${id}`, staffPutFormData)
        .then((res) => {
          callBack();
          closePanel();
        })
        .catch((err) => {
          console.error(err);
        });

      // previous code for update
      // const payload = { ...postStaff, avatar: postStaff?.avatar?.id };
      // const result = await restaurantServices.updateStaff(id, payload);
      // if (result.status === 200) {
      //   // await fetchRestaurant(id);
      // }
    }
    callBack();
    closePanel();
  };

  const [selectedRoleOption, setSelectedRoleOption] = useState();

  function handleClickSelectOption(selectedId) {
    setSelectedRoleOption(selectedId);
  }

  return (
    <DrawerStaffContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={
        type === "Edit" && <FaTrash size={18} color={colors.white} />
      }
      onClickRightIcon={() => {}}
    >
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">{type} Staff</div>
          {/* <div onClick={() => openDrawer(drawersStaff.staffRemove)}>
            <i className="far fa-trash cursor-pointer" />
          </div> */}
          <div
            onClick={() => {
              setStaffRemoveInsideEditOption({
                ...staffRemoveInsideEditOption,
                staffRemove: true,
              });
            }}
          >
            <i className="far fa-trash cursor-pointer" />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "49px 0 32px 0",
          }}
        >
          <PhotoUploaderFormData
            src={postStaff?.avatar ?? NoAvatar}
            height={"150px"}
            width={"150px"}
            onUploadSuccess={onUploadSuccess}
          />
        </div>
        <DrawerInput
          label="First Name"
          placeholder="Write First Name"
          value={postStaff?.first_name}
          onChange={(e) =>
            setPostStaff({ ...postStaff, first_name: e.target.value })
          }
        />
        <DrawerInput
          label="Last Name"
          placeholder="Write Last Name"
          value={postStaff?.last_name}
          onChange={(e) =>
            setPostStaff({ ...postStaff, last_name: e.target.value })
          }
        />
        <DrawerInput
          label="Mobile Number"
          placeholder="Enter Mobile Number"
          value={postStaff?.phone_number}
          onChange={(e) =>
            setPostStaff({ ...postStaff, phone_number: e.target.value })
          }
        />
        {/* <DrawerInput
          label="Email Address"
          placeholder="Enter Email Address"
          value={postStaff?.email}
          onChange={(e) =>
            setPostStaff({ ...postStaff, email: e.target.value })
          }
        /> */}
        <DrawerInputWithVerify
          label="Email Address"
          placeholder="Enter Email Address"
          value={postStaff?.email}
          onChange={(e) =>
            setPostStaff({ ...postStaff, email: e.target.value })
          }
          onClick={() => openDrawer(drawersStaff.staffEmailStatus)}
        />
        <div style={{ marginTop: "65px" }}>
          <ItemPicker
            label="Role"
            placeholder="Choose role"
            value={postStaff?.role_slug}
            onClick={() => openDrawer(drawersStaff.staffRole)}
          />

          {/* <ItemPicker
            label="Status"
            placeholder="Set status"
            value={postStaff?.status === 1 ? "Available" : "Not Available"}
            onClick={() => openDrawer(drawersStaff.staffStatus)}
          /> */}

          <ItemPicker
            label="Status"
            placeholder="Set status"
            value={
              (postStaff?.serving_status === true && "Available") ||
              (postStaff?.serving_status === false && "Not Available")
            }
            onClick={() => openDrawer(drawersStaff.staffStatus)}
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={!isValid ? "red" : "gray"}
            onClick={onDone}
            // disabled={isValid}
            className="text-bold"
          >
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerRemoveStaff
        // isActive={!!openDrawers[drawersStaff.staffRemove]}
        // closePanel={() => closeDrawer(drawersStaff.staffRemove)}
        isActive={staffRemoveInsideEditOption.staffRemove}
        closePanel={() => {
          setStaffRemoveInsideEditOption({
            ...staffRemoveInsideEditOption,
            staffRemove: false,
          });
        }}
      />
      <DrawerStaffVerifyEmail
        isActive={!!openDrawers[drawersStaff.staffEmailStatus]}
        closePanel={() => closeDrawer(drawersStaff.staffEmailStatus)}
      />
    </DrawerStaffContainer>
  );
}
