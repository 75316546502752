import axios from "axios";
import { showMessage } from "services/functions";
import { getJwt, clearJwt } from "services/jwt";

const customAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}admin/v1`,
});

customAxios.defaults.headers.common["Content-Type"] = "application/json";
customAxios.defaults.headers.post["Content-Type"] = "application/json";
customAxios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const setAuthHeader = (token) => {
  customAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
if (getJwt()) {
  setAuthHeader(getJwt());
}

customAxios.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line no-console
    // if (response.config) {
    //   console.info(
    //     `[AXIOS]:${response.config.method.toUpperCase()}:${
    //       response.config.url
    //     }`,
    //     { config: response.config, data: response.data }
    //   );
    // }
    return {
      ok: true,
      status: response.status,
      data: (response || {}).data,
      errMessage: "",
    };
  },
  (error) => {
    // console.log({ error })
    // eslint-disable-next-line no-console
    console.error("✉️ ", error);
    // eslint-disable-next-line no-console
    console.error("response ", error.response);

    if (error.response.data.message === "Please login again") {
      clearJwt();
      localStorage.removeItem('feasttt-restaurant-user');
      localStorage.removeItem('feasttt-restaurant-id');
      window.location.reload();
    }

    if (error.response.data === 'Please login again') {
      clearJwt();
      localStorage.removeItem('feasttt-restaurant-user');
      localStorage.removeItem('feasttt-restaurant-id');
      showMessage('Please login again');
      window.location.reload();
    }

    if (error.response) {
      return {
        ok: false,
        status: error.response.data.status,
        data: { data: { success: false } },
        errMessage: error.response.data.message,
      };
    } else {
      return {
        ok: false,
        status: error.message,
        data: { data: { success: false } },
        errMessage: error.message,
      };
    }
  }
);

export { setAuthHeader };

export { customAxios };
