import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../_core/MetronicLayout";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { LayoutInit } from "./LayoutInit";
import { ScrollTop } from "./extras/ScrollTop";
import { LayoutContainer } from "./style";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          <LayoutContainer
            className="d-flex flex-column flex-row-fluid wrapper content-holder"
            id="kt_wrapper"
          >
            {children}
          </LayoutContainer>
        </div>
      </div>
      <ScrollTop />
      <LayoutInit />
    </>
  ) : (
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
