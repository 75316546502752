import styled from "@emotion/styled";
import { Dropdown } from "react-bootstrap";

export const MySelectContainer = styled(Dropdown)`
  &.show {
    .dropdown-toggle {
      background-color: #27282a !important;
      border-color: transparent !important;
    }
  }
  .dropdown-toggle {
    border-radius: 25px;
    border: solid 1px transparent;
    background-color: #27282a;
    font-size: 14px;
    font-weight: normal;
    /* padding: 0 30px; */
    height: 40px;
    &:hover,
    &:focus,
    &:active {
      background-color: #27282a !important;
      border-color: transparent !important;
    }
  }
  .dropdown-menu {
    padding: 0;
    &.show {
      border-radius: 25px;
      border: solid 1px transparent;
      background-color: #27282a;
      min-width: 75px;
      width: 100%;
      padding: 16px 0;
      a:first-of-type {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      a:last-child {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      a {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        p {
          margin-bottom: 0;
        }
      }
      a:hover {
        background-color: transparent;
        color: #ed2626;
      }

      .active {
        background-color: transparent;
        color: #ed2626;
      }
    }
  }
  .dropdown-title {
    margin-right: 5px;
    color: #8f949a;
  }
`;
