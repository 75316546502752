import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerDeleteItemContainer = styled(MyDrawerCenterModal)`
  text-align: center;
  .cancel-btn {
    margin-top: 10px;
  }

  .confirmation-delete {
    font-size: 14px;
    color: #fff;
  }

  .bottom-btns {
    width: -webkit-fill-available;
  }
`;
