import React, { useState, useEffect, useContext, useRef } from "react";
import { DrawerViewAllModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Badge } from "react-bootstrap";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawersRestaurants } from "static/staticData";
import moment from "moment";
import { customAxios } from "helpers/custom-helpers/customAxios";
import EmptyImage from "assets/img/empty.png";
import MyPromoCodeSearchComponent from "components/Elements/MyPromoCodeSearch";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MyButton from "components/Elements/MyButton";
import MyPromoCodeInput from "components/Elements/MyPromoCodeInput";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import useOnclickOutside from "react-cool-onclickoutside";
import { Switch } from "@material-ui/core";
import noResult from "../../../../../assets/img/no-filter-data.png";

const ViewAllDrawerModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
  getPromoCodeFun = () => {},
  handleSelect,
  promoCodeStatus,
}) => {
  const { id } = useParams();
  // const filterRef = useRef();

  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    setUpdatePromoCodeDetails,
    couponData,
    setCouponData,
    statusActiveNotification,
    setStatusActiveNotification,
    statusInactiveNotification,
    setStatusInactiveNotification,

    searchPromoCode,
    setSearchPromoCode,

    finalDiscountDollarFrom,
    setFinalDiscountDollarFrom,

    finalDiscountDollarTo,
    setFinalDiscountDollarTo,

    finalDiscountPercentTo,
    setFinalDiscountPercentTo,

    finalDiscountPercentFrom,
    setFinalDiscountPercentFrom,
  } = useContext(RestaurantsContext);

  // const [couponData, setCouponData] = useState([]);

  // const [promoCodeStatus, setPromoCodeStatus] = useState(1);

  // useEffect(() => {
  //   getPromoCodeData();
  // }, [id, promoCodeStatus]);

  // function getPromoCodeData() {
  //   customAxios
  //     .get(`/coupon?restaurant_id=${id}&status=${promoCodeStatus}`)
  //     .then((res) => {
  //       setCouponData(res?.data?.reverse());
  //       getPromoCodeFun()
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  // // tabs onClick method
  // const handleSelect = (key) => {
  //   if (key === "1") {
  //     setPromoCodeStatus(1);
  //   } else if (key === "2") {
  //     setPromoCodeStatus(2);
  //   }
  // };

  function updateCouponDetail(promoCodedetails) {
    setUpdatePromoCodeDetails(promoCodedetails);
    openDrawer(drawersRestaurants.promoStatusModal);
    // openDrawer(drawersRestaurants.addPromoCodeModal);
  }

  const [selectedFilter, setSelectedFilter] = useState("");
  const [sortType, setSortType] = useState("");
  const [copiedCode, setCopiedCode] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [openDiscountDollar, setOpenDiscountDollar] = useState(false);
  const [openDiscountPercent, setOpenDiscountPercent] = useState(false);
  const [discountDollarFrom, setDiscountDollarFrom] = useState("");
  const [discountDollarTo, setDiscountDollarTo] = useState("");
  const [discountPercentFrom, setDiscountPercentFrom] = useState("");
  const [discountPercentTo, setDiscountPercentTo] = useState("");
  // const [searchPromoCode, setSearchPromoCode] = useState("");

  const [filterApplied, setFilterApplied] = useState(false);

  const [nameSort, setNameSort] = useState(false);
  const [discountSort, setDiscountSort] = useState(false);

  const onChangeSearchPromoCode = (newValue) => setSearchPromoCode(newValue);
  const onClearSearchPromoCode = () => setSearchPromoCode("");

  function toggleFilter(filterName) {
    if (selectedFilter === filterName) {
      setSortType(sortType === "up" ? "down" : "up");
    } else {
      setSelectedFilter(filterName);
      setSortType("up");
    }
  }

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const copyToClipboard = (code) => {
    setCopiedCode(code);
    navigator.clipboard.writeText(code);
    setTimeout(() => {
      setCopiedCode("");
    }, 4000);
  };

  const ref = useOnclickOutside(() => {
    if (openFilter) {
      setOpenFilter(false);
    }
  });

  useEffect(() => {
    if (isActive) {
      getPromoCodeFun();
    }
  }, [
    searchPromoCode,
    finalDiscountPercentTo,
    finalDiscountPercentFrom,
    finalDiscountDollarTo,
    finalDiscountDollarFrom,
    isActive,
  ]);

  const updateInActivePromoCode = (promocode, statusValue) => {
    let data = [...couponData];
    let findIndex = data.findIndex((el) => el?.id === promocode?.id);
    data[findIndex].status = statusValue;
    setCouponData(data);
    const configUpdatePromoCode = {
      status: statusValue,
    };

    configUpdatePromoCode.restaurant_id = id;

    customAxios
      .patch(`/coupon/${promocode?.id}`, configUpdatePromoCode)
      .then((res) => {
        if (res.status === 200) {
          getPromoCodeFun();
          if (statusValue === 1) {
            setStatusActiveNotification({
              message: "Promo Code Active.",
              type: "promo-active",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promocode?.name} • ${promocode?.code}</span>`,
            });
          } else {
            setStatusActiveNotification({
              message: "Promo Code Inactive.",
              type: "promo-inactive",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promocode?.name} • ${promocode?.code}</span>`,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    promoCodeNameToggleFilter();
    promoCodeDiscountToggleFilter();
  }, []);

  function promoCodeNameToggleFilter() {
    setNameSort(!nameSort);
    if (nameSort) {
      setCouponData(couponData.sort((a, b) => b.name.localeCompare(a.name)));
    } else {
      setCouponData(couponData.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }

  function promoCodeDiscountToggleFilter() {
    setDiscountSort(!discountSort);
    if (discountSort) {
      setCouponData(
        couponData.sort((a, b) => b.discount.localeCompare(a.discount))
      );
    } else {
      setCouponData(
        couponData.sort((a, b) => a.discount.localeCompare(b.discount))
      );
    }
  }

  useEffect(() => {
    setDiscountDollarFrom("");
    setFinalDiscountDollarFrom("");
    setDiscountDollarTo("");
    setFinalDiscountDollarTo("");
    setDiscountPercentFrom("");
    setFinalDiscountPercentFrom("");
    setDiscountPercentTo("");
    setFinalDiscountPercentTo("");
    setFilterApplied(false);
    setOpenDiscountDollar(false);
    setOpenDiscountPercent(false);
  }, [promoCodeStatus]);

  return (
    <DrawerViewAllModalContainer
      isActive={isActive}
      closePanel={closePanel}
      //   top="10rem"
      //   bottom={"10rem"}
      width={"80%"}
      left="60%"
      top="50%"
      zIndex="9999"
      height="765px"
      transform="translate(-60%, -50%)"
    >
      <DrawerContainer>
        <div className="view-all-container">
          {/* {statusActiveNotification && (
            <MyMessageBoxItemNew
              message={
                statusActiveNotification?.message
                  ? statusActiveNotification?.message
                  : ""
              }
              table={
                statusActiveNotification?.table
                  ? statusActiveNotification?.table
                  : ""
              }
              type={
                statusActiveNotification?.type
                  ? statusActiveNotification?.type
                  : ""
              }
              onClick={() => setStatusActiveNotification(null)}
            />
          )} */}

          {statusInactiveNotification && (
            <MyMessageBoxItemNew
              message={
                statusInactiveNotification?.message
                  ? statusInactiveNotification?.message
                  : ""
              }
              table={
                statusInactiveNotification?.table
                  ? statusInactiveNotification?.table
                  : ""
              }
              type={
                statusInactiveNotification?.type
                  ? statusInactiveNotification?.type
                  : ""
              }
              onClick={() => setStatusInactiveNotification(null)}
            />
          )}

          <div className="top-title-header">
            <div className="title-text">
              Promo Codes{" "}
              <span
                style={{
                  fontSize: "14px",
                  color: "#939495",
                  fontWeight: "normal",
                }}
              >
                {searchPromoCode && !filterApplied
                  ? `(Search Applied: ${couponData.length} results)`
                  : filterApplied && !searchPromoCode
                  ? `(Filter Applied: ${couponData.length} results)`
                  : filterApplied && searchPromoCode
                  ? `(Search & Filter Applied: ${couponData.length} results)`
                  : ""}
              </span>
            </div>
            <div>
              <i
                className="fa fa-times cursor-pointer"
                aria-hidden="true"
                onClick={closePanel}
              />
            </div>
          </div>

          <div className="promo-codes-tab">
            <Tab.Container
              id=""
              defaultActiveKey={promoCodeStatus}
              activeKey={promoCodeStatus}
              onSelect={handleSelect}
            >
              <div className="nav-header">
                <div>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey={"1"}>
                        {" "}
                        <span
                          style={
                            promoCodeStatus === 1
                              ? { color: "#fff" }
                              : { opacity: 0.5 }
                          }
                        >
                          Active
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={"2"}>
                        {" "}
                        <span
                          style={
                            promoCodeStatus === 2
                              ? { color: "#fff" }
                              : { opacity: 0.5 }
                          }
                        >
                          Inactive
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="filter-wrapper">
                  <MyPromoCodeSearchComponent
                    placeholder="Enter..."
                    options={[
                      ...(couponData &&
                        couponData.map(({ name }, index) => ({
                          id: index,
                          value: `${name}`,
                        }))),
                    ]}
                    searchValue={searchPromoCode}
                    setSearchValue={setSearchPromoCode}
                    onChangeSearch={(value) => onChangeSearchPromoCode(value)}
                    onClearAfterSearch={() => onClearSearchPromoCode()}
                    suggestions={false}
                  />
                  {couponData.length || filterApplied || searchPromoCode ? (
                    <div
                      // className={`filter-btn-wrapper ${(discountDollarFrom ||
                      //   discountDollarTo ||
                      //   discountPercentFrom ||
                      //   discountPercentTo) &&
                      //   "active"}`}

                      // onClick={() => {
                      //   setOpenFilter(true);
                      // }}

                      className={`filter-btn-wrapper ${filterApplied &&
                        "active"}`}
                      ref={ref}
                    >
                      {/* <i
                      class="fas fa-sliders-h"
                      onClick={() => {
                        setOpenFilter(!openFilter);
                      }}
                    /> */}

                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={() => {
                          setOpenFilter(!openFilter);
                        }}
                      >
                        <i class="fas fa-sliders-h"></i>
                      </div>

                      {openFilter && (
                        <div className="filter-container">
                          <div className="filter-header">
                            <span>Filter</span>
                            {(discountDollarFrom ||
                              discountDollarTo ||
                              discountPercentFrom ||
                              discountPercentTo) && (
                              <button
                                // onClick={() => {
                                //   setOpenFilter(!openFilter);
                                //   setDiscountDollarFrom("");
                                //   setDiscountDollarTo("");
                                //   setDiscountPercentFrom("");
                                //   setDiscountPercentTo("");
                                // }}

                                onClick={() => {
                                  // setOpenFilter(true);
                                  setOpenFilter(!openFilter);
                                  setDiscountDollarFrom("");
                                  setFinalDiscountDollarFrom("");
                                  setDiscountDollarTo("");
                                  setFinalDiscountDollarTo("");
                                  setDiscountPercentFrom("");
                                  setFinalDiscountPercentFrom("");
                                  setDiscountPercentTo("");
                                  setFinalDiscountPercentTo("");
                                  setFilterApplied(false);
                                  // setOpenFilter(!openFilter);
                                }}
                              >
                                Reset All
                              </button>
                            )}
                          </div>
                          <div className="filter-body">
                            <div className="filter-item">
                              <div className="filter-item-header">
                                <span
                                  onClick={() =>
                                    setOpenDiscountDollar(!openDiscountDollar)
                                  }
                                >
                                  Discount, $
                                </span>
                                <div className="filter-item-header-btn">
                                  {(discountDollarFrom || discountDollarTo) && (
                                    <button
                                      className="clear-filter-btn"
                                      // onClick={() => {
                                      //   setDiscountDollarFrom("");
                                      //   setDiscountDollarTo("");
                                      // }}
                                      onClick={() => {
                                        setDiscountDollarFrom("");
                                        setFinalDiscountDollarFrom("");
                                        setDiscountDollarTo("");
                                        setFinalDiscountDollarTo("");
                                        if (discountPercentTo) {
                                          setFilterApplied(true);
                                        } else {
                                          setFilterApplied(false);
                                        }
                                      }}
                                    >
                                      X
                                    </button>
                                  )}
                                  {openDiscountDollar ? (
                                    <i
                                      class="fas fa-angle-up"
                                      onClick={() =>
                                        setOpenDiscountDollar(
                                          !openDiscountDollar
                                        )
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      class="fas fa-angle-down"
                                      onClick={() =>
                                        setOpenDiscountDollar(
                                          !openDiscountDollar
                                        )
                                      }
                                    ></i>
                                  )}
                                </div>
                              </div>
                              {openDiscountDollar && (
                                <div className="filter-body">
                                  <Slider
                                    range
                                    allowCross={false}
                                    defaultValue={[
                                      discountDollarFrom,
                                      discountDollarTo,
                                    ]}
                                    value={[
                                      discountDollarFrom,
                                      discountDollarTo,
                                    ]}
                                    onChange={(value) => {
                                      setDiscountDollarFrom(value[0]);
                                      setDiscountDollarTo(value[1]);
                                    }}
                                    max={999}
                                  />
                                  <div className="filter-item-body">
                                    <MyPromoCodeInput
                                      className=""
                                      placeholder="From"
                                      value={discountDollarFrom}
                                      onChange={(e) =>
                                        setDiscountDollarFrom(
                                          e.target.value.replace(/[^0-9.]/g, "")
                                        )
                                      }
                                      style={{
                                        width: "50%",
                                        border: "none",
                                        background: "transparent",
                                        boxShadow: "none",
                                        outline: "none",
                                        height: "auto",
                                      }}
                                      taxes={true}
                                      prefix="$"
                                    />
                                    <MyPromoCodeInput
                                      className=""
                                      placeholder="To"
                                      value={discountDollarTo}
                                      onChange={(e) =>
                                        setDiscountDollarTo(
                                          e.target.value.replace(/[^0-9.]/g, "")
                                        )
                                      }
                                      style={{
                                        width: "50%",
                                        border: "none",
                                        background: "transparent",
                                        boxShadow: "none",
                                        outline: "none",
                                        height: "auto",
                                      }}
                                      taxes={true}
                                      prefix="$"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="filter-item">
                              <div className="filter-item-header">
                                <span
                                  onClick={() =>
                                    setOpenDiscountPercent(!openDiscountPercent)
                                  }
                                >
                                  Discount, %
                                </span>
                                <div className="filter-item-header-btn">
                                  {(discountPercentFrom ||
                                    discountPercentTo) && (
                                    <button
                                      className="clear-filter-btn"
                                      onClick={() => {
                                        setDiscountDollarFrom("");
                                        setFinalDiscountDollarFrom("");
                                        setDiscountDollarTo("");
                                        setFinalDiscountDollarTo("");
                                        setFilterApplied(false);
                                        if (discountPercentTo) {
                                          setFilterApplied(true);
                                        } else {
                                          setFilterApplied(false);
                                        }
                                      }}
                                    >
                                      X
                                    </button>
                                  )}
                                  {openDiscountPercent ? (
                                    <i
                                      class="fas fa-angle-up"
                                      onClick={() =>
                                        setOpenDiscountPercent(
                                          !openDiscountPercent
                                        )
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      class="fas fa-angle-down"
                                      onClick={() =>
                                        setOpenDiscountPercent(
                                          !openDiscountPercent
                                        )
                                      }
                                    ></i>
                                  )}
                                </div>
                              </div>
                              {openDiscountPercent && (
                                <div className="filter-body">
                                  <Slider
                                    range
                                    allowCross={false}
                                    defaultValue={[
                                      discountPercentFrom,
                                      discountPercentTo,
                                    ]}
                                    value={[
                                      discountPercentFrom,
                                      discountPercentTo,
                                    ]}
                                    onChange={(value) => {
                                      setDiscountPercentFrom(value[0]);
                                      setDiscountPercentTo(value[1]);
                                    }}
                                    max={100}
                                  />
                                  <div className="filter-item-body">
                                    <MyPromoCodeInput
                                      className=""
                                      placeholder="From"
                                      value={discountPercentFrom}
                                      onChange={(e) =>
                                        setDiscountPercentFrom(
                                          e.target.value.replace(/[^0-9]/g, "")
                                        )
                                      }
                                      style={{
                                        width: "50%",
                                        border: "none",
                                        background: "transparent",
                                        boxShadow: "none",
                                        outline: "none",
                                        height: "auto",
                                      }}
                                      taxes={true}
                                      prefix="%"
                                    />
                                    <MyPromoCodeInput
                                      className=""
                                      placeholder="To"
                                      value={discountPercentTo}
                                      onChange={(e) =>
                                        setDiscountPercentTo(
                                          e.target.value.replace(/[^0-9]/g, "")
                                        )
                                      }
                                      style={{
                                        width: "50%",
                                        border: "none",
                                        background: "transparent",
                                        boxShadow: "none",
                                        outline: "none",
                                        height: "auto",
                                      }}
                                      taxes={true}
                                      prefix="%"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <MyButton
                            className="apply-btn"
                            disabled={
                              // (!discountDollarFrom && !discountDollarTo) || (!discountPercentFrom && !discountPercentTo)
                              !discountDollarTo && !discountPercentTo
                                ? true
                                : false
                            }
                            type={
                              // (!discountDollarFrom && !discountDollarTo) || (!discountPercentFrom && !discountPercentTo)
                              !discountDollarTo && !discountPercentTo
                                ? "gray"
                                : "red"
                            }
                            onClick={() => {
                              setOpenFilter(!openFilter);
                              // setOpenFilter(false);
                              setFinalDiscountDollarFrom(discountDollarFrom);
                              setFinalDiscountDollarTo(discountDollarTo);
                              setFinalDiscountPercentFrom(discountPercentFrom);
                              setFinalDiscountPercentTo(discountPercentTo);
                              setFilterApplied(true);
                            }}
                          >
                            Apply
                          </MyButton>
                          <MyButton
                            className="cancel-btn"
                            type={
                              // (!discountDollarFrom && !discountDollarTo) || (!discountPercentFrom && !discountPercentTo)
                              "transparent"
                            }
                            onClick={() => {
                              setOpenFilter(false);
                            }}
                          >
                            Cancel
                          </MyButton>
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div
                    className="add-promo-btn"
                    onClick={() => {
                      openDrawer(drawersRestaurants.addPromoCodeModal);
                      setUpdatePromoCodeDetails("");
                    }}
                  >
                    <FaPlus color="white" className="mr-2" /> Add Promo Code
                  </div>
                </div>
              </div>

              {/* tab section */}
              <Row style={{ marginTop: "34px" }}>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey={"1"}>
                      <div>
                        {couponData &&
                        couponData?.filter((el) => el?.status === 1).length >
                          0 ? (
                          <table style={{ display: "block" }}>
                            <thead style={{ display: "block" }}>
                              <tr>
                                {/* <th onClick={() => toggleFilter("first_name")}>
                                  Name{" "}
                                  {selectedFilter === "first_name" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() => promoCodeNameToggleFilter()}
                                  style={{ cursor: "pointer" }}
                                >
                                  Name{" "}
                                  {nameSort ? (
                                    <i className="fas fa-angle-up" />
                                  ) : (
                                    <i className="fas fa-angle-down" />
                                  )}
                                </th>
                                <th onClick={() => {}}>
                                  Promo Code{" "}
                                  {selectedFilter === "role_slug" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Active Dates{" "}
                                  {selectedFilter === "status" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Expiration Time{" "}
                                  {selectedFilter === "rating" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>

                                <th onClick={() => {}}>
                                  Total Use Times{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Use Times/Customer{" "}
                                  {selectedFilter === "phone_number" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Min Order{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Max Discount{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                {/* <th onClick={() => toggleFilter("email")}>
                                  Discount{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() =>
                                    promoCodeDiscountToggleFilter()
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  Discount
                                  {discountSort ? (
                                    <i className="fas fa-angle-up" />
                                  ) : (
                                    <i className="fas fa-angle-down" />
                                  )}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="list-scroll">
                              {couponData?.map((promoCode, index) => {
                                return (
                                  <tr key={`staff-${index}`}>
                                    <td>
                                      <div className="name-container">
                                        <span className="staff-avatar-title">
                                          {getHighlightedText(
                                            promoCode?.name,
                                            searchPromoCode,
                                            "#ed2626"
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-container-normal">
                                      <span
                                        style={{
                                          padding: "8px 6px",
                                          background: "#27282a",
                                          borderRadius: "30px",
                                        }}
                                      >
                                        {getHighlightedText(
                                          promoCode?.code,
                                          searchPromoCode,
                                          "#ed2626"
                                        )}{" "}
                                        <i
                                          class="fas fa-copy"
                                          style={{
                                            color:
                                              copiedCode === promoCode?.code
                                                ? "#fff"
                                                : "#767777",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            copyToClipboard(promoCode?.code);
                                          }}
                                        ></i>
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      {promoCode?.end_date
                                        ? `${moment(
                                            promoCode?.start_date
                                          ).format("ll")} - ${moment(
                                            promoCode?.end_date
                                          ).format("ll")}`
                                        : "Not Limited"}
                                    </td>
                                    <td>
                                      {promoCode?.end_date &&
                                      promoCode?.end_time
                                        ? moment(
                                            promoCode?.end_time,
                                            "h:mm A"
                                          ).format("HH:mm A")
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {" "}
                                      {promoCode?.times_used ||
                                      promoCode?.limit_overall
                                        ? `${promoCode?.times_used}/${promoCode?.limit_overall}`
                                        : `${promoCode?.times_used}/Not Limited`}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.limit_user
                                        ? promoCode?.limit_user
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.threshold !== null ||
                                      promoCode?.threshold > 0
                                        ? `$${promoCode?.threshold}`
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.max_discount
                                        ? `$${promoCode?.max_discount}`
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{
                                        color: "#1fc085",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {" "}
                                      {promoCode?.discount_type === 1
                                        ? `${promoCode?.discount}%`
                                        : `$${promoCode?.discount}`}
                                    </td>
                                    <td style={{ opacity: 1 }}>
                                      <span
                                        style={{
                                          justifyContent: "flex-end",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          updateCouponDetail(promoCode);
                                        }}
                                      >
                                        <div className="toggle-btn">
                                          <Switch
                                            checked={
                                              promoCode?.status === 2
                                                ? false
                                                : true
                                            }
                                            // onChange={() => {
                                            //   updateInActivePromoCode(
                                            //     promoCode,
                                            //     2
                                            //   );
                                            // }}
                                          />
                                        </div>
                                        {/* <i
                                          className="fas fa-cog"
                                          style={{
                                            color: "#767777",
                                            fontSize: "18px",
                                          }}
                                        /> */}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <>
                            {searchPromoCode ? (
                              <div className="search-no-result-wrapper">
                                {/* <div className="search-applied">
                                  <div className="applied-text">
                                    Search Applied
                                  </div>
                                  <div className="result-text">
                                    <span className="text-bold">0</span> results
                                  </div>
                                </div> */}

                                <div className="no-result">
                                  <img src={noResult} alt="No results" />
                                  <div className="no-result-text">
                                    No results
                                  </div>
                                  <div className="broadening-title">
                                    Try broadening your search.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="empty-container">
                                <img src={EmptyImage} alt="" />
                                <div className="empty-screen">
                                  There are no promo <br />
                                  codes to show yet.
                                </div>
                                {/* <div
                                  className="add-promo-btn mt-3"
                                  onClick={() => {
                                    openDrawer(
                                      drawersRestaurants.addPromoCodeModal
                                    );
                                    setUpdatePromoCodeDetails("");
                                  }}
                                >
                                  <FaPlus color="white" className="mr-2" /> Add
                                  Promo Code
                                </div> */}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={"2"}>
                      <div>
                        {couponData &&
                        couponData?.filter((el) => el?.status === 2).length >
                          0 ? (
                          <table style={{ display: "block" }}>
                            <thead style={{ display: "block" }}>
                              <tr>
                                {/* <th onClick={() => toggleFilter("first_name")}>
                                  Name{" "}
                                  {selectedFilter === "first_name" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() => promoCodeNameToggleFilter()}
                                  style={{ cursor: "pointer" }}
                                >
                                  Name{" "}
                                  {nameSort ? (
                                    <i className="fas fa-angle-up" />
                                  ) : (
                                    <i className="fas fa-angle-down" />
                                  )}
                                </th>
                                <th onClick={() => {}}>
                                  Promo Code{" "}
                                  {selectedFilter === "role_slug" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Active Dates{" "}
                                  {selectedFilter === "status" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Expiration Time{" "}
                                  {selectedFilter === "rating" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>

                                <th onClick={() => {}}>
                                  Total Use Times
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Use Times/Customer{" "}
                                  {selectedFilter === "phone_number" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Min Order{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => {}}>
                                  Max Discount{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                {/* <th onClick={() => toggleFilter("email")}>
                                  Discount{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() =>
                                    promoCodeDiscountToggleFilter()
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  Discount{" "}
                                  {discountSort ? (
                                    <i className="fas fa-angle-up" />
                                  ) : (
                                    <i className="fas fa-angle-down" />
                                  )}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="list-scroll">
                              {couponData?.map((promoCode, index) => {
                                return (
                                  <tr key={`staff-${index}`}>
                                    <td>
                                      <div className="name-container">
                                        <span className="staff-avatar-title">
                                          {getHighlightedText(
                                            promoCode?.name,
                                            searchPromoCode,
                                            "#ed2626"
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-container-normal">
                                      <span
                                        style={{
                                          padding: "8px 15px",
                                          background: "#27282a",
                                          borderRadius: "30px",
                                        }}
                                      >
                                        {" "}
                                        {getHighlightedText(
                                          promoCode?.code,
                                          searchPromoCode,
                                          "#ed2626"
                                        )}{" "}
                                        <i
                                          class="fas fa-copy"
                                          style={{
                                            color:
                                              copiedCode === promoCode?.code
                                                ? "#fff"
                                                : "#767777",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            copyToClipboard(promoCode?.code)
                                          }
                                        ></i>
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      {promoCode?.end_date
                                        ? `${moment(
                                            promoCode?.start_date
                                          ).format("ll")} - ${moment(
                                            promoCode?.end_date
                                          ).format("ll")}`
                                        : "Not Limited"}
                                    </td>
                                    <td>
                                      {promoCode?.end_date &&
                                      promoCode?.end_time
                                        ? moment(
                                            promoCode?.end_time,
                                            "h:mm A"
                                          ).format("HH:mm A")
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {" "}
                                      {promoCode?.times_used ||
                                      promoCode?.limit_overall
                                        ? `${promoCode?.times_used}/${promoCode?.limit_overall}`
                                        : `${promoCode?.times_used}/Not Limited`}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.limit_user}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.threshold !== null ||
                                      promoCode?.threshold > 0
                                        ? `$${promoCode?.threshold}`
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.max_discount
                                        ? `$${promoCode?.max_discount}`
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{
                                        color: "#1fc085",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {" "}
                                      {promoCode?.discount_type === 1
                                        ? `${promoCode?.discount}%`
                                        : `$${promoCode?.discount}`}
                                    </td>
                                    <td style={{ opacity: 1 }}>
                                      <span
                                        style={{
                                          justifyContent: "flex-end",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          updateCouponDetail(promoCode);
                                        }}
                                      >
                                        <div className="toggle-btn">
                                          <Switch
                                            checked={
                                              promoCode?.status === 2
                                                ? false
                                                : true
                                            }
                                            // onChange={() => {
                                            //   updateInActivePromoCode(
                                            //     promoCode,
                                            //     1
                                            //   );
                                            // }}
                                          />
                                        </div>
                                        {/* <i
                                          className="fas fa-cog"
                                          style={{
                                            color: "#767777",
                                            fontSize: "18px",
                                          }}
                                        /> */}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <>
                            {searchPromoCode ? (
                              <div className="search-no-result-wrapper">
                                {/* <div className="search-applied">
                                  <div className="applied-text">
                                    Search Applied
                                  </div>
                                  <div className="result-text">
                                    <span className="text-bold">0</span> results
                                  </div>
                                </div> */}

                                <div className="no-result">
                                  <img src={noResult} alt="No results" />
                                  <div className="no-result-text">
                                    No results
                                  </div>
                                  <div className="broadening-title">
                                    Try broadening your search.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="empty-container">
                                <img src={EmptyImage} alt="" />
                                <div className="empty-screen">
                                  There are no promo
                                  <br /> codes to show yet.
                                </div>
                                {/* <div
                                  className="add-promo-btn mt-3"
                                  onClick={() => {
                                    openDrawer(
                                      drawersRestaurants.addPromoCodeModal
                                    );
                                    setUpdatePromoCodeDetails("");
                                  }}
                                >
                                  <FaPlus color="white" className="mr-2" /> Add
                                  Promo Code
                                </div> */}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </DrawerContainer>
    </DrawerViewAllModalContainer>
  );
};

export default ViewAllDrawerModal;
