import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React from "react";
import { DrawerDateCustomerContainer } from "./style";
import moment from "moment";

export default function DrawerDeleteClosedDay({
  isActive = false,
  closePanel = () => {},
  onDeleteClosedDay = () => {},
  selectedItem = "",
}) {
  return (
    <DrawerDateCustomerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="delete-closed-day-modal">
          <div className="delete-closed-day-modal-title">Delete Closed Day</div>
          <div className="delete-closed-day-modal-meta">
            Are you sure you want to delete Closed Day on{" "}
            <b>{moment(selectedItem.closed_date).format("MMMM DD")}</b> ?
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton type="red" onClick={closePanel}>
            Keep
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            onClick={() => {
              onDeleteClosedDay();
            }}
            className="mt-5"
          >
            Delete
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDateCustomerContainer>
  );
}
