import React, { useContext, useState } from "react";
import { NewServerAssignedModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OrdersContext } from "context/OrdersContext";
import { NoAvatar, IconTable } from "assets/img";

const NewServerAssignedModal = ({
  isActive = false,
  closePanel = () => {},
  checkInData = "",
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  return (
    <NewServerAssignedModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="new-server-assigned-modal">
          <div className="img-container">
            <img
              src={
                checkInData?.avatar !== null ? checkInData?.avatar : NoAvatar
              }
              alt=""
            />
          </div>
          <div className="delete-suborder-title">New server assigned</div>
          <div className="server-data-meta">
            <div className="d-flex justify-content-center align-items-center">
              <h5>
                <b>
                  {checkInData?.first_name} {checkInData?.last_name?.charAt(0)}
                </b>
              </h5>
              <h5 className="opacity6"> &nbsp; is assigned to</h5>
            </div>
            <h5 className="opacity6">
              Table {orderDetailData?.table_number} • #{orderDetailData?.token}
            </h5>
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type={"red"}
              className="apply-btn"
              onClick={closePanel}
            >
              <b>Okay</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </NewServerAssignedModalContainer>
  );
};

export default NewServerAssignedModal;
