/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { RestaurantMenuSelectContainer } from "./style";
import { Dropdown } from "react-bootstrap";
import { formatBusinessHoursToday, formatName } from "services/functions";
import { colors } from "theme";

export default function RestaurantMenuSelect({
  className = "",
  titleKey = "name",
  options = [],
  defaultValueId,
  onChange = () => {},
  renderExtra = () => {},
  selectedMenuOption = () => {},
}) {
  // const [value, setValue] = useState({id: 0, name: "All Items"});
  const [value, setValue] = useState();

  // useEffect(() => {
  //   // console.log(value)
  //   if (value) {
  //     onChange(value);
  //   }
  // }, []);

  useEffect(() => {
    if (defaultValueId === undefined) {
      setValue(options[0]);
      onChange(options[0]);
    } else {
      setValue(options.find((_option) => _option.id === defaultValueId));
      onChange(options.find((_option) => _option.id === defaultValueId));
    }
  }, [defaultValueId]);

  const onSelect = (option) => {
    setValue(options.find((_option) => _option.id === option.id));
    onChange(option);
  };

  const selectedValueRenderer = (value) => {
    let selectedMenu = options.filter((el) => el?.name === value[titleKey]);
    selectedMenuOption(selectedMenu[0]);
    let time = formatBusinessHoursToday(selectedMenu[0]?.availability);
    return (
      <div className="d-flex  align-items-center">
        <div>
          {value[titleKey]} ({time})
        </div>
        <i
          className="fas fa-circle"
          style={{
            color: selectedMenu[0]?.status ? colors.green : colors.gray,
            fontSize: "10px",
            position: "absolute",
            left: "220px",
          }}
        />
      </div>
    );
  };

  const selectedValueRendererById = (defaultValueId) => {
    let selectedMenu = options.filter((el) => el?.id === defaultValueId);
    selectedMenuOption(selectedMenu[0]);
    let time = formatBusinessHoursToday(selectedMenu[0]?.availability);
    return (
      <div className="d-flex  align-items-center">
        <div>
          {selectedMenu[0]?.name} ({time})
        </div>
        <i
          className="fas fa-circle"
          style={{
            color: selectedMenu[0]?.status ? colors.green : colors.gray,
            fontSize: "10px",
            position: "absolute",
            left: "220px",
          }}
        />
      </div>
    );
  };

  return (
    <RestaurantMenuSelectContainer className={className}>
      <Dropdown.Toggle id="dropdown-basic">
        {value ? (
          selectedValueRenderer(value)
        ) : defaultValueId ? (
          options.find((_option) => _option.id == defaultValueId) ? (
            selectedValueRendererById(defaultValueId)
          ) : (
            <>Select</>
          )
        ) : (
          <>Select</>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((_option, _i) => {
          return (
            <Dropdown.Item key={_i} onClick={() => onSelect(_option)}>
              <div className="d-flex justify-content-between  align-items-center">
                <div>
                  {formatName(_option.name)} &nbsp; (
                  {formatBusinessHoursToday(_option.availability)}) &nbsp;{" "}
                </div>
                <i
                  className="fas fa-circle"
                  style={{
                    color: _option.status ? colors.green : colors.gray,
                    fontSize: "10px",
                  }}
                />
              </div>
            </Dropdown.Item>
          );
        })}
        {renderExtra() && <Dropdown.Item>{renderExtra()}</Dropdown.Item>}
      </Dropdown.Menu>
    </RestaurantMenuSelectContainer>
  );
}
