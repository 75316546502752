import styled from "@emotion/styled";
import { Button } from "react-bootstrap";
import { colors } from "theme";

export const MyDrawerButtonContainer = styled(Button)`
  height: 50px;
  background-color: ${(props) =>
    props.type === "gray"
      ? colors.gray
      : props.type === "redColor" || props.type === "transparent"
      ? "transparent"
      : props.type === "gray2"
      ? "rgba(255, 255, 255, 0.2)"
      : colors.redColor} !important;
  color: ${(props) =>
    props.type === "redColor" || props.type === "transparent"
      ? colors.redColor
      : props.type === "gray2"
      ? "rgba(255, 255, 255, 0.4)"
      : colors.white} !important;
  border-color: transparent !important;
  border-radius: 8px;
  font-weight: 600;

  &:disabled{
    cursor: default;
  }
`;
