import React, { useEffect, useContext } from "react";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { DrawerCheckExistsMenuContainer } from "./style";
import alreadyExistMenuImage from "assets/img/already_exist.png";
import { RestaurantsContext } from "context/RestaurantsContext";

export default function DrawerCheckExistsMenu({
  isActive = false,
  closePanel = () => {},
}) {
  const { selectedMenu } = useContext(RestaurantsContext);

  return (
    <DrawerCheckExistsMenuContainer
      isActive={isActive}
      closePanel={closePanel}
      top="35%"
    >
      <DrawerContainer>
        <div>
          <div className="img-wrapper">
            <img className="menu-exist" src={alreadyExistMenuImage} alt="" />
          </div>
          <div>
            <h2 className="text-bold">Oops!</h2>
            <h2 className="confirmation-delete mt-3">
              We’re sorry,{" "}
              <span className="text-bold">{selectedMenu?.name}</span> menu
              already exists in <br /> this restaurant. Try another name for the
              menu, <br /> please.
            </h2>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="text-bold cancel-btn"
                onClick={closePanel}
              >
                <b>Okay</b>
              </MyDrawerButton>
            </div>
          </div>
        </div>
      </DrawerContainer>
    </DrawerCheckExistsMenuContainer>
  );
}
