import React, { useContext, useEffect, useState } from "react";
import { MenuCategoriesContainer } from "./style";
import SingleText from "components/SingleText";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from "components/Box";
import { formatName } from "services/functions";
import { colors } from "theme";
import { FaBars, FaTrash, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services/restaurantServices";
import DrawerDeleteCategory from "./DrawerDeleteCategory";
import { showMessage } from "services/functions";
import DrawerCategory from "./DrawerCategory";
import DrawerMoveDeleteCategory from "./DrawerMoveDeleteCategory";
import DrawerMoveCategoriesItem from "./DrawerMoveCategoriesItem";
import DrawerMoveCategoryDeleteConfirmation from "./DrawerMoveCategoryDeleteConfirmation";
import DrawerEditCategory from "./DrawerEditCategory";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerIfNoCategoriesDelete from "./DrawerIfNoCategoriesDelete";
import DrawerCheckExistsCategory from "./DrawerAlreadyExistsCategory";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const MenuCategories = ({ type, isActive = false, closePanel = () => {} }) => {
  const { id } = useParams();
  const [restaurantId, setRestaurantId] = useState(id);
  const {
    selectedMenuCategories,
    setSelectedMenucatogories,
    menuId,
    setSelectedMenu,
    drawerAlreadyExistMenuCategory,
    setDrawerAlreadyExistMenuCategory,
    postMenu,
    selectedMenu,
    setFoods,
  } = useContext(RestaurantsContext);

  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = useState(false);
  const [chosenCategory, setChosenCategory] = useState({});
  const [
    deleteCategoriesDrawersForMoveAndDelete,
    setDeleteCategoriesDrawersForMoveAndDelete,
  ] = useState({
    deleteCategory: false,
    moveItems: false,
    confirmMoveAndDelete: false,
    noCategoriesDelete: false,
  });
  const [selectedCatForMoveItems, setSelectedCatForMoveItems] = useState({});

  const [isAddCategoryDrawerOpen, setIsAddCategoryDrawerOpen] = useState(false);

  const [isCategoryEdit, setIsCategoryEdit] = useState(false);
  const [selectedCategory, setSelctedCategory] = useState("");

  const [countCatgoryItemState, setCountCatgoryItemState] = useState({});

  const [
    deleteCategoryNotification,
    setDeleteCategoryNotification,
  ] = useState();

  useEffect(() => {
    if (deleteCategoryNotification) {
      setTimeout(() => setDeleteCategoryNotification(null), 2 * 1000);
    }
  }, [deleteCategoryNotification]);

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    if (source.index === destination.index) return;

    const newCategories = reorder(
      [...selectedMenuCategories.categories],
      source.index,
      destination.index
    );

    setSelectedMenucatogories({
      ...selectedMenuCategories,
      categories: newCategories,
    });

    restaurantServices.categoriesSort(restaurantId, selectedMenuCategories.id, {
      sort: newCategories.map((item) => item.id),
    });
  };

  const onClickDeleteCategory = async () => {
    const result = await restaurantServices.deleteCategory(
      restaurantId,
      selectedMenuCategories.id,
      // chosenCategory?.id
      countCatgoryItemState.id
    );
    if (result?.status === 200) {
      setChosenCategory({});
      setIsCategoryDrawerOpen(false);
      let data = [...selectedMenuCategories.categories];
      let filterData = data.filter(
        (el) => el?.id !== countCatgoryItemState?.id
      );
      setSelectedMenucatogories({
        ...selectedMenuCategories,
        categories: filterData,
      });
      getMenuDetailById();
      setDeleteCategoryNotification({
        message: `"${countCatgoryItemState?.name}" category deleted.`,
        type: "remove",
        table: ``,
      });
    } else {
      showMessage(result?.errMessage, "error");
    }
  };

  const onClickAddCategory = () => {
    setIsAddCategoryDrawerOpen(!isAddCategoryDrawerOpen);
  };

  const moveAndDeleteConfirmationHandler = () => {
    setDeleteCategoriesDrawersForMoveAndDelete({
      ...deleteCategoriesDrawersForMoveAndDelete,
      deleteCategory: false,
      moveItems: false,
      confirmMoveAndDelete: true,
    });
  };

  const finalDeleteCatWithItem = (selectcatForMoveItem, selectedCat) => {
    customAxios
      .delete(
        `/restaurant/${restaurantId}/menu/${selectedMenuCategories?.id}/category/${selectedCat?.id}?move_category_id=${selectcatForMoveItem?.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          setDeleteCategoriesDrawersForMoveAndDelete({
            ...deleteCategoriesDrawersForMoveAndDelete,
            deleteCategory: false,
            moveItems: false,
            confirmMoveAndDelete: false,
          });
          let filterData =
            selectedMenuCategories &&
            selectedMenuCategories?.categories.filter(
              (el) => el.id !== selectedCat.id
            );
          setSelectedMenucatogories({
            ...selectedMenuCategories,
            categories: filterData,
          });
          setChosenCategory({});
          setSelectedCatForMoveItems({});
          getMenuDetailById();
        } else {
        }
      })
      .catch((err) => {});
  };

  const finalDeleteCategoryHandler = (selectedCat) => {
    customAxios
      .delete(
        `/restaurant/${restaurantId}/menu/${selectedMenuCategories?.id}/category/${countCatgoryItemState?.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          setDeleteCategoriesDrawersForMoveAndDelete({
            ...deleteCategoriesDrawersForMoveAndDelete,
            deleteCategory: false,
            moveItems: false,
            confirmMoveAndDelete: false,
            noCategoriesDelete: false,
          });
          let filterData =
            selectedMenuCategories &&
            selectedMenuCategories?.categories.filter(
              (el) => el.id !== countCatgoryItemState.id
            );
          setSelectedMenucatogories({
            ...selectedMenuCategories,
            categories: filterData,
          });
          setChosenCategory({});
          setSelectedCatForMoveItems({});
          getMenuDetailById();
        } else {
        }
      })
      .catch((err) => {});
  };

  const getMenuDetailById = async () => {
    if (menuId) {
      const result = await restaurantServices.getRestaurantMenuDetail(
        id,
        menuId
      );
      if (result.status === 200) {
        setSelectedMenu(result?.data);
      }
    }
  };

  const editCategoryHandler = (editedName) => {
    let filterArray = selectedMenuCategories?.categories.filter(
      (el) => el.name === editedName
    );
    if (
      filterArray.length >= 1 &&
      selectedCategory?.id !== filterArray[0]?.id
    ) {
      showMessage("category name already exists", "error");
    } else {
      let editData = {
        name: editedName,
      };
      customAxios
        .put(
          `/restaurant/${restaurantId}/menu/${selectedMenuCategories?.id}/category/${selectedCategory?.id}`,
          editData
        )
        .then((res) => {
          if (res?.status === 200) {
            let data = selectedMenuCategories;
            let findIndex = data?.categories.findIndex(
              (el) => el.id === selectedCategory.id
            );
            let categoryObj = data?.categories[findIndex];
            categoryObj.name = editedName;
            data.categories[findIndex] = categoryObj;
            setSelectedMenucatogories(data);
            setIsCategoryEdit(!isCategoryEdit);
            setSelctedCategory("");
            getMenuDetailById();
          } else {
          }
        })
        .catch((err) => {});
    }
  };

  const countCategoryItem = (countCategoryItem) => {
    setCountCatgoryItemState(countCategoryItem);
    if (countCategoryItem?.items?.length > 0) {
      setDeleteCategoriesDrawersForMoveAndDelete({
        ...deleteCategoriesDrawersForMoveAndDelete,
        deleteCategory: true,
      });
    } else {
      setIsCategoryDrawerOpen(!isCategoryDrawerOpen);
    }
  };

  // function getCategoryData() {
  //   console.log("test")
  //   customAxios
  //     .get(`/restaurant/${id}?get_detail=true`)
  //     .then((res) => {
  //       res && res?.foods && res.foods.map((food) => {
  //         setCountCatgoryItemState(food)
  //       })
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  // const getRestaurantProfile = async () => {
  //   const result = await restaurantServices.getRestaurantDetail(id);
  //   if (result.status === 200) {
  //     setFoods(result.data.foods);
  //   }
  // };

  return (
    <MenuCategoriesContainer>
      {/* <SingleText size={17} weight="bold" marginBottom={15}>
        Menu Categories
      </SingleText> */}
      {/* <p className="tip">
        Categories help customers browse through your <br/> menu more efficiently.{" "}
        <span className="tip-example">
          Example: Featured, <br/> Specials, Appetizers, Soups & Salads, Entrees, <br />
          Dessert, Drinks, etc.
        </span>
      </p> */}
      {deleteCategoryNotification && (
        <MyMessageBoxItemNew
          message={
            deleteCategoryNotification?.message
              ? deleteCategoryNotification?.message
              : ""
          }
          table={
            deleteCategoryNotification?.table
              ? deleteCategoryNotification?.table
              : ""
          }
          type={
            deleteCategoryNotification?.type
              ? deleteCategoryNotification?.type
              : ""
          }
          onClick={() => setDeleteCategoryNotification(null)}
        />
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {selectedMenuCategories &&
                selectedMenuCategories?.categories?.map(
                  (subCategory, index) => {
                    return (
                      <Draggable
                        key={subCategory.id}
                        draggableId={`${subCategory.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Box
                              className={`${
                                snapshot.isDragging
                                  ? "sub-category dragging"
                                  : "sub-category"
                              }`}
                              key={"sub-category-" + index}
                            >
                              <Box className="title-with-control">
                                <div {...provided.dragHandleProps}>
                                  <Box className="drag-icon">
                                    <FaBars
                                      color={`${
                                        snapshot.isDragging
                                          ? "white"
                                          : colors.white3
                                      }`}
                                    />
                                  </Box>
                                </div>
                                <SingleText spacing="0.27px">
                                  {formatName(subCategory.name)}
                                </SingleText>
                              </Box>
                              <Box className="delete-icon cursor-pointer">
                                <FaPencilAlt
                                  color={colors.white3}
                                  style={{ marginRight: "10px" }}
                                  onClick={() => {
                                    setIsCategoryEdit(!isCategoryEdit);
                                    setSelctedCategory(subCategory);
                                  }}
                                />
                                <FaTrashAlt
                                  color={colors.white3}
                                  // previous code
                                  // onClick={() => {
                                  //   setChosenCategory(subCategory);
                                  //   if (selectedMenuCategories?.categories?.length > 1) {
                                  //     setDeleteCategoriesDrawersForMoveAndDelete(
                                  //       {
                                  //         ...deleteCategoriesDrawersForMoveAndDelete,
                                  //         deleteCategory: true,
                                  //       }
                                  //     );
                                  //   } else {
                                  //     setIsCategoryDrawerOpen(
                                  //       !isCategoryDrawerOpen
                                  //     );
                                  //   }
                                  // }}

                                  onClick={() => {
                                    countCategoryItem(subCategory);
                                  }}
                                />
                              </Box>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {type === "Add" ? null : (
        <Box className="add-category-container" onClick={onClickAddCategory}>
          <SingleText
            size={14}
            spacing="0.27px"
            color={colors.redColor}
            weight={600}
          >
            Add Category
          </SingleText>
        </Box>
      )}
      <DrawerDeleteCategory
        isActive={isCategoryDrawerOpen}
        closePanel={() => {
          setIsCategoryDrawerOpen(!isCategoryDrawerOpen);
        }}
        chosenCategory={chosenCategory}
        onClickDeleteCategory={onClickDeleteCategory}
        countCatgoryItemState={countCatgoryItemState}
      />
      <DrawerMoveDeleteCategory
        isActive={deleteCategoriesDrawersForMoveAndDelete.deleteCategory}
        closePanel={() => {
          setDeleteCategoriesDrawersForMoveAndDelete({
            ...deleteCategoriesDrawersForMoveAndDelete,
            deleteCategory: false,
          });
        }}
        setDeleteCategoriesDrawersForMoveAndDelete={
          setDeleteCategoriesDrawersForMoveAndDelete
        }
        deleteCategoriesDrawersForMoveAndDelete={
          deleteCategoriesDrawersForMoveAndDelete
        }
        chosenCategory={chosenCategory}
        countCatgoryItemState={countCatgoryItemState}
      />
      <DrawerMoveCategoriesItem
        isActive={deleteCategoriesDrawersForMoveAndDelete.moveItems}
        closePanel={() => {
          setDeleteCategoriesDrawersForMoveAndDelete({
            ...deleteCategoriesDrawersForMoveAndDelete,
            moveItems: false,
          });
        }}
        setDeleteCategoriesDrawersForMoveAndDelete={
          setDeleteCategoriesDrawersForMoveAndDelete
        }
        deleteCategoriesDrawersForMoveAndDelete={
          deleteCategoriesDrawersForMoveAndDelete
        }
        chosenCategory={chosenCategory}
        selectedMenuCategories={selectedMenuCategories}
        setSelectedCatForMoveItems={setSelectedCatForMoveItems}
        selectedCatForMoveItems={selectedCatForMoveItems}
        moveAndDeleteConfirmationHandler={moveAndDeleteConfirmationHandler}
        countCatgoryItemState={countCatgoryItemState}
      />
      <DrawerMoveCategoryDeleteConfirmation
        isActive={deleteCategoriesDrawersForMoveAndDelete.confirmMoveAndDelete}
        closePanel={() => {
          setDeleteCategoriesDrawersForMoveAndDelete({
            ...deleteCategoriesDrawersForMoveAndDelete,
            confirmMoveAndDelete: false,
          });
        }}
        chosenCategory={chosenCategory}
        selectedCatForMoveItems={selectedCatForMoveItems}
        finalDeleteCatWithItem={finalDeleteCatWithItem}
        finalDeleteCategoryHandler={finalDeleteCategoryHandler}
        countCatgoryItemState={countCatgoryItemState}
      />
      <DrawerIfNoCategoriesDelete
        isActive={deleteCategoriesDrawersForMoveAndDelete.noCategoriesDelete}
        closePanel={() => {
          setDeleteCategoriesDrawersForMoveAndDelete({
            ...deleteCategoriesDrawersForMoveAndDelete,
            noCategoriesDelete: false,
          });
        }}
        deleteCategoryHandler={() => {
          finalDeleteCategoryHandler(chosenCategory);
        }}
        countCatgoryItemState={countCatgoryItemState}
      />
      <DrawerCategory
        isActive={isAddCategoryDrawerOpen}
        closePanel={() => {
          setIsAddCategoryDrawerOpen(!isAddCategoryDrawerOpen);
        }}
        type="Add"
        selectedMenuInfo={selectedMenuCategories}
        setSelectedMenucatogories={setSelectedMenucatogories}
        categoriesList={selectedMenuCategories?.categories}
      />
      <DrawerEditCategory
        isActive={isCategoryEdit}
        closePanel={() => {
          setIsCategoryEdit(!isCategoryEdit);
          setSelctedCategory("");
        }}
        selectedCategory={selectedCategory}
        editCategoryHandler={editCategoryHandler}
      />
      <DrawerCheckExistsCategory
        isActive={drawerAlreadyExistMenuCategory}
        closePanel={() => {
          setDrawerAlreadyExistMenuCategory(!drawerAlreadyExistMenuCategory);
        }}
      />
    </MenuCategoriesContainer>
  );
};

export default MenuCategories;
