import React from "react";
import PropTypes from "prop-types";
import { AnalyzeFoodContainer } from "./style";
export default function AnalyzeFood({
  price,
  rating,
  calories,
  averageRating,
}) {
  return (
    <AnalyzeFoodContainer>
      <span className="price-title" key="food-price">{`$ ${price ??
        "N/A"}`}</span>
      <span className="rating-container" key="food-rating">
        <i className="fas fa-star" />
        {rating ? averageRating : "-"}
      </span>
      <span className="calories-container" key="food-calories">
        <i className="fas fa-fire calories-icon-item" />
        {calories ? `${calories} cal` : "N/A"}
      </span>
    </AnalyzeFoodContainer>
  );
}

AnalyzeFood.propTypes = {
  price: PropTypes.number,
  rating: PropTypes.number,
  calories: PropTypes.number,
  averageRating: PropTypes.any,
};
