import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AnalyticsPageContainer } from "./style";
import { AnalyticsOrdersPage } from "../AnalyticsOrdersPage";
import { AnalyticsRestaurantsPage } from "../AnalyticsRestaurantsPage";
import { AnalyticsCustomersPage } from "../AnalyticsCustomersPage";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useLocation } from "react-router-dom";

/**
 * page can be:
 * 1. `Orders` sub page
 * 2. `Restaurants` sub page
 * 3. `Customers` sub page
 */
export function AnalyticsPage() {
  let location = useLocation();

  const [analyticsReataurant, setAnalyticsReataurant] = useState({});
  const { total_restaurants, total_staffs } = analyticsReataurant;

  const { page } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const totalRestaurants = [
    // {
    //   title: "Total Restaurants",
    //   value: total_restaurants,
    // },
    {
      title: "Total Staff",
      value: total_staffs,
    },
  ];

  const totalCustomers = [
    {
      title: "Total Customers",
      value: "12,742",
    },
  ];

  useEffect(() => {
    getAnalyticsRestaurant();
  }, []);

  function getAnalyticsRestaurant() {
    if (location.pathname === "/analytics/restaurant") {
      customAxios
        .get(`/analytics-restaurant`)
        .then((res) => {
          setAnalyticsReataurant(res?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <AnalyticsPageContainer>
      {location.pathname === "/analytics/order" && (
        <AnalyticsOrdersPage active={isPageActive(1, Number(page))} />
      )}

      {location.pathname === "/analytics/restaurant" && (
        <AnalyticsRestaurantsPage
          active={isPageActive(2, Number(page))}
          total={totalRestaurants}
        />
      )}

      {location.pathname === "/analytics/customer" && (
        <AnalyticsCustomersPage
          active={isPageActive(3, Number(page))}
          total={totalCustomers}
        />
      )}
    </AnalyticsPageContainer>
  );
}

export const isPageActive = (page, currentPage) => page === currentPage;
