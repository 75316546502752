import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerAddCuisinesModalContainer = styled(MyDrawerCenterModal)`
  .add-cuisine-wrapper {
    padding: 0 28px 0 28px;

    .cuisine-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-cuisine-title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        margin: 28px 0 28px 0;
      }
    }

    .avatar-uploader {
      .photo {
        .photo-hover {
          border-radius: 20px !important;
        }
        .uploaded-img {
          width: auto;
          max-width: 100%;
          height: 288px !important;
          border-radius: 20px !important;
          object-fit: cover;
        }
      }
    }

    .photo-hover {
      .input-title {
        left: unset !important;
        bottom: 8px !important;
        font-weight: 500 !important;
        font-size: 15px !important;
      }
    }

    .cuisine-image {
      position: relative;
      left: 10rem;
    }

    .cuisine-input {
      margin: 20px 0 28px 0;
    }

    .add-cuisine-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 10px;
      height: 50px;
    }

    .cancel-btn {
      margin: 32px 0 35px 0;
      text-align: center;
      color: #ed2626;
    }
  }
`;
