import React, { useState, useEffect, useContext } from "react";
import { StaffPageContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { Link, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { StaffProfileCard } from "./StaffProfileCard";
import { StaffRestaurant } from "./StaffRestaurant";
import { StaffNotes } from "./StaffNotes";
import { StaffReviews } from "./StaffReviews";
import { colors } from "theme";

export const StaffProfilePage = () => {
  const { id } = useParams();
  const { restaurant, staffViewData } = useContext(RestaurantsContext);

  return (
    <StaffPageContainer>
      <div className="staff-profile-wrapper">
        <div className="top-navigation-panel">
          <div className="nav-line-to-back">
            <Link to="/restaurants" className="backlink-to-rest">
              Restaurants
            </Link>
            <span className="arrow-icon-container mx-3">
              <i
                className="fas fa-angle-right"
                style={{ color: colors.gray }}
              />
            </span>
            <Link
              to={`/restaurant-detail/${id}?view=staff&mode=all`}
              className="backlink-to-rest"
            >
              <span>{restaurant?.title}</span>
            </Link>
            <span className="arrow-icon-container mx-3">
              <i
                className="fas fa-angle-right"
                style={{ color: colors.gray }}
              />
            </span>
            <span className="current-restaurant">
              {staffViewData?.first_name} {staffViewData?.last_name}
            </span>
          </div>
        </div>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={4}
        >
          <Grid container item xs={12} md={6}>
            <div
              className="restaurant-profile-card"
              style={{ padding: "22px 36px" }}
            >
              <StaffProfileCard />
            </div>

            <div className="w-100 add-promo-code-wrapper">
              <StaffRestaurant />
            </div>
          </Grid>

          <Grid container item xs={12} md={6}>
            <div className="restaurant-profile-card profile-stat">
              <StaffNotes />
            </div>

            <div className="restaurant-profile-card restaurant-notes">
              <StaffReviews />
            </div>
          </Grid>
        </Grid>
      </div>
    </StaffPageContainer>
  );
};
