import React, { useState, createContext } from "react";
import { promiseRequest } from "helpers/custom-helpers/promiseRequest";
import { customerServices } from "services";

const CustomersContext = createContext({});

export { CustomersContext };

export const CustomersContextContainer = ({ children }) => {
  // Panels
  const openDrawer = (drawer) => {
    setOpenDrawers({ ...openDrawers, [drawer]: drawer });
  };
  const closeDrawer = (drawer) => {
    // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
    let temp = { ...openDrawers };
    delete temp[drawer];
    setOpenDrawers(temp);
  };

  const [filterCustomer, setFilterCustomer] = useState({});
  // Filters
  const [statusFilter, setStatusFilter] = useState("");

  // Data
  const [customersList, setCustomersList] = useState([]);
  const [customerStats, setCustomerStats] = useState({});
  const [getSuggestionList, setSuggestionList] = useState([]);
  const [openDrawers, setOpenDrawers] = useState({});
  const [profileData, setProfileData] = useState({});
  const [customerId, setCustomerId] = useState([]);
  const [editCustomerData, setEditCustomerData] = useState({});
  const [deleteCustomerModals, setDeleteCustomerModals] = useState({
    deleteCustomer: false,
    deleteConfirmation: false,
    confirmationOTP: false,
    deleteConfirmed: false,
    customerHaveOrders: false,
  });
  const [deleteCustomerInput, setDeleteCustomerInput] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    five: "",
    six: "",
  });

  const [
    editCustomerInactiveStatusNote,
    setEditCustomerInactiveStatusNote,
  ] = useState("");

  const [notesData, setNotesData] = useState([]);

  // Request Functions
  function getCustomerList(parameters, status) {
    const { getCustomer } = customerServices;
    return promiseRequest(setCustomersList, getCustomer, parameters, status);
  }

  function getCustomerStats() {
    const { getCustomersStats } = customerServices;
    return promiseRequest(setCustomerStats, getCustomersStats);
  }

  function getSuggestions(parameters) {
    const { getSearchSuggestions } = customerServices;
    return promiseRequest(setSuggestionList, getSearchSuggestions, parameters);
  }

  function deleteCustomers(customerId) {
    const { deleteCustomer } = customerServices;
    return promiseRequest(null, deleteCustomer, customerId);
  }

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [customerListTab, setCustomerListTab] = useState(1);

  const [
    customerProfileNotification,
    setCustomerProfileNotification,
  ] = useState("");

  const [customerBanId, setCustomerBanId] = useState("");

  const [customerName, setCustomerName] = useState({});

  const [customerActiveNote, setCustomerActiveNote] = useState("");
  const [inactiveReason, setInactiveReason] = useState("");
  const [customerInactiveNote, setCustomerInactiveNote] = useState("");

  const [deleteNoteData, setDeleteNoteData] = useState({
    deleteModal: false,
    selectedNoteData: "",
  });

  return (
    <CustomersContext.Provider
      value={{
        openDrawer,
        closeDrawer,
        filterCustomer,
        setFilterCustomer,
        statusFilter,
        setStatusFilter,
        customerId,
        setCustomerId,
        editCustomerData,
        setEditCustomerData,

        customersList,
        setCustomersList,
        customerStats,
        getSuggestionList,

        getCustomerList,
        getCustomerStats,
        getSuggestions,
        openDrawers,
        setOpenDrawers,
        profileData,
        setProfileData,

        deleteCustomers,

        editCustomerInactiveStatusNote,
        setEditCustomerInactiveStatusNote,

        notesData,
        setNotesData,
        deleteCustomerModals,
        setDeleteCustomerModals,
        deleteCustomerInput,
        setDeleteCustomerInput,

        state,
        setState,

        customerListTab,
        setCustomerListTab,

        customerProfileNotification,
        setCustomerProfileNotification,

        customerBanId,
        setCustomerBanId,

        customerName,
        setCustomerName,

        customerActiveNote,
        setCustomerActiveNote,
        inactiveReason,
        setInactiveReason,
        customerInactiveNote,
        setCustomerInactiveNote,

        deleteNoteData,
        setDeleteNoteData,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};
