import React from "react";
import { NumberVerifiedModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { MobileImage } from "assets/img";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { SuccessFull } from "assets/img";

const NumberVerifiedModal = ({ isActive = false, closePanel = () => {} }) => {
  return (
    <NumberVerifiedModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-number">
          <div className="modal-image">
            <img src={SuccessFull} alt="" />
          </div>
          <div className="verified-icon-check">
            <i className="fas fa-check" />
          </div>
          <div className="modal-title-new-number">
            Phone number successfully updated
          </div>
          <div className="verify-actions">
            <MyDrawerButton
              type="red"
              onClick={() => {
                closePanel();
              }}
            >
              <b>Okay</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </NumberVerifiedModalContainer>
  );
};

export default NumberVerifiedModal;
