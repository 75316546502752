/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, Fragment } from "react";
import TableItem from "./TableItem";
import { TableListContainer } from "./style";
import { restaurantServices } from "services";
import { useParams } from "react-router-dom";
import MyPaginator from "components/Elements/MyPaginator";
import { FaSpinner } from "react-icons/fa";
import { colors } from "theme";
import { useHistory } from "react-router-dom";

import Table from "react-bootstrap/Table";

export const TableList = () => {
  const [tableListData, setTableListData] = useState([]);

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedLimitToShow, setSelectedLimitToShow] = useState(13);
  const [totalCount, setTotalCount] = useState();

  const [tableSortToggleBtn, setTableSortToggleBtn] = useState(true);
  const [statusSortToggleBtn, setStatusSortToggleBtn] = useState(true);

  const history = useHistory();
  const { id } = useParams();

  const getTables = async () => {
    const result = await restaurantServices.getTables(id, {
      page: selectedPage,
      page_size: selectedLimitToShow,
      sort: 3,
    });
    if (result.status === 200) {
      const sortTable = result?.data?.tables.sort(
        (a, b) => a.table_number - b.table_number
      );
      setTableListData(sortTable);
      setTotalCount(result?.data?.total_count);
    }
  };

  useEffect(() => {
    getTables();
  }, [selectedLimitToShow, selectedPage, id]);

  // useEffect(() => {
  //   getTables();
  // }, [id]);

  const onPageChange = async (e) => {
    setSelectedPage(e.selected + 1);
  };

  function sortTableDataA() {
    setTableSortToggleBtn(true);
    const sortD = [...tableListData].sort(
      (a, b) => +a.table_number - +b.table_number
    );
    setTableListData(sortD);
  }

  function sortTableDataD() {
    setTableSortToggleBtn(false);
    const sortD = [...tableListData].sort(
      (a, b) => +b.table_number - +a.table_number
    );
    setTableListData(sortD);
  }

  function handleStatusD() {
    setStatusSortToggleBtn(false);
    const sortStatus = [...tableListData].sort((a, b) => +b.status - +a.status);
    // console.log(sortStatus)
    setTableListData(sortStatus);
  }

  function handleStatusA() {
    setStatusSortToggleBtn(true);
    const sortStatus = [...tableListData].sort((a, b) => +a.status - +b.status);
    // console.log(sortStatus)
    setTableListData(sortStatus);
  }

  function handleTotalD() {
    setStatusSortToggleBtn(false);
    const sortStatus = [...tableListData].sort(
      (a, b) => +b.total_price - +a.total_price
    );
    // console.log(sortStatus)
    setTableListData(sortStatus);
  }

  function handleTotalA() {
    setStatusSortToggleBtn(true);
    const sortStatus = [...tableListData].sort(
      (a, b) => +a.total_price - +b.total_price
    );
    // console.log(sortStatus)
    setTableListData(sortStatus);
  }

  return (
    <TableListContainer className="tables-wrapper">
      <Table>
        <thead>
          <tr>
            <th>
              Table{" "}
              {tableSortToggleBtn ? (
                <i
                  className="far fa-angle-down cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={sortTableDataD}
                />
              ) : (
                <i
                  className="far fa-angle-up cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={sortTableDataA}
                />
              )}
            </th>
            <th>
              Status{" "}
              {statusSortToggleBtn ? (
                <i
                  className="fas fa-angle-down cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={handleStatusD}
                />
              ) : (
                <i
                  className="far fa-angle-up cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={handleStatusA}
                />
              )}
            </th>
            <th>Order #</th>
            <th>Guests</th>
            <th>Items</th>
            <th>
              Order Total{" "}
              {statusSortToggleBtn ? (
                <i
                  className="fas fa-angle-down cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={handleTotalD}
                />
              ) : (
                <i
                  className="far fa-angle-up cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={handleTotalA}
                />
              )}
            </th>
            <th>Server</th>
            <th>Floor</th>
          </tr>
        </thead>
        <tbody className="p-3">
          {tableListData &&
            tableListData.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td className="text-bold">Table {item?.table_number}</td>
                    <td>
                      {item?.status === 2 && (
                        <span
                          style={{
                            color: "#f7a64c",
                            padding: "2px 6px",
                            background: "#27282A",
                            borderRadius: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Occupied
                        </span>
                      )}
                      {item?.status === 1 && (
                        <span
                          style={{
                            color: "#1fc086",
                            padding: "2px 6px",
                            background: "#27282A",
                            borderRadius: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Active
                        </span>
                      )}
                      {item?.status === 0 && (
                        <span
                          style={{
                            color: "#60646c",
                            padding: "2px 6px",
                            background: "#27282A",
                            borderRadius: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Inactive
                        </span>
                      )}
                    </td>
                    <td
                      className="text-bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: item.order_token ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (item?.order_token) {
                          history.push(`/order-detail/${item?.order_token}`);
                        }
                      }}
                    >
                      <span
                        style={{ color: item.order_token ? "#ed2626" : "#fff" }}
                      >
                        {item?.order_token || "-"}{" "}
                      </span>{" "}
                      {item?.order_token && (
                        <i
                          className="fas fa-chevron-right"
                          style={{ fontSize: "11px", color: "#ed2626" }}
                        />
                      )}
                    </td>
                    <td>{`${item?.guest_count ? item?.guest_count : 0} / ${
                      item?.seats ? item?.seats : "-"
                    }`}</td>
                    <td>{item?.item_count || "-"}</td>
                    <td className="text-bold">{item?.total_price || "-"}</td>
                    <td>
                      {item?.waiter_name?.trim() ? item?.waiter_name : "-"}
                    </td>
                    <td>{item?.floor_name || "-"}</td>
                  </tr>
                </Fragment>
              );
            })}
        </tbody>
      </Table>

      <div className="mt-5">
        <MyPaginator
          initialPage={0}
          totalCount={totalCount}
          limitShow={selectedLimitToShow}
          onPageChange={onPageChange}
        />
      </div>
    </TableListContainer>
  );
};
