import React, { useContext, useEffect, useState } from "react";
import MyButton from "components/Elements/MyButton";
import MySearch from "components/Elements/MySearch";
import { CuisinePageContainer } from "./style";
import { colors } from "theme";
import AddCuisines from "./CuisineModalScreen/AddCuisineModal";
import { RestaurantsContext } from "context/RestaurantsContext";
// import { drawersOverall } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyPromoCodeSearchComponent from "components/Elements/MyPromoCodeSearch";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import noResultImg from "../../../assets/img/no-filter-data.png";

export function CuisinesPage() {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    cuisineList,
    setCuisineList,
    updateCuisineDetailsData,
    setUpdateCuisineDetailsData,
    cuisineCloseDrawer,
    setCuisineCloseDrawer,
    setCuisineImageFile,
    statusActiveNotification,
    setStatusActiveNotification,
    statusInactiveNotification,
    setStatusInactiveNotification,
  } = useContext(RestaurantsContext);

  const [cuisineToShow, setCuisineToShow] = useState([]);
  // const [searchCuisine, setSearchCuisine] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [nameFilter, setNameFilter] = useState(true);
  const [restaurantFilter, setRestaurantFilter] = useState(false);

  useEffect(() => {
    if (cuisineList?.length) {
      setCuisineToShow(cuisineList);
    }
  }, [cuisineList]);

  useEffect(() => {
    if (searchFilter) {
      setCuisineToShow(
        cuisineList.filter((cuisine) =>
          cuisine.name.toLowerCase().includes(searchFilter.toLowerCase())
        )
      );
    } else {
      setCuisineToShow(cuisineList);
    }
  }, [searchFilter]);

  useEffect(() => {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
        setCuisineList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (statusActiveNotification) {
      setTimeout(() => setStatusActiveNotification(null), 2 * 1000);
    }
  }, [statusActiveNotification]);

  useEffect(() => {
    if (statusInactiveNotification) {
      setTimeout(() => setStatusInactiveNotification(null), 2 * 1000);
    }
  }, [statusInactiveNotification]);

  function updateCuisineDetails(cuisineDetails) {
    setUpdateCuisineDetailsData(cuisineDetails);
    // openDrawer(drawersOverall.addCuisines);
    setCuisineCloseDrawer({
      ...cuisineCloseDrawer,
      addCuisines: true,
    });
  }

  // const onChangeSearchCuisine = (newValue) => setSearchCuisine(newValue);
  // const onClearSearchCuisine = () => setSearchCuisine("");

  // const onChange = (event) => {
  //   setSearchFilter(event.target.value);
  // };

  const nameFilterd = () => {
    setNameFilter(!nameFilter);
  };

  const restaurantFilterd = () => {
    setRestaurantFilter(!restaurantFilter);
    if (restaurantFilter === true) {
      setCuisineToShow((prev) =>
        [...prev].sort((a, b) => (a?.used > b?.used ? 1 : -1))
      );
    } else {
      if (restaurantFilter === false) {
        // setCuisineToShow(cuisineList);
        setCuisineToShow((prev) =>
          [...prev].sort((a, b) => (b?.used > a?.used ? 1 : -1))
        );
      }
    }
  };

  useEffect(() => {
    if (nameFilter === true) {
      setCuisineToShow(cuisineList);
    } else {
      if (nameFilter === false) {
        setCuisineToShow((prev) => [...prev].reverse());
      }
    }
  }, [nameFilter, cuisineList]);

  // useEffect(() => {
  //   if (restaurantFilter === true) {
  //     setCuisineToShow((prev) =>
  //       [...prev].sort((a, b) => (a?.used > b?.used ? 1 : -1))
  //     );
  //   } else {
  //     if (restaurantFilter === false) {
  //       // setCuisineToShow(cuisineList);
  //       setCuisineToShow((prev) =>
  //       [...prev].sort((a, b) => (b?.used > a?.used ? 1 : -1))
  //     );
  //     }
  //   }
  // }, [restaurantFilter, cuisineList]);

  useEffect(() => {
    if (searchFilter) {
      setCuisineToShow(
        cuisineList.filter((cuisine) =>
          cuisine.name.toLowerCase().includes(searchFilter.toLowerCase())
        )
      );
    } else {
      setCuisineToShow(cuisineList);
    }
  }, [searchFilter]);

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <CuisinePageContainer>
      {/* post */}
      {statusActiveNotification && (
        <MyMessageBoxItemNew
          message={
            statusActiveNotification?.message
              ? statusActiveNotification?.message
              : ""
          }
          table={
            statusActiveNotification?.table
              ? statusActiveNotification?.table
              : ""
          }
          type={
            statusActiveNotification?.type ? statusActiveNotification?.type : ""
          }
          onClick={() => setStatusActiveNotification(null)}
        />
      )}

      {/* put */}
      {statusInactiveNotification && (
        <MyMessageBoxItemNew
          message={
            statusInactiveNotification?.message
              ? statusInactiveNotification?.message
              : ""
          }
          table={
            statusInactiveNotification?.table
              ? statusInactiveNotification?.table
              : ""
          }
          type={
            statusInactiveNotification?.type
              ? statusInactiveNotification?.type
              : ""
          }
          onClick={() => setStatusInactiveNotification(null)}
        />
      )}

      <div className="cuisine-wrapper">
        <div className="cuisine-header">
          <div className="cuisine-title">Cuisines</div>
          <div className="filter-wrapper">
            <div className="search-filter">
              <MySearch
                value={searchFilter}
                onChange={(e) => {
                  if (e?.target?.value === undefined) {
                    setSearchFilter("");
                  } else {
                    setSearchFilter(e?.target?.value);
                  }
                }}
                defaultShow={true}
                placeHolder="Search Cuisines"
              />
            </div>
            <div className="add-cuisine">
              <MyButton
                className="add-cuisine-btn"
                type="red"
                onClick={() => {
                  // openDrawer(drawersOverall.addCuisines);
                  setCuisineCloseDrawer({
                    ...cuisineCloseDrawer,
                    addCuisines: true,
                  });
                  setUpdateCuisineDetailsData("");
                  setCuisineImageFile(null);
                }}
              >
                <i class="far fa-plus" /> Add Cuisine
              </MyButton>
            </div>
          </div>
        </div>

        {searchFilter?.length > 0 && (
          <div className="search-applied">
            <div className="text-bold">Search Applied</div>
            <div>
              <span className="text-bold">{cuisineToShow?.length}</span>{" "}
              {cuisineToShow?.length > 1 ? "results" : "result"}
            </div>
          </div>
        )}

        {cuisineToShow?.length > 0 && (
          <div className="header-title">
            <div className="name cursor" onClick={nameFilterd}>
              Name{" "}
              {nameFilter ? (
                <i
                  class="fas fa-angle-down ml-1"
                  style={{ fontSize: "14px" }}
                />
              ) : (
                <i class="fas fa-angle-up ml-1" style={{ fontSize: "14px" }} />
              )}
            </div>
            <div className="restaurant cursor" onClick={restaurantFilterd}>
              Restaurants{" "}
              {restaurantFilter ? (
                <i
                  class="fas fa-angle-down ml-1"
                  style={{ fontSize: "14px" }}
                />
              ) : (
                <i class="fas fa-angle-up ml-1" style={{ fontSize: "14px" }} />
              )}
            </div>
          </div>
        )}

        {cuisineToShow?.length > 0 ? (
          <>
            {cuisineToShow &&
              cuisineToShow.map((_cuisine, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="cuisine-list mt-3">
                      <div className="name-left">
                        <div className="cuisine-image">
                          <img src={_cuisine?.image} alt="" height={20} />
                        </div>
                        <div className="cuisine-name">
                          {getHighlightedText(
                            _cuisine.name,
                            searchFilter,
                            "#ed2626"
                          )}
                        </div>
                      </div>
                      <div className="restaurent-right">
                        <div
                          className="cuisine-image"
                          style={{ fontSize: "16px", fontWeight: 600 }}
                        >
                          {_cuisine?.used}
                        </div>
                        <div
                          className="cuisine-name cursor"
                          onClick={() => {
                            updateCuisineDetails(_cuisine);
                          }}
                        >
                          <i
                            class="fas fa-cog"
                            style={{
                              color: `#8f949a`,
                              fontSize: "18px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </>
        ) : (
          <>
            <div>
              <div className="no-result">
                <div className="no-result-img">
                  <img src={noResultImg} alt="No result" />
                </div>
                {searchFilter?.length > 0 ? (
                  <>
                    <div className="no-result-title">No results</div>
                    <div className="no-result-subtitle">
                      Try broadening your search.
                    </div>
                  </>
                ) : (
                  <div className="no-result-title">No results</div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <AddCuisines
        // isActive={!!openDrawers[drawersOverall.addCuisines]}
        // closePanel={() => closeDrawer(drawersOverall.addCuisines)}

        isActive={cuisineCloseDrawer.addCuisines}
        closePanel={() => {
          setCuisineCloseDrawer({
            ...cuisineCloseDrawer,
            addCuisines: false,
          });
        }}
      />
    </CuisinePageContainer>
  );
}
