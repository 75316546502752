import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerMenusContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.gray5};
    border-radius: 16px;
    border: 1px solid transparent;
    padding: 20px 22px;

    &.disabled {
      opacity: 0.4;
    }

    .status-and-look {
      cursor: pointer;
      display: flex;
      align-items: center;

      .status {
        margin-right: 15px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #5f6061;

        &.active {
          background-color: ${colors.green};
        }
      }
    }
  }

  .edit-menu-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #5b5e61;
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 15px;

    .bar-title-inline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }

    .icon-drag {
      cursor: grab;
    }
  }
`;
