/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle from "theme/globalStyle";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../layout";
import { useMediaQuery } from "react-responsive";
import ThisAppIsNotSupported from "./pages/ThisAppIsNotSupported";

export default function App({ store, persistor, basename }) {
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });

  const desktopViewRender = (component) => {
    if (isMobile) {
      return <ThisAppIsNotSupported />;
    } else {
      return component;
    }
  };
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <GlobalStyle />
                {desktopViewRender(<Routes />)}
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
