import styled from "@emotion/styled";
import { colors } from "theme";

export const AsideContainer = styled.div`
  &.main-aside-menu {
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2);
    background-color: ${colors.gray2};
    width: 250px;
    .brand {
      background-color: ${colors.gray2};
    }
    .aside-menu-wrapper {
      .aside-menu {
        position: relative;
        background-color: ${colors.gray2};
      }

      .my-account-navlink {
        cursor: pointer;
        color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 15px 5px;
        width: 100%;
        .my-account-navlink-wrapper {
          padding: 5px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom-left-radius: 12px;
          border-top-left-radius: 12px;
          margin-right: -15px;
          .my-account-context {
            display: flex;
            flex-direction: column;
            .my-account-name {
              font-size: 13px;
              font-weight: 600;
            }
            .my-account-role {
              opacity: 0.5;
              font-size: 13px;
            }
          }
          .my-account-image {
            padding-right: 15px;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              border: solid 3px rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
      .my-account-navlink:hover {
        .my-account-navlink-wrapper {
          background-color: #333437;
        }
      }
    }
  }
`;
