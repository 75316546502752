import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { FaChevronLeft, FaTrash } from "react-icons/fa";
import { useParams } from "react-router";
import { restaurantServices } from "services";
import { drawersMenu } from "static/staticData";
import { colors } from "theme";
import DrawerCategoryFirstOptions from "../DrawerCategoriesFirstOptions";
import { DrawerCategoryContainer } from "./style";

export default function DrawerCategory({
  type = "Add",
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();

  const {
    postCategory,
    setPostCategory,
    selectedMenu,
    setRestaurant,
    setMenus,
    setFoods,
    setSelectedMenu,
    openDrawer,
    closeDrawer,
    openDrawers,
    categoryCloseDrawer,
    setCategoryCloseDrawer,
  } = useContext(RestaurantsContext);

  const onClickDone = async () => {
    if (type === "Add") {
      const result = await restaurantServices.addNewCategory(
        id,
        selectedMenu.id,
        { name: postCategory.name }
      );
      if (result.status === 200) {
        await getRestaurant();
        closePanel();
      }
    } else {
      const result = await restaurantServices.updateCategory(
        id,
        selectedMenu.id,
        postCategory.id,
        { name: postCategory.name }
      );
      if (result.status === 200) {
        await getRestaurant();
        closePanel();
      }
    }
  };

  const getRestaurant = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setRestaurant(result.data.restaurant);
      setMenus(result.data.menus);
      setFoods(result.data.foods);
      if (selectedMenu?.id) {
        setSelectedMenu(
          result.data.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      }
    }
  };

  function onOpenDrawer() {
    // openDrawer(drawersMenu?.openDrawerCategoryFirstOptions);
    setCategoryCloseDrawer({
      ...categoryCloseDrawer,
      openDrawerCategoryFirstOptions: true,
    });
  }

  // console.log(postCategory)

  return (
    <DrawerCategoryContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">{type} Category</div>
          <div>
            {type === "Add" ? (
              <></>
            ) : (
              <FaTrash
                size={18}
                color={colors.redColor}
                className="cursor-pointer"
                onClick={onOpenDrawer}
              />
            )}
          </div>
        </div>
        <div className="drawer-categories-wrapper mb-5">
          <DrawerInput
            label="Category Name"
            placeholder="Enter Full Name"
            value={postCategory?.name}
            onChange={(e) =>
              setPostCategory({ ...postCategory, name: e.target.value })
            }
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={onClickDone}
            disabled={postCategory?.name === ""}
          >
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerCategoryFirstOptions
        isActive={categoryCloseDrawer.openDrawerCategoryFirstOptions}
        closePanel={() =>
          setCategoryCloseDrawer({
            ...categoryCloseDrawer,
            openDrawerCategoryFirstOptions: false,
          })
        }
      />
    </DrawerCategoryContainer>
  );
}
