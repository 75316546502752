import React, { useContext, useEffect } from "react";
import { BlockOrderDetailContainer } from "./style";
import { drawersOrder } from "static/staticData";
import { OrdersContext } from "context/OrdersContext";
import FoodItemDetail from "./FoodItemDetail";

export default function PaidOrderDetail({
  className = "",
  drinkData,
  qucikFoodData,
  entreesData,
  onClickOrderFoodReview = () => {},
}) {
  const { closeDrawer, orderDetailData } = useContext(OrdersContext);

  useEffect(() => {
    closeDrawer(drawersOrder.deleteOrderItem);
    closeDrawer(drawersOrder.deleteSubOrderConfirmation);
  }, [orderDetailData]);

  return (
    <BlockOrderDetailContainer className={className}>
      <div className="card-block">
        {drinkData
          ?.filter((el) => el.status >= 0)
          .map((menuItem, _i) => {
            return (
              <>
                {_i === 0 ? (
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#fff",
                      marginBottom: "16px",
                    }}
                  >
                    <b>Drinks</b>
                  </div>
                ) : (
                  <></>
                )}
                <FoodItemDetail
                  menuItem={menuItem}
                  key={_i}
                  onClickOrderFoodReview={onClickOrderFoodReview}
                />
              </>
            );
          })}
        {qucikFoodData
          ?.filter((el) => el.status >= 0)
          .map((menuItem, _i) => {
            return (
              <>
                {_i === 0 ? (
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#fff",
                      marginBottom: "16px",
                      marginTop: "20px",
                    }}
                  >
                    <b>Quick Foods</b>
                  </div>
                ) : (
                  <></>
                )}
                <FoodItemDetail
                  menuItem={menuItem}
                  key={_i}
                  onClickOrderFoodReview={onClickOrderFoodReview}
                />
              </>
            );
          })}
        {entreesData
          ?.filter((el) => el.status >= 0)
          .map((menuItem, _i) => {
            return (
              <>
                {_i === 0 ? (
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#fff",
                      marginBottom: "16px",
                      marginTop: "20px",
                    }}
                  >
                    <b>Entrées</b>
                  </div>
                ) : (
                  <></>
                )}
                <FoodItemDetail
                  menuItem={menuItem}
                  key={_i}
                  onClickOrderFoodReview={onClickOrderFoodReview}
                />
              </>
            );
          })}
      </div>
    </BlockOrderDetailContainer>
  );
}
