import React, { useRef, useState, useEffect, useContext } from "react";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { DrawerStaffFilterContainer, PopupCalendarContainer } from "./style";
import { FaChevronLeft } from "react-icons/fa";
import MyDateRange from "components/Elements/MyDateRange";
import MyButton from "components/Elements/MyButton";
import Slider from "rc-slider";
import { useOutsideClick } from "services/functions";
import { RestaurantsContext } from "context/RestaurantsContext";

export default function DrawerActiveStaffFilter({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
}) {
  const joinedDateRef = useRef();

  const {} = useContext(RestaurantsContext);

  const [joinedDateModal, setJoinedDateModal] = useState(false);

  const [numberOfOrders, setNumberOfOrders] = useState({
    from: "",
    to: "",
  });

  const [totalPaidOrders, setTotalPaidOrders] = useState({
    from: "",
    to: "",
  });

  useOutsideClick(joinedDateRef, () => {
    setJoinedDateModal(!joinedDateModal);
  });

  return (
    <DrawerStaffFilterContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              color="#60646c"
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Filter</div>
          <div className="reset-all-btn"></div>
        </div>

        <div className="drawer-content">
          {/* joined */}
          <div className="joined-date-wrapper">
            <label className="join-date-label">Joined Date</label>
            <div className="join-date-div">
              <input
                placeholder="Select date"
                onClick={() => {
                  setJoinedDateModal(!joinedDateModal);
                }}
                className="join-date-input"
                // value={
                //   joinedDate?.startDate === undefined
                //     ? ""
                //     : joinedDate?.startDate === joinedDate?.endDate
                //     ? `${joinedDate?.startDate}`
                //     : `${joinedDate?.startDate} - ${joinedDate?.endDate}`
                // }
              />
              <i
                className="far fa-calendar-alt"
                style={{
                  color: "#fff",
                  opacity: "1",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              />
              {joinedDateModal && (
                <PopupCalendarContainer
                  className="popup-sales-calendar"
                  ref={joinedDateRef}
                >
                  <MyDateRange date={""} months={2} />
                  <MyButton
                    className="apply-date"
                    type={"red"}
                    disabled={false}
                  >
                    Apply
                  </MyButton>
                </PopupCalendarContainer>
              )}
            </div>
          </div>

          {/* rating */}
          <div className="number-of-orders-container">
            <label className="filter-label">
              Rating{" "}
              <>
                <i
                  className="fas fa-times"
                  style={{
                    cursor: "pointer",
                    color: "#ed2626",
                    fontSize: "14px",
                  }}
                />
              </>
            </label>
            <Slider
              range
              allowCross={false}
              defaultValue={[numberOfOrders?.from, numberOfOrders?.to]}
              value={[numberOfOrders?.from, numberOfOrders?.to]}
              onChange={(value) => {
                setNumberOfOrders({
                  from: value[0],
                  to: value[1],
                });
              }}
              max={5}
              step={0.1}
            />
            <div className="input-divs">
              <input
                onChange={(e) => {
                  setNumberOfOrders({
                    ...numberOfOrders,
                    from: e.target.value,
                  });
                }}
                value={numberOfOrders.from}
                placeholder="From"
              />
              <input
                onChange={(e) => {
                  setNumberOfOrders({
                    ...numberOfOrders,
                    to: e.target.value,
                  });
                }}
                value={numberOfOrders.to}
                placeholder="To"
              />
            </div>
          </div>

          {/* active restaurants */}
          <div className="number-of-orders-container">
            <label className="filter-label">
              Active Restaurants
              <i
                className="fas fa-times"
                style={{
                  cursor: "pointer",
                  color: "#ed2626",
                  fontSize: "14px",
                }}
              />
            </label>
            <Slider
              range
              allowCross={false}
              defaultValue={[totalPaidOrders?.from, totalPaidOrders?.to]}
              value={[totalPaidOrders?.from, totalPaidOrders?.to]}
              onChange={(value) => {
                setTotalPaidOrders({
                  from: value[0],
                  to: value[1],
                });
              }}
            />
            <div className="total-paid-divs">
              <div className="total-paid-div">
                <input
                  onChange={(e) => {
                    setTotalPaidOrders({
                      ...totalPaidOrders,
                      from: e.target.value,
                    });
                  }}
                  value={totalPaidOrders.from}
                  placeholder="From"
                />
              </div>
              <div className="total-paid-div">
                <input
                  onChange={(e) => {
                    setTotalPaidOrders({
                      ...totalPaidOrders,
                      to: e.target.value,
                    });
                  }}
                  value={totalPaidOrders.to}
                  placeholder="To"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-btns">
          <MyDrawerButton className="text-bold">
            <b>Apply</b>
          </MyDrawerButton>
          <MyDrawerButton type="redColor" className="mt-4 text-bold">
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerStaffFilterContainer>
  );
}
