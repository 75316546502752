/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { TableLayoutContainer } from "./style";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SingleMovable from "components/Elements/MyMovable";
import { colors } from "theme";
import { StatusBadge } from "./StatusBadge";
import MySelect from "components/Elements/MySelect";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawersMenu } from "static/staticData";
import DrawerFloors from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerFloors";
import { FaMinus, FaPlus, FaRedo, FaUndo, FaPencilAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import DrawerFloor from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerFloor";
import DrawerDeleteFloor from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerDeleteFloor";
import Box from "components/Box";
import DrawerElement from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerElement";
import DrawerTable from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerTable";
import DrawerStatusTable from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerStatusTable";
import DrawerDeleteTable from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerDeleteTable";
import DrawerDeleteTableSide from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerDeleteTableSide";
import DrawerWall from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerWall";
import DrawerDeleteWallSide from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerDeleteWallSide";
import DrawerDeleteWall from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerDeleteWall";
import { restaurantServices } from "services";
import RestaurantTableFloorSelect from "components/RestaurantTableFloorSelect";

export const TableLayout = ({ editBtn, tablesData }) => {
  const [scale, onScale] = useState(1);
  const [isPanDisabled, setPanDisabled] = useState(false);
  const [sceneRotation, setSceneRotation] = useState(0);
  const [isdDrawerElementOpen, setIsDrawerElementOpen] = useState(false);
  const { id } = useParams();
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    floors,
    fetchFloors,
    floorId,
    setFloorId,
    setPostTable,
    setPostWall,
  } = useContext(RestaurantsContext);
  const onSwitchMenu = (floor) => {
    setFloorId(floor.id);
  };

  const getFloors = async (id) => {
    const result = await fetchFloors(id);
    if (result.data) {
      setFloorId(result?.data[0]?.id);
    }
  };

  const duplicateTable = async (table) => {
    const currentFloor = floors.filter((item) => item.id === floorId)[0];
    const updateFloor = {
      ...currentFloor,
      tables: [
        ...(currentFloor.tables || []),
        {
          status: 1,
          seats: table.seats,
          type: table.type,
          x: 0,
          y: 0,
          rotation: 0,
        },
      ],
    };
    try {
      await restaurantServices.updateFloor(id, updateFloor);
      fetchFloors(id);
    } catch (err) {
      console.log(err);
    }
  };

  const removeTable = (table) => {
    setPostTable({
      status: table.status,
      seats: table.seats,
      type: table.type,
      id: table.id,
    });
    openDrawer([drawersMenu.deleteTableSide, drawersMenu.deleteTable]);
  };

  const editTable = (table) => {
    setPostTable({
      status: table.status,
      seats: table.seats,
      type: table.type,
      id: table.id,
    });
    openDrawer(drawersMenu.editTable);
  };

  const editWall = (wall) => {
    setPostWall(wall);
    openDrawer(drawersMenu.editWall);
  };

  const removeWall = (wall) => {
    setPostWall(wall);
    openDrawer([drawersMenu.deleteWallSide, drawersMenu.deleteWall]);
  };

  useEffect(() => {
    getFloors(id);
  }, [id]);

  const currentFloor = floors.filter((item) => item.id === floorId)[0];
  let floorWalls = currentFloor?.walls || [];
  let tables = currentFloor?.tables || [];

  // console.log(tablesData);

  return (
    <TableLayoutContainer className="tables-wrapper">
      <div className="tables-header">
        <div className="left-part">
          <div className="status-menu">
            {[
              [
                "Available",
                tables.filter((table) => table.status === 1).length,
                colors.green,
              ],
              [
                "Occupied",
                tables.filter((table) => table.status === 2).length,
                colors.orange1,
              ],
              [
                "Inactive",
                tables.filter((table) => table.status === 0).length,
                colors.gray,
              ],
            ].map(([title, value, backgroundColor]) => (
              <StatusBadge
                title={title}
                value={value}
                backgroundColor={backgroundColor}
                key={title}
              />
            ))}
          </div>
        </div>
        {/* @TODO previous code of floor select */}
        {/* <div className="right-part">
          <MySelect
            className="floor-select"
            options={floors}
            defaultValueId={floorId}
            renderExtra={() => (
              <p
                className="text-red text-bold"
                onClick={() => openDrawer(drawersMenu.floor)}
              >
                Edit Floor
              </p>
            )}
            onChange={(option) => onSwitchMenu(option)}
          />
        </div> */}
        <div
          className="right-part"
          style={{
            width: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {editBtn && (
            <div
              className={`edit-button`}
              onClick={() => openDrawer(drawersMenu.floor)}
            >
              <FaPencilAlt size={20} />
            </div>
          )}
          {/* <div className="tab-switcher-container">
            {floors.map((floor) => {
              return (
                <div
                  href="#"
                  className={`tab-switcher-item ${
                    floorId === floor?.id ? "active" : ""
                  }`}
                  onClick={() => {
                    onSwitchMenu(floor);
                  }}
                >
                  {floor.name}
                </div>
              );
            })}
          </div> */}
          <RestaurantTableFloorSelect
            className="floor-select"
            options={floors}
            defaultValueId={floorId}
            // renderExtra={() => (
            //   <p
            //     className="text-red text-bold"
            //     onClick={() => openDrawer(drawersMenu.floor)}
            //   >
            //     Edit Floor
            //   </p>
            // )}
            tablesData={tablesData}
            onChange={(option) => onSwitchMenu(option)}
          />
        </div>
      </div>
      <div className="tables">
        <TransformWrapper
          scale={scale}
          wheel={{ disabled: true }}
          pan={{ disabled: isPanDisabled }}
        >
          <TransformComponent>
            <div
              className="internal-scene"
              style={{ transform: `rotate(${sceneRotation}deg)` }}
            >
              {tables.map((_table, _i) => {
                // console.log(_table, 'table-data')
                // I had to use some parameters, like isRightFloor, because of specifications of Movable library. I tried to manipulate with "floorTables" directly, but it cause app to crash.
                return (
                  <SingleMovable
                    key={_i}
                    className={`table_${_table.id}`}
                    // className={_table.className}
                    otherClassNames={[
                      ...tables
                        .filter((_item) => _item.id !== _table.id)
                        .map((_item) => `table_${_item.id}`),
                      ...floorWalls.map((_item, i) => `walls-${i}`),
                    ]}
                    translate={[_table.x, _table.y]}
                    seats={_table.seats}
                    status={_table.status}
                    shape={_table.type === 0 ? "circle" : "square"}
                    position={_table.position}
                    tableName={`${_table.id}`}
                    isOccupied={_table.status === 2}
                    tableNumber={_table.table_number}
                    rotate={_table.rotation}
                    tableGuests={
                      _table?.meal_info?.guest_count
                        ? _table.meal_info.guest_count
                        : 0
                    }
                    // isRightFloor={_table.floor === floorsTemp[selectedFloorIndex].value}
                    setPanDisabled={setPanDisabled}
                    duplicateTable={() => duplicateTable(_table)}
                    removeTable={() => removeTable(_table)}
                    editTable={() => editTable(_table)}
                    editable={editBtn}
                  />
                );
              })}

              {floorWalls.map((_wall, i) => {
                // Walls
                // console.log(_wall)
                const otherWallsArr = [
                  ...floorWalls.map((_item, _i) =>
                    i !== _i ? `walls-${_i}` : null
                  ),
                ];
                return (
                  <SingleMovable
                    key={`${i}walls-movable`}
                    className={`walls-${i}`}
                    // className={_table.className}
                    otherClassNames={[
                      ...otherWallsArr.filter((_item) => _item !== null),
                      ...tables.map((_item) => `table_${_item.id}`),
                    ]}
                    translate={[_wall.x, _wall.y]}
                    seats={null}
                    status={null}
                    position={
                      _wall.width > _wall.height ? "horizontal" : "vertical"
                    }
                    shape="wall"
                    wall={_wall}
                    tableName={null}
                    isOccupied={null}
                    rotate={
                      _wall.rotation && _wall.rotation > 0 ? _wall.rotation : 0
                    }
                    // isRightFloor={_walls.floor === floorsTemp[selectedFloorIndex].value}
                    setPanDisabled={setPanDisabled}
                    removeWall={() => removeWall(_wall)}
                    editWall={() => editWall(_wall)}
                    editable={editBtn}
                  />
                );
              })}
            </div>
          </TransformComponent>
        </TransformWrapper>
        {/* Buttons */}
        {editBtn ? (
          <Box
            className="add-table-class"
            backgroundColor={colors.redColor}
            width={60}
            height={60}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => setIsDrawerElementOpen(true)}
          >
            <FaPlus color="white" fontSize={23} />
          </Box>
        ) : null}
        <Box
          className="zoom-class"
          width="auto"
          padding={3}
          borderRadius="25px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            className="zoom-button zoom-button-margin-bottom"
            width={30}
            height={30}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => onScale(scale + 0.25)}
            backgroundColor={"rgba(255,255,255,0.3"}
          >
            <FaPlus />
          </Box>
          <Box
            className="zoom-button"
            width={30}
            height={30}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => scale > 1 && onScale(scale - 0.25)}
            backgroundColor={"rgba(255,255,255,0.1"}
          >
            <FaMinus />
          </Box>
        </Box>

        {editBtn ? (
          <Box
            className="rotate-class"
            width="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              className="rotate-button"
              width={40}
              height={40}
              borderRadius="25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => setSceneRotation((sceneRotation - 90) % 360)}
            >
              <FaUndo />
            </Box>
            <Box
              className="rotate-button"
              width={40}
              height={40}
              borderRadius="25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => setSceneRotation((sceneRotation + 90) % 360)}
            >
              <FaRedo />
            </Box>
          </Box>
        ) : null}
      </div>

      {/* Drawer Floors */}
      <DrawerFloors
        isActive={!!openDrawers[drawersMenu.floor]}
        closePanel={() => closeDrawer(drawersMenu.floor)}
      />
      {/* Drawer Floor Add */}
      <DrawerFloor
        isActive={!!openDrawers[drawersMenu.floorAdd]}
        closePanel={() => closeDrawer(drawersMenu.floorAdd)}
      />

      {/* Drawer Floor Edit */}
      <DrawerFloor
        type="Edit"
        isActive={!!openDrawers[drawersMenu.floorEdit]}
        closePanel={() => closeDrawer(drawersMenu.floorEdit)}
      />
      {/* Drawer Delete Menu */}
      <DrawerDeleteFloor
        isActive={!!openDrawers[drawersMenu.deleteFloor]}
        closePanel={() => closeDrawer(drawersMenu.deleteFloor)}
      />
      <DrawerElement
        isActive={isdDrawerElementOpen}
        closePanel={() => {
          setIsDrawerElementOpen(false);
        }}
      />
      <DrawerTable
        type="Add"
        isActive={!!openDrawers[drawersMenu.addTable]}
        closePanel={() => {
          closeDrawer(drawersMenu.addTable);
        }}
        setIsDrawerElementOpen={setIsDrawerElementOpen}
      />
      <DrawerTable
        type="Edit"
        isActive={!!openDrawers[drawersMenu.editTable]}
        closePanel={() => closeDrawer(drawersMenu.editTable)}
        setIsDrawerElementOpen={setIsDrawerElementOpen}
      />
      {/* <DrawerDeleteTableSide
        isActive={!!openDrawers[drawersMenu.deleteTableSide]}
        closePanel={() => closeDrawer(drawersMenu.deleteTableSide)}
      /> */}
      <DrawerDeleteTable
        isActive={!!openDrawers[drawersMenu.deleteTable]}
        closePanel={() => {
          closeDrawer([drawersMenu.deleteTable, drawersMenu.deleteTableSide]);
        }}
      />
      {/* Drawer Status Table */}
      <DrawerStatusTable
        isActive={!!openDrawers[drawersMenu.statusTable]}
        closePanel={() => closeDrawer(drawersMenu.statusTable)}
      />
      <DrawerWall
        isActive={!!openDrawers[drawersMenu.addWall]}
        closePanel={() => closeDrawer(drawersMenu.addWall)}
        setIsDrawerElementOpen={setIsDrawerElementOpen}
      />
      <DrawerWall
        type="Edit"
        isActive={!!openDrawers[drawersMenu.editWall]}
        closePanel={() => closeDrawer(drawersMenu.editWall)}
        setIsDrawerElementOpen={setIsDrawerElementOpen}
      />
      {/* <DrawerDeleteWallSide
        isActive={!!openDrawers[drawersMenu.deleteWallSide]}
        closePanel={() => closeDrawer(drawersMenu.deleteWallSide)}
      /> */}
      <DrawerDeleteWall
        isActive={!!openDrawers[drawersMenu.deleteWall]}
        closePanel={() => {
          closeDrawer([drawersMenu.deleteWall, drawersMenu.deleteWallSide]);
          // closeDrawer(drawersMenu.deleteWallSide);
        }}
      />
    </TableLayoutContainer>
  );
};
