import React, { memo, useContext, useEffect, useState } from "react";
import {
  EditRestaurantModalContainer,
  DrawerMobileNumberInputWithVerifyContainer,
} from "./style";
import { DrawerContainer } from "components/styledStyle";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { PhotoUploader } from "components/Elements/PhotoUploader";
import { RestaurantsContext } from "context/RestaurantsContext";
import {
  FaTrash,
  FaTimes,
  FaChevronRight,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import { drawersOverall } from "static/staticData";
import { colors } from "theme";
import NoAvatar from "assets/img/noAvatar.png";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";
import NumberFormat from "react-number-format";
import PriceTierModal from "../PriceTierModal";
import LocationModal from "../LocationModal";
import CuisineModal from "../CuisineModal";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import correctIcon from "assets/img/selected.png";

const tiers = [
  {
    id: 1,
    price: "$",
  },
  {
    id: 2,
    price: "$$",
  },
  {
    id: 3,
    price: "$$$",
  },
  {
    id: 4,
    price: "$$$$",
  },
];

const EditRestaurantModal = ({ isActive = false, closePanel = () => {} }) => {
  const {
    openDrawer,
    postRestaurant,
    setPostRestaurant,
    restaurant,
    setRestaurant,
    selectedCuisines,
    managerDetail,
    latlng,
    notesData,
    cuisineGlobalData,
    setSelectedCuisines,
  } = useContext(RestaurantsContext);
  const [profileAssets, setProfileAssets] = useState();
  const [cuisinesData, setCuisinesData] = useState([]);

  const [
    restaurantUptadeNotificationMessage,
    setRestaurantUptadeNotificationMessage,
  ] = useState();

  const [modals, setModals] = useState({
    priceTier: false,
    cuisines: false,
    location: false,
  });

  const [selectedRestaurant, setSelectedRestaurant] = useState("");

  const [selectedTire, setSelectedTire] = useState("");
  const [openPriceDropDown, setOpenPriceDropDown] = useState(false);

  const [openLocationDropDown, setOpenLocationDropDown] = useState(false);

  useEffect(() => {
    if (isActive) {
      getCuisineData();
    }
  }, [isActive]);

  function getCuisineData() {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
        setCuisinesData(res?.data);
        // eslint-disable no-unused-expressions
        let data = [...selectedCuisines];
        res &&
          res.data.map((cuisine, index) => {
            cuisineGlobalData &&
              cuisineGlobalData.map((_item, i) => {
                if (cuisine.name === _item) {
                  data.push(cuisine.id);
                  setSelectedCuisines(data);
                }
              });
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onSaveProfile = () => {
    const uniqueChars = selectedCuisines.filter((c, index) => {
      return selectedCuisines.indexOf(c) === index;
    });
    const updateDataConfig = {
      photo: profileAssets,
      title: postRestaurant.title,
      phone_number: postRestaurant.phone_number,
      email: postRestaurant.email,
      // address: postRestaurant.address,
      address: value,
      price_level: postRestaurant.price_level,
      manager_name: postRestaurant.manager_name,
      manager_title: postRestaurant.manager_title,
      cuisines: uniqueChars,
    };

    customAxios
      .put(`/restaurant/${postRestaurant?.id}`, updateDataConfig)
      .then((res) => {
        if (res.status === 200) {
          restaurantGetApi();
          closePanel();
          setRestaurantUptadeNotificationMessage({
            message: `Restaurant profile updated.`,
            type: ``,
            table: ``,
          });
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });

    setOpenLocationDropDown(false);
  };

  function restaurantGetApi() {
    customAxios
      .get(`/restaurant/${postRestaurant?.id}`)
      .then((res) => {
        setRestaurant(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onUploadSuccess = (asset) => {
    setProfileAssets(asset.id);
    setPostRestaurant({ ...postRestaurant, photo: asset });
  };

  useEffect(() => {
    if (restaurantUptadeNotificationMessage) {
      setTimeout(() => setRestaurantUptadeNotificationMessage(null), 2 * 1000);
    }
  }, [restaurantUptadeNotificationMessage]);

  // location
  // google places autocomplete
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
    // cache: 24 * 60 * 60
  });

  useEffect(() => {
    if (postRestaurant && isActive) {
      setValue(postRestaurant.address);
      setSelectedRestaurant(postRestaurant.address);
    }
  }, [postRestaurant, isActive]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  // console.log(value)

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      // get address from coordinate
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.enableDebug();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setSelectedRestaurant(address);
        },
        (error) => {}
      );
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className="found-address-list cursor-pointer"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <div>
            <p>
              <span className="text-bold">{main_text}</span>, {secondary_text}
            </p>
          </div>
          <div></div>
        </div>
      );
    });
  // end of google places autocomplete

  // price
  useEffect(() => {
    if ((postRestaurant, isActive)) {
      setSelectedTire(postRestaurant?.price_level);
    }
  }, [postRestaurant, isActive]);

  function getPriceId(tireInfo) {
    setSelectedTire(tireInfo?.id);
    setPostRestaurant({ ...postRestaurant, price_level: tireInfo?.id });
    setOpenPriceDropDown(false);
  }

  // setOpenPriceDropDown(false)

  const refPriceTire = useOnclickOutside(() => {
    setOpenPriceDropDown(false);
  });

  const refLocation = useOnclickOutside(() => {
    setOpenLocationDropDown(false);
  });

  return (
    <EditRestaurantModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"5%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        {restaurantUptadeNotificationMessage && (
          <MyMessageBoxItemNew
            message={
              restaurantUptadeNotificationMessage?.message
                ? restaurantUptadeNotificationMessage?.message
                : ""
            }
            table={
              restaurantUptadeNotificationMessage?.table
                ? restaurantUptadeNotificationMessage?.table
                : ""
            }
            type={
              restaurantUptadeNotificationMessage?.type
                ? restaurantUptadeNotificationMessage?.type
                : ""
            }
            onClick={() => setRestaurantUptadeNotificationMessage(null)}
          />
        )}
        <div className="drawer-content">
          <div className="top-bar">
            <div></div>
            <div className="edit-title">
              <b>Edit Restaurant</b>
            </div>
            <div></div>
          </div>

          <div className="upload-profile-wrapper d-flex justify-content-center">
            <PhotoUploader
              src={
                postRestaurant?.photo ? postRestaurant?.photo?.file : NoAvatar
              }
              height={"149px"}
              width={"149px"}
              onUploadSuccess={onUploadSuccess}
            />
          </div>

          <div>
            <DrawerInput
              className="res-name"
              label="Restaurant Name"
              placeholder="Ex: All Day, Lunch/Dinner, Holiday Menu, etc."
              value={postRestaurant.title}
              onChange={(e) =>
                setPostRestaurant({ ...postRestaurant, title: e.target.value })
              }
            />
            <DrawerMobileNumberInputWithVerifyContainer className="">
              {<h6 className="mb-0">Phone Number</h6>}
              <div className="drawer-input-container">
                <NumberFormat
                  format={"+# (###) ###-####"}
                  className={`single-ant-format-input`}
                  value={postRestaurant.phone_number || ""}
                  onValueChange={(e) => {
                    setPostRestaurant({
                      ...postRestaurant,
                      phone_number: e?.value,
                    });
                  }}
                  onBlur={() => {}}
                  placeholder={"Set Mobile Number"}
                />
              </div>
              <div className="input-icon"></div>
            </DrawerMobileNumberInputWithVerifyContainer>
            <DrawerInput
              label="Email Address"
              placeholder="Set Email Address"
              value={postRestaurant.email}
              onChange={(e) =>
                setPostRestaurant({ ...postRestaurant, email: e.target.value })
              }
            />
            <div className="divide-section">
              {/* <ItemPicker
                label="Price Tier"
                placeholder="Set Price Tier"
                value={
                  postRestaurant.price_level &&
                  Array.from(
                    { length: postRestaurant.price_level },
                    (v, i) => "$"
                  ).join("")
                }
                // onClick={() => setModals({ ...modals, priceTier: true })}
              /> */}
              <div className="location-input">
                <label style={{ opacity: 0.3, display: "initial" }}>
                  Price Tier
                </label>
                <div className="location-input-icon">
                  <input
                    type="text"
                    placeholder="Set Price Tier"
                    className="w-100 cursor-pointer"
                    value={
                      postRestaurant.price_level &&
                      Array.from(
                        { length: postRestaurant.price_level },
                        (v, i) => "$"
                      ).join("")
                    }
                    onClick={() => {
                      setOpenPriceDropDown(!openPriceDropDown);
                    }}
                  />
                  <div className="input-icon">
                    {openPriceDropDown ? (
                      <i
                        className="far fa-angle-up"
                        style={{ fontSize: "20px" }}
                      />
                    ) : (
                      <i
                        className="far fa-angle-down"
                        style={{ fontSize: "20px" }}
                      />
                    )}
                  </div>
                </div>
                <div className="input-line" />
              </div>
              {openPriceDropDown && (
                <div className="price-list" ref={refPriceTire}>
                  <div className="wrapper-circle">
                    {tiers.map((_tier, _i) => {
                      let tireId = _tier.id;
                      return (
                        <div className="price">
                          <div
                            className="price-circle"
                            style={
                              tireId === selectedTire
                                ? { backgroundColor: "#28c928" }
                                : { backgroundColor: "rgb(27 27 27)" }
                            }
                            onClick={() => getPriceId(_tier)}
                          >
                            {tireId === selectedTire && (
                              <img
                                src={correctIcon}
                                alt=""
                                height={"20px"}
                                width={"20px"}
                                style={{ marginTop: "-7px" }}
                              />
                            )}
                          </div>
                          <div className="price-dollar">{_tier.price}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <ItemPicker
                label="Cuisine"
                placeholder="Set Cuisine"
                value={
                  postRestaurant.cuisinesName &&
                  postRestaurant.cuisinesName.length > 0
                    ? postRestaurant.cuisinesName.join(", ")
                    : postRestaurant.cuisines &&
                      postRestaurant.cuisines.length > 0
                    ? postRestaurant.cuisines.join(", ")
                    : ""
                }
                onClick={() => setModals({ ...modals, cuisines: true })}
              />
            </div>

            {/* {console.log(postRestaurant.address)} */}
            <div className="location-input">
              {/* <ItemPicker
                label="Address"
                placeholder="Set Address"
                value={postRestaurant.address}
                onChange={(e) =>
                  setPostRestaurant({
                    ...postRestaurant,
                    address: e.target.value,
                  })
                }
                onClick={() => setModals({ ...modals, location: true })}
              /> */}

              <label style={{ opacity: 0.3, display: "initial" }}>
                Address
              </label>
              <div className="location-input-icon">
                <input
                  type="text"
                  placeholder="Search Location"
                  className="w-100 cursor-pointer"
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  onClick={() => {
                    setOpenLocationDropDown(true);
                  }}
                />
                <div className="input-icon">
                  {value.length > 0 ? (
                    <i
                      className="fas fa-times cursor-pointer"
                      onClick={() => setValue("")}
                    />
                  ) : (
                    <i className="far fa-search" />
                  )}
                </div>
              </div>
              <div className="input-line" />
            </div>
            {status === "OK" &&
              (openLocationDropDown ? (
                <div className="location-list" ref={refLocation}>
                  <div>{renderSuggestions()}</div>
                </div>
              ) : (
                ""
              ))}
            {/* {status === "OK" && (
              <div className="location-list">
                <div>{renderSuggestions()}</div>
              </div>
            )} */}
          </div>
          <div className="bottom-btns">
            <MyDrawerButton type="red" onClick={onSaveProfile}>
              <b>Save Changes</b>
            </MyDrawerButton>
            {/* <MyDrawerButton
              className="cancel-btn"
              type="redColor"
              onClick={closePanel}
            >
              <b>Cancel</b>
            </MyDrawerButton> */}
            <div className="cancel-btn" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
      <PriceTierModal
        isActive={modals.priceTier}
        closePanel={() => {
          setModals({ ...modals, priceTier: false });
        }}
      />
      <LocationModal
        isActive={modals.location}
        closePanel={() => {
          setModals({ ...modals, location: false });
        }}
      />
      <CuisineModal
        isActive={modals.cuisines}
        closePanel={() => {
          setModals({ ...modals, cuisines: false });
        }}
        cuisinesData={cuisinesData}
      />
    </EditRestaurantModalContainer>
  );
};

export default EditRestaurantModal;
