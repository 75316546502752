import styled from "@emotion/styled";

export const MySearchContainer = styled.div`
  cursor: pointer;
  /* margin-right: 15px; */
  width: 50px;
  height: 40px;
  border-radius: 25px;
  border: solid 1px #525558;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-transition: border-color 500ms ease-out;
  -moz-transition: border-color 500ms ease-out;
  -o-transition: border-color 500ms ease-out;
  transition: border-color 500ms ease-out;

  background: #27282A;

  .on-search-active {
    display: none;
  }

  &.active {
    width: 252px;
    justify-content: space-between;
    .fa-search {
      display: block;
      margin-left: 15px;
    }
    .on-search-active {
      display: initial;
    }
    .fa-times {
      margin-right: 15px;
    }
    .fa-times:hover {
      color: #fff;
    }
    .search-input {
      width: 252px;
      margin-left: 10px;
      background-color: transparent;
      box-shadow: none;
      border: none;
      color: #fff;
    }
    .search-input:focus {
      outline: none;
    }
  }

  &:hover {
    border-color: #fff;
  }
  .fa-search {
    font-size: 14px;
    color: #ffffff;
  }
`;
