import styled from "@emotion/styled";
import { colors } from "theme";

export const CustomersPageContainer = styled.div`
  .customer-list-wrapper {
    color: #fff;
    padding: 10px 60px;
    font-family: Montserrat;
    .customer-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;
      .left-part {
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
        }
      }
      .right-part {
        display: flex;
        align-items: center;
        .show-limit {
          display: flex;
          align-items: center;
          margin-right: 16px;
          .show-message {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            padding-right: 10px;
          }
          .dropdown {
            .dropdown-toggle {
              border-radius: 25px;
              border: solid 1px #525558;
              background-color: transparent;
              font-size: 14px;
              font-weight: normal;

              &#dropdown-basic {
                padding: 9px 15px;
              }
            }
            .dropdown-toggle:hover {
              background-color: transparent;
              border-color: #fff;
            }
            .dropdown-menu {
              padding: 0;
              &.show {
                margin: 0px 0px 0px -5px !important;
                border-radius: 25px;
                border: solid 1px #525558;
                background-color: #333437;
                min-width: 75px;
                a:first-of-type {
                  border-top-left-radius: 25px;
                  border-top-right-radius: 25px;
                }
                a:last-child {
                  border-bottom-left-radius: 25px;
                  border-bottom-right-radius: 25px;
                }
                a {
                  color: #fff;
                }
                a:hover {
                  background-color: rgba(255, 255, 255, 0.1);
                }
              }

              .dropdown-item {
                background-color: #1b1c1d;
              }
            }
          }
        }
        .search-pane {
          cursor: pointer;
          margin-right: 15px;
          width: 50px;
          height: 40px;
          border-radius: 25px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          -webkit-transition: border-color 500ms ease-out;
          -moz-transition: border-color 500ms ease-out;
          -o-transition: border-color 500ms ease-out;
          transition: border-color 500ms ease-out;

          &:hover {
            border: none;
          }

          // -webkit-transition : width 500ms ease-out;
          // -moz-transition : width 500ms ease-out;
          // -o-transition : width 500ms ease-out;
          // transition : width 500ms ease-out;
          .on-search-active {
            display: none;
          }

          &.active {
            width: 252px;
            justify-content: space-between;
            // .fa-search {
            //   display: none;
            // }
            .on-search-active {
              display: initial;
            }
            // .fa-times {
            //   margin-right: 15px;
            // }
            // .fa-times:hover {
            //   color: #fff;
            // }
            .search-input {
              width: 350px;
              margin: 11px 0px 11px 20px;
              background-color: transparent;
              box-shadow: none;
              border: none;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
            }
            .search-input:focus {
              outline: none;
            }
          }

          &:hover {
            border-color: #fff;
          }
          .fa-search {
            font-size: 14px;
            color: #ffffff;
          }
        }
        .show-search-result {
          position: absolute;
          background: #151617;
          width: 252px;
          border-radius: 20px;
          border: 1px solid #525558;
          margin-top: 6px;
          z-index: 1;
          padding: 15px 20px 0 20px;
          max-height: 400px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: ${colors.gray5};
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: ${colors.white};
            border-radius: 5px;
          }

          .restaurant-title {
            .restaurant-title-gap {
              margin-bottom: 0;
              cursor: pointer;
            }
          }
        }
        .menu-item {
          cursor: pointer;
          width: 50px;
          height: 40px;
          border-radius: 25px;
          /* border: solid 1px #525558; */
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background-color: #27282a;

          -webkit-transition: border-color 500ms ease-out;
          -moz-transition: border-color 500ms ease-out;
          -o-transition: border-color 500ms ease-out;
          transition: border-color 500ms ease-out;
          /* &:hover {
            border-color: #fff;
          } */
          i {
            font-size: 14px;
            color: #ffffff;
          }
          .active-dot {
            position: absolute;
            width: 11px;
            height: 11px;
            border: solid 3px #333437;
            background-color: #ed2626;
            border-radius: 50%;
            top: 7px;
            right: 10px;
          }
        }

        input::placeholder {
          font-weight: 100 !important;
        }
      }

      .center-part {
        display: flex;
        align-items: center;
        .rounded-button {
          cursor: pointer;
          border-radius: 25px;
          background-color: #60646c !important;
          padding: 8px 20px !important;
          width: 170px;
          margin: 0 7px;
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;

          &.delete-button {
            width: 40px;
            padding: 9px 12px !important;
            .fa-trash {
              color: #fff;
              font-size: 16px;
            }
          }
        }
        .squire-button {
          border-radius: 8px;
          box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
          background-color: ${colors.gray2} !important;
          padding: 8px 25px;
          margin: 0 7px;
          .text-content {
            font-size: 14px;
            color: #ffffff;
            margin-right: 10px;
          }
          .text-undo {
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: #ed2626;
          }
        }
      }
    }
    .separator-line {
      border-bottom: 1px solid #4d5053;
      margin-left: -40px;
      margin-right: -40px;
    }
    .customer-list-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-total-count {
        opacity: 0.5;
        .total-count-text1 {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #fff;
        }
        .total-count-text2 {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #fff;
        }
      }
      .nav {
        background: #27282a;
        border-radius: 30px;
        width: 612px !important;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .nav-item {
          width: 128px;
        }
      }

      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #ed2626;
        text-align: center;
        border-radius: 30px;
        width: 12rem !important;
      }

      a.nav-link {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: normal;
        text-align: center !important;
        width: 12rem !important;
      }

      .nav.nav-pills .show > .nav-link,
      .nav.nav-pills .nav-link.hover {
        color: unset;
      }

      nav .show > .nav-link,
      .nav .nav-link:hover:not(.disabled),
      .nav .nav-link.active {
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
        color: #fff;
      }

      .nav.nav-pills .nav-link {
        color: #fff;
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
        position: relative;
        font-weight: normal;
        color: white;
        padding-left: 50px;
        opacity: 0.5;

        &.active {
          opacity: 1;
          font-weight: 600;
        }
      }
    }
    .analytic-container {
      padding: 25px 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .customers-list-container {
      .customers-list {
        background-color: #1a1a1b;
        border-radius: 8px;
      }

      .customers-titles {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        color: rgba(255, 255, 255, 0.3);
        .fa-circle {
          color: rgba(255, 255, 255, 0.3);
        }
        .fa-minus-circle {
          color: #fff;
        }
        .fa-check-circle {
          color: white;
        }
        .item-flex-small {
          flex: 0 0 8%;
          font-size: 14px;
        }
        .item-flex-normal {
          flex: 0 0 13%;
          font-size: 14px;
        }
        .item-flex-big {
          flex: 0 0 22%;
          font-size: 14px;
        }
        .item-flex-last {
          flex: 1 1 auto;
          font-size: 14px;
          text-align: end;
        }
        .left-padding-1 {
          padding-left: 15px;
        }
      }

      .paginator-element {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 0.3);
        font-size: 18px;
        font-weight: bold;
        margin: 30px 0 51px;
        .item-arrow {
          cursor: pointer;
          padding: 0 10px;
          i {
            color: rgba(255, 255, 255, 0.3);
          }
          &.active {
            i {
              color: #fff;
            }
          }
        }
        .numbers-container {
          display: flex;
          align-items: center;
          .number-item {
            cursor: pointer;
            padding: 0 10px;
            &.active {
              color: #fff;
            }
          }
        }
      }

      .search-applied {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .no-result {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .no-result-img {
          img {
            height: 250px;
            margin: 90px 0 15px 0;
          }
        }
        .no-result-title {
          font-size: 18px;
          font-size: bold;
          margin-bottom: 10px;
        }
        .no-result-subtitle {
          color: #fff;
          opacity: 0.4;
        }
      }
    }
  }
`;
