import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerClosedDaysContainer = styled(MyDrawerRight)`
  .closed-days-modal-header {
    margin-bottom: 49px;
    .fa-angle-left {
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.53px;
      text-align: right;
      color: #fff;
      cursor: pointer;
    }
    .fa-plus {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: right;
      color: #ed2626;
      cursor: pointer;
    }
  }
  .panel-status-container {
    margin-bottom: auto;
    .upper-part {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .company-logo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 15px;
      }
      .company-name {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .notes-count {
      padding: 20px;
      font-size: 14px;
      color: #ffffff;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .notes-list {
      .no-note-data {
        margin: 50px 0;
        text-align: center;
        .no-notes-img {
          width: 300px;
        }
        .no-notes-message {
          margin-top: 40px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
        }
      }

      .note-container-item {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .note-image-block {
          width: 45px;
          height: 45px;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }
        .note-content-block {
          margin-left: 15px;
          p {
            margin-bottom: 0;
          }
          .commentator-name {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
          }
          .commentator-status {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.6);
          }
          .commentator-mail {
            font-size: 14px;
            color: #fff;
          }
        }
        .note-end-block {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          p {
            margin-bottom: 0;
          }
          .delete-note {
            cursor: pointer;
            margin-bottom: 20px;
            width: 100%;
            text-align: end;
            height: 15px;
            .fa-trash {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.3);
            }
          }
          .delete-note:hover {
            .fa-trash {
              color: #fff;
            }
          }
          .date-note {
            font-size: 13px;
            color: #fff;
          }
        }
      }
    }
  }
  .notes-abuse-warning {
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    .abuse-part {
      cursor: pointer;
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px rgba(255, 255, 255, 0.15);
      i {
        color: #ffffff;
      }
    }
    .change-active-status {
      padding: 5px 15px;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      i {
        font-size: 14px;
        color: #ffffff;
        margin-right: 10px;
      }
    }
  }
`;

export const ClosedDaysListContainer = styled.div`
  width: 100%;
  min-height: 56px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  .item-picker-left {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    .repeated-annualy-div {
      border-radius: 10.5px;
      background-color: #525558;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: -0.29px;
      color: #fff;
      margin-left: 10px;
      padding: 0 6px;
    }
    h6 {
      line-height: 1.57;
      letter-spacing: -0.34px;
      margin-bottom: 0;
      &.item-picker-label {
        opacity: 0.3;
      }
      &.item-picker-value {
        color: ${colors.white};
        font-weight: 500;
      }
    }
  }
  &.set {
    .item-picker-left {
      h6.item-picker-value {
        color: ${colors.white};
      }
    }
  }
`;
