import React from "react";
import { AnalyticItemContainer } from "./style";

export const AnalyticItem = ({
  title = "",
  subTitle = "",
  icon,
  value = 0,
  image,
}) => {
  return (
    <AnalyticItemContainer
      className="d-flex flex-column justify-content-between"
      image={image}
      bottom={title === "Active Customers" ? true : false}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="stat-item-header">
          {icon}
          <span className="stat-item-message">{title}</span>
        </div>
        {subTitle ? (
          <div className="stat-item-footer-period text-white-50 mb-0">
            {subTitle}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="stat-item-footer d-flex justify-content-between align-items-center">
        <div className="stat-item-footer-counter mb-0">{value}</div>
      </div>
    </AnalyticItemContainer>
  );
};
