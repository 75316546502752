import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import DrawerFood from "../DrawerFood";
import { DrawerAddTypeContainer } from "./style";

const types = [
  { id: "create", title: "Create a new item" },
  { id: "choose", title: "Choose an existing item" },
];

export default function DrawerAddType({
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer,setChooseCloseDrawer, chooseCloseDrawer } = useContext(RestaurantsContext);

  const onChooseItem = (type) => {
    if (type === "create") {
      openDrawer(drawersMenu.itemAdd);
    } else {
      // openDrawer(drawersMenu.foods);
      setChooseCloseDrawer({
        ...chooseCloseDrawer,
        foods: true,
      });
    }
  };

  return (
    <DrawerAddTypeContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="mb-4">
          <FaChevronLeft size={18} onClick={closePanel} className="cursor-pointer"/>
        </div>
        <div className="drawer-status--options mb-auto mt-5">
          {types.map((_type, _i) => (
            <DrawerChooseItem
              key={_i}
              className="mb-4"
              title={_type.title}
              onChooseItem={() => onChooseItem(_type.id)}
            />
          ))}
        </div>
      </DrawerContainer>
      <DrawerFood close={closePanel} />
    </DrawerAddTypeContainer>
  );
}
