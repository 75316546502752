import React from "react";
import PropTypes from "prop-types";
import { DrawerChooseItemContainer } from "./style";

export default function DrawerChooseItem({
  className = "",
  active = false,
  title = "",
  subtitle = "",
  icon = "",
  onChooseItem = () => {},
}) {
  return (
    <DrawerChooseItemContainer
      className={`drawer-choose--option cursor ${active &&
        "active"} ${className}`}
      onClick={onChooseItem}
    >
      {icon && <span className="mr-4">{icon}</span>} <p>{title}</p>
      {subtitle && <p className="choose-subtitle">&nbsp; {subtitle}</p>}
    </DrawerChooseItemContainer>
  );
}

DrawerChooseItem.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
};
