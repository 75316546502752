import { NoAvatar } from "assets/img";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { ProfileCardNotesContainer } from "./style";
import { useParams } from "react-router-dom";
import moment from "moment";
import { drawersOverall, notes } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import NoFilterData from "assets/img/no-filter-data.png";

export default function ProfileCardNotes({ className = "", profile }) {
  const { openDrawer, notesData, setNotesData, setNoteId } = useContext(
    RestaurantsContext
  );
  const { id } = useParams();

  useEffect(() => {
    if (profile?.status !== 3 && profile?.status !== undefined) {
      getNotesData();
    }
  }, [id, profile?.status]);

  function getNotesData() {
    customAxios
      .get(`/restaurant/${id}/notes`)
      .then((res) => {
        setNotesData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleDeleteNotes(notesId) {
    setNoteId(notesId);
    openDrawer(drawersOverall.deleteNotes);
  }

  const statusRenderer = (status) => {
    switch (status) {
      case 11:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#ed2626",
              fontSize: "13px",
              fontWeight: "500",
            }}
          >
            Banned
          </h6>
        );
      case 12:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Unbanned
          </h6>
        );
      case 2:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Changed to <b>Active</b>
          </h6>
        );
      case 1:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Changed to <b>Inactive</b>
          </h6>
        );
      case 0:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Deleted
          </h6>
        );
      case 3:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Email Verified
          </h6>
        );
      case 4:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Email Unverified
          </h6>
        );
      case 5:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Phone Verified
          </h6>
        );
      case 6:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#ed2626",
              fontSize: "13px",
              fontWeight: "700",
            }}
          >
            Phone Unverified
          </h6>
        );
      case 7:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Website Verified
          </h6>
        );
      case 8:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#ed2626",
              fontSize: "13px",
              fontWeight: "700",
            }}
          >
            Website Unverified
          </h6>
        );
      case 9:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Request Approved
          </h6>
        );
      case 10:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#ed2626",
              fontSize: "13px",
              fontWeight: "700",
            }}
          >
            Request Declined
          </h6>
        );
      default:
        return (
          <h6
            className="opacity1"
            style={{
              color: "#8f949a",
              opacity: "1",
              fontSize: "13px",
            }}
          >
            Status not <b>changed</b>
          </h6>
        );
    }
  };

  return (
    <ProfileCardNotesContainer className={`${className}`}>
      <div className="notes-card-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0 text-bold">
          Notes {notesData?.length ? `(${notesData?.length})` : ""}
        </h3>
        {notesData?.length > 0 ? (
          <FaAngleRight
            size={18}
            className="cursor-pointer"
            onClick={() => openDrawer(drawersOverall.notes)}
          />
        ) : null}
      </div>
      <div className="notes-card-body d-flex flex-column">
        {notesData && notesData.length ? (
          notesData.map((_note, _i) => {
            return (
              <>
                <div
                  key={_i}
                  className="notes-card-item d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex" style={{ gap: "20px" }}>
                    <div className="notes-card-avatar">
                      <Image
                        className="rounded-circle"
                        src={_note.image ? _note.image : NoAvatar}
                        width={33}
                        height={33}
                      />
                    </div>
                    <div>
                      <h6 className="text-bold">{_note?.admin_name}</h6>
                      <h6 className="opacity6">
                        {_note?.note ? `${_note?.note}` : "-"}
                      </h6>
                    </div>
                    <div>
                      {/* <h6>
                          {(_note.action === 1 && "Changed to Inactive") ||
                            (_note.action === 2 && "Changed to Active") ||
                            (_note?.action === null && "Status not changed")}
                        </h6> */}
                      {statusRenderer(_note?.action)}
                    </div>
                  </div>
                  <div
                    className="note-date"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <h6 className="mb-0 opacity6">
                      {moment(_note.createdAt).format("M/D/YYYY")}
                    </h6>
                    <div
                      className="delete-note cursor-pointer"
                      // onClick={() => setDeleteNotePanelActive(true)}
                      onClick={() => handleDeleteNotes(_note?.id)}
                    >
                      <i className="fas fa-trash-alt" />
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="empty-container">
            <img src={NoFilterData} alt="" />
            <div className="empty-screen">No notes yet</div>
          </div>
        )}
      </div>
    </ProfileCardNotesContainer>
  );
}
