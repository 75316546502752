import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const MarkAsDeliverModalContainer = styled(MyDrawerCenterModal)`
  .mark-as-ready-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .delete-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      /* i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 18px;
      } */
      .fa-concierge-bell {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 16px;
      }
      .fa-glass-cheers {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 13px;
      }
    }
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .delete-suborder-meta {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-actions {
      button {
        width: 100%;
        margin-bottom: 10px;
      }
      .mark-as-delivered {
      }
      .cancel {
        width: 100%;
        margin-top: 20px;
        color: ${colors.redColor};
        text-align: center;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
`;

export const ConfirmDeleteSubOrderModalContainer = styled(MyDrawerCenterModal)`
  .delete-suborder-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .delete-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 18px;
      }
    }
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .delete-suborder-meta {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-actions {
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;
