import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerItemContainer = styled(MyDrawerRight)`
  * {
    height: auto;
    padding: 0 !important;
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .items-wrapper {
    .top-item-image {
      .fa-times {
        font-size: 28px;
        font-weight: 400;
        color: white;
        position: absolute;
        top: 3rem;
        left: 30px;
        z-index: 2;
        cursor: pointer;
      }
      img {
        filter: blur(9px);
      }
    }

    .overlay-drawer {
      background-color: rgb(27, 28, 29);
      height: 55rem;
      width: 100%;
      position: absolute;
      bottom: 0px;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;

      .inner-image {
        img {
          padding: 25px !important;
          border-radius: 35px !important;
          position: absolute;
          margin-top: -12rem;
          height: 250px;
        }
      }

      .bottom-space {
        padding: 0 30px 0 30px !important;
        margin-top: 7rem;
        text-align: center;

        .item-name {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .item-description {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.5);
          opacity: 1;
          text-align: center;
          cursor: inherit;
          margin-bottom: 30px;
        }

        .item-dietary-badge {
          .dietary-tag {
            padding: 8px 12px 8px 12px !important;
            .dietary-tag-title {
              font-size: 13px;
              letter-spacing: -0.31px;
              color: white;
            }
          }
        }

        .food-tags {
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          flex-wrap: wrap;
          .food-tags-item {
            padding: 8px 12px 8px 12px !important;
            border-radius: 14px;
            background-color: #333437;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.31px;
            text-align: center;
            color: white;
          }
        }

        .badge-secondary {
          color: #181c32;
          background-color: #484848;
          border-radius: 50px;
        }

        .nutritions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          /* padding: 25px !important; */

          .inner-nutrition {
            /* border: 1px solid red; */
            padding: 0 25px 0 25px !important;
            .numbers {
              font-size: 20px;
              font-weight: bold;
              color: #fff;
            }
            .nutritions-name {
              font-size: 13px;
              text-transform: uppercase;
              letter-spacing: -0.31px;
              color: rgba(255, 255, 255, 0.4);
            }
          }
          .calories-wrapper {
            border-right: outset;
            border-right-color: rgba(255, 255, 255, 0.4);
          }
          .protein-wrapper {
            border-right: outset;
            border-right-color: rgba(255, 255, 255, 0.4);
          }
        }

        .nutrition-title {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          margin-bottom: 40px;
          .fa-info-circle {
            color: rgb(237, 38, 38);
          }
          .nutrition-text {
            color: rgb(237, 38, 38);
            cursor: pointer;
          }
        }

        .rating-review-container {
          width: 100%;
          margin-bottom: 15px;
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 12px;
          cursor: pointer;
          .rating-review-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px !important;
            .fa-star {
              color: rgb(255, 211, 95);
              font-size: 15px;
            }
          }
        }

        .option-container {
          width: 100%;
          margin-bottom: 15px;
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 12px;
          cursor: pointer;
          .option-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px !important;
            .customization-text {
              font-size: 15px;
              font-weight: bold;
              color: white;
            }
          }
        }
      }
    }
  }
`;
