import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerDeleteWallContainer = styled(MyDrawerCenterModal)`
  .remove-wall-wrapper {
    .top-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      left: 15rem;
      bottom: 21rem;
      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 18px 19px;
        &:after {
          opacity: unset;
        }
      }
    }

    .bottom-btns {
      margin-top: 22px;
      button:last-child {
        margin-top: 20px;
      }
    }
  }
`;
