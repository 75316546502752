import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useState, useContext, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { drawersStaff, mocks } from "static/staticData";
import { DrawerStatusStaffContainer } from "./style";
import DrawerStatusStaffConfirm from "./DrawerStatusStaffConfirm";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const stausMocks = {
  statuses: {
    0 : {
      value: false,
      title: "Inactive",
    },
    1 : {
      value: true,
      title: "Active",
    }
  }
}

export default function DrawerStatusStaff({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    postStaff,
    setPostStaff,
  } = useContext(RestaurantsContext);
  const [status, setStatus] = useState();

  const onChangeStatus = (status) => {
    console.log(status);
    setStatus(status);
    setPostStaff({ ...postStaff, serving_status: status });
    closePanel();

    if (status === true) {
      openDrawer(drawersStaff.staffStatusActive);
    }
    if (status === false) {
      openDrawer(drawersStaff.staffStatusActive);
    }
  };

  return (
    <DrawerStatusStaffContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Status</div>
          <div></div>
        </div>
        <div className="drawer-status--options mb-auto">
          {Object.values(stausMocks.statuses)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((_status, _i) => (
              <DrawerChooseItem
                key={_i}
                className="mb-4"
                title={_status.title}
                active={postStaff?.status === _status.value}
                onChooseItem={() => onChangeStatus(_status.value)}
              />
            ))}
        </div>
        <MyDrawerButton
          type="red"
          className="save-btn text-bold"
          onClick={closePanel}
        >
          Save
        </MyDrawerButton>
      </DrawerContainer>
      <DrawerStatusStaffConfirm
        isActive={!!openDrawers[drawersStaff.staffStatusActive]}
        closePanel={() => closeDrawer(drawersStaff.staffStatusActive)}
        status={status}
      />
    </DrawerStatusStaffContainer>
  );
}
