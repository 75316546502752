import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerDateCustomerContainer = styled(MyDrawerBottom)`
  .delete-closed-day-modal {
    .delete-closed-day-modal-title {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.42px;
      color: #fff;
      margin-bottom: 10px;
    }
    .delete-closed-day-modal-meta {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.27px;
      color: #fff;
      margin-bottom: 31px;
    }
  }
`;
