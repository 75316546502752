import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
// import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerMoveDeleteCategoryContainer = styled(MyDrawerCenterModal)`
  .keep-btn {
    margin-top: 10px;
  }

  .delete-btn {
    margin-top: 20px;
  }

  .cancel-btn {
    color: #ed2626 !important;
    font-weight: 600;
    margin-top: 20px;
  }
`;
