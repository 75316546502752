import React from "react";
import { LiveNowContainer } from "./style";

export const LiveNow = ({
  topTitle = "Live Now",
  facts,
  children,
  rolesCount,
  isOrderAnalytics = false,
  isCustomerAnalytics = false,
}) => {
  // console.log(rolesCount);
  return (
    <LiveNowContainer>
      {/* <div className="live-now-top">
        <h4 className="live-now-top-title">{topTitle}</h4>
      </div> */}
      {/* <div className="live-facts-container">
        {facts && facts.map(({ title, value }) => (
          <div className="fact-live-item" key={title + value}>
            <span className="fact-live-title">{title}</span>
            <div className="fact-live-value">{value}</div>
          </div>
        ))}
      </div> */}

      <div className="live-now-title text-center">
        {isCustomerAnalytics ? <h4>Live Now</h4> : <h4>Live Now</h4>}
      </div>

      <div className="live-now-country">{children}</div>

      <div className="roles">
        {isOrderAnalytics ? (
          <>
            <div className="role text-center">
              <div className="role-title">Active Orders</div>
              <div className="role-count">{rolesCount?.active_orders}</div>
            </div>
            <div className="role text-center">
              <div className="role-title">Guests Checked In</div>
              <div className="role-count">
                {rolesCount?.guest_checked_in || 0}
              </div>
            </div>
            <div className="role text-center">
              <div className="role-title">Average Order</div>
              <div className="role-count">{rolesCount?.average_order || 0}</div>
            </div>
          </>
        ) : (
          <>
            {isCustomerAnalytics ? (
              <>
                <div className="role text-center" />
                <div className="role text-center">
                  {/* <div className="customers-live-count">
                    {rolesCount?.live_customers || 0}
                  </div> */}
                </div>
                <div className="role text-center" />
              </>
            ) : (
              <>
                <div className="role text-center">
                  <div className="role-title">Managers/Owners</div>
                  <div className="role-count">{rolesCount?.manager}</div>
                </div>
                <div className="role text-center">
                  <div className="role-title">Kitchen Staff</div>
                  <div className="role-count">{rolesCount?.kitchen_staff}</div>
                </div>
                <div className="role text-center">
                  <div className="role-title">Hosts/Servers</div>
                  <div className="role-count">{rolesCount?.waiter}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>

     
    </LiveNowContainer>
  );
};
