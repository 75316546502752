import React from "react";
import { DrawerIfNoCategoriesDeleteContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerIfNoCategoriesDelete = ({
  isActive = false,
  closePanel = () => {},
  deleteCategoryHandler = () => {},
  countCatgoryItemState,
}) => {
  return (
    <DrawerIfNoCategoriesDeleteContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center mb-3">
          Delete Category
        </h2>
        <p className="text-white text-center mb-5 item-content">
          You have {countCatgoryItemState?.items?.length} items assigned to this category but you <br /> don’t have
          any category to move them to.
        </p>
        <p className="text-white text-center mb-5 item-content">
          What would you like to do?
        </p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mb-4 text-bold"
            onClick={() => {
              deleteCategoryHandler();
            }}
          >
            <b>Delete Category & Items</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="text-bold"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerIfNoCategoriesDeleteContainer>
  );
};

export default DrawerIfNoCategoriesDelete;
