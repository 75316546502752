import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { mocks } from "static/staticData";
import { DrawerItemTypeContainer, DrawerChooseItemContainer } from "./style";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export default function DrawerItemType({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postFood, setPostFood } = useContext(RestaurantsContext);

  const [kindsData, setKindData] = useState([
    {
      value: 1,
      title: "Drink",
      subtitle: "",
    },
    {
      value: 2,
      title: "Quick Food ",
      subtitle: "(Starters, Desserts, Other)",
    },
    {
      value: 0,
      title: "Entrée",
      subtitle: "",
    },
  ]);

  const onChooseItemType = (type) => {
    setPostFood({ ...postFood, kind: type.value });
    closePanel();
  };

  return (
    <DrawerItemTypeContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <div className="drawer-bottom-content w-100">
            <div className="top-title">
              <div className="title">Type</div>
              <div></div>
            </div>
            <div className="drawer-types-wrapper mb-auto">
              {/* {Object.values(mocks.kinds).map((_type, _i) => (
                <DrawerChooseItem
                  key={_i}
                  title={_type.title}
                  active={postFood?.kind === _type.value}
                  onChooseItem={() => onChooseItemType(_type)}
                  className="mb-5"
                />
              ))} */}
              {kindsData.map((el, i) => {
                return (
                  <DrawerChooseItemContainer
                    key={i}
                    className={`drawer-choose--option cursor `}
                    onClick={() => onChooseItemType(el)}
                    style={
                      postFood?.kind === el.value
                        ? { border: "2px solid rgb(237, 38, 38)" }
                        : { border: "2px solid rgba(255,255,255,0.1)" }
                    }
                  >
                    <p>{el?.title}</p>
                    {el?.subtitle && (
                      <p className="choose-subtitle"> &nbsp; {el?.subtitle}</p>
                    )}
                  </DrawerChooseItemContainer>
                );
              })}
            </div>
            <div className="bottom-btns">
              <MyDrawerButton type="redColor" onClick={closePanel}>
                <b>Cancel</b>
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerItemTypeContainer>
  );
}
