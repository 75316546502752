import React, { useContext } from "react";
import { AddModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { drawersMenu, drawersRestaurants } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";

const EditItem = ({
  isActive = false,
  closePanel = () => {},
  name,
  onClickEdit = () => {},
  onClickDelete = () => {},
}) => {
  const {
    openDrawer,
    setIsDeleteRestaurantModalOpen,
    restaurant,
    setPostFood,
    setCategoryId,
  } = useContext(RestaurantsContext);

  return (
    <AddModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
    >
      <DrawerContainer>
        <div className="add-item-wrapper">
         <h3 style={{textAlign:'center'}}>{name}</h3>
          <div
            className="clickable-row"
            onClick={() => {
              onClickEdit()
              closePanel()
            }}
          >
            Edit
          </div>
          <div
            className="clickable-row"
           onClick={() => {
            onClickDelete()
            closePanel()
           }}
          >
            Delete
          </div>
          <MyDrawerButton
            className="action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </AddModalContainer>
  );
};

export default EditItem;
