import React, { useContext, useState } from "react";
import { CashPaymentModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OrdersContext } from "context/OrdersContext";

const CashPaymentModal = ({
  isActive = false,
  closePanel = () => {},
  paymentByCash = () => {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const [paymentOption, setPaymentOption] = useState("Full Balance");
  const [dollars, setDollars] = useState("");
  return (
    <CashPaymentModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"0%"}
      left={"0%"}
      position="absolute"
    >
      <DrawerContainer>
        <div className="remove-item-modal">
          <div className="delete-icon">
            <i className="fas fa-money-bill-wave" />
          </div>
          <div className="delete-suborder-title">Cash Payment</div>
          <div className="delete-suborder-meta1">Amount of cash received:</div>
          <div className="discount-options-container">
            {["Full Balance", "Partial Amount"].map((option, index) => (
              <div
                className={`discount-option ${option === paymentOption &&
                  "active"}`}
                onClick={() => {
                  if (option !== "Partial Amount") {
                    setPaymentOption(option);
                  } else {
                  }
                }}
                key={index + option}
              >
                {option === "Full Balance" ? (
                  <span className="option-title-header">
                    ${orderDetailData?.checkout_data?.total}
                  </span>
                ) : (
                  <div className="type-value-container dollars">
                    <span className="type-value">$</span>
                    <input
                      type="number"
                      value={dollars}
                      onChange={(event) =>
                        setDollars(Number(event.target.value))
                      }
                      max={1000}
                      disabled
                    />
                  </div>
                )}
                <span className="option-title">{option}</span>
              </div>
            ))}
          </div>
          <div className="delete-suborder-meta">
            Feasttt charges a service fee for every order. For cash
            transactions, the fee is automatically deducted from the overall
            restaurant earnings.
          </div>
          <div className="delete-suborder-actions d-flex flex-column">
            <MyDrawerButton
              type="red"
              onClick={() => {
                paymentByCash();
              }}
            >
              <b>Record Payment</b>
            </MyDrawerButton>
            <MyDrawerButton
              onClick={() => {
                closePanel();
              }}
              type="gray"
            >
              <b>Back</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </CashPaymentModalContainer>
  );
};

export default CashPaymentModal;
