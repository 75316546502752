import styled from "@emotion/styled";
import usaMapBg from "assets/img/usa-map-bg.png";
import { colors } from "theme";

export const CountryChartContainer = styled.div`
  width: 100%;
  height: 440px;
  background-position: center;
  background-size: 100% 100%;
  background-image: url(${usaMapBg});

  .country-chart-core {
    position: relative;
    left: -60px;

    .country-chart-tooltip {
      padding-top: 16px;
      padding-bottom: 16px;

      .chart-tooltip-title {
        color: white;
        font-weight: bold;
        margin-bottom: 9px;

        .country-chart-city-pin {
          font-size: 18px;
          color: ${colors.redColor};
          margin-bottom: 6px;
        }
      }

      .chart-tooltip-amount-customers {
        font-weight: 500 !important;
        color: rgba(255, 255, 255, 0.5);

        .country-chart-customers-circle {
          font-size: 11px;
          color: #1fc086;
          margin-right: 4px;
        }
      }
    }
  }
`;
