import styled from "@emotion/styled";
import { colors } from "theme";

export const BlockOrderSummaryContainer = styled.div`
  min-height: 50px;
  background-color: ${colors.gray2};
  margin-bottom: 25px;
  border-radius: 20px;
  .card-block {
    padding: 30px 20px 10px;
    .card-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
      h5 {
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
    }
    .card-details {
      display: flex;
      justify-content: space-between;
      align-items: start;
      .color-green {
        color: #1fc086;
      }
    }
    .payment-mode {
      padding: 2px 10px 3px;
      border-radius: 12px;
      background-color: #d1d3e012;
    }
    &.summary-bottom {
      padding: 20px 20px 19px 28px;
      border-radius: 13px;
      background-color: rgba(255, 255, 255, 0.02);
    }
  }
`;
