import { PhotoUploader } from "components/Elements/PhotoUploader";
import React, { useState, useContext, useEffect } from "react";
import { DrawerAddCuisinesModalContainer } from "./style";
import { MyFileUploader } from "components/Elements/MyFileUploader";
import { FaCamera } from "react-icons/fa";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyButton from "components/Elements/MyButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { RestaurantsContext } from "context/RestaurantsContext";
import NoAvatar from "assets/img/noAvatar.png";
import { DefaultFood } from "assets/img";
import { drawersOverall } from "static/staticData";
import DeleteCuisines from "../DeleteCuisineModal";
import DefaultCuisineImage from "../../../../../assets/img/cuisine.png";

const AddCuisines = ({ isActive = false, closePanel = () => {} }) => {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    setCuisineList,
    updateCuisineDetailsData,
    setUpdateCuisineDetailsData,
    cuisineCloseDrawer,
    setCuisineCloseDrawer,
    cuisineImageFile,
    setCuisineImageFile,
    statusActiveNotification,
    setStatusActiveNotification,
    statusInactiveNotification,
    setStatusInactiveNotification,
  } = useContext(RestaurantsContext);

  const [cuisineName, setCuisineName] = useState("");
  const [cuisineImage, setCuisineImageset] = useState();
  // const [cuisineImageFile, setCuisineImageFile] = useState();

  useEffect(() => {
    if (Object.keys(updateCuisineDetailsData)?.length) {
      setCuisineName(updateCuisineDetailsData?.name);
      setCuisineImageFile(updateCuisineDetailsData?.image);
    } else {
      setCuisineName("");
    }
  }, [updateCuisineDetailsData]);

  function getCuisineData() {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
        setCuisineList(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function clearInputs() {
    setCuisineName("");
    setCuisineImageset(null);
    setCuisineImageFile(null);
  }

  // post
  function onClickAddCuisine() {
    const configCuisinesData = {
      image: cuisineImage,
      name: cuisineName,
      // description: "",
    };
    customAxios
      .post(`/cuisine`, configCuisinesData)
      .then((res) => {
        if (res.status === 201) {
          getCuisineData();
          // setCuisineName("");
          setStatusActiveNotification({
            message: "Cuisine Added.",
            type: "cuisine-active",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${cuisineName}</span>`,
          });
          closePanel();
        } else {
          closePanel();
          setStatusInactiveNotification({
            message: res.errMessage,
            type: "cuisine-inactive",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${cuisineName}</span>`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    clearInputs();
  }

  // put
  function onClickEditCuisine() {
    const configCuisinesEditData = {
      image: cuisineImage,
      name: cuisineName,
      // description: "",
    };
    customAxios
      .put(`/cuisine/${updateCuisineDetailsData?.id}`, configCuisinesEditData)
      .then((res) => {
        getCuisineData();
        closePanel();
        setStatusActiveNotification({
          message: "Cuisine Changes Saved.",
          type: "cuisine-active",
          table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${updateCuisineDetailsData?.name}</span>`,
        });
      })
      .catch((err) => {
        console.error(err);
      });
    clearInputs();
  }

  function onClickDelete() {
    // openDrawer(drawersOverall.deleteCuisines);
    setCuisineCloseDrawer({
      ...cuisineCloseDrawer,
      deleteCuisines: true,
      addCuisines: false,
    });
  }

  // const onUploadSuccess = (asset) => {
  //   setCuisineImageset(asset?.id);
  //   setCuisineImageFile(asset?.file);
  // };

  function onCancel() {
    if (Object.keys(updateCuisineDetailsData)?.length > 0) {
      closePanel();
    } else {
      setCuisineName("");
      setCuisineImageFile(null);
      closePanel();
    }
  }

  const callBackUpload = (asset) => {
    setCuisineImageset(asset?.id);
    setCuisineImageFile(asset?.file);
  };

  return (
    <>
      <DrawerAddCuisinesModalContainer
        isActive={isActive}
        closePanel={closePanel}
      >
        <div className="add-cuisine-wrapper">
          <div className="cuisine-header">
            <div></div>
            <div className="add-cuisine-title">
              {Object.keys(updateCuisineDetailsData)?.length ? "Edit" : "Add"}{" "}
              Cuisine
            </div>
            <div>
              {Object.keys(updateCuisineDetailsData)?.length > 0 && (
                <i
                  className="fas fa-trash-alt cursor"
                  style={{ color: "#ed2626" }}
                  onClick={onClickDelete}
                />
              )}
            </div>
          </div>

          <MyFileUploader
            shape="square"
            imgSrc={cuisineImageFile ? cuisineImageFile : DefaultCuisineImage}
            // placeholder={DefaultFood}
            // uploading={uploadingImage}
            // onUpload={onUploadImage}
            callBackUpload={callBackUpload}
            overlay={
              <div
                className="d-flex align-items-center input-title"
                style={{ position: "absolute", bottom: 0, left: "9rem" }}
              >
                <FaCamera size={24} color="white" />
                <p style={{ margin: 0, padding: "12px" }}>
                  {Object.keys(updateCuisineDetailsData)?.length > 0 ||
                  cuisineImageFile
                    ? "Replace"
                    : "Add"}{" "}
                  Image
                </p>
              </div>
            }
          />

          {/* <div className="cuisine-image">
          <PhotoUploader
            src={cuisineImageFile ? cuisineImageFile : DefaultFood}
            height={"149px"}
            width={"149px"}
            onUploadSuccess={onUploadSuccess}
          />
        </div> */}
          <div className="cuisine-input">
            <DrawerInput
              label="Cuisine Name"
              placeholder="Ex: Asian"
              value={cuisineName || ""}
              onChange={(e) => setCuisineName(e.target.value)}
            />
          </div>
          {Object.keys(updateCuisineDetailsData)?.length > 0 ? (
            <MyButton
              className="add-cuisine-btn"
              type={
                updateCuisineDetailsData?.name &&
                updateCuisineDetailsData?.image
                  ? "red"
                  : "gray"
              }
              onClick={onClickEditCuisine}
            >
              Save
            </MyButton>
          ) : (
            <MyButton
              className="add-cuisine-btn"
              type={cuisineName && cuisineImage ? "red" : "gray"}
              onClick={onClickAddCuisine}
              disabled={cuisineName && cuisineImage ? false : true}
            >
              Add Cuisine
            </MyButton>
          )}

          <div className="cancel-btn cursor text-bold" onClick={onCancel}>
            Cancel
          </div>
        </div>
      </DrawerAddCuisinesModalContainer>
      <DeleteCuisines
        // isActive={!!openDrawers[drawersOverall.deleteCuisines]}
        // closePanel={() => closeDrawer(drawersOverall.deleteCuisines)}
        isActive={cuisineCloseDrawer.deleteCuisines}
        closePanel={() => {
          setCuisineCloseDrawer({
            ...cuisineCloseDrawer,
            deleteCuisines: false,
          });
        }}
      />
    </>
  );
};

export default AddCuisines;
