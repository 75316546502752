import React, { useState, createContext } from "react";

const OrdersContext = createContext({});

export { OrdersContext };

export const OrdersContextContainer = (props) => {
  const [openDrawers, setOpenDrawers] = useState({});

  const [ordersData, setOrdersData] = useState([]);
  const [orderDetailData, setOrderDetailData] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedSubOrder, setSelectedSubOrder] = useState("");
  const [serverReviewData, setServerReviewData] = useState([]);
  const [selectedCardDetails, setSelectedCardDetails] = useState("");
  const [paymentDrawers, setPaymentDrawers] = useState({
    processPayment: false,
    processExistingCardPayment: false,
    addTip: false,
    cardDetailsForm: false,
    noPaymentCashConfirmation: false,
    cashPayment: false,
    paymentFailed: false,
  });

  const [isOrdersStatusSortEnabled, setIsOrdersStatusSortEnabled] = useState(
    false
  );
  const [
    isOrdersDateTimeSortEnabled,
    setIsOrdersDateTimeSortEnabled,
  ] = useState(false);

  const [orderNotification, setOrderNotification] = useState();
  const [addItemsToOrder, setAddItemsToOrder] = useState([]);
  const [orderRequestData, setOrderRequestData] = useState([]);

  const openDrawer = (drawer) => {
    setOpenDrawers({ ...openDrawers, [drawer]: drawer });
  };
  const closeDrawer = (drawer) => {
    // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
    let temp = { ...openDrawers };
    delete temp[drawer];
    setOpenDrawers(temp);
  };

  const [ordersRestaurantId, setOrdersRestaurantId] = useState();

  const [addOrderCount, setAddOrderCount] = useState(0);

  const [isOrderReviewsEnable, setOrderReviewsEnable] = useState(true);
  const [isRestoreDataEnable, setRestoreDataEnable] = useState(true);
  const [deleteReviewId, setDeleteReviewId] = useState();
  const [restoreReviewId, setRestoreReviewId] = useState();

  const [orderFoodInfo, setOrderFoodInfo] = useState({})

  return (
    <OrdersContext.Provider
      value={{
        openDrawers,
        openDrawer,
        closeDrawer,

        ordersData,
        setOrdersData,
        orderDetailData,
        setOrderDetailData,
        setSelectedItem,
        selectedItem,
        selectedSubOrder,
        setSelectedSubOrder,
        serverReviewData,
        setServerReviewData,
        setSelectedCardDetails,
        selectedCardDetails,
        paymentDrawers,
        setPaymentDrawers,

        isOrdersStatusSortEnabled,
        setIsOrdersStatusSortEnabled,
        isOrdersDateTimeSortEnabled,
        setIsOrdersDateTimeSortEnabled,
        orderNotification,
        setOrderNotification,
        addItemsToOrder,
        setAddItemsToOrder,
        orderRequestData,
        setOrderRequestData,

        ordersRestaurantId,
        setOrdersRestaurantId,

        addOrderCount,
        setAddOrderCount,

        isOrderReviewsEnable,
        setOrderReviewsEnable,
        isRestoreDataEnable,
        setRestoreDataEnable,
        deleteReviewId,
        setDeleteReviewId,

        restoreReviewId,
        setRestoreReviewId,

        orderFoodInfo, 
        setOrderFoodInfo,
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
};
