import React from "react";
import { BlockOrderRequestDetailContainer } from "./style";
import { FaClock, FaExchangeAlt } from "react-icons/fa";
import moment from "moment";
import RequestFoodItem from "./RequestFoodItem";

const BlockOrderRequestDetail = ({ returnRequestDetail }) => {
  const requestIconRender = (request) => {
    if (request.request_type === 0) {
      if (request.status === 1) {
        return (
          <div
            style={{
              width: "58px",
              height: "58px",
              backgroundColor: "#1fc085",
              borderRadius: "50%",
              padding: "17px 17px 17px 15px",
            }}
          >
            <i
              className="far fa-glass-cheers"
              style={{
                fontSize: "24px",
                color: "white",
              }}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: "58px",
              height: "58px",
              backgroundColor: "#ed2626",
              borderRadius: "50%",
              padding: "17px 17px 17px 15px",
            }}
          >
            <i
              className="far fa-glass-cheers"
              style={{
                fontSize: "24px",
                color: "white",
              }}
            />
          </div>
        );
      }
    } else if (request.request_type === 1) {
      if (request.status === 1) {
        return (
          <div
            style={{
              width: "58px",
              height: "58px",
              backgroundColor: "#1fc085",
              borderRadius: "50%",
              padding: "17px 17px 17px 18px",
            }}
          >
            <FaExchangeAlt color="white" size={24} />
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: "58px",
              height: "58px",
              backgroundColor: "#ed2626",
              borderRadius: "50%",
              padding: "17px 17px 17px 18px",
            }}
          >
            <FaExchangeAlt color="white" size={24} />
          </div>
        );
      }
    } else if (request.request_type === 2) {
      if (request.status === 1) {
        return (
          <div
            style={{
              width: "58px",
              height: "58px",
              backgroundColor: "#1fc085",
              borderRadius: "50%",
              padding: "17px 17px 17px 18px",
            }}
          >
            <i
              className="far fa-exclamation-circle"
              style={{
                fontSize: "24px",
                color: "white",
              }}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: "58px",
              height: "58px",
              backgroundColor: "#ed2626",
              borderRadius: "50%",
              padding: "17px 17px 17px 18px",
            }}
          >
            <i
              className="far fa-exclamation-circle"
              style={{
                fontSize: "24px",
                color: "white",
              }}
            />
          </div>
        );
      }
    } else {
    }
  };

  const renderRequestText = (request) => {
    if (request.request_type === 0) {
      return "Drink Refill Request";
    } else if (request.request_type === 1) {
      return "Return Request";
    } else if (request.request_type === 2) {
      return "Other Request";
    } else {
    }
  };

  const renderRequestStatus = (request) => {
    if (request.status === 0) {
      return (
        <div
          style={{ fontSize: "15px", fontWeight: "normal", color: "#f7a64c" }}
        >
          Pending
        </div>
      );
    } else if (request.status === 1) {
      return (
        <div
          style={{ fontSize: "15px", fontWeight: "normal", color: "#1fc086" }}
        >
          Completed
        </div>
      );
    } else if (request.status === 2) {
      return (
        <div
          style={{ fontSize: "15px", fontWeight: "normal", color: "#ed2626" }}
        >
          Rejected
        </div>
      );
    } else if (request.status === 3) {
      return (
        <div
          style={{ fontSize: "15px", fontWeight: "normal", color: "#ed2626" }}
        >
          Cancelled
        </div>
      );
    } else {
    }
  };

  return (
    <BlockOrderRequestDetailContainer>
      <div className="card-block">
        <div className="card-top d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center ">
            <div
              style={{
                marginRight: "10px",
              }}
            >
              {requestIconRender(returnRequestDetail)}
            </div>
            <div className="d-flex flex-column align-items-start">
              <h3 className="mb-0 mr-3">
                {renderRequestText(returnRequestDetail)}
              </h3>
              {renderRequestStatus(returnRequestDetail)}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-column">
            <span className="mt-2">
              {returnRequestDetail?.updatedAt ? (
                <>
                  <FaClock style={{ marginBottom: "4px" }} /> &nbsp;
                  <span className="opacity6">
                    {moment(returnRequestDetail?.updatedAt).format("mm")}m
                    &nbsp;
                    {moment(returnRequestDetail?.updatedAt).format("ss")}s
                  </span>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        </div>
        {returnRequestDetail?.items?.map((item, itemIndex) => {
          return (
            <RequestFoodItem
              requestFoodItemDetails={item}
              key={itemIndex}
              itemsArray={returnRequestDetail?.items}
              itemIndex={itemIndex}
              itemComment={returnRequestDetail?.comment}
            />
          );
        })}
      </div>
    </BlockOrderRequestDetailContainer>
  );
};

export default BlockOrderRequestDetail;
