import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerDeleteCustomerOTPContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fa-trash {
      color: #ed2626;
      font-size: 20px;
      margin-top: 6px;
    }
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .customer-delete-input-box {
    display: flex;
    margin-top: 26px;
    gap: 15px;
  }
  .delete-customer-otp {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.34px;
    color: #fff;
    margin-top: 88px;
  }
`;

export const StyledInput = styled.input`
  width: 43px;
  height: 43px;
  border-radius: 8px;
  border: ${(props) =>
    props.isValid ? "solid 1px #9b9b9b" : "solid 1px #ed2626"};
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 10px 13px 9px 9px;
  :focus-visible {
    outline: unset !important;
  }
`;
