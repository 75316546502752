import styled from "@emotion/styled";
import { colors } from "theme";

export const StaffPageContainer = styled.div`
  .staff-profile-wrapper {
    padding: 25px 35px 50px;
    .top-navigation-panel {
      display: flex;
      justify-content: space-between;
      padding: 25px 0;
      .backlink-to-rest {
        color: ${colors.gray};
        font-size: 17px;
      }
      .arrow-icon-container {
        i {
          color: white;
        }
      }
      .current-restaurant {
        font-size: 17px;
        font-weight: bold;
      }
    }

    .restaurant-profile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 35px 0px 25px 0px;
      margin: 0 20px 0 5px;
      border-bottom: 1px solid #4d5053;
      .left-part {
        flex: 1 1 50%;
        padding-left: 40px;
        .backlink-to-rest {
          color: ${colors.white};
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: -0.41px;
        }
        .arrow-icon-container {
          i {
            color: white;
          }
        }
        .current-restaurant {
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: -0.41px;
        }
      }
    }

    .restaurant-profile-card {
      min-height: 160px;
      width: 100%;
      /* height: 420px; */
      background-color: ${colors.gray2};
      border-radius: 20px;
      box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
      /* margin-top: 33px; */
      &.restaurant-history {
        min-height: 768px;
      }
      &.profile-stat {
        margin-bottom: 30px;
      }
    }

    .restaurant-favorite-card {
      width: 100%;
      background-color: ${colors.gray2};
      margin-top: 33px;
      height: 140px;
    }

    .left-part {
      flex: 1 1 50%;
      .nav-line-to-back {
        margin-bottom: 26px;
      }
      .backlink-to-rest {
        color: ${colors.white};
        font-size: 17px;
      }
      .arrow-icon-container {
        i {
          color: white;
        }
      }
      .current-restaurant {
        font-size: 17px;
        font-weight: bold;
      }
      .restaurant-title {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
      }
    }

    .add-promo-code-wrapper {
      background-color: #1b1c1d;
      padding: 22px 36px;
      margin-top: 30px;
      border-radius: 8px;
      box-shadow: 0 2px 22px 0 rgb(0 0 0 / 10%);
      height: 635px;
      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tabs {
        }
        .add-promo-view-btn {
        }
      }
    }
  }
`;
