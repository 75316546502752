import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useState, useEffect } from "react";
import { DrawerDeleteReviewContainer, Label } from "./style";
import { CardInput } from "./CardInput";
import { useCardDetails } from "../useCardDetails";
import { OrdersContext } from "context/OrdersContext";

export default function DrawerCardDetails({
  isActive = false,
  closePanel = () => {},
  addCardDetails = () => {},
}) {
  const { card, cardFields, changeCard, clearCard, isReady } = useCardDetails();
  const {
    setSelectedCardDetails,
    selectedCardDetails,
    paymentDrawers,
    setPaymentDrawers,
    orderDetailData,
  } = useContext(OrdersContext);

  const [isTouched, setIsTouched] = useState(false);
  const [balanceType, setBalanceType] = useState("full_balance");
  const [paymentOption, setPaymentOption] = useState("Full Balance");
  const [dollars, setDollars] = useState("");

  const touch = () => setIsTouched(true);
  const clearTouches = () => setIsTouched(false);

  const onAddCard = () => {
    addCardDetails(card);
  };

  const onChange = (key, newValue) => {
    changeCard({ [key]: newValue });

    if (!isTouched) {
      touch();
    }
  };

  useEffect(() => {
    if (!isActive && isTouched) {
      clearTouches();
      clearCard();
    }
  }, [isActive, isTouched, clearCard]);

  const allCardFields = [...cardFields];
  const combinedCardFields = allCardFields.splice(2, 1);

  return (
    <DrawerDeleteReviewContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"0%"}
      left={"0%"}
      position="absolute"
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <h3
            className="option-title-header d-flex justify-content-center align-items-center"
            style={{ marginBottom: "24px" }}
          >
            Credit or debit card
          </h3>
          <div className="discount-options-container">
            {["Full Balance", "Partial Amount"].map((option, index) => (
              <div
                className={`discount-option ${option === paymentOption &&
                  "active"}`}
                onClick={() => {
                  if (option !== "Partial Amount") {
                    setPaymentOption(option);
                  } else {
                  }
                }}
                key={index + option}
              >
                {option === "Full Balance" ? (
                  <span className="option-title-header">
                    ${orderDetailData?.checkout_data?.total}
                  </span>
                ) : (
                  <div className="type-value-container dollars">
                    <span className="type-value">$</span>
                    <input
                      type="number"
                      value={dollars}
                      onChange={(event) =>
                        setDollars(Number(event.target.value))
                      }
                      max={1000}
                      disabled
                    />
                  </div>
                )}
                <span className="option-title">{option}</span>
              </div>
            ))}
          </div>
          {allCardFields.map(
            ([label, key, isValid, formatter, convertBack], index) => {
              return (
                <CardInput
                  className={key === "number" ? "card-number" : ""}
                  label={label}
                  value={card[key] || ""}
                  formatToReadableValue={formatter}
                  convertVisibleValueToRealValue={convertBack}
                  onChange={(newValue) => onChange(key, newValue)}
                  isValid={isValid()}
                  isTouched={isTouched}
                  key={index + label + key}
                  placeholder={
                    key === "number"
                      ? "0000 0000 0000 0000"
                      : "Enter cardholder’s full name"
                  }
                />
              );
            }
          )}
          {combinedCardFields.map(([leftField, rightField], index) => {
            const [
              leftFieldLabel,
              leftFieldKey,
              isLeftFieldValid,
              formatterLeft,
              convertBackLeft,
            ] = leftField;
            const [
              rightFieldLabel,
              rightFieldKey,
              isRightFieldValid,
              formatterRight,
              convertBackRight,
            ] = rightField;

            return (
              <div
                className="combined-container"
                key={
                  index +
                  leftFieldLabel +
                  leftFieldKey +
                  rightFieldLabel +
                  rightFieldKey
                }
              >
                <CardInput
                  className="combined-left"
                  label={leftFieldLabel}
                  value={card[leftFieldKey] || ""}
                  formatToReadableValue={formatterLeft}
                  convertVisibleValueToRealValue={convertBackLeft}
                  onChange={(newValue) => onChange(leftFieldKey, newValue)}
                  isValid={isLeftFieldValid()}
                  isTouched={isTouched}
                  placeholder="MM / YY"
                />
                <CardInput
                  className={`combined-right ${
                    rightFieldKey === "cvv" ? "cvv" : ""
                  }`}
                  htmlType={rightFieldKey === "cvv" ? "password" : "text"}
                  label={rightFieldLabel}
                  value={card[rightFieldKey] || ""}
                  formatToReadableValue={formatterRight}
                  convertVisibleValueToRealValue={convertBackRight}
                  onChange={(newValue) => onChange(rightFieldKey, newValue)}
                  isValid={isRightFieldValid()}
                  isTouched={isTouched}
                  placeholder="CVV"
                />
              </div>
            );
          })}
          {isTouched && !isReady() ? (
            <div className="error-message-container">
              {/* <i className="fas fa-exclamation-circle" /> */}
              <i className="fas fa-times-circle" style={{color: "#ed2626", fontSize: '20px'}}/>
              <span className="error-message">Incomplete Fields</span>
            </div>
          ) : (
            ""
          )}
          <div className="bottom-btns" style={{ marginTop: "51px" }}>
            <MyDrawerButton
              type="red"
              className="mb-3"
              onClick={onAddCard}
              disabled={!isTouched || !isReady()}
            >
              <b>Continue</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteReviewContainer>
  );
}
