import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useState } from "react";
import { DrawerFoundManagerContainer } from "./style";
import { FaChevronRight, FaSearch, FaTimes } from "react-icons/fa";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { colors } from "theme";
import { RestaurantsContext } from "context/RestaurantsContext";
import Geocode from "react-geocode";
import useOnclickOutside from "react-cool-onclickoutside";

export default function DrawerFoundManager({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { setPostRestaurant, postRestaurant } = useContext(RestaurantsContext);

  // google places autocomplete
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['address']
    },
    debounce: 300,
    // cache: 24 * 60 * 60
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      console.log("📍 Coordinates: ", { lat, lng });
      // get address from coordinate
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.enableDebug();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setPostRestaurant({ ...postRestaurant, address: address });
          console.log(address);
          closePanel();
        },
        (error) => {
        }
      );
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className="found-address-list cursor-pointer"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <div>
            <p className="text-bold">{main_text}</p>
            <p>{secondary_text}</p>
          </div>
          <div>
            <FaChevronRight
              size={15}
              color={colors.white3}
              className="click-pointer"
            />
          </div>
        </div>
      );
    });
  // end of google places autocomplete

  function textReset() {
    setValue("")
    clearSuggestions();
  }

  return (
    <DrawerFoundManagerContainer isActive={isActive} closePanel={closePanel} ref={ref}>
      <DrawerContainer>
        <h2 className="text-white text-center found-location-title">
          Found Location
        </h2>
        {/* ref={ref} */}
        <div className="w-100">
          <input
            type="text"
            placeholder="Enter an address"
            className="w-100 location-input"
            value={value}
            // value={postRestaurant?.address}
            onChange={handleInput}
            disabled={!ready}
          />
          {value?.length > 0 ? (
            <div className="input-icon cursor-pointer">
              <FaTimes
                size={15}
                color={colors.white3}
                onClick={textReset}
              />
            </div>
          ) : (
            <div className="input-icon">
              <FaSearch size={15} color={colors.white3} />
            </div>
          )}
        </div>
        {status === "OK" && <span>{renderSuggestions()}</span>}
      </DrawerContainer>
    </DrawerFoundManagerContainer>
  );
}
