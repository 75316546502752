import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerRemoveItemContainer } from "./style";
import { CustomersContext } from "context/CustomersContext";

export default function DrawerStatusConfirm({
  isActive = false,
  closePanel = () => {},
  onClickSetStatus = () => {},
  status,
  savedStatus,
  closeConfirmStausDrawer = () => {},
}) {
  const {
    customerActiveNote,
    setCustomerActiveNote,
    inactiveReason,
    setInactiveReason,
    customerInactiveNote,
    setCustomerInactiveNote,
  } = useContext(CustomersContext);

  const customerStatusAtive = () => {
    closePanel();
  };

  const customerStatusInative = () => {
    closePanel();
  };

  return (
    <DrawerRemoveItemContainer
      isActive={isActive}
      closePanel={closePanel}
      renderLeftIcon={() => {}}
      left="40%"
      top="20%"
    >
      <DrawerContainer>
        <div className="set-discount-container">
          <div className="modal-title">
            {status === 1 ? "Set Active Status" : "Set Inctive Status"}
          </div>

          {status === 1 ? (
            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  <i>Optional • Visible to Feasttt admins</i>
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={customerActiveNote || ""}
                placeholder="Type here"
                onChange={(event) => setCustomerActiveNote(event.target.value)}
              />
            </div>
          ) : (
            <>
              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Describe the Reason</div>
                  <div className="label-text">
                    <i>Mandatory • Visible to customer</i>
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={inactiveReason || ""}
                  placeholder="Type here"
                  onChange={(event) => setInactiveReason(event.target.value)}
                />
              </div>

              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Internal Note</div>
                  <div className="label-text">
                    <i>Optional • Visible to Feasttt admins</i>
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={customerInactiveNote || ""}
                  placeholder="Type here"
                  onChange={(event) =>
                    setCustomerInactiveNote(event.target.value)
                  }
                />
              </div>
            </>
          )}

          {status === 1 ? (
            <MyDrawerButton
              type="red"
              className="status-btn"
              // onClick={() => onClickSetStatus(status)}
              onClick={customerStatusAtive}
            >
              Set Active
            </MyDrawerButton>
          ) : (
            <MyDrawerButton
              type={inactiveReason ? "red" : "gray"}
              className="status-btn"
              disabled={inactiveReason ? false : true}
              // onClick={() => onClickSetStatus(status)}
              onClick={customerStatusInative}
            >
              Set Inactive
            </MyDrawerButton>
          )}

          <div
            className="cancel-btn cursor-pointer"
            onClick={closeConfirmStausDrawer}
          >
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerRemoveItemContainer>
  );
}
