import React, { useContext } from "react";
import { PendingOrderActionsModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";
import { colors } from "theme";

const PendingSubOrderActionsModal = ({
  isActive = false,
  closePanel = () => {},
  onClickDeleteSubOrder = () => {},
  order = {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();
  return (
    <PendingOrderActionsModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"38%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="pending-order-actions">
          <div
            className="clickable-row"
            onClick={() => {
              history.push(`/order-detail/${orderDetailData?.token}/add-item`);
              closePanel();
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-plus"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Add Item</div>
            </div>
          </div>
          <div
            className="clickable-row"
            onClick={() => {
              onClickDeleteSubOrder(order);
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-trash-alt"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Delete Sub-Order</div>
            </div>
          </div>
          {/* <MyDrawerButton
            className="pending-order-action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton> */}
          <div className="cancel-btn" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </PendingOrderActionsModalContainer>
  );
};

export default PendingSubOrderActionsModal;
