import React, { useContext } from "react";
import { MarkAsReadyModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";
import { FaConciergeBell } from "react-icons/fa";
const MarkAsReadyModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteSubOrder = () => {},
  subOrderType = "Drinks",
  onDoneMarkAsReady = () => {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();

  const typeRenderer = () => {
    switch (subOrderType) {
      case "Drinks":
        return "drinks";
      case "Mark_Quick":
        return "quick foods";
      case "Entrees":
        return "entrées";
      default:
        return null;
    }
  };

  const typeButtonRenderer = () => {
    switch (subOrderType) {
      case "Drinks":
        return "Mark Drinks as Ready";
      case "Mark_Quick":
        return "Mark Quick Foods as Ready";
      case "Entrees":
        return "Mark Entrées as Ready";
      default:
        return null;
    }
  };

  return (
    <MarkAsReadyModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="mark-as-ready-modal">
          <div className="delete-icon">
            {subOrderType === "Entrees" && <i className="fas fa-concierge-bell" />}
            {subOrderType === "Mark_Quick" && <i className="fas fa-concierge-bell" />}
            {subOrderType === "Drinks" && <i className="far fa-glass-cheers" />}
          </div>
          <div className="delete-suborder-title">{typeButtonRenderer()}</div>
          <div className="delete-suborder-meta">
            The server will be notified that {typeRenderer()} are ready to be
            delivered to the table.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              className="mark-btn"
              type="red"
              onClick={() => {
                onDoneMarkAsReady();
              }}
            >
              <b>{typeButtonRenderer()}</b>
            </MyDrawerButton>
            {/* <MyDrawerButton onClick={closePanel} type="gray">
              <b>Keep</b>
            </MyDrawerButton> */}
            <div className="cancel-btn" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </MarkAsReadyModalContainer>
  );
};

export default MarkAsReadyModal;
