  import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
  import { DrawerContainer } from "components/styledStyle";
  import React from "react";
  import { ModalHideNutritionContainer } from "./style";

  export default function HideNutritionModal({
    isActive = false,
    closePanel = () => {},
    foodTitle,
  }) {

    return (
      <ModalHideNutritionContainer
        isActive={isActive}
        closePanel={closePanel}
        top="33%"
        zIndexBackground="9999"
      >
        <DrawerContainer>
          <h2 className="drawer-title">Hide Nutrition</h2>
          <p className="menu-name">
            Are you sure you want to hide <b>{foodTitle}</b>?
          </p>
          <MyDrawerButton type="red" className="text-bold mb-5 mt-4">
            Hide
          </MyDrawerButton>
          <MyDrawerButton type="gray" className="text-bold" onClick={closePanel}>
            Keep
          </MyDrawerButton>
        </DrawerContainer>
      </ModalHideNutritionContainer>
    );
  }
