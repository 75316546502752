import React, { useContext } from "react";
import {
  ApproveConfiramtionModalContainer,
  ApproveInputContainer,
} from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerInputContainer } from "components/Elements/DrawerElements/DrawerInput/style";
import { CustomersContext } from "context/CustomersContext";
import { NoAvatar } from "assets/img";

const ApproveBanModal = ({
  isActive = false,
  closePanel = () => {},
  customer = {},
  onDone = () => {},
}) => {
  return (
    <ApproveConfiramtionModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"37%"}
      position="fixed"
      width="472px"
      zIndexBackground="9999"
    >
      <DrawerContainer>
        <div className="restaurant-approval-div">
          <div className="modal-image">
            <img
              src={customer?.avatar?.file || NoAvatar}
              alt=""
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="modal-title">
            <b>Are you sure?</b>
          </div>
          <div className="modal-meta">
            You want to ban
            <b>
              &nbsp;{" "}
              {customer?.first_name === "" && customer?.last_name === ""
                ? "No Name"
                : `${customer?.first_name} ${customer?.last_name}`}
              &nbsp;
            </b>
            ?
          </div>
          <div className="modal-bottom">
            <MyDrawerButton
              type="red"
              className="approve-btn"
              onClick={() => {
                onDone();
              }}
            >
              <b>Yes, Ban Customer</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ApproveConfiramtionModalContainer>
  );
};

export default ApproveBanModal;
