import React from "react";
import { ConfirmDeleteSubOrderModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const ConfirmEntireOrderDeleteModal = ({
  isActive = false,
  closePanel = () => {},
  onSucessFullDeleteOrder = () => {},
}) => {
  return (
    <ConfirmDeleteSubOrderModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"42%"}
      left={"38%"}
      position="fixed"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Order Deleted</div>
          <div className="delete-suborder-meta">
            All items were removed from the system and the customer’s bill.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              onClick={() => {
                onSucessFullDeleteOrder();
              }}
              type="red"
            >
              <b>Okay</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ConfirmDeleteSubOrderModalContainer>
  );
};

export default ConfirmEntireOrderDeleteModal;
