export const api = {
  customer: {
    getCustomer: (handledOptions, status) =>
      `/customer/list/${status}${handledOptions}`,
    getCustomerById: "/customer/",
    updateCustomerById: "/customer/",
    getCustomerCardsByCustomerId: (customerId) =>
      `/customer/${customerId}/card`,
    getCustomerLocationsByCustomerId: (customerId) =>
      `/customer/${customerId}/location`,
    getCustomerCardByCustomerId: (customerId, cardId) =>
      `/customer/${customerId}/card/${cardId}`,
    createCard: (customerId) => `/customer/${customerId}/card`,
    updateCustomerCard: (customerId, cardId) =>
      `/customer/${customerId}/card/${cardId}`,
    deleteCustomerCard: (customerId, cardId) =>
      `/customer/${customerId}/card/${cardId}`,
    createCustomerLocation: (customerId) => `/customer/${customerId}/location`,
    updateCustomerLocation: (customerId, locationId) =>
      `/customer/${customerId}/location/${locationId}`,
    deleteLocation: (customerId, locationId) =>
      `/customer/${customerId}/location/${locationId}`,
  },
};
