import { clearJwt } from "services/jwt";

export function promiseRequest(dataSetter, promise, ...parameters) {
  return promise(...parameters)
    .then((res) => {
      dataSetter(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err.response);

      // Checks for auth issues. Since error codes are same, I had to use messages.
      if (
        err.response?.data?.message === "Please login again" ||
        err.response?.data?.message === "Signature verification failed"
      ) {
        clearJwt();
        window.location.reload();
      }
      return err;
    });
}
