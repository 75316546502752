import styled from "@emotion/styled";

export const LiveNowContainer = styled.div`
  width: 52%;
  background-color: #141414;
  // background-color: #1a1a1b;
  /* background-color: #1b1c1d; */
  border-radius: 8px;
  padding: 25px 25px 70px 25px;
  display: flex;
  flex-direction: column;

  .live-now-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 41px;

    .live-now-top-title {
      font-size: 20px;
      font-weight: bold;
      color: white;
      margin-left: -35px;
      margin-bottom: 0px;
    }
  }

  /* .live-facts-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .fact-live-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .fact-live-title {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 6px;
      }

      .fact-live-value {
        font-size: 40px;
        font-weight: bold;
        color: white;
      }
    }
  } */

  /* .live-now-country {
    margin-top: 86px;
    width: 100%;
  } */

  .live-now-title {
    h4 {
      padding: 0 70px 0 70px;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 45px;
    }
  }

  .roles {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 0 81px 0 52px;
    margin-bottom: 94px;
    position: relative;
    top: 10rem;
    .role {
      .role-title {
        opacity: 0.6;
        font-size: 16px;
        color: #fff;
      }
      .customers-live-title {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      .customers-live-count {
        font-family: Montserrat;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -1.2px;
        color: #fff;
      }
      .role-count {
        font-size: 40px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
`;
