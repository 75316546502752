import styled from "@emotion/styled";

export const CustomerSignUpsTotalContainer = styled.div`
  width: 20%;
  padding: 12px 0px 6px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-bottom: 58px;
  gap: 58px;

  .sign-ups-total-item {
    display: flex;
    flex-direction: column;
    /* margin: 0px 86px 58px 106px; */

    .sign-ups-total-item-title {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 3px;
      width: max-content;
    }

    .sign-ups-total-item-value {
      font-size: 30px;
      font-weight: bold;
      color: white;
    }
  }
`;
