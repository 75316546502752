import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerEditProfileContainer = styled(MyDrawerRight)`
  .header-bg {
    position: relative;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .close-drawer-icon {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      position: absolute;
      left: 23px;
      top: 23px;
      z-index: 999;
      width: 87%;
      .drawer-title {
        /* margin-left: 100px; */
      }
    }
    .bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(5px);
      transform: scale(2);
    }
    /* &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100px;
      bottom: 0;
      background-color: #1b1c1d;
      background-color: red;
      border-radius: 30px 30px 0 0;
      z-index: 1;
    } */
  }

  .upload-profile-wrapper {
    /* margin-top: 49px; */
    margin-bottom: 39px;
  }
  .divide-section {
    margin-top: 50px;
  }
  .cancel-btn {
    text-align: center;
    color: #ed2626;
    margin: 30px 0 30px 0;
    cursor: pointer;
  }
  .bottom-btns {
    padding-top: 50px;
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-title {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
  }
  .pointer {
    cursor: pointer;
  }

  .search-address {
    input::placeholder {
      color: ${colors.redColor};
    }
  }
`;

export const DrawerMobileNumberInputWithVerifyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding-top: 10px;
  h6 {
    font-size: 14px;
    opacity: 0.4;
    line-height: 1.57;
    letter-spacing: -0.34px;
  }
  .drawer-input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    .input-prefix {
      flex: 0 0 auto;
      color: #fff;
      padding-right: 10px;
    }
    input {
      flex: 1 1 auto;
      background-color: transparent;
      color: #fff;
      font-weight: bold;
      border: unset;
      outline: unset;
      padding-left: 0;
    }
  }
  .input-icon {
    position: absolute;
    right: 0;
    width: 27px;
    height: 27px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: ${colors.gray};
    }
  }
`;
