import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useEffect, useState } from "react";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteNotesContainer } from "./style";
import { useParams } from "react-router-dom";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerDeleteNotes({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
}) {
  const { id } = useParams();
  const { noteId, setNotesData, restaurant } = useContext(RestaurantsContext);

  const [notesNotification, setNotesNotification] = useState();

  useEffect(() => {
    if (notesNotification) {
      setTimeout(() => setNotesNotification(null), 2 * 1000);
    }
  }, [notesNotification]);

  function handleClickDeleteNotes() {
    customAxios
      .delete(`/restaurant/${id}/note/${noteId}`)
      .then((res) => {
        getNotesData();
        setNotesNotification({
          message: "Note Deleted.",
          type: "remove",
          table: `<p style="color: #8F949A">${restaurant?.title}</b>.</p>`,
        });
        closePanel();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getNotesData() {
    customAxios
      .get(`/restaurant/${id}/notes`)
      .then((res) => {
        setNotesData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <DrawerDeleteNotesContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"31%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {notesNotification && (
          <MyMessageBoxItemNew
            message={
              notesNotification?.message ? notesNotification.message : ""
            }
            table={notesNotification?.table ? notesNotification.table : ""}
            type={notesNotification?.type ? notesNotification.type : "success"}
            onClick={() => setNotesNotification(null)}
          />
        )}
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Delete Notes</div>
          <div className="delete-suborder-meta">
            Are you sure you want to delete this note?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton type="red" onClick={handleClickDeleteNotes}>
              <b>Yes, Delete Note</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-4">
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteNotesContainer>
  );
}
