import React from "react";
import { DrawerDeleteCustomerConfirmationContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";

export default function DrawerDeleteCustomersConfirmation({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
}) {
  return (
    <DrawerDeleteCustomerConfirmationContainer
      isActive={isActive}
      closePanel={closePanel}
      containerClassName="delete-customer-drawer"
    >
      <DrawerContainer nopadding style={{ paddingBottom: 0 }}>
        <h2 className="drawer-title text-white text-center">Delete Customer</h2>
        <div className="set-status-wrapper mt-auto">
          <h2 className="text-white text-left">Are you sure?</h2>
          <p className="text-white text-left">
            Selected Customer will be deleted.
          </p>
          <p className="delete-customer-undone">This cannot be undone.</p>
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mb-5 keep-btn"
              onClick={closePanel}
            >
              Keep Customer
            </MyDrawerButton>
            <MyDrawerButton type="gray" className="delete-btn" onClick={onDone}>
              Yes, delete Customer
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteCustomerConfirmationContainer>
  );
}
