import React, { useState, useEffect, useContext } from "react";
import { StaffContainer } from "./style";
import { Link, useParams } from "react-router-dom";
import { colors } from "theme";
import MySearch from "components/Elements/MySearch";
import MyButton from "components/Elements/MyButton";
import MySelect from "components/Elements/MySelect";

import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import {
  ActiveRestaurants,
  NewRestaurantCard,
  TotalRestaurants,
} from "assets/img";
import MyPaginator from "components/Elements/MyPaginator";
import noResultImg from "assets/img/no-filter-data.png";
import { StaffList } from "./StaffList";
import { useHistory } from "react-router-dom";
import { StaffAnalyticItem } from "./StaffAnalyticItem";
import { showLimits, drawersStaff } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import DrawerActiveStaffFilter from "./Drawers/ActiveStaffFilter";
import DrawerStaffInactive from "./Drawers/StaffInactiveModal";
import DrawerStaffActive from "./Drawers/StaffActiveModal";
import DrawerInactiveStaffFilter from "./Drawers/InactiveStaffFilter";
import DrawerBannedStaffFilter from "./Drawers/BannedStaffFilter";
import DrawerDeletedStaffFilter from "./Drawers/DeletedStaffFilter";
import { customAxios } from "helpers/custom-helpers/customAxios";

const statsTypes = [
  {
    key: "total",
    icon: <i className="fas fa-sigma" />,
    title: "Total Staff",
    image: NewRestaurantCard,
  },
  {
    key: "new",
    icon: <i className="fas fa-user-tie" />,
    title: "New Staff",
    subTitle: "in last 30 days",
    image: ActiveRestaurants,
  },
  {
    key: "active",
    icon: <i className="fas fa-spinner" />,
    title: "Active Staff",
    subTitle: "in last 30 days",
    image: TotalRestaurants,
  },
];

export const StaffPage = () => {
  const history = useHistory();

  const { openDrawer, openDrawers, closeDrawer } = useContext(
    RestaurantsContext
  );

  const [searchInput, setSearchInput] = useState("");
  const [isSearchFilterApplied, setIsSearchFilterApplied] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerFilterIcon, setCustomerFilterIcon] = useState(true);
  const [customerListTab, setCustomerListTab] = useState(1);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);
  const [totalList, setTotalList] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const [staffWidgetData, setStaffWidgetData] = useState({})


  const onPageChange = async (e) => {
    setSelectedPage(e.selected + 1);
  };


  // staff widget api
  useEffect(() => {
    customAxios
      .get(`/staff/widget`)
      .then((res) => {
        setStaffWidgetData(res?.data)
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const columnRenderHandler = () => {
    if (customerListTab === 1) {
      return (
        <>
          <div className="item-flex-normal joined-active">Joined</div>
          <div className="item-flex-normal">Status</div>
          <div className="item-flex-normal">Rating</div>
          <div className="item-flex-normal active-restaurant">
            Active Restaurants
          </div>
          <div className="item-flex-normal">Phone Number</div>
          <div className="item-flex-last">Email</div>
        </>
      );
    } else if (customerListTab === 0) {
      return (
        <>
          {/* <div className="item-flex-normal">Inactivated</div> */}
          <div className="item-flex-normal joined-active">Joined</div>
          <div className="item-flex-normal">Status</div>
          <div className="item-flex-normal">Rating</div>
          <div className="item-flex-normal active-restaurant">Restaurants</div>
          <div className="item-flex-normal">Phone Number</div>
          <div className="item-flex-last">Email</div>
        </>
      );
    } else if (customerListTab === 9) {
      return (
        <>
          <div className="item-flex-normal">Banned</div>
          <div className="item-flex-normal">Joined</div>
          <div className="item-flex-normal">Status</div>
          <div className="item-flex-normal">Rating</div>
          <div className="item-flex-normal">Banned By</div>
          <div className="item-flex-normal">Phone Number</div>
          <div className="item-flex-last">Email</div>
        </>
      );
    } else if (customerListTab === -1) {
      return (
        <>
          <div className="item-flex-normal">Deleted</div>
          <div className="item-flex-normal">Joined</div>
          <div className="item-flex-normal">Status</div>
          <div className="item-flex-normal">Deleted By</div>
          <div className="item-flex-normal">Phone Number</div>
          <div className="item-flex-last">Email</div>
        </>
      );
    }
  };

  function handleRestaurantFilteredAscending() {}

  function handleRestaurantFilteredDescending() {}

  const toggleCustomer = (customers, e) => {
    if (customerListTab === 1 || customerListTab === 0) {
      if (selectedCustomers.includes(customers?.id))
        setSelectedCustomers([
          ...selectedCustomers.filter(
            (selectedId) => selectedId !== customers?.id
          ),
        ]);
      if (!selectedCustomers.includes(customers?.id))
        setSelectedCustomers([...selectedCustomers, customers?.id]);
    }
  };

  const selectAll = () => {
    if (selectedCustomers.length) {
      setSelectedCustomers([]);
    } else {
      if (activeStaff?.length) {
        const allSelectCustomer = activeStaff?.map(
          (el) => el.id
        );
        setSelectedCustomers(allSelectCustomer);
      }
    }
  };

  const onClickHandler = () => {};

  function filterStaffTab() {
    if (customerListTab && customerListTab === 1) {
      openDrawer(drawersStaff.staffDrawerFilter);
    } else if (customerListTab === 0) {
      openDrawer(drawersStaff.InactiveStaffDrawerFilter);
    } else if (customerListTab === 9) {
      openDrawer(drawersStaff.BannedStaffDrawerFilter);
    } else if (customerListTab === -1) {
      openDrawer(drawersStaff.DeletedStaffDrawerFilter);
    }
  }

  const [activeStaff, setActiveStaff] = useState([])


const fetchStaff = () => {
  if(customerListTab == 1){
    customAxios
      .get(`/staff?page=${selectedPage}&page_size=${selectedLimitToShow}`)
      .then((res) => {
        setActiveStaff(res?.data?.active_staff)
        setTotalList(res?.data?.active_count);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else if (customerListTab == 0){
      customAxios
        .get(`/staff?page=${selectedPage}&page_size=${selectedLimitToShow}&status=0`)
        .then((res) => {
          setActiveStaff(res?.data?.active_staff)
          setTotalList(res?.data?.active_count);
        })
        .catch((err) => {
          console.log(err);
        });
    }else if (customerListTab == 9){
      customAxios
        .get(`/staff/banned?page=${selectedPage}&page_size=${selectedLimitToShow}`)
        .then((res) => {
          setActiveStaff(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else if (customerListTab == -1){
      customAxios
        .get(`/staff/archived?page=${selectedPage}&page_size=${selectedLimitToShow}`)
        .then((res) => {
          setActiveStaff(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }
}


  useEffect(() => {
 fetchStaff();
  }, [customerListTab, selectedPage])



  return (
    <StaffContainer>
      <div className="customer-list-wrapper">
        <div className="customer-component-header">
          <div className="left-part">
            <h2>Staff</h2>
          </div>

          <div className="center-part">
         {customerListTab == 0 && selectedCustomers.length > 0 &&   <MyButton className="rounded-button"   onClick={() => openDrawer(drawersStaff.staffDrawerActive)}>Set All Active</MyButton>}
         {customerListTab == 1 && selectedCustomers.length > 0 &&   <MyButton
              className="rounded-button"
              onClick={() => openDrawer(drawersStaff.staffDrawerInactive)}
            >
              Set All Inactive (Global)
            </MyButton>
            }
         {selectedCustomers.length > 0 && (customerListTab == 1 || customerListTab == 0) &&   <MyButton className="squire-button">
              <span>
                {selectedCustomers?.length} <span style={{ fontWeight: 500 }}>staff(s) selected</span>{" "}
                <span className="text-undo" onClick={() => {
                  setSelectedCustomers([])
                }}>Undo</span>
              </span>
            </MyButton>
}
          </div>

          <div className="right-part">
            <div style={{ position: "relative" }}>
              <MySearch
                value={searchInput}
                onChange={(e) => {
                  if (e?.target?.value === undefined) {
                    setSearchInput("");
                  } else {
                    setSearchInput(e?.target?.value);
                  }
                }}
                defaultShow={true}
                placeHolder="Search Staff Name"
              />
            </div>
            <MySelect
              className="mr-6"
              options={showLimits}
              defaultValueId={showLimits[0]}
              onChange={(option) => setSelectedLimitToShow(option.name)}
            />

            <div
              className="menu-item"
              onClick={filterStaffTab}
              // onClick={() => openDrawer(drawersStaff.staffDrawerFilter)}
              // onClick={() => openDrawer(drawersStaff.InactiveStaffDrawerFilter)}
            >
              <i className="fas fa-sliders-h" />
              {/* <div className="active-dot" /> */}
            </div>
          </div>
        </div>

        <div className="separator-line" />

        <div className="customer-list-tabs">
          <Tab.Container
            id=""
            defaultActiveKey={1}
            onSelect={(key) => {
              setCustomerListTab(Number(key));
              setSelectedCustomers([]);
              setIsFilterApplied(false);
              setSelectedPage(1);
            }}
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey={1}>Active</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={0}>Inactive (Global)</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={9}>Banned</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={-1}>Deleted</Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <div className="header-total-count">
            <span className="total-count-text1">
              {isSearchFilterApplied && isFilterApplied ? (
                <>Filter & Search Results:</>
              ) : (
                <>
                  {isSearchFilterApplied ? (
                    <>Search Results:</>
                  ) : (
                    <>{isFilterApplied ? "Filter Results:" : "Total:"}</>
                  )}
                </>
              )}
            </span>
            <span className="total-count-text2">5</span>
          </div>
        </div>

        {/* analytics */}
        <Row className="analytic-container">
          {statsTypes.map((_type, _i) => (
            <Col key={_i} md={4}>
              <StaffAnalyticItem
                // value={customerStats[_type.key]}
                value={staffWidgetData[_type.key]}
                title={_type.title}
                subTitle={_type.subTitle}
                icon={_type.icon}
                image={_type?.image}
              />
            </Col>
          ))}
        </Row>

        {/* filter badge */}
        {/* <div className="filter-badge">
          <div className="filter">
            <span className="badge-label">Joined Date</span> Aug 12, 2022 - Aug
            12, 2023{" "}
            <i
              className="fas fa-times"
              style={{
                cursor: "pointer",
                color: "#ed2626",
                fontSize: "14px",
                fontWeight: 500,
              }}
            />
          </div>

          <div className="filter">
            <span className="badge-label">Rating</span> 1.6 - 3.4{" "}
            <i
              className="fas fa-times"
              style={{
                cursor: "pointer",
                color: "#ed2626",
                fontSize: "14px",
                fontWeight: 500,
              }}
            />
          </div>

          <div className="filter">
            <span className="badge-label">Active Restaurants</span> 8 -14{" "}
            <i
              className="fas fa-times"
              style={{
                cursor: "pointer",
                color: "#ed2626",
                fontSize: "14px",
                fontWeight: 500,
              }}
            />
          </div>

          <div className="filter" style={{ cursor: "pointer" }}>
            <span
              className="badge-label"
              style={{
                color: "#ed2626",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Clear All
            </span>{" "}
          </div>
        </div> */}

        {/* staff list component */}
        <div className="customers-list-container">
          {true && !loading && (
            <div className="customers-titles">
              <div className="left-padding-1 item-flex-small">
                {customerListTab === 1 || customerListTab === 0 ? (
                  <>
                    {selectedCustomers?.length ? (
                      selectedCustomers?.length >= selectedLimitToShow ? (
                        <i
                          className="fas fa-check-circle cursor"
                          onClick={selectAll}
                        />
                      ) : (
                        <i
                          className="fas fa-minus-circle cursor"
                          onClick={selectAll}
                        />
                      )
                    ) : (
                      <i className="far fa-circle cursor" onClick={selectAll} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="item-flex-big cursor-pointer"
                onClick={
                  customerFilterIcon
                    ? handleRestaurantFilteredAscending
                    : handleRestaurantFilteredDescending
                }
              >
                Full Name &nbsp;
                <span className="cursor-pointer">
                  {customerFilterIcon ? (
                    <i
                      className="far fa-angle-down cursor-pointer"
                      style={{ fontSize: "16px", color: "#5c5d5d" }}
                      onClick={handleRestaurantFilteredAscending}
                    />
                  ) : (
                    <i
                      className="far fa-angle-up cursor-pointer"
                      style={{ fontSize: "16px", color: "#5c5d5d" }}
                      onClick={handleRestaurantFilteredDescending}
                    />
                  )}
                </span>
              </div>
              {/* <div className="item-flex-normal">Status</div> */}
              {columnRenderHandler()}
            </div>
          )}

          {true ? (
            <div className="customers-list">
              {activeStaff.map((customer, index) => {
                return (
                  <>
                    <StaffList
                      key={`restkey${customer?.id}-${index}`}
                      isSelected={selectedCustomers.includes(customer?.id)}
                      customOnClick={(e) => toggleCustomer(customer, e)}
                      goToProfile={(user_id) => {
                        if (selectedCustomers?.length === 0) {
                          //   history.push(`/customer-detail/${customer?.user_id}`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                          //   setCustomerBanId(customer?.ban_id || "");
                          setCustomerListTab(1);
                        }
                      }}
                      customerListTab={customerListTab}
                      customer={customer}
                      selectedCustomers={selectedCustomers}
                      searchInput={searchInput}
                    />
                  </>
                );
              })}
            </div>
          ) : (
            !loading && (
              <div>
                <div className="search-applied">
                  {(isFilterApplied || isSearchFilterApplied) && (
                    <div>
                      <span className="text-bold">0</span> results
                    </div>
                  )}
                </div>

                <div className="no-result">
                  <div className="no-result-img">
                    <img src={noResultImg} alt="No result" />
                  </div>

                  <div className="no-result-title">No results</div>

                  {(isFilterApplied || isSearchFilterApplied) && (
                    <div className="no-result-subtitle">
                      Try broadening your search.
                    </div>
                  )}
                </div>
              </div>
            )
          )}

          <div className="mt-3">
            <MyPaginator
              initialPage={0}
              totalCount={totalList}
              limitShow={selectedLimitToShow}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>

      <DrawerActiveStaffFilter
        isActive={!!openDrawers[drawersStaff.staffDrawerFilter]}
        closePanel={() => closeDrawer(drawersStaff.staffDrawerFilter)}
        onDone={onClickHandler}
      />

      <DrawerInactiveStaffFilter
        isActive={!!openDrawers[drawersStaff.InactiveStaffDrawerFilter]}
        closePanel={() => closeDrawer(drawersStaff.InactiveStaffDrawerFilter)}
      />

      <DrawerBannedStaffFilter
        isActive={!!openDrawers[drawersStaff.BannedStaffDrawerFilter]}
        closePanel={() => closeDrawer(drawersStaff.BannedStaffDrawerFilter)}
      />

      <DrawerDeletedStaffFilter
        isActive={!!openDrawers[drawersStaff.DeletedStaffDrawerFilter]}
        closePanel={() => closeDrawer(drawersStaff.DeletedStaffDrawerFilter)}
      />

      <DrawerStaffInactive
        isActive={!!openDrawers[drawersStaff.staffDrawerInactive]}
        closePanel={() => closeDrawer(drawersStaff.staffDrawerInactive)}
        selectedCustomers={selectedCustomers}
        fetchStaff={fetchStaff}
        setSelectedCustomers={setSelectedCustomers}
      />
         <DrawerStaffActive
        isActive={!!openDrawers[drawersStaff.staffDrawerActive]}
        closePanel={() => closeDrawer(drawersStaff.staffDrawerActive)}
        selectedCustomers={selectedCustomers}
        fetchStaff={fetchStaff}
        setSelectedCustomers={setSelectedCustomers}
      />
    </StaffContainer>
  );
};
