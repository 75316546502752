import React, { useContext, useEffect, useState } from "react";
import { DrawerDeleteCustomizationContainer } from "./style";
import Box from "components/Box";
import SingleText from "components/SingleText";
import { DrawerContainer } from "components/styledStyle";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export function DrawerDeleteCustomization({
  isActive,
  closePanel,
  deleteCustomization = () => {},
}) {
  return (
    <DrawerDeleteCustomizationContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper>
          <div className="drawer-bottom-content w-100">
            <Box className="question-container">
              <SingleText
                size={22}
                spacing="0.42px"
                align="center"
                marginBottom={10}
              >
                Are you sure ?
              </SingleText>

              <SingleText size={14} spacing="0.27px" lineHeight="21px">
                This customization will be deleted.
              </SingleText>
              <SingleText size={14} spacing="0.27px" lineHeight="21px">
                This cannot be undone.
              </SingleText>
            </Box>
            <div className="bottom-btns">
              <MyDrawerButton type="red" onClick={deleteCustomization}>
                Yes, delete customization
              </MyDrawerButton>
              <br />
              <MyDrawerButton type="gray" onClick={closePanel}>
                Keep
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerDeleteCustomizationContainer>
  );
}
