import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerFilterRestaurantsContainer = styled(MyDrawerRight)`
  overflow-y: unset !important;
  .drawer-status--options {
    margin-top: 40px;
    margin-bottom: 40px;
    .filter-statuses-wrapper {
      display: flex;
      // margin: 0 -5px;
      .item {
        // margin: 0 5px;
      }
    }
  }
  .top-title {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .bottom-btns {
    margin-top: 50px;
    .apply-restaurant-filter {
      margin-bottom: 10px;
    }
  }

  .filter-item {
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    .filter-item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .clear-filter-btn {
        color: #ed2626;
        background: transparent;
        border: none;
        font-size: 14px;
        font-weight: 700;
      }

      i {
        cursor: pointer;
        opacity: 0.4;
        font-size: 16px;
      }
    }

    .rc-slider-track {
      background-color: #ed2626 !important;
    }

    .rc-slider-handle {
      border: 5px solid #ed2626 !important;
    }

    .rc-slider-handle-dragging {
      box-shadow: 0 0 0 5px #ed2626;
    }
    .filter-item-body {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 45px;

      div {
        padding: 9px 45px 9px 0px;
        /* border-radius: 25px; */
        /* background-color: #27282a; */
        border-bottom: 1px solid #525558;
      }
    }
  }

  .clear-filter-btn {
    color: #ed2626;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 700;
  }

  .joined-date-wrapper {
    .join-date-label {
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fa-times {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #ed2626;
      }
    }
    .join-date-div {
      background: transparent;
      /* border-radius: 20px; */
      padding: 12px 12px 12px 0;
      display: flex;
      align-items: center;
      position: relative;
      bottom: 0;
      border-bottom: 1px solid #525558;
    }
    .join-date-input {
      background: transparent;
      color: #fff;
      border: unset;
      outline: unset;
      width: 100%;
      ::placeholder {
        opacity: 1;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.34px;
        color: #ed2626;
      }
    }
    margin-top: 10px;
    .date-text {
      .date-picker-label {
        font-weight: bold;
      }
    }
  }
  .banned-date {
    margin-top: 0px;
  }
`;

export const StatusItem = styled.div`
  height: 60px;
  width: 155px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.active {
    border: solid 1px #ed2626;
  }
`;

export const PopupCalendarContainer = styled.div`
  position: absolute;
  padding: 20px 10px;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  background-color: #27282a;
  min-width: 700px;
  z-index: 1;
  left: -27.5rem;
  top: 4rem;
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
  .apply-date {
    float: right;
    margin-right: 25px;
    width: 30%;
  }
`;
