import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { DrawerFilterOrdersContainer, Label } from "./style";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { OrdersContext } from "context/OrdersContext";
import { FaChevronLeft } from "react-icons/fa";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { ParamControl } from './ParamControl';

export default function DrawerEditItem({
  isActive = false,
  closePanel = () => {},
  onClickEditSave = () => {},
}) {
  const { selectedItem, orderDetailData, orderFoodInfo } = useContext(
    OrdersContext
  );
  const [customizationMenu, setCustomizationMenu] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [customizations, setCustomizations] = useState([]);
  const [customizationData, setCustomizationData] = useState([]);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [customizePrice, setCustomizePrice] = useState([]);
  const [finalCustomizations, setFinalCustomizations] = useState();
  const [disabled, setDisabled] = useState(true);
  const [price, setPrice] = useState(0);
  const [priceDifference, setPriceDifference] = useState(0);


useEffect(() => {
 setQuantity(selectedItem?.quantity) 
 setSpecialInstructions(selectedItem?.instructions)
},[selectedItem])


useEffect(() => {
  if (isActive) {
    customAxios.get(`/restaurant/${orderDetailData?.restaurant?.id}/food/${orderFoodInfo?.food_item_id}/customization`)
      .then((res) => {
        if (res.status === 200) {
          res && res.data && res.data.map((_el) => {
            if (_el.food_item_id === orderFoodInfo?.food_item_id) {
              setCustomizationMenu(res?.data)
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}, [isActive]);


  useEffect(() => {
    if (isActive && selectedItem) {
      let temp = [];
     selectedItem && selectedItem.customizations &&
  selectedItem.customizations.map((item) => {
     item.options.map((i) => {
       temp.push(i.id);
     });
     })
      setCustomizations(temp);
      setCustomizationData(selectedItem?.customizations ? selectedItem?.customizations : []);
    }
  }, [isActive, selectedItem]);



  const checkIsSelected = (id) => customizations.includes(id);

  const addCustomization = (foodItem, customization, option) => {
    if (!checkIsSelected(customization)) {
      setCustomizations([...customizations, customization]);
      let match = customizationData?.find((item) => item.id === foodItem.id);
      if (match) {
        setCustomizationData(
          customizationData.map((item) => {
            if (item.id === foodItem.id) {
              return {
                ...item,
                options: [...item.options, option],
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setCustomizationData([
          ...customizationData,
          {
            id: foodItem.id,
            name: foodItem.name,
            options: [option],
          },
        ]);
      }
    } else {
      setCustomizations(
        customizations.filter((item) => item !== customization)
      );

      let tempData = customizationData.map((item) => {
        if (item.id === foodItem.id) {
          return {
            ...item,
            options: item.options.filter(
              (i) => (i.id || i.customization_option_id) !== customization
            ),
          };
        } else {
          return item;
        }
      });
      setCustomizationData(tempData.filter((item) => item.options.length > 0));
    }
  };

  const onClickAddition = () => {
    setQuantity(quantity + 1);
  };

  const onClickMinus = () => {
    if (quantity > 1){
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    if (customizationData && customizationData.length > 0) {
      const temp = customizationData?.map((item) => {
        return {
          [item.id]: item?.options?.map(
            (i) => i?.id || i?.customization_option_id
          ),
        };
      });
      setFinalCustomizations(
        temp.reduce((obj, item) => Object.assign(obj, item), {})
      );

    } else {
      setFinalCustomizations({});
    }
  }, [customizationData]);

  let finalPrice = [];


  useEffect(() => {
if(selectedItem){
  console.log(selectedItem?.customizations?.map((item) => [...item?.options?.map((i) => i.price)].reduce((a, b) => Number(a) + Number(b), 0)))
  // console.log((+selectedItem.quantity * selectedItem?.customizations?.map((item) => item.options.map((i) => i.price))?.reduce((a, b) => Number(a) + Number(b), 0)))
    setPriceDifference(((selectedItem.price * selectedItem.quantity) + (+selectedItem.quantity * selectedItem?.customizations && selectedItem?.customizations?.map((item) => [...item.options.map((i) => i.price)].reduce((a, b) => Number(a) + Number(b), 0))?.reduce((a, b) => Number(a) + Number(b), 0)))?.toFixed(2));
}
  },[selectedItem])

  useEffect(() => {
    customizationMenu.map((item) => {
      item.options.map((_i) => {
        if (checkIsSelected(_i.customization_option_id)) {
          finalPrice.push(_i.price);
          setCustomizePrice(finalPrice);
          setPrice(((selectedItem.price * quantity) + (quantity * finalPrice
            .reduce((a, b) => Number(a) + Number(b), 0)))?.toFixed(2));
        } else if (!customizations.length) {
          setCustomizePrice([]);
          setPrice((selectedItem.price * quantity)?.toFixed(2));
        }
      });
    });
  }, [customizations, customizationMenu, quantity, selectedItem]);

  useEffect(() => {
    if (!customizationMenu?.length) {
      setDisabled(false);
    } else {
      let requiredMenu = customizationMenu.filter((item) => item.required);
      if (requiredMenu?.length && !customizationData?.length) {
        setDisabled(true);
      } else if (requiredMenu?.length && customizationData?.length) {
        let requiredCustomization = requiredMenu.filter((item) =>
          customizationData.some((i) => i.id === item.id)
        );
        if (requiredCustomization?.length) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(false);
      }
    }
  }, [customizationData, customizationMenu]);



  return (
    <DrawerFilterOrdersContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div
          className="top-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <FaChevronLeft
              size={20}
              className="cursor-pointer"
              onClick={closePanel}
            />
          </div>
          <div></div>
        </div>
        <div className="drawer-content" style={{ marginTop: "35px" }}>
          <div className="drawer-header">
            <Label size="14px" weight={500} color="#bbb">
              Edit Item
            </Label>
            <Label>{selectedItem?.name}</Label>
          </div>
          {/* test map */}
          {customizationMenu && customizationMenu?.length > 0 ? (
            <>
              {customizationMenu.map((item, index) => {
                return (
                  <ParamControl
                  key={index}
                  title={item.name}
                  options={item.options}
                  maxSelection={item.available_selection}
                  customizeMenu={customizationMenu}
                  selectedOptions={customizations}
                  onChange={(option) => {
                    addCustomization(item, option.customization_option_id, option);
                  }}
                  isOptional={!item.required}
                />
                );
              })}
            </>
          ) : (
            <></>
          )}

          <div className="special-instructions" style={{ marginTop: "51px" }}>
            <Label size="14px" weight="bold">
              Special Instructions
            </Label>
            <input
        type='text'
        placeholder='Write Special Instructions here...'
        style={{
          display: 'block',
          width: '100%',
          backgroundColor: 'transparent',
          border: 'unset',
          outline: 'unset',
          paddingLeft: 0,
          paddingTop: 5,
          paddingBottom: 10,
          color: '#fff',
        }}
        value={specialInstructions}
        onChange={(e) => {
          setSpecialInstructions(e.target.value);
        }}
      />
          </div>
        </div>

        <div className="bottom-btns">
          <div className="increment-decrement-div text-center d-flex align-items-center justify-content-center">
            <div
              style={{ marginRight: "35px" }}
              className="click-btn-div"
              onClick={() => {
                onClickMinus();
              }}
            >
              <i className="fas fa-minus" />
            </div>
            <div style={{ marginRight: "35px" }} className="item-count">
              {quantity}
            </div>
            <div
              className="click-btn-div"
              onClick={() => {
                onClickAddition();
              }}
            >
              <i className="fas fa-plus" />
            </div>
          </div>
       {price - priceDifference !== 0  &&   <Label
            size="13px"
            weight="500"
            marginBottom="18px"
            textAlign="center"
          >
           ${price - priceDifference > 0 ? price - priceDifference : -(price - priceDifference)} will be {price - priceDifference > 0 ? "Added to" : "Removed from"} the customer's bill.
          </Label>
}
          <MyDrawerButton
            type={!disabled ? "red" : "gray"}
            className="mt-auto"
            onClick={() => {
              let dObj = {
                quantity: quantity,
      customizations: customizations,
      instructions: specialInstructions,
              };
              onClickEditSave(dObj, (price - priceDifference));
            }}
            disabled={disabled}
          >
            Save
          </MyDrawerButton>
          <div className="cancel-btn" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerFilterOrdersContainer>
  );
}
