import styled from "@emotion/styled";

export const ProfileHistoryContainer = styled.div`
  .notes-history-header {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    min-height: 70px;
    /* padding: 0 36px; */
    padding: 0 27px;
  }
  .notes-history-body {
    flex: 1;
    .no-result {
      margin-top: 13rem;
      img {
        height: 180px;
      }
      h3 {
        margin-top: 20px;
      }
      i {
        width: 62px;
        height: 70px;
        opacity: 0.6;
        font-size: 70px;
        font-weight: 300;
        color: #fff;
        margin-bottom: 27px;
      }
    }
    .customers-history-titles {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      color: rgba(255, 255, 255, 0.3);
      .fa-circle {
        color: rgba(255, 255, 255, 0.3);
      }
      .fa-minus-circle {
        color: #fff;
      }
      .fa-check-circle {
        color: white;
      }
      .item-flex-small {
        flex: 0 0 8%;
        font-size: 14px;
      }
      .item-flex-normal {
        flex: 0 0 13%;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
      .item-flex-big {
        flex: 0 0 28%;
        font-size: 14px;
      }
      .item-flex-last {
        flex: 1 1 auto;
        font-size: 14px;
        text-align: end;
      }
      .left-padding-1 {
        padding-left: 15px;
      }
    }
  }
`;

export const ProfileHistoryListContainer = styled.div`
  padding: 18px 20px;
  // cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  &:nth-of-type(odd) {
    background-color: #171819;
  }
`;

export const Label = styled.div`
  flex: ${(props) => (props.flex ? props.flex : "")};
  font-size: ${(props) => (props.fointSize ? props.fointSize : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};
  position: relative;
  cursor: ${(props) => (props.cursor ? props.cursor : "default")};
`;
