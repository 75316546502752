import React from "react";
import PropTypes from "prop-types";
import { DrawerInputContainer } from "./style";

export default function DrawerInput({
  placeholder = "Ex: Caesar Salad",
  className = "",
  label = "",
  type = 'text',
  value = '',
  onChange = () => {},
  onClick = () => {},
}) {
  return (
    <DrawerInputContainer className={className}>
      {label && <h6 className='mb-0'>{label}</h6>}
      <div className="drawer-input-container">
        <input
          type={type}
          value={value || ''}
          placeholder={placeholder}
          onChange={onChange}
          onClick={onClick}
        />
      </div>
    </DrawerInputContainer>
  );
}

DrawerInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
};
