import React, { useContext } from "react";
// import { DefaultMenu } from 'assets/images/menu'
import PropTypes from "prop-types";
import NoAvatar from "assets/img/noAvatar.png";
// import { Draggable } from "react-beautiful-dnd";
import { RestaurantItemContainer } from "./style";
import moment from "moment";
import { RestaurantsContext } from "context/RestaurantsContext";

export default function RestaurantItem({
  data = {},
  isActive,
  customOnClick,
  status,
  searchInput,
}) {
  const { selectedRestaurants } = useContext(RestaurantsContext);

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const statusRenderer = (status) => {
    switch (status) {
      case 1:
        return (
          <span
            style={{
              color: "#1fc086",
              padding: "6px 8px",
              borderRadius: "10px",
              backgroundColor: "#27282a",
              marginLeft: "-11px",
            }}
          >
            Active
          </span>
        );
      case 0:
        return (
          <span
            style={{
              color: "#a6a6a6",
              padding: "6px 8px",
              borderRadius: "10px",
              backgroundColor: "#27282a",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              width: "max-content",
              marginLeft: "-14px",
            }}
          >
            {/* <i
              className="fas fa-eye-slash"
              style={{ fontSize: "12px", color: "#a6a6a6" }}
            />{" "} */}
            Inactive
          </span>
        );
      case 3:
        return (
          <span
            style={{
              padding: "6px 8px",
              borderRadius: "10px",
              backgroundColor: "#27282a",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: "#f7a64c",
              width: "max-content",
              marginLeft: "-18px",
            }}
          >
            {/* <i
              className="fas fa-spinner"
              style={{ fontSize: "12px", color: "#f7a64c" }}
            />{" "} */}
            Pending
          </span>
        );
      case 9:
        return (
          <span
            style={{
              padding: "6px 8px",
              borderRadius: "10px",
              backgroundColor: "#27282a",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: "#ed2626",
              width: "max-content",
              marginLeft: "-16px",
            }}
          >
            {/* <i
              className="fas fa-minus-circle"
              style={{ fontSize: "12px", color: "#ed2626" }}
            />{" "} */}
            Banned
          </span>
        );
      case -1:
        return (
          <span
            style={{
              padding: "6px 8px",
              borderRadius: "10px",
              backgroundColor: "#27282a",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: "#ed2626",
              width: "max-content",
              marginLeft: "-16px",
            }}
          >
            {/* <i
              className="fas fa-trash"
              style={{ fontSize: "12px", color: "#ed2626" }}
            />{" "} */}
            Deleted
          </span>
        );
      default:
        return (
          <span
            style={{
              color: "#a6a6a6",
              padding: "6px 8px",
              borderRadius: "10px",
              backgroundColor: "#27282a",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              width: "max-content",
              marginLeft: "-14px",
            }}
          >
            {/* <i
              className="fas fa-eye-slash"
              style={{ fontSize: "12px", color: "#a6a6a6" }}
            />{" "} */}
            Inactive
          </span>
        );
    }
  };

  return (
    <RestaurantItemContainer
      className={`restaurant-item ${isActive ? "selected-restaurant" : ""} ${
        data.status === 3 && !isActive ? "pending-restaurant-row" : ""
      }`}
      style={
        selectedRestaurants?.length
          ? { cursor: "default" }
          : { cursor: "pointer" }
      }
      onClick={customOnClick}
    >
      <div className="item-icon pr-4 cursor">
        <i
          className={`fas fa-check ${!isActive && "d-none"}`}
          data-txt="select"
        />
        <img
          src={data?.restaurant_image ? data.restaurant_image : NoAvatar}
          alt="avatar"
          data-txt={status === 1 || status === 0 ? "select" : ""}
        />
      </div>
      <div className="item-name item-flex-big">
        {getHighlightedText(data?.restaurant_name, searchInput, "#ed2626")}
      </div>
      <div
        className={`item-status item-flex-normal ${status === 3 &&
          "item-flex-big-big"}`}
      >
        {/* {data.status === 1 ? "Active" : "Inactive"} */}
        {statusRenderer(status)}
      </div>
      {status === 1 ? (
        <>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.joined_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-cuisine item-flex-big">{data?.cuisine}</div>
          <div className="item-sales item-flex-normal center">
            {`$ ${data.total_sales ? data.total_sales : "0"}`}
          </div>
          <div className="item-fees item-flex-normal center">
            {`$ ${data.total_fees ? data.total_fees : "0"}`}
          </div>
          <div className="item-staff item-flex-last">{data?.staff}</div>
        </>
      ) : status === 9 ? (
        <>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.banned_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.joined_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-name item-flex-small-big">
            {data?.admin_name}
          </div>
          <div className="item-sales item-flex-normal center">
            {`$ ${data.total_sales ? data.total_sales : "0"}`}
          </div>
          <div className="item-fees item-flex-normal center">
            {`$ ${data.total_fees ? data.total_fees : "0"}`}
          </div>
          <div className="item-staff item-flex-last">{data?.staff}</div>
        </>
      ) : status === 0 ? (
        <>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.inactive_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.joined_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-name item-flex-small-big">{data?.cuisine}</div>
          <div className="item-sales item-flex-normal center">
            {`$ ${data.total_sales ? data.total_sales : "0"}`}
          </div>
          <div className="item-fees item-flex-normal center">
            {`$ ${data.total_fees ? data.total_fees : "0"}`}
          </div>
          <div className="item-staff item-flex-last">{data?.staff}</div>
        </>
      ) : status === -1 ? (
        <>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.banned_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.joined_date).format("MMM DD, YYYY")}
          </div>
          <div className="item-name item-flex-small-big">
            {data?.deleted_by}
          </div>
          <div className="item-sales item-flex-normal center">
            {`$ ${data.total_sales ? data.total_sales : "0"}`}
          </div>
          <div className="item-fees item-flex-normal center">
            {`$ ${data.total_fees ? data.total_fees : "0"}`}
          </div>
          <div className="item-staff item-flex-last">{data?.staff}</div>
        </>
      ) : status === 3 ? (
        <>
          <div className="item-joined-date item-flex-normal">
            {moment(data?.req_date).format("MMM DD, YYYY")}
          </div>
          <div className=" item-flex-last item-flex-last-last">
            {data?.cuisine}
          </div>
        </>
      ) : null}
    </RestaurantItemContainer>
  );
}

RestaurantItem.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  searchInput: PropTypes.string,
};
