import styled from "@emotion/styled";
import { colors } from "theme";

export const TableListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 34px;

  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: rgb(27, 28, 29);
  }

  .table-striped > tbody > tr:nth-child(even) > td,
  .table-striped > tbody > tr:nth-child(even) > th {
    background-color: rgb(24, 25, 26);
  }
  /* 
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: transparent;
  } */

  th {
    color: #ffffff;
    opacity: 0.6;
  }

  tr {
    /* height: 65px; */
    td {
      vertical-align: middle;
    }
  }

  .active {
    font-size: 14px;
    font-weight: bold;
    color: #1fc086;
  }

  .inactive {
    font-size: 14px;
    font-weight: bold;
    color: rgb(96, 100, 108);
  }

  thead {
    color: rgba(255, 255, 255, 0.3);
    tr {
      th {
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
        border: transparent;
        padding: 10px 0;
        i {
          margin-left: 5px;
          font-weight: normal;
          opacity: 1;
        }
      }
      th:first-of-type {
        padding-left: 30px;
      }
      th:last-child {
        padding-right: 30px;
        text-align: end;
      }
    }
  }
  tbody {
    background-color: #1b1c1d;
    tr {
      td {
        padding: 20px 0;
        width: 12.5%;
        border: transparent;
        color: #fff;
        font-size: 14px;
        &.text-container {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }

        &.table-status {
          font-weight: bold;
        }
      }
      td:first-of-type {
        padding-left: 30px;
      }
      td:last-child {
        padding-right: 30px;
        text-align: end;
      }
    }
    tr:first-of-type {
      td:first-of-type {
        border-top-left-radius: 8px;
      }
      td:last-child {
        border-top-right-radius: 8px;
      }
    }
    tr:last-child {
      td:first-of-type {
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    tr:nth-of-type(odd) {
      td {
        background-color: #1b1c1d;
      }
    }
    tr:nth-of-type(even) {
      td {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
`;
