import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { memo, useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
// import { cuisines } from "static/staticData";
import { DrawerCuisineContainer } from "./style";

const DrawerCuisine = ({
  profile = {},
  isActive = false,
  closePanel = () => {},
  onSaveProfile = () => {},
}) => {
  const {
    selectedCuisines,
    setSelectedCuisines,
    postRestaurant,
    setPostRestaurant,
    restaurant,
    cuisineGlobalData,
  } = useContext(RestaurantsContext);

  const [cuisinesData, setCuisinesData] = useState([]);

  function toggleSelectedCuisines(id) {
    if (selectedCuisines.includes(id)) {
      setSelectedCuisines([...selectedCuisines.filter((_id) => _id !== id)]);
    } else if (selectedCuisines?.length < 3) {
      const uniqueChars = selectedCuisines.filter((c, index) => {
        return selectedCuisines.indexOf(c) === index;
      });
      setSelectedCuisines([...uniqueChars, id]);
    }
  }

  const findCuisinesName = (selectedCuisines, cuisineList) => {
    return selectedCuisines
      .map((title) => cuisineList.find(({ id }) => id === title))
      .filter((cuisine) => !!cuisine)
      .map(({ name }) => name);
  };

  useEffect(() => {
    if (isActive) {
      getCuisineData();
    }
  }, [cuisineGlobalData, isActive]);

  // function getCuisineData() {
  //   customAxios
  //     .get(`/cuisine`)
  //     .then((res) => {
  //       setCuisinesData(res?.data);
  //       // eslint-disable no-unused-expressions
  //       let data = [...selectedCuisines];
  //       res &&
  //         res.data.map((cuisine, index) => {
  //           cuisineGlobalData &&
  //             cuisineGlobalData.map((_item, i) => {
  //               if (cuisine.name === _item) {
  //                 data.push(cuisine.id);
  //                 setSelectedCuisines(data);
  //               }
  //             });
  //         });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  function getCuisineData() {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
  
        setCuisinesData(res?.data);
  
        const selected = res.data.filter(el => cuisineGlobalData.indexOf(el.name) > -1);
        const selectedCuisinesIds = selected.map(el => el.id);
        setSelectedCuisines(selectedCuisinesIds);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleClickDone() {
    setPostRestaurant({
      ...postRestaurant,
      cuisines: selectedCuisines,
      cuisinesName: findCuisinesName(selectedCuisines, cuisinesData),
    });
    closePanel();
  }

  return (
    <DrawerCuisineContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Cuisine</div>
          <div></div>
        </div>
        <div className="panel-status-container">
          <p className="select-message">Select up to 3</p>
          <div className="cuisine-container">
            {cuisinesData.length > 0 && cuisinesData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((_cuisine, _i) => {
                  return (
                    <div
                      className={`cuisine-item ${
                        selectedCuisines.includes(_cuisine?.id)
                          ? "selected"
                          : ""
                      }`}
                      style={
                        _cuisine?.image
                          ? {
                              backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url(${_cuisine?.image})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }
                          : {}
                      }
                      key={_i}
                      onClick={() => toggleSelectedCuisines(_cuisine?.id)}
                    >
                      <p className="internal-title">{_cuisine?.name}</p>
                    </div>
                  );
                })}
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            // onClick={onSaveProfile}
            onClick={handleClickDone}
          >
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerCuisineContainer>
  );
};

export default memo(DrawerCuisine);
