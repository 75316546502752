import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteWallContainer } from "./style";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerDeleteWall({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    closeDrawer,
    floors,
    fetchFloors,
    floorId,
    postWall,
    setPostWall,
  } = useContext(RestaurantsContext);
  const { id } = useParams();
  const currentFloor = floors.filter((item) => item.id === floorId)[0];

  const [wallRemovedNotification, setWallRemovedNotification] = useState();

  useEffect(() => {
    if (wallRemovedNotification) {
      setTimeout(() => setWallRemovedNotification(null), 2 * 1000);
    }
  }, [wallRemovedNotification]);

  const onRemove = async () => {
    if (postWall.x) {
      currentFloor.walls = currentFloor.walls.filter(
        (wall) => wall !== postWall
      );
      try {
        await restaurantServices.updateFloor(id, currentFloor);
        fetchFloors(id);
        closeDrawer([
          drawersMenu.editWall,
          drawersMenu.deleteWall,
          drawersMenu.deleteWallSide,
        ]);
        setPostWall({});
        setWallRemovedNotification({
          message: "Wall Removed.",
          type: "remove",
          table: ``,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      closeDrawer([
        drawersMenu.editWall,
        drawersMenu.deleteWall,
        drawersMenu.deleteWallSide,
      ]);
      setPostWall({});
    }
  };

  return (
    <DrawerDeleteWallContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      top={"30%"}
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      {wallRemovedNotification && (
        <MyMessageBoxItemNew
          message={
            wallRemovedNotification?.message
              ? wallRemovedNotification.message
              : ""
          }
          table={
            wallRemovedNotification?.table ? wallRemovedNotification.table : ""
          }
          type={
            wallRemovedNotification?.type
              ? wallRemovedNotification.type
              : "success"
          }
          onClick={() => setWallRemovedNotification(null)}
        />
      )}

      <DrawerContainer>
        <div className="remove-wall-wrapper">
          <h2
            className="drawer-title text-white text-center text-bold"
            style={{ marginTop: "20px" }}
          >
            Remove Wall
          </h2>
          <div className="top-icon">
            <i className="fad fa-question" />
          </div>
          <p
            className="text-white text-center mt-4"
            style={{ fontSize: "15px" }}
          >
            Are you sure you want to remove this wall?
          </p>
          <div className="bottom-btns">
            <MyDrawerButton type="red" onClick={onRemove}>
              Remove
            </MyDrawerButton>
            <MyDrawerButton
              type="gray"
              onClick={() => closeDrawer(drawersMenu.deleteWall)}
            >
              Keep
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteWallContainer>
  );
}
