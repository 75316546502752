import React, { useContext, useState, useEffect } from "react";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerTableContainer } from "./style";
import { drawersMenu } from "static/staticData";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import { FaTrash } from "react-icons/fa";
import { colors } from "theme";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyNumberIncrease from "components/Elements/MyNumberIncrease";
import Box from "components/Box";
import {
  decreaseSeatsCount,
  SEATS_RECTANGLE_MAX_LIMIT,
  validateSeatsAndShapeForRectangle,
  validateSeatsForCircleTable,
} from "constant";
import { restaurantServices } from "services";
import { useParams } from "react-router-dom";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const shapes = [
  { id: 1, name: "square" },
  { id: 0, name: "circle" },
];

export default function DrawerTable({
  type = "Add",
  isActive = false,
  closePanel = () => {},
  setIsDrawerElementOpen = () => {},
  getTables = () => {},
}) {
  const { id } = useParams();
  const {
    openDrawer,
    closeDrawer,
    floorId,
    floors,
    postTable,
    setPostTable,
    fetchFloors,
  } = useContext(RestaurantsContext);

  const [tableUpdateNotification, setTableUpdateNotification] = useState();
  const [tableAddNotification, setTableAddNotification] = useState();

  useEffect(() => {
    if (tableUpdateNotification) {
      setTimeout(() => setTableUpdateNotification(null), 2 * 1000);
    }
  }, [tableUpdateNotification]);

  useEffect(() => {
    if (tableAddNotification) {
      setTimeout(() => setTableAddNotification(null), 2 * 1000);
    }
  }, [tableAddNotification]);

  const onChangeShape = (shape) => {
    if (shape.id === 1 && postTable.seats > SEATS_RECTANGLE_MAX_LIMIT) {
      setPostTable({
        ...postTable,
        seats: SEATS_RECTANGLE_MAX_LIMIT,
        type: shape.id,
      });
    } else {
      setPostTable({ ...postTable, type: shape.id });
    }
  };

  const increaseSeats = () => {
    const updatedSeats = postTable.seats + 1;
    if (postTable.type === 0) {
      const seats = validateSeatsForCircleTable(updatedSeats);
      setPostTable({ ...postTable, seats: seats });
    } else {
      const [
        updatedRectSeats,
        updatedRectShape,
      ] = validateSeatsAndShapeForRectangle(updatedSeats);
      setPostTable({ ...postTable, seats: updatedRectSeats });
      // setPostTable({...postTable, seats: updatedRectSeats, type: updatedRectShape});
    }
  };

  const decreaseSeats = () => {
    const updatedSeats = postTable.seats - 1;
    const seats = decreaseSeatsCount(updatedSeats);
    setPostTable({ ...postTable, seats: seats });
  };

  const onDone = async () => {
    const currentFloor = floors.filter((item) => item.id === floorId)[0];
    if (type === "Add") {
      const updateFloor = {
        ...currentFloor,
        tables: [
          ...(currentFloor.tables || []),
          {
            ...postTable,
            x: 0,
            y: 0,
            rotation: 0,
          },
        ],
      };
      try {
        await restaurantServices.updateFloor(id, updateFloor);
        fetchFloors(id);
        closeDrawer(drawersMenu.addTable);
        setIsDrawerElementOpen(false);
        getTables();
        setTableAddNotification({
          message: "New table added.",
          type: "tables-update",
          table: ``,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      var index = currentFloor.tables.findIndex((x) => x.id === postTable.id);
      currentFloor.tables[index] = postTable;
      try {
        await restaurantServices.updateFloor(id, currentFloor);
        fetchFloors(id);
        closeDrawer(drawersMenu.editTable);
        setIsDrawerElementOpen(false);
        setTableUpdateNotification({
          message: "Tables Updated.",
          type: "tables-update",
          table: ``,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <DrawerTableContainer
      isActive={isActive}
      closePanel={closePanel}
      // renderRightIcon={type === 'Edit' ? <FaTrash size={18} color={colors.redColor} /> : null}
      onClickRightIcon={() => openDrawer(drawersMenu.deleteTable)}
    >
      {tableUpdateNotification && (
        <MyMessageBoxItemNew
          message={
            tableUpdateNotification?.message
              ? tableUpdateNotification.message
              : ""
          }
          table={
            tableUpdateNotification?.table ? tableUpdateNotification.table : ""
          }
          type={
            tableUpdateNotification?.type
              ? tableUpdateNotification.type
              : "success"
          }
          onClick={() => setTableUpdateNotification(null)}
        />
      )}

      {tableAddNotification && (
        <MyMessageBoxItemNew
          message={
            tableAddNotification?.message ? tableAddNotification.message : ""
          }
          table={tableAddNotification?.table ? tableAddNotification.table : ""}
          type={
            tableAddNotification?.type ? tableAddNotification.type : "success"
          }
          onClick={() => setTableAddNotification(null)}
        />
      )}
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">{type} Table</h2>
        <div className="table-shape-container">
          <p className="text-bold">Choose Table Shape</p>
          {/* <SingleText weight={600} spacing='-0.34px' marginBottom={29}>Choose table shape</SingleText> */}
          <div className="shape-container">
            {shapes.map((_shape, _i) => (
              <Box
                key={_i}
                className={`table-shape ${
                  postTable.type === _shape.id ? "active" : "inactive"
                }`}
                onClick={() => onChangeShape(_shape)}
                cursor="pointer"
                marginBottom={20}
              >
                <Box
                  width={50}
                  height={50}
                  background="#d8d9da"
                  borderRadius={_shape.name === "circle" ? "50%" : "0px"}
                />
              </Box>
            ))}
          </div>
        </div>
        <div className="table-information">
          <MyNumberIncrease
            label="Select seats"
            seats={postTable.seats}
            increaseSeats={increaseSeats}
            decreaseSeats={decreaseSeats}
            isUserDidNotSelectShape={postTable.type === undefined}
          />
          <ItemPicker
            label="Status"
            placeholder="Choose status"
            value={postTable?.status === 1 ? "Active" : "InActive"}
            onClick={() => openDrawer(drawersMenu.statusTable)}
            style={{}}
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={onDone}
          >
            {type} Table
          </MyDrawerButton>
          <MyDrawerButton
            className="text-bold"
            type="redColor"
            onClick={closePanel}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerTableContainer>
  );
}
