import React, { useState } from "react";
import PropTypes from "prop-types";
import SingleText from "components/SingleText";
import { AuthInputContainer } from "./style";

export default function AuthInput({
  placeholder = "",
  placeholderIcon,
  label = "",
  value = "",
  onChange = () => {},
  width = "350px",
  height = "50px",
  password = false,
  type = "",
  onKeyPress = () => {},
  error = false,
  onFocus = () => {},
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <AuthInputContainer display="flex" flexDirection="column" error={error}>
      {label && (
        <div className="d-flex justify-content-between">
          <SingleText size={15} weight={500} marginBottom={15}>
            {label}
          </SingleText>
          {error ? (
            <SingleText
              size={15}
              weight={500}
              marginBottom={15}
              color="#ed2626"
            >
              {label === "E-mail"
                ? "Enter correct email"
                : "Enter correct password"}
            </SingleText>
          ) : (
            <></>
          )}
        </div>
      )}
      <div
        className="auth-input-container"
        style={{ width: width, height: height }}
      >
        {!value && (
          <span className="input-placeholder">
            {placeholderIcon && (
              <span className="input-placeholder-icon">{placeholderIcon}</span>
            )}
            {<span className="input-placeholder-text">{placeholder}</span>}
          </span>
        )}
        <input
          placeholder={""}
          value={value}
          onChange={onChange}
          type={password && !showPassword ? "password" : type}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
        />
        {password && (
          <div
            className="password-toggle"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <i className="far fa-eye-slash cursor-pointer" />
            ) : (
              <i className="far fa-eye cursor-pointer" />
            )}
          </div>
        )}
      </div>
    </AuthInputContainer>
  );
}

AuthInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  customValue: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  type: PropTypes.string,
  password: PropTypes.bool,
  onChange: PropTypes.func,
};
