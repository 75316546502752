import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerAddAddressContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    .fa-plus {
      color: #ed2626;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .input-divs {
    .address-name-input {
      input::placeholder {
        font-weight: 500;
      }
    }
    .address-input {
      input::placeholder {
        color: ${colors.redColor};
        font-weight: 500;
      }
    }
  }

  .address-list-div {
    .address-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 5px;
      padding-bottom: 11px;
      .address-info {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .location-list {
    margin-bottom: 10px;
  }

  .address-box {
    background: #232425;
    height: 18rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
    border-radius: 20px;
    padding: 20px;
    margin-top: 5px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    ul {
      display: contents;
    }
  }

  .input-divs {
    label {
      display: contents;
    }
    .location-input-wrapper {
      display: flex;
      justify-content: center;
      align-items: baseline;
      .location-input {
        width: 100%;
        color: #fff;
        margin-top: -10px;
      }
      .close-icon {
        position: relative;
        right: 12px;
        align-items: center;
        display: flex;
        cursor: pointer;
      }
      input {
        &::placeholder {
          color: #ed2626;
          font-weight: 500;
        }
      }
    }
  }

  .bottom-btns {
    .cancel-btn {
      color: ${colors.redColor};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;
