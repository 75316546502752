/* eslint-disable no-restricted-imports */
import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import SingleText from "components/SingleText";
import { SingleButtonContainer } from "./style";
import { colors } from "theme";

export default function SingleButton({
  title = "",
  btnColor = colors.redColor,
  txtColor = "white",
  margin = "",
  icon,
  border = "none",
  padding = "0 21px",
  htmlType = "button",
  className = "",
  containerClassName = "",
  disabled = false,
  width = "100%",
  height = 50,
  shape = "rect",
  loading = false,
  onClick = () => {},
}) {
  return (
    <SingleButtonContainer
      className={containerClassName}
      whileHover={{ scaleX: 1.05 }}
      transition={{ duration: 0.2 }}
      width={width}
    >
      <Button
        className={`single-button ${className}`}
        type={htmlType}
        style={{
          background: disabled ? "rgba(255, 255, 255, 0.1)" : btnColor,
          borderRadius: shape === "rect" ? "7px" : "25px",
          padding,
          margin: margin,
          width: width,
          height: height,
          border,
        }}
        disabled={disabled}
        icon={icon}
        onClick={onClick}
      >
        {icon && icon}
        {title && (
          <SingleText
            align="center"
            marginLeft={icon ? 5 : 0}
            size={14}
            weight={600}
            color={txtColor}
          >
            {loading ? "Submitting..." : title}
          </SingleText>
        )}
      </Button>
    </SingleButtonContainer>
  );
}

SingleButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  txtColor: PropTypes.string,
  btnColor: PropTypes.string,
  htmlType: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  children: PropTypes.object,
  icon: PropTypes.object,
  border: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  margin: PropTypes.string,
  shape: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
