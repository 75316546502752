/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import query from "query-string";
import {
  RestaurantProfileMenu,
  RestaurantProfileOverall,
  RestaurantProfileStaff,
  RestaurantProfileTables,
} from "components/restaurantProfile";
import { drawersRestaurants } from "static/staticData";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { RestaurantProfilePageContainer } from "./style";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { restaurantServices } from "services";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import ApproveRestaurantModal from "./Modals/ApproveRestaurantModal";
import DeclineRestaurantModal from "./Modals/DeclineRestaurantModal";
import RestaurantEditMoreModal from "./Modals/RestaurantEditMoreModal";
import BanRestaurantModal from "./Modals/BanRestaurantModal";
import ApproveBanModal from "./Modals/ApproveBanModal";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { RestaurantPendingProfileMenu } from "components/restaurantProfile/RestaurantProfileMenu/RestaurantPendingProfileMenu";
import ApproveConfiramtionModal from "./Modals/ApproveConfiramtionModal";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import SetTaxesAndFeeModal from "./Modals/SetTaxesAndFeeModal";
import { drawersMenu, drawersOverall } from "static/staticData";
import BanErrorModal from "./Modals/BanErrorModal";
import DrawerDeleteRestaurant from "../RestaurantsPage/Drawers/DrawerDeleteRestaurant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { FaChevronLeft } from "react-icons/fa";
import { StaffProfilePage } from "../StaffProfilePage";
import AddItem from "components/restaurantProfile/RestaurantProfileMenu/Drawers/AddItemModal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    left: "250px",
  },
}));

const tabs = [
  { id: "overall", title: "Overall" },
  { id: "menu", title: "Menu" },
  { id: "tables", title: "Tables" },
  { id: "staff", title: "Staff" },
];

export function RestaurantProfilePage() {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const location = useLocation();

  const {
    setFoods,
    setMenus,
    restaurant,
    setRestaurant,
    menuId,
    setMenuId,
    restaurantDetailNotification,
    setRestaurantDetailNotification,
    openDrawers,
    closeDrawer,
    setPostRestaurant,
    banRestaurantModal,
    setBanRestaurantModal,
    isDeleteRestaurantModalOpen,
    setIsDeleteRestaurantModalOpen,
    setRestaurantEditMoreModal: setRestaurantEditMoreModalGlobal,
    staffViewData,
  } = useContext(RestaurantsContext);

  // console.log(staffViewData.id)

  const [approveRestaurantModal, setApproveRestaurantModal] = useState(false);
  const [declineRestaurantModal, setDeclineRestaurantModal] = useState(false);
  const [approveConfirmationModal, setApproveConfirmationModal] = useState(
    false
  );
  const [approveBanModal, setApproveBanModal] = useState(false);
  const [banErrorModal, setBanErrorModal] = useState(false);
  const [restaurantEditMoreModal, setRestaurantEditMoreModal] = useState(false);

  const [feastttFeeInput, setFeastttFeeInput] = useState("");
  const [tax, setTax] = useState("");
  const [banPublicNote, setBanPublicNote] = useState("");
  const [banInternalNote, setBanInternalNote] = useState("");

  const [taxAndFeesModal, setTaxAndFeesModal] = useState(false);

  const [
    approveRestaurantNotification,
    setApproveRestaurantNotification,
  ] = useState();

  const [
    restaurantDeclinedNotification,
    setRestaurantDeclinedNotification,
  ] = useState();

  const view = query.parse(history.location.search).view;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result?.status === 200) {
      setRestaurant(result?.data?.restaurant);
      setPostRestaurant(result?.data?.restaurant);
      setMenus(result?.data?.menus);
      let menuFirstData = result?.data?.menus[0];
      setMenuId(menuFirstData?.id);
      setFoods(result?.data?.foods);
      if (result?.data?.restaurant?.status === 3) {
        setFeastttFeeInput("");
        setTax("");
      } else {
        setFeastttFeeInput(result?.data?.restaurant?.feast_fees);
        setTax(result?.data?.restaurant?.tax);
      }
    }
  };

  useEffect(() => {
    getRestaurantProfile();
  }, [id]);

  useEffect(() => {
    if (restaurantDetailNotification) {
      setTimeout(() => setRestaurantDetailNotification(null), 2 * 1000);
    }
  }, [restaurantDetailNotification]);

  useEffect(() => {
    if (approveRestaurantNotification) {
      setTimeout(() => setApproveRestaurantNotification(null), 2 * 1000);
    }
  }, [approveRestaurantNotification]);

  useEffect(() => {
    if (restaurantDeclinedNotification) {
      setTimeout(() => setRestaurantDeclinedNotification(null), 2 * 1000);
    }
  }, [restaurantDeclinedNotification]);

  const onClickBanRestaurant = () => {
    if (restaurant.status === 9) {
      customAxios
        .delete(`/ban/${restaurant?.ban_id}?note=${banInternalNote}`)
        .then((res) => {
          getRestaurantProfile();
          setBanRestaurantModal(false);
          setApproveBanModal(false);
          setRestaurantDetailNotification({
            message: "Restaurant Unbanned",
            type: "active-success",
            table: restaurant?.title,
          });
          closeDrawer(drawersOverall.editProfile);
        })
        .catch((err) => {
          setBanRestaurantModal(false);
          setApproveBanModal(false);
          setBanErrorModal(true);
        });
    } else {
      customAxios
        .post(`/ban`, {
          target_id: restaurant?.id,
          target_type: 1,
          public_note: banPublicNote,
          note: banInternalNote,
        })
        .then((res) => {
          if (res?.status === 200) {
            setRestaurantDetailNotification({
              message: "Restaurant Banned",
              type: "ban-success",
              table: restaurant?.title,
            });
            getRestaurantProfile();
            setBanRestaurantModal(false);
            setApproveBanModal(false);
            closeDrawer(drawersOverall.editProfile);
          }
        })
        .catch((err) => {
          setBanRestaurantModal(false);
          setApproveBanModal(false);
          setBanErrorModal(true);
        });
    }
  };

  const onClickApproveRestaurant = () => {
    customAxios
      .patch(`/restaurant/${id}/publish-approval`, {
        publish: true,
        note: "",
        tax: Number(tax),
        feast_fees: Number(feastttFeeInput),
      })
      .then((res) => {
        if (res?.status === 200) {
          getRestaurantProfile();
          setApproveConfirmationModal(false);
          setApproveRestaurantNotification({
            message: "Restaurant Approved.",
            type: "resend-email",
            table: `${restaurant?.title}`,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  const onClickDeclineRestaurant = (note, noteToRestaurant) => {
    customAxios
      .patch(`/restaurant/${id}/publish-approval`, {
        publish: false,
        note: note,
        note_to_restaurant: noteToRestaurant,
      })
      .then((res) => {
        if (res?.status === 200) {
          setDeclineRestaurantModal(!declineRestaurantModal);
          history.push(`/restaurants`);
          setRestaurantDeclinedNotification({
            message: "Restaurant Declined.",
            type: "restaurant-declined",
            table: `${restaurant?.title}`,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  const setTaxAndFeeHandler = () => {
    let updateDataConfig = {
      tax: Number(tax),
      feast_fees: Number(feastttFeeInput),
    };
    customAxios
      .patch(`/restaurant/${restaurant?.id}/settings`, updateDataConfig)
      .then((res) => {
        if (res.status === 200) {
          setRestaurantDetailNotification({
            // message: "Success!",
            // type: "coins-success",
            // table: `Feasttt Fee rate and Tax set`,
            message: "Taxes & Fees Updated.",
            type: "coins-success",
            table: ``,
          });
          setTaxAndFeesModal(!taxAndFeesModal);
          getRestaurantProfile();
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {!restaurant || (restaurant && restaurant?.status === undefined) ? (
        <Backdrop
          className={classes.backdrop}
          open={restaurant?.status === undefined ? true : false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <RestaurantProfilePageContainer
          style={{
            height:
              !!openDrawers[drawersMenu.itemAdd] ||
              !!openDrawers[drawersMenu.itemEdit] ||
              !!openDrawers[drawersOverall.editProfile] ||
              !!openDrawers[drawersOverall.statusOverall] ||
              !!openDrawers[drawersOverall.notes]
                ? "100vh"
                : "-webkit-fill-available",
            overflowY:
              !!openDrawers[drawersMenu.itemAdd] ||
              !!openDrawers[drawersMenu.itemEdit] ||
              !!openDrawers[drawersOverall.editProfile] ||
              !!openDrawers[drawersOverall.statusOverall] ||
              !!openDrawers[drawersOverall.notes]
                ? "hidden"
                : "auto",
          }}
        >
          {restaurantDetailNotification && (
            <MyMessageBoxItemNew
              message={
                restaurantDetailNotification?.message
                  ? restaurantDetailNotification.message
                  : ""
              }
              table={
                restaurantDetailNotification?.table
                  ? restaurantDetailNotification.table
                  : ""
              }
              type={
                restaurantDetailNotification?.type
                  ? restaurantDetailNotification.type
                  : "success"
              }
              onClick={() => setRestaurantDetailNotification(null)}
            />
          )}

          {approveRestaurantNotification && (
            <MyMessageBoxItemNew
              message={
                approveRestaurantNotification?.message
                  ? approveRestaurantNotification.message
                  : ""
              }
              table={
                approveRestaurantNotification?.table
                  ? approveRestaurantNotification.table
                  : ""
              }
              type={
                approveRestaurantNotification?.type
                  ? approveRestaurantNotification.type
                  : ""
              }
              onClick={() => setApproveRestaurantNotification(null)}
            />
          )}

          {restaurantDeclinedNotification && (
            <MyMessageBoxItemNew
              message={
                restaurantDeclinedNotification?.message
                  ? restaurantDeclinedNotification.message
                  : ""
              }
              table={
                restaurantDeclinedNotification?.table
                  ? restaurantDeclinedNotification.table
                  : ""
              }
              type={
                restaurantDeclinedNotification?.type
                  ? restaurantDeclinedNotification.type
                  : ""
              }
              onClick={() => setRestaurantDeclinedNotification(null)}
            />
          )}

          <div className="restaurant-profile-page-header">
            {restaurant?.status === 3 && view !== "staff" ? (
              <div className="left-part">
                <div>
                  <FaChevronLeft
                    size={17}
                    className="cursor-pointer mr-4"
                    onClick={() => history.push("/restaurants")}
                  />
                </div>
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  New Restaurant Request
                </div>
              </div>
            ) : (
              <>
                {view !== "overall" ? (
                  <>
                    <div className="left-part">
                      {window.location.pathname ===
                      `/restaurant-detail/${id}` ? (
                        // <div className="nav-line-to-back">
                        //   <Link to="/restaurants" className="backlink-to-rest">
                        //     Restaurants
                        //   </Link>
                        //   <span className="arrow-icon-container mx-3">
                        //     <i className="fas fa-angle-right" />
                        //   </span>
                        //   <span className="current-restaurant">
                        //     {restaurant.title}
                        //   </span>
                        // </div>
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          <span
                            className="arrow-icon-container mx-3"
                            onClick={() => history.push("/restaurants")}
                          >
                            <i
                              className="fas fa-angle-left"
                              style={{ fontSize: "22px", cursor: "pointer" }}
                            />
                          </span>{" "}
                          {location.search ===
                          `?view=staff&mode=all&staff-profile/${staffViewData?.id}`
                            ? " Restaurant Staff Profile"
                            : " Restaurant Profile"}
                        </div>
                      ) : (
                        <div className="restaurant-title">
                          <i className="fas fa-angle-left" /> Restaurants
                          Profile
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="left-part">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        <span
                          className="arrow-icon-container mx-3"
                          onClick={() => history.push("/restaurants")}
                        >
                          <i
                            className="fas fa-angle-left"
                            style={{ fontSize: "22px", cursor: "pointer" }}
                          />
                        </span>{" "}
                        Restaurant Profile
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="right-part">
              {tabs.map((_tab, _i) => {
                if (_tab.title === "Staff" && restaurant?.status === 3) {
                  return null;
                } else {
                  return (
                    <div
                      key={_i}
                      className={`menu-item ${
                        view === _tab.id ? "active-item" : ""
                      }`}
                    >
                      <Link
                        to={`/restaurant-detail/${id}?view=${_tab.id}`}
                        className="text-gray"
                      >
                        {_tab.title}
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          {restaurant?.status !== 3 && view === "overall" ? (
            <div className="breadcrumb-part">
              {window.location.pathname === `/restaurant-detail/${id}` ? (
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="nav-line-to-back">
                    <Link to="/restaurants" className="backlink-to-rest">
                      Restaurants
                    </Link>
                    <span className="arrow-icon-container mx-3">
                      <i className="fas fa-angle-right" />
                    </span>
                    <span className="current-restaurant">
                      {restaurant.title}
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ marginRight: "5rem", gap: "10px" }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                        className="opacity6"
                      >
                        Feasttt Fee
                      </span>
                      <span>
                        &nbsp;{" "}
                        {restaurant?.feast_fees ? restaurant?.feast_fees : 0}%
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                        className="opacity6"
                      >
                        Tax Rate
                      </span>
                      <span>
                        &nbsp; {restaurant?.tax ? restaurant?.tax : 0}%
                      </span>
                    </div>
                    <div className="cursor-pointer">
                      <i
                        className="fas fa-cog opacity6"
                        style={{
                          fontSize: "20px",
                        }}
                        onClick={() => {
                          setTaxAndFeesModal(!taxAndFeesModal);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="restaurant-title">Restaurants Profile</div>
              )}
            </div>
          ) : (
            <></>
          )}

          {location.search ===
          `?view=staff&mode=all&staff-profile/${staffViewData?.id}` ? (
            <StaffProfilePage />
          ) : (
            <div className="restaurant-profile-page-body">
              {view === "overall" && (
                <RestaurantProfileOverall
                  id={id}
                  setRestaurantEditMoreModal={setRestaurantEditMoreModal}
                />
              )}

              {restaurant?.status !== 3 ? (
                <>{view === "menu" && <RestaurantProfileMenu id={id} />}</>
              ) : (
                <>
                  {view === "menu" && <RestaurantPendingProfileMenu id={id} />}
                </>
              )}

              {view === "tables" && <RestaurantProfileTables id={id} />}

              {restaurant?.status !== 3 ? (
                <>{view === "staff" && <RestaurantProfileStaff id={id} />}</>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* <div className="restaurant-profile-page-body">
            {view === "overall" && (
              <RestaurantProfileOverall
                id={id}
                setRestaurantEditMoreModal={setRestaurantEditMoreModal}
              />
            )}

            {restaurant?.status !== 3 ? (
              <>{view === "menu" && <RestaurantProfileMenu id={id} />}</>
            ) : (
              <>{view === "menu" && <RestaurantPendingProfileMenu id={id} />}</>
            )}

            {view === "tables" && <RestaurantProfileTables id={id} />}

            {restaurant?.status !== 3 ? (
              <>{view === "staff" && <RestaurantProfileStaff id={id} />}</>
            ) : (
              <></>
            )}
          </div> */}

          {restaurant?.status == 3 && (
            <div className="pending-restaurant-decline-approove d-flex align-items-center justify-content-between">
              <div className="pending-resturant-name">
                <span className="span-one">Request from </span>
                <span className="span-two">{restaurant?.title}</span>
              </div>
              <div className="d-flex pending-restuarant-actions">
                <MyDrawerButton
                  type="redColor"
                  onClick={() => {
                    setBanRestaurantModal(!banRestaurantModal);
                  }}
                >
                  <b>Ban</b>
                </MyDrawerButton>
                <MyDrawerButton
                  type="redColor"
                  onClick={() => {
                    setDeclineRestaurantModal(!declineRestaurantModal);
                  }}
                >
                  <b>Decline</b>
                </MyDrawerButton>
                <MyDrawerButton
                  type="red"
                  onClick={() => {
                    setApproveRestaurantModal(!approveRestaurantModal);
                  }}
                >
                  <b>Approve Restaurant</b>
                </MyDrawerButton>
              </div>
            </div>
          )}
          <RestaurantEditMoreModal
            isActive={restaurantEditMoreModal}
            setBanRestaurantModal={setBanRestaurantModal}
            closePanel={() => {
              setRestaurantEditMoreModal(false);
            }}
            status={restaurant?.status}
          />
          <BanRestaurantModal
            isActive={banRestaurantModal}
            closePanel={() => {
              setBanRestaurantModal(!banRestaurantModal);
              setBanInternalNote("");
              setBanPublicNote("");
            }}
            restaurant={restaurant}
            onDone={() => {
              setApproveBanModal(true);
              setBanRestaurantModal(!banRestaurantModal);
            }}
            banPublicNote={banPublicNote}
            setBanPublicNote={setBanPublicNote}
            banInternalNote={banInternalNote}
            setBanInternalNote={setBanInternalNote}
          />
          <ApproveBanModal
            isActive={approveBanModal}
            closePanel={() => {
              setApproveBanModal(!approveBanModal);
              setBanRestaurantModal(!banRestaurantModal);
            }}
            restaurant={restaurant}
            onDone={() => {
              onClickBanRestaurant();
              setRestaurantEditMoreModalGlobal(false);
              setRestaurantEditMoreModal(false);
            }}
          />
          <BanErrorModal
            isActive={banErrorModal}
            closePanel={() => {
              setBanErrorModal(false);
              setRestaurantEditMoreModalGlobal(false);
              setRestaurantEditMoreModal(false);
            }}
          />

          <ApproveRestaurantModal
            isActive={approveRestaurantModal}
            closePanel={() => {
              setApproveRestaurantModal(!approveRestaurantModal);
              setFeastttFeeInput("");
              setTax("");
            }}
            restaurant={restaurant}
            onDone={() => {
              setApproveConfirmationModal(true);
              setApproveRestaurantModal(!approveRestaurantModal);
            }}
            feastttFeeInput={feastttFeeInput}
            setFeastttFeeInput={setFeastttFeeInput}
            tax={tax}
            setTax={setTax}
          />
          <ApproveConfiramtionModal
            isActive={approveConfirmationModal}
            closePanel={() => {
              setApproveConfirmationModal(!approveConfirmationModal);
              setApproveRestaurantModal(!approveRestaurantModal);
            }}
            restaurant={restaurant}
            onDone={() => {
              onClickApproveRestaurant();
            }}
          />
          <DeclineRestaurantModal
            isActive={declineRestaurantModal}
            closePanel={() => {
              setDeclineRestaurantModal(!declineRestaurantModal);
            }}
            onDecline={onClickDeclineRestaurant}
          />
          <SetTaxesAndFeeModal
            isActive={taxAndFeesModal}
            closePanel={() => {
              setTaxAndFeesModal(!taxAndFeesModal);
            }}
            restaurant={restaurant}
            onDone={() => {
              setTaxAndFeeHandler();
            }}
            feastttFeeInput={feastttFeeInput}
            setFeastttFeeInput={setFeastttFeeInput}
            tax={tax}
            setTax={setTax}
          />
          <DrawerDeleteRestaurant
            isActive={isDeleteRestaurantModalOpen}
            closePanel={() => setIsDeleteRestaurantModalOpen(false)}
            restaurant={restaurant}
          />
          <MyDrawerRight>test12</MyDrawerRight>
        </RestaurantProfilePageContainer>
      )}
    </>
  );
}
