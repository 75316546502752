import { Avatar, Divider } from "@material-ui/core";
import MyRating from "components/Elements/MyRating";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { FaEllipsisH, FaTrash } from "react-icons/fa";
import { drawersOrder } from "static/staticData";
import { StyledIconWrapper, StyledPopupContainer } from "theme/styles";
import { DrawerReviewServerContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { mocks } from "static/staticData";
import { DrawerNeutritions } from "app/pages/OrderDetailPage/Drawers/DrawerNeutritions";
import Nutrition from "app/pages/DashboardPage/Drawers/Nutrition";
import DrawerFoodCustomization from "./DrawerFoodCustomization";
import DrawerFoodItemReviews from "./DrawerFoodItemReviews";

export default function DrawerShowItem({
  isActive = false,
  closePanel = () => {},
  foodDetails = {},
  onClickEdit = () => {},
}) {
  const { openDrawer } = useContext(OrdersContext);
  const [isNeutritionsDrawerOpen, setIsNeutritionsDrawerOpen] = useState(false);
  const [isCustomizationDrawerOpen, setIsCustomizationDrawerOpen] = useState(
    false
  );
  const [isFoodReviewDrawerOpen, setIsFoodReviewDrawerOpen] = useState(false);
  const [nutritionsData, setNutritionsData] = useState({});

  // useEffect(() => {}, [foodDetails]);
  // console.log("foodDetails", foodDetails);

  const dietaryTagIconRenderer = (tagId) => {
    switch (tagId) {
      case 0:
        return <i className="fas fa-leaf-heart" />;
      case 1:
        return <i className="fas fa-leaf" />;
      case 2:
        return <i className="fas fa-wheat" />;
      case 3:
        return <i className="fas fa-cow" />;
      case 4:
        return <i className="fas fa-candy-cane" />;
    }
  };

  useEffect(() => {
    if (foodDetails) {
      setNutritionsData(JSON.parse(foodDetails?.nutrition));
    }
  }, [foodDetails]);

  return (
    <DrawerReviewServerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <div className="header-bg">
          <FaTimes
            size={20}
            style={{ zIndex: "9999999" }}
            className="cursor-pointer close-drawer-icon"
            onClick={closePanel}
          />
          <Image
            src={foodDetails?.photo?.file || defaultFood}
            className="bg-img"
          />
          <div className="drawer-header-content">
            <img src={foodDetails?.photo?.file || defaultFood} />
          </div>
          <FaPencilAlt
            size={20}
            style={{ zIndex: "9999999" }}
            className="cursor-pointer edit-drawer-icon"
            onClick={() => {
              onClickEdit();
            }}
          />
        </div>
        <div className="reviews-body">
          <div className="food-name">{foodDetails?.title}</div>
          <h5
            className="food-description opacity6 text-center"
            style={{ marginTop: "14px" }}
          >
            {foodDetails?.description}
          </h5>
          <div className="food-tags">
            {foodDetails?.dietary ? (
              foodDetails?.dietary?.map((_tagId, _i) => (
                <div key={_i} className="food-tags-item">
                  {dietaryTagIconRenderer(_tagId)} &nbsp;
                  {mocks.dietaryTags[_tagId].title}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div className="diet-info">
            <div className="diet-info-item diet-info-border-right">
              <div className="diet-count">{nutritionsData?.calories || 0}</div>
              <div className="diet-type">Calories</div>
            </div>
            <div className="diet-info-item diet-info-border-right">
              <div className="diet-count">{nutritionsData?.protein || 0}</div>
              <div className="diet-type">Protein</div>
            </div>
            <div className="diet-info-item">
              <div className="diet-count">
                {nutritionsData?.totalCarbohydrate || 0}
              </div>
              <div className="diet-type">Carbs</div>
            </div>
          </div>
          <div
            className="neutrition-div cursor-pointer"
            onClick={() => setIsNeutritionsDrawerOpen(!isNeutritionsDrawerOpen)}
          >
            <i className="far fa-info-circle" />
            Nutrition
          </div>
          <div
            className="rating-customization-card cursor-pointer"
            style={{ marginTop: "52px" }}
            onClick={() => {
              // if (foodDetails?.reviews_count !== 0) {
              //   closePanel();
              //   setIsFoodReviewDrawerOpen(!isFoodReviewDrawerOpen);
              // } else {
              // }
              closePanel();
              setIsFoodReviewDrawerOpen(!isFoodReviewDrawerOpen);
            }}
          >
            <div className="card-div-one">
              <i className="fas fa-star" /> {foodDetails?.rating || 0}
            </div>
            <div className="card-div-two d-flex align-items-center">
              {foodDetails?.reviews_count || 0} reviews
              <i className="fas fa-chevron-right" />
            </div>
          </div>
          {/* <div
            className="rating-customization-card cursor-pointer"
            style={{ marginTop: "15px" }}
            onClick={() => {
              if (foodDetails?.customizations !== 0) {
                closePanel();
                setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen);
              } else {
              }
            }}
          >
            <div className="card-div-one">Customize</div>
            <div className="card-div-two d-flex align-items-center">
              {foodDetails?.customizations || 0} options
              <i className="fas fa-chevron-right" />
            </div>
          </div> */}
        </div>
      </DrawerContainer>
      {/* <DrawerNeutritions
        isActive={isNeutritionsDrawerOpen}
        closePanel={() => {
          setIsNeutritionsDrawerOpen(!isNeutritionsDrawerOpen);
        }}
        foodDetails={foodDetails}
      /> */}
      <Nutrition
        isActive={isNeutritionsDrawerOpen}
        closePanel={() => {
          setIsNeutritionsDrawerOpen(!isNeutritionsDrawerOpen);
        }}
        nutritionsData={nutritionsData}
      />
      <DrawerFoodCustomization
        isActive={isCustomizationDrawerOpen}
        closePanel={() => {
          closePanel();
          setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen);
        }}
        foodDetails={foodDetails}
      />
      <DrawerFoodItemReviews
        isActive={isFoodReviewDrawerOpen}
        closePanel={() => {
          closePanel();
          setIsFoodReviewDrawerOpen(!isFoodReviewDrawerOpen);
        }}
        foodDetails={foodDetails}
      />
    </DrawerReviewServerContainer>
  );
}
