import React from "react";
import { MyMessageBoxItemContainer } from "./style";
import tableUpdate from "assets/img/table.png";
import floorUpdate from "assets/img/right-icon.png";
import wallAdd from "assets/img/wall-icon.png";

const MyMessageBoxItemNew = ({ message, table, onClick, type }) => {
  return (
    <MyMessageBoxItemContainer>
      <div className="my-message-box-custom" style={{}}>
        <div
          className="message-box-custom-inner"
          style={{
            background: "#27282a",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: "12px",
              width: "58px",
              height: "58px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background:
                type === "remove"
                  ? "#ed2626"
                  : type === "no-payment"
                  ? "#f8a94e"
                  : type === "payment"
                  ? "#1fc086"
                  : type === "cash"
                  ? "#1fc086"
                  : type === "declined"
                  ? "#ed2626"
                  : type === "error"
                  ? "#ed2626"
                  : type === "coins-success"
                  ? "#1fc086"
                  : type === "coins-remove"
                  ? "#ed2626"
                  : type === "ban-success"
                  ? "#ed2626"
                  : type === "active-success"
                  ? "#1fc085"
                  : type === "inactive-success"
                  ? "#767b86"
                  : type === "promo-active"
                  ? "#1fc085"
                  : type === "promo-inactive"
                  ? "#ed2626"
                  : type === "cuisine-active"
                  ? "#1fc085"
                  : type === "cuisine-inactive"
                  ? "#ed2626"
                  : type === "resend-email"
                  ? "#1FC086"
                  : type === "restaurant-declined"
                  ? "#ed2626"
                  : type === "restore-review"
                  ? "#1FC086"
                  : type === "restaurant-update" ? 
                  "#1FC086"
                  : "#1fc086",
              borderRadius: "50%",
            }}
          >
            {type === "remove" ? (
              <i className="fas fa-trash-alt" style={{ color: "#fff" }} />
            ) : type === "no-payment" ? (
              <i
                className="far fa-money-bill-wave-alt"
                style={{ color: "#fff" }}
              />
            ) : type === "payment" ? (
              <i
                className="far fa-money-bill-wave-alt"
                style={{ color: "#fff" }}
              />
            ) : type === "declined" ? (
              <i
                className="far fa-money-bill-wave-alt"
                style={{ color: "#fff" }}
              />
            ) : type === "cash" ? (
              <i
                className="far fa-money-bill-wave-alt"
                style={{ color: "#fff" }}
              />
            ) : type === "error" ? (
              <i className="fas fa-exclamation" style={{ color: "#fff" }} />
            ) : type === "coins-success" ? (
              <i className="fas fa-coins" style={{ color: "#fff" }} />
            ) : type === "coins-remove" ? (
              <i className="fas fa-coins" style={{ color: "#fff" }} />
            ) : type === "ban-success" ? (
              <i
                className="fas fa-ban"
                style={{ color: "#fff", fontSize: "28px" }}
              />
            ) : type === "active-success" ? (
              <i
                className="fas fa-eye"
                style={{ color: "#fff", fontSize: "28px" }}
              />
            ) : type === "inactive-success" ? (
              <i
                className="fas fa-eye-slash"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "promo-active" ? (
              <i
                className="fas fa-eye"
                style={{ color: "#fff", fontSize: "25px" }}
              />
            ) : type === "promo-inactive" ? (
              <i
                className="fas fa-eye-slash"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "cuisine-active" ? (
              <i
                className="fas fa-utensils"
                style={{ color: "#fff", fontSize: "28px" }}
              />
            ) : type === "cuisine-inactive" ? (
              <i
                className="fas fa-trash-alt"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "restore-review" ? (
              <i
                className="fas fa-trash-undo-alt"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "resend-email" ? (
              <i
                className="fas fa-badge-check"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "changed-role-to-kitchen" ? (
              <i
                className="fas fa-users"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "tables-update" ? (
              <img
                src={tableUpdate}
                alt=""
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "floor-update" ? (
              <img
                src={floorUpdate}
                alt=""
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "restaurant-declined" ? (
              <i
                className="fas fa-times"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) :
            type === 'restaurant-update' ? (
              <i
              className='fas fa-store-alt'
              style={{ color: '#fff', fontSize: '25px' }}
            />
            )          
            : type === "added-new-item" ? (
              <i
                className="fas fa-utensils-alt"
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : type === "wall-add" ? (
              <img
                src={wallAdd}
                alt=""
                style={{ color: "#fff", fontSize: "20px" }}
              />
            ) : (
              <i className="fas fa-check" style={{ color: "#fff" }} />
            )}
          </div>
          <div style={{ marginTop: "5px" }}>
            <h5
              style={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}
              dangerouslySetInnerHTML={{ __html: message }}
            >
              {/* <span style={{ fontWeight: 'bold', color: '#fff' }}>
                3 items{' '}
              </span>
              for
              <span style={{ fontWeight: 'bold', color: '#fff' }}>
                $26.97
              </span>{' '}
              have been successfully added to the order. */}
            </h5>
            <h6
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: "#7d7e7f",
              }}
              dangerouslySetInnerHTML={{ __html: table }}
            >
              {/* {table} */}
              {/* Table 4 •{' '}
              <span style={{ fontWeight: 'bold', color: '#ed2626' }}>
                #HFSYJD-1
              </span> */}
            </h6>
          </div>
          <button
            style={{
              marginLeft: "30px",
              // width: "11px",
              height: "24px",
              background: "transparent",
              border: "none",
              color: "#fff",
              opacity: "0.5",
              alignSelf: "self-start",
              fontWeight: "600",
              fontSize: "16px",
              marginTop: "3px",
            }}
            onClick={onClick}
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </div>
    </MyMessageBoxItemContainer>
  );
};

export default MyMessageBoxItemNew;
