import React from "react";
import PropTypes from "prop-types";
import { DrawerPasswordContainer } from "./style";

export default function DrawerPassword({
  placeholder = "Ex: Caesar Salad",
  type = 'password',
  className = "",
  label = "",
  value = "",
  autoComplete = "new-password",
  onChange = () => {},
}) {
  return (
    <DrawerPasswordContainer className={className}>
      {label && <h6 className='mb-0'>{label}</h6>}
      <span className="drawer-input-container">
        <input
          style={{ paddingLeft: 0 }}
          type={type}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={onChange}
        />
      </span>
    </DrawerPasswordContainer>
  );
}

DrawerPassword.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
};
