import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaBars, FaPen, FaTrash } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { colors } from "theme";
import { DrawerEditCustomizationsOptionContainer } from "./style";
import { Switch } from "@material-ui/core";

export default function DrawerEditCustomizationsOption({
  menus = [],
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer, editOption, setEditOption, editOptionInfo } = useContext(
    RestaurantsContext
  );

  const [editOptionName, setEditOptionName] = useState();
  const [editOptionPrice, setEditOptionPrice] = useState(0);
  const [freeOptionToggle, setFreeOptionToggle] = useState(false);

  useEffect(() => {
    setEditOptionName(editOptionInfo?.name);
    setEditOptionPrice(editOptionInfo?.price);
    if (editOptionInfo?.price === 0) {
      setFreeOptionToggle(true);
    } else {
      setFreeOptionToggle(false);
    }
  }, [editOptionInfo]);

  // console.log(editOptionInfo);

  const freeOptionHandleChange = (event) => {
    setFreeOptionToggle(event.target.checked);
  };

  function onClickEditOption() {
    const optionsArr = [...editOption];
    let optionData = {
      name: editOptionName,
      price: editOptionPrice,
    };
    optionsArr.push(optionData);
    setEditOption(optionsArr);

    setEditOptionName("");
    setEditOptionPrice("");
    closePanel();
  }

  return (
    <DrawerEditCustomizationsOptionContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
      onClickRightIcon={() => openDrawer(drawersMenu.deleteMenu)}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">Edit Option</h2>
        <div className="add-cutomization-option-input-area">
          <div className="mb-4">
            <div>Name</div>
            <input
              type="text"
              placeholder="Ex: Caesar Salad"
              className="option-input"
              value={editOptionName || ""}
              onChange={(e) => setEditOptionName(e.target.value)}
            />
            <div className="input-bottom-line" />
          </div>

          <div className="selection-required">
            <div className="input-bottom-line" />
            <div className="required">
              <div>Free Option</div>
              <div>
                <Switch
                  checked={freeOptionToggle}
                  onChange={freeOptionHandleChange}
                  // inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className="input-bottom-line" />
          </div>

          {freeOptionToggle === true ? null : (
            <div className="mb-4">
              <div className="mt-3">Additional Price</div>
              <input
                type="text"
                placeholder="+$ 0.00"
                className="option-input"
                value={editOptionPrice || ""}
                onChange={(e) => setEditOptionPrice(e.target.value)}
              />
              <div className="input-bottom-line" />
            </div>
          )}
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={onClickEditOption}
          >
            Add Option
          </MyDrawerButton>
          <div
            className="cancel-btn text-center text-bold cursor-pointer"
            onClick={closePanel}
          >
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerEditCustomizationsOptionContainer>
  );
}
