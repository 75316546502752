import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerAddCustomizationsContainer = styled(MyDrawerRight)`
  .add-cutomization-input-area {
    margin-top: 20px;
    .top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 49px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }
    .customization-input {
      background: transparent;
      width: 100%;
      border: 0;
      color: #fff;
      margin-bottom: 10px;
      &:focus-visible {
        outline: unset;
      }
    }

    .input-bottom-line {
      border-bottom: 1px solid #535457;
    }

    .additional-add-option {
      opacity: 0.5;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: -0.29px;
      color: #fff;
    }

    .add-option {
      color: #ed2626;
      margin-top: 60px;
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.41px;
      color: #fff;
    }
    .add-option-list {
      .add-option-title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        color: #ed2626;
      }
      padding: 21px 19px;
      object-fit: contain;
      border-radius: 16px;
      border: solid 1px rgba(255, 255, 255, 0.08);
      margin-top: 23px;
    }
    .added-option {
      padding: 21px 19px;
      object-fit: contain;
      border-radius: 16px;
      border: solid 1px rgba(255, 255, 255, 0.08);
    }
  }

  .cancel-btn {
    color: #ed2626;
  }

  .bottom-btns {
    margin-top: 30px;
  }

  .required-filed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0 2px 0;
    padding-bottom: 9px;
    border-bottom: 1px solid #535457;
    .switch-refill {
      position: relative;
      display: inline-block;
      width: 46px;
      height: 24px;
      transform: translateY(5px);
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: ${colors.green};
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }

  .selection-required {
    // margin-top: 50px;
    .selections-allowed {
      display: flex;
      justify-content: space-between;
      margin: 11px 0 11px 0;
      .selection-plus-minus {
        display: flex;
        .plus {
          background-color: #676767;
          padding: 0 6px 0 6px;
          border-radius: 50px;
        }
        .digit {
          margin: 0 8px 0 8px;
        }
        .minus {
          background-color: #676767;
          padding: 0 7px 0 7px;
          border-radius: 50px;
        }
      }
    }

    .required {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2px 0 2px 0;
    }
  }

  /* material-ui switch custom css */
  .MuiIconButton-label {
    color: #fff;
  }

  .MuiSwitch-track {
    background-color: #5f626a;
    opacity: unset;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ed2626 !important;
    opacity: unset;
  }
`;

export const SortableListContainer = styled.ul`
  padding: 0;
  width: 100%;
  margin-bottom: 0px;
`;
