import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const EnterEmailModalContainer = styled(MyDrawerCenterModal)`
  .enter-new-email {
    position: relative;
    .modal-title-new-email {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      // margin-top: 31px;
    }
    .enter-email-input-div {
      margin-top: 24px;
      margin-bottom: 24px;
      input {
        background: transparent;
        width: 100%;
        border: 0;
        text-align: center;
        border-top: 1px solid #60626a4f;
        border-bottom: 1px solid #60626a4f;
        padding: 15px 0 15px 0;
        color: #fff;
        &:focus-visible {
          outline: unset;
          outline-offset: unset;
        }
        &::placeholder {
          color: #60626a;
          font-weight: 500;
        }
      }
    }
    .new-email-action-btns {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  }
`;
