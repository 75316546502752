import React, { useState, useContext, useEffect } from "react";
import { DrawerSetInactiveStatusModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { RestaurantsContext } from "context/RestaurantsContext";

const StatusInactiveModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
}) => {
  const { inactiveNote, setInactiveNote, reason, setReason } = useContext(
    RestaurantsContext
  );

  const onSubmitInactiveStatus = (event) => {
    event.preventDefault();
    closePanel();
  };

  return (
    <DrawerSetInactiveStatusModalContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      top="20%"
    >
      <DrawerContainer>
        <form onSubmit={onSubmitInactiveStatus}>
          <div className="set-discount-container">
            <div className="modal-title">Set Inactive Status</div>

            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Describe the Reason</div>
                <div className="label-text">
                  <i>Mandatory • Visible to owner</i>
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={reason || ""}
                placeholder="Type here"
                onChange={(event) => setReason(event.target.value)}
              />
            </div>

            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  <i>Optional • Visible to Feasttt admins</i>
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={inactiveNote || ""}
                placeholder="Type here"
                onChange={(event) => setInactiveNote(event.target.value)}
              />
            </div>

            <input
              type="submit"
              value="Set Inactive"
              className={reason ? "set-active-btn" : "set-inactive-btn"}
              disabled={reason ? false : true}
            />
            <div className="cancel-btn cursor-pointer" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </form>
      </DrawerContainer>
    </DrawerSetInactiveStatusModalContainer>
  );
};

export default StatusInactiveModal;
