import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerSetPromoCodeModalContainer = styled(MyDrawerCenterModal)`
  /* height: 400px !important; */
  .set-promo-code-container {
    .modal-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .description {
      font-size: 14px;
      text-align: center;
      margin-bottom: 27px;
    }

    .generate-code {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #545556;
      border-bottom: 1px solid #545556;
      padding: 20px 0 20px 0;
      margin-bottom: 30px;
      .enter-code {
        input {
          width: 16rem;
          background: transparent;
          border: 0;
          color: #fff;
          &:focus {
            outline: none;
          }
        }
      }
      .generate-random {
        font-size: 14px;
        font-weight: bold;
        color: #ed2626;
      }
    }

    .generate-code-error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      padding: 20px 0 20px 0;
      margin-bottom: 30px;
      .enter-code {
        input {
          width: 16rem;
          background: transparent;
          border: 0;
          color: #fff;
          &:focus {
            outline: none;
          }
        }
      }
      .generate-random {
        font-size: 14px;
        font-weight: bold;
        color: #ed2626;
      }
    }

    .error-message {
      text-align: center;
      color: #ed2626;
      margin-bottom: 13px;
      font-size: 14px;
    }

    .done-btn-disable {
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      padding: 10px 0 10px 0;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 25px;
      width: 100%;
      border: 0;
      color: rgba(255, 255, 255, 0.2);
      height: 50px;
    }

    .done-btn-enable {
      text-align: center;
      background: #ed2626;
      padding: 10px 0 10px 0;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 25px;
      width: 100%;
      border: 0;
      color: #ffffff;
      cursor: pointer;
      height: 50px;
    }

    .cancel-btn {
      text-align: center;
      font-weight: bold;
      padding: 10px 0 10px 0;
      color: #ed2626;
    }
  }
`;
