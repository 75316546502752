import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerDeleteReviewContainer = styled(MyDrawerCenterModal)`
  .drawer-bottom-content {
    // flex: 1;
    h5 {
      margin-bottom: 33px;
    }

    .option-title-header {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.36px;
      color: white;
    }

    .discount-options-container {
      width: 100%;
      max-height: 94px;
      /* border-top: 1px solid rgba(255, 255, 255, 0.08); */
      /* border-bottom: 1px solid rgba(255, 255, 255, 0.08); */
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 12px;
      place-items: center;
      // padding: 0 28px;
      margin-bottom: 48px;
  
      .discount-option {
        width: 100%;
        height: 100%;
        padding-top: 26px;
        padding-bottom: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        border-radius: 16px;
        border: solid 1px #5b5e61;
  
        &.active {
          /* background-color: ${colors.redColor}; */
          border: solid 2px ${colors.redColor};
        }
  
        .option-title {
          font-size: 15px;
          font-weight: normal;
          letter-spacing: -0.36px;
          color: white;
          margin-top: 5px;
        }
  
        .option-title-header {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.36px;
          color: white;
        }
      }
      .type-value-container {
        width: 50%;
        margin: 0 auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        input {
          color: white;
        }
      }
    }


  .type-title,
  .type-value {
    font-size: 20px;
    line-height: 14px;
    letter-spacing: -0.34px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
  }

  .type-title {
    margin-bottom: 3px;
  }

  .type-value-container {
    display: flex;
    align-items: baseline;

    &.percents {
      input {
        text-align: right;
      }
    }

    input {
      width: 100%;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      background-color: transparent;
      border: 0px solid transparent;
      outline: none;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 4px;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
    }
  }

    .combined-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .combined-left,
      .combined-right {
        /* width: 100px; */
      }

      .combined-right {
        &.cvv {
          .card-input-view {
            font-size: 16px;
            /* font-weight: bolder; */
          }
        }
      }
    }

    .error-message-container {
      margin-top: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 13px;
        color: ${colors.redColor};
      }

      .error-message {
        font-size: 13px;
        color: white;
        font-weight: 600;
        margin-left: 15px;
      }
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
