import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import React, { useContext } from "react";
import { DrawerDeleteNotesContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerDeleteNotes({
  isActive = false,
  closePanel = () => {},
  deleteNoteHandler = () => {},
  noteDeletedNotification,
  setNoteDeletedNotification,
}) {
  // const { profileData, notesData } = useContext(CustomersContext);

  return (
    <DrawerDeleteNotesContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {noteDeletedNotification && (
          <MyMessageBoxItemNew
            message={
              noteDeletedNotification?.message
                ? noteDeletedNotification.message
                : ""
            }
            table={
              noteDeletedNotification?.table
                ? noteDeletedNotification.table
                : ""
            }
            type={
              noteDeletedNotification?.type
                ? noteDeletedNotification.type
                : "success"
            }
            onClick={() => setNoteDeletedNotification(null)}
          />
        )}
        <div className="delete-note-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-note-title">Delete Note</div>
          <div className="delete-note-meta">
            Are you sure you want to delete this note?
          </div>
          <div className="delete-note-actions">
            <MyDrawerButton
              className="mt-2"
              type="red"
              onClick={() => {
                deleteNoteHandler();
              }}
            >
              <b>Yes, Delete Note</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} className="mt-2">
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteNotesContainer>
  );
}
