import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { DrawerFilterOrdersContainer, Label } from "./style";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DatePicker from "components/Elements/DrawerElements/DatePicker";
import { OrdersContext } from "context/OrdersContext";
import { drawersOrder } from "static/staticData";
import { StyledStatusItem } from "theme/styles";
import { FaChevronLeft } from "react-icons/fa";
import { restaurantServices } from "services";
import { Link } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";
import CustomizationList from "./CustomizationList";

export default function DrawerFoodCustomization({
  isActive = false,
  closePanel = () => {},
  foodDetails,
  onClickDone = () => {},
}) {
  const { orderDetailData,  addOrderCount, setAddOrderCount} = useContext(OrdersContext);
  const [dressingList, setDressingList] = useState([]);
  const [price, setPrice] = useState();

  // customization option select
  // const [customizations, setCustomizations] = useState({});

  useEffect(() => {
    if (foodDetails && isActive) {
      customAxios
        .get(
          `/restaurant/${orderDetailData?.restaurant?.id}/food/${foodDetails?.id}/customization`
        )
        .then((res) => {
          if (res.status === 200) {
            let filterData = res?.data?.map((el) => {
              return { ...el, isSelected: false };
            });
            setDressingList(filterData);
          } else {
          }
        })
        .catch((err) => {});
      setPrice(foodDetails?.price);
    }
  }, [foodDetails, isActive]);


  
  const onClickDressingItem = (itemDetails, index) => {
    let data = [...dressingList];
    let indexFind = data.findIndex((el, i) => i === index);
    let customInfoDetail = data[indexFind];

    let optionIndex = customInfoDetail.options.findIndex(
      (optionEl) => optionEl.name === itemDetails.name
    );
    if (
      customInfoDetail.options[optionIndex].isSelected === true &&
      itemDetails.isSelected === true
    ) {
      customInfoDetail.options.filter((dl) => {
        dl.isSelected = false;
      });
    } else {
      customInfoDetail.options.filter((dl) => {
        dl.isSelected = false;
      });
      customInfoDetail.options[optionIndex].isSelected = !data[index]
        .isSelected;
    }

    customInfoDetail = customInfoDetail;
    data[indexFind] = customInfoDetail;
    setDressingList(data);


    // new customization option select
    // const selectedOptionId = itemDetails.customization_option_id
    // const customizationItem = dressingList.find(el => el.options.find(elem => elem.customization_option_id === selectedOptionId))
    // const customizationItemId = customizationItem.id
    // if (customizations[customizationItemId] && customizations[customizationItemId].indexOf(selectedOptionId) > -1) {
    //   if (customizations[customizationItemId].length === 1) {
    //     let filtered = {...customizations}
    //     delete filtered[customizationItemId]
    //     if (Object.keys(filtered).length === 0) {
    //       setCustomizations({})
    //     } else {
    //       setCustomizations(filtered)
    //     }
    //   } else {
    //     setCustomizations(prev => ({...prev, [customizationItemId]: prev[customizationItemId].filter(el => el !== selectedOptionId)}))
    //   }
    // } else {
    //   setCustomizations(prev => ({...prev, [customizationItemId]: prev[customizationItemId] ? [...prev[customizationItemId], selectedOptionId] : [selectedOptionId]}))
    // }

  };

  const countDressing = () => {
    let data = [...dressingList];
    let filredData = data.filter((el) => el.isSelected === true);
    return filredData.length === 0 ? "" : filredData.length;
  };

  const onClickAddition = () => {
    let add = Number(price) + Number(foodDetails?.price);
    setPrice(Number(add.toFixed(2)));
  };

  const onClickMinus = () => {
    let minus = Number(price) - Number(foodDetails?.price);
    if (Number(minus) === 0) {
    } else {
      setPrice(Number(minus.toFixed(2)));
    }
  };

  return (
    <DrawerFilterOrdersContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div
          className="top-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <FaChevronLeft
              size={20}
              className="cursor-pointer"
              onClick={closePanel}
            />
          </div>
          <div></div>
        </div>
        <div className="drawer-header mt-5">
          <Label>{foodDetails?.title}</Label>
        </div>
        {dressingList.map((el, i) => {
          return (
            <CustomizationList
              countDressing={countDressing}
              onClickDressingItem={onClickDressingItem}
              customDetail={el}
              key={i}
              customIndex={i}
            />
          );
        })}
        {/* <div className="special-instructions" style={{ marginTop: "51px" }}>
          <Label size="14px" weight="bold">
            Special Instructions
          </Label>
          <Label size="14px" weight="500" opacity="0.8">
            No pecans please, I’m allergic.
          </Label>
        </div> */}
        <div className="bottom-btns">
          <div className="increment-decrement-div text-center d-flex align-items-center justify-content-center">
            <div
              style={{ marginRight: "35px" }}
              className="click-btn-div"
              onClick={() => {
                onClickMinus();
              }}
            >
              <i className="fas fa-minus" />
            </div>
            <div style={{ marginRight: "35px" }} className="item-count">
              {parseInt(price / foodDetails?.price)}
            </div>
            <div
              className="click-btn-div"
              onClick={() => {
                onClickAddition();
              }}
            >
              <i className="fas fa-plus" />
            </div>
          </div>
          <Label
            size="13px"
            weight="500"
            marginBottom="18px"
            textAlign="center"
          >
            ${price} will be added to the customer's bill.
          </Label>
          <MyDrawerButton
            type="red"
            className="mt-auto"
            onClick={() => {
              onClickDone(dressingList, price, foodDetails);
              setPrice(foodDetails?.price);
              // setAddOrderCount(addOrderCount + 1)
            }}
          >
            <b>Add To Order</b>
          </MyDrawerButton>
          <MyDrawerButton type="redColor" onClick={closePanel}>
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerFilterOrdersContainer>
  );
}
