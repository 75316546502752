import styled from "@emotion/styled";

export const AllStaffContainer = styled.div`
  table {
    width: 100%;
    thead {
      color: rgba(255, 255, 255, 0.3);
      tr {
        th {
          cursor: default;
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5);
          padding: 10px 0;
          i {
            margin-left: 5px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.5);
          }
        }
        th:first-of-type {
          padding-left: 101px;
        }
        th:last-child {
          padding-right: 30px;
          text-align: end;
        }
      }
    }

    tbody {
      background-color: #1b1c1d;
      tr {
        cursor: default;

        &.inactive {
          td {
            opacity: 1;

            &:first-child {
              opacity: 1;
              position: relative;

              &:after {
                content: "";
                display: block;
                width: 4px;
                height: 53px;
                background-color: #727373;
                border-top-right-radius: 14px;
                border-bottom-right-radius: 14px;
                position: absolute;
                top: 18px;
                left: 0;
              }
            }

            .name-container {
              opacity: 0.4;
            }
          }
        }

        &.pending {
          cursor: default;
          td {
            &:first-child {
              position: relative;

              &:after {
                content: "";
                display: block;
                width: 4px;
                height: 53px;
                background-color: #f7a64c;
                border-top-right-radius: 14px;
                border-bottom-right-radius: 14px;
                position: absolute;
                top: 18px;
                left: 0;
              }
            }
          }
        }

        td {
          padding: 12px 0;
          // width: 12.5%;
          color: #fff;

          &:first-of-type {
            width: 22%;
          }

          &:nth-of-type(2) {
            width: 12%;
          }

          &:nth-of-type(3) {
            width: 12%;
          }

          &:nth-of-type(4) {
            width: 12%;
          }

          &:nth-of-type(5) {
            width: 10%;
          }

          &:nth-of-type(6) {
            width: 15%;
          }
          &:nth-of-type(7) {
            width: 15%;
          }
          &:nth-of-type(8) {
            width: 4%;
          }

          &.text-container-big {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.text-container-normal {
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
          }
          .staff-avatar {
            width: 48px;
            height: 48px;
            border-radius: 50px;
          }

          .name-container {
            display: flex;
            align-items: center;

            .staff-avatar-title {
              font-size: 14px;
              font-weight: bold;
              color: white;
              margin-left: 21px;
            }
          }

          .status-title {
            display: inline-block;
            font-size: 14px;
            height: 26px;
            border-radius: 8px;
            background-color: #27282a;
            padding: 2px 6px;

            &.active {
              color: #1fc086;
            }

            &.pending {
              color: #f7a64c;

              .spinner-icon {
                margin-right: 3px;
              }
            }

            &.banned {
              color: #ed2626;
            }

            &.inactive {
              color: #8f949a;
              .spinner-icon {
                margin-right: 3px;
              }
            }
          }

          .ratings {
            display: flex;
            align-items: center;

            .ratings-icon {
              color: #ed2626;
            }
          }
        }
        td:first-of-type {
          padding-left: 30px;
        }
        td:last-child {
          padding-right: 40px;
          text-align: end;
        }
      }
      tr:first-of-type {
        td:first-of-type {
          border-top-left-radius: 20px;
        }
        td:last-child {
          border-top-right-radius: 20px;
        }
      }
      tr:last-child {
        td:first-of-type {
          border-bottom-left-radius: 20px;
        }
        td:last-child {
          border-bottom-right-radius: 20px;
        }
      }
      tr:nth-of-type(odd) {
        td {
          background-color: #1b1c1d;
        }
      }
      tr:nth-of-type(even) {
        td {
          background-color: rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  .empty-staff-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 153px;

    img {
      width: 347px;
      height: 314px;
      margin-bottom: 61px;
    }
    .title {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-bottom: 14px;
    }
    .sub-title {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }

  .no-orders-data-div {
    img {
      height: 250px;
      margin-top: 11rem;
    }
    .no-result-text {
      margin-top: 20px;
      font-size: 24px;
      font-weight: 700;
    }
    .no-result-search-meta {
      font-size: 16px;
      font-weight: 500;
      opacity: 0.3;
      margin-top: 5px;
    }
  }
`;
