import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerFoodsContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .top-title-meta {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    margin-bottom: 49px;
    color: #bbb;
  }
  .selected-count-text {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
    color: #fff;
    margin: 23px 0;
  }
  .add-items-to-category-btn {
    margin-bottom: 15px;
  }
`;

export const DrawerChooseItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 62px;
  padding: 0 26px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  transition: ease 0.3s;
  &.active,
  &:hover {
    border: solid 1px #ed2626;
  }
  &.inactive {
    border: solid 1px rgba(255, 255, 255, 0.1);
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    &.choose-subtitle {
      opacity: 0.5;
    }
  }
`;
