import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import { drawersMenu } from "static/staticData";
import { restaurantServices } from "services";
import { DrawerFloorContainer } from "./style";
import { FaTrash } from "react-icons/fa";
import { colors } from "theme";
import ErrorModal from "./ErrorModal";
import { set } from "lodash";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerFloor({
  type = "Add",
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const {
    openDrawer,
    closeDrawer,
    postFloor,
    setPostFloor,
    fetchFloors,
  } = useContext(RestaurantsContext);

  const [errorModal, setErrorModal] = useState(false);

  const [addFloorNotification, setAddFloorNotification] = useState();
  const [editFloorNotification, setEditFloorNotification] = useState();

  useEffect(() => {
    if (editFloorNotification) {
      setTimeout(() => setEditFloorNotification(null), 2 * 1000);
    }
  }, [editFloorNotification]);

  useEffect(() => {
    if (addFloorNotification) {
      setTimeout(() => setAddFloorNotification(null), 2 * 1000);
    }
  }, [addFloorNotification]);

  const onDone = async () => {
    if (type === "Add") {
      await restaurantServices
        .addNewFloor(id, postFloor)
        .then((res) => {
          if (res.errMessage) {
            setErrorModal(true);
          } else {
            fetchFloors(id);
            closeDrawer(drawersMenu.floorAdd);
          }
          if (res.status === 200) {
            setAddFloorNotification({
              message: "New Floor Added.",
              type: "floor-update",
              table: ``,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
      // fetchFloors(id);
      // closeDrawer(drawersMenu.floorAdd);
    } else {
      await restaurantServices.updateFloor(id, postFloor);
      fetchFloors(id);
      closeDrawer(drawersMenu.floorEdit);
      setEditFloorNotification({
        message: "Floor Updated.",
        type: "floor-update",
        table: ``,
      });
    }
  };

  return (
    <>
      <DrawerFloorContainer
        isActive={isActive}
        closePanel={closePanel}
        renderRightIcon={
          type === "Edit" ? <FaTrash size={18} color={colors.redColor} /> : null
        }
        onClickRightIcon={() => openDrawer(drawersMenu.deleteFloor)}
      >
        {editFloorNotification && (
          <MyMessageBoxItemNew
            message={
              editFloorNotification?.message
                ? editFloorNotification.message
                : ""
            }
            table={
              editFloorNotification?.table ? editFloorNotification.table : ""
            }
            type={
              editFloorNotification?.type
                ? editFloorNotification.type
                : "success"
            }
            onClick={() => setEditFloorNotification(null)}
          />
        )}

        {addFloorNotification && (
          <MyMessageBoxItemNew
            message={
              addFloorNotification?.message ? addFloorNotification.message : ""
            }
            table={
              addFloorNotification?.table ? addFloorNotification.table : ""
            }
            type={
              addFloorNotification?.type ? addFloorNotification.type : "success"
            }
            onClick={() => setAddFloorNotification(null)}
          />
        )}
        <DrawerContainer>
          <h2 className="drawer-title text-white text-center">{type} Floor</h2>
          <DrawerInput
            label="Floor Name"
            placeholder="Ex: First Floor"
            value={postFloor?.name || ""}
            onChange={(e) =>
              setPostFloor({ ...postFloor, name: e.target.value })
            }
          />
          {type === "Add" ? (
            <div className="bottom-btns">
              <MyDrawerButton
                type={!postFloor || postFloor.name === "" ? "gray" : "red"}
                className="mt-auto"
                onClick={onDone}
                disabled={!postFloor || postFloor.name === ""}
              >
                Add New Floor
              </MyDrawerButton>
              <MyDrawerButton type="redColor" onClick={closePanel}>
                {/* onClick={() => openDrawer(drawersMenu.deleteFloor)} */}
                Cancel
              </MyDrawerButton>
            </div>
          ) : (
            <div className="bottom-btns">
              <MyDrawerButton
                type={postFloor?.name === "" ? "gray" : "red"}
                className="mt-auto"
                onClick={onDone}
                disabled={postFloor?.name === ""}
              >
                Save
              </MyDrawerButton>
              <MyDrawerButton type="redColor" onClick={closePanel}>
                Cancel
              </MyDrawerButton>
            </div>
          )}
        </DrawerContainer>
      </DrawerFloorContainer>
      <ErrorModal
        isActive={errorModal}
        closePanel={() => setErrorModal(false)}
        floorName={postFloor?.name}
      />
    </>
  );
}
