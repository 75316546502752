import React from "react";
import { FaTrash, FaQuestion, FaExclamationTriangle } from "react-icons/fa";
import { BottomWithIconWrapperContainer } from "./style";
import { colors } from "theme";

export default function BottomWithIconWrapper({
  className = "",
  hasIcon = true,
  iconInfo = false,
  iconWarning = false,
  children,
  bottomIcon = <FaTrash className="text-white" size={24} />,
  iconBackGroundColor = colors.redColor,
}) {
  return (
    <BottomWithIconWrapperContainer
      className={className}
      iconBackGroundColor={iconBackGroundColor}
    >
      <div className="drawer-bottom">
        {hasIcon && (
          <div className="drawer-icon-wrapper">
            <div className="drawer-icon d-flex justify-content-center align-items-center">
              {iconInfo ? (
                <FaQuestion className="text-white" size={24} />
              ) : iconWarning ? (
                <FaExclamationTriangle className="text-white" size={24} />
              ) : (
                <> {bottomIcon}</>
              )}
            </div>
          </div>
        )}
        {children}
      </div>
    </BottomWithIconWrapperContainer>
  );
}
