/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { restaurantServices } from "services";
import DrawerFilterRestaurants from "./Drawers/DrawerFilterRestaurants";
import { drawersRestaurants, showLimits } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import MySelect from "components/Elements/MySelect";
import RestaurantItem from "./RestaurantItem";
import StatItem from "./StatItem";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import MyButton from "components/Elements/MyButton";
import { RestaurantsPageContainer } from "./style";
import DrawerSetStatus from "./Drawers/DrawerSetStatus";
import MyPaginator from "components/Elements/MyPaginator";
import MySearch from "components/Elements/MySearch";
import { FaTrash } from "react-icons/fa";
import DrawerDeleteRestaurant from "./Drawers/DrawerDeleteRestaurant";
import { Link } from "react-router-dom";
import DrawerEditFeasttFee from "./Drawers/DrawerEditFeasttFee";
import DrawerChangeFeasttFee from "./Drawers/DrawerChangeFeasttFee";
import DrawerChangeFestttFeeAllRestaurant from "./Drawers/DrawerChangeFestttFeeAllRestaurant";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import {
  DefaultFeastttFee,
  DefaultFeastttFeeBg,
  NewRestaurantCard,
  ActiveRestaurants,
  TotalRestaurants,
  TotalRestaurantsBg,
} from "assets/img";
import { customAxios } from "helpers/custom-helpers/customAxios";
import noResultImg from "../../../assets/img/no-filter-data.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { CollectionsOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    left: "250px",
  },
}));

const statsTypes = [
  {
    key: "total",
    icon: <i class="fas fa-sigma" />,
    title: "Total Restaurants",
    image: NewRestaurantCard,
  },
  {
    key: "new",
    icon: <i className="fas fa-store-alt" />,
    title: "New Restaurants",
    subTitle: "in last 30 days",
    image: ActiveRestaurants,
  },
  {
    key: "active",
    icon: <i className="fas fa-spinner" />,
    title: "Active Restaurants",
    subTitle: "in last 30 days",
    image: TotalRestaurantsBg,
  },
  {
    key: "feast_fees",
    subIcon: <i className="fas fa-cog" />,
    title: "Default Feasttt Fee",
    image: DefaultFeastttFeeBg,
  },
];

export function RestaurantsPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);
  const [selectedPage, setSelectedPage] = useState(1);
  // const [searchInput, setSearchInput] = useState("");
  const [restaurantStats, setRestaurantStats] = useState({});

  // this state has global state
  // const [restaurantList, setRestaurantList] = useState([]);

  const [filteredRestaurantList, setFilteredRestaurantList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalList, setTotalList] = useState();

  const [restaurantFilterIcon, setRestaurantFilterIcon] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);

  const [defaultFeasttFeeModals, setDefaultFeasttFeeModals] = useState({
    editFeasttFee: false,
    changeFeasttFee: false,
    changeFeasttFeeAllRestaurant: false,
  });
  const [editFeastttFeeValue, setEditFeastttFeeValue] = useState("");
  const [restaurantStatus, setRestaurantStatus] = useState(1);
  const [filterCuisines, setFilterCuisines] = useState();
  const [filterBannedBy, setFilterBannedBy] = useState();
  const [filterDeletedBy, setFilterDeletedBy] = useState();
  const [filterDate, setFilterDate] = useState();
  const [filterBanDate, setFilterBanDate] = useState();
  const [filterInactiveDate, setFilterInactiveDate] = useState();
  const [filterDeletedDate, setFilterDeletedDate] = useState();
  const [loading, setLoading] = useState(false);

  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    activeStatus,
    resId,
    setResId,
    setRestaurantId,
    setRestaurant,
    restaurantList,
    setRestaurantList,
    isOpen,
    activeLength,
    searchInput,
    setSearchInput,
    selectedRestaurants,
    setSelectedRestaurants,
    restaurantListNotification,
    setRestaurantListNotification,

    restaurantStaffFilter,
    setRestaurantStaffFilter,

    restaurantStatusFilter,
    setRestaurantStatusFilter,
  } = useContext(RestaurantsContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getRestaurantStats = async () => {
    const result = await restaurantServices.getRestaurantStats();
    if (result.status === 200) {
      setRestaurantStats(result?.data);
      setEditFeastttFeeValue(result?.data?.feast_fees);
    }
  };

  // useEffect(() => {
  //   getRestaurantList();
  // }, [restaurantStaffFilter, restaurantStatusFilter]]);

  const getRestaurantList = async () => {
    let payload;
    if (restaurantStatus === 1) {
      payload = {
        page_size: selectedLimitToShow,
        // query: searchInput,
        page: selectedPage,
        staff_count: restaurantStaffFilter ? restaurantStaffFilter : "",
        cuisines: filterCuisines ? filterCuisines : "",
        joined_date: filterDate ? filterDate : "",
        // status: statusFilter ? statusDictionary[statusFilter] : "",
      };
    } else if (restaurantStatus === 9) {
      payload = {
        page_size: selectedLimitToShow,
        // query: searchInput,
        page: selectedPage,
        admin_ids: filterBannedBy ? filterBannedBy : "",
        ban_date: filterBanDate ? filterBanDate : "",
        joined_date: filterDate ? filterDate : "",
        staff_count: restaurantStaffFilter ? restaurantStaffFilter : "",
      };
    } else if (restaurantStatus === 0) {
      payload = {
        page_size: selectedLimitToShow,
        // query: searchInput,
        page: selectedPage,
        cuisines: filterCuisines ? filterCuisines : "",
        inactive_date: filterInactiveDate ? filterInactiveDate : "",
        joined_date: filterDate ? filterDate : "",
        staff_count: restaurantStaffFilter ? restaurantStaffFilter : "",
      };
    } else if (restaurantStatus === -1) {
      payload = {
        page_size: selectedLimitToShow,
        // query: searchInput,
        page: selectedPage,
        deleted_by: filterDeletedBy ? filterDeletedBy : "",
        deleted_date: filterDeletedDate ? filterDeletedDate : "",
        joined_date: filterDate ? filterDate : "",
        staff_count: restaurantStaffFilter ? restaurantStaffFilter : "",
      };
    } else if (restaurantStatus === 3) {
      payload = {
        page_size: selectedLimitToShow,
        // query: searchInput,
        page: selectedPage,
        cuisines: filterCuisines ? filterCuisines : "",
        req_date: filterDate ? filterDate : "",
      };
    }
    if (searchInput) {
      payload.query = searchInput;
      setIsSearchApplied(true);
    } else {
      if (isSearchApplied) {
        setIsSearchApplied(false);
      }
    }
    setRestaurantList([]);
    setFilteredRestaurantList([]);
    const result = await restaurantServices.getRestaurant(
      restaurantStatus,
      payload
    );
    setLoading(true);
    if (result.status === 200) {
      setTimeout(() => {
        setLoading(false);
      }, [500]);
      setRestaurantList(result?.data?.restaurants);
      setFilteredRestaurantList(result?.data?.restaurants);
      setTotalCount(result?.data?.total_count);
      setTotalList(result?.data?.total);
    }
  };

  useEffect(() => {
    getRestaurantStats();
    if (history.location.pathname === "/restaurants") {
      setResId(null);
      setRestaurant({});
    }
  }, [history.location.pathname]);

  useEffect(() => {
    getRestaurantList();
  }, [
    restaurantStatus,
    searchInput,
    selectedLimitToShow,
    selectedPage,
    // selectedRestaurants,
    restaurantStaffFilter,
    restaurantStatusFilter,
    filterCuisines,
    filterDate,
    filterBanDate,
    filterDeletedDate,
    filterInactiveDate,
    filterBannedBy,
    filterDeletedBy,
  ]);

  const onClickRestaurant = (restaurant, e) => {
    if (e.target?.dataset?.txt === "select") {
      if (selectedRestaurants.includes(restaurant?.restaurant_id))
        setSelectedRestaurants([
          ...selectedRestaurants.filter(
            (selectedId) => selectedId !== restaurant?.restaurant_id
          ),
        ]);
      if (!selectedRestaurants.includes(restaurant?.restaurant_id))
        setSelectedRestaurants([
          ...selectedRestaurants,
          restaurant?.restaurant_id,
        ]);
    } else {
      if (selectedRestaurants?.length === 0) {
        history.push(
          `/restaurant-detail/${restaurant?.restaurant_id}?view=overall`
        );
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setResId(restaurant?.restaurant_id);
      }
    }
  };

  // filter method
  const onFilterRestaurants = (data) => {
    setRestaurantStaffFilter(data.staff_count);
    setFilterCuisines(data.cuisines);
    setFilterDate(data.joined_date);
    setFilterBannedBy(data.banned_by);
    setFilterBanDate(data.ban_date);
    setFilterInactiveDate(data.inactive_date);
    setFilterDeletedDate(data.deleted_date);
    setFilterDeletedBy(data.deleted_by);
  };

  const onResetFilter = () => {
    setRestaurantStaffFilter();
    setFilterCuisines();
    setFilterDate();
    setFilterBannedBy();
    setFilterBanDate();
    setFilterInactiveDate();
    setFilterDeletedDate();
    setFilterDeletedBy();
  };

  const onPageChange = async (e) => {
    setSelectedPage(e.selected + 1);
  };

  setRestaurantId(selectedRestaurants);

  const [showCheckCircle, setShowCheckCircle] = useState(false);

  // useEffect(() => {
  //   selectAll();
  // }, []);

  useEffect(() => {
    setSelectedRestaurants([]);
  }, []);

  const selectAll = () => {
    if (selectedRestaurants.length) {
      setSelectedRestaurants([]);
      setShowCheckCircle(false);
    } else {
      const allRestaurantId = filteredRestaurantList.map(
        (el) => el.restaurant_id
      );
      setSelectedRestaurants(allRestaurantId);
      setShowCheckCircle(true);
    }
  };

  useEffect(() => {
    if (restaurantListNotification) {
      setTimeout(() => setRestaurantListNotification(null), 2 * 1000);
    }
  }, [restaurantListNotification]);

  function handleRestaurantFiltered() {
    setRestaurantFilterIcon(false);
    const restaurantNameFilter = [...filteredRestaurantList].sort((a, b) =>
      b.restaurant_name?.localeCompare(a.restaurant_name)
    );
    setFilteredRestaurantList(restaurantNameFilter);
  }

  function handleRestaurantNonFiltered() {
    setRestaurantFilterIcon(true);
    const restaurantNameFilter = [...filteredRestaurantList].sort((a, b) =>
      a.restaurant_name?.localeCompare(b.restaurant_name)
    );
    setFilteredRestaurantList(restaurantNameFilter);
  }

  const onClickResetRestaurantFilter = () => {
    onResetFilter();
    setIsFilterApplied(false);
    setSelectedLimitToShow(25);
    // setTimeout(() => getRestaurantList(), 1 * 1000);
  };

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const defaultFeasttFeeModalHandler = () => {
    setDefaultFeasttFeeModals({
      ...defaultFeasttFeeModals,
      editFeasttFee: true,
    });
  };

  const onChangeEditFeastttValue = (value) => {
    setEditFeastttFeeValue(value);
  };

  const editFeastttFeeOnSave = () => {
    setDefaultFeasttFeeModals({
      ...defaultFeasttFeeModals,
      editFeasttFee: false,
      changeFeasttFee: true,
    });
  };

  const changeAllRestaurantHandler = () => {
    setDefaultFeasttFeeModals({
      ...defaultFeasttFeeModals,
      editFeasttFee: false,
      changeFeasttFee: false,
      changeFeasttFeeAllRestaurant: true,
    });
  };

  const onDoneChangeAllRestaurants = () => {
    customAxios
      .put(`/restaurant/feast_fees`, {
        update_existing: true,
        feast_fees: Number(editFeastttFeeValue),
      })
      .then((res) => {
        if (res.status === 200) {
          getRestaurantStats();
          setDefaultFeasttFeeModals({
            ...defaultFeasttFeeModals,
            editFeasttFee: false,
            changeFeasttFee: false,
            changeFeasttFeeAllRestaurant: false,
          });
          setRestaurantListNotification({
            message: "Success!",
            type: "coins-success",
            table: `Feasttt Fee rate changed for all restaurants.`,
          });
        } else {
          setRestaurantListNotification({
            message: "Oops, something went wrong.",
            type: "coins-remove",
            table: `Feasttt Fee rate was not changed.`,
          });
        }
      })
      .catch((err) => {});
  };

  const changeOnlyForNewRestaurantHandler = () => {
    customAxios
      .put(`/restaurant/feast_fees`, {
        update_existing: false,
        feast_fees: Number(editFeastttFeeValue),
      })
      .then((res) => {
        if (res.status === 200) {
          getRestaurantStats();
          setDefaultFeasttFeeModals({
            ...defaultFeasttFeeModals,
            editFeasttFee: false,
            changeFeasttFee: false,
            changeFeasttFeeAllRestaurant: false,
          });
          setRestaurantListNotification({
            message: "Success!",
            type: "coins-success",
            table: `Feasttt Fee rate changed for new restaurants.`,
          });
        } else {
          setRestaurantListNotification({
            message: "Oops, something went wrong.",
            type: "coins-remove",
            table: `Feasttt Fee rate was not changed.`,
          });
        }
      })
      .catch((err) => {});
  };

  const handleSelect = (key) => {
    if (Number(key) !== Number(restaurantStatus)) {
      setSelectedRestaurants([]);
      setLoading(true);
    }
    if (isSearchApplied) {
      setIsSearchApplied(false);
      setSearchInput("");
    }
    if (key === "1") {
      onResetFilter();
      setRestaurantStatus(1);
      setRestaurantFilterIcon(true);
    } else if (key === "3") {
      onResetFilter();
      setRestaurantStatus(3);
      setRestaurantFilterIcon(true);
    } else if (key === "0") {
      onResetFilter();
      setRestaurantStatus(0);
      setRestaurantFilterIcon(true);
    } else if (key === "9") {
      onResetFilter();
      setRestaurantStatus(9);
      setRestaurantFilterIcon(true);
    } else if (key === "-1") {
      onResetFilter();
      setRestaurantStatus(-1);
      setRestaurantFilterIcon(true);
    }
  };

  return (
    <RestaurantsPageContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {restaurantListNotification && (
        <MyMessageBoxItemNew
          message={
            restaurantListNotification?.message
              ? restaurantListNotification.message
              : ""
          }
          table={
            restaurantListNotification?.table
              ? restaurantListNotification.table
              : ""
          }
          type={
            restaurantListNotification?.type
              ? restaurantListNotification.type
              : "success"
          }
          onClick={() => setRestaurantListNotification(null)}
        />
      )}
      <div className="restaurant-list-wrapper">
        <div className="restaurant-component-header">
          <div className="left-part">
            <div className="arrow-icon">
              {location.pathname === "/restaurants" ? (
                ""
              ) : (
                <i className="far fa-angle-left" />
              )}
              <h2>Restaurants</h2>
            </div>
          </div>

          {selectedRestaurants.length > 0 && (
            <Row className="handle-wrapper">
              {/* <Col>
                <MyButton
                  className="btn-all-active delete-icon"
                  type="gray"
                  onClick={() =>
                    openDrawer(drawersRestaurants.drawerRestaurantDeleteScreen)
                  }
                >
                  <FaTrash />
                </MyButton>
              </Col> */}
              {restaurantStatus === 0 && (
                <Col md="auto">
                  <MyButton
                    className="btn-all-active"
                    type="gray"
                    onClick={() => openDrawer(drawersRestaurants.setAllActive)}
                  >
                    Set all active
                  </MyButton>
                </Col>
              )}
              {restaurantStatus === 1 && (
                <Col md="auto">
                  <MyButton
                    className="btn-all-active"
                    type="gray"
                    onClick={() =>
                      openDrawer(drawersRestaurants.setAllInactive)
                    }
                  >
                    Set all inactive
                  </MyButton>
                </Col>
              )}
              <Col md="auto">
                <div className="selected-restaurants d-flex align-items-center">
                  <p className="mb-0">
                    {selectedRestaurants?.length} restaurant(s) selected
                  </p>
                  <p
                    className="mb-0 text-red ml-3 text-undo cursor-pointer"
                    onClick={() => setSelectedRestaurants([])}
                  >
                    Undo
                  </p>
                </div>
              </Col>
            </Row>
          )}

          <div className="right-part">
            <div>
              <MySearch
                value={searchInput}
                onChange={(e) => {
                  if (e?.target?.value === undefined) {
                    setSearchInput("");
                  } else {
                    setSearchInput(e?.target?.value);
                  }
                }}
                defaultShow={true}
                placeHolder="Search Restaurant Name"
              />
              {searchInput !== "" && filteredRestaurantList.length !== 0 ? (
                <div className="show-search-result">
                  {filteredRestaurantList.map((searchRestaurant, i) => (
                    <span
                      className="restaurant-title"
                      key={i}
                      onClick={(e) => {
                        history.push(
                          `/restaurant-detail/${searchRestaurant.restaurant_id}?view=overall`
                        );
                      }}
                    >
                      <p className="restaurant-title-gap">
                        <div className="text-white">
                          {searchInput !== "" &&
                            searchInput !== null &&
                            searchInput !== undefined &&
                            getHighlightedText(
                              searchRestaurant?.restaurant_name,
                              searchInput,
                              "#ed2626"
                            )}
                        </div>
                      </p>
                      <br />
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="show-limit d-flex align-items-center mr-4">
              <MySelect
                className=""
                options={showLimits}
                defaultValueId={showLimits[0]}
                onChange={(option) => {
                  setSelectedLimitToShow(option?.name);
                }}
              />
            </div>

            <div
              className="menu-item"
              onClick={() => {
                setSelectedRestaurants([]);
                openDrawer(drawersRestaurants.filterRestaurants);
              }}
            >
              <i className="fas fa-sliders-h" />
              {isFilterApplied && <div className="active-dot" />}
            </div>
          </div>
        </div>
        <div className="switcher-body">
          <Tab.Container id="" defaultActiveKey={1} onSelect={handleSelect}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey={"1"}>Active</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"3"}>Pending</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"0"}>Inactive</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"9"}>Banned</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"-1"}>Deleted</Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <h6 style={{ color: "#8f949a", size: "16px" }}>
            {searchInput && !isFilterApplied
              ? "Search Results"
              : !searchInput && isFilterApplied
              ? "Filter results"
              : searchInput && isFilterApplied
              ? "Filter & Search Results"
              : "Total"}
            : <b>{totalList}</b>
          </h6>
        </div>
        {/* analytics */}
        <div className="restaurant-list-body">
          <div className="d-flex  align-items-center justify-content-between restaurant-stats-cards">
            {statsTypes.map((_type, _i) => (
              <StatItem
                value={restaurantStats[_type.key]}
                title={_type.title}
                subTitle={_type.subTitle}
                icon={_type.icon}
                subIcon={_type?.subIcon}
                defaultFeasttFeeModalHandler={defaultFeasttFeeModalHandler}
                image={_type?.image}
              />
            ))}
          </div>

          {/* restaurants list component */}
          <div className="restaurant-list-container">
            {filteredRestaurantList?.length > 0 && !loading && (
              <div className="restaurant-titles">
                {restaurantStatus === 1 || restaurantStatus === 0 ? (
                  <div className="left-padding-1 pr-4">
                    {selectedRestaurants?.length ? (
                      selectedRestaurants?.length >= selectedLimitToShow ? (
                        <i
                          className="fas fa-check-circle"
                          onClick={selectAll}
                        />
                      ) : (
                        <i
                          className="fas fa-minus-circle cursor"
                          onClick={selectAll}
                        />
                      )
                    ) : (
                      // <>
                      //   {showCheckCircle ? (
                      //     <i className="fas fa-check-circle" onClick={selectAll} />
                      //   ) : (
                      //     <i className="fas fa-minus-circle cursor" onClick={selectAll} />
                      //   )}
                      // </>
                      <i
                        className="far fa-circle cursor-pointer"
                        onClick={selectAll}
                      />
                    )}
                  </div>
                ) : (
                  <div className="left-padding-1 pr-4">
                    <i
                      className="far fa-circle cursor-pointer"
                      style={{ visibility: "hidden" }}
                    />
                  </div>
                )}
                <div
                  className="item-flex-big cursor-pointer"
                  onClick={
                    restaurantFilterIcon
                      ? handleRestaurantFiltered
                      : handleRestaurantNonFiltered
                  }
                >
                  Restaurant{" "}
                  <span className="cursor-pointer">
                    {restaurantFilterIcon ? (
                      <i
                        className="fal fa-angle-down cursor-pointer"
                        style={{ fontSize: "14px", color: "#5c5d5d" }}
                        // onClick={handleRestaurantFiltered}
                      />
                    ) : (
                      <i
                        className="fal fa-angle-up cursor-pointer"
                        style={{ fontSize: "14px", color: "#5c5d5d" }}
                        // onClick={handleRestaurantNonFiltered}
                      />
                    )}
                  </span>
                </div>
                <div
                  className={`item-flex-normal ${restaurantStatus === 3 &&
                    "item-flex-big-big"}`}
                >
                  Status
                </div>
                {restaurantStatus === 1 ? (
                  <>
                    {" "}
                    <div className="item-flex-normal">Joined</div>
                    <div className="item-flex-cuisine">Cuisine</div>
                    <div className="item-flex-total-sales">Total Sales</div>
                    <div className="item-flex-small">Total Fees</div>
                    <div className="item-flex-last">Staff</div>
                  </>
                ) : restaurantStatus === 9 ? (
                  <>
                    {" "}
                    <div className="item-flex-normal">Banned</div>
                    <div className="item-flex-normal">Joined</div>
                    <div className="item-flex-small-big">Banned By</div>
                    <div className="item-flex-total-sales">Total Sales</div>
                    <div className="item-flex-small">Total Fees</div>
                    <div className="item-flex-last-last">Staff</div>
                  </>
                ) : restaurantStatus === 0 ? (
                  <>
                    {" "}
                    <div className="item-flex-normal">Inactivated</div>
                    <div className="item-flex-normal">Joined</div>
                    <div className="item-flex-small-big">Cuisines</div>
                    <div className="item-flex-total-sales">Total Sales</div>
                    <div className="item-flex-small">Total Fees</div>
                    <div className="item-flex-last-last">Staff</div>
                  </>
                ) : restaurantStatus === -1 ? (
                  <>
                    {" "}
                    <div className="item-flex-normal">Deleted</div>
                    <div className="item-flex-normal">Joined</div>
                    <div className="item-flex-small-big">Deleted By</div>
                    <div className="item-flex-total-sales">Total Sales</div>
                    <div className="item-flex-small">Total Fees</div>
                    <div className="item-flex-last-last">Staff</div>
                  </>
                ) : restaurantStatus === 3 ? (
                  <>
                    {" "}
                    <div className="item-flex-normal">Join Request</div>
                    <div className="item-flex-cuisine item-flex-last-last">
                      Cuisine
                    </div>
                  </>
                ) : null}
              </div>
            )}

            {filteredRestaurantList?.length > 0 && !loading ? (
              <div className="restaurant-list">
                {filteredRestaurantList &&
                  filteredRestaurantList.map((restaurant, _i) => {
                    return (
                      <>
                        <RestaurantItem
                          key={_i}
                          data={restaurant}
                          isActive={selectedRestaurants.includes(
                            restaurant?.restaurant_id
                          )}
                          customOnClick={(e) => {
                            onClickRestaurant(restaurant, e);
                          }}
                          status={restaurantStatus}
                          searchInput={searchInput}
                        />
                      </>
                    );
                  })}
              </div>
            ) : (
              !loading && (
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {/* {isSearchApplied && (
                    <div className="search-applied">
                      <div className="text-bold">Search Applied</div>
                      <div>
                        <span className="text-bold">0</span> results
                      </div>
                    </div>
                  )} */}

                  <div className="no-result">
                    <div className="no-result-img">
                      <img src={noResultImg} alt="No result" />
                    </div>
                    <div className="no-result-title">No results</div>
                    {searchInput?.length > 0 && (
                      <div className="no-result-subtitle">
                        Try broadening your search.
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
            {filteredRestaurantList?.length > 0 && !loading && (
              <MyPaginator
                initialPage={0}
                totalCount={totalList}
                limitShow={selectedLimitToShow}
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
        <DrawerFilterRestaurants
          isActive={!!openDrawers[drawersRestaurants.filterRestaurants]}
          closePanel={() => closeDrawer(drawersRestaurants.filterRestaurants)}
          onDone={onFilterRestaurants}
          restaurantData={restaurantList}
          onClickResetRestaurantFilter={onClickResetRestaurantFilter}
          setIsFilterApplied={setIsFilterApplied}
          isFilterApplied={isFilterApplied}
          restaurantStatus={restaurantStatus}
        />
        <DrawerSetStatus
          isActive={!!openDrawers[drawersRestaurants.setAllActive]}
          closePanel={() => closeDrawer(drawersRestaurants.setAllActive)}
          getRestaurantList={getRestaurantList}
        />
        <DrawerSetStatus
          type="Inactive"
          isActive={!!openDrawers[drawersRestaurants.setAllInactive]}
          closePanel={() => closeDrawer(drawersRestaurants.setAllInactive)}
          getRestaurantList={getRestaurantList}
        />
        <DrawerDeleteRestaurant
          isActive={
            !!openDrawers[drawersRestaurants.drawerRestaurantDeleteScreen]
          }
          closePanel={() =>
            closeDrawer(drawersRestaurants.drawerRestaurantDeleteScreen)
          }
        />
        <DrawerEditFeasttFee
          isActive={defaultFeasttFeeModals.editFeasttFee}
          closePanel={() => {
            setDefaultFeasttFeeModals({
              ...defaultFeasttFeeModals,
              editFeasttFee: false,
            });
          }}
          restaurantStats={restaurantStats}
          onChangeEditFeastttValue={onChangeEditFeastttValue}
          editFeastttFeeValue={editFeastttFeeValue}
          editFeastttFeeOnSave={editFeastttFeeOnSave}
        />
        <DrawerChangeFeasttFee
          isActive={defaultFeasttFeeModals.changeFeasttFee}
          closePanel={() => {
            setDefaultFeasttFeeModals({
              ...defaultFeasttFeeModals,
              changeFeasttFee: false,
            });
          }}
          restaurantStats={restaurantStats}
          editFeastttFeeValue={editFeastttFeeValue}
          changeAllRestaurantHandler={changeAllRestaurantHandler}
          changeOnlyForNewRestaurantHandler={changeOnlyForNewRestaurantHandler}
        />
        <DrawerChangeFestttFeeAllRestaurant
          isActive={defaultFeasttFeeModals.changeFeasttFeeAllRestaurant}
          closePanel={() => {
            setDefaultFeasttFeeModals({
              ...defaultFeasttFeeModals,
              changeFeasttFeeAllRestaurant: false,
            });
          }}
          restaurantStats={restaurantStats}
          editFeastttFeeValue={editFeastttFeeValue}
          onDoneChangeAllRestaurants={onDoneChangeAllRestaurants}
        />
      </div>
    </RestaurantsPageContainer>
  );
}
