import styled from "@emotion/styled";

export const DrawerInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding-top: 10px;
  h6 {
    font-size: 14px;
    opacity: 0.4;
    line-height: 1.57;
    letter-spacing: -0.34px;
  }
  .drawer-input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    .input-prefix {
      flex: 0 0 auto;
      color: #fff;
      padding-right: 10px;
    }
    input {
      flex: 1 1 auto;
      background-color: transparent;
      color: #fff;
      font-weight: bold;
      border: unset;
      outline: unset;
      padding-left: 0;
    }
  }
`;
