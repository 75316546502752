import DrawerCheckbox from "components/Elements/DrawerElements/DrawerCheckbox";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { MyTimePicker } from "components/Elements/MyTimePicker";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { DrawerAvailableDayContainer } from "./style";

export default function DrawerAvailableDay({
  selectedDay = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    closeDrawer,
    postMenu,
    setPostMenu,
    availabilityDrawerType,
  } = useContext(RestaurantsContext);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [allDay, setAllDay] = useState(false);
  const [open, setIsOpen] = useState(false);
  // const [curDay, setCurDay] = useState({});

  // const onChangeAvailable = (status) => {
  //   let tempAvailabilities = postMenu.availability
  //     ? [...postMenu.availability]
  //     : [];
  //   console.log(tempAvailabilities, "tempAvailabilities");
  //   if (!status) {
  //     setPostMenu({
  //       ...postMenu,
  //       availability: tempAvailabilities.filter(
  //         (_x) => _x.day !== selectedDay.num
  //       ),
  //     });
  //   } else {
  //     tempAvailabilities.push({
  //       day: selectedDay.num,
  //       times: [
  //         [39600, 50400],
  //         [61200, 75600],
  //       ],
  //     });
  //     setPostMenu({ ...postMenu, availability: tempAvailabilities });
  //   }
  // };
  const daysOfWeekObject = [
    { id: 0, day: "Monday" },
    { id: 1, day: "Tuesday" },
    { id: 2, day: "Wednesday" },
    { id: 3, day: "Thursday" },
    { id: 4, day: "Friday" },
    { id: 5, day: "Saturday" },
    { id: 6, day: "Sunday" },
  ];

  // function setDayTime() {
  //   if (open) {
  //     const timezone = (new Date().getTimezoneOffset() / 60) * -1;
  //     const workingHours = {
  //       day: daysOfWeekObject.find((x) => x.day === selectedDay.content)?.id,
  //       times: allDay ? [[0, 86400]] : [[startTime, endTime]],
  //       timezone: `GMT${timezone > 0 ? "+" : ""}${timezone}`,
  //     };
  //     const updated = availabilityDrawerType === 'add' ? [] : [...postMenu.availability.filter((x) => x.day !== workingHours.day)]
  //     const newData = {
  //       ...postMenu,
  //       availability: [
  //         ...updated,
  //         workingHours,
  //       ],
  //     };
  //     setPostMenu(newData);
  //     closeDrawer(drawersMenu.availableDay);
  //   } else {
  //     const updated = availabilityDrawerType === 'add' ? [] : [...postMenu.availability.filter((x) => x.day !== daysOfWeekObject.find((x) => x.day === selectedDay.content).id)]
  //     const newData = {
  //       ...postMenu,
  //       availability: [...updated]
  //     };
  //     setPostMenu(newData);
  //     closeDrawer(drawersMenu.availableDay);
  //   }
  // }

  // console.log(postMenu)

  function setDayTime() {
    if (open) {
      const timezone = (new Date().getTimezoneOffset() / 60) * -1;
      const workingHours = {
        day: daysOfWeekObject.find((x) => x.day === selectedDay.content)?.id,
        times: allDay ? [[0, 86400]] : [[startTime, endTime]],
        timezone: `GMT${timezone > 0 ? "+" : ""}${timezone}`,
      };
      let prev = [];
      if (availabilityDrawerType === "add" && postMenu  && postMenu.availability) {
        prev = [
          ...postMenu?.availability?.filter((x) => x.day !== workingHours.day),
        ];
      }
      const updated =
        availabilityDrawerType === "add"
          ? prev
          : [
              ...postMenu?.availability?.filter(
                (x) => x.day !== workingHours.day
              ),
            ];
      const newData = {
        ...postMenu,
        availability: [...updated, workingHours],
      };
      setPostMenu(newData);
      closeDrawer(drawersMenu.availableDay);
    } else {
      let prev = [];
      if (availabilityDrawerType === "add" && postMenu && postMenu.availability) {
        prev = [
          ...postMenu?.availability?.filter(
            (x) =>
              x.day !==
              daysOfWeekObject.find((x) => x.day === selectedDay.content).id
          ),
        ];
      }
      const updated =
        availabilityDrawerType === "add"
          ? prev
          : [
              ...postMenu.availability.filter(
                (x) =>
                  x.day !==
                  daysOfWeekObject.find((x) => x.day === selectedDay.content).id
              ),
            ];
      const newData = {
        ...postMenu,
        availability: [...updated],
      };
      setPostMenu(newData);
      closeDrawer(drawersMenu.availableDay);
    }
  }

  useEffect(() => {
    setIsOpen(
      !!postMenu?.availability?.find((_a) => _a?.day === selectedDay?.num)
    );
  }, [postMenu?.availability, selectedDay]);

  const onDone = () => {
    closeDrawer(drawersMenu.availableDay);
  };

  // useEffect(() => {
  //   let temp = postMenu?.availability.find((_a) => _a.day === selectedDay?.num);
  //   if (temp && isActive) {
  //     setCurDay(temp);
  //   }
  // }, [isActive]);

  // console.log(startTime)
  // console.log(endTime)

  return (
    <DrawerAvailableDayContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">{selectedDay?.content}</div>
          <div></div>
        </div>
        <DrawerCheckbox
          title="Available"
          isOpen={open}
          onChange={() => setIsOpen(!open)}
        />
        <DrawerCheckbox
          title="All Day"
          isOpen={allDay}
          onChange={() => setAllDay(!allDay)}
        />
        {!allDay && (
          <div className="">
            <p className="set-time-header mt-4">Set Your Time</p>
            <div className="time-picker-wrapper d-flex justify-content-between align-items-center">
              <MyTimePicker selectedTime={setStartTime} />
              <div className="arrow-icon-container">
                <i className="fas fa-long-arrow-alt-right" />
              </div>
              <MyTimePicker selectedTime={setEndTime} />
            </div>
          </div>
        )}
        <div className="bottom-btns">
          <MyDrawerButton className="text-bold" type={startTime > 0 && endTime > 0 ? "red" : "gray"} onClick={setDayTime}>
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerAvailableDayContainer>
  );
}
