import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { OrderDetailPageContainer } from "./style";
// import { mockOrders } from "static/staticData";
import { Container, Row, Col } from "react-bootstrap";
import BlockOrderInfo from "./BlockOrderInfo";
import BlockOrderSummary from "./BlockOrderSummary";
import BlockOrderDetail from "./BlockOrderDetail";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { OrdersContext } from "context/OrdersContext";
import DrawerOrderFoodItemReviews from "./Drawers/DrawerOrderFoodItemReviews";
import { drawersOrder } from "static/staticData";
import PaidOrderDetail from "./PaidOrderDetail";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { OopsFail } from "assets/img";
import BlockOrderRequestDetail from "./BlockOrderRequestDetail";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import OrderReviewsRemoveModal from "./Drawers/ModalRemoveReview";
import OrderRestoreReviewModal from "./Drawers/RestoreReviewModal";
import { RestaurantsContext } from "context/RestaurantsContext";

export function OrderDetailPage() {
  const { id } = useParams();
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    orderDetailData,
    setOrderDetailData,
    orderNotification,
    setOrderNotification,
    orderRequestData,
    setOrderRequestData,
  } = useContext(OrdersContext);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      left: "250px",
    },
  }));
  const classes = useStyles();
  const [selectedMenuInfo, setSelectedMenuInfo] = useState("");

  useEffect(() => {
    setOrderDetailData();
    getOrderDetail();
  }, [id]);

  // useEffect(() => {
  //   if (orderNotification) {
  //     setTimeout(() => setOrderNotification(null), 2 * 6000);
  //   }
  // }, [orderNotification]);

  const getOrderDetail = async () => {
    customAxios
      .get(`/orders/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setOrderDetailData(res?.data?.order);
          setOrderRequestData(res?.data?.requests);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onClickOrderFoodReview = (menuInfo) => {
    setSelectedMenuInfo(menuInfo);
    openDrawer(drawersOrder.orderFoodItemReview);
  };

  const paidOrderDetailsRender = () => {
    let drinkData = [];
    let qucikFoodData = [];
    let entreesData = [];
    orderDetailData &&
      orderDetailData?.sub_orders &&
      Object.keys(orderDetailData?.sub_orders).map((order, _i) => {
        Object.keys(orderDetailData?.sub_orders[order]).map((el, index) => {
          if (el === "Drink") {
            Object.keys(orderDetailData?.sub_orders[order][el]).map((fData) => {
              drinkData.push(orderDetailData?.sub_orders[order][el][fData]);
            });
          } else if (el === "Quick Bites") {
            Object.keys(orderDetailData?.sub_orders[order][el]).map((fData) => {
              qucikFoodData.push(orderDetailData?.sub_orders[order][el][fData]);
            });
          } else if (el === "Food") {
            Object.keys(orderDetailData?.sub_orders[order][el]).map((fData) => {
              entreesData.push(orderDetailData?.sub_orders[order][el][fData]);
            });
          }
          // else {
          // }
        });
      });

    return (
      <PaidOrderDetail
        drinkData={drinkData}
        qucikFoodData={qucikFoodData}
        entreesData={entreesData}
        onClickOrderFoodReview={onClickOrderFoodReview}
      />
    );
  };

  // console.log("orderDetailData", orderDetailData);
  // console.log("test", orderDetailData?.sub_orders)
  // if(orderDetailData && orderDetailData.sub_orders) {
  //   Object.keys(orderDetailData.sub_orders).map((el, i) => {
  //     console.log(orderDetailData.sub_orders[el])
  //   })
  // }

  return (
    <>
      {!orderDetailData || (orderDetailData && !orderDetailData?.token) ? (
        <Backdrop
          className={classes.backdrop}
          open={!orderDetailData ? true : false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <OrderDetailPageContainer>
          {orderNotification && (
            <MyMessageBoxItemNew
              message={
                orderNotification?.message ? orderNotification?.message : ""
              }
              table={orderNotification?.table ? orderNotification?.table : ""}
              type={
                orderNotification?.type ? orderNotification?.type : "success"
              }
              onClick={() => setOrderNotification(null)}
            />
          )}
          <div className="order-page-header">
            <div className="left-part">
              <div className="nav-line-to-back">
                {/* <Link to="/orders" className="backlink-to-rest">
              Orders
            </Link> */}
                <Link to="/orders">
                  {" "}
                  <span className="arrow-icon-container mx-3">
                    <i className="fas fa-angle-left" />
                  </span>
                </Link>
                <span className="current-order">#{orderDetailData?.token}</span>
              </div>
            </div>
          </div>
          <Container fluid className="order-page-body">
            <Row>
              <Col md={6}>
                <div className="order-part">
                  <BlockOrderInfo
                    className="order-detail-card"
                    detail={orderDetailData && orderDetailData}
                  />
                  <BlockOrderSummary
                    className="order-detail-card"
                    summaryDetail={orderDetailData?.checkout_data}
                    managerDiscountDetail={
                      orderDetailData?.checkout_data?.manager_discount_details
                    }
                    couponDetails={
                      orderDetailData?.checkout_data?.coupon_details
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="orders-details-tab">
                  <Tab.Container id="" defaultActiveKey={1} onSelect={() => {}}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey={"1"}>Orders</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={"2"}>Requests</Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Row style={{ marginTop: "25px" }}>
                      <Col>
                        <Tab.Content>
                          <Tab.Pane eventKey={"1"}>
                            {orderDetailData?.status === 2 ? (
                              <div className="order-part">
                                <div className="order-detail-card">
                                  {paidOrderDetailsRender()}
                                </div>
                              </div>
                            ) : (
                              <div className="order-part">
                                {orderDetailData &&
                                  orderDetailData?.sub_orders &&
                                  Object.keys(orderDetailData?.sub_orders)?.map(
                                    (order, _i) => {
                                      let isDrinkHaveData = true;
                                      let isQuickFoodHaveData = true;
                                      let isEntreeshaveHaveData = true;
                                      let foodTypeData =
                                        orderDetailData?.sub_orders[order];
                                      const orderAt = foodTypeData?.ordered_at;
                                      // console.log("foodTypeData", foodTypeData)
                                      return (
                                        <>
                                          {(isDrinkHaveData ||
                                            isQuickFoodHaveData ||
                                            isEntreeshaveHaveData) && (
                                            <div
                                              className="order-detail-card"
                                              key={_i}
                                            >
                                              <BlockOrderDetail
                                                order={
                                                  foodTypeData && foodTypeData
                                                }
                                                index={_i}
                                                orderDetails={
                                                  orderDetailData &&
                                                  orderDetailData
                                                }
                                                onClickOrderFoodReview={
                                                  onClickOrderFoodReview
                                                }
                                                orderAt={orderAt && orderAt}
                                                key={_i}
                                              />
                                            </div>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"2"}>
                            {orderRequestData && orderRequestData?.length ? (
                              <div className="order-part">
                                {orderRequestData?.map((reqEl, reqI) => {
                                  return (
                                    <div
                                      className="order-detail-card"
                                      key={reqI}
                                    >
                                      <BlockOrderRequestDetail
                                        returnRequestDetail={reqEl}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="empty-request-pane">
                                <img src={OopsFail} alt="" />
                                <div className="empty-request-pane-text-div">
                                  <div className="empty-request-pane-text">
                                    There are no requests
                                  </div>
                                  <div className="empty-request-pane-text">
                                    to show yet.
                                  </div>
                                </div>
                              </div>
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Container>
          <DrawerOrderFoodItemReviews
            isActive={!!openDrawers[drawersOrder.orderFoodItemReview]}
            closePanel={() => closeDrawer(drawersOrder.orderFoodItemReview)}
            detail={orderDetailData && orderDetailData}
            selectedMenuInfo={selectedMenuInfo}
          />
          <OrderReviewsRemoveModal
            // isActive={true}
            isActive={!!openDrawers[drawersOrder.removeReviewModalScreen]}
            closePanel={() => closeDrawer(drawersOrder.removeReviewModalScreen)}
            detail={orderDetailData && orderDetailData}
          />
          <OrderRestoreReviewModal
            isActive={!!openDrawers[drawersOrder.RestoreReviewModalScreen]}
            closePanel={() =>
              closeDrawer(drawersOrder.RestoreReviewModalScreen)
            }
            detail={orderDetailData && orderDetailData}
          />
        </OrderDetailPageContainer>
      )}
    </>
  );
}
