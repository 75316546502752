import styled from "@emotion/styled";

export const DashboardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 88px;
  padding: 35px 35px 24px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);

  .left-part {
    .dashboard-title {
      font-size: 24px;
      font-weight: bold;
      color: white;
      margin-bottom: 0px;
    }
  }

  .right-part {
    display: flex;
    align-items: center;

    .dashboard-date-day-of-week {
      font-size: 14px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.6);
      margin-right: 15px;
    }

    .dashboard-date-month-and-year {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
    }
  }
`;
