import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useEffect, useState, useContext } from "react";
import { drawersStaff } from "static/staticData";
import { useParams } from "react-router";
import { DrawerStaffChooseRoleContainer } from "./style";
import { FaChevronLeft } from "react-icons/fa";

const options = [
  {
    id: 1,
    value: "manager",
    name: "Manager",
    description: "Full admin access to management tools.",
  },
  {
    id: 2,
    value: "kitchen_staff",
    name: "Kitchen Staff",
    description: "Access to manage menu and view orders.",
  },
  {
    id: 3,
    value: "waiter",
    name: "Server / Host",
    description: "View orders and check in customers.",
  },
];

export default function DrawerStaffChooseRole({
  type = "Add",
  isActive = false,
  callBack = () => {},
  closePanel = () => {},
  openInviteDrawerHandler = () => {},
}) {
  const { id } = useParams();
  const {
    postStaff,
    setPostStaff,
    openDrawer,
    selectedStaffRole,
    setSelectedStaffRole,
  } = useContext(RestaurantsContext);

  const [selectedRoleOption, setSelectedRoleOption] = useState();

  useEffect(() => {
    if (isActive) {
      setSelectedRoleOption("");
    }
  }, [isActive]);

  function handleClickSelectOption(selectedId, selectedRole) {
    setSelectedRoleOption(selectedId);
    setSelectedStaffRole(selectedRole);
  }

  // console.log(selectedStaffRole)

  return (
    <DrawerStaffChooseRoleContainer
      isActive={isActive}
      closePanel={closePanel}
      // renderRightIcon={
      //   type === "Edit" && <FaTrash size={18} color={colors.white} />
      // }
      onClickRightIcon={() => {}}
    >
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Choose Role</div>
          <div></div>
        </div>

        <div className="permission">Each role has different permissions.</div>

        {options &&
          options.map((_el, i) => {
            return (
              <div
                className="roles mb-5 cursor-pointer"
                key={i}
                style={{
                  border:
                    _el?.id === selectedRoleOption
                      ? "2px solid #ed2626"
                      : "1px solid #3e4245",
                }}
                onClick={() => handleClickSelectOption(_el?.id, _el?.value)}
              >
                <div className="text-bold mb-2">{_el?.name}</div>
                <div>{_el?.description}</div>
              </div>
            );
          })}

        <div className="bottom-btns">
          <MyDrawerButton
            className="text-bold"
            type={selectedRoleOption ? "red" : "gray"}
            disabled={selectedRoleOption ? false : true}
            onClick={() => {
              // openDrawer(drawersStaff.staffInvite);
              openInviteDrawerHandler();
            }}
          >
            Continue
          </MyDrawerButton>
          <MyDrawerButton
            className="text-bold mt-6"
            type={"transparent"}
            onClick={() => {
              closePanel();
            }}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerStaffChooseRoleContainer>
  );
}
