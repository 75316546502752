import React, { useState, useEffect } from "react";
import { OrdersByHourContainer } from "./style";
import { AnalyticPeriod } from "../../AnalyticPeriod";
import { OrdersByHourChart } from "./OrdersByHourChart";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";

export const OrdersByHour = () => {
  const [period, setPeriod] = useState("day");
  const [dateRange, setDateRange] = useState();
  const [ordersByHourList, setOrdersByHourList] = useState([]);

  useEffect(() => {
    let url;
    if (dateRange) {
      url = `/analytics-orders/orders-chart?period=${period}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
    } else {
      url = `/analytics-orders/orders-chart?period=${period}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        let hoursArray = [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
        ];
        // res.data &&
        //   res.data.map((el) => {
        //     let obj = {
        //       name: `${el.hour} ${el.hour < 12 ? "AM" : "PM"}`,
        //       orders: el.order_count,
        //     };
        //     array.push(obj);
        //   });
        // setOrdersByHourList(array);

        let dummyData = [...ordersByHourList];
        hoursArray.map((elHours) => {
          let filteredArray =
            res && res.data.filter((el) => el.hour === elHours);
          let emptyObj;
          if (filteredArray && filteredArray.length) {
            emptyObj = {
              name: `${filteredArray[0].hour % 12 || 12} ${
                filteredArray[0].hour < 12 ? "AM" : "PM"
              }`,
              orders: filteredArray[0].order_count,
            };
          } else {
            emptyObj = {
              name: `${elHours % 12 || 12} ${elHours < 12 ? "AM" : "PM"}`,
              orders: 0,
            };
          }
          dummyData.push(emptyObj);
        });
        setOrdersByHourList(dummyData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [period, dateRange]);

  const onTimePeriodChange = (time) => {
    setOrdersByHourList([]);
    setPeriod(time);
  };

  return (
    <OrdersByHourContainer>
      <div className="orders-by-hour-top">
        <h4 className="orders-by-hour-title">Orders By Hour</h4>
        <div className="barchart-date">
          {/* {dateRange !== undefined &&
            `${moment(dateRange?.startDate).format(
              "dddd MMMM DD, YYYY"
            )} - ${moment(dateRange?.endDate).format("dddd MMMM DD, YYYY")}`} */}
          {dateRange?.startDate && dateRange?.endDate
            ? `${moment(dateRange.startDate).format("dddd MMMM DD, YYYY")}
                 - ${moment(dateRange.endDate).format("dddd MMMM DD, YYYY")}`
            : period === "day"
            ? moment().format("dddd MMMM DD, YYYY")
            : period === "week"
            ? `${
                moment()
                  .subtract(6, "days")
                  .format("dddd MMMM DD, YYYY")
                  .split("T")[0]
              }
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "month"
            ? `${moment()
                .subtract(30, "days")
                .format("dddd MMMM DD, YYYY")}
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "quarter"
            ? `${moment()
                .subtract(91, "days")
                .format("dddd MMMM DD, YYYY")}
                      - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "year"
            ? `${moment()
                .subtract(1, "year")
                .format("dddd MMMM DD, YYYY")}
                - ${moment().format("dddd MMMM DD, YYYY")}`
            : moment()
                .format("dddd, MMMM DD, YYYY")
                .split("T")[0]}
        </div>
        <AnalyticPeriod
          className="orders-by-hour-top-time-switcher"
          onChange={onTimePeriodChange}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
      <OrdersByHourChart chartData={ordersByHourList} />
    </OrdersByHourContainer>
  );
};
