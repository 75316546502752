import React, { useState, useEffect, useContext } from "react";
import { ReviewsRemoveModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { OrdersContext } from "context/OrdersContext";
import { customAxios } from "helpers/custom-helpers/customAxios";

const OrderReviewsRemoveModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteEntireOrder = () => {},
  detail,
}) => {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,

    isRestoreDataEnable,
    setRestoreDataEnable,

    deleteReviewId, 
    setDeleteReviewId,

    selectedItem,
  } = useContext(OrdersContext);

  const [
    customerProfileNotification,
    setCustomerProfileNotification,
  ] = useState("");

  const [removeNotification, setRemoveNotification] = useState();

  useEffect(() => {
    if (removeNotification) {
      setTimeout(() => setRemoveNotification(null), 2 * 1000);
    }
  }, [removeNotification]);

  const deleteReview = () => {
    if (isRestoreDataEnable) {
      setRestoreDataEnable(false);
      setRemoveNotification({
        message: "Review Removed.",
        type: "remove",
        table: `<p style="color: #8F949A"><b>Server's (${selectedItem?.name})</b> review was removed in <b style="color: #8F949A">${detail?.restaurant?.name}</b>.</p>`,
      });
      closePanel();
    } else {
      setRestoreDataEnable(true);
      closePanel();
    }
    const configData = { hide_review: isRestoreDataEnable };
    if (selectedItem?.id) {
      customAxios
        .patch(`/staff/review/${selectedItem?.id}`, configData)
        .then((res) => {
          // setReviewRestoreData(res.data)
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <ReviewsRemoveModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {removeNotification && (
          <MyMessageBoxItemNew
            message={
              removeNotification?.message
                ? removeNotification.message
                : ""
            }
            table={
              removeNotification?.table
                ? removeNotification.table
                : ""
            }
            type={
              removeNotification?.type
                ? removeNotification.type
                : "success"
            }
            onClick={() => setRemoveNotification(null)}
          />
        )}

        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Remove Review</div>
          <div className="delete-suborder-meta">
            You are about to remove this{" "}
            <span className="text-bold">Review</span>.
            <br /> This review will not affect the overall rating and will{" "}
            <br /> not be visible to Feasttt users anymore. Are you sure?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              onClick={() => {
                deleteReview();
              }}
            >
              <b>Yes, Remove Review</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-3">
              <b>Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ReviewsRemoveModalContainer>
  );
};

export default OrderReviewsRemoveModal;
