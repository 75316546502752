import React from "react";
import { GuestsListContainer } from "./style";
import { Image } from "react-bootstrap";
import { NoAvatar } from "assets/img";

const GuestsList = ({ guestsList }) => {
  console.log("guestsList", guestsList);
  return (
    <GuestsListContainer className="d-flex flex-column">
      {guestsList &&
        guestsList.map((guest, i) => {
          return (
            <div className="d-flex  align-items-center" key={i}>
              <div className="guests-avtar">
                <Image src={guest.image ? guest.image : NoAvatar} />
              </div>
              <div className="guests-name">{guest.name}</div>
            </div>
          );
        })}
    </GuestsListContainer>
  );
};

export default GuestsList;
