/* eslint-disable react-hooks/exhaustive-deps */
import DatePicker from "components/Elements/DrawerElements/DatePicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
// import MySwitch from "components/Elements/MySwitch";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import React, { useContext, useState, useEffect, useRef } from "react";
import { drawersCustomers } from "static/staticData";
import { StyledStatusItem } from "theme/styles";
import {
  DrawerNewFilterCustomersContainer,
  PopupCalendarContainer,
} from "./style";
import { Switch } from "@material-ui/core";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment";
import MyDateRange from "components/Elements/MyDateRange";
import MyButton from "components/Elements/MyButton";
import MyLocationFilter from "components/Elements/MyLocationFilter";
import Slider from "rc-slider";
import { useOutsideClick } from "services/functions";
import MySelectAdminDropdown from "components/Elements/MySelectAdminDropdown";

const statuses = [
  {
    value: 1,
    title: "Active",
  },
  {
    value: 2,
    title: "Inactive",
  },
];

export default function DrawerNewFilterCustomers({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  joinedDate,
  onClickResetCustomer = () => {},
  setIsFilterApplied,
  customerListTab,
  setJoinedDate,
  bannedDate,
  setBannedDate,
  onResetFilter,
  isFilterApplied,
}) {
  const joinedDateRef = useRef();
  const bannedDateRef = useRef();

  const { openDrawer, setState } = useContext(CustomersContext);

  const [profilePhoto, setProfilePhoto] = useState(false);
  const [ccFile, setCcFile] = useState(false);

  const [joinedDateModal, setJoinedDateModal] = useState(false);
  const [tempJoinDate, setTempJoinDate] = useState({});

  const [bannedDateModal, setBannedDateModal] = useState(false);
  const [tempBannedDate, setTempBannedDate] = useState({});

  const [selectedLastLocations, setSelctedLastLocations] = useState([]);

  const [selectedAdmins, setSelectedAdmins] = useState([]);

  const [numberOfOrders, setNumberOfOrders] = useState({
    from: "",
    to: "",
  });

  const [totalPaidOrders, setTotalPaidOrders] = useState({
    from: "",
    to: "",
  });

  const [close, setClose] = useState(false);

  // const onClickJoinedDate = () => {
  //   openDrawer(drawersCustomers.joinedDate);
  // };

  const [customersStatus, setCustomersStatus] = useState();

  const onChangeFiltered = (_status) => {
    if (_status === customersStatus) {
      setCustomersStatus(undefined);
    } else {
      setCustomersStatus(_status);
    }
  };

  useEffect(() => {
    if (isActive && !isFilterApplied) {
      setProfilePhoto(false);

      setTempJoinDate({});
      setJoinedDate({});
      setTempBannedDate({});
      setBannedDate({});
      setSelctedLastLocations([]);
      setSelectedAdmins([]);
      setNumberOfOrders({
        from: "",
        to: "",
      });
      setTotalPaidOrders({
        from: "",
        to: "",
      });
    }
    //  else if (!isActive) {
    //   setProfilePhoto(false);

    //   setTempJoinDate({});
    //   setJoinedDate({});
    //   setTempBannedDate({});
    //   setBannedDate({});
    //   setSelctedLastLocations([]);
    //   setSelectedAdmins([]);
    //   setNumberOfOrders({
    //     from: "",
    //     to: "",
    //   });
    //   setTotalPaidOrders({
    //     from: "",
    //     to: "",
    //   });
    //   setJoinedDateModal(false);
    // }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      if (!isFilterApplied) {
        resetAllButtonHandler();
      }
    }
  }, [customerListTab, isActive]);

  const ProfilePhotoHandleChange = (event) => {
    setProfilePhoto(event.target.checked);
    // console.log(event.target.checked)
  };

  const CcFileHandleChange = (event) => {
    setCcFile(event.target.checked);
    // console.log(event.target.checked)
  };

  // **google api suggestions**
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    // setValue("");
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      console.log("📍 Coordinates: ", { lat, lng });
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  // **end of google api suggestions**

  const onClickDone = () => {
    const filterObject = {};
    if (profilePhoto) {
      filterObject.hasAvatar = profilePhoto;
    }
    if (selectedLastLocations.length && customerListTab === 1) {
      let array = [];
      selectedLastLocations.map((el) => {
        array.push(el.abbreviation);
      });
      filterObject.last_locations = array.toString();
    }
    if (numberOfOrders.from >= 0 && numberOfOrders.to > 0) {
      filterObject.order_count = `${numberOfOrders.from},${numberOfOrders.to}`;
    }

    if (bannedDate && customerListTab === 9) {
      filterObject.ban_date = bannedDate;
    }

    if (bannedDate && customerListTab === 0) {
      filterObject.inactive_date = bannedDate;
    }

    if (bannedDate && customerListTab === -1) {
      filterObject.delete_date = bannedDate;
    }

    if (
      selectedAdmins.length &&
      (customerListTab === 9 || customerListTab === -1)
    ) {
      let array = [];
      selectedAdmins.map((el) => {
        array.push(el.id);
      });
      if (customerListTab === -1) {
        filterObject.deleted_by = array;
      } else {
        filterObject.admin_ids = array;
      }
    }

    onDone(filterObject);
  };

  // date range method
  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempJoinDate({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const onChangeBannedDate = (date) => {
    if (date && date?.endDate) {
      setTempBannedDate({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const resetAllButtonTextRenderer = () => {
    if (customerListTab === 1) {
      if (
        Object.keys(joinedDate).length ||
        selectedLastLocations.length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        isFilterApplied
      ) {
        return "Reset All";
      }
    } else if (customerListTab === 0) {
      if (
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        isFilterApplied
      ) {
        return "Reset All";
      }
    } else if (customerListTab === 9) {
      if (
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        selectedAdmins.length ||
        isFilterApplied
      ) {
        return "Reset All";
      }
    } else if (customerListTab === -1) {
      if (
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        selectedAdmins.length ||
        isFilterApplied
      ) {
        return "Reset All";
      }
    }
  };

  const resetAllButtonHandler = () => {
    if (customerListTab === 1) {
      setTempJoinDate({});
      setJoinedDate({});
      setNumberOfOrders({
        from: "",
        to: "",
      });
      setTotalPaidOrders({
        from: "",
        to: "",
      });
      setSelctedLastLocations([]);
      setProfilePhoto(false);
    } else if (customerListTab === 0) {
      setTempJoinDate({});
      setJoinedDate({});
      setTempBannedDate({});
      setBannedDate({});
      setNumberOfOrders({
        from: "",
        to: "",
      });
      setTotalPaidOrders({
        from: "",
        to: "",
      });
      setProfilePhoto(false);
    } else if (customerListTab === 9) {
      setTempJoinDate({});
      setJoinedDate({});
      setTempBannedDate({});
      setBannedDate({});
      setNumberOfOrders({
        from: "",
        to: "",
      });
      setTotalPaidOrders({
        from: "",
        to: "",
      });
      setProfilePhoto(false);
      setSelectedAdmins([]);
    } else if (customerListTab === -1) {
      setTempJoinDate({});
      setJoinedDate({});
      setTempBannedDate({});
      setBannedDate({});
      setNumberOfOrders({
        from: "",
        to: "",
      });
      setTotalPaidOrders({
        from: "",
        to: "",
      });
      setProfilePhoto(false);
      setSelectedAdmins([]);
    }
    onResetFilter();
    // closePanel();
  };

  const disableFilterButton = () => {
    if (customerListTab === 1) {
      if (
        Object.keys(joinedDate).length ||
        selectedLastLocations.length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto
      ) {
        return false;
      } else {
        return true;
      }
    } else if (customerListTab === 0) {
      if (
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto
      ) {
        return false;
      } else {
        return true;
      }
    } else if (customerListTab === 9) {
      if (
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        selectedAdmins.length
      ) {
        return false;
      } else {
        return true;
      }
    } else if (customerListTab === -1) {
      if (
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        selectedAdmins.length
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const buttonColorRenderer = () => {
    if (customerListTab === 1) {
      if (
        Object.keys(joinedDate).length ||
        selectedLastLocations.length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto
      ) {
        return "red";
      } else {
        return "gray";
      }
    } else if (customerListTab === 0) {
      if (
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto
      ) {
        return "red";
      } else {
        return "gray";
      }
    } else if (customerListTab === 9) {
      if (
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        selectedAdmins.length
      ) {
        return "red";
      } else {
        return "gray";
      }
    } else if (customerListTab === -1) {
      if (
        (numberOfOrders.from >= 0 && numberOfOrders.to > 0) ||
        (totalPaidOrders.from >= 0 && totalPaidOrders.to > 0) ||
        profilePhoto ||
        Object.keys(joinedDate).length ||
        Object.keys(bannedDate).length ||
        selectedAdmins.length
      ) {
        return "red";
      } else {
        return "gray";
      }
    }
  };

  useOutsideClick(joinedDateRef, () => {
    if(joinedDateModal) {
      setJoinedDateModal(false);
    }
  });

  useOutsideClick(bannedDateRef, () => {
    if(bannedDateModal) {
      setBannedDateModal(false);
    }
  });


  useEffect(() => {
if(!isActive){
  setJoinedDateModal(false);
  setBannedDateModal(false);
}
  },[isActive])


  useEffect(() => {
    if (joinedDateModal || bannedDateModal) {
      setClose(true);
    } else {
      setClose(false);
    }
  }, [joinedDateModal, bannedDateModal]);

  return (
    <DrawerNewFilterCustomersContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              color="#60646c"
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Filter</div>
          <div
            className="reset-all-btn"
            onClick={() => {
              resetAllButtonHandler();
            }}
          >
            <b>{resetAllButtonTextRenderer()}</b>
          </div>
        </div>
        <div className="drawer-content">
          {customerListTab === 9 ||
          customerListTab === 0 ||
          customerListTab === -1 ? (
            <div className="joined-date-wrapper">
              <label className="join-date-label">
                {customerListTab === 9
                  ? "Banned Date"
                  : customerListTab === 0
                  ? "Inactivated Date"
                  : "Deleted Date"}
                {Object.keys(bannedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setBannedDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select date"
                  onClick={() => {
                    setBannedDateModal(!bannedDateModal);
                  }}
                  value={
                    bannedDate?.startDate === undefined
                      ? ""
                      : bannedDate?.startDate === bannedDate?.endDate
                      ? `${bannedDate?.startDate}`
                      : `${bannedDate?.startDate} - ${bannedDate?.endDate}`
                  }
                  className="join-date-input"
                />
                <i
                  className="far fa-calendar-alt"
                  onClick={() => {
                    setBannedDateModal(!bannedDateModal);
                  }}
                  style={{
                    color: bannedDateModal ? "#fff" : "#fff",
                    opacity: bannedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {bannedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={bannedDateRef}
                  >
                    <MyDateRange
                      date={bannedDate}
                      months={2}
                      onChangeDate={onChangeBannedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={bannedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setBannedDate(tempBannedDate);
                        setBannedDateModal(!bannedDateModal);
                      }}
                      disabled={
                        Object.keys(tempBannedDate).length > 0 ? false : true
                      }
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="joined-date-wrapper">
            <label className="join-date-label">
              Joined Date
              {Object.keys(joinedDate).length ? (
                <i
                  className="fas fa-times"
                  onClick={() => {
                    setJoinedDate({});
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              ) : (
                <></>
              )}
            </label>
            <div className="join-date-div">
              <input
                placeholder="Select date"
                onClick={() => {
                  setJoinedDateModal(!joinedDateModal);
                }}
                value={
                  joinedDate?.startDate === undefined
                    ? ""
                    : joinedDate?.startDate === joinedDate?.endDate
                    ? `${joinedDate?.startDate}`
                    : `${joinedDate?.startDate} - ${joinedDate?.endDate}`
                }
                className="join-date-input"
              />
              <i
                className="far fa-calendar-alt"
                onClick={() => {
                  setJoinedDateModal(!joinedDateModal);
                }}
                style={{
                  color: joinedDateModal ? "#fff" : "#fff",
                  opacity: joinedDateModal ? "1" : "1",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              />
              {joinedDateModal && (
                <PopupCalendarContainer
                  className="popup-sales-calendar"
                  ref={joinedDateRef}
                >
                  <MyDateRange
                    date={joinedDate}
                    months={2}
                    onChangeDate={onChangeDate}
                  />
                  <MyButton
                    className="apply-date"
                    type={joinedDate?.endDate !== "" ? "red" : "gray"}
                    onClick={() => {
                      setJoinedDate(tempJoinDate);
                      setJoinedDateModal(!joinedDateModal);
                    }}
                    disabled={
                      Object.keys(tempJoinDate).length > 0 ? false : true
                    }
                  >
                    Apply
                  </MyButton>
                </PopupCalendarContainer>
              )}
            </div>
          </div>
          {customerListTab === 1 ? (
            <div className="location-wrapper">
              <MyLocationFilter
                label="Last Location"
                setValue={setSelctedLastLocations}
                value={selectedLastLocations}
                close={close}
              />
            </div>
          ) : (
            <></>
          )}

          {customerListTab === 9 || customerListTab === -1 ? (
            <div className="admin-select-container">
              <MySelectAdminDropdown
                label={customerListTab === 9 ? "Banned By" : "Deleted By"}
                setValue={setSelectedAdmins}
                value={selectedAdmins}
                close={close}
                isActive={isActive}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="number-of-orders-container">
            <label className="filter-label">
              # of Orders{" "}
              {numberOfOrders.from !== "" && numberOfOrders.to !== "" ? (
                <>
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setNumberOfOrders({
                        from: "",
                        to: "",
                      });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#ed2626",
                      fontSize: "14px",
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </label>
            <Slider
              range
              allowCross={false}
              defaultValue={[numberOfOrders?.from, numberOfOrders?.to]}
              value={[numberOfOrders?.from, numberOfOrders?.to]}
              onChange={(value) => {
                setNumberOfOrders({
                  from: value[0],
                  to: value[1],
                });
              }}
              max={999}
            />
            <div className="input-divs">
              <input
                onChange={(e) => {
                  setNumberOfOrders({
                    ...numberOfOrders,
                    from: e.target.value,
                  });
                }}
                value={numberOfOrders.from}
                placeholder="From"
              />
              <input
                onChange={(e) => {
                  setNumberOfOrders({
                    ...numberOfOrders,
                    to: e.target.value,
                  });
                }}
                value={numberOfOrders.to}
                placeholder="To"
              />
            </div>
          </div>

          <div className="number-of-orders-container">
            <label className="filter-label">
              Total Paid
              {totalPaidOrders.from !== "" && totalPaidOrders.to !== "" ? (
                <>
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setTotalPaidOrders({
                        from: "",
                        to: "",
                      });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#ed2626",
                      fontSize: "14px",
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </label>
            <Slider
              range
              allowCross={false}
              defaultValue={[totalPaidOrders?.from, totalPaidOrders?.to]}
              value={[totalPaidOrders?.from, totalPaidOrders?.to]}
              onChange={(value) => {
                setTotalPaidOrders({
                  from: value[0],
                  to: value[1],
                });
              }}
            />
            <div className="total-paid-divs">
              <div className="total-paid-div">
                <div
                  style={{
                    paddingRight: "5px",
                    opacity: "0.4",
                  }}
                >
                  $
                </div>
                <input
                  onChange={(e) => {
                    setTotalPaidOrders({
                      ...totalPaidOrders,
                      from: e.target.value,
                    });
                  }}
                  value={totalPaidOrders.from}
                  placeholder="From"
                />
              </div>
              <div className="total-paid-div">
                <div
                  style={{
                    paddingRight: "5px",
                    opacity: "0.4",
                  }}
                >
                  $
                </div>
                <input
                  onChange={(e) => {
                    setTotalPaidOrders({
                      ...totalPaidOrders,
                      to: e.target.value,
                    });
                  }}
                  value={totalPaidOrders.to}
                  placeholder="To"
                />
              </div>
            </div>
          </div>

          {/* <div className="cc-file-wrapper">
            <div className="text-bold">CC on File</div>
            <div>
              <Switch
                checked={ccFile}
                onChange={CcFileHandleChange}
                // inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div> */}
          <div className="profile-photo">
            <div className="text-bold"> Profile Photo</div>
            <div>
              <Switch
                checked={profilePhoto}
                onChange={ProfilePhotoHandleChange}
                // inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={buttonColorRenderer()}
            onClick={onClickDone}
            disabled={disableFilterButton()}
            className="text-bold"
          >
            <b>Apply</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            onClick={() => {
              closePanel();
            }}
            className="mt-4 text-bold"
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerNewFilterCustomersContainer>
  );
}
