import React, { useRef, useState, useEffect } from "react";
import { MyLocationFilterContainer } from "./style";
import { useOutsideClick } from "services/functions";
import { customAxios } from "helpers/custom-helpers/customAxios";

export default function MyCuisineFilter({
  className = "",
  label = "",
  setValue = () => {},
  value = [],
  isActive,
}) {
  const locationDropdownRef = useRef();
  const [cuisines, setCuisines] = useState([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsDropDownOpen(false);
    }
  }, [isActive]);

  const addLocationHandler = (location) => {
    let tempData = value.find((el) => el.id === location.id);
    if (!tempData) {
      setValue([...value, location]);
    } else {
      let tempData = value.filter((el) => el.id !== location.id);
      setValue(tempData);
    }
  };

  useOutsideClick(locationDropdownRef, () => {
    setIsDropDownOpen(false);
  });

  useEffect(() => {
    if (isDropDownOpen && label === "Cuisine") {
      customAxios
        .get(`/cuisine`)
        .then((res) => {
          setCuisines(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (
      (isDropDownOpen && label === "Banned By") ||
      label === "Deleted By"
    ) {
      customAxios
        .get(`/admin/list`)
        .then((res) => {
          setCuisines(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isDropDownOpen]);

  return (
    <MyLocationFilterContainer className={className} ref={locationDropdownRef}>
      <div className="label-div">
        {label}{" "}
        {value && value.length ? (
          <i
            className="fas fa-times"
            style={{
              color: "#ed2626",
              fontSize: "14px",
              lineHeight: "1.29",
              cursor: "pointer",
            }}
            onClick={() => {
              setValue([]);
              setIsDropDownOpen(false);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="state-input">
        {value.length ? (
          label === "Cuisine" ? (
            <div className="state-name-render-div">
              {value.map((el) => {
                return (
                  <div className="state-abbr-name">
                    {el.name}{" "}
                    <i
                      className="fas fa-times"
                      onClick={() => {
                        let tempData = [...value];
                        let index = tempData.findIndex(
                          (el1) => el1.id === el.id
                        );
                        tempData.splice(index, 1);
                        setValue(tempData);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="state-name-render-div">
              {value.map((el) => {
                return (
                  <div className="state-abbr-name">
                    {el.name1} {el.name2}
                    <i
                      className="fas fa-times"
                      onClick={() => {
                        let tempData = [...value];
                        let index = tempData.findIndex(
                          (el1) => el1.id === el.id
                        );
                        tempData.splice(index, 1);
                        setValue(tempData);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )
        ) : (
          <div className="select-text">
            {label === "Cuisine" ? "Select Cuisine" : "Select Admin"}
          </div>
        )}
        <div>
          {isDropDownOpen ? (
            <i
              className="fas fa-angle-up"
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <i
              className="fas fa-angle-down"
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      {isDropDownOpen ? (
        label === "Cuisine" ? (
          <div className="states-list-div">
            {cuisines.map((el, index) => {
              return (
                <div
                  className="state-name-div"
                  onClick={() => {
                    addLocationHandler(el);
                  }}
                  key={index}
                >
                  <div className="name-container">
                    {value?.some((e) => e.id === el.id) ? (
                      <div className="selected">
                        <i className="fas fa-check" />
                      </div>
                    ) : (
                      <div className="unselected"></div>
                    )}

                    <div className="state-name"> {el.name}</div>
                  </div>
                  {/* <div className="state-abbreviation">{el?.abbreviation}</div> */}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="states-list-div">
            {cuisines.map((el, index) => {
              return (
                <div
                  className="state-name-div"
                  onClick={() => {
                    addLocationHandler(el);
                  }}
                  key={index}
                >
                  <div className="name-container">
                    {value?.some((e) => e.id === el.id) ? (
                      <div className="selected">
                        <i className="fas fa-check" />
                      </div>
                    ) : (
                      <div className="unselected"></div>
                    )}

                    <div className="state-name">
                      {" "}
                      {el.name1} {el.name2}
                    </div>
                  </div>
                  {/* <div className="state-abbreviation">{el?.abbreviation}</div> */}
                </div>
              );
            })}
          </div>
        )
      ) : (
        <></>
      )}
    </MyLocationFilterContainer>
  );
}
