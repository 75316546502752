import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerVerifyEmailContainer = styled(MyDrawerCenterModal)`
  /* .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  } */

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
  }

  .bottom-btns {
    margin-top: 30px;
  }

  .cancel-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: ${colors.redColor};
    cursor: pointer;
  }

`;
