import React from "react";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { colors } from "theme";
import { RestaurantsFactsContainer } from "./style";

export const RestaurantsFacts = ({ total }) => {
  // console.log(total);

  return (
    <RestaurantsFactsContainer>
      {total &&
        total.map(({ title, value, change }) => (
          <div className="restaurant-fact-list-item" key={title}>
            <div className="d-flex">
              <span className="restaurant-title mr-2">{title}</span>
              {/* <span className="restaurant-title mr-2">
                <span>
                  {change >= 0 ? (
                    <FaArrowCircleUp color={colors.green} />
                  ) : (
                    <FaArrowCircleDown color={colors.orange} />
                  )}
                </span>
              </span> */}
              {/* <span
                className="restaurant-title text-bold"
                style={{
                  color: change >= 0 ? "#1fc086" : "#f7a64c",
                }}
              >
                {Math.abs(change)}%
              </span> */}
            </div>
            <span className="restaurant-value">
              {/* {value === 0 ? value : value > 0 ? `+${value}` : ""} */}
              {value === 0 ? value : value > 0 ? `${value}` : ""}
            </span>
          </div>
        ))}
    </RestaurantsFactsContainer>
  );
};
