import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerRearrangeCuisinesModalContainer = styled(
  MyDrawerCenterModal
)`
  padding-left: 0;
  padding-right: 0;
  .rearrange-cuisine-modal {
    .rearrange-cuisine-title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 25px;
    }
    .rearrange-cuisine-list {
      /* height: 585px; */
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #555;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .rearrange-cuisine-name {
        display: flex;
        align-items: center;
        height: 54px;
        background: #1b1c1d;
        padding: 12px;
        border-radius: 15px;
        margin-bottom: 10px;
        margin-right: 26px;
        margin-left: 26px;
        /* border: 1px solid #5b5e61; */
      }
    }
    .rearrange-cuisine-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 88%;
      border-radius: 10px;
      margin-top: 20px;
      margin-bottom: 25px;
      margin-right: 26px;
      margin-left: 26px;
    }
    .cancel-btn {
      color: #ed2626;
      text-align: center;
      margin-right: 26px;
      margin-left: 26px;
    }
  }
`;
