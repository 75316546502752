import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import React, { memo, useContext, useState, useEffect } from "react";
import {
  FaCheckCircle,
  FaChevronLeft,
  FaChevronRight,
  FaEllipsisH,
  FaTimes,
  FaTimesCircle,
  FaTrash,
} from "react-icons/fa";
import { drawersOverall, drawersCustomers } from "static/staticData";
import { colors } from "theme";
import {
  DrawerEditCustomerContainer,
  DrawerMobileNumberInputWithVerifyContainer,
} from "./style";
import { PhotoUploader } from "components/Elements/PhotoUploader";
import NoAvatar from "assets/img/noAvatar.png";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { customerServices } from "services/customerServices";
import DrawerDeleteCustomer from "../DrawerDeleteCustomer";
import DrawerDeleteCustomerConfirmation from "../DrawerDeleteCustomerConfirmation";
import DrawerDeleteCustomerOTP from "../DrawerDeleteCustomerOTP";
import CustomerEditMoreModal from "../../Modals/CustomerEditMoreModal";
import NumberFormat from "react-number-format";
import { Image } from "react-bootstrap";
import { PhotoUploaderFormData } from "components/Elements/PhotoUploaderFormData";

const DrawerEditCustomer = ({
  profile = {},
  isActive = false,
  closePanel = () => {},
  customerProfilerData = {},
  setRestaurantEditMoreModal = () => {},
  setBanRestaurantModal,
  setUnbanCustomerModal,
  restaurantEditMoreModal,
}) => {
  const {
    openDrawer,
    profileData,
    setProfileData,
    closeDrawer,
    editCustomerData,
    setEditCustomerData,
    editCustomerInactiveStatusNote,
    setEditCustomerInactiveStatusNote,
    deleteCustomerModals,
    setDeleteCustomerModals,
  } = useContext(CustomersContext);

  useEffect(() => {
    if (profileData || isActive) {
      let obj = {
        avatar: profileData.avatar,
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        email: profileData.email,
        locations: profileData.locations,
        cards: profileData.cards,
        status: profileData.status,
        notes: profileData.notes,
        phone_verified: profileData.phone_verified,
        phone_number: profileData.phone_number,
        email_verified: profileData.email_verified,
      };
      setEditCustomerData(obj);
      setEditCustomerInactiveStatusNote("");
    }
  }, [profileData, isActive]);

  const onUploadSuccess = (asset) => {
    // console.log(asset);
    setEditCustomerData({ ...editCustomerData, avatar: asset });
  };

  const onEditSaveProfile = (editData) => {
    const formData = new FormData();
    if (!editData.avatar.asset_type) {
      formData.append("asset", editData.avatar);
    }
    // editData.avatar && formData.append("asset", editData.avatar);
    editData.first_name && formData.append("first_name", editData.first_name);
    editData.last_name && formData.append("last_name", editData.last_name);
    editData.phone_number &&
      formData.append("phone_number", editData.phone_number);
    editData.email && formData.append("email", editData.email);
    editData.email_verified &&
      formData.append("email_verified", editData.email_verified);

    let editObj = {
      first_name: editData.first_name,
      last_name: editData.last_name,
      email: editData.email,
      phone_number: editData.phone_number,
      email_verified: editData.email_verified,
    };
    if (editData && editData.avatar && editData.avatar.id) {
      editObj.avatar = editData.avatar.id;
    }

    customAxios
      .put(
        `/customer/${profileData?.id}`,
        // editObj
        formData
      )
      .then((res) => {
        let statusObj = {
          customer_ids: [profileData.id],
          status: editData.status,
          note: editCustomerInactiveStatusNote,
        };
        customAxios
          .put(`/customer/status`, statusObj)
          .then((res) => {
            getCustomerProfile();
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });

    let newlyAddedAddresses =
      editData &&
      editData.addedLocations &&
      editData.addedLocations.filter(
        (location) => location.newAddress === true
      );
    if (newlyAddedAddresses) {
      newlyAddedAddresses.map((location) => {
        let postData = {
          title: location.title,
          is_default: false,
          place_id: location.place_id,
        };
        customAxios
          .post(`/customer/${profileData.id}/location`, postData)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
      });
    }

    let editAddedAddresses =
      editData &&
      editData.addedLocations &&
      editData.addedLocations.filter(
        (location) => location.editAddress === true
      );
    if (editAddedAddresses) {
      editAddedAddresses.map((location) => {
        let editData = {
          title: location.title,
          is_default: false,
          place_id: location.place_id,
        };
        customAxios
          .put(`/customer/${profileData?.id}/location/${location.id}`, editData)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
      });
    }

    let deleteAdddedAddress =
      editData &&
      editData.addedLocations &&
      editData.addedLocations.filter(
        (location) => location.deleteAddress === true
      );
    if (deleteAdddedAddress) {
      deleteAdddedAddress.map((location) => {
        customAxios
          .delete(`/customer/${profileData.id}/location/${location.id}`)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
      });
    }
  };

  const getCustomerProfile = async () => {
    const result = await customerServices.getCustomerById(profileData.id);
    if (result.status === 200) {
      closeDrawer(drawersCustomers.editCustomer);
      setProfileData(result?.data);
    }
  };

  return (
    <DrawerEditCustomerContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
      onClickRightIcon={() => openDrawer(drawersOverall.deleteCustomer)}
    >
      <div className="header-bg">
        <div className="d-flex  align-items-center justify-content-between close-drawer-icon">
          <div>
            <FaChevronLeft
              size={20}
              style={{ zIndex: "9999999" }}
              className="cursor-pointer "
              onClick={closePanel}
            />
          </div>
          <div className="drawer-title">Edit Customer</div>
          <div>
            <FaEllipsisH
              size={18}
              className="pointer cursor-pointer"
              onClick={() => {
                setRestaurantEditMoreModal(true);
              }}
            />
          </div>
        </div>
        <Image
          src={editCustomerData?.avatar?.file ?? NoAvatar}
          className="bg-img"
        />
      </div>

      <DrawerContainer style={{ position: "relative", bottom: "10rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 0 32px 0",
          }}
        >
          <PhotoUploaderFormData
            src={editCustomerData?.avatar?.file ?? NoAvatar}
            height={"150px"}
            width={"150px"}
            onUploadSuccess={onUploadSuccess}
          />
        </div>

        <div style={{ marginBottom: "50px" }}>
          <DrawerInput
            label="First Name"
            placeholder="First Name"
            value={editCustomerData.first_name}
            onChange={(e) =>
              setEditCustomerData({
                ...editCustomerData,
                first_name: e.target.value,
              })
            }
          />
          <DrawerInput
            label="Last Name"
            placeholder="Last Name"
            value={editCustomerData.last_name}
            onChange={(e) =>
              setEditCustomerData({
                ...editCustomerData,
                last_name: e.target.value,
              })
            }
          />
          {/* <ItemPicker
            label="Mobile Number"
            placeholder="Set Mobile Number"
            value={editCustomerData.phone_number}
            verifyIcon={true}
            isVerified={editCustomerData.phone_verified ? true : false}
            onClick={() => openDrawer(drawersOverall.verifyPhone)}
          /> */}

          {/* <DrawerInputWithVerify
            label="Mobile Number"
            placeholder="Set Mobile Number"
            value={editCustomerData.phone_number}
            onChange={(e) =>
              setEditCustomerData({
                ...editCustomerData,
                phone_number: e.target.value,
              })
            }
            onClick={() => openDrawer(drawersOverall.verifyPhone)}
            verified={editCustomerData.phone_verified ? true : false}
            isHideArrowIcon={false}
          /> */}

          <DrawerMobileNumberInputWithVerifyContainer className="">
            {<h6 className="mb-0">Mobile Number</h6>}
            <div className="drawer-input-container">
              <NumberFormat
                format={"+# (###) ###-####"}
                className={`single-ant-format-input`}
                value={editCustomerData?.phone_number}
                onChange={(e) =>
                  setEditCustomerData({
                    ...editCustomerData,
                    phone_number: e.target.value,
                  })
                }
                onBlur={() => {}}
                placeholder={"Set Mobile Number"}
                // style={style}
              />
              <FaCheckCircle size={20} color={"#1ece6d"} className="mobile-number-verify-icon"/>
              {/* <FaTimesCircle size={20} color={"#ed2626"} className="mobile-number-verify-icon" /> */}
              <div className="arrow-icon">
                <FaChevronRight
                  size={15}
                  color={colors.white3}
                  onClick={() => openDrawer(drawersOverall.verifyPhone)}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </DrawerMobileNumberInputWithVerifyContainer>

          {/* <ItemPicker
            label="Email Address"
            placeholder="Unknown"
            value={editCustomerData.email}
            onClick={() => openDrawer(drawersOverall.verifyEmail)}
          /> */}

          <DrawerInputWithVerify
            label="Email Address"
            type="email"
            placeholder="Set Email Address"
            value={editCustomerData.email}
            onChange={(e) =>
              setEditCustomerData({
                ...editCustomerData,
                email: e.target.value,
              })
            }
            onClick={() => openDrawer(drawersOverall.verifyEmail)}
            isVerifiedShow={true}
            verified={editCustomerData.email_verified ? true : false}
          />
        </div>

        <div style={{ marginBottom: "50px" }}>
          <ItemPicker
            label="Addresses on File"
            placeholder="Add addresses"
            value={
              editCustomerData.locations > 0
                ? editCustomerData.locations + " " + "addresses"
                : ""
            }
            onClick={() => openDrawer(drawersOverall.locations)}
          />
          <ItemPicker
            label={"Payment Methods"}
            placeholder={"Add payment methods"}
            value={
              editCustomerData.cards > 0
                ? editCustomerData.cards + " " + "cards"
                : ""
            }
            onClick={() => openDrawer(drawersOverall.paymentCard)}
          />
        </div>

        <div style={{ marginBottom: "50px" }}>
          <ItemPicker
            label="Status"
            placeholder="Set Status"
            value={editCustomerData.status ? "Active" : "Inactive"}
            onClick={() => openDrawer(drawersOverall.statusOverall)}
          />
          {/* <ItemPicker
            label={null}
            placeholder={null}
            value={`View notes (${editCustomerData.notes})`}
            onClick={() => openDrawer(drawersOverall.notes)}
          /> */}
        </div>

        <div className="bottom-btns" style={{ marginTop: "30px" }}>
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            disabled={
              editCustomerData.first_name === "" ||
              editCustomerData.last_name === "" ||
              editCustomerData.phone_number === "" ||
              editCustomerData.email === ""
            }
            onClick={() => onEditSaveProfile(editCustomerData)}
          >
            Save
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="text-bold"
            onClick={() => {
              closeDrawer(drawersCustomers.editCustomer);
              setEditCustomerData("");
            }}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>

      <DrawerDeleteCustomer
        isActive={deleteCustomerModals.deleteCustomer}
        closePanel={() => {
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            deleteCustomer: false,
          });
        }}
      />

      <DrawerDeleteCustomerConfirmation
        isActive={deleteCustomerModals.deleteConfirmation}
        closePanel={() => {
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            deleteCustomer: false,
            deleteConfirmation: false,
          });
        }}
      />

      <DrawerDeleteCustomerOTP
        isActive={deleteCustomerModals.confirmationOTP}
        // isActive={true}
        closePanel={() => {
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            deleteCustomer: false,
            deleteConfirmation: false,
            confirmationOTP: false,
          });
        }}
      />

      <CustomerEditMoreModal
        isActive={restaurantEditMoreModal}
        setBanRestaurantModal={setBanRestaurantModal}
        closePanel={() => {
          setRestaurantEditMoreModal(false);
        }}
        setUnbanCustomerModal={setUnbanCustomerModal}
      />
    </DrawerEditCustomerContainer>
  );
};

export default memo(DrawerEditCustomer);
