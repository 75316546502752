import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
import { drawersOrder } from "static/staticData";
import { DrawerDeleteReviewContainer } from "./style";

export default function DrawerDeleteSubOrder({
  isActive = false,
  closePanel = () => {},
  orderDelete = false,
}) {
  const { openDrawer } = useContext(OrdersContext);

  const openConfirmationModel = () => {
    closePanel();
    if (orderDelete) {
      openDrawer(drawersOrder.deleteOrderConfirmation);
    } else {
      openDrawer(drawersOrder.deleteSubOrderConfirmation);
    }
  };

  return (
    <DrawerDeleteReviewContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper>
          <div className="drawer-bottom-content w-100">
            <h3 className="mb-3">
              {orderDelete ? "Delete Order" : "Delete Sub-Order"}
            </h3>
            <h5 className="opacity6">
              {orderDelete
                ? "The entire order will be deleted."
                : "The sub-order will be deleted."}
            </h5>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-3"
                onClick={openConfirmationModel}
              >
                Delete
              </MyDrawerButton>
              <MyDrawerButton type="gray" onClick={closePanel}>
                Keep
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerDeleteReviewContainer>
  );
}
