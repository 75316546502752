import styled from "@emotion/styled";
import { colors } from "theme";

export const ActiveOrderAddItemPageContainer = styled.div`
  padding: 34px 24px;
`;

export const ActiveOrderAddItemPageHeaderContainer = styled.div`
  padding: 0 40px 20px 40px;
  // border-bottom: 1px solid #4d5053;
  padding-bottom: 24px;
  .header-title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.58px;
    color: #fff;
  }
  a {
    width: 120px;
    height: 40px;
    padding: 11px 38px;
    border-radius: 25px;
    background-color: #60646c;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .add-item-btn {
    border-radius: 25px;
    height: 40px;
    width: max-content;
    margin-left: 24px;
    font-weight: bold;
  }
`;

export const AddItemBodyContainer = styled.div``;

export const AddItemCategoriesAndDropDown = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid #4d5053;
  .category-scroll {
    ::-webkit-scrollbar {
      display: none;
    }
    width: 70rem;
    display: flex;
    overflow-x: scroll;
    align-items: center;
    white-space: nowrap;
    .category-badge-wrapper {
      .category-badge {
        background-color: #1b1c1d;
        margin-right: 8px;
        border-radius: 25px;
        padding: 13px 24px;
        font-size: 14px;
        cursor: pointer;
      }
      .selected-category-badge {
        border-radius: 25px !important;
        background-color: #27282a !important;
        margin-right: 8px !important;
        padding: 13px 24px !important;
        font-size: 14px !important;
        cursor: pointer !important;
        color: #fff !important;
      }
    }
  }
  .menu-header-select {
    margin-right: 15px;
    .dropdown-header-menu {
      background-color: #27282a;
      border: 1px solid transparent;
    }
  }
`;

export const AddItemCategoriesAndItemList = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  .menu-category {
    margin-bottom: 26px;
    .category-header {
    }
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  // border-bottom: 1px solid #4d5053;
  padding: 20px 30px 50px 0;
  .edit-button {
    /* width: 50px;
    height: 40px;
    margin: 0 13px 0 0;
    border-radius: 25px;
    background-color: #60646c;
    display: flex;
    justify-content: center;
    align-items: center; */
  }
  .categories-group {
    p {
      margin: 0 13px;
      padding: 11px 21px;
      border-radius: 25px;
      background-color: ${colors.gray2};
    }
  }

  .select-menu-and-re-order {
  }

  .slider-filter-btn {
    background: #151617 !important;
    border: 1px solid #525558 !important;
    padding: 0px 20px;
    margin-right: 15px;
  }

  .menu-header-select {
    margin-right: 15px;
    .dropdown-header-menu {
      background-color: #27282a;
      border: 1px solid transparent;
    }
  }

  .category-scroll {
    ::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    overflow-x: scroll;
    align-items: center;
    white-space: nowrap;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #27282A;
    width: max-content;
    border-radius: 30px;
    
    .category-badge-wrapper {
      .category-badge {
        background-color: #1b1c1d;
        margin-right: 8px;
        border-radius: 50px;
        padding: 15px 15px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .dietary-btn-title {
    background: #151617;
    border-radius: 20px;
    border: 1px solid #fff;
  }
  .option-dietary-item {
    padding: 6px 20px;
    &:hover {
      background-color: ${colors.gray5};
    }
  }
  .active {
    background-color: ${colors.gray5};
  }
`;

export const MealTimeDropDown = styled.div`
  // padding: 20px 30px 20px 0;
  .meal-time-dropdown {
    width: 280px;
  }
  .dropdown-toggle {
    width: 280px;
    text-align: left;
  }
  .menu-header-select {
    .dropdown-header-menu {
      background-color: #27282a;
      border: 1px solid transparent;
    }
  }
`;
