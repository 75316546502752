import React, { useContext } from "react";
import { DrawerFailAddItemInExistingContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OopsFail } from "assets/img";

const DrawerFailAddItemInExisting = ({
  isActive = false,
  closePanel = () => {},
  tryAgainClick = () => {},
}) => {
  return (
    <DrawerFailAddItemInExistingContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <img className="added-existing-item-img" src={OopsFail} alt="" />
          <div className="added-existing-item-meta d-flex flex-column ">
            <div className="oops-text">Oops!</div>
            <div className="something-went-wrong-text">
              Something went wrong.
            </div>
          </div>
          <MyDrawerButton
            type="red"
            className="added-existing-item-btn mb-4"
            onClick={tryAgainClick}
          >
            <b>Try Again</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="added-existing-item-btn"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerFailAddItemInExistingContainer>
  );
};

export default DrawerFailAddItemInExisting;
