import React from "react";
import { CustomerSignUpsTotalContainer } from "./style";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { colors } from "theme";

export const CustomerSignUpsTotal = ({ total }) => {
  return (
    <CustomerSignUpsTotalContainer>
      {total.map(({ title, value, change }) => (
        <div className="sign-ups-total-item" key={title + value}>
          <h4 className="sign-ups-total-item-title d-flex">
            {title} &nbsp;
            {/* <span className="percent text-green d-flex">
              {change >= 0 ? (
                <FaArrowCircleUp color={colors.green} />
              ) : (
                <FaArrowCircleDown color={colors.orange} />
              )}
              &nbsp;
              <span
                style={{
                  color: change >= 0 ? "#1fc086" : "#f7a64c",
                }}
              >
                <b>{Math.abs(change)}%</b>
              </span>
            </span> */}
          </h4>
          <span className="sign-ups-total-item-value">
            {value === 0 ? value : value > 0 ? `+${value}` : ""}
          </span>
        </div>
      ))}
    </CustomerSignUpsTotalContainer>
  );
};
