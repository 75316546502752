import React, { useContext, useState, useEffect, useRef } from "react";
import {
  FaClock,
  FaDollarSign,
  FaEllipsisH,
  FaMapMarkerAlt,
  FaSpinner,
  FaStar,
  FaCheck,
} from "react-icons/fa";
import { drawersOrder } from "static/staticData";
import {
  BlockOrderInfoContainer,
  PopupOrderContainer,
  GuestsInfoContainer,
} from "./style";
import IconCheck from "components/Elements/IconCheck";
import { OverlayTrigger } from "react-bootstrap";
import MyDivider from "components/Elements/MyDivider";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { StyledIconWrapper } from "theme/styles";
import DrawerReviewServer from "../Drawers/DrawerReviewServer";
import { OrdersContext } from "context/OrdersContext";
import DrawerDeleteReview from "../Drawers/DrawerDeleteReview";
import DrawerReviewDeleted from "../Drawers/DrawerReviewDeleted";
import DrawerFullRefund from "../Drawers/DrawerFullRefund";
import DrawerPartialRefund from "../Drawers/DrawerPartialRefund";
import DrawerRemoveItem from "../Drawers/DrawerRemoveItem";
import { DrawerApplyDiscount } from "../Drawers/DrawerApplyDiscount";
import { DrawerApplyDiscountAreYouSure } from "../Drawers/DrawerApplyDiscountAreYouSure";
import moment from "moment";
import { NoAvatar } from "assets/img";
import GuestsInfo from "./GuestsInfo";
import { Link, useParams } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerGuestCheckout from "../Drawers/DrawerGuestCheckout";
import DrawerLastGuestCheckout from "../Drawers/DrawerLastGuestCheckout";
import DrawerProcessPayment from "../Drawers/DrawerProcessPayment";
import DrawerDeleteSubOrder from "../Drawers/DrawerDeleteSubOrder";
import DrawerDeleteSubOrderConfirmation from "../Drawers/DrawerDeleteSubOrderConfirmation";
import DrawerDeletedSubOrder from "../Drawers/DrawerDeletedSubOrder";
import { useHistory } from "react-router-dom";
import { showMessage } from "services/functions";
import { Image } from "react-bootstrap";
import MyButton from "components/Elements/MyButton";
import { useOutsideClick } from "services/functions";
import { colors } from "theme";
import ActiveOrderActionsModal from "../Modals/ActiveOrderActionsModal";
import PaidOrderActionsModal from "../Modals/PaidOrderActionsModal";
import ApplyPromoCodeModal from "../Modals/ApplyPromoCodeModal";
import AssignNewServerModal from "../Modals/AssignNewServerModal";
import NewServerAssignedModal from "../Modals/NewServerAssignedModal";
import DeleteEntireOrderModal from "../Modals/DeleteEntireOrderModal";
import ConfirmEntireOrderDeleteModal from "../Modals/DeleteEntireOrderModal/ConfirmEntireOrderDeleteModal";
import RefundConfirmationModal from "../Modals/RefundConfirmationModal";
import FullRefundConfirmationModal from "../Modals/FullRefundConfirmationModal";
import TimeStamp from "./TimeStamp";
import { RestaurantsContext } from "context/RestaurantsContext";

const _discountTypes = {
  first: {
    value: "percentage",
    initial: 15,
  },
  second: {
    value: "fixed",
    initial: 10,
  },
};

const tempImg =
  "https://image.shutterstock.com/image-photo/close-portrait-young-smiling-handsome-600w-1180874596.jpg";

export default function BlockOrderInfo({ className = "", detail = {} }) {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    setServerReviewData,
    selectedItem,
    paymentDrawers,
    setPaymentDrawers,
    setOrderNotification,
    setOrderDetailData,
  } = useContext(OrdersContext);

  // console.log(detail);

  const { restaurant, setRestaurant } = useContext(RestaurantsContext);

  let history = useHistory();
  const [dollars, setDollars] = useState("");
  const [percents, setPercents] = useState("");
  const [discount, setDiscount] = useState(undefined);
  const [paymentType, setPaymentType] = useState(_discountTypes.first.value);
  const [selectedGuestInfo, setSelectedGuestInfo] = useState("");
  const [isHostGuestPopUpShown, setIsHostGuestPopUpShown] = useState(false);

  const [isActiveOrderActionModalShown, setIsACtiveOrderModalShown] = useState(
    false
  );
  const [paidOrderActionsModal, setPaidOrderActionsModal] = useState(false);

  const [openApplyDiscountModal, setOpenApplyDiscountModal] = useState(false);
  const [openApplyPromoCodeModal, setOpenApplyPromoCodeModal] = useState(false);
  const [openAssignNewServerModal, setOpenAssignNewServerModal] = useState(
    false
  );
  const [opneNewAssignedServerModal, setOpenNewAssignedServerModal] = useState(
    false
  );
  const [openEntireDeleteOrderModal, setOpenEntireDeleteOrderModal] = useState(
    false
  );

  const [partialRefundModals, setPartialRefundModals] = useState({
    partialRefund: false,
    refundConfirmation: false,
    data: "",
  });

  const [fullRefundModals, setFullRefundModals] = useState({
    fullRefund: false,
    fullRefundConfirmation: false,
    data: "",
  });

  const [
    openEntireOrderDeletedMessageModal,
    setOpenEntireOrderDeletedMessageModal,
  ] = useState(false);

  const [checkInData, setCheckInData] = useState();
  const [promoCode, setPromoCode] = useState("");

  const hostRef = useRef();

  const [
    isPaymentAdditionalAvailable,
    setIsPaymentAdditionalAvailable,
  ] = useState(false);
  const paymentAdditionalNowAvailable = () =>
    setIsPaymentAdditionalAvailable(true);
  const paymentAdditionalBecomeUnavailable = () =>
    setIsPaymentAdditionalAvailable(false);

  const [isOther, setIsOther] = useState(false);

  const enableOtherMode = () => setIsOther(true);
  const disableOtherMode = () => setIsOther(false);

  const [paymentOption, setPaymentOption] = useState("");

  const onChangePaymentOption = (newOption) => {
    if (!isPaymentAdditionalAvailable) {
      paymentAdditionalNowAvailable();
    }
    setPaymentOption(newOption);
  };

  const onChangePaymentType = (newType) => {
    if (newType === _discountTypes.first.value) {
      setPaymentType(_discountTypes.first.value);
    } else if (newType === _discountTypes.second.value) {
      setPaymentType(_discountTypes.second.value);
    }
  };

  const isFirstType = () => paymentType === _discountTypes.first.value;
  const isSecondType = () => paymentType === _discountTypes.second.value;

  useEffect(() => {
    if (isFirstType()) {
      // changing dollars when changing percents
      setDollars(Number(computeDiscount(detail, percents, true)));
    } else if (isSecondType()) {
      // changing percents when changing dollars
      setPercents(Number(computeDiscount(detail, dollars, false)));
    }
  }, [dollars, percents, detail, isSecondType, isFirstType]);

  const onClickPartitialRefund = () => {
    setPartialRefundModals({
      ...partialRefundModals,
      partialRefund: true,
    });
  };
  const onClickFullRefund = () => {
    // openDrawer(drawersOrder.fullRefund);
    setFullRefundModals({
      ...fullRefundModals,
      fullRefund: true,
    });
  };
  const onClickDeleteOrder = () => {
    openDrawer(drawersOrder.removeOrder);
  };

  const onClickAddItem = () => {
    localStorage.setItem("ordersRestaurantId", detail?.restaurant?.id);
  };

  const onClickReviewServer = () => {
    openDrawer(drawersOrder.reviewServer);
  };

  const onClickApplyDiscount = () => {
    openDrawer(drawersOrder.applyDiscount);
  };

  const onClickProcessPayment = () => {
    setPaymentDrawers({ ...paymentDrawers, processPayment: true });
  };

  const onChangePercentageOption = (option) => {
    disableOtherMode();
    onChangePaymentType(_discountTypes.first.value);
    onChangePaymentOption(option);
    setPercents(option);
  };

  const onApplyDiscount = () => {
    // setDiscount({
    //   type: paymentType,
    //   value: isOther ? (isFirstType() ? percents : dollars) : paymentOption,
    //   money: dollars,
    // });
    let obj = {
      type: paymentType,
      value: isOther ? (isFirstType() ? percents : dollars) : paymentOption,
      money: dollars,
    };
    applyDiscountForActiveOrder(obj);
  };

  const onSwitchToOtherAndSetPercentage = () => {
    enableOtherMode();
    onChangePaymentType(_discountTypes.first.value);
  };

  const applyDiscountForActiveOrder = (discount) => {
    let dObj;
    if (discount.type === "percentage") {
      dObj = {
        discount: discount?.value,
        discount_type: 1,
      };
    } else {
      dObj = {
        discount: Number(discount?.money),
        discount_type: 2,
      };
    }
    customAxios
      .post(`/orders/${detail?.token}/discount`, dObj)
      .then((res) => {
        if (res?.status === 200) {
          setIsACtiveOrderModalShown(false);
          closeDrawer(drawersOrder.setOpenOrderModal);
          setOpenApplyDiscountModal(!openApplyDiscountModal);
          setOrderNotification({
            message: "Discount Applied",
            table: `Table ${detail?.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
            #${detail?.token}
          </span>`,
          });
          customAxios
            .get(`/orders/${detail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
          setPercents("");
          setDollars("");
          setPaymentOption("");
        } else {
          showMessage(
            "You can only apply discount  after order complete",
            "error"
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // onCloseDrawerApplyDiscount();
  };

  const partialDiscountHandler = (data) => {
    setPartialRefundModals({
      ...partialRefundModals,
      partialRefund: false,
      refundConfirmation: true,
      data: data,
    });
    // let dObj = {
    //   amount: data.value.toFixed(2),
    // };
    // console.log("dObj", dObj);
    // customAxios
    //   .post(`/orders/${detail.token}/refund/partial`, dObj)
    //   .then((res) => {
    //     console.log("partial res", res);
    //     closeDrawer(drawersOrder.partialRefund);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };

  const applyPartialRefundHandler = (data) => {
    let dObj = {
      amount: data && data?.value && Number(data?.value)?.toFixed(2),
    };
    customAxios
      .post(`/orders/${detail?.token}/refund/partial`, dObj)
      .then((res) => {
        if (res?.status === 200) {
          setPartialRefundModals({
            ...partialRefundModals,
            partialRefund: false,
            refundConfirmation: false,
            data: "",
          });
          setPaidOrderActionsModal(false);
          closeDrawer(drawersOrder.setOpenPaidOrderModal);
          setOrderNotification({
            message: "Refund Issued.",
            table: `Table ${detail?.table_number} •  #${detail?.token} •  $${dObj?.amount}`,
          });
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fullRefundHandler = () => {
    customAxios
      .post(`/orders/${detail?.token}/refund/full`)
      .then((res) => {
        if (res?.status === 200) {
          setOrderNotification({
            message: "Refund Issued.",
            table: `Table ${detail?.table_number} •  #${detail?.token} •  $${detail?.checkout_data?.total}`,
          });
          setFullRefundModals({
            ...fullRefundModals,
            fullRefund: false,
            fullRefundConfirmation: false,
            data: "",
          });
          setPaidOrderActionsModal(false);
          closeDrawer(drawersOrder.setOpenPaidOrderModal);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const orderStatusRenderer = (status) => {
    switch (status) {
      case 0:
        return null;
      case 1:
        return "Active Order";
      case 2:
        return detail?.payment_mode === 3 ? "COMPLIMENTARY" : "PAID";
      case 3:
        return "Active Order";
      default:
        return null;
    }
  };

  const orderIconRenderer = (status) => {
    switch (status) {
      case 0:
        return null;
      case 1:
        return <FaSpinner className="text-white" size={24} />;
      case 2:
        return <FaCheck className="text-white" size={24} />;
      case 3:
        return <FaSpinner className="text-white" size={24} />;
      default:
        return null;
    }
  };

  const orderPopUpRenderer = (status) => {
    switch (status) {
      case 0:
        return null;
      case 1:
        return openDrawer(drawersOrder.setOpenOrderModal);
      // setIsACtiveOrderModalShown(!isActiveOrderActionModalShown);
      case 2:
        return openDrawer(drawersOrder.setOpenPaidOrderModal);
      // setPaidOrderActionsModal(!paidOrderActionsModal);
      case 3:
        return openDrawer(drawersOrder.setOpenOrderModal);
      default:
        return null;
    }
  };

  const onSelectGuestCheckout = (guestInfo) => {
    setSelectedGuestInfo(guestInfo);
    openDrawer(drawersOrder.guestsCheckout);
  };

  const onCheckoutGuest = () => {
    let dObj = {
      guest_id: selectedGuestInfo?.id,
    };
    console.log("dObj", dObj);
    customAxios
      .post(`/orders/${detail?.token}/checkout/guest/${selectedGuestInfo?.id}`)
      .then((res) => {
        console.log("checkut res", res);
      })
      .catch((err) => {
        console.error(err);
      });
    closeDrawer(drawersOrder.guestsCheckout);
    // openDrawer(drawersOrder.lastGuestCheckout);
  };

  const onCheckoutLastGuest = () => {
    closeDrawer(drawersOrder.lastGuestCheckout);
  };

  const onDeleteReview = () => {
    customAxios
      .delete(`/orders/server/reviews/${selectedItem?.id}`)
      .then((res) => {
        if (res?.status === 200) {
          customAxios
            .get(`/orders/server/${detail?.server?.id}/reviews`)
            .then((res) => {
              if (res?.status === 200) {
                setServerReviewData(res?.data);
              } else {
              }
            })
            .catch((err) => {
              console.error(err);
            });
          openDrawer(drawersOrder.deletedReview);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onClickDeleteFullOrder = () => {
    setOpenEntireDeleteOrderModal(!openEntireDeleteOrderModal);
  };

  const deleteMainOrder = () => {
    customAxios
      .delete(`/orders/${detail?.token}`)
      .then((res) => {
        if (res?.status === 200) {
          setOpenEntireDeleteOrderModal(!openEntireDeleteOrderModal);
          setOpenEntireOrderDeletedMessageModal(
            !openEntireOrderDeletedMessageModal
          );
          setIsACtiveOrderModalShown(false);
          closeDrawer(drawersOrder.setOpenOrderModal);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onSucessFullDeleteOrder = () => {
    setOpenEntireOrderDeletedMessageModal(!openEntireOrderDeletedMessageModal);
    closeDrawer(drawersOrder.setOpenPaidOrderModal);
    history.push(`/orders`);
  };

  const popupCompleted = (
    <PopupOrderContainer className="popup-wrapper">
      <div className="popup-item" onClick={onClickPartitialRefund}>
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Partial Refund</span>
      </div>
      <div className="popup-item" onClick={onClickFullRefund}>
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Full Refund</span>
      </div>
      <div className="popup-item" onClick={onClickDeleteFullOrder}>
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Delete Full Order</span>
      </div>
    </PopupOrderContainer>
  );
  const popupActive = (
    <PopupOrderContainer className="popup-wrapper">
      <Link
        to={`/order-detail/${detail?.token}/add-item`}
        className="popup-item backlink-to-rest"
        onClick={onClickAddItem}
      >
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Add Item</span>
      </Link>
      <div className="popup-item" onClick={onClickApplyDiscount}>
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Apply Discount</span>
      </div>
      <div className="popup-item" onClick={onClickProcessPayment}>
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Process a Payment</span>
      </div>
      <div className="popup-item" onClick={onClickDeleteFullOrder}>
        <FaDollarSign className="popup-item-icon" color="white" />
        <span>Delete Full Order</span>
      </div>
    </PopupOrderContainer>
  );

  useOutsideClick(hostRef, () => {
    setIsHostGuestPopUpShown(false);
  });

  const applyPromoCodeHandler = (promoCode) => {
    customAxios
      .patch(`/orders/${detail?.token}/coupon?coupon_code=${promoCode}`)
      .then((res) => {
        if (res?.status === 200) {
          setOpenApplyPromoCodeModal(!openApplyPromoCodeModal);
          setPromoCode("");
          setIsACtiveOrderModalShown(false);
          closeDrawer(drawersOrder.setOpenOrderModal);
          setOrderNotification({
            message: "Promo Code Applied.",
            table: `Table ${detail?.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
      #${detail?.token}
    </span>`,
          });
          customAxios
            .get(`/orders/${detail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (detail?.status !== 3) {
            showMessage(
              "You can apply promo code  only after order is complete",
              "error"
            );
          } else {
            setOrderNotification({
              type: "error",
              message: "Promo code invalid or has expired.",
              table: `Table ${detail?.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
              #${detail?.token}
            </span>`,
            });
          }
        }
      });
  };

  const assignNewServerHandler = () => {
    console.log("checkInData", checkInData);
    customAxios
      .patch(`/orders/${detail?.token}/server`, { server_id: checkInData?.id })
      .then((res) => {
        if (res?.status === 200) {
          customAxios
            .get(`/orders/${detail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {
              console.log(err);
            });
          setOpenAssignNewServerModal(!openAssignNewServerModal);
          setOpenNewAssignedServerModal(!opneNewAssignedServerModal);
          setIsACtiveOrderModalShown(false);
        }
      })
      .catch((err) => {});
  };


  let total = 5
  let result = total - detail?.server?.rating?.substring(0, 1)

  return (
    <BlockOrderInfoContainer className={className}>
      <div className="card-block">
        <div className="order-status-header d-flex align-items-center justify-content-between">
          <div className="order-status-icon-name d-flex align-items-center">
            <div
              className="order-status-icon"
              style={{
                background:
                  detail?.status === 1 || detail?.status === 3
                    ? "linear-gradient(322deg,rgb(253, 203, 110) 29%,rgb(247, 166, 76) 100%)"
                    : "#1fc086",
              }}
            >
              {orderIconRenderer(detail?.status)}
            </div>
            <div className="order-status-name d-flex flex-column align-items-start">
              <h3
                style={{
                  color:
                    detail?.status === 2
                      ? detail?.payment_mode === 3
                        ? "#d6a67c"
                        : "#1fc086"
                      : "",
                }}
              >
                <b>{orderStatusRenderer(detail?.status)}</b>
              </h3>
              <span className="opacity6" style={{ fontSize: "15px" }}>
                Table {detail?.table_number}
                <span className="mx-2">•</span>#{detail?.token}
                <span className="mx-2">•</span>
                {detail?.item_count} Items
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-end flex-column align-items-end">
            <div id="order-edit-wrapper">
              {/* <OverlayTrigger
                container={() => document.getElementById("order-edit-wrapper")}
                placement="bottom-end"
                popperConfig={{ className: "fffffffffff" }}
                trigger="click"
                overlay={orderPopUpRenderer(detail.status)}
                rootClose
              >
                <StyledIconWrapper>
                  <FaEllipsisH color="white" className="cursor" />
                </StyledIconWrapper>
              </OverlayTrigger> */}
              <StyledIconWrapper
                onClick={() => orderPopUpRenderer(detail?.status)}
              >
                <FaEllipsisH color="white" className="cursor" size={18} />
              </StyledIconWrapper>
            </div>
            <div style={{ fontSize: "15px" }}>
              {detail?.status === 2 ? (
                <>
                  <h5
                    style={{
                      marginTop: "10px",
                    }}
                    className="ml-auto d-flex align-items-center"
                  >
                    {Array.from({ length: 1 }, (v, i) => (
                      <FaStar className="text-orange mx-1" />
                    ))}
                    ({detail?.order_rating || 0})
                  </h5>
                </>
              ) : (
                <h5
                  className="opacity6"
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {" "}
                  <FaClock />{" "}
                  {detail.started_at && (
                    <TimeStamp date={new Date(detail?.started_at)} />
                  )}
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDivider />

      <div className="card-block">
        <div className="order-restaurant-details d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={detail?.restaurant?.image || NoAvatar}
              alt=""
              style={{
                marginRight: "20px",
              }}
            />
            <div className=" d-flex flex-column align-items-start  ">
              <h5
                className="text-white cursor-pointer"
                onClick={() => {
                  history.push(
                    `/restaurant-detail/${detail?.restaurant?.id}?view=overall`
                  );
                }}
              >
                <b>{detail?.restaurant?.name}</b>
              </h5>
              <h5 className="mb-0 opacity6">
                <FaMapMarkerAlt color="white" /> &nbsp;
                {detail?.restaurant?.city}, {detail?.restaurant?.state}
              </h5>
            </div>
          </div>
          <div
            onClick={() => {
              history.push(
                `/restaurant-detail/${detail?.restaurant?.id}?view=overall`
              );
            }}
            className=" d-flex flex-column align-items-center justify-content-end cursor-pointer "
          >
            <h5 className="text-red ">
              View Profile{" "}
              <i className="fas fa-angle-right" style={{ color: "#ed2626" }} />{" "}
            </h5>
          </div>
        </div>
      </div>

      <MyDivider />
      <div className="card-block">
        <div className="order-date-details d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column ">
            <h5 className="opacity6">Date</h5>
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              {moment(detail?.createdat).format("ddd MMM DD, YYYY")}
            </h5>
          </div>
          <div className="checked-in-checked-out-div d-flex align-items-center">
            <div className="d-flex flex-column align-items-end">
              <h5 style={{ color: "#8f949a" }}>Checked In</h5>
              <h5
                style={{
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                {moment(detail?.createdat).format("h:mm a")}
              </h5>
            </div>
            {detail?.checked_out_at !== null ? (
              <div className="d-flex flex-column align-items-end">
                <h5 style={{ color: "#8f949a" }}>Checked Out</h5>
                <h5
                  style={{
                    fontSize: "15px",
                    fontWeight: "normal",
                  }}
                >
                  {moment(detail?.checked_out_at).format("h:mm a")}
                </h5>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <MyDivider />
      <div className="card-block">
        <div className="guets-server-details d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h5 className="opacity6">{detail?.guests?.length} Guests</h5>
            <div className="d-flex align-items-center">
              <AvatarGroup>
                {detail?.guests
                  ?.filter((el) => el?.is_host === true)
                  .map((guest, _i) => {
                    return (
                      <div
                        style={{ position: "relative", border: "0" }}
                        className="host-avatar cursor-pointer"
                        onClick={() => {
                          setIsHostGuestPopUpShown(!isHostGuestPopUpShown);
                        }}
                        ref={hostRef}
                        key={_i}
                      >
                        <Avatar
                          alt={guest?.name || ""}
                          src={guest?.image || NoAvatar}
                          style={{
                            width: "36px",
                            height: "36px",
                            marginRight: "10px",
                          }}
                        />
                        <div
                          className="host-avtar-star-icon"
                          style={{
                            position: "absolute",
                            right: "9px",
                            bottom: "-3px",
                            width: "17px",
                            height: "17px",
                            backgroundColor: "#f7a64c",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // padding: "5px 1px 1px 3px",
                          }}
                        >
                          <i
                            style={{
                              fontSize: "10px",
                              position: "relative",
                              color: "#fff",
                              // top: "-1px",
                              // left: "3px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="fas fa-star"
                          />
                        </div>
                        {isHostGuestPopUpShown ? (
                          <GuestsInfoContainer>
                            <div className="guest-profile-info-header d-flex mb-1 justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="guestprofile-avatar">
                                  <Image
                                    style={{ borderRadius: "50%" }}
                                    src={guest?.image || NoAvatar}
                                    width={92}
                                  />
                                  <div className="customer-profile-rating">
                                    <div className="rating-container">
                                      <div className="rating-icon">
                                        <i className="fas fa-star" />
                                      </div>
                                      <span className="rating-number">
                                        {guest?.total_ratings || 0}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex flex-column ">
                                  <h5 style={{ marginBottom: "15px" }}>
                                    <b>{guest?.name}</b>
                                  </h5>
                                  <h5
                                    style={{ marginBottom: "15px" }}
                                    className=" opacity6"
                                  >
                                    Joined:&nbsp;
                                    {moment(guest?.joined_at).format(
                                      "MMMM DD, YYYY, HH:MM A "
                                    )}
                                  </h5>
                                  <h5
                                    style={{ marginBottom: "15px" }}
                                    className=" opacity6"
                                  >
                                    <FaMapMarkerAlt color="white" />-
                                  </h5>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  history.push(
                                    `/customer-detail/${guest?.user_id}`
                                  );
                                }}
                              >
                                <b
                                  className="text-red cursor-pointer"
                                  style={{
                                    fontSize: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  View Profile{" "}
                                  <i
                                    className="fas fa-angle-right"
                                    style={{
                                      color: "#ed2626",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </b>
                              </div>
                            </div>
                            <div
                              className="d-flex justify-content-between mb-5 "
                              style={{
                                marginTop: "28px",
                                gap: "15px",
                              }}
                            >
                              <div
                                className="d-flex flex-column"
                                style={{ marginBottom: "6px" }}
                              >
                                <h5 className=" opacity8">
                                  {guest?.feast_here || 0}
                                </h5>
                                <h5 className=" opacity6">Feasts here</h5>
                              </div>
                              <div className="d-flex flex-column ">
                                <h5 className=" opacity8">
                                  {guest?.total_feast || 0}
                                </h5>
                                <h5 className=" opacity6">Total feasts</h5>
                              </div>
                              <div className="d-flex flex-column ">
                                <h5 className=" opacity8">0</h5>
                                <h5 className=" opacity6">Reviews left</h5>
                              </div>
                              <div
                                className="d-flex flex-column"
                                style={{
                                  marginBottom: "6px",
                                  textAlign: "right",
                                }}
                              >
                                <h5 className=" opacity8">0</h5>
                                <h5 className=" opacity6">
                                  Time spent on our app
                                </h5>
                              </div>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center guest-check-out-btn">
                              <b className="text-red cursor "></b>
                              {detail.status === 2 ||
                              detail?.guests?.length === 1 ? (
                                <></>
                              ) : (
                                <MyButton
                                  type="redColor"
                                  onClick={() => {
                                    onSelectGuestCheckout(guest);
                                  }}
                                >
                                  Check Out
                                </MyButton>
                              )}
                            </div> */}
                          </GuestsInfoContainer>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
              </AvatarGroup>
              <AvatarGroup>
                {detail?.guests
                  ?.filter((el) => el.is_host !== true)
                  .map((guest, _i) => {
                    return (
                      <GuestsInfo
                        orderDetails={detail}
                        guest={guest}
                        key={_i}
                        onSelectGuestCheckout={onSelectGuestCheckout}
                      />
                    );
                  })}
              </AvatarGroup>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end">
            <div className="d-flex align-items-center">
              <h5 style={{ fontSize: "15px" }} className="opacity6">
                Server &nbsp;
              </h5>
              <h5
                className="text-red cursor-pointer d-flex align-items-center"
                onClick={() => {
                  setRestaurant({});
                  if (Object.keys(restaurant || {}).length !== 0) {
                    setRestaurant({});
                  } else {
                    history.push(
                      `/restaurant-detail/${detail?.restaurant?.id}?view=staff&mode=all`
                    );
                  }
                }}
                style={{ fontSize: "15px" }}
              >
                {detail?.server?.name}{" "}
                <i
                  className="fas fa-angle-right"
                  style={{ color: "#ed2626", marginLeft: "5px" }}
                />
              </h5>
            </div>
            <Avatar
              src={detail?.server?.image || NoAvatar}
              style={{ width: "40px", height: "40px" }}
            />
            {detail?.server?.rating > 0 ? (
              <h5
                style={{
                  marginTop: "10px",
                }}
                className="ml-auto d-flex align-items-center"
              >
                {Array.from({ length: detail?.server?.rating?.substring(0, 1) }, (v, i) => {
                  return (
                    <FaStar className="mx-1" style={{color: '#FDCB6E'}}/>                   
                  )
                })}
                {Array.from({ length: result }, (v, i) => {
                  return (
                    <FaStar className="mx-1" style={{color : '#3E4245'}} />                   
                  )
                })}

                <div className="ml-2">({detail?.server?.rating?.substring(0, 1) || 0})</div>
                <b
                  className="text-red ml-2 cursor"
                  onClick={onClickReviewServer}
                >
                  {`Reviews >`}
                </b>
              </h5>
            ) : null}
          </div>
        </div>
      </div>

      <DrawerReviewServer
        isActive={!!openDrawers[drawersOrder.reviewServer]}
        closePanel={() => closeDrawer(drawersOrder.reviewServer)}
        orderDetails={detail}
        isOrderServer={"isOrderServer"}
      />
      <DrawerDeleteReview
        isActive={!!openDrawers[drawersOrder.deleteReview]}
        closePanel={() => closeDrawer(drawersOrder.deleteReview)}
        onDeleteReview={onDeleteReview}
      />
      <DrawerReviewDeleted
        isActive={!!openDrawers[drawersOrder.deletedReview]}
        closePanel={() => closeDrawer(drawersOrder.deletedReview)}
      />
      <DrawerPartialRefund
        isActive={partialRefundModals.partialRefund}
        closePanel={() => {
          setPartialRefundModals({
            ...partialRefundModals,
            partialRefund: false,
          });
          setPaidOrderActionsModal(!paidOrderActionsModal);
        }}
        orderDetails={detail}
        partialDiscountHandler={partialDiscountHandler}
      />
      <DrawerFullRefund
        isActive={fullRefundModals.fullRefund}
        closePanel={() => {
          setFullRefundModals({
            ...fullRefundModals,
            fullRefund: false,
            data: "",
          });
        }}
        orderDetails={detail}
        onClickContinueFullRefund={() => {
          setFullRefundModals({
            ...fullRefundModals,
            fullRefund: false,
            fullRefundConfirmation: true,
            data: "",
          });
        }}
      />
      <DrawerRemoveItem
        isActive={!!openDrawers[drawersOrder.removeOrder]}
        closePanel={() => closeDrawer(drawersOrder.removeOrder)}
      />
      <DrawerApplyDiscount
        isActive={openApplyDiscountModal}
        closePanel={() => {
          // setIsACtiveOrderModalShown(!isActiveOrderActionModalShown);
          setOpenApplyDiscountModal(!openApplyDiscountModal);
          setPercents("");
          setDollars("");
          setPaymentOption("");
        }}
        discountType={paymentType}
        onChangeType={onChangePaymentType}
        discountOption={paymentOption}
        onChoose={onChangePercentageOption}
        onApply={onApplyDiscount}
        isOther={isOther}
        percents={percents}
        setPercents={setPercents}
        dollars={dollars}
        setDollars={setDollars}
        onSwitchToOtherAndSetPercentage={onSwitchToOtherAndSetPercentage}
        isPercentageActive={isFirstType}
        isFixedActive={isSecondType}
        activeOrderDetail={detail}
      />
      {/* <DrawerApplyDiscountAreYouSure
        isActive={!!openDrawers[drawersOrder.discountConfirm]}
        closePanel={() => closeDrawer(drawersOrder.discountConfirm)}
        discount={discount}
        discountTypes={_discountTypes}
        applyDiscountForActiveOrder={applyDiscountForActiveOrder}
      /> */}
      <DrawerGuestCheckout
        isActive={!!openDrawers[drawersOrder.guestsCheckout]}
        closePanel={() => closeDrawer(drawersOrder.guestsCheckout)}
        selectedGuestInfo={selectedGuestInfo}
        onCheckoutGuest={onCheckoutGuest}
      />
      <DrawerLastGuestCheckout
        isActive={!!openDrawers[drawersOrder.lastGuestCheckout]}
        closePanel={() => closeDrawer(drawersOrder.lastGuestCheckout)}
        selectedGuestInfo={selectedGuestInfo}
        onCheckoutLastGuest={onCheckoutLastGuest}
      />
      <DrawerProcessPayment
        isActive={paymentDrawers.processPayment}
        // isActive={true}
        closePanel={() =>{
          setPaymentDrawers({ ...paymentDrawers, processPayment: false })
        }
        }
        activeOrderDetail={detail}
        setIsActiveOrderModalShown={closeDrawer.setOpenOrderModal}
        // setIsActiveOrderModalShown={setIsACtiveOrderModalShown}
      />
      <DrawerDeleteSubOrder
        isActive={!!openDrawers[drawersOrder.deleteOrder]}
        closePanel={() => closeDrawer(drawersOrder.deleteOrder)}
        orderDelete={true}
      />
      <DrawerDeleteSubOrderConfirmation
        isActive={!!openDrawers[drawersOrder.deleteOrderConfirmation]}
        closePanel={() => closeDrawer(drawersOrder.deleteOrderConfirmation)}
        orderDelete={true}
        onDeleteSubOrder={deleteMainOrder}
      />
      <DrawerDeletedSubOrder
        isActive={!!openDrawers[drawersOrder.deletedOrderMessage]}
        closePanel={() => closeDrawer(drawersOrder.deletedOrderMessage)}
        orderDelete={true}
        onSucessFullDeleteOrder={onSucessFullDeleteOrder}
      />
      <ActiveOrderActionsModal
        isActive={!!openDrawers[drawersOrder.setOpenOrderModal]}
        closePanel={() => closeDrawer(drawersOrder.setOpenOrderModal)}
        setOpenApplyDiscountModal={setOpenApplyDiscountModal}
        onClickProcessPayment={onClickProcessPayment}
        onClickDeleteFullOrder={onClickDeleteFullOrder}
        setOpenApplyPromoCodeModal={setOpenApplyPromoCodeModal}
        setOpenAssignNewServerModal={setOpenAssignNewServerModal}
      />
      <PaidOrderActionsModal
        isActive={!!openDrawers[drawersOrder.setOpenPaidOrderModal]}
        closePanel={() => closeDrawer(drawersOrder.setOpenPaidOrderModal)}
        onClickDeleteFullOrder={onClickDeleteFullOrder}
        onClickPartitialRefund={onClickPartitialRefund}
        onClickFullRefund={onClickFullRefund}
      />
      <ApplyPromoCodeModal
        isActive={openApplyPromoCodeModal}
        closePanel={() => {
          // setIsACtiveOrderModalShown(!isActiveOrderActionModalShown);
          setOpenApplyPromoCodeModal(!openApplyPromoCodeModal);
          setPromoCode("");
        }}
        applyPromoCodeHandler={applyPromoCodeHandler}
        setPromoCode={setPromoCode}
        promoCode={promoCode}
      />
      <AssignNewServerModal
        isActive={openAssignNewServerModal}
        closePanel={() => {
          // setIsACtiveOrderModalShown(!isActiveOrderActionModalShown);
          setOpenAssignNewServerModal(!openAssignNewServerModal);
          setCheckInData();
        }}
        setCheckInData={setCheckInData}
        checkInData={checkInData}
        assignNewServerHandler={assignNewServerHandler}
      />
      <NewServerAssignedModal
        isActive={opneNewAssignedServerModal}
        closePanel={() => {
          setOpenNewAssignedServerModal(!opneNewAssignedServerModal);
          setCheckInData();
        }}
        checkInData={checkInData}
      />
      <DeleteEntireOrderModal
        isActive={openEntireDeleteOrderModal}
        closePanel={() => {
          setOpenEntireDeleteOrderModal(!openEntireDeleteOrderModal);
          // if (detail?.status !== 2) {
          //   setIsACtiveOrderModalShown(!isActiveOrderActionModalShown);
          // }
        }}
        onDeleteEntireOrder={deleteMainOrder}
      />
      <ConfirmEntireOrderDeleteModal
        isActive={openEntireOrderDeletedMessageModal}
        closePanel={() => {
          setOpenEntireOrderDeletedMessageModal(
            !openEntireOrderDeletedMessageModal
          );
          onSucessFullDeleteOrder();
        }}
        onSucessFullDeleteOrder={onSucessFullDeleteOrder}
      />
      <RefundConfirmationModal
        isActive={partialRefundModals.refundConfirmation}
        // isActive={true}
        closePanel={() => {
          setPartialRefundModals({
            ...partialRefundModals,
            partialRefund: true,
            refundConfirmation: false,
          });
          // setPaidOrderActionsModal(!paidOrderActionsModal);
        }}
        partialRefundData={partialRefundModals?.data}
        applyPartialRefundHandler={applyPartialRefundHandler}
      />
      <FullRefundConfirmationModal
        isActive={fullRefundModals.fullRefundConfirmation}
        closePanel={() => {
          setFullRefundModals({
            ...fullRefundModals,
            fullRefund: false,
            fullRefundConfirmation: false,
            data: "",
          });
        }}
        fullRefundHandler={fullRefundHandler}
      />
    </BlockOrderInfoContainer>
  );
}

export const computeDiscount = (
  activeOrder,
  currentUserDiscount,
  isPercentDiscount = false
) => {
  const totalPay = Number(activeOrder?.checkout_data?.total);
  if (isPercentDiscount) {
    const dollars = totalPay * (currentUserDiscount / 100);

    return dollars.toFixed(2);
  } else {
    const percents = (currentUserDiscount / totalPay) * 100;

    return percents.toFixed(2);
  }
};
