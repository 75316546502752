import React, { useState } from "react";
import { OopsCustomerModalContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import pendingOrderError from "assets/img/pendingOrdersError.png";
import { DrawerContainer } from "components/styledStyle";

const CustomerErrorModal = ({
  isActive = false,
  closePanel = () => {},
  floorName,
}) => {
  return (
    <OopsCustomerModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"37%"}
      // position="absolute"
      width="472px"
      zIndexBackground={999999}
      zIndex={9999999}
    >
      <DrawerContainer>
        <div className="restaurant-decline-div">
          <img
            src={pendingOrderError}
            alt="order deleted"
            className="img-leave-error"
          />
          <div className="modal-title">
            <b>Oops!</b>
          </div>
          <p>This customer could not be deleted due to the active order(s).</p>
          <div className="modal-bottom">
            <MyDrawerButton
              type={"red"}
              className="decline-btn"
              onClick={() => {
                closePanel();
              }}
            >
              <b>Okay</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </OopsCustomerModalContainer>
  );
};

export default CustomerErrorModal;
