import styled from "@emotion/styled";
import { MyDrawerLeftArrow } from "components/Elements/MyDrawerLeftArrow";

export const DrawerFloorsContainer = styled(MyDrawerLeftArrow)`
  .mt-auto {
    margin-bottom: 20px;
  }
  .drawer-title {
    margin-bottom: 40px;
  }
  .icon-drag {
    cursor: grab;
  }

  .drag-floor-item {
    border: solid 1px rgba(255, 255, 255, 0.1);
    margin: 0 0px 15px 0px;
    border-radius: 20px;
    padding-left: 20px;
  }
  .new-btn {
    width: 100%;
    background: transparent !important;
    border: solid 1px rgba(255, 255, 255, 0.1) !important;
    border-radius: 20px;
    text-align: left;
    color: #ed2626 !important;
    height: 62px;
  }
`;
