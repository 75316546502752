import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { PhotoUploader } from "components/Elements/PhotoUploader";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { memo, useContext, useEffect, useState } from "react";
import {
  FaTrash,
  FaEllipsisH,
  FaTimes,
  FaChevronRight,
  FaCheckCircle,
  FaTimesCircle,
  FaChevronLeft,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
// import { restaurantServices } from "services";
import { dayTitles, drawersOverall } from "static/staticData";
import { colors } from "theme";
import {
  DrawerEditProfileContainer,
  DrawerMobileNumberInputWithVerifyContainer,
} from "./style";
import NoAvatar from "assets/img/noAvatar.png";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";
import NumberFormat from "react-number-format";
import RestaurantEditMoreModal from "app/pages/RestaurantProfilePage/Modals/RestaurantEditMoreModal";
import { Image } from "react-bootstrap";
import { DefaultFood } from "assets/img";
import { PhotoUploaderFormData } from "components/Elements/PhotoUploaderFormData";

const DrawerEditProfile = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
  setNotification = () => {},
  // setRestaurantEditMoreModal = () => {},
}) => {
  const { id } = useParams();

  const {
    openDrawer,
    postRestaurant,
    setPostRestaurant,
    restaurant,
    setRestaurant,
    selectedCuisines,
    managerDetail,
    latlng,
    notesData,
    setSelectedCuisines,
    setCuisinesData,
    cuisineGlobalData,
    restaurantEditMoreModal,
    setRestaurantEditMoreModal,
    banRestaurantModal,
    setBanRestaurantModal,
  } = useContext(RestaurantsContext);
  const [profileAssets, setProfileAssets] = useState();

  function getCuisineData() {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
        setCuisinesData(res?.data);
        let data = [...selectedCuisines];
        res &&
          res.data.map((cuisine, index) => {
            cuisineGlobalData &&
              cuisineGlobalData.map((_item, i) => {
                if (cuisine.name === _item) {
                  data.push(cuisine.id);
                  setSelectedCuisines(data);
                }
              });
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (isActive) {
      getCuisineData();
    } else {
      setProfileAssets(null);
      setPostRestaurant({
        ...postRestaurant,
        photo: restaurant?.photo,
      });
    }
  }, [isActive]);

  const onSaveProfile = async () => {
    // let payload = { ...postRestaurant };
    // if (payload.photo) payload.photo = payload.photo.id;
    // delete payload.cuisines;

    // const result = await restaurantServices.updateRestaurant(id, payload);
    // if (result.status === 200) {
    //   onDoneSaveProfile();
    // }

    const uniqueChars = selectedCuisines.filter((c, index) => {
      return selectedCuisines.indexOf(c) === index;
    });

    // const updateDataConfig = {
    //   // photo: profileAssets,
    //   title: postRestaurant.title,
    //   phone_number: postRestaurant.phone_number,
    //   email: postRestaurant.email,
    //   website: postRestaurant.website,
    //   address: postRestaurant.address,
    //   price_level: postRestaurant.price_level,
    //   manager_name: postRestaurant.manager_name,
    //   manager_title: postRestaurant.manager_title,
    //   cuisines: uniqueChars,
    //   working_hours: postRestaurant.working_hours,
    //   status: postRestaurant.status,
    //   email_verified: postRestaurant.email_verified,
    // };

    // FormData
    const restaurantPutFormData = new FormData();
    if (!postRestaurant.photo.asset_type) {
      restaurantPutFormData.append("asset", profileAssets);
    }
    // profileAssets && restaurantPutFormData.append("asset", profileAssets);
    postRestaurant.title &&
      restaurantPutFormData.append("title", postRestaurant.title);
    postRestaurant.phone_number &&
      restaurantPutFormData.append("phone_number", postRestaurant.phone_number);
    postRestaurant.email &&
      restaurantPutFormData.append("email", postRestaurant.email);
    postRestaurant.website &&
      restaurantPutFormData.append("website", postRestaurant.website);
    postRestaurant.address &&
      restaurantPutFormData.append("address", postRestaurant.address);

    postRestaurant.price_level &&
      restaurantPutFormData.append("price_level", postRestaurant.price_level);
    postRestaurant.manager_name &&
      restaurantPutFormData.append("manager_name", postRestaurant.manager_name);
    postRestaurant.manager_title &&
      restaurantPutFormData.append(
        "manager_title",
        postRestaurant.manager_title
      );

    uniqueChars?.length > 0 &&
      restaurantPutFormData.append("cuisines", JSON.stringify(uniqueChars));
    // postRestaurant?.working_hours && restaurantPutFormData.append("working_hours", JSON.stringify(postRestaurant?.working_hours));
    postRestaurant.status &&
      restaurantPutFormData.append("status", postRestaurant.status);

    postRestaurant.email_verified &&
      restaurantPutFormData.append(
        "email_verified",
        postRestaurant.email_verified
      );

    customAxios
      .put(`/restaurant/${postRestaurant?.id}`, restaurantPutFormData)
      .then((res) => {
        setRestaurant({ ...postRestaurant });
        closePanel();
        restaurantGetApi();
        setNotification({
          message: "Restaurant Profile Updated",
          table: "",
          type: "restaurant-update",
        });
        customAxios
        .put(`/restaurant/${postRestaurant?.id}`, {
          working_hours: postRestaurant?.working_hours
        })
        .then((res) => {
          setRestaurant({ ...postRestaurant });
        })
        .catch((err) => {
          console.error(err);
        });
      })
      .catch((err) => {
        console.error(err);
      });


    


    // customAxios
    //   .put(`/restaurant/${postRestaurant?.id}`, updateDataConfig)
    //   .then((res) => {
    //     setRestaurant({ ...postRestaurant });
    //     closePanel();
    //     restaurantGetApi();
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };

  const onUploadSuccess = (asset) => {
    // setProfileAssets(asset.id);
    setProfileAssets(asset);
    setPostRestaurant({ ...postRestaurant, photo: asset });
  };

  useEffect(() => {
    if (restaurant?.status !== 3 && restaurant?.status !== undefined) {
      restaurantGetApi();
    }
  }, [postRestaurant?.id, id]);

  function restaurantGetApi() {
    customAxios
      .get(`/restaurant/${id}`)
      .then((res) => {
        setRestaurant(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // business hours
  const getWorkingHour = (schedule) => {
    // console.log(schedule)
    if (schedule?.length > 0) {
      const day = new Date().getDay() - 1;
      let finalTime;
      schedule.map((_time) => {
        if (_time.day === day) {
          const timeFrom = moment
            .utc(+_time.times[0][0] * 1000)
            .format("hh:mm A");
          const timeTo = moment
            .utc(+_time.times[0][1] * 1000)
            .format("hh:mm A");
          finalTime = `${timeFrom} - ${timeTo}`;
        }
        return;
      });
      return finalTime;
    } else {
      return <p>-</p>;
    }
  };

  function onClickCancel() {
    closePanel();
  }

  function convertTimeToReadableFormat(time) {
    const formattedTime = time % (12 * 60 * 60);
    const hr = Math.floor(formattedTime / (60 * 60));
    const min = (formattedTime - hr * 60 * 60) / 60;
    const dateTime = Math.floor(time / (12 * 60 * 60)) >= 1 ? "PM" : "AM";

    return `${hr < 10 ? "0" + hr : hr}:${min === 0 ? "00" : min} ${dateTime}`;
    // console.log(`${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`)
  }

  return (
    <DrawerEditProfileContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={<FaEllipsisH size={18} color={colors.redColor} />}
      onClickRightIcon={() => {}}
    >
      <div className="header-bg">
        <div className="d-flex  align-items-center justify-content-between close-drawer-icon">
          <div>
            <FaChevronLeft
              size={20}
              style={{ zIndex: "9999999" }}
              className="cursor-pointer "
              onClick={closePanel}
            />
          </div>
          <div className="drawer-title">Edit Restaurant</div>
          <div>
            <FaEllipsisH
              size={18}
              className="pointer"
              onClick={() => {
                setRestaurantEditMoreModal(true);
              }}
            />
          </div>
        </div>
        <Image
          src={
            postRestaurant?.photo?.file ? postRestaurant?.photo?.file : NoAvatar
          }
          className="bg-img"
        />
      </div>
      <DrawerContainer style={{ position: "relative", bottom: "10rem" }}>
        <div className="upload-profile-wrapper d-flex justify-content-center">
          <PhotoUploaderFormData
            src={
              postRestaurant?.photo?.file
                ? postRestaurant?.photo?.file
                : NoAvatar
            }
            height={"182px"}
            width={"182px"}
            onUploadSuccess={onUploadSuccess}
          />
        </div>

        <div className="mb-5">
          <DrawerInput
            label="Restaurant Name"
            placeholder="Restaurant Name"
            value={postRestaurant.title}
            onChange={(e) =>
              setPostRestaurant({ ...postRestaurant, title: e.target.value })
            }
          />
          {/* <DrawerInputWithVerify
            label="Mobile Number"
            placeholder="Set Mobile Number"
            value={postRestaurant.phone_number}
            onChange={(e) =>
              setPostRestaurant({
                ...postRestaurant,
                phone_number: e.target.value,
              })
            }
            onClick={() => openDrawer(drawersOverall.verifyPhone)}
          /> */}
          <DrawerMobileNumberInputWithVerifyContainer className="">
            {<h6 className="mb-0">Phone Number</h6>}
            <div className="drawer-input-container">
              {/* <input
                type="text"
                value={postRestaurant.phone_number || ""}
                placeholder={"Set Mobile Number"}
                onChange={(e) =>
                  setPostRestaurant({
                    ...postRestaurant,
                    phone_number: e.target.value,
                  })
                }
              /> */}
              <NumberFormat
                format={"+# (###) ###-####"}
                className={`single-ant-format-input`}
                value={postRestaurant.phone_number || ""}
                onValueChange={(e) => {
                  setPostRestaurant({
                    ...postRestaurant,
                    phone_number: e?.value,
                  });
                }}
                onBlur={() => {}}
                placeholder={"Set Mobile Number"}
                // style={style}
              />
            </div>
            {/* <div
              className="input-icon"
              onClick={() => openDrawer(drawersOverall.verifyPhone)}
            >
              <FaChevronRight size={15} color={colors.white3} />
            </div> */}
          </DrawerMobileNumberInputWithVerifyContainer>
          <DrawerInputWithVerify
            label="Website"
            placeholder="Set Website"
            value={postRestaurant.website}
            onChange={(e) =>
              setPostRestaurant({ ...postRestaurant, website: e.target.value })
            }
            // onClick={() => openDrawer(drawersOverall.verifyWebsite)}
            isHideArrowIcon={true}
            isVerifiedShow={false}
          />

          {/* <div
            className="d-flex"
            style={{ position: "relative", top: "16%", left: "40%" }}
          >
            {postRestaurant.email_verified === true ? (
              <FaCheckCircle className="ml-3" color={colors.green} />
            ) : (
              <FaTimesCircle className="ml-3" color={colors.redColor} />
            )}
          </div> */}

          <DrawerInputWithVerify
            label="Email Address"
            placeholder="Set Email Address"
            value={postRestaurant.email}
            onChange={(e) =>
              setPostRestaurant({
                ...postRestaurant,
                email: e.target.value,
              })
            }
            onClick={() => openDrawer(drawersOverall.verifyEmail)}
            isVerifiedShow={true}
            verified={postRestaurant.email_verified}
          />

          {/* <DrawerInput
            label="Address"
            placeholder="Set Address"
            value={postRestaurant.address}
            onChange={(e) =>
              setPostRestaurant({ ...postRestaurant, address: e.target.value })
            }
          /> */}

          <ItemPicker
            label="Address"
            placeholder="Search Location"
            className="search-address"
            value={postRestaurant.address}
            onChange={(e) =>
              setPostRestaurant({ ...postRestaurant, address: e.target.value })
            }
            onClick={() => openDrawer(drawersOverall.foundAddress)}
          />
        </div>

        <div className="divide-section">
          <ItemPicker
            label="Price Tier"
            placeholder="Set Price Tier"
            value={
              postRestaurant.price_level &&
              Array.from(
                { length: postRestaurant.price_level },
                (v, i) => "$"
              ).join("")
            }
            onClick={() => openDrawer(drawersOverall.priceTier)}
          />
          <ItemPicker
            label="Cuisine"
            placeholder="Set Cuisine"
            value={
              postRestaurant.cuisinesName &&
              postRestaurant.cuisinesName.length > 0
                ? postRestaurant.cuisinesName.join(", ")
                : postRestaurant.cuisines && postRestaurant.cuisines.length > 0
                ? postRestaurant.cuisines.join(", ")
                : ""
            }
            onClick={() => openDrawer(drawersOverall.cuisines)}
          />
           
          <ItemPicker
            label="Business Hours"
            placeholder="Set Business Hours"
            // value={
            //   postRestaurant.working_hours &&
            //   postRestaurant.working_hours.length > 0
            //     ? "Set"
            //     : "Not available"
            // }
            // value={postRestaurant.working_hours && getWorkingHour(postRestaurant?.working_hours)}
            value={
              postRestaurant?.working_hours?.length > 0
                ? postRestaurant?.working_hours
                    .map((_hour) => dayTitles[_hour.day]?.key)
                    .join(", ")
                : ""
            }
            onClick={() => openDrawer(drawersOverall.businessHours)}
          />

          <ItemPicker
            label="Manager"
            placeholder="Set Manager"
            value={postRestaurant.manager_name}
            // onClick={() => openDrawer(drawersMenu.availabilities)}
            onClick={() => openDrawer(drawersOverall.addManager)}
          />
        </div>
        <div className="divide-section">
          <ItemPicker
            label="Status"
            placeholder="Set Status"
            value={postRestaurant.status === 0 ? "Inactive" : "Active"}
            onClick={() => openDrawer(drawersOverall.statusOverall)}
          />
          {/* <ItemPicker
            label={null}
            placeholder={null}
            // value={`View notes (${postRestaurant.reviews_count})`}
            value={`View notes (${notesData?.length})`}
            onClick={() => openDrawer(drawersOverall.notes)}
          /> */}
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold"
            onClick={onSaveProfile}
          >
            Save
          </MyDrawerButton>
          <div className="cancel-btn text-bold" onClick={onClickCancel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
      <RestaurantEditMoreModal
        isActive={restaurantEditMoreModal}
        setBanRestaurantModal={setBanRestaurantModal}
        closePanel={() => {
          setRestaurantEditMoreModal(false);
        }}
        status={postRestaurant?.status}
      />
    </DrawerEditProfileContainer>
  );
};

export default memo(DrawerEditProfile);
