/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { DrawerSetStatusContainer } from "./style";
import { customerServices } from "services";
import { CustomersContext } from "context/CustomersContext";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerSetStatus({
  type = "Active",
  isActive = false,
  closePanel = () => {},
  setSelectedCustomers,
  selectedCustomers,
  customerListTab,
}) {
  const { customerId, setCustomersList, customerName } = useContext(
    CustomersContext
  );

  const [customerActiveNote, setCustomerActiveNote] = useState("");

  const [inactiveReason, setInactiveReason] = useState("");
  const [customerInactiveNote, setCustomerInactiveNote] = useState("");

  const [
    customerActiveNoteNotification,
    setCustomerActiveNoteNotification,
  ] = useState();
  const [
    customerInactiveNoteNotification,
    setCustomerInactiveNoteNotification,
  ] = useState();

  useEffect(() => {
    if (customerActiveNoteNotification) {
      setTimeout(() => setCustomerActiveNoteNotification(null), 2 * 1000);
    }
  }, [customerActiveNoteNotification]);

  useEffect(() => {
    if (customerInactiveNoteNotification) {
      setTimeout(() => setCustomerInactiveNoteNotification(null), 2 * 1000);
    }
  }, [customerInactiveNoteNotification]);

  function activeStatus(e) {
    e.preventDefault();
    if (customerId) {
      const updateStatusDataConfig = {
        customer_ids: customerId,
        status: 1,
        active: true,
        note: customerActiveNote,
      };
      customAxios
        .put(`/customer/status`, updateStatusDataConfig)
        .then((res) => {
          getCustomerList();

          closePanel();
          clearInputs();
          setCustomerActiveNoteNotification({
            message: "Status changed to Active.",
            type: "promo-active",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${selectedCustomers?.length} customers </span> status changed to Active.`,
          });

          setSelectedCustomers([]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function inactiveStatus(e) {
    e.preventDefault();
    if (customerId) {
      const updateStatusDataConfig = {
        customer_ids: customerId,
        status: 0,
        note: customerInactiveNote,
        note_to_customer: inactiveReason,
        active: false,
      };
      customAxios
        .put(`/customer/status`, updateStatusDataConfig)
        .then((res) => {
          getCustomerList();
          closePanel();
          clearInputs();
          setCustomerInactiveNoteNotification({
            message: "Status changed to Inactive.",
            type: "inactive-success",
            table: `The status of <span style="font-weight:bold; color:#7d7e7f; margin-left:0">${selectedCustomers?.length} customers</span> changed to Inactive.`,
          });
          setSelectedCustomers([]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const getCustomerList = async () => {
    customAxios
      .get(`/customer/list/${customerListTab}`)
      .then((res) => {
        setCustomersList(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function clearInputs() {
    setCustomerActiveNote("");
    setInactiveReason("");
    setCustomerInactiveNote("");
  }

  function onClickCancel() {
    clearInputs();
    closePanel();
  }

  return (
    <DrawerSetStatusContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      top="25%"
    >
      <div className="set-status-wrapper mt-auto">
        {customerActiveNoteNotification && (
          <MyMessageBoxItemNew
            message={
              customerActiveNoteNotification?.message
                ? customerActiveNoteNotification?.message
                : ""
            }
            table={
              customerActiveNoteNotification?.table
                ? customerActiveNoteNotification?.table
                : ""
            }
            type={
              customerActiveNoteNotification?.type
                ? customerActiveNoteNotification?.type
                : ""
            }
            onClick={() => setCustomerActiveNoteNotification(null)}
          />
        )}

        {customerInactiveNoteNotification && (
          <MyMessageBoxItemNew
            message={
              customerInactiveNoteNotification?.message
                ? customerInactiveNoteNotification?.message
                : ""
            }
            table={
              customerInactiveNoteNotification?.table
                ? customerInactiveNoteNotification?.table
                : ""
            }
            type={
              customerInactiveNoteNotification?.type
                ? customerInactiveNoteNotification?.type
                : ""
            }
            onClick={() => setCustomerInactiveNoteNotification(null)}
          />
        )}

        <h2 className="text-white text-center text-bold mb-6">
          {type === "Inactive" ? "Set Inactive Status" : "Set Active Status"}
        </h2>
        {type === "Inactive" ? (
          <form>
            <div className="note-input">
              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Describe the Reason</div>
                  <div className="label-text">
                    Mandatory • Visible to customer
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={inactiveReason || ""}
                  placeholder="Type here"
                  onChange={(event) => setInactiveReason(event.target.value)}
                />
              </div>

              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Internal Note</div>
                  <div className="label-text">
                    Optional • Visible to Feasttt admins
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={customerInactiveNote || ""}
                  placeholder="Type here"
                  onChange={(event) =>
                    setCustomerInactiveNote(event.target.value)
                  }
                />
              </div>
            </div>
          </form>
        ) : (
          <form>
            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  Optional • Visible to Feasttt admins
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={customerActiveNote || ""}
                placeholder="Type here"
                onChange={(event) => setCustomerActiveNote(event.target.value)}
              />
            </div>
          </form>
        )}
        <div className="bottom-btns">
          {type === "Inactive" ? (
            <MyDrawerButton
              type={inactiveReason ? "red" : "gray2"}
              className="set-status-btn w-100"
              onClick={inactiveStatus}
              disabled={inactiveReason ? false : true}
            >
              Set Inactive
            </MyDrawerButton>
          ) : (
            <MyDrawerButton
              type="red"
              className="set-status-btn w-100"
              onClick={activeStatus}
            >
              Set Active
            </MyDrawerButton>
          )}
          <div className="cancel-btn" onClick={onClickCancel}>
            Cancel
          </div>
        </div>
      </div>
    </DrawerSetStatusContainer>
  );
}
