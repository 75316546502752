import { customAxios } from "helpers/custom-helpers/customAxios";

const getMyInfo = async () => await customAxios.get(`/admin/me`);
const updateMyInfo = async (payload, onOk, onFail) => {
    const { ok, errMessage } = await customAxios.put(`/admin/me`, payload);
    if (ok) {
        onOk()
    } else {
        onFail(errMessage)
    }
}

const changePassword = async (payload, onOk, onFail) => {
    const { ok, errMessage } = await customAxios.patch(`/admin/me/password`, payload);
    if (ok) {
        onOk()
    } else {
        onFail(errMessage)
    }
}

export const adminServices = {
    getMyInfo,
    updateMyInfo,
    changePassword,
};
