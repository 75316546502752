import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerDeleteCustomizationContainer = styled(MyDrawerRight)`
  background-color: ${colors.gray2};
  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }
`;
