import React, { useState, useContext, useEffect } from "react";
import { UseTimePerCustomerDrawerModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerContainer } from "components/styledStyle";
import { Switch } from "@material-ui/core";
import { FaChevronRight } from "react-icons/fa";

const UseTimePerCustomerDrawerModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
}) => {
  const {
    setUseTimesPerCustomer,
    localUseTimesPerCustomer,
    setLocalUseTimesPerCustomer,
    updatePromoCodeDetails,
    setUpdatePromoCodeDetails,
    useTimePerCustomerToggle,
    setUseTimePerCustomerToggle,
  } = useContext(RestaurantsContext);

  // const [localUseTimesPerCustomer, setLocalUseTimesPerCustomer] = useState()

  // const [statusToggle, setStatusToggle] = useState(false);

  function handleChangeToggle(event) {
    setUseTimePerCustomerToggle(event.target.checked);
  }

  function handleOnClick() {
    if (useTimePerCustomerToggle === true) {
      // setUseTimesPerCustomer("");
      setUseTimesPerCustomer(useTimePerCustomerToggle);
    } else {
      setUseTimesPerCustomer(localUseTimesPerCustomer);
    }
    closePanel();
  }

  useEffect(() => {
    if (Object.keys(updatePromoCodeDetails)?.length) {
      if (updatePromoCodeDetails.limit_user === null) {
        setUseTimePerCustomerToggle(true);
      } else {
        setUseTimePerCustomerToggle(false);
      }
    }
  }, [updatePromoCodeDetails.limit_user]);

  function onClickCancel() {
    setLocalUseTimesPerCustomer("")
    setUseTimePerCustomerToggle(false)
    closePanel()
  }
  
  return (
    <UseTimePerCustomerDrawerModalContainer
      isActive={isActive}
      closePanel={closePanel}
      // top="25rem"
      // bottom={statusToggle ? "29rem" : "25rem"}
      left="0"
      top="20%"
      position="absolute"
    >
      <DrawerContainer>
        <div className="total-use-times-container">
          <div className="title">Use Times per Customer</div>

          <div className="active-toggle mb-5">
            <div className="promo-apply">
              <div className="promo-apply-text">No Limit</div>
            </div>
            <div className="toggle-btn">
              <Switch checked={useTimePerCustomerToggle} onChange={handleChangeToggle} />
            </div>
          </div>

          {useTimePerCustomerToggle ? null : (
            <div className="total-time-input">
              <input
                type="text"
                value={localUseTimesPerCustomer || ""}
                placeholder="Enter use times per customer"
                onChange={(e) => setLocalUseTimesPerCustomer(e.target.value.replace(/\D/g, ""))}
              />
            </div>
          )}

          <div className="btn-container">
            <div
              className={
                localUseTimesPerCustomer?.length || useTimePerCustomerToggle
                  ? "done-red"
                  : "done-gray"
              }
              onClick={handleOnClick}
            >
              Done
            </div>
            <div className="cancel-btn cursor-pointer" onClick={onClickCancel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </UseTimePerCustomerDrawerModalContainer>
  );
};

export default UseTimePerCustomerDrawerModal;
