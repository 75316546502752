import styled from "@emotion/styled";
import { colors } from "theme";

export const MyAccountPageContainer = styled.div`
  .my-account-component {
    color: #fff;
    font-family: Montserrat;
    .my-account-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 37px;
      min-height: 88px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.08);
      .left-part {
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
        }
      }

      .right-part {
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 20px;
          color: #ff5a5a;
          margin-right: 10px;
        }
        span {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .my-account-component-body {
      padding: 30px 37px;

      .my-account-content {
        // display: flex;
        // align-items: center;
        width: 100%;
        background-color: ${colors.gray2};
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
        .my-account-avatar-body {
          display: flex;
          align-items: start;
          .profile-photo-label {
            display: flex;
            align-items: start;
            flex-direction: column;
            margin-right: 146px;
            .profile-title {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              color: #fff;
            }
            .profile-subtitle {
              opacity: 0.5;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: -0.34px;
              color: #fff;
            }
          }
        }
        .personal-info-container {
          margin-top: 35px;
          display: flex;
          .profile-photo-label {
            display: flex;
            align-items: start;
            flex-direction: column;
            margin-right: 120px;
            .profile-title {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              color: #fff;
            }
            .profile-subtitle {
              opacity: 0.5;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: -0.34px;
              color: #fff;
            }
          }
          .my-account-block {
            display: flex;
            .account-form-item {
              padding-top: unset;
              width: 542px;
              &.last-name-style {
                margin-left: 33px;
              }
            }
          }
        }
        .contact-info-container {
          margin-top: 35px;
          display: flex;
          .profile-photo-label {
            display: flex;
            align-items: start;
            flex-direction: column;
            margin-right: 146px;
            .profile-title {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              color: #fff;
            }
            .profile-subtitle {
              opacity: 0.5;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: -0.34px;
              color: #fff;
            }
          }
          .my-account-block {
            display: flex;
            .account-form-item {
              padding-top: unset;
              width: 542px;
              &.last-name-style {
                margin-left: 33px;
              }
              .single-ant-format-input {
                width: 100%;
                color: white;
                font-weight: 500;
                background-color: transparent;
                outline: unset;
                border: 0;
              }
            }
          }
        }
        .password-contianer {
          margin-top: 35px;
          display: flex;
          .profile-photo-label {
            display: flex;
            align-items: start;
            flex-direction: column;
            margin-right: 350px;
            .profile-title {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              color: #fff;
            }
          }
          .change-password-link {
            padding: 11px 20px 11px 20px;
            border-radius: 25px;
            background-color: #60646c;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            .fa-lock {
              color: #fff;
            }
          }
        }
        .account-info-wrapper {
          flex: 1;
          .my-account-block {
            display: flex;
            .account-form-item {
              flex: 1;
              margin-left: 60px;
              .input-label {
                label {
                  color: #fff;
                  opacity: 0.3;
                }
              }
              .single-ant-format-input {
                width: 100%;
                color: white;
                font-weight: 500;
                background-color: transparent;
                outline: unset;
                border: 0;
              }
              &.password-field {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .change-password {
                  display: flex;
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }

      .button-container {
        width: 100%;
        margin-top: 20px;
        text-align: end;
        .save-changes-button {
          width: 170px;
          height: 40px;
          border-radius: 25px;
          background-color: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.4);
          font-size: 14px;
          font-weight: 600;
          border: none;
        }
        .active {
          background-color: #ff5a5a;
          color: #fff;
        }
      }
    }
  }
`;
