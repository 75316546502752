import React, { useState, useContext, useEffect } from "react";
import { PopularListContainer } from "./style";
// import { PeriodFilter } from "../PeriodFilter";
import { DashboardContext } from "context/DashboardContext";
// import noAvatar from "assets/img/noAvatar.png";
import defaultFood from "assets/img/default-food.jpg";
import { Link } from "react-router-dom";
import { drawersOverall } from "static/staticData";
import DrawerItem from "app/pages/DashboardPage/Drawers/DrawerItem";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { PopupCalendarContainer } from "components/analytics/AnalyticPeriod/style";
import MyButton from "components/Elements/MyButton";
import MyDateRange from "components/Elements/MyDateRange";
import moment from "moment";
import useOnclickOutside from "react-cool-onclickoutside";
import { useHistory } from "react-router-dom";

// const amountFormatterDefault = (initial) => initial;

export const PopularList = ({
  className = "",
  title,
  list,
  // amountFormatter = amountFormatterDefault,
  isFood = false,
  popularRestaurantCalendar,
  setPopularRestaurantCalendar = () => {},
  setPopularRestaurantDateRange,
  popularRestaurantDateRange,
  popularItemCalendar,
  setPopularItemCalendar = () => {},
  setPopularItemDateRange,
  popularItemDateRange,
}) => {
  const history = useHistory();
  const {
    periodPopularRestaurants,
    setPeriodPopularRestaurants,
    periodPopularItems,
    setPeriodPopularItems,
    tempDateRange,
    setTempDateRange,
  } = useContext(DashboardContext);

  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    heightRateItem,
    setHeightRateItem,
    setFoodReviewsId,
  } = useContext(RestaurantsContext);

  const [popularItemRestaurantData, setPopularItemRestaurantData] = useState(
    []
  );

  // useEffect(() => {
  //   getRestaurantData();
  // }, []);

  function handleClickPopularItem(id, resId, image, name) {
    let itemData = {
      id: id,
      image: image,
      name: name,
    };
    setFoodReviewsId(itemData);
    if (resId) {
      customAxios
        .get(`/restaurant/${resId}?get_detail=true`)
        .then((res) => {
          res &&
            res.data &&
            res.data.foods.filter((_item) => {
              if (_item?.id === id) {
                setHeightRateItem(_item);
              }
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    openDrawer(drawersOverall?.dashboardDrawer);
  }

  // function getRestaurantData() {
  //   customAxios
  //     .get(`/restaurant`)
  //     .then((res) => {
  //       res && res.data && res.data.restaurants.map((_el) => setPopularItemRestaurantData(_el));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const ref = useOnclickOutside(() => {
    setPopularRestaurantCalendar(false);
    setPopularItemCalendar(false);
  });

  // date range method
  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempDateRange({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  function onChangePopularRestaurant() {
    if (tempDateRange && tempDateRange?.endDate) {
      setPopularRestaurantDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
    }
    setPopularRestaurantCalendar(!popularRestaurantCalendar);
  }

  function onChangePopularItem() {
    if (tempDateRange && tempDateRange?.endDate) {
      setPopularItemDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
    }
    setPopularItemCalendar(!popularItemCalendar);
  }

  // console.log(heightRateItem)

  return (
    <PopularListContainer className={className}>
      <div className="popular-list-top-container">
        <h4 className="popular-list-top-title">{title}</h4>

        {/* popular-restaurants selected tab */}
        {!isFood && (
          <div className="time-periods">
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularRestaurants === "day" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularRestaurants("day");
                setPopularRestaurantDateRange();
              }}
            >
              Today
            </div>
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularRestaurants === "week" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularRestaurants("week");
                setPopularRestaurantDateRange();
              }}
            >
              Week
            </div>
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularRestaurants === "month" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularRestaurants("month");
                setPopularRestaurantDateRange();
              }}
            >
              Month
            </div>
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularRestaurants === "total" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularRestaurants("total");
                setPopularRestaurantDateRange();
              }}
            >
              Total
            </div>
            <div id="popular-restaurant-calendar-popup">
              <span
                className="checked ml-3"
                onClick={() => {
                  setPopularRestaurantCalendar(true);
                  setTempDateRange({
                    startDate: "",
                    endDate: "",
                  });
                }}
              >
                <i className="fas fa-calendar-alt cursor" />
              </span>
              {popularRestaurantCalendar && (
                <PopupCalendarContainer
                  className="popup-sales-calendar"
                  ref={ref}
                >
                  <MyDateRange
                    months={2}
                    onChangeDate={onChangeDate}
                    date={popularRestaurantDateRange}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 20px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px" }}>
                      {popularRestaurantDateRange?.startDate
                        ? moment(popularRestaurantDateRange?.startDate).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                      {popularRestaurantDateRange && "  to  "}
                      {popularRestaurantDateRange?.endDate
                        ? moment(popularRestaurantDateRange?.endDate).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </span>

                    <MyButton
                      className="apply-date"
                      type={
                        tempDateRange?.endDate ||
                        popularRestaurantDateRange?.endDate
                          ? "red"
                          : "gray"
                      }
                      onClick={onChangePopularRestaurant}
                    >
                      Apply
                    </MyButton>
                  </div>
                </PopupCalendarContainer>
              )}
            </div>
          </div>
        )}

        {/* popular-items selected tab */}
        {isFood && (
          <div className="time-periods">
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularItems === "day" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularItems("day");
                setPopularItemDateRange();
              }}
            >
              Today
            </div>
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularItems === "week" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularItems("week");
                setPopularItemDateRange();
              }}
            >
              Week
            </div>
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularItems === "month" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularItems("month");
                setPopularItemDateRange();
              }}
            >
              Month
            </div>
            <div
              id="pointer"
              className={`period-filter ${
                periodPopularItems === "total" ? "selected-tab" : ""
              }`}
              onClick={() => {
                setPeriodPopularItems("total");
                setPopularItemDateRange();
              }}
            >
              Total
            </div>
            <div id="popular-restaurant-calendar-popup">
              <span
                className="checked ml-3"
                onClick={() => {
                  setPopularItemCalendar(true);
                  setTempDateRange({
                    startDate: "",
                    endDate: "",
                  });
                }}
              >
                <i className="fas fa-calendar-alt cursor" />
              </span>
              {popularItemCalendar && (
                <PopupCalendarContainer
                  className="popup-sales-calendar"
                  ref={ref}
                >
                  <MyDateRange
                    months={2}
                    onChangeDate={onChangeDate}
                    date={popularItemDateRange}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 20px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px" }}>
                      {popularItemDateRange?.startDate
                        ? moment(popularItemDateRange?.startDate).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                      {popularItemDateRange && "  to  "}
                      {popularItemDateRange?.endDate
                        ? moment(popularItemDateRange?.endDate).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </span>

                    <MyButton
                      className="apply-date"
                      type={
                        tempDateRange?.endDate || popularItemDateRange?.endDate
                          ? "red"
                          : "gray"
                      }
                      onClick={onChangePopularItem}
                    >
                      Apply
                    </MyButton>
                  </div>
                </PopupCalendarContainer>
              )}
            </div>
          </div>
        )}
      </div>

      {isFood ? (
        <div className="popular">
          <div className="popular-item">Item</div>
          <div className="popular-item">Restaurant</div>
        </div>
      ) : (
        <div className="popular-1">
          <div className="popular-restaurant">Restaurant Name</div>
          <div className="popular-restaurant">Orders</div>
        </div>
      )}

      <div className="popular-list-core">
        {list?.length > 0 &&
          list.map(
            (
              { id, name, image, order_count, restaurant_id, restaurant_name },
              index
            ) => {
              // const restaurantName = restaurant_id === popularItemRestaurantData?.id ? popularItemRestaurantData?.name : '';
              return (
                <React.Fragment>
                  {index < 10 && (
                    <div
                      className={`popular-list-item  cursor-pointer ${
                        (index + 1) % 2 === 0 ? "dark" : "darker"
                      }`}
                      key={index}
                      onClick={() => {
                        if (isFood) {
                          handleClickPopularItem(
                            id,
                            restaurant_id,
                            image,
                            name
                          );
                        } else {
                          history.push(`/restaurant-detail/${id}?view=overall`);
                        }
                      }}
                    >
                      <span className="popular-list-item-number">{`${
                        index + 1 < 10 ? "0" : ""
                      }${index + 1}`}</span>
                      <div
                        className={`popular-list-item-avatar ${
                          isFood ? "rounded" : ""
                        }`}
                      >
                        {isFood && (
                          <img
                            src={image ? image : defaultFood}
                            width={isFood ? "77" : "56"}
                            // height="56"
                            height={isFood ? "51" : "56"}
                            alt="popular-list-item-avatar"
                          />
                        )}

                        {!isFood && (
                          <img
                            src={image ? image : defaultFood}
                            width={isFood ? "77" : "56"}
                            // height="56"
                            height={isFood ? "51" : "56"}
                            alt="popular-list-item-avatar"
                          />
                        )}
                      </div>
                      <div className="popular-name-and-value-container cursor-pointer">
                        {/* eslint-disable no-unused-expressions */}
                        {isFood ? (
                          <span
                            className="popular-list-item-name"
                            // onClick={() =>
                            //   handleClickPopularItem(
                            //     id,
                            //     restaurant_id,
                            //     image,
                            //     name
                            //   )
                            // }
                          >
                            {name}
                          </span>
                        ) : (
                          <span className="popular-list-item-name">
                            <Link
                              to={`/restaurant-detail/${id}?view=overall`}
                              className="text-white"
                            >
                              {name}
                            </Link>
                          </span>
                        )}
                        <span
                          className="popular-list-item-value"
                          onClick={(e) => {
                            if (isFood) {
                              e.stopPropagation();
                              history.push(
                                `/restaurant-detail/${restaurant_id}?view=overall`
                              );
                            } else {
                              e.stopPropagation();
                            }
                          }}
                        >
                          {/* {isFood ? `${order_count}` : `${order_count} orders`} */}
                          {isFood ? (
                            `${restaurant_name}`
                          ) : (
                            <span
                              style={{
                                cursor: "default",
                              }}
                            >{`${order_count}`}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            }
          )}
      </div>
      <DrawerItem
        isActive={!!openDrawers[drawersOverall.dashboardDrawer]}
        // isActive={true}
        closePanel={() => closeDrawer(drawersOverall.dashboardDrawer)}
      />
    </PopularListContainer>
  );
};
