/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import query from "query-string";
import { AllStaff } from "./AllStaff";
import { HighestRatedStaff } from "./HighestRatedStaff";
import { RestaurantProfileStaffContainer } from "./style";
import MyButton from "components/Elements/MyButton";
import { FaPlus, FaSlidersH } from "react-icons/fa";
import { drawersStaff } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import DrawerRoleStaff from "./Drawers/DrawerRoleStaff";
import DrawerStatusStaff from "./Drawers/DrawerStatusStaff";
import { restaurantServices } from "services";
import DrawerStaff from "./Drawers/DrawerStaff";
import MySearch from "components/Elements/MySearch";
import MyDropDown from "components/Elements/MyDropDown";
import DrawerReviews from "./Drawers/DrawerReviews";
import DrawerInviteStaff from "./Drawers/DrawerInviteStaff";
import DrawerStaffChooseRole from "./Drawers/DrawerChooseRole";
import NotesEmptyScreen from "../../../assets/img/no-filter-data.png";
import DrawerDeleteStaff from "./Drawers/DrawerDeleteStaff";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { Archived } from "./Archived";
import DrawerFilterStaff from "./Drawers/DrawerStaffFilter";
import RemoveReviewModal from "./Drawers/RemoveReviewModal";
import RestoreReviewModal from "./Drawers/ResoreReviewModal";
import ServerHostActiveModal from "./Drawers/ServerHostActiveModal";
import ChangeRoleToKitchenStaffModal from "./Drawers/ChangeRoleToKitchenStaff";
import ChangeRoleToManagerModal from "./Drawers/ChangeRoleToManager";
import StaffStatusInactiveModal from "./Drawers/SetInactiveStatus";
import StaffStatusInactiveGlobalModal from "./Drawers/SetInactiveStatusGlobal";
import RemoveModal from "./Drawers/RemoveFromRestaurant";
import RemoveConfirmModal from "./Drawers/RemoveConfirm";
import DrawerFilterStaffArchived from "./Drawers/DrawerStaffFilterArchived";
import ArchivedMainModal from "./Drawers/ArchivedOptions";
import ArchivedStatusModal from "./Drawers/ArchivedGlobalStaus";

export const RestaurantProfileStaff = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const mode = query.parse(history.location.search).mode;
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    setPostStaff,
    staffs,
    setStaffs,

    staffCloseDrawer,
    setStaffCloseDrawer,
    restaurant,

    isActiveStaffFilter,
    setActiveStaffFilter,

    staffRoleSlugFilter,
    staffStatusFilter,

    staffSearchInput,
    setStaffSearchInput,

    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,

    removeFromRestaurantModal,
    setRemoveFromRestaurantModal,

    removeFromRestaurantConfirmModal,
    setRemoveFromRestaurantConfirmModal,

    staffRoleSlugArchivedFilter,
    setStaffRoleSlugArchivedFilter,

    staffStatusArchivedFilter,
    setStaffStatusArchivedFilter,

    archivedData,
    setArchivedData,

    setStaffRoleSlugFilter,
    setStaffStatusFilter,

    setRoleTitle,
    setStatusTitle,

    setRoleArchivedTitle,
    setStatusArchivedTitle,

    archivedMainModal,
    setArchivedMainModal,
  } = useContext(RestaurantsContext);

  const [staffSortData, setStaffSortData] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [staffStatus, setStaffStatus] = useState("");

  const [filterredData, setFilteredData] = useState([]);

  const [addStaffDrawers, setAddStaffDarwers] = useState({
    staffChooseRole: false,
    staffInvite: false,
    staffInviteSend: false,
  });

  const [notification, setNotification] = useState();
  const [totalCount, setTotalCount] = useState();
  const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (
      `/restaurant-detail/${id}?view=staff` ===
      window.location.pathname + window.location.search
    ) {
      history.push(`/restaurant-detail/${id}?view=staff&mode=all`);
    }
  }, []);

  useEffect(() => {
    if (mode === "all") {
      getStaffAll();
    }
  }, [staffSortData, staffSearchInput, id, mode, currentPage]);

  const getStaffAll = async () => {
    if (mode === "all") {
      const payload = {
        restaurant_id: id,
        page: currentPage,
        page_size: selectedLimitToShow,
        sort: staffSortData,
        role_slug: staffRoleSlugFilter,
        status: staffStatusFilter,
        query: staffSearchInput,
        include_pending: true,
      };

      const result = await restaurantServices.getStaffAll(id, payload);
      if (result.status === 200) {
        if (result?.data) {
          // console.log(result.data)
          setStaffs([
            ...result?.data?.active_staff,
            ...result?.data?.pending_staff,
          ]);
          setTotalCount(result?.data?.total_count);
          setActiveStaffFilter(false);
          setStaffRoleSlugArchivedFilter("");
          setStaffStatusArchivedFilter("");
          setRoleArchivedTitle("");
          setStatusArchivedTitle("");
        }
      }
    }
  };

  useEffect(() => {
    if (staffSearchInput !== "") {
      const filterArray = staffs.filter(
        (value) =>
          value?.first_name
            ?.toLowerCase()
            .includes(staffSearchInput?.toLowerCase()) ||
          value?.last_name
            ?.toLowerCase()
            .includes(staffSearchInput?.toLowerCase())
      );
      setStaffs(filterArray);
    } else {
      getStaffAll();
    }
  }, [id, staffSearchInput]);

  // staff sort filter
  if (staffSortData === "Oldest") {
    setStaffSortData(1);
  } else if (staffSortData === "Highest Rated") {
    setStaffSortData(3);
  } else if (staffSortData === "Lowest Rated") {
    setStaffSortData(2);
  } else if (staffSortData === "Earliest") {
    setStaffSortData(0);
  }

  // status filter
  if (staffStatus === "Active") {
    setStaffStatus(1);
  } else if (staffStatus === "Inactive") {
    setStaffStatus(0);
  } else if (staffStatus === "Pending") {
    setStaffStatus(2);
  }

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification(null), 5000);
    }
  }, [notification]);

  useEffect(() => {
    if (mode === "archived") {
      customAxios
        .get(
          `/staff/archived?restaurant_id=${id}&role_slug=${staffRoleSlugArchivedFilter}&status=${staffStatusArchivedFilter}&query=${staffSearchInput}&page=${currentPage}&page_size=${selectedLimitToShow}`
        )
        .then((res) => {
          if (res.status === 200) {
            setArchivedData(res.data);

            setActiveStaffFilter(false);
            setStaffRoleSlugFilter("");
            setStaffStatusFilter("");
            setRoleTitle([]);
            setStatusTitle([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [mode, id, staffSearchInput, currentPage]);

  const getHighlightedText = (text, highlight, color) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  // console.log(staffs)

  return (
    <RestaurantProfileStaffContainer className="restaurant-staff-component">
      {notification && (
        <MyMessageBoxItemNew
          message={notification?.message ? notification?.message : ""}
          table={notification?.table ? notification?.table : ""}
          type={notification?.type ? notification?.type : ""}
          onClick={() => setNotification(null)}
        />
      )}

      <div className="top-navigation-panel">
        <div className="nav-line-to-back">
          <Link to="/restaurants" className="backlink-to-rest">
            Restaurants
          </Link>
          <span className="arrow-icon-container mx-3">
            <i className="fas fa-angle-right" />
          </span>
          <span className="current-restaurant">{restaurant?.title}</span>
        </div>

        <div className="staff-handlers">
          <div style={{ position: "relative" }}>
            {mode !== "highest" && (
              <MySearch
                value={staffSearchInput}
                onChange={(e) => {
                  if (e?.target?.value === undefined) {
                    setStaffSearchInput("");
                  } else {
                    setStaffSearchInput(e?.target?.value);
                  }
                }}
                defaultShow={true}
              />
            )}

            {staffSearchInput !== "" && staffs?.length !== 0 ? (
              <div className="serach-input-staff">
                {staffs?.map((el, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="search-input-div">
                        {staffSearchInput !== "" &&
                          staffSearchInput !== null &&
                          staffSearchInput !== undefined &&
                          getHighlightedText(
                            `${el?.first_name} ${el?.last_name}`,
                            staffSearchInput,
                            "#ed2626"
                          )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* sort filter */}
          {mode !== "highest" && (
            <MyDropDown
              className="menu-header-select sort-filter"
              options={[
                "Earliest",
                "Oldest",
                "Highest Rated",
                "Lowest Rated",
              ].map((item) => {
                return { label: item, value: item };
              })}
              // value={{ label: staffSort, value: staffSort }}
              onChange={(selectedSortType) =>
                setStaffSortData(selectedSortType)
              }
              width={115}
              iconClosed={<i className="fas fa-angle-down" />}
              placeholder="Sort"
            />
          )}

          {mode !== "highest" && (
            <div
              style={{
                // border: "solid 1px #525558",
                borderRadius: "25px",
                padding: "9px 17px",
                width: "50px",
                height: "40px",
                cursor: "pointer",
                marginRight: "15px",
                backgroundColor: "#27282a",
              }}
              onClick={() =>
                mode === "all"
                  ? openDrawer(drawersStaff.staffFilterDrawer)
                  : openDrawer(drawersStaff.staffFilterArchivedDrawer)
              }
            >
              <FaSlidersH />
              {isActiveStaffFilter && <div className="filter-red-dot" />}
            </div>
          )}

          {/* role filter */}
          {/* <MyDropDown
            className="menu-header-select role-filter"
            options={["Manager / Owner", "Kitchen Staff", "Server / Host"].map(
              (item) => ({
                label: item,
                value: item,
              })
            )}
            // value={{ label: staffRole, value: staffRole }}
            onChange={(selectedRoleType) => setStaffRole(selectedRoleType)}
            width={115}
            iconClosed={<i className="fas fa-angle-down" />}
            placeholder="Role"
          /> */}

          {/* status filter */}
          {/* <MyDropDown
            className="menu-header-select status-filter"
            options={["Active", "Inactive", "Pending"].map((item) => ({
              label: item,
              value: item,
            }))}
            // value={{ label: staffStatus, value: staffStatus }}
            onChange={(selectedStatusType) =>
              setStaffStatus(selectedStatusType)
            }
            width={115}
            iconClosed={<i className="fas fa-angle-down" />}
            placeholder="Status"
          /> */}

          <MyButton
            className="btn-add-staff"
            type="gray"
            // onClick={() => {
            //   setPostStaff({});
            //   openDrawer(drawersStaff.staffAdd);
            // }}
            onClick={() => {
              setAddStaffDarwers({ ...addStaffDrawers, staffChooseRole: true });
            }}
          >
            <FaPlus /> Add Staff
          </MyButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 40px",
          marginBottom: "30px",
        }}
      >
        <div className="staff-navigation-panel">
          <div
            className={`staff-menu-nav ${mode === "all" && "selected"}`}
            onClick={() =>{
              setCurrentPage(0)
              history.push(`/restaurant-detail/${id}?view=staff&mode=all`)
            }}
          >
            All Staff {staffSearchInput !== "" ? `(${staffs?.length})` : ""}
          </div>
          <div
            className={`staff-menu-nav ${mode === "highest" && "selected"}`}
            onClick={() => {
              setCurrentPage(0)
              setStaffSearchInput("");
              history.push(`/restaurant-detail/${id}?view=staff&mode=highest`);
            }}
          >
            Highest-Rated
          </div>
          <div
            className={`staff-menu-nav ${mode === "archived" && "selected"}`}
            onClick={() => {
              setCurrentPage(0)
              setStaffSearchInput("");
              history.push(`/restaurant-detail/${id}?view=staff&mode=archived`);
            }}
          >
            Archived
          </div>
        </div>
        {mode !== "highest" && (
          <h6 style={{ color: "#8f949a", size: "16px" }}>
            Total: <b>{staffs?.length}</b>
          </h6>
        )}
      </div>
      <div className="main-content-panel">
        <div className="staff-container">
          {mode === "all" && (
            <>
              {staffs && staffs?.length === 0 && staffSearchInput !== "" ? (
                <div className="no-result-staff-screen">
                  <img src={NotesEmptyScreen} alt="" />
                  <div className="staff-no-result-text">No results</div>
                  <div className="staff-no-result-meta-text">
                    Try broadening your search
                  </div>
                </div>
              ) : (
                <AllStaff staffs={staffs} selectedLimitToShow={selectedLimitToShow} setCurrentPage={setCurrentPage} currentPage={currentPage} totalCount={totalCount} />
              )}
            </>
          )}

          {mode === "highest" && <HighestRatedStaff staffs={staffs} />}

          {mode === "archived" && (
            <>
              {archivedData &&
              archivedData?.length === 0 &&
              staffSearchInput !== "" ? (
                <div className="no-result-staff-screen">
                  <img src={NotesEmptyScreen} alt="" />
                  <div className="staff-no-result-text">No results</div>
                  <div className="staff-no-result-meta-text">
                    Try broadening your search
                  </div>
                </div>
              ) : (
                <Archived archivedData={archivedData} selectedLimitToShow={selectedLimitToShow} setCurrentPage={setCurrentPage} currentPage={currentPage} totalCount={totalCount}  />
              )}
            </>
          )}
        </div>
      </div>

      <DrawerStaffChooseRole
        isActive={addStaffDrawers.staffChooseRole}
        closePanel={() => {
          setAddStaffDarwers({ ...addStaffDrawers, staffChooseRole: false });
        }}
        openInviteDrawerHandler={() => {
          setAddStaffDarwers({ ...addStaffDrawers, staffInvite: true });
        }}
      />
      <DrawerStaff
        type="Add"
        isActive={!!openDrawers[drawersStaff.staffAdd]}
        closePanel={() => closeDrawer(drawersStaff.staffAdd)}
        callBack={getStaffAll}
      />
      <DrawerStaff
        type="Edit"
        isActive={!!openDrawers[drawersStaff.staffEdit]}
        closePanel={() => closeDrawer(drawersStaff.staffEdit)}
        callBack={getStaffAll}
      />
      {/* staff pending remove */}
      {/* <DrawerDeleteStaff
        isActive={!!openDrawers[drawersStaff.staffDelete]}
        closePanel={() => closeDrawer(drawersStaff.staffDelete)}
      /> */}
      <DrawerDeleteStaff
        isActive={staffCloseDrawer.staffDelete}
        closePanel={() =>
          setStaffCloseDrawer({
            ...staffCloseDrawer,
            setStaffCloseDrawer: false,
          })
        }
      />
      <DrawerInviteStaff
        isActive={addStaffDrawers.staffInvite}
        closePanel={() => {
          setAddStaffDarwers({ ...addStaffDrawers, staffInvite: false });
        }}
        addStaffDrawers={addStaffDrawers}
        setAddStaffDarwers={setAddStaffDarwers}
        getStaffAll={getStaffAll}
        setNotification={setNotification}
      />
      <DrawerRoleStaff
        isActive={!!openDrawers[drawersStaff.staffRole]}
        closePanel={() => closeDrawer(drawersStaff.staffRole)}
      />
      <DrawerStatusStaff
        isActive={!!openDrawers[drawersStaff.staffStatus]}
        closePanel={() => closeDrawer(drawersStaff.staffStatus)}
      />
      <DrawerReviews
        isActive={!!openDrawers[drawersStaff.staffReviews]}
        closePanel={() => closeDrawer(drawersStaff.staffReviews)}
      />
      <DrawerFilterStaff
        isActive={!!openDrawers[drawersStaff.staffFilterDrawer]}
        closePanel={() => closeDrawer(drawersStaff.staffFilterDrawer)}
        mode={mode}
      />
      <DrawerFilterStaffArchived
        isActive={!!openDrawers[drawersStaff.staffFilterArchivedDrawer]}
        closePanel={() => closeDrawer(drawersStaff.staffFilterArchivedDrawer)}
        mode={mode}
      />
      <RemoveReviewModal
        isActive={!!openDrawers[drawersStaff.staffReviewRemove]}
        closePanel={() => closeDrawer(drawersStaff.staffReviewRemove)}
      />
      <RestoreReviewModal
        // isActive={true}
        isActive={!!openDrawers[drawersStaff.staffReviewRestore]}
        closePanel={() => closeDrawer(drawersStaff.staffReviewRestore)}
      />

      <ServerHostActiveModal
        // isActive={!!openDrawers[drawersStaff.staffServerHostActiveModal]}
        // closePanel={() => closeDrawer(drawersStaff.staffServerHostActiveModal)}
        isActive={staffChangeRoleMainModal.staffServerHostActiveModal}
        closePanel={() => {
          setStaffChangeRoleMainModal({
            ...staffChangeRoleMainModal,
            staffServerHostActiveModal: false,
          });
        }}
        getStaffAll={getStaffAll}
      />

      <ChangeRoleToKitchenStaffModal
        isActive={!!openDrawers[drawersStaff.ChangeRoleToKitchenStaffModal]}
        closePanel={() =>
          closeDrawer(drawersStaff.ChangeRoleToKitchenStaffModal)
        }
        getStaffAll={getStaffAll}
      />

      <ChangeRoleToManagerModal
        isActive={!!openDrawers[drawersStaff.ChangeRoleToManagerModal]}
        closePanel={() => closeDrawer(drawersStaff.ChangeRoleToManagerModal)}
        getStaffAll={getStaffAll}
      />

      <StaffStatusInactiveModal
        isActive={!!openDrawers[drawersStaff.StaffStatusInactiveModalForm]}
        closePanel={() =>
          closeDrawer(drawersStaff.StaffStatusInactiveModalForm)
        }
        getStaffAll={getStaffAll}
      />

      <StaffStatusInactiveGlobalModal
        isActive={
          !!openDrawers[drawersStaff.StaffStatusInactiveGlobalModalForm]
        }
        closePanel={() =>
          closeDrawer(drawersStaff.StaffStatusInactiveGlobalModalForm)
        }
        getStaffAll={getStaffAll}
      />

      <RemoveModal
        // isActive={!!openDrawers[drawersStaff.removeFromRestaurant]}
        // closePanel={() => closeDrawer(drawersStaff.removeFromRestaurant)}
        isActive={removeFromRestaurantModal.removeFromRestaurant}
        closePanel={() => {
          setRemoveFromRestaurantModal({
            ...removeFromRestaurantModal,
            removeFromRestaurant: false,
          });
        }}
      />

      <RemoveConfirmModal
        // isActive={!!openDrawers[drawersStaff.removeConfirmFromRestaurant]}
        // closePanel={() => closeDrawer(drawersStaff.removeConfirmFromRestaurant)}
        isActive={removeFromRestaurantConfirmModal.removeConfirmFromRestaurant}
        closePanel={() => {
          setRemoveFromRestaurantConfirmModal({
            ...removeFromRestaurantConfirmModal,
            removeConfirmFromRestaurant: false,
          });
        }}
        getStaffAll={getStaffAll}
      />

      {/* archived */}
      <ArchivedMainModal
        isActive={archivedMainModal.archivedMainOption}
        closePanel={() => {
          setArchivedMainModal({
            ...archivedMainModal,
            archivedMainOption: false,
          });
        }}
      />

      <ArchivedStatusModal
        isActive={!!openDrawers[drawersStaff.archivedStatusGlobal]}
        closePanel={() => closeDrawer(drawersStaff.archivedStatusGlobal)}
      />
    </RestaurantProfileStaffContainer>
  );
};
