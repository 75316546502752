import React, { useContext, useState, useEffect } from "react";
import {
  ApplyPromoCodeModalContainer,
  ArrowContainer,
  CheckItem,
} from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OrdersContext } from "context/OrdersContext";
import { NoAvatar, IconTable } from "assets/img";
import { customAxios } from "helpers/custom-helpers/customAxios";
// import { useParams } from "react-router-dom";

const AssignNewServerModal = ({
  isActive = false,
  closePanel = () => {},
  setCheckInData = () => {},
  checkInData = "",
  assignNewServerHandler = () => {},
}) => {
  // const { id } = useParams();

  const { orderDetailData } = useContext(OrdersContext);

  const [serverListData, setServerListData] = useState([]);

  // previous api
  // useEffect(() => {
  //   if (isActive) {
  //     customAxios
  //       .get(`/restaurant/${orderDetailData?.restaurant?.id}/staff?role=waiter`)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           let filterData = res?.data?.current_staff?.staff?.filter(
  //             (el) => el?.role_slug === "waiter"
  //           );
  //           setServerListData(filterData);

  //           // let filterData = res?.data?.staffs?.filter((el) => el?.role_slug === "waiter");
  //           // console.log(filterData)
  //           // setServerListData(filterData);
  //         } else {
  //           setServerListData([]);
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  // }, [isActive, orderDetailData]);

  // temporary api
  useEffect(() => {
    if (isActive) {
      customAxios
        .get(
          `/staff?restaurant_id=${orderDetailData?.restaurant?.id}&page=1&page_size=10&role_slug=waiter`
        )
        .then((res) => {
          if (res.status === 200) {
            setServerListData(res?.data?.active_staff);
          } else {
            setServerListData([]);
          }
        })
        .catch((err) => {});
    }
  }, [isActive, orderDetailData]);

  const choseServer = (server) => setCheckInData(server);

  return (
    <ApplyPromoCodeModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      position="fixed"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="assign-new-server-modal">
          <div className="delete-suborder-title">Assign New Server</div>
          <div className="servers-list">
            {serverListData &&
              serverListData.map((server) => {
                const isSelected = server.id === checkInData?.id ? true : false;
                return (
                  <div
                    key={`serverkeypr${server.id}`}
                    className={`server-item ${isSelected ? "active" : ""}`}
                    onClick={() => choseServer(server)}
                    aria-hidden="true"
                  >
                    <div className="left-part">
                      <div className="image-container">
                        <img
                          src={server?.avatar ? server.avatar : NoAvatar}
                          alt="user"
                        />
                        {isSelected === true && (
                          <CheckItem>
                            <i className="fas fa-check" />
                          </CheckItem>
                        )}
                      </div>
                      <div className="server-name">
                        {server?.first_name} {server?.last_name}
                      </div>
                    </div>
                    <div
                      className={`right-part ${
                        Number(server?.cur_orders) === 0 ? "opacity6" : ""
                      }`}
                    >
                      <div className="table-count">
                        <span>{server?.cur_orders}</span>
                        <img src={IconTable} alt="icon-table" />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type={checkInData !== "" ? "red" : "gray"}
              className="apply-btn"
              onClick={assignNewServerHandler}
              disabled={checkInData === ""}
            >
              <b>Assign New Server</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="redColor">
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ApplyPromoCodeModalContainer>
  );
};

export default AssignNewServerModal;
