import styled from '@emotion/styled';

export const SingleNumberIncreaseContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  .handle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #65676b;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    cursor: pointer;
  }
  .handle-btn:first-of-type {
    margin-right: ${(props) => `${props.size}px`};
  }
  .handle-btn:last-child {
    margin-left: ${(props) => `${props.size}px`};
  }
`;
