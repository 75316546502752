import React, { useState, useEffect, useContext } from "react";
import { BlockOrderDetailContainer } from "./style";
import { OrdersContext } from "context/OrdersContext";
import { useParams } from "react-router-dom";
import { FaCheck, FaClock, FaEllipsisH } from "react-icons/fa";
import TimeStamp from "../BlockOrderInfo/TimeStamp";
import FoodItem from "./FoodItem";
import { drawersOrder } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerDeleteSubOrder from "../Drawers/DrawerDeleteSubOrder";
import DrawerDeleteSubOrderConfirmation from "../Drawers/DrawerDeleteSubOrderConfirmation";
import DrawerDeletedSubOrder from "../Drawers/DrawerDeletedSubOrder";
import DrawerEditItem from "../Drawers/DrawerEditItem";
import PendingSubOrderActionsModal from "../Modals/PendingSubOrderActionsModal";
import DeleteSubOrderModal from "../Modals/DeleteSubOrderModal";
import ConfirmSubOrderDeleteModal from "../Modals/DeleteSubOrderModal/ConfirmSubOrderDeleteModal";
import RemoveItemModal from "../Modals/RemoveItemModal";
import MarkAsReadyModal from "../Modals/MarkAsReadyModal";
import MarkAsDeliveredModal from "../Modals/MarkAsDeliveredModal";
import { showMessage } from "services/functions";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const BlockOrderDetail = ({
  className = "",
  order,
  index,
  orderDetails,
  onClickOrderFoodReview = () => {},
  orderAt,
}) => {
  const { id } = useParams();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,

    orderDetailData,
    setOrderDetailData,

    selectedItem,
    setSelectedItem,

    selectedSubOrder,
    setSelectedSubOrder,

    setOrderNotification,

    setOrderFoodInfo
  } = useContext(OrdersContext);

  const [itemUpdateNotification, setItemUpdateNotification] = useState();

  useEffect(() => {
    if (itemUpdateNotification) {
      setTimeout(() => setItemUpdateNotification(null), 2 * 1000);
    }
  }, [itemUpdateNotification]);

  const [openPendingSubOrderActions, setOpenPendingSubOrderActions] = useState(
    false
  );

  const [deleteSubOrderModals, setDeleteSubOrderModals] = useState({
    subOrderDelete: false,
    confirmSubOrderDelete: false,
  });

  const [isRemoveItemModalShown, setIsRemoveItemModalShown] = useState(false);

  const [markAsReadyModalData, setMarkAsReadyData] = useState({
    isModalOpen: false,
    type: "",
    data: "",
  });

  const [markAsDelivered, setMarkAsDelivered] = useState({
    isModalOpen: false,
    type: "",
    data: "",
  });

  const orderStatusIconRenderer = (order) => {
    let orderMenuData = [];
    Object.keys(order).map((el, i) => {
      orderMenuData.push(...order[el]);
    });

    let filterData = orderMenuData.filter((data) => data.status >= 0);
    let completedVar = filterData.every((el) => el.status === 3);
    let pendingVar = filterData.every((el) => el.status === 0);
    let orderReady = filterData.every((el) => el.status === 2);
    let kichenSent = filterData.every((el) => el.status === 1);
    let orderReadyFilter = filterData.filter((el) => el.status === 2);
    if (completedVar) {
      return (
        <div
          style={{
            width: "58px",
            height: "58px",
            backgroundColor: "rgb(255 255 255 / 10%)",
            borderRadius: "50%",
            padding: "17px 17px 17px 18px",
          }}
        >
          <FaCheck color="white" size={24} />
        </div>
      );
    } else if (orderReady) {
      return (
        <div
          style={{
            width: "58px",
            height: "58px",
            background: "linear-gradient(315deg, #5ff7c0 96%, #1fc086 8%)",
            borderRadius: "50%",
            padding: "17px 17px 17px 18px",
          }}
        >
          <i
            className="fas fa-concierge-bell"
            style={{ fontSize: "24px", color: "#fff" }}
          />
        </div>
      );
    } else if (pendingVar || kichenSent) {
      return (
        <div
          style={{
            width: "58px",
            height: "58px",
            background:
              "linear-gradient(322deg, rgb(255, 125, 125) 29%, rgb(237, 38, 38) 100%)",
            borderRadius: "50%",
            padding: "17px 17px 17px 20px",
          }}
        >
          <i
            className="fas fa-receipt"
            style={{ fontSize: "24px", color: "#fff" }}
          />
        </div>
      );
    } else if (
      orderReadyFilter?.length &&
      !completedVar &&
      !pendingVar &&
      !orderReady &&
      !kichenSent
    ) {
      return (
        <div
          style={{
            width: "58px",
            height: "58px",
            background:
              "linear-gradient( 315deg,   #ff7d7d 0%,  #ed2626 50%, #5ff7c0 50%, #1fc086 100%)",
            borderRadius: "50%",
            padding: "17px 17px 17px 20px",
          }}
        >
          <i
            className="fas fa-receipt"
            style={{ fontSize: "24px", color: "#fff" }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "58px",
            height: "58px",
            background:
              "linear-gradient(322deg, rgb(255, 125, 125) 29%, rgb(237, 38, 38) 100%)",
            borderRadius: "50%",
            padding: "17px 17px 17px 20px",
          }}
        >
          <i
            className="fas fa-receipt"
            style={{ fontSize: "24px", color: "#fff" }}
          />
        </div>
      );
    }
  };

  const orderCount = () => {
    let orderMenuData = [];
    Object.keys(order).map((el, i) => {
      orderMenuData.push(...order[el]);
    });
    return orderMenuData?.length;
  };

  const pendingSuborderActions = (subOrder) => {
    switch (subOrder) {
      case "Pending":
        return (
          <FaEllipsisH
            color="white"
            className="cursor"
            size={18}
            onClick={() => {
              setOpenPendingSubOrderActions(!openPendingSubOrderActions);
            }}
          />
        );
      case "Some Items Ready":
        return (
          <FaEllipsisH
            color="white"
            className="cursor"
            size={18}
            onClick={() => {
              setOpenPendingSubOrderActions(!openPendingSubOrderActions);
            }}
          />
        );
      case "Order Ready":
        return (
          <FaEllipsisH
            color="white"
            className="cursor"
            size={18}
            onClick={() => {
              setOpenPendingSubOrderActions(!openPendingSubOrderActions);
            }}
          />
        );
      case "Delivered":
        return <></>;
      default:
        return null;
    }
  };

  const orderStatusRenderer = () => {
    let orderMenuData = [];
    Object.keys(order).map((el, i) => {
      orderMenuData.push(...order[el]);
    });
    let filterData = orderMenuData.filter((data) => data?.status >= 0);
    let completedVar = filterData.every((el) => el?.status === 3);
    let pendingVar = filterData.every((el) => el?.status === 0);
    let orderReady = filterData.every((el) => el?.status === 2);
    let kichenSent = filterData.every((el) => el?.status === 1);
    let orderReadyFilter = filterData.filter((el) => el?.status === 2);
    if (completedVar) {
      return "Delivered";
    } else if (orderReady) {
      return "Order Ready";
    } else if (pendingVar || kichenSent) {
      return "Pending";
    } else if (
      orderReadyFilter?.length &&
      !completedVar &&
      !pendingVar &&
      !orderReady &&
      !kichenSent
    ) {
      return "Some Items Ready";
    } else {
      return "Pending";
    }
  };

  const foodTypeStatusRenderer = (order) => {
    let completedVar = order.every((el) => el.status === 3);
    // let pendingVar = order.every((el) => el.status === 0);
    let orderReady = order.every((el) => el.status === 2);
    // let kichenSent = order.every((el) => el.status === 1);

    // let pendingVarFilter = order.filter((el) => el.status === 0);
    // let orderReadyFilter = order.filter((el) => el.status === 2);
    // let kichenSentFilter = order.filter((el) => el.status === 1);
    if (completedVar) {
      return (
        <div
          style={{
            width: "90px",
            height: "24px",
            fontSize: "15px",
            fontWeight: "500",
            color: "#fff",
            backgroundColor: "rgba(255, 255, 255, 0.1",
            borderRadius: "12px",
            padding: "0px 10px 3px",
          }}
        >
          Delivered
        </div>
      );
    } else if (orderReady) {
      return (
        <div
          style={{
            width: "66px",
            height: "24px",
            fontSize: "15px",
            fontWeight: "500",
            color: "#fff",
            backgroundColor: "#1fc085",
            borderRadius: "12px",
            padding: "0px 10px 3px",
          }}
        >
          Ready
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "83px",
            height: "24px",
            fontSize: "15px",
            fontWeight: "500",
            color: "#fff",
            backgroundColor: "#ed2626",
            borderRadius: "12px",
            padding: "0px 10px 3px",
          }}
        >
          Pending
        </div>
      );
    }
  };

  const onClickEditFoodItem = (menuItem) => {
    // console.log("menu-item", menuItem)
    setOrderFoodInfo(menuItem)
    setSelectedItem(menuItem);
    openDrawer(drawersOrder.editItem);
  };

  const onClickDeleteFoodItem = (menuItem) => {
    setSelectedItem(menuItem);
    setIsRemoveItemModalShown(true);
  };

  const onClickDeleteSubOrder = (order) => {
    setSelectedSubOrder(order);
    setDeleteSubOrderModals({
      ...deleteSubOrderModals,
      subOrderDelete: !deleteSubOrderModals.subOrderDelete,
    });
  };

  const onDeleteItemAPI = () => {
    customAxios
      .delete(`/orders/${id}/${selectedItem?.id}`)
      .then((res) => {
        if (res.status === 200) {
          setIsRemoveItemModalShown(!isRemoveItemModalShown);
          setOrderNotification({
            message: "Item Removed.",
            type: "remove",
            table: `Table ${orderDetailData.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
      #${orderDetailData.token}-${selectedItem.subOrderNumber}
    </span>`,
          });
          customAxios
            .get(`/orders/${id}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onDeleteSubOrderAPI = () => {
    let firstObj = [];
    Object.keys(selectedSubOrder).map((el) => {
      if (typeof selectedSubOrder[el] === "object") {
        selectedSubOrder[el].map((data) => {
          firstObj.push(data?.batch);
        });
      }
    });
    customAxios
      .delete(`/orders/${id}?batch=${Number(firstObj[0])}`)
      .then((res) => {
        if (res.status === 200) {
          setOpenPendingSubOrderActions(!openPendingSubOrderActions);
          setDeleteSubOrderModals({
            ...deleteSubOrderModals,
            subOrderDelete: false,
            confirmSubOrderDelete: true,
          });
          setSelectedSubOrder("");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOrderDetail = () => {
    customAxios
      .get(`/orders/${id}`)
      .then((res) => {
        setOrderDetailData(res?.data?.order);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onClickEditSave = (editItemDetails, price) => {
    // let dArray = [];
    // editItemDetails &&
    //   editItemDetails.customizationData &&
    //   editItemDetails.customizationData.map((el) => {
    //     el &&
    //       el.options.map((optionData) => {
    //         if (optionData?.isSelected) {
    //           dArray.push(optionData?.id);
    //         }
    //       });
    //   });
    // let apiObj = {
    //   quantity: editItemDetails?.totalPrice / editItemDetails?.price,
    // };
    // if (dArray && dArray?.length) {
    //   apiObj.customizations = dArray;
    // }
    customAxios
      .put(`/orders/${id}/${selectedItem?.id}`, editItemDetails)
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(`/orders/${id}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {
              console.error(err);
            });
          closeDrawer(drawersOrder.editItem);
          setItemUpdateNotification({
            message: "Item Updated.",
            type: "",
            table: `$${price > 0 ? price : -price} will be ${price > 0 ? "Added to" : "Removed from"} the customer's bill.`,
          });
        } else {
          showMessage(
            "Item is ready to deliver or delivered not editable",
            "error"
          );
          closeDrawer(drawersOrder.editItem);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onDoneMarkAsReady = () => {
    markAsReadyModalData &&
      markAsReadyModalData.data.map((el, index) => {
        customAxios
          .patch(`/orders/${id}/ready`, { order_food_item_id: el?.id })
          .then((res) => {
            if (res.status === 200) {
              setOrderNotification({
                message: `${
                  markAsReadyModalData?.type === "Entrees"
                    ? "Entreés"
                    : markAsReadyModalData?.type === "Drinks"
                    ? "Drinks"
                    : "Quick Foods"
                } Marked as Ready`,
                table: `Table ${orderDetails?.table_number}• <span style="font-weight:bold; color:#ed2626; margin-left:0">
                #${orderDetails?.token}-${markAsReadyModalData?.subOrderNumber}
              </span>`,
              });
              setMarkAsReadyData({
                ...markAsReadyModalData,
                isModalOpen: false,
              });
              customAxios
                .get(`/orders/${id}`)
                .then((res) => {
                  setOrderDetailData(res?.data?.order);
                })
                .catch((err) => {
                  console.error(err);
                });
              if (index === 0) {
                let notifyObj = {
                  type:
                    markAsReadyModalData?.type === "Drinks"
                      ? 1
                      : markAsReadyModalData?.type === "Mark_Quick"
                      ? 2
                      : 0,
                  batch: markAsReadyModalData?.data[0]?.batch,
                };
                customAxios
                  .patch(`/orders/${id}/ready/notify`, notifyObj)
                  .then((res) => {})
                  .catch((err) => {});
              }
            } else {
            }
          })
          .catch((err) => {});
      });
  };

  const onDoneMarkAsDelivered = () => {
    let notifyObj = {
      type:
        markAsDelivered?.type === "Drinks"
          ? 1
          : markAsDelivered?.type === "Mark_Quick"
          ? 2
          : 0,
      batch: markAsDelivered?.data[0]?.batch,
    };
    customAxios
      .patch(`/orders/${id}/deliver`, notifyObj)
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(`/orders/${id}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {
              console.error(err);
            });
          setOrderNotification({
            message: `${
              markAsDelivered?.type === "Entrees"
                ? "Entreés"
                : markAsDelivered?.type === "Drinks"
                ? "Drinks"
                : "Quick Foods"
            } Marked as Delivered`,
            table: `Table ${orderDetails?.table_number}• <span style="font-weight:bold; color:#ed2626; margin-left:0">
            #${orderDetails?.token}-${markAsDelivered?.subOrderNumber}
          </span>`,
          });
          setMarkAsDelivered({
            ...markAsDelivered,
            isModalOpen: false,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  const onClickReadyItem = (orderDetailsInfo, foodType) => {
    customAxios
      .patch(`/orders/${id}/ready`, {
        order_food_item_id: orderDetailsInfo?.id,
      })
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(`/orders/${id}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
        }
      })
      .catch((err) => {});
  };

  const rollBackReadyItemToKitchen = (orderDetailsInfo, foodType) => {
    customAxios
      .patch(`/orders/${id}/rollback`, {
        order_food_item_id: orderDetailsInfo?.id,
      })
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(`/orders/${id}`)
            .then((res) => {
              if (res.status === 200) {
                setOrderDetailData(res?.data?.order);
              } else {
              }
            })
            .catch((err) => {});
        } else {
        }
      });
  };

  // console.log(orderDetailData)
  // checkout_data
  // feast_fees

  return (
    <BlockOrderDetailContainer>
      {itemUpdateNotification && (
        <MyMessageBoxItemNew
          message={
            itemUpdateNotification?.message
              ? itemUpdateNotification.message
              : ""
          }
          table={
            itemUpdateNotification?.table ? itemUpdateNotification.table : ""
          }
          type={
            itemUpdateNotification?.type
              ? itemUpdateNotification.type
              : "success"
          }
          onClick={() => setItemUpdateNotification(null)}
        />
      )}
      <div className="card-block">
        <div className="card-top d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center ">
            <div
              style={{
                marginRight: "10px",
              }}
            >
              {orderStatusIconRenderer(order)}
            </div>
            <div className="d-flex flex-column align-items-start">
              <h3 className="mb-0 mr-3">{orderStatusRenderer(order)}</h3>
              <h5 className="mt-2 opacity6" style={{ fontSize: "15px" }}>
                #{orderDetails?.token}-{index + 1} - {orderCount()} items
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-end flex-column">
            <div>{pendingSuborderActions(orderStatusRenderer(order))}</div>
            {orderStatusRenderer(order) !== "Delivered" && (
              <span className="mt-2 opacity6" style={{ fontSize: "15px" }}>
                <FaClock />{" "}
                {orderDetails?.time && <TimeStamp date={new Date(orderAt)} />}
              </span>
            )}
          </div>
        </div>

        {Object.keys(order)?.map((menuItem, _i) => {
          if (menuItem === "Drink") {
            return order[menuItem]
              .filter((filterEl) => filterEl?.status >= 0)
              .map((el, foodIndex) => {
                return (
                  <>
                    {foodIndex === 0 && (
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          margin: "34px 0 14px 0",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <b>Drinks</b>
                        </div>
                        <div>{foodTypeStatusRenderer(order[menuItem])}</div>
                      </div>
                    )}
                    <FoodItem
                      orders={order[menuItem]}
                      orderDetails={el}
                      key={foodIndex}
                      foodType="drink"
                      foodIndex={foodIndex}
                      onClickEditFoodItem={onClickEditFoodItem}
                      onClickDeleteFoodItem={onClickDeleteFoodItem}
                      subOrderNumber={index + 1}
                      markAsReadyClick={(data, subOrderNumber) => {
                        setMarkAsReadyData({
                          isModalOpen: true,
                          type: "Drinks",
                          data: data,
                          subOrderNumber: subOrderNumber,
                        });
                      }}
                      markAsDeliveredClick={(data, subOrderNumber) => {
                        setMarkAsDelivered({
                          isModalOpen: true,
                          type: "Drinks",
                          data: data,
                          subOrderNumber: subOrderNumber,
                        });
                      }}
                      onClickReadyItem={onClickReadyItem}
                      rollBackReadyItemToKitchen={rollBackReadyItemToKitchen}
                    />
                  </>
                );
              });
          }
        })}

        {Object.keys(order)?.map((menuItem, _i) => {
          if (menuItem === "Quick Bites") {
            return order[menuItem]
              .filter((filterEl) => filterEl?.status >= 0)
              .map((el, foodIndex) => {
                return (
                  <>
                    {foodIndex === 0 && (
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          margin: "34px 0 14px 0",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <b>Quick Foods</b>
                        </div>
                        <div>{foodTypeStatusRenderer(order[menuItem])}</div>
                      </div>
                    )}
                    <FoodItem
                      orders={order[menuItem]}
                      orderDetails={el}
                      key={foodIndex}
                      foodType="quick_food"
                      foodIndex={foodIndex}
                      onClickEditFoodItem={onClickEditFoodItem}
                      onClickDeleteFoodItem={onClickDeleteFoodItem}
                      subOrderNumber={index + 1}
                      markAsReadyClick={(data, subOrderNumber) => {
                        setMarkAsReadyData({
                          isModalOpen: true,
                          type: "Mark_Quick",
                          data: data,
                          subOrderNumber: subOrderNumber,
                        });
                      }}
                      markAsDeliveredClick={(data, subOrderNumber) => {
                        setMarkAsDelivered({
                          isModalOpen: true,
                          type: "Mark_Quick",
                          data: data,
                          subOrderNumber: subOrderNumber,
                        });
                      }}
                      onClickReadyItem={onClickReadyItem}
                      rollBackReadyItemToKitchen={rollBackReadyItemToKitchen}
                    />
                  </>
                );
              });
          }
        })}

        {Object.keys(order)?.map((menuItem, _i) => {
          if (menuItem === "Food") {
            return order[menuItem]
              .filter((filterEl) => filterEl?.status >= 0)
              .map((el, foodIndex) => {
                return (
                  <>
                    {foodIndex === 0 && (
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          margin: "34px 0 14px 0",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <b>Entrées</b>
                        </div>
                        <div>{foodTypeStatusRenderer(order[menuItem])}</div>
                      </div>
                    )}
                    <FoodItem
                      orders={order[menuItem]}
                      orderDetails={el}
                      key={foodIndex}
                      foodType="entrees"
                      foodIndex={foodIndex}
                      onClickEditFoodItem={onClickEditFoodItem}
                      onClickDeleteFoodItem={onClickDeleteFoodItem}
                      subOrderNumber={index + 1}
                      markAsReadyClick={(data, subOrderNumber) => {
                        setMarkAsReadyData({
                          isModalOpen: true,
                          type: "Entrees",
                          data: data,
                          subOrderNumber: subOrderNumber,
                        });
                      }}
                      markAsDeliveredClick={(data, subOrderNumber) => {
                        setMarkAsDelivered({
                          isModalOpen: true,
                          type: "Entrees",
                          data: data,
                          subOrderNumber: subOrderNumber,
                        });
                      }}
                      onClickReadyItem={onClickReadyItem}
                      rollBackReadyItemToKitchen={rollBackReadyItemToKitchen}
                    />
                  </>
                );
              });
          }
        })}
      </div>

      <DrawerDeleteSubOrder
        isActive={!!openDrawers[drawersOrder.deleteSubOrder]}
        closePanel={() => closeDrawer(drawersOrder.deleteSubOrder)}
      />
      <DrawerDeleteSubOrderConfirmation
        isActive={!!openDrawers[drawersOrder.deleteSubOrderConfirmation]}
        closePanel={() => closeDrawer(drawersOrder.deleteSubOrderConfirmation)}
        onDeleteSubOrder={onDeleteSubOrderAPI}
      />
      <DrawerDeletedSubOrder
        isActive={!!openDrawers[drawersOrder.deleteSubOrderMessage]}
        closePanel={() => closeDrawer(drawersOrder.deleteSubOrderMessage)}
      />
      <DrawerEditItem
        isActive={!!openDrawers[drawersOrder.editItem]}
        closePanel={() => closeDrawer(drawersOrder.editItem)}
        onClickEditSave={onClickEditSave}
      />
      <PendingSubOrderActionsModal
        isActive={openPendingSubOrderActions}
        closePanel={() =>
          setOpenPendingSubOrderActions(!openPendingSubOrderActions)
        }
        onClickDeleteSubOrder={onClickDeleteSubOrder}
        order={order}
      />
      <DeleteSubOrderModal
        isActive={deleteSubOrderModals.subOrderDelete}
        closePanel={() =>
          setDeleteSubOrderModals({
            ...deleteSubOrderModals,
            subOrderDelete: false,
          })
        }
        onDeleteSubOrder={onDeleteSubOrderAPI}
      />
      <ConfirmSubOrderDeleteModal
        isActive={deleteSubOrderModals.confirmSubOrderDelete}
        closePanel={() =>
          setDeleteSubOrderModals({
            ...deleteSubOrderModals,
            confirmSubOrderDelete: false,
          })
        }
        fetchOrderDetail={fetchOrderDetail}
      />
      <RemoveItemModal
        isActive={isRemoveItemModalShown}
        closePanel={() => setIsRemoveItemModalShown(!isRemoveItemModalShown)}
        removeFoodItem={onDeleteItemAPI}
      />
      <MarkAsReadyModal
        isActive={markAsReadyModalData?.isModalOpen}
        closePanel={() =>
          setMarkAsReadyData({
            ...markAsReadyModalData,
            isModalOpen: false,
          })
        }
        onDoneMarkAsReady={onDoneMarkAsReady}
        subOrderType={markAsReadyModalData?.type}
      />
      <MarkAsDeliveredModal
        isActive={markAsDelivered?.isModalOpen}
        closePanel={() =>
          setMarkAsDelivered({
            ...markAsDelivered,
            isModalOpen: false,
          })
        }
        onDoneMarkAsDelivered={onDoneMarkAsDelivered}
        subOrderType={markAsDelivered?.type}
      />
    </BlockOrderDetailContainer>
  );
};

export default BlockOrderDetail;
