import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaCandyCane, FaLeaf } from "react-icons/fa";
import { DrawerItemContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import { FoodNutritionDetail } from "./FoodNutritionDetail";
import { colors } from "theme";

const nutritionProperties = [
  { name: "Calories", placeholder: "Add Calories", key: "calories" },
  {
    name: "Total Fat",
    placeholder: "Add Total Fat",
    key: "totalFat",
    weight_type: "g",
  },
  {
    name: "Saturated Fat",
    placeholder: "Add Saturated Fat",
    key: "saturatedFat",
    weight_type: "g",
  },
  {
    name: "Trans Fat",
    placeholder: "Add Trans Fat",
    key: "transFat",
    weight_type: "g",
  },
  {
    name: "Cholesterol",
    placeholder: "Add Cholesterol",
    key: "cholesterol",
    weight_type: "g",
  },
  {
    name: "Sodium",
    placeholder: "Add Sodium",
    key: "sodium",
    weight_type: "g",
  },
  {
    name: "Total Carbohydrate",
    placeholder: "Add Total Carbohydrate",
    key: "totalCarbohydrate",
    weight_type: "g",
  },
  {
    name: "Dietary Fiber",
    placeholder: "Add Dietary Fiber",
    key: "dietaryFiber",
    weight_type: "g",
  },
  {
    name: "Total Sugars",
    placeholder: "Add Total Sugars",
    key: "totalSugars",
    weight_type: "g",
  },
  {
    name: "Added Sugars",
    placeholder: "Add Added Sugars",
    key: "addedSugars",
    weight_type: "g",
  },
  {
    name: "Protein",
    placeholder: "Add Protein",
    key: "protein",
    weight_type: "g",
  },
  {
    name: "Vitamin A",
    placeholder: "Add Vitamin A",
    key: "vitaminA",
    weight_type: "mcg",
  },
  {
    name: "Vitamin B",
    placeholder: "Add Vitamin B",
    key: "vitaminB",
    weight_type: "mcg",
  },
  {
    name: "Vitamin C",
    placeholder: "Add Vitamin C",
    key: "vitaminC",
    weight_type: "mcg",
  },
  {
    name: "Vitamin D",
    placeholder: "Add Vitamin D",
    key: "vitaminD",
    weight_type: "mcg",
  },
  {
    name: "Vitamin E",
    placeholder: "Add Vitamin E",
    key: "vitaminE",
    weight_type: "mcg",
  },
  {
    name: "Calcium",
    placeholder: "Add Calcium",
    key: "calcium",
    weight_type: "mcg",
  },
  { name: "Iron", placeholder: "Add Iron", key: "iron", weight_type: "mcg" },
  {
    name: "Potassium",
    placeholder: "Add Potassium",
    key: "potassium",
    weight_type: "mcg",
  },
];

export default function DrawerNutrition({
  profile = {},
  isActive = false,
  closePanel = () => {},
  nutritionsData,
}) {
  const [showMore, setShowMore] = useState(true);
  const [showLess, setShowLess] = useState(false);

  function showMoreFunc() {
    setShowMore(false);
    setShowLess(true);
  }

  function showLessFunc() {
    if (showMore === false) {
      setShowMore(true);
      setShowLess(false);
    }
  }

  // console.log(nutritionsData)

  return (
    <DrawerItemContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="nutrition-wrapper" style={{height: "650px"}}>
          <div className="title">
            <div>
              <h1>Nutrition</h1>
            </div>
            <div className="close-icon" onClick={closePanel}>
              <i className="fas fa-times" />
            </div>
          </div>

          {nutritionProperties &&
            nutritionProperties.map((_nutrition, i) => {
              if ((i <= 8 && !showLess) || showLess) {
                return (
                  <FoodNutritionDetail
                    key={i}
                    label={_nutrition.name}
                    // percentages={nutritions[_nutrition].percentages}
                      percentages={
                        nutritionsData
                          ? nutritionsData[_nutrition.key]
                            ? nutritionsData[_nutrition.key]
                            : 0
                          : 0
                      }
                    // digit={0}
                    unit={_nutrition.weight_type}
                  />
                );
              }
              return "";
            })}

          <p className="description">
            The % Daily Value (DV) tells you how much a nutrient in a serving of
            food contributes to a daily diet. 2,000 calories a day is used for
            general nutrition advice.
          </p>

          {showMore ? (
            <p
              className="text-center"
              style={{
                color: `${colors.redColor}`,
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={showMoreFunc}
            >
              Show more
            </p>
          ) : (
            ""
          )}

          {showLess ? (
            <p
              className="text-center"
              style={{
                color: `${colors.redColor}`,
                cursor: "pointer",
                fontWeight: "600",
                // paddingBottom: '30px !important',
              }}
              onClick={showLessFunc}
            >
              Show less
            </p>
          ) : (
            ""
          )}
        </div>
      </DrawerContainer>
    </DrawerItemContainer>
  );
}
