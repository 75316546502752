import React from "react";
import { ItemsListContainer } from "./style";
import { Image } from "react-bootstrap";
import { NoAvatar } from "assets/img";

const ItemsList = ({ itemsList }) => {
  return (
    <ItemsListContainer className="d-flex flex-column">
      {itemsList &&
        itemsList.map((item) => {
          return (
            <div
              className="d-flex  align-items-center justify-content-between"
              style={{ marginBottom: "10px" }}
              key={item.food_id}
            >
              <div className="item-avtar ">
                <Image src={item.photo ? item.photo : NoAvatar} />
                <div className="item-name">{item.food_name}</div>
              </div>
              <div className="item-count">x{item.quantity}</div>
            </div>
          );
        })}
    </ItemsListContainer>
  );
};

export default ItemsList;
