import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const RefundConfirmationModalContainer = styled(MyDrawerCenterModal)`
  .mark-as-ready-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .cash-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 17px 13px;
      }
    }
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .cards-list-info-div {
      gap: 12px;
      margin-top: 16px;
      margin-bottom: 16px;
      .card-info {
        padding: 12px 20px;
        border-radius: 16px;
        background-color: #1b1c1d;
        width: -webkit-fill-available;
      }
    }
    .delete-suborder-meta {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-actions {
      button {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .cancel-btn {
        text-align: center;
        color: ${colors.redColor};
        margin-top: 14px;
        font-weight: 600;
      }
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
