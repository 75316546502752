import styled from "@emotion/styled";

export const TotalSalesChartContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;

  .total-sales-chart-top {
    .total-sales-title {
      font-size: 20px;
      font-weight: bold;
      color: white;
      padding-top: 5px;
      padding-left: 40px;
    }

    .chart-date {
      position: absolute;
      margin-top: 6rem;
      font-size: 14px;
      font-weight: 500;
      margin-left: 40px;
      margin-bottom: 25px;
    }
  }

  .dropdown-menu {
    &.show {
      background-color: #1b1c1d !important;
    }
  }
`;
