import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerLogoutContainer = styled(MyDrawerRight)`
  padding-bottom: 0;
  .log-out-panel {
    padding: 0 25px;
    font-family: Montserrat;
    position: relative;
    height: 100%;
    .log-out-footer {
      padding: 35px 25px 20px 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #27282a;
      width: 100%;
      .log-out-footer-head {
        margin-bottom: 40px;
        h3 {
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 10px;
        }
      }
      .log-out-footer-context {
        display: flex;
        flex-direction: column;
        .button {
          height: 50px;
          width: 100%;
          border-radius: 8px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          box-shadow: none;
          border: none;

          opacity: 1;
          -moz-transition: opacity 0.4s ease-out;
          -o-transition: opacity 0.4s ease-out;
          -webkit-transition: opacity 0.4s ease-out;
          -ms-transition: opacity 0.4s ease-out;
          transition: opacity 0.4s ease-out;
        }
        .button:hover {
          opacity: 0.8;
        }
        .button-accept {
          background-color: #ed2626;
          margin-bottom: 10px;
        }
        .button-cancel {
          background-color: transparent;
          color: #ed2626;
          font-weight: 600;
        }
      }
    }
  }
`;
