import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerNewFilterCustomersContainer = styled(MyDrawerRight)`
  overflow-y: unset !important;
  .drawer-content {
    // margin-top: 30px;

    .status-items {
      display: flex;
      gap: 30px;
      margin-top: 15px;
    }

    .location-wrapper {
      margin-top: 24px;
      .location-label {
      }

      .location-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: baseline;

        .location-input {
          margin-top: 25px;
          width: 100%;
          height: 38px;
          border-radius: 99px;
          border: solid 1px #525558;
          background: transparent;
          padding: 20px;
          color: #fff;
        }

        .close-icon {
          position: relative;
          right: 29px;
          align-items: center;
          display: flex;
          cursor: pointer;
        }
      }

      input:focus-visible {
        outline: unset;
      }

      .google-location-wrapper {
        ul {
          background-color: #1b1c1d;
          margin-top: 9px;
          border-radius: 20px;
          padding: 10px 20px;
          border: 1px solid #525558;
        }
        li {
          list-style: none;
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }

    .admin-select-container {
      margin-top: 24px;
    }

    .number-of-orders-container {
      margin-top: 24px;
      .rc-slider-track {
        background-color: #ed2626 !important;
      }

      .rc-slider-handle {
        border: 5px solid #ed2626 !important;
      }

      .rc-slider-handle-dragging {
        box-shadow: 0 0 0 5px #ed2626;
      }
      .filter-item-body {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 45px;
      }
      .filter-label {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.34px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .total-paid-divs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;
        .total-paid-div {
          display: flex;
          align-items: center;
          background: transparent;
          width: 144px !important;
          /* border-radius: 25px; */
          padding: 12px;
          border-bottom: 1px solid #525558;
          input {
            background: transparent;
            color: #fff;
            border: unset;
            outline: unset;
            width: -webkit-fill-available;
            ::placeholder {
              opacity: 0.3;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: -0.34px;
              color: #fff;
            }
          }
          input:focus-visible {
            outline: unset;
          }
        }
      }
      .input-divs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;
        input {
          background: transparent;
          color: #fff;
          border: unset;
          outline: unset;
          width: 144px !important;
          /* border-radius: 25px; */
          padding: 12px;
          border-bottom: 1px solid #525558;
          ::placeholder {
            opacity: 0.3;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: -0.34px;
            color: #fff;
          }
        }
        input:focus-visible {
          outline: unset;
        }
      }
    }
    .cc-file-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 39px;
    }

    .profile-photo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
    }

    .joined-date-wrapper {
      .join-date-label {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .fa-times {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: right;
          color: #ed2626;
        }
      }
      .join-date-div {
        background: transparent;
        /* border-radius: 20px; */
        border-bottom: 1px solid #525558;
        padding: 10px 20px 10px 0;
        display: flex;
        align-items: center;
        margin-top: 8px;
        position: relative;
        bottom: 0;
      }
      .join-date-input {
        background: transparent;
        color: #fff;
        border: unset;
        outline: unset;
        width: 100%;
        ::placeholder {
          opacity: 1;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: -0.34px;
          color: #ed2626;
        }
      }
      margin-top: 24px;
      .date-text {
        .date-picker-label {
          font-weight: bold;
        }
      }
    }

    .status-wrapper {
      .status-items {
        .status-item {
        }
      }
    }

    /* material-ui switch custom css */
    .MuiIconButton-label {
      color: #fff;
    }

    .MuiSwitch-track {
      background-color: #5f626a;
      opacity: unset;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #ed2626 !important;
      opacity: unset;
    }
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    .reset-all-btn {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ed2626;
      cursor: pointer;
    }
  }
`;

export const PopupCalendarContainer = styled.div`
  position: absolute;
  padding: 20px 10px;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  background-color: #27282a;
  min-width: 700px;
  z-index: 1;
  left: -27.5rem;
  top: 4rem;
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
  .apply-date {
    float: right;
    margin-right: 25px;
    width: 30%;
  }
`;
