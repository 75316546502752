import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { DrawerVerifyPhoneContainer } from "./style";
import { CustomersContext } from "context/CustomersContext";
import { drawersOverall } from "static/staticData";

export default function DrawerVerifyPhone({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    closeDrawer,
    profileData,
    setProfileData,
    editCustomerData,
    setEditCustomerData,
  } = useContext(CustomersContext);

  const [isVerifiedPhone, setIsVerifiedPhone] = useState();

  useEffect(() => {
    if (editCustomerData) {
      setIsVerifiedPhone(editCustomerData.phone_verified);
    } else {
      setIsVerifiedPhone(false);
    }
  }, [editCustomerData]);

  const onChangeStatus = (phone_verified) => {
    setIsVerifiedPhone(phone_verified);
  };

  const onSaveProfile = () => {
    setEditCustomerData({
      ...editCustomerData,
      phone_verified: isVerifiedPhone,
    });
    closeDrawer(drawersOverall.verifyPhone);
  };

  return (
    <DrawerVerifyPhoneContainer
      isActive={isActive}
      closePanel={closePanel}
      top="25%"
      width="470px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {/* <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Phone Number</div>
          <div></div>
        </div> */}
        <div className="title">Phone Number</div>
        <div className="drawer-status--options mb-auto mt-4">
          <DrawerChooseItem
            className="mb-4"
            title="Verified"
            active={isVerifiedPhone === 1}
            onChooseItem={() => onChangeStatus(1)}
          />
          <DrawerChooseItem
            title="Unverified"
            // subtitle="Not visible to customers"
            active={isVerifiedPhone !== 1}
            onChooseItem={() => onChangeStatus(0)}
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={onSaveProfile}
          >
            Save
          </MyDrawerButton>
        </div>
        <div className="cancel-btn" onClick={closePanel}>
          Cancel
        </div>
      </DrawerContainer>
    </DrawerVerifyPhoneContainer>
  );
}
