import styled from "@emotion/styled";
import { colors } from "theme";

export const StaffNotesContainer = styled.div`
  height: 400px !important;
  overflow-y: hidden;
  .notes-card-header {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    min-height: 70px;
    padding: 0 36px;
  }
  .notes-card-body {
    height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #27282a;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .notes-card-item {
      /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
      min-height: 85px;
      padding: 14px 32px;
      &:nth-child(odd) {
        background-color: rgb(0 0 0 / 13%);
      }
      &:nth-child(even) {
        background-color: #1b1c1d;
      }

      .change-status {
        color:#fff;
        opacity: 0.6;
      }
    }
  }
  .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    img {
      width: auto;
      height: 160px;
      object-fit: contain;
      margin-bottom: 26px;
    }
    .empty-screen {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      opacity: 0.6;
    }
  }
`;
