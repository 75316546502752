import React, { useState, useContext, useEffect } from "react";
import { DrawerSetInactiveStatusGlobalModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
// import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

const StaffStatusInactiveGlobalModal = ({
  isActive = false,
  closePanel = () => {},
  getStaffAll = () => {},
  //   onDoneSaveProfile = () => {},
}) => {
  const { id } = useParams();

  const {
    staffViewData,
    removeFromRestaurantConfirmModal,
    setRemoveFromRestaurantConfirmModal,
    setStaffs,
    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,
  } = useContext(RestaurantsContext);

  const [reason, setReason] = useState("");
  const [inactiveNote, setInactiveNote] = useState("");

  const [
    staffInactiveNoteNotification,
    setStaffInactiveNoteNotification,
  ] = useState();


useEffect(() => {
  setReason("")
  setInactiveNote("")
},[isActive])


  useEffect(() => {
    if (staffInactiveNoteNotification) {
      setTimeout(() => setStaffInactiveNoteNotification(null), 2 * 1000);
    }
  }, [staffInactiveNoteNotification]);

  const onSubmitInactiveStatus = (event) => {
    event.preventDefault();
    const dataConfig = {
      is_inactive: true,
      note_to_staff: reason,
      admin_note: inactiveNote,
    };
    customAxios
      .patch(`/staff/${staffViewData?.id}/status`, dataConfig)
      .then((res) => {
        setStaffInactiveNoteNotification({
          message: "Status changed to Inactive(Global).",
          type: "inactive-success",
          table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${staffViewData?.first_name} ${staffViewData?.last_name}</span> status changed to <b>Inactive (Global)</b>.`,
        });
        setStaffChangeRoleMainModal({
          ...staffChangeRoleMainModal,
          staffServerHostActiveModal: false,
        });
        getStaffAll();
      })
      .catch((err) => {
        console.error(err);
      });
    closePanel();
  };

  const onSubmitActiveStatus = (event) => {
    event.preventDefault();
    const dataConfig = {
      is_inactive: false,
      admin_note: inactiveNote,
    };
    customAxios
      .patch(`/staff/${staffViewData?.id}/status`, dataConfig)
      .then((res) => {
        setStaffInactiveNoteNotification({
          message: "Status changed to Active(Global).",
          type: "active-success",
          table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${staffViewData?.first_name} ${staffViewData?.last_name}</span> status changed to <b>Active (Global)</b>.`,
        });
        setStaffChangeRoleMainModal({
          ...staffChangeRoleMainModal,
          staffServerHostActiveModal: false,
        });
        getStaffAll();
      })
      .catch((err) => {
        console.error(err);
      });
    closePanel();
  };



  return (
    <DrawerSetInactiveStatusGlobalModalContainer
      isActive={isActive}
      closePanel={closePanel}
      left="38%"
      top="25%"
      width="470px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {staffInactiveNoteNotification && (
          <MyMessageBoxItemNew
            message={
              staffInactiveNoteNotification?.message
                ? staffInactiveNoteNotification?.message
                : ""
            }
            table={
              staffInactiveNoteNotification?.table
                ? staffInactiveNoteNotification?.table
                : ""
            }
            type={
              staffInactiveNoteNotification?.type
                ? staffInactiveNoteNotification?.type
                : ""
            }
            onClick={() => setStaffInactiveNoteNotification(null)}
          />
        )}

        {(staffViewData.serving_status === true ||
          staffViewData.serving_status == null) &&
        staffViewData?.status === 1 ? (
          <form onSubmit={onSubmitInactiveStatus}>
            <div className="set-discount-container">
              <div className="modal-title">Set Inactive Status (Global)</div>
              <div className="modal-subtitle">
                <b>{`${staffViewData?.first_name} ${staffViewData?.last_name}`}</b>{" "}
                status will be changed to{" "}
                <b>
                  {(staffViewData?.serving_status === true ||
                    staffViewData?.serving_status == null) &&
                  staffViewData?.status === 1
                    ? "Inactive"
                    : "Active"}
                </b>{" "}
                in <br /> all restaurants.
              </div>

              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Describe the Reason</div>
                  <div className="label-text">
                    Mandatory • Visible to restaurant staff
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={reason || ""}
                  placeholder="Type here"
                  onChange={(event) => setReason(event.target.value)}
                />
              </div>

              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Internal Note</div>
                  <div className="label-text">
                    Optional • Visible to Feasttt admins
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={inactiveNote || ""}
                  placeholder="Type here"
                  onChange={(event) => setInactiveNote(event.target.value)}
                />
              </div>

              <input
                type="submit"
                value="Set Inactive (Global)"
                className={reason ? "set-active-btn" : "set-inactive-btn"}
                disabled={reason ? false : true}
              />
              <div className="cancel-btn cursor-pointer" onClick={closePanel}>
                Cancel
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={onSubmitActiveStatus}>
            <div className="set-discount-container">
              <div className="modal-title">Set Active Status (Global)</div>
              <div className="modal-subtitle">
                <b>
                  {staffViewData?.first_name === undefined &&
                  staffViewData?.last_name === undefined
                    ? "No Name"
                    : `${staffViewData?.first_name} ${staffViewData?.last_name}`}
                </b>{" "}
                status will be changed to <b>inactive</b> in <br /> all
                restaurants.
              </div>

              {/* <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Describe the Reason</div>
                  <div className="label-text">
                    Mandatory • Visible to restaurant staff
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={reason || ""}
                  placeholder="Type here"
                  onChange={(event) => setReason(event.target.value)}
                />
              </div> */}

              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Internal Note</div>
                  <div className="label-text">
                    Optional • Visible to Feasttt admins
                  </div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={inactiveNote || ""}
                  placeholder="Type here"
                  onChange={(event) => setInactiveNote(event.target.value)}
                />
              </div>

              <input
                type="submit"
                value="Set Active (Global)"
                className={"set-active-btn"}
                // className={inactiveNote ? "set-active-btn" : "set-inactive-btn"}
                // disabled={inactiveNote ? false : true}
              />
              <div className="cancel-btn cursor-pointer" onClick={closePanel}>
                Cancel
              </div>
            </div>
          </form>
        )}
      </DrawerContainer>
    </DrawerSetInactiveStatusGlobalModalContainer>
  );
};

export default StaffStatusInactiveGlobalModal;
