import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useState, useContext, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { drawersStaff, mocks } from "static/staticData";
import { DrawerFilterStaffArchivedContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { restaurantServices } from "services";
import { useParams } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";

const stausMocks = {
  status: {
    0: {
      value: false,
      title: "Active",
    },
    1: {
      value: true,
      title: "Inactive",
    },
    2: {
      value: false,
      title: "Banned",
    },
    3: {
      value: true,
      title: "Deleted",
    },
  },

  role: {
    0: {
      value: true,
      title: "Manager",
    },
    1: {
      value: false,
      title: "Kitchen Staff",
    },
    2: {
      value: true,
      title: "Server / Host",
    },
  },
};

export default function DrawerFilterStaffArchived({
  profile = {},
  isActive = false,
  mode,
  closePanel = () => {},
}) {
  const { id } = useParams();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,

    setActiveStaffFilter,

    staffRoleSlugArchivedFilter,
    setStaffRoleSlugArchivedFilter,

    staffStatusArchivedFilter,
    setStaffStatusArchivedFilter,

    archivedData,
    setArchivedData,

    roleArchivedTitle,
    setRoleArchivedTitle,
    
    statusArchivedTitle,
    setStatusArchivedTitle,
  } = useContext(RestaurantsContext);

  const getArchivedStaff = async () => {
    customAxios
      .get(
        `/staff/archived?restaurant_id=${id}&page=1&page_size=10&role_slug=${staffRoleSlugArchivedFilter}&status=${staffStatusArchivedFilter}`
      )
      .then((res) => {
        if (res.status === 200) {
          setArchivedData(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Status filter
  const onChangeStatus = (status) => {
    // if (statusTitle.includes(status)) {
    //   setStatusTitle(statusTitle.filter((el) => el !== status));
    // } else {
    //   setStatusTitle((prev) => [...prev, status]);
    // }

    if (status === "Inactive") {
      //   if (staffStatusArchivedFilter.indexOf(0) > -1) {
      //     setStaffStatusArchivedFilter((prev) => prev.filter((el) => el !== 0));
      //   } else {
      //     setStaffStatusArchivedFilter((prev) => [...prev, 0]);
      //   }
      setStaffStatusArchivedFilter(0);
      setStatusArchivedTitle("Inactive");
    }
    if (status === "Active") {
      //   if (staffStatusArchivedFilter.indexOf(1) > -1) {
      //     setStaffStatusArchivedFilter((prev) => prev.filter((el) => el !== 1));
      //   } else {
      //     setStaffStatusArchivedFilter((prev) => [...prev, 1]);
      //   }
      setStaffStatusArchivedFilter(1);
      setStatusArchivedTitle("Active");
    }
    if (status === "Pending") {
      //   if (staffStatusArchivedFilter.indexOf(2) > -1) {
      //     setStaffStatusArchivedFilter((prev) => prev.filter((el) => el !== 2));
      //   } else {
      //     setStaffStatusArchivedFilter((prev) => [...prev, 2]);
      //   }
      setStaffStatusArchivedFilter(2);
      setStatusArchivedTitle("Pending");
    }
    if (status === "Banned") {
      //   if (staffStatusArchivedFilter.indexOf(9) > -1) {
      //     setStaffStatusArchivedFilter((prev) => prev.filter((el) => el !== 9));
      //   } else {
      //     setStaffStatusArchivedFilter((prev) => [...prev, 9]);
      //   }
      setStaffStatusArchivedFilter(9);
      setStatusArchivedTitle("Banned");
    }
    if (status === "Deleted") {
      //   if (staffStatusArchivedFilter.indexOf(-1) > -1) {
      //     setStaffStatusArchivedFilter((prev) => prev.filter((el) => el !== -1));
      //   } else {
      //     setStaffStatusArchivedFilter((prev) => [...prev, -1]);
      //   }
      setStaffStatusArchivedFilter(-1);
      setStatusArchivedTitle("Deleted");
    }
    setActiveStaffFilter(true);
  };

  // Role filter
  const onChangeRole = (role) => {
    // if (roleTitle.includes(role)) {
    //   setRoleTitle(roleTitle.filter((el) => el !== role));
    // } else {
    //   setRoleTitle((prev) => [...prev, role]);
    // }

    if (role === "Manager") {
      //   if (staffRoleSlugArchivedFilter.indexOf("manager") > -1) {
      //     setStaffRoleSlugArchivedFilter((prev) =>
      //       prev.filter((el) => el !== "manager")
      //     );
      //   } else {
      //     setStaffRoleSlugArchivedFilter((prev) => [...prev, "manager"]);
      //   }
      setStaffRoleSlugArchivedFilter("manager");
      setRoleArchivedTitle("Manager");
    }

    if (role === "Kitchen Staff") {
      //   if (staffRoleSlugArchivedFilter.indexOf("kitchen_staff") > -1) {
      //     setStaffRoleSlugArchivedFilter((prev) =>
      //       prev.filter((el) => el !== "kitchen_staff")
      //     );
      //   } else {
      //     setStaffRoleSlugArchivedFilter((prev) => [...prev, "kitchen_staff"]);
      //   }
      setStaffRoleSlugArchivedFilter("kitchen_staff");
      setRoleArchivedTitle("Kitchen Staff");
    }

    if (role === "Server / Host") {
      //   if (staffRoleSlugArchivedFilter.indexOf("waiter") > -1) {
      //     setStaffRoleSlugArchivedFilter((prev) =>
      //       prev.filter((el) => el !== "waiter")
      //     );
      //   } else {
      //     setStaffRoleSlugArchivedFilter((prev) => [...prev, "waiter"]);
      //   }
      setStaffRoleSlugArchivedFilter("waiter");
      setRoleArchivedTitle("Server / Host");
    }

    setActiveStaffFilter(true);
  };

  function onClickApply() {
    getArchivedStaff().then(() => closePanel());
  }

  function resetAll() {
    setStaffRoleSlugArchivedFilter("");
    setStaffStatusArchivedFilter("");
    setActiveStaffFilter(false);
    setRoleArchivedTitle("");
    setStatusArchivedTitle("");
    customAxios
      .get(`/staff/archived?restaurant_id=${id}&page=1&page_size=10`)
      .then((res) => {
        setArchivedData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
    closePanel();
  }

  useEffect(() => {
    console.log(staffRoleSlugArchivedFilter);
  }, [staffRoleSlugArchivedFilter]);

  return (
    <DrawerFilterStaffArchivedContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Filter</div>
          <div className="reset-all" onClick={resetAll}>
            Reset All
          </div>
        </div>
        <div className="drawer-status--options mb-auto">
          <div className="text-bold mb-4">Status</div>
          {Object.values(stausMocks.status).map((_status, _i) => (
            <DrawerChooseItem
              key={_i}
              className="mb-4"
              title={_status.title}
              // active={statusTitle.indexOf(_status.title) > -1}
              active={statusArchivedTitle === _status.title}
              onChooseItem={() => onChangeStatus(_status.title)}
            />
          ))}

          <div className="text-bold mb-4 role-title">Role</div>
          {Object.values(stausMocks.role).map((_role, _i) => {
            return (
              <DrawerChooseItem
                key={_i}
                className="mb-4"
                title={_role.title}
                // active={roleTitle && roleTitle.indexOf(_role.title) > -1}
                active={roleArchivedTitle === _role.title}
                onChooseItem={() => onChangeRole(_role.title)}
              />
            );
          })}
        </div>
        <MyDrawerButton
          type={
            staffRoleSlugArchivedFilter ||
            staffStatusArchivedFilter ||
            staffStatusArchivedFilter === 0
              ? "red"
              : "gray"
          }
          className="save-btn text-bold"
          onClick={onClickApply}
        >
          Apply
        </MyDrawerButton>
        <div className="cancel-btn" onClick={closePanel}>
          Cancel
        </div>
      </DrawerContainer>
    </DrawerFilterStaffArchivedContainer>
  );
}
