import React, { useState, useContext, useEffect } from "react";
import { DrawerTotalUseTimesModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerContainer } from "components/styledStyle";
import { Switch } from "@material-ui/core";
import { FaChevronRight } from "react-icons/fa";

const TotalUseTimesDrawerModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
}) => {
  const {
    setTotalUseTimes,
    localTotalUseTimes,
    setLocalTotalUseTimes,
    updatePromoCodeDetails,

    totalUseTimeToggle, setTotalUseTimeToggle,
  } = useContext(RestaurantsContext);

  // const [localTotalUseTimes, setLocalTotalUseTimes] = useState();

  // const [statusToggle, setStatusToggle] = useState(false);

  function handleChangeToggle(event) {
    // setStatusToggle(event.target.checked);
    setTotalUseTimeToggle(event.target.checked)
  }

  function handleOnClick() {
    if (totalUseTimeToggle === true) {
      // setTotalUseTimes("");
      setTotalUseTimes(totalUseTimeToggle);
    } else {
      setTotalUseTimes(localTotalUseTimes);
    }
    closePanel();
  }

  useEffect(() => {
    if (Object.keys(updatePromoCodeDetails)?.length) {
      if (updatePromoCodeDetails.limit_overall === null) {
        // setStatusToggle(true);
        setTotalUseTimeToggle(true)
      } else {
        // setStatusToggle(false);
        setTotalUseTimeToggle(false)
      }
    }
  }, [updatePromoCodeDetails.limit_overall]);


  function onClickCancel() {
    setLocalTotalUseTimes("")
    setTotalUseTimeToggle(false)
    closePanel()
  }

  return (
    <DrawerTotalUseTimesModalContainer
      isActive={isActive}
      closePanel={closePanel}
      // top="25rem"
      // bottom={statusToggle ? "29rem" : "25rem"}
      left="0"
      top="20%"
      position="absolute"
    >
      <DrawerContainer>
        <div className="total-use-times-container">
          <div className="title">Total Use Times</div>

          <div className="active-toggle mb-5">
            <div className="promo-apply">
              <div className="promo-apply-text">No Limit</div>
            </div>
            <div className="toggle-btn">
              <Switch checked={totalUseTimeToggle} onChange={handleChangeToggle} />
            </div>
          </div>

          {totalUseTimeToggle ? null : (
            <div className="total-time-input">
              <input
                type="text"
                value={localTotalUseTimes || ""}
                placeholder="Enter total use times here"
                onChange={(e) => setLocalTotalUseTimes(e.target.value.replace(/\D/g, ""))}
              />
            </div>
          )}

          <div className="btn-container">
            <div className={ localTotalUseTimes?.length || totalUseTimeToggle ? "done-btn-red" : "done-btn-gray"} onClick={handleOnClick}>
              Done
            </div>
            <div className="cancel-btn cursor-pointer" onClick={onClickCancel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </DrawerTotalUseTimesModalContainer>
  );
};

export default TotalUseTimesDrawerModal;
