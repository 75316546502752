import styled from "@emotion/styled";

export const HighestRatedStaffContainer = styled.div`
  .staff-highest-card {
    height: 335px;
    border-radius: 8px;
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: #1b1c1d;
    border-radius: 20px;
    padding: 0;

    .days {
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #27282a;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    .avatar {
      margin-top: 32px;
      height: 120px;
      width: auto;
    }

    .staff-name {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }

    .role {
      opacity: 0.5;
      font-size: 14px;
      color: #fff;
    }

    .badge-status {
      color: #181c32;
      background-color: #27282a;
      margin-top: 11px;
      color: #ffffff;
      padding: 7px 20px 7px 20px;
      margin-right: 10px;
      color: #1fc086;
    }

    .badge-rating {
      color: #181c32;
      background-color: #27282a;
      margin-top: 11px;
      color: white;
      padding: 7px 20px 7px 20px;
      i {
        color: #ffffff;
        font-size: 12px;
        color: #fdcb6e;
      }
    }

    .icon {
      position: absolute;
      right: 30px;
      bottom: 35px;
    }

    .not-enough {
      opacity: 0.6;
    }
  }
`;
