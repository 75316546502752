import React, { useState, useEffect } from "react";
import { AuthPageContainer } from "./style";
// import { Logo } from "assets/img";
import SingleButton from "components/SingleButton";
import { AuthLayout } from "layout/components/auth-layout";
import { colors } from "theme";
import { useHistory } from "react-router-dom";
import AuthInput from "components/Auth/AuthInput";
import { isValidEmail } from "services/functions";
import Logo from "assets/img/feast-logo.png";
import { customAxios } from "helpers/custom-helpers/customAxios";
import emailIcon from "assets/img/email.png";
import keyIcon from "assets/img/key.png";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export const ResetPasswordPage = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const [emailNotification, setEmailNotification] = useState();

  useEffect(() => {
    if (emailNotification) {
      setTimeout(() => setEmailNotification(null), 2 * 1000);
    }
  }, [emailNotification]);

  const changeValue = (e) => {
    setEmail(e.target.value);
  };

  const canSubmit = email && isValidEmail(email);

  const onClickSubmit = () => {
    // if (emailSent) {
    //   history.push("/create-password");
    // } else {
    //   setEmailSent(true);
    // }
    customAxios
      .post("/auth/forgot-password", { email })
      .then((res) => {
        setEmailSent(true);
        setEmailNotification({
          message: `We’ve just sent email with reset password link.`,
          type: "resend-email",
          table: `Check  <span style="font-weight:bold; color:#7d7e7f; margin-left:0">${res?.data?.email}`,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onClickCancel = () => {
    history.goBack();
  };

  return (
    <AuthPageContainer className="d-flex">
      {emailNotification && (
        <MyMessageBoxItemNew
          message={emailNotification?.message ? emailNotification?.message : ""}
          table={emailNotification?.table ? emailNotification?.table : ""}
          type={emailNotification?.type ? emailNotification?.type : ""}
          onClick={() => setEmailNotification(null)}
        />
      )}
      <AuthLayout>
        <div className="reset-password-left d-flex justify-content-center align-items-center">
          <div className="reset-password-form">
            <div className="reset-password-logo">
              <img src={Logo} alt="logo" width={160} />
            </div>
            {emailSent ? (
              <>
                <img src={emailIcon} alt="" className="icon" />
                <h3 className="reset-password-title">Check Email.</h3>
                <h3 className="reset-password-subtitle">{`We sent a link to reset your password to ${email}`}</h3>
                <SingleButton
                  title="Okay"
                  onClick={() => {
                    history.push("/");
                  }}
                />
                <div className="resend">
                  <span className="resend-text">Didn’t get it?</span>
                  <span className="resend-button" onClick={onClickSubmit}>
                    Resend
                  </span>
                </div>
              </>
            ) : (
              <>
                <img src={keyIcon} alt="" className="icon" />
                <h3 className="reset-password-title">Reset Password.</h3>
                <h3 className="reset-password-subtitle">
                  Don’t worry, we’ll email you a reset password link.
                </h3>
                <div className="reset-password-content">
                  <AuthInput
                    label="E-mail"
                    placeholder={"Enter your e-mail"}
                    placeholderIcon={<i className="far fa-envelope" />}
                    value={email}
                    onChange={changeValue}
                  />
                </div>
                <SingleButton
                  title="Continue"
                  disabled={!canSubmit}
                  onClick={onClickSubmit}
                />
                <SingleButton
                  className="cancel-btn"
                  title="Cancel"
                  btnColor="transparent"
                  txtColor={colors.redColor}
                  onClick={onClickCancel}
                />
              </>
            )}
          </div>
        </div>
      </AuthLayout>
    </AuthPageContainer>
  );
};
