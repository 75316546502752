import styled from "@emotion/styled";

export const DrawerChooseItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 26px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  transition: ease 0.3s;
  &.active,
  &:hover {
    border: solid 2px #ed2626;
  }
  &.inactive {
    border: solid 1px rgba(255, 255, 255, 0.1);
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    &.choose-subtitle {
      opacity: 0.5;
    }
  }
`;
