import styled from "@emotion/styled";

export const DrawerContainer = styled.div`
  padding: ${(props) => (props.nopadding ? "37px 0px" : "37px 26px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .bottom-btns {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    .mt-auto {
      margin-bottom: 20px;
    }
  }
  .drag-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 35px 15px 14px;
    border-radius: 16px;
    border: solid 1px #5b5e61;
    .drag-menu-item-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: auto;
      margin-left: 19px;
      .drag-menu-item-name {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.34px;
      }
    }
  }
  .drag-floor-item {
    padding: 20px 27px 20px 40px;
    display: -webkit-box;
    justify-content: space-between;
    align-items: center;
    margin-left: -26px;
    margin-right: -26px;
    border-bottom: solid 1px #5b5e61;
    &:first-of-type {
      border-top: solid 1px #5b5e61;
    }
    .left {
      display: flex;
      .drag-floor-item-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: auto;
        margin-left: 19px;
        .drag-floor-item-name {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: -0.34px;
        }
      }
    }
  }
`;
