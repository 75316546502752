/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerStaffInactiveContainer } from "./style";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { customAxios } from "helpers/custom-helpers/customAxios";

export default function DrawerStaffActive({
  // type = "Active",
  isActive = false,
  closePanel = () => {},
  selectedCustomers,
  fetchStaff = () => {},
  setSelectedCustomers = () => {},
}) {
  const [
    staffInactiveNoteNotification,
    setStaffInactiveNoteNotification,
  ] = useState();

  const [inactiveReason, setInactiveReason] = useState("");
  const [staffInactiveNote, setStaffInactiveNote] = useState("");

  useEffect(() => {
    if (staffInactiveNoteNotification) {
      setTimeout(() => setStaffInactiveNoteNotification(null), 2 * 1000);
    }
  }, [staffInactiveNoteNotification]);

  function activeStatus(e) {
    e.preventDefault();
    customAxios
    .patch(`/staff/status?staff_ids=${selectedCustomers.map((item) => item).join(",")}&is_inactive=false`, {
      is_suspended: false,
      note_to_staff: staffInactiveNote
    })
    .then((res) => {
      setInactiveReason("")
      setStaffInactiveNote("")
      setStaffInactiveNoteNotification({
        message: "Status changed to Active (Global).",
        type: "active-success",
        table: `The status of <span style="font-weight:bold; color:#7d7e7f; margin-left:0"> ${selectedCustomers.length} restaurant staff</span> changed to Active (Global).`,
      });
      closePanel();
      fetchStaff();
      setSelectedCustomers([])
    })
    .catch((err) => {
      console.error(err);
    });
 
  }

  return (
    <DrawerStaffInactiveContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      top="25%"
      width="470px"
    >
      <div className="set-status-wrapper mt-auto">
        {staffInactiveNoteNotification && (
          <MyMessageBoxItemNew
            message={
              staffInactiveNoteNotification?.message
                ? staffInactiveNoteNotification?.message
                : ""
            }
            table={
              staffInactiveNoteNotification?.table
                ? staffInactiveNoteNotification?.table
                : ""
            }
            type={
              staffInactiveNoteNotification?.type
                ? staffInactiveNoteNotification?.type
                : ""
            }
            onClick={() => setStaffInactiveNoteNotification(null)}
          />
        )}

        <h2 className="text-white text-center text-bold mb-3">
          Set Active Status (Global)
        </h2>

        <div className="modal-subtitle">
          The status of the selected staff will be changed to <br />{" "}
          <b>Active</b> in all restaurants.
        </div>

        <form>
          <div className="note-input">
            {/* <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Describe the Reason</div>
                <div className="label-text">Mandatory • Visible to staff</div>
              </div>
              <input
                className="w-100"
                type="text"
                value={inactiveReason || ""}
                placeholder="Type here"
                onChange={(event) => setInactiveReason(event.target.value)}
              />
            </div> */}

            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  Optional • Visible to Feasttt admins
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={staffInactiveNote || ""}
                placeholder="Type here"
                onChange={(event) => setStaffInactiveNote(event.target.value)}
              />
            </div>
          </div>
        </form>

        <div className="bottom-btns">
          <MyDrawerButton
            type={"red"}
            className="set-status-btn w-100"
            onClick={activeStatus}
          >
            Set Active (Global)
          </MyDrawerButton>

          <div className="cancel-btn" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </div>
    </DrawerStaffInactiveContainer>
  );
}
