/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { CustomersContext } from "context/CustomersContext";
import React, { useContext, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { customerServices } from "services/customerServices";
import { drawersCustomers } from "static/staticData";
import { colors } from "theme";
import DrawerEditCustomer from "./Drawers/DrawerEditCustomer";
import ProfileCardFavorite from "./ProfileCardFavorite";
import ProfileCardInfo from "./ProfileCardInfo";
import ProfileCardNotes from "./ProfileCardNotes";
import ProfileCardStat from "./ProfileCardStat";
import ProfileHistory from "./ProfileHistory";
import ProfileReviews from "./ProfileReviews";
import { CustomerProfilePageContainer } from "./style";
import { drawersOverall } from "static/staticData";
import DrawerStatus from "./Drawers/DrawerStatus";
import DrawerVerifyPhone from "./Drawers/DrawerVerifyPhone";
import DrawerVerifyEmail from "./Drawers/DrawerVerifyEmail";
import DrawerNotes from "./Drawers/DrawerNotes";
import DrawerRemoveCustomer from "./Drawers/DrawerRemoveCustomer";
import DrawerLocations from "./Drawers/DrawerLocations";
import { customAxios } from "helpers/custom-helpers/customAxios";
import CustomerEditMoreModal from "./Modals/CustomerEditMoreModal";
import BanCustomerModal from "./Modals/BanCustomerModal";
import ApproveBanModal from "./Modals/ApproveBanModal";
import BanErrorModal from "./Modals/BanErrorModal";
import UnbanCustomerModal from "./Modals/UnbanCustomerModal";
import ApproveUnbanModal from "./Modals/ApproveUnbanModal";
import { useHistory } from "react-router-dom";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import ReviewsRemoveModal from "./Drawers/DrawerReviewsDeleteModal";
import ReviewsRestoreModal from "./Drawers/DraweRestoreReviews";
import DrawerPaymentCard from "./Drawers/DrawerPaymentCard";
import CustomerErrorModal from "./Drawers/OopsCustomerModal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    left: "250px",
  },
}));

export const CustomerProfilePage = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const {
    openDrawers,
    closeDrawer,
    profileData,
    setProfileData,
    customerProfileNotification,
    setCustomerProfileNotification,
    customerBanId,
  } = useContext(CustomersContext);

  const [customerProfiler, setCustomerProfiler] = useState({});
  const [widgetData, setWidgetData] = useState({});
  const [banRestaurantModal, setBanRestaurantModal] = useState(false);
  const [approveBanModal, setApproveBanModal] = useState(false);
  const [banErrorModal, setBanErrorModal] = useState(false);
  const [restaurantEditMoreModal, setRestaurantEditMoreModal] = useState(false);
  const [banPublicNote, setBanPublicNote] = useState("");
  const [banInternalNote, setBanInternalNote] = useState("");

  const [unbanCustomerModal, setUnbanCustomerModal] = useState(false);
  const [confirmUnbanModal, setConfirmUnbanModal] = useState(false);

  const getRestaurantProfile = async () => {
    const result = await customerServices.getCustomerById(id);
    if (result.status === 200) {
      setProfileData(result?.data);
    }
  };

  useEffect(() => {
    getRestaurantProfile();
    customAxios
      .get(`/customer/${id}/widgets`)
      .then((res) => {
        setWidgetData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  useEffect(() => {
    if (customerProfileNotification) {
      setTimeout(() => setCustomerProfileNotification(null), 2 * 1000);
    }
  }, [customerProfileNotification]);

  const onClickBanCustomer = () => {
    customAxios
      .post(`/ban`, {
        target_id: id,
        target_type: 3,
        public_note: banPublicNote,
        note: banInternalNote,
      })
      .then((res) => {
        if (res?.status === 200) {
          getRestaurantProfile();
          setBanRestaurantModal(false);
          setApproveBanModal(false);
          setCustomerProfileNotification({
            message: "Customer Banned",
            type: "ban-success",
            table: ` ${profileData?.first_name} ${profileData.last_name}`,
          });
          closeDrawer(drawersCustomers.editCustomer);
        }
      })
      .catch((err) => {
        setBanRestaurantModal(false);
        setApproveBanModal(false);
        setBanErrorModal(true);
      });
  };

  const onClickUnbanCustomer = () => {
    let url;
    if (banInternalNote) {
      url = `/ban/${customerBanId}?note=${banInternalNote}`;
    } else {
      url = `/ban/${customerBanId}`;
    }
    customAxios.delete(url).then((res) => {
      if (res.status === 200) {
        setBanInternalNote("");
        setConfirmUnbanModal(false);
        setUnbanCustomerModal(false);
        getRestaurantProfile();
        setCustomerProfileNotification({
          message: "Customer Unbanned",
          type: "active-success",
          table: ` ${profileData?.first_name} ${profileData.last_name}`,
        });
        closeDrawer(drawersCustomers.editCustomer);
        // history.push("/customer");
      } else {
      }
    });
  };

  return (
    <>
      {profileData && profileData?.id === undefined ? (
        <Backdrop
          className={classes.backdrop}
          open={profileData?.id === undefined ? true : false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <CustomerProfilePageContainer
          style={{
            height: !!openDrawers[drawersCustomers.editCustomer]
              ? "100vh"
              : "-webkit-fill-available",
            overflowY: !!openDrawers[drawersCustomers.editCustomer]
              ? "hidden"
              : "auto",
          }}
        >
          {customerProfileNotification && (
            <MyMessageBoxItemNew
              message={
                customerProfileNotification?.message
                  ? customerProfileNotification.message
                  : ""
              }
              table={
                customerProfileNotification?.table
                  ? customerProfileNotification.table
                  : ""
              }
              type={
                customerProfileNotification?.type
                  ? customerProfileNotification.type
                  : "success"
              }
              onClick={() => setCustomerProfileNotification(null)}
            />
          )}
          <div className="customer-profile-header">
            <div className="left-part">
              <div className="title-header">
                <Link to="/customers" className="backlink-to-rest">
                  <i className="far fa-angle-left" />
                </Link>
                <h2>Custumer Profile</h2>
              </div>
            </div>
          </div>
          <div className="customer-profile-breadcumb">
            <div className="left-part">
              <div className="nav-line-to-back">
                <Link to="/customers" className="backlink-to-rest">
                  <span style={{ opacity: 0.6 }}>Customers</span>
                </Link>
                <span className="arrow-icon-container mx-3">
                  <i className="fas fa-angle-right" style={{ opacity: 0.6 }} />
                </span>
                <span className="current-user-name">
                  {profileData &&
                    `${
                      profileData?.first_name ? profileData?.first_name : "No"
                    } ${
                      profileData?.last_name ? profileData?.last_name : "Name"
                    }`}
                </span>
              </div>
            </div>
          </div>
          <div className="customer-profile-body">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              spacing={4}
            >
              <Grid container item xs={12} md={9}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid container item xs={12} md={8}>
                    <ProfileCardInfo
                      className="customer-profile-card"
                      customerProfilerData={profileData}
                    />
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <ProfileCardStat
                      customerId={id}
                      className="customer-profile-card"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid container item xs={12} md={4}>
                    <ProfileCardFavorite
                      className="customer-profile-card customer-favorite-card"
                      title="Favorite Restaurant"
                      value={widgetData?.restaurant?.name}
                      image={widgetData?.restaurant?.image}
                      icon="fas fa-store-alt"
                    />
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <ProfileCardFavorite
                      className="customer-profile-card customer-favorite-card"
                      title="Favorite Food"
                      value={widgetData?.food?.name}
                      image={widgetData?.food?.image}
                      icon="fas fa-utensils"
                      subTitle={widgetData?.food?.restaurant_name}
                    />
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <ProfileCardFavorite
                      className="customer-profile-card customer-favorite-card"
                      title="Favorite Drink"
                      value={widgetData?.drink?.name}
                      image={widgetData?.drink?.image}
                      icon="fas fa-glass-citrus"
                      subTitle={widgetData?.drink?.restaurant_name}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={3}>
                <ProfileCardNotes
                  customerId={id}
                  className="customer-profile-card customer-notes"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              spacing={4}
              className="mt-5"
            >
              <Grid container item xs={12} md={9}>
                <ProfileHistory
                  customerId={id}
                  className="customer-profile-card customer-history d-flex flex-column"
                />
              </Grid>
              <Grid container item xs={12} md={3}>
                <ProfileReviews
                  customerId={id}
                  className="customer-profile-card customer-reviews d-flex flex-column"
                />
              </Grid>
            </Grid>
          </div>
          <BanCustomerModal
            isActive={banRestaurantModal}
            closePanel={() => {
              setBanRestaurantModal(!banRestaurantModal);
              setBanInternalNote("");
              setBanPublicNote("");
            }}
            customer={profileData}
            onDone={() => {
              setApproveBanModal(true);
              setBanRestaurantModal(!banRestaurantModal);
            }}
            banPublicNote={banPublicNote}
            setBanPublicNote={setBanPublicNote}
            banInternalNote={banInternalNote}
            setBanInternalNote={setBanInternalNote}
          />
          <ApproveBanModal
            isActive={approveBanModal}
            closePanel={() => {
              setApproveBanModal(!approveBanModal);
              setBanRestaurantModal(!banRestaurantModal);
            }}
            customer={profileData}
            onDone={() => {
              onClickBanCustomer();
            }}
          />
          <BanErrorModal
            isActive={banErrorModal}
            closePanel={() => {
              setBanErrorModal(false);
            }}
          />
          <UnbanCustomerModal
            customer={profileData}
            isActive={unbanCustomerModal}
            closePanel={() => {
              setUnbanCustomerModal(!unbanCustomerModal);
              setBanInternalNote("");
            }}
            banInternalNote={banInternalNote}
            setBanInternalNote={setBanInternalNote}
            onDone={() => {
              setConfirmUnbanModal(!confirmUnbanModal);
              setUnbanCustomerModal(!unbanCustomerModal);
            }}
          />
          <ApproveUnbanModal
            isActive={confirmUnbanModal}
            closePanel={() => {
              setConfirmUnbanModal(!confirmUnbanModal);
              setUnbanCustomerModal(!unbanCustomerModal);
            }}
            onDone={() => {
              onClickUnbanCustomer();
            }}
          />
          {/* <CustomerEditMoreModal
        isActive={restaurantEditMoreModal}
        setBanRestaurantModal={setBanRestaurantModal}
        closePanel={() => {
          setRestaurantEditMoreModal(false);
        }}
        setUnbanCustomerModal={setUnbanCustomerModal}
      /> */}
          <DrawerEditCustomer
            isActive={!!openDrawers[drawersCustomers.editCustomer]}
            closePanel={() => closeDrawer(drawersCustomers.editCustomer)}
            renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
            onSaveProfile={() => {}}
            customerProfilerData={customerProfiler}
            setRestaurantEditMoreModal={setRestaurantEditMoreModal}
            setBanRestaurantModal={setBanRestaurantModal}
            setUnbanCustomerModal={setUnbanCustomerModal}
            restaurantEditMoreModal={restaurantEditMoreModal}
          />
          <DrawerStatus
            isActive={!!openDrawers[drawersOverall.statusOverall]}
            closePanel={() => closeDrawer(drawersOverall.statusOverall)}
            setBanRestaurantModal={setBanRestaurantModal}
          />
          <DrawerVerifyPhone
            isActive={!!openDrawers[drawersOverall.verifyPhone]}
            closePanel={() => closeDrawer(drawersOverall.verifyPhone)}
          />

          <DrawerVerifyEmail
            isActive={!!openDrawers[drawersOverall.verifyEmail]}
            closePanel={() => closeDrawer(drawersOverall.verifyEmail)}
          />
          <DrawerNotes
            isActive={!!openDrawers[drawersOverall.notes]}
            closePanel={() => closeDrawer(drawersOverall.notes)}
          />
          <DrawerRemoveCustomer
            isActive={!!openDrawers[drawersOverall.deleteCustomer]}
            closePanel={() => {
              closeDrawer(drawersOverall.deleteCustomer);
              closeDrawer(drawersCustomers.editCustomer);
            }}
          />
          <DrawerLocations
            isActive={!!openDrawers[drawersOverall.locations]}
            closePanel={() => closeDrawer(drawersOverall.locations)}
            customerId={id}
          />
          <ReviewsRemoveModal
            isActive={!!openDrawers[drawersOverall.customerReviewsDelete]}
            closePanel={() => closeDrawer(drawersOverall.customerReviewsDelete)}
          />
          <ReviewsRestoreModal
            isActive={!!openDrawers[drawersOverall.customerRestoreReviews]}
            closePanel={() =>
              closeDrawer(drawersOverall.customerRestoreReviews)
            }
          />
          <DrawerPaymentCard
            isActive={!!openDrawers[drawersOverall.paymentCard]}
            closePanel={() => closeDrawer(drawersOverall.paymentCard)}
          />
          <CustomerErrorModal
            isActive={!!openDrawers[drawersOverall.oopsErrorModal]}
            closePanel={() => closeDrawer(drawersOverall.oopsErrorModal)}
          />
        </CustomerProfilePageContainer>
      )}
    </>
  );
};
