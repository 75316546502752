import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerApplyDiscountContainer = styled(MyDrawerCenterModal)`
  
  .drawer-bottom-content {
    text-align: left;
    h5 {
      margin-bottom: 22px;
    }
    h3 {
      padding: 0 26px 0 26px;
    }
    .subtotal-container {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-left: 26px;
      padding-right: 26px;
    }

    .discount-options-container {
      width: 100%;
      max-height: 72px;
      display: flex;
      gap: 12px;

      .discount-option {
        width: 25%;
        height: 100%;
        padding-top: 26px;
        padding-bottom: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        border-radius: 16px;
        border: solid 1px #5b5e61;
        background-color: #27282a;

        &.active {
          border: solid 1px ${colors.redColor};
        }

        .option-title {
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.36px;
          color: white;
        }
      }
    }
  }
`;
