import React from "react";
import { DrawerMoveDeleteCategoryContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerMoveDeleteCategory = ({
  isActive = false,
  closePanel = () => {},
  chosenCategory = {},
  setDeleteCategoriesDrawersForMoveAndDelete = () => {},
  deleteCategoriesDrawersForMoveAndDelete,
  countCatgoryItemState,
}) => {
  return (
    <DrawerMoveDeleteCategoryContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">Delete Category</h2>
        <p className="text-white text-center mt-3">
          Looks like you have {countCatgoryItemState?.items?.length} items assigned to
          this category.
        </p>
        <p className="text-white text-center mt-3">
          What would you like to do?
        </p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mb-4 mt-2 text-bold"
            onClick={() => {
              setDeleteCategoriesDrawersForMoveAndDelete({
                ...deleteCategoriesDrawersForMoveAndDelete,
                deleteCategory: false,
                moveItems: true,
              });
            }}
          >
            Move Items
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold mt-2"
            onClick={() => {
              setDeleteCategoriesDrawersForMoveAndDelete({
                ...deleteCategoriesDrawersForMoveAndDelete,
                deleteCategory: false,
                moveItems: false,
                confirmMoveAndDelete: false,
                noCategoriesDelete: true,
              });
            }}
          >
            Delete Category & Items
          </MyDrawerButton>
          <MyDrawerButton
            type="transparent"
            className="cancel-btn"
            onClick={() => {
              closePanel();
            }}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerMoveDeleteCategoryContainer>
  );
};

export default DrawerMoveDeleteCategory;
