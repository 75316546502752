import styled from "@emotion/styled";

export const AnalyticsPageContainer = styled.div`
  padding: 15px 20px 15px 5px;

  .orders-stats-list-item {
    width: 100%;
    padding: 14px 0px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &.dark {
      background-color: #1b1c1d;
    }

    &.darker {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .stat-index {
      width: 6%;
      font-size: 22px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.5);
      text-align: right;
      padding-left: 34px;
    }

    .stat-logo {
      margin-left: 43px;
    }

    .stat-title {
      margin-left: 20px;
      width: 47%;
      font-size: 14px;
      font-weight: 700;
      color: white;
    }

    .cost-container {
      width: 47%;
      padding-right: 23px;
      display: flex;
      justify-content: flex-end;

      .cost-title {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }

      .cost-description {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }

  .tooltip-restaurants-list-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tooltip-list-item {
      font-size: 14px;
      color: white;
    }
  }
`;
