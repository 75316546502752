import { Avatar } from "@material-ui/core";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useEffect } from "react";
import { Image } from "react-bootstrap";
import { drawersOrder } from "static/staticData";
import { DrawerReviewServerContainer } from "./style";
import { customAxios } from "helpers/custom-helpers/customAxios";
import ServerReviewList from "./ServerReviewList";
import { FaChevronLeft, FaStar } from "react-icons/fa";
import defaultUser from "../../../../../assets/img/noAvatar.png";
import MyDropDown from "components/Elements/MyDropDown";
import moment from "moment";
import { NotesEmptyScreen } from "assets/img";

export default function DrawerReviewServer({
  isActive = false,
  closePanel = () => {},
  orderDetails = {},
  isOrderServer,
}) {
  const {
    openDrawer,
    serverReviewData,
    setServerReviewData,
    setSelectedItem,

    isOrderReviewsEnable,
    setOrderReviewsEnable,

    selectedItem,

    restoreReviewId,
    setRestoreReviewId,

    isRestoreDataEnable,
    setRestoreDataEnable,
  } = useContext(OrdersContext);

  useEffect(() => {
    if (orderDetails) {
      customAxios
        .get(`/orders/server/${orderDetails?.server?.id}/reviews`)
        .then((res) => {
          if (res.status === 200) {
            setServerReviewData(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isActive]);

  const onClickDeleteReview = (reviewData) => {
    setSelectedItem(reviewData);
    // openDrawer(drawersOrder.deleteReview);
  };

  function onClickRestoreReview(reviewData) {
    setRestoreReviewId(reviewData);
  }

  // console.log(serverReviewData, "own");
  // console.log(orderDetails, "orderDetails");

  return (
    <>
      <DrawerReviewServerContainer isActive={isActive} closePanel={closePanel}>
        <DrawerContainer className="p-0">
          {/* <div className="header-bg">
            <Image
              src={orderDetails?.server?.image || tempImg}
              className="bg-img"
            />
            <div className="drawer-header-content">
              <Avatar src={orderDetails?.server?.image || tempImg} />
              <h3>{orderDetails?.server?.name || ""}</h3>
              <h5>Server / Host</h5>
            </div>
          </div>
          <div className="reviews-body">
            {serverReviewData.map((_review, _i) => (
              <ServerReviewList
                _review={_review}
                key={_i}
                onClickDeleteReview={onClickDeleteReview}
              />
            ))}
          </div> */}
          <div className="drawer-bottom-content w-100">
            <div className="header-bg">
              <div className="d-flex  align-items-center close-drawer-icon">
                <FaChevronLeft
                  size={20}
                  style={{ zIndex: "9999999", color: "#fff" }}
                  className="cursor-pointer"
                  onClick={closePanel}
                />
                <div className="drawer-title">Reviews</div>
              </div>
              <Image
                src={orderDetails?.server?.image || defaultUser}
                className="bg-img"
              />
            </div>

            <div className="panel-status-container">
              {/* <div className="profile-line"></div> */}
              <div className="upper-part">
                <img
                  className="company-logo"
                  src={orderDetails?.server?.image || defaultUser}
                  alt="user-avatar"
                />

                <p className="company-name">{orderDetails?.server?.name}</p>

                <div className="role-slug">Server / Host</div>

                <div className="mt-2 rating">
                  <div className="mr-2">
                    <FaStar color={"#FDCB6E"} size={15} />
                  </div>
                  <div className="rating-number">
                    {orderDetails?.server?.rating}
                  </div>
                </div>
              </div>

              <div className="notes-count">
                <div>
                  <b>
                    {serverReviewData ? serverReviewData?.length : "--"} reviews
                  </b>
                </div>
                {/* {staffReviewsData.length > 0 && ( */}
                <div>
                  <MyDropDown
                    className="menu-header-select"
                    options={[
                      "Earliest",
                      "Oldest",
                      "Highest Rated",
                      "Lowest Rated",
                    ].map((item) => {
                      return { label: item, value: item };
                    })}
                    // value={{ label: staffSort, value: staffSort }}
                    // onChange={(selectedSortType) => setStaffSort(selectedSortType)}
                    width={115}
                    iconClosed={<i className="fas fa-angle-down" />}
                    placeholder="Sort"
                  />
                </div>
                {/* )} */}
              </div>

              <div className="notes-list">
                {serverReviewData?.length > 0 ? (
                  <div style={{ marginBottom: 50 }}>
                    {serverReviewData &&
                      serverReviewData.map((_review, _i) => {
                        return (
                          <div
                            style={
                              selectedItem?.id === _review?.id ||
                              restoreReviewId?.id === _review?.id
                                ? isRestoreDataEnable
                                  ? { opacity: "unset" }
                                  : { opacity: 0.4 }
                                : { opacity: "unset" }
                            }
                          >
                            <div key={_i} className="note-container-item">
                              {/* {_review?.id} */}
                              <div className="note-image-block">
                                <img
                                  src={
                                    _review?.image
                                      ? _review?.image
                                      : defaultUser
                                  }
                                  alt="avatar"
                                />
                              </div>

                              <div className="note-content-block">
                                <p className="commentator-name">
                                  {_review?.name}
                                </p>
                                <p className="commentator-mail">
                                  <b>1</b> Feasts Here . <b>4</b> Feasts Total
                                </p>
                              </div>

                              <div className="note-end-block">
                                <p className="date-note">
                                  <FaStar color={"#FDCB6E"} size={15} />
                                </p>
                                <div className="delete-note">
                                  {_review?.rating}
                                </div>
                              </div>
                            </div>

                            <div className="description">
                              {_review?.comment}
                            </div>

                            <div className="show-more">
                              {_review?.comment?.length > 50 ? (
                                <div className="text-bold">Show more</div>
                              ) : (
                                <div className=""></div>
                              )}
                              <div className="date-delete">
                                <div>
                                  {moment(_review?.created_date).format(
                                    "MMM D, YYYY"
                                  )}
                                </div>

                                {isRestoreDataEnable ? (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      openDrawer(
                                        drawersOrder.removeReviewModalScreen
                                      );
                                      onClickDeleteReview(_review);
                                    }}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </div>
                                ) : (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      openDrawer(
                                        drawersOrder.RestoreReviewModalScreen
                                      );
                                      onClickRestoreReview(_review);
                                    }}
                                  >
                                    <i className="fas fa-trash-undo-alt" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="no-note-data">
                    <img
                      className="no-notes-img"
                      src={NotesEmptyScreen}
                      alt="empty"
                    />
                    <p className="no-notes-message">No notes yet.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DrawerContainer>
      </DrawerReviewServerContainer>
    </>
  );
}
