import styled from "@emotion/styled";

export const ProfileCardStatContainer = styled.div`
  /* padding: 20px 36px; */
  .profile-stat-row {
    margin-top: 43px;
    .profile-stat-row-label {
      display: flex;
      margin-bottom: 10px;
      .percent {
        margin-left: 11px;
        display: flex;
      }
    }
    .profile-stat-row-value {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .sales-wrapper {
    padding-top: 19px;
    padding-bottom: 6px;
    border-radius: 8px;
    /* box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1); */
    /* background-color: #1b1c1d; */
    min-height: 316px;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: flex-start; */

    .time-periods {
      display: flex;
      align-items: center;
      margin-left: 37px;
      background: #28292a;
      border-radius: 20px;
      width: max-content;

      .period-filter {
        div {
          width: 91px;
        }
        padding: 8px 0px;
        width: 91px;
        text-align: center;
        font-size: 14px;
        color: #ffffff7d;
        cursor: pointer;

        &.selected-tab {
          background-color: #525558;
          padding: 15px;
          border-radius: 30px;
          padding: 8px 0px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          height: 40px;
        }
      }
    }

    .sales-container {
      padding: 0 38px 0 42px;
      margin-top: 50px;
      .first-row {
        margin-top: 41px;
      }
      .second-row {
        margin-top: 70px;
      }
      .sales-label {
        font-size: 16px;
        color: #fff;
        .shadow-text {
          opacity: 0.6;
        }
      }
      .sales-numbers {
        font-size: 30px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
`;
