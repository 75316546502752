import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import { DrawerContainer } from "components/styledStyle";
import React, { useState, useEffect, useContext } from "react";
import { FaArrowRight } from "react-icons/fa";
import { colors } from "theme";
import { DrawerClosedDaysContainer, ClosedDaysListContainer } from "./style";
import { FaChevronRight } from "react-icons/fa";
import DrawerDatePicker from "./DrawerDatePicker";
import DrawerAddEditClosedDay from "./DrawerAddEditClosedDay";
import DrawerDeleteClosedDay from "./DrawerDeleteClosedDay";
import moment from "moment";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams, Link } from "react-router-dom";
import { RestaurantsContext } from "context/RestaurantsContext";

export default function DrawerClosedDays({
  isActive = false,
  closePanel = () => {},
  onClickPlus = () => {},
}) {
  const { id } = useParams();
  const { restaurant, postRestaurant, setPostRestaurant } = useContext(
    RestaurantsContext
  );
  const [closedDaysDrawers, setClosedDaysDrawers] = useState({
    datePicker: false,
    addClosedDay: false,
    deleteClosedDay: false,
  });
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [closedDaysData, setClosedDaysData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (isActive) {
      getClosedDaysList();
    }
  }, [isActive]);

  const getClosedDaysList = () => {
    customAxios.get(`/restaurant/${id}/closed`).then((res) => {
      if (res.status === 200) {
        setClosedDaysData(res.data);
        setPostRestaurant({
          ...postRestaurant,
          closed_days: res.data,
        });
      } else {
      }
    });
  };

  const onChangeDate = (date) => {
    setSelectedDate(date);
    setSelectedItem("");
    setClosedDaysDrawers({
      ...closedDaysDrawers,
      datePicker: false,
      addClosedDay: true,
    });
  };

  const onClickSaveClosedDay = ({ name, isRepeat, date }) => {
    customAxios
      .post(`/restaurant/${id}/closed`, {
        closed_date: moment(date).format("YYYY-MM-DD"),
        name: name,
        is_annual: isRepeat,
      })
      .then((res) => {
        if (res.status === 200) {
          getClosedDaysList();
          setClosedDaysDrawers({
            ...closedDaysDrawers,
            datePicker: false,
            addClosedDay: false,
          });
          setSelectedItem("");
        }
      })
      .catch((err) => {});
  };

  const onClickEditClosedDay = (closedFormData) => {
    customAxios
      .put(`/restaurant/${id}/closed/${selectedItem?.id}`, {
        name: closedFormData.name,
        is_annual: closedFormData.isRepeat,
      })
      .then((res) => {
        if (res.status === 200) {
          getClosedDaysList();
          setClosedDaysDrawers({
            ...closedDaysDrawers,
            datePicker: false,
            addClosedDay: false,
          });
          setSelectedItem("");
          setSelectedId("");
        }
      })
      .catch((err) => {});
  };

  const onClickDeleteClosedDayModalHandler = () => {
    setClosedDaysDrawers({
      ...closedDaysDrawers,
      addClosedDay: false,
      deleteClosedDay: true,
    });
  };

  const onDeleteClosedDay = () => {
    customAxios
      .delete(`/restaurant/${id}/closed/${selectedItem?.id}`)
      .then((res) => {
        if (res.status === 200) {
          getClosedDaysList();
          setClosedDaysDrawers({
            ...closedDaysDrawers,
            datePicker: false,
            addClosedDay: false,
            deleteClosedDay: false,
          });
          setSelectedItem("");
          setSelectedId("");
        }
      })
      .catch((err) => {});
  };

  return (
    <DrawerClosedDaysContainer
      isActive={isActive}
      closePanel={closePanel}
      onClickRightIcon={onClickPlus}
      renderRightIcon={<FaArrowRight color={colors.gray} />}
    >
      <DrawerContainer>
        <div className="closed-days-modal-header d-flex justify-content-between align-items-center">
          <i className="far fa-angle-left" onClick={closePanel} />
          <h2 className="drawer-title text-white text-center">Closed Days</h2>
          <i
            className="far fa-plus cursor-pointer"
            onClick={() => {
              setClosedDaysDrawers({ ...closedDaysDrawers, datePicker: true });
              setSelectedId("");
              setSelectedItem({
                ...selectedItem,
                date: new Date(),
                name: "",
                isRepeated: false,
              });
            }}
          />
        </div>
        <div className="panel-status-container">
          {closedDaysData.length > 0 &&
            closedDaysData.map((dayItem, _i) => {
              return (
                <ClosedDaysListContainer>
                  <div className={`item-picker-left`}>
                    <div className="d-flex align-items-center">
                      <h6 className="item-picker-label">
                        {moment(dayItem?.closed_date).format("MMMM DD")}
                      </h6>
                      {dayItem?.is_annual ? (
                        <div className="repeated-annualy-div">
                          Repeat Annually
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h6 className="item-picker-value">{dayItem?.name}</h6>
                    </div>
                  </div>
                  <FaChevronRight
                    size={15}
                    color={colors.white3}
                    onClick={() => {
                      setSelectedId(_i);
                      setSelectedItem(dayItem);
                      setClosedDaysDrawers({
                        ...closedDaysDrawers,
                        datePicker: false,
                        addClosedDay: true,
                      });
                    }}
                  />
                </ClosedDaysListContainer>
              );
            })}
        </div>
      </DrawerContainer>
      <DrawerDatePicker
        isActive={closedDaysDrawers.datePicker}
        closePanel={() => {
          setClosedDaysDrawers({ ...closedDaysDrawers, datePicker: false });
        }}
        onChangeDate={onChangeDate}
        date={selectedItem.date}
      />
      <DrawerAddEditClosedDay
        isActive={closedDaysDrawers.addClosedDay}
        closePanel={() => {
          setClosedDaysDrawers({ ...closedDaysDrawers, addClosedDay: false });
          setSelectedItem("");
          setSelectedId("");
          setSelectedDate(new Date());
        }}
        selectedItem={selectedItem}
        onClickSaveClosedDay={onClickSaveClosedDay}
        onClickEditClosedDay={onClickEditClosedDay}
        selectedId={selectedId}
        selectedDateInfo={selectedDate}
        onClickDeleteClosedDayModalHandler={onClickDeleteClosedDayModalHandler}
      />
      <DrawerDeleteClosedDay
        isActive={closedDaysDrawers.deleteClosedDay}
        closePanel={() => {
          setClosedDaysDrawers({
            ...closedDaysDrawers,
            deleteClosedDay: false,
          });
          setSelectedItem("");
          setSelectedId("");
          setSelectedDate(new Date());
        }}
        onDeleteClosedDay={onDeleteClosedDay}
        selectedItem={selectedItem}
      />
    </DrawerClosedDaysContainer>
  );
}
