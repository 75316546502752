import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerApplyDiscountAreYouSureContainer = styled(MyDrawerBottom)`
  .drawer-bottom-content {
    height: 620px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: gainsboro;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    text-align: left;
    .top-title {
      .fa-times {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.53px;
        text-align: right;
        color: #ed2626;
      }
    }
    .neutritions-data {
      padding: 20px 6px;
      border-bottom: 1px solid #4d5053;
    }
  }
`;

export const Label = styled.div`
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => (props.weight ? props.weight : "bold")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  display: flex;
  align-items: center;
  gap: 2px;
  .weight-type {
    font-weight: normal !important;
  }
`;
