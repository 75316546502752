import React from "react";
import { ChartTooltipContainer } from "./style";

export const ChartTooltip = ({
  className = "",
  title,
  importantThing,
  factsList = [],
  isOrdersCountryChart = false,
  isRestaurantsCountryChart = false,
  isCustomersCountryChart = false,
}) => {
  const regularChartTooltipContent = (
    <>
      <span className="chart-tooltip-title">{title}</span>
      <span className="chart-tooltip-amount-customers">{importantThing}</span>
    </>
  );

  const ordersCountryChartTooltipContent = (
    <>
      <span className="chart-tooltip-title" id="orders-format">
        <i className="fas fa-store-alt" />
        {title}
      </span>
      <div className="tooltip-restaurants-list-container">
        {factsList.map((factItem) => (
          <span className="tooltip-list-item" kye={factItem}>
            {factItem}
          </span>
        ))}
      </div>
    </>
  );

  const restaurantsCountryChartTooltipContent = (
    <>
      <span className="chart-tooltip-amount-customers" id="restaurants-format">
        <i className="fas fa-circle country-chart-customers-circle" />
        Live Now, {title}
      </span>
      <div className="tooltip-restaurants-list-container">
        {factsList.map((factItem) => (
          <span className="tooltip-list-item" kye={factItem}>
            {factItem}
          </span>
        ))}
      </div>
    </>
  );

  const customersCountryChartTooltipContent = (
    <>
      <span className="chart-tooltip-title">
        <i className="fas fa-map-marker-alt country-chart-city-pin" />
        {title}
      </span>
      <span className="chart-tooltip-amount-customers">
        <i className="fas fa-circle country-chart-customers-circle" />
        {importantThing}
      </span>
    </>
  );

  return (
    <ChartTooltipContainer className={className}>
      {!isOrdersCountryChart &&
      !isRestaurantsCountryChart &&
      !isCustomersCountryChart
        ? regularChartTooltipContent
        : ""}
      {isOrdersCountryChart ? ordersCountryChartTooltipContent : ""}
      {isRestaurantsCountryChart ? restaurantsCountryChartTooltipContent : ""}
      {isCustomersCountryChart ? customersCountryChartTooltipContent : ""}
    </ChartTooltipContainer>
  );
};
