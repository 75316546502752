import React, { useEffect, useState } from "react";
import { TabsListByPeriodContainer } from "./style";
import { AnalyticPeriod } from "../../AnalyticPeriod";

export const TabsListByPeriod = ({
  className,
  tabs = ["First", "Second"],
  child1,
  child2,
  children,
  onTimePeriodChange = () => {},
  setDateRange = () => {},
  dateRange,
  onChangeActiveTab = () => {},
  label1,
  label2,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (activeTab) {
      onChangeActiveTab(activeTab);
    }
  }, [activeTab]);

  // console.log(location.pathname);

  return (
    <TabsListByPeriodContainer className={className}>
      <div className="tabs-container">
        {tabs.map((tab) => (
          <div
            className={`tab ${activeTab === tab ? "tab-active" : ""}`}
            onClick={() => setActiveTab(tab)}
            style={{ width: `${100 / tabs.length}%` }}
            key={tab}
          >
            {tab}
          </div>
        ))}
      </div>
      <AnalyticPeriod
        className="period-control-container"
        onChange={onTimePeriodChange}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
      <div className="tab-label">
        <div className="res-label">{label1}</div>
        <div>{label2}</div>
      </div>
      {/* <div>Please Keep Both children render here </div> */}
      <div className="tabs-content-list">{children}</div>
      <div className="tabs-content-list">
        {activeTab === tabs[0] ? child1 : child2}
      </div>
    </TabsListByPeriodContainer>
  );
};
