import MyDateRange from "components/Elements/MyDateRange";
import { DrawerContainer } from "components/styledStyle";
import React from "react";
import { DrawerAddClosedDayContainer } from "./style";

export default function DrawerAddClosedDay({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {

  return (
    <DrawerAddClosedDayContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer nopadding={true}>
        <div className={`calendar`}>
          <MyDateRange />
        </div>
      </DrawerContainer>
    </DrawerAddClosedDayContainer>
  );
}
