import styled from "@emotion/styled";
import { colors } from "theme";
import Box from "components/Box";

export const AuthInputContainer = styled(Box)`
  .auth-input-container {
    display: flex;
    border-radius: 7px;
    padding: 15px 20px;
    border-radius: 7px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    background-color: ${colors.gray2};
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    box-shadow: unset !important;
    position: relative;

    .input-placeholder {
      .input-placeholder-text {
        font-size: 15px;
        color: ${colors.white3};
      }
      .input-placeholder-icon {
        margin-right: 5px;

        i {
          font-size: 15px;
          color: ${colors.white3};
        }
      }
    }

    .password-toggle {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      opacity: 0.4;
    }

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      outline: unset;
      border: unset;
      padding: 0;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: 0px 35px 0px 20px;
      border: ${(props) => (props.error ? "solid 1px #ed2626" : "unset")};
      border-radius: ${(props) => (props.error ? "8px" : "unset")};
    }
  }
`;
