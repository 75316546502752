import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const PriceTierModalContainer = styled(MyDrawerCenterModal)`
  .drawer-content {
    .price-wrapper {
      display: contents;
      li {
        list-style: none;
        padding: 20px;
        border-radius: 13px;
        margin-bottom: 15px;
        color: #fff;
        cursor: pointer;
        text-align: center;
      }
    }
    .top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }
    .bottom-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: unset;
      color: ${colors.redColor} !important;
      font-weight: 600 !important;
      cursor: pointer;
    }
  }
`;
