import React, { useContext, useEffect, useState } from "react";
import { ServerHostActiveModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import EmptyStaff from "assets/img/noAvatar.png";
import { drawersStaff } from "static/staticData";
import { colors } from "theme";
import { useParams } from "react-router-dom";

const ServerHostActiveModal = ({ isActive = false, closePanel = () => {} }) => {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    staffViewData,
    postStaff,
    setPostStaff,

    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,

    removeFromRestaurantModal,
    setRemoveFromRestaurantModal,
  } = useContext(RestaurantsContext);

  const { id } = useParams();

  // console.log(staffViewData);

  return (
    <ServerHostActiveModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"15%"}
      left={"38%"}
      width="472px"
    >
      <DrawerContainer>
        <div className="server-host-active-modal">
          <div className="staff-profile">
            <img
              className="rounded-circle"
              src={staffViewData?.avatar ? staffViewData?.avatar : EmptyStaff}
              alt=""
            />
          </div>
          <div className="staff-title">
            {staffViewData?.first_name === undefined &&
            staffViewData?.last_name === undefined
              ? "No Name"
              : `${staffViewData?.first_name} ${staffViewData?.last_name}`}
          </div>

          <div className="drawer-title">
            {((staffViewData.serving_status === true ||
              staffViewData.serving_status == null) &&
              staffViewData?.status === 1 && (
                <span style={{ color: colors.green }}>Active</span>
              )) ||
              (staffViewData?.serving_status === undefined && (
                <span style={{ color: "rgb(247, 166, 76)" }}>Pending</span>
              )) ||
              (staffViewData?.status === 9 && (
                <span style={{ color: colors.redColor }}>Banned</span>
              )) ||
              (staffViewData?.status === -1 && (
                <span style={{ color: colors.redColor }}>Deleted</span>
              )) ||
              (staffViewData?.serving_status === false &&
                staffViewData?.status === 1 && (
                  <span style={{ color: colors.gray }}>Inactive</span>
                )) ||
              (staffViewData.serving_status === true &&
                staffViewData.status === 0 && (
                  <span style={{ color: colors.gray }}>Inactive (Global)</span>
                ))}
          </div>

          {/* options */}
          <div className="staff-option">
            {/* kitchen-staff active */}
            {(staffViewData.role_slug === "kitchen_staff" &&
              staffViewData.serving_status === true &&
              staffViewData.status === 1) ||
            (staffViewData.role_slug === "kitchen_staff" &&
              staffViewData.serving_status === false &&
              staffViewData.status === 1) ||
            (staffViewData.role_slug === "kitchen_staff" &&
              staffViewData.serving_status === true &&
              staffViewData.status === 0) ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.ChangeRoleToKitchenStaffModal)
                  }
                >
                  <span>
                    <i className="fas fa-concierge-bell" />
                  </span>{" "}
                  Change Role to <b>Server/Host</b>
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.ChangeRoleToManagerModal)
                  }
                >
                  <span>
                    <i className="fas fa-star" />
                  </span>{" "}
                  Change Role to <b>Manager</b>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveModalForm)
                  }
                >
                  <span>
                    {staffViewData.serving_status === true ||
                    (staffViewData.serving_status == null &&
                      staffViewData?.status === 1) ? (
                      <i className="fas fa-eye-slash" />
                    ) : (
                      <i className="fas fa-eye" />
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Current Restaurant)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveGlobalModalForm)
                  }
                >
                  <span>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1 ? (
                      <>
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.75 7.5H8.21875C8.6875 4.71875 9.75 2.75 11 2.75C12.2188 2.75 13.3125 4.71875 13.75 7.5ZM8 10.5C8 9.8125 8.03125 9.15625 8.09375 8.5H13.875C13.9375 9.15625 13.9688 9.8125 13.9688 10.5C13.9688 11.2188 13.9375 11.875 13.875 12.5H8.09375C8.03125 11.875 8 11.2188 8 10.5ZM18.125 7.5H14.75C14.4688 5.75 13.9688 4.15625 13.1875 3.09375C15.4375 3.75 17.25 5.40625 18.125 7.5ZM8.78125 3.09375C8 4.15625 7.5 5.75 7.21875 7.5H3.84375C4.71875 5.40625 6.53125 3.75 8.78125 3.09375ZM18.4688 8.5C18.625 9.15625 18.7188 9.8125 18.7188 10.5C18.7188 11.2188 18.625 11.875 18.4688 12.5H14.875C14.9375 11.8438 15 11.1875 15 10.5C15 9.84375 14.9375 9.15625 14.875 8.5H18.4688ZM7 10.5C7 11.1875 7.03125 11.8438 7.09375 12.5H3.5C3.34375 11.875 3.25 11.2188 3.25 10.5C3.25 9.8125 3.34375 9.15625 3.5 8.5H7.09375C7.03125 9.15625 7 9.84375 7 10.5ZM8.21875 13.5H13.75C13.3125 16.3125 12.2188 18.25 11 18.25C9.75 18.25 8.6875 16.3125 8.21875 13.5ZM13.1875 17.9375C13.9688 16.875 14.5 15.2812 14.75 13.5H18.125C17.25 15.625 15.4375 17.2812 13.1875 17.9375ZM3.84375 13.5H7.21875C7.5 15.2812 8 16.875 8.78125 17.9375C6.53125 17.2812 4.71875 15.625 3.84375 13.5Z"
                            fill="white"
                          />
                          <path
                            d="M2.63477 5.63672L17.6059 16.1196"
                            stroke="#27282A"
                            stroke-width="4"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.02278 4.08926C3.18117 3.86306 3.49294 3.80809 3.71915 3.96648L19.2364 14.8318C19.4626 14.9902 19.5176 15.3019 19.3592 15.5282L18.9768 16.0743C18.8184 16.3005 18.5067 16.3554 18.2805 16.197L2.76319 5.33173C2.53698 5.17334 2.48201 4.86157 2.6404 4.63537L3.02278 4.08926Z"
                            fill="white"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-globe" />
                      </>
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Global)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* kitchen-staff pending */}
            {staffViewData.role_slug === "kitchen_staff" &&
            staffViewData?.serving_status == null ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/*  kitchen-staff banned */}
            {staffViewData.role_slug === "kitchen_staff" &&
            staffViewData?.status === 9 ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* kitchen-staff deleted */}
            {staffViewData.role_slug === "kitchen_staff" &&
            staffViewData?.status === -1 ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>
              </>
            ) : null}

            {/* manager active */}
            {(staffViewData.role_slug === "manager" &&
              staffViewData.serving_status === true &&
              staffViewData.status === 1 &&
              staffViewData.is_owner === false) ||
            (staffViewData.role_slug === "manager" &&
              staffViewData.serving_status === false &&
              staffViewData.status === 1 &&
              staffViewData.is_owner === false) ||
            (staffViewData.role_slug === "manager" &&
              staffViewData.serving_status === true &&
              staffViewData.status === 0 &&
              staffViewData.is_owner === false) ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.ChangeRoleToKitchenStaffModal)
                  }
                >
                  <span>
                    <i className="fas fa-hat-chef" />
                  </span>{" "}
                  Change Role to <b>Server/Host</b>
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.ChangeRoleToManagerModal)
                  }
                >
                  <span>
                    <i className="fas fa-star" />
                  </span>{" "}
                  Change Role to <b>Kitchen Staff</b>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveModalForm)
                  }
                >
                  <span>
                    {staffViewData.serving_status === true ||
                    (staffViewData.serving_status == null &&
                      staffViewData?.status === 1) ? (
                      <i className="fas fa-eye-slash" />
                    ) : (
                      <i className="fas fa-eye" />
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "Inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Current Restaurant)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveGlobalModalForm)
                  }
                >
                  <span>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1 ? (
                      <>
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.75 7.5H8.21875C8.6875 4.71875 9.75 2.75 11 2.75C12.2188 2.75 13.3125 4.71875 13.75 7.5ZM8 10.5C8 9.8125 8.03125 9.15625 8.09375 8.5H13.875C13.9375 9.15625 13.9688 9.8125 13.9688 10.5C13.9688 11.2188 13.9375 11.875 13.875 12.5H8.09375C8.03125 11.875 8 11.2188 8 10.5ZM18.125 7.5H14.75C14.4688 5.75 13.9688 4.15625 13.1875 3.09375C15.4375 3.75 17.25 5.40625 18.125 7.5ZM8.78125 3.09375C8 4.15625 7.5 5.75 7.21875 7.5H3.84375C4.71875 5.40625 6.53125 3.75 8.78125 3.09375ZM18.4688 8.5C18.625 9.15625 18.7188 9.8125 18.7188 10.5C18.7188 11.2188 18.625 11.875 18.4688 12.5H14.875C14.9375 11.8438 15 11.1875 15 10.5C15 9.84375 14.9375 9.15625 14.875 8.5H18.4688ZM7 10.5C7 11.1875 7.03125 11.8438 7.09375 12.5H3.5C3.34375 11.875 3.25 11.2188 3.25 10.5C3.25 9.8125 3.34375 9.15625 3.5 8.5H7.09375C7.03125 9.15625 7 9.84375 7 10.5ZM8.21875 13.5H13.75C13.3125 16.3125 12.2188 18.25 11 18.25C9.75 18.25 8.6875 16.3125 8.21875 13.5ZM13.1875 17.9375C13.9688 16.875 14.5 15.2812 14.75 13.5H18.125C17.25 15.625 15.4375 17.2812 13.1875 17.9375ZM3.84375 13.5H7.21875C7.5 15.2812 8 16.875 8.78125 17.9375C6.53125 17.2812 4.71875 15.625 3.84375 13.5Z"
                            fill="white"
                          />
                          <path
                            d="M2.63477 5.63672L17.6059 16.1196"
                            stroke="#27282A"
                            stroke-width="4"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.02278 4.08926C3.18117 3.86306 3.49294 3.80809 3.71915 3.96648L19.2364 14.8318C19.4626 14.9902 19.5176 15.3019 19.3592 15.5282L18.9768 16.0743C18.8184 16.3005 18.5067 16.3554 18.2805 16.197L2.76319 5.33173C2.53698 5.17334 2.48201 4.86157 2.6404 4.63537L3.02278 4.08926Z"
                            fill="white"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-globe" />
                      </>
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "Inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Global)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* manager pending */}
            {staffViewData.role_slug === "manager" &&
            staffViewData.serving_status == null ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* manager banned */}
            {staffViewData.role_slug === "manager" &&
            staffViewData?.status === 9 ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* manager deleted */}
            {staffViewData.role_slug === "manager" &&
            staffViewData?.status === -1 ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>
              </>
            ) : null}

            {/* server/host active */}
            {(staffViewData.role_slug === "waiter" &&
              staffViewData.serving_status === true &&
              staffViewData.status === 1) ||
            (staffViewData.role_slug === "waiter" &&
              staffViewData.serving_status === false &&
              staffViewData.status === 1) ||
            (staffViewData.role_slug === "waiter" &&
              staffViewData.serving_status === true &&
              staffViewData.status === 0) ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.ChangeRoleToKitchenStaffModal)
                  }
                >
                  <span>
                    <i className="fas fa-hat-chef" />
                  </span>{" "}
                  Change Role to <b>Kitchen staff</b>
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.ChangeRoleToManagerModal)
                  }
                >
                  <span>
                    <i className="fas fa-star" />
                  </span>{" "}
                  Change Role to <b>Manager</b>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveModalForm)
                  }
                >
                  <span>
                    {staffViewData.serving_status === true ||
                    (staffViewData.serving_status == null &&
                      staffViewData?.status === 1) ? (
                      <i className="fas fa-eye-slash" />
                    ) : (
                      <i className="fas fa-eye" />
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Current Restaurant)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveGlobalModalForm)
                  }
                >
                  <span>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1 ? (
                      <>
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.75 7.5H8.21875C8.6875 4.71875 9.75 2.75 11 2.75C12.2188 2.75 13.3125 4.71875 13.75 7.5ZM8 10.5C8 9.8125 8.03125 9.15625 8.09375 8.5H13.875C13.9375 9.15625 13.9688 9.8125 13.9688 10.5C13.9688 11.2188 13.9375 11.875 13.875 12.5H8.09375C8.03125 11.875 8 11.2188 8 10.5ZM18.125 7.5H14.75C14.4688 5.75 13.9688 4.15625 13.1875 3.09375C15.4375 3.75 17.25 5.40625 18.125 7.5ZM8.78125 3.09375C8 4.15625 7.5 5.75 7.21875 7.5H3.84375C4.71875 5.40625 6.53125 3.75 8.78125 3.09375ZM18.4688 8.5C18.625 9.15625 18.7188 9.8125 18.7188 10.5C18.7188 11.2188 18.625 11.875 18.4688 12.5H14.875C14.9375 11.8438 15 11.1875 15 10.5C15 9.84375 14.9375 9.15625 14.875 8.5H18.4688ZM7 10.5C7 11.1875 7.03125 11.8438 7.09375 12.5H3.5C3.34375 11.875 3.25 11.2188 3.25 10.5C3.25 9.8125 3.34375 9.15625 3.5 8.5H7.09375C7.03125 9.15625 7 9.84375 7 10.5ZM8.21875 13.5H13.75C13.3125 16.3125 12.2188 18.25 11 18.25C9.75 18.25 8.6875 16.3125 8.21875 13.5ZM13.1875 17.9375C13.9688 16.875 14.5 15.2812 14.75 13.5H18.125C17.25 15.625 15.4375 17.2812 13.1875 17.9375ZM3.84375 13.5H7.21875C7.5 15.2812 8 16.875 8.78125 17.9375C6.53125 17.2812 4.71875 15.625 3.84375 13.5Z"
                            fill="white"
                          />
                          <path
                            d="M2.63477 5.63672L17.6059 16.1196"
                            stroke="#27282A"
                            stroke-width="4"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.02278 4.08926C3.18117 3.86306 3.49294 3.80809 3.71915 3.96648L19.2364 14.8318C19.4626 14.9902 19.5176 15.3019 19.3592 15.5282L18.9768 16.0743C18.8184 16.3005 18.5067 16.3554 18.2805 16.197L2.76319 5.33173C2.53698 5.17334 2.48201 4.86157 2.6404 4.63537L3.02278 4.08926Z"
                            fill="white"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-globe" />
                      </>
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Global)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* server/host pending */}
            {staffViewData.role_slug === "waiter" &&
            staffViewData?.serving_status == null ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  // onClick={() => openDrawer(drawersStaff.removeFromRestaurant)}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* server/host banned */}
            {staffViewData.role_slug === "waiter" &&
            staffViewData?.status === 9 ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {
                    setRemoveFromRestaurantModal({
                      ...removeFromRestaurantModal,
                      removeFromRestaurant: true,
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash-alt" />
                  </span>{" "}
                  Remove from Restaurant
                </div>
              </>
            ) : null}

            {/* server/host deleted */}
            {staffViewData.role_slug === "waiter" &&
            staffViewData?.status === -1 ? (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>
              </>
            ) : null}

            {/* owner */}
            {((staffViewData?.is_owner &&
              staffViewData.status === 1 &&
              staffViewData.serving_status === true) ||
              (staffViewData?.is_owner &&
                staffViewData.status === 1 &&
                staffViewData.serving_status === false) ||
              (staffViewData?.is_owner &&
                staffViewData.status === 0 &&
                staffViewData.serving_status === true)) && (
              <>
                <div
                  className="box"
                  style={{ padding: "20px 20px" }}
                  onClick={() => {}}
                >
                  View Profile
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveModalForm)
                  }
                >
                  <span>
                    {staffViewData.serving_status === true ||
                    (staffViewData.serving_status == null &&
                      staffViewData?.status === 1) ? (
                      <i className="fas fa-eye-slash" />
                    ) : (
                      <i className="fas fa-eye" />
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "Inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Current Restaurant)</div>
                </div>

                <div
                  className="box"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    openDrawer(drawersStaff.StaffStatusInactiveGlobalModalForm)
                  }
                >
                  <span>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1 ? (
                      <>
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.75 7.5H8.21875C8.6875 4.71875 9.75 2.75 11 2.75C12.2188 2.75 13.3125 4.71875 13.75 7.5ZM8 10.5C8 9.8125 8.03125 9.15625 8.09375 8.5H13.875C13.9375 9.15625 13.9688 9.8125 13.9688 10.5C13.9688 11.2188 13.9375 11.875 13.875 12.5H8.09375C8.03125 11.875 8 11.2188 8 10.5ZM18.125 7.5H14.75C14.4688 5.75 13.9688 4.15625 13.1875 3.09375C15.4375 3.75 17.25 5.40625 18.125 7.5ZM8.78125 3.09375C8 4.15625 7.5 5.75 7.21875 7.5H3.84375C4.71875 5.40625 6.53125 3.75 8.78125 3.09375ZM18.4688 8.5C18.625 9.15625 18.7188 9.8125 18.7188 10.5C18.7188 11.2188 18.625 11.875 18.4688 12.5H14.875C14.9375 11.8438 15 11.1875 15 10.5C15 9.84375 14.9375 9.15625 14.875 8.5H18.4688ZM7 10.5C7 11.1875 7.03125 11.8438 7.09375 12.5H3.5C3.34375 11.875 3.25 11.2188 3.25 10.5C3.25 9.8125 3.34375 9.15625 3.5 8.5H7.09375C7.03125 9.15625 7 9.84375 7 10.5ZM8.21875 13.5H13.75C13.3125 16.3125 12.2188 18.25 11 18.25C9.75 18.25 8.6875 16.3125 8.21875 13.5ZM13.1875 17.9375C13.9688 16.875 14.5 15.2812 14.75 13.5H18.125C17.25 15.625 15.4375 17.2812 13.1875 17.9375ZM3.84375 13.5H7.21875C7.5 15.2812 8 16.875 8.78125 17.9375C6.53125 17.2812 4.71875 15.625 3.84375 13.5Z"
                            fill="white"
                          />
                          <path
                            d="M2.63477 5.63672L17.6059 16.1196"
                            stroke="#27282A"
                            stroke-width="4"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.02278 4.08926C3.18117 3.86306 3.49294 3.80809 3.71915 3.96648L19.2364 14.8318C19.4626 14.9902 19.5176 15.3019 19.3592 15.5282L18.9768 16.0743C18.8184 16.3005 18.5067 16.3554 18.2805 16.197L2.76319 5.33173C2.53698 5.17334 2.48201 4.86157 2.6404 4.63537L3.02278 4.08926Z"
                            fill="white"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-globe" />
                      </>
                    )}
                  </span>{" "}
                  Change status to{" "}
                  <b>
                    {(staffViewData.serving_status === true ||
                      staffViewData.serving_status == null) &&
                    staffViewData?.status === 1
                      ? "Inactive"
                      : "Active"}
                  </b>
                  <div style={{ color: "gray" }}>(Global)</div>
                </div>
              </>
            )}
          </div>

          <div className="actions">
            <div
              onClick={() => {
                setStaffChangeRoleMainModal({
                  ...staffChangeRoleMainModal,
                  staffServerHostActiveModal: false,
                });
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </ServerHostActiveModalContainer>
  );
};

export default ServerHostActiveModal;
