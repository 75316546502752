import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerPartialRefundContainer = styled(MyDrawerCenterModal)`
  .drawer-bottom-content {
    text-align: center;
    h3 {
      margin-bottom: 10px;
    }
    .modal-title {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
    }
    h6 {
      margin-bottom: 31px;
      line-height: 1.5;
      letter-spacing: 0.27px;
    }
    .tip-meta {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 10px;
    }
    .tip-options {
      width: 100%;
      height: 64px;
      // border-top: 1px solid rgba(255, 255, 255, 0.08);
      // border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      display: flex;
      gap: 14px;

      .tip-option {
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.08);
        width: 25%;
        height: 100%;
        padding: 21px 0px;
        text-align: center;
        border-radius: 16px;

        // &:last-of-type {
        //   border-right-color: transparent;
        // }

        &.active {
          border: solid 2px #ed2626;
          .tip-option-title {
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: -0.36px;
            color: white !important;
          }
        }

        .tip-option-title {
          text-align: center;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: -0.36px;
          color: white;
          color: #5b5e61;
        }
      }
    }

    .type-tip-container {
      width: 100%;
      margin-bottom: 8px;
      margin-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 22px 0 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .tip-type-container {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 50%;

        &.active {
          .tip-type-title,
          .tip-type-value {
            color: white;
          }

          .tip-type-value-container {
            input {
              color: white;
            }
          }
        }

        &.fixed {
          align-items: flex-start;
        }

        &.percentage {
          align-items: flex-end;
        }

        .tip-type-title,
        .tip-type-value {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.34px;
          color: rgba(255, 255, 255, 0.5);
        }

        .tip-type-title {
          line-height: 14px;
          margin-bottom: 3px;
        }

        .tip-type-value-container {
          display: flex;

          &.percents {
            input {
              text-align: right;
            }
          }

          input {
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            background-color: transparent;
            border: 0px solid transparent;
            outline: none;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 4px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }

    .total-tip-container {
      text-align: center;
      margin-top: 24px;
      margin-bottom: 17px;

      .tip-title {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.25px;
        color: white;
      }
      .tip-notice {
        opacity: 0.6;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #fff;
      }
    }

    .bottom-btns {
      padding: 0 15px;
    }
  }
`;
