import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { MyAccountContext } from "context/MyAccountContext";
import React, { useState, useEffect, useContext } from "react";
import { adminServices } from "services";
import { showMessage } from "services/functions";
import { DrawerEmailSuccessContainer } from "./style";

export default function DrawerEmailSuccess({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    myAccount,
    setMyAccount,
    emailField,
    setEmailField,
    drawerChangeEmailClose,
    setDrawerChangeEmailClose,
  } = useContext(MyAccountContext);

  const [user, setUser] = useState({});

  useEffect(() => {
    if (myAccount) {
      setUser(myAccount);
    }
  }, [myAccount]);

  const onClickSaveChanges = async () => {
    saveChanges(user);
  };

  const saveChanges = (payload) => {
    const { updateMyInfo } = adminServices;
    const onSuccess = () => {
      setMyAccount(payload);
      showMessage("Link is resent");
    };
    const onFailure = (error) => showMessage(error, "error");
    updateMyInfo(
      { ...payload, avatar: payload?.avatar?.id, email: emailField ?? null },
      onSuccess,
      onFailure
    );
  };

  
  
  return (
    <DrawerEmailSuccessContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left">
          We sent you an email
        </h2>
        <p className="text-white text-left">
          {`Check your email to verify address (${myAccount?.email ?? ""}).`}
        </p>
        <p className="text-white text-left mb-5">
          Didn’t get it?{" "}
          <strong
            className="text-red text-bold cursor"
            onClick={onClickSaveChanges}
          >
            Resend
          </strong>
        </p>
        <div className="bottom-btns">
          <MyDrawerButton className="text-bold" type="red" onClick={() => {
              setDrawerChangeEmailClose({...drawerChangeEmailClose, changeEmail: false});
              closePanel();
              setEmailField('')
            }}>
            Okay
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerEmailSuccessContainer>
  );
}
