/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { DrawerSetStatusContainer } from "./style";
import { restaurantServices } from "services";

export default function DrawerSetStatus({
  type = "Active",
  isActive = false,
  closePanel = () => {},
  getRestaurantList = () => {},
}) {
  const [note, setNote] = useState("");
  const [internalNote, setInternalNote] = useState("");
  const {
    restaurantId,
    setRestaurantList,
    setSelectedRestaurants,
    setRestaurantListNotification,
  } = useContext(RestaurantsContext);
  // console.log(restaurantId);

  function activeStatus(e) {
    e.preventDefault();
    if (restaurantId) {
      const updateStatusDataConfig = {
        restaurant_ids: restaurantId,
        active: type === "Active" ? true : false,
        note_to_restaurant: note,
        note: internalNote,
      };
      customAxios
        .put(`/restaurant/status`, updateStatusDataConfig)
        .then((res) => {
          // console.log(res.data);
          getRestaurantList();
          setSelectedRestaurants([]);
          setRestaurantListNotification({
            message: `Status changed to ${
              type === "Active" ? "active" : "inactive"
            }. `,
            type: type === "Active" ? "promo-active" : "inactive-success",
            table: `The status of ${
              restaurantId.length
            } restaurants changed to ${
              type === "Active" ? "active" : "inactive"
            }.`,
          });
          setNote("");
          setInternalNote("");
          closePanel();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function onClickCancel() {
    setNote("");
    setInternalNote("");
    closePanel();
  }

  // const getRestaurantList = async () => {
  //   const result = await restaurantServices.getRestaurant();
  //   if (result.status === 200) {
  //     setRestaurantList(result?.data?.restaurants);
  //     // setFilteredRestaurantList(result?.data?.restaurants);
  //   }
  // };

  return (
    <DrawerSetStatusContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"37%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">
          Set {type} Status
        </h2>
        <div className="set-status-wrapper mt-auto">
          <div className="approve-modal-inputs">
            {type === "Inactive" && (
              <div className="input-area">
                <div className="d-flex justify-content-between">
                  <div className="label-text">Describe the Reason</div>
                  <div className="label-text">Mandatory • Visible to owner</div>
                </div>
                <input
                  className="w-100"
                  type="text"
                  value={note || ""}
                  placeholder="Type here"
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}
                />
              </div>
            )}
            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  Optional • Visible to Feasttt admins
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={internalNote || ""}
                placeholder="Type here"
                onChange={(event) => {
                  setInternalNote(event.target.value);
                }}
              />
            </div>
          </div>
          {/* <form>
            <div>
              <label className="label-text">Note</label>
            </div>
            {type === "Inactive" ? (
              <div>
                <input
                  type="text"
                  value={note || ""}
                  placeholder="Add note"
                  className="input-note"
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            ) : (
              <input
                type="text"
                value={note || ""}
                placeholder="Add note"
                className="input-note"
                onChange={(e) => setNote(e.target.value)}
              />
            )}
          </form> */}
          <div className="bottom-btns">
            {type === "Inactive" ? (
              <MyDrawerButton
                type={note ? "red" : "gray"}
                className="set-status-btn"
                onClick={activeStatus}
                disabled={note ? false : true}
              >
                Set Inactive
              </MyDrawerButton>
            ) : (
              <MyDrawerButton
                type="red"
                className="set-status-btn"
                onClick={activeStatus}
              >
                Set Active
              </MyDrawerButton>
            )}
            <MyDrawerButton
              type="transparent"
              className="cancel-btn mt-6"
              onClick={onClickCancel}
            >
              Cancel
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerSetStatusContainer>
  );
}
