import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const ApplyPromoCodeModalContainer = styled(MyDrawerCenterModal)`
  .assign-new-server-modal {
    position: relative;
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }

    .delete-suborder-actions {
      button {
        width: 100%;
      }
      .apply-btn {
        margin-bottom: 10px;
      }
    }

    .servers-list {
      max-height: 596px;
      margin-bottom: 15px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: gainsboro;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .server-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 24px;
        border-radius: 20px;
        border: solid 1px #5b5e61;
        background-color: #1b1c1d;
        cursor: pointer;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          border: solid 2px #ed2626;
          .left-part {
            .image-container {
              position: relative;
              .is-selected {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid 1px #ffffff;
                background-color: rgba(0, 0, 0, 0.6);
                border-radius: 50%;
              }
            }
          }
        }
        .left-part {
          display: flex;
          align-items: center;
          .image-container {
            margin-right: 15px;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          .server-name {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .right-part {
          display: flex;
          align-items: center;
          .table-count {
            margin-right: 20px;
            i {
              color: #ffffff;
              font-size: 16px;
            }
            img {
              width: 13px;
            }
            span {
              margin-right: 7px;
              color: #ffffff;
              font-size: 14px;
              font-weight: bold;
            }
          }
          .arrow-container {
            i {
              font-size: 20px;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.2);
            }
          }
        }
      }
    }
  }
`;

export const ArrowContainer = styled.div`
  i {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.2);
  }
`;

export const CheckItem = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
`;
