import styled from "@emotion/styled";
import { Dropdown } from "react-bootstrap";

export const RestaurantMenuSelectContainer = styled(Dropdown)`
  width: 280px;

  &.show {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent !important;
      border-color: #fff !important;
    }
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    text-align: left !important;
    border-radius: 25px;
    border: solid 1px #525558;
    /* background-color: transparent; */
    background-color: #27282A;
    font-size: 14px;
    font-weight: normal;
    padding: 0 30px;
    height: 40px;
    &:hover,
    &:focus,
    &:active {
      /* background-color: transparent !important; */
      background-color: #27282A !important;
      border-color: #fff !important;
    }
  }
  .dropdown-menu {
    max-height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: gainsboro;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    padding: 0;
    .dropdown-item {
      display: block !important;
    }
    &.show {
      border-radius: 25px;
      border: solid 1px #525558;
      background-color: #333437;
      min-width: 75px;
      width: 100%;
      a:first-of-type {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      a:last-child {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      a {
        color: #fff;
        p {
          margin-bottom: 0;
        }
      }
      a:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;
