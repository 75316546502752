import React from "react";
import { FoodItemDetailContainer } from "./style";
// import IconCheck from "components/Elements/IconCheck";
import { FaStar } from "react-icons/fa";

const FoodItemDetail = ({
  menuItem = {},
  onClickOrderFoodReview = () => {},
}) => {
  console.log(menuItem?.rating);
  let total = 5;
  let ratingResult = total - menuItem?.rating;

  return (
    <FoodItemDetailContainer>
      <div className="card-item">
        {/* {menuItem?.status === 3 ? (
          <IconCheck className="mr-5" />
        ) : (
          <i className="far fa-circle mr-5" />
        )} */}
        <h5 className="mr-4">{menuItem?.quantity || 0}</h5>
        <div className="card-item-content">
          <div className="item-row">
            <h5>{menuItem?.name || "-"}</h5>
            <h5 className="opacity6 d-flex" style={{ position: "relative" }}>
              {"$" + menuItem?.price || "Free"}
              <></>
            </h5>
          </div>
          {menuItem.customizations &&
            menuItem.customizations.length &&
            menuItem.customizations.map((item) => {
              return (
                <div className="item-row">
                  <h5 className="opacity6">
                    {`– ${item.name}: ${item.options
                      .map((_i) => _i.name)
                      .join(",")}`}
                  </h5>
                </div>
              );
            })}
          {menuItem?.instructions && (
            <div className="item-row">
              <h5 className="opacity6">{menuItem?.instructions || ""}</h5>
            </div>
          )}
          <div className="item-row">
            {menuItem?.rating > 0 ? (
              <>
                <h5>
                  {Array.from({ length: menuItem?.rating }, (v, i) => (
                    <FaStar className="mx-1" style={{color: '#FDCB6E'}} />
                  ))}{" "}
                  {Array.from({ length: ratingResult }, (v, i) => (
                    <FaStar className="mx-1" style={{color: '#3E4245'}} />
                  ))}{" "}
                  ({menuItem?.rating || 0})
                </h5>
                <h5
                  className="text-red cursor-pointer"
                  onClick={() => onClickOrderFoodReview(menuItem)}
                >
                  {"Review >"}
                </h5>
              </>
            ) : (
              <h5 className="cursor-pointer" style={{ opacity: 0.6 }}>
                No Reviews
              </h5>
            )}
          </div>
        </div>
      </div>
    </FoodItemDetailContainer>
  );
};

export default FoodItemDetail;
