import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerFilterOrdersContainer = styled(MyDrawerRight)`
  .top-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .drawer-label {
      font-size: 22px;
      font-weight: 500;
      margin-left: 2rem;
    }
    .reset-all-btn {
      font-weight: 600;
      font-size: 14px;
      color: #ed2626;
      cursor: pointer;
    }
  }
  .fa-times {
    color: #ed2626;
    cursor: pointer;
  }

  .drawer-content {
    .status-filter {
      .status-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 48px;
        margin-bottom: 20px;
      }
      .status-label {
        /* margin-bottom: 20px;
        margin-top: 48px; */
      }
    }
    .filter-group {
      margin-top: 30px;
      margin-bottom: 40px;
      .date-text {
        .date-picker-label {
          font-weight: bold;
        }
      }
      .cards-wrapper {
        display: flex;
        margin: 0 -5px;
        .active {
          border: 2px solid #ed2626;
        }
        .item {
          margin: 0 5px;
        }
      }
      .guests-label {
        margin-bottom: 20px;
      }
      .item-label {
        margin-bottom: 20px;
      }
      .popup-date-filter-calendar {
        position: absolute;
        left: -25.5rem;
        margin-top: 15px;
      }
      .guests-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .location-wrapper {
    width: 100%;
    .restaurant-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .location-label {
    }

    .location-input-wrapper {
      position: relative;
      .location-input {
        margin-top: 18px;
        width: 100%;
        height: 38px;
        border-radius: 99px;
        border: solid 1px #525558;
        background: transparent;
        color: #fff;
        background: #27282a;
        padding: 0 25px;
        &::placeholder {
          padding-left: 20px;
        }
      }

      .fa-search,
      .fa-times {
        /* position: absolute; */
        /* right: 19px; */
        align-items: center;
        display: flex;
        cursor: pointer;
        margin-top: -25px;
        margin-left: 1rem;
      }
    }

    input:focus-visible {
      outline: unset;
    }

    .google-location-wrapper {
      ul {
        background-color: #1b1c1d;
        margin-top: 9px;
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #525558;
      }
      li {
        list-style: none;
        margin-bottom: 12px;
        cursor: pointer;
      }
    }
    .show-search-result {
      position: absolute;
      background: #27282A;
      width: 336px;
      border-radius: 20px;
      border: 1px solid #525558;
      margin-top: 6px;
      z-index: 1;
      padding: 15px 20px 5px 20px;

      .restaurant-title {
        .restaurant-title-gap {
          margin-bottom: 14px;
          cursor: pointer;
        }
      }
    }
  }
`;
