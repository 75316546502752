import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const LocationModalContainer = styled(MyDrawerCenterModal)`
  .drawer-content {
    position: relative;
    .top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }
    * {
      height: auto;
    }

    .found-location-title {
      margin-bottom: 40px;
    }

    .location-input {
      background: transparent;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 1px solid #555555;
      color: #fff;
      font-weight: bold;
      min-height: 40px;
      padding-left: 22px;
      ::placeholder {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.34px;
        color: #ed2626;
      }
    }

    input:focus-visible {
      outline: unset;
    }

    .location-suggestions {
      position: absolute;
      padding: 16px 16px;
      object-fit: contain;
      border-radius: 20px;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
      background-color: #1b1c1d;
      width: 100%;
      z-index: 1;
    }

    .input-label {
      opacity: 0.5;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: -0.34px;
      color: #fff;
    }

    .found-address-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #555555;
      margin-top: 9px;
    }

    .click-pointer {
      cursor: pointer;
    }

    .input-icon {
      position: absolute;
      bottom: 14px;
    }

    .bottom-btns {
      button:first-child {
        margin-bottom: 20px;
      }
    }
  }
`;
