import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext } from "react";
import { drawersOrder } from "static/staticData";
import { DrawerDeleteReviewContainer } from "./style";

export default function DrawerDeleteReview({
  isActive = false,
  closePanel = () => {},
  onDeleteReview = () => {},
}) {
  const { openDrawer } = useContext(OrdersContext);

  // const onDeleteReview = () => {
  //   openDrawer(drawersOrder.deletedReview);
  // };
  return (
    <DrawerDeleteReviewContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper>
          <div className="drawer-bottom-content w-100">
            <h3 className="mb-3">Remove Review</h3>
            <p>
              You are about to remove this Review. This review will not affect
              the overall rating and will not be visible to Feasttt users
              anymore. Are you sure?
            </p>
            {/* <h5>This cannot be undone.</h5> */}
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-3"
                onClick={onDeleteReview}
              >
                Yes, Remove review
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                // onClick={() => closeDrawer(drawersMenu.availabilities)}
              >
                Keep
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerDeleteReviewContainer>
  );
}
