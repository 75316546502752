import React, { useState, useContext, useEffect, useRef } from "react";
import { EmailOtpSentModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { EmailImage } from "assets/img";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import ReactCodeInput from "react-verification-code-input";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { adminServices } from "services";
import { MyAccountContext } from "context/MyAccountContext";

const EmailOtpSentModal = ({
  isActive = false,
  closePanel = () => {},
  emailAddressInput,
  setMyAccountNotification,
}) => {
  const [resendingOTP, setResendingOTP] = useState(false);

  const resendOtpHandler = () => {
    customAxios
      .patch(`/admin/me/update/request`, {
        update: "email",
        email: emailAddressInput,
      })
      .then((res) => {
        if (res.status === 200) {
          setResendingOTP(false);
          setMyAccountNotification({
            message: "We’ve just sent email with confirmation code.",
            type: "active-success",
            table: `Check  <span style="font-weight:bold; color:#7d7e7f; margin-left:0">
    ${emailAddressInput || ""}
  </span>`,
          });
        } else {
        }
      });
  };

  return (
    <EmailOtpSentModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-email">
          <div className="modal-image">
            <div className="round-circle">
              <img src={EmailImage} alt="" />
            </div>
          </div>
          <div className="modal-title-new-email">We sent you an email</div>

          <div className="email-info-meta">
            {`Check your email to verify address (${emailAddressInput}).`}
          </div>

          <div className="email-info-meta-sub">
            Link is active for 10 minutes.
          </div>

          <div className="did-not-get-it-container">
            <span className="did-not-get-it-title">Didn’t get it?</span>
            <div
              className="btn-resend"
              onClick={() => {
                resendOtpHandler();
              }}
              aria-hidden="true"
            >
              <span
                className={`resend-title ${
                  resendingOTP ? "inactive" : "active"
                }`}
              >
                Resend
              </span>
            </div>
          </div>

          <div className="verify-actions">
            <MyDrawerButton
              type="red"
              onClick={() => {
                closePanel();
              }}
            >
              <b>Okay</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </EmailOtpSentModalContainer>
  );
};

export default EmailOtpSentModal;
