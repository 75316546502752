import React, { useEffect, useState } from "react";
import "./style.js";
import { TimeStampContainer } from "./style.js";

export default function TimeStamp({ date = 0 }) {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const evaluateTime = () => {
    let diff = Date.parse(new Date()) - date;
    const timeObj = {
      days: Math.trunc(diff / (1000 * 60 * 60 * 24)),
      hours: Math.trunc((diff / (1000 * 60 * 60)) % 24),
      minutes: Math.trunc((diff / 1000 / 60) % 60),
      seconds: Math.trunc((diff / 1000) % 60),
    };
    setTime(timeObj);
  };

  useEffect(() => {
    let timerId;
    if (date) {
      timerId = setInterval(() => evaluateTime(), 1000);
    }
    return () => timerId && clearInterval(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  if (!date) {
    return null;
  }

  return (
    <span>
      {time.days > 0 && `${time.days}d `}
      {time.hours > 0 && `${time.hours}h `}
      {`${time.minutes}m `}
      {`${time.seconds > 0 ? time.seconds : 0}s`}
    </span>
  );
}
