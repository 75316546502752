import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerStatusStaffContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 41px;
    .fa-trash {
      color: #ed2626;
      font-size: 20px;
      margin-top: 6px;
    }
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
`;
