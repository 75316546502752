import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerRemoveItemContainer } from "./style";

export default function DrawerRemoveCustomer({
  isActive = false,
  closePanel = () => {},
}) {
  // const { openDrawer } = useContext(OrdersContext);

  const onDeleteOrder = () => {
    // openDrawer(drawersOrder.deletedReview);
  };
  return (
    <DrawerRemoveItemContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <div className="drawer-bottom-content w-100">
            <h3 className="mb-3">Delete Customer</h3>
            <h6 className="mb-3">
              Are you sure you want to delete <b>selected Customer?</b>
            </h6>
            <h6 style={{ marginBottom: "15px" }}>This cannot be undone.</h6>
            <div className="bottom-btns">
              <MyDrawerButton type="red" className="mb-3" onClick={closePanel}>
                Keep Customer
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                // onClick={() => closeDrawer(drawersMenu.availabilities)}
              >
                Delete Customer
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerRemoveItemContainer>
  );
}
