import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerDeleteReviewContainer = styled(MyDrawerRight)`
  .drawer-bottom-content {
    text-align: center;
    h5 {
      margin-bottom: 22px;
    }
  }
`;
