import styled from "@emotion/styled";
import { colors } from "theme";

export const ItemPickerContainer = styled.div`
  width: 100%;
  min-height: 56px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  .item-picker-left {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    h6 {
      line-height: 1.57;
      letter-spacing: -0.34px;
      margin-bottom: 0;
      &.item-picker-label {
        opacity: 0.3;
      }
      &.item-picker-value {
        color: ${colors.redColor};
        font-weight: 500;
      }
    }
  }
  &.set {
    .item-picker-left {
      h6.item-picker-value {
        color: ${colors.white};
      }
    }
  }
`;
