import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerAssignToMenuContainer = styled(MyDrawerRight)`
  .drawer-menus-wrapper {
    .accordion {
      .card {
        background-color: #1b1c1d;
        border: unset;
        .selected-count {
          padding: 2px 5px 2px 6px;
          border-radius: 4px;
          background-color: #ed2626;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          color: #fff;
        }
        .card-header {
          background-color: #1b1c1d;
          padding: 15px 10px 15px 0;
          font-size: 18px;
        }
        .card-body {
          .category-item {
            padding: 15px 10px;
            border: solid 1px rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            margin-bottom: 10px;
            &.selected {
              border-color: #ed2626;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .header-title {
    .title {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.27px;
      color: #bbb;
      margin-bottom: 8px;
    }
    .food-title {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #fff;
    }
  }
`;
