import { Avatar, Divider } from "@material-ui/core";
import MyRating from "components/Elements/MyRating";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { FaEllipsisH, FaTrash } from "react-icons/fa";
import { drawersOrder } from "static/staticData";
import { StyledIconWrapper, StyledPopupContainer } from "theme/styles";
import { DrawerReviewServerContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import { FaTimes } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { mocks } from "static/staticData";
import noAvatar from "assets/img/noAvatar.png";
import { FaClock, FaStar } from "react-icons/fa";
import NoItem from "assets/img/no-items-yet.png";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { DrawerSortReview } from "app/pages/OrderDetailPage/Drawers/DrawerSortReview";

let sortArray = [
  {
    title: "Earliest",
    value: "earliest",
  },
  {
    title: "Oldest",
    value: "oldest",
  },
  {
    title: "Highest Rated",
    value: "highest_rated",
  },
  {
    title: "Lowest Rated",
    value: "lowest_rated",
  },
];

export default function DrawerFoodItemReviews({
  isActive = false,
  closePanel = () => {},
  foodDetails = {},
}) {
  const { openDrawers, openDrawer, closeDrawer } = useContext(OrdersContext);
  const [foodPrice, setFoodPrice] = useState(0);
  const [selectedSort, setSelectedSort] = useState("");
  const [reviewData, setReviewData] = useState("");
  const { id } = useParams();
  const [isSortReviewDrawerShowing, setIsSortReviewDrawerShowing] = useState(
    false
  );

  useEffect(() => {
    if (foodDetails) {
      setFoodPrice(foodDetails?.price);
      customAxios
        .get(`/orders/${id}/food/${foodDetails.id}/reviews`)
        .then((res) => {
          setReviewData(res?.data || []);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [foodDetails]);

  const onClickAddition = () => {
    let add = foodPrice + foodDetails?.price;
    setFoodPrice(add);
  };

  const onClickMinus = () => {
    let minus = foodPrice - foodDetails?.price;
    if (foodDetails?.price === foodPrice) {
    } else {
      setFoodPrice(minus);
    }
  };

  const onClickSortItem = (sortItem) => {
    setSelectedSort(sortItem);
    let data = [...reviewData.reviews];
    let sortedArray = [];
    if (sortItem?.value === "earliest") {
      sortedArray = data.sort(
        (a, b) => new Date(a.createdat) - new Date(b.createdat)
      );
      setReviewData({ ...reviewData, reviews: sortedArray });
    } else if (sortItem?.value === "oldest") {
      sortedArray = data.sort(
        (a, b) => new Date(b.createdat) - new Date(a.createdat)
      );
      setReviewData({ ...reviewData, reviews: sortedArray });
    } else if (sortItem?.value === "highest_rated") {
      sortedArray = data.sort((a, b) => +b.rating - +a.rating);
      setReviewData({ ...reviewData, reviews: sortedArray });
    } else if (sortItem?.value === "lowest_rated") {
      sortedArray = data.sort((a, b) => +a.rating - +b.rating);
      setReviewData({ ...reviewData, reviews: sortedArray });
    } else {
    }
    setIsSortReviewDrawerShowing(!isSortReviewDrawerShowing);
  };

  return (
    <DrawerReviewServerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <div className="header-bg">
          <div className="d-flex  align-items-center close-drawer-icon">
            <FaTimes
              size={20}
              style={{ zIndex: "9999999" }}
              className="cursor-pointer "
              onClick={closePanel}
            />
            <div className="drawer-title">Reviews</div>
          </div>

          <Image
            src={foodDetails?.photo?.file || defaultFood}
            className="bg-img"
          />
          <div className="drawer-header-content-review-food">
            <img src={foodDetails?.photo?.file || defaultFood} />
            <div className="food-name">{foodDetails?.title}</div>
          </div>
        </div>
        <div className="reviews-body">
          {reviewData && reviewData?.reviews && reviewData?.reviews?.length ? (
            <div className="r-header">
              <div className="reviews-body-header d-flex justify-content-between align-items-center">
                <div className="reviews-overall-rating">
                  <i className="fas fa-star" /> {reviewData?.average}
                </div>
                <div
                  className="sort-dropdown cursor-pointer"
                  onClick={() => {
                    setIsSortReviewDrawerShowing(!isSortReviewDrawerShowing);
                  }}
                >
                  {selectedSort ? selectedSort.title : "Sort"}
                  <i className="fas fa-angle-down" />
                </div>
              </div>
              <div className="sort-overall-review-count">
                {reviewData?.reviews?.length}
                <span className="sort-overall-title">reviews</span>
              </div>
            </div>
          ) : (
            <div className="empty-header">
              <div className="sort-overall-review-count">
                0 <span className="sort-overall-title">reviews</span>
              </div>
            </div>
          )}
          {reviewData && reviewData?.reviews && reviewData?.reviews?.length ? (
            <div className="d-flex flex-column review-list">
              {reviewData &&
                reviewData?.reviews &&
                reviewData?.reviews.map((el, _i) => (
                  <div className="review-list-item" key={_i}>
                    <div className="profile-info d-flex justify-content-between alignitems-center">
                      <div className="d-flex align-items-center image-div">
                        <img
                          src={el?.image || noAvatar}
                          alt=""
                          style={{ borderRadius: "50%" }}
                        />
                        <div className="name-time-div">
                          <div className="review-added-user-name">
                            {el?.name}
                          </div>
                          <div className="review-added-time">
                            {moment(el?.createdat)
                              .startOf("day")
                              .fromNow()}
                          </div>
                        </div>
                      </div>
                      <div className="review-rating-div">
                        {Array.from({ length: 5 }, (v, i) => (
                          <FaStar className="text-orange mx-1" />
                        ))}
                        {`(${el?.rating})`}
                      </div>
                    </div>
                    <div className="review-meta">{el?.comment}</div>
                    <div className="feastt-review-numericals d-flex">
                      <div className="feastt-review-numericals-item">
                        <div className="review-numericals-item-count">
                          {el?.feast_here || 0}
                        </div>
                        <div className="review-numericals-item-title">
                          Feasts here
                        </div>
                      </div>
                      <div className="feastt-review-numericals-item">
                        <div className="review-numericals-item-count">
                          {el?.total_feast || 0}
                        </div>
                        <div className="review-numericals-item-title">
                          Total feasts
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="empty-item-image">
              <img src={NoItem} alt="" />
            </div>
          )}
        </div>
      </DrawerContainer>
      <DrawerSortReview
        isActive={isSortReviewDrawerShowing}
        closePanel={() =>
          setIsSortReviewDrawerShowing(!isSortReviewDrawerShowing)
        }
        foodDetails={foodDetails}
        sortArray={sortArray}
        onClickSortItem={onClickSortItem}
      />
    </DrawerReviewServerContainer>
  );
}
