import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const RemoveConfirmContainer = styled(MyDrawerCenterModal)`
  .server-host-active-modal {
    position: relative;
    /* &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    } */
    .staff-profile {
      /* background-color: #1FC086; */
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;
      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 17px;
      }
      img {
        height: 7rem;
        position: absolute;
        bottom: -17px;
        left: -13px;
      }
    }
    .staff-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .staff-subtitle {
      text-align: center;
      font-size: 14px;
    }
    .actions {
      margin-top: 25px;
      text-align: center;
      color: ${colors.redColor};
      font-weight: 600;
      cursor: pointer;
      button {
        width: 100%;
        margin-bottom: 10px;
      }
      .remove-btn {
        border-radius: 8px;
        height: 50px;
      }
      .cancel-btn {
        border-radius: 8px;
        height: 50px;
        margin-top: 10px;
      }
    }
  }
`;
