import styled from "@emotion/styled";
import { colors } from "theme";

export const RestaurantsPageContainer = styled.div`
  .restaurant-list-wrapper {
    color: #fff;
    font-family: Montserrat;
    .restaurant-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 60px;
      border-bottom: 1px solid #4d5053;
      .left-part {
        .arrow-icon {
          display: flex;
          align-items: center;
          i {
            font-size: 25px;
            margin-right: 21px;
            margin-bottom: 7px;
            color: ${colors.white};
          }
          h2 {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
          }
        }
      }
      .handle-wrapper {
        justify-content: flex-end;
        margin-right: 20px;
        .delete-icon {
          padding: 0 14px;
        }
        .btn-all-active {
          border-radius: 25px;
          background-color: #60646c !important;
        }
        .selected-restaurants {
          height: 40px;
          padding: 0 23px;
          border-radius: 8px;
          box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
          background-color: ${colors.gray2};
          .text-undo {
            font-weight: bold;
            font-size: 14px !important;
            color: ${colors.redColor};
          }
        }
      }
      .right-part {
        display: flex;
        align-items: center;

        .menu-item {
          display: flex;
          align-items: center;
          position: relative;
          padding: 8px 12px 13px 18px;
          border-radius: 25px;
          /* border: solid 1px #525558; */
          background: #27282a;
          cursor: pointer;
          .fa-sliders-h {
            position: relative;
            left: -3px;
            top: 3px;
          }
          .active-dot {
            position: absolute;
            width: 11px !important;
            height: 11px !important;
            font-size: 14px;
            border: solid 3px #333437 !important;
            background-color: #ed2626 !important;
            border-radius: 50% !important;
            top: 10px !important;
            right: 12px !important;
          }
        }

        .search-pane {
          cursor: pointer;
          margin-right: 15px;
          height: 40px;
          border-radius: 25px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          -webkit-transition: border-color 500ms ease-out;
          -moz-transition: border-color 500ms ease-out;
          -o-transition: border-color 500ms ease-out;
          transition: border-color 500ms ease-out;

          &:hover {
            border: none;
          }
        }

        .show-search-result {
          position: absolute;
          background: #151617;
          width: 250px;
          border-radius: 20px;
          border: 1px solid #525558;
          margin-top: 6px;
          z-index: 1;
          padding: 15px 20px 0 20px;
          max-height: 400px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: ${colors.gray5};
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: ${colors.white};
            border-radius: 5px;
          }

          .restaurant-title {
            .restaurant-title-gap {
              margin-bottom: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
    .restaurant-list-body {
      padding: 25px 60px;
      .restaurant-stats-cards {
        gap: 20px;
      }
      .restaurant-list-container {
        margin-top: 30px;
        .restaurant-list {
          background-color: ${colors.gray2};
          border-radius: 8px;
          margin-bottom: 15px;
        }

        .restaurant-titles {
          /* position: sticky; */
          /* top: 0; */
          display: flex;
          align-items: center;
          padding: 10px 20px;
          color: rgba(255, 255, 255, 0.3);
          .fa-circle {
            color: rgba(255, 255, 255, 0.3);
          }
          .fa-minus-circle {
            color: #fff;
          }
          .item-flex-small {
            flex: 0 0 6%;
            font-size: 12px;
          }
          .item-flex-normal {
            flex: 0 0 11%;
            font-size: 12px;
          }
          .item-flex-big {
            flex: 0 0 23%;
            /* margin: 0 0 0 16px; */
            font-size: 14px;
            padding-left: 15px;
          }
          .item-flex-small-big {
            flex: 0 0 16%;
            font-size: 14px;
            padding-left: 15px;
          }

          .item-flex-big-big {
            flex: 0 0 42%;
            font-size: 14px;
            padding-left: 14px;
          }

          .item-flex-cuisine {
            flex: 0 0 25%;
            font-size: 14px;
          }
          .item-flex-total-sales {
            flex: 0 0 11%;
          }
          .item-flex-last {
            flex: 1 1 auto;
            font-size: 12px;
            /* text-align: end; */
            text-align: center;
          }
          .item-flex-last-last {
            flex: 1 1 auto;
            font-size: 12px;
            text-align: end;
            transform: translateX(-15px);
          }
          .left-padding-1 {
            padding-left: 15px;
          }
        }

        .paginator-element {
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.3);
          font-size: 18px;
          font-weight: bold;
          margin: 30px 0;
          .item-arrow {
            cursor: pointer;
            padding: 0 10px;
            i {
              color: rgba(255, 255, 255, 0.3);
            }
            &.active {
              i {
                color: #fff;
              }
            }
          }
          .numbers-container {
            display: flex;
            align-items: center;
            .number-item {
              cursor: pointer;
              padding: 0 10px;
              &.active {
                color: #fff;
              }
            }
          }
        }
        .search-applied {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .no-result {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .no-result-img {
            img {
              height: 250px;
              margin: 90px 0 15px 0;
            }
          }
          .no-result-title {
            font-size: 18px;
            font-size: bold;
            margin-bottom: 10px;
          }
          .no-result-subtitle {
            color: #fff;
            opacity: 0.4;
          }
        }
      }
    }
  }

  .switcher-body {
    display: flex;
    justify-content: space-between;
    padding: 25px 60px 0 60px;

    .nav {
      background: #27282a;
      border-radius: 30px;
      width: auto !important;
      display: flex;
      justify-content: space-between;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff;
      background-color: #ed2626;
      text-align: center;
      border-radius: 30px;
      width: 11rem !important;
    }

    a.nav-link {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-weight: 400;
      text-align: center !important;
      width: 11rem !important;
      display: flex;
      justify-content: center;
    }

    .nav.nav-pills .show > .nav-link,
    .nav.nav-pills .nav-link.hover {
      color: unset;
    }

    nav .show > .nav-link,
    .nav .nav-link:hover:not(.disabled),
    .nav .nav-link.active {
      transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease;
      color: #fff !important;
      font-weight: 600 !important;
    }

    .nav.nav-pills .nav-link {
      color: #b5b5c3;
      transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease;
      position: relative;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      /* padding-left: 50px; */
    }
    .tab-switcher-container {
      color: #fff;
      width: 50%;
      display: flex;
      align-items: center;
      border-radius: 20px;
      background-color: ${colors.gray5};
      margin: 0px 0;
      .tab-switcher-item {
        flex: 1 1 50%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        cursor: pointer;
        &.active {
          border-radius: 20px;
          background-color: ${colors.redColor};
          font-weight: 600;
        }
      }
    }
  }
`;
