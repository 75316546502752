/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaTrash } from "react-icons/fa";
import ProfileCardRated from "./ProfileCardRated";
import ProfileCardInfo from "./ProfileCardInfo";
import ProfileCardNotes from "./ProfileCardNotes";
import RestaurantProfileCardStat from "./ProfileCardStat";
import DrawerVerifyPhone from "./Drawers/DrawerVerifyPhone";
import DrawerVerifyEmail from "./Drawers/DrawerVerifyEmail";
import DrawerVerifyWebsite from "./Drawers/DrawerVerifyWebsite";
import DrawerPriceTier from "./Drawers/DrawerPriceTier";
import DrawerCuisine from "./Drawers/DrawerCuisine";
import DrawerBusinessHours from "./Drawers/DrawerBusinessHours";
import DrawerNotes from "./Drawers/DrawerNotes";
import DrawerClosedDays from "./Drawers/DrawerClosedDays";
import DrawerAvailableDay from "./Drawers/DrawerAvailableDay";
import DrawerAddClosedDay from "./Drawers/DrawerAddClosedDay";
import DrawerEditProfile from "./Drawers/DrawerEditProfile";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import DrawerStatus from "./Drawers/DrawerStatus";
import DrawerAddManager from "./Drawers/DrawerAddManager";
import { RestaurantProfileOverallContainer } from "./style";
import { drawersOverall } from "static/staticData";
import { colors } from "theme";
import { useParams, Link } from "react-router-dom";
import DrawerFoundManager from "./Drawers/DrawerFoundAddress";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { PromoCode } from "./PromoCode";
import ProfileCardInfoForPendingStatus from "./ProfileCardInfoForPendingStatus";
import { LeaveWithoutSaveModalContainer } from "./Drawers/DrawerLeavePageWithoutSaveModal/style";

export const RestaurantProfileOverall = ({}) => {
  const { id } = useParams();
  const {
    restaurant,
    openDrawers,
    openDrawer,
    closeDrawer,
    fetchRestaurant,
    postRestaurant,
    setPostRestaurant,
  } = useContext(RestaurantsContext);

  const [widgetData, setWidgetData] = useState({});
  const [notification, setNotification] = useState();

  const renderDrawerLeavePage = useMemo(() => {
    return (
      <LeaveWithoutSaveModalContainer
        isActive={!!openDrawers[drawersOverall.leavePage]}
        closePanel={() => closeDrawer(drawersOverall.leavePage)}
      />
    );
  }, [openDrawers[drawersOverall.leavePage]]);

  const renderDrawerEditProfile = useMemo(() => {
    return (
      <DrawerEditProfile
        isActive={!!openDrawers[drawersOverall.editProfile]}
        closePanel={() => closeDrawer(drawersOverall.editProfile)}
        renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
        onDoneSaveProfile={onDoneSaveProfile}
        setNotification={setNotification}
      />
    );
  }, [openDrawers[drawersOverall.editProfile]]);

  const renderDrawerStatus = useMemo(() => {
    return (
      <DrawerStatus
        isActive={!!openDrawers[drawersOverall.statusOverall]}
        closePanel={() => closeDrawer(drawersOverall.statusOverall)}
        onChangeStatus={(status) => {
          setPostRestaurant({ ...postRestaurant, status: status });
        }}
      />
    );
  }, [openDrawers[drawersOverall.statusOverall]]);

  const renderDrawerAddManager = useMemo(() => {
    return (
      <DrawerAddManager
        isActive={!!openDrawers[drawersOverall.addManager]}
        closePanel={() => closeDrawer(drawersOverall.addManager)}
      />
    );
  }, [openDrawers[drawersOverall.addManager]]);

  const renderDrawerVerifyPhone = useMemo(() => {
    return (
      <DrawerVerifyPhone
        isActive={!!openDrawers[drawersOverall.verifyPhone]}
        closePanel={() => closeDrawer(drawersOverall.verifyPhone)}
      />
    );
  }, [openDrawers[drawersOverall.verifyPhone]]);

  const renderDrawerVerifyEmail = useMemo(() => {
    return (
      <DrawerVerifyEmail
        isActive={!!openDrawers[drawersOverall.verifyEmail]}
        closePanel={() => closeDrawer(drawersOverall.verifyEmail)}
      />
    );
  }, [openDrawers[drawersOverall.verifyEmail]]);

  const renderDrawerVerifyWebsite = useMemo(() => {
    return (
      <DrawerVerifyWebsite
        isActive={!!openDrawers[drawersOverall.verifyWebsite]}
        closePanel={() => closeDrawer(drawersOverall.verifyWebsite)}
      />
    );
  }, [openDrawers[drawersOverall.verifyWebsite]]);

  const renderDrawerFoundAddress = useMemo(() => {
    return (
      <DrawerFoundManager
        isActive={!!openDrawers[drawersOverall.foundAddress]}
        closePanel={() => closeDrawer(drawersOverall.foundAddress)}
      />
    );
  });

  const renderDrawerPriceTier = useMemo(() => {
    return (
      <DrawerPriceTier
        isActive={!!openDrawers[drawersOverall.priceTier]}
        closePanel={() => closeDrawer(drawersOverall.priceTier)}
      />
    );
  }, [openDrawers[drawersOverall.priceTier]]);

  const renderDrawerCuisine = useMemo(() => {
    return (
      <DrawerCuisine
        isActive={!!openDrawers[drawersOverall.cuisines]}
        closePanel={() => closeDrawer(drawersOverall.cuisines)}
      />
    );
  }, [openDrawers[drawersOverall.cuisines]]);

  const renderDrawerBusinessHours = useMemo(() => {
    return (
      <DrawerBusinessHours
        isActive={!!openDrawers[drawersOverall.businessHours]}
        closePanel={() => closeDrawer(drawersOverall.businessHours)}
      />
    );
  }, [openDrawers[drawersOverall.businessHours]]);

  const renderDrawerAvailableDay = useMemo(() => {
    return (
      <DrawerAvailableDay
        isActive={!!openDrawers[drawersOverall.availableDay]}
        closePanel={() => closeDrawer(drawersOverall.availableDay)}
      />
    );
  }, [openDrawers[drawersOverall.availableDay]]);

  const renderDrawerClosedDays = useMemo(() => {
    return (
      <DrawerClosedDays
        isActive={!!openDrawers[drawersOverall.closedDays]}
        closePanel={() => closeDrawer(drawersOverall.closedDays)}
        onClickPlus={() => {
          openDrawer(drawersOverall.addClosedDay);
        }}
      />
    );
  }, [openDrawers[drawersOverall.closedDays]]);

  const renderDrawerAddClosedDay = useMemo(() => {
    return (
      <DrawerAddClosedDay
        isActive={!!openDrawers[drawersOverall.addClosedDay]}
        closePanel={() => closeDrawer(drawersOverall.addClosedDay)}
      />
    );
  }, [openDrawers[drawersOverall.addClosedDay]]);

  const renderDrawerNotes = useMemo(() => {
    return (
      <DrawerNotes
        isActive={!!openDrawers[drawersOverall.notes]}
        closePanel={() => closeDrawer(drawersOverall.notes)}
      />
    );
  }, [openDrawers[drawersOverall.notes]]);

  const onDoneSaveProfile = async () => {
    await fetchRestaurant(id);
    // closeDrawer([drawersMenu.deleteMenu, drawersMenu.menuEdit, drawersMenu.deleteConfirm]);
  };

  useEffect(() => {
    if (restaurant?.status !== 3 && restaurant?.status !== undefined) {
      getWidgetData();
    }
  }, [id, restaurant?.status]);

  function getWidgetData() {
    customAxios(`/restaurant/${id}/widgets`)
      .then((res) => {
        setWidgetData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification(null), 5000);
    }
  }, [notification]);

  return (
    <RestaurantProfileOverallContainer>
         {notification && (
        <MyMessageBoxItemNew
          message={notification?.message ? notification?.message : ""}
          table={notification?.table ? notification?.table : ""}
          type={notification?.type ? notification?.type : ""}
          onClick={() => setNotification(null)}
        />
      )}
      <div className="restaurant-profile-body">
        {/* <div className="left-part">
          <div className="nav-line-to-back">
            <Link to="/restaurants" className="backlink-to-rest">
              Restaurants
            </Link>
            <span className="arrow-icon-container mx-3">
              <i className="fas fa-angle-right" />
            </span>
            <span className="current-restaurant">{restaurant?.title}</span>
          </div>
        </div> */}
        {restaurant?.status === 3 ? (
          <ProfileCardInfoForPendingStatus
            className="restaurant-profile-card"
            profile={restaurant}
          />
        ) : (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
          >
            <Grid container item xs={12} md={6}>
              <ProfileCardInfo
                className="restaurant-profile-card"
                profile={restaurant}
              />
              {/* ---- */}
              <div className="w-100 add-promo-code-wrapper">
                <PromoCode profile={restaurant} />
              </div>
              {/* ---- */}
            </Grid>

            <Grid container item xs={12} md={6}>
              <RestaurantProfileCardStat
                className="restaurant-profile-card profile-stat"
                profile={restaurant}
              />
              <ProfileCardNotes
                className="restaurant-profile-card restaurant-notes"
                profile={restaurant}
              />
              {/* ---- */}
              <Grid
                justify="space-between"
                container
                direction="row"
                spacing={4}
              >
                <Grid container item xs={12} md={6}>
                  <ProfileCardRated
                    className="restaurant-profile-card restaurant-favorite-card"
                    title={widgetData?.highest_rated?.name}
                    value="Highest Rated Item"
                    rating={widgetData?.highest_rated?.average_rating}
                    widgetBackgroundImage={widgetData?.highest_rated?.image}
                  />
                </Grid>
                <Grid container item xs={12} md={6}>
                  <ProfileCardRated
                    className="restaurant-profile-card restaurant-favorite-card"
                    title={widgetData?.lowest_rated?.name}
                    value="Lowest Rated Item"
                    rating={widgetData?.lowest_rated?.average_rating}
                    widgetBackgroundImage={widgetData?.lowest_rated?.image}
                  />
                </Grid>
              </Grid>
              {/* ---- */}
            </Grid>
          </Grid>
        )}
      </div>

      {renderDrawerEditProfile}
      {renderDrawerStatus}
      {renderDrawerVerifyPhone}
      {renderDrawerVerifyEmail}
      {renderDrawerVerifyWebsite}
      {renderDrawerPriceTier}
      {renderDrawerCuisine}
      {renderDrawerBusinessHours}
      {renderDrawerAvailableDay}
      {renderDrawerClosedDays}
      {renderDrawerAddClosedDay}
      {renderDrawerNotes}
      {renderDrawerAddManager}
      {renderDrawerFoundAddress}
      {renderDrawerLeavePage}
    </RestaurantProfileOverallContainer>
  );
};
