import React, { useEffect } from "react";
import {
  ApproveRestaurantModalContainer,
  ApproveInputContainer,
} from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerInputContainer } from "components/Elements/DrawerElements/DrawerInput/style";
import { NoAvatar } from "assets/img";

const UnbanCustomerModal = ({
  isActive = false,
  closePanel = () => {},
  customer = {},
  onDone = () => {},
  banInternalNote = "",
  setBanInternalNote = () => {},
}) => {
  return (
    <ApproveRestaurantModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"37%"}
      position="fixed"
      width="472px"
      zIndexBackground="9999"
    >
      <DrawerContainer>
        <div className="restaurant-approval-div">
          <div className="modal-image">
            <img
              src={customer?.avatar?.file || NoAvatar}
              alt=""
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="modal-title">
            <b>Unban Customer</b>
          </div>
          <div className="modal-restaurant-name">
            {customer?.first_name} {customer.last_name}
          </div>
          <div className="approve-modal-inputs">
            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  <i>Optional • Visible to Feasttt admins</i>
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={banInternalNote || ""}
                placeholder="Type here"
                onChange={(event) => {
                  setBanInternalNote(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="modal-bottom">
            <MyDrawerButton
              type="red"
              className="approve-btn"
              onClick={() => {
                onDone();
              }}
              // disabled={banInternalNote === "" || banInternalNote === null}
            >
              <b>Unban Customer</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ApproveRestaurantModalContainer>
  );
};

export default UnbanCustomerModal;
