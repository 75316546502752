import React, { useContext } from "react";
import { RemoveItemModalContainer } from "../RemoveItemModal/style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OrdersContext } from "context/OrdersContext";

const RemoveDiscountModal = ({
  isActive = false,
  closePanel = () => {},
  removeDiscount = () => {},
}) => {
  const { selectedItem } = useContext(OrdersContext);
  return (
    <RemoveItemModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"38%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="remove-item-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Remove Discount</div>
          <div className="delete-suborder-meta">
            The discount will be removed from the customer’s bill.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              className="apply-btn"
              onClick={() => {
                removeDiscount();
              }}
            >
              <b>Remove</b>
            </MyDrawerButton>
            <MyDrawerButton
              onClick={() => {
                closePanel();
              }}
              type="gray"
            >
              <b>Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </RemoveItemModalContainer>
  );
};

export default RemoveDiscountModal;
