import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerFilterOrdersContainer = styled(MyDrawerRight)`
  .drawer-content {
    .filter-group {
      margin-bottom: 40px;
      .date-text {
        .date-picker-label {
          font-weight: bold;
        }
      }
      .cards-wrapper {
        display: flex;
        margin: 0 -5px;
        .item {
          margin: 0 5px;
        }
      }
    }
  }
  .collpased-scroll {
    .angle-down-div {
      .selected-count-div {
        width: 22px;
        height: 22px;
        padding: 2px 8px;
        border-radius: 4px;
        background-color: #ed2626;
        margin-right: 17px;
      }
    }
  }
  .dressing-item-list {
    .dressing-item {
      padding: 21px 20px 21px 20px;
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.34px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .special-instructions {
  }

  .bottom-btns {
    .increment-decrement-div {
      margin-top: 42px;
      margin-bottom: 35px;
      .click-btn-div {
        width: 60px;
        height: 60px;
        background-color: #333437;
        border-radius: 50%;
        color: #fff;
        padding: 23px 2px 2px 1px;
      }
      .item-count {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        text-align: center;
        color: #fff;
      }
    }
    .cancel-btn {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: ${colors.redColor};
      margin-top: 20px;
      cursor: pointer;
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
