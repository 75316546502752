import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerCustomersContainer = styled(MyDrawerRight)`
  .set-status-wrapper {
    padding: 36px 26px 38px;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: ${colors.gray5};

    .delete-customer-undone {
      margin: 40px 0 40px 0;
    }

    .bottom-btns {
      .keep-btn {
        color: #fff !important;
        font-weight: 600;
      }
      .delete-btn {
        color: #fff !important;
        font-weight: 600;
      }
    }
  }
`;
