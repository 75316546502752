import React, { useContext } from "react";
import { MarkAsDeliverModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";
import { FaConciergeBell } from "react-icons/fa";
const MarkAsDeliveredModal = ({
  isActive = false,
  closePanel = () => {},
  subOrderType = "Drinks",
  onDoneMarkAsDelivered = () => {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();

  const typeRenderer = () => {
    switch (subOrderType) {
      case "Drinks":
        return "Drinks";
      case "Mark_Quick":
        return "Quick Foods";
      case "Entrees":
        return "Entrées";
      default:
        return null;
    }
  };

  const typeButtonRenderer = () => {
    switch (subOrderType) {
      case "Drinks":
        return "Mark Drinks as Delivered";
      case "Mark_Quick":
        return "Mark Quick Foods as Delivered";
      case "Entrees":
        return "Mark Entrées as Delivered";
      default:
        return null;
    }
  };

  return (
    <MarkAsDeliverModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"38%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="mark-as-ready-modal">
          <div className="delete-icon">
            {subOrderType === "Entrees" && <i className="fas fa-concierge-bell" />}
            {subOrderType === "Mark_Quick" && <i className="fas fa-concierge-bell" />}
            {subOrderType === "Drinks" && <i className="far fa-glass-cheers" />}
          </div>
          <div className="delete-suborder-title">Mark as Delivered</div>
          <div className="delete-suborder-meta">
            {typeRenderer()} were delivered to &nbsp;
            <b>Table&nbsp;{orderDetailData?.table_number}</b>
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              className="mark-as-delivered"
              type="red"
              onClick={() => {
                onDoneMarkAsDelivered();
              }}
            >
              <b>{typeButtonRenderer()}</b>
            </MyDrawerButton>
            {/* <MyDrawerButton onClick={closePanel} type="gray">
              <b>Keep</b>
            </MyDrawerButton> */}
             <div className="cancel" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </MarkAsDeliverModalContainer>
  );
};

export default MarkAsDeliveredModal;
