import React, { useContext } from "react";
import { ActiveOederActionsModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { CustomersContext } from "context/CustomersContext";

const CustomerEditMoreModal = ({
  isActive = false,
  closePanel = () => {},
  setBanRestaurantModal = () => {},
  setUnbanCustomerModal = () => {},
}) => {
  const {
    profileData,
    deleteCustomerModals,
    setDeleteCustomerModals,
  } = useContext(CustomersContext);
  return (
    <ActiveOederActionsModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="active-order-actions">
          <div
            className="clickable-row"
            onClick={() => {
              if (profileData?.status === 9) {
                setUnbanCustomerModal(true);
              } else {
                setBanRestaurantModal(true);
              }
              // onClickPartitialRefund();
              closePanel();
            }}
          >
            {profileData?.status === 9 ? "Unban" : "Ban"} Customer
          </div>
          <div
            className="clickable-row"
            onClick={() => {
              closePanel();
              // onClickFullRefund();
              setDeleteCustomerModals({
                ...deleteCustomerModals,
                deleteCustomer: true,
                // deleteConfirmation: true,
              });
            }}
          >
           <span className="mr-2"><i className="fas fa-trash-alt" /></span> Delete Customer
          </div>
          <MyDrawerButton
            className="active-order-action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </ActiveOederActionsModalContainer>
  );
};

export default CustomerEditMoreModal;
