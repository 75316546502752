import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FaPen,
  FaTrash,
  FaBars,
  FaEllipsisH,
  FaStar,
  FaUtensils,
} from "react-icons/fa";
import { MenuCardContainer } from "./style";
import { colors } from "theme";
import { Image } from "react-bootstrap";
import defaultFood from "assets/img/default-food.jpg";
import { drawersOrder, mocks } from "static/staticData";
import AnalyzeFood from "components/restaurantProfile/RestaurantProfileMenu/ItemCard/AnalyzeFood";
import FoodControl from "components/restaurantProfile/RestaurantProfileMenu/ItemCard/FoodControl";
import { OrdersContext } from "context/OrdersContext";

export default function ItemCard({
  food = {},
  onClickItem = () => {},
  added = false,
  quantity = null,
  increaseQuantity = () => {},
  decreaseQuantity = () => {},
  deleteItem = () => {},
  addItemsToOrder,
}) {
  const {
    addOrderCount,
    setAddOrderCount,
    openDrawers,
    openDrawer,
    closeDrawer,
  } = useContext(OrdersContext);

  // function openRemoveItemDrawer() {
  //   openDrawer(drawersOrder.removeAddItem);
  // }

  return (
    <>
      <MenuCardContainer
        id="restaurant-menu-card"
        onClick={() => {
          if (!added) {
            onClickItem(food);
          }
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div className={`menu-card ${added && "new"}`}>
          <div className="menu-card-left">
            <div className="inner-block-menu-left">
              <div className={`card-col image-place `}>
                {food?.photo ? (
                  <div
                    className={`food-photo ${
                      food?.kind === 1 ? "contain" : ""
                    }`}
                    style={{
                      backgroundImage: `url(${food?.photo?.file})`,
                      backgroundSize: food?.kind === 1 ? "contain" : "cover",
                    }}
                  />
                ) : (
                  <div className="utensils">
                    <FaUtensils
                      className="no-image-class"
                      size={70}
                      color="rgba(255,255,255,0.19)"
                    />
                  </div>
                )}
              </div>
              {added && (
                <div className="quantity-control">
                  {quantity > 1 ? (
                    addItemsToOrder?.length > 1 ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          openDrawer(drawersOrder.removeAddItem);
                        }}
                      >
                        -
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          decreaseQuantity(food);
                        }}
                      >
                        -
                      </button>
                    )
                  ) : (
                    <button
                      style={{ opacity: 1 }}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteItem(food);
                      }}
                    >
                      <i
                        className="fas fa-trash"
                        style={{ color: "#ed2626" }}
                      />
                    </button>
                  )}
                  <h5>{quantity}</h5>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      increaseQuantity(food);
                    }}
                  >
                    +
                  </button>
                </div>
              )}
            </div>
            <div className="name-block main-description">
              <span className="title">{food?.title}</span>
              <AnalyzeFood
                price={food?.price}
                rating={food?.reviews_count}
                averageRating={food?.yum_percent}
                calories={
                  food?.nutrition
                    ? JSON.parse(food?.nutrition)?.[0]?.calories
                    : null
                }
              />
              <span className="description">
                <p className="text-description">{food?.description}</p>
              </span>
              <FoodControl foodItem={food} isActionButtonShowing={false} />
            </div>
          </div>
        </div>
      </MenuCardContainer>
    </>
  );
}

ItemCard.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  newIndex: PropTypes.number,
};
