import styled from "@emotion/styled";
import { colors } from "theme";

export const StaffRestaurantContainer = styled.div`
  .add-promo-btn {
    background: #ed2626;
    border-radius: 40px;
    border: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
  }

  .view-all {
    background: #60646c;
    border-radius: 40px;
    border: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
  }

  .nav {
    background: #27282a;
    border-radius: 30px;
    width: 25rem !important;
    display: flex;
    justify-content: space-between;

    .nav-item {
      margin-right: 0;
    }
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ed2626 !important;
    text-align: center;
    border-radius: 30px !important;
    width: 12rem !important;
    border-bottom: transparent !important;
  }

  a.nav-link {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 600;
    text-align: center !important;
    width: 12rem !important;
  }

  .nav.nav-pills .show > .nav-link,
  .nav.nav-pills .nav-link.hover {
    color: unset;
  }

  nav .show > .nav-link,
  .nav .nav-link:hover:not(.disabled),
  .nav .nav-link.active {
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease;
    color: #fff;
  }

  .nav.nav-pills .nav-link {
    color: #b5b5c3;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease;
    position: relative;
    font-weight: bold;
    color: white;
    padding-left: 50px;
  }

  .custom-card-container {
    background-color: #151617;
    padding: 20px;
    border-radius: 20px;
    .whole-flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .flex-title-batch {
        display: flex;
        gap: 20px;

        .title {
          font-size: 17px;
          font-weight: bold;
          color: #fff;
        }
        .badge {
          background: #3e3e3e;
          border-radius: 12px;
          display: flex;
          align-items: center;
        }
      }
    }

    .date-time-container {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      .date {
        opacity: 0.6;
        font-size: 14px;
      }
      .time {
        opacity: 0.6;
        font-size: 14px;
      }
    }

    .requirement {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      .order {
        opacity: 0.6;
        font-size: 14px;
      }
      .used {
        opacity: 0.6;
        font-size: 14px;
      }
    }

    .percentage {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      .percentage-digit {
        color: #1fc085;
        font-size: 17px;
        font-weight: bold;
      }
      .value {
        opacity: 0.6;
        font-size: 14px;

        .amount {
          font-weight: bold;
        }
      }
    }
  }

  .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    padding-top: 100px;
    img {
      width: 200px;
      height: 160px;
      object-fit: contain;
      margin-bottom: 26px;
    }
    .empty-screen {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      opacity: 0.6;
    }
  }

  .promo-table {
    height: 510px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.gray5};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.white};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 12px;
      tbody {
        background-color: #151617;
        tr {
          background-color: #1b1c1d;
          margin-bottom: 12px;
          border-radius: 8px;
          /* material-ui switch custom css */
          .MuiIconButton-label {
            color: #fff;
          }

          .MuiSwitch-track {
            background-color: #5f626a;
            opacity: unset;

            position: absolute;
            width: 45px;
            height: 25px;
            top: 7px;
            left: 6px;
            border-radius: 20px;
          }

          .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: rgb(30, 206, 109) !important;
            opacity: unset;
          }
          td {
            padding: 20px 0;
            // width: 12.5%;
            color: #fff;

            &:first-of-type {
              width: 25%;
            }

            &:nth-of-type(2) {
              width: 30%;
            }

            &:nth-of-type(3) {
              width: 30%;
            }

            &:nth-of-type(4) {
              width: 10%;
            }

            &:nth-of-type(5) {
              width: 5%;
            }

            &.text-container-big {
              font-size: 16px;
              font-weight: 500;
              color: #ffffff;
            }
            &.text-container-normal {
              font-size: 14px;
              font-weight: normal;
              color: #ffffff;
            }
            .staff-avatar {
              width: 50px;
              height: 50px;
              border-radius: 49px;
            }

            .name-container {
              display: flex;
              align-items: center;

              .staff-avatar-title {
                font-size: 14px;
                font-weight: bold;
                color: white;
              }
            }

            .status-title {
              font-size: 14px;
            }

            .ratings {
              display: flex;
              align-items: center;

              .ratings-icon {
                color: ${colors.rating};
              }
            }
          }
          td:first-of-type {
            padding-left: 30px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          td:last-child {
            padding-right: 40px;
            text-align: end;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
        tr:first-of-type {
          td:first-of-type {
            border-top-left-radius: 20px;
          }
          td:last-child {
            border-top-right-radius: 20px;
          }
        }
        tr:last-child {
          td:first-of-type {
            border-bottom-left-radius: 20px;
          }
          td:last-child {
            border-bottom-right-radius: 20px;
          }
        }
        tr:nth-of-type(odd) {
          td {
            background-color: #27282a;
          }
        }
        tr:nth-of-type(even) {
          td {
            background-color: #27282a;
          }
        }
      }
    }
  }
`;
