import styled from "@emotion/styled";

export const GuestsListContainer = styled.div`
  display: flex;
  width: 150px;
  padding: 12px 13px 20px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  border: solid 1px rgba(255, 255, 255, 0.08);
  background-color: #151617;
  left: -27px;
  bottom: 0;
  position: absolute;
  margin-bottom: 44px;
  :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent rgba(255, 255, 255, 0.08) transparent transparent;
    left: 58px;
    bottom: 0;
    margin-bottom: -20px;
    transform: rotate(270deg);
  }
  .guests-avtar {
    img {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .guests-name {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }
`;
