import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerRemoveItemContainer } from "./style";

export default function DrawerRemoveItem({
  isActive = false,
  closePanel = () => {},
}) {
  // const { openDrawer } = useContext(OrdersContext);

  const onDeleteOrder = () => {
    // openDrawer(drawersOrder.deletedReview);
  };
  return (
    <DrawerRemoveItemContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper>
          <div className="drawer-bottom-content w-100">
            <h3 className="mb-3">Remove Item</h3>
            <h5>Are you sure you want to remove Coke?</h5>
            <h5>Any preferences the customer selected for this item will be lost.</h5>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-3"
                onClick={onDeleteOrder}
              >
                Remove
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                // onClick={() => closeDrawer(drawersMenu.availabilities)}
              >
                Keep
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerRemoveItemContainer>
  );
}
