import React, { useContext, useState } from "react";
import { ApplyPromoCodeModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OrdersContext } from "context/OrdersContext";
const ApplyPromoCodeModal = ({
  isActive = false,
  closePanel = () => {},
  applyPromoCodeHandler = () => {},
  setPromoCode = () => {},
  promoCode = "",
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  // const [promoCode, setPromoCode] = useState("");

  return (
    <ApplyPromoCodeModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"38%"}
      left={"38%"}
      position="fixed"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="apply-promo-code-modal">
          <div className="delete-suborder-title">Apply Promo Code</div>
          <div className="enter-promo-code-div">
            <input
              placeholder="Enter Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type={promoCode === "" ? "gray" : "red"}
              className="apply-btn"
              disabled={promoCode === ""}
              onClick={() => {
                applyPromoCodeHandler(promoCode);
              }}
            >
              <b>Apply</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="redColor">
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ApplyPromoCodeModalContainer>
  );
};

export default ApplyPromoCodeModal;
