import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerAddManagerContainer = styled(MyDrawerRight)`
  * {
    height: auto;
  }

  .full-name-input {
    display: contents;
  }

  .job-title-wrapper {
    display: contents;
  }

  .latel-text {
    opacity: 0.5;
    font-size: 14px;
  }

  .custom-input {
    background: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    color: #fff;
    margin-bottom: 30px;
    border-bottom: 1px solid #3f4346;
  }

  input:focus-visible {
    outline: unset;
  }

  input::-webkit-input-placeholder {
  }

  .save-btn {
    height: 50px;
    position: absolute;
    bottom: 65px;
    right: 0;
    margin: 0 14px 0 0;
    width: 94%;
  }

  .cancel-btn {
    color: #ed2626;
    position: absolute;
    bottom: 30px;
    right: 0;
    margin: 0 14px 0 0;
    width: 94%;
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
`;
