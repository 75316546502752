import React, { useEffect, useState } from "react";
import { ProfileHistoryContainer } from "./style";
import ProfileHistoryList from "./ProfileHistoryList";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyPaginator from "components/Elements/MyPaginator";
import EmptyHistoryImage from "assets/img/no-filter-data.png";

export default function ProfileHistory({ className = "", customerId }) {
  const [historyData, setHistoryData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedLimitToShow, setSelectedLimitToShow] = useState(11);

  const [dateTimeFilter, setDateTimeFilter] = useState(false);

  useEffect(() => {
    setSelectedPage(1);
  }, [selectedLimitToShow]);

  useEffect(() => {
    customAxios
      .get(
        `/customer/${customerId}/orders?page_size=${selectedLimitToShow}&page=${selectedPage}`
      )
      .then((res) => {
        setHistoryData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [customerId, selectedPage]);

  const onPageChange = async (e) => {
    setSelectedPage(e.selected + 1);
  };

  // console.log("historyData.orders", historyData.orders);

  return (
    <ProfileHistoryContainer className={`${className}`}>
      <div
        className="notes-history-header d-flex justify-content-between align-items-center"
        style={
          historyData?.orders?.length > 0
            ? { borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }
            : { borderBottom: "unset" }
        }
      >
        <h3 className="mb-0 text-bold">History</h3>
      </div>
      <div className="notes-history-body d-flex flex-column">
        {historyData && historyData.orders && historyData.orders.length ? (
          <>
            <div className="customers-history-titles">
              <div className="item-flex-big">Reastaurant</div>
              <div className="item-flex-normal">Order #</div>
              <div className="item-flex-normal">
                Date & Time
                {dateTimeFilter ? (
                  <i
                    className="fal fa-angle-up ml-2 cursor-pointer"
                    style={{ opacity: 0.6, fontSize: "20px" }}
                    onClick={() => {setDateTimeFilter(false)}}
                  />
                ) : (
                  <i
                    className="fal fa-angle-down ml-2 cursor-pointer"
                    style={{ opacity: 0.6, fontSize: "20px" }}
                    onClick={() => {setDateTimeFilter(true)}}
                  />
                )}
              </div>
              <div className="item-flex-normal">Server Name</div>
              <div className="item-flex-normal"># of guests</div>
              <div className="item-flex-normal"># of items</div>
              <div className="item-flex-last">Total Price</div>
            </div>
            <div className="customer-history-list">
              {historyData &&
                historyData.orders &&
                historyData.orders.map((history) => {
                  return (
                    <ProfileHistoryList history={history} key={history.id} />
                  );
                })}
            </div>
            {historyData?.total_count > selectedLimitToShow && (
              <div className="mt-5">
                <MyPaginator
                  initialPage={0}
                  totalCount={historyData?.total_count}
                  limitShow={selectedLimitToShow}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </>
        ) : (
          <div className="no-result d-flex flex-column justify-content-center align-items-center w-100">
            {/* <i className="fas fa-align-center" /> */}
            <img src={EmptyHistoryImage} alt="" />
            <h3>No history yet</h3>
          </div>
        )}
      </div>
    </ProfileHistoryContainer>
  );
}
