import styled from "@emotion/styled";

export const MyPaginatorContainer = styled.div`
  ul {
    display: flex;
    list-style: none;
    justify-content: center;
    li {
      margin: 0 10px;
      font-weight: bold;
      font-size: 18px;
      opacity: 0.3;
      &.selected {
        opacity: 1;
      }
    }
  }
`;
