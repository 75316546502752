import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const PaymentErrorModalContainer = styled(MyDrawerCenterModal)`
  .drawer-content {
    padding: 0px 0px 23px;
    background-color: ${colors.gray2};
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    position: relative;
    .close-icon {
      cursor: pointer;
      margin-left: 7px;
      margin-bottom: 40px;
      height: 29px;
      i {
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.53px;
        color: white;
      }
    }
  }
`;
