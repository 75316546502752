import React from "react";
import { StaffNotesContainer } from "./style";
import { NoAvatar, NoFilterData } from "assets/img";
import { FaAngleRight } from "react-icons/fa";
import moment from "moment";

export const StaffNotes = () => {
  return (
    <StaffNotesContainer>
      <div className="notes-card-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0 text-bold">
          Notes (0){/* {notesData?.length ? `(${notesData?.length})` : ""} */}
        </h3>
        <FaAngleRight size={18} className="cursor-pointer" />
      </div>
      <div className="notes-card-body d-flex flex-column">
        {true ? (
          [{}, {}, {}, {}, {}].map((_note, _i) => {
            return (
              <>
                <div
                  key={_i}
                  className="notes-card-item d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex" style={{ gap: "20px" }}>
                    <div className="notes-card-avatar">
                      <img
                        className="rounded-circle"
                        src={NoAvatar}
                        width={33}
                        height={33}
                      />
                    </div>
                    <div>
                      <h6 className="text-bold">Isabelle Freeman</h6>
                      <h6 className="">Abuse</h6>
                    </div>
                    <div className="change-status">
                      Changed to <b>Inactive</b>
                    </div>
                  </div>
                  <div
                    className="note-date"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <h6 className="mb-0 opacity6">
                      {/* {moment(_note.createdAt).format("M/D/YYYY")} */}
                      Jan 3, 2023
                    </h6>
                    <div className="delete-note cursor-pointer">
                      <i className="fas fa-trash-alt" />
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="empty-container">
            <img src={NoFilterData} alt="" />
            <div className="empty-screen">No notes yet</div>
          </div>
        )}
      </div>
    </StaffNotesContainer>
  );
};
