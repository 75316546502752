import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteFloorContainer } from "./style";
import ErrorModal from "./ErrorModal";

export default function DrawerDeleteFloor({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    closeDrawer,
    floors,
    postFloor,
    fetchFloors,
    selectedFloorId,
  } = useContext(RestaurantsContext);
  const [errorModal, setErrorModal] = useState(false);
  const { id } = useParams();

  const onRemove = async () => {
    if (occupiedStatus === 2) {
      closeDrawer(drawersMenu.deleteFloor)
      setErrorModal(true);
      return;
    } else {
      await restaurantServices.deleteFloor(id, postFloor.id);
      closeDrawer([drawersMenu.deleteFloor, drawersMenu.floorEdit]);
      fetchFloors(id);
    }
  };

  let occupiedStatus;
  floors.filter((_e) =>
    _e?.id === selectedFloorId
      ? _e?.tables.map((_floor) => {
          if (_floor?.status === 2) {
            occupiedStatus = _floor?.status;
          }
        })
      : null
  );

  return (
    <>
      <DrawerDeleteFloorContainer
        isActive={isActive}
        closePanel={closePanel}
        left="40%"
        top="30%"
        zIndex={"99999999999"}
        zIndexBackground={999999999}
      >
        <DrawerContainer>
          <div className="delete-modal-wrapper">
            <div className="top-icon">
              <i className="fad fa-question" />
            </div>
            <h2 className="drawer-title main-title">Remove Floor</h2>
            {occupiedStatus === 8 ? (
              <p className="">
                We’re sorry, <b>{postFloor?.name}</b> has at least one table
                that is currently occupied. Please wait for the guest(s) to
                checkout before trying to remove this floor again.
              </p>
            ) : (
              <>
                <p className="text-content confirm">
                  Are you sure you want to remove this floor?
                </p>
                <p className="text-content">This cannot be undone.</p>
              </>
            )}

            {occupiedStatus === 8 ? (
              <div className="bottom-btns">
                <MyDrawerButton
                  type="red"
                  onClick={() => closeDrawer(drawersMenu.deleteFloor)}
                >
                  Okay
                </MyDrawerButton>
              </div>
            ) : (
              <div className="bottom-btns">
                <MyDrawerButton type="red" onClick={onRemove}>
                  Remove
                </MyDrawerButton>
                <MyDrawerButton
                  type="gray"
                  onClick={() => closeDrawer(drawersMenu.deleteFloor)}
                >
                  Keep
                </MyDrawerButton>
              </div>
            )}
          </div>
        </DrawerContainer>
      </DrawerDeleteFloorContainer>
      <ErrorModal
        isActive={errorModal}
        closePanel={() => {
          setErrorModal(false);
          closePanel();
        }}
        floorName={postFloor?.name}
      />
    </>
  );
}
