import messageBoxItem from "components/MessageBoxItem";
import { useEffect } from "react";

const dayTitles = [
  { key: "Mon", content: "Monday", num: 0 },
  { key: "Tue", content: "Tuesday", num: 1 },
  { key: "Wed", content: "Wednesday", num: 2 },
  { key: "Thu", content: "Thursday", num: 3 },
  { key: "Fri", content: "Friday", num: 4 },
  { key: "Sat", content: "Saturday", num: 5 },
  { key: "Sun", content: "Sunday", num: 6 },
];

export const showMessage = (content, type) => {
  messageBoxItem(content, type);
};

export const isValidEmail = (email) => {
  //eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const formatBusinessHoursToday = (businessHours) => {
  let result = "";
  const timeRightNow = new Date();
  const availableDays = businessHours?.map(({ day }) => day);

  if (availableDays?.includes(timeRightNow.getDay() - 1)) {
    result += " ";

    // compute schedule from seconds that we took (start, end)
    const index = availableDays.indexOf(timeRightNow.getDay() - 1);
    const {
      times: [timesCore],
    } = businessHours[index];
    let [start, end] = timesCore; // time in seconds

    // translate seconds into hours range 0 - 24
    // start = Math.floor(start / 3600);
    // end = Math.floor(end / 3600);

    // stringify hours that we have

    // const timeBegin = start
    // ? `${start > 23 ? 23 : start}:00${start >= 12 ? 'pm' : 'am'}`
    // : '12:00am';

    // const timeEnd = end
    // ? `${end > 23 ? 23 : end}:00${end >= 12 ? 'pm' : 'am'}`
    // : '12:00am';

    // result += `${timeBegin}-${timeEnd}`;
    result += `${convertTimeToReadableFormat(
      start
    )}-${convertTimeToReadableFormat(end)}`;

    if (result.includes("00 AM-00 PM") || result.includes("00 AM-00 AM")) {
      result = "All day";
    }
  } else if (
    dayTitles &&
    availableDays &&
    dayTitles[[...availableDays].pop()]
  ) {
    const data = [];
    const index = timeRightNow.getDay() - 1;
    availableDays.sort().forEach((day, i) => {
      if (day > index) {
        data.push(day);
        return (result = ` ${dayTitles[data[0]].content}`);
      } else {
        result += ` ${dayTitles[availableDays.sort().pop()].content}`;
      }
    });
  }

  return result;
};

function convertTimeToReadableFormat(time) {
  const formattedTime = time % (12 * 60 * 60);
  const hr = Math.floor(formattedTime / (60 * 60));
  const min = (formattedTime - hr * 60 * 60) / 60;
  const dateTime = Math.floor(time / (12 * 60 * 60)) >= 1 ? "PM" : "AM";
  return `${hr < 10 ? "0" + hr : hr} ${dateTime}`;
  // console.log(`${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`)
}

export const formatName = (name, slice = 21) =>
  !name || (name && name.length <= 27) ? name : `${name.slice(0, slice)}...`;
