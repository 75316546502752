import styled from "@emotion/styled";

export const MyFileUploaderFormDataContainer = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};
  .avatar-uploader {
    height: 100%;
    width: 100%;
    height: 100%;
    border: unset;
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 0;
    .photo {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      .ant-avatar-square {
        border-radius: 8px;
      }
      .uploading-icon {
        position: absolute;
        top: 42%;
      }
      .uploaded-img {
        width: auto;
        max-width: 100%;
        height: 220px;
      }
      .photo-hover {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        /* width: 343px; */
        height: 100%;
        /* transition: 0.5s ease; */
        /* opacity: 1; */
        text-align: center;
        border-radius: ${(props) => (props.shape === "circle" ? "50%" : "8px")};
        background: linear-gradient(
          to bottom,
          transparent 0,
          transparent 77.33%,
          rgba(0, 0, 0, 0.5) 0,
          rgba(0, 0, 0, 0.5) 100%
        );
        /* border: solid 1px white; */
        /* background: rgba(0, 0, 0, 0.7); */
        cursor: pointer;
        /* &:hover {
          opacity: 1;
        } */
      }
    }
  }
`;
