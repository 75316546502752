import styled from "@emotion/styled";
import { colors } from "theme";

export const ProfileMenuContainer = styled.div`
  .menu-active-inactive-wrapper {
    width: 100%;
    padding-left: 40px;
    .active-inactive-wrapper {
      margin-top: 14px;
      margin-bottom: 28px;
      /* padding: 20px 40px; */
      padding: 9px 20px 9px 20px;
      background: linear-gradient(313.7deg, #ff7d7d 5.09%, #ed2626 95.34%);
      border-radius: 4px 35px 31px 32px;
      .fa-exclamation-circle {
        color: #fff;
      }
      .set-cancel-btn {
        width: 154px;
        background: rgba(255, 255, 255, 0.12) !important;
        margin-right: 23px;
        /* color: #ed2626 !important;
        &:hover {
          color: #fff !important;
        } */
      }
      .set-d-active-btn {
        padding: 0 15px;
        background-color: #fff !important;
        color: #ed2626 !important;
        /* &:hover {
          color: #ed2626 !important;
        } */
      }
      .set-active-btn {
        border-radius: 25px;
        background-color: #fff !important;
        color: #ed2626 !important;
        /* &:hover {
          color: #ed2626 !important;
        } */
      }
    }
  }
  .menus-wrapper {
    position: relative;
    padding: 20px 40px;
    .no-menu-message {
      // margin-top: 5%;
      .empty-screen {
        width: auto;
        height: 22rem;
        object-fit: contain;
        margin-bottom: 25px !important;
      }
      .text1 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #fff;
      }
      .text2 {
        opacity: 0.6;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        margin-bottom: 25px;
      }
      .btn-add-menu-categories {
        border-radius: 8px;
        width: 26rem;
        height: 50px;
      }
    }
    .right-btns {
      position: absolute;
      right: 40px;
      top: 20px;
      display: flex;
    }
    .menu-category {
      /* margin-top: 50px; */
      margin-bottom: 26px;
      .category-header {
        /* min-height: 40px; */
        .add-item-wrapper {
          position: relative;

          .add-item-tooltip {
            position: absolute;
            background: #27282a;
            top: -120px;
            left: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100px;
            width: 256px;
            border-radius: 30px;
            transform: translateX(-50%);

            .add-item-button {
              gap: 18px;
              height: 50px;
              display: flex;
              padding-left: 26px;
              align-items: center;
              border: none;
              outline: none;
              background: transparent;
              color: #fff;
              box-sizing: border-box;
              position: relative;
              z-index: 1;

              .tooltip-icon {
                font-size: 18px;
              }

              &:hover {
                background: rgb(255 255 255 / 10%);
              }
              &:first-child {
                border-radius: 30px 30px 0 0;
              }
              &:last-child {
                border-radius: 0 0 30px 30px;
              }
            }

            &::after {
              content: "";
              position: absolute;
              height: 20px;
              top: 81%;
              width: 20px;
              left: 50%;
              background: #27282a;
              transform: rotate(45deg) translate(-5%, 70%) skew(10deg, 10deg);
              border-radius: 2px;
              z-index: 0;
            }
          }
        }
      }
    }
  }
`;

export const ButtonsRightContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-left: 16px;
  }
  div:first-of-type {
    margin-left: 0;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px;
  /* border-bottom: ${(props) =>
    props.isBorderBottom ? "1px solid #4d5053" : ""}; */

  .nav-line-to-back {
    a,
    button {
      outline: 0 !important;
      color: #60646c;
      font-size: 17px;
    }
    .current-restaurant {
      font-size: 17px;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .filter-part {
    display: flex;
    align-items: center;
  }
  .edit-button {
    border-radius: 25px !important;
    width: 40px;
    height: 40px;
    padding: 9px 13px 13px 10px;
    /* width: 50px;
    height: 40px;
    margin: 0 13px 0 0;
    background-color: #60646c;
    display: flex;
    justify-content: center;
    align-items: center; */
  }
  .categories-group {
    p {
      margin: 0 13px;
      padding: 11px 21px;
      border-radius: 25px;
      background-color: ${colors.gray2};
    }
  }

  .select-menu-and-re-order {
  }

  .slider-filter-btn {
    background: #151617 !important;
    border: 1px solid #525558 !important;
    padding: 0px 20px;
    margin-right: 15px;
  }

  .menu-header-select {
    margin-right: 15px;
    .dropdown-header-menu {
      background-color: #27282a;
      border: 1px solid transparent;
    }
    .dropdown-content-menu {
      background-color: rgb(39, 40, 42) !important;
    }
  }

  .category-scroll {
    ::-webkit-scrollbar {
      display: none;
    }
    width: 70rem;
    display: flex;
    overflow-x: scroll;
    align-items: center;
    white-space: nowrap;
    // margin-left: 30px;
    margin-right: 30px;

    background-color: #27282a;
    width: max-content;
    border-radius: 30px;
    .category-badge-wrapper {
      .category-badge {
        border-radius: 50px;
        padding: 15px 15px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .dietary-btn-title {
    background: #151617;
    border-radius: 20px;
    border: 1px solid #fff;
  }

  .option-dietary-item {
    padding: 6px 20px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .active {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
