import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyDragger from "components/Elements/MyDragger";
import { DrawerContainer } from "components/styledStyle";
import arrayMove from "array-move";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState } from "react";
import { FaBars, FaPen, FaTimes, FaTrashAlt } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { DrawerFloorsContainer } from "./style";

export default function DrawerFloors({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    floors,
    setFloors,
    setPostFloor,
    setSelectedFloorId,
  } = useContext(RestaurantsContext);

  const [editFloorNotification, setEditFloorNotification ] = useState()

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    console.log({ oldIndex, newIndex }, arrayMove(floors, oldIndex, newIndex));
    let sortedFloors = arrayMove(floors, oldIndex, newIndex);
    setFloors(sortedFloors);
  };

  const onClickEdiFloor = (menu) => {
    setPostFloor(menu);
    openDrawer(drawersMenu.floorEdit);
    // console.log(menu.id)
    setSelectedFloorId(menu?.id);
  };

  const onClickDeleteFloor = (menu) => {
    setPostFloor(menu);
    openDrawer(drawersMenu.deleteFloor);
    setSelectedFloorId(menu?.id);
  };

  const onClickFloorAdd = () => {
    setPostFloor(null);
    openDrawer(drawersMenu.floorAdd);
  };
  return (
    <DrawerFloorsContainer
      isActive={isActive}
      closePanel={closePanel}
      renderLeftIcon={<FaTimes size={24} color={"#60646c"} />}
    >
      <DrawerContainer padding="nopadding">
        <h2 className="drawer-title text-white text-center">Floors</h2>
        <MyDragger
          renderItem={(item) => (
            <div className="drag-floor-item">
              <div className="left">
                <FaBars
                  className="icon-drag"
                  color="rgba(255, 255, 255, 0.3)"
                  size={20}
                />
                <div className="drag-floor-item-content">
                  <p className="drag-floor-item-name mb-0">{item?.name}</p>
                </div>
              </div>
              <FaPen
                size={16}
                cursor="pointer"
                color="#60646c"
                onClick={() => onClickEdiFloor(item)}
              />
              <FaTrashAlt
                size={16}
                cursor="pointer"
                color="#60646c"
                style={{ marginLeft: "10px" }}
                onClick={() => onClickDeleteFloor(item)}
              />
            </div>
          )}
          items={floors}
          onSortEnd={(param) => onSortEnd(param)}
          addNewButton={true}
          onClick={onClickFloorAdd}
        />
        <div className="bottom-btns">
          <MyDrawerButton type="red" onClick={closePanel} className="mt-auto">
            Save
          </MyDrawerButton>
          <MyDrawerButton type="transparent" onClick={closePanel}>
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerFloorsContainer>
  );
}
