import styled from "@emotion/styled";

export const CustomerSignUpsContainer = styled.div`
  width: 100%;
  background-color: #1b1c1d;
  border-radius: 8px;
  margin-top: 27px;
  padding: 17px 22px 0px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .customer-sign-ups-top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 55px;

    .customer-sign-ups-title {
      font-size: 20px;
      font-weight: bold;
      color: white;
      padding-left: 37px;
      margin-bottom: 0px;
    }

    .customer-sign-ups-analytic-period {
      background-color: #27282a;
      border-radius: 30px;
    }

    .chart-date {
      position: absolute;
      margin-top: 6rem;
      font-size: 14px;
      font-weight: 500;
      margin-left: 35px;
    }
  }

  .customer-sign-ups-main {
    display: flex;
  }
`;
