import React from "react";
import { LogoutModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { clearJwt } from "services/jwt";

const LogoutModal = ({ isActive = false, closePanel = () => {} }) => {
  function logOff() {
    clearJwt();
    window.location.reload();
    closePanel();
  }
  return (
    <LogoutModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-number">
          <div className="modal-image">
            <div className="round-circle">
              <i
                className="fas fa-sign-out-alt"
                style={{
                  fontSize: "24px",
                  color: "#fff",
                }}
              />
            </div>
          </div>
          <div className="modal-title-new-number">Are you sure?</div>
          <div className="modal-title-new-number-meta">
            You will be logged out of Feasttt.
          </div>
          <div className="new-number-action-btns">
            <MyDrawerButton type="red" onClick={() => logOff()}>
              <b>Log out</b>
            </MyDrawerButton>
            <MyDrawerButton
              type="redColor"
              onClick={() => {
                closePanel();
              }}
            >
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </LogoutModalContainer>
  );
};

export default LogoutModal;
