import React from 'react'
import PropTypes from 'prop-types';
import { BoxContainer } from './style';

export default function Box({
  children, 
  className, 
  framer, 
  onClick = () => {}, 
  ...otherProps
}) {

  return (
    <BoxContainer 
      className={className} 
      {...framer} 
      style={{...otherProps}}
      onClick={onClick}
    >
      {children}
    </BoxContainer>
  )
}

Box.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  framer: PropTypes.object,
  onClick: PropTypes.func,
};
