/* eslint-disable react-hooks/exhaustive-deps */
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaChevronDown, FaChevronLeft, FaChevronUp } from "react-icons/fa";
import { DrawerAssignToMenuContainer } from "./style";

export default function DrawerAssignToMenu({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postFood, setPostFood, menus } = useContext(RestaurantsContext);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selected, setSelected] = useState(null);

  const onClickCategory = (categoryId) => {
    let tempCategories = [...selectedCategories];
    if (tempCategories.includes(categoryId)) {
      setSelectedCategories(
        tempCategories.filter((_categoryId) => _categoryId !== categoryId)
      );
    } else {
      tempCategories.push(categoryId);
      setSelectedCategories(tempCategories);
    }
  };

  const onAssignToMenu = () => {
    setPostFood({ ...postFood, category: selectedCategories });
    closePanel();
  };

  useEffect(() => {
    if (postFood.categories) {
      setSelectedCategories([...postFood.categories]);
    }
  }, [postFood]);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <DrawerAssignToMenuContainer isActive={isActive} closePanel={closePanel}>
      {/* <DrawerContainer nopadding={true}> */}
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div></div>
        </div>
        <div className="header-title">
          <div className="title">Assign Item</div>
          <div className="food-title">{postFood?.title || ""}</div>
        </div>
        <div className="drawer-menus-wrapper mb-auto">
          <Accordion defaultActiveKey="0">
            {menus.length > 0 &&
              menus.map((_menu, _i) => {
                let fData = _menu.categories?.filter((el) => {
                  return selectedCategories.indexOf(el?.id) > -1;
                });
                return (
                  <Card key={_i}>
                    <Accordion.Toggle as={Card.Header} eventKey={_i}>
                      <div className="d-flex justify-content-between">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {_menu?.name}{" "}
                        </div>
                        <span
                          onClick={() => toggle(_i)}
                          className="d-flex align-items-center justify-content-between "
                        >
                          {fData?.length !== 0 ? (
                            <div className="selected-count">
                              Selected {fData?.length}
                            </div>
                          ) : (
                            <></>
                          )}
                          &nbsp;
                          {selected === _i ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronUp />
                          )}
                        </span>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={_i}>
                      <Card.Body>
                        {_menu.categories && _menu.categories.length > 0 ? (
                          _menu.categories.map((_category, _k) => (
                            <div
                              key={_k}
                              className={`category-item cursor-pointer ${selectedCategories.includes(
                                _category.id
                              ) && "selected"}`}
                              onClick={() => onClickCategory(_category.id)}
                            >
                              <p>{_category?.name}</p>
                            </div>
                          ))
                        ) : (
                          <p>There are no categories.</p>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={selectedCategories.length > 0 ? "red" : "gray"}
            className="mt-auto"
            onClick={onAssignToMenu}
          >
            Assign ({selectedCategories.length})
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerAssignToMenuContainer>
  );
}
