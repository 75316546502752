import React from "react";
import { SessionsBarsContainer } from "./style";

export const SessionsBars = ({ sessionTimes }) => {
  const colorsClasses = [
    "orange-line",
    "gray-brown-line",
    "green-line",
    "light-green-line",
  ];

  return (
    <SessionsBarsContainer>
      {Object.entries(sessionTimes).map(([key, value], index) => (
        <div
          className={`session-bar-item ${colorsClasses[index]}`}
          key={key + value}
        >
          <div className="session-bar-top">
            <span className="session-minutes">{key}</span>
            <span className="session-audience-percentage">{value} %</span>
          </div>
          <div
            className="session-bar-core"
            style={{ "--width": `${(value / 100) * 333}px` }}
          />
        </div>
      ))}
    </SessionsBarsContainer>
  );
};
