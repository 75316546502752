import React, { useContext, useState, useEffect } from "react";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { DrawerStaffReviewContainer } from "./style";
import { FaChevronLeft, FaPen, FaStar } from "react-icons/fa";
import { Image } from "react-bootstrap";
import MyDropDown from "components/Elements/MyDropDown";
import MyPaginator from "components/Elements/MyPaginator";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import { RestaurantsContext } from "context/RestaurantsContext";
import defaultUser from "../../../../../assets/img/noAvatar.png";
import { DrawerSortReview } from "../DrawerSortReview";
import NotesEmptyScreen from "assets/img/no-filter-data.png";
import { drawersStaff } from "static/staticData";
import moment from "moment";

export default function DrawerReviews({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();

  const {
    staffGlobalDetail,
    openDrawers,
    openDrawer,
    closeDrawer,
    isRestoreDataEnable,
    postStaff,
    staffViewData,

    deleteReviewId,
    setDeleteReviewId,
    restoreReviewId,
    setRestoreReviewId,
  } = useContext(RestaurantsContext);

  const [isOpenSortReview, setIsOpenSortReview] = useState(false);
  const [selectedSort, setSelectedSort] = useState("");

  const [staffSort, setStaffSort] = useState("");
  const [staffReview, setStaffReview] = useState([]);
  const [totalReviewCount, setTotalReviewCount] = useState();
  const [rating, setRating] = useState();

  const [selectOption, setSelectOption] = useState();

  const [staffReviewsData, setStaffReviewsData] = useState([]);

  useEffect(() => {
    getStaffReviewData();
    setSelectedSort("");
  }, [id, staffGlobalDetail, staffSort, staffViewData]);

  function getStaffReviewData() {
    if (staffViewData?.id) {
      customAxios
        .get(
          `/staff/${staffViewData?.id}/review?page=1&page_size=10&sort=${staffSort}`
        )
        .then((res) => {
          setStaffReviewsData(res?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  // console.log(staffSort)

  if (staffSort === "Oldest") {
    setStaffSort(1);
  } else if (staffSort === "Highest Rated") {
    setStaffSort(3);
  } else if (staffSort === "Lowest Rated") {
    setStaffSort(2);
  } else if (staffSort === "Earliest") {
    setStaffSort(0);
  }

  function onClickDeleteReview(deleteReview) {
    setDeleteReviewId(deleteReview?.id);
  }

  function onClickRestoreReview(drestoreReview) {
    setRestoreReviewId(drestoreReview?.id);
  }

  // useEffect(() => {
  //   console.log(isRestoreDataEnable);
  // }, [isRestoreDataEnable]);

  const [restaurantNameList, setRestaurantNameList] = useState([]);

  useEffect(() => {
    if (staffViewData?.id) {
      customAxios
        .get(`/staff/${staffViewData?.id}/restaurants`)
        .then((res) => {
          if (res.status === 200) {
            setRestaurantNameList(res.data);
            // res &&
            //   res.data.map((_el) => {
            //     console.log(_el.name);
            //     setRestaurantNameList(_el?.name);
            //   });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [staffViewData]);

  // function Options({ options }) {
  //   return options.map((option, index) => (
  //     <option key={index}>{option?.name}</option>
  //   ));
  // }

  return (
    <>
      <DrawerStaffReviewContainer isActive={isActive} closePanel={closePanel}>
        <DrawerContainer className="p-0">
          <div className="drawer-bottom-content w-100">
            <div className="header-bg">
              <div className="d-flex  align-items-center close-drawer-icon">
                <FaChevronLeft
                  size={20}
                  style={{ zIndex: "9999999" }}
                  className="cursor-pointer "
                  onClick={closePanel}
                />
                <div className="drawer-title">
                  {(staffViewData?.status === 1 && "Active") ||
                    (staffViewData?.status === 2 && "Pending") ||
                    (staffViewData?.status === 9 && "Banned") ||
                    (staffViewData?.status === -1 && "Deleted") ||
                    (staffViewData?.status === 0 && "Inactive")}
                </div>
              </div>
              <Image
                src={
                  staffViewData?.avatar ? staffViewData?.avatar : defaultUser
                }
                className="bg-img"
              />
            </div>

            <div className="panel-status-container">
              <div className="profile-line"></div>
              <div className="upper-part">
                <img
                  className="company-logo"
                  src={
                    staffViewData?.avatar ? staffViewData?.avatar : defaultUser
                  }
                  alt="user-avatar"
                />

                <p className="company-name">
                  {staffViewData?.first_name} {staffViewData?.last_name}
                </p>

                <div className="role-slug">
                  {(staffViewData?.is_owner && "Owner") ||
                    (!staffViewData?.is_owner &&
                      staffViewData?.role_slug === "manager" &&
                      "Manager") ||
                    (staffViewData?.role_slug === "kitchen_staff" &&
                      "Kitchen Staff") ||
                    (staffViewData?.role_slug === "waiter" && "Server / Host")}
                </div>

                <div className="mt-2 rating">
                  <div className="mr-2">
                    <FaStar color={"#FDCB6E"} size={15} />
                  </div>
                  <div className="rating-number">{staffViewData?.rating}</div>
                </div>

                <div className="view-profile mt-2">
                  <div>View Profile</div>
                  <div>
                    <i className="far fa-angle-right" />
                  </div>
                </div>

                <div>
                  <MyDropDown
                    className="review-option-select"
                    options={
                      restaurantNameList &&
                      restaurantNameList.map((item) => {
                        return { label: item.name, value: item.name };
                      })
                    }
                    // value={{ label: staffSort, value: staffSort }}
                    onChange={(selectedOption) =>
                      setSelectOption(selectedOption)
                    }
                    width={115}
                    iconClosed={<i className="fas fa-angle-down" />}
                    placeholder={restaurantNameList && restaurantNameList[0]?.name}
                  />
                </div>

                {/* <div>
                  <select className="restaurant-name-list">
                    <Options options={restaurantNameList} />
                  </select>
                </div> */}
              </div>

              <div className="notes-count">
                <div>
                  <b>{staffReviewsData?.length} reviews</b>
                </div>
                {staffReviewsData.length > 0 && (
                  <div>
                    <MyDropDown
                      className="menu-header-select"
                      options={[
                        "Earliest",
                        "Oldest",
                        "Highest Rated",
                        "Lowest Rated",
                      ].map((item) => {
                        return { label: item, value: item };
                      })}
                      // value={{ label: staffSort, value: staffSort }}
                      onChange={(selectedSortType) =>
                        setStaffSort(selectedSortType)
                      }
                      width={115}
                      iconClosed={<i className="fas fa-angle-down" />}
                      placeholder="Sort"
                    />
                  </div>
                )}
              </div>

              <div className="notes-list">
                {staffReviewsData?.length > 0 ? (
                  <div style={{ marginBottom: 50 }}>
                    {staffReviewsData.length > 0 &&
                      staffReviewsData.map((_review, _i) => {
                        return (
                          <div
                            style={
                              deleteReviewId === _review?.id ||
                              restoreReviewId === _review?.id
                                ? isRestoreDataEnable
                                  ? { opacity: "unset" }
                                  : { opacity: 0.4 }
                                : { opacity: "unset" }
                            }
                          >
                            <div key={_i} className="note-container-item">
                              {/* {_review?.id} */}
                              <div className="note-image-block">
                                <img
                                  src={
                                    _review?.image
                                      ? _review?.image
                                      : defaultUser
                                  }
                                  alt="avatar"
                                />
                              </div>

                              <div className="note-content-block">
                                <p className="commentator-name">
                                  {_review?.name}
                                </p>
                                <p className="commentator-mail">
                                  <b>1</b> Feasts Here . <b>4</b> Feasts Total
                                </p>
                              </div>

                              <div className="note-end-block">
                                <p className="date-note">
                                  <FaStar color={"#FDCB6E"} size={15} />
                                </p>
                                <div className="delete-note">
                                  {`${_review?.rating}.0`}
                                </div>
                              </div>
                            </div>

                            <div className="description">
                              {_review?.comment}
                            </div>

                            <div className="show-more">
                              {_review?.comment?.length > 50 ? (
                                <div className="text-bold">Show more</div>
                              ) : (
                                <div className=""></div>
                              )}
                              <div className="date-delete">
                                <div>
                                  {moment(_review?.createdat).format(
                                    "MMM D, YYYY"
                                  )}
                                </div>

                                {isRestoreDataEnable ? (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      openDrawer(
                                        drawersStaff.staffReviewRemove
                                      );
                                      onClickDeleteReview(_review);
                                    }}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </div>
                                ) : (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      openDrawer(
                                        drawersStaff.staffReviewRestore
                                      );
                                      onClickRestoreReview(_review);
                                    }}
                                  >
                                    <i className="fas fa-trash-undo-alt" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="no-note-data">
                    <img
                      className="no-notes-img"
                      src={NotesEmptyScreen}
                      alt="empty"
                    />
                    <p className="no-notes-message">No notes yet.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DrawerContainer>
      </DrawerStaffReviewContainer>
    </>
  );
}
