import React from "react";
import {
  ApproveConfiramtionModalContainer,
  ApproveInputContainer,
} from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerInputContainer } from "components/Elements/DrawerElements/DrawerInput/style";
import { NoAvatar } from "assets/img";

const ApproveBanModal = ({
  isActive = false,
  closePanel = () => {},
  restaurant = {},
  onDone = () => {},
}) => {
  return (
    <ApproveConfiramtionModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"37%"}
      position="fixed"
      width="472px"
      zIndexBackground="9999"
    >
      <DrawerContainer>
        <div className="restaurant-approval-div">
          <div className="modal-image">
            <img
              src={restaurant?.photo?.file || NoAvatar}
              alt=""
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="modal-title">
            <b>Are you sure?</b>
          </div>
          {/* <div className="modal-restaurant-name">{restaurant?.title}</div> */}
          <div className="modal-meta">
            Are you sure you want to {restaurant.status === 9 ? "unban" : "ban"}{" "}
            <b>{restaurant?.title}</b> restaurant?
          </div>
          <div className="modal-bottom">
            <MyDrawerButton
              type="red"
              className="approve-btn"
              onClick={() => {
                onDone();
              }}
            >
              <b>Yes, {restaurant.status === 9 ? "Unban" : "Ban"} Restaurant</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ApproveConfiramtionModalContainer>
  );
};

export default ApproveBanModal;
