import React, { useState } from "react";
import { FoodItemDetailsAndActionsContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import IconCheck from "components/Elements/IconCheck";
import { FaChevronRight, FaTrash, FaPencilAlt, FaCheck } from "react-icons/fa";

const RequestFoodItem = ({
  requestFoodItemDetails = {},
  itemsArray,
  itemIndex,
  itemComment,
}) => {
  const foodItemStatusRender = ({
    resolve_type,
    reject_reason,
    reject_comment,
    partial,
  }) => {
    if (resolve_type === 0) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#f7a64c",
            marginTop: "8px",
          }}
        >
          Pending
        </div>
      );
    } else if (resolve_type === 1) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#ed2626",
            marginTop: "8px",
          }}
        >
          Rejected{rejectReasonHandler(reject_reason, reject_comment)}
        </div>
      );
    } else if (resolve_type === 2) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#1fc086",
            marginTop: "8px",
          }}
        >
          Fulfilled
        </div>
      );
    } else if (resolve_type === 3) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#1fc086",
            marginTop: "8px",
          }}
        >
          Partial Refund {partial ? `: ${partial}%` : ""}
        </div>
      );
    } else if (resolve_type === 4) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#f7a64c",
            marginTop: "8px",
          }}
        >
          Replaced
        </div>
      );
    } else if (resolve_type === 5) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#ed2626",
            marginTop: "8px",
          }}
        >
          Cancelled
        </div>
      );
    } else if (resolve_type === 6) {
      return (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#1fc086",
            marginTop: "8px",
          }}
        >
          Accepted
        </div>
      );
    }
  };

  const rejectReasonHandler = (reason, comment) => {
    if (reason === 0) {
      return `: Other ${comment !== null ? `[${comment}]` : ``}`;
    } else if (reason === 1) {
      return `: Out of Stock ${comment !== null ? `[${comment}]` : ``}`;
    } else if (reason === 2) {
      return `: Bar Closed ${comment !== null ? `[${comment}]` : ``}`;
    } else if (reason === 3) {
      return `: Requested by mistake ${comment !== null ? `[${comment}]` : ``}`;
    } else if (reason === 4) {
      return `: Unreasonable Request ${comment !== null ? `[${comment}]` : ``}`;
    }
  };

  const othersFoodTypeTextRender = (otherType) => {
    if (otherType === 0) {
      return "More napkins";
    } else if (otherType === 1) {
      return "Silverware";
    } else if (otherType === 2) {
      return "Printed menu";
    }
  };

  return (
    <FoodItemDetailsAndActionsContainer>
      <>
        <div className="card-item">
          {/* {requestFoodItemDetails?.status === 3 ||
          requestFoodItemDetails?.status === 2 ? (
            <IconCheck className="mr-5" />
          ) : (
            <></>
          )} */}
          <h5
            className={
              requestFoodItemDetails?.status === 3 ? "opacity6 mr-4" : "mr-4"
            }
          >
            {requestFoodItemDetails?.quantity || 0}
          </h5>
          <div className="card-item-content">
            <div className="item-row">
              <h5 className={""}>
                {requestFoodItemDetails?.other_request_item !== null ? (
                  <>
                    {othersFoodTypeTextRender(
                      requestFoodItemDetails?.other_request_item
                    )}
                  </>
                ) : (
                  <>
                    {requestFoodItemDetails?.food_item
                      ? requestFoodItemDetails?.food_item?.food_name || "-"
                      : "-"}
                  </>
                )}

                {foodItemStatusRender(requestFoodItemDetails)}
              </h5>
              <h5 className="opacity6 d-flex" style={{ position: "relative" }}>
                {requestFoodItemDetails?.food_item
                  ? `${"$" + requestFoodItemDetails?.food_item?.price ||
                      "Free"}`
                  : ""}
              </h5>
            </div>
          </div>
        </div>
      </>
      {itemsArray?.length - 1 === itemIndex ? (
        <>
          {itemComment !== null && itemComment !== "" ? (
            <div className="card-item">
              <div className="card-item-content">
                <div className="item-row">
                  <h5>Comment</h5>
                </div>
                <div className="item-row">
                  <div
                    style={{
                      fontSize: "15px",
                      color: "#fff",
                      fontWeight: "normal",
                      opacity: "0.6",
                    }}
                  >
                    {itemComment}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </FoodItemDetailsAndActionsContainer>
  );
};

export default RequestFoodItem;
