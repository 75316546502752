import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerEditCustomizationsContainer = styled(MyDrawerBottom)`
  .edit-cutomization-input-area {
    margin-top: 20px;
    .customization-input {
      background: transparent;
      width: 100%;
      border: 0;
      color: #fff;
      margin-bottom: 10px;
      &:focus-visible {
        outline: unset;
      }
    }

    .input-bottom-line {
      border-bottom: 1px solid #535457;
    }

    .add-option {
      color: #ed2626;
    }
  }

  .cancel-btn {
    color: #ed2626;
  }

  .bottom-btns {
    margin-top: 30px;
  }

  .selection-required {
    margin-top: 50px;
    .selections-allowed {
      display: flex;
      justify-content: space-between;
      margin: 11px 0 11px 0;
      .selection-plus-minus {
        display: flex;
        .plus {
          background-color: #676767;
          padding: 0 6px 0 6px;
          border-radius: 50px;
        }
        .digit {
          margin: 0 8px 0 8px;
        }
        .minus {
          background-color: #676767;
          padding: 0 7px 0 7px;
          border-radius: 50px;
        }
      }
    }

    .required {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2px 0 2px 0;
    }
  }

  /* material-ui switch custom css */
  .MuiIconButton-label {
    color: #fff;
  }

  .MuiSwitch-track {
    background-color: #5f626a;
    opacity: unset;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ed2626 !important;
    opacity: unset;
  }
`;
