import React, { useState, useEffect } from "react";
import { StaffReviewsContainer } from "./style";
import { NoAvatar, NoFilterData } from "assets/img";
import { FaAngleRight } from "react-icons/fa";
import moment from "moment";
import MyDropDown from "components/Elements/MyDropDown";
import { FaStar } from "react-icons/fa";
import { colors } from "theme";

const res = [{ name: "Catch LA" }, { name: "Bite Cafe" }];

export const StaffReviews = () => {
  const [staffSortData, setStaffSortData] = useState("");
  const [selectOption, setSelectOption] = useState();

  return (
    <StaffReviewsContainer>
      <div className="notes-card-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0 text-bold">Reviews</h3>

        <div className="d-flex">
          <div>
            <MyDropDown
              className="menu-header-select sort-filter"
              options={[
                "Earliest",
                "Oldest",
                "Highest Rated",
                "Lowest Rated",
              ].map((item) => {
                return { label: item, value: item };
              })}
              // value={{ label: staffSort, value: staffSort }}
              onChange={(selectedSortType) =>
                setStaffSortData(selectedSortType)
              }
              width={140}
              iconClosed={<i className="fas fa-angle-down" />}
              placeholder="Sort"
            />
          </div>

          <div style={{ marginLeft: "15px" }}>
            <MyDropDown
              className="review-option-select"
              options={
                res &&
                res.map((item) => {
                  return { label: item.name, value: item.name };
                })
              }
              // value={{ label: staffSort, value: staffSort }}
              onChange={(selectedOption) => setSelectOption(selectedOption)}
              width={300}
              iconClosed={<i className="fas fa-angle-down" />}
              placeholder={"Catch LA"}
            />
          </div>
        </div>
      </div>
      <div className="notes-card-body d-flex flex-column">
        <div className="rating-review">
          <div className="rating">
            <FaStar color="#FDCB6E" size={15} /> <b>4.5</b>
          </div>
          <div>
            <b>13</b> reviews
          </div>
        </div>
        {true ? (
          [{}, {}, {}, {}, {}].map((_note, _i) => {
            return (
              <div className="notes-card-item">
                <div
                  key={_i}
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex" style={{ gap: "20px" }}>
                    <div className="notes-card-avatar">
                      <img
                        className="rounded-circle"
                        src={NoAvatar}
                        width={33}
                        height={33}
                      />
                    </div>
                    <div>
                      <h6 className="text-bold">Isabelle Freeman</h6>
                      <h6 className="opacity6">
                        <b>1</b> Feast Here • <b>13</b> Feasts Total
                      </h6>
                      <h6>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut <br /> labore et
                        dolore magna aliqua.
                      </h6>
                    </div>
                  </div>

                  <div
                    className="note-date"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FaStar color="#FDCB6E" size={18} />
                    </div>
                    <div className="rating">4.5</div>
                  </div>
                </div>

                <div className="date">
                  <div style={{ color: colors.gray }}>Mar 12, 2023</div>
                  <div className="delete-note cursor-pointer">
                    <i className="fas fa-trash-alt" />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-container">
            <img src={NoFilterData} alt="" />
            <div className="empty-screen">No notes yet</div>
          </div>
        )}
      </div>
    </StaffReviewsContainer>
  );
};
