import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerPromoCodeModalContainer = styled(MyDrawerCenterModal)`
  .promo-code-container {
    padding: 28px;
    .form-scroll {
      max-height: 630px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .without_ampm::-webkit-datetime-edit-ampm-field {
      display: none;
    }
    input[type="time"]::-webkit-clear-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      -ms-appearance: none;
      appearance: none;
      margin: -10px;
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
      background: none;
    }

    .input-label {
      opacity: 0.3;
      font-size: 14px;
    }

    .timer {
      cursor: pointer;
    }

    .active-time {
      color: #fff;
      font-weight: 700;
    }

    .promo-input {
      width: 100%;
      background: transparent;
      color: #fff;
      border: 0;
      /* border-bottom: 1px solid #323334; */
      &:focus-visible {
        outline: unset;
        outline-offset: unset;
      }
      &::placeholder {
        color: #ed2626;
        font-weight: 500;
      }
    }

    .promo-input-optional {
      width: 100%;
      background: transparent;
      color: #fff;
      border: 0;
      &:focus-visible {
        outline: unset;
        outline-offset: unset;
      }
      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
        font-weight: 500;
      }
    }

    .arrow-icon {
      position: relative;
      top: 20px;
    }

    .bottom-line {
      padding-bottom: 9px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px !important;
      font-weight: bold;
      margin-bottom: 28px;
    }

    .active-toggle {
      background-color: #1b1c1d;
      padding: 10px 0;
      border-radius: 15px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 64px;

      .promo-apply {
        .promo-apply-text {
          font-size: 14px;
          font-weight: bold;
        }
        .description {
          opacity: 0.5;
        }
      }

      .toggle-btn {
      }
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 28px;
      .cancel-btn {
        color: #ed2626;
        font-weight: bold;
        cursor: pointer;
      }
      .btn-disable {
        background: #4b4b4d;
        padding: 15px 20px;
        border-radius: 10px;
        font-weight: bold;
        color: #fff;
        opacity: 0.4;
        cursor: pointer;
        border: 0;
      }

      .btn-enable {
        background: #ed2626;
        padding: 15px 20px;
        border-radius: 10px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        border: 0;
      }
    }

    /* input[type="text"] {
    } */

    label {
      display: inline-block;
      margin-bottom: 0.1rem;
    }
  }

  /* material-ui switch custom css */
  .MuiIconButton-label {
    color: #fff;
  }

  .MuiSwitch-track {
    background-color: #5f626a;
    opacity: unset;

    position: absolute;
    width: 45px;
    height: 25px;
    top: 7px;
    left: 6px;
    border-radius: 20px;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: rgb(30, 206, 109) !important;
    opacity: unset;
  }
`;
