import styled from "@emotion/styled";
import { DateRange } from "react-date-range";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { colors } from "theme";

export const DrawerJoinedDateContainer = styled(MyDrawerBottom)`
  .bottom-btns {
    margin-top: 65px;
  }
  .cancel-btn {
    color: red !important;
    font-weight: 600;
    margin-top: 15px;
  }
`;

export const JoinedDateRangeContainer = styled(DateRange)`
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 17px;
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: unset;
    padding-top: 0;
    margin-bottom: 20px;
    .rdrPprevButton,
    .rdrNextButton {
      background: transparent;
    }
    .rdrPprevButton {
      i {
        border-color: transparent #ffffff transparent transparent;
      }
    }
    .rdrNextButton {
      i {
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  .rdrMonths {
    .rdrMonth {
      width: 100%;
      padding: 0;
      .rdrDays {
        .rdrDay {
          .rdrDayNumber {
            span {
              color: #fff;
              font-size: 14px;
              font-weight: 600;
            }
          }
          &.rdrDayDisabled {
            background-color: transparent;
            &:after {
              background: ${colors.redColor};
              content: "";
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translate(-50%, 0);
              width: 4px;
              height: 4px;
              border-radius: 50%;
            }
          }
          &.rdrDayToday {
            .rdrDayNumber {
              span:after {
                background: ${colors.green};
              }
            }
          }
        }
      }
    }
  }
  .rdrYearPicker {
    display: none;
  }
  .rdrMonthName {
    display: none;
  }
  .rdrMonthAndYearPickers {
    select {
      font-size: 17px;
      font-weight: 600;
      color: #fff;
      margin-left: 20px;
    }
  }
  .rdrWeekDay {
    font-weight: 600;
    line-height: 2.667em;
    font-size: 13px;
    opacity: 0.5;
    color: #fff;
  }
`;
