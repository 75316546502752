import { DrawerContainer } from "components/styledStyle";
import { MyAccountContext } from "context/MyAccountContext";
import React, { useContext } from "react";
import { DrawerLogoutContainer } from "./style";
import { clearJwt } from "services/jwt";

export default function DrawerLogout({
  profile = {},
  isActive = false,
  closePanel = () => { },
}) {
  const { setLogOutPanel } = useContext(MyAccountContext);

  function logOff() {
    clearJwt()
    window.location.reload();
  }
  return (
    <DrawerLogoutContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer nopadding className='pb-0'>
        <h2 className="drawer-title text-white text-center">Log Out</h2>
        <div className="log-out-panel">
          <div className="log-out-footer">
            <div className="log-out-footer-head">
              <h3 className="log-out-footer-title">Are you sure?</h3>
              You will be logged out of Feasttt.
            </div>
            <div className="log-out-footer-context">
              <button
                type="button"
                className="button button-accept"
                onClick={() => logOff()}
              >
                Log out
              </button>
              <button
                type="button"
                className="button button-cancel"
                onClick={() => setLogOutPanel(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </DrawerContainer>
    </DrawerLogoutContainer>
  );
}
