import React, { useState, useContext, useEffect } from "react";
import { DrawerSetActiveStatusModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";

const StatusActiveModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
}) => {
  const { activeNotes, setActiveNotes } = useContext(RestaurantsContext);

  const onSubmitActiveStatus = (event) => {
    event.preventDefault();
    closePanel();
  };

  return (
    <DrawerSetActiveStatusModalContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      top="20%"
    >
      <DrawerContainer>
        <form onSubmit={onSubmitActiveStatus}>
          <div className="set-discount-container">
            <div className="modal-title">Set Active Status</div>

            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Internal Note</div>
                <div className="label-text">
                  Optional • Visible to Feasttt admins
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={activeNotes || ""}
                placeholder="Type here"
                onChange={(event) => setActiveNotes(event.target.value)}
              />
            </div>

            <input
              type="submit"
              value="Set Active"
              className={"set-active-btn"}
            />

            <div className="cancel-btn cursor-pointer" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </form>
      </DrawerContainer>
    </DrawerSetActiveStatusModalContainer>
  );
};

export default StatusActiveModal;
