/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { MyAccountContext } from "context/MyAccountContext";
import NoAvatar from "assets/img/noAvatar.png";
import { drawersMyAccount } from "static/staticData";
import { MyAccountPageContainer } from "./style";
import DrawerChangePassword from "./Drawers/DrawerChangePassword";
import DrawerLogout from "./Drawers/DrawerLogout";
import DrawerChangeEmail from "./Drawers/DrawerChangeEmail";
import DrawerIsRestorePwd from "./Drawers/DrawerIsRestorePwd";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyButton from "components/Elements/MyButton";
import DrawerEmailSuccess from "./Drawers/DrawerEmailSuccess";
import { adminServices } from "services";
import { PhotoUploader } from "components/Elements/PhotoUploader";
import { showMessage } from "services/functions";
import isEqual from "lodash/isEqual";
import DrawerChangePhoneNumber from "./Drawers/DrawerPhoneNumber";
import NumberFormat from "react-number-format";
import { colors } from "theme";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import EnterNewPhoneNumberModal from "./Modals/EnterNewPhoneNumberModal";
import VerifyNewPhoneNumberModal from "./Modals/VerifyNewPhoneNumberModal";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import DiscardChangesModal from "./Modals/DiscardChangesModal";
import NumberVerifiedModal from "./Modals/NumberVerifiedModal";
import EnterEmailModal from "./Modals/EnterEmailModal";
import EmailOtpSentModal from "./Modals/EmailOtpSentModal";
import LogoutModal from "./Modals/LogoutModal";

export function MyAccountPage() {
  const { getMyInfo } = adminServices;
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  const [mobileModals, setMobileModals] = useState({
    enterNewNumber: false,
    verifyNumber: false,
    numberVerifiedSuccess: false,
  });

  const [emailAddressModals, setEmailAddressModals] = useState({
    enterEmail: false,
    sentEmail: false,
  });
  const [emailAddressInput, setEmailAddressInput] = useState("");

  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const {
    myAccount,
    setMyAccount,
    openDrawer,
    openDrawers,
    closeDrawer,
    drawerChangeEmailClose,
    setDrawerChangeEmailClose,
  } = useContext(MyAccountContext);

  const [user, setUser] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [myAccountNotification, setMyAccountNotification] = useState(null);

  useEffect(() => {
    if (myAccount) {
      setUser(myAccount);
    }
  }, [myAccount]);

  useEffect(() => {
    if (myAccount && user) {
      setIsActiveBtn(!isEqual(myAccount, user));
    }
  }, [myAccount, user]);

  useEffect(() => {
    if (myAccountNotification) {
      setTimeout(() => setMyAccountNotification(null), 2 * 1000);
    }
  }, [myAccountNotification]);

  const changeValue = (key, e) => {
    const { value } = e.target;
    setUser({ ...user, [key]: value });
  };

  const onClickSaveChanges = async () => {
    let payload = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      phone: user?.phone,
      // avatar: user?.avatar?.id,
    };
    if (user?.avatar?.id) {
      payload.avatar = user?.avatar?.id;
    }
    saveChanges(payload);
  };

  const saveChanges = (payload) => {
    const { updateMyInfo } = adminServices;

    const onSuccess = () => {
      // setMyAccount(payload);
      getMyInfo()
        .then((res) => {
          if (res.ok === true) {
            setMyAccount(res.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
      showMessage("Updated successfully!", "success");
    };
    const onFailure = (error) => showMessage(error, "error");
    updateMyInfo({ ...payload }, onSuccess, onFailure);
  };

  const onUploadSuccess = (asset) => {
    setUser({ ...user, avatar: asset });
  };

  let phoneNumberTemp = user?.phone;
  let result = phoneNumberTemp?.replace(/[^a-zA-Z0-9]+/g, "");

  const isButtonDisabled = () => {
    if (
      isActiveBtn &&
      result !== null &&
      result !== undefined &&
      result?.length <= 11
    ) {
      return false;
    } else {
      return true;
    }
  };

  const otpSendForPhoneNumber = () => {
    customAxios
      .patch(`/admin/me/update/request`, {
        update: "phone",
        phone_number: phoneNumber,
      })
      .then((res) => {
        if (res.status === 200) {
          setMobileModals({
            ...mobileModals,
            enterNewNumber: false,
            verifyNumber: true,
          });
        } else {
        }
      });
  };

  const otpSentToEmailHandler = () => {
    customAxios
      .patch(`/admin/me/update/request`, {
        update: "email",
        email: emailAddressInput,
      })
      .then((res) => {
        if (res.status === 200) {
          setEmailAddressModals({
            ...emailAddressModals,
            enterEmail: false,
            sentEmail: true,
          });
        } else {
        }
      });
  };

  return (
    <MyAccountPageContainer>
      {myAccountNotification && (
        <MyMessageBoxItemNew
          message={
            myAccountNotification?.message ? myAccountNotification.message : ""
          }
          table={
            myAccountNotification?.table ? myAccountNotification.table : ""
          }
          type={
            myAccountNotification?.type ? myAccountNotification.type : "success"
          }
          onClick={() => setMyAccountNotification(null)}
        />
      )}
      <div className="my-account-component">
        <div className="my-account-component-header">
          <div className="left-part">
            <h2>My Account</h2>
          </div>
          <div
            className="right-part"
            onClick={() => setIsLogoutModalOpen(true)}
          >
            <i
              className="fas fa-sign-out-alt"
              style={{ color: colors.redColor }}
            />
            <span>Log Out</span>
          </div>
        </div>

        {/* content part */}
        <div className="my-account-component-body">
          <div className="my-account-content">
            <div className="my-account-avatar-body d-flex align-items-start">
              <div className="profile-photo-label">
                <div className="profile-title">Profile Photo</div>
                <div className="profile-subtitle">
                  This photo will be visible only to Admins.
                </div>
              </div>
              <div>
                <PhotoUploader
                  src={user?.avatar?.file ?? NoAvatar}
                  height={"182px"}
                  width={"182px"}
                  onUploadSuccess={onUploadSuccess}
                />
              </div>
            </div>
            <div className="personal-info-container">
              <div className="profile-photo-label">
                <div className="profile-title">Personal Information</div>
                <div className="profile-subtitle">
                  This information will be visible only to Admins.
                </div>
              </div>
              <div className="my-account-block mb-4">
                <DrawerInput
                  className="account-form-item"
                  placeholder="Enter your first name"
                  label="First Name"
                  value={user?.first_name}
                  onChange={(e) => changeValue("first_name", e)}
                />
                <DrawerInput
                  className="account-form-item last-name-style"
                  placeholder="Enter your last name"
                  label="Last Name"
                  value={user?.last_name}
                  onChange={(e) => changeValue("last_name", e)}
                />
              </div>
            </div>
            <div className="contact-info-container">
              <div className="profile-photo-label">
                <div className="profile-title">Contact Information</div>
                <div className="profile-subtitle">
                  This Email Address will be using for <b>Login</b>.
                </div>
              </div>
              <div className="my-account-block mb-4">
                <ItemPicker
                  className="account-form-item"
                  label="Email Address"
                  placeholder="Add email address"
                  value={user?.email}
                  // onClick={() => openDrawer(drawersMyAccount.changeEmail)}
                  onClick={() =>
                    // setDrawerChangeEmailClose({
                    //   ...drawerChangeEmailClose,
                    //   changeEmail: true,
                    // })
                    setEmailAddressModals({
                      ...emailAddressModals,
                      enterEmail: true,
                    })
                  }
                />
                <ItemPicker
                  className="account-form-item last-name-style"
                  label="Mobile Number"
                  placeholder="Add phone number"
                  value={`+${user?.phone}`}
                  onClick={() => {
                    setMobileModals({ ...mobileModals, enterNewNumber: true });
                  }}
                />
                {/* <div className="account-form-item last-name-style">
                  <div className="input-label">
                    <label>Phone Number</label>
                  </div>
                  <div>
                    <NumberFormat
                      format={"+# (###) ###-####"}
                      className={`single-ant-format-input`}
                      value={user?.phone || ""}
                      onChange={(e) => changeValue("phone", e)}
                      onBlur={() => {}}
                      placeholder="Add phone number"
                    />
                    <div>
                      <hr style={{ borderTop: "1px solid #323334" }} />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="password-contianer">
              <div className="profile-photo-label">
                <div className="profile-title">Password</div>
              </div>
              <span
                className="change-password-link  cursor"
                onClick={() => openDrawer(drawersMyAccount.changePassword)}
              >
                <i className="fas fa-lock" />
                &nbsp;Change password
              </span>
            </div>

            <div className="account-info-wrapper">
              {/* <div className="my-account-block mb-4">
                <div className="account-form-item password-field">
                  <p>Password</p>
                  <div className="change-password">
                    <span className="hidden-password">* * * * * * *</span>
                  </div>
                </div>
              </div> */}
              <div className="my-account-block w-50">
                {/* <ItemPicker
                  className="account-form-item"
                  label="Phone Number"
                  placeholder="Add phone number"
                  value={user?.phone}
                  onClick={() => openDrawer(drawersMyAccount.phoneNumber)}
                /> */}
                {/* <DrawerInput
                  className="account-form-item"
                  placeholder="Add phone number"
                  label="Phone Number"
                  value={user?.phone}
                  onChange={(e) => changeValue("phone", e)}
                /> */}
              </div>
            </div>
          </div>
          <div className="button-container">
            {!isButtonDisabled() ? (
              <MyDrawerButton
                type="redColor"
                onClick={() => {
                  setDiscardModalOpen(true);
                }}
                className="mr-10"
              >
                <b>Cancel</b>
              </MyDrawerButton>
            ) : (
              <></>
            )}
            <MyButton
              // type={isActiveBtn ? "red" : "gray"}
              // disabled={!isActiveBtn}
              type={isButtonDisabled() ? "gray" : "red"}
              disabled={isButtonDisabled()}
              onClick={() => onClickSaveChanges()}
            >
              <b>Save Changes</b>
            </MyButton>
          </div>
        </div>

        <DrawerChangeEmail
          // isActive={!!openDrawers[drawersMyAccount.changeEmail]}
          // closePanel={() => closeDrawer(drawersMyAccount.changeEmail)}
          isActive={drawerChangeEmailClose.changeEmail}
          closePanel={() => {
            setDrawerChangeEmailClose({
              ...drawerChangeEmailClose,
              changeEmail: false,
            });
          }}
        />
        <DrawerEmailSuccess
          isActive={!!openDrawers[drawersMyAccount.emailSuccess]}
          closePanel={() => closeDrawer(drawersMyAccount.emailSuccess)}
        />
        <DrawerChangePassword
          isActive={!!openDrawers[drawersMyAccount.changePassword]}
          closePanel={() => closeDrawer(drawersMyAccount.changePassword)}
        />
        <DrawerIsRestorePwd
          isActive={!!openDrawers[drawersMyAccount.isRestorePassword]}
          closePanel={() => closeDrawer(drawersMyAccount.isRestorePassword)}
        />
        <DrawerLogout
          isActive={!!openDrawers[drawersMyAccount.logout]}
          closePanel={() => closeDrawer(drawersMyAccount.logout)}
        />
        <DrawerChangePhoneNumber
          isActive={!!openDrawers[drawersMyAccount.phoneNumber]}
          closePanel={() => closeDrawer(drawersMyAccount.phoneNumber)}
        />
        <EnterNewPhoneNumberModal
          isActive={mobileModals.enterNewNumber}
          // isActive={true}
          closePanel={() =>
            setMobileModals({ ...mobileModals, enterNewNumber: false })
          }
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          onDone={otpSendForPhoneNumber}
        />
        <VerifyNewPhoneNumberModal
          isActive={mobileModals.verifyNumber}
          // isActive={true}
          closePanel={() =>
            setMobileModals({
              ...mobileModals,
              enterNewNumber: true,
              verifyNumber: false,
            })
          }
          phoneNumber={phoneNumber}
          setMobileModals={setMobileModals}
          setMyAccountNotification={setMyAccountNotification}
          setPhoneNumber={setPhoneNumber}
        />
        <DiscardChangesModal
          isActive={isDiscardModalOpen}
          // isActive={true}
          closePanel={() => setDiscardModalOpen(false)}
          onDone={() => {
            setUser(myAccount);
            setDiscardModalOpen(false);
          }}
        />
        <NumberVerifiedModal
          isActive={mobileModals.numberVerifiedSuccess}
          // isActive={true}
          closePanel={() =>
            setMobileModals({ ...mobileModals, numberVerifiedSuccess: false })
          }
        />
        <EnterEmailModal
          isActive={emailAddressModals.enterEmail}
          // isActive={true}
          closePanel={() =>
            setEmailAddressModals({ ...emailAddressModals, enterEmail: false })
          }
          setEmailAddressInput={setEmailAddressInput}
          emailAddressInput={emailAddressInput}
          onDone={otpSentToEmailHandler}
        />
        <EmailOtpSentModal
          isActive={emailAddressModals.sentEmail}
          // isActive={true}
          closePanel={() =>
            setEmailAddressModals({ ...emailAddressModals, sentEmail: false })
          }
          emailAddressInput={emailAddressInput}
          setMyAccountNotification={setMyAccountNotification}
        />
        <LogoutModal
          isActive={isLogoutModalOpen}
          // isActive={true}
          closePanel={() => {
            setIsLogoutModalOpen(false);
          }}
        />
      </div>
    </MyAccountPageContainer>
  );
}
