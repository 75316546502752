import React from "react";
import { DeletePaymentModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DeletePaymentModal = ({ isActive = false, closePanel = () => {} }) => {
  return (
    <DeletePaymentModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"38%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="delete-payment-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-sub-title">Are you sure?</div>
          <div className="delete-sub-meta">
            This Payment Method will be deleted.
          </div>
          <div className="delete-sub-actions">
            <MyDrawerButton className="mt-2" type="red" onClick={() => {}}>
              <b>Yes, Delete</b>
            </MyDrawerButton>
            <MyDrawerButton className="cancel-btn" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DeletePaymentModalContainer>
  );
};

export default DeletePaymentModal;
