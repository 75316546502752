import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerDeleteReviewContainer = styled(MyDrawerCenterModal)`
  .drawer-bottom-content {
    flex: 1;
    text-align: center;
    .modal-title{
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
    }
    &:before {
      content:  ${(props) => (props.tipModalShown ? "unset" : "")};
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -36px;
      left: 187px;
    }
    .selected-card-details {
      padding: 22px 30px 19px 26px;
      border-radius: 16px;
      background-color: #1b1c1d;
      margin-bottom: 32px;
    }
    .add-edit-tip-container {
      // margin-bottom: 42px;
    }
    .order-total-div {
      margin-bottom: 16px;
      text-align-center;
    }
    .delete-icon {
      background-color: #1fc086;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -28px;
      left: 195px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 13px;
      }
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
  cursor: ${(props) => props.cursor && "pointer"};
`;
