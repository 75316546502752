import React from "react";
import { FaHome, FaStoreAlt } from "react-icons/fa";
import { ProfileCardFavoriteContainer } from "./style";
import defaultFoodImage from "assets/img/default-food.jpg";

export default function ProfileCardFavorite({
  className = "",
  icon,
  title = "",
  value = "",
  subTitle = "",
  image = null,
}) {
  return (
    <ProfileCardFavoriteContainer
      className={`${className}`}
      image={image !== null ? image : defaultFoodImage}
    >
      <div className="favorite-content">
        <h4 className="favorite-title">
          <i className={`${icon} mr-3`} />
          {title}
        </h4>
        <h3 className="favorite-value text-bold">{value ? value : "-"}</h3>
        {subTitle ? (
          <h5 className="favorite-subtitle">
            <FaStoreAlt className="mr-3" />
            {subTitle}
          </h5>
        ) : (
          ""
        )}
      </div>
    </ProfileCardFavoriteContainer>
  );
}
