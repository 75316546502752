import React from "react";
import { AnalyticsHeaderContainer } from "./style";

export const AnalyticsHeader = ({ title, children }) => {
  return (
    <AnalyticsHeaderContainer>
      <div className="header-left-core">
        <h4>{title}</h4>
      </div>
      <div className="header-right-children">{children}</div>
    </AnalyticsHeaderContainer>
  );
};
