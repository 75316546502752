import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useState, useEffect, useContext } from "react";
import { drawersMenu } from "static/staticData";
import DrawerDeleteMenuAndItem from "../DrawerDeleteMenuAndItem";
import DrawerCategoryMoveItems from "./DrawerCategoryMoveItem";
import { DrawerCategoryFirstOptionsContainer } from "./style";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";

export default function DrawerCategoryFirstOptions({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    closeDrawer,
    postMenu,
    openDrawers,
    postCategory,
    setFoods,
    globalMenuId,
    selectedMenu,
    setSelectedMenu,
    categoryCloseDrawer,
    setCategoryCloseDrawer,
  } = useContext(RestaurantsContext);

  // const [category, setCategory] = useState([]);

  const { id } = useParams();

  // useEffect(() => {
  //   getCategoryData();
  // }, []);

  useEffect(() => {}, [id]);

  function openDrawerDeleteCategoryAndItemsMethod() {
    openDrawer(drawersMenu.openDrawerDeleteMenuAndItem);
    setCategoryCloseDrawer({
      ...categoryCloseDrawer,
      openDrawerDeleteMenuAndItem: true,
    });
  }

  function handleMoveItems() {
    // openDrawer(drawersMenu.openDrawerCategoryMoveItem);
    setCategoryCloseDrawer({
      ...categoryCloseDrawer,
      openDrawerCategoryMoveItem: true,
    });
  }

  // function getCategoryData() {
  //   customAxios
  //     .get(`/restaurant/${id}?get_detail=true`)
  //     .then((res) => {
  //       setCategory(res?.data?.menus);
  //       // console.log(res?.data?.menus)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // console.log(postCategory?.items?.length)

  function handleDeleteItem() {
    customAxios
      .delete(
        `/restaurant/${id}/menu/${globalMenuId}/category/${postCategory?.id}`
      )
      .then((res) => {
        // getCategoryData();
        getRestaurantProfile();
        getMenu();
        // closePanel();
        setCategoryCloseDrawer({
          ...categoryCloseDrawer,
          categoryEdit: false,
          openDrawerCategoryFirstOptions: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
    }
  };

  function getMenu() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <DrawerCategoryFirstOptionsContainer
        isActive={isActive}
        closePanel={closePanel}
      >
        <DrawerContainer>
          {/* {postCategory?.items?.length > 0 ? "move" : "non-move"} */}
          <h2 className="drawer-title text-white text-left main-title">
            Delete Category
          </h2>

          {postCategory?.items?.length > 0 ? (
            <p className="text-white text-left text-font">
              Are you sure you want to delete{" "}
              <strong>{postCategory?.name}</strong> with items inside?
            </p>
          ) : (
            <p className="text-white text-left text-font">
              Are you sure you want to delete category{" "}
              <strong>{postCategory?.name}</strong>
            </p>
          )}

          {postCategory?.items?.length > 0 ? (
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="text-bold move-item-btn"
                onClick={handleMoveItems}
              >
                Move Items
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                className="text-bold"
                onClick={openDrawerDeleteCategoryAndItemsMethod}
              >
                Delete Category & Items
              </MyDrawerButton>
              <MyDrawerButton
                type="transparent"
                // onClick={() => closeDrawer(drawersMenu.deleteMenu)}
                className="cancel-btn"
                onClick={closePanel}
              >
                Cancel
              </MyDrawerButton>
            </div>
          ) : (
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-5 text-bold"
                onClick={closePanel}
              >
                Keep
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                className="text-bold"
                onClick={handleDeleteItem}
              >
                Delete
              </MyDrawerButton>
            </div>
          )}
        </DrawerContainer>
        <DrawerDeleteMenuAndItem
          isActive={categoryCloseDrawer.openDrawerDeleteMenuAndItem}
          closePanel={() =>
            setCategoryCloseDrawer({
              ...categoryCloseDrawer,
              openDrawerDeleteMenuAndItem: false,
            })
          }
        />
        {/* <DrawerCategoryMoveItems
        isActive={categoryCloseDrawer.openDrawerCategoryMoveItem}
        closePanel={() => setCategoryCloseDrawer({
          ...categoryCloseDrawer,
          openDrawerCategoryMoveItem : false,
        })}
      /> */}
      </DrawerCategoryFirstOptionsContainer>
      
      {/* <DrawerCategoryMoveItems
        isActive={categoryCloseDrawer.openDrawerCategoryMoveItem}
        closePanel={() =>
          setCategoryCloseDrawer({
            ...categoryCloseDrawer,
            openDrawerCategoryMoveItem: false,
          })
        }
      /> */}
    </>
  );
}
