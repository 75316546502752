import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerRemoveItemContainer } from "./style";

export default function DrawerLastGuestCheckout({
  isActive = false,
  closePanel = () => {},
  selectedGuestInfo,
  onCheckoutLastGuest = () => {},
}) {
  // const { openDrawer } = useContext(OrdersContext);

  return (
    <DrawerRemoveItemContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper iconWarning={true}>
          <div className="drawer-bottom-content w-100">
            <h3 className="mb-3">Check Out</h3>
            <h5>
              <b>{selectedGuestInfo?.name}</b>the last guest at the table and
              cannot be removed.
            </h5>
            <h5>You must delete the entire order. </h5>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-3"
                onClick={onCheckoutLastGuest}
              >
                Okay
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerRemoveItemContainer>
  );
}
