import React, { useContext } from "react";
import { DrawerCustomersContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import { drawersCustomers } from "static/staticData";

export default function DrawerDeleteLocation({
  isActive = false,
  closePanel = () => {},
  deleteLocationHandler = () => {},
}) {
  const { openDrawers, openDrawer, closeDrawer } = useContext(CustomersContext);

  return (
    <DrawerCustomersContainer
      isActive={isActive}
      closePanel={closePanel}
      containerClassName="delete-customer-drawer"
      top={"33%"}
    >
      <DrawerContainer>
        <div className="delete-location-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-location-title">Delete Address</div>
          <div className="delete-location-meta">
            Are you sure you want to delete this <br /> address?
          </div>
          <div className="delete-location-actions">
            <MyDrawerButton
              type="red"
              className="mb-4"
              onClick={() => {
                deleteLocationHandler();
                closePanel();
              }}
            >
              <b>Yes, Delete</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray">
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerCustomersContainer>
  );
}
