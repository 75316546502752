/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "layout";
import { AuthPage } from "./pages/AuthPage";
import { MyAccountContextContainer } from "context/MyAccountContext";
import { DashboardPage } from "./pages/DashboardPage";
import { AnalyticsPage } from "./pages/AnalyticsPage";
import { MyAccountPage } from "./pages/MyAccountPage";
import { CustomersPage } from "./pages/CustomersPage";
import { CustomerProfilePage } from "./pages/CustomerProfilePage";
import { OrdersPage } from "./pages/OrdersPage";
import { RestaurantsPage } from "./pages/RestaurantsPage";
import { RestaurantProfilePage } from "./pages/RestaurantProfilePage";
import { RestaurantsContextContainer } from "context/RestaurantsContext";
import { OrdersContextContainer } from "context/OrdersContext";
import { CustomersContextContainer } from "context/CustomersContext";
import { OrderDetailPage } from "./pages/OrderDetailPage";
import { ActiveOrderAddItem } from "./pages/OrderDetailPage/ActiveOrderAddItem";
import { getJwt } from "services/jwt";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { CreatePasswordPage } from "./pages/CreatePasswordPage";
import { DashboardContextContainer } from "context/DashboardContext";
import { PromoCodesContextContainer } from "context/PromoCodesContext";
import { PromoCodesPage } from "./pages/PromoCodesPage";
import { CuisinesPage } from "./pages/CuisinesPage";
import { StaffProfilePage } from "./pages/StaffProfilePage";
import { StaffPage } from "./pages/StaffPage";

export function Routes() {
  const MyRoute = ({ component: Component, ...props }) => {
    return (
      <Route {...props}>
        {getJwt() ? <Component {...props} /> : <Redirect to="/login" />}
      </Route>
    );
  };
  return (
    <Switch>
      <Route exact path="/">
        {!getJwt() ? <AuthPage /> : <Redirect to="/dashboard" />}
      </Route>
      <Route exact path="/login">
        {!getJwt() ? <AuthPage /> : <Redirect to="/dashboard" />}
      </Route>
      <Route exact path="/reset-password">
        {!getJwt() ? <ResetPasswordPage /> : <Redirect to="/dashboard" />}
      </Route>
      <Route exact path="/create-password">
        {!getJwt() ? <CreatePasswordPage /> : <Redirect to="/dashboard" />}
      </Route>

      <MyAccountContextContainer>
        <DashboardContextContainer>
          <RestaurantsContextContainer>
            <OrdersContextContainer>
              <CustomersContextContainer>
                <PromoCodesContextContainer>
                  <Layout>
                    <MyRoute
                      exact
                      path="/dashboard"
                      component={DashboardPage}
                    />
                    <MyRoute exact path="/orders" component={OrdersPage} />
                    <MyRoute
                      exact
                      path="/order-detail/:id"
                      component={OrderDetailPage}
                    />
                    <MyRoute
                      exact
                      path="/order-detail/:id/add-item"
                      component={ActiveOrderAddItem}
                    />

                    <MyRoute
                      exact
                      path="/restaurants"
                      component={RestaurantsPage}
                    />
                    <MyRoute
                      exact
                      path="/restaurant-detail/:id"
                      component={RestaurantProfilePage}
                    />
                    <MyRoute exact path="/cuisines" component={CuisinesPage} />

                    <MyRoute
                      exact
                      path="/customer-detail/:id"
                      component={CustomerProfilePage}
                    />
                    <MyRoute
                      exact
                      path="/customers"
                      component={CustomersPage}
                    />

                    <MyRoute
                      exact
                      path="/promo-codes"
                      component={PromoCodesPage}
                    />

                    <MyRoute
                      exact
                      path="/analytics/order"
                      component={AnalyticsPage}
                    />
                    <MyRoute
                      exact
                      path="/analytics/restaurant"
                      component={AnalyticsPage}
                    />
                    <MyRoute
                      exact
                      path="/analytics/customer"
                      component={AnalyticsPage}
                    />
                    <MyRoute
                      exact
                      path="/my-account"
                      component={MyAccountPage}
                    />
                    {/* <MyRoute
                      exact
                      path=""
                      component={StaffProfilePage}
                    /> */}
                    <MyRoute
                      exact
                      path="/staff"
                      component={StaffPage}
                    />
                  </Layout>
                </PromoCodesContextContainer>
              </CustomersContextContainer>
            </OrdersContextContainer>
          </RestaurantsContextContainer>
        </DashboardContextContainer>
      </MyAccountContextContainer>
    </Switch>
  );
}
// here will be all routes for app
Routes.customerProfile = "/customer-detail/:id";
