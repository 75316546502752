import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { DateRange } from "react-date-range";
import { colors } from "theme";

export const DrawerPromoCodeDateContainer = styled(MyDrawerCenterModal)`
  .set-active-container {
    padding: 28px 26px;
    .modal-title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin-bottom: 28px;
    }

    .not-limited-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #1b1c1d;
      padding: 7px 6px 6px 18px;
      border-radius: 20px;
      height: 64px;
      margin-bottom: 25px;
    }

    .not-limited-label {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
    }

    .show-date {
      text-align: center;
      margin-bottom: 16px;
      margin-top: 41px;
    }

    .save-btn {
      background: #ed2626;
      width: 100%;
      padding: 13px 0 14px 0;
      border: 0;
      border-radius: 8px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 32px;
      height: 50px;
    }

    .cancel-btn {
      text-align: center;
      color: #ed2626;
      font-weight: bold;
    }
  }
`;

export const DateRangeContainer = styled(DateRange)`
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 17px;
  /* .rdrCalendarWrapper {
    font-size: 18px;
  } */
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: unset;
    padding-top: 0;
    margin-bottom: 20px;
    .rdrPprevButton,
    .rdrNextButton {
      background: transparent;
    }
    .rdrPprevButton {
      i {
        border-color: transparent #ffffff transparent transparent;
      }
    }
    .rdrNextButton {
      i {
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  .rdrMonths {
    .rdrMonth {
      width: 100%;
      padding: 0;
      .rdrDays {
        .rdrDay {
          .rdrDayNumber {
            span {
              color: #fff;
              font-size: 14px;
              font-weight: 600;
            }
          }
          &.rdrDayDisabled {
            background-color: transparent;
            &:after {
              background: ${colors.redColor};
              content: "";
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translate(-50%, 0);
              width: 4px;
              height: 4px;
              border-radius: 50%;
            }
          }
          &.rdrDayToday {
            .rdrDayNumber {
              span:after {
                background: ${colors.green};
              }
            }
          }
        }
      }
    }
  }
  .rdrYearPicker {
    display: none;
  }
  .rdrMonthName {
    display: none;
  }
  .rdrMonthAndYearPickers {
    select {
      font-size: 17px;
      font-weight: 600;
      color: #fff;
      margin-left: 20px;
    }
  }
  .rdrWeekDay {
    font-weight: 600;
    line-height: 2.667em;
    font-size: 13px;
    opacity: 0.5;
    color: #fff;
  }
`;
