import React, { Fragment, useEffect, useState } from "react";
import { DrawerMoveCategoriesItemContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerMoveCategoriesItem = ({
  isActive = false,
  closePanel = () => {},
  chosenCategory = {},
  selectedMenuCategories,
  setDeleteCategoriesDrawersForMoveAndDelete = () => {},
  deleteCategoriesDrawersForMoveAndDelete,
  selectedCatForMoveItems = {},
  setSelectedCatForMoveItems = () => {},
  moveAndDeleteConfirmationHandler = () => {},
  countCatgoryItemState,
}) => {

  const [categoriesList, setCategoriesList] = useState([]);
  
  useEffect(() => {
    if (isActive) {
      let filterArray = selectedMenuCategories?.categories?.filter(
        (el) => el?.id !== chosenCategory.id
      );
      setCategoriesList(filterArray);
    }
  }, [chosenCategory, selectedMenuCategories, isActive]);

  return (
    <DrawerMoveCategoriesItemContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"20%"}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center text-bold mb-5">
          Move the items to an <br /> existing category.
        </h2>
        <div className="categories-list">
          {categoriesList?.map((catEl) => {
            return (
              <Fragment>
                {countCatgoryItemState?.id === catEl?.id ? null : (
                  <div
                    className="cat-item"
                    style={{
                      border:
                        selectedCatForMoveItems?.id === catEl?.id
                          ? "solid 2px #ed2626"
                          : "solid 1px #5b5e61",
                    }}
                    onClick={() => {
                      setSelectedCatForMoveItems(catEl);
                    }}
                  >
                    {catEl?.name}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mb-4 text-bold"
            onClick={() => {
              moveAndDeleteConfirmationHandler();
            }}
          >
            <b>Move & Delete Category</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="text-bold"
            onClick={() => {
              setDeleteCategoriesDrawersForMoveAndDelete({
                ...deleteCategoriesDrawersForMoveAndDelete,
                deleteCategory: false,
                moveItems: false,
              });
              setSelectedCatForMoveItems({});
            }}
          >
            <b>Back</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerMoveCategoriesItemContainer>
  );
};

export default DrawerMoveCategoriesItem;
