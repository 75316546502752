/* eslint-disable no-useless-escape */
import React, { useContext, useState, useEffect } from "react";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { MyAccountContext } from "context/MyAccountContext";
import { drawersMyAccount } from "static/staticData";
import { DrawerChangeEmailContainer } from "./style";
import { isValidEmail, showMessage } from "services/functions";
import { FaChevronLeft } from "react-icons/fa";
import { adminServices } from "services";

export default function DrawerChangeEmail({
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer, myAccount, setMyAccount, emailField, setEmailField } = useContext(MyAccountContext);
  // const [emailField, setEmailField] = useState("");

  const [canSubmit, setCanSubmit] = useState(false);

  const [user, setUser] = useState({});

  useEffect(() => {
    setCanSubmit(emailField && isValidEmail(emailField));
  }, [emailField]);

  const emailValueChange = (e) => {
    e.preventDefault();
    setEmailField(e.target.value);
  };

  // const onClickContinue = () => {
  //   if (!canSubmit) {
  //     return;
  //   }
  //   setMyAccount({ ...myAccount, emailRequestedToChange: emailField });
  //   openDrawer(drawersMyAccount.emailSuccess);
  // };

  useEffect(() => {
    if (myAccount) {
      setUser(myAccount);
    }
  }, [myAccount]);

  const onClickSaveChanges = async () => {
    saveChanges(user);
  };

  const saveChanges = (payload) => {
    const { updateMyInfo } = adminServices;
    const onSuccess = () => {
      setMyAccount(payload);
      // showMessage("Updated successfully!", "success");
      openDrawer(drawersMyAccount.emailSuccess);
    };
    const onFailure = (error) => showMessage(error, "error");
    updateMyInfo(
      { ...payload, avatar: payload?.avatar?.id,  email: emailField ?? null },
      onSuccess,
      onFailure
    );
  };

  return (
    <DrawerChangeEmailContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Email Address</div>
          <div></div>
        </div>

        <div className="drawer-content">
          <h6 className="text-bold">Update email address.</h6>
          <div className="email-input">
            <DrawerInput
              placeholder="Enter email address"
              value={emailField}
              onChange={(e) => emailValueChange(e)}
            />
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={canSubmit ? "red" : "gray"}
            // onClick={onClickContinue}
            onClick={onClickSaveChanges}
            className="text-bold"
          >
            Continue
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerChangeEmailContainer>
  );
}
