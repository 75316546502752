import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
// import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerMoveItemsContainer = styled(MyDrawerRight)`
  .main-title {
    margin-bottom: 20px;
  }

  .bottom-btns {
    .move-btn {
      margin-top: 30px;
    }
    .back-btn {
      margin-top: 20px;
      color: #ed2626 !important;
    }
  }

  .white {
    border: 1px solid white;
  }

  .drawer-choose--option {
    padding: 17px 26px;
  }

  /* new drawer code css  */
  .item-customization-wraapper {
    .customization-title {
      margin-top: 40px;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .customization-name {
      font-size: 22px;
      font-weight: 500;
    }
    .dressing-item-list {
      .dressing-item {
        padding: 16px 16px !important;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.34px;
        margin-top: 15px;
      }
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.weight ? props.weight : "700")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
