import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { DrawerStaffVerifyEmailContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";

export default function DrawerStaffVerifyEmail({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {postStaff, setPostStaff} = useContext(RestaurantsContext)

  const [isEmailVerified, setIsEmailVerified] = useState();

  useEffect(() => {
    if (postStaff) {
      setIsEmailVerified(postStaff.email_verified);
    }
  }, [postStaff]);

  const onChangeStatus = (emailVerified) => {
    console.log(emailVerified)
    setIsEmailVerified(emailVerified);
  };

  const onSaveEmailStatus = () => {
    setPostStaff({...postStaff, email_verified: isEmailVerified})
    closePanel()
  };

  return (
    <DrawerStaffVerifyEmailContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Email Address</div>
          <div></div>
        </div>
        <div className="drawer-status--options mb-auto mt-4">
          <DrawerChooseItem
            className="mb-4"
            title="Verified"
            active={isEmailVerified === true}
            onChooseItem={() => onChangeStatus(true)}
          />
          <DrawerChooseItem
            title="Unverified"
            // subtitle="Not visible to customers"
            active={isEmailVerified !== true}
            onChooseItem={() => onChangeStatus(false)}
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={onSaveEmailStatus}
          >
            Save
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerStaffVerifyEmailContainer>
  );
}
