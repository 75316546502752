import styled from "@emotion/styled";
import { colors } from "theme";

export const MenuCardContainer = styled.div`
  padding: 8px 0;
  // width: 100%;
  .drag-card {
    box-shadow: 2px 2px 16px 6px rgba(0, 0, 0, 0.6) !important;
  }
  .new {
    border: 2px solid ${colors.redColor};
  }
  .menu-card {
    max-width: 550px;
    border-radius: 20px;
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.gray2};
    margin-right: 26px;
    padding: 24px 12px 24px 24px;

    .menu-card-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .inner-block-menu-left {
        width: 187px;
        display: flex;
        align-items: center;
        width: fit-content;
        position: relative;

        .image-place {
          width: 100%;
          height: 186px;

          &.image-left-part {
            margin-left: 0;
          }

          .utensils {
            width: 187px;
            height: 186px;
            border-radius: 15px;
            background-color: ${colors.gray5};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .food-photo {
            width: 187px;
            height: 186px;
            border-radius: 15px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;

            // for drinks
            &.contain {
              background-color: white;
            }
          }
        }
        .quantity-control {
          position: absolute;
          display: inline-flex;
          width: 96px;
          height: 40px;
          background: #fff;
          justify-content: space-between;
          align-items: center;
          z-index: 99;
          border-radius: 10px;
          bottom: -7%;
          left: 25%;
          button {
            background: transparent;
            border: none;
            font-size: 17px;
            color: #27282a;
            opacity: 0.4;
            font-weight: bold;
            width: 36px;
            height: 100%;
            padding: 0;
          }

          h5 {
            margin-bottom: 0;
            color: #1b1c1d;
            font-weight: 500;
            font-size: 17px;
          }
        }
      }

      .main-description {
        padding-left: 17px;
        min-height: 186px;
        position: relative;

        .title {
          font-size: 16.2px;
          font-weight: bold;
          color: white;
          letter-spacing: -0.41px;
          cursor: pointer;
        }

        .description {
          display: flex;
          flex-direction: column;

          .text-description {
            width: 246px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            letter-spacing: -0.29px;
            line-height: 18px;
            margin-top: 8px;
            margin-bottom: 0px;
          }
        }
      }
    }

    .card-col {
      text-align: left;

      &.icon {
        width: 40px;
        cursor: pointer;

        .icon-container {
          width: 10px;
          text-align: center;
          .icon-drag {
            width: 19px;
            color: #dadde8;
          }
        }
      }

      &.name-block {
        padding: 0 80px 0 30px;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: white;
          letter-spacing: -0.38px;
        }

        .description {
          font-size: 14px;
          letter-spacing: -0.38px;
          color: white;
          opacity: 0.5;
        }
      }

      &.more-detail {
        width: 5%;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  ..menu-card {
    border-radius: 8px;
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.gray2};
    padding: 10px 10px;
    /* margin: 8px 0; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .menu-card-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 55%;
      .menu-card-img-wrapper {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        .card-col {
          width: fit-content;
          &.card-img {
            width: 187px;
            text-align: center;
            background: #fff;
            img {
              max-height: 125px;
              max-width: 187px;
              border-radius: 4px;
            }
            .menu-card-img-default {
              width: 187px;
              height: 125px;
              margin: 0 33px 0 20px;
              padding: 28px 11px 27px 12px;
              border-radius: 8px;
              background-color: #6b6b6d;
            }
          }
        }
      }
    }
    .card-col {
      text-align: left;
      &.card-description {
        margin-left: 30px;
        h6 {
          font-weight: 500;
        }
        p {
          font-size: 14px;
          opacity: 0.5;
        }
      }
      &.card-title {
        width: 40%;
      }
      &.card-price {
        width: 10%;
      }
      &.card-cal {
        width: 10%;
      }
      &.card-service {
        width: 10%;
        display: flex;
        flex-direction: column;
      }
      &.card-reviews_count {
        width: 10%;
      }
      &.card-edit {
        margin-left: auto;
        margin-right: 40px;
        .popup-wrapper {
          padding: 16px 33px;
          border-radius: 25px;
          box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
          border: solid 1px rgba(255, 255, 255, 0.08);
          background-color: #151617;
        }
      }
      .icon-container {
        width: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 10px;
        .icon-drag {
          width: 19px;
          color: #dadde8;
          cursor: -webkit-grab;
          cursor: grab;
          /* cursor: -webkit-grabbing; */
          /* cursor: grabbing; */
        }
      }
    }
  }
`;

export const PopupContainer = styled.div`
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
`;
