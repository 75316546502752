import React, { useEffect, useState } from "react";
import { DrawerEditCategoryContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerEditCategory = ({
  isActive = false,
  closePanel = () => {},
  selectedCategory = "",
  editCategoryHandler = () => {},
}) => {
  const [catName, setCatName] = useState("");
  useEffect(() => {
    if (isActive) {
      setCatName(selectedCategory?.name);
    }
  }, [isActive]);
  return (
    <DrawerEditCategoryContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Edit Category Name</div>
          <div></div>
        </div>
        <div className="drawer-categories-wrapper ">
          <DrawerInput
            placeholder="Enter Full Name"
            value={catName}
            onChange={(e) => {
              setCatName(e?.target?.value);
            }}
            className="input-div"
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold mb-2"
            onClick={() => {
              editCategoryHandler(catName);
            //   setCatName();
            }}
            disabled={catName === ""}
          >
            Done
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="mt-auto text-bold"
            onClick={closePanel}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerEditCategoryContainer>
  );
};

export default DrawerEditCategory;
