import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DrawerApplyDiscountContainer } from "./style";
import SingleText from "components/SingleText";
import { DiscountTypeValue } from "./DiscountTypeValue";
import { DrawerContainer } from "components/styledStyle";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export function DrawerApplyDiscount({
  isActive = false,
  closePanel = () => {},
  onChangeType,
  discountOption,
  onChoose,
  onApply,

  isOther,
  percents,
  setPercents,
  dollars,
  setDollars,
  onSwitchToOtherAndSetPercentage,
  isPercentageActive,
  isFixedActive,
  activeOrderDetail,
}) {

  const isButonDisabledHandler = () => {
    if (discountOption === "" && dollars === 0) {
      return true;
    } else if (discountOption !== "" && dollars === 0) {
      return false;
    } else if (discountOption === "" && dollars !== 0) {
      return false;
    } else if (discountOption !== "" && dollars !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <DrawerApplyDiscountContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      position="fixed"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <h3 className="mb-10 d-flex justify-content-center">
            Apply Discount
          </h3>
          <div className="discount-options-container div-padding ">
            {[10, 15, 20].map((option, index) => (
              <div
                className={`discount-option ${
                  option === discountOption && isPercentageActive() && !isOther
                    ? "active"
                    : ""
                }`}
                onClick={() => onChoose(option)}
                key={index + option}
              >
                <span className="option-title">{option}%</span>
              </div>
            ))}
            <div
              className={`discount-option ${isOther ? "active" : ""}`}
              onClick={onSwitchToOtherAndSetPercentage}
            >
              <span className="option-title">Other</span>
            </div>
          </div>
          {isOther ? (
            <DiscountTypeValue
              dollars={dollars}
              setDollars={setDollars}
              percents={percents}
              setPercents={setPercents}
              onChangeType={onChangeType}
              isFixedActive={isFixedActive}
              isPercentageActive={isPercentageActive}
              activeOrderDetail={activeOrderDetail}
            />
          ) : (
            ""
          )}
          <div className="subtotal-container div-padding ">
            <SingleText
              size={15}
              spacing="0.25px"
              align="center"
              weight="500"
              width="100%"
            >
              {percents || dollars ? (
                <>
                  {" "}
                  {isPercentageActive()
                    ? `${
                        isOther ? Math.trunc(percents) : discountOption
                      }% of the subtotal will be discounted.`
                    : `$${
                        dollars ? dollars?.toFixed(2) : ""
                      } will be discounted`}
                </>
              ) : (
                <>Select a discount above.</>
              )}
            </SingleText>
            <SingleText size={14} opacity={"0.6"}>
              Customer will see this discount on their bill.
            </SingleText>
          </div>
          <div
            className="bottom-btns div-padding "
            style={{ marginTop: "30px" }}
          >
            <MyDrawerButton
              type="red"
              className="mb-3"
              onClick={onApply}
              disabled={isButonDisabledHandler()}
            >
              <b>Apply</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerApplyDiscountContainer>
  );
}

DrawerApplyDiscount.propTypes = {
  visible: PropTypes.bool,
  closePanel: PropTypes.func,
  onChangeType: PropTypes.func,
  discountOption: PropTypes.number,
  onChoose: PropTypes.func,
  onApply: PropTypes.func,

  isOther: PropTypes.bool,
  isPercentageActive: PropTypes.func,
  isFixedActive: PropTypes.func,
  percents: PropTypes.number,
  setPercents: PropTypes.func,
  dollars: PropTypes.number,
  setDollars: PropTypes.func,
  onSwitchToOtherAndSetPercentage: PropTypes.func,
};
