import { NoAvatar } from "assets/img";
import MyButton from "components/Elements/MyButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect } from "react";
import { Image } from "react-bootstrap";
import {
  FaCheckCircle,
  FaMapMarkerAlt,
  FaPhone,
  FaClock,
  FaAt,
  FaUserAlt,
  FaTimesCircle,
  FaMobileAlt,
  FaDollarSign,
  FaUtensils,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { drawersOverall } from "static/staticData";
import { colors } from "theme";
import { ProfileCardInfoContainer, InfoContainer } from "./style";
import moment from "moment";
import NumberFormat from "react-number-format";

export default function ProfileCardInfo({ className = "", profile = {} }) {
  const {
    openDrawer,
    postRestaurant,
    setPostRestaurant,
    setCuisineGlobalData,
  } = useContext(RestaurantsContext);

  useEffect(() => {
    if (postRestaurant) {
      setCuisineGlobalData(postRestaurant?.cuisines);
    }
  }, [postRestaurant]);

  const onClickEditRestaurant = () => {
    setPostRestaurant(profile);
    openDrawer(drawersOverall.editProfile);
  };

  const getWorkingHours = (schedule) => {
    if (schedule.length > 0) {
      const day = new Date().getDay() - 1;
      let finalTimeRender;
      schedule.map((_time) => {
        if (_time.day === day) {
          const timeFrom = moment
            .utc(+_time.times[0][0] * 1000)
            .format("hh:mm A");
          const timeTo = moment
            .utc(+_time.times[0][1] * 1000)
            .format("hh:mm A");
          finalTimeRender = `${timeFrom} - ${timeTo}`;
        }
        return;
      });
      return finalTimeRender;
    } else {
      return <p>-</p>;
    }
  };

  const statusRenderer = (status) => {
    switch (status) {
      case 1:
        return <span className={`active`}>Active</span>;
      case 0:
        return <span className={`inactive`}>Inactive</span>;
      case 9:
        return <span className={`banned`}>Banned</span>;
      default:
        return null;
    }
  };

  return (
    <ProfileCardInfoContainer className={`${className} profile-info`}>
      <div className="profile-info-header d-flex justify-content-between align-items-start">
        <div className="profile-info-avatar">
          <Image
            className="rounded-circle profile-picture"
            src={profile?.photo?.file ? profile?.photo?.file : NoAvatar}
          />
          <div className="customer-profile-rating">
            <div className="rating-container">
              <div className="rating-icon">
                <i className="fas fa-star" />
              </div>
              <span className="rating-number">
                {profile?.scores
                  ? (
                      (Number(profile?.scores?.rating_food) +
                        Number(profile?.scores?.rating_service)) /
                      2
                    )?.toFixed(1)
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start mr-auto">
          <h3 className="profile-info-name text-bold">{profile.title}</h3>
          <h6 className="profile-info-join-date">
            Joined: -{" "}
            <b>
              {" "}
              {profile.joined &&
                moment(profile.joined).format("MMMM DD, YYYY, HH:MM A")}
            </b>
            {statusRenderer(profile?.status)}
            {/* <span className={`${profile.status === 0 ? "inactive" : "active"}`}>
              {profile.status === 0 ? "Inactive" : "Active"}
            </span> */}
          </h6>
          <h6 className="profile-info-location">
            <FaMapMarkerAlt className="mr-3" style={{ opacity: "0.6" }} />
            <span style={{ color: "#8f949a" }}>{profile.address}</span>
          </h6>
        </div>
        <MyButton
          type="gray"
          className="edit-btn"
          onClick={onClickEditRestaurant}
        >
          Edit
        </MyButton>
      </div>
      
      <div className="profile-info-row">
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6 ">
              <FaMobileAlt style={{ fontSize: "16px" }} />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Phone</h6>
              <h5 className="profile-info-col-value">
                {profile.phone_number !== "" ? (
                  <div className="mobile-number-input">
                    <div className="drawer-input-container">
                      <NumberFormat
                        format={"+# (###) ###-####"}
                        className={`single-ant-format-input`}
                        value={profile.phone_number || ""}
                        onValueChange={(e) => {}}
                        onBlur={() => {}}
                        placeholder={"Set Mobile Number"}
                        readOnly
                      />
                    </div>
                    <FaCheckCircle className="ml-3 mb-1" color={colors.green} />
                  </div>
                ) : (
                  <>-</>
                )}
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <MdEmail style={{ fontSize: "16px" }} />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Email Address</h6>
              <h5 className="profile-info-col-value">
                {profile.email}
                {profile?.email_verified === true ? (
                  <FaCheckCircle className="ml-3" color={colors.green} />
                ) : (
                  <FaTimesCircle className="ml-3" color={colors.redColor} />
                )}
              </h5>
            </div>
          </InfoContainer>
        </div>
      </div>
      <div className="profile-info-row">
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <FaClock style={{ fontSize: "16px" }} />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Today's hours</h6>
              <h5 className="profile-info-col-value">
                {/* {profile.working_hours && profile.working_hours.length > 0
                  ? "Set"
                  : "Not available"} */}
                <div>
                  {profile.working_hours &&
                    getWorkingHours(profile.working_hours)}
                </div>
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <FaDollarSign style={{ fontSize: "16px" }} />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Price Tier</h6>
              <h5 className="profile-info-col-value">
                {/* {profile.price_level &&
                  Array.from(
                    { length: profile.price_level - 1 },
                    (v, i) => "$"
                  )} */}
                {profile.price_level &&
                  Array.from({ length: profile.price_level }, (v, i) => "$")}
              </h5>
            </div>
          </InfoContainer>
        </div>
      </div>
      <div className="profile-info-row">
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <FaUtensils style={{ fontSize: "16px" }} />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Cuisine</h6>
              <h5 className="profile-info-col-value">
                {profile.cuisines &&
                  profile.cuisines.length > 0 &&
                  profile.cuisines.join(", ")}
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <FaUserAlt style={{ fontSize: "16px" }} />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Manager</h6>
              <h5 className="profile-info-col-value">
                {profile.manager_name}{" "}
                {profile.manager_title && `, ${profile.manager_title}`}
              </h5>
            </div>
          </InfoContainer>
        </div>
      </div>
    </ProfileCardInfoContainer>
  );
}
