import React, { useState, createContext } from "react";

const PromoCodesContext = createContext({});

export { PromoCodesContext };

export const PromoCodesContextContainer = (props) => {

  const [openDrawers, setOpenDrawers] = useState({});
 
  const openDrawer = (drawer) => {
    // console.log(drawer)
    let temp = { ...openDrawers };
    // console.log(temp)
    if (Array.isArray(drawer)) {
      drawer.forEach((_drawer) => {
        temp = { ...temp, [_drawer]: _drawer };
      });
    } else {
      temp = { ...temp, [drawer]: drawer };
    }
    setOpenDrawers(temp);
  };

  const closeDrawer = (drawer) => {
    // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
    let temp = { ...openDrawers };
    if (Array.isArray(drawer)) {
      drawer.forEach((_drawer) => {
        delete temp[_drawer];
      });
    } else {
      delete temp[drawer];
    }
    setOpenDrawers(temp);
  };

  return (
    <PromoCodesContext.Provider
      value={{
        openDrawer,
        closeDrawer,
      }}
    >
      {props.children}
    </PromoCodesContext.Provider>
  );
};
