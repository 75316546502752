import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerDeleteCustomerContainer = styled(MyDrawerCenterModal)`
  .delete-icon {
    background-color: #ed2626;
    border-radius: 50%;
    width: 57px;
    height: 57px;
    position: absolute;
    top: -33px;
    left: 194px;
    i {
      font-size: 24px;
      text-align: center;
      color: #fff;
      padding: 16px 17px;
    }
  }
  .top-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .delete-customer-message {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.27px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  .delete-customer-meta {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.27px;
    color: #fff;
    margin-bottom: 41px;
    text-align: center;
  }
`;
