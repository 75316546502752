import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { restaurantServices } from "services";
import { DrawerDeleteFoodContainer } from "./style";

export default function DrawerDeleteFood({
  profile = {},
  isActive = false,
  closePanel = () => {},
  callBack = () => {},
}) {
  const { id } = useParams();
  const {
    postFood,
    categoryId,
    setSelectedMenu,
    setFoods,
    selectedMenu,
  } = useContext(RestaurantsContext);

  useEffect(() => {}, [id, postFood, categoryId]);

  useEffect(() => {}, [postFood]);

  const onDeleteItem = async () => {
    customAxios
      .delete(`/restaurant/${id}/food/${postFood?.id}?category=${categoryId}`)
      .then((res) => {
        closePanel();
        callBack();

        getCategoryData();
        getRestaurantProfile();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCancel = () => {
    closePanel();
  };

  function getCategoryData() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
    }
  };

  return (
    <DrawerDeleteFoodContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2>Delete Item</h2>
        <h2 className="confirmation-delete mt-3">
          Are you sure you want to delete{" "}
          <span className="text-bold">{postFood?.title}</span>?
        </h2>
        <p className="undone">This cannot be undone.</p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold mb-3"
            onClick={onCancel}
          >
            Keep
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold cancel-btn"
            onClick={onDeleteItem}
          >
            Delete
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDeleteFoodContainer>
  );
}
