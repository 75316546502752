/* eslint-disable react-hooks/exhaustive-deps */
import MyButton from "components/Elements/MyButton";
import React, { useContext, useEffect, useState, useRef } from "react";
import query from "query-string";
import { FaPlus, FaSlidersH } from "react-icons/fa";
import ItemCard from "./ItemCard";
import { drawersMenu } from "static/staticData";
import { CategoriesContainer, ProfileMenuContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import DrawerMenus from "./Drawers/Menu/DrawerMenus";
import DrawerMenu from "./Drawers/Menu/DrawerMenu";
import DrawerAvailabilities from "./Drawers/Menu/DrawerAvailabilities";
import DrawerStatusFood from "./Drawers/Food/DrawerStatusFood";
import DrawerDeleteMenu from "./Drawers/Menu/DrawerDeleteMenu";
import DrawerDeleteConfirm from "./Drawers/Menu/DrawerDeleteConfirm";
import DrawerMoveItems from "./Drawers/Menu/DrawerMoveItems";
import DrawerCategories from "./Drawers/Category/DrawerCategories";
import DrawerItemType from "./Drawers/Food/DrawerItemType";
import DrawerDietaryTags from "./Drawers/Food/DrawerDietaryTags";
import { restaurantServices } from "services";
import DrawerCustomizations from "./Drawers/Food/DrawerCustomizations";
import DrawerNutritions from "./Drawers/Food/DrawerNutritions";
import DrawerAssignToMenu from "./Drawers/Food/DrawerAssignToMenu";
import DrawerStatusMenu from "./Drawers/Menu/DrawerStatusMenu";
import DrawerAddType from "./Drawers/Food/DrawerAddType";
import DrawerFood from "./Drawers/Food/DrawerFood";
import DrawerDeleteItem from "./Drawers/Food/DrawerDeleteItem";
import { useHistory } from "react-router";
import DrawerFoods from "./Drawers/Food/DrawerFoods";
import RestaurantMenuSelect from "components/RestaurantMenuSelect";
import Badge from "react-bootstrap/Badge";
import MySearch from "components/Elements/MySearch";
import MyDropDown from "components/Elements/MyDropDown";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useOutsideClick } from "services/functions";
import { NoScreen } from "assets/img";
import DrawerShowItem from "./Drawers/Food/DrawerShowItem";
import DrawerCheckExistsMenu from "./Drawers/Menu/DrawerCheckExistsMenu";

export const _mocked_Food_Checks = [
  { icon: <i className="fas fa-candy-cane" />, name: "Sugar-Free", value: 4 },
  { icon: <i className="fas fa-cow" />, name: "Dairy-Free", value: 3 },
  { icon: <i className="fas fa-leaf" />, name: "Vegetarian", value: 0 },
  { icon: <i className="fas fa-leaf-heart" />, name: "Vegan", value: 1 },
  { icon: <i className="fas fa-wheat" />, name: "Gluten-Free", value: 2 },
];

export const RestaurantPendingProfileMenu = ({ id }) => {
  const ref = useRef();
  const addRef = useRef();
  const history = useHistory();
  const [isEditable] = useState(false);
  const {
    foods,
    setPostFood,
    postMenu,
    menus,
    openDrawers,
    openDrawer,
    closeDrawer,
    selectedMenu,
    setSelectedMenu,
    setSelectedCategory,
    fetchRestaurant,
    restaurantId,
    setCategoryId,
    globalMenuId,
    setGlobalMenuId,
    restaurant,
    chooseCloseDrawer,
    setChooseCloseDrawer,
    menuCloseDrawer,
    setMenuCloseDrawer,
    postFood,
    postRestaurant,
    postCategory,
    setFoods,
    restauranProfileSelectedMenu,
    setRestaurantProfileSelectedMenu,
    setPostMenu,
    setMenuId,
    setSelectedMenucatogories,
    menuId,
    setMenus,
    drawerEditMenus,
    setDrawerEditMenus,
    drawerMenuAdd,
    setDrawerMenuAdd,
  } = useContext(RestaurantsContext);

  const [ratingType, setRatingType] = useState();

  const [editMenu, setEditMenu] = useState(true);
  const [reorderItems, setReorderItems] = useState(true);

  const [openFoodType, setOpenFoodType] = useState(false);
  const [foodType, setFoodType] = useState([]);

  const [showActiveMenuMessage, setShowActiveMenuMessage] = useState(false);
  const [activeTooltipId, setActiveTooltipId] = useState(false);
  const [showFoodItem, setShowFoodItem] = useState(null);
  const [isShowItemDrawerOpen, setIsShowItemDrawerOpen] = useState(false);

  const [categoryTabId, setCategoryTabId] = useState();

  useEffect(() => {
    onSwitchMenu();
  }, []);

  useEffect(() => {
    if (
      restauranProfileSelectedMenu !== null &&
      restauranProfileSelectedMenu?.status !== 1
    ) {
      setShowActiveMenuMessage(true);
    } else {
      setShowActiveMenuMessage(false);
    }
  }, [restauranProfileSelectedMenu]);

  const onSwitchMenu = (menu) => {
    setGlobalMenuId(menu?.id);
    setMenuId(menu?.id);
    history.push(`/restaurant-detail/${id}?view=menu&menuId=${menu?.id}`);
  };

  const getMenuDetailById = async () => {
    if (menuId) {
      const result = await restaurantServices.getRestaurantMenuDetail(
        id,
        menuId
      );
      if (result.status === 200) {
        setSelectedMenu(result?.data);
        // console.log(result, "--");
      }
    }
  };

  const onClickAddItem = (_category) => {
    setSelectedCategory(_category);
    setPostFood({ category: [_category.id] });

    // openDrawer(drawersMenu.addType);
    setChooseCloseDrawer({
      ...chooseCloseDrawer,
      addType: true,
    });
    // console.log(_category);
  };

  const onClickEditItem = (itemId, cateId) => {
    let fData = foods?.find((_food) => _food.id === itemId);
    console.log("fData", fData);
    setPostFood(foods.find((_food) => _food.id === itemId));
    openDrawer(drawersMenu.itemEdit);
    setCategoryId(cateId);
    // console.log(cateId, 'c-id-edit')
  };

  const onClickDeleteItem = (itemId, cateId) => {
    setPostFood(foods.find((_food) => _food.id === itemId));
    openDrawer(drawersMenu.itemDelete);
    setCategoryId(cateId);
    // console.log(cateId, 'c-id-delete')
  };

  const onDoneDeleteMenu = async () => {
    const result = await restaurantServices.deleteMenu(id, postMenu.id);
    if (result.status === 200) {
      await fetchRestaurant(id);
      closeDrawer([
        drawersMenu.deleteMenu,
        drawersMenu.menuEdit,
        drawersMenu.deleteConfirm,
      ]);
    }
  };

  // useEffect(() => {
  //   if (menuId && menuId !== "0") {
  //     getMenuDetailById();
  //   } else {
  //     setSelectedMenu({});
  //   }
  // }, [menuId, id]);

  const newData = [];

  useEffect(() => {
    if (menuId && menuId !== "0") {
      getMenuDetailById();
    } else {
      setSelectedMenu(newData);
      menus.map((item) => {
        newData.push(...item.categories);
        setSelectedMenu(newData);
      });
    }
  }, [menuId, id, menus]);

  const scroll = (i) => {
    let element = document.getElementById(`list-category-${i}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const reorderColumnList = (list, startIndex, endIndex) => {
    const [removed] = list.items.splice(startIndex, 1);
    list.items.splice(endIndex, 0, removed);
    return list;
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceCol = selectedMenu.categories.find(
      (col) => col.id == source.droppableId
    );
    const destCol = selectedMenu.categories.find(
      (col) => col.id == destination.droppableId
    );
    if (sourceCol.id === destCol.id) {
      const newColumn = reorderColumnList(
        sourceCol,
        source.index,
        destination.index
      );

      const newState = selectedMenu.categories.map((col) => {
        if (col.id === newColumn.id) {
          return newColumn;
        }
        return col;
      });
      setSelectedMenu({
        ...selectedMenu,
        categories: newState,
      });

      const payload = {
        sort: newColumn.items,
        food_item_id: draggableId,
        target_category_id: destCol.id,
      };

      return await restaurantServices.foodItemSort(
        restaurant.id,
        selectedMenu.id,
        source.droppableId,
        payload
      );
    }

    if (sourceCol.id !== destCol.id) {
      const [sourceRemoved] = sourceCol.items.splice(source.index, 1);
      destCol.items.splice(destination.index, 0, sourceRemoved);
      const newState = selectedMenu.categories.map((col) => {
        if (col.id === sourceCol.id) {
          return sourceCol;
        }
        if (col.id === destCol.id) {
          return destCol;
        }
        return col;
      });

      setSelectedMenu({
        ...selectedMenu,
        categories: newState,
      });

      const payload = {
        sort: destCol.items,
        food_item_id: draggableId,
        target_category_id: destCol.id,
      };

      return await restaurantServices.foodItemSort(
        restaurant.id,
        selectedMenu.id,
        source.droppableId,
        payload
      );
    }
  };

  useOutsideClick(ref, () => {
    if (openFoodType) setOpenFoodType(false);
  });

  useOutsideClick(addRef, () => {
    setActiveTooltipId(false);
  });

  const checkIsSelected = (id) => foodType.includes(id);

  const addFoodType = (item) => {
    if (!checkIsSelected(item)) {
      setFoodType([...foodType, item]);
    } else {
      setFoodType(foodType?.filter((i) => i !== item));
    }
  };

  const handleChooseExistingMenu = (category) => {
    setSelectedCategory(category);
    setChooseCloseDrawer({
      ...chooseCloseDrawer,
      foods: true,
    });
  };

  const menuSetToActiveHandler = async () => {
    const result = await restaurantServices.updateMenu(id, {
      status: 1,
      id: restauranProfileSelectedMenu?.id,
    });
    if (result.status === 200) {
      setRestaurantProfileSelectedMenu({
        ...restauranProfileSelectedMenu,
        status: 0,
      });
      setShowActiveMenuMessage(false);
      const result = await restaurantServices.getRestaurantDetail(id);
      if (result?.status === 200) {
        setMenus(result?.data?.menus);
      }
    } else {
      setRestaurantProfileSelectedMenu({});
    }
  };

  const openEditMenuDrawer = () => {
    setMenuCloseDrawer({
      ...menuCloseDrawer,
      menuEdit: true,
    });
    setPostMenu(selectedMenu);
    setMenuId(selectedMenu?.id);
    setSelectedMenucatogories(selectedMenu);
  };

  return (
    <ProfileMenuContainer className="profile-menu-container">
      <CategoriesContainer isBorderBottom={true}>
        {editMenu ? (
          <>
            {menuId !== "0" ? (
              <MyButton
                className="edit-button"
                type="redColor"
                onClick={() => {
                  openEditMenuDrawer();
                }}
              >
                <i className="fal fa-pencil" />
              </MyButton>
            ) : (
              <MyButton
                className="edit-button"
                type="redColor"
                onClick={() => {
                  openEditMenuDrawer();
                }}
              >
                <i className="fal fa-pencil" />
              </MyButton>
            )}
          </>
        ) : (
          <></>
        )}

        {menuId !== "0" ? (
          <div
            className="category-scroll"
            style={{ marginLeft: editMenu ? "30px" : "unset" }}
          >
            {selectedMenu?.categories?.length > 0 &&
              selectedMenu?.categories
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((_category, _i) => (
                  <div
                    key={_i}
                    className="category-badge-wrapper"
                    onClick={() => {
                      scroll(_i);
                      setCategoryTabId(_category?.id);
                    }}
                  >
                    <Badge
                      bg="primary"
                      className="category-badge"
                      style={{
                        backgroundColor:
                          categoryTabId === _category?.id
                            ? "#3E4245"
                            : "#27282A",
                        padding: "13px 24px",
                        fontSize: "14px",
                        cursor: "pointer",
                        borderRadius: "25px",
                        color: "#fff",
                      }}
                    >
                      {_category.name}
                    </Badge>
                  </div>
                ))}
          </div>
        ) : (
          <div className="category-scroll">
            {selectedMenu &&
              selectedMenu.length > 0 &&
              selectedMenu
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((categories, _i) => (
                  <div key={_i} className="category-badge-wrapper">
                    <Badge bg="primary" className="category-badge">
                      {categories.name}
                    </Badge>
                  </div>
                ))}
          </div>
        )}

        <div className="d-flex ml-auto">
          <div className="mr-3">
            <MySearch
            // value={}
            // onChange={() => {}}
            />
          </div>

          {/* <div
            style={{
              position: "relative",
              width: "50px",
              height: "40px",
              borderRadius: "25px",
              background: "#27282a",
              cursor: "pointer",
              padding: "10px 19px",
            }}
          >
            <FaSlidersH />
          </div> */}

          {/* dietary filter */}
          <div
            style={{
              position: "relative",
              width: "151px",
              height: "43px",
              borderRadius: "25px",
              background: "#27282a",
              cursor: "pointer",
              margin: "0 10px",
            }}
            className="dietary-select"
            ref={ref}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 20px",
                color: "#fff",
                alignItems: "center",
                overflowX: "auto",
                overflowY: "auto",
              }}
              onClick={() => {
                setOpenFoodType(!openFoodType);
              }}
            >
              <span style={{ overflow: "auto" }}>
                {/* {foodType.length > 0 && foodType.length <= 2
                    ? foodType.map((item) => check(item)).join(', ')
                    : 'Dietary'} */}
                Dietary
              </span>
              {!openFoodType ? (
                <i className="fas fa-angle-down" />
              ) : (
                <i className="fas fa-angle-up" />
              )}
            </div>
            {openFoodType && (
              <div
                className="options-dietary"
                style={{
                  position: "absolute",
                  width: "100%",
                  // left: "-5%",
                  top: "110%",
                  borderRadius: "25px",
                  border: "solid 1px rgba(255, 255, 255, 0.08)",
                  background: "#151617",
                  height: "max-content",
                  zIndex: 99,
                  minWidth: "10rem",
                  fontSize: "1rem",
                  color: "#fff",
                  textAlign: "left",
                  padding: "10px 0",
                }}
              >
                {_mocked_Food_Checks &&
                  _mocked_Food_Checks.map((item, index) => (
                    <div
                      className={`${
                        foodType.includes(item.value)
                          ? "option-dietary-item active"
                          : "option-dietary-item "
                      }`}
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginLeft: 0,
                        alignItems: "center",
                        cursor: "pointer",
                        marginBottom: "3px",
                      }}
                      onClick={() => addFoodType(item.value)}
                    >
                      {item.icon} <span> {item.name}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {menus && menus.length ? (
            <RestaurantMenuSelect
              className="ml-3"
              options={[...menus]}
              defaultValueId={menuId}
              renderExtra={() => (
                <p
                  className="text-red text-bold"
                  // onClick={() => openDrawer(drawersMenu.menus)}
                  onClick={() =>
                    setDrawerEditMenus({
                      ...drawerEditMenus,
                      menus: true,
                    })
                  }
                >
                  Edit Menus
                </p>
              )}
              onChange={(option) => onSwitchMenu(option)}
              selectedMenuOption={(option) => {
                setRestaurantProfileSelectedMenu(option);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </CategoriesContainer>

      <CategoriesContainer isBorderBottom={false}>
        <div className="d-flex ml-auto">
          {/* {editMenu ? (
            <MyButton
              className="edit-button"
              type="redColor"
              // onClick={() => openDrawer(drawersMenu.menus)}
              onClick={() =>
                setDrawerEditMenus({
                  ...drawerEditMenus,
                  menus: true,
                })
              }
            >
              <i className="fal fa-pencil" />
            </MyButton>
          ) : (
            <></>
          )} */}

          {/* {menus && menus.length ? (
            <RestaurantMenuSelect
              className="ml-3"
              options={[...menus]}
              defaultValueId={menuId}
              renderExtra={() => (
                <p
                  className="text-red text-bold"
                  // onClick={() => openDrawer(drawersMenu.menus)}
                  onClick={() =>
                    setDrawerEditMenus({
                      ...drawerEditMenus,
                      menus: true,
                    })
                  }
                >
                  Edit Menus
                </p>
              )}
              onChange={(option) => onSwitchMenu(option)}
              selectedMenuOption={(option) => {
                setRestaurantProfileSelectedMenu(option);
              }}
            />
          ) : (
            <></>
          )} */}
        </div>
      </CategoriesContainer>

      <div className="menu-active-inactive-wrapper">
        {showActiveMenuMessage && restauranProfileSelectedMenu?.status !== 1 ? (
          <div className="active-inactive-wrapper">
            <div className="d-flex align-items-start flex-column">
              <b>Menu status will be changed to Active.</b>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Customers will be able to browse this menu in the Feasttt app.
                You will be able to change status in the Menu Settings anytime.
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <MyButton
                  className="set-cancel-btn"
                  type="red"
                  onClick={() => {
                    setShowActiveMenuMessage(false);
                  }}
                >
                  Cancel
                </MyButton>
                <MyButton
                  className="set-d-active-btn"
                  type="red"
                  onClick={() => menuSetToActiveHandler()}
                >
                  Set to Active
                </MyButton>
              </div>
            </div>
          </div>
        ) : (
          <></>
          // <div className="active-inactive-wrapper">
          //   <div className="d-flex align-items-center justify-content-between">
          //     <div>
          //       <i className="fas fa-exclamation-circle" /> Menu not visible to
          //       customers.
          //     </div>
          //     <MyButton
          //       className="set-active-btn"
          //       type="red"
          //       onClick={() => {}}
          //     >
          //       Set to Active
          //     </MyButton>
          //   </div>
          // </div>
        )}
      </div>

      <div className="menus-wrapper">
        {/* @TODO this is all menu function for in future use */}
        {menus && menus.length ? (
          <></>
        ) : (
          <div className="no-menu-message d-flex flex-column align-items-center justify-content-center">
            <img className="empty-screen mb-3" src={NoScreen} alt="" />
            <div className="text1 mb-3">Customers are hungry.</div>
            <div className="text2">Add first</div>
            <div className="text2 mb-8">menu.</div>
            <MyButton
              className="btn-add-menu-categories"
              type="red"
              // onClick={() => openDrawer(drawersMenu.menuAdd)}
              onClick={() =>
                setDrawerMenuAdd({
                  ...drawerMenuAdd,
                  menuAdd: true,
                })
              }
            >
              Add Menu
            </MyButton>
          </div>
        )}
        {menuId === "0" ? (
          <div>
            {selectedMenu &&
              selectedMenu.length > 0 &&
              selectedMenu?.map((_category, _i) => {
                return (
                  <div key={_i} className="menu-category">
                    <div className="category-header d-flex justify-content-between mb-4">
                      <h5 className="mb-0">{_category.name}</h5>
                      {editMenu ? (
                        <div className="add-item-wrapper">
                          {activeTooltipId === _i && (
                            <div ref={addRef} className="add-item-tooltip">
                              <button
                                className="add-item-button"
                                onClick={() => {
                                  openDrawer(drawersMenu.itemAdd);
                                  setPostFood({});
                                }}
                              >
                                <span className="tooltip-icon">+</span>
                                Create a new item
                              </button>
                              <button
                                className="add-item-button"
                                onClick={() =>
                                  handleChooseExistingMenu({
                                    id: _category.id,
                                    name: _category.name,
                                  })
                                }
                              >
                                <i className="fal fa-pencil" />
                                Choose an existing item
                              </button>
                            </div>
                          )}
                          <MyButton
                            className="btn-add-item"
                            type="redColor"
                            onClick={() => {
                              setActiveTooltipId(
                                activeTooltipId === false ? _i : false
                              );
                              setCategoryId(_category.id);
                            }}
                          >
                            <FaPlus /> Add Item
                          </MyButton>
                        </div>
                      ) : null}
                    </div>
                    {_category?.items?.length > 0 && isEditable ? (
                      <div></div>
                    ) : (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {_category?.items?.length > 0 ? (
                          <>
                            {_category?.items?.map((_itemId, _i) => {
                              return (
                                <ItemCard
                                  key={_i}
                                  food={foods.find(
                                    (_food) => _food.id === _itemId
                                  )}
                                  isDraggable={reorderItems}
                                  onClickDelete={() =>
                                    onClickDeleteItem(_itemId, _category.id)
                                  }
                                  onClickEdit={() =>
                                    onClickEditItem(_itemId, _category.id)
                                  }
                                  setShowFoodItem={setShowFoodItem}
                                  setIsShowItemDrawerOpen={
                                    setIsShowItemDrawerOpen
                                  }
                                />
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* {_category?.items?.length > 0 &&
                          _category.items.map((_itemId, _i) => {
                            return (
                              <ItemCard
                                key={_i}
                                food={foods.find(
                                  (_food) => _food.id === _itemId
                                )}
                                isDraggable={reorderItems}
                                onClickDelete={() =>
                                  onClickDeleteItem(_itemId, _category.id)
                                }
                                onClickEdit={() =>
                                  onClickEditItem(_itemId, _category.id)
                                }
                              />
                            );
                          })} */}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        ) : selectedMenu?.categories?.length === 0 ? (
          <div>
            <div className="no-menu-message d-flex flex-column align-items-center justify-content-center">
              <img className="empty-screen mb-3" src={NoScreen} alt="" />
              <div className="text1 mb-3">Good job creating this menu.</div>
              <div className="text2 mb-8">
                Before adding any items you must set up menu categories..
              </div>
              <MyButton
                className="btn-add-menu-categories"
                type="red"
                onClick={() => openEditMenuDrawer()}
              >
                Manage Categories
              </MyButton>
            </div>
          </div>
        ) : !reorderItems ? (
          <>
            {selectedMenu?.categories?.map((_category, _i) => {
              return (
                <div key={_i} className="menu-category">
                  <div
                    className="category-header d-flex justify-content-between mb-4 list-category"
                    id={`list-category-${_i}`}
                  >
                    <h5 className="mb-0" style={{ fontSize: "20px" }}>
                      {_category.name}
                    </h5>
                    {editMenu ? (
                      <div className="add-item-wrapper">
                        {activeTooltipId === _i && (
                          <div ref={addRef} className="add-item-tooltip">
                            <button
                              className="add-item-button"
                              onClick={() => {
                                openDrawer(drawersMenu.itemAdd);
                                setPostFood({});
                              }}
                            >
                              <span className="tooltip-icon">+</span>
                              Create a new item
                            </button>
                            <button
                              className="add-item-button"
                              onClick={() =>
                                handleChooseExistingMenu({
                                  id: _category.id,
                                  name: _category.name,
                                })
                              }
                            >
                              <i className="fal fa-pencil" />
                              Choose an existing item
                            </button>
                          </div>
                        )}
                        <MyButton
                          className="btn-add-item"
                          type="redColor"
                          onClick={() => {
                            setActiveTooltipId(
                              activeTooltipId === false ? _i : false
                            );
                            setCategoryId(_category.id);
                          }}
                        >
                          <FaPlus /> Add Item
                        </MyButton>
                      </div>
                    ) : null}
                  </div>
                  {_category?.items?.length > 0 && isEditable ? (
                    <div></div>
                  ) : (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {_category?.items?.length > 0 ? (
                        <>
                          {_category?.items
                            ?.filter((_item) => {
                              const foodItemList = foods.find(
                                (_food) => _food.id === _item
                              );
                              return ratingType
                                ? foodItemList?.yum_percent >= ratingType
                                : foodType?.length > 0
                                ? foodItemList?.dietary?.some((i) =>
                                    foodType?.includes(i)
                                  )
                                : _item;
                            })
                            .map((_itemId, index) => {
                              return (
                                <ItemCard
                                  key={index}
                                  newIndex={index}
                                  food={foods.find(
                                    (_food) => _food.id === _itemId
                                  )}
                                  isDraggable={reorderItems}
                                  onClickDelete={() =>
                                    onClickDeleteItem(_itemId, _category.id)
                                  }
                                  onClickEdit={() =>
                                    onClickEditItem(_itemId, _category.id)
                                  }
                                  setShowFoodItem={setShowFoodItem}
                                  setIsShowItemDrawerOpen={
                                    setIsShowItemDrawerOpen
                                  }
                                />
                              );
                            })}
                        </>
                      ) : (
                        <div className="opacity-50">
                          There are no items yet.
                        </div>
                      )}
                      {/* {_category?.items?.length > 0 &&
                        _category.items
                          .filter((_item) => {
                            const foodItemList = foods.find(
                              (_food) => _food.id === _item
                            );
                            return ratingType
                              ? foodItemList?.yum_percent >= ratingType
                              : foodType?.length > 0
                              ? foodItemList?.dietary?.some((i) =>
                                  foodType?.includes(i)
                                )
                              : _item;
                          })
                          .map((_itemId, index) => {
                            return (
                              <ItemCard
                                key={index}
                                newIndex={index}
                                food={foods.find(
                                  (_food) => _food.id === _itemId
                                )}
                                isDraggable={reorderItems}
                                onClickDelete={() =>
                                  onClickDeleteItem(_itemId, _category.id)
                                }
                                onClickEdit={() =>
                                  onClickEditItem(_itemId, _category.id)
                                }
                              />
                            );
                          })} */}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            {selectedMenu?.categories?.map((_category, _i) => {
              return (
                <Droppable droppableId={`${_category?.id}`} key={_category.id}>
                  {(provided, snapshot) => (
                    <div
                      key={_i}
                      className="menu-category"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div
                        className="category-header d-flex justify-content-between mb-4 list-category"
                        id={`list-category-${_i}`}
                      >
                        <h5 className="mb-0" style={{ fontSize: "20px" }}>
                          {_category.name}
                        </h5>
                        {editMenu ? (
                          <div className="add-item-wrapper">
                            {activeTooltipId === _i && (
                              <div ref={addRef} className="add-item-tooltip">
                                <button
                                  className="add-item-button"
                                  onClick={() => {
                                    openDrawer(drawersMenu.itemAdd);
                                    setPostFood({});
                                  }}
                                >
                                  <span className="tooltip-icon">+</span>
                                  Create a new item
                                </button>
                                <button
                                  className="add-item-button"
                                  onClick={() =>
                                    handleChooseExistingMenu({
                                      id: _category.id,
                                      name: _category.name,
                                    })
                                  }
                                >
                                  <i className="fal fa-pencil" />
                                  Choose an existing item
                                </button>
                              </div>
                            )}
                            <MyButton
                              className="btn-add-item"
                              type="redColor"
                              onClick={() => {
                                setActiveTooltipId(
                                  activeTooltipId === false ? _i : false
                                );
                                setCategoryId(_category.id);
                              }}
                            >
                              <FaPlus /> Add Item
                            </MyButton>
                          </div>
                        ) : null}
                      </div>
                      {_category?.items?.length > 0 && isEditable ? (
                        <div></div>
                      ) : (
                        <div>
                          {_category?.items?.length > 0 ? (
                            <>
                              {_category?.items?.map((_itemId, index) => {
                                return (
                                  <ItemCard
                                    key={index}
                                    newIndex={index}
                                    food={foods.find(
                                      (_food) => _food.id === _itemId
                                    )}
                                    isDraggable={reorderItems}
                                    onClickDelete={() =>
                                      onClickDeleteItem(_itemId, _category.id)
                                    }
                                    onClickEdit={() =>
                                      onClickEditItem(_itemId, _category.id)
                                    }
                                    setShowFoodItem={(data) => {
                                      setShowFoodItem({
                                        ...data,
                                        categoryID: _category.id,
                                      });
                                    }}
                                    setIsShowItemDrawerOpen={
                                      setIsShowItemDrawerOpen
                                    }
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <div className="opacity-50">
                              There are no items yet.
                            </div>
                          )}
                          {/* {_category?.items?.length > 0 &&
                            _category.items.map((_itemId, index) => {
                              return (
                                <ItemCard
                                  key={index}
                                  newIndex={index}
                                  food={foods.find(
                                    (_food) => _food.id === _itemId
                                  )}
                                  isDraggable={reorderItems}
                                  onClickDelete={() =>
                                    onClickDeleteItem(_itemId, _category.id)
                                  }
                                  onClickEdit={() =>
                                    onClickEditItem(_itemId, _category.id)
                                  }
                                />
                              );
                            })} */}
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </DragDropContext>
        )}
      </div>

      {/* Drawer Menus */}
      <DrawerMenus
        // isActive={!!openDrawers[drawersMenu.menus]}
        // closePanel={() => closeDrawer(drawersMenu.menus)}

        isActive={drawerEditMenus.menus}
        closePanel={() => {
          setDrawerEditMenus({
            ...drawerEditMenus,
            menus: false,
          });
        }}
      />

      {/* Drawer Menu Add */}
      <DrawerMenu
        // isActive={!!openDrawers[drawersMenu.menuAdd]}
        // closePanel={() => closeDrawer(drawersMenu.menuAdd)}

        isActive={drawerMenuAdd.menuAdd}
        closePanel={() =>
          setDrawerMenuAdd({
            ...drawerMenuAdd,
            menuAdd: false,
          })
        }
      />

      {/* Drawer Menu Edit */}
      <DrawerMenu
        type="Edit"
        isActive={menuCloseDrawer.menuEdit}
        closePanel={() =>
          setMenuCloseDrawer({
            ...menuCloseDrawer,
            menuEdit: false,
          })
        }
      />

      {/* Drawer Availabilities */}
      <DrawerAvailabilities
        isActive={!!openDrawers[drawersMenu.availabilities]}
        closePanel={() => closeDrawer(drawersMenu.availabilities)}
      />

      {/* Drawer Availabile Day */}
      {/* <DrawerAvailableDay
        isActive={!!openDrawers[drawersMenu.availableDay]}
        closePanel={() => closeDrawer(drawersMenu.availableDay)}
      /> */}

      {/* Drawer Status Menu */}
      <DrawerStatusMenu
        isActive={!!openDrawers[drawersMenu.statusMenu]}
        closePanel={() => closeDrawer(drawersMenu.statusMenu)}
      />

      {/* Drawer Delete Menu */}
      <DrawerDeleteMenu
        isActive={menuCloseDrawer.deleteMenu}
        closePanel={() =>
          setMenuCloseDrawer({
            ...menuCloseDrawer,
            deleteMenu: false,
          })
        }
      />

      {/* Drawer Delete Confirm */}
      <DrawerDeleteConfirm
        isActive={menuCloseDrawer.deleteConfirm}
        closePanel={() => {
          setMenuCloseDrawer({
            ...menuCloseDrawer,
            deleteConfirm: false,
          });
        }}
        onDone={onDoneDeleteMenu}
      />

      {/* Drawer Move Items */}
      <DrawerMoveItems
        isActive={menuCloseDrawer.moveItems}
        closePanel={() =>
          setMenuCloseDrawer({
            ...menuCloseDrawer,
            moveItems: false,
          })
        }
      />

      {/* Drawer Categories */}
      <DrawerCategories
        isActive={!!openDrawers[drawersMenu.categories]}
        closePanel={() => closeDrawer(drawersMenu.categories)}
      />

      {/* Drawer Add Type */}
      <DrawerAddType
        isActive={chooseCloseDrawer.addType}
        closePanel={() =>
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            addType: false,
          })
        }
      />

      {/* Drawer Delete Item */}
      <DrawerDeleteItem
        isActive={!!openDrawers[drawersMenu.itemDelete]}
        closePanel={() => closeDrawer(drawersMenu.itemDelete)}
        callBack={() => getMenuDetailById()}
      />

      {/* Drawer Food */}
      <DrawerFood
        type="Add"
        isActive={!!openDrawers[drawersMenu.itemAdd]}
        closePanel={() => closeDrawer(drawersMenu.itemAdd)}
      />

      {/* Drawer Food Edit */}
      <DrawerFood
        type="Edit"
        isActive={!!openDrawers[drawersMenu.itemEdit]}
        closePanel={() => closeDrawer(drawersMenu.itemEdit)}
      />

      {/* Drawer Existing foods */}
      <DrawerFoods
        isActive={chooseCloseDrawer.foods}
        closePanel={() =>
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            foods: false,
          })
        }
        // mainClosePanel={() => closeDrawer(drawersMenu.addType)}
      />

      {/* Drawer Status */}
      <DrawerStatusFood
        isActive={!!openDrawers[drawersMenu.statusFood]}
        closePanel={() => closeDrawer(drawersMenu.statusFood)}
      />

      {/* Drawer Status */}
      <DrawerAssignToMenu
        isActive={!!openDrawers[drawersMenu.assignToMenu]}
        closePanel={() => closeDrawer(drawersMenu.assignToMenu)}
      />

      {/* Drawer Item Type */}
      <DrawerItemType
        isActive={!!openDrawers[drawersMenu.itemType]}
        closePanel={() => closeDrawer(drawersMenu.itemType)}
      />

      {/* Drawer Dietary Tags */}
      <DrawerDietaryTags
        isActive={!!openDrawers[drawersMenu.dietaryTags]}
        closePanel={() => closeDrawer(drawersMenu.dietaryTags)}
      />

      {/* Drawer Nutritions */}
      <DrawerNutritions
        isActive={!!openDrawers[drawersMenu.nutritions]}
        closePanel={() => closeDrawer(drawersMenu.nutritions)}
      />

      {/* Drawer Customizations */}
      <DrawerCustomizations
        isActive={!!openDrawers[drawersMenu.customizations]}
        closePanel={() => closeDrawer(drawersMenu.customizations)}
      />

      {/* onClick item show item details  */}
      <DrawerShowItem
        isActive={isShowItemDrawerOpen}
        closePanel={() => {
          setIsShowItemDrawerOpen(!isShowItemDrawerOpen);
        }}
        foodDetails={showFoodItem}
        onClickEdit={() => {
          setIsShowItemDrawerOpen(!isShowItemDrawerOpen);
          onClickEditItem(showFoodItem?.id, showFoodItem?.categoryID);
        }}
      />

      {/* Drawer check exists menu */}
      <DrawerCheckExistsMenu
        isActive={!!openDrawers[drawersMenu.openDrawerCheckExistsMenu]}
        closePanel={() => closeDrawer(drawersMenu.openDrawerCheckExistsMenu)}
      />
    </ProfileMenuContainer>
  );
};
