import React, { Fragment, useEffect, useState, useContext } from "react";
import { StaffRestaurantContainer } from "./style";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import EmptyImage from "assets/img/no-filter-data.png";

export const StaffRestaurant = () => {
  return (
    <StaffRestaurantContainer>
      <div className="promo-codes-tab">
        <Tab.Container
          id=""
          defaultActiveKey={1}
          //   activeKey={promoCodeStatus}
          //   onSelect={handleSelect}
        >
          <Row className="">
            <Col>
              <h3>
                <b>Restaurants</b>
              </h3>
            </Col>
            <Col className="d-flex justify-content-end">
              <Nav variant="pills" className="d-flex">
                <Nav.Item>
                  <Nav.Link eventKey={"1"}>Active: 0</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"2"}>Archived</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>

          {/* tab section */}
          <Row style={{ marginTop: "25px" }}>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey={"1"}>
                  <div>
                    {true &&
                    [{}, {}].filter((el) => el?.status === 1).length > 0 ? (
                      <div className="promo-table">
                        <table>
                          <tbody>
                            {[{}, {}].map((promoCode, index) => {
                              return (
                                <tr key={`staff-${index}`}>
                                  <td>
                                    <div className="name-container">
                                      <span className="staff-avatar-title">
                                        {promoCode?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-container-normal">
                                    <span
                                      style={{
                                        padding: "8px 6px",
                                        background: "#1b1c1d",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      {promoCode?.code}{" "}
                                      <i
                                        class="fas fa-copy"
                                        style={{
                                          //   color:
                                          //     copiedCode === promoCode?.code
                                          //       ? "#fff"
                                          //       : "#767777",
                                          cursor: "pointer",
                                        }}
                                        // onClick={() => {
                                        //   copyToClipboard(promoCode?.code);
                                        // }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-container-normal">
                                    {promoCode?.end_date
                                      ? `${moment(promoCode?.start_date).format(
                                          "ll"
                                        )} - ${moment(
                                          promoCode?.end_date
                                        ).format("ll")}`
                                      : "Not Limited"}
                                  </td>
                                  <td
                                    className="text-container-normal"
                                    style={{
                                      color: "#1fc085",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    {promoCode?.discount_type === 1
                                      ? `${Number(promoCode?.discount).toFixed(
                                          0
                                        )}%`
                                      : `$${promoCode?.discount}`}
                                  </td>
                                  <td style={{ opacity: 1 }}>
                                    <span
                                      style={{
                                        justifyContent: "flex-end",
                                        cursor: "pointer",
                                      }}
                                      //   onClick={() => {
                                      //     updateCouponDetail(promoCode);
                                      //   }}
                                    >
                                      {/* <div className="toggle-btn">
                                        <Switch
                                          checked={
                                            promoCode?.status === 2
                                              ? false
                                              : true
                                          }
                                          onChange={() => {
                                            updateInActivePromoCode(
                                              promoCode,
                                              2
                                            );
                                          }}
                                        />
                                      </div> */}
                                      <i
                                        className="fas fa-cog"
                                        style={{
                                          color: "#767777",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="empty-container">
                        <img src={EmptyImage} alt="" />
                        <div className="empty-screen">No restaurants yet.</div>
                      </div>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={"2"}>
                  <div>
                    {true &&
                    [{}, {}].filter((el) => el.status === 2).length > 0 ? (
                      <div className="promo-table">
                        <table>
                          <tbody>
                            {[{}, {}].map((promoCode, index) => {
                              return (
                                <tr key={`staff-${index}`}>
                                  <td>
                                    <div className="name-container">
                                      <span className="staff-avatar-title">
                                        {promoCode?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-container-normal">
                                    <span
                                      style={{
                                        padding: "8px 6px",
                                        background: "#1b1c1d",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      {promoCode?.code}{" "}
                                      <i
                                        class="fas fa-copy"
                                        style={{
                                          //   color:
                                          //     copiedCode === promoCode?.code
                                          //       ? "#fff"
                                          //       : "#767777",
                                          cursor: "pointer",
                                        }}
                                        // onClick={() => {
                                        //   copyToClipboard(promoCode?.code);
                                        // }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-container-normal">
                                    {promoCode?.end_date
                                      ? `${moment(promoCode?.start_date).format(
                                          "ll"
                                        )} - ${moment(
                                          promoCode?.end_date
                                        ).format("ll")}`
                                      : "Not Limited"}
                                  </td>
                                  <td
                                    className="text-container-normal"
                                    style={{
                                      color: "#1fc085",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    {promoCode?.discount_type === 1
                                      ? `${Number(promoCode?.discount).toFixed(
                                          0
                                        )}%`
                                      : `$${promoCode?.discount}`}
                                  </td>
                                  <td style={{ opacity: 1 }}>
                                    <span
                                      style={{
                                        justifyContent: "flex-end",
                                        cursor: "pointer",
                                      }}
                                      //   onClick={() => {
                                      //     updateCouponDetail(promoCode);
                                      //   }}
                                    >
                                      {/* <div className="toggle-btn">
                                        <Switch
                                          checked={
                                            promoCode?.status === 2
                                              ? false
                                              : true
                                          }
                                          onChange={() => {
                                            updateInActivePromoCode(
                                              promoCode,
                                              1
                                            );
                                          }}
                                        />
                                      </div> */}
                                      <i
                                        className="fas fa-cog"
                                        style={{
                                          color: "#767777",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="empty-container">
                        <img src={EmptyImage} alt="" />
                        <div className="empty-screen">No restaurants yet.</div>
                      </div>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </StaffRestaurantContainer>
  );
};
