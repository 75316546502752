import React, { useContext, useState, useEffect } from "react";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerWallContainer } from "./style";
import { drawersMenu } from "static/staticData";
import { FaTrash } from "react-icons/fa";
import { colors } from "theme";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import Box from "components/Box";
import {
  assignHeightToWall,
  assignWidthToWall,
  getWallType,
  WallShapes,
} from "constant";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerWall({
  type = "Add",
  isActive = false,
  closePanel = () => {},
  setIsDrawerElementOpen = () => {},
}) {
  const { id } = useParams();
  const {
    openDrawer,
    closeDrawer,
    floorId,
    floors,
    postWall,
    setPostWall,
    fetchFloors,
  } = useContext(RestaurantsContext);
  const [shapeType, setShapeType] = useState();

  const [wallAddNotification, setWallAddNotification] = useState();

  useEffect(() => {
    if (wallAddNotification) {
      setTimeout(() => setWallAddNotification(null), 2 * 1000);
    }
  }, [wallAddNotification]);

  useEffect(() => {
    if (type === "Edit") {
      setShapeType(getWallType(postWall.width));
    }
  }, [isActive, type]);

  const onChangeShapeType = (shape) => {
    setShapeType(shape);
    setPostWall({
      ...postWall,
      width: shape === "horizontal" ? 200 : 20,
      height: shape === "horizontal" ? 20 : 200,
    });
  };

  const onDone = async () => {
    const currentFloor = floors.filter((item) => item.id === floorId)[0];
    const newWall = {
      x: Math.random() * 500,
      y: Math.random() * 500,
      width: shapeType === "horizontal" ? 200 : 20,
      height: shapeType === "horizontal" ? 20 : 200,
    };
    if (type === "Add") {
      const updateFloor = {
        ...currentFloor,
        walls: [...(currentFloor.walls || []), newWall],
      };
      try {
        await restaurantServices.updateFloor(id, updateFloor);
        fetchFloors(id);
        closeDrawer(drawersMenu.addWall);
        setIsDrawerElementOpen(false);
        setWallAddNotification({
          message: "New wall added.",
          type: "wall-add",
          table: ``,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log(postWall, "postWall");
      var index = currentFloor.walls.findIndex(
        (item) => item.x === postWall.x && item.y === postWall.y
      );
      currentFloor.walls[index] = postWall;
      try {
        await restaurantServices.updateFloor(id, currentFloor);
        fetchFloors(id);
        closeDrawer(drawersMenu.editWall);
        setIsDrawerElementOpen(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <DrawerWallContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={
        type === "Edit" ? <FaTrash size={18} color={colors.redColor} /> : null
      }
      onClickRightIcon={() => openDrawer(drawersMenu.deleteWall)}
    >
      {wallAddNotification && (
        <MyMessageBoxItemNew
          message={
            wallAddNotification?.message ? wallAddNotification.message : ""
          }
          table={wallAddNotification?.table ? wallAddNotification.table : ""}
          type={
            wallAddNotification?.type ? wallAddNotification.type : "success"
          }
          onClick={() => setWallAddNotification(null)}
        />
      )}

      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">{type} Wall</h2>
        <div className="table-shape-container">
          <p>Choose wall shape</p>
          <div className="shape-container">
            {[WallShapes.vertical, WallShapes.horizontal].map((_shape, i) => (
              <Box
                key={i}
                className={`wall-shape ${
                  shapeType === _shape ? "active" : "inactive"
                }`}
                onClick={() => onChangeShapeType(_shape)}
              >
                <Box
                  width={_shape === WallShapes.horizontal ? 50 : 15}
                  height={_shape === WallShapes.horizontal ? 15 : 50}
                  background="#d8d9da"
                />
              </Box>
            ))}
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={shapeType ? "red" : "gray"}
            className="mt-auto"
            disabled={!shapeType}
            onClick={() => shapeType && onDone()}
          >
            {type} Wall
          </MyDrawerButton>
          <MyDrawerButton type="redColor" onClick={closePanel}>
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerWallContainer>
  );
}
