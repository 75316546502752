import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerSetStatusContainer = styled(MyDrawerCenterModal)`
  .set-status-wrapper {
    /* padding: 36px 26px 38px;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: ${colors.gray5}; */

    .input-area {
      border-bottom: 1px solid #545556;
      padding: 10px 0 10px 0;
      .label-text {
        font-size: 14px;
        color: #fff;
        opacity: 0.5;
      }
      .label-text i {
        font-size: 14px;
        color: #fff;
        opacity: 0.7;
      }
      input,
      textarea {
        background: transparent;
        border: 0;
        color: #fff;
        &:focus {
          outline: none;
        }
      }
      input::placeholder {
        font-size: 14px;
        color: #fff;
        opacity: 0.3;
      }
    }


    .input-note {
      width: 100%;
      background: #27282a;
      margin: 0 0 30px 0;
      border-bottom: 2px solid #3a3a3a;
      border-top: none;
      border-left: none;
      border-right: none;
      color: #fff;
    }

    input:focus-visible {
      outline: unset;
    }

    .label-text {
      opacity: 0.5;
      font-size: 14px;
      color: #fff;
    }

    .set-status-btn {
      margin-top: 30px;
      font-weight: 600 !important;
    }

    .cancel-btn {
      color: #ed2626 !important;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
