import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerAvailableDayContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  /* material-ui switch custom css */
  .MuiIconButton-label {
    color: #fff;
  }

  .MuiSwitch-track {
    background-color: #5f626a;
    opacity: unset;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ed2626 !important;
    opacity: unset;
  }
`;
