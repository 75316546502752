import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { DrawerAddManagerContainer } from "./style";

export default function DrawerAddManager({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postRestaurant, setPostRestaurant } = useContext(RestaurantsContext);

  function handleOnClick(e) {
    e.preventDefault();
    closePanel();
  }

  return (
    <DrawerAddManagerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Edit Manager</div>
          <div></div>
        </div>
        <div className="full-name-input">
          <label className="latel-text">Full Name</label>
          <input
            type="text"
            placeholder="Enter full name"
            value={postRestaurant.manager_name}
            className="custom-input"
            onChange={(e) =>
              setPostRestaurant({
                ...postRestaurant,
                manager_name: e.target.value,
              })
            }
          />
        </div>

        <div className="job-title-wrapper">
          <label className="latel-text">Job Title</label>
          <input
            type="text"
            placeholder="Enter job title"
            value={postRestaurant.manager_title}
            className="custom-input"
            onChange={(e) =>
              setPostRestaurant({
                ...postRestaurant,
                manager_title: e.target.value,
              })
            }
          />
        </div>

        <MyDrawerButton
          type={
            postRestaurant?.manager_name?.length > 0 &&
            postRestaurant?.manager_title?.length > 0
              ? "red"
              : "gray"
          }
          className="text-bold save-btn mb-5"
          onClick={handleOnClick}
          disabled={
            postRestaurant?.manager_name?.length > 0 &&
            postRestaurant?.manager_title?.length > 0
              ? false
              : true
          }
        >
          Save
        </MyDrawerButton>
        <div
          className="text-bold text-center cancel-btn"
          onClick={() => closePanel()}
        >
          Cancel
        </div>
      </DrawerContainer>
    </DrawerAddManagerContainer>
  );
}
