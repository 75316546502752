import React, { useState, useContext, useEffect } from "react";
import { DrawerRearrangeCuisinesModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerContainer } from "components/styledStyle";
import MyButton from "components/Elements/MyButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const RearrangeCuisine = ({ isActive = false, closePanel = () => {} }) => {
  const { updateCuisineDetailsData, cuisineList, setCuisineList } = useContext(
    RestaurantsContext
  );

  const [cuisinesData, setCuisinesData] = useState();
  const [
    deleteRearrangeNotificationMessage,
    setDeleteRearrangeNotificationMessage,
  ] = useState();
  const [rearrangeCuisineName, setRearrangeCuisineName] = useState();

  useEffect(() => {
    if (deleteRearrangeNotificationMessage) {
      setTimeout(() => setDeleteRearrangeNotificationMessage(null), 2 * 1000);
    }
  }, [deleteRearrangeNotificationMessage]);

  function getCuisineData() {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
        setCuisineList(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getCuisineId(cuisinesInfo) {
    if (cuisinesData === cuisinesInfo?.id) {
      setCuisinesData();
      setRearrangeCuisineName();
    } else {
      setCuisinesData(cuisinesInfo?.id);
      setRearrangeCuisineName(cuisinesInfo?.name);
    }
  }

  function rearrangeCuisine() {
    customAxios
      .delete(
        `/cuisine/${updateCuisineDetailsData?.id}?new_cuisine=${cuisinesData}`
      )
      .then((res) => {
        if (res.status === 200) {
          getCuisineData();
          closePanel();
          setDeleteRearrangeNotificationMessage({
            message: `${updateCuisineDetailsData?.name} Cuisine Deleted.`,
            type: "cuisine-inactive",
            table: `Restaurants rearranged to <span style="font-weight:bold; color:#7d7e7f; margin-left:0">${rearrangeCuisineName}</span> cuisine`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <DrawerRearrangeCuisinesModalContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      // top="13%"
      top={cuisineList?.length > 8 ? "10%" : "15%"}
    >
      <DrawerContainer nopadding>
        {deleteRearrangeNotificationMessage && (
          <MyMessageBoxItemNew
            message={
              deleteRearrangeNotificationMessage?.message
                ? deleteRearrangeNotificationMessage?.message
                : ""
            }
            table={
              deleteRearrangeNotificationMessage?.table
                ? deleteRearrangeNotificationMessage?.table
                : ""
            }
            type={
              deleteRearrangeNotificationMessage?.type
                ? deleteRearrangeNotificationMessage?.type
                : ""
            }
            onClick={() => setDeleteRearrangeNotificationMessage(null)}
          />
        )}
        <div className="rearrange-cuisine-modal">
          <div className="rearrange-cuisine-title">Rearrange Cuisine</div>
          <div
            className="rearrange-cuisine-list"
            style={
              cuisineList?.length > 8
                ? { height: "500px" }
                : { height: "unset" }
            }
          >
            {cuisineList &&
              cuisineList.map((_cuisine, index) => {
                return (
                  <React.Fragment key={index}>
                    {updateCuisineDetailsData?.id !== _cuisine?.id ? (
                      <div
                        className="rearrange-cuisine-name cursor"
                        style={
                          cuisinesData === _cuisine?.id
                            ? { border: "2px solid #ed2626" }
                            : { border: "1px solid #5b5e61" }
                        }
                        onClick={() => getCuisineId(_cuisine)}
                      >
                        {_cuisine?.name}
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </div>
          <MyButton
            className="rearrange-cuisine-btn"
            type={cuisinesData ? "red" : "gray-cuisine"}
            onClick={rearrangeCuisine}
            disabled={cuisinesData ? false : true}
          >
            <b>Rearrange Cuisine</b>
          </MyButton>
          <div className="cancel-btn text-bold cursor" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerRearrangeCuisinesModalContainer>
  );
};

export default RearrangeCuisine;
