import styled from "@emotion/styled";

export const AverageSessionContainer = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .average-session-top-container {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .average-session-top-title {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 0px;
    }

    i {
      margin-left: 5px;
      margin-right: 3px;
      font-size: 12px;
      color: #1fc086;
    }

    .growth-percentage {
      font-size: 12px;
      font-weight: bold;
      color: #1fc086;
    }
  }

  .average-time-session {
    font-size: 30px;
    font-weight: 700;
    color: white;
  }
`;
