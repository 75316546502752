import styled from "@emotion/styled";
import { Rating } from "@material-ui/lab";
import { colors } from "theme";

export const MyRatingContainer = styled(Rating)`
  color: ${colors.orange};
  .MuiRating-decimal {
    .MuiRating-iconEmpty {
      color: #37383a;
    }
  }
`;
