import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { restaurantServices } from "services";
import { HighestRatedStaffContainer } from "./style";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import defaultUser from "../../../../assets/img/noAvatar.png";
import { FaEllipsisH, FaStar } from "react-icons/fa";
import { customAxios } from "helpers/custom-helpers/customAxios";

const options = ["today", "7day", "30day"];

export const HighestRatedStaff = ({ staffs }) => {
  const { id } = useParams();
  const [highest, setHighest] = useState();

  const getStaffsHighest = async () => {
    // previous code
    // const result = await restaurantServices.getStaffHighest(id);
    // // console.log(result.data)
    // if (result.status === 200) {
    //   setHighest(result.data);
    // }
    customAxios
      .get(`/staff/highest?restaurant_id=${id}`)
      .then((res) => {
        // console.log(res);
        setHighest(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getStaffsHighest();
  }, [id]);

  //   <div
  //   style={{
  //     width: '120px',
  //     height: '120px',
  //     borderRadius: '60px',
  //     background: '#3e4245',
  //     marginTop: 32,
  //     marginBottom: 24,
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //   }}
  // >
  //   <FaStar color={'#60646c'} size={80} />
  // </div>

  return (
    <HighestRatedStaffContainer>
      <Container fluid className="p-0 m-0">
        <Row style={{ gap: "40px" }}>
          {/* {highest && options.map((_option, index) => {
              return (
                <Col className="staff-highest-card" key={index}>
                  <div className="text-center">
                    <div className="days">
                      {(_option === "today" && "Today") ||
                        (_option === "7day" && "7 Days") ||
                        (_option === "30day" && "30 Days")}
                    </div>
                    <div>
                      <Image
                        src={
                          highest[_option]?.avatar?.file
                            ? highest[_option]?.avatar?.file
                            : defaultUser
                        }
                        alt=""
                        width={70}
                        height={70}
                        className="rounded-circle mt-4"
                      />
                    </div>
                    <div className="mt-4 staff-name">
                      {highest[_option]?.first_name &&
                      highest[_option]?.last_name
                        ? `${highest[_option]?.first_name} ${highest[_option]?.last_name}`
                        : "NA"}
                    </div>
                    <div className="role">
                      {highest[_option]?.role_slug === "waiter"
                        ? "Server / Host"
                        : "NA"}
                    </div>
                    <div className="badge badge-secondary">
                      <span className="text-bold">
                        {highest[_option]?.rating
                          ? <span className="mr-1">{highest[_option]?.rating }</span>
                          : <span className="mr-1">N/A</span>}
                      </span>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                </Col>
              );
            })} */}

          {options &&
            options.map((_option, index) => {
              return (
                <Col className="staff-highest-card" key={index}>
                  <div className="text-center">
                    <div className="days">
                      {(_option === "today" && "Today") ||
                        (_option === "7day" && "7 Days") ||
                        (_option === "30day" && "30 Days")}
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      {/* <Image
                        src={defaultUser}
                        alt=""
                        width={70}
                        height={70}
                        className="rounded-circle avatar"
                      /> */}
                      <div
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "60px",
                          background: "#3e4245",
                          marginTop: 32,
                          marginBottom: 24,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaStar color={"#60646c"} size={80} />
                      </div>
                    </div>

                    {/* <div className="mt-4 staff-name">Bob Martin</div>
                    <div className="role mt-2">Server/Hot</div> */}
                    <div className="mt-4 staff-name">-</div>

                    <div className="d-flex justify-content-center align-content-center">
                      {/* <div>
                        <div className="badge badge-status">
                          <span className="text-bold">Active</span>
                        </div>
                        <div className="badge badge-rating">
                          <i className="fas fa-star" />{" "}
                          <span className="text-bold">4.5</span>
                        </div>
                      </div> */}
                      {/* <div className="icon">
                        <FaEllipsisH color="white" className="cursor" />
                      </div> */}
                      <div className="not-enough mt-2">
                        Not enough data to display this statistic.
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </Container>
    </HighestRatedStaffContainer>
  );
};
