import styled from "@emotion/styled";

export const SessionsContainer = styled.div`
  width: 52%;
  background-color: #1b1c1d;
  border-radius: 8px;
  padding: 23px 22px 35px 37px;
  display: flex;
  flex-direction: column;

  .sessions-top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sessions-top-title {
      font-size: 20px;
      font-weight: bold;
      color: white;
      margin-bottom: 0px;
    }

    .sessions-date {
      position: absolute;
      margin-top: 6rem;
      font-size: 14px;
      font-weight: 500;
    }

    .sessions-period {
      background-color: #27282a;
      border-radius: 30px;
    }
  }

  .sessions-main-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
