import React, { useContext, useEffect, useState } from "react";
import { RestoreModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { OrdersContext } from "context/OrdersContext";

const OrderRestoreReviewModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteEntireOrder = () => {},
  detail,
}) => {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,

    isOrderReviewsEnable,
    setOrderReviewsEnable,

    isRestoreDataEnable,
    setRestoreDataEnable,

    deleteReviewId, 
    setDeleteReviewId,

    restoreReviewId,
  } = useContext(OrdersContext);

  const [restoreNotification, setRestoreNotification] = useState();

  useEffect(() => {
    if (restoreNotification) {
      setTimeout(() => setRestoreNotification(null), 2 * 1000);
    }
  }, [restoreNotification]);

  const restoreReview = () => {
    if (isRestoreDataEnable) {
      setRestoreDataEnable(false);
      closePanel();
    } else {
      setRestoreDataEnable(true);
      setRestoreNotification({
        message: "Review Restored.",
        type: "restore-review",
        table: `<p style="color: #8F949A"><b>Server's (${restoreReviewId?.name})</b> review was restored in <b style="color: #8F949A">${detail?.restaurant?.name}</b>.</p>`,
      });
      closePanel();
    }
    const configData = { hide_review: isRestoreDataEnable };
    if (restoreReviewId?.id) {
      customAxios
        .patch(`/staff/review/${restoreReviewId?.id}`, configData)
        .then((res) => {
          // setReviewRestoreData(res.data)
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <RestoreModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"31%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {restoreNotification && (
          <MyMessageBoxItemNew
            message={
              restoreNotification?.message ? restoreNotification.message : ""
            }
            table={restoreNotification?.table ? restoreNotification.table : ""}
            type={
              restoreNotification?.type ? restoreNotification.type : "success"
            }
            onClick={() => setRestoreNotification(null)}
          />
        )}
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-undo-alt" />
          </div>
          <div className="delete-suborder-title">Restore Review</div>
          <div className="delete-suborder-meta">
            You are about to restore this <b>Review</b>.
            <br />
            This review will affect the overall rating and will be
            <br />
            visible to Feasttt users. Are you sure?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton type="red" onClick={restoreReview}>
              <b>Yes, Restore Review</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-4">
              <b>Keep Removed</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </RestoreModalContainer>
  );
};

export default OrderRestoreReviewModal;
