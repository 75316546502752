import styled from "@emotion/styled";

export const RestaurantsFactsContainer = styled.div`
  display: grid;
  gap: 58px;
  margin-bottom: 58px;
  padding-left: 5rem;

  .restaurant-fact-list-item {
    /* margin: 0px 73px 58px 96px; */
    /* margin: 0px 73px 58px 40px; */
    display: flex;
    flex-direction: column;

    .restaurant-title {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
    }

    .restaurant-value {
      font-size: 30px;
      font-weight: bold;
      color: white;
    }
  }
`;
