import styled from "@emotion/styled";

export const ProfileCardNotesContainer = styled.div`
  .notes-card-header {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    min-height: 70px;
    padding: 0 36px;
  }
  .notes-card-body {
    .notes-card-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      min-height: 85px;
      padding: 14px 32px;
      .notes-card-avatar {
        img {
          border-radius: 50%;
        }
      }
    }
    .no-result {
      margin-top: 7rem;
      img {
        height: 180px;
      }
      h3 {
        margin-top: 20px;
      }
      i {
        width: 70px;
        height: 70px;
        opacity: 0.6;
        font-size: 70px;
        font-weight: 300;
        color: #fff;
        margin-bottom: 27px;
      }
    }
    .note-date {
      display: flex;
      gap: 10px;
      i {
        cursor: pointer;
      }
    }
  }
`;
