import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useEffect, useContext, useState } from "react";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteMenuContainer } from "./style";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerDeleteMenu({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    closeDrawer,
    postMenu,
    menuId,
    menuCloseDrawer,
    setMenuCloseDrawer,
    setFoods,
    selectedMenu,
    setSelectedMenu,
    setMenus,
    setMenuId,
    selectedMenuCategories,
    menus,
  } = useContext(RestaurantsContext);

  const { id } = useParams();

  const [deleteMuneNotification, setDeleteMuneNotification] = useState(null);

  useEffect(() => {
    if (deleteMuneNotification) {
      setTimeout(() => setDeleteMuneNotification(null), 2 * 1000);
    }
  }, [deleteMuneNotification]);

  useEffect(() => {}, [id]);

  // count of all items
  const totalValue = (accumulator, a) => {
    return accumulator + a;
  };
  const length =
    postMenu &&
    postMenu.categories &&
    postMenu.categories.map((item) => {
      return item?.items?.length;
    });
  const totalMenuLength = length?.reduce(totalValue, 0);
  // console.log(totalMenuLength)

  function onConfirmDeleteMenu() {
    customAxios
      .delete(`/restaurant/${id}/menu/${menuId}/move`)
      .then((res) => {
        getRestaurantProfile();
        getMenu();
        // closePanel();
        setMenuCloseDrawer({
          ...menuCloseDrawer,
          menuEdit: false,
          deleteMenu: false,
        });
        setDeleteMuneNotification({
          message: `"${postMenu?.name}" menu deleted.`,
          type: "remove",
          table: ``,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getMenu() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      let menuFirstData = result?.data?.menus[0];
      setMenuId(menuFirstData?.id);
      setFoods(result.data.foods);
      setMenus(result.data.menus);
    }
  };

  function handleDeleteMenuAndItem() {
    // openDrawer(drawersMenu.deleteConfirm);

    // setMenuCloseDrawer({
    //   ...menuCloseDrawer,
    //   deleteConfirm: true,
    // })

    setMenuCloseDrawer({
      ...menuCloseDrawer,
      deleteMenu: false,
      deleteConfirm: true,
    })

    // menus &&
    //   menus.map((menu) => {
    //     console.log(menu);
    //     console.log(menuId);
    //     if (menu?.id === menuId) {
    //       setMenuCloseDrawer({
    //         ...menuCloseDrawer,
    //         deleteMenu: false,
    //         deleteConfirm: true,
    //       });
    //     } else {
    //       console.log("modal");
    //     }
    //   });
  }

  return (
    <DrawerDeleteMenuContainer
      isActive={isActive}
      closePanel={closePanel}
      top="33%"
      zIndexBackground="9999"
    >
      {deleteMuneNotification && (
        <MyMessageBoxItemNew
          message={
            deleteMuneNotification?.message
              ? deleteMuneNotification.message
              : ""
          }
          table={
            deleteMuneNotification?.table ? deleteMuneNotification.table : ""
          }
          type={
            deleteMuneNotification?.type
              ? deleteMuneNotification.type
              : "success"
          }
          onClick={() => setDeleteMuneNotification(null)}
        />
      )}

      <DrawerContainer>
        <h2 className="drawer-title">Delete Menu</h2>
        <p className="menu-name">
          Looks like you have {totalMenuLength} items assigned to this menu.{" "}
          {/* <strong className="text-bold">{postMenu?.name}</strong>? */}
        </p>
        <p className="undone">What would you like to do?</p>
        {totalMenuLength > 0 ? (
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mt-3 mb-4 text-bold"
              // onClick={() => openDrawer(drawersMenu.moveItems)}
              onClick={() => {
                setMenuCloseDrawer({
                  ...menuCloseDrawer,
                  moveItems: true,
                  deleteMenu: false,
                });
              }}
            >
              Move Items
            </MyDrawerButton>
            <MyDrawerButton
              type="gray"
              className="text-bold mt-3"
              onClick={handleDeleteMenuAndItem}
            >
              Delete Menu & Items
            </MyDrawerButton>
            <MyDrawerButton
              type="transparent"
              className="cancel-btn"
              // onClick={() => closeDrawer(drawersMenu.deleteMenu)}
              onClick={() => {
                setMenuCloseDrawer({
                  ...menuCloseDrawer,
                  deleteMenu: false,
                });
              }}
            >
              Cancel
            </MyDrawerButton>
          </div>
        ) : (
          <>
            <MyDrawerButton
              type="red"
              className="text-bold mb-5 mt-4"
              // onClick={() => openDrawer(drawersMenu.deleteConfirm)}
              onClick={onConfirmDeleteMenu}
            >
              Delete
            </MyDrawerButton>
            <MyDrawerButton
              type="gray"
              className="text-bold"
              // onClick={() => openDrawer(drawersMenu.moveItems)}
              onClick={closePanel}
            >
              Keep
            </MyDrawerButton>
          </>
        )}
      </DrawerContainer>
    </DrawerDeleteMenuContainer>
  );
}
