import styled from "@emotion/styled";
// import foodImg from "../../../../assets/img/Carbonara.png";
import foodDefaultImg from "../../../../assets/img/default-food.jpg";

export const ProfileCardRatedContainer = styled.div`
  position: relative;
  padding: 22px 36px;
  /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${foodDefaultImg}); */
  /* background-size: 100% auto; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */

  .favorite-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .favorite-title {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }
    .favorite-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .favorite-title {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
      .favorite-rating {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
`;
