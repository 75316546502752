import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerApplyDiscountAreYouSureContainer = styled(MyDrawerRight)`
  .drawer-bottom-content {
    text-align: left;
    h3 {
      padding: 0 26px 0 26px;
    }
  }
`;
