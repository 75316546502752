import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerFilterOrdersContainer = styled(MyDrawerCenterModal)`
  height: ${(props) => (props.isNoPaymentModalOpen ? "150px" : "max-content")};
  overflow-y: ${(props) => (props.isNoPaymentModalOpen ? "hidden" : "unset")};
  .drawer-content {
    .filter-group {
      margin-bottom: 40px;
      .date-text {
        .date-picker-label {
          font-weight: bold;
        }
      }
      .cards-wrapper {
        display: flex;
        margin: 0 -5px;
        .item {
          margin: 0 5px;
        }
      }
    }
    .fetched-payment-card-list {
      margin-top: 24px;
      gap: 15px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: gainsboro;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      max-height: 400px;
      overflow-y: auto;
      .fetched-card-item {
        padding: 22px 30px 19px 26px;
        border-radius: 16px;
        // border: solid 1px #5b5e61;
        background-color: #27282a;
      }
    }
  }
  .bottom-btns {
    margin-top: 20px !important;
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
