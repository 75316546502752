import React, { useEffect, useState } from "react";
import moment from "moment";
// import { RestaurantsChartContainer } from "./style";
// import ReactApexChart from "react-apexcharts";

import c3 from "c3";
import "c3/c3.css";
import { MyC3ChartContainer } from "./style";

// const graphData = [
//   {
//     "time_stamp": "2023-08-03T00:00:00.000Z",
//     "count": 1
//   },
//   {
//     "time_stamp": "2023-08-06T00:00:00.000Z",
//     "count": 3
//   },
//   {
//     "time_stamp": "2023-08-10T00:00:00.000Z",
//     "count": 4
//   },
//   {
//     "time_stamp": "2023-08-15T00:00:00.000Z",
//     "count": 6
//   },
//   {
//     "time_stamp": "2023-08-28T00:00:00.000Z",
//     "count": 8
//   },
// ]

export const RestaurantsChart = ({
  signUpsData,
  period,
  graphData,
  endDate,
}) => {
  const [xAxis, setXAxis] = useState(["x"]);
  const [yAxis, setYAxis] = useState(["Total sales"]);

  // console.log(period);
  // console.log(graphData);
  // console.log(signUpsData)

  // --Apex--
  // const periodsArr = ["year", "month", "week", "day", "quarter"];

  // const chartOption = {
  //   series: [
  //     {
  //       data: graphData.map(Object.values),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "area",
  //       zoom: {
  //         autoScaleYaxis: true,
  //         enabled: false,
  //       },
  //       toolbar: {
  //         autoSelected: "zoom",
  //         show: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     markers: {
  //       size: 0,
  //       style: "hollow",
  //     },
  //     stroke: {
  //       curve: "straight",
  //     },
  //     xaxis: {
  //       type: "datetime",
  //       tickAmount: 100,
  //       labels: {
  //         format:
  //           period === "year"
  //             ? "MMM yy"
  //             : period === "month"
  //             ? "dd/MM"
  //             : period === "week"
  //             ? "ddd"
  //             : period === "day"
  //             ? "hh:mm TT"
  //             : period === "quarter"
  //             ? "MMM yy"
  //             : "",
  //       },
  //       tooltip: {
  //         enabled: false,
  //       },
  //       axisBorder: {
  //         show: graphData?.length > 0 ? true : false,
  //       },
  //       axisTicks: {
  //         show: graphData?.length > 0 ? true : false,
  //       },
  //     },
  //     yaxis: {
  //       opposite: true,
  //       tickAmount: graphData?.length,
  //       labels: {
  //         show: graphData?.length > 0 ? true : false,
  //         formatter: function(val) {
  //           return val.toFixed(0);
  //         },
  //       },
  //     },
  //     tooltip: {
  //       x: {
  //         format: "dd MMM yyyy",
  //       },
  //       fixed: {
  //         enabled: false,
  //       },
  //       custom: function({ series, seriesIndex, dataPointIndex, w }) {
  //         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  //         const monthData =
  //           period === "month"
  //             ? moment(data[0]).format("DD/MM")
  //             : period === "week"
  //             ? moment(data[0]).format("ddd,HH:MM A")
  //             : period === "day"
  //             ? moment(data[0]).format("HH:MM A")
  //             : period === "year"
  //             ? moment(data[0]).format("MMM YYYY")
  //             : period === "quarter"
  //             ? moment(data[0]).format("MMM DD YYYY")
  //             : "";
  //         return (
  //           '<div class="arrow_box">' +
  //           "<span>" +
  //           monthData +
  //           "</span>" +
  //           "<span>" +
  //           `${
  //             periodsArr.indexOf(period) > -1
  //               ? `${series[seriesIndex][dataPointIndex]} Sign Ups`
  //               : ""
  //           }` +
  //           "</span>" +
  //           "</div>"
  //         );
  //       },
  //     },
  //     grid: {
  //       show: false,
  //       borderColor: "#232628",
  //       strokeDashArray: 0,
  //       position: "back",
  //     },
  //     colors: ["#33D4A5"],
  //     noData: {
  //       text: "No data available",
  //       align: "center",
  //       verticalAlign: "middle",
  //       offsetX: 0,
  //       offsetY: 0,
  //       style: {
  //         fontSize: "24px",
  //         color: "#fff",
  //       },
  //     },
  //   },
  // };

  function formatCompactNumber(number) {
    if (number < 1000) {
      return number;
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
  }

  useEffect(() => {
    if (graphData && graphData?.length > 0) {
      const x = graphData.map((xGraph) => moment.utc(xGraph.time_stamp));

      let y = [];
      if (graphData[0].count) {
        y = graphData.map((yGraph) => yGraph?.count);
      }

      if (
        period === "week" ||
        period === "month" ||
        period === "quarter" ||
        period === "Custom"
      ) {
        var chosen_start_date = x[0]; //changed from x[1] to x[0], chosen start of date range
        var chosen_end_date =
          period === "Custom" ? new Date(endDate) : new Date(); //chosen end of date range

        var new_dates = [];
        var new_values = [];
        let date;
        let date_obj;
        var prev_real_date = x[0];
        var following = chosen_start_date;
        for (var i = 0; i < x.length; i++) {
          date = x[i];
          date_obj = new Date(date);

          if (!moment(date_obj).isSame(following, "day")) {
            // Following on from an earlier real date value we need to add a zero
            new_dates.push(moment(following));
            new_values.push("0");
          }

          let earlier = new Date(date_obj);
          earlier.setDate(date_obj.getDate() - 1);

          if (
            !moment(prev_real_date).isSame(earlier, "day") &&
            !moment(following).isSame(earlier, "day")
          ) {
            // Before this real date value we need to add a zero
            new_dates.push(moment(earlier));
            new_values.push("0");
          }

          following = new Date(date_obj);
          following.setDate(date_obj.getDate() + 1);

          //Push this real date value
          new_dates.push(moment(date));
          new_values.push(y[i]);

          prev_real_date = date_obj;
        }
        if (moment(chosen_end_date).isAfter(date_obj)) {
          // Add final zeros to take us to the end date
          new_dates.push(moment(following));
          new_values.push("0");
          // if (moment(chosen_end_date).isAfter(following)) {
          //   new_dates.push(moment(chosen_end_date));
          //   new_values.push("0");
          // }
        }
        setXAxis(["x", ...new_dates]);
        setYAxis(["", ...new_values]);
      } else if (period === "year") {
        setXAxis(["x", ...x]);
        setYAxis(["", ...y]);
      } else if (period === "day") {
        var chosen_start_date = moment()
          .set("hour", 8)
          .set("minute", 0); //chosen start of date range
        console.log(chosen_start_date);
        var chosen_end_date = moment()
          .set("hour", 20)
          .set("minute", 0); //chosen end of date range
        const times = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        var new_dates = [chosen_start_date];
        var new_values = [];
        let date;
        let date_obj;
        var prev_real_date = x[0];
        var following = chosen_start_date;
        for (var i = 0; i <= 6; i++) {
          date = new_dates[i];
          date_obj = new Date(date);
          new_dates.push(moment(date_obj).add(2, "hours"));
          new_values.push("0");

          //Push this real date value
          if (moment(date_obj).isSame(prev_real_date, "hour")) {
            new_values.push(y[i]);
          }
        }

        setXAxis(["x", ...new_dates]);
        setYAxis(["", ...new_values]);
      }
    } else {
      setXAxis(["x", new Date()]);
      setYAxis(["", "0"]);
    }
  }, [period, graphData]);

  useEffect(() => {
    c3.generate({
      bindto: "#chart",
      data: {
        x: "x",
        columns: [xAxis, yAxis],
        type: "area",
      },
      zoom: {
        enabled: true,
        type: "scroll",
        rescale: true,
        extent: [1, 5],
      },
      point: {
        show: true,
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            // fit: true,
            // culling: false,
            culling: {
              max: 10,
            },
            // outer: false,
            format: "%Y-%m-%d",
            // format: '%Y-%m-%d %H:%M:%S'
            format: function(value, timestamp, opts) {
              const monthData =
                period === "month"
                  ? moment(value).format("MMM DD")
                  : period === "quarter"
                  ? moment(value).format("DD/MM")
                  : period === "week"
                  ? moment(value).format("ddd")
                  : period === "day"
                  ? moment(value).format("h A")
                  : period === "Custom"
                  ? moment(value).format("MMM")
                  : moment(value).format("MMM");
              return monthData;
            },
          },
        },
        y: {
          // outer: true,
          tick: {
            count: 8,
            format: function(y) {
              return period && formatCompactNumber(Math.round(y));
            },
          },
        },
      },
      tooltip: {
        contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
          const data = d[0];
          const val = data?.value;
          const date = data?.x;
          const monthData =
            period === "month"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "quarter"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "week"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "day"
              ? `Today, ${moment(date).format("dddd, MMM DD, YYYY")}`
              : period === "Custom"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : moment(date).format("dddd, MMM DD, YYYY");
          return (
            '<div class="arrow_box">' +
            '<span class="month_name">' +
            "<b>" +
            `${monthData}` +
            "</b>" +
            "</span>" +
            '<span class="value">' +
            "<b>" +
            `${`${val} Sign Ups`}` +
            "</b>" +
            "</span>" +
            "</div>"
          );
        },
        grouped: true,
      },
      size: {
        // width: 700,
        // height: 300,
      },
      color: {
        pattern: ["#1FC086"],
      },
      padding: {
        top: 20,
      },
      transition: {
        duration: 100,
      },
      // grid: {
      //   y: {
      //     show: true,
      //   },
      // },
    });
  }, [xAxis, yAxis]);

  // return (
  //   <RestaurantsChartContainer>
  //     <ReactApexChart
  //       options={chartOption.options}
  //       series={chartOption.series}
  //       height={400}
  //       type="area"
  //     />
  //   </RestaurantsChartContainer>
  // );

  return graphData?.length ? (
    <MyC3ChartContainer>
      <div id="chart" />
    </MyC3ChartContainer>
  ) : (
    <div
      className="no-data"
      style={{
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '78%'
      }}
    >
      <p style={{ fontSize: "24px" }}>No data available</p>
    </div>
  );
};
