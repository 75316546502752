import React, { useContext } from "react";
import { ActiveOederActionsModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { drawersRestaurants } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";

const RestaurantEditMoreModal = ({
  isActive = false,
  closePanel = () => {},
  setBanRestaurantModal = () => {},
  status,
}) => {
  const { openDrawer, setIsDeleteRestaurantModalOpen, restaurant } = useContext(
    RestaurantsContext
  );

  return (
    <ActiveOederActionsModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="active-order-actions">
          <div
            className="clickable-row"
            onClick={() => {
              setBanRestaurantModal(true);
              // onClickPartitialRefund();
              // closePanel();
            }}
          >
            {restaurant?.status === 9 ? "Unban Restaurant" : "Ban Restaurant"}
          </div>
          <div
            className="clickable-row"
            onClick={() => {
              // openDrawer(drawersRestaurants.drawerRestaurantDeleteScreen);
              setIsDeleteRestaurantModalOpen(true);
              closePanel();
            }}
          >
            Delete Restaurant
          </div>
          <MyDrawerButton
            className="active-order-action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </ActiveOederActionsModalContainer>
  );
};

export default RestaurantEditMoreModal;
