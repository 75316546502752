import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerChangePasswordContainer = styled(MyDrawerRight)`
  .drawer-title {
    font-size: 18px;
  }
  .change-password-panel {
    .panel-content-body {
      .password-inputs {
        margin-top: 50px;
      }
      .password-conditions {
        margin-top: 60px;
        .password-conditions-field {
          color: rgba(255, 255, 255, 0.5);
          font-size: 14px;
          margin-bottom: 6px;
          &.active {
            color: #fff;
          }

          i {
            margin-right: 7px;
          }
        }
      }
      .password-toggle {
        float: right;
        padding: 20px 0px;
        cursor: pointer;

        .password-toggle-text {
          font-size: 12px;
          font-weight: bold;
          margin-right: 8px;
          font-size: 14px;
        }
      }
      .forgot-password {
        margin-top: 35px;
        margin-bottom: 20px;
        .restore-pass-text {
          font-size: 14px;
        }
        .restore-pass {
          color: #ed2626;
          font-weight: bold;
          margin-left: 7px;
          cursor: pointer;
          font-size: 14px;

          i {
            color: #ed2626;
          }
        }
      }
    }
  }

  .bottom-btns {
    .cancel-btn {
      color: ${colors.redColor};
      font-weight: 600;
      text-align: center;
      margin-top: 33px;
      cursor: pointer;
    }
  }
`;
