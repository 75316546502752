import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerDeleteReviewContainer = styled(MyDrawerRight)`
  .drawer-bottom-content {
    flex: 1;
    text-align: center;
    h5 {
      margin-bottom: 33px;
    }
    .selected-card-details {
      padding: 22px 30px 19px 26px;
      border-radius: 16px;
      background-color: #1b1c1d;
      margin-bottom: 32px;
    }
    .add-edit-tip-container {
      margin-bottom: 42px;
    }
    .order-total-div {
      margin-bottom: 16px;
      text-align-center;
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
