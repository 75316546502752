import styled from "@emotion/styled";
import { colors } from "theme";

export const OrderDetailPageContainer = styled.div`
  .order-page-header {
    font-family: Montserrat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0px 25px 0px;
    margin: 0 20px 0 5px;
    /* border-bottom: 1px solid #4d5053; */
    .left-part {
      flex: 1 1 50%;
      padding-left: 40px;
      .nav-line-to-back {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .backlink-to-rest {
        color: ${colors.white};
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
      .arrow-icon-container {
        i {
          color: white;
          font-size: 20px;
        }
      }
      .current-order {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
    }
  }

  .order-page-body {
    padding: 0 40px;
    .order-part {
      .order-detail-card {
        min-height: 50px;
        background-color: ${colors.gray2};
        margin-bottom: 25px;
        border-radius: 20px;
        padding: 16px;
      }
    }
    .orders-details-tab {
      .nav {
        background: #1b1c1d;
        border-radius: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 37px;
      }

      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #ed2626;
        width: 29rem;
        text-align: center;
        border-radius: 30px;
      }

      a.nav-link {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: 600;
        width: 29rem;
        text-align: center !important;
      }

      .nav.nav-pills .show > .nav-link,
      .nav.nav-pills .nav-link.hover {
        color: unset;
      }

      nav .show > .nav-link,
      .nav .nav-link:hover:not(.disabled),
      .nav .nav-link.active {
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
      }

      .nav.nav-pills .nav-link {
        color: #ffffff7d !important;
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
        position: relative;
        font-weight: bold;

        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          color: #fff !important;
        }
      }
    }
    .empty-request-pane {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 180px;
      img {
        width: 352px;
        height: 200px;
      }
      .empty-request-pane-text-div {
        margin-top: 24px;
        .empty-request-pane-text {
          opacity: 0.6;
          font-family: Montserrat;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }
    }
  }

  .delete-suborder-actions {
    button {
      width: 100%;
    }
    .apply-btn {
      margin-bottom: 15px;
    }
  }
`;
