import React, { useState, useEffect } from "react";
// import { CustomerSignUpsChartContainer } from "./style";
// import {
//   ResponsiveContainer,
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
// } from "recharts";
// import { ChartTooltip } from "../../../ChartTooltip";
import moment from "moment";
import c3 from "c3";
import "c3/c3.css";
import { MyC3ChartContainer } from "./style";

// const graphData = [
//   {
//     time_stamp: "2023-08-03T00:00:00.000Z",
//     count: 1,
//   },
//   {
//     time_stamp: "2023-08-06T00:00:00.000Z",
//     count: 3,
//   },
//   {
//     time_stamp: "2023-08-10T00:00:00.000Z",
//     count: 4,
//   },
//   {
//     time_stamp: "2023-08-15T00:00:00.000Z",
//     count: 6,
//   },
//   {
//     time_stamp: "2023-08-28T00:00:00.000Z",
//     count: 8,
//   },
// ];

export const CustomerSignUpsChart = ({
  signUpsData,
  period,
  graphData,
  endDate,
}) => {
  // const CustomTooltip = ({ active, payload, label }) => {
  //   let filterData =
  //     graphData &&
  //     payload &&
  //     payload[0] &&
  //     graphData.length &&
  //     graphData.filter(
  //       (el) =>
  //         payload[0]?.payload?.name === moment(el?.time_stamp).format("LT")
  //     );
  //   if (active && payload && payload.length) {
  //     return (
  //       <ChartTooltip
  //         title={`${getPerioText(period)} , ${moment(
  //           filterData[0]?.time_stamp
  //         ).format("MMMM DD LT")}`}
  //         importantThing={`${payload[0]?.payload?.signUps} Sign Ups`}
  //       />
  //     );
  //   }

  //   return null;
  // };

  // const getPerioText = (period) => {
  //   switch (period) {
  //     case "day":
  //       return "Today";
  //     case "week":
  //       return "Week";
  //     case "month":
  //       return "Month";
  //     case "quarter":
  //       return "Quarter";
  //     case "year":
  //       return "Year";
  //     default:
  //       return null;
  //   }
  // };

  // return (
  //   <CustomerSignUpsChartContainer>
  //     <ResponsiveContainer width="100%" height="100%">
  //       <AreaChart data={signUpsData}>
  //         <defs>
  //           <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
  //             <stop offset="40%" stopColor="#39d9ae" stopOpacity={0.3} />
  //             <stop offset="95%" stopColor="#42e1bb" stopOpacity={0.01} />
  //           </linearGradient>
  //         </defs>
  //         <XAxis dataKey="name" axisLine={false} />
  //         <YAxis
  //           dataKey="signUps"
  //           orientation="right"
  //           axisLine={false}
  //           tickCount={6}
  //         />
  //         <Tooltip content={<CustomTooltip />} cursor={false} />
  //         <CartesianGrid
  //           vertical={false}
  //           stroke="#28292a"
  //           strokeDasharray="20 20 5"
  //         />
  //         <Area
  //           type="linear"
  //           dataKey="signUps"
  //           stroke="#1fc086"
  //           strokeWidth={6}
  //           dot={{ stroke: "#1fc086", fill: "#1fc086" }}
  //           activeDot={{ stroke: "#1fc086", fill: "#1fc086" }}
  //           fillOpacity={1}
  //           fill="url(#colorUv)"
  //         />
  //       </AreaChart>
  //     </ResponsiveContainer>
  //   </CustomerSignUpsChartContainer>
  // );

  // c3.js graph

  // const graphData = [
  //   {
  //     count: 16,
  //     time_stamp : "2023-05-22T00:00:00.000Z"
  //   }
  // ]

  const [xAxis, setXAxis] = useState(["x"]);
  const [yAxis, setYAxis] = useState(["Total sales"]);

  function formatCompactNumber(number) {
    if (number < 1000) {
      return number;
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
  }

  useEffect(() => {
    if (graphData && graphData?.length > 0) {
      const x = graphData.map((xGraph) => moment.utc(xGraph.time_stamp));

      let y = [];
      if (graphData[0].count) {
        y = graphData.map((yGraph) => yGraph?.count);
      }

      if (
        period === "week" ||
        period === "month" ||
        period === "quarter" ||
        period === "Custom"
      ) {
        var chosen_start_date = x[0]; // changed from x[1] to x[0], chosen start of date range
        var chosen_end_date =
          period === "Custom" ? new Date(endDate) : new Date(); //chosen end of date range

        var new_dates = [];
        var new_values = [];
        let date;
        let date_obj;
        var prev_real_date = x[0];
        var following = chosen_start_date;
        for (var i = 0; i < x.length; i++) {
          date = x[i];
          date_obj = new Date(date);

          if (!moment(date_obj).isSame(following, "day")) {
            // Following on from an earlier real date value we need to add a zero
            new_dates.push(moment(following));
            new_values.push("0");
          }

          let earlier = new Date(date_obj);
          earlier.setDate(date_obj.getDate() - 1);

          if (
            !moment(prev_real_date).isSame(earlier, "day") &&
            !moment(following).isSame(earlier, "day")
          ) {
            // Before this real date value we need to add a zero
            new_dates.push(moment(earlier));
            new_values.push("0");
          }

          following = new Date(date_obj);
          following.setDate(date_obj.getDate() + 1);

          //Push this real date value
          new_dates.push(moment(date));
          new_values.push(y[i]);

          prev_real_date = date_obj;
        }
        if (moment(chosen_end_date).isAfter(date_obj)) {
          // Add final zeros to take us to the end date
          new_dates.push(moment(following));
          new_values.push("0");
          // if (moment(chosen_end_date).isAfter(following)) {
          //   new_dates.push(moment(chosen_end_date));
          //   new_values.push("0");
          // }
        }
        setXAxis(["x", ...new_dates]);
        setYAxis(["", ...new_values]);
      } else if (period === "year") {
        setXAxis(["x", ...x]);
        setYAxis(["", ...y]);
      } else if (period === "day") {
        var chosen_start_date = moment()
          .set("hour", 8)
          .set("minute", 0); //chosen start of date range
        console.log(chosen_start_date);
        var chosen_end_date = moment()
          .set("hour", 20)
          .set("minute", 0); //chosen end of date range
        const times = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        var new_dates = [chosen_start_date];
        var new_values = [];
        let date;
        let date_obj;
        var prev_real_date = x[0];
        var following = chosen_start_date;
        for (var i = 0; i <= 6; i++) {
          date = new_dates[i];
          date_obj = new Date(date);
          new_dates.push(moment(date_obj).add(2, "hours"));
          new_values.push("0");

          //Push this real date value
          if (moment(date_obj).isSame(prev_real_date, "hour")) {
            new_values.push(y[i]);
          }
        }

        setXAxis(["x", ...new_dates]);
        setYAxis(["", ...new_values]);
      }
    } else {
      setXAxis(["x", new Date()]);
      setYAxis(["", "0"]);
    }
  }, [period, graphData]);

  useEffect(() => {
    c3.generate({
      bindto: "#chart",
      data: {
        x: "x",
        columns: [xAxis, yAxis],
        type: "area",
      },
      zoom: {
        enabled: true,
        type: "scroll",
        rescale: true,
        extent: [1, 5],
      },
      point: {
        show: true,
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            // fit: true,
            // culling: false,
            culling: {
              max: 10,
            },
            // outer: false,
            format: "%Y-%m-%d",
            // format: '%Y-%m-%d %H:%M:%S'
            format: function(value, timestamp, opts) {
              const monthData =
                period === "month"
                  ? moment(value).format("MMM DD")
                  : period === "quarter"
                  ? moment(value).format("DD/MM")
                  : period === "week"
                  ? moment(value).format("ddd")
                  : period === "day"
                  ? moment(value).format("h A")
                  : period === "Custom"
                  ? moment(value).format("MMM")
                  : moment(value).format("MMM");
              return monthData;
            },
          },
        },
        y: {
          // outer: true,
          tick: {
            count: 8,
            format: function(y) {
              return period && formatCompactNumber(Math.round(y));
            },
          },
        },
      },
      tooltip: {
        contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
          const data = d[0];
          const val = data?.value;
          const date = data?.x;
          const monthData =
            period === "month"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "quarter"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "week"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "day"
              ? `Today, ${moment(date).format("dddd, MMM DD, YYYY")}`
              : period === "Custom"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : moment(date).format("dddd, MMM DD, YYYY");
          return (
            '<div class="arrow_box">' +
            '<span class="month_name">' +
            "<b>" +
            `${monthData}` +
            "</b>" +
            "</span>" +
            '<span class="value">' +
            "<b>" +
            `${`${val} Sign Ups`}` +
            "</b>" +
            "</span>" +
            "</div>"
          );
        },
        grouped: true,
      },
      size: {
        // width: 700,
        // height: 300,
      },
      color: {
        pattern: ["#1FC086"],
      },
      padding: {
        top: 20,
      },
      transition: {
        duration: 100,
      },
      // grid: {
      //   y: {
      //     show: true,
      //   },
      // },
    });
  }, [xAxis, yAxis]);

  return graphData?.length ? (
    <MyC3ChartContainer>
      <div id="chart" />
    </MyC3ChartContainer>
  ) : (
    <div
      className="no-data"
      style={{
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "78%",
      }}
    >
      <p style={{ fontSize: "24px" }}>No data available</p>
    </div>
  );
};
