import React, { useState, useEffect, useContext } from "react";
import { ItemRemoveReviewsModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawersOverall } from "static/staticData";

const ItemRemoveReviewsModal = ({
  isActive = false,
  closePanel = () => {},
  //   onDeleteEntireOrder = () => {},
}) => {
  const { openDrawer, openDrawers, closeDrawer } = useContext(
    RestaurantsContext
  );
  const [itemReviewNotification, setItemReviewNotification] = useState("");

  useEffect(() => {
    if (itemReviewNotification) {
      setTimeout(() => setItemReviewNotification(null), 2 * 1000);
    }
  }, [itemReviewNotification]);

  //@TODO item remove review notificatoin
    // useEffect(() => {
    //   setItemReviewNotification({
    //     message: "Review Removed.",
    //     type: "remove",
    //     table: `<p style="color: #8F949A">Item review was removed.</p>`,
    //   });
    // }, []);

  return (
    <ItemRemoveReviewsModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {itemReviewNotification && (
          <MyMessageBoxItemNew
            message={
              itemReviewNotification?.message
                ? itemReviewNotification.message
                : ""
            }
            table={
              itemReviewNotification?.table ? itemReviewNotification.table : ""
            }
            type={
              itemReviewNotification?.type
                ? itemReviewNotification.type
                : "success"
            }
            onClick={() => setItemReviewNotification(null)}
          />
        )}

        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Remove Review</div>
          <div className="delete-suborder-meta">
            You are about to remove this{" "}
            <span className="text-bold">Review</span>.
            <br /> This review will affect the overall rating and will not be{" "}
            <br /> visible to Feasttt users anymore. Are you sure?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              //   onClick={() => {
              //     onDeleteEntireOrder();
              //   }}
            >
              <b>Yes, Remove Review</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-3">
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ItemRemoveReviewsModalContainer>
  );
};

export default ItemRemoveReviewsModal;
