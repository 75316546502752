import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerSortContainer = styled(MyDrawerBottom)`
  .reviews-sort-wrapper {
    padding: 30px !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .sort-options {
      .option {
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 2px solid rgb(237, 38, 38); */
        border: 1px solid rgba(255, 255, 255, 0.08);
        padding: 13px 0 12px 0 !important;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 14px;
        height: 52px;
        cursor: pointer;
      }
    }

    .cancel-btn {
      text-align: center;
      margin-top: 30px;
      color: #ed2626;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;
