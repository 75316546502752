import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyDateRange from "components/Elements/MyDateRange";
import { DrawerContainer } from "components/styledStyle";
import React, { useState } from "react";
import { DrawerDateCustomerContainer } from "./style";

export default function DrawerDateCustomer({
  isActive = false,
  closePanel = () => {},
  onSelectDateRange = () => {},
}) {
  const [dateRange, setDaterange] = useState();

  const onChangeDate = (date) => {
    setDaterange(date);
  };

  const onCancelDate = () => {
    setDaterange("");
    onSelectDateRange("");
  };
  return (
    <DrawerDateCustomerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <MyDateRange onChangeDate={onChangeDate} />
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            onClick={() => onSelectDateRange(dateRange)}
          >
            Select
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            onClick={() => {
              onCancelDate();
            }}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDateCustomerContainer>
  );
}
