import React, { useState, useEffect } from "react";
import { colors } from "theme";
import { MyDateRangeContainer } from "./style";

export default function MyDateRange({
  className = "",
  months = 1,
  onChangeDate = () => {},
  date,
}) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (date && date.startDate && date.endDate)
      setState([
        {
          startDate: new Date(date.startDate),
          endDate: new Date(date.endDate),
          key: "selection",
        },
      ]);
  }, [date]);

  return (
    <MyDateRangeContainer
      className={`my-datepicker ${className}`}
      editableDateInputs={true}
      showDateDisplay={false}
      onChange={(item) => {
        setState([item.selection]);
        onChangeDate(item.selection);
      }}
      direction="horizontal"
      months={months}
      moveRangeOnFirstSelection={false}
      rangeColors={[colors.redColor, colors.green]}
      ranges={state}
    />
  );
}
