import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const LogoutModalContainer = styled(MyDrawerCenterModal)`
  .enter-new-number {
    position: relative;
    &:before {
      content: "";
      width: 80px;
      height: 80px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -101px;
      left: 170px;
    }
    .modal-image {
      position: absolute;
      left: 14rem;
      top: -7rem;
      .round-circle {
        width: 57px;
        height: 57px;
        border: solid 5px #ed2626;
        background-color: #ed2626;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 29px;
        }
      }
    }
    .modal-title-new-number {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      // margin-top: 31px;
    }
    .modal-title-new-number-meta {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-top: 16px;
      margin-bottom: 24px;
    }
    .new-number-action-btns {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;
