import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useState, useEffect, useContext } from "react";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteConfirmContainer } from "./style";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";

export default function DrawerDeleteConfirm({
  profile = {},
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
}) {
  const {
    closeDrawer,
    postMenu,
    menuId,
    menuCloseDrawer,
    setMenuCloseDrawer,
    setFoods,
    setSelectedMenu,
    selectedMenu,
    menus,
    drawerEditMenus,
    setDrawerEditMenus,
    fetchRestaurant,
  } = useContext(RestaurantsContext);

  // const onConfirmDeleteMenu = async () => {
  //   console.log({ postMenu });
  //   await onDone();
  // };

  const { id } = useParams();

  useEffect(() => {}, [id]);

  function onConfirmDeleteMenu() {
    customAxios
      .delete(`/restaurant/${id}/menu/${menuId}/move`)
      .then((res) => {
        getMenu();
        getRestaurantProfile();
        // closePanel();
        setMenuCloseDrawer({
          ...menuCloseDrawer,
          menuEdit: false,
          deleteMenu: false,
          moveItems: false,
          deleteConfirm: false,
        });
        // setDrawerEditMenus({
        //   ...drawerEditMenus,
        //   menus: false,
        // });
        fetchRestaurant(id);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getMenu() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
    }
  };

  // count of all items
  const totalValue = (accumulator, a) => {
    return accumulator + a;
  };
  const length =
    postMenu &&
    postMenu.categories &&
    postMenu.categories.map((item) => {
      return item?.items?.length;
    });
  const totalMenuLength = length?.reduce(totalValue, 0);

  return (
    <DrawerDeleteConfirmContainer
      isActive={isActive}
      closePanel={closePanel}
      top="33%"
      zIndexBackground="9999"
    >
      <DrawerContainer>
        <div className="delete-menu-wrapper">
          <h2 className="drawer-title text-white">Are you sure?</h2>
          <div className="para-content">
            <p>
              The menu <strong>{postMenu?.name}</strong> and {totalMenuLength}{" "}
              items under it will be deleted.
            </p>
            <p>
              Any items assigned to multiple menus or categories <br /> will not
              be affected.
            </p>
            <p>This cannot be undone.</p>
          </div>
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mb-4 text-bold"
              onClick={onConfirmDeleteMenu}
            >
              Delete Menu & items
            </MyDrawerButton>
            {/* <MyDrawerButton
              type="gray"
              className="text-bold"
              onClick={() => {
                setMenuCloseDrawer({
                  ...menuCloseDrawer,
                  deleteConfirm: false,
                });
              }}
            >
              Delete
            </MyDrawerButton> */}
            <div
              className="cancel-btn"
              onClick={() => {
                setMenuCloseDrawer({
                  ...menuCloseDrawer,
                  deleteConfirm: false,
                });
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteConfirmContainer>
  );
}
