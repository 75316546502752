import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const EmailOtpSentModalContainer = styled(MyDrawerCenterModal)`
  .enter-new-email {
    position: relative;
    &:before {
      content: "";
      width: 80px;
      height: 80px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -93px;
      left: 164px;
    }
    .modal-image {
      position: absolute;
      left: 13rem;
      top: -7rem;
      .round-circle {
        width: 70px;
        height: 70px;
        border: solid 5px #27282a;
        background-color: rgba(255, 255, 255, 0.08);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 33px;
          height: 42px;
          margin-bottom: 9px;
        }
      }
    }
    .modal-title-new-email {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      // margin-top: 31px;
    }
    .email-info-meta {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-top: 16px;
      b {
        font-weight: bold;
      }
    }
    .email-info-meta-sub {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #7d7e7f;
      margin-top: 8px;
      margin-bottom: 12px;
    }
    .did-not-get-it-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .did-not-get-it-title {
        font-size: 16px;
        font-weight: 500;
        color: white;
        letter-spacing: 0;
        margin-right: 7px;
      }

      .btn-resend {
        cursor: pointer;
        .resend-title {
          font-size: 16px;
          font-weight: bold;

          &.active {
            color: ${colors.redColor};
          }

          &.inactive {
            color: ${colors.gray5};
          }
        }
      }
    }
    .verify-actions {
      text-align: center;
      margin-top: 24px;
      button {
        width: -webkit-fill-available;
      }
    }
    .new-email-action-btns {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  }
`;
