import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerDeleteRestaurantContainer = styled(MyDrawerCenterModal)`
  .set-status-wrapper {
    /* padding: 36px 26px 38px;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: ${colors.gray5}; */
    
    text-align: center;

    .delete-restaurant-undone {
    }

    .bottom-btns {
      .keep-btn {
        color: #fff !important;
        font-weight: 600;
      }
      .delete-btn {
        color: #fff !important;
        font-weight: 600;
      }
    }

    .modal-image {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 83%;
      img {
        width: 112px;
        height: 112px;
      }
    }
  }
`;
