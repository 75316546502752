import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerReviewServerContainer = styled(MyDrawerRight)`
  .header-bg {
    position: relative;
    // min-height: 348px;
    min-height: 305px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .close-drawer-icon {
      position: absolute;
      left: 23px;
      top: 23px;
    }
    .bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(5px);
      transform: scale(2);
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 30px;
      bottom: 0;
      background-color: #1b1c1d;
      border-radius: 30px 30px 0 0;
      z-index: 1;
    }
    .drawer-header-content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 343px;
        height: 220px;
        border-radius: 8px;
        position: absolute;
        top: -68px;
      }
    }
  }
  .reviews-body {
    padding: 0 26px 0 26px;
    flex: 1;
    .food-tags {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      flex-wrap: wrap;
      .food-tags-item {
        padding: 5px 6px;
        border-radius: 14px;
        background-color: #333437;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.31px;
        text-align: center;
        color: #fff;
      }
    }
    .food-name {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.58px;
      text-align: center;
      color: #fff;
      margin-top: 26px;
    }
    .diet-info {
      margin-top: 45px;
      margin-bottom: 19px;
      display: flex;
      .diet-info-border-right {
        border-right: 1px solid #4d5053;
      }
      .diet-info-item {
        width: 114px;
        text-align: center;

        .diet-count {
          font-family: Montserrat;
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.48px;
          text-align: center;
          color: #fff;
          margin-bottom: 3px;
        }
        .diet-type {
          opacity: 0.4;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.31px;
          text-align: center;
          color: #fff;
        }
      }
    }
    .neutrition-div {
      .fa-info-circle {
        color: #ed2626;
      }
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.34px;
      text-align: center;
      color: #ed2626;
    }
    .rating-customization-card {
      padding: 15px 16px;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.08);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-div-one {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        .fa-star {
          color: #ffd35f;
        }
      }
      .card-div-two {
        opacity: 0.8;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        text-align: right;
        color: #fff;
        .fa-chevron-right {
          margin-left: 17px;
        }
      }
    }
    .increment-decrement-div {
      margin-top: 42px;
      .click-btn-div {
        width: 60px;
        height: 60px;
        background-color: #333437;
        border-radius: 50%;
        color: #fff;
        padding: 23px 2px 2px 1px;
      }
      .item-count {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .add-to-order-div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: right;
    color: #fff;
    .total-food-price {
      position: relative;
      left: 77px;
    }
  }
`;
