import styled from "@emotion/styled";
import { colors } from "theme";

export const CustomerItemContainer = styled.div`
  &.customers-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    &:nth-of-type(even) {
      background-color: #171819;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .item-flex-small {
      flex: 0 0 8%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
    .item-flex-normal {
      flex: 0 0 13%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
    .item-flex-big {
      flex: 0 0 22%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
    .item-flex-last {
      flex: 1 1 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      text-align: end;
    }
    .banned-status {
      width: fit-content;
      padding: 6px 10px;
      border-radius: 10px;
      background-color: #27282a;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ed2626 !important;
      i {
        color: #ed2626 !important;
      }
      margin-left: -14px;
    }
    .inactive-status {
      width: fit-content;
      padding: 6px 10px;
      border-radius: 10px;
      background-color: #27282a;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #a6a6a6 !important;
      i {
        color: #a6a6a6 !important;
        font-weight: normal !important;
      }
      margin-left: -14px;
    }
    .active-status {
      width: fit-content;
      padding: 6px 10px;
      border-radius: 10px;
      background-color: #27282a;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1fc086 !important;
      margin-left: -8px;
    }
    .item-icon {
      position: relative;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.5s ease;
        opacity: 1;
        -moz-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        -webkit-transition: opacity 0.3s linear;
        transition: opacity 0.3s linear;
        opacity: 0.5;
      }
      .fa-check {
        position: absolute;
        font-size: 18px;
        color: #fff;
        left: 18px;
        top: 16px;
        font-weight: 900;
      }
    }
    .item-icon:hover {
      img {
        opacity: 0.4;
      }
    }
    .item-name {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      font-weight: bold;
    }
    .item-status {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-weight: bold;
    }

    .item-staff {
      text-align: end;
    }

    .center {
      padding-left: 30px;
    }

    &.inner-selected {
      .item-icon {
        position: relative;
        img {
          border: 2px solid #fff;
          opacity: 1;
        }

        .fa-check {
          z-index: 97;
        }

        &:after {
          content: "";
          position: absolute;
          display: block;
          top: 2px;
          left: 3px;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.6);
        }
      }

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 4px;
        top: 0;
        left: 0;
        background-color: #60646c;
      }
    }

    &.selected-restaurant {
      .item-icon {
        img {
          opacity: 1;
        }
      }

      .item-name {
        color: white;
        font-weight: bold;
      }

      .item-flex-small {
        color: white;
      }
      .item-flex-normal {
        color: white;
      }
      .item-flex-big {
        color: white;
      }
      .item-flex-last {
        color: white;
      }
    }
  }
  &.customers-item:hover {
    .item-icon {
      img {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }
`;
