import React, { useContext, useEffect, useState } from "react";
import { ChangeRoleToKitchenStaffModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import EmptyStaff from "assets/img/noAvatar.png";
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyButton from "components/Elements/MyButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

const ChangeRoleToKitchenStaffModal = ({
  isActive = false,
  closePanel = () => {},
  getStaffAll = () => {},
  // onDeleteEntireOrder = () => {},
}) => {
  const { id } = useParams();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    staffViewData,
    setStaffs,
    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,
  } = useContext(RestaurantsContext);

  const [
    changeRoleToKitchenNotification,
    setChangeRoleToKitchenNotification,
  ] = useState();

  const [
    errorChangeRoleToKitchenNotification,
    setErrorChangeRoleToKitchenNotification,
  ] = useState();

  useEffect(() => {
    if (changeRoleToKitchenNotification) {
      setTimeout(() => setChangeRoleToKitchenNotification(null), 2 * 1000);
    }
  }, [changeRoleToKitchenNotification]);

  useEffect(() => {
    if (errorChangeRoleToKitchenNotification) {
      setTimeout(() => setErrorChangeRoleToKitchenNotification(null), 2 * 1000);
    }
  }, [errorChangeRoleToKitchenNotification]);

  // role_slug: "kitchen_staff",

  const onClickRoleChangeToKitchen = () => {
    const roleConfig = {
      role_slug:
        (staffViewData?.role_slug === "kitchen_staff" && "waiter") ||
        (staffViewData?.role_slug === "waiter" && "kitchen_staff") ||
        (staffViewData?.role_slug === "manager" && "waiter"),
    };
    customAxios
      .put(`/staff/${staffViewData?.id}?restaurant_id=${id}`, roleConfig)
      .then((res) => {
        if (res.status === 200) {
          setChangeRoleToKitchenNotification({
            message: "Role Changed.",
            type: "changed-role-to-kitchen",
            table: `<p style="color: #8F949A"><b> ${staffViewData?.first_name} ${staffViewData?.last_name} </b> role has been successfully changed to the <b>Kitchen Staff.</b>.</p>`,
          });
          closePanel();
          setStaffChangeRoleMainModal({
            ...staffChangeRoleMainModal,
            staffServerHostActiveModal: false,
          });
          getStaffAll();
        } else {
          setErrorChangeRoleToKitchenNotification({
            message: "Oops! Something went wrong.",
            type: "error",
            table: `<p style="color: #8F949A"><b> ${staffViewData?.first_name} ${staffViewData?.last_name} </b> role has not been changed. Please try again.</p>`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <ChangeRoleToKitchenStaffModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {changeRoleToKitchenNotification && (
          <MyMessageBoxItemNew
            message={
              changeRoleToKitchenNotification?.message
                ? changeRoleToKitchenNotification.message
                : ""
            }
            table={
              changeRoleToKitchenNotification?.table
                ? changeRoleToKitchenNotification.table
                : ""
            }
            type={
              changeRoleToKitchenNotification?.type
                ? changeRoleToKitchenNotification.type
                : "success"
            }
            onClick={() => setChangeRoleToKitchenNotification(null)}
          />
        )}

        {errorChangeRoleToKitchenNotification && (
          <MyMessageBoxItemNew
            message={
              errorChangeRoleToKitchenNotification?.message
                ? errorChangeRoleToKitchenNotification.message
                : ""
            }
            table={
              errorChangeRoleToKitchenNotification?.table
                ? errorChangeRoleToKitchenNotification.table
                : ""
            }
            type={
              errorChangeRoleToKitchenNotification?.type
                ? errorChangeRoleToKitchenNotification.type
                : "success"
            }
            onClick={() => setErrorChangeRoleToKitchenNotification(null)}
          />
        )}

        <div className="server-host-active-modal">
          <div className="staff-profile">
            <i className="fas fa-question" />
          </div>
          <div className="staff-title">Are you sure?</div>
          <div className="staff-option">
            <p>
              Are you sure you want to change{" "}
              <b>
                {staffViewData?.first_name === undefined &&
                staffViewData?.last_name === undefined
                  ? "No Name"
                  : `${staffViewData?.first_name} ${staffViewData?.last_name}`}
              </b>{" "}
              <br />{" "}
              <b>
                {(staffViewData?.role_slug === "kitchen_staff" &&
                  "(Kitchen Staff)") ||
                  (staffViewData?.role_slug === "waiter" && "Server/Host") ||
                  (staffViewData?.role_slug === "manager" && "Manager")}
              </b>{" "}
              role to the{" "}
              <b>
                {(staffViewData?.role_slug === "kitchen_staff" &&
                  "(Server/Host)") ||
                  (staffViewData?.role_slug === "waiter" &&
                    "(Kitchen Staff)") ||
                  (staffViewData?.role_slug === "manager" && "(Server/Host)")}
              </b>
              ?
            </p>

            <p>It will change permissions for this user. </p>
          </div>
          <div className="actions">
            <MyButton
              className="change-role-btn"
              type="red"
              onClick={
                onClickRoleChangeToKitchen
              }
            >
              Yes, Change Role
            </MyButton>
            <div className="cancel-btn" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </ChangeRoleToKitchenStaffModalContainer>
  );
};

export default ChangeRoleToKitchenStaffModal;
