/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { colors } from "theme";
import { MyDrawerCeneterModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { OrdersContext } from "context/OrdersContext";

export function MyDrawerCenterModal({
  children,
  className = "",
  isActive = false,
  // renderLeftIcon = <FaTimes size={18} color={colors.white3} />,
  // renderRightIcon = null,
  // onClickLeftIcon = () => {},
  // onClickRightIcon = () => {},
  closePanel = () => {},
  containerClassName = "",
  // top = "8rem",
  // bottom = "6rem",
  width = "450px",

  left = "40%",
  top = "10%",
  // zIndex = 9999,
  zIndex = 99999999,
  position = "fixed",
  height = "max-content",
  zIndexBackground = 999,
  transform,
}) {
  const { openDrawers } = useContext(RestaurantsContext);
  const { openDrawers: openDrawersOrders } = useContext(OrdersContext);

  useEffect(() => {
    if (
      Object.keys(openDrawers).length > 0 ||
      Object.keys(openDrawersOrders).length > 0 ||
      isActive
    ) {
      document.body.style.overflow = "hidden";
    } else {
      if (!document.querySelector(".drawer-opend")) {
        document.body.style.overflow = "unset";
      }
    }
  }, [openDrawers, openDrawersOrders, isActive]);

  return (
    <MyDrawerCeneterModalContainer>
      <div
        className={`offcanvas ${containerClassName}`}
        style={
          isActive
            ? {
                position: position,
                // left: 0,
                // right: 0,
                // marginLeft: "auto",
                // marginRight: "auto",
                // marginTop: top,
                // marginBottom: bottom,
                borderRadius: "26px",
                width: width,

                left: left,
                height: height,
                top: top,
                zIndex: zIndex,
                transform: transform ? transform : "",
              }
            : {}
        }
      >
        <div className={`drawer-content-wrapper ${className}`}>{children}</div>
      </div>
      <div
        className={`offcanvas-overlay`}
        style={
          isActive
            ? { background: "rgb(0 0 0 / 59%)", zIndex: zIndexBackground }
            : { display: "none" }
        }
        onClick={closePanel}
      ></div>
    </MyDrawerCeneterModalContainer>
  );
}
