import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerDeleteMenuContainer = styled(MyDrawerCenterModal)`
  text-align: center !important;

  .drawer-title {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;
  }

  .menu-name {
    margin-top: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
  }

  .undone {
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
  }

  .keep-btn {
    margin-top: 10px;
  }

  .bottom-btns {
    .cancel-btn {
    color: #ed2626 !important;
    font-weight: 600;
    margin-top: 20px;
    font-size: 14px;
  }
  }

  
`;
