import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
// import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { colors } from "theme";

export const DrawerMoveCategoriesItemContainer = styled(MyDrawerCenterModal)`
  .keep-btn {
    margin-top: 10px;
  }

  .delete-btn {
    margin-top: 20px;
  }

  .cancel-btn {
    color: #ed2626 !important;
    font-weight: 600;
    margin-top: 20px;
  }

  .categories-list {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 15px;
    overflow: auto;
    height: 300px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.gray5};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.white};
      border-radius: 5px;
    }
    .cat-item {
      padding: 21px 34px 21px 35px;
      border-radius: 16px;
      background-color: rgba(21, 22, 23, 0);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.34px;
      text-align: center;
      color: #fff;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }
`;
