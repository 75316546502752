import styled from "@emotion/styled";
import { Dropdown } from "react-bootstrap";

export const RestaurantTableFloorSelectContainer = styled(Dropdown)`
  &.show {
    .dropdown-toggle {
      background-color: transparent !important;
      border-color: #333437 !important;
    }
  }
  .dropdown-toggle {
    border-radius: 25px;
    /* border: solid 1px #525558; */
    border: solid 1px #333437;
    background-color: transparent;
    font-size: 14px;
    font-weight: normal;
    padding: 0 30px;
    height: 40px;
    &:hover,
    &:focus,
    &:active {
      /* background-color: transparent !important; */
      /* border-color: #fff !important; */
      background-color: #333437 !important;
      border-color: #333437 !important;
    }
  }
  .dropdown-menu {
    padding: 0;
    &.show {
      border-radius: 25px;
      border: solid 1px #333437;
      /* background-color: #151617; */
      background-color: #333437;
      min-width: 75px;
      width: 100%;
      a:first-of-type {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      a:last-child {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      a {
        color: #fff;
        p {
          margin-bottom: 0;
        }
      }
      a:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  #dropdown-basic {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 15rem;
    /* background: #151617; */
    background-color: #333437; 
    border: solid 1px #333437;
    height: 37px;
    padding: 0 12px 0 12px;
  }

  .dropdown-toggle::after {
    display: none;
  }
`;
