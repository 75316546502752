import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerInviteStaffContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .content-wrapper {
    margin-bottom: 36px;
    span {
      font-size: 14px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .staff-phone-number {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 6px 0;

    div {
      width: 100%;
    }

    .before-input-title {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.34px;
      color: white;
      margin-right: 6px;
      transform: translateY(1px);
    }

    .value-input {
      width: 100%;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: white;
      padding-left: 0px;
      background-color: transparent;
      border-color: transparent;
      outline: none;
      border-left-width: 0px;
      border-right-width: 0px;
    }

    .single-ant-format-input {
      border-radius: 0px;
      border-color: transparent;
      background-color: transparent;
      padding-left: 0px;
      height: 28px;
      color: #fff;
      &:hover {
        border-color: transparent;
      }
      &:focus {
        border-color: transparent;
      }
    }
  }
`;
