import React from "react";
import PropTypes from "prop-types";
import SingleText from "components/SingleText";
import { MyPromoCodeInputContainer } from "./style";
import { AiFillCheckCircle } from "react-icons/ai";
import { colors } from "theme";

export default function MyPromoCodeInput({
  placeholder = "Ex: Caesar Salad",
  className = "",
  label = "",
  prefix = null,
  suffix = null,
  value = "",
  onChange = () => {},
  onPressEnter = () => {},
  onBlur = () => {},
  profile = false,
  verified = false,
  taxes = false,
  ...props
}) {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onPressEnter();
    }
  };

  return (
    <MyPromoCodeInputContainer
      display="flex"
      className={className}
      flexDirection="column"
      borderBottom="solid 1px rgba(255, 255, 255, 0.1)"
      marginBottom="10px"
    >
      {profile ? (
        <>
          <div>
            {verified ? (
              <AiFillCheckCircle
                size={15}
                color={colors.green}
                style={{ marginRight: "5px" }}
              />
            ) : (
              <AiFillCheckCircle
                size={15}
                color={colors.gray3}
                style={{ marginRight: "5px" }}
              />
            )}
            {label && (
              <SingleText
                size={14}
                opacity={0.3}
                lineHeight={1.57}
                spacing="-0.34px"
              >
                {label}
              </SingleText>
            )}
          </div>
          <span
            className="text-input-container"
            style={{ padding: profile && 0 }}
          >
            {prefix && <span className="input-prefix">{prefix}</span>}
            <input
              style={{ paddingLeft: 0 }}
              type="text"
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={handleKeyDown}
              {...props}
            />
          </span>
        </>
      ) : (
        <>
          {label && (
            <SingleText
              size={14}
              opacity={0.3}
              lineHeight={1.57}
              spacing="-0.34px"
            >
              {label}
            </SingleText>
          )}
          <span
            className="text-input-container"
            style={{ padding: taxes && 0 }}
          >
            {prefix && <span className="input-prefix">{prefix}</span>}
            <input
              style={{ paddingLeft: 0 }}
              type="text"
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={handleKeyDown}
              maxLength={taxes ? 3 : 100}
              {...props}
            />
            {suffix && <span className="input-prefix">{suffix}</span>}
          </span>
          {/* <Input 
        placeholder={placeholder} 
        prefix={prefix} 
        bordered={false} 
        style={{paddingLeft: 0}} 
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
      /> */}
        </>
      )}
    </MyPromoCodeInputContainer>
  );
}

MyPromoCodeInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
  taxes: PropTypes.bool,
};
