import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerItemContainer = styled(MyDrawerBottom)`
  .nutrition-wrapper {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 30px !important;
      padding-bottom: 20px !important;
      .close-icon {
        .fa-times {
          color: red;
          cursor: pointer;
          font-size: 23px;
        }
      }
    }

    .description {
      padding-left: 15px !important;
      padding-right: 15px !important;
      opacity: 0.3;
      color: #fff;
      font-size: 12px;
    }
  }
`;
