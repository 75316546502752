import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { drawersOrder } from "static/staticData";
import { DrawerReviewServerContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import { FaStar, FaTimes } from "react-icons/fa";
import { DrawerSortReview } from "../DrawerSortReview";
import NoItem from "assets/img/no-items-yet.png";
import DrawerDeleteReview from "../DrawerDeleteReview";
import DrawerReviewDeleted from "../DrawerReviewDeleted";
import { customAxios } from "helpers/custom-helpers/customAxios";
import ReviewList from "./ReviewList";
import MyDropDown from "components/Elements/MyDropDown";
import moment from "moment";
import { NoAvatar } from "assets/img";
import { colors } from "theme";

let sortArray = [
  {
    title: "Earliest",
    value: "earliest",
  },
  {
    title: "Oldest",
    value: "oldest",
  },
  {
    title: "Highest Rated",
    value: "highest_rated",
  },
  {
    title: "Lowest Rated",
    value: "lowest_rated",
  },
];

export default function DrawerOrderFoodItemReviews({
  isActive = false,
  closePanel = () => {},
  detail = {},
  selectedMenuInfo = {},
}) {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    setOrderNotification,
  } = useContext(OrdersContext);
  const [selectedSort, setSelectedSort] = useState("");
  const [itemReviewData, setSelectedItemReviewData] = useState("");
  const [selectedReview, setSelectedReview] = useState();

  useEffect(() => {
    if (detail && selectedMenuInfo) {
      customAxios
        .get(
          `/orders/${detail?.token}/food/${selectedMenuInfo?.food_item_id}/reviews`
        )
        .then((res) => {
          if (res.status === 200) {
            setSelectedItemReviewData(res.data);
          } else {
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [detail, selectedMenuInfo]);

  const onClickSortItem = (sortItem) => {
    setSelectedSort(sortItem);
    let data = [...itemReviewData.reviews];
    let sortedArray = [];
    if (sortItem?.value === "earliest") {
      sortedArray = data.sort(
        (a, b) => new Date(a.createdat) - new Date(b.createdat)
      );
      setSelectedItemReviewData({ ...itemReviewData, reviews: sortedArray });
    } else if (sortItem?.value === "oldest") {
      sortedArray = data.sort(
        (a, b) => new Date(b.createdat) - new Date(a.createdat)
      );
      setSelectedItemReviewData({ ...itemReviewData, reviews: sortedArray });
    } else if (sortItem?.value === "highest_rated") {
      sortedArray = data.sort((a, b) => b.rating - a.rating);
      setSelectedItemReviewData({ ...itemReviewData, reviews: sortedArray });
    } else if (sortItem?.value === "lowest_rated") {
      sortedArray = data.sort((a, b) => a.rating - b.rating);
      setSelectedItemReviewData({ ...itemReviewData, reviews: sortedArray });
    } else {
    }
    closeDrawer(drawersOrder.sortFoodReview);
  };

  const onClickDeleteReview = (reviewData) => {
    closePanel();
    openDrawer(drawersOrder.deleteItemReview);
  };

  const onDeleteReview = () => {
    customAxios
      .delete(`/orders/food/reviews/${selectedReview?.id}`)
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(
              `/orders/${detail?.token}/food/${selectedMenuInfo?.food_item_id}/reviews`
            )
            .then((res) => {
              if (res.status === 200) {
                setSelectedItemReviewData(res.data);
                setOrderNotification({
                  message: "Review Removed.",
                  type: "remove",
                  table: `Server’s ${detail?.server?.name} review was removed from ${detail.restaurant.name}.`,
                });
              } else {
              }
            })
            .catch((err) => {
              console.error(err);
            });
          // openDrawer(drawersOrder.deletedReview);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <DrawerReviewServerContainer isActive={isActive} closePanel={closePanel}>
      {/* <DrawerContainer className="p-0">
        <div className="header-bg">
          <div className="d-flex  align-items-center close-drawer-icon">
            <FaTimes
              size={20}
              style={{ zIndex: "9999999" }}
              className="cursor-pointer "
              onClick={closePanel}
            />
            <div className="drawer-title">Reviews</div>
          </div>

          <Image
            src={selectedMenuInfo?.image || defaultFood}
            className="bg-img"
          />
          <div className="drawer-header-content">
            <img src={selectedMenuInfo?.image || defaultFood} />
            <div className="food-name">{selectedMenuInfo?.name || ""}</div>
          </div>
        </div>
        <div className="reviews-body">
          {itemReviewData && itemReviewData?.reviews?.length ? (
            <div className="r-header">
              <div className="reviews-body-header d-flex justify-content-between align-items-center">
                <div className="reviews-overall-rating">
                  <i className="fas fa-star" /> {itemReviewData?.average || 0}
                </div>
                <div
                  className="sort-dropdown cursor-pointer"
                  onClick={() => {
                    openDrawer(drawersOrder.sortFoodReview);
                  }}
                >
                  {selectedSort ? selectedSort.title : "Sort"}
                  <i className="fas fa-angle-down" />
                </div>
              </div>
              <div className="sort-overall-review-count">
                {itemReviewData?.reviews?.length}{" "}
                <span className="sort-overall-title">reviews</span>
              </div>
            </div>
          ) : (
            <div className="empty-header">
              <div className="sort-overall-review-count">
                0 <span className="sort-overall-title">reviews</span>
              </div>
            </div>
          )}
          {itemReviewData && itemReviewData?.reviews?.length ? (
            <div className="d-flex flex-column review-list">
              {itemReviewData?.reviews &&
                itemReviewData?.reviews?.map((el, _i) => (
                  <ReviewList
                    el={el}
                    key={_i}
                    selectedReview={selectedReview}
                    setSelectedReview={setSelectedReview}
                    onClickDeleteReview={onClickDeleteReview}
                  />
                ))}
            </div>
          ) : (
            <div className="empty-item-image">
              <img src={NoItem} alt="" />
            </div>
          )}
        </div>
      </DrawerContainer> */}

      <DrawerContainer className="p-0">
        <div className="header-bg">
          <div className="d-flex  align-items-center close-drawer-icon">
            {/* <FaTimes
              size={20}
              style={{ zIndex: "9999999" }}
              className="cursor-pointer "
              onClick={closePanel}
            /> */}
            <i
              className="far fa-angle-left cursor-pointer"
              onClick={closePanel}
              style={{ fontSize: "30px", color: colors.white }}
            />
            <div className="drawer-title">Reviews</div>
          </div>
          <Image src={selectedMenuInfo?.image || defaultFood} className="bg-img" />
          <div className="drawer-header-content-review-food">
            <img src={selectedMenuInfo?.image || defaultFood} />
            <div className="food-name">{selectedMenuInfo.name}</div>
            <div className="rating">
              <FaStar color={colors.yellow} size={18} className="mr-2" />{" "}
              <b>{itemReviewData?.average || ' --'}</b>
            </div>
          </div>
        </div>
        <div className="reviews-body">
          {/* reviewData && reviewData?.reviews && reviewData?.reviews?.length */}
          {true ? (
            <div className="r-header">
              <div className="sort-overall-review-count">
                {itemReviewData?.reviews?.length || 0} 
                <span className="sort-overall-title">&nbsp; reviews</span>
              </div>

              {/* <div className="reviews-body-header d-flex justify-content-between align-items-center">
                <div
                  className="sort-dropdown cursor-pointer"
                  onClick={() => {
                    setIsSortReviewDrawerShowing(!isSortReviewDrawerShowing);
                  }}
                >
                  {selectedSort ? selectedSort.title : "Sort"}
                  <i className="fas fa-angle-down" />
                </div>
              </div> */}

              <div className="reviews-body-header d-flex justify-content-between align-items-center">
                <MyDropDown
                  className="menu-header-select"
                  options={[
                    "Earliest",
                    "Oldest",
                    "Highest Rated",
                    "Lowest Rated",
                  ].map((item) => {
                    return { label: item, value: item };
                  })}
                  // value={{ label: staffSort, value: staffSort }}
                  // onChange={(selectedSortType) => setItemSort(selectedSortType)}
                  width={115}
                  iconClosed={<i className="fas fa-angle-down" />}
                  placeholder="Sort"
                />
              </div>
            </div>
          ) : (
            <div className="empty-header">
              <div className="sort-overall-review-count">
                0 <span className="sort-overall-title">reviews</span>
              </div>
            </div>
          )}

          {/* reviewData && reviewData?.reviews && reviewData?.reviews?.length */}
          {itemReviewData && itemReviewData?.reviews?.length ? (
            <div className="d-flex flex-column review-list">
              {itemReviewData?.reviews?.map((el, _i) => {
                return (
                  <div className="review-list-item" key={_i}>
                    <div className="profile-info d-flex justify-content-between alignitems-center">
                      <div className="d-flex align-items-center image-div">
                        <img
                          src={el?.image || NoAvatar}
                          alt=""
                          style={{ borderRadius: "50%" }}
                        />
                        <div className="name-time-div">
                          <div className="review-added-user-name">
                            {/* {el?.author?.first_name} {el?.author?.last_name} */}
                            {el.name}
                          </div>
                          <div className="feast-text">
                            {/* <b>1</b> Feasts Here • <b>4</b> Feasts Total */}
                            {moment(el?.createdat)
                                  .startOf("day")
                                  .fromNow()}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ position: "relative", bottom: "15px" }}
                      >
                        <div>
                          <FaStar
                            className="text-orange mx-1 mr-3"
                            style={{ fontSize: "18px", marginBottom: "4px" }}
                          />
                        </div>
                        <div>
                          <b>{el?.rating ? el?.rating : "- -"}</b>
                        </div>
                      </div>
                    </div>

                    <div className="review-meta">{el?.comment}</div>

                    <div className="feastt-review-numericals">
                      <div className="feastt-review-numericals-item show-more cursor-pointer">
                        {/* Show more */}
                      </div>
                      <div className="feastt-review-numericals-item date">
                        <span className="mr-2">
                          {moment(el?.date).format("MMM D YYYY")}
                        </span>{" "}
                        <i
                          className="fas fa-trash-alt cursor-pointer"
                          onClick={() =>
                            openDrawer(drawersOrder.removeReviewModalScreen)
                          }
                        />
                        {/* <i
                          className="fas fa-trash-undo-alt cursor-pointer"
                          onClick={() =>
                            openDrawer(drawersOrder.RestoreReviewModalScreen)
                          }
                        /> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="empty-item-image">
              <img src={NoItem} alt="" />
              <p className="empty-review">No reviews yet</p>
            </div>
          )}
        </div>
      </DrawerContainer>

      <DrawerSortReview
        isActive={!!openDrawers[drawersOrder.sortFoodReview]}
        closePanel={() => closeDrawer(drawersOrder.sortFoodReview)}
        sortArray={sortArray}
        onClickSortItem={onClickSortItem}
      />
      <DrawerDeleteReview
        isActive={!!openDrawers[drawersOrder.deleteItemReview]}
        closePanel={() => closeDrawer(drawersOrder.deleteItemReview)}
        onDeleteReview={onDeleteReview}
      />
      <DrawerReviewDeleted
        isActive={!!openDrawers[drawersOrder.deletedReview]}
        closePanel={() => closeDrawer(drawersOrder.deletedReview)}
      />
    </DrawerReviewServerContainer>
  );
}
