import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { DrawerFilterOrdersContainer, Label } from "./style";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DatePicker from "components/Elements/DrawerElements/DatePicker";
import { OrdersContext } from "context/OrdersContext";
import { drawersOrder } from "static/staticData";
import { StyledStatusItem } from "theme/styles";
import { FaChevronLeft } from "react-icons/fa";
import { restaurantServices } from "services";
import { Link } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import CustomizationList from "./CustomizationList";

export default function DrawerFoodCustomization({
  isActive = false,
  closePanel = () => {},
  foodDetails,
  onClickDone = () => {},
}) {
  const { openDrawer } = useContext(OrdersContext);

  const [
    itemProfileCustomizationData,
    setItemProfileCustomizationData,
  ] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (foodDetails) {
      customAxios
        .get(`/restaurant/${id}/food/${foodDetails?.id}/customization`)
        .then((res) => {
          console.log("custom profile", res?.data);
          if (res?.status === 200) {
            setItemProfileCustomizationData(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [foodDetails]);

  const [isDressingOpened, setIsDressingOpend] = useState(false);
  const [dressingList, setDressingList] = useState([
    {
      title: "Ranch",
      value: "ranch",
      isSelected: true,
    },
    {
      title: "French",
      value: "french",
      isSelected: false,
    },
    {
      title: "Italian",
      value: "italian",
      isSelected: false,
    },
  ]);
  const [price, setPrice] = useState(0);

  return (
    <DrawerFilterOrdersContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div
          className="top-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <FaChevronLeft
              size={20}
              className="cursor-pointer"
              onClick={closePanel}
            />
          </div>
          <div></div>
        </div>
        <div className="drawer-content" style={{ marginTop: "35px" }}>
          <div className="drawer-header">
            <Label>{foodDetails?.title}</Label>
          </div>
          {itemProfileCustomizationData &&
            itemProfileCustomizationData.map((el) => {
              return <CustomizationList customizationDetails={el} />;
            })}
        </div>
        <div className="bottom-btns"></div>
      </DrawerContainer>
    </DrawerFilterOrdersContainer>
  );
}
