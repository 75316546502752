import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const PendingOrderActionsModalContainer = styled(MyDrawerCenterModal)`
  .pending-order-actions {
    .clickable-row {
      border-radius: 16px;
      border: solid 1px #5b5e61;
      background-color: #27282a;
      font-size: 14px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
      margin-top: 16px;
      cursor: pointer;
    }
    .pending-order-action-cancel {
      width: 100%;
    }
    .cancel-btn {
      color: ${colors.redColor};
      text-align: center;
      margin-top: 30px;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;
