import { DefaultMenu } from "assets/img";
import React, { useEffect, useRef, useState, useContext } from "react";
import { MoveableStyled, ContextMenuContainer } from "./style";
import { restaurantServices } from "services";
import { RestaurantsContext } from "context/RestaurantsContext";
import { useParams } from "react-router-dom";
import { useOutsideClick } from "services/functions";
const tables = [
  {
    floor_id: 17,
    guests: 0,
    id: 122,
    is_available: true,
    restaurant_id: 8,
    rotation: 0,
    seats: 1,
    status: 1,
    table_number: 14,
    type: 0,
    x: 185,
    y: 98,
  },
  {
    floor_id: 17,
    guests: 0,
    id: 121,
    is_available: true,
    restaurant_id: 8,
    rotation: 0,
    seats: 1,
    status: 1,
    table_number: 13,
    type: 0,
    x: 185,
    y: 169,
  },
];

// ***IMPORTANT NOTE***
// This component has extra code, that can be useful in future (like: rectangle elements for tables, horizontal / vertical positions etc...). For now, these part of code doesn't use anywhere. In case, if it will not be used, these part of codes can be refactored.

const CreateSeats = (props) => {
  const { shape, seats, position, width, height, backgroundColor } = props;
  if (shape === "circle" || shape === "square") {
    let rotation;
    if (shape === "circle") rotation = 360 / seats;
    if (shape === "square") rotation = 90;

    const isRectangle =
      (width === 48 || width === 103) &&
      (height === 48 || height === 103) &&
      shape === "square";
    // const rectangleVertical = seats <=8 ? [-25, -2, 21, 44, -25, -2, 21, 44 ] : [-25, -2, 21, 44, 60, 70, -25, -2, 21, 44, 60, 70 ] ;

    const rectangleVertical = [
      -35,
      -15,
      5,
      24,
      41,
      58,
      -35,
      -15,
      5,
      24,
      41,
      58,
    ];

    // const rectangleVertical = [ -25, -2, 21, 44, 67, 90 -25, -2, 21, 44, 67, 90 ];
    const rectangleHorizontal =
      seats <= 6
        ? [-48, -26, -4, 18, 38, -68]
        : [-38, -18, 0, 18, 36, -58, -38, -18, 0, 18, 36, -58];

    return (
      <>
        {[...Array(seats)].map((e, i) => {
          return (
            <div
              // key={i}
              // className="seat-container"
              // style={{
              //   width: width + 25,
              //   height: height + 30,
              //   transform: `rotate(${rotation * i}deg)`,
              //   position: "absolute",
              //   display: "flex",
              //   justifyContent: "center",
              // }}

              key={i}
              className={`seat-container`}
              style={{
                width: width + 25,
                height: !isRectangle ? height + 30 : 83,
                transform: !isRectangle
                  ? `rotate(${rotation * i}deg)`
                  : i <= 5
                  ? width > height
                    ? "rotate(0deg)"
                    : "rotate(90deg)"
                  : width > height
                  ? "rotate(180deg)"
                  : "rotate(270deg)",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                top: !isRectangle
                  ? "initial"
                  : width < height
                  ? rectangleVertical[i]
                  : "initial",
                left: !isRectangle
                  ? "initial"
                  : i <= 5
                  ? width > height
                    ? rectangleHorizontal[i]
                    : "initial"
                  : width > height
                  ? rectangleHorizontal[i]
                  : "initial",
              }}
            >
              <div
                className="seat"
                style={{
                  width: 13,
                  height: 9,
                  backgroundColor: backgroundColor,
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: 9,
                    height: 8,
                    border: "2px solid rgb(58 60 63)",
                    backgroundColor: backgroundColor,
                    marginTop: 3,
                    borderRadius: 3,
                  }}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  }

  if (shape === "rectangle") {
    return (
      <div
        style={{
          width: 80,
          // height: position === "vertical" ? "auto" : "100px",
          maxHeight: position === "vertical" ? "auto" : "150px",
          position: "absolute",
          top: position === "vertical" ? 0 : "auto",
          left: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignContent: "start",
          transform: position === "vertical" ? "" : "rotate(-90deg)",
        }}
      >
        {[...Array(seats)].map((e, i) => {
          return (
            <div
              key={i}
              className="seat-container"
              style={{
                height: 25,
                flex: "1 1 50%",
                display: "flex",
                justifyContent: i % 2 === 0 ? "initial" : "flex-end",
                alignItems: "center",
              }}
            >
              <div
                className="seat"
                style={{
                  width: 13,
                  height: 9,
                  backgroundColor: backgroundColor,
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  transform: `rotate(${i % 2 ? 90 : -90}deg)`,
                }}
              >
                <div
                  style={{
                    width: 9,
                    height: 8,
                    border: "2px solid rgb(58 60 63)",
                    backgroundColor: backgroundColor,
                    marginTop: 3,
                    borderRadius: 3,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return "";
};

function collisionDetection(currentElementsCoordinates, elementGuidelines) {
  // console.time("answer time");
  // Temp solution. Context will go there.
  let isCollisionDetected = false;
  elementGuidelines.forEach((_table) => {
    const regex = /(?<=translate\()(.+?)(?=\))/;
    const coordinateUnformatted = _table.style.transform.match(regex);

    if (!coordinateUnformatted) {
      return;
    }

    const coordinates = coordinateUnformatted[0]
      .replaceAll("px", "")
      .split(", ");
    const otherTable = {
      coordinateX: parseInt(coordinates[0], 10),
      coordinateY: parseInt(coordinates[1], 10),
      width: parseInt(_table.style.width.replaceAll("px", ""), 10),
      height: parseInt(_table.style.height.replaceAll("px", ""), 10),
    };

    currentElementsCoordinates.forEach((_size) => {
      // Algorithm to check with rotation. Seems, that this algorithm working correctly, but still, need extra tests.
      // coordinateX should be + elementPosition.centerCoordinateX and and coordinateY + elementPosition.centerCoordinateY = this will display new coordinates after rotation.
      //   const tempX = _size[0] - parseInt(elementPosition.centerCoordinateX, 10);
      //   const tempY = _size[1] - parseInt(elementPosition.centerCoordinateY, 10);

      //   const coordinateX = tempX * Math.cos(elementPosition.degree) - tempY * Math.sin(elementPosition.degree);
      //   const coordinateY = tempX * Math.sin(elementPosition.degree) + tempY * Math.cos(elementPosition.degree);

      //   const rotatedX = coordinateX + elementPosition.centerCoordinateX
      //   const rotatedY = coordinateY + elementPosition.centerCoordinateY

      //   if ((otherTable.coordinateX <= rotatedX && rotatedX <= otherTable.coordinateX + otherTable.width)
      //   && (otherTable.coordinateY <= rotatedY && rotatedY <= otherTable.coordinateY + otherTable.height)) {
      //   // Collision Detected
      //   console.log('collision of rotated object!')
      // }

      if (
        otherTable.coordinateX <= _size[0] + 20 &&
        _size[0] - 20 <= otherTable.coordinateX + otherTable.width &&
        otherTable.coordinateY <= _size[1] + 20 &&
        _size[1] - 20 <= otherTable.coordinateY + otherTable.height
      ) {
        // Collision Detected
        isCollisionDetected = true;
      }
    });
  });
  return isCollisionDetected;
}

export default function MyMovable({
  className,
  otherClassNames,
  translate = [178, 78],
  seats,
  status,
  shape,
  rotate,
  tableGuests,
  isOccupied,
  // isRightFloor,
  editable,
  tableName,
  tableNumber,
  position,
  setPanDisabled,
  duplicateTable,
  removeTable,
  editTable,
  removeWall,
  editWall,
  wall = null,
}) {
  // console.log({ otherClassNames })
  // console.log(tableRotation)

  const [target, setTarget] = useState();
  const [elementGuidelines, setElementGuidelines] = useState([]);
  const [isRotatedHidden, setRotatedHidden] = useState(true);
  const [isTableActive, setTableActive] = useState(false);
  const [frame, setFrame] = useState({
    translate: [...translate],
    rotate: rotate,
  });
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const ref = useRef();

  const { id } = useParams();

  const { floors, floorId, fetchFloors } = useContext(RestaurantsContext);

  // const [elementSize, setElementSize] = React.useState({ width: 50, height: 50 });
  let elementSize = { width: 50, height: 50 };

  // if (seats <= 4) {
  //   elementSize = { width: 50, height: 50 };
  // } else if (seats <= 8 && shape === "square") {
  //   elementSize = { width: 48, height: 103 };
  // } else if (seats >= 4 && shape === "circle") {
  //   elementSize = { width: 80, height: 80 };
  // } else if (seats <= 12 && shape === "square") {
  //   elementSize = { width: 48, height: 103 };
  // } else if (position === "horizontal") {
  //   elementSize = { width: 103, height: 48 };
  // } else if (shape === "wall") {
  //   if (position === "vertical") elementSize = { width: 20, height: 200 };
  //   if (position === "horizontal") elementSize = { width: 200, height: 20 };
  // }

  if (shape === "wall") {
    if (position === "vertical") elementSize = { width: 20, height: 200 };
    if (position === "horizontal") elementSize = { width: 200, height: 20 };
  } else {
    if (seats <= 4) {
      elementSize = { width: 50, height: 50 };
    } else if (seats <= 8 && shape === "square") {
      elementSize = { width: 48, height: 103 };
    } else if (seats >= 4 && shape === "circle") {
      elementSize = { width: 80, height: 80 };
    } else if (seats <= 12 && shape === "square") {
      elementSize = { width: 103, height: 48 };
    } else if (position === "horizontal") {
      elementSize = { width: 103, height: 48 };
    }
  }

  useEffect(() => {
    setTarget(document.querySelector(`.${className}`));
    setElementGuidelines(
      otherClassNames.map((_className) =>
        document.querySelector(`.${_className}`)
      )
    );
    //eslint-disable-next-line
  }, [tables]);

  useEffect(() => {
    setFrame({
      translate: [...translate],
      rotate: rotate,
    });
    // eslint-disable-next-line
  }, [translate[0], translate[1], rotate]);

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  useEffect(() => {
    document.addEventListener("click", mouseEvent);
    return () => {
      document.removeEventListener("click", mouseEvent);
    };
  });

  function mouseEvent(e) {
    e.preventDefault();
    setIsContextMenuActive(false);
  }

  function handleContextMenu(e) {
    if (!editable) {
      return;
    }
    setIsContextMenuActive(false);
    e.preventDefault();
    if (ref.current.contains(e.target)) {
      setIsContextMenuActive(true);
      setContextMenuPosition({ x: e.layerX, y: e.layerY });
    }
  }

  useOutsideClick(ref, () => {
    if (!isRotatedHidden || isTableActive) {
      setRotatedHidden(true);
      setTableActive(false);
    }
    if (isContextMenuActive) {
      setIsContextMenuActive(false);
    }
  });

  async function onDragEndRelease() {
    const currentFloor = floors.filter((item) => item.id === floorId)[0];
    if (shape === "wall") {
      const newWalls = [...currentFloor.walls];
      const indexObjectToChange = newWalls.findIndex(
        (wall) =>
          wall.x === translate[0] &&
          wall.y === translate[1] &&
          ((position === "horizontal" && wall.width > wall.height) ||
            (position === "vertical" && wall.width < wall.height))
      );
      newWalls.splice(indexObjectToChange, 1, {
        x: frame.translate[0],
        y: frame.translate[1],
        width: position === "horizontal" ? 200 : 20,
        height: position === "horizontal" ? 20 : 200,
        rotation: frame.rotate,
      });
      currentFloor.walls = newWalls;
      try {
        await restaurantServices.updateFloor(id, currentFloor);
        fetchFloors(id);
      } catch (err) {
        console.log(err);
      }
    } else {
      const newTablesList = [...currentFloor.tables];
      const editedTable = newTablesList.find(
        (x) => x.id === parseInt(tableName)
      );
      var index = currentFloor.tables.findIndex(
        (x) => x.id === parseInt(tableName)
      );
      if (!editedTable) {
        return;
      }
      editedTable.x = frame.translate[0];
      editedTable.y = frame.translate[1];
      editedTable.rotation = frame.rotate;
      currentFloor.tables[index] = editedTable;
      try {
        await restaurantServices.updateFloor(id, currentFloor);
        fetchFloors(id);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function onRemoveWall() {
    setIsContextMenuActive(false);
    const newWalls = [];
    const indexObjectToChange = newWalls.findIndex(
      (wall) => wall.x === translate[0] && wall.y === translate[1]
      // ((position === "horizontal" && wall.width > wall.height) ||
      //   (position === "vertical" && wall.width < wall.height))
    );
    newWalls.splice(indexObjectToChange, 1);
    removeWall();
  }

  function onDuplicateTable() {
    setIsContextMenuActive(false);
    duplicateTable();
  }

  function onRemoveTable() {
    setIsContextMenuActive(false);
    removeTable();
  }

  function onEditTable() {
    setIsContextMenuActive(false);
    editTable();
  }

  function onEditWall() {
    setIsContextMenuActive(false);
    editWall();
  }

  const MenuItem = () => {
    return (
      <ContextMenuContainer
        style={{
          transform: `translate(${contextMenuPosition.x}px, ${contextMenuPosition.y}px)`,
          zIndex: 10000,
        }}
      >
        {shape === "wall" ? (
          <>
            <div
              key="1menuitem"
              className="context-menu-item edit"
              onClick={() => onEditWall()}
            >
              <i className="fas fa-pencil-alt" />
              <span>Edit</span>
            </div>
            <div
              key="3menuitem"
              className="context-menu-item"
              onClick={() => onRemoveWall()}
            >
              <i className="fas fa-trash-alt" />
              <span>Remove</span>
            </div>
          </>
        ) : (
          <>
            <div
              key="1menuitem"
              className="context-menu-item edit"
              onClick={() => onEditTable()}
            >
              <i className="fas fa-pencil-alt" />
              <span>Edit</span>
            </div>
            <div
              key="2menuitem"
              className="context-menu-item"
              onClick={onDuplicateTable}
            >
              <i className="far fa-clone" />
              <span>Duplicate</span>
            </div>
            <div
              key="3menuitem"
              className="context-menu-item"
              onClick={onRemoveTable}
            >
              <i className="fas fa-trash-alt" />
              <span>Remove</span>
            </div>
          </>
        )}
      </ContextMenuContainer>
    );
  };

  return (
    <div style={{ minHeight: 0, width: 0, position: "absolute" }} ref={ref}>
      {/* Actual table element */}
      {/* "position" property appears only for walls. It can be vertical or horizontal */}
      {isContextMenuActive === true && <MenuItem />}
      <div
        src={DefaultMenu}
        className={`${className} ${isContextMenuActive}`}
        // preview={false}
        style={{
          cursor: "pointer",
          // transform: `translate(${translate[0]}px, ${translate[1]}px)` + `rotate(${rotate}deg)`,
          transform:
            `translate(${translate[0]}px, ${translate[1]}px)` +
            `rotate(${rotate}deg)`,
          width: elementSize.width,
          height: elementSize.height,
          // backgroundColor: `${
          //   status === 1 ? (isOccupied ? "#d6a67c" : "#1fc086") : "#767b81"
          // }`,
          backgroundColor: `${
            status === 1 ? "#1fc086" : isOccupied ? "#d6a67c" : "#767b81"
          }`,
          borderRadius: `${shape === "circle" ? "50%" : ""}`,
          display: `flex`,
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontSize: 11,
          // transform: `rotate(${tableRotation}deg)`,
        }}
      >
        <span
          className="table-name-title"
          style={{ transform: `${rotate ? `rotate(${-rotate}deg)` : ""}` }}
        >
          <div className="text-center">
            <div>{tableNumber}</div>
            {/* <div>{tableName}</div> */}
            <div>
              {tableGuests}
              {seats && `/${seats}`}
            </div>
          </div>
        </span>
        <CreateSeats
          seats={seats}
          shape={shape}
          position={position}
          width={elementSize.width}
          height={elementSize.height}
          // backgroundColor={
          //   status === 1 ? (isOccupied ? "#d6a67c" : "#1fc086") : "#767b81"
          // }
          backgroundColor={
            status === 1 ? "#1fc086" : isOccupied ? "#d6a67c" : "#767b81"
          }
        />
      </div>

      <MoveableStyled
        className={`${isRotatedHidden ? "hidden-rotatable" : ""}`}
        target={target}
        elementGuidelines={elementGuidelines}
        snappable={editable}
        snapThreshold={5}
        isDisplaySnapDigit={true}
        snapGap={true}
        snapElement={true}
        snapVertical={true}
        snapHorizontal={true}
        snapCenter={true}
        snapDigit={0}
        draggable={editable}
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onClick={() => {
          setRotatedHidden(!isRotatedHidden);
          if (!isTableActive) setTableActive(true);
        }}
        rotatable={editable}
        throttleRotate={45}
        rotationPosition={"top"}
        rotateAroundControls={true}
        zoom={isTableActive ? 1 : 0}
        origin={false}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        onDragStart={({ set }) => {
          setPanDisabled(true);
          set(frame.translate);
        }}
        onDrag={({ target, beforeTranslate }) => {
          if (!isTableActive) {
            setTableActive(true);
          }
          const currentElementsCoordinates = [
            [beforeTranslate[0], beforeTranslate[1]],
            [beforeTranslate[0] + elementSize.width, beforeTranslate[1]],
            [beforeTranslate[0], beforeTranslate[1] + elementSize.height],
            [
              beforeTranslate[0] + elementSize.width,
              beforeTranslate[1] + elementSize.height,
            ],

            [beforeTranslate[0] + elementSize.width / 2, beforeTranslate[1]],
            [
              beforeTranslate[0] + elementSize.width / 2,
              beforeTranslate[1] + elementSize.height,
            ],
            [beforeTranslate[0], beforeTranslate[1] + elementSize.height / 2],
            [
              beforeTranslate[0] + elementSize.width,
              beforeTranslate[1] + elementSize.height / 2,
            ],
          ];
          if (
            !collisionDetection(currentElementsCoordinates, elementGuidelines)
          ) {
            setFrame({ ...frame, translate: beforeTranslate });
            // frame.translate = beforeTranslate;
            target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          }
        }}
        onDragEnd={() => {
          onDragEndRelease();
          setPanDisabled(false);
        }}
        onRotateStart={({ set }) => {
          set(frame.rotate);
          setPanDisabled(true);
        }}
        onRotate={({ beforeRotation, beforeRotate }) => {
          setFrame({ ...frame, rotate: beforeRotate });
          target.style.transform = `rotate(${beforeRotation}deg)`;
        }}
        onRotateEnd={() => {
          onDragEndRelease();
          setPanDisabled(false);
        }}
        onRender={({ target }) => {
          const { translate, rotate } = frame;
          target.style.transform =
            `translate(${translate[0]}px, ${translate[1]}px)` +
            ` rotate(${rotate}deg)`;
        }}
      />
    </div>
  );
}
