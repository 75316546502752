import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const NumberVerifiedModalContainer = styled(MyDrawerCenterModal)`
  .enter-new-number {
    position: relative;
    .modal-image {
      position: absolute;
      left: 4rem;
      top: -231px;
      img {
        width: 312px;
        height: 194px;
      }
    }
    .verified-icon-check {
      i {
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.53px;
        text-align: center;
        color: #1fc086;
      }
      margin-bottom: 16px;
      text-align: center;
    }
    .modal-title-new-number {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      // margin-top: 31px;
    }
    .verify-actions {
      margin-top: 28px;
      text-align: center;
      button {
        width: -webkit-fill-available;
      }
    }
  }
`;
