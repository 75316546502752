import styled from "@emotion/styled";
import { colors } from "theme";

export const AllStaffContainer = styled.div`
  width: 100%;
  &:nth-of-type(even) {
    .staff-card {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  .staff-card {
    min-height: 65px;
    background-color: ${colors.gray2};
    &.is-header {
      background-color: transparent;
    }
    padding: 10px 10px;
    /* margin: 8px 0; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-img {
      width: 70px;
    }
    .restaurent-profile {
      display: flex;
      align-items: center;
      gap: 20px;
      img {
        border-radius: 50px;
      }
      .restaurant-name {
        font-weight: 700;
      }
    }
    .card-edit {
      margin-right: 40px;
      .popup-wrapper {
        padding: 16px 33px;
        border-radius: 25px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
        border: solid 1px rgba(255, 255, 255, 0.08);
        background-color: #151617;
      }
      h6 {
        width: 100%;
      }
    }
    .card-col {
      flex: 1;
      text-align: center;
      .total {
        /* font-weight: 700; */
        text-align: right;
        /* width: 100%; */
        /* position: relative;
        right: calc(100% - 16rem); */
        width: calc(100% - 33px);
      }
      .rating-wrapper {
        display: flex;
        align-items: center;
        gap: 7px;
        .view {
          display: flex;
          align-items: center;
          gap: 7px;
          .view-text {
            color: ${colors.redColor};
            font-weight: 500;
          }
          i {
            color: ${colors.redColor};
          }
        }
      }
      .single-ant-format-input {
        background: transparent;
        border: 0;
        color: #ffffff;
        &:focus-visible {
          outline: unset;
        }
      }
      h6 {
        width: max-content;
        margin-bottom: 0;
        .order-badge {
          display: flex;
          background: #27282a;
          width: max-content;
          padding: 5px 7px;
          border-radius: 7px;
          /* gap: 7px; */
          font-weight: 500;
        }
      }

      .rating-wrapper {
        .rating-star {
          padding: 7px 6px 6px;
          border-radius: 15.5px;
          background-color: #ed2626;
        }
      }
    }

    .card-col {
      .restaurent-profile {
        .name {
          padding-left: 5rem;
        }
      }
      .email {
        padding-left: 11.5rem;
      }
    }
  }
`;

export const PopupContainer = styled.div`
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
`;
