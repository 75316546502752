import MyButton from "components/Elements/MyButton";
import React, { useEffect, useState } from "react";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { colors } from "theme";
import { ProfileCardStatContainer } from "./style";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyDateRange from "components/Elements/MyDateRange";
import { PopupCalendarContainer } from "components/analytics/AnalyticPeriod/style";
import moment from "moment";
import useOnclickOutside from "react-cool-onclickoutside";

// const options = [
//   {
//     value: "week",
//     label: "Week",
//   },
//   {
//     value: "month",
//     label: "Month",
//   },
//   {
//     value: "total",
//     label: "Total",
//   },
// ];

const options = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "month",
    label: "Month",
  },
  {
    value: "custom",
    label: "Custom",
  },
];

export default function ProfileCardStat({ className = "", customerId }) {
  const [activeOption, setActiveOption] = useState("week");
  const [stats, setStats] = useState();
  const [openCustomCalender, setOpenCustomCalender] = useState(false);

  const [tempDateRange, setTempDateRange] = useState();
  const [salesGraphCalendar, setSalesGraphCalendar] = useState(false);
  const [salesGraphDateRange, setSalesGraphDateRange] = useState();

  useEffect(() => {
    if(activeOption === 'custom' && salesGraphDateRange?.startDate && salesGraphDateRange?.endDate){
      customAxios
      .get(`/customer/${customerId}/orders-stats?period=${activeOption}&start_date=${salesGraphDateRange?.startDate}&end_date=${salesGraphDateRange?.endDate}`)
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    }else{
    customAxios
      .get(`/customer/${customerId}/orders-stats?period=${activeOption}`)
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    }
  }, [customerId, activeOption, salesGraphDateRange?.startDate, salesGraphDateRange?.endDate]);

  const onSwitch = (option) => {
    setActiveOption(option.value);
  };

  function onChangeSalesGraph() {
    if (tempDateRange && tempDateRange?.endDate) {
      setSalesGraphDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
    }
    setSalesGraphCalendar(!salesGraphCalendar);
    setOpenCustomCalender(!openCustomCalender)
  }

  // date range method
  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempDateRange({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const ref = useOnclickOutside(() => {
    setSalesGraphCalendar(false);
    setOpenCustomCalender(false)
  });

  return (
    <ProfileCardStatContainer className={`${className}`}>
      {/* previous tabs code */}
      {/* <div className="toggle-options">
        {options.map((_option, _i) => (
          <MyButton
            key={_i}
            type={activeOption === _option.value ? "gray" : "transparent"}
            onClick={() => onSwitch(_option)}
            className={
              activeOption !== _option.value && "un-selected-time-period"
            }
          >
            {_option.label}
          </MyButton>
        ))}
      </div> */}

      <div className="statistic-wrapper">
        <div className="title">Statistic</div>
        <div
          className="date-btn"
          onClick={() => setOpenCustomCalender(!openCustomCalender)}
        >
          Date{" "}
          {openCustomCalender ? (
            <i className="far fa-chevron-up" />
          ) : (
            <i className="far fa-chevron-down" />
          )}
        </div>
      </div>

      {openCustomCalender && (
        <div ref={ref}>
          {activeOption === "custom" ? (
            <div id="sales-calendar-popup">
              <PopupCalendarContainer
                className="popup-sales-calendar"
              >
                <MyDateRange
                  months={2}
                  onChangeDate={onChangeDate}
                  date={salesGraphDateRange}
                />
                <div
                  style={{
                    display: "contents",
                    justifyContent: "space-between",
                    padding: "0 20px",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: "14px" }}>
                    {salesGraphDateRange?.startDate
                      ? moment(salesGraphDateRange?.startDate).format(
                          "MM/DD/YYYY"
                        )
                      : ""}
                    {salesGraphDateRange && "  to  "}
                    {salesGraphDateRange?.endDate
                      ? moment(salesGraphDateRange?.endDate).format(
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </span>
                  <MyButton
                    className="apply-date"
                    type={
                      tempDateRange?.endDate || salesGraphDateRange?.endDate
                        ? "red"
                        : "gray"
                    }
                    onClick={onChangeSalesGraph}
                  >
                    Apply
                  </MyButton>
                </div>
              </PopupCalendarContainer>
            </div>
          ) : null}

          <div
            className="custom-calender"
            style={
              activeOption === "custom"
                ? { height: "445px" }
                : { height: "unset" }
            }
            
          >
            <div className="text-label">
              {options.map((_option, _i) => (
                <MyButton
                  key={_i}
                  type={activeOption === _option.value ? "gray" : "transparent"}
                  onClick={() => onSwitch(_option)}
                  className={
                    activeOption !== _option.value && "un-selected-time-period"
                  }
                >
                  {_option.label}
                </MyButton>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="profile-stat-row">
        <h5 className="profile-stat-row-label">
          <span className="opacity6">Total Paid</span>
          {stats && stats?.sum && stats?.sum?.change >= 0 ? (
            <span className="percent text-green">
              {stats?.sum?.change >= 0 ? (
                <FaArrowCircleUp color={colors.green} />
              ) : (
                <FaArrowCircleDown color={colors.orange} />
              )}
              <span
                style={{
                  color: stats?.sum?.change >= 0 ? "#1fc086" : "#f7a64c",
                }}
              >
                <b>{Math.abs(stats?.sum?.change)}%</b>
              </span>
            </span>
          ) : (
            <></>
          )}
        </h5>
        <h1 className="profile-stat-row-value">
          ${stats?.sum?.current === null ? 0 : stats?.sum?.current}
        </h1>
      </div>

      <div className="profile-stat-row">
        <h5 className="profile-stat-row-label">
          <span className="opacity6">Orders</span>
          {stats?.total_orders?.change >= 0 ? (
            <span className="percent text-orange">
              {stats?.total_orders?.change >= 0 ? (
                <FaArrowCircleUp color={colors.green} />
              ) : (
                <FaArrowCircleDown color={colors.orange} />
              )}
              <span
                style={{
                  color:
                    stats?.total_orders?.change >= 0 ? "#1fc086" : "#f7a64c",
                }}
              >
                <b>{Math.abs(stats?.total_orders?.change)}%</b>
              </span>
            </span>
          ) : (
            <></>
          )}
        </h5>
        <h1 className="profile-stat-row-value">
          {stats?.total_orders?.current === null
            ? 0
            : stats?.total_orders?.current}
        </h1>
      </div>
    </ProfileCardStatContainer>
  );
}
