import React from "react";
import PropTypes from "prop-types";
import { FaChevronRight, FaMinusCircle } from "react-icons/fa";
import { colors } from "theme";
import { ItemPickerContainer } from "./style";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

export default function ItemPicker({
  label = "",
  className = "",
  placeholder = "",
  value = "",
  onClick = () => {},
  isVerified = false,
  verifyIcon = false,
  IsNutritionTrashIcon = false,
}) {
  // console.log(value)
  return (
    <ItemPickerContainer
      className={`${className} ${value ? "set" : "unset"}`}
      onClick={onClick}
    >
      <div className={`item-picker-left`}>
        {label && <h6 className="item-picker-label">{label}</h6>}
        <div style={{ display: "flex", alignItems: "center" }}>
          <h6 className="item-picker-value">{value || placeholder}</h6>
          {verifyIcon ? (
            isVerified ? (
              <AiFillCheckCircle
                size={15}
                color={colors.green}
                style={{ marginLeft: "10px" }}
              />
            ) : (
              <AiFillCloseCircle
                size={15}
                color={"red"}
                style={{ marginLeft: "10px" }}
              />
            )
          ) : null}
        </div>
      </div>
      <FaChevronRight size={15} color={colors.white3} />
    </ItemPickerContainer>
  );
}

ItemPicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
};
