import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { mocks } from "static/staticData";
import { DrawerStatusMenuContainer } from "./style";

export default function DrawerStatusMenu({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postMenu, setPostMenu } = useContext(RestaurantsContext);

  const onChangeStatus = (status) => {
    setPostMenu({ ...postMenu, status: status });
    closePanel();
  };

  return (
    <DrawerStatusMenuContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">Status Menu</h2>
        <div className="drawer-status--options mb-auto">
          {Object.values(mocks.statuses).map((_status, _i) => (
            <DrawerChooseItem
              key={_i}
              className="mb-4"
              title={_status.title}
              active={postMenu?.status === _status.value}
              onChooseItem={() => onChangeStatus(_status.value)}
            />
          ))}
        </div>
      </DrawerContainer>
    </DrawerStatusMenuContainer>
  );
}
