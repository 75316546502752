import { DrawerContainer } from "components/styledStyle";
import React, { useState, useContext } from "react";
import { DrawerVerifyEmailContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const emails = [
  {
    id: 1,
    price: "Verified",
    isVerified: true,
  },
  {
    id: 2,
    price: "Unverified",
    isVerified: false,
  },
];

export default function DrawerVerifyEmail({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postRestaurant, setPostRestaurant } = useContext(RestaurantsContext);
  const [emailStatus, setEmailStatus] = useState();

  function getPriceId(emailInfo) {
    // console.log(emailInfo?.id);
    // console.log(emailInfo?.isVerified);
    setEmailStatus(emailInfo?.isVerified);
    setPostRestaurant({...postRestaurant, email_verified: emailInfo?.isVerified});
    // closePanel();
  }

  return (
    <DrawerVerifyEmailContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Email Address</div>
          <div></div>
        </div>
        <div className="drawer-status--options mb-auto">
          {emails &&
            emails.map((_email, _i) => {
              let emailVerify = _email?.isVerified;
              return (
                <ul className="price-wrapper" key={_i}>
                  <li
                    style={{
                      border:
                        emailVerify === postRestaurant?.email_verified
                          ? "1px solid #fff"
                          : "1px solid #5b5e61",
                    }}
                    onClick={() => getPriceId(_email)}
                  >
                    {_email.price}
                  </li>
                </ul>
              );
            })}
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={closePanel}
          >
            Save
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerVerifyEmailContainer>
  );
}
