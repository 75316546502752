import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DrawerApplyDiscountAreYouSureContainer, Label } from "./style";
import SingleText from "components/SingleText";
import { DrawerContainer } from "components/styledStyle";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export function DrawerSortReview({
  isActive,
  closePanel,
  foodDetails = {},
  sortArray,
  onClickSortItem = () => {},
}) {
  useEffect(() => {}, [foodDetails]);
  return (
    <DrawerApplyDiscountAreYouSureContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <div className="drawer-bottom-content w-100">
            <div className="top-title d-flex justify-content-center align-items-center">
              <h2>Sort</h2>
            </div>
            <div className="d-flex flex-column w-100">
              {sortArray &&
                sortArray.map((el, i) => (
                  <div
                    key={i}
                    className="sort-item cursor-pointer"
                    onClick={() => onClickSortItem(el)}
                  >
                    {el?.title}
                  </div>
                ))}
            </div>
            <div
              className="bottom-btns div-padding "
              style={{ marginTop: "30px", paddingBottom: "10px" }}
            >
              <MyDrawerButton type="redColor" onClick={closePanel}>
                Cancel
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerApplyDiscountAreYouSureContainer>
  );
}

DrawerSortReview.propTypes = {
  visible: PropTypes.bool,
  closePanel: PropTypes.func,
  discount: PropTypes.object,
  discountTypes: PropTypes.object,
  applyDiscountForActiveOrder: PropTypes.func,
};
