import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { MyFileUploader } from "components/Elements/MyFileUploader";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { FaCamera, FaTimes, FaTrash, FaVideo } from "react-icons/fa";
import { useParams } from "react-router";
import { restaurantServices } from "services";
import { mocks, drawersMenu } from "static/staticData";
import DrawerNutritions from "../DrawerNutritions";
import DrawerDeleteFood from "./DrawerDeleteFood";
import { DrawerFoodContainer } from "./style";
import SingleVideoUploader from "components/Elements/MyFileUploader/SingleVideoUploader";
import { DefaultFood } from "assets/img";
import Box from "components/Box";
import SingleText from "components/SingleText";
import { MyFileUploaderFormData } from "components/Elements/MyFilleUploaderFormData";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const typeArray = [
  {
    value: 1,
    title: "Drink",
  },
  {
    value: 2,
    title: "Quick Food (Starters, Desserts, Other)",
  },
  {
    value: 0,
    title: "Entrée",
  },
];

export default function DrawerFood({
  type = "Add",
  isActive = false,
  closePanel = () => {},
  close = () => {},
}) {
  const { id } = useParams();
  const {
    openDrawer,
    postFood,
    setPostFood,
    setFoods,
    selectedMenu,
    setSelectedMenu,
    categoryId,
    closeDrawer,
    openDrawers,
  } = useContext(RestaurantsContext);
  const [selectedTab, setSelectedTab] = useState("image");
  const [saveActive, setSaveActive] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState();
  const [selectedType, setSelectedType] = useState("");

  const [addNewItemNotification, setAddNewItemNotification] = useState(null);

  const [editItemNotification, setEditItemNotification] = useState(null);

  const onChangeInput = (value, type) => {
    setPostFood({ ...postFood, [type]: value });
  };

  const callBackUpload = (data) => {
    // console.log("postFood", postFood)
    // console.log(data);
    // setCurImageSrc(data.file);
    setPostFood({ ...postFood, photo: data });
  };

  useEffect(() => {
    if (saveActive) {
      closeDrawer(drawersMenu.addType);
      setSaveActive(false);
    }
  }, [saveActive]);

  useEffect(() => {
    if (postFood && postFood?.kind !== "") {
      let filterData = typeArray.filter((el) => el.value === postFood.kind);
      setSelectedType(filterData[0]);
    }
  }, [postFood]);

  useEffect(() => {
    if (addNewItemNotification) {
      setTimeout(() => setAddNewItemNotification(null), 2 * 1000);
    }
  }, [addNewItemNotification]);

  useEffect(() => {
    if (editItemNotification) {
      setTimeout(() => setEditItemNotification(null), 2 * 1000);
    }
  }, [editItemNotification]);

  const onAddItem = async () => {
    const itemPostFormData = new FormData();
    postFood.photo && itemPostFormData.append("asset", postFood.photo);
    postFood?.category?.length > 0 &&
      itemPostFormData.append("category", JSON.stringify(postFood.category));
    postFood.title && itemPostFormData.append("title", postFood.title);
    postFood.kind && itemPostFormData.append("kind", postFood.kind);
    postFood.price && itemPostFormData.append("price", postFood.price);
    postFood.status && itemPostFormData.append("status", postFood.status);
    postFood.description &&
      itemPostFormData.dietary &&
      itemPostFormData.append("description", postFood.description);
    postFood.dietary?.length > 0 &&
      itemPostFormData.append("dietary", JSON.stringify(postFood.dietary));
    postFood.nutrition &&
      itemPostFormData.append("nutrition", postFood.nutrition);
    (postFood.free_refill === true || false) &&
      itemPostFormData.append("free_refill", postFood.free_refill);
    postFood.customization &&
      itemPostFormData.append(
        "customization",
        JSON.stringify(postFood.customization)
      );

    // if (postFood.photo) {
    //   postFood.photo = postFood.photo.id;
    // }
    // if (uploadedVideo !== "") {
    //   postFood.video = uploadedVideo;
    // }

    // Formdata post api
    const result = await restaurantServices.addNewFoodItem(
      id,
      itemPostFormData
    );

    // previous post api
    // const result = await restaurantServices.addNewFoodItem(id, postFood);

    if (result.status === 200) {
      closePanel();
      postFood &&
        postFood.customization &&
        postFood.customization.map((el) => {
          customAxios
            .post(`restaurant/${id}/food/${result?.data?.id}/customization`, el)
            .then((res) => {})
            .catch((err) => {
              console.error(err);
            });
        });
      closePanel();
      // closeDrawer(drawersMenu.addType);
      callBack();
      setSaveActive(true);
      setAddNewItemNotification({
        message: `New item added.`,
        type: "added-new-item",
        table: ``,
      });
    }
  };

  const onSaveItem = async () => {
    const itemPutFormData = new FormData();
    if (!postFood.photo.asset_type) {
      itemPutFormData.append("asset", postFood.photo);
    }
    postFood?.categories?.length > 0 &&
      itemPutFormData.append("category", JSON.stringify(postFood.categories));
    postFood.title && itemPutFormData.append("title", postFood.title);
    postFood.kind && itemPutFormData.append("kind", postFood.kind);
    postFood.price && itemPutFormData.append("price", postFood.price);
    postFood.status && itemPutFormData.append("status", postFood.status);
    postFood.description &&
      itemPutFormData.dietary &&
      itemPutFormData.append("description", postFood.description);
    postFood.dietary?.length > 0 &&
      itemPutFormData.append("dietary", JSON.stringify(postFood.dietary));
    postFood.nutrition &&
      itemPutFormData.append("nutrition", postFood.nutrition);
    (postFood.free_refill === true || false) &&
      itemPutFormData.append("free_refill", postFood.free_refill);
    postFood.customization &&
      itemPutFormData.append(
        "customization",
        JSON.stringify(postFood.customization)
      );

    // if (postFood) {
    //   postFood.photo = postFood?.photo?.id;
    // }

    // if (uploadedVideo !== "") {
    //   postFood.video = uploadedVideo;
    // }

    // FormData put api
    customAxios
      .put(`/restaurant/${id}/food/${postFood?.id}`, itemPutFormData)
      .then((res) => {
        if (res.status === 200) {
          closePanel();
          callBack();
          setEditItemNotification({
            message: `Item Updated.`,
            type: "added-new-item",
            table: ``,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // put api
    // const result = await restaurantServices.updateFoodItem(id, postFood);

    // if (result.status === 200) {
    //   closePanel();
    //   callBack();
    // }
  };

  const callBack = async () => {
    // setSelectedMenu(menus.find((_menu) => _menu.id === menu.id));
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
      setSelectedMenu(
        result.data.menus.find((_menu) => _menu.id === selectedMenu.id)
      );
    }
  };

  useEffect(() => {}, [id, postFood, categoryId]);

  // const onDeleteItem = async () => {
  //   customAxios
  //     .delete(`/restaurant/${id}/food/${postFood.id}?category=${categoryId}`)
  //     .then((res) => {
  //       // console.log(res.data);
  //       closePanel();
  //       // callBack();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // console.log(JSON.stringify(postFood));

  function setNewVideo(result) {
    setUploadedVideo(result?.data?.id);
  }

  return (
    <DrawerFoodContainer isActive={isActive} closePanel={closePanel}>
      {addNewItemNotification && (
        <MyMessageBoxItemNew
          message={
            addNewItemNotification?.message
              ? addNewItemNotification.message
              : ""
          }
          table={
            addNewItemNotification?.table ? addNewItemNotification.table : ""
          }
          type={
            addNewItemNotification?.type
              ? addNewItemNotification.type
              : "success"
          }
          onClick={() => setAddNewItemNotification(null)}
        />
      )}

      {editItemNotification && (
        <MyMessageBoxItemNew
          message={
            editItemNotification?.message
              ? editItemNotification.message
              : ""
          }
          table={
            editItemNotification?.table ? editItemNotification.table : ""
          }
          type={
            editItemNotification?.type
              ? editItemNotification.type
              : "success"
          }
          onClick={() => setEditItemNotification(null)}
        />
      )}
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaTimes
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
              style={{
                opacity: "0.2",
              }}
            />
          </div>
          <div className="title">{type} Item</div>
          <div>
            {type === "Edit" ? (
              // <FaTrash className="cursor-pointer" color="#fff" size={20} onClick={onDeleteItem} />
              // <FaTrash
              //   className="cursor-pointer"
              //   color="#fff"
              //   size={20}
              //   onClick={() => openDrawer(drawersMenu.openDrawerDeleteFood)}
              // />
              <i
                className="fas fa-trash-alt cursor-pointer"
                style={{ fontSize: "20px", color: "#fff" }}
                onClick={() => openDrawer(drawersMenu.openDrawerDeleteFood)}
              />
            ) : null}
          </div>
        </div>
        <Tab.Container
          id="tabs-bootstrap"
          defaultActiveKey="image"
          activeKey={selectedTab}
        >
          <Tab.Content>
            <Tab.Pane eventKey="image">
              <MyFileUploaderFormData
                shape="square"
                imgSrc={postFood?.photo?.file}
                // placeholder={DefaultFood}
                // uploading={uploadingImage}
                // onUpload={onUploadImage}
                callBackUpload={callBackUpload}
                overlay={
                  <div
                    className="d-flex align-items-center input-title"
                    style={{ position: "absolute", bottom: 0, left: "9rem" }}
                  >
                    <FaCamera size={24} color="white" />
                    <p style={{ margin: 0, padding: "12px" }}>
                      {" "}
                      {type === "Add" ? "Add Image" : "Replace Image"}
                    </p>
                  </div>
                }
              />
            </Tab.Pane>
            <Tab.Pane eventKey="video">
              {/* <MyFileUploader
                fileType="video"
                shape="square"
                // placeholder={DefaultFood}
                // uploading={uploadingImage}
                // onUpload={onUploadImage}
                overlay={
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ position: "absolute", bottom: 0, left: "9rem" }}
                  >
                    <FaCamera size={24} color="white" />
                    <p style={{ margin: 0, padding: "12px" }}>
                      {type === "Add" ? "Add Video" : "Replace Video"}
                    </p>
                  </div>
                }
              /> */}
              <SingleVideoUploader
                shape="square"
                placeholder={DefaultFood}
                receiveVideo={setNewVideo}
                height="220px"
                overlay={
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ position: "absolute", bottom: 0, left: "9rem" }}
                  >
                    <FaVideo size={24} color="white" />
                    <p style={{ margin: 0, padding: "12px" }}>
                      {type === "Add" ? "Add Video" : "Replace Video"}
                    </p>
                  </div>
                }
              />
            </Tab.Pane>
          </Tab.Content>

          <div className="tab-switcher-container">
            <div
              href="#"
              className={`tab-switcher-item ${
                selectedTab === "image" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("image")}
            >
              Image
            </div>
            <div
              href="#"
              className={`tab-switcher-item ${
                selectedTab === "video" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("video")}
            >
              Video
            </div>
          </div>
        </Tab.Container>
        <DrawerInput
          label="Item Name"
          placeholder="Ex: Caesar Salad"
          value={postFood?.title}
          onChange={(e) => onChangeInput(e.target.value, "title")}
          className="add-item-input"
        />
        <ItemPicker
          label="Type"
          placeholder="Set Type"
          value={selectedType?.title || ""}
          onClick={() => openDrawer(drawersMenu.itemType)}
        />
        <DrawerInput
          type="text"
          label="Price"
          placeholder="Enter Price"
          value={postFood?.price}
          // onChange={(e) => onChangeInput(parseFloat(e.target.value), "price")}
          onChange={(e) => onChangeInput(e.target.value, "price")}
          className="add-item-input"
        />
        <ItemPicker
          label="Status"
          placeholder="Set Status"
          value={mocks.statuses[postFood?.status]?.title}
          onClick={() => openDrawer(drawersMenu.statusFood)}
        />
        {/* <ItemPicker
          label="Category"
          placeholder="Set Category"
          onClick={() => openDrawer(drawersMenu.itemType)}
        /> */}
        <ItemPicker
          label="Menu"
          placeholder="Assign to menu"
          value={
            postFood?.categories && postFood?.categories?.length > 0
              ? `Assigned to ${postFood?.categories?.length} menus`
              : ""
          }
          // value={
          //   postFood?.category && postFood?.category?.length > 0
          //     ? `Assigned to ${postFood?.category?.length} menus`
          //     : ""
          // }
          onClick={() => openDrawer(drawersMenu.assignToMenu)}
        />

        <>
          {postFood?.kind && postFood?.kind === 1 ? (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                height="62px"
                padding="12px 0"
                alignItems="center"
                borderBottom="solid 1px rgba(255, 255, 255, 0.1)"
              >
                <SingleText
                  content="Free Refills"
                  opacity={0.7}
                  size={15}
                  weight={600}
                  spacing="-0.36px"
                  marginRight="auto"
                >
                  Free Refills
                </SingleText>
                <label className="switch-refill">
                  <input
                    type="checkbox"
                    checked={postFood?.free_refill}
                    onChange={() =>
                      setPostFood({
                        ...postFood,
                        free_refill: !postFood.free_refill,
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </Box>
            </>
          ) : (
            <></>
          )}
        </>

        <div className="optional-wrapper mt-5">
          <p
            className="optional-title mb-3 text-bold "
            style={{
              opacity: "0.7",
            }}
          >
            Optional but highly recommended.
          </p>
          <DrawerInput
            label="Description"
            placeholder="Write a description"
            value={postFood?.description}
            onChange={(e) => onChangeInput(e.target.value, "description")}
            className="add-item-input"
          />
          <ItemPicker
            label="Dietary Tags"
            placeholder="Set dietary tags"
            value={
              postFood?.dietary &&
              postFood?.dietary
                .map((_value) => mocks.dietaryTags[_value].title)
                .join(", ")
            }
            onClick={() => openDrawer(drawersMenu.dietaryTags)}
          />
          <ItemPicker
            label="Nutrition Facts"
            placeholder="Set nutrition facts"
            value={postFood?.nutrition ? "Edit nutrition facts" : ""}
            onClick={() => openDrawer(drawersMenu.nutritions)}
          />
          <ItemPicker
            label="Customizations"
            placeholder="Set customizations"
            value={postFood?.customizations ? "Edit customizations" : ""}
            onClick={() => openDrawer(drawersMenu.customizations)}
          />
        </div>
        <div className="bottom-btns">
          {type === "Add" ? (
            <>
              <MyDrawerButton
                className="add-btn text-bold"
                type={
                  postFood?.title === undefined ||
                  (postFood?.title === "" &&
                    postFood?.category?.length > 0 &&
                    postFood?.kind === undefined &&
                    postFood?.price === null &&
                    postFood?.status === undefined)
                    ? "gray"
                    : "red"
                }
                disabled={
                  postFood?.title === undefined ||
                  (postFood?.title === "" &&
                    postFood?.category?.length > 0 &&
                    postFood?.kind === undefined &&
                    postFood?.price === null &&
                    postFood?.status === undefined)
                    ? true
                    : false
                }
                onClick={onAddItem}
              >
                Add Item
              </MyDrawerButton>
            </>
          ) : (
            <MyDrawerButton
              type="red"
              className="text-bold mt-5 mb-5"
              onClick={onSaveItem}
            >
              Save Item
            </MyDrawerButton>
          )}
        </div>
      </DrawerContainer>
      <DrawerDeleteFood
        isActive={!!openDrawers[drawersMenu.openDrawerDeleteFood]}
        closePanel={() => closeDrawer(drawersMenu.openDrawerDeleteFood)}
      />
    </DrawerFoodContainer>
  );
}
