import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useState } from "react";
import { DrawerDateCustomerContainer, MyDateContainer } from "./style";

export default function DrawerDatePicker({
  isActive = false,
  closePanel = () => {},
  date = "",
  onChangeDate = () => {},
}) {
  return (
    <DrawerDateCustomerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="add-day-title">Add Day</div>
        <MyDateContainer date={date} onChange={onChangeDate} />
      </DrawerContainer>
    </DrawerDateCustomerContainer>
  );
}
