import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { DrawerStatusContainer } from "./style";
import { CustomersContext } from "context/CustomersContext";
import { drawersOverall, drawersCustomers } from "static/staticData";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerAddAddress from "./DrawerAddAddress";
import DrawerEditAddress from "./DrawerEditAddress";
import MyButton from "components/Elements/MyButton";

export default function DrawerLocations({
  isActive = false,
  closePanel = () => {},
  customerId,
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    profileData,
    setProfileData,
    editCustomerData,
    setEditCustomerData,
  } = useContext(CustomersContext);

  const [addedLocations, setAddedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  useEffect(() => {
    customAxios
      .get(`/customer/${customerId}/location`)
      .then((res) => {
        setAddedLocations(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [customerId]);

  const onSaveAddress = (savedLocation) => {
    let obj = {
      address: savedLocation.locationAddress,
      title: savedLocation.locationTitle,
      is_default: false,
      place_id: savedLocation.locationPlaceId,
      newAddress: true,
    };
    let data = [...addedLocations];
    data.push(obj);
    setAddedLocations(data);
    setEditCustomerData({
      ...editCustomerData,
      locations: data.length,
      addedLocations: data,
    });
  };

  const onSaveEditAddress = (editLocation) => {
    if (selectedLocation && selectedLocation.newAddress) {
      let obj = {
        address: editLocation.locationAddress,
        title: editLocation.locationTitle,
        is_default: false,
        place_id: editLocation.locationPlaceId,
        newAddress: true,
      };
      let data = [...addedLocations];
      data[selectedLocation.index] = obj;
      setAddedLocations(data);
      setEditCustomerData({
        ...editCustomerData,
        locations: data.length,
        addedLocations: data,
      });
    } else {
      let data = [...addedLocations];
      data[selectedLocation.index].address = editLocation.locationAddress;
      data[selectedLocation.index].title = editLocation.locationTitle;
      data[selectedLocation.index].place_id = editLocation.locationPlaceId;
      data[selectedLocation.index].editAddress = true;
      setAddedLocations(data);
      setEditCustomerData({
        ...editCustomerData,
        locations: data.length,
        addedLocations: data,
      });
    }
  };

  const deleteLocationHandler = () => {
    if (selectedLocation && selectedLocation.newAddress) {
      let data = [...addedLocations];
      let filteredData = data.filter(
        (location, index) => index !== selectedLocation.index
      );
      setAddedLocations(filteredData);
      setEditCustomerData({
        ...editCustomerData,
        locations: filteredData.length,
        addedLocations: filteredData,
      });
    } else {
      let data = [...addedLocations];
      data[selectedLocation.index].deleteAddress = true;
      data[selectedLocation.index].editAddress = false;
      let filteredData = data.filter(
        (location, index) => index !== selectedLocation.index
      );
      setAddedLocations(filteredData);
      setEditCustomerData({
        ...editCustomerData,
        locations: filteredData.length,
        addedLocations: data,
      });
    }
  };

  return (
    <DrawerStatusContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Addresses on File</div>
          <div onClick={() => openDrawer(drawersCustomers.addAddress)}>
            <i className="fas fa-plus" />
          </div>
        </div>
        <div className="address-list-div mb-auto mt-4">
          {addedLocations &&
            addedLocations.length &&
            addedLocations.map((location, index) => {
              return (
                <div
                  key={index}
                  className="address-list-item"
                  onClick={() => {
                    openDrawer(drawersCustomers.editAddress);
                    setSelectedLocation({ ...location, index: index });
                  }}
                >
                  <div className="address-info">
                    <h6 className="text-bold">{location.address}</h6>
                    <h6 className="opacity6">{location.title}</h6>
                  </div>
                  <h6>
                    <i className="fas fa-angle-right" />
                  </h6>
                </div>
              );
            })}
        </div>
        <MyButton className="done-btn" type={"red"} onClick={closePanel}>
          Done
        </MyButton>
      </DrawerContainer>
      <DrawerAddAddress
        isActive={!!openDrawers[drawersCustomers.addAddress]}
        closePanel={() => closeDrawer(drawersCustomers.addAddress)}
        customerId={customerId}
        onSaveAddress={onSaveAddress}
      />
      <DrawerEditAddress
        isActive={!!openDrawers[drawersCustomers.editAddress]}
        closePanel={() => closeDrawer(drawersCustomers.editAddress)}
        customerId={customerId}
        onSaveEditAddress={onSaveEditAddress}
        selectedLocation={selectedLocation}
        deleteLocationHandler={deleteLocationHandler}
      />
    </DrawerStatusContainer>
  );
}
