import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerSetActiveStatusModalContainer = styled(MyDrawerCenterModal)`
  .set-discount-container {
    .modal-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .input-area {
      border-bottom: 1px solid #545556;
      padding: 10px 0 10px 0;
      margin-bottom: 30px;
      .label-text {
        font-size: 14px;
        color: #fff;
        opacity: 0.5;
      }
      .label-text i {
        font-size: 14px;
        color: #fff;
        opacity: 0.7;
      }
      input {
        background: transparent;
        border: 0;
        color: #fff;
        &:focus {
          outline: none;
        }
      }
      input::placeholder {
        font-size: 14px;
        color: #fff;
        opacity: 0.3;
      }
    }

    .save-btn-disable {
      text-align: center;
      background: #3b3b3c;
      padding: 10px 0 10px 0;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 25px;
      height: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .save-btn-enable {
      text-align: center;
      background: #ed2626;
      padding: 10px 0 10px 0;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 25px;
      cursor: pointer;
      height: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .set-active-btn {
      width: 100%;
      background: #ed2626;
      border: 0;
      color: #fff;
      font-weight: 600;
      height: 50px;
      border-radius: 8px;
      margin-bottom: 32px;
    }

    .cancel-btn {
      text-align: center;
      font-weight: bold;
      color: #ed2626;
     
    }
  }
`;
