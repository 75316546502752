import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerDeleteConfirmContainer = styled(MyDrawerCenterModal)`
  text-align: center;
  .delete-menu-wrapper {
    h2 {
      font-size: 22px;
      margin-bottom: 20px;
    }
    .para-content {
      p {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .bottom-btns {
    .cancel-btn {
      color: ${colors.redColor};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;
