import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerStaffChooseRoleContainer = styled(MyDrawerRight)`
  -webkit-justify-content: unset;
  justify-content: unset;

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .permission {
    margin-top: 64px;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .roles {
    padding: 20px;
    border-radius: 16px;
  }
`;
