import { useState } from 'react';
/**
 * Hook for tips / discounts with dollars or percents
 * @param {{ initialValueFromFirstType: string | number, paymentTypes: { first: { value: string, initial: string | number }, second: { value: string, initial: string | number } }}}
 * @returns
 */
export const usePaymentAdditional = ({
  paymentTypes,
  initialValueFromFirstType,
}) => {
  const [isPaymentAdditionalAvailable, setIsPaymentAdditionalAvailable] =
    useState(false);
  const paymentAdditionalNowAvailable = () =>
    setIsPaymentAdditionalAvailable(true);
  const paymentAdditionalBecomeUnavailable = () =>
    setIsPaymentAdditionalAvailable(false);

  const [isOther, setIsOther] = useState(false);

  const enableOtherMode = () => setIsOther(true);
  const disableOtherMode = () => setIsOther(false);

  const [paymentOption, setPaymentOption] = useState(initialValueFromFirstType);

  const onChangePaymentOption = (newOption) => {
    if (!isPaymentAdditionalAvailable) {
      paymentAdditionalNowAvailable();
    }

    setPaymentOption(newOption);
  };

  const [paymentType, setPaymentType] = useState(paymentTypes.first.value);

  const onChangePaymentType = (newType) => {
    if (newType === paymentTypes.first.value) {
      setPaymentType(paymentTypes.first.value);
    } else if (newType === paymentTypes.second.value) {
      setPaymentType(paymentTypes.second.value);
    }
  };

  const isFirstType = () => paymentType === paymentTypes.first.value;
  const isSecondType = () => paymentType === paymentTypes.second.value;

  return {
    isOther,
    setIsOther,
    enableOtherMode,
    disableOtherMode,

    isPaymentAdditionalAvailable,
    setIsPaymentAdditionalAvailable,
    paymentAdditionalNowAvailable,
    paymentAdditionalBecomeUnavailable,

    paymentOption,
    onChangePaymentOption,

    paymentType,
    onChangePaymentType,
    isFirstType,
    isSecondType,
  };
};
