import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useState, useEffect, useContext } from "react";
import { DrawerRemoveStaffConfirmContainer } from "./style";
import { useParams } from "react-router-dom";

export default function DrawerRemoveStaffConfirm({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {postStaff, setStaffs, staffRemoveInsideEditOption, setStaffRemoveInsideEditOption} = useContext(RestaurantsContext);

  const { id } = useParams();

  useEffect(() => {
    //  getStaffDataList();
  }, [id]);

  function handleClickStaffRemove() {
    customAxios.delete(`/restaurant/${id}/staff/${postStaff?.id}`)
      .then((res) => {
        if (res.status === 200) {
          setStaffRemoveInsideEditOption({
            ...staffRemoveInsideEditOption,
            staffRemove: false,
            staffRemoveConfirm: false,
          });
          closePanel();
          getStaffDataList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getStaffDataList() {
    customAxios
      .get(`/restaurant/${id}/staff`)
      .then((res) => {
        setStaffs([...res?.data?.current_staff?.staff, ...res?.data?.pending_staff?.staff])
      })
      .catch((err) => console.error(err));
  }

  return (
    <DrawerRemoveStaffConfirmContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left main-title">
          Are you sure?
        </h2>
        <p className="text-white text-left text-font">
          <span className="text-bold">{`${postStaff?.first_name} ${postStaff?.last_name}`}</span>{" "}
          will no longer be able to access their account.
        </p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold move-item-btn"
            onClick={handleClickStaffRemove}
          >
            Yes, remove account
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold"
            // onClick={closePanel}
            onClick={() => {
              setStaffRemoveInsideEditOption({
                ...staffRemoveInsideEditOption,
                staffRemoveConfirm: false,
              });
            }}
          >
            Keep
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerRemoveStaffConfirmContainer>
  );
}
