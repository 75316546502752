import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const ApplyPromoCodeModalContainer = styled(MyDrawerCenterModal)`
  .apply-promo-code-modal {
    position: relative;
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-meta {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-actions {
      button {
        width: 100%;
      }
      .apply-btn {
        margin-bottom: 10px;
      }
    }
    .enter-promo-code-div {
      input {
        width: 100%;
        height: 60px;
        padding: 20px 0;
        background: transparent;
        border: none;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        outline: none;
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
        font-weight: 500;
      }
      margin-bottom: 24px;
    }
  }
`;

export const ConfirmDeleteSubOrderModalContainer = styled(MyDrawerCenterModal)`
  .delete-suborder-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .delete-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 18px;
      }
    }
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .delete-suborder-meta {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-actions {
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;
