import React, { Fragment, useEffect, useState, useContext } from "react";
import { RestaurantPromoCodeContainer } from "./style";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { Badge } from "react-bootstrap";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";
import PromoCodeModal from "./PromoCodeDrawerModal";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawersRestaurants } from "static/staticData";
import moment from "moment";
import ViewAllDrawerModal from "./PromoCodeViewAllDrawerModal";
import EmptyImage from "assets/img/no-filter-data.png";
import { Switch } from "@material-ui/core";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import PromoStatusModal from "./PromoStatusModal"

export const PromoCode = ({ profile }) => {
  const { id } = useParams();

  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    updatePromoCodeDetails,
    setUpdatePromoCodeDetails,
    couponData,
    setCouponData,

    searchPromoCode,
    setSearchPromoCode,

    finalDiscountDollarFrom,
    setFinalDiscountDollarFrom,

    finalDiscountDollarTo,
    setFinalDiscountDollarTo,

    finalDiscountPercentTo,
    setFinalDiscountPercentTo,

    finalDiscountPercentFrom,
    setFinalDiscountPercentFrom,
    setStatusActiveNotification,
    statusActiveNotification,
  } = useContext(RestaurantsContext);

  // const [couponData, setCouponData] = useState([]);

  const [promoCodeStatus, setPromoCodeStatus] = useState(1);

  useEffect(() => {
    if (profile?.status !== 3 && profile?.status !== undefined) {
      getPromoCodeData();
    }
  }, [
    id,
    promoCodeStatus,
    profile?.status,
    // searchPromoCode,
    // finalDiscountPercentTo,
    // finalDiscountPercentFrom,
    // finalDiscountDollarTo,
    // finalDiscountDollarFrom,
  ]);

  useEffect(() => {
    if (statusActiveNotification) {
      setTimeout(() => setStatusActiveNotification(null), 2 * 1000);
    }
  }, [statusActiveNotification]);

  function getPromoCodeData() {
    let url;
    if (promoCodeStatus) {
      url = `/coupon?status=${promoCodeStatus}&restaurant_id=${id}&scope=1&query=${searchPromoCode}`;
    }
    if (finalDiscountPercentTo) {
      url = `/coupon?status=${promoCodeStatus}&restaurant_id=${id}&scope=1&query=${searchPromoCode}&discount_percent=${finalDiscountPercentFrom},${finalDiscountPercentTo}`;
    }
    if (finalDiscountDollarTo) {
      url = `/coupon?status=${promoCodeStatus}&restaurant_id=${id}&scope=1&query=${searchPromoCode}&discount_dollar=${finalDiscountDollarFrom},${finalDiscountDollarTo}`;
    }
    if (finalDiscountDollarTo && finalDiscountPercentTo) {
      url = `/coupon?status=${promoCodeStatus}&restaurant_id=${id}&scope=1&query=${searchPromoCode}&discount_percent=${finalDiscountPercentFrom},${finalDiscountPercentTo}&discount_dollar=${finalDiscountDollarFrom},${finalDiscountDollarTo}`;
    }

    customAxios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCouponData(res?.data?.reverse());
        } else {
          if (res.status === 204) {
            setCouponData([]);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // tabs method
  const handleSelect = (key) => {
    if (key === "1") {
      setPromoCodeStatus(1);
    } else if (key === "2") {
      setPromoCodeStatus(2);
    }
  };

  function updateCouponDetail(promoCodedetails) {
    // console.log(promoCodedetails)
    setUpdatePromoCodeDetails(promoCodedetails);
    openDrawer(drawersRestaurants.promoStatusModal);
    // openDrawer(drawersRestaurants.addPromoCodeModal);
  }

  // console.log(promoCodeStatus)

  const [copiedCode, setCopiedCode] = useState("");

  const copyToClipboard = (code) => {
    setCopiedCode(code);
    navigator.clipboard.writeText(code);
    setTimeout(() => {
      setCopiedCode("");
    }, 4000);
  };

  const updateInActivePromoCode = (promocode, statusValue) => {
    let data = [...couponData];
    let findIndex = data.findIndex((el) => el?.id === promocode?.id);
    data[findIndex].status = statusValue;
    setCouponData(data);
    const configUpdatePromoCode = {
      status: statusValue,
    };

    configUpdatePromoCode.restaurant_id = id;

    customAxios
      .patch(`/coupon/${promocode?.id}`, configUpdatePromoCode)
      .then((res) => {
        if (res.status === 200) {
          getPromoCodeData();
          if (statusValue === 1) {
            setStatusActiveNotification({
              message: "Promo Code Active.",
              type: "promo-active",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promocode?.name} • ${promocode?.code}</span>`,
            });
          } else {
            setStatusActiveNotification({
              message: "Promo Code Inactive.",
              type: "promo-inactive",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promocode?.name} • ${promocode?.code}</span>`,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <RestaurantPromoCodeContainer>
      {statusActiveNotification && (
        <MyMessageBoxItemNew
          message={
            statusActiveNotification?.message
              ? statusActiveNotification?.message
              : ""
          }
          table={
            statusActiveNotification?.table
              ? statusActiveNotification?.table
              : ""
          }
          type={
            statusActiveNotification?.type ? statusActiveNotification?.type : ""
          }
          onClick={() => setStatusActiveNotification(null)}
        />
      )}
      <div className="promo-codes-tab">
        <Tab.Container
          id=""
          defaultActiveKey={1}
          activeKey={promoCodeStatus}
          onSelect={handleSelect}
        >
          <Row className="">
            <Col>
              <Nav variant="pills" className="d-flex">
                <Nav.Item>
                  <Nav.Link eventKey={"1"}>Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"2"}>Inactive</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* onClick={handleShow} */}
              <div
                className="mr-3 add-promo-btn"
                onClick={() => {
                  openDrawer(drawersRestaurants.addPromoCodeModal);
                  setUpdatePromoCodeDetails("");
                }}
              >
                <FaPlus color="white" className="mr-2" /> Add Promo Code
              </div>{" "}
              <div
                className="view-all"
                onClick={() => openDrawer(drawersRestaurants.viewAll)}
              >
                View All
              </div>{" "}
            </Col>
          </Row>

          {/* tab section */}
          <Row style={{ marginTop: "25px" }}>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey={"1"}>
                  <div>
                    {couponData &&
                    couponData?.filter((el) => el?.status === 1).length > 0 ? (
                      <div className="promo-table">
                        <table>
                          <tbody>
                            {couponData?.map((promoCode, index) => {
                              return (
                                <tr key={`staff-${index}`}>
                                  <td>
                                    <div className="name-container">
                                      <span className="staff-avatar-title">
                                        {promoCode?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-container-normal">
                                    <span
                                      style={{
                                        padding: "8px 6px",
                                        background: "#1b1c1d",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      {promoCode?.code}{" "}
                                      <i
                                        class="fas fa-copy"
                                        style={{
                                          color:
                                            copiedCode === promoCode?.code
                                              ? "#fff"
                                              : "#767777",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          copyToClipboard(promoCode?.code);
                                        }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-container-normal">
                                    {promoCode?.end_date
                                      ? `${moment(promoCode?.start_date).format(
                                          "ll"
                                        )} - ${moment(
                                          promoCode?.end_date
                                        ).format("ll")}`
                                      : "Not Limited"}
                                  </td>
                                  <td
                                    className="text-container-normal"
                                    style={{
                                      color: "#1fc085",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    {promoCode?.discount_type === 1
                                      ? `${Number(promoCode?.discount).toFixed(
                                          0
                                        )}%`
                                      : `$${promoCode?.discount}`}
                                  </td>
                                  <td style={{ opacity: 1 }}>
                                    <span
                                      style={{
                                        justifyContent: "flex-end",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        updateCouponDetail(promoCode);
                                      }}
                                    >
                                      <div className="toggle-btn">
                                        <Switch
                                          checked={
                                            promoCode?.status === 2
                                              ? false
                                              : true
                                          }
                                          // onChange={() => {
                                          //   updateInActivePromoCode(
                                          //     promoCode,
                                          //     2
                                          //   );
                                          // }}
                                        />
                                      </div>
                                      {/* <i
                                        className="fas fa-cog"
                                        style={{
                                          color: "#767777",
                                          fontSize: "18px",
                                        }}
                                      /> */}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="empty-container">
                        <img src={EmptyImage} alt="" />
                        <div className="empty-screen">
                          There are no promo <br /> codes to show yet.
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={"2"}>
                  <div>
                    {couponData &&
                    couponData?.filter((el) => el.status === 2).length > 0 ? (
                      <div className="promo-table">
                        <table>
                          <tbody>
                            {couponData?.map((promoCode, index) => {
                              return (
                                <tr key={`staff-${index}`}>
                                  <td>
                                    <div className="name-container">
                                      <span className="staff-avatar-title">
                                        {promoCode?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-container-normal">
                                    <span
                                      style={{
                                        padding: "8px 6px",
                                        background: "#1b1c1d",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      {promoCode?.code}{" "}
                                      <i
                                        class="fas fa-copy"
                                        style={{
                                          color:
                                            copiedCode === promoCode?.code
                                              ? "#fff"
                                              : "#767777",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          copyToClipboard(promoCode?.code);
                                        }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-container-normal">
                                    {promoCode?.end_date
                                      ? `${moment(promoCode?.start_date).format(
                                          "ll"
                                        )} - ${moment(
                                          promoCode?.end_date
                                        ).format("ll")}`
                                      : "Not Limited"}
                                  </td>
                                  <td
                                    className="text-container-normal"
                                    style={{
                                      color: "#1fc085",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    {promoCode?.discount_type === 1
                                      ? `${Number(promoCode?.discount).toFixed(
                                          0
                                        )}%`
                                      : `$${promoCode?.discount}`}
                                  </td>
                                  <td style={{ opacity: 1 }}>
                                    <span
                                      style={{
                                        justifyContent: "flex-end",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        updateCouponDetail(promoCode);
                                      }}
                                    >
                                      <div className="toggle-btn">
                                        <Switch
                                          checked={
                                            promoCode?.status === 2
                                              ? false
                                              : true
                                          }
                                          // onChange={() => {
                                          //   updateInActivePromoCode(
                                          //     promoCode,
                                          //     1
                                          //   );
                                          // }}
                                        />
                                      </div>
                                      {/* <i
                                        className="fas fa-cog"
                                        style={{
                                          color: "#767777",
                                          fontSize: "18px",
                                        }}
                                      /> */}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="empty-container">
                        <img src={EmptyImage} alt="" />
                        <div className="empty-screen">
                          There are no promo <br /> codes to show yet.
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      <PromoCodeModal
        isActive={!!openDrawers[drawersRestaurants.addPromoCodeModal]}
        closePanel={() => closeDrawer(drawersRestaurants.addPromoCodeModal)}
        getPromoCodeFun={getPromoCodeData}
      />

        <PromoStatusModal
        isActive={!!openDrawers[drawersRestaurants.promoStatusModal]}
        closePanel={() => closeDrawer(drawersRestaurants.promoStatusModal)}
        getPromoCodeData={getPromoCodeData}
      />

      <ViewAllDrawerModal
        isActive={!!openDrawers[drawersRestaurants.viewAll]}
        closePanel={() => closeDrawer(drawersRestaurants.viewAll)}
        getPromoCodeFun={getPromoCodeData}
        handleSelect={handleSelect}
        promoCodeStatus={promoCodeStatus}
      />
    </RestaurantPromoCodeContainer>
  );
};
