import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerFilterCustomersContainer = styled(MyDrawerRight)`
  .drawer-content {
    margin-top: 30px;

    .status-items {
      display: flex;
      gap: 30px;
      margin-top: 15px;
    }

    .location-wrapper {
      margin-top: 35px;
      .location-label {
      }

      .location-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: baseline;

        .location-input {
          margin-top: 25px;
          width: 100%;
          height: 38px;
          border-radius: 99px;
          border: solid 1px #525558;
          background: transparent;
          padding: 20px;
          color: #fff;
        }

        .close-icon {
          position: relative;
          right: 29px;
          align-items: center;
          display: flex;
          cursor: pointer;
        }
      }

      input:focus-visible {
        outline: unset;
      }

      .google-location-wrapper {
        ul {
          background-color: #1b1c1d;
          margin-top: 9px;
          border-radius: 20px;
          padding: 10px 20px;
          border: 1px solid #525558;
        }
        li {
          list-style: none;
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }

    .cc-file-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 39px;
    }

    .profile-photo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
    }

    .joined-date-wrapper {
      .join-date-label {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
      .join-date-input {
        background: transparent;
        border-radius: 20px;
        padding: 10px 20px;
        color: #fff;
        /* border: 1px solid #525558; */
        width: 100%;
        margin-top: 25px;
        outline: unset;
      }
      margin-top: 39px;
      .date-text {
        .date-picker-label {
          font-weight: bold;
        }
      }
    }

    .status-wrapper {
      .status-items {
        .status-item {
        }
      }
    }

    /* material-ui switch custom css */
    .MuiIconButton-label {
      color: #fff;
    }

    .MuiSwitch-track {
      background-color: #5f626a;
      opacity: unset;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #ed2626 !important;
      opacity: unset;
    }
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
`;
