import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const ApproveRestaurantModalContainer = styled(MyDrawerCenterModal)`
  .restaurant-approval-div {
    position: relative;
    /* &:before {
      content: "";
      width: 133px;
      height: 123px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -104px;
      left: 144px;
    } */
    .modal-image {
      position: absolute;
      left: 12rem;
      top: -7rem;
      img {
        width: 111px;
        height: 111px;
      }
    }
    .modal-title {
      margin-top: 45px;
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-bottom: 8px;
    }
    .modal-restaurant-name {
      opacity: 0.5;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.38px;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
    }
    .modal-meta {
      margin-top: 16px;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
    }
    .approve-modal-inputs {
    }
    .modal-bottom {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        width: 100% !important;
      }
      .approve-btn {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ApproveInputContainer = styled.div`
  .drawer-input-container[data-value] {
    position: relative;
  }
  .drawer-input-container[data-value]:after {
    content: ${(props) =>
      props.isInputValuePresent ? `attr(data-value) "%"` : "unset"};
    position: absolute;
    top: 6px; /* Borders */
    // left: 2px; /* Borders */
    font-weight: bold;
  }
`;
