import styled from "@emotion/styled";

export const AnalyticPeriodContainer = styled.div`
  display: flex;

  .period-option {
    padding: 8px 0px;
    width: 91px;
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: 14px;
    }

    &.checked {
      color: white;
      background-color: #3e4245;
      border-radius: 25px;
      font-weight: bold;

      i {
        color: white;
      }
    }
  }

  #calendar-popup-handle {
    padding: 0 10px 0 10px;
  }
`;

export const PopupCalendarContainer = styled.div`
  padding: 20px 10px;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  background-color: #27282a;
  min-width: 700px;
  z-index: 999;
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0;
  }
  .apply-date {
    float: right;
    margin-right: 25px;
    width: 30%;
  }
`;
