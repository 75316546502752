import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { DrawerVerifyWebsiteContainer } from "./style";

export default function DrawerVerifyWebsite({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  return (
    <DrawerVerifyWebsiteContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="phone-title">Website</div>
          <div></div>
        </div>
        <div className="drawer-status--options">
          <DrawerChooseItem
            className="mb-4"
            title="Verified"
            // active={selectedMenu?.status === 1}
            // onChangeStatus={onChangeStatus}
          />
          <DrawerChooseItem
            title="Unverified"
            // subtitle="Not visible to customers"
            // active={selectedMenu?.status !== 1}
            // onChangeStatus={onChangeStatus}
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto"
            // onClick={onSaveProfile}
          >
            Save
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerVerifyWebsiteContainer>
  );
}
