import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { restaurantServices } from "services";
import { DrawerDeleteItemContainer } from "./style";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerDeleteItem({
  profile = {},
  isActive = false,
  closePanel = () => {},
  callBack = () => {},
}) {
  const { id } = useParams();
  const { postFood, categoryId } = useContext(RestaurantsContext);

  const [deleteItemNotification, setDeleteItemNotification] = useState(null);

  useEffect(() => {
    if (deleteItemNotification) {
      setTimeout(() => setDeleteItemNotification(null), 2 * 1000);
    }
  }, [deleteItemNotification]);

  useEffect(() => {}, [id, postFood, categoryId]);

  const onDeleteItem = async () => {
    customAxios
      .delete(`/restaurant/${id}/food/${postFood?.id}?category=${categoryId}`)
      .then((res) => {
        closePanel();
        callBack();
        setDeleteItemNotification({
          message: `Item Deleted.`,
          type: "remove",
          table: ``,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCancel = () => {
    closePanel();
  };

  useEffect(() => {}, [postFood]);

  return (
    <DrawerDeleteItemContainer
      isActive={isActive}
      closePanel={closePanel}
      top="30%"
    >
      {deleteItemNotification && (
        <MyMessageBoxItemNew
          message={
            deleteItemNotification?.message
              ? deleteItemNotification.message
              : ""
          }
          table={
            deleteItemNotification?.table ? deleteItemNotification.table : ""
          }
          type={
            deleteItemNotification?.type
              ? deleteItemNotification.type
              : "success"
          }
          onClick={() => setDeleteItemNotification(null)}
        />
      )}

      <DrawerContainer>
        <h2>Delete Item</h2>
        <h2 className="confirmation-delete mt-3">
          Are you sure you want to delete{" "}
          <span className="text-bold">{postFood?.title}</span>?
        </h2>
        <p className="mt-3">This cannot be undone.</p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold "
            onClick={onDeleteItem}
          >
            <b>Delete</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold cancel-btn"
            onClick={onCancel}
          >
            <b>Keep</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDeleteItemContainer>
  );
}
