import React from "react";
import { GuestsListContainer } from "./style";
import { Image } from "react-bootstrap";
import { NoAvatar } from "assets/img";

const GuestsList = ({ guestsList }) => {
  return (
    <GuestsListContainer className="d-flex flex-column">
      {guestsList &&
        guestsList.map((guest) => {
          return (
            <div className="d-flex  align-items-center">
              <div className="guests-avtar">
                <Image src={guest.avatar ? guest.avatar : NoAvatar} />
              </div>
              <div className="guests-name d-flex">
                {guest.first_name} {guest.last_name.charAt(0)}.
              </div>
            </div>
          );
        })}
    </GuestsListContainer>
  );
};

export default GuestsList;
