import styled from "@emotion/styled";
import { colors } from "theme";

export const StatItemContainer = styled.div`
  min-height: 93px;
  background-color: ${colors.gray2};
  border-radius: 20px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 28px;
  width: ${(props) => (props.width ? props.width : "406px")};
  background-image: ${(props) =>
    props.backGroundImage ? `url(${props.backGroundImage})` : ""};
  background-position: ${(props) => (props.bottom ? "bottom" : "top")};
  background-repeat: no-repeat;
  background-size: cover;
  .stat-item-header {
    font-size: 16px !important;
    color: ${colors.white};
    font-weight: 500;
    letter-spacing: -0.38px;
    i {
      color: ${colors.white};
      margin-right: 10px;
    }
  }
  .stat-item-footer-counter {
    opacity: 1;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: #fff;
  }
`;
