import React, { useContext, useEffect } from "react";
import { DrawerContainer } from "components/styledStyle";
import { DrawerCheckExistsCategoryContainer } from "./style";
import alreadyExistMenuImage from "assets/img/already_exist.png";
import { RestaurantsContext } from "context/RestaurantsContext";

export default function DrawerCheckExistsCategory({
  isActive = false,
  closePanel = () => {},
}) {
  const { postCategory } = useContext(RestaurantsContext);

  return (
    <DrawerCheckExistsCategoryContainer
      isActive={isActive}
      closePanel={closePanel}
      top="35%"
      zIndexBackground="9999"
      width={"471px"}
    >
      <DrawerContainer>
        <div>
          <div className="img-wrapper">
            <img className="menu-exist" src={alreadyExistMenuImage} alt="" />
          </div>
          <div>
            <h2 className="text-bold">Oops!</h2>
            <h2 className="confirmation-delete mt-3 mb-3">
              We’re sorry,{" "}
              <span className="text-bold">{postCategory?.name}</span> category
              already exists in this <br /> menu. Try another name for the
              category, please.
            </h2>
            <div className="bottom-btns" onClick={closePanel}>
              <div className="text-bold">Okay</div>
            </div>
          </div>
        </div>
      </DrawerContainer>
    </DrawerCheckExistsCategoryContainer>
  );
}
