import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
// import { MyDrawerLeftArrow } from "components/Elements/MyDrawerLeftArrow";
import { colors } from "theme";

export const DrawerElementContainer = styled(MyDrawerCenterModal)`
  /* background-color: ${colors.gray2}; */
  background-color: #27282a;
  border-radius: 20px;
  .drawer-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .drawer-option {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 62px;
      padding: 0 26px;
      border-radius: 16px;
      border: solid 1px rgba(255, 255, 255, 0.1);
      p {
        margin: 0;
      }
      &.active {
        border: solid 1px rgba(255, 255, 255, 1);
        transition: 0.5s ease;
      }
      &.inactive {
        border: solid 1px rgba(255, 255, 255, 0.1);
        transition: 0.5s ease;
      }
    }
  }
`;
