import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { colors } from "theme";

export const DrawerAddEditClosedDayContainer = styled(MyDrawerBottom)`
  .add-edit-closed-day {
    .add-edit-form-header {
      margin-bottom: 27px;
      .add-edit-form-title {
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.42px;
        color: #fff;
      }
      .fa-trash {
        opacity: 0.5;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        text-align: right;
        color: #fff;
      }
    }
    .divider {
      width: 386px;
      height: 1px;
      margin-left: -24px;
      opacity: 0.1;
      background-color: #fff;
    }
    .input-actions {
      .repeated-annualy-switch-div {
        .repeated-annulay-text {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: -0.34px;
          color: #fff;
        }
        .required-filed {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 18px 0 13px 0;
          padding-bottom: 9px;
          .switch-refill {
            position: relative;
            display: inline-block;
            width: 46px;
            height: 24px;
            transform: translateY(5px);
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 18px;
              width: 18px;
              left: 3px;
              bottom: 3px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            input:checked + .slider {
              background-color: ${colors.redColor};
            }

            input:focus + .slider {
              box-shadow: 0 0 1px #2196f3;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(22px);
              -ms-transform: translateX(22px);
              transform: translateX(22px);
            }

            /* Rounded sliders */
            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .bottom-btns {
    margin-top: 58px;
    button {
      margin-bottom: 20px;
    }
  }
`;
