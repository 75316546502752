import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { ScaleLoader } from "react-spinners";
import { SingleVideoUploaderContainer } from "./style";
import { showMessage } from "services/functions";
import { DefaultFood } from "assets/img";
import { assetServices } from "services/assetServices";

export const useBinaryState = (initialValue = false) => {
  const [isActive, setActive] = useState(initialValue);

  const activate = () => setActive(true);
  const deactivate = () => setActive(false);

  return {
    isActive,
    activate,
    deactivate,
  };
};

export default function SingleVideoUploader({
  receiveVideo = () => {},
  placeholder,
  width = "100%",
  height = "100%",
  shape = "circle",
  video = "",
  overlay,
}) {
  const [videoUrl, setVideoUrl] = useState(video);

  const {
    isActive: isUploading,
    activate: startUploading,
    deactivate: endUploading,
  } = useBinaryState();

  const {
    isActive: isHover,
    activate: showHover,
    deactivate: hideHover,
  } = useBinaryState();

  const onUploadLocalFileToAssetsStorage = async (info) => {
    startUploading();
    const postData = new FormData();
    postData.append("asset", info.target.files[0]);
    postData.append("type", "food");
    postData.append("api_key", process.env.REACT_APP_API_KEY);

    const result = await assetServices.uploadToS3(postData);
    if (result.status == 200) {
      setVideoUrl(result?.data?.file);
      receiveVideo(result);
      endUploading();
    } else {
      showMessage(result?.data?.message, "error");
      endUploading();
    }
  };

  const inputFileRef = useRef();
  const invokeEventForInput = () => inputFileRef.current.click();

  return (
    <SingleVideoUploaderContainer width={width} height={height} shape={shape}>
      <div className="video-uploader" onClick={invokeEventForInput}>
        <div
          className={`video ${shape === "circle" ? "circle" : "square"}`}
          onMouseOverCapture={showHover}
          onMouseLeave={hideHover}
        >
          <input
            className="file-video-input"
            type="file"
            ref={inputFileRef}
            onChange={onUploadLocalFileToAssetsStorage}
            accept="video/*"
          />
          {videoUrl ? (
            <video
              src={videoUrl}
              alt="video"
              className={`video-thumb ${
                shape === "circle" ? "circle" : "square"
              }`}
            />
          ) : (
            <img
              src={placeholder || DefaultFood}
              alt="video"
              className={`video-thumb ${
                shape === "circle" ? "circle" : "square"
              }`}
            />
          )}
          {isUploading && (
            <div className="uploading-icon">
              <ScaleLoader size={15} color={"white"} />
            </div>
          )}
          <div className="video-hover">
            {overlay || (
              <div className="change-container">
                <span className="change-text">Change</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </SingleVideoUploaderContainer>
  );
}

// SingleVideoUploader.propTypes = {
//   uploading: PropTypes.bool,
//   receiveVideo: PropTypes.func,
//   placeholder: PropTypes.any,
//   src: PropTypes.any,
//   width: PropTypes.string,
//   height: PropTypes.string,
//   shape: PropTypes.string,
//   overlay: PropTypes.node,
//   video: PropTypes.string,
// };
