import styled from "@emotion/styled";
import { colors } from "theme";

export const MyDrawerBottomContainer = styled.div`
  .offcanvas {
    background-color: ${colors.gray2};
    right: 0;
    top: unset;
    bottom: -120%;
    border-radius: 40px 40px 0 0;
    .drawer-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  /* .offcanvas-overlay {
    z-index: 1001;
  } */
`;
