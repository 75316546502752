import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerEditCategoryContainer = styled(MyDrawerBottom)`
  .top-title {
    margin-bottom: unset;
  }
  .drawer-categories-wrapper {
    .input-div {
      margin-top: 30px;
    }
  }
  .bottom-btns {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
  }
`;
