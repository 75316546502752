import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyFormatInput from "components/Elements/MyFormatInput";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useState, useEffect, useContext } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { DrawerInviteStaffContainer } from "./style";
import { useParams } from "react-router-dom";
import DrawerInviteSend from "../DrawerInviteSent";
import { drawersStaff } from "static/staticData";

export default function DrawerInviteStaff({
  isActive = false,
  closePanel = () => {},
  addStaffDrawers,
  setAddStaffDarwers,
  getStaffAll,
  setNotification,
}) {
  const {
    selectedStaffRole,
    closeDrawer,
    openDrawers,
    openDrawer,
  } = useContext(RestaurantsContext);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const { id } = useParams();

  function handleClickInviteStaff() {
    const postDataConfig = {
      phone_number: 1 + phoneNumber,
      role_slug: selectedStaffRole,
    };
    customAxios
      // .post(`/restaurant/${id}/staff`, postDataConfig)
      .post(`/staff/?restaurant_id=${id}`, postDataConfig)
      .then((res) => {
        // console.log(res.data);
        // openDrawer(drawersStaff.staffInviteSend);
        setPhoneNumber("");
        setFormattedPhoneNumber("");
        setAddStaffDarwers({
          staffChooseRole: false,
          staffInvite: false,
          staffInviteSend: false,
        });
        setNotification({
          message: "Invite Sent.",
          table: `<span style="font-weight:bold; margin-left:0; color:#7d7e7f">
          ${"+1" + formattedPhoneNumber}
      </span> has been successfully invited. This account will be pending until the user accepts the invitation.`,
          type: "active-success",
        });
        // setAddStaffDarwers({ ...addStaffDrawers, staffInviteSend: true });
        getStaffAll();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onChangePhoneNumber = (values) => {
    setPhoneNumber(values.value);
    setFormattedPhoneNumber(values.formattedValue);
  };

  return (
    <DrawerInviteStaffContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Invite Staff</div>
          <div></div>
        </div>

        <div className="content-wrapper">
          <span>Invite someone to join your team.</span>
          <p>
            They’ll receive a text with instructions on how to log-in to their
            account.
          </p>
        </div>

        <div className="staff-phone-number">
          <span className="before-input-title">+1</span>
          <MyFormatInput
            placeholder="Enter their mobile number"
            height="40px"
            type="underline"
            value={formattedPhoneNumber}
            onChange={onChangePhoneNumber}
            format="(###) ###-####"
          />
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type={phoneNumber.length === 10 ? "red" : "gray"}
            className="text-bold"
            onClick={handleClickInviteStaff}
          >
            Send Invite
          </MyDrawerButton>
          <MyDrawerButton
            type={"transparent"}
            className="text-bold mt-6"
            onClick={() => {
              setAddStaffDarwers({
                staffChooseRole: false,
                staffInvite: false,
                staffInviteSend: false,
              });
            }}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      {/* <DrawerInviteSend
        isActive={addStaffDrawers.staffInviteSend}
        closePanel={() => {
          setAddStaffDarwers({
            staffChooseRole: false,
            staffInvite: false,
            staffInviteSend: false,
          });
        }}
      /> */}
    </DrawerInviteStaffContainer>
  );
}
