import React from "react";
import { DrawerChangeFestttFeeAllRestaurantContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerChangeFestttFeeAllRestaurant = ({
  isActive = false,
  closePanel = () => {},
  restaurantStats = {},
  editFeastttFeeValue = {},
  onDoneChangeAllRestaurants = () => {},
}) => {
  return (
    <DrawerChangeFestttFeeAllRestaurantContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"37%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="change-feasttt-fee-modal">
          <div className="delete-icon">
            <i className="fas fa-coins" />
          </div>
          <div className="change-feasttt-fee-modal-title">
            Change Feasttt Fee
          </div>
          <div className="change-feasttt-fee-modal-meta">
            You are about change the Feasttt Fee rate &nbsp;
            <span
              style={{
                fontWeight: "normal",
              }}
            >
              from &nbsp;
            </span>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {restaurantStats?.feast_fees || ""}% &nbsp;
            </span>
            to &nbsp;
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {editFeastttFeeValue}% &nbsp;
            </span>
            for{" "}
            <span
              style={{
                color: "#ed2626",
                fontWeight: "700",
              }}
            >
              ALL
            </span>{" "}
            restaurants.
          </div>
          <div className="change-feasttt-fee-modal-meta">
            Are you sure you want to make this change?
          </div>
          <MyDrawerButton
            type="red"
            className="mb-6 w-100"
            onClick={() => {
              onDoneChangeAllRestaurants();
            }}
          >
            <b>Change for ALL restaurants</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className=" w-100"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerChangeFestttFeeAllRestaurantContainer>
  );
};

export default DrawerChangeFestttFeeAllRestaurant;
