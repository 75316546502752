import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const CashPaymentModalContainer = styled(MyDrawerCenterModal)`
  .remove-item-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .delete-icon {
      background-color: #1fc086;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 18px 13px;
      }
    }
    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .delete-suborder-meta1 {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
    }

    .discount-options-container {
        margin-top:24px;
        width: 100%;
        max-height: 94px;
        /* border-top: 1px solid rgba(255, 255, 255, 0.08); */
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.08); */
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 12px;
        place-items: center;
        // padding: 0 28px;
        margin-bottom: 48px;
    
        .discount-option {
          width: 100%;
          height: 100%;
          padding-top: 26px;
          padding-bottom: 26px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          border-radius: 16px;
          border: solid 1px #5b5e61;
    
          &.active {
            /* background-color: ${colors.redColor}; */
            border: solid 2px ${colors.redColor};
          }
    
          .option-title {
            font-size: 15px;
            font-weight: normal;
            letter-spacing: -0.36px;
            color: white;
            margin-top: 5px;
          }
    
          .option-title-header {
            font-size: 20px;
            font-weight: bold;
            letter-spacing: -0.36px;
            color: white;
          }
        }
        .type-value-container {
          width: 50%;
          margin: 0 auto;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          input {
            color: white;
          }
        }
    }

    .type-title,
    .type-value {
      font-size: 20px;
      line-height: 14px;
      letter-spacing: -0.34px;
      color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
    }

    .type-title {
      margin-bottom: 3px;
    }

    .type-value-container {
      display: flex;
      align-items: baseline;

      &.percents {
        input {
          text-align: right;
        }
      }

      input {
        width: 100%;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        background-color: transparent;
        border: 0px solid transparent;
        outline: none;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 4px;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.5);
        font-weight: bold;
      }
    }
    .delete-suborder-meta {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .delete-suborder-actions {
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;
