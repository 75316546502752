import { NoAvatar } from "assets/img";
import React, { useEffect, useState } from "react";
import { StaffListContainer } from "./style";
import moment from "moment";
import { customAxios } from "helpers/custom-helpers/customAxios";
import NumberFormat from "react-number-format";
export const StaffList = (props) => {
  const {
    isSelected,
    customOnClick,
    goToProfile,
    customerListTab,
    customer,
    selectedCustomers,
    searchInput,
  } = props;

  
  
  const customHandler = (event) => {
    if (event) {
      event.stopPropagation();
      customOnClick();
    }
  };

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const columnRenderHandler = () => {
    if (customerListTab === 1) {
      return (
        <>
          <div
            className="item-icon item-flex-small"
            onClick={customHandler}
            style={{
              cursor: "pointer",
            }}
          >
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.avatar|| NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-normal">
            {customer?.first_name === undefined
              ? "No Name"
              : getHighlightedText((customer?.first_name + ' ' + customer?.last_name.charAt(0)), searchInput, "#ed2626")}
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(customer?.joining_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="status-active item-flex-normal ">
            <div className="active-status">Active</div>
          </div>
          <div className="rating-active item-flex-normal">
            <div className="">{customer.rating}</div>
          </div>
          <div className="item-last-location item-flex-normal">{customer.restaurants}</div>
          <div className="item-sales item-flex-normal">
          <NumberFormat
                        format={"+1 (###) ###-####"}
                        className={`item-sales `}
                        value={customer.phone_number || ""}
                        onValueChange={(e) => {}}
                        onBlur={() => {}}
                        placeholder={"Set Mobile Number"}
                        readOnly
                      />
          </div>
          <div className="item-fees item-flex-last">
            {customer.email}
          </div>
        </>
      );
    } else if (customerListTab === 0) {
      return (
        <>
          <div
            className="item-icon item-flex-small"
            onClick={customHandler}
            style={{
              cursor: "pointer",
            }}
          >
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.avatar || NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-normal">
            {customer?.first_name === undefined
              ? "No Name"
              : getHighlightedText((customer?.first_name + ' ' + customer?.last_name.charAt(0)), searchInput, "#ed2626")}
          </div>
          {/* <div className="item-status item-flex-normal inactive-inactivated">
            <div className=""> Mar 2, 2023</div>
          </div> */}
          <div className="item-joined-date item-flex-normal">
            {moment(customer?.joining_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="status-active item-flex-normal ">
            <div className="inactive-status">Inactive</div>
          </div>
          <div className="item-flex-normal rating-active">
            <div className="">{customer?.rating || "-"} </div>
          </div>
          <div className="item-last-location item-flex-normal">{customer?.restaurants || 0}</div>
          <div className="item-flex-normal item-sales">
          <NumberFormat
                        format={"+1 (###) ###-####"}
                        className={`item-sales `}
                        value={customer.phone_number || ""}
                        onValueChange={(e) => {}}
                        onBlur={() => {}}
                        placeholder={"Set Mobile Number"}
                        readOnly
                      />
          </div>
          <div className="item-fees item-flex-last">{customer?.email}</div>
        </>
      );
    } else if (customerListTab === 9) {
      return (
        <>
          <div className="item-icon item-flex-small" onClick={customHandler}>
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.staff_avatar|| NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-normal">
            {customer?.staff_name === undefined
              ? "No Name"
              : getHighlightedText(customer?.staff_name, searchInput, "#ed2626")}
          </div>
          <div className="item-status item-flex-normal banned">
            <div className="">{moment(customer?.banned_date).format(`MMM DD, YYYY`)}</div>
          </div>
          <div className="item-joined-date item-flex-normal banned-joined">
            {moment(customer?.joined_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="item-flex-normal banned-status-table status-active">
            <div className="banned-status">Banned</div>
          </div>
          <div className="item-status item-flex-normal banned-rating">
            <div className="">{customer?.rating || "-"}</div>
          </div>
          <div className="item-flex-normal banned-by">{customer?.admin_name || "-"}</div>
          <div className="item-sales item-flex-normal">
          <NumberFormat
                        format={"+1 (###) ###-####"}
                        className={`item-sales `}
                        value={customer.staff_phone || ""}
                        onValueChange={(e) => {}}
                        onBlur={() => {}}
                        placeholder={""}
                        readOnly
                      />
          </div>
          <div className="item-fees item-flex-last">
           {customer?.staff_email}
          </div>
        </>
      );
    } else if (customerListTab === -1) {
      return (
        <>
          <div className="item-icon item-flex-small" onClick={customHandler}>
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.staff_avatar || NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-normal">
            {customer?.staff_name === undefined
              ? "No Name"
              : getHighlightedText(customer?.staff_name, searchInput, "#ed2626")}
          </div>
          <div className="item-status item-sales item-flex-normal deleted">
            <div className="">{moment(customer?.deleted_date).format(`MMM DD, YYYY`)}</div>
          </div>
          <div className="item-sales item-joined-date item-flex-normal deleted-joined">
            {customer?.joined_date ? moment(customer?.joined_date).format(`MMM DD, YYYY`) : "-"}
          </div>
          <div className="item-status item-sales item-flex-normal deleted-status">
            <div className="banned-status">Deleted</div>
          </div>
          <div className="item-flex-normal item-sales deleted-by">{customer?.deleted_by || "-"}</div>
          <div className="item-sales item-flex-normal">
          <NumberFormat
                        format={"+1 (###) ###-####"}
                        className={`item-sales `}
                        value={customer.staff_phone || ""}
                        onValueChange={(e) => {}}
                        onBlur={() => {}}
                        placeholder={""}
                        readOnly
                      />
          </div>
          <div className="item-fees item-flex-last">{customer?.staff_email || "-"}</div>
        </>
      );
    } else {
    }
  };

  return (
    <StaffListContainer
      className={`customers-item selected-restaurant
      } ${isSelected ? "inner-selected" : ""}`}
      onClick={goToProfile}
      style={{
        cursor: selectedCustomers?.length ? "default" : "pointer",
      }}
    >
      {columnRenderHandler()}
    </StaffListContainer>
  );
};
