/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { drawersStaff } from "static/staticData";
import { ArchivedContainer } from "./style";
import MyPaginator from "components/Elements/MyPaginator";
import { RestaurantsContext } from "context/RestaurantsContext";
import { NoFilterData, NotesEmptyScreen } from "assets/img";
import ArchivedList from "./ArchivedList";
// import AllStaffList from "./AllStaffList";

export const Archived = ({ archivedData, selectedLimitToShow, currentPage, setCurrentPage, totalCount }) => {
  const {
    openDrawer,
    postStaff,
    setPostStaff,
    staffCloseDrawer,
    setStaffCloseDrawer,
    staffs,
  } = useContext(RestaurantsContext);
  // const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);

  const onPageChange = async (e) => {
    setCurrentPage(e.selected + 1);
  };

  const onClickEditStaff = (staff) => {
    setPostStaff(staff);
    openDrawer(drawersStaff.staffEdit);
  };

  const onClickDeleteStaff = (staff) => {
    setPostStaff(staff);
    // openDrawer(drawersStaff.staffDelete);
    setStaffCloseDrawer({
      ...staffCloseDrawer,
      staffDelete: true,
    });
  };

  // console.log(archivedData);
  // console.log(staffs);

  return (
    <ArchivedContainer>
      {archivedData && archivedData.length > 0 ? (
        <div className="orders-table">
          <ArchivedList header={true} />
          {archivedData?.length > 0 &&
            archivedData.map((_staff, _i) => {
              // console.log(_staff);
              return (
                <>
                  <ArchivedList
                    staff={_staff}
                    onClickEdit={onClickEditStaff}
                    onClickDelete={onClickDeleteStaff}
                  />
                </>
              );
            })}

          {archivedData.length > 0 && (
            <div className="mt-5">
              <MyPaginator
                 initialPage={currentPage}
                 totalCount={archivedData.length}
                 limitShow={selectedLimitToShow}
                 onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="no-orders-data-div d-flex flex-column justify-content-center align-items-center">
            <img src={NoFilterData} alt="" />
            <div className="no-result-text">No results</div>
            <div className="no-result-search-meta">
              Try broadening your search.
            </div>
          </div>

          {/* {isSearchFilterApplied || isFilterApplied ? (
            <div className="no-orders-data-div d-flex flex-column justify-content-center align-items-center">
              <img src={NoFilterData} alt="" />
              <div className="no-result-text">No results</div>
              <div className="no-result-search-meta">
                Try broadening your search.
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </>
      )}
    </ArchivedContainer>
  );
};
