import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const AddModalContainer = styled(MyDrawerCenterModal)`
  .add-item-wrapper {
    .clickable-row {
      border-radius: 16px;
      border: solid 1px #5b5e61;
      // background-color: #27282a;
      font-size: 14px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
      margin-top: 16px;
      cursor: pointer;
      &:hover {
        border: solid 1px ${colors.redColor};
      }
    }
    .action-cancel {
      width: 100%;
    }
  }
`;
