import React from "react";
import { MyDrawerButtonContainer } from "./style";

export default function MyDrawerButton({
  children,
  className = "",
  type = "gray",
  onClick = () => {},
  disabled = false,
}) {
  return (
    <MyDrawerButtonContainer
      className={`${className}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MyDrawerButtonContainer>
  );
}
