import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerNotesContainer = styled(MyDrawerRight)`
  .drawer-bottom-content {
    .header-bg {
      position: relative;
      /* min-height: 360px; */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .close-drawer-icon {
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        color: #fff;
        position: absolute;
        left: 23px;
        top: 23px;
        z-index: 999;
        .drawer-title {
          margin-left: 120px;
        }
      }
      .bg-img {
        /* position: absolute; */
        width: 100%;
        height: 100%;
        filter: blur(5px);
        transform: scale(2);
        height: 13rem;
        background-size: cover;
        /* height: 50px; */
      }
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 30px;
        bottom: 0;
        background-color: #1b1c1d;
        border-radius: 30px 30px 0 0;
        z-index: 1;
      }
    }

    .top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }

    .bg-img {
      /* position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(5px);
      transform: scale(2); */
      width: 100%;
    }

    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .panel-status-container {
      height: calc(100vh - 280px);
      position: relative;
      bottom: 7rem;
      z-index: 1;

      .profile-line {
        border: 3px solid #1fc086;
        width: 320px;
        margin: 0 auto;
        position: relative;
        top: 4.7rem;
        z-index: -1;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .upper-part {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-top: 35px; */

        /* position: relative;
        bottom: 7rem;
        z-index: 1; */

        .company-logo {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background: #181919;
          z-index: 2;
        }
        .company-name {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          margin-top: 14px;
        }
      }

      .notes-count {
        padding: 20px;
        font-size: 14px;
        color: #ffffff;
        /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
      }

      .notes-list {
        &::-webkit-scrollbar {
          display: none;
        }
        overflow-y: auto;
        height: calc(100% - 180px);
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: ${colors.gray5};
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: ${colors.white};
          border-radius: 5px;
        }
        .no-note-data {
          margin: 150px 0;
          text-align: center;
          .no-notes-img {
            width: 200px;
          }
          .no-notes-message {
            margin-top: 40px;
            font-size: 16px;
            color: #fff;
            margin-bottom: 105px;
            color: #ffffff;
            opacity: 0.6;
            font-weight: 500;
            font-size: 20px;
          }
        }

        .note-container-item {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
          &:nth-child(odd) {
            background: #1717179e;
          }
          &:nth-child(even) {
            background: transparent;
          }
          .note-image-block {
            width: 45px;
            height: 45px;
            margin-bottom: 15px;
            img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }
          }
          .note-content-block {
            margin-left: 15px;
            p {
              margin-bottom: 0;
            }
            .commentator-name {
              font-size: 14px;
              font-weight: bold;
              color: #fff;
            }
            .commentator-status {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.6);
            }
            .commentator-mail {
              font-size: 14px;
              color: #fff;
            }
          }
          .note-end-block {
            margin-left: auto;
            display: flex;
            /* flex-direction: column; */
            justify-content: flex-end;
            margin-bottom: 2rem;
            p {
              margin-bottom: 0;
            }
            .delete-note {
              cursor: pointer;
              margin-bottom: 20px;
              /* width: 100%; */
              text-align: end;
              height: 15px;
              margin-left: 10px;
              .fa-trash {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.3);
              }
            }
            .delete-note:hover {
              .fa-trash {
                color: #fff;
              }
            }
            .date-note {
              font-size: 13px;
              color: #fff;
              opacity: 0.6;
            }
          }
        }
      }
    }
    .notes-abuse-warning {
      display: flex;
      flex-direction: column;
      border: solid 1px rgba(255, 255, 255, 0.15);
      border-radius: 8px;
      .abuse-part {
        cursor: pointer;
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        i {
          color: #ffffff;
        }
      }
      .change-active-status {
        padding: 5px 0 5px 16px !important;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        i {
          font-size: 14px;
          color: #ffffff;
          margin-right: 10px;
        }
      }
    }
    .form-section {
      width: 28rem;
      margin-bottom: 22px;
      position: absolute;
      bottom: 0;
      padding-left: 2.5rem;
      .notes-abuse-warning {
        display: flex;
        flex-direction: column;
        border: solid 1px rgba(255, 255, 255, 0.15);
        border-radius: 8px;
        .abuse-part {
          cursor: pointer;
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px rgba(255, 255, 255, 0.15);
          /* padding: 0; */
          i {
            color: #ffffff;
          }
          .note-input {
            background: #1b1c1d;
            border: 0;
            color: #fff;
            &:focus-visible {
              outline: unset;
            }
          }
        }
        .change-active-status {
          padding: 5px 0 5px 16px !important;
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          display: flex;
          align-items: center;
          i {
            font-size: 14px;
            color: #ffffff;
            margin-right: 10px;
          }
        }

        textarea {
          overflow: auto;
          resize: vertical;
          background: #27282a;
          /* border-radius: 20px; */
          resize: none;
          color: #ffffff;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          border: none;
          font-size: 15px;
          padding: 5px 10px;
          font-weight: 500;
          &:focus-visible {
            outline: none;
          }
        }

        .note-submit-btn {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
`;
