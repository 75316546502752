import styled from '@emotion/styled';
import Box from 'components/Box';

export const StatusBadgeContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  .value-container {
    width: 32px;
    height: 22px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
