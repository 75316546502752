import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerVerifyEmailContainer = styled(MyDrawerRight)`
  .price-wrapper {
    display: contents;
    li {
      list-style: none;
      padding: 20px;
      border-radius: 13px;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
`;
