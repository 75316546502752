import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerDeleteFoodContainer = styled(MyDrawerBottom)`
  .confirmation-delete {
    font-size: 14px;
    color: #fff;
    margin-bottom: 19px;
  }
  .undone {
    margin-bottom: 41px;
  }
  .cancel-btn {
    margin-top: 10px;
  }
`;
