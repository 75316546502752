import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyDragger from "components/Elements/MyDragger";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useEffect, useState } from "react";
import {
  FaBars,
  FaPen,
  FaTrash,
  FaChevronLeft,
  FaPlus,
  FaPencilAlt,
} from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { colors } from "theme";
import { DrawerCustomizationsContainer } from "./style";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams } from "react-router-dom";
// import DrawerCustomizations from "./Drawers/Food/DrawerCustomizations";
import DrawerAddCustomizations from "./DrawerAddCustomization";
import DrawerEditCustomizations from "./DrawerEditCustomization";
import Box from "components/Box";
import SingleText from "components/SingleText";

export default function DrawerCustomizations({
  menus = [],
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    foodId,
    openDrawers,
    closeDrawer,
    customizationData,
    setCustomizationData,
    setEditCustomizationInfo,
    postFood,
    drawerMenuAdd,
    setDrawerMenuAdd,
  } = useContext(RestaurantsContext);
  const onSortEnd = ({ oldIndex, newIndex }) => {};
  const { id } = useParams();
  // const [customizationData, setCustomizationData] = useState();

  useEffect(() => {
    getCustomizationData();
  }, [id, foodId]);

  function getCustomizationData() {
    if (id && foodId) {
      customAxios
        .get(`/restaurant/${id}/food/${foodId}/customization`)
        .then((res) => {
          setCustomizationData(res?.data || []);
          // console.log(res?.data)
        })
        .catch((err) => {
          console.error(err);
          setCustomizationData([]);
        });
    }
  }

  function handleCustomizationId(getEditItemInfo) {
    setEditCustomizationInfo(getEditItemInfo);
  }

  return (
    <DrawerCustomizationsContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
      onClickRightIcon={() => openDrawer(drawersMenu.deleteMenu)}
    >
      {/* eslint-disable no-unused-expressions */}
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title"></div>
          <div>
            <FaPlus
              size={20}
              color={"#ed2626"}
              className="cursor-pointer"
              onClick={() => {
                // openDrawer(drawersMenu.menuAdd);
                setDrawerMenuAdd({
                  ...drawerMenuAdd,
                  menuAdd: true,
                });
                openDrawer(drawersMenu.openDrawerAddCustomization);
                setEditCustomizationInfo({});
              }}
            />
          </div>
        </div>
        <div className="header-title">
          <div className="title">Customize</div>
          <div className="food-title">{postFood?.title || ""}</div>
        </div>
        {customizationData?.length ? (
          <></>
        ) : (
          <Box className="empty-item">
            <div className="text-wrapper">
              <SingleText>
                <b>There are no customozations yet.</b>
                <br />
              </SingleText>
              <SingleText>
                Add customizations by clicking <FaPlus /> <br /> on the right on
                the top.
              </SingleText>
            </div>
          </Box>
        )}
        <MyDragger
          renderItem={(item) => {
            return (
              <div className="drag-menu-item mb-3">
                <FaBars
                  className="icon-drag"
                  color="rgba(255, 255, 255, 0.3)"
                  size={20}
                />
                {/* <div className="drag-menu-item-content">
                  <p className="drag-menu-item-name mb-0">{item.name}</p>
                </div> */}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  marginRight="auto"
                  marginLeft={19}
                >
                  <SingleText
                    content={item.name}
                    size={14}
                    weight={600}
                    spacing="-0.34px"
                  >
                    {item.name}
                  </SingleText>
                  <SingleText marginTop="8px">
                    {item?.options?.length > 1
                      ? `${item?.options?.length} options`
                      : `${item?.options?.length} option`}{" "}
                    • {item?.required === true ? "Required" : "Optional"}
                  </SingleText>
                </Box>
                <FaPencilAlt
                  size={20}
                  cursor="pointer"
                  // color="white"
                  onClick={() => {
                    openDrawer(drawersMenu.openDrawerAddCustomization),
                      handleCustomizationId(item);
                  }}
                />
              </div>
            );
          }}
          items={customizationData}
          onSortEnd={(param) => onSortEnd(param)}
        />

        {/* eslint-disable no-unused-expressions */}
        <div className="bottom-btns">
          {/* <MyDrawerButton className="text-bold" type="red" onClick={closePanel}>
            Done
          </MyDrawerButton> */}
        </div>
      </DrawerContainer>

      <DrawerAddCustomizations
        isActive={!!openDrawers[drawersMenu.openDrawerAddCustomization]}
        // isActive={true}
        closePanel={() => closeDrawer(drawersMenu.openDrawerAddCustomization)}
      />

      <DrawerEditCustomizations
        isActive={!!openDrawers[drawersMenu.openDrawerEditCustomization]}
        closePanel={() => closeDrawer(drawersMenu.openDrawerEditCustomization)}
      />
    </DrawerCustomizationsContainer>
  );
}
