import React from 'react'
import PropTypes from 'prop-types';
import SingleText from 'components/SingleText';
import { SingleNumberIncreaseContainer } from './style';
import { FaMinus, FaPlus } from 'react-icons/fa';

export default function SingleNumberIncrease({ 
  value = 1,
  onIncrease = () => {},
  onDecrease = () => {},
}) {
  return (
    <SingleNumberIncreaseContainer 
      size={10}
    >
      <div className='handle-btn' onClick={onDecrease}>
        <FaMinus color='white' />
      </div>
      <SingleText size={16} weight={600} family='SFProText' lineHeight={1.38} spacing='-0.38px' className='text-bold'>{value}</SingleText>
      <div className='handle-btn' onClick={onIncrease}>
        <FaPlus color='white' />
      </div>
    </SingleNumberIncreaseContainer>
  )
}

SingleNumberIncrease.propTypes = {
  value: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
};
