import styled from "@emotion/styled";
import { colors } from "theme";

export const DatePickerContainer = styled.div`
  width: 100%;
  /* .date-picker-label {
    margin-bottom: 25px;
  } */
  /* .date-picker {
    height: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    cursor: pointer;
    border-radius: 25px;
    border: solid 1px #525558;
    h6 {
      line-height: 1.57;
      letter-spacing: -0.34px;
      margin-bottom: 0;
      &.date-picker-value {
        color: ${colors.white3};
        font-weight: 500;
      }
    }
  } */

  .date-picker-label {
    margin-bottom: 10px;
  }
  .date-picker {
    height: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: solid 1px #525558;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    h6 {
      line-height: 1.57;
      letter-spacing: -0.34px;
      margin-bottom: 0;
      &.date-picker-value {
        color: ${colors.white3};
        font-weight: 500;
      }
      .placeholder-text {
        color: ${colors.redColor};
      }
    }
    i {
      color: ${colors.white3};
    }
  }
`;
