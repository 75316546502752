/* eslint-disable no-useless-escape */
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React from "react";
import { DrawerRestorePasswordContainer } from "./style";

export default function DrawerRestorePassword({
  isActive = false,
  closePanel = () => {},
}) {
  return (
    <DrawerRestorePasswordContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">
          Restore Password
        </h2>
        <p className="text-white text-left text-bold password-note">
          Don’t worry, we’ll send you a restore link to your email address{" "}
        </p>
        <DrawerInput
          placeholder="Enter email address"
          value=""
          label={null}
          onChange={() => console.log("")}
        />
        <div className="bottom-btns">
          <MyDrawerButton className="text-bold" type={"gray"} onClick={closePanel}>
            Continue
          </MyDrawerButton>
          <div className="cancel-btn" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerRestorePasswordContainer>
  );
}
