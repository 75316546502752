import React, { useContext, useEffect, useState } from "react";
import { PriceTierModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const PriceTierModal = ({ isActive = false, closePanel = () => {} }) => {
  const { postRestaurant, setPostRestaurant } = useContext(RestaurantsContext);
  const [selectedTire, setSelectedTire] = useState("");
  const tiers = [
    {
      id: 1,
      price: "$",
    },
    {
      id: 2,
      price: "$$",
    },
    {
      id: 3,
      price: "$$$",
    },
    {
      id: 4,
      price: "$$$$",
    },
  ];
  useEffect(() => {
    if ((postRestaurant, isActive)) {
      setSelectedTire(postRestaurant?.price_level);
    }
  }, [postRestaurant, isActive]);
  function getPriceId(tireInfo) {
    setSelectedTire(tireInfo?.id);
    setPostRestaurant({ ...postRestaurant, price_level: tireInfo?.id });
    closePanel();
  }
  return (
    <PriceTierModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="drawer-content">
          <div className="top-title">
            <div></div>
            <div className="title">
              <b>Price Tier</b>
            </div>
            <div></div>
          </div>
          <div className="drawer-status--options mb-auto">
            {tiers.map((_tier, _i) => {
              let tireId = _tier.id;
              return (
                <ul className="price-wrapper" key={_i}>
                  <li
                    style={{
                      border:
                        tireId === selectedTire
                          ? "1px solid #ed2626"
                          : "1px solid #5b5e61",
                    }}
                    onClick={() => getPriceId(_tier)}
                  >
                    {_tier.price}
                  </li>
                </ul>
              );
            })}
            {/* <div className="bottom-btns">
              <MyDrawerButton type="redColor" onClick={closePanel}>
                <b>Cancel</b>
              </MyDrawerButton>
            </div> */}
            <div className="bottom-btns" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </PriceTierModalContainer>
  );
};

export default PriceTierModal;
