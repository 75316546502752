import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { DrawerStatusContainer } from "./style";
import { CustomersContext } from "context/CustomersContext";
import DrawerStatusConfirm from "./DrawerStatusConfirm";
import { drawersOverall } from "static/staticData";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { showMessage } from "services/functions";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerStatus({
  isActive = false,
  closePanel = () => {},
  setBanRestaurantModal = () => {},
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    profileData,
    setProfileData,
    editCustomerData,
    setEditCustomerData,

    customerActiveNote,
    setCustomerActiveNote,
    inactiveReason,
    setInactiveReason,
    customerInactiveNote,
    setCustomerInactiveNote,

    setNotesData,
  } = useContext(CustomersContext);

  const [status, setStatus] = useState();
  const [savedStatus, setSavedStatus] = useState();

  useEffect(() => {
    if (editCustomerData) {
      setStatus(editCustomerData.status);
    } else {
      setStatus();
    }
  }, [editCustomerData]);

  const onChangeStatus = (status) => {
    setStatus(status);
    openDrawer(drawersOverall.statusConfirm);
  };

  const onClickSetStatus = (status) => {
    setSavedStatus(status);
    closeDrawer(drawersOverall.statusConfirm);
  };

  const onSaveStatus = () => {
    setEditCustomerData({ ...editCustomerData, status: savedStatus });
    closeDrawer(drawersOverall.statusOverall);
    if (status === 1) {
      customerStatusAtive();
    }
    if (status !== 1) {
      customerStatusInative();
    }
  };

  const closeConfirmStausDrawer = () => {
    setStatus("");
    closeDrawer(drawersOverall.statusConfirm);
  };

  const [
    customerActiveNoteNotification,
    setCustomerActiveNoteNotification,
  ] = useState();

  const [
    customerInactiveNoteNotification,
    setCustomerInactiveNoteNotification,
  ] = useState();

  useEffect(() => {
    if (customerActiveNoteNotification) {
      setTimeout(() => setCustomerActiveNoteNotification(null), 2 * 1000);
    }
  }, [customerActiveNoteNotification]);

  useEffect(() => {
    if (customerInactiveNoteNotification) {
      setTimeout(() => setCustomerInactiveNoteNotification(null), 2 * 1000);
    }
  }, [customerInactiveNoteNotification]);

  useEffect(() => {
    if (isActive) {
      notesGet();
    }
  }, [profileData?.id, isActive]);

  function clearInput() {
    setCustomerActiveNote("");
    setInactiveReason("");
    setCustomerInactiveNote("");
  }

  function notesGet() {
    if (profileData?.id) {
      customAxios
        .get(`/customer/${profileData?.id}/notes`)
        .then((res) => {
          setNotesData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function customerStatusAtive() {
    const customerDataActiveConfig = {
      note: customerActiveNote,
      active: true,
    };
    customAxios
      .post(`/customer/${profileData?.id}/note`, customerDataActiveConfig)
      .then((res) => {
        if (res.status === 200) {
          setCustomerActiveNoteNotification({
            message: "Status changed to Active.",
            type: "active-success",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${editCustomerData?.first_name} ${editCustomerData?.last_name} (customer)</span> status changed to Active.`,
          });
          notesGet();
          clearInput();
          closePanel();
        } else {
          if (res?.errMessage === "Action not allowed") {
            showMessage("Action not allowed", "error");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function customerStatusInative() {
    const customerDataInactiveConfig = {
      note: customerInactiveNote,
      note_to_customer: inactiveReason,
      active: false,
    };
    customAxios
      .post(`/customer/${profileData?.id}/note`, customerDataInactiveConfig)
      .then((res) => {
        if (res.status === 200) {
          setCustomerInactiveNoteNotification({
            message: "Status changed to Inactive.",
            type: "inactive-success",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${editCustomerData?.first_name} ${editCustomerData?.last_name} (customer)</span> status changed to Inactive.`,
          });
          notesGet();
          clearInput();
          closePanel();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <DrawerStatusContainer
      isActive={isActive}
      closePanel={closePanel}
      top="25%"
      width="470px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {customerActiveNoteNotification && (
          <MyMessageBoxItemNew
            message={
              customerActiveNoteNotification?.message
                ? customerActiveNoteNotification?.message
                : ""
            }
            table={
              customerActiveNoteNotification?.table
                ? customerActiveNoteNotification?.table
                : ""
            }
            type={
              customerActiveNoteNotification?.type
                ? customerActiveNoteNotification?.type
                : ""
            }
            onClick={() => setCustomerActiveNoteNotification(null)}
          />
        )}

        {customerInactiveNoteNotification && (
          <MyMessageBoxItemNew
            message={
              customerInactiveNoteNotification?.message
                ? customerInactiveNoteNotification?.message
                : ""
            }
            table={
              customerInactiveNoteNotification?.table
                ? customerInactiveNoteNotification?.table
                : ""
            }
            type={
              customerInactiveNoteNotification?.type
                ? customerInactiveNoteNotification?.type
                : ""
            }
            onClick={() => setCustomerInactiveNoteNotification(null)}
          />
        )}

        {/* <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Status</div>
          <div></div>
        </div> */}

        <div className="title">Status</div>

        <div className="drawer-status--options mb-auto mt-4">
          <DrawerChooseItem
            className="mb-4"
            title="Active"
            active={status === 1}
            onChooseItem={() => {
              onChangeStatus(1);
              // setBanRestaurantModal(false);
            }}
          />
          <DrawerChooseItem
            title="Inactive"
            // subtitle="Not visible to customers"
            active={status !== 1}
            onChooseItem={() => {
              onChangeStatus(0);
              // setBanRestaurantModal(false);
            }}
          />
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={onSaveStatus}
          >
            Save
          </MyDrawerButton>
        </div>

        <div className="cancel-btn" onClick={closePanel}>Cancel</div>
      </DrawerContainer>
      <DrawerStatusConfirm
        isActive={!!openDrawers[drawersOverall.statusConfirm]}
        closePanel={() => closeDrawer(drawersOverall.statusConfirm)}
        onClickSetStatus={onClickSetStatus}
        status={status}
        savedStatus={savedStatus}
        closeConfirmStausDrawer={closeConfirmStausDrawer}
      />
    </DrawerStatusContainer>
  );
}
