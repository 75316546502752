import { customAxios } from "helpers/custom-helpers/customAxios";

const login = async (payload, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.post(`/auth/login`, payload);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage)
  }
}
const logout = async () => await customAxios.post('/auth/logout');
const emailVerify = async ({ token }) => await customAxios.get(`/auth/email-verify?email_token=${token}`);
const forgotPassword = async (payload) => await customAxios.post('/auth/forgot-password', payload);
const forgotPasswordVerify = async (token) => await customAxios.get(`/auth​/forgot-password-verify?token=${token}`);
const changePassword = async (payload) => await customAxios.post('/auth/change-password', payload);


export const authServices = {
  login,
  logout,
  emailVerify,
  forgotPassword,
  forgotPasswordVerify,
  changePassword,
};
