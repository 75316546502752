import React, { useContext } from "react";
import { RemoveItemModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { OrdersContext } from "context/OrdersContext";

const RemoveItemModal = ({
  isActive = false,
  closePanel = () => {},
  removeFoodItem = () => {},
}) => {
  const { selectedItem } = useContext(OrdersContext);
  return (
    <RemoveItemModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="remove-item-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Remove Item</div>
          <div className="delete-suborder-meta">
            <b>{selectedItem?.name}</b> will be removed from the order and any
            preferences the customer selected for this item will be lost.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              className="remove-btn"
              type="red"
              onClick={() => {
                removeFoodItem();
              }}
            >
              <b>Remove</b>
            </MyDrawerButton>
            <MyDrawerButton
              className="keep-btn"
              onClick={() => {
                closePanel();
              }}
              type="gray"
            >
              <b>Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </RemoveItemModalContainer>
  );
};

export default RemoveItemModal;
