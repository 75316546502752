import React, { useState, useEffect } from "react";
import { authServices } from "services";
import { AuthPageContainer } from "./style";
// import { Logo } from "assets/img";
import { setAuthHeader } from "helpers/custom-helpers/customAxios";
import { setJwt } from "services/jwt";
import { isValidEmail } from "services/functions";
import { showMessage } from "services/functions";
import SingleButton from "components/SingleButton";
import { AuthLayout } from "layout/components/auth-layout";
import { useHistory } from "react-router-dom";
import AuthInput from "components/Auth/AuthInput";
import { RaisedHands } from "assets/img";
import Logo from "assets/img/feast-logo.png";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import ReactCodeInput from "react-verification-code-input";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import phoneIcon from "assets/img/phone.png";
import emailIcon from "assets/img/email.png";
import lockIcon from "assets/img/lock.png";

export const WHEN_CODE_IS_MATCHED_ANIMATION_DURATION_MS = 500;
export const DELAY_ANIMATION_MULTIPLIER = 1.9;

export const AuthPage = () => {
  const history = useHistory();

  const { login } = authServices;
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [processing, setProcessing] = useState(false);
  const [isInputError, setIsInputError] = useState(false);
  const [loginSteps, setLoginSteps] = useState({
    isLoginSuccess: false,
    isSmsSelected: false,
    isTwoFactorSelected: false,
    isEmailSelected: false,
  });
  const [loggedAdminId, setLoggedAdminId] = useState(2);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [resendingOTP, setResendingOTP] = useState(false);

  const [myAccountNotification, setMyAccountNotification] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (myAccountNotification) {
      setTimeout(() => setMyAccountNotification(null), 2 * 1000);
    }
  }, [myAccountNotification]);

  const canSubmit =
    loginData.email && loginData.password && isValidEmail(loginData.email);

  const changeValue = (e, key) => {
    setLoginData({ ...loginData, [key]: e.target.value });
  };

  const logIn = () => {
    const onSuccess = (res) => {
      setProcessing(false);
      setLoginSteps({ ...loginSteps, isLoginSuccess: true });

      setIsInputError(false);
      setLoggedAdminId(res?.id);
    };

    const onError = (error) => {
      setProcessing(false);
      // showMessage(error, "error");
      setIsInputError(true);
    };

    const payload = {
      email: loginData.email,
      password: loginData.password,
    };

    setProcessing(true);
    login(payload, onSuccess, onError);
  };

  const authorizeUser = (token) => {
    setJwt(token);
    window.location.reload();
  };

  const sendEnteredCode = async (enteredCode) => {
    customAxios
      .post(`/auth/${loggedAdminId}/2fa/verify?code=${enteredCode}`)
      .then((res) => {
        if (res?.status === 200) {
          setIsFilled(true);
          setIsOtpValid(true);
          setTimeout(() => {
            authorizeUser(res?.data?.access_token);
            setAuthHeader(res?.data?.access_token);
            // setIsOtpValid(false);
            // setIsFilled(false);
          }, 1000);
        } else {
          setIsFilled(true);
          setIsOtpValid(false);
        }
      });
  };

  const twoFactorAuthentiactionSelectedHandler = () => {
    customAxios
      .post(
        `/auth/${loggedAdminId}/2fa/send?type=${
          loginSteps.isEmailSelected ? "email" : "sms"
        }`
      )
      .then((res) => {
        setLoginSteps({
          ...loginSteps,
          isTwoFactorSelected: true,
        });
        setPhoneNumber(res?.data?.phone || "");
      });
  };

  const resendOtpHandler = () => {
    if (resendingOTP) {
      return;
    } else {
      customAxios
        .post(
          `/auth/${loggedAdminId}/2fa/send?type=${
            loginSteps.isEmailSelected ? "email" : "sms"
          }`
        )
        .then((res) => {
          if (res?.status === 200) {
            setTimeout(() => {
              setResendingOTP(false);
              if (loginSteps.isEmailSelected) {
                setMyAccountNotification({
                  message: "We’ve just sent email with confirmation code.",
                  type: "resend-email",
                  table: `Check  <span style="font-weight:bold; color:#7d7e7f; margin-left:0">
          ${loginData?.email}
        </span>`,
                });
              } else {
                setMyAccountNotification({
                  message: "We’ve just sent SMS with confirmation code.",
                  type: "resend-email",
                  table: `Check  <span style="font-weight:bold; color:#7d7e7f; margin-left:0">
          ${`+${phoneNumber}` || "-"}
        </span>`,
                });
              }
            }, 1000);
          } else {
          }
        });
    }
  };

  const isValid = () => {
    return isFilled ? (isOtpValid ? "valid" : "invalid") : "";
  };

  const resetIfFilled = () => {
    if (isFilled) {
      setIsFilled(false);
    }
  };

  return (
    <AuthPageContainer
      className="d-flex"
      animationDuration={WHEN_CODE_IS_MATCHED_ANIMATION_DURATION_MS}
    >
      {myAccountNotification && (
        <MyMessageBoxItemNew
          message={
            myAccountNotification?.message ? myAccountNotification.message : ""
          }
          table={
            myAccountNotification?.table ? myAccountNotification.table : ""
          }
          type={
            myAccountNotification?.type ? myAccountNotification.type : "success"
          }
          onClick={() => setMyAccountNotification(null)}
        />
      )}
      <AuthLayout>
        <div className="login-left d-flex justify-content-center align-items-center">
          <div className="login-form">
            <div className="login-with-email-icon">
              <img src={Logo} alt="logo" width={160} />
            </div>
            {loginSteps.isLoginSuccess ? (
              <>
                <h1 className="icon-login">
                  {loginSteps.isTwoFactorSelected ? (
                    <>
                      {loginSteps.isEmailSelected ? (
                        <img src={emailIcon} alt="" />
                      ) : (
                        <img src={phoneIcon} alt="" />
                      )}
                    </>
                  ) : (
                    <img src={lockIcon} alt="" />
                  )}
                </h1>{" "}
                <div className="two-factor-title">
                  Two-Factor <br /> Authentication
                </div>
                {loginSteps.isTwoFactorSelected ? (
                  <>
                    <div className="one-time-verification-msg">
                      We sent a one-time verification code to: <br />
                      <b>
                        {loginSteps.isEmailSelected
                          ? loginData?.email
                          : `+${phoneNumber}`}
                      </b>
                    </div>
                    <div className="type-the-code">Type the code</div>
                    <ReactCodeInput
                      className={`verify-code-input ${isValid()}`}
                      fields={4}
                      onChange={resetIfFilled}
                      onComplete={sendEnteredCode}
                      fieldWidth={78}
                      fieldHeight={78}
                    />
                    <div className="did-not-get-it-container">
                      <span className="did-not-get-it-title">
                        Didn’t get it?
                      </span>
                      <div
                        className="btn-resend"
                        onClick={() => {
                          resendOtpHandler();
                        }}
                        aria-hidden="true"
                      >
                        <span
                          className={`resend-title ${
                            resendingOTP ? "inactive" : "active"
                          }`}
                        >
                          Resend
                        </span>
                      </div>
                    </div>
                    <div className="otp-verify-cancel-btn">
                      <MyDrawerButton
                        type="redColor"
                        onClick={() => {
                          setLoginSteps({
                            ...loginSteps,
                            isTwoFactorSelected: false,
                            isEmailSelected: false,
                            isSmsSelected: false,
                          });
                          setIsOtpValid(false);
                          setIsFilled(false);
                        }}
                      >
                        <b>Cancel</b>
                      </MyDrawerButton>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="select-one-option">
                      Please select one option.
                    </div>
                    <div
                      className="selection-card"
                      style={{
                        marginTop: "25px",
                        border: loginSteps.isSmsSelected
                          ? "solid 2px #ed2626"
                          : "solid 1px rgba(255, 255, 255, 0.1)",
                      }}
                      onClick={() => {
                        setLoginSteps({
                          ...loginSteps,
                          isSmsSelected: true,
                          isEmailSelected: false,
                        });
                      }}
                    >
                      <div className="selection-icon">
                        <span className="emoji">
                          <img src={phoneIcon} alt="" height={28} />
                        </span>
                      </div>
                      <div className="card-info">
                        <div className="card-selection-title">SMS</div>
                        <div className="card-meta">
                          Code will be sent to the phone number registered.
                        </div>
                      </div>
                    </div>
                    <div
                      className="selection-card"
                      style={{
                        marginTop: "16px",
                        marginBottom: "32px",
                        border: loginSteps.isEmailSelected
                          ? "solid 2px #ed2626"
                          : "solid 1px rgba(255, 255, 255, 0.1)",
                      }}
                      onClick={() => {
                        setLoginSteps({
                          ...loginSteps,
                          isSmsSelected: false,
                          isEmailSelected: true,
                        });
                      }}
                    >
                      <div className="selection-icon">
                        <span className="emoji">
                          <img src={emailIcon} alt="" height={28} />
                        </span>
                      </div>
                      <div className="card-info">
                        <div className="card-selection-title">Email</div>
                        <div className="card-meta">
                          Code will be sent to the email address registered.
                        </div>
                      </div>
                    </div>
                    <div className="two-factor-btns">
                      <MyDrawerButton
                        type={
                          loginSteps.isEmailSelected || loginSteps.isSmsSelected
                            ? "red"
                            : "gray"
                        }
                        disabled={
                          loginSteps.isEmailSelected || loginSteps.isSmsSelected
                            ? false
                            : true
                        }
                        onClick={() => {
                          twoFactorAuthentiactionSelectedHandler();
                        }}
                      >
                        <b>Continue</b>
                      </MyDrawerButton>
                      <MyDrawerButton
                        type="redColor"
                        onClick={() => {
                          setLoginSteps({
                            ...loginSteps,
                            isLoginSuccess: false,
                            isSmsSelected: false,
                            isEmailSelected: false,
                            isTwoFactorSelected: false,
                          });
                        }}
                      >
                        <b>Cancel</b>
                      </MyDrawerButton>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                <h1 className="icon-login">
                  {/* <span role="img" aria-label="logo-img">
                🙌
              </span> */}
                  <img src={RaisedHands} alt="" />
                </h1>
                <h3 className="login-with-email-title">Welcome back!</h3>
                <div className="login-with-email-content">
                  <AuthInput
                    label="E-mail"
                    placeholder={"Enter your e-mail"}
                    placeholderIcon={<i className="far fa-envelope" />}
                    value={loginData.email}
                    onChange={(e) => changeValue(e, "email")}
                    onKeyPress={(e) => {
                      if (
                        e.key === "Enter" &&
                        loginData.password &&
                        loginData.email
                      ) {
                        logIn();
                      }
                    }}
                    onFocus={() => {
                      setIsInputError(false);
                    }}
                    error={isInputError}
                  />
                  <AuthInput
                    label="Password"
                    placeholder="Enter your password"
                    placeholderIcon={<i className="far fa-lock"></i>}
                    value={loginData.password}
                    onChange={(e) => changeValue(e, "password")}
                    password
                    onKeyPress={(e) => {
                      if (
                        e.key === "Enter" &&
                        loginData.password &&
                        loginData.email
                      ) {
                        logIn();
                      }
                    }}
                    onFocus={() => {
                      setIsInputError(false);
                    }}
                    error={isInputError}
                  />
                </div>
                <SingleButton
                  title="Sign In"
                  disabled={!canSubmit}
                  loading={processing}
                  onClick={logIn}
                />
                <div className="forgot-password">
                  <span className="restore-pass-text">
                    Forgot your password?
                  </span>
                  <span
                    className="restore-pass"
                    onClick={() => history.push("/reset-password")}
                  >
                    Reset <i className="fas fa-angle-right" />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </AuthLayout>
    </AuthPageContainer>
  );
};
