import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const ActiveOederActionsModalContainer = styled(MyDrawerCenterModal)`
  .active-order-actions {
    .inactive-action-row {
      opacity: 0.5 !important;
      border-radius: 16px !important;
      border: solid 1px #5b5e61 !important;
      // background-color: #27282a !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      padding: 20px 0;
      text-align: center;
      margin-top: 16px;
      cursor: not-allowed !important;
      &:hover {
        border: solid 1px #5b5e61 !important;
      }
    }
    .clickable-row {
      border-radius: 16px;
      border: solid 1px #5b5e61 !important;
      // background-color: #27282a !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      padding: 20px 0;
      text-align: center;
      margin-top: 16px !important;
      cursor: pointer;
      &:hover {
        border: solid 1px ${colors.redColor}!important;
      }
    }
    .active-order-action-cancel {
      width: 100%;
    }
  }
`;
