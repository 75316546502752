import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerCustomizationsContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .header-title {
    margin-bottom: 43px;
    .title {
      font-size: 14px;
      font-weight: 500;
    }
    .food-title {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #fff;
    }
  }
  .empty-item {
    background-color: ${colors.gray5};
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 22px;
  }
  .drawer-title {
    margin-bottom: 30px;
  }
`;
