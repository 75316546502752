/* eslint-disable react-hooks/exhaustive-deps */
import DatePicker from "components/Elements/DrawerElements/DatePicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
// import MySwitch from "components/Elements/MySwitch";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import React, { useContext, useState, useEffect } from "react";
import { drawersCustomers } from "static/staticData";
import { StyledStatusItem } from "theme/styles";
import { DrawerFilterCustomersContainer } from "./style";
import { Switch } from "@material-ui/core";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment";

const statuses = [
  {
    value: 1,
    title: "Active",
  },
  {
    value: 2,
    title: "Inactive",
  },
];

export default function DrawerFilterCustomers({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  joinedDate,
  onClickResetCustomer = () => {},
  setIsFilterApplied,
}) {
  const { openDrawer, setState, closeDrawer } = useContext(CustomersContext);

  const [profilePhoto, setProfilePhoto] = useState(false);
  const [ccFile, setCcFile] = useState(false);

  // const onClickJoinedDate = () => {
  //   openDrawer(drawersCustomers.joinedDate);
  // };

  const [customersStatus, setCustomersStatus] = useState();

  const onChangeFiltered = (_status) => {
    if (_status === customersStatus) {
      setCustomersStatus(undefined);
    } else {
      setCustomersStatus(_status);
    }
  };

  useEffect(() => {
    // console.log(customersStatus)
  }, [customersStatus]);

  const ProfilePhotoHandleChange = (event) => {
    setProfilePhoto(event.target.checked);
    // console.log(event.target.checked)
  };

  const CcFileHandleChange = (event) => {
    setCcFile(event.target.checked);
    // console.log(event.target.checked)
  };

  // **google api suggestions**
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    // setValue("");
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      console.log("📍 Coordinates: ", { lat, lng });
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  // **end of google api suggestions**

  const onClickDone = () => {
    const filterObject = {};
    if (customersStatus) {
      filterObject.status = customersStatus;
    }
    if (profilePhoto) {
      filterObject.profile = profilePhoto;
    }
    if (ccFile) {
      filterObject.ccOnFile = ccFile;
    }
    if (value) {
      filterObject.location = value;
    }
    onDone(filterObject);
  };

  return (
    <DrawerFilterCustomersContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Filter</div>
          <div></div>
        </div>
        <div className="drawer-content">
          <div className="status-wrapper">
            <h6 className="text-bold">Status</h6>
            <div className="status-items d-flex justify-content-between">
              {statuses.map((_status, _i) => {
                return (
                  <StyledStatusItem
                    key={_i}
                    className={`${
                      customersStatus === _status.value ? "active" : "inactive"
                    } item`}
                    onClick={() => onChangeFiltered(_status.value)}
                  >
                    {_status.title}
                  </StyledStatusItem>
                );
              })}
            </div>
          </div>

          <div className="location-wrapper">
            <h6 className="location-label text-bold">Location</h6>
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            <div className="location-input-wrapper">
              <input
                type="text"
                placeholder="Enter Location"
                className="location-input"
                value={value}
                onChange={handleInput}
                disabled={!ready}
              />
              <i
                className="fa fa-times close-icon"
                aria-hidden="true"
                onClick={() => {
                  clearSuggestions();
                  setValue("");
                }}
              />
            </div>
            <span ref={ref} className="google-location-wrapper">
              {status === "OK" && <ul>{renderSuggestions()}</ul>}
            </span>
          </div>

          <div className="joined-date-wrapper">
            <label className="join-date-label">Joined Date</label>
            <input
              placeholder="Select date"
              onClick={() => openDrawer(drawersCustomers.joinedDate)}
              value={
                joinedDate?.startDate === undefined
                  ? ""
                  : joinedDate?.startDate === joinedDate?.endDate
                  ? `${joinedDate?.startDate}`
                  : `${joinedDate?.startDate} - ${joinedDate?.endDate}`
              }
              className="join-date-input"
            />
            {/* <DatePicker
              label="Joined Date"
              placeholder="Select date"
              onClick={() => openDrawer(drawersCustomers.joinedDate)}
              className="date-text"
              value={joinedDateInputFunc()}
              // value={`${joinedDate?.startDate} - ${joinedDate?.endDate}`}
            /> */}
          </div>

          <div className="cc-file-wrapper">
            <div className="text-bold">CC on File</div>
            <div>
              <Switch
                checked={ccFile}
                onChange={CcFileHandleChange}
                // inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div className="profile-photo">
            <div className="text-bold"> Profile Photo</div>
            <div>
              <Switch
                checked={profilePhoto}
                onChange={ProfilePhotoHandleChange}
                // inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={
              customersStatus ||
              value ||
              joinedDate ||
              profilePhoto === true ||
              ccFile === true
                ? "red"
                : "gray"
            }
            onClick={onClickDone}
            className="text-bold"
          >
            <b>Apply</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            onClick={() => {
              closePanel();
              setValue("");
              setProfilePhoto(false);
              setCcFile(false);
              setCustomersStatus("");
              onClickResetCustomer();
              setIsFilterApplied(false);
              setState([
                {
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                },
              ]);
            }}
            className="mt-4 text-bold"
          >
            <b>Reset</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerFilterCustomersContainer>
  );
}
