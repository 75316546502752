import styled from "@emotion/styled";
import { colors } from "theme";

export const MyDrawerCeneterModalContainer = styled.div`
  .offcanvas {
    background-color: ${colors.gray5};
    &.delete-restaurant-drawer {
      .drawer-left-icon {
        display: none;
      }
    }

    /* .drawer-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: gainsboro;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    } */
  }
`;
