/* eslint-disable react-hooks/exhaustive-deps */
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState, useEffect, useRef } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { StyledStatusItem } from "theme/styles";
import { useOutsideClick } from "services/functions";
// import { useParams } from "react-router";
// import { restaurantServices } from "services";
import {
  DrawerFilterRestaurantsContainer,
  StatusItem,
  PopupCalendarContainer,
} from "./style";
import MyPromoCodeInput from "components/Elements/MyPromoCodeInput";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import FilterCuisineDropDown from "./CuisineDropDown";
import MyDateRange from "components/Elements/MyDateRange";
import MyButton from "components/Elements/MyButton";
import moment from "moment";
import { cuisines } from "static/staticData";

const statuses = [
  {
    value: 1,
    title: "Active",
  },
  {
    value: 0,
    title: "Inactive",
  },
];

const staffLengths = [
  {
    value: 10,
    title: "1-9",
  },
  {
    value: 20,
    title: "10-19",
  },
  {
    value: 30,
    title: "20-29",
  },
  {
    value: 40,
    title: ">30",
  },
];

export default function DrawerFilterRestaurants({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  restaurantData,
  onClickResetRestaurantFilter = () => {},
  setIsFilterApplied,
  isFilterApplied,
  restaurantStatus,
}) {
  const joinedDateRef = useRef();
  const bannedDateRef = useRef();
  const inactiveDateRef = useRef();
  const deletedDateRef = useRef();
  const {
    activeStatus,
    setActiveStatus,
    activeLength,
    setActiveLength,
    setRestaurantStatusFilter,
    setRestaurantStaffFilter,
  } = useContext(RestaurantsContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [bannedDate, setBannedDate] = useState({});
  const [joinedDate, setJoinedDate] = useState({});
  const [inactiveDate, setInactiveDate] = useState({});
  const [deletedDate, setDeletedDate] = useState({});
  const [tempBannedDate, setTempBannedDate] = useState({});
  const [tempJoinDate, setTempJoinDate] = useState({});
  const [tempInactiveDate, setTempInactiveDate] = useState({});
  const [tempDeletedDate, setTempDeletedDate] = useState({});
  const [bannedDateModal, setBannedDateModal] = useState(false);
  const [joinedDateModal, setJoinedDateModal] = useState(false);
  const [inactiveDateModal, setInactiveDateModal] = useState(false);
  const [deletedDateModal, setDeletedDateModal] = useState(false);
  const [cuisine, setCuisine] = useState([]);
  const [bannedBy, setBannedBy] = useState([]);
  const [deletedBy, setDeletedBy] = useState([]);
  const [totalSalesFrom, setTotalSalesFrom] = useState("");
  const [totalSalesTo, setTotalSalesTo] = useState("");
  const [totalFeesFrom, setTotalFeesFrom] = useState("");
  const [totalFeesTo, setTotalFeesTo] = useState("");

  useEffect(() => {
    setBannedDate({});
    setJoinedDate({});
    setInactiveDate({});
    setDeletedDate({});
    setTempBannedDate({});
    setTempJoinDate({});
    setTempInactiveDate({});
    setTempDeletedDate({});
    setBannedDateModal(false);
    setJoinedDateModal(false);
    setInactiveDateModal(false);
    setDeletedDateModal(false);
    setCuisine([]);
    setBannedBy([]);
    setDeletedBy([]);
    setTotalSalesFrom("");
    setTotalSalesTo("");
    setTotalFeesFrom("");
    setTotalFeesTo("");
    setIsFilterApplied(false);
  }, [restaurantStatus]);

  useEffect(() => {
    if (!isFilterApplied) {
      setBannedDate({});
      setJoinedDate({});
      setInactiveDate({});
      setDeletedDate({});
      setTempBannedDate({});
      setTempJoinDate({});
      setTempInactiveDate({});
      setTempDeletedDate({});
      setBannedDateModal(false);
      setJoinedDateModal(false);
      setInactiveDateModal(false);
      setDeletedDateModal(false);
      setCuisine([]);
      setBannedBy([]);
      setDeletedBy([]);
      setTotalSalesFrom("");
      setTotalSalesTo("");
      setTotalFeesFrom("");
      setTotalFeesTo("");
      setIsFilterApplied(false);
    }
  }, [isFilterApplied, isActive]);

  const onChangeStatus = (_status) => {
    if (_status === activeStatus) {
      setActiveStatus(undefined);
    } else {
      setActiveStatus(_status);
    }
  };

  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempJoinDate({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const onChangeBannedDate = (date) => {
    if (date && date?.endDate) {
      setTempBannedDate({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const onChangeInactiveDate = (date) => {
    if (date && date?.endDate) {
      setTempInactiveDate({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const onChangeDeletedDate = (date) => {
    if (date && date?.endDate) {
      setTempDeletedDate({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    if (restaurantStatus === 1) {
      if (
        activeStatus ||
        activeLength ||
        cuisine.length ||
        totalSalesFrom ||
        totalSalesTo ||
        totalFeesFrom ||
        totalFeesTo ||
        joinedDate?.startDate ||
        joinedDate?.endDate
        // (restaurantStatus === 9 && (bannedDate?.startDate || bannedDate?.endDate))
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (restaurantStatus === 9) {
      if (
        activeLength ||
        bannedBy.length ||
        totalSalesFrom ||
        totalSalesTo ||
        totalFeesFrom ||
        totalFeesTo ||
        joinedDate?.startDate ||
        joinedDate?.endDate ||
        bannedDate?.startDate ||
        bannedDate?.endDate
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (restaurantStatus === 0) {
      if (
        activeLength ||
        cuisine.length ||
        totalSalesFrom ||
        totalSalesTo ||
        totalFeesFrom ||
        totalFeesTo ||
        joinedDate?.startDate ||
        joinedDate?.endDate ||
        inactiveDate?.startDate ||
        inactiveDate?.endDate
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (restaurantStatus === -1) {
      if (
        activeLength ||
        deletedBy.length ||
        totalSalesFrom ||
        totalSalesTo ||
        totalFeesFrom ||
        totalFeesTo ||
        joinedDate?.startDate ||
        joinedDate?.endDate ||
        deletedDate?.startDate ||
        deletedDate?.endDate
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (restaurantStatus === 3) {
      if (cuisine.length || joinedDate?.startDate || joinedDate?.endDate) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [
    bannedBy,
    bannedDate,
    joinedDate,
    inactiveDate,
    cuisine,
    totalSalesFrom,
    totalSalesTo,
    totalFeesFrom,
    totalFeesTo,
    activeLength,
  ]);

  useOutsideClick(joinedDateRef, () => {
    if (joinedDateModal) {
      setJoinedDateModal(false);
    }
  });

  useOutsideClick(bannedDateRef, () => {
    if (bannedDateModal) {
      setBannedDateModal(false);
    }
  });

  useOutsideClick(inactiveDateRef, () => {
    if (inactiveDateModal) {
      setInactiveDateModal(false);
    }
  });

  useOutsideClick(deletedDateRef, () => {
    if (deletedDateModal) {
      setDeletedDateModal(false);
    }
  });

  const onChangeLength = (_length) => {
    if (_length === activeLength) {
      setActiveLength(undefined);
    } else {
      setActiveLength(_length);
    }
  };

  const onClickDone = () => {
    if (restaurantStatus === 1) {
      const filterObject = {
        staff_count:
          activeLength === 10
            ? "1,9"
            : activeLength === 20
            ? "10,19"
            : activeLength === 30
            ? "20,29"
            : activeLength === 40 && 30,
        cuisines: cuisine.map((item) => item.id).join(","),
        joined_date:
          joinedDate?.startDate === undefined
            ? null
            : joinedDate?.startDate === joinedDate?.endDate
            ? `${moment(joinedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(joinedDate?.startDate).format("YYYY-MM-DD")},${moment(
                joinedDate?.endDate
              ).format("YYYY-MM-DD")}`,
      };
      onDone(filterObject);
    } else if (restaurantStatus === 9) {
      const filterObject = {
        staff_count:
          activeLength === 10
            ? "1,9"
            : activeLength === 20
            ? "10,19"
            : activeLength === 30
            ? "20,29"
            : activeLength === 40 && 30,
        banned_by: bannedBy.map((item) => item.id).join(","),
        joined_date:
          joinedDate?.startDate === undefined
            ? null
            : joinedDate?.startDate === joinedDate?.endDate
            ? `${moment(joinedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(joinedDate?.startDate).format("YYYY-MM-DD")},${moment(
                joinedDate?.endDate
              ).format("YYYY-MM-DD")}`,
        ban_date:
          bannedDate?.startDate === undefined
            ? null
            : bannedDate?.startDate === bannedDate?.endDate
            ? `${moment(bannedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(bannedDate?.startDate).format("YYYY-MM-DD")},${moment(
                bannedDate?.endDate
              ).format("YYYY-MM-DD")}`,
      };
      onDone(filterObject);
    } else if (restaurantStatus === 0) {
      const filterObject = {
        staff_count:
          activeLength === 10
            ? "1,9"
            : activeLength === 20
            ? "10,19"
            : activeLength === 30
            ? "20,29"
            : activeLength === 40 && 30,
        cuisines: cuisine.map((item) => item.id).join(","),
        joined_date:
          joinedDate?.startDate === undefined
            ? null
            : joinedDate?.startDate === joinedDate?.endDate
            ? `${moment(joinedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(joinedDate?.startDate).format("YYYY-MM-DD")},${moment(
                joinedDate?.endDate
              ).format("YYYY-MM-DD")}`,
        inactive_date:
          inactiveDate?.startDate === undefined
            ? null
            : inactiveDate?.startDate === inactiveDate?.endDate
            ? `${moment(inactiveDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(inactiveDate?.startDate).format("YYYY-MM-DD")},${moment(
                inactiveDate?.endDate
              ).format("YYYY-MM-DD")}`,
      };
      onDone(filterObject);
    } else if (restaurantStatus === -1) {
      const filterObject = {
        staff_count:
          activeLength === 10
            ? "1,9"
            : activeLength === 20
            ? "10,19"
            : activeLength === 30
            ? "20,29"
            : activeLength === 40 && 30,
        deleted_by: deletedBy.map((item) => item.id).join(","),
        joined_date:
          joinedDate?.startDate === undefined
            ? null
            : joinedDate?.startDate === joinedDate?.endDate
            ? `${moment(joinedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(joinedDate?.startDate).format("YYYY-MM-DD")},${moment(
                joinedDate?.endDate
              ).format("YYYY-MM-DD")}`,
        deleted_date:
          deletedDate?.startDate === undefined
            ? null
            : deletedDate?.startDate === deletedDate?.endDate
            ? `${moment(deletedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(deletedDate?.startDate).format("YYYY-MM-DD")},${moment(
                deletedDate?.endDate
              ).format("YYYY-MM-DD")}`,
      };
      onDone(filterObject);
    } else if (restaurantStatus === 3) {
      const filterObject = {
        staff_count:
          activeLength === 10
            ? "1,9"
            : activeLength === 20
            ? "10,19"
            : activeLength === 30
            ? "20,29"
            : activeLength === 40 && 30,
        cuisines: cuisine.map((item) => item.id).join(","),
        joined_date:
          joinedDate?.startDate === undefined
            ? null
            : joinedDate?.startDate === joinedDate?.endDate
            ? `${moment(joinedDate?.startDate).format("YYYY-MM-DD")}`
            : `${moment(joinedDate?.startDate).format("YYYY-MM-DD")},${moment(
                joinedDate?.endDate
              ).format("YYYY-MM-DD")}`,
      };
      onDone(filterObject);
    }
    if (isDisabled) {
      setIsFilterApplied(false);
    } else {
      setIsFilterApplied(true);
    }
    closePanel();
  };

  return (
    <DrawerFilterRestaurantsContainer
      isActive={isActive}
      closePanel={() => {
        closePanel();
        setJoinedDateModal(false);
        setBannedDateModal(false);
        setInactiveDateModal(false);
        setDeletedDateModal(false);
      }}
    >
      <DrawerContainer
        style={{
          justifyContent:
            restaurantStatus === 3 ? "flex-start" : "space-between",
          height: "100%",
          gap: "18px",
        }}
      >
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Filter</div>
          {restaurantStatus === 1
            ? (cuisine.length ||
                totalFeesFrom ||
                totalSalesTo ||
                totalFeesFrom ||
                totalFeesTo ||
                activeLength ||
                joinedDate.startDate ||
                joinedDate.endDate ||
                isFilterApplied) && (
                <div
                  style={{
                    cursor: "pointer",
                    color: "#ed2626",
                    justifySelf: "end",
                  }}
                  onClick={() => {
                    setActiveLength();
                    setJoinedDate({});
                    setTempJoinDate({});
                    setCuisine([]);
                    setTotalSalesFrom("");
                    setTotalSalesTo("");
                    setTotalFeesFrom("");
                    setTotalFeesTo("");
                    onClickResetRestaurantFilter();
                    setRestaurantStatusFilter();
                    setRestaurantStaffFilter();
                    setIsFilterApplied(false);
                  }}
                >
                  Reset All
                </div>
              )
            : restaurantStatus === 9
            ? (bannedBy.length ||
                totalFeesFrom ||
                totalSalesTo ||
                totalFeesFrom ||
                totalFeesTo ||
                activeLength ||
                joinedDate.startDate ||
                joinedDate.endDate ||
                bannedDate.startDate ||
                bannedDate.endDate ||
                isFilterApplied) && (
                <div
                  style={{
                    cursor: "pointer",
                    color: "#ed2626",
                    justifySelf: "end",
                  }}
                  onClick={() => {
                    setActiveLength();
                    setJoinedDate({});
                    setTempJoinDate({});
                    setBannedDate({});
                    setTempBannedDate({});
                    setInactiveDate({});
                    setTempInactiveDate({});
                    setCuisine([]);
                    setBannedBy([]);
                    setTotalSalesFrom("");
                    setTotalSalesTo("");
                    setTotalFeesFrom("");
                    setTotalFeesTo("");
                    onClickResetRestaurantFilter();
                    setRestaurantStatusFilter();
                    setRestaurantStaffFilter();
                    setIsFilterApplied(false);
                  }}
                >
                  Reset All
                </div>
              )
            : restaurantStatus === 0
            ? (cuisine.length ||
                totalFeesFrom ||
                totalSalesTo ||
                totalFeesFrom ||
                totalFeesTo ||
                activeLength ||
                joinedDate.startDate ||
                joinedDate.endDate ||
                inactiveDate.startDate ||
                inactiveDate.endDate ||
                isFilterApplied) && (
                <div
                  style={{
                    cursor: "pointer",
                    color: "#ed2626",
                    justifySelf: "end",
                  }}
                  onClick={() => {
                    setActiveLength();
                    setJoinedDate({});
                    setTempJoinDate({});
                    setInactiveDate({});
                    setTempInactiveDate({});
                    setCuisine([]);
                    setTotalSalesFrom("");
                    setTotalSalesTo("");
                    setTotalFeesFrom("");
                    setTotalFeesTo("");
                    onClickResetRestaurantFilter();
                    setRestaurantStatusFilter();
                    setRestaurantStaffFilter();
                    setIsFilterApplied(false);
                  }}
                >
                  Reset All
                </div>
              )
            : restaurantStatus === -1
            ? (deletedBy.length ||
                totalFeesFrom ||
                totalSalesTo ||
                totalFeesFrom ||
                totalFeesTo ||
                activeLength ||
                joinedDate.startDate ||
                joinedDate.endDate ||
                deletedDate.startDate ||
                deletedDate.endDate ||
                isFilterApplied) && (
                <div
                  style={{
                    cursor: "pointer",
                    color: "#ed2626",
                    justifySelf: "end",
                  }}
                  onClick={() => {
                    setActiveLength();
                    setJoinedDate({});
                    setTempJoinDate({});
                    setDeletedDate({});
                    setTempDeletedDate({});
                    setInactiveDate({});
                    setTempInactiveDate({});
                    setCuisine([]);
                    setBannedBy([]);
                    setDeletedBy([]);
                    setTotalSalesFrom("");
                    setTotalSalesTo("");
                    setTotalFeesFrom("");
                    setTotalFeesTo("");
                    onClickResetRestaurantFilter();
                    setRestaurantStatusFilter();
                    setRestaurantStaffFilter();
                    setIsFilterApplied(false);
                  }}
                >
                  Reset All
                </div>
              )
            : restaurantStatus === 3
            ? (cuisine.length ||
                joinedDate.startDate ||
                joinedDate.endDate ||
                isFilterApplied) && (
                <div
                  style={{
                    cursor: "pointer",
                    color: "#ed2626",
                    justifySelf: "end",
                  }}
                  onClick={() => {
                    setJoinedDate({});
                    setTempJoinDate({});
                    setDeletedDate({});
                    setTempDeletedDate({});
                    setInactiveDate({});
                    setTempInactiveDate({});
                    setCuisine([]);
                    setBannedBy([]);
                    setDeletedBy([]);
                    setTotalSalesFrom("");
                    setTotalSalesTo("");
                    setTotalFeesFrom("");
                    setTotalFeesTo("");
                    onClickResetRestaurantFilter();
                    setRestaurantStatusFilter();
                    setRestaurantStaffFilter();
                    setIsFilterApplied(false);
                    // onClickDone();
                  }}
                >
                  Reset All
                </div>
              )
            : null}
        </div>
        {restaurantStatus === 9 ? (
          <>
            <div className="joined-date-wrapper">
              <label className="join-date-label">
                Banned Date
                {Object.keys(bannedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setBannedDate({});
                      setTempBannedDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setBannedDateModal(!bannedDateModal);
                  }}
                  value={
                    bannedDate?.startDate === undefined
                      ? ""
                      : bannedDate?.startDate === bannedDate?.endDate
                      ? `${moment(bannedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(bannedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(bannedDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setBannedDateModal(!bannedDateModal);
                  }}
                  style={{
                    color: bannedDateModal ? "#fff" : "#fff",
                    opacity: bannedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {bannedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={bannedDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeBannedDate}
                      date={bannedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={bannedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setBannedDate(tempBannedDate);
                        setBannedDateModal(!bannedDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>
            <div className="joined-date-wrapper banned-date">
              <label className="join-date-label">
                Joined Date
                {Object.keys(joinedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setJoinedDate({});
                      setTempJoinDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  value={
                    joinedDate?.startDate === undefined
                      ? ""
                      : joinedDate?.startDate === joinedDate?.endDate
                      ? `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(joinedDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  style={{
                    color: joinedDateModal ? "#fff" : "#fff",
                    opacity: joinedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {joinedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={joinedDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeDate}
                      date={joinedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={joinedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setJoinedDate(tempJoinDate);
                        setJoinedDateModal(!joinedDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>

            <FilterCuisineDropDown
              value={bannedBy}
              setValue={setBannedBy}
              label={"Banned By"}
              isActive={isActive}
            />
          </>
        ) : restaurantStatus === 1 ? (
          <>
            <div className="joined-date-wrapper">
              <label className="join-date-label">
                Joined Date
                {Object.keys(joinedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setJoinedDate({});
                      setTempJoinDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  value={
                    joinedDate?.startDate === undefined
                      ? ""
                      : joinedDate?.startDate === joinedDate?.endDate
                      ? `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(joinedDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  style={{
                    color: joinedDateModal ? "#fff" : "#fff",
                    opacity: joinedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {joinedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={joinedDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeDate}
                      date={joinedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={joinedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setJoinedDate(tempJoinDate);
                        setJoinedDateModal(!joinedDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>
            <FilterCuisineDropDown
              value={cuisine}
              setValue={setCuisine}
              label={"Cuisine"}
              isActive={isActive}
            />
          </>
        ) : restaurantStatus === 0 ? (
          <>
            <div className="joined-date-wrapper">
              <label className="join-date-label">
                Inactivated Date
                {Object.keys(inactiveDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setInactiveDate({});
                      setTempInactiveDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setInactiveDateModal(!inactiveDateModal);
                  }}
                  value={
                    inactiveDate?.startDate === undefined
                      ? ""
                      : inactiveDate?.startDate === inactiveDate?.endDate
                      ? `${moment(inactiveDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(inactiveDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(inactiveDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setInactiveDateModal(!inactiveDateModal);
                  }}
                  style={{
                    color: inactiveDateModal ? "#fff" : "#fff",
                    opacity: inactiveDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {inactiveDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={inactiveDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeInactiveDate}
                      date={inactiveDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={inactiveDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setInactiveDate(tempInactiveDate);
                        setInactiveDateModal(!inactiveDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>
            <div className="joined-date-wrapper banned-date">
              <label className="join-date-label">
                Joined Date
                {Object.keys(joinedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setJoinedDate({});
                      setTempJoinDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  value={
                    joinedDate?.startDate === undefined
                      ? ""
                      : joinedDate?.startDate === joinedDate?.endDate
                      ? `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(joinedDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  style={{
                    color: joinedDateModal ? "#fff" : "#fff",
                    opacity: joinedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {joinedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={joinedDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeDate}
                      date={joinedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={joinedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setJoinedDate(tempJoinDate);
                        setJoinedDateModal(!joinedDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>

            <FilterCuisineDropDown
              value={cuisine}
              setValue={setCuisine}
              label={"Cuisine"}
              isActive={isActive}
            />
          </>
        ) : restaurantStatus === -1 ? (
          <>
            <div className="joined-date-wrapper">
              <label className="join-date-label">
                Deleted Date
                {Object.keys(deletedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setDeletedDate({});
                      setTempDeletedDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setDeletedDateModal(!deletedDateModal);
                  }}
                  value={
                    deletedDate?.startDate === undefined
                      ? ""
                      : deletedDate?.startDate === deletedDate?.endDate
                      ? `${moment(deletedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(deletedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(deletedDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setDeletedDateModal(!deletedDateModal);
                  }}
                  style={{
                    color: deletedDateModal ? "#fff" : "#fff",
                    opacity: deletedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {deletedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={deletedDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeDeletedDate}
                      date={deletedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={deletedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setDeletedDate(tempDeletedDate);
                        setDeletedDateModal(!deletedDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>
            <div className="joined-date-wrapper banned-date">
              <label className="join-date-label">
                Joined Date
                {Object.keys(joinedDate).length ? (
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setJoinedDate({});
                      setTempJoinDate({});
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="join-date-div">
                <input
                  placeholder="Select Date"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  value={
                    joinedDate?.startDate === undefined
                      ? ""
                      : joinedDate?.startDate === joinedDate?.endDate
                      ? `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )}`
                      : `${moment(joinedDate?.startDate).format(
                          "MMM DD, YYYY"
                        )} - ${moment(joinedDate?.endDate).format(
                          "MMM DD, YYYY"
                        )}`
                  }
                  className="join-date-input"
                />
                <i
                  className="fas fa-calendar-alt"
                  onClick={() => {
                    setJoinedDateModal(!joinedDateModal);
                  }}
                  style={{
                    color: joinedDateModal ? "#fff" : "#fff",
                    opacity: joinedDateModal ? "1" : "0.2",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
                {joinedDateModal && (
                  <PopupCalendarContainer
                    className="popup-sales-calendar"
                    ref={joinedDateRef}
                  >
                    <MyDateRange
                      months={2}
                      onChangeDate={onChangeDate}
                      date={joinedDate}
                    />
                    <MyButton
                      className="apply-date"
                      type={joinedDate?.endDate !== "" ? "red" : "gray"}
                      onClick={() => {
                        setJoinedDate(tempJoinDate);
                        setJoinedDateModal(!joinedDateModal);
                      }}
                    >
                      Apply
                    </MyButton>
                  </PopupCalendarContainer>
                )}
              </div>
            </div>

            <FilterCuisineDropDown
              value={deletedBy}
              setValue={setDeletedBy}
              label={"Deleted By"}
              isActive={isActive}
            />
          </>
        ) : (
          restaurantStatus === 3 && (
            <>
              <div className="joined-date-wrapper">
                <label className="join-date-label">
                  Joined Request Date
                  {Object.keys(joinedDate).length ? (
                    <i
                      className="fas fa-times"
                      onClick={() => {
                        setJoinedDate({});
                        setTempJoinDate({});
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </label>
                <div className="join-date-div">
                  <input
                    placeholder="Select Date"
                    onClick={() => {
                      setJoinedDateModal(!joinedDateModal);
                    }}
                    value={
                      joinedDate?.startDate === undefined
                        ? ""
                        : joinedDate?.startDate === joinedDate?.endDate
                        ? `${moment(joinedDate?.startDate).format(
                            "MMM DD, YYYY"
                          )}`
                        : `${moment(joinedDate?.startDate).format(
                            "MMM DD, YYYY"
                          )} - ${moment(joinedDate?.endDate).format(
                            "MMM DD, YYYY"
                          )}`
                    }
                    className="join-date-input"
                  />
                  <i
                    className="fas fa-calendar-alt"
                    onClick={() => {
                      setJoinedDateModal(!joinedDateModal);
                    }}
                    style={{
                      color: joinedDateModal ? "#fff" : "#fff",
                      opacity: joinedDateModal ? "1" : "0.2",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  />
                  {joinedDateModal && (
                    <PopupCalendarContainer
                      className="popup-sales-calendar"
                      ref={joinedDateRef}
                    >
                      <MyDateRange
                        months={2}
                        onChangeDate={onChangeDate}
                        date={joinedDate}
                      />
                      <MyButton
                        className="apply-date"
                        type={joinedDate?.endDate !== "" ? "red" : "gray"}
                        onClick={() => {
                          setJoinedDate(tempJoinDate);
                          setJoinedDateModal(!joinedDateModal);
                        }}
                      >
                        Apply
                      </MyButton>
                    </PopupCalendarContainer>
                  )}
                </div>
              </div>
              <FilterCuisineDropDown
                value={cuisine}
                setValue={setCuisine}
                label={"Cuisine"}
                isActive={isActive}
              />
            </>
          )
        )}

        {restaurantStatus !== 3 && (
          <div className="drawer-staff--options">
            <div className="filter-item">
              <div className="filter-item-header">
                <span>Total Sales</span>
                <div className="filter-item-header-btn">
                  {(totalSalesFrom || totalSalesTo) && (
                    <button
                      className="clear-filter-btn"
                      onClick={() => {
                        setTotalSalesFrom("");
                        setTotalSalesTo("");
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
              <div className="filter-body">
                <Slider
                  range
                  allowCross={false}
                  defaultValue={[totalSalesFrom, totalSalesTo]}
                  value={[totalSalesFrom, totalSalesTo]}
                  onChange={(value) => {
                    setTotalSalesFrom(value[0]);
                    setTotalSalesTo(value[1]);
                  }}
                  max={999}
                />
                <div className="filter-item-body">
                  <MyPromoCodeInput
                    className=""
                    placeholder="From"
                    value={totalSalesFrom}
                    onChange={(e) =>
                      setTotalSalesFrom(e.target.value.replace(/[^0-9.]/g, ""))
                    }
                    style={{
                      width: "50%",
                      border: "none",
                      background: "transparent",
                      boxShadow: "none",
                      outline: "none",
                      height: "auto",
                    }}
                    taxes={true}
                    prefix="$"
                  />
                  <MyPromoCodeInput
                    className=""
                    placeholder="To"
                    value={totalSalesTo}
                    onChange={(e) =>
                      setTotalSalesTo(e.target.value.replace(/[^0-9.]/g, ""))
                    }
                    style={{
                      width: "50%",
                      border: "none",
                      background: "transparent",
                      boxShadow: "none",
                      outline: "none",
                      height: "auto",
                    }}
                    taxes={true}
                    prefix="$"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {restaurantStatus !== 3 && (
          <div className="drawer-staff--options">
            <div className="filter-item">
              <div className="filter-item-header">
                <span>Total Fees</span>
                <div className="filter-item-header-btn">
                  {(totalFeesFrom || totalFeesTo) && (
                    <button
                      className="clear-filter-btn"
                      onClick={() => {
                        setTotalFeesFrom("");
                        setTotalFeesTo("");
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
              <div className="filter-body">
                <Slider
                  range
                  allowCross={false}
                  defaultValue={[totalFeesFrom, totalFeesTo]}
                  value={[totalFeesFrom, totalFeesTo]}
                  onChange={(value) => {
                    setTotalFeesFrom(value[0]);
                    setTotalFeesTo(value[1]);
                  }}
                  max={999}
                />
                <div className="filter-item-body">
                  <MyPromoCodeInput
                    className=""
                    placeholder="From"
                    value={totalFeesFrom}
                    onChange={(e) =>
                      setTotalFeesFrom(e.target.value.replace(/[^0-9.]/g, ""))
                    }
                    style={{
                      width: "50%",
                      border: "none",
                      background: "transparent",
                      boxShadow: "none",
                      outline: "none",
                      height: "auto",
                    }}
                    taxes={true}
                    prefix="$"
                  />
                  <MyPromoCodeInput
                    className=""
                    placeholder="To"
                    value={totalFeesTo}
                    onChange={(e) =>
                      setTotalFeesTo(e.target.value.replace(/[^0-9.]/g, ""))
                    }
                    style={{
                      width: "50%",
                      border: "none",
                      background: "transparent",
                      boxShadow: "none",
                      outline: "none",
                      height: "auto",
                    }}
                    taxes={true}
                    prefix="$"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {restaurantStatus !== 3 && (
          <div className="drawer-staff--options">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Staff</label>
              {activeLength && (
                <button
                  className="clear-filter-btn"
                  onClick={() => {
                    setActiveLength();
                  }}
                >
                  X
                </button>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                gap: "10px",
              }}
            >
              {staffLengths.map((_length, _i) => (
                <DrawerChooseItem
                  key={_i}
                  className="mb-4 justify-content-center p-0 length"
                  title={<b>{_length.title}</b>}
                  active={activeLength === _length.value}
                  onChooseItem={() => onChangeLength(_length.value)}
                />
              ))}
            </div>
          </div>
        )}
        <div
          className="bottom-btns"
          style={{ marginTop: restaurantStatus === 3 ? "auto" : 50 }}
        >
          <MyDrawerButton
            type={isDisabled ? "gray" : "red"}
            onClick={() => {
              onClickDone();
              setBannedDateModal(false);
              setJoinedDateModal(false);
              setInactiveDateModal(false);
              setDeletedDateModal(false);
            }}
            className="apply-restaurant-filter"
            disabled={isDisabled}
          >
            <b>Apply</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            onClick={() => {
              closePanel();
              // setActiveStatus();
              // setActiveLength();
              // onClickResetRestaurantFilter();
              // setRestaurantStatusFilter();
              // setRestaurantStaffFilter();
              // setIsFilterApplied(false);
            }}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerFilterRestaurantsContainer>
  );
}
