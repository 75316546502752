import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  FaCheck,
  FaEllipsisH,
  FaPen,
  FaSpinner,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { AllStaffContainer, PopupContainer } from "./style";
import { OverlayTrigger } from "react-bootstrap";
import { colors } from "theme";
import moment from "moment";
// import ItemsList from "./ItemsList";
// import GuestsList from "./GuestsList";
import { OrdersContext } from "context/OrdersContext";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { drawersStaff } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import EmptyAvatar from "assets/img/noAvatar.png";
import { useHistory, useParams } from "react-router-dom";

export default function AllStaffList({
  staff = {},
  header = false,
  onClickEdit = () => {},
  onClickDelete = () => {},
  selectedPage,
}) {
  const history = useHistory();
  const { id } = useParams();
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    setStaffViewData,
    staffSearchInput,
    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,
  } = useContext(RestaurantsContext);

  // console.log(staff.serving_status === undefined)

  const [isGuestsListShowing, setIsGuestsListShowing] = useState(false);
  const [isItemsListShowing, setIsItemsListShowing] = useState(false);

  const [nameSort, setNameSort] = useState(false);

  const content = (
    <PopupContainer className="popup-wrapper">
      <div className="popup-item" onClick={() => onClickEdit(staff)}>
        <FaPen className="popup-item-icon" color="white" />
        <span content="Edit" size={14} weight={600}>
          Edit
        </span>
      </div>

      <div className="popup-item" onClick={() => onClickDelete(staff)}>
        <FaTrash className="popup-item-icon" color="white" />
        <span content="Delete" size={14} weight={600}>
          Delete
        </span>
      </div>
    </PopupContainer>
  );

  const contentPending = (
    <PopupContainer className="popup-wrapper">
      <div className="popup-item" onClick={() => onClickDelete(staff)}>
        <FaTrash className="popup-item-icon" color="white" />
        <span content="Delete" size={14} weight={600}>
          Delete
        </span>
      </div>
    </PopupContainer>
  );

  // phone number format
  function normalize(phone) {
    phone = phone?.replace(/[^\d]/g, "");
    if (phone?.length == 11) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else {
      return (
        <span style={{ color: "rgb(131 131 131)", marginLeft: "40px" }}>-</span>
      );
    }
    // return null;
  }

  function staffName() {
    const { first_name, last_name } = staff;
    if (first_name === undefined && last_name === undefined) {
      return <span style={{ color: "rgb(131 131 131)" }}>No Name</span>;
    } else {
      return `${first_name} ${last_name}`;
    }
  }

  const onClickView = (staff) => {
    openDrawer(drawersStaff.staffReviews);
    setStaffViewData(staff);
  };

  const onClickThreeDotMenu = (staff) => {
    setStaffChangeRoleMainModal({
      ...staffChangeRoleMainModal,
      staffServerHostActiveModal: true,
    });
    setStaffViewData(staff);
  };

  const onClickStaffProfileRoute = (staff) => {
    // console.log(staff?.id)
    // history.push(`/staff-profile/${staff?.id}`);
    history.push(`/restaurant-detail/${id}?view=staff&mode=all&staff-profile/${staff?.id}`)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStaffViewData(staff);
  };

  const getHighlightedText = (text, highlight, color) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <AllStaffContainer id="restaurant-staff-card">
      <div className={`staff-card ${header && "is-header"}`}>
        <div className="card-col" style={{ flex: 1.2 }}>
          <div className="restaurent-profile">
            {header ? (
              <img
                src={staff?.avatar ? staff?.avatar : EmptyAvatar}
                height={48}
                width={48}
                alt=""
                style={{ display: "none" }}
              />
            ) : (
              <img
                src={staff?.avatar ? staff?.avatar : EmptyAvatar}
                height={48}
                width={48}
                alt=""
              />
            )}
            {header ? (
              <>
                <h6
                  className="name"
                  style={{
                    fontSize: "14px",
                    color: "rgb(255 255 255 / 33%)",
                  }}
                >
                  Name
                </h6>
                <span>
                  {nameSort ? (
                    <i
                      className="far fa-angle-up cursor-pointer"
                      style={{
                        fontSize: "20px",
                        color: "rgba(255, 255, 255, 0.33)",
                        marginLeft: "-9px",
                        marginTop: "3px",
                      }}
                      onClick={() => {
                        setNameSort(false);
                      }}
                    />
                  ) : (
                    <i
                      className="far fa-angle-down cursor-pointer"
                      style={{
                        fontSize: "20px",
                        color: "rgba(255, 255, 255, 0.33)",
                        marginLeft: "-9px",
                        marginTop: "3px",
                      }}
                      onClick={() => {
                        setNameSort(true);
                      }}
                    />
                  )}
                </span>
              </>
            ) : (
              <>
                {/* <h6 className="restaurant-name">{staffName()}</h6> */}
                <h6
                  className="restaurant-name cursor-pointer"
                  onClick={() => onClickStaffProfileRoute(staff)}
                >
                  {/* {getHighlightedText(staffName(), staffSearchInput, "#ed2626")} */}
                  {getHighlightedText(
                    staff?.first_name === undefined &&
                      staff?.last_name === undefined
                      ? `No Name`
                      : `${staff?.first_name} ${staff?.last_name}`,
                    staffSearchInput,
                    "#ed2626"
                  )}
                </h6>
              </>
            )}
          </div>
        </div>

        <div className="card-col" style={{ flex: 1 }}>
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Role
            </h6>
          ) : (
            <h6>
              {(staff.is_owner && "Owner") ||
                (!staff.is_owner &&
                  staff?.role_slug === "manager" &&
                  "Manager") ||
                (staff?.role_slug === "kitchen_staff" && "Kitchen Staff") ||
                (staff?.role_slug === "waiter" && "Server / Host")}
            </h6>
          )}
        </div>

        <div className="card-col" style={{ flex: 1.2 }}>
          {header ? (
            <h6
              className="d-flex"
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
                cursor: "pointer",
              }}
            >
              Joined (current restaurant)
            </h6>
          ) : (
            <h6>{moment(staff?.joining_date).format("MMM DD, YYYY")}</h6>
          )}
        </div>

        <div className="card-col">
          {header ? (
            <h6
              className="d-flex"
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
                cursor: "pointer",
              }}
            >
              Status
            </h6>
          ) : (
            <h6 style={{ width: "30%" }}>
              <>
                {/* {staff.status === 1 || staff.status === 3 ? (
                  <div className="order-badge">
                    <div><FaSpinner color={colors.orange} /></div>
                    <div style={{ color: colors.green }}>Active</div>
                  </div>
                ) : (
                  <div className="order-badge">
                    <div><FaCheck color={colors.green} /></div>
                    <div style={{ color: colors.green }}>Completed</div>
                  </div>
                )} */}
                <div className="order-badge">
                  {((staff.serving_status === true ||
                    staff.serving_status == null) &&
                    staff.status === 1 && (
                      <span style={{ color: colors.green }}>Active</span>
                    )) ||
                    (staff?.serving_status === undefined && (
                      <span style={{ color: "rgb(247, 166, 76)" }}>
                        Pending
                      </span>
                    )) ||
                    (staff?.status === 9 && (
                      <span style={{ color: colors.redColor }}>Banned</span>
                    )) ||
                    (staff?.status === -1 && (
                      <span style={{ color: colors.redColor }}>Deleted</span>
                    )) ||
                    (staff.serving_status === false && staff.status === 1 && (
                      <span style={{ color: colors.gray }}>Inactive</span>
                    )) ||
                    (staff.serving_status === true && staff.status === 0 && (
                      <span style={{ color: colors.gray }}>
                        Inactive (Global)
                      </span>
                    ))}
                </div>
              </>
            </h6>
          )}
        </div>

        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Rating
            </h6>
          ) : (
            <h6 style={{ position: "relative", width: "50%" }}>
              <div className="rating-wrapper">
                {staff?.role_slug === "waiter" ? (
                  <>
                    <div>
                      <FaStar size={20} color="#FDCB6E" className="cursor" />
                    </div>
                    <div className="text-bold">{staff?.rating}</div>
                    <div
                      className="view cursor-pointer"
                      // onClick={() => openDrawer(drawersStaff.staffReviews)}
                      onClick={() => onClickView(staff)}
                    >
                      <div className="view-text">View</div>
                      <div>
                        <i className="far fa-angle-right" />
                      </div>
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </div>
            </h6>
          )}
        </div>

        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Phone Number
            </h6>
          ) : (
            <h6 style={{ position: "relative", width: "50%" }}>
              {/* {staff?.phone_number && normalize(staff?.phone_number)} */}
              {staff?.phone_number || staff?.phone ? (
                <NumberFormat
                  format={"+# (###) ###-####"}
                  className={`single-ant-format-input`}
                  value={staff?.phone_number || staff?.phone}
                  onValueChange={(e) => {}}
                  onBlur={() => {}}
                  readOnly
                />
              ) : (
                "-"
              )}
            </h6>
          )}
        </div>

        <div className="card-col" style={{ flex: 1.1 }}>
          {header ? (
            <h6
              className="email"
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Email
            </h6>
          ) : (
            <h6 className="total">
              {staff?.email ? staff?.email : "No Email"}
            </h6>
          )}
        </div>

        {/* previous edit and delete drawer */}
        {/* <div className="card-edit" id="card-edit-wrapper">
          {!header && (
            <OverlayTrigger
              container={() => document.getElementById("card-edit-wrapper")}
              placement="bottom-end"
              popperConfig={{ className: "fffffffffff" }}
              trigger="click"
              overlay={
                staff?.serving_status === undefined ? contentPending : content
              }
              // overlay={content}
              rootClose
            >
              <FaEllipsisH color="white" className="cursor" />
            </OverlayTrigger>
          )}
        </div> */}

        <div className="card-edit" id="card-edit-wrapper">
          {/* && !staff?.is_owner */}
          {!header && (
            <FaEllipsisH
              color="white"
              className="cursor"
              onClick={() => onClickThreeDotMenu(staff)}
            />
          )}
        </div>
      </div>
    </AllStaffContainer>
  );
}

AllStaffList.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  selectedPage: PropTypes.number,
};
