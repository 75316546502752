import styled from "@emotion/styled";

export const OrdersByHourContainer = styled.div`
  background-color: #1b1c1d;
  border-radius: 8px;
  margin: 32px 0px 34px;
  padding: 27px 0px 27px 0px;

  .orders-by-hour-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 19px;
    margin-bottom: 70px;

    .orders-by-hour-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .orders-by-hour-top-time-switcher {
      background-color: #27282a;
      border-radius: 30px;
    }

    .barchart-date {
      position: absolute;
      margin-top: 6rem;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
