import styled from "@emotion/styled";
import { colors } from "theme";

export const RestaurantItemContainer = styled.div`
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  &:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.08);
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .item-flex-normal {
    flex: 0 0 11%;
    font-size: 12px;
  }
  .item-flex-big {
    flex: 0 0 22%;
    font-size: 14px;
  }

  .item-flex-small-big {
    flex: 0 0 16%;
    font-size: 14px;
    padding-left: 14px;
  }

  .item-flex-big-big {
    flex: 0 0 42%;
    font-size: 14px;
    padding-left: 14px;
  }

  .item-flex-last {
    flex: 1 1 auto;
    font-size: 12px;
  }
  .item-flex-last-last {
    text-align: end;
  }

  .center {
    padding-left: 20px;
  }

  .item-icon {
    position: relative;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: transparent;
      transition: background-color 0.5s ease;
      opacity: 1;
      -moz-transition: opacity 0.3s linear;
      -ms-transition: opacity 0.3s linear;
      -o-transition: opacity 0.3s linear;
      -webkit-transition: opacity 0.3s linear;
      transition: opacity 0.3s linear;
    }
    .fa-check {
      position: absolute;
      font-size: 18px;
      color: #fff;
      left: 18px;
      top: 16px;
      font-weight: 900;
    }
  }
  .item-icon:hover {
    img {
      opacity: 0.4;
    }
  }
  .item-name {
    color: #ffffff;
    font-weight: bold;
  }
  .item-status {
    color: #ffffff;
    font-weight: 600;
  }
  .item-staff {
    text-align: end;
  }

  &.selected-restaurant {
    .item-icon {
      img {
        border: 2px solid #fff;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 1;
      }
    }
  }

  &.selected-restaurant:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
    background-color: #60646c;
  }

  &.pending-restaurant-row:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    top: 0;
    left: 0;
    background-color: #f7a64c;
  }

  &:hover {
    .item-icon {
      img {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .item-cuisine {
    flex: 0 0 25%;
  }

  .item-sales {
    flex: 0 0 11%;
  }

  .item-fees {
    flex: 0 0 8%;
  }

  .item-staff {
    flex: 0 0 3.5%;
  }
`;
