import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { DrawerFilterOrdersContainer, Label } from "./style";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { OrdersContext } from "context/OrdersContext";
import { drawersOrder } from "static/staticData";
import { FaChevronLeft } from "react-icons/fa";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerProcessPaymentAreYouSureContainer from "./DrawerProcessPaymentAreYouSureContainer";
import DrawerCardDetails from "./DrawerCardDetails";
import DrawerProcessWithoutPayment from "./DrawerProcessWithoutPayment";
import CashPaymentModal from "../../Modals/CashPaymentModal";
import NoPaymentNecessaryModal from "../../Modals/NoPaymentNecessaryModal";
import PaymentErrorModal from "../../Modals/PaymentErrorModal";

export default function DrawerProcessPayment({
  isActive = false,
  closePanel = () => {},
  activeOrderDetail = {},
  setIsActiveOrderModalShown = () => {},
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    setSelectedCardDetails,
    selectedCardDetails,
    paymentDrawers,
    setPaymentDrawers,
    setOrderNotification,
    setOrderDetailData,
  } = useContext(OrdersContext);

  const [fechedCardDetails, setFetchedcardDetails] = useState([]);

  useEffect(() => {
    if (activeOrderDetail && isActive) {
      customAxios
        .get(`/orders/${activeOrderDetail?.token}/cards`)
        .then((res) => {
          let data;
          if (res?.data?.length) {
            data = [...res?.data];
            data.filter((el) => (el.listType = 1));
          } else {
            data = [];
          }
          // data.push({
          //   title: "demo",
          //   listType: 1,
          //   brand: "visa",
          //   last4: 3456,
          //   exp_month: 11,
          //   exp_year: 11,
          // });
          data.push({
            title: "Credit or debit card",
            listType: 2,
            icon: "fas fa-credit-card",
          });
          data.push({
            title: "Cash",
            listType: 3,
            icon: "fas fa-money-bill-wave",
          });
          data.push({
            title: "No payment necessary",
            listType: 4,
          });
          setFetchedcardDetails(data);
        })
        .catch((err) => {});
      setSelectedCardDetails("");
    }
  }, [activeOrderDetail, isActive]);

  const onClickProcessPayment = () => {
    if (selectedCardDetails?.listType === 1) {
      closePanel();
      setPaymentDrawers({
        ...paymentDrawers,
        processExistingCardPayment: true,
      });
    } else if (selectedCardDetails?.listType === 2) {
      closePanel();
      setPaymentDrawers({
        ...paymentDrawers,
        cardDetailsForm: true,
      });
    } else if (selectedCardDetails?.listType === 3) {
      closePanel();
      setPaymentDrawers({
        ...paymentDrawers,
        cashPayment: true,
      });
    } else if (selectedCardDetails?.listType === 4) {
      closePanel();
      setPaymentDrawers({
        ...paymentDrawers,
        noPaymentCashConfirmation: true,
      });
    } else {
    }
  };

  const addCardDetails = (cardDetails) => {
    let expiryDetails = cardDetails?.expires?.split("/");
    let cardObj = {
      number: cardDetails?.number,
      exp_month: Number(expiryDetails[0]),
      exp_year: Number(expiryDetails[1]),
      cvc: cardDetails?.cvv,
      country: "US",
      zipcode: "11100",
      is_primary: false,
      listType: 2,
    };
    setSelectedCardDetails(cardObj);
    setPaymentDrawers({
      ...paymentDrawers,
      cardDetailsForm: false,
      processExistingCardPayment: true,
    });
  };

  const paymentByAddCard = (cardDetails, tip) => {
    let cardObj = {
      card: {
        number: cardDetails?.number,
        exp_month: cardDetails?.exp_month,
        exp_year: cardDetails?.exp_year,
        cvc: cardDetails?.cvv,
        country: "US",
        zipcode: "11100",
        is_primary: false,
      },
    };
    if (tip) {
      cardObj.tip = tip?.money;
    }
    customAxios
      .post(`/orders/${activeOrderDetail?.token}/checkout/card`, cardObj)
      .then((res) => {
        if (res.status === 200) {
          setSelectedCardDetails("");
          setOrderNotification({
            message: "Payment Successful.",
            type: "payment",
            table: `Table ${activeOrderDetail?.table_number} • #${
              activeOrderDetail?.token
            } • ${activeOrderDetail?.checkout_data?.total -
              (activeOrderDetail?.checkout_data?.total - tip?.money)}`,
          });
          setPaymentDrawers({
            ...paymentDrawers,
            cardDetailsForm: false,
            processExistingCardPayment: false,
            processPayment: false,
          });
          closeDrawer(drawersOrder.setOpenOrderModal)
          customAxios
            .get(`/orders/${activeOrderDetail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
        } else {
          if (activeOrderDetail?.status === 3) {
            setOrderNotification({
              message: "Payment Declined.",
              type: "declined",
              table: `Table ${activeOrderDetail?.table_number} • #${
                activeOrderDetail?.token
              } • ${activeOrderDetail?.checkout_data?.total -
                (activeOrderDetail?.checkout_data?.total - tip?.money)}`,
            });
            setPaymentDrawers({
              ...paymentDrawers,
              processPayment: false,
              cashPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
            closeDrawer(drawersOrder.setOpenOrderModal)
          } else {
            setPaymentDrawers({
              ...paymentDrawers,
              cardDetailsForm: false,
              processExistingCardPayment: false,
              processPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
          }
        }
      })
      .catch((err) => {});
  };

  const paymentByCash = (tip) => {
    customAxios
      .post(`/orders/${activeOrderDetail?.token}/checkout/cash`)
      .then((res) => {
        if (res.status === 200) {
          setIsActiveOrderModalShown(false);
          setPaymentDrawers({
            ...paymentDrawers,
            processPayment: false,
            cashPayment: false,
          });
          setOrderNotification({
            message: "Cash Payment Recorded.",
            type: "cash",
            table: `Table ${activeOrderDetail?.table_number} • #${activeOrderDetail?.token} • $${activeOrderDetail?.checkout_data?.total}`,
          });
          closeDrawer(drawersOrder.setOpenOrderModal)
          customAxios
            .get(`/orders/${activeOrderDetail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
        } else {
          if (activeOrderDetail?.status === 3) {
            setOrderNotification({
              message: "Cash Payment Not Recorded.",
              type: "no-payment",
              table: `Table ${activeOrderDetail?.table_number} • #${activeOrderDetail?.token} • $${activeOrderDetail?.checkout_data?.total}`,
            });
            setPaymentDrawers({
              ...paymentDrawers,
              processPayment: false,
              cashPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
            closeDrawer(drawersOrder.setOpenOrderModal)
          } else {
            setPaymentDrawers({
              ...paymentDrawers,
              processPayment: false,
              cashPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
          }
        }
      })
      .catch((err) => {});
  };

  const withoutPaymentProcess = () => {
    customAxios
      .post(`/orders/${activeOrderDetail?.token}/checkout/no-payment`)
      .then((res) => {
        if (res.status === 200) {
          setIsActiveOrderModalShown(false);
          setPaymentDrawers({
            ...paymentDrawers,
            processPayment: false,
            processExistingCardPayment: false,
            noPaymentCashConfirmation: false,
          });
          setOrderNotification({
            message: "Payment Collected.",
            type: "cash",
            table: `Table ${activeOrderDetail?.table_number} • #${activeOrderDetail?.token} • $0.00`,
          });
          closeDrawer(drawersOrder.setOpenOrderModal)
          customAxios
            .get(`/orders/${activeOrderDetail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
        } else {
          if (activeOrderDetail.status === 3) {
            setOrderNotification({
              message: "No Payment Collected.",
              type: "no-payment",
              table: `Table ${activeOrderDetail?.table_number} • #${activeOrderDetail?.token} • $0.00`,
            });
            setPaymentDrawers({
              ...paymentDrawers,
              processPayment: false,
              cashPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
            closeDrawer(drawersOrder.setOpenOrderModal)
          } else {
            setPaymentDrawers({
              ...paymentDrawers,
              processExistingCardPayment: false,
              noPaymentCashConfirmation: false,
              processPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
          }
        }
      })
      .catch((err) => {});
  };

  const paymentBycard = (cardDetails, tip) => {
    let postObj = {
      payment_card_id: cardDetails?.id,
    };
    if (tip) {
      postObj.tip = tip?.money;
    }
    customAxios
      .post(`/orders/${activeOrderDetail?.token}/checkout/card`, postObj)
      .then((res) => {
        setPaymentDrawers({
          ...paymentDrawers,
          processPayment: false,
          processExistingCardPayment: false,
        });
        if (res.status === 200) {
          customAxios
            .get(`/orders/${activeOrderDetail?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
          setIsActiveOrderModalShown(false);
          setOrderNotification({
            message: "Payment Successful.",
            type: "payment",
            table: `Table ${activeOrderDetail?.table_number} • #${
              activeOrderDetail?.token
            } • ${activeOrderDetail?.checkout_data?.total -
              (activeOrderDetail?.checkout_data?.total - tip?.money)}`,
          });
          closeDrawer(drawersOrder.setOpenOrderModal)
        } else {
          if (activeOrderDetail?.status === 3) {
            setOrderNotification({
              message: "Payment Declined.",
              type: "declined",
              table: `Table ${activeOrderDetail?.table_number} • #${
                activeOrderDetail?.token
              } • ${activeOrderDetail?.checkout_data?.total -
                (activeOrderDetail?.checkout_data?.total - tip?.money)}`,
            });
            setPaymentDrawers({
              ...paymentDrawers,
              processPayment: false,
              cashPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
            closeDrawer(drawersOrder.setOpenOrderModal)
          } else {
            setPaymentDrawers({
              ...paymentDrawers,
              processPayment: false,
              processExistingCardPayment: false,
              paymentFailed: true,
            });
            setIsActiveOrderModalShown(false);
          }
        }
      })
      .catch((err) => {});
  };

  return (
    <DrawerFilterOrdersContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"19%"}
      left={"38%"}
      position="fixed"
      isNoPaymentModalOpen={paymentDrawers.noPaymentCashConfirmation}
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="drawer-content">
          <div className="drawer-header d-flex justify-conetnt-center align-items-center flex-column">
            <Label size="22px" weight="bold">
              Process Payment
            </Label>
            <Label color="#bbb" size="15px" weight="500" opacity="0.6">
              Choose a payment method.
            </Label>
          </div>
          <div className="fetched-payment-card-list d-flex flex-column  w-100">
            {fechedCardDetails &&
              fechedCardDetails.map((card, _i) => {
                if (card && card?.listType === 1) {
                  return (
                    <div
                      className="fetched-card-item d-flex justify-content-between align-items-start cursor-pointer"
                      onClick={() => {
                        setSelectedCardDetails(card);
                      }}
                      style={{
                        border:
                          selectedCardDetails?.last4 === card?.last4
                            ? "solid 1px #ed2626"
                            : "solid 1px #5b5e61",
                      }}
                    >
                      <div className="d-flex flex-column ">
                        <Label size="15px" weight="bold" color="#fff">
                          {card?.brand} {card?.last4}
                        </Label>
                        <Label size="14px" weight="normal" color="#fff">
                          Ex {`${card?.exp_month} / ${card?.exp_year}`}
                        </Label>
                      </div>
                      <div></div>
                    </div>
                  );
                } else if (
                  (card && card.listType === 2) ||
                  card.listType === 3
                ) {
                  return (
                    <div
                      className="fetched-card-item d-flex justify-content-between align-items-start cursor-pointer"
                      onClick={() => {
                        setSelectedCardDetails(card);
                      }}
                      style={{
                        border:
                          selectedCardDetails?.title === card?.title
                            ? "solid 1px #ed2626"
                            : "solid 1px #5b5e61",
                      }}
                    >
                      <div className="d-flex flex-column ">
                        <Label size="15px" weight="bold" color="#fff">
                          {card?.title}
                        </Label>
                        <Label size="14px" weight="normal" color="#fff"></Label>
                      </div>
                      <div>
                        <i
                          className={card?.icon}
                          style={{ fontSize: "15px", color: "#fff" }}
                        />
                      </div>
                    </div>
                  );
                } else if (card && card.listType === 4) {
                  return (
                    <div
                      className="fetched-card-item d-flex justify-content-between align-items-start cursor-pointer"
                      onClick={() => {
                        setSelectedCardDetails(card);
                      }}
                      style={{
                        border:
                          selectedCardDetails?.title === card?.title
                            ? "solid 1px #ed2626"
                            : "solid 1px #5b5e61",
                      }}
                    >
                      <div className="d-flex flex-column ">
                        <Label size="15px" weight="bold" color="#fff">
                          {card?.title}
                        </Label>
                        <Label size="14px" weight="normal" color="#fff" />
                      </div>
                      <div />
                    </div>
                  );
                } else {
                }
              })}
          </div>
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-5"
            disabled={selectedCardDetails !== "" ? false : true}
            onClick={onClickProcessPayment}
          >
            <b>Continue</b>
          </MyDrawerButton>
          <MyDrawerButton type="redColor" className="mt-5" onClick={closePanel}>
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerProcessPaymentAreYouSureContainer
        isActive={paymentDrawers.processExistingCardPayment}
        // isActive={true}
        closePanel={() =>
          setPaymentDrawers({
            ...paymentDrawers,
            processExistingCardPayment: false,
          })
        }
        paymentBycard={paymentBycard}
        paymentByAddCard={paymentByAddCard}
      />
      <DrawerCardDetails
        // isActive={true}
        isActive={paymentDrawers.cardDetailsForm}
        closePanel={() =>
          setPaymentDrawers({
            ...paymentDrawers,
            cardDetailsForm: false,
          })
        }
        addCardDetails={addCardDetails}
      />
      {/* <DrawerProcessWithoutPayment
        isActive={paymentDrawers.noPaymentCashConfirmation}
        closePanel={() =>
          setPaymentDrawers({
            ...paymentDrawers,
            noPaymentCashConfirmation: false,
          })
        }
        withoutPaymentProcess={withoutPaymentProcess}
      /> */}
      <CashPaymentModal
        isActive={paymentDrawers.cashPayment}
        closePanel={() =>
          setPaymentDrawers({
            ...paymentDrawers,
            cashPayment: false,
          })
        }
        paymentByCash={paymentByCash}
      />
      <NoPaymentNecessaryModal
        isActive={paymentDrawers.noPaymentCashConfirmation}
        closePanel={() =>
          setPaymentDrawers({
            ...paymentDrawers,
            noPaymentCashConfirmation: false,
          })
        }
        withoutPaymentProcess={withoutPaymentProcess}
      />
      <PaymentErrorModal
        isActive={paymentDrawers.paymentFailed}
        closePanel={() =>
          setPaymentDrawers({
            ...paymentDrawers,
            paymentFailed: false,
          })
        }
      />
    </DrawerFilterOrdersContainer>
  );
}
