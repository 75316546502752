import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerFullRefundContainer = styled(MyDrawerCenterModal)`
  .drawer-bottom-content {
    text-align: center;
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .modal-title {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 20px;
    }
    .cash-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 17px 13px;
      }
    }
    h6 {
      line-height: 1.5;
      letter-spacing: 0.27px;
    }
    .bottom-btns {
      margin-top: 25px;
    }
  }
`;
