import styled from "@emotion/styled";

export const NumberIncreaseContainer = styled.div`
  height: 60px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  /* border-top: solid 1px rgba(255,255,255,0.1); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px 0 39px;

  .title {
    margin: 0;
    font-weight: 500;
  }
`;
