import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { colors } from "theme";

export const DrawerDeleteCustomerFailedContainer = styled(MyDrawerBottom)`
  .deleted-customer-modal {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .deleted-customer-modal-meta {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.27px;
    color: #fff;
    margin-bottom: 30px;
    text-align: center;
    opacity: 0.6;
  }
  .deleted-customer-order-details-div {
    border-radius: 16px;
    background-color: rgb(39, 40, 42);
    padding: 12px;
    margin-bottom: 30px;
    text-align: center;
    .customer-name-div {
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.27px;
      color: #fff;
      text-align: center;
      margin-bottom: 8px;
    }
    .customer-details {
      .text {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.27px;
        color: #fff;
        opacity: 0.6;
      }
    }
  }
`;
