import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { mocks } from "static/staticData";
import { DrawerRoleStaffContainer } from "./style";

export default function DrawerRoleStaff({
  isActive = false,
  closePanel = () => {},
}) {
  const { postStaff, setPostStaff } = useContext(RestaurantsContext);

  const onChooseRole = (_role) => {
    setPostStaff({ ...postStaff, role_slug: _role });
    closePanel();
  };
  const onDone = async () => {
    console.log({ postStaff });
  };

  return (
    <DrawerRoleStaffContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Choose Role</div>
          <div></div>
        </div>
        {Object.values(mocks.staffRoles).map((_role, _i) => (
          <DrawerChooseItem
            key={_i}
            className="mb-4"
            title={_role.title}
            active={postStaff?.role_slug === _role.value}
            onChooseItem={() => onChooseRole(_role.value)}
          />
        ))}
        <div className="bottom-btns">
          <MyDrawerButton type="gray" onClick={onDone}>
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerRoleStaffContainer>
  );
}
