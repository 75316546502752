import React, { useContext, useEffect, useState } from "react";
import { RestoreModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const RestoreReviewModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteEntireOrder = () => {},
}) => {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,

    isRestoreDataEnable,
    setRestoreDataEnable,

    reviewRestoreData,
    setReviewRestoreData,

    restoreReviewId,
    setRestoreReviewId,

    staffViewData,

    restaurant,
  } = useContext(RestaurantsContext);

  const [restoreNotification, setRestoreNotification] = useState();

  useEffect(() => {
    if (restoreNotification) {
      setTimeout(() => setRestoreNotification(null), 2 * 1000);
    }
  }, [restoreNotification]);

  const restoreReview = () => {
    if (isRestoreDataEnable) {
      setRestoreDataEnable(false);
      closePanel();
    } else {
      setRestoreDataEnable(true);
      setRestoreNotification({
        message: "Review Restored.",
        type: "restore-review",
        table: `<p style="color: #8F949A"><b>Server's (${staffViewData?.first_name} ${staffViewData?.last_name})</b> review was restored in <b style="color: #8F949A">${restaurant?.title}</b>.</p>`,
      });
      closePanel();
    }
    const configData = { hide_review: isRestoreDataEnable };
    if (restoreReviewId) {
      customAxios
        .patch(`/staff/review/${restoreReviewId}`, configData)
        .then((res) => {
          // setReviewRestoreData(res.data)
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <RestoreModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"31%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {restoreNotification && (
          <MyMessageBoxItemNew
            message={
              restoreNotification?.message ? restoreNotification.message : ""
            }
            table={restoreNotification?.table ? restoreNotification.table : ""}
            type={
              restoreNotification?.type ? restoreNotification.type : "success"
            }
            onClick={() => setRestoreNotification(null)}
          />
        )}
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-undo-alt" />
          </div>
          <div className="delete-suborder-title">Restore Review</div>
          <div className="delete-suborder-meta">
            You are about to restore this <b>Review</b>.
            <br />
            This review will affect the overall rating and will be
            <br />
            visible to Feasttt users. Are you sure?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton type="red" onClick={restoreReview}>
              <b>Yes, Restore Review</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-4">
              <b>No, Keep Removed</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </RestoreModalContainer>
  );
};

export default RestoreReviewModal;
