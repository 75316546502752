import React, { useEffect, useState } from "react";
import { TotalSalesChartContainer } from "./style";
import { TotalSalesChartCore } from "./TotalSalesChartCore";
import MySelect from "components/Elements/MySelect";
import { ordersAnalyticsTotalSales } from "static/staticData";
import moment from "moment";

export const TotalSalesChart = ({
  totalSalesList,
  originalGraphData,
  totalSalesFilter,
  setTotalSalesFilter,
  period,
  dateRange,
}) => {
  return (
    <TotalSalesChartContainer>
      <div className="total-sales-chart-top d-flex justify-content-between align-items-center">
        <h4 className="total-sales-title">{totalSalesFilter?.name}</h4>
        <div className="chart-date">
          {/* {dateRange !== undefined &&
            `${moment(dateRange?.startDate).format(
              "dddd MMMM DD, YYYY"
            )} - ${moment(dateRange?.endDate).format("dddd MMMM DD, YYYY")}`} */}
          {dateRange?.startDate && dateRange?.endDate
            ? `${moment(dateRange.startDate).format("dddd MMMM DD, YYYY")}
                 - ${moment(dateRange.endDate).format("dddd MMMM DD, YYYY")}`
            : period === "day"
            ? moment().format("dddd MMMM DD, YYYY")
            : period === "week"
            ? `${
                moment()
                  .subtract(6, "days")
                  .format("dddd MMMM DD, YYYY")
                  .split("T")[0]
              }
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "month"
            ? `${moment()
                .subtract(30, "days")
                .format("dddd MMMM DD, YYYY")}
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "quarter"
            ? `${moment()
                .subtract(91, "days")
                .format("dddd MMMM DD, YYYY")}
               - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "year"
            ? `${moment()
                .subtract(1, "year")
                .format("dddd MMMM DD, YYYY")}
                - ${moment().format("dddd MMMM DD, YYYY")}`
            : moment()
                .format("dddd, MMMM DD, YYYY")
                .split("T")[0]}
        </div>
        <MySelect
          className="ml-3"
          options={[
            { id: 3, name: "Total Sales" },
            { id: 2, name: "Average Order" },
            { id: 1, name: "# of Orders" },
          ]}
          defaultValue={totalSalesFilter}
          defaultValueId={totalSalesFilter}
          onChange={setTotalSalesFilter}
          dropdownLabel=""
        />
      </div>
      <TotalSalesChartCore
        chartData={totalSalesList}
        originalGraphData={originalGraphData}
        salesFilter={totalSalesFilter?.id}
        period={period}
      />
    </TotalSalesChartContainer>
  );
};
