import React, { useEffect, useState, useMemo, useRef } from "react";
import { AnalyticsRestaurantsPageContainer } from "./style";
import { AnalyticsHeader, CountryChart } from "components/analytics";
import { RestaurantsSignUps } from "components/analytics/restaurants";
import { LiveNow, TabsListByPeriod } from "components/analytics/orders";

import noAvatar from "assets/img/noAvatar.png";
import { customAxios } from "helpers/custom-helpers/customAxios";

import {
  LayersControl,
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  Tooltip,
} from "react-leaflet";
import mapData from "./ges.json";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { ChartTooltip } from "components/analytics/ChartTooltip";
import { colors } from "theme";
import { useHistory } from "react-router-dom";

export const AnalyticsRestaurantsPage = ({ active, total }) => {
  const history = useHistory();
  const [heatData, setHeatData] = useState([]);
  const [ratedDateRange, setRatedDateRange] = useState("");
  const [selectedHighestTab, setSelectedHighestTab] = useState("Highest Rated");
  const [ratedPeriod, setRatedPeriod] = useState("");
  const [ratedData, setRatedData] = useState("");

  let data = [
    [40, -100],
    [38, -89],
    [39, -89],
    [32, -89],
    [31, -89],
  ];

  const newicon = new L.icon({
    iconUrl: require("./../../../assets/img/Simple_orange_circle.svg.png"),
    iconSize: [20, 20],
  });

  const iconStyle = (count) => {
    return new L.icon({
      iconUrl: require("./../../../assets/img/Simple_orange_circle.svg.png"),
      iconSize: sizeRender(count),
    });
  };

  const sizeRender = (count) => {
    if (count > 0 && count <= 100) {
      return [10, 10];
    } else if (count > 50 && count <= 100) {
      return [15, 15];
    } else if (count > 100 && count <= 150) {
      return [20, 20];
    } else {
      return [25, 25];
    }
  };

  function geoJsonStyle(feature) {
    return {
      fillColor: "#1b1c1d",
      weight: 2,
      opacity: 1,
      color: "#1b1c1d", //Outline color
      fillOpacity: 1,
      height: "500px",
    };
  }

  const [map, setMap] = useState(null);
  const geoJsonRef = useRef();

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[40, -100]}
        zoom={4}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{ height: "500px" }}
        // dragging={false}
        // doubleClickZoom={false}
        attributionControl={false}
        // zoomControl={false}
        minZoom={4}
        maxZoom={10}
      >
        <GeoJSON ref={geoJsonRef} data={mapData} style={geoJsonStyle}>
          {heatData &&
            heatData
              .filter((el) => el?.city !== null && el?.city !== "")
              .map((el, index) => {
                let filterData = heatData?.filter(
                  (cityData) => cityData.city === el?.city
                );
                let result = filterData.reduce(
                  (prev, curr) => {
                    const role = curr?.role_slug;
                    return {
                      ...prev,
                      [role]: prev[role] + curr?.count,
                    };
                  },
                  { waiter: 0, manager: 0, kitchen_staff: 0 }
                );
                return (
                  <Marker
                    position={[el?.lat, el?.lng]}
                    key={index}
                    icon={iconStyle(el?.count)}
                  >
                    <Tooltip>
                      <div className="restaurant-analytics-custome-tooltip">
                        <span
                          className="chart-tooltip-amount-customers"
                          id="restaurants-format"
                        >
                          <i
                            className="fas fa-circle country-chart-customers-circle"
                            style={{ color: colors.green }}
                          />
                          &nbsp; Live Now, {el?.city}
                        </span>
                        <div className="tooltip-restaurants-list-container">
                          <span className="tooltip-list-item">
                            {result?.manager || 0} Managers/Owners
                          </span>
                          <span className="tooltip-list-item">
                            {result?.kitchen_staff || 0} Kitchen Staff
                          </span>
                          <span className="tooltip-list-item">
                            {result?.waiter || 0} Hosts/Servers
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  </Marker>
                );
              })}
        </GeoJSON>
      </MapContainer>
    ),
    [heatData]
  );
  window.dispatchEvent(new Event("resize"));

  // useEffect(() => {
  //   getRatingData();
  // }, []);

  useEffect(() => {
    getHeatMapData();
  }, []);

  // highest and lowest rated api implementation
  useEffect(() => {
    let url;
    if (ratedDateRange) {
      url = `/analytics-restaurant/restaurant-ratings?period=${ratedPeriod}&start_date=${ratedDateRange.startDate}&end_date=${ratedDateRange.endDate}`;
    } else {
      url = `/analytics-restaurant/restaurant-ratings?period=${ratedPeriod}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          if (selectedHighestTab && selectedHighestTab === "Highest Rated") {
            setRatedData(res?.data?.highest_rated);
          } else {
            setRatedData(res?.data?.lowest_rated);
          }
        } else {
          setRatedData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [ratedPeriod, ratedDateRange, selectedHighestTab]);

  const onEarnersTimePeriodChange = (time) => {
    setRatedPeriod(time);
  };

  const onChangeHighestActiveTab = (tab) => {
    setSelectedHighestTab(tab);
  };

  const getRoleAmount = () => {
    return (
      heatData &&
      heatData.reduce(
        (prev, curr) => {
          const role = curr?.role_slug;
          return {
            ...prev,
            [role]: prev[role] + curr?.count,
          };
        },
        { waiter: 0, manager: 0, kitchen_staff: 0 }
      )
    );
  };

  function getHeatMapData() {
    customAxios
      .get(`/analytics-restaurant/heatmap`)
      .then((res) => {
        if (res?.status === 200) {
          setHeatData(res?.data);
        } else {
          setHeatData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {/* {active ? ( */}
      <AnalyticsRestaurantsPageContainer>
        <AnalyticsHeader title="Restaurants Analytics">
          {total.map(({ title, value }) => (
            <div className="total-container" key={title + value}>
              <span className="total-title">{title}</span>
              <span className="total-value">{value}</span>
            </div>
          ))}
        </AnalyticsHeader>
        <div style={{ padding: "0 40px 0 55px" }}>
          <RestaurantsSignUps />
          <div className="restaurants-live-now-container">
            <TabsListByPeriod
              className="special-list-restaurants"
              tabs={["Highest Rated", "Lowest Rated"]}
              onTimePeriodChange={onEarnersTimePeriodChange}
              setDateRange={setRatedDateRange}
              dateRange={ratedDateRange}
              onChangeActiveTab={onChangeHighestActiveTab}
              label1="Restaurant Name"
              label2="Orders"
            >
              {ratedData &&
                ratedData.map(
                  ({ name, rating, image, restaurant_id }, index) => (
                    <div
                      className={`orders-stats-list-item ${
                        (index + 1) % 2 === 0 ? "darker" : "dark"
                      }`}
                      key={index}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(
                          `/restaurant-detail/${restaurant_id}?view=overall`
                        );
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <div className="stat-index">
                        {index + 1 > 9 ? `${index + 1}` : `0${index + 1}`}
                      </div>
                      <div className="stat-logo logo-div">
                        <img
                          src={image || noAvatar}
                          width="31"
                          height="31"
                          alt="restaurant-logo"
                          style={{ borderRadius: "50px" }}
                        />
                      </div>
                      <span className="stat-title">{name}</span>
                      <div className="cost-container">
                        <span className="cost-title">
                          <i className="fas fa-star" /> {rating}
                        </span>
                      </div>
                    </div>
                  )
                )}
            </TabsListByPeriod>

            {/* <LiveNow facts={_mocked_LiveFacts}> */}
            <LiveNow rolesCount={getRoleAmount()}>
              {/* <CountryChart isRestaurantsCountryChart /> */}
              <div className="heat-map-ui">{displayMap}</div>
            </LiveNow>
          </div>
        </div>
      </AnalyticsRestaurantsPageContainer>
      {/* ) : (
        ""
      )} */}
    </>
  );
};
