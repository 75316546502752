/* eslint-disable no-useless-escape */
import { DrawerContainer } from "components/styledStyle";
import { MyAccountContext } from "context/MyAccountContext";
import React, { useContext, useState } from "react";
import { DrawerIsRestorePwdContainer } from "./style";

export default function DrawerIsRestorePwd({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { setRestorePasswordPanel, setChangePasswordPanel } = useContext(
    MyAccountContext
  );
  const [emailField, setEmailField] = useState("");
  const [isMailSentActive, setMailSentActive] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function emailValueChange(e) {
    e.preventDefault();
    setEmailField(e.target.value);
  }

  function closeEverything() {
    setMailSentActive(false);
    setRestorePasswordPanel(false);
    setChangePasswordPanel(false);
  }
  return (
    <DrawerIsRestorePwdContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="restore-password-panel">
          <div className="panel-header-container">
            <div
              className="back-arrow"
              onClick={() => setRestorePasswordPanel(false)}
            >
              <i className="fas fa-chevron-left" />
            </div>
            <div className="panel-header-context">Restore Password</div>
          </div>

          <div className="panel-content-body">
            <p className="panel-content-message">
              Don’t worry, we’ll send you a restore link to your email address
            </p>
            <div className="enter-email-placeholder">
              <input
                placeholder="Enter email address"
                value={emailField}
                onChange={(e) => emailValueChange(e)}
              />
            </div>
          </div>

          <div className="buttons-container">
            <button
              type="button"
              className={`button-general submit-button ${
                validateEmail(emailField) ? "active" : ""
              }`}
              onClick={() => setMailSentActive(true)}
            >
              Continue
            </button>
            <button
              type="button"
              className="button-general cancel-button"
              onClick={() => setRestorePasswordPanel(false)}
            >
              Cancel
            </button>
          </div>

          {/* We sent you an email block */}
          <div
            className={`email-sent-message ${isMailSentActive ? "active" : ""}`}
          >
            <h3>We sent you an email</h3>
            <p>Check your email to restore password ({emailField}).</p>
            <p>
              Didn’t get it?{" "}
              <span>
                Resend <i className="fas fa-chevron-right" />
              </span>
            </p>
            <button type="button" onClick={() => closeEverything()}>
              Okay
            </button>
          </div>
        </div>
      </DrawerContainer>
    </DrawerIsRestorePwdContainer>
  );
}
