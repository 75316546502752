import React, { useContext, useEffect, useState } from "react";
import { ChangeRoleToManagerModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import EmptyStaff from "assets/img/noAvatar.png";
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyButton from "components/Elements/MyButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

const ChangeRoleToManagerModal = ({
  isActive = false,
  closePanel = () => {},
  getStaffAll = () => {},
  // onDeleteEntireOrder = () => {},
}) => {
  const { id } = useParams();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    staffViewData,
    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,
    setStaffs,
  } = useContext(RestaurantsContext);

  const [
    changeRoleToManagerNotification,
    setChangeRoleToManagerNotification,
  ] = useState();

  const [
    errorChangeRoleToManagerNotification,
    setErrorChangeRoleToManagerNotification,
  ] = useState();

  useEffect(() => {
    if (changeRoleToManagerNotification) {
      setTimeout(() => setChangeRoleToManagerNotification(null), 2 * 1000);
    }
  }, [changeRoleToManagerNotification]);

  useEffect(() => {
    if (errorChangeRoleToManagerNotification) {
      setTimeout(() => setErrorChangeRoleToManagerNotification(null), 2 * 1000);
    }
  }, [errorChangeRoleToManagerNotification]);

  const onClickRoleChangeToManager = () => {
    const roleConfig = {
      role_slug:
        (staffViewData?.role_slug === "kitchen_staff" && "manager") ||
        (staffViewData?.role_slug === "waiter" && "manager") ||
        (staffViewData?.role_slug === "manager" && "kitchen_staff"),
    };
    customAxios
      .put(`/staff/${staffViewData?.id}?restaurant_id=${id}`, roleConfig)
      .then((res) => {
        if (res.status === 200) {
          setChangeRoleToManagerNotification({
            message: "Role Changed.",
            type: "changed-role-to-kitchen",
            table: `<p style="color: #8F949A"><b> ${staffViewData?.first_name} ${staffViewData?.last_name} </b> role has been successfully changed to the <b>Manager.</b>.</p>`,
          });
          closePanel();
          setStaffChangeRoleMainModal({
            ...staffChangeRoleMainModal,
            staffServerHostActiveModal: false,
          });
          getStaffAll();
        } else {
          setErrorChangeRoleToManagerNotification({
            message: "Oops! Something went wrong.",
            type: "error",
            table: `<p style="color: #8F949A"><b> ${staffViewData?.first_name} ${staffViewData?.last_name} </b> role has not been changed. Please try again.</p>`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };


  return (
    <ChangeRoleToManagerModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {changeRoleToManagerNotification && (
          <MyMessageBoxItemNew
            message={
              changeRoleToManagerNotification?.message
                ? changeRoleToManagerNotification.message
                : ""
            }
            table={
              changeRoleToManagerNotification?.table
                ? changeRoleToManagerNotification.table
                : ""
            }
            type={
              changeRoleToManagerNotification?.type
                ? changeRoleToManagerNotification.type
                : "success"
            }
            onClick={() => setChangeRoleToManagerNotification(null)}
          />
        )}

        {errorChangeRoleToManagerNotification && (
          <MyMessageBoxItemNew
            message={
              errorChangeRoleToManagerNotification?.message
                ? errorChangeRoleToManagerNotification.message
                : ""
            }
            table={
              errorChangeRoleToManagerNotification?.table
                ? errorChangeRoleToManagerNotification.table
                : ""
            }
            type={
              errorChangeRoleToManagerNotification?.type
                ? errorChangeRoleToManagerNotification.type
                : "success"
            }
            onClick={() => setErrorChangeRoleToManagerNotification(null)}
          />
        )}

        <div className="server-host-active-modal">
          <div className="staff-profile">
            <i className="fas fa-question" />
          </div>
          <div className="staff-title">Are you sure?</div>
          <div className="staff-option">
            <p>
              Are you sure you want to change{" "}
              <b>
                {staffViewData?.first_name === undefined &&
                staffViewData?.last_name === undefined
                  ? "No Name"
                  : `${staffViewData?.first_name} ${staffViewData?.last_name}`}
              </b>{" "}
              <br />
              <b>
                {(staffViewData?.role_slug === "kitchen_staff" &&
                  "(Kitchen Staff)") ||
                  (staffViewData?.role_slug === "waiter" && "Server/Host") ||
                  (staffViewData?.role_slug === "manager" && "Manager")}
              </b>{" "}
              role to the{" "}
              <b>
                {(staffViewData?.role_slug === "kitchen_staff" &&
                  "(Manager)") ||
                  (staffViewData?.role_slug === "waiter" && "(Manager)") ||
                  (staffViewData?.role_slug === "manager" && "(Kitchen Staff)")}
              </b>
              ?{" "}
            </p>

            <p>It will change permissions for this user. </p>
          </div>
          <div className="actions">
            <MyButton
              className="change-role-btn"
              type="red"
              onClick={onClickRoleChangeToManager}
            >
              Yes, Change Role
            </MyButton>
            <div className="cancel-btn" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </ChangeRoleToManagerModalContainer>
  );
};

export default ChangeRoleToManagerModal;
