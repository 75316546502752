import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const EnterNewPhoneNumberContainer = styled(MyDrawerCenterModal)`
  .enter-new-number {
    position: relative;
    &:before {
      content: "";
      width: 80px;
      height: 80px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -93px;
      left: 164px;
    }
    .modal-image {
      position: absolute;
      left: 13rem;
      top: -7rem;
      .round-circle {
        width: 70px;
        height: 70px;
        border: solid 5px #27282a;
        background-color: rgba(255, 255, 255, 0.08);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 29px;
        }
      }
    }
    .modal-title-new-number {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      // margin-top: 31px;
    }
    .account-form-item {
      padding-top: unset;
      width: 542px;
      margin-top: 27px;
      .input-label {
        label {
          opacity: 0.5;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: -0.34px;
          color: #fff;
        }
      }
      .single-ant-format-input {
        width: 100%;
        color: white;
        font-weight: 500;
        background-color: transparent;
        outline: unset;
        border: 0;
      }
    }
    .break-line {
      width: 400px;
    }
    .new-number-action-btns {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  }
`;
