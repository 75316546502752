/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { RestaurantTableFloorSelectContainer } from "./style";
import { Dropdown } from "react-bootstrap";
import { colors } from "theme";

export default function RestaurantTableFloorSelect({
  className = "",
  titleKey = "name",
  options = [],
  defaultValueId,
  tablesData,
  onChange = () => {},
  renderExtra = () => {},
}) {
  const [value, setValue] = useState(null);

  const onSelect = (option) => {
    console.log(option?.id);
    setValue(options.find((_option) => _option.id === option.id));
    onChange(option);
  };

  // const [toggleArrowIcon, setToggleArrowIcon] = useState(false);

  // console.log(value?.id);
  // console.log(tablesData)

  return (
    <RestaurantTableFloorSelectContainer className={className}>
      <Dropdown.Toggle id="dropdown-basic">
        {value
          ? value[titleKey]
          : defaultValueId
          ? options.find((_option) => _option.id == defaultValueId)
            ? options.find((_option) => _option.id == defaultValueId)[titleKey]
            : "Select"
          : "Select"}

        {value?.tables?.length > 0 ? (
          <i className="fas fa-circle" style={{ color: colors.green, fontSize: '13px', position: "relative", marginLeft: "-13px" }} />
        ) : (
          <i className="fas fa-circle" style={{ fontSize: '13px', position: "relative", marginLeft: "-13px" }} />
        )}

        <i className="fal fa-angle-right" />

        {/* {toggleArrowIcon ? (
          <i
            class="fal fa-angle-up"
            onClick={() => setToggleArrowIcon(!toggleArrowIcon)}
          />
        ) : (
          <i
            class="fal fa-angle-right"
            onClick={() => setToggleArrowIcon(!toggleArrowIcon)}
          />
        )} */}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((_option, _i) => {
          return (
            <Dropdown.Item key={_i} onClick={() => onSelect(_option)}>
              <div className="d-flex justify-content-between">
                <div>{_option.name}</div>
                <div>
                  {/* {value?.tables?.length > 0 ? (
                    <i
                      className="fas fa-circle"
                      style={{ color: colors.green }}
                    />
                  ) : (
                    <i className="fas fa-circle" />
                  )} */}
                  <i className="fas fa-circle" style={{fontSize: "13px", position:"absolute", right:"15px" }} />
                </div>
              </div>
            </Dropdown.Item>
          );
        })}
        {renderExtra() && <Dropdown.Item>{renderExtra()}</Dropdown.Item>}
      </Dropdown.Menu>
    </RestaurantTableFloorSelectContainer>
  );
}
