import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useState, useContext } from "react";
import { DrawerDeleteCustomerFailedContainer } from "./style";
import { OopsFail, NoAvatar } from "assets/img";
import { CustomersContext } from "context/CustomersContext";

export default function DrawerDeleteCustomerFailed({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  deleteCustomerResponse = "",
}) {
  const { profileData } = useContext(CustomersContext);
  return (
    <DrawerDeleteCustomerFailedContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div>
          <img
            src={OopsFail}
            alt=""
            style={{
              width: "350px",
              height: "216px",
            }}
          />
        </div>
        <div className="deleted-customer-modal">Oops!</div>
        <div className="deleted-customer-modal-meta">
          Customer account can't be deleted while customer are checked in to a
          restaurant.
        </div>
        <div className="deleted-customer-order-details-div">
          <img
            src={profileData?.avatar?.file || NoAvatar}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              marginBottom: "8px",
              textAlign: "center",
              borderRadius: "50%",
            }}
          />
          <div className="customer-name-div">
            {profileData &&
              `${profileData?.first_name} ${profileData?.last_name}`}
          </div>
          <div className="customer-details">
            <span className="text">
              {deleteCustomerResponse?.guests_count} Guests{" "}
            </span>
            <span className="mx-2">•</span>
            <span className="text">
              {deleteCustomerResponse?.items_count} Items{" "}
            </span>
            <span className="mx-2">•</span>
            <span className="text"> ${deleteCustomerResponse?.total} </span>
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold"
            onClick={() => {
              onDone();
            }}
          >
            <b>Okay</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDeleteCustomerFailedContainer>
  );
}
