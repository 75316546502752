import React from "react";
import { FaCheck } from "react-icons/fa";
import { colors } from "theme";
import { IconCheckContainer } from "./style";

export default function IconCheck({
  className = "",
  size = "normal",
}) {
  return (
    <IconCheckContainer className={`${className}`} size={size}>
      <FaCheck color={colors.white} />
    </IconCheckContainer>
  );
}
