import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerAddAddressContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    .fa-plus {
      color: #ed2626;
      font-size: 20px;
      cursor: pointer;
    }
    .fa-trash {
      color: #ed2626;
      font-size: 20px;
      margin-top: 6px;
    }
  }
  .address-list-div {
    .address-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 5px;
      padding-bottom: 11px;
      .address-info {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
