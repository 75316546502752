import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerCategoryMoveItemsContainer = styled(MyDrawerRight)`
  padding: 20px;

  .main-title {
    margin-bottom: 30px;
  }

  .drawer-choose--option {
    p {
      margin-left: 26px;
    }
  }

  .bottom-btns {
    .move-btn {
      margin-top: 30px;
      font-weight: 600;
    }
    .back-btn {
      color: #ed2626 !important;
      margin-top: 20px;
      font-weight: 600;
    }
  }

  .white {
    border: 1px solid white;
  }
 
  .drawer-choose--option {
    padding: 17px 26px;
  }
`;
