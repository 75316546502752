import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerDeleteConfirmCuisinesModalContainer = styled(
  MyDrawerCenterModal
)`
  .delete-cuisine-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -77px;
      left: 167px;
    }
    .delete-icon {
      background-color: #ed2626;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      position: absolute;
      top: -70px;
      left: 174px;

      i {
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding: 16px 17px;
      }
    }
    .delete-cuisine-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
      margin-bottom: 16px;
    }
    .delete-cuisine-subtitle {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }
    .cuisine-content {
      text-align: center;
      margin-bottom: 18px;
      color: #8f949a;
      font-size: 14px;
    }
    .delete-cuisine-btn {
      button {
        width: 100%;
        margin-bottom: 10px;
      }
      .cancel-btn {
        text-align: center;
        margin-top: 20px;
        color: #ed2626;
      }
    }
  }
`;
