import styled from "@emotion/styled";
import { colors } from "theme";

export const BottomWithIconWrapperContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .drawer-bottom {
    position: relative;
    background-color: #27282a;
    padding: 70px 26px 33px;
    border-radius: 40px 40px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .drawer-icon-wrapper {
      position: absolute;
      content: "";
      width: 70px;
      height: 70px;
      top: -35px;
      background-color: #27282a;
      border-radius: 50%;
      z-index: 1;
      padding: 7px;
      .drawer-icon {
        background-color: ${(props) =>
          props.iconBackGroundColor
            ? props.iconBackGroundColor
            : colors.redColor};
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
`;
