import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteTableContainer } from "./style";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { pendingOrderError } from "assets/img";

export default function DrawerDeleteTable({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    closeDrawer,
    floors,
    fetchFloors,
    floorId,
    postTable,
    setPostTable,
  } = useContext(RestaurantsContext);
  const { id } = useParams();

  const [tableRemoveNotification, setTableRemoveNotification] = useState();

  useEffect(() => {
    if (tableRemoveNotification) {
      setTimeout(() => setTableRemoveNotification(null), 2 * 1000);
    }
  }, [tableRemoveNotification]);

  const currentFloor = floors.filter((item) => item.id === floorId)[0];

  const onRemove = async () => {
    if (postTable.id) {
      currentFloor.tables = currentFloor.tables.filter(
        (table) => table.id !== postTable.id
      );
      try {
        await restaurantServices.updateFloor(id, currentFloor);
        fetchFloors(id);
        closeDrawer([
          drawersMenu.editTable,
          drawersMenu.deleteTable,
          drawersMenu.deleteTableSide,
        ]);
        setPostTable({});
        setTableRemoveNotification({
          message: `Table ${tableNumber} Removed.`,
          type: "remove",
          table: ``,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      closeDrawer([
        drawersMenu.editTable,
        drawersMenu.deleteTable,
        drawersMenu.deleteTableSide,
      ]);
      setPostTable({});
    }
  };

  const onClose = () => {
    closeDrawer([
      drawersMenu.editTable,
      drawersMenu.deleteTable,
      drawersMenu.deleteTableSide,
    ]);
  };

  /* eslint-disable no-unused-expressions */
  let occupiedTable;
  let tableNumber;
  currentFloor?.tables.map((el) => {
    if (el?.id === postTable?.id) {
      occupiedTable = el?.status;
      tableNumber = el?.table_number;
    }
  });

  // console.log(postTable)

  return (
    <DrawerDeleteTableContainer
      isActive={isActive}
      closePanel={closePanel}
      left="40%"
      top={occupiedTable === 2 ? "38%" : "30%"}
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      {tableRemoveNotification && (
        <MyMessageBoxItemNew
          message={
            tableRemoveNotification?.message
              ? tableRemoveNotification.message
              : ""
          }
          table={
            tableRemoveNotification?.table ? tableRemoveNotification.table : ""
          }
          type={
            tableRemoveNotification?.type
              ? tableRemoveNotification.type
              : "success"
          }
          onClick={() => setTableRemoveNotification(null)}
        />
      )}

      <DrawerContainer>
        <div className="remove-modal-wrapper">
          <h2
            className="drawer-title text-white text-center text-bold"
            style={occupiedTable === 2 ? {} : { marginTop: "20px" }}
          >
            Remove Table
          </h2>

          {occupiedTable === 2 ? null : (
            <div className="top-icon">
              <i className="fad fa-question" />
            </div>
          )}

          {occupiedTable === 2 ? (
            <div className="occupied">
              <img
                src={pendingOrderError}
                alt="order deleted"
                className="img-leave-error"
              />
              <p className="text-white text-left mt-4">
                We’re sorry, <b>Table {tableNumber}</b> is currently occupied.
                Please wait for the guest(s) to checkout before trying to remove
                this table again.
              </p>
            </div>
          ) : (
            <p className="text-white text-center remove-text">
              Are you sure you want to remove <b>Table {tableNumber}</b>?
            </p>
          )}

          {occupiedTable === 2 ? (
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                // onClick={() => closeDrawer(drawersMenu.deleteTable)}
                onClick={closePanel}
              >
                Okay
              </MyDrawerButton>
            </div>
          ) : (
            <div className="bottom-btns">
              <MyDrawerButton type="red" onClick={onRemove}>
                Remove
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                // onClick={() => closeDrawer(drawersMenu.deleteTable)}
                onClick={onClose}
              >
                Keep
              </MyDrawerButton>
            </div>
          )}
        </div>
      </DrawerContainer>
    </DrawerDeleteTableContainer>
  );
}
