import React, { useState, useEffect, useMemo, useRef } from "react";
import { AnalyticsOrdersPageContainer } from "./style";
import { AnalyticsHeader, CountryChart } from "components/analytics";
import {
  TotalSales,
  OrdersByHour,
  LiveNow,
  TabsListByPeriod,
} from "components/analytics/orders";
import { customAxios } from "helpers/custom-helpers/customAxios";

import noAvatar from "assets/img/noAvatar.png";

import {
  LayersControl,
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  Tooltip,
} from "react-leaflet";
import mapData from "./ges.json";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { colors } from "theme";
import { useHistory } from "react-router-dom";
import DrawerItem from "./Drawers/DrawerItem";

export const AnalyticsOrdersPage = ({ active }) => {
  const history = useHistory();
  // top customers and selling items section states
  const [topCustomersPeriod, setTopCustomersPeriod] = useState("day");
  const [topCustomersDateRange, setTopCustomersDateRange] = useState();
  const [selectedTopCustomersTab, setSelectedTopCustomersTab] = useState(
    "Top Customers"
  );
  const [topCustomersData, setTopCustomersData] = useState();

  // top earners and lowest earners section states
  const [earnersPeriod, setEarnersPeriod] = useState("day");
  const [earnersDateRange, setEarnersDateRange] = useState();
  const [selectedEarnersTab, setSelectedEarnersTab] = useState("Top Earners");
  const [earnersData, setEarnersData] = useState();

  // most orders and lowest orders section
  const [mostOrdersPeriod, setMostOrdersPeriod] = useState("day");
  const [mostOrdersDateRange, setMostOrdersDateRange] = useState();
  const [selectedmostOrdersTab, setSelectedMostOrdersTab] = useState(
    "Most Orders"
  );
  const [mostOrdersData, setMostOrdersData] = useState([]);
  const [ordersHeatMapData, setOrdersHeatMapData] = useState([]);

  const [isDrawerItemOpen, setIsDrawerItemOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState("");

  const [orderCustomerTabLabel, setOrderCustomerTabLabel] = useState("")

  let data = [
    [40, -100],
    [38, -89],
    [39, -89],
    [32, -89],
    [31, -89],
  ];

  const sizeRender = (count) => {
    if (count > 0 && count <= 25) {
      return [10, 10];
    } else if (count > 25 && count <= 50) {
      return [15, 15];
    } else if (count > 50 && count <= 100) {
      return [20, 20];
    } else {
      return [25, 25];
    }
  };

  const iconStyle = (count) => {
    return new L.icon({
      iconUrl: require("./../../../assets/img/Simple_orange_circle.svg.png"),
      iconSize: sizeRender(count),
    });
  };

  function geoJsonStyle(feature) {
    return {
      fillColor: "#1b1c1d",
      weight: 2,
      opacity: 1,
      color: "#1b1c1d", //Outline color
      fillOpacity: 1,
      height: "500px",
    };
  }

  const [map, setMap] = useState(null);
  const geoJsonRef = useRef();
  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[40, -100]}
        zoom={4}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{ height: "500px" }}
        // dragging={false}
        // doubleClickZoom={false}
        attributionControl={false}
        // zoomControl={false}
        minZoom={4}
        maxZoom={10}
      >
        <GeoJSON ref={geoJsonRef} data={mapData} style={geoJsonStyle}>
          {ordersHeatMapData &&
            ordersHeatMapData.length &&
            ordersHeatMapData
              .filter((el) => el?.location !== "" && el?.location !== null)
              .map((el, index) => {
                return (
                  <Marker
                    position={[el?.lat, el?.lng]}
                    key={index}
                    icon={iconStyle(el?.active_orders)}
                  >
                    <Tooltip>
                      <div className="orders-analytics-heat-map">
                        <span
                          className="chart-tooltip-title"
                          id="orders-format"
                        >
                          <i
                            className="fas fa-store-alt"
                            style={{ color: colors.redColor }}
                          />
                          {el?.location || ""}
                        </span>
                        <div className="tooltip-restaurants-list-container">
                          {el?.active_orders ? (
                            <span className="tooltip-list-item">
                              {`${el?.active_orders} Active Orders`}
                            </span>
                          ) : (
                            <></>
                          )}
                          {el?.guest_checked_in ? (
                            <span className="tooltip-list-item">
                              {`${el?.guest_checked_in} Guests Checked In`}
                            </span>
                          ) : (
                            <></>
                          )}
                          {el?.average_order ? (
                            <span className="tooltip-list-item">
                              {`$${el?.average_order} Average Order`}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </Marker>
                );
              })}
        </GeoJSON>
      </MapContainer>
    ),
    [ordersHeatMapData]
  );
  window.dispatchEvent(new Event("resize"));

  const _mocked_orders = [
    ...new Array(23)
      .fill(undefined)
      .map(() => Math.ceil(Math.random() * (600 - 200) + 200)),
  ];

  useEffect(() => {
    customAxios
      .get(`/analytics-orders/heatmap`)
      .then((res) => {
        if (res?.status === 200) {
          setOrdersHeatMapData(res?.data);
        } else {
          setOrdersHeatMapData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let url;
    if (topCustomersDateRange) {
      url = `/analytics-orders/top-customer-and-items?period=${topCustomersPeriod}&start_date=${topCustomersDateRange.startDate}&end_date=${topCustomersDateRange.endDate}`;
    } else {
      url = `/analytics-orders/top-customer-and-items?period=${topCustomersPeriod}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          setTopCustomersData([]);
          if (
            selectedTopCustomersTab &&
            selectedTopCustomersTab === "Top Customers"
          ) {
            let sortData = res?.data?.top_customers?.sort(
              (a, b) => b?.order_count - a?.order_count
            );
            setTopCustomersData(sortData);
          } else {
            let sortData = res?.data?.top_items?.sort(
              (a, b) => b?.order_count - a?.order_count
            );
            setTopCustomersData(sortData);
          }
        } else {
          setTopCustomersData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [topCustomersPeriod, topCustomersDateRange, selectedTopCustomersTab]);

  useEffect(() => {
    let url;
    if (earnersDateRange) {
      url = `/analytics-orders/restaurant-by-sales?period=${earnersPeriod}&start_date=${earnersDateRange.startDate}&end_date=${earnersDateRange.endDate}`;
    } else {
      url = `/analytics-orders/restaurant-by-sales?period=${earnersPeriod}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          if (selectedEarnersTab && selectedEarnersTab === "Top Earners") {
            let sortData = res?.data?.top_restaurant.sort((a, b) => {
              return +b?.total_income - +a?.total_income;
            });
            setEarnersData(sortData);
          } else {
            let sortData = res?.data?.lowest_restaurant.sort(
              (a, b) => +a?.total_income - +b?.total_income
            );
            setEarnersData(sortData);
          }
        } else {
          setEarnersData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [earnersPeriod, earnersDateRange, selectedEarnersTab]);

  useEffect(() => {
    let url;
    if (mostOrdersDateRange) {
      url = `/analytics-orders/restaurant-by-orders?period=${mostOrdersPeriod}&start_date=${mostOrdersDateRange.startDate}&end_date=${mostOrdersDateRange.endDate}`;
    } else {
      url = `/analytics-orders/restaurant-by-orders?period=${mostOrdersPeriod}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          setMostOrdersData([]);
          if (selectedmostOrdersTab === "Most Orders") {
            let sortData = res?.data?.top_restaurant.sort(
              (a, b) => b?.order_count - a?.order_count
            );
            setMostOrdersData(sortData);
          } else {
            let sortData = res?.data?.lowest_restaurant.sort(
              (a, b) => a?.order_count - b?.order_count
            );
            setMostOrdersData(sortData);
          }
        } else {
          setMostOrdersData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [mostOrdersPeriod, mostOrdersDateRange, selectedmostOrdersTab]);

  const _mocked_LiveFacts = [
    { title: "Active Orders", value: 876 },
    { title: " Guests Checked In", value: 982 },
    { title: "Average order", value: "$76.08" },
  ];

  const onTopCustomersTimePeriodChange = (time) => {
    setTopCustomersPeriod(time);
  };

  const onChangeTopCustomerActiveTab = (tab) => {
    setOrderCustomerTabLabel(tab)
    // console.log(tab)
    setSelectedTopCustomersTab(tab);
  };

  const onEarnersTimePeriodChange = (time) => {
    setEarnersPeriod(time);
  };

  const onChangeEarnersActiveTab = (tab) => {
    setSelectedEarnersTab(tab);
  };

  const onMostOrdersPeriodChange = (time) => {
    setMostOrdersPeriod(time);
  };

  const onChangeMostOrdersActiveTab = (tab) => {
    setSelectedMostOrdersTab(tab);
  };

  const getRoleAmount = () => {
    return (
      ordersHeatMapData &&
      ordersHeatMapData?.reduce(
        (prev, curr) => {
          return {
            ...prev,
            active_orders: prev?.active_orders + curr?.active_orders,
            guest_checked_in: prev?.guest_checked_in + curr?.guest_checked_in,
            average_order: prev?.average_order + curr?.average_order,
          };
        },
        { active_orders: 0, guest_checked_in: 0, average_order: 0 }
      )
    );
  };

  return (
    <>
      {/* {active ? ( */}
      <AnalyticsOrdersPageContainer>
        <AnalyticsHeader title="Orders Analytics" />
        <div style={{ padding: "0 40px 0 55px" }}>
          <TotalSales />
          <OrdersByHour orders={_mocked_orders} />
          <div className="live-container">
            <TabsListByPeriod
              className="tabs-list-restaurants"
              tabs={["Top Earners", "Lowest Grossing"]}
              onTimePeriodChange={onEarnersTimePeriodChange}
              setDateRange={setEarnersDateRange}
              dateRange={earnersDateRange}
              onChangeActiveTab={onChangeEarnersActiveTab}
              label1="Restaurant Name"
              label2="Sales"
            >
              {earnersData &&
                earnersData.map(
                  (
                    { restaurant_name, total_income, image, restaurant_id },
                    index
                  ) => (
                    <div
                      className={`orders-stats-list-item ${
                        (index + 1) % 2 === 0 ? "darker" : "dark"
                      }`}
                      key={restaurant_name}
                      // style={{
                      //   cursor: "default",
                      // }}
                      onClick={() => {
                        history.push(
                          `/restaurant-detail/${restaurant_id}?view=overall`
                        );
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <div className="stat-index">{index + 1 > 9 ? `${index + 1}` : `0${index + 1}`}</div>
                      <div className="stat-logo logo-div">
                        <img
                          src={image || noAvatar}
                          width="31"
                          height="31"
                          alt="restaurant-logo"
                        />
                      </div>
                      <span className="stat-title">{restaurant_name}</span>
                      <div className="cost-container">
                        <span className="cost-title">$ {total_income}</span>
                      </div>
                    </div>
                  )
                )}
            </TabsListByPeriod>
            <LiveNow rolesCount={getRoleAmount()} isOrderAnalytics={true}>
              {/* <CountryChart isOrdersCountryChart /> */}
              <div className="heat-map-ui">{displayMap}</div>
            </LiveNow>
          </div>
          <div className="people-by-orders-and-customers-container">
            <TabsListByPeriod
              className="tabs-list-people-by-orders"
              tabs={["Most Orders", "Lowest Orders"]}
              onTimePeriodChange={onMostOrdersPeriodChange}
              setDateRange={setMostOrdersDateRange}
              dateRange={mostOrdersDateRange}
              onChangeActiveTab={onChangeMostOrdersActiveTab}
              label1="Restaurant Name"
              label2="Orders"
            >
              {mostOrdersData &&
                mostOrdersData.map(
                  ({ name, order_count, image, restaurant_id }, index) => (
                    <div
                      className={`orders-stats-list-item ${
                        (index + 1) % 2 === 0 ? "darker" : "dark"
                      }`}
                      key={name}
                      // style={{
                      //   cursor: "default",
                      // }}
                      onClick={() => {
                        history.push(
                          `/restaurant-detail/${restaurant_id}?view=overall`
                        );
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <div className="stat-index">{index + 1 > 9 ? `${index + 1}` : `0${index + 1}`}</div>
                      <div className="stat-logo logo-div">
                        <img
                          src={image || noAvatar}
                          width="31"
                          height="31"
                          alt="restaurant-logo"
                        />
                      </div>
                      <span className="stat-title">{name}</span>
                      <div className="cost-container">
                        <span className="cost-title">{order_count}</span>
                        <span className="cost-description">orders</span>
                      </div>
                    </div>
                  )
                )}
            </TabsListByPeriod>
            <TabsListByPeriod
              className="tabs-list-customers"
              tabs={["Top Customers", "Top Selling Items"]}
              onTimePeriodChange={onTopCustomersTimePeriodChange}
              setDateRange={setTopCustomersDateRange}
              dateRange={topCustomersDateRange}
              onChangeActiveTab={onChangeTopCustomerActiveTab}
              label1={orderCustomerTabLabel === "Top Customers" ? "Customer Name" : "Item"}
              label2="Orders"
            >
              {topCustomersData && topCustomersData.map((el, index) => (
                  <div
                    className={`orders-stats-list-item ${
                      (index + 1) % 2 === 0 ? "darker" : "dark"
                    }`}
                    key={el?.name || el?.concat_ws}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (el?.dietary || el?.dietary === null) {
                        setIsDrawerItemOpen(true);
                        setItemDetails(el);
                      } else {
                        history.push(`/customer-detail/${el?.id}`);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    <div className="stat-index">{index + 1 > 9 ? `${index + 1}` : `0${index + 1}`}</div>
                    <div className="stat-logo logo-div">
                      <img
                        src={el?.image || noAvatar}
                        width="31"
                        height="31"
                        alt="restaurant-logo"
                      />
                    </div>
                    <span className="stat-title">
                      {el?.name || el?.concat_ws}
                    </span>
                    <div className="cost-container">
                      <span className="cost-title">{el?.order_count}</span>
                      <span className="cost-description">orders</span>
                    </div>
                  </div>
                ))}
            </TabsListByPeriod>
          </div>
        </div>
      </AnalyticsOrdersPageContainer>
      {/* ) : (
        ""
      )} */}
      <DrawerItem
        isActive={isDrawerItemOpen}
        closePanel={() => {
          setIsDrawerItemOpen(false);
        }}
        itemDetails={itemDetails}
      />
    </>
  );
};
