import React, { useState } from "react";
import { CreatePasswordPageContainer } from "./style";
import Logo from "assets/img/feast-logo.png";
import SingleButton from "components/SingleButton";
import { AuthLayout } from 'layout/components/auth-layout';
import { useHistory } from "react-router-dom";
import AuthInput from "components/Auth/AuthInput";
import { colors } from "theme";

export const CreatePasswordPage = () => {
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const isAtLeastCharacters = () => password.length >= 8;

  const isUpperCase = () => /[A-Z]/.test(password);

  const isAtLeastOneNum = () => /[1-9]/.test(password);

  const isPasswordsEqual = () => password === confirmPassword;

  const allConditionsOk = () =>
    isAtLeastCharacters() &&
    isUpperCase() &&
    isAtLeastOneNum() &&
    isPasswordsEqual();

  const canSubmit = password && confirmPassword && allConditionsOk()

  const onClickSubmit = () => { }

  const onClickCancel = () => history.push("/");

  return (
    <CreatePasswordPageContainer className="d-flex">
      <AuthLayout>
        <div className="create-password-left d-flex justify-content-center align-items-center">
          <div className="create-password-form">
            <div className="create-password-logo">
              <img src={Logo} width={160} alt="logo" />
            </div>
            <h3 className="create-password-title">Create Password.</h3>
            <div className="create-password-content">
              <AuthInput
                label="New password"
                placeholder={"Enter new password"}
                // placeholderIcon={<i className="far fa-lock" />}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                password
              />
              <AuthInput
                label="Confirm new password"
                placeholder="Confirm new password"
                // placeholderIcon={<i className="far fa-lock"></i>}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                // type="password"
                password
              />
              <div className="password-conditions">
                <div
                  className={`password-condition ${isAtLeastCharacters() ? "active" : ""}`}>
                  <i className={`${isAtLeastCharacters() ? "fas fa-check-circle" : "far fa-circle"}`} />
                  <span>At least 8 characters</span>
                </div>
                <div
                  className={`password-condition ${isUpperCase() ? "active" : ""}`} >
                  <i
                    className={`${isUpperCase() ? "fas fa-check-circle" : "far fa-circle"}`} />
                  <span>At least 1 upper case letter (A-Z)</span>
                </div>
                <div
                  className={`password-condition ${isAtLeastOneNum() ? "active" : ""}`} >
                  <i
                    className={`${isAtLeastOneNum() ? "fas fa-check-circle" : "far fa-circle"}`} />
                  <span>At least 1 number (0-9)</span>
                </div>
              </div>
            </div>
            <SingleButton
              title='Save'
              disabled={!canSubmit}
              onClick={onClickSubmit}
            />
            <SingleButton
              title='Cancel'
              btnColor="transparent"
              txtColor={colors.redColor}
              onClick={onClickCancel}
            />
          </div>
        </div>
      </AuthLayout>
    </CreatePasswordPageContainer>
  );
};
