import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerSetStatusContainer = styled(MyDrawerCenterModal)`
  .set-status-wrapper {
    padding: 37px 26px;

    /* .note-input {
      margin-top: 22px;
    } */

    .input-note {
      width: 100%;
      background: #27282a;
      border-bottom: 2px solid #3a3a3a;
      border-top: none;
      border-left: none;
      border-right: none;
      color: #fff;
    }

    input:focus-visible {
      outline: unset;
    }

    .label-text {
      opacity: 0.5;
      font-size: 14px;
      color: #fff;
    }

    .set-status-btn {
      font-weight: 600 !important;
    }

    .bottom-btns {
      margin-top: 35px;
    }

    .cancel-btn {
      color: #ed2626 !important;
      font-size: 14px;
      font-weight: 600;
      margin-top: 30px;
      text-align: center;
      cursor: pointer;
    }

    .input-area {
      border-bottom: 1px solid #545556;
      padding: 10px 0 10px 0;
      /* margin-bottom: 30px; */
      .label-text {
        font-size: 14px;
        color: #fff;
        opacity: 0.5;
      }
      .label-text i {
        font-size: 14px;
        color: #fff;
        opacity: 0.7;
      }
      input {
        background: transparent;
        border: 0;
        color: #fff;
        &:focus {
          outline: none;
        }
      }
      input::placeholder {
        font-size: 14px;
        color: #fff;
        opacity: 0.3;
      }
    }
  }
`;
