export const SEATS_MIN_LIMIT = 1;
export const SEATS_RECT_MAX_LIMIT = 4;
// export const SEATS_RECTANGLE_MAX_LIMIT = 8;
export const SEATS_RECTANGLE_MAX_LIMIT = 12;
export const SEATS_CIRCLE_MAX_LIMIT = 12;
export const validateSeatsForCircleTable = (seats) => {
  if (seats < SEATS_CIRCLE_MAX_LIMIT) {
    return seats;
  } else {
    return SEATS_CIRCLE_MAX_LIMIT;
  }
};

export const validateSeatsAndShapeForRectangle = (seats) => {
  if (seats <= SEATS_RECT_MAX_LIMIT) {
    return [seats, "rect"];
  } else if (seats <= SEATS_RECTANGLE_MAX_LIMIT) {
    return [seats, "rectangleh"];
  } else {
    return [SEATS_RECTANGLE_MAX_LIMIT, "rectangleh"];
  }
};

export const decreaseSeatsCount = (seats) => {
  if (seats > SEATS_MIN_LIMIT) {
    return seats;
  } else {
    return SEATS_MIN_LIMIT;
  }
};

export const WallShapes = {
  horizontal: "horizontal",
  vertical: "vertical",
};

export const assignWidthToWall = (type) => (type === "horizontal" ? 200 : 20);
export const assignHeightToWall = (type) => (type === "horizontal" ? 20 : 200);
export const getWallType = (width) =>
  width == 200 ? WallShapes.horizontal : WallShapes.vertical;
