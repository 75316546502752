import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useState } from "react";
import { DrawerCustomerDeletedContainer } from "./style";

export default function DrawerCustomerDeleted({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
}) {
  return (
    <DrawerCustomerDeletedContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="deleted-customer-modal">Deleted</div>
        <div className="deleted-customer-modal-meta">
          Selected Customer deleted.
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold"
            onClick={() => {
              onDone();
            }}
          >
            <b>Okay</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerCustomerDeletedContainer>
  );
}
