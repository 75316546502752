import React, { useEffect } from "react";
import { ThisAppIsNotSupportedContainer } from "./style";
import { colors } from "theme";

export default function ThisAppIsNotSupported() {
  useEffect(() => {
    document.body.style.backgroundColor = colors.black1;

    return () => {
      document.body.style.backgroundColor = undefined;
    };
  }, []);

  return (
    <ThisAppIsNotSupportedContainer>
      <div className="this-app-main-image-container">
        <div className="this-app-is-not-supported" />
      </div>
      <div className="is-not-supported-container">
        <h2 className="not-supported-title">
          This application is not supported on mobile browsers.
        </h2>
        <p className="not-supported-text">
          Contact the development team to grant you beta access.
        </p>
      </div>
    </ThisAppIsNotSupportedContainer>
  );
}
