import React from "react";
import PropTypes from "prop-types";
import { DrawerCheckboxContainer } from "./style";
import MySwitch from "components/Elements/MySwitch";

export default function DrawerCheckbox({
  isOpen = true,
  title = '',
  onChange = () => {},
}) {
  return (
    <DrawerCheckboxContainer
    >
      <div className="left-part">
        <p className={`${isOpen ? "is-open" : ""}`}>{title}</p>
      </div>
      <div className="right-part">
        <MySwitch checked={isOpen} onChange={onChange} />
      </div>
    </DrawerCheckboxContainer>
  );
}

DrawerCheckbox.propTypes = {
  isInDrawer: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
};
