import React, { useContext, useEffect, useState } from "react";
import { RomoveContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";
// import { customAxios } from "helpers/custom-helpers/customAxios";
// import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import EmptyStaff from "assets/img/noAvatar.png";
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { drawersStaff } from "static/staticData";
import MyButton from "components/Elements/MyButton";

const RemoveModal = ({
  isActive = false,
  closePanel = () => {},
  // onDeleteEntireOrder = () => {},
}) => {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    staffViewData,

    removeFromRestaurantModal,
    setRemoveFromRestaurantModal,

    removeFromRestaurantConfirmModal,
    setRemoveFromRestaurantConfirmModal,

    restaurant,
  } = useContext(RestaurantsContext);

  //   const [restoreNotification, setRestoreNotification] = useState();

  //   useEffect(() => {
  //     if (restoreNotification) {
  //       setTimeout(() => setRestoreNotification(null), 2 * 1000);
  //     }
  //   }, [restoreNotification]);

  // useEffect(() => {
  //   console.log(isRestoreDataEnable)
  // }, [isRestoreDataEnable])

  //   setRestoreNotification({
  //     message: "Review Restored.",
  //     type: "restore-review",
  //     table: `<p style="color: #8F949A"><b>Server's (${staffViewData?.first_name} ${staffViewData?.last_name})</b> review was restored in <b style="color: #8F949A">${restaurant?.title}</b>.</p>`,
  //   });

  const onClickRemove = () => {
    // openDrawer(drawersStaff.removeConfirmFromRestaurant)
    setRemoveFromRestaurantConfirmModal({
      ...removeFromRestaurantConfirmModal,
      removeConfirmFromRestaurant: true,
    });

    setRemoveFromRestaurantModal({
      ...removeFromRestaurantModal,
      removeFromRestaurant: false,
    });
  };

  return (
    <RomoveContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {/* {restoreNotification && (
          <MyMessageBoxItemNew
            message={
              restoreNotification?.message ? restoreNotification.message : ""
            }
            table={restoreNotification?.table ? restoreNotification.table : ""}
            type={
              restoreNotification?.type ? restoreNotification.type : "success"
            }
            onClick={() => setRestoreNotification(null)}
          />
        )} */}

        <div className="server-host-active-modal">
          <div className="staff-profile">
            <img
              className="rounded-circle"
              src={staffViewData?.avatar ? staffViewData?.avatar : EmptyStaff}
              alt=""
            />
          </div>
          <div className="staff-title">Are you sure?</div>
          <div className="staff-subtitle">
            Are you sure want to remove{" "}
            <b>
              {staffViewData?.first_name === undefined &&
              staffViewData?.last_name === undefined
                ? "No Name"
                : `${staffViewData?.first_name} ${staffViewData?.last_name}`}
            </b>
            <br />
            <b>(Server/Host)</b> from the <b>{restaurant?.title}</b>?
          </div>
          <div className="actions">
            <MyButton className="remove-btn" type="red" onClick={onClickRemove}>
              Yes, Remove
            </MyButton>
            <MyButton className="cancel-btn" type="gray" onClick={closePanel}>
              No, Keep
            </MyButton>
          </div>
        </div>
      </DrawerContainer>
    </RomoveContainer>
  );
};

export default RemoveModal;
