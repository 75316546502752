import styled from "@emotion/styled";
import { colors } from "theme";

export const ProfileCardInfoContainer = styled.div`
  padding: 20px 36px;
  .profile-info-header {
    /* margin-bottom: 40px; */
    .profile-info-avatar {
      margin-right: 54px;

      .profile-picture {
        width: 92px;
        height: 92px;
      }

      .customer-profile-rating {
        width: max-content;
        height: 23px;
        padding: 2px 9px 3px 10px;
        border-radius: 8px;
        background-color: #27282a;
        position: absolute;
        margin: -19px 0 0 18px;
        .rating-container {
          display: flex;
          align-items: center;
          width: max-content;
          height: 18px;
          .rating-number {
            font-size: 14px;
            font-weight: bold;
            color: white;
            margin-right: 5px;
            margin-left: 5px;
          }

          .rating-icon {
            i {
              font-size: 12px;
              color: #fdcb6e;
            }
          }
        }
      }
    }
    .profile-info-name {
      margin-bottom: 15px;
    }
    .profile-info-join-date {
      display: flex;
      margin-bottom: 15px;
      color: #8f949a;
      b {
        color: #fff;
      }
      span {
        padding: 6px 12px;
        background-color: ${colors.green};
        border-radius: 15px;
        font-weight: 600;
        margin-left: 13px;
        color: #fff;
        &.inactive {
          background-color: ${colors.gray};
        }
        &.banned {
          background-color: ${colors.redColor};
        }
      }
      .join-req {
        color: #fff;
        font-weight: 500;
      }
    }
    .edit-btn {
      border-radius: 16px !important;
      padding: 0px 12px;
      width: 53px;
      background-color: #60646c !important;
      height: 32px;
    }
    .edit-btn-for-pending-restaurant {
      border-radius: 30px !important;
      padding: 10px 16px;
    }
    .profile-info-location {
      display: flex;
      color: #8F949A;
    }
  }

  hr.line {
    margin-top: 18px;
    border-top: 1px solid #363636;
  }

  .profile-info-row {
    display: flex;
    /* margin-bottom: 33px; */
    margin-top: 33px;
    .profile-info-col {
      flex: 1;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  .profile-info-col-content {
    margin-left: 16px;
    .profile-info-col-label {
      margin-bottom: 6px;
      opacity: 0.6;
    }
    .profile-info-col-value {
      .mobile-number-input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h6 {
          font-size: 14px;
          opacity: 0.4;
          line-height: 1.57;
          letter-spacing: -0.34px;
        }
        .drawer-input-container {
          display: flex;
          align-items: center;
          .input-prefix {
            flex: 0 0 auto;
            color: #fff;
            padding-right: 10px;
          }
          input {
            flex: 1 1 auto;
            background-color: transparent;
            color: #fff;
            font-weight: bold;
            border: unset;
            outline: unset;
            padding-left: 0;
            width: 155px;
          }
        }
        .input-icon {
          position: absolute;
          right: 0;
          width: 27px;
          height: 27px;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background: ${colors.gray};
          }
        }
      }
    }
  }
`;
