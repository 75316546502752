import { DrawerContainer } from "components/styledStyle";
import React, { useState, useContext } from "react";
import { DrawerPriceTierContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { FaChevronLeft } from "react-icons/fa";

const tiers = [
  {
    id: 1,
    price: "$",
  },
  {
    id: 2,
    price: "$$",
  },
  {
    id: 3,
    price: "$$$",
  },
  {
    id: 4,
    price: "$$$$",
  },
];

export default function DrawerPriceTier({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postRestaurant, setPostRestaurant } = useContext(RestaurantsContext);
  const [selectedTire, setSelectedTire] = useState();

  function getPriceId(tireInfo) {
    setSelectedTire(tireInfo?.id);
    setPostRestaurant({ ...postRestaurant, price_level: tireInfo?.id });
    closePanel();
  }

  return (
    <DrawerPriceTierContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Price tier</div>
          <div></div>
        </div>
        <div className="drawer-status--options mb-auto">
          {tiers.map((_tier, _i) => {
            let tireId = _tier.id;
            return (
              <ul className="price-wrapper" key={_i}>
                <li
                  style={{
                    border:
                      tireId === selectedTire || postRestaurant.price_level == tireId
                        ? "1px solid #ed2626"
                        : "1px solid #5b5e61",
                  }}
                  onClick={() => getPriceId(_tier)}
                >
                  {_tier.price}
                </li>
              </ul>
            );
          })}
        </div>
      </DrawerContainer>
    </DrawerPriceTierContainer>
  );
}
