import styled from "@emotion/styled";
import { colors } from "theme";

export const MyDrawerRightContainer = styled.div`
  .offcanvas {
    background-color: ${colors.gray2};

    &.status-drawer {
      z-index: 1002;
    }

    &.delete-restaurant-drawer {
      .drawer-left-icon {
        display: none;
      }
    }
    .drawer-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      /* overflow-y: scroll; */
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: ${colors.gray5};
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${colors.white};
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      &.notes-scroll-hidden {
        overflow-y: auto;
      }
    }
    /* .drawer-left-icon {
      position: absolute;
      top: 36px;
      left: 26px;
      z-index: 1;
      background-color: red;
    } */
    /* .drawer-right-icon {
      position: absolute;
      top: 36px;
      right: 26px;
    } */
  }
`;
