import React, { useEffect, useState } from "react";
import { SessionsContainer } from "./style";
import { AnalyticPeriod } from "../../AnalyticPeriod";
import { SessionsBars } from "./SessionsBars";
import { AverageSession } from "./AverageSession";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";

export const Sessions = () => {
  const [sessionData, setSessionData] = useState({});
  const [averageSession, setAverageSession] = useState({
    percentage: 0,
    time: 0,
  });
  const [period, setPeriod] = useState("day");
  const [dateRange, setDateRange] = useState();

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    let label = "";
    if (hours) {
      label += `${hours} H `;
    }
    if (minutes) {
      label += `${hours ? `.` : ``} ${minutes} M`;
    }
    return label || "0";
  }

  useEffect(() => {
    let stepInMins = 30;
    let url;
    if (dateRange) {
      url = `/analytics-customer/sessions?period=${period}&step=${stepInMins *
        60}&start_date=${dateRange?.startDate}&end_date=${dateRange?.endDate}`;
    } else {
      url = `/analytics-customer/sessions?period=${period}&step=${stepInMins *
        60}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        let sessionDataObj = {},
          counter = 0;
        const sessionsArr = Object.entries(res.data.sessions);
        for (let [index, [key, value]] of sessionsArr.entries()) {
          if (index == sessionsArr.length - 1) {
            sessionDataObj[
              `${toHoursAndMinutes(stepInMins * counter)} + `
            ] = value;
            continue;
          }
          sessionDataObj[
            `${toHoursAndMinutes(stepInMins * counter)} - ${toHoursAndMinutes(
              stepInMins * (counter + 1)
            )}`
          ] = value;
          counter += 1;
        }
        setSessionData(sessionDataObj);
        setAverageSession({
          percentage: 0,
          time: res?.data?.average_time,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [period, dateRange]);

  const onTimePeriodChange = (time) => {
    setPeriod(time);
  };

  return (
    <SessionsContainer>
      <div className="sessions-top-container">
        <h4 className="sessions-top-title">Sessions</h4>
        <div className="sessions-date">
          {/* {dateRange !== undefined &&
            `${moment(dateRange?.startDate).format(
              "dddd MMMM DD, YYYY"
            )} - ${moment(dateRange?.endDate).format("dddd MMMM DD, YYYY")}`} */}
          {dateRange?.startDate && dateRange?.endDate
            ? `${moment(dateRange.startDate).format("dddd MMMM DD, YYYY")}
                 - ${moment(dateRange.endDate).format("dddd MMMM DD, YYYY")}`
            : period === "day"
            ? moment().format("dddd MMMM DD, YYYY")
            : period === "week"
            ? `${
                moment()
                  .subtract(6, "days")
                  .format("dddd MMMM DD, YYYY")
                  .split("T")[0]
              }
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "month"
            ? `${moment()
                .subtract(30, "days")
                .format("dddd MMMM DD, YYYY")}
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "quarter"
            ? `${moment()
                .subtract(91, "days")
                .format("dddd MMMM DD, YYYY")}
                      - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "year"
            ? `${moment()
                .subtract(1, "year")
                .format("dddd MMMM DD, YYYY")}
                - ${moment().format("dddd MMMM DD, YYYY")}`
            : moment()
                .format("dddd, MMMM DD, YYYY")
                .split("T")[0]}
        </div>
        <AnalyticPeriod
          className="sessions-period"
          onChange={onTimePeriodChange}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
      <div className="sessions-main-container">
        <SessionsBars sessionTimes={sessionData} />
        <AverageSession
          time={averageSession.time}
          growth={averageSession.percentage}
        />
      </div>
    </SessionsContainer>
  );
};
