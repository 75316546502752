export const fonts = {
  roboto: "Roboto",
  mono: "Menlo, Monaco, monospace",
  main: "Montserrat",
};

export const colors = {
  green: "#1ece6d",
  redColor: "#ED2626",
  mainColor: "#3C3D3C",
  border: "#525558",
  black1: "#151617",
  gray: "#60646c",
  gray1: "#151617",
  // gray2: '#1b1c1d',
  gray2: "#1b1c1d",
  gray3: "#515457",
  // gray4: '#3a3c40',
  gray5: "#27282a",
  orange: "#fdcb6e",
  orange1: "#d6a67c",
  yellow: "#ffca5b",
  white5: "rgba(255, 255, 255, 0.1)",
  white3: "rgba(255, 255, 255, 0.3)",
  white: "rgba(255, 255, 255, 1)",
  dark1: "rgba(0, 0, 0, 0.1)",
  gray: "rgb(103, 103, 105)",

  pageBg: "#151617",
};

export const mixins = {
  drawerWidth: "395px",
};
