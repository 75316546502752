/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { CustomersPageContainer } from "./style";
import { useHistory } from "react-router-dom";
import { CustomersContext } from "context/CustomersContext";
import MyButton from "components/Elements/MyButton";
import NoAvatar from "assets/img/noAvatar.png";
import { AnalyticItem } from "./AnalyticItem";
import { CustomerItem } from "./CustomerItem";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import MySelect from "components/Elements/MySelect";
import { showLimits, drawersCustomers } from "static/staticData";
import DrawerFilterCustomers from "./Drawers/DrawerFilterCustomers";
import DrawerJoinedDate from "./Drawers/DrawerJoinedDate";
import MyPaginator from "components/Elements/MyPaginator";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerDeleteCustomers from "./Drawers/DrawerDeleteCustomers";
import DrawerSetStatus from "./Drawers/DrawerSetStatus";
import moment from "moment";
import noResultImg from "../../../assets/img/no-filter-data.png";
import useOnclickOutside from "react-cool-onclickoutside";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import DrawerNewFilterCustomers from "./Drawers/DrawerNewFilterCustomers";
import {
  NewRestaurantCard,
  ActiveRestaurants,
  TotalRestaurants,
} from "assets/img";
import MySearch from "components/Elements/MySearch";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const statsTypes = [
  {
    key: "total",
    icon: <i className="fas fa-sigma" />,
    title: "Total Customers",
    image: NewRestaurantCard,
  },
  {
    key: "new",
    icon: <i className="fas fa-users" />,
    title: "New Customers",
    subTitle: "in last 30 days",
    image: ActiveRestaurants,
  },
  {
    key: "active",
    icon: <i className="fas fa-spinner" />,
    title: "Active Customers",
    subTitle: "in last 30 days",
    image: TotalRestaurants,
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    left: "250px",
  },
}));

export function CustomersPage() {
  const {
    setProfileData,
    customersList,
    openDrawer,
    closeDrawer,
    openDrawers,
    statusFilter,
    getCustomerList,
    getCustomerStats,
    customerStats,
    deleteCustomers,
    setCustomerId,
    setCustomersList,
    // customerListTab,
    // setCustomerListTab,
    setCustomerBanId,
    customerName,
    setCustomerName,
  } = useContext(CustomersContext);

  const history = useHistory();
  const classes = useStyles();

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isSearchOpen, setSearchOpen] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [customerListTab, setCustomerListTab] = useState(1);

  const [statusFiltered, setStatusFiltered] = useState();
  const [profileFiltered, setProfileFiltered] = useState(false);

  const [isCustomerDelete, setIsCustomerDelete] = useState(false);
  const [joinedDate, setJoinedDate] = useState({});
  const [bannedDate, setBannedDate] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isSearchFilterApplied, setIsSearchFilterApplied] = useState(false);

  const [customerFilterIcon, setCustomerFilterIcon] = useState(true);
  const [isCustomerFilterOpen, setisCustomerFilterOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  // stats
  useEffect(() => {
    setProfileData({});
    fetchData();
    async function fetchData() {
      const stats = await getCustomerStats();
    }
  }, []);

  const fetchData = async () => {
    let payload = {
      page_size: selectedLimitToShow,
      page: selectedPage,
    };

    setLoading(true);

    if (searchInput) {
      payload.query = searchInput;
      if (!isSearchFilterApplied) {
        setIsSearchFilterApplied(true);
      }
    } else {
      if (isSearchFilterApplied) {
        setIsSearchFilterApplied(false);
      }
    }
    setCustomersList([]);

    setTimeout(() => {
      setLoading(false);
    }, [1000]);

    return await getCustomerList(payload, customerListTab);
  };

  useEffect(() => {
    fetchData();
  }, [selectedLimitToShow, selectedPage, customerListTab, searchInput]);

  // useEffect(() => {
  //   if (isCustomerDelete) {
  //     fetchData();
  //     async function fetchData() {
  //       const res = await getCustomerList(
  //         {
  //           page_size: selectedLimitToShow,
  //           query: searchInput,
  //           page: selectedPage,
  //         },
  //         customerListTab
  //       );
  //     }
  //     setIsCustomerDelete(false);
  //     closeDrawer(drawersCustomers.deleteCustomer);
  //   }
  // }, [isCustomerDelete]);

  const openSearchFilter = (e) => {
    e.preventDefault();
    if (!isSearchOpen) setSearchOpen(true);
  };

  const toggleCustomer = (customers, e) => {
    if (customerListTab === 1 || customerListTab === 0) {
      setCustomerName(customers);
      if (selectedCustomers.includes(customers?.user_id))
        setSelectedCustomers([
          ...selectedCustomers.filter(
            (selectedId) => selectedId !== customers?.user_id
          ),
        ]);
      if (!selectedCustomers.includes(customers?.user_id))
        setSelectedCustomers([...selectedCustomers, customers?.user_id]);
    }
  };

  const closeSearchFilter = (e) => {
    e.preventDefault();
    if (isSearchOpen) {
      // setSearchOpen(false);
      setSearchInput("");
    }
  };

  const changeSearchQuery = (e) => {
    setSearchInput(e.target.value);
  };

  const onPageChange = async (e) => {
    // console.log({ e });
    setSelectedPage(e.selected + 1);
  };

  const onFilterCustomers = async (filterObject) => {
    let filterParams = {
      page_size: selectedLimitToShow,
      page: selectedPage,
    };

    if (searchInput) {
      filterParams.query = searchInput;
      if (!isSearchFilterApplied) {
        setIsSearchFilterApplied(true);
      }
    } else {
      if (isSearchFilterApplied) {
        setIsSearchFilterApplied(false);
      }
    }

    if (filterObject && filterObject.hasAvatar) {
      filterParams.hasAvatar = filterObject.hasAvatar;
    }

    if (joinedDate) {
      if (
        joinedDate?.startDate === undefined &&
        joinedDate?.endDate === undefined
      ) {
      }
      // else if (joinedDate?.startDate === joinedDate?.endDate) {
      //   filterParams.joined_date = `${moment(joinedDate?.startDate).format(
      //     "YYYY/MM/DD"
      //   )},`;
      // }
      else if (joinedDate?.startDate && joinedDate?.endDate) {
        filterParams.joined_date = `${moment(joinedDate?.startDate).format(
          "YYYY/MM/DD"
        )},${moment(joinedDate?.endDate).format("YYYY/MM/DD")}`;
      }
    }

    if (filterObject && filterObject.ban_date) {
      if (
        filterObject.ban_date?.startDate === undefined &&
        filterObject.ban_date?.endDate === undefined
      ) {
      }
      // else if (
      //   filterObject.ban_date?.startDate === filterObject.ban_date?.endDate
      // ) {
      //   filterParams.ban_date = `${moment(
      //     filterObject.ban_date?.startDate
      //   ).format("YYYY/MM/DD")},`;
      // }
      else if (
        filterObject.ban_date?.startDate &&
        filterObject.ban_date?.endDate
      ) {
        filterParams.ban_date = `${moment(
          filterObject.ban_date?.startDate
        ).format("YYYY/MM/DD")},${moment(filterObject.ban_date?.endDate).format(
          "YYYY/MM/DD"
        )}`;
      }
    }

    if (filterObject && filterObject.inactive_date) {
      if (
        filterObject.inactive_date?.startDate === undefined &&
        filterObject.inactive_date?.endDate === undefined
      ) {
      }
      // else if (
      //   filterObject.inactive_date?.startDate ===
      //   filterObject.inactive_date?.endDate
      // ) {
      //   filterParams.inactive_date = `${moment(
      //     filterObject.inactive_date?.startDate
      //   ).format("YYYY/MM/DD")},`;
      // }
      else if (
        filterObject.inactive_date?.startDate &&
        filterObject.inactive_date?.endDate
      ) {
        filterParams.inactive_date = `${moment(
          filterObject.inactive_date?.startDate
        ).format("YYYY/MM/DD")},${moment(
          filterObject.inactive_date?.endDate
        ).format("YYYY/MM/DD")}`;
      }
    }

    if (filterObject && filterObject.delete_date) {
      if (
        filterObject.delete_date?.startDate === undefined &&
        filterObject.delete_date?.endDate === undefined
      ) {
      }
      // else if (
      //   filterObject.delete_date?.startDate ===
      //   filterObject.delete_date?.endDate
      // ) {
      //   filterParams.delete_date = `${moment(
      //     filterObject.delete_date?.startDate
      //   ).format("YYYY/MM/DD")},`;
      // }
      else if (
        filterObject.delete_date?.startDate &&
        filterObject.delete_date?.endDate
      ) {
        filterParams.delete_date = `${moment(
          filterObject.delete_date?.startDate
        ).format("YYYY/MM/DD")},${moment(
          filterObject.delete_date?.endDate
        ).format("YYYY/MM/DD")}`;
      }
    }

    if (filterObject && filterObject.order_count) {
      filterParams.order_count = filterObject.order_count;
    }

    if (filterObject && filterObject.last_locations) {
      filterParams.last_locations = filterObject.last_locations;
    }

    if (filterObject && filterObject.admin_ids) {
      filterParams.admin_ids = filterObject.admin_ids;
    }

    if (filterObject && filterObject.deleted_by) {
      filterParams.deleted_by = filterObject.deleted_by;
    }

    const res = await getCustomerList(filterParams, customerListTab);
    if (res) {
      closeDrawer(drawersCustomers.filterCustomers);
      setIsFilterApplied(true);
      setisCustomerFilterOpen(false);
    }
  };

  const selectAll = () => {
    if (selectedCustomers.length) {
      setSelectedCustomers([]);
    } else {
      if (customersList) {
        const allSelectCustomer = customersList?.customers.map(
          (el) => el.user_id
        );
        setSelectedCustomers(allSelectCustomer);
      }
    }
  };

  const deleteCustomeresHandler = async () => {
    const deleteCustomersRes = await deleteCustomers(selectedCustomers);
    if (deleteCustomersRes) {
      setIsCustomerDelete(true);
      setSelectedCustomers([]);
    }
  };

  if (selectedCustomers.length) {
    setCustomerId(selectedCustomers);
  }

  const onSelectDateRange = (date) => {
    setJoinedDate(date);
    closeDrawer(drawersCustomers.joinedDate);
  };

  function handleRestaurantFilteredAscending() {
    setCustomerFilterIcon(false);
    const restaurantNameFilter = [...customersList?.customers].sort((a, b) =>
      a.user_name?.localeCompare(b.user_name)
    );
    setCustomersList({ ...customersList, customers: restaurantNameFilter });
  }

  function handleRestaurantFilteredDescending() {
    setCustomerFilterIcon(true);
    const restaurantNameFilter = [...customersList?.customers].sort((a, b) =>
      b.user_name?.localeCompare(a.user_name)
    );
    setCustomersList({ ...customersList, customers: restaurantNameFilter });
  }

  const getCustomerListFetch = async () => {
    return await getCustomerList(
      {
        page_size: selectedLimitToShow,
        query: searchInput,
        page: selectedPage,
      },
      customerListTab
    );
  };

  const onClickResetCustomer = () => {
    setJoinedDate("");
    getCustomerListFetch();
  };

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const ref = useOnclickOutside(() => {
    setSearchInput("");
  });

  const columnRenderHandler = () => {
    if (customerListTab === 1) {
      return (
        <>
          <div className="item-flex-normal">Joined</div>
          <div className="item-flex-big">Last Location</div>
          <div className="item-flex-normal"># of Orders</div>
          <div className="item-flex-last">Total Paid</div>
        </>
      );
    } else if (customerListTab === 0) {
      return (
        <>
          <div className="item-flex-normal">Inactivated</div>
          <div className="item-flex-big">Joined</div>
          <div className="item-flex-normal"># of Orders</div>
          <div className="item-flex-last">Total Paid</div>
        </>
      );
    } else if (customerListTab === 9) {
      return (
        <>
          <div className="item-flex-normal">Banned</div>
          <div className="item-flex-normal">Joined</div>
          <div className="item-flex-normal">Banned By</div>
          <div className="item-flex-normal"># of Orders</div>
          <div className="item-flex-last">Total Paid</div>
        </>
      );
    } else if (customerListTab === -1) {
      return (
        <>
          <div className="item-flex-normal">Deleted</div>
          <div className="item-flex-normal">Joined</div>
          <div className="item-flex-normal">Deleted By</div>
          <div className="item-flex-normal"># of Orders</div>
          <div className="item-flex-last">Total Paid</div>
        </>
      );
    }
  };

  const onResetFilter = async () => {
    let payload = {
      page_size: selectedLimitToShow,
      page: selectedPage,
    };
    if (searchInput) {
      payload.query = searchInput;
    }
    const res = await getCustomerList(payload, customerListTab);
    if (res) {
      setIsFilterApplied(false);
    }
  };

  return (
    <CustomersPageContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="customer-list-wrapper">
        <div className="customer-component-header">
          <div className="left-part">
            <h2>Customers</h2>
          </div>

          {selectedCustomers.length > 0 && (
            <div className="center-part">
              {/* {selectedCustomers.length === 1 && (
                <MyButton
                  className="rounded-button"
                  onClick={() => openDrawer(drawersCustomers.setAllActive)}
                >
                  Set all active
                </MyButton>
              )} */}

              {customerListTab === 0 && (
                <MyButton
                  className="rounded-button"
                  onClick={() => openDrawer(drawersCustomers.setAllActive)}
                >
                  Set All Active
                </MyButton>
              )}

              {customerListTab === 1 && (
                <MyButton
                  className="rounded-button"
                  onClick={() => openDrawer(drawersCustomers.setAllInactive)}
                >
                  Set All Inactive
                </MyButton>
              )}

              {/* {customerListTab === 0 && (
                <MyButton className="squire-button">
                  <span className="text-content">
                    {selectedCustomers.length} customer(s) selected
                  </span>
                  <span
                    className="text-undo"
                    onClick={() => setSelectedCustomers([])}
                  >
                    Set All Active
                  </span>
                </MyButton>
              )} */}

              <MyButton className="squire-button">
                <span className="text-content">
                  {selectedCustomers.length} customer(s) selected
                </span>
                <span
                  className="text-undo"
                  onClick={() => setSelectedCustomers([])}
                >
                  Undo
                </span>
              </MyButton>
            </div>
          )}

          <div className="right-part">
            {/* <div className="show-limit">
              <div className="show-message">Show</div> */}

            {/* </div> */}
            <div style={{ position: "relative" }}>
              {/* <div
                className={`search-pane ${isSearchOpen ? "active" : ""}`}
                onClick={(e) => openSearchFilter(e)}
              >
                <i className="fas fa-search" />

                <input
                  className="search-input on-search-active"
                  value={searchInput}
                  // onBlur={() => {
                  //   // setSearchOpen(false);
                  //   setSearchInput("");
                  // }}
                  onClick={() => {
                    setSearchInput("");
                  }}
                  placeholder="Search Customer"
                  onChange={(e) => changeSearchQuery(e)}
                />
                <i
                  className="fas fa-times on-search-active"
                  onClick={(e) => closeSearchFilter(e)}
                />
              </div> */}
              <MySearch
                value={searchInput}
                onChange={(e) => {
                  if (e?.target?.value === undefined) {
                    setSearchInput("");
                  } else {
                    setSearchInput(e?.target?.value);
                  }
                }}
                defaultShow={true}
                placeHolder="Search Customer Name"
              />
              {/* {searchInput !== "" && customersList?.customers?.length !== 0 ? (
                <div className="show-search-result">
                  {customersList?.customers?.map((searchCustomer, i) => {
                    return (
                      <>
                        <span
                          className="restaurant-title"
                          key={i}
                          onClick={(e) =>
                            history.push(
                              `/customer-detail/${searchCustomer?.user_id}`
                            )
                          }
                          ref={ref}
                        >
                          <p className="restaurant-title-gap">
                            <div className="text-white">
                              {searchInput !== "" &&
                                searchInput !== null &&
                                searchInput !== undefined &&
                                getHighlightedText(
                                  searchCustomer?.user_name,
                                  searchInput,
                                  "#ed2626"
                                )}
                            </div>
                          </p>
                          <br />
                        </span>
                      </>
                    );
                  })}
                </div>
              ) : null} */}
            </div>
            <MySelect
              className="mr-6"
              options={showLimits}
              defaultValueId={showLimits[0]}
              onChange={(option) => setSelectedLimitToShow(option.name)}
            />
            <div
              className="menu-item"
              onClick={() => {
                setisCustomerFilterOpen(true);
              }}
            >
              <i className="fas fa-sliders-h" />
              {/* {statusFilter !== "" && <div className="active-dot" />} */}
              {isFilterApplied && <div className="active-dot" />}
            </div>
          </div>
        </div>

        <div className="separator-line" />
        <div className="customer-list-tabs">
          <Tab.Container
            id=""
            defaultActiveKey={1}
            onSelect={(key) => {
              setCustomerListTab(Number(key));
              setSelectedCustomers([]);
              setIsFilterApplied(false);
              // setLoading(true);
            }}
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey={1}>Active</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={0}>Inactive</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={9}>Banned</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={-1}>Deleted</Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <div className="header-total-count">
            <span className="total-count-text1">
              {isSearchFilterApplied && isFilterApplied ? (
                <>Filter & Search Results:</>
              ) : (
                <>
                  {isSearchFilterApplied ? (
                    <>Search Results:</>
                  ) : (
                    <>{isFilterApplied ? "Filter Results:" : "Total:"}</>
                  )}
                </>
              )}
            </span>
            <span className="total-count-text2">
              &nbsp;{customersList?.total_count || customersList?.total}
            </span>
          </div>
        </div>
        {/* analytics */}
        <Row className="analytic-container">
          {statsTypes.map((_type, _i) => (
            <Col key={_i} md={4}>
              <AnalyticItem
                value={customerStats[_type.key]}
                title={_type.title}
                subTitle={_type.subTitle}
                icon={_type.icon}
                image={_type?.image}
              />
            </Col>
          ))}
        </Row>

        {/* customers list component */}
        <div className="customers-list-container">
          {customersList?.customers?.length > 0 && !loading && (
            <div className="customers-titles">
              <div className="left-padding-1 item-flex-small">
                {customerListTab === 1 || customerListTab === 0 ? (
                  <>
                    {selectedCustomers?.length ? (
                      selectedCustomers?.length >= selectedLimitToShow ? (
                        <i
                          className="fas fa-check-circle cursor"
                          onClick={selectAll}
                        />
                      ) : (
                        <i
                          className="fas fa-minus-circle cursor"
                          onClick={selectAll}
                        />
                      )
                    ) : (
                      <i className="far fa-circle cursor" onClick={selectAll} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="item-flex-big cursor-pointer"
                onClick={
                  customerFilterIcon
                    ? handleRestaurantFilteredAscending
                    : handleRestaurantFilteredDescending
                }
              >
                Full Name &nbsp;
                <span className="cursor-pointer">
                  {customerFilterIcon ? (
                    <i
                      className="far fa-angle-down cursor-pointer"
                      style={{ fontSize: "16px", color: "#5c5d5d" }}
                      onClick={handleRestaurantFilteredAscending}
                    />
                  ) : (
                    <i
                      className="far fa-angle-up cursor-pointer"
                      style={{ fontSize: "16px", color: "#5c5d5d" }}
                      onClick={handleRestaurantFilteredDescending}
                    />
                  )}
                </span>
              </div>
              <div className="item-flex-normal">Status</div>
              {columnRenderHandler()}
            </div>
          )}

          {customersList?.customers?.length > 0 && !loading ? (
            <div className="customers-list">
              {customersList?.customers &&
                customersList.customers.map((customer, index) => {
                  return (
                    <CustomerItem
                      key={`restkey${customer?.user_id}-${index}`}
                      isSelected={selectedCustomers.includes(customer?.user_id)}
                      customOnClick={(e) => toggleCustomer(customer, e)}
                      goToProfile={(user_id) => {
                        if (selectedCustomers?.length === 0) {
                          history.push(`/customer-detail/${customer?.user_id}`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                          setCustomerBanId(customer?.ban_id || "");
                          setCustomerListTab(1);
                        }
                      }}
                      customerListTab={customerListTab}
                      customer={customer}
                      selectedCustomers={selectedCustomers}
                      searchInput={searchInput}
                    />
                  );
                })}
            </div>
          ) : (
            !loading && (
              <div>
                <div className="search-applied">
                  <div className="text-bold">{/* Search Applied */}</div>
                  {(isFilterApplied || isSearchFilterApplied) && (
                    <div>
                      <span className="text-bold">0</span> results
                    </div>
                  )}
                </div>

                <div className="no-result">
                  <div className="no-result-img">
                    <img src={noResultImg} alt="No result" />
                  </div>

                  <div className="no-result-title">No results</div>

                  {(isFilterApplied || isSearchFilterApplied) && (
                    <div className="no-result-subtitle">
                      Try broadening your search.
                    </div>
                  )}
                </div>
              </div>
            )
          )}

          {customersList?.customers?.length > 0 && !loading && (
            <div className="mt-3">
              <MyPaginator
                initialPage={0}
                totalCount={customersList?.total_count}
                limitShow={selectedLimitToShow}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      </div>
      <DrawerFilterCustomers
        isActive={!!openDrawers[drawersCustomers.filterCustomers]}
        closePanel={() => closeDrawer(drawersCustomers.filterCustomers)}
        onDone={onFilterCustomers}
        joinedDate={joinedDate}
        onClickResetCustomer={onClickResetCustomer}
        setIsFilterApplied={setIsFilterApplied}
      />
      <DrawerNewFilterCustomers
        isActive={isCustomerFilterOpen}
        // isActive={true}
        closePanel={() => {
          setisCustomerFilterOpen(false);
        }}
        onDone={onFilterCustomers}
        joinedDate={joinedDate}
        onClickResetCustomer={() => {}}
        setIsFilterApplied={setIsFilterApplied}
        customerListTab={customerListTab}
        setJoinedDate={setJoinedDate}
        bannedDate={bannedDate}
        setBannedDate={setBannedDate}
        onResetFilter={onResetFilter}
        isFilterApplied={isFilterApplied}
      />
      <DrawerJoinedDate
        isActive={!!openDrawers[drawersCustomers.joinedDate]}
        closePanel={() => closeDrawer(drawersCustomers.joinedDate)}
        onSelectDateRange={onSelectDateRange}
      />
      <DrawerDeleteCustomers
        isActive={!!openDrawers[drawersCustomers.deleteCustomer]}
        closePanel={() => closeDrawer(drawersCustomers.deleteCustomer)}
        onDone={deleteCustomeresHandler}
      />
      <DrawerSetStatus
        isActive={!!openDrawers[drawersCustomers.setAllActive]}
        closePanel={() => closeDrawer(drawersCustomers.setAllActive)}
        setSelectedCustomers={setSelectedCustomers}
        selectedCustomers={selectedCustomers}
        customerListTab={customerListTab}
      />
      <DrawerSetStatus
        type="Inactive"
        isActive={!!openDrawers[drawersCustomers.setAllInactive]}
        closePanel={() => closeDrawer(drawersCustomers.setAllInactive)}
        setSelectedCustomers={setSelectedCustomers}
        selectedCustomers={selectedCustomers}
        customerListTab={customerListTab}
      />
    </CustomersPageContainer>
  );
}

const filterByStatusCustomers = (customers, statusFilter) => {
  let copyCustomers = [...customers];

  if (statusFilter !== "") {
    copyCustomers = customers.filter(
      (customerItem) => customerItem.status === statusFilter
    );
  }

  return copyCustomers;
};
