import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerRemoveItemContainer = styled(MyDrawerRight)`
  .drawer-bottom-content {
    text-align: left;
    h5 {
      margin-bottom: 22px;
    }
  }
`;
