import React, { useContext } from "react";
import { DeleteSubOrderModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";

const DeleteSubOrderModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteSubOrder = () => {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();
  return (
    <DeleteSubOrderModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Delete Sub-Order</div>
          <div className="delete-suborder-meta">
            All pending items will be removed from the system and the customer’s
            bill.
          </div>
          <div className="delete-suborder-meta">
            Any items already marked as delivered to the customer’s table will
            not be removed.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              className="delete-btn"
              type="red"
              onClick={() => {
                onDeleteSubOrder();
              }}
            >
              <b>Delete</b>
            </MyDrawerButton>
            <MyDrawerButton
              className="keep-btn"
              type="gray"
              onClick={closePanel}
            >
              <b>Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DeleteSubOrderModalContainer>
  );
};

export default DeleteSubOrderModal;
