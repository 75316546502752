import styled from "@emotion/styled";

export const ChartTooltipContainer = styled.div`
  background-color: #27282a;
  padding: 12px 25px;
  border-radius: 18px;
  border-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: column;

  .chart-tooltip-title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 4px;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .chart-tooltip-amount-customers {
    font-size: 14px;
    font-weight: bold;
    color: white;

    display: flex;
    align-items: center;
  }
`;
