import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const EditRestaurantModalContainer = styled(MyDrawerCenterModal)`
  /* @media (max-height: 900px) {
    height: 800px !important;
    overflow-y: auto;
  } */
  /* @media (max-height: 955px) {
    height: 810px !important;
    overflow-y: auto;
  } */
  /* @media (max-height: 960px) {
    height: 880px !important;
    overflow-y: auto;
  } */
  /* @media (max-height: 860px) {
    height: 760px !important;
    overflow-y: auto;
  } */
  /* @media (max-height: 760px) {
    height: 680px !important;
    overflow-y: auto;
  } */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray5};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.white};
    border-radius: 5px;
  }
  .upload-profile-wrapper {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .divide-section {
    margin-top: 22px;
  }
  .location-input {
    margin-top: 22px;
    .show-addresses {
      background: #27282a;
      height: 180px;
      position: absolute;
      width: 89%;
      border-radius: 20px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.28);
      overflow: auto;
      margin-top: 5px;
      padding: 20px;
    }

    .location-input-icon {
      display: flex;
      input {
        background: transparent;
        border: 0;
        color: #fff;
        &::placeholder {
          color: #ed2626;
          font-weight: 500;
        }
        &:focus {
          outline: none;
        }
      }
      .input-icon {
        position: relative;
        bottom: 10px;

        i {
          color: #68696a;
        }
      }
    }
    .input-line {
      border: 1px solid #3d3e40;
      margin-top: 7px;
    }
  }
  .location-list {
    position: absolute;
    padding: 16px 16px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
    /* background-color: #1b1c1d; */
    background-color: #27282A;
    width: 89%;
    z-index: 1;
    line-height: 2;
    height: 180px;
    overflow: auto;
    margin-top: 6px;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .price-list {
    position: absolute;
    padding: 16px 16px 16px 16px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
    background-color: #27282A;
    width: 89%;
    z-index: 1;
    line-height: 2;
    height: 152px;
    margin-top: 6px;
    .wrapper-circle {
      .price {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .price-circle {
          height: 20px;
          width: 20px;
          border-radius: 20px;
          margin-right: 10px;
          cursor: pointer;
          /* &:after {
            content: '';
            display: block;
            height: 20px;
            width: 20px;
            top: 0px;
            background: #F8E6AE url(ico.gif) no-repeat 0px 0px;
            border-radius: 20px;
          } */
        }
        .price-dollar {
        }
      }
    }
  }
  .cancel-btn {
    text-align: center;
    color: #ed2626;
    cursor: pointer;
  }
  .bottom-btns {
    padding-top: 20px;
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-title {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .res-name {
    input {
      font-size: 16px;
    }
  }
  .cancel-btn {
    margin-top: 40px;
    margin-top: 15px;
    font-weight: 600;
  }
  h6 {
    line-height: unset !important;
  }
`;

export const DrawerMobileNumberInputWithVerifyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding-top: 10px;
  h6 {
    font-size: 14px;
    opacity: 0.4;
    line-height: 1.57;
    letter-spacing: -0.34px;
  }
  .drawer-input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    .input-prefix {
      flex: 0 0 auto;
      color: #fff;
      padding-right: 10px;
    }
    input {
      flex: 1 1 auto;
      background-color: transparent;
      color: #fff;
      font-weight: bold;
      border: unset;
      outline: unset;
      padding-left: 0;
    }
  }
  .input-icon {
    position: absolute;
    right: 0;
    width: 27px;
    height: 27px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: ${colors.gray};
    }
  }
`;
