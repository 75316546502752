import styled from "@emotion/styled";

export const ProfileCardFavoriteContainer = styled.div`
  position: relative;
  padding: 26px 36px;
  background: ${(props) => props.image && `url(${props.image})`};
  background-size: 100% auto;
  background-position: center;
  display: flex;
  flex-direction: column;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
  }
  .favorite-content {
    z-index: 1;
    .favorite-title {
      i {
        color: #fff;
      }
      margin-bottom: 13px;
    }
    .favorite-value {
      margin-bottom: 13px;
    }
  }
`;
