import React, { useContext, useState, useEffect } from "react";
import { DrawerContainer } from "components/styledStyle";
import { Image } from "react-bootstrap";
import defaultFood from "assets/img/default-food.jpg";
import { DrawerItemCustomizationContainer, Label } from "./style";

// customization mock data
// const customDetail = [
//   {
//     id: 1,
//     food_item_id: 124,
//     name: "SIZE",
//     available_selection: 1,
//     required: true,
//     options: [
//       {
//         customization_option_id: 94,
//         name: "small",
//         price: "4.00",
//       },
//       {
//         customization_option_id: 95,
//         name: "Medim",
//         price: "2.00",
//       },
//       {
//         customization_option_id: 96,
//         name: "Large",
//         price: "0.00",
//       },
//     ],
//   },
//   {
//     id: 2,
//     food_item_id: 45,
//     name: "Add-Ons",
//     available_selection: 1,
//     required: false,
//     options: [
//       {
//         customization_option_id: 65,
//         name: "Mint",
//         price: "0.00",
//       },
//       {
//         customization_option_id: 66,
//         name: "Lemon",
//         price: "0.00",
//       },
//     ],
//   },
// ];

export default function DrawerFoodItemCustomization({
  isActive = false,
  closePanel = () => {},
  foodDetails,
  foodReviews,
  itemCustomize,
}) {
  const [isDressingOpened, setIsDressingOpend] = useState(false);

  const [selectOptionbox, setSelectOptionbox] = useState()

  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const optionSelect = (optionSelect) => {
   setSelectOptionbox(optionSelect?.customization_option_id)
  }

  return (
    <DrawerItemCustomizationContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div className="item-customization-wraapper">
          <div>
            <i
              className="far fa-angle-left cursor-pointer"
              onClick={closePanel}
              style={{ fontSize: "30px", color: "#fff" }}
            />
          </div>
          <div className="customization-title">Customizations</div>
          <div className="customization-name">{foodDetails?.name}</div>

          {
            <div className="dressing-item-list d-flex flex-column w-100">
              {itemCustomize &&
                itemCustomize.map((el, i) => {
                  // console.log(JSON.stringify(el.required))
                  return (
                    <div key={i}>
                      <div
                        style={{ marginTop: "30px" }}
                        className="collpased-scroll d-flex justify-content-between align-items-center"
                        onClick={() => {
                          setIsDressingOpend(!isDressingOpened);
                        }}
                      >
                        <Label>{el?.name}</Label>
                        <div className="angle-down-div  d-flex align-items-center">
                          <Label
                            size="14px"
                            weight="500"
                            opacity="0.4"
                            marginRight="11px"
                          >
                           {el?.required ? "" : "Optional"} 
                          </Label>
                          {/* {isDressingOpened ? (
                            <i className="fas fa-chevron-up" style={{color: '#fff'}} />
                          ) : (
                            <i className="fas fa-chevron-down" style={{color: '#fff'}} />
                          )} */}
                          <div onClick={() => toggle(i)}>
                            {selected == i ? (
                              <i
                                className="fas fa-chevron-up cursor-pointer"
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              <i
                                className="fas fa-chevron-down cursor-pointer"
                                style={{ color: "#fff" }}
                              />
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      {/* isDressingOpened ? */}
                      {selected == i ? (
                        <div>
                          {el &&
                            el.options &&
                            el.options.map((optionsEl, _i) => {
                              return (
                                <div
                                  key={_i}
                                  className="dressing-item cursor-pointer"
                                  style={{
                                    border: optionsEl?.customization_option_id === selectOptionbox
                                      ? "solid 1px #4d5053"
                                      : "solid 1px #4d5053",
                                  }}
                                  onClick={() => optionSelect(optionsEl)}
                                >
                                  {optionsEl?.name}
                                </div>
                              );
                            })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          }
        </div>
      </DrawerContainer>
    </DrawerItemCustomizationContainer>
  );
}
