import styled from '@emotion/styled'
import { colors } from 'theme';

export const MySwitchContainer = styled.div`
  /* .switch-handle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition-property: left;
    transition-duration: 0.3s;
  }
  &.active-button{
    background-color: ${colors.redColor};
    .switch-handle{
      left: 23px;
    }
  } */
`;
