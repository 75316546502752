import styled from "@emotion/styled";
import { colors } from "theme";

export const AuthPageContainer = styled.div`
  display: flex;
  background-color: ${colors.gray1};
  .reset-password-left {
    min-width: 50%;
    .reset-password-form {
      width: 350px;
      .reset-password-logo {
        margin-bottom: 70px;
      }
      .reset-password-title {
        margin-bottom: 27px;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
      }
      .reset-password-subtitle {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 1.44;
      }
      .reset-password-content {
        margin: 10px 0 0 0;
        width: 100%;

        .auth-input-container {
          margin-bottom: 35px;
        }
      }
      .resend {
        margin-top: 31px;
        margin-bottom: 20px;

        .resend-text {
          opacity: 0.5;
          font-size: 13px;
        }
        .resend-button {
          color: #ed2626;
          font-weight: bold;
          margin-left: 7px;
          cursor: pointer;
          font-size: 13px;

          i {
            color: #ed2626;
          }
        }
      }
      .cancel-btn {
        margin-top: 10px;
      }
      .icon {
        margin-bottom: 28px;
      }
    }
  }
`;
