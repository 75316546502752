import React, { useState, useContext, useEffect } from "react";
import { DrawerDeleteCuisinesModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyButton from "components/Elements/MyButton";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import DeleteConfirm from "./DeleteConfirm";
import { drawersOverall } from "static/staticData";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { customAxios } from "helpers/custom-helpers/customAxios";

const DeleteCuisines = ({ isActive = false, closePanel = () => {} }) => {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    cuisineCloseDrawer,
    setCuisineCloseDrawer,
    updateCuisineDetailsData,
    setCuisineList,
  } = useContext(RestaurantsContext);

  const [
    cuisineDeleteNotificationMessage,
    setCuisineDeleteNotificationMessage,
  ] = useState();

  useEffect(() => {
    if (cuisineDeleteNotificationMessage) {
      setTimeout(() => setCuisineDeleteNotificationMessage(null), 2 * 1000);
    }
  }, [cuisineDeleteNotificationMessage]);

  function getCuisineData() {
    customAxios
      .get(`/cuisine`)
      .then((res) => {
        setCuisineList(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function onClickDelete() {
    customAxios
      .delete(`/cuisine/${updateCuisineDetailsData?.id}`)
      .then((res) => {
        if (res.status === 200) {
          getCuisineData();
          closePanel();
          setCuisineDeleteNotificationMessage({
            message: `${updateCuisineDetailsData?.name} Deleted.`,
            type: "cuisine-inactive",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">Restaurants will be notified and will be able to arrange new cuisine on their own.</span>`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <DrawerDeleteCuisinesModalContainer
        isActive={isActive}
        closePanel={closePanel}
        left="40%"
        top="25%"
      >
        <DrawerContainer>
          {cuisineDeleteNotificationMessage && (
            <MyMessageBoxItemNew
              message={
                cuisineDeleteNotificationMessage?.message
                  ? cuisineDeleteNotificationMessage?.message
                  : ""
              }
              table={
                cuisineDeleteNotificationMessage?.table
                  ? cuisineDeleteNotificationMessage?.table
                  : ""
              }
              type={
                cuisineDeleteNotificationMessage?.type
                  ? cuisineDeleteNotificationMessage?.type
                  : ""
              }
              onClick={() => setCuisineDeleteNotificationMessage(null)}
            />
          )}
          <div className="delete-cuisine-modal">
            <div className="delete-icon">
              <i className="fas fa-trash" />
            </div>
            <div className="delete-cuisine-title">Delete Cuisine</div>
            <div className="delete-cuisine-subtitle">
              Are you sure you want to delete{" "}
              <span className="text-bold">
                {updateCuisineDetailsData?.name}
              </span>
              ?
            </div>
            <div className="delete-cuisine-btn">
              {updateCuisineDetailsData?.used === 0 ? (
                <MyDrawerButton onClick={onClickDelete} type="red">
                  <b>Yes, Delete</b>
                </MyDrawerButton>
              ) : (
                <MyDrawerButton
                  type="red"
                  onClick={() => {
                    // openDrawer(drawersOverall.deleteCuisines);
                    setCuisineCloseDrawer({
                      ...cuisineCloseDrawer,
                      deleteConfirmCuisines: true,
                      deleteCuisines: false,
                    });
                  }}
                >
                  <b>Yes, Delete</b>
                </MyDrawerButton>
              )}

              <MyDrawerButton onClick={closePanel} type="gray">
                <b>No, Keep</b>
              </MyDrawerButton>
            </div>
          </div>
        </DrawerContainer>
      </DrawerDeleteCuisinesModalContainer>
      <DeleteConfirm
        // isActive={!!openDrawers[drawersOverall.deleteConfirmCuisines]}
        // closePanel={() => closeDrawer(drawersOverall.deleteConfirmCuisines)}

        isActive={cuisineCloseDrawer.deleteConfirmCuisines}
        closePanel={() => {
          setCuisineCloseDrawer({
            ...cuisineCloseDrawer,
            deleteConfirmCuisines: false,
          });
        }}
      />
    </>
  );
};

export default DeleteCuisines;
