import styled from '@emotion/styled'
import { colors } from 'theme';

export const IconCheckContainer = styled.div`
  height: ${props => props.size === 'large' ? 29 : 20}px;
  width: ${props => props.size === 'large' ? 29 : 20}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.green};
`;
