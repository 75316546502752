import styled from "@emotion/styled";
import { colors } from "theme";

export const RestaurantProfileStaffContainer = styled.div`
  color: #fff;
  .top-navigation-panel {
    display: flex;
    justify-content: space-between;
    padding: 25px 40px;
    .backlink-to-rest {
      color: ${colors.white};
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.41px;
    }
    .arrow-icon-container {
      i {
        color: white;
      }
    }
    .current-restaurant {
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.41px;
    }

    .staff-handlers {
      display: flex;
      .search-pane {
          cursor: pointer;
          margin-right: 15px;
          height: 40px;
          border-radius: 25px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          -webkit-transition: border-color 500ms ease-out;
          -moz-transition: border-color 500ms ease-out;
          -o-transition: border-color 500ms ease-out;
          transition: border-color 500ms ease-out;

          &:hover {
            border: none;
          }
        }
      .serach-input-staff {
        width: 250px;
        border-radius: 25px;
        border: solid 1px #27282a;
        background-color: #27282a;
        display: flex;
        padding: 5px 0 5px 0;
        flex-direction: column;
        position: absolute;
        margin-top: 5px;
        .search-input-div {
          font-size: 14px;
          color: #fff;
          padding: 10px 20px;
        }
      }
    }
    .filter-navigation-panel {
      display: flex;
      align-items: center;
    }
  }

  .staff-navigation-panel {
    display: flex;
    align-items: center;
    background-color: #27282a;
    border-radius: 20px;
    height: 40px;
    width: fit-content;
    .staff-menu-nav {
      cursor: pointer;
      padding: 0 0px;
      height: 100%;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.5);
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      &.selected {
        color: rgba(255, 255, 255, 1);
        background-color: #ed2626;
        border-radius: 20px;

        /* &:after {
          content: "";
          border-bottom: solid 3px ${colors.redColor};
          display: block;
          width: 30px;
          margin: 0 auto;
        } */
      }
    }
    /* .staff-menu-nav:after {
      content: "";
      border-bottom: solid 3px transparent;
      display: block;
      width: 30px;
      margin: 0 auto;
    } */
  }

  .main-content-panel {
    padding: 0px 50px;
    .no-result-staff-screen {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 120px;
      img {
        width: 347px;
        height: 314px;
        margin-bottom: 61px;
      }
      .staff-no-result-text {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-bottom: 14px;
      }
      .staff-no-result-meta-text {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
  }

  .sort-filter {
    margin-right: 4px;
    
    .dropdown-header-menu {
      width: 8rem;
      background-color: #27282a;
    }
    .dropdown-content-menu {
      width: unset !important;
    }
  }

  .filter-red-dot {
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    position: relative;
    bottom: 18px;
    left: 8px;
  }

  .role-filter {
    margin-right: 80px;
    .dropdown-header-menu {
      width: 14rem;
    }
    .dropdown-content-menu {
      width: unset !important;
    }
  }

  .status-filter {
    margin-right: 40px;
    .dropdown-header-menu {
      width: 10rem;
    }
    .dropdown-content-menu {
      width: unset !important;
    }
  }
  
  .btn-add-staff{
    width: 180px;
  }

  .menu-header-select {
    .dropdown-content-menu {
      width: 165px !important;
    }
  }
`;
