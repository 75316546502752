import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useState, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { DrawerStatusContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import StatusActiveModal from "./StatusActiveModal";
import { drawersRestaurants } from "static/staticData";
import StatusInactiveModal from "./StatusInactiveModal";
import { showMessage } from "services/functions";
import { useParams } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerStatus({
  profile = {},
  isActive = false,
  closePanel = () => {},
  onChangeStatus = () => {},
}) {
  const { id } = useParams();

  const [activeNoteNotofication, setActiveNoteNotofication] = useState();
  const [inactiveNoteNotofication, setInactiveNoteNotofication] = useState();

  const {
    postRestaurant,
    setPostRestaurant,
    openDrawer,
    openDrawers,
    closeDrawer,
    setNotesData,

    activeNotes,
    setActiveNotes,

    inactiveNote,
    setInactiveNote,
    reason,
    setReason,
  } = useContext(RestaurantsContext);

  useEffect(() => {
    if (isActive) {
      getNotesData();
    }
  }, [id, isActive]);

  useEffect(() => {
    if (activeNoteNotofication) {
      setTimeout(() => setActiveNoteNotofication(null), 2 * 1000);
    }
  }, [activeNoteNotofication]);

  useEffect(() => {
    if (inactiveNoteNotofication) {
      setTimeout(() => setInactiveNoteNotofication(null), 2 * 1000);
    }
  }, [inactiveNoteNotofication]);

  const getNotesData = () => {
    customAxios
      .get(`/restaurant/${id}/notes`)
      .then((res) => {
        setNotesData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // restaurant set active
  const handleClickActive = () => {
    const notesPayload = {
      note: activeNotes,
      active: true,
    };
    customAxios
      .post(`/restaurant/${id}/note`, notesPayload)
      .then((res) => {
        if (res.status === 200) {
          getNotesData();
          setActiveNoteNotofication({
            message: "Status changed to Active.",
            type: "active-success",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0"> ${postRestaurant?.title}</span> status changed to Active.`,
          });
          closePanel();
        } else {
          if (res?.errMessage === "Action not allowed") {
            showMessage("Action not allowed", "error");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setActiveNotes("");
  };

  // restaurant set inactive
  const handleClickInactive = () => {
    const notesPayload = {
      note: inactiveNote,
      note_to_restaurant: reason,
      active: false,
    };
    customAxios
      .post(`/restaurant/${id}/note`, notesPayload)
      .then((res) => {
        if (res.status === 200) {
          setInactiveNoteNotofication({
            message: "Status changed to Inctive.",
            type: "inactive-success",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0"> ${postRestaurant?.title}</span> status changed to Inactive.`,
          });
          getNotesData();
          closePanel();
        } else {
          if (res?.errMessage === "Action not allowed") {
            showMessage("Action not allowed", "error");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setInactiveNote("");
    setReason("");
  };

  return (
    <DrawerStatusContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        {activeNoteNotofication && (
          <MyMessageBoxItemNew
            message={
              activeNoteNotofication?.message
                ? activeNoteNotofication?.message
                : ""
            }
            table={
              activeNoteNotofication?.table ? activeNoteNotofication?.table : ""
            }
            type={
              activeNoteNotofication?.type ? activeNoteNotofication?.type : ""
            }
            onClick={() => setActiveNoteNotofication(null)}
          />
        )}

        {inactiveNoteNotofication && (
          <MyMessageBoxItemNew
            message={
              inactiveNoteNotofication?.message
                ? inactiveNoteNotofication?.message
                : ""
            }
            table={
              inactiveNoteNotofication?.table
                ? inactiveNoteNotofication?.table
                : ""
            }
            type={
              inactiveNoteNotofication?.type
                ? inactiveNoteNotofication?.type
                : ""
            }
            onClick={() => setInactiveNoteNotofication(null)}
          />
        )}

        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Status</div>
          <div></div>
        </div>
        <div className="drawer-status--options mb-auto">
          <DrawerChooseItem
            className="mb-4"
            title="Active"
            active={postRestaurant?.status === 1}
            onChooseItem={() => {
              onChangeStatus(1);
              // closePanel();
              openDrawer(drawersRestaurants.setActiveModal);
            }}
          />
          <DrawerChooseItem
            title="Inactive"
            active={postRestaurant?.status !== 1}
            onChooseItem={() => {
              onChangeStatus(0);
              // closePanel();
              openDrawer(drawersRestaurants.setInactiveModal);
            }}
          />
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold"
            // onClick={closePanel}
            onClick={
              postRestaurant?.status === 1
                ? handleClickActive
                : handleClickInactive
            }
          >
            Save
          </MyDrawerButton>
        </div>
      </DrawerContainer>

      <StatusActiveModal
        isActive={!!openDrawers[drawersRestaurants.setActiveModal]}
        closePanel={() => closeDrawer(drawersRestaurants.setActiveModal)}
      />
      <StatusInactiveModal
        isActive={!!openDrawers[drawersRestaurants.setInactiveModal]}
        closePanel={() => closeDrawer(drawersRestaurants.setInactiveModal)}
      />
    </DrawerStatusContainer>
  );
}
