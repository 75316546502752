import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useState, useContext, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { dayTitles, drawersOverall } from "static/staticData";
import { DrawerBusinessHoursContainer } from "./style";
import moment from "moment";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

export default function DrawerBusinessHours({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer, postRestaurant, selectedDayToEdit, setSelectedDayToEdit } = useContext(
    RestaurantsContext
  );
  const { id } = useParams();
  useEffect(() => {}, [id]);

  // console.log(postRestaurant?.working_hours);

  function convertTimeToReadableFormat(time) {
    const formattedTime = time % (12 * 60 * 60);
    const hr = Math.floor(formattedTime / (60 * 60));
    const min = (formattedTime - hr * 60 * 60) / 60;
    const dateTime = Math.floor(time / (12 * 60 * 60)) >= 1 ? "PM" : "AM";

    return `${hr < 10 ? "0" + hr : hr}:${min === 0 ? "00" : min} ${dateTime}`;
    // console.log(`${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`)
  }

  // function onDaySelect(d) {

  // }

  const handleDayEditClick = (day) => {
    const dayNumber = dayTitles.find((el) => el?.content === day).num;
    setSelectedDayToEdit(dayNumber);
    openDrawer(drawersOverall.availableDay);
  };

  // const [displayTime, setDisplayTime] = useState()

  return (
    <DrawerBusinessHoursContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Business Hours</div>
          <div></div>
        </div>

        <div style={{marginBottom: "25px"}}>
          <ItemPicker
            label={"Closed Days"}
            placeholder="Select Days"
            value={
              postRestaurant?.closed_days
                ? postRestaurant?.closed_days?.length === 0
                  ? ""
                  : `${postRestaurant?.closed_days?.length} days`
                : ""
            }
            onClick={() => openDrawer(drawersOverall.closedDays)}
          />
        </div>

        {dayTitles.map(({ content: day }, _i) => {
          const dayNumeric = dayTitles.find(
            ({ content: dayContent }) => dayContent === day
          ).num;
          const isDayWasSelected = postRestaurant?.working_hours?.find(
            (selectedDay) => selectedDay?.day === dayNumeric
          );
          let dayToDisplay = "";
          if (isDayWasSelected !== null && isDayWasSelected !== undefined) {
            const timeRange = isDayWasSelected?.times[0];
            const [startTime, endTime] = timeRange;
            if (startTime === 0 && endTime === 86400) {
              dayToDisplay = "All Day";
              // console.log(dayToDisplay)
            } else {
              dayToDisplay =
                convertTimeToReadableFormat(startTime) +
                " - " +
                convertTimeToReadableFormat(endTime);
              // console.log(dayToDisplay)
            }
          }
          // console.log(dayToDisplay, '++')
          return (
            <ItemPicker
              key={_i}
              label={day}
              placeholder="Set time"
              value={dayToDisplay}
              onClick={() => handleDayEditClick(day)}
            />
          );
        })}

        <div className="bottom-btns">
          <MyDrawerButton
            type={
              postRestaurant?.working_hours === undefined ||
              postRestaurant?.working_hours?.length === 0
                ? "gray"
                : "red"
            }
            className="text-bold"
            // onClick={() => closeDrawer(drawersMenu.availabilities)}
            disabled={
              postRestaurant?.working_hours === undefined ||
              postRestaurant?.working_hours?.length === 0
            }
            onClick={closePanel}
          >
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerBusinessHoursContainer>
  );
}
