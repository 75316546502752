import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const NewServerAssignedModalContainer = styled(MyDrawerCenterModal)`
  .new-server-assigned-modal {
    position: relative;
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -80px;
      left: 163px;
    }

    .server-data-meta {
      text-align: center;
      margin-bottom: 24px;
    }

    .img-container {
      z-index: 98;
      width: 82px;
      height: 80px;
      border-radius: 50%;
      background-color: #27282a;
      border: solid 6px #27282a;
      position: absolute;
      top: -79px;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }

    .delete-suborder-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
    }

    .delete-suborder-actions {
      button {
        width: 100%;
      }
      .apply-btn {
        margin-bottom: 10px;
      }
    }
  }
`;
