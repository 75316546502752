import styled from "@emotion/styled";
import { colors } from "theme";

export const CuisinePageContainer = styled.div`
  .cuisine-wrapper {
    padding: 25px 60px;
    .cuisine-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cuisine-title {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 26px;
        margin-top: 20px;
      }
      .filter-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        .search-filter {
          margin-right: -15px;
        }

        .search-pane {
          cursor: pointer;
          margin-right: 15px;
          height: 40px;
          border-radius: 25px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          -webkit-transition: border-color 500ms ease-out;
          -moz-transition: border-color 500ms ease-out;
          -o-transition: border-color 500ms ease-out;
          transition: border-color 500ms ease-out;

          &:hover {
            border: none;
          }
        }

        .add-cuisine {
          .add-cuisine-btn {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 6rem 0 9rem;
      margin-top: 20px;
      .name {
        color: #fff;
        opacity: 0.5;
      }
      .restaurant {
        color: #fff;
        opacity: 0.5;
      }
    }

    .search-applied {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .no-result {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 50px;
      .no-result-img {
        img {
          height: 350px;
          margin: 90px 0 15px 0;
        }
      }
      .no-result-title {
        font-size: 18px;
        font-size: bold;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 24px;
      }
      .no-result-subtitle {
        color: #fff;
        opacity: 0.4;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .cuisine-list {
      background-color: #1b1c1d;
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-radius: 20px;
      margin-bottom: 12px;
      .name-left {
        display: flex;
        align-items: center;
        gap: 32px;
        .cuisine-image {
          -webkit-filter: drop-shadow(5px 5px 12px black);
          img {
            width: 70px;
            height: 70px;
            border-radius: 13px;
            object-fit: contain;
          }
        }
        .cuisine-name {
          font-size: 17px;
          font-weight: bold;
        }
      }
      .restaurent-right {
        display: flex;
        align-items: center;
        gap: 90px;
      }
    }
  }
`;
