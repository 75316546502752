import React, { useState, useRef } from "react";
import { GuestsInfoContainer } from "./style";
import { Avatar } from "@material-ui/core";
import { NoAvatar } from "assets/img";
import { Image } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import MyButton from "components/Elements/MyButton";
import moment from "moment";
import { useOutsideClick } from "services/functions";

const GuestsInfo = ({
  guest,
  onSelectGuestCheckout = () => {},
  orderDetails = {},
}) => {
  const [isGuestsInfoShowing, setIsGuestsInfoShowing] = useState(false);
  const guestRef = useRef();
  useOutsideClick(guestRef, () => {
    setIsGuestsInfoShowing(false);
  });
  return (
    <div onClick={() => setIsGuestsInfoShowing(true)} ref={guestRef}>
      <div style={{ position: "relative" }}>
        <Avatar alt={guest?.name || ""} src={guest?.image || NoAvatar} />
        {isGuestsInfoShowing && (
          <GuestsInfoContainer>
            <div className="guest-profile-info-header d-flex justify-content-between mb-1">
              <div className="d-flex align-items-center">
                <div className="guestprofile-avatar">
                  <Image
                    style={{ borderRadius: "50%" }}
                    src={guest?.image || NoAvatar}
                    width={92}
                  />
                  <div className="customer-profile-rating">
                    <div className="rating-container">
                      <span className="rating-number">
                        {guest?.total_ratings || 0}
                      </span>
                      <div className="rating-icon">
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <h5 style={{ marginBottom: "15px" }}>
                    <b>{guest?.name}</b>
                  </h5>
                  <h5 style={{ marginBottom: "15px" }} className=" opacity6">
                    Joined:&nbsp;
                    {moment(guest?.joined_at).format("MMMM DD,YYYY,HH:MM A ")}
                  </h5>
                  <h5 style={{ marginBottom: "15px" }} className=" opacity6">
                    <FaMapMarkerAlt color="white" />-
                  </h5>
                </div>
              </div>
              <div>
                <b className="text-red cursor ">View profile {" >"}</b>
              </div>
            </div>
            <div
              className="d-flex justify-content-between mb-5 "
              style={{
                marginTop: "28px",
              }}
            >
              <div
                className="d-flex flex-column mr-7 "
                style={{ marginBottom: "6px" }}
              >
                <h5 className=" opacity8">{guest?.feast_here || 0}</h5>
                <h5 className=" opacity6">Feasts here</h5>
              </div>
              <div className="d-flex flex-column ">
                <h5 className=" opacity8">{guest?.total_feast || 0}</h5>
                <h5 className=" opacity6">Total feasts</h5>
              </div>
              <div className="d-flex flex-column ">
                <h5 className=" opacity8">0</h5>
                <h5 className=" opacity6">Reviews left</h5>
              </div>
              <div
                className="d-flex flex-column mr-7 "
                style={{ marginBottom: "6px" }}
              >
                <h5 className=" opacity8">0</h5>
                <h5 className=" opacity6">Time spent on our app</h5>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center guest-check-out-btn">
              {orderDetails?.status === 2 ||
              orderDetails?.guests?.length === 1 ? (
                <></>
              ) : (
                <MyButton
                  type="redColor"
                  onClick={() => {
                    onSelectGuestCheckout(guest);
                  }}
                >
                  Check Out
                </MyButton>
              )}
            </div> */}
          </GuestsInfoContainer>
        )}
      </div>
    </div>
  );
};

export default GuestsInfo;
