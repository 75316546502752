import MyDateRange from "components/Elements/MyDateRange";
import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import { StyledIconWrapper } from "theme/styles";
import { AnalyticPeriodContainer, PopupCalendarContainer } from "./style";
import moment from "moment";
import MyButton from "components/Elements/MyButton";

export const AnalyticPeriod = ({
  className = "",
  // options = ["Today", "Week", "Month", "Quarter", "Year", "all"],
  options = ["Today", "Week", "Month", "Quarter", "Year"],
  onChange = () => {},
  setDateRange,
  dateRange,
  defaultPeriodSelect = "day",
}) => {
  const [tempDateRange, setTempDateRange] = useState();

  const [periodsList, setPeriodsList] = useState([
    { title: "Today", value: "day", isChecked: true },
    { title: "Week", value: "week", isChecked: false },
    { title: "Month", value: "month", isChecked: false },
    { title: "Quarter", value: "quarter", isChecked: false },
    { title: "Total", value: "year", isChecked: false },
  ]);

  useEffect(() => {
    if (defaultPeriodSelect === "week") {
      let array = [
        { title: "Today", value: "day", isChecked: false },
        { title: "Week", value: "week", isChecked: true },
        { title: "Month", value: "month", isChecked: false },
        { title: "Quarter", value: "quarter", isChecked: false },
        { title: "Total", value: "year", isChecked: false },
      ];
      setPeriodsList(array);
    }
  }, [defaultPeriodSelect]);

  const handleClick = (option, index) => {
    let data = [...periodsList];
    data.filter((el) => (el.isChecked = false));
    data[index].isChecked = !data[index].isChecked;
    setPeriodsList(data);
    setDateRange();
    onChange(option.value);
  };

  const onChangeDate = (date) => {
    if (date && date.endDate) {
      setTempDateRange({
        startDate: moment(date.startDate).format("YYYY-MM-DD"),
        endDate: moment(date.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  function onChangeApply() {
    if (tempDateRange && tempDateRange.endDate) {
      setDateRange({
        startDate: moment(tempDateRange.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange.endDate).format("YYYY-MM-DD"),
      });
      document.body.click();
    }
  }

  const popupCompleted = (
    <PopupCalendarContainer className="popup-wrapper">
      <MyDateRange months={2} onChangeDate={onChangeDate} date={dateRange} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "14px" }}>
          {dateRange?.startDate
            ? moment(dateRange?.startDate).format("MM/DD/YYYY")
            : ""}
          {dateRange && " - "}
          {dateRange?.endDate
            ? moment(dateRange?.endDate).format("MM/DD/YYYY")
            : ""}
        </span>

        <MyButton
          className="apply-date"
          type={tempDateRange?.endDate || dateRange?.endDate ? "red" : "gray"}
          onClick={onChangeApply}
        >
          Apply
        </MyButton>
      </div>
    </PopupCalendarContainer>
  );

  return (
    <AnalyticPeriodContainer className={className}>
      {periodsList.map((option, index) => (
        <h5
          className={`period-option ${
            option.isChecked === true ? "checked" : ""
          }`}
          onClick={() => handleClick(option, index)}
          key={option.title}
        >
          {option.title}
        </h5>
      ))}
      <div id="calendar-popup-handle">
        <OverlayTrigger
          container={() => document.getElementById("calendar-popup-handle")}
          placement="bottom-end"
          popperConfig={{ className: "fffffffffff" }}
          trigger="click"
          overlay={popupCompleted}
          rootClose
        >
          <StyledIconWrapper>
            <span className="checked">
              <i className="fas fa-calendar-alt" />
            </span>
          </StyledIconWrapper>
        </OverlayTrigger>
      </div>
    </AnalyticPeriodContainer>
  );
};
