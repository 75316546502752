import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerCategoriesContainer = styled(MyDrawerRight)`
  * {
    padding: 0;
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    padding: 20px 20px 0 20px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .drag-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 18px 15px 18px;
    border-radius: unset;
    border: solid 1px #5b5e61;
    border-right-color: transparent;
    border-left-color: transparent;
    .drag-menu-item-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: auto;
      margin-left: 19px;
      .drag-menu-item-name {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.34px;
      }
    }
  }

  .bottom-btns {
    padding: 20px;
  }
`;
