import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ParamControlContainer } from './style';
import { showMessage } from 'services/functions';

export function ParamControl({
  title = '',
  options = [],
  selectedOptions = [],
  onChange = () => {},
  isOptional,
  customizeMenu,
  maxSelection,
}) {
  const [open, setOpen] = useState(false);
  const showHide = () => setOpen(!open);
  const [count, setCount] = useState(0);

  // console.log(selectedOptions);
  // console.log(customizeMenu);
  // console.log(customizeMenu.filter((item) => item.name === 'Add Ons'));
  useEffect(() => {
    setCount(
      options.filter((item) =>
        selectedOptions.includes(item.customization_option_id)
      ).length
    );
  }, [onChange]);

  return (
    <ParamControlContainer>
      <div className='param-control-top' onClick={showHide}>
        <div className='param-control-top-title-container'>
          <span className='param-control-top-title'>{title}</span>
        </div>
        <div className='show-control-container'>
          <div
            className={`option-title-container ${
              isOptional ? 'not-so-important' : ''
            }`}
          >
            <span className='option-title'>Optional</span>
          </div>

          <div className='show-selected-items-total'>
            {selectedOptions?.length && options?.length
              ? options.filter((item) =>
                  selectedOptions.includes(item.customization_option_id)
                ).length
              : 0}
          </div>

          <div className='show-button-container'>
            <i
              className={`fas ${open ? 'fa-chevron-up' : 'fa-chevron-down'}`}
            />
          </div>
        </div>
      </div>
      {open ? (
        <div className='param-control-options'>
          {options.map((option, index) => (
            <div
              className={`param-control-option ${
                selectedOptions.some(
                  (selectedOption) =>
                    selectedOption === option.customization_option_id
                )
                  ? 'active'
                  : ''
              }`}
              // onClick={() => onChange(price ? [option, price] : [option])}
              onClick={() => {
                // options.some((item) => {
                //   if (selectedOptions.includes(item.customization_option_id)) {
                //     const newOptions = selectedOptions.findIndex((i) => {
                //       return i === item.customization_option_id;
                //     });
                //     onChange(selectedOptions.splice(newOptions, 1));
                //   } else {
                //     onChange(option);
                //   }
                // });
                // onChange(option);
                // if (count === maxSelection && options.length > 1) {
                //   const newOptions = selectedOptions.findIndex((i) => {
                //     return (
                //       i ===
                //       options.filter((item) =>
                //         selectedOptions.includes(item.customization_option_id)
                //       )[0].customization_option_id
                //     );
                //   });
                //   showMessage(`You can only select ${maxSelection} options`);

                //   setCount(maxSelection);
                //   // console.log(newOptions);
                //   // console.log(selectedOptions.splice(newOptions, 1));
                //   onChange(selectedOptions.splice(newOptions, 1));
                //   onChange(option);
                // } else {
                //   onChange(option);
                // }

                // onChange(option);
                if (
                  count === maxSelection &&
                  options.length > 1 &&
                  !selectedOptions?.includes(option?.customization_option_id)
                ) {
                  const newOptions = selectedOptions.findIndex((i) => {
                    return (
                      i ===
                      options.filter((item) =>
                        selectedOptions.includes(item.customization_option_id)
                      )[0].customization_option_id
                    );
                  });
                  showMessage(`You can only select ${maxSelection} option`);
                  // onChange(selectedOptions.splice(newOptions, 1));
                  // setCount(count - 1);
                  // onChange(option);
                }
                if (
                  selectedOptions?.includes(option?.customization_option_id)
                ) {
                  onChange(option);
                  setCount(count - 1);
                } else if (count < maxSelection) {
                  onChange(option);
                }
              }}
              key={option + index}
            >
              {option ? (
                <div className='name-with-price'>
                  <span className='option-name'>{option.name}</span>
                  {Number(option.price) > 0 && (
                    <span className='option-price'>
                      +${Number(option.price).toFixed(2)}
                    </span>
                  )}
                </div>
              ) : (
                option
              )}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </ParamControlContainer>
  );
}

ParamControl.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  isOptional: PropTypes.bool,
  customizeMenu: PropTypes.array,
  maxSelection: PropTypes.number,
};
