import React, { useEffect } from "react";
import { SetTaxesAndFeeModalContainer, ApproveInputContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerInputContainer } from "components/Elements/DrawerElements/DrawerInput/style";
import { NoAvatar } from "assets/img";

const SetTaxesAndFeeModal = ({
  isActive = false,
  closePanel = () => {},
  restaurant = {},
  onDone = () => {},
  feastttFeeInput = "",
  setFeastttFeeInput = () => {},
  tax = "",
  setTax = () => {},
}) => {
  useEffect(() => {
    if (restaurant) {
      setFeastttFeeInput(restaurant?.feast_fees);
      setTax(restaurant?.tax);
    }
  }, [isActive]);
  return (
    <SetTaxesAndFeeModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"37%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="restaurant-approval-div">
          <div className="modal-image">
            <img
              src={restaurant?.photo?.file || NoAvatar}
              alt=""
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="modal-title">
            <b>Set Taxes & Fees</b>
          </div>
          <div className="modal-restaurant-name">{restaurant?.title}</div>
          <div className="approve-modal-inputs">
            <ApproveInputContainer
              isInputValuePresent={feastttFeeInput ? true : false}
            >
              <DrawerInputContainer>
                <h6 className="mb-0">Feasttt Fee</h6>
                <div
                  className="drawer-input-container"
                  data-value={feastttFeeInput ? feastttFeeInput : ""}
                >
                  <input
                    value={feastttFeeInput || ""}
                    placeholder="Feasttt Fee"
                    onChange={(e) => {
                      if (e.target.value >= 0 && e.target.value < 100) {
                        setFeastttFeeInput(e.target.value);
                      }
                    }}
                  />
                </div>
              </DrawerInputContainer>
            </ApproveInputContainer>
            <ApproveInputContainer isInputValuePresent={tax ? true : false}>
              <DrawerInputContainer>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h6 className="mb-0">Taxes</h6>
                  <h6
                    className="mb-0"
                  >
                    Set by restaurant owner
                  </h6>
                </div>
                <div
                  className="drawer-input-container"
                  data-value={tax ? tax : ""}
                >
                  <input
                    value={tax || ""}
                    placeholder="Tax"
                    onChange={(e) => {
                      console.log("e.target.value", e.target.value);
                      if (e.target.value >= 0 && e.target.value < 100) {
                        setTax(e.target.value);
                      }
                    }}
                  />
                </div>
              </DrawerInputContainer>
            </ApproveInputContainer>
          </div>
          <div className="modal-bottom">
            <MyDrawerButton
              type="red"
              className="approve-btn"
              onClick={() => {
                onDone();
              }}
              disabled={
                tax === "" ||
                tax === null ||
                feastttFeeInput === "" ||
                feastttFeeInput === null
              }
            >
              <b>Save</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </SetTaxesAndFeeModalContainer>
  );
};

export default SetTaxesAndFeeModal;
