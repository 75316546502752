import React from 'react';
import PropTypes from 'prop-types';
import { AuthLayoutContainer } from './style';

export function AuthLayout({ children }) {
  return (
    <AuthLayoutContainer>
      <div className='left-side'>
        <div className='auth-control-container'>{children}</div>
      </div>
      <div className='right-side' />
    </AuthLayoutContainer>
  );
}

AuthLayout.propTypes = {
  onBackIconClick: PropTypes.func,
  children: PropTypes.any,
};
