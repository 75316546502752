import React from "react";
import { DashboardHeaderContainer } from "./style";

import { dayTitles, monthTitles } from "static/staticData";

export const DashboardHeader = () => {
  const date = new Date();

  return (
    <DashboardHeaderContainer>
      <div className="left-part">
        <h5 className="dashboard-title">Dashboard</h5>
      </div>
      <div className="right-part">
        <span className="dashboard-date-day-of-week">
          {
            [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ][new Date().getDay()]
          }
        </span>
        <span className="dashboard-date-month-and-year">
          {monthTitles[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
        </span>
      </div>
    </DashboardHeaderContainer>
  );
};
