import styled from "@emotion/styled";
import { colors } from "theme";

export const CreatePasswordPageContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: ${colors.gray1};
  .create-password-left,
  .create-password-right {
    min-width: 50%;
    .create-password-form {
      width: 350px;
      .create-password-logo {
        margin-bottom: 70px;
      }
      .icon-create-password {
        font-size: 40px;
        margin-bottom: 15px;
      }
      .create-password-title {
        margin-bottom: 62px;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
      }
      .create-password-content {
        margin: 10px 0 0 0;
        width: 100%;

        .auth-input-container {
          margin-bottom: 35px;
        }
        .password-conditions {
          margin-bottom: 30px;
          .password-condition {
            color: white;
            opacity: 0.7;
            font-size: 14px;
            margin-bottom: 16px;
            letter-spacing: -0.34px;

            i {
              font-size: 14px;
              margin-right: 10px;
            }

            &.active {
              opacity: 1;

              i {
                color: #1fc086;
              }
            }
          }
        }
      }
      .forgot-password {
        margin-top: 31px;
        margin-bottom: 20px;

        .restore-pass-text {
          opacity: 0.5;
          font-size: 13px;

        } 
        .restore-pass {
          color:#ed2626;
          font-weight: bold;
          margin-left: 7px;
          cursor: pointer;
          font-size: 13px;

          i{
            color:#ed2626;
          }
        }
      }
    }
  }
`;
