import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const ActiveOederActionsModalContainer = styled(MyDrawerCenterModal)`
  .active-order-actions {
    .inactive-action-row {
      opacity: 0.5;
      border-radius: 16px;
      border: solid 1px #5b5e61;
      background-color: #27282a;
      font-size: 14px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
      margin-top: 16px;
      cursor: not-allowed;
      &:hover {
        border: solid 1px #5b5e61;
      }
    }
    .clickable-row {
      border-radius: 16px;
      border: solid 1px #5b5e61;
      background-color: #27282a;
      font-size: 14px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
      margin-top: 16px;
      cursor: pointer;
      &:hover {
        border: solid 1px ${colors.redColor};
      }
    }
    .active-order-action-cancel {
      width: 100%;
    }
  }
`;
