import React, { useRef, useState, useEffect } from "react";
import { MyLocationFilterContainer } from "./style";
import { useOutsideClick } from "services/functions";

export default function MyLocationFilter({
  className = "",
  label = "",
  setValue,
  value,
  close,
}) {
  const locationDropdownRef = useRef();
  const [states, setStates] = useState([
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const addLocationHandler = (location) => {
    let data = value.filter((el) => el.name === location.name);
    if (data.length === 0) {
      let tempData = [...value];
      tempData.push(location);
      setValue(tempData);
    }
  };

  useOutsideClick(locationDropdownRef, () => {
    setIsDropDownOpen(false);
  });

  const deleteSelectedLocation = (location) => {
    let data = [...value];
    data = data.filter((el) => el.name !== location.name);
    setValue(data);
  };

  useEffect(() => {
    if (close && isDropDownOpen) {
      setIsDropDownOpen(false);
    }
  }, [close]);

  return (
    <MyLocationFilterContainer className={className} ref={locationDropdownRef}>
      <div className="label-div">
        {label}{" "}
        {value && value.length ? (
          <i
            className="fas fa-times"
            style={{
              color: "#ed2626",
              fontSize: "14px",
              lineHeight: "1.29",
              cursor: "pointer",
            }}
            onClick={() => {
              setValue([]);
              setIsDropDownOpen(false);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="state-input">
        {value.length ? (
          <div className="state-name-render-div">
            {value.map((el) => {
              return (
                <div className="state-abbr-name">
                  {el?.abbreviation}{" "}
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      deleteSelectedLocation(el);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="select-text">Select State</div>
        )}
        <div>
          {isDropDownOpen ? (
            <i
              className="fas fa-angle-up"
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <i
              className="fas fa-angle-down"
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      {isDropDownOpen ? (
        <div className="states-list-div">
          {states.map((el, index) => {
            return (
              <div
                className="state-name-div"
                onClick={() => {
                  addLocationHandler(el);
                }}
                key={index}
              >
                <div className="name-container">
                  {value.some((e) => e.name === el.name) ? (
                    <div className="selected">
                      <i className="fas fa-check" />
                    </div>
                  ) : (
                    <div className="unselected"></div>
                  )}

                  <div className="state-name"> {el?.name}</div>
                </div>
                <div className="state-abbreviation">{el?.abbreviation}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </MyLocationFilterContainer>
  );
}
