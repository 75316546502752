import styled from '@emotion/styled';

export const SingleVideoUploaderContainer = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};

  .video-uploader {
    height: 100%;
    width: 100%;
    height: 100%;
    border: unset;
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 0;

    .video {
      cursor: pointer;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      &.circle {
        border-radius: 50%;
      }

      &.square {
        border-radius: 0;
      }

      &:hover {
        .video-hover {
          opacity: 1;
        }
      }

      .file-video-input {
        display: none;
      }

      .video-thumb {
        width: 100%;
        height: 100%;

        &.circle {
          border-radius: 50%;
        }

        &.square {
          border-radius: 0;
        }
      }

      .uploading-icon {
        position: absolute;
        top: 42%;
      }

      .video-hover {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 42px;
        transition: 0.5s ease;
        opacity: 1;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
        cursor: pointer;

        .change-container {
          display: flex;
          justify-content: center;
          align-items: center;

          .change-text {
            font-family: 'SFProText';
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.38px;
            color: white;
          }
        }
      }
    }
  }
`;
