import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerItemTypeContainer = styled(MyDrawerRight)`
  .drawer-bottom {
    padding-top: 33px;
    .drawer-bottom-content {
      .top-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 29px;
        .title {
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }
`;

export const DrawerChooseItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  height: 62px;
  padding: 0 26px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  transition: ease 0.3s;
  &.active,
  &:hover {
    border: solid 1px #ffffff;
  }
  &.inactive {
    border: solid 1px rgba(255, 255, 255, 0.1);
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    &.choose-subtitle {
      opacity: 0.5;
    }
  }
`;
