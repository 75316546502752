import { DefaultMenu } from "assets/img";
// import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { drawersOverall, notes } from "static/staticData";
import { DrawerNotesContainer } from "./style";
import emptyImg from "../../../../../assets/img/notes-empty-image.png";
import { FaChevronLeft } from "react-icons/fa";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import moment from "moment";
import DrawerDeleteNotes from "../DrawerDeleteNotes";
import defaultImage from "../../../../../assets/img/default-food.jpg";
import { DrawerContainer } from "components/styledStyle";
import { Image } from "react-bootstrap";
// import Checkbox from "@material-ui/core/Checkbox";
import DefaultUser from "../../../../../assets/img/noAvatar.png";
import NotesEmptyScreen from "assets/img/no-filter-data.png";

export default function DrawerNotes({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();

  const {
    postRestaurant,
    openDrawers,
    closeDrawer,
    openDrawer,
    setNoteId,
    notesData,
    setNotesData,
    restaurant,
    setRestaurant,
  } = useContext(RestaurantsContext);

  const [noteInput, setNoteInput] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isActive) {
      getNotesData();
      getRestaurantDetail();
    }
    // console.log(JSON.stringify(postRestaurant))
  }, [id, postRestaurant, isActive]);

  function getNotesData() {
    customAxios
      .get(`/restaurant/${id}/notes`)
      .then((res) => {
        setNotesData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getRestaurantDetail() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setRestaurant(res?.data?.restaurant);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleDeleteNotes(notesId) {
    setNoteId(notesId);
    openDrawer(drawersOverall.deleteNotes);
  }

  // console.log(restaurant)

  useEffect(() => {
    if (!isActive) {
      setNoteInput("");
    }
  }, [isActive]);

  function handleSubmilForm(event) {
    event.preventDefault();
    setNoteInput("");
    setIsChecked(false);
    const notesPayload = {
      note: noteInput,
      active:
        isChecked && restaurant.status === 1
          ? false
          : isChecked && restaurant.status === 0
          ? true
          : null,
    };
    customAxios
      .post(`/restaurant/${id}/note`, notesPayload)
      .then((res) => {
        getNotesData();
        getRestaurantDetail();
        closePanel();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const statusRenderer = (status) => {
    switch (status) {
      case 11:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Banned
          </h6>
        );
      case 12:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Unbanned
          </h6>
        );
      case 2:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Changed to Active
          </h6>
        );
      case 1:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Changed to Inactive
          </h6>
        );
      case 0:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Deleted
          </h6>
        );
      case 3:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Email Verified
          </h6>
        );
      case 4:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Email Unverified
          </h6>
        );
      case 5:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Phone Verified
          </h6>
        );
      case 6:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Phone Unverified
          </h6>
        );
      case 7:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Website Verified
          </h6>
        );
      case 8:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Website Unverified
          </h6>
        );
      case 9:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Request Approved
          </h6>
        );
      case 10:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Request Declined
          </h6>
        );
      default:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Status not changed
          </h6>
        );
    }
  };

  return (
    <DrawerNotesContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <div className="drawer-bottom-content w-100">
          <div className="header-bg">
            <div className="d-flex  align-items-center close-drawer-icon">
              <FaChevronLeft
                size={20}
                style={{ zIndex: "9999999" }}
                className="cursor-pointer "
                onClick={closePanel}
              />
              <div className="drawer-title">Notes</div>
            </div>
            <Image
              src={
                postRestaurant?.photo?.file
                  ? postRestaurant?.photo?.file
                  : DefaultUser
              }
              className="bg-img"
            />
          </div>

          <div className="panel-status-container">
            <div className="profile-line"></div>
            <div className="upper-part">
              <img
                className="company-logo"
                src={
                  postRestaurant?.photo?.file
                    ? postRestaurant?.photo?.file
                    : DefaultUser
                }
                alt="user-avatar"
              />
              <p className="company-name">{postRestaurant?.title}</p>
            </div>

            {notesData?.length > 0 ? (
              <div className="notes-count">
                <b>{notesData?.length}</b> notes
              </div>
            ) : null}

            <div className="notes-list">
              {notesData?.length ? (
                <div style={{ marginBottom: 50 }}>
                  {notesData.map((note, _i) => {
                    return (
                      <div key={_i} className="note-container-item">
                        <div className="note-image-block">
                          <img
                            src={note?.image ? note?.image : DefaultUser}
                            alt="avatar"
                          />
                        </div>
                        <div className="note-content-block">
                          <p className="commentator-name">{note?.admin_name}</p>
                          {statusRenderer(note?.action)}
                          <p className="commentator-mail">
                            {note?.note ? note?.note : "-"}
                          </p>
                        </div>
                        <div className="note-end-block">
                          <p className="date-note">
                            {moment(note.createdat).format("MMM DD, YYYY")}
                          </p>
                          <div
                            className="delete-note"
                            onClick={() => handleDeleteNotes(note?.id)}
                          >
                            <i className="fas fa-trash-alt" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="no-note-data">
                  <img
                    className="no-notes-img"
                    src={NotesEmptyScreen}
                    alt="empty"
                  />
                  <p className="no-notes-message">No notes yet.</p>
                </div>
              )}
            </div>
          </div>

          <div className="form-section">
            <form onSubmit={handleSubmilForm}>
              <div className="notes-abuse-warning">
                <textarea
                  name="postContent"
                  rows={4}
                  cols={40}
                  placeholder="Write note here..."
                  value={noteInput}
                  onChange={(event) => setNoteInput(event.target.value)}
                />

                <div className="note-submit-btn">
                  {noteInput !== "" ? (
                    <button
                      type="submit"
                      style={{ background: "transparent", border: 0 }}
                    >
                      <i className="fas fa-share" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        background: "transparent",
                        border: 0,
                        opacity: "0.5",
                      }}
                      disabled
                    >
                      <i className="fas fa-share" />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </DrawerContainer>

      <DrawerDeleteNotes
        isActive={!!openDrawers[drawersOverall.deleteNotes]}
        closePanel={() => closeDrawer(drawersOverall.deleteNotes)}
      />
    </DrawerNotesContainer>
  );
}
