import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useState, useContext, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { drawersStaff, mocks } from "static/staticData";
import { DrawerFilterStaffContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { restaurantServices } from "services";
import { useParams } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";

const stausMocks = {
  statuses: {
    0: {
      value: false,
      title: "Active",
    },
    1: {
      value: true,
      title: "Inactive",
    },
    2: {
      value: false,
      title: "Pending",
    },
    3: {
      value: true,
      title: "Banned",
    },
  },

  role: {
    0: {
      value: false,
      title: "Owner",
    },
    1: {
      value: true,
      title: "Manager",
    },
    2: {
      value: false,
      title: "Kitchen Staff",
    },
    3: {
      value: true,
      title: "Server / Host",
    },
  },

  statusesArchived: {
    0: {
      value: false,
      title: "Active",
    },
    1: {
      value: true,
      title: "Inactive",
    },
    2: {
      value: false,
      title: "Banned",
    },
    3: {
      value: true,
      title: "Deleted",
    },
  },

  roleArchived: {
    0: {
      value: true,
      title: "Manager",
    },
    1: {
      value: false,
      title: "Kitchen Staff",
    },
    2: {
      value: true,
      title: "Server / Host",
    },
  },
};

export default function DrawerFilterStaff({
  profile = {},
  isActive = false,
  mode,
  closePanel = () => {},
}) {
  const { id } = useParams();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    postStaff,
    setPostStaff,
    setStaffs,
    isActiveStaffFilter,
    setActiveStaffFilter,

    staffRoleSlugFilter,
    setStaffRoleSlugFilter,

    staffStatusFilter,
    setStaffStatusFilter,

    roleTitle,
    setRoleTitle,

    statusTitle,
    setStatusTitle,
  } = useContext(RestaurantsContext);

  // const [roleTitle, setRoleTitle] = useState([]);
  // const [statusTitle, setStatusTitle] = useState([]);

  const [isOwnerRole, setOwnerRole] = useState(false);

  const getStaffAll = async () => {
    customAxios
      .get(
        `/staff?restaurant_id=${id}&page=1&page_size=10&role_slug=${staffRoleSlugFilter}&status=${staffStatusFilter}&is_owner=${isOwnerRole}`
      )
      .then((res) => {
        if (res.status === 200) {
          setStaffs([
            ...res?.data?.active_staff,
            // ...res?.data?.pending_staff,
          ]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Status filter
  const onChangeStatus = (status) => {
    // setStatusTitle(status);

    // if (statusTitle.indexOf(status) > -1) {
    //   statusTitle.length > 0 || (statusTitle !== "undefined" && statusTitle.filter((el) => el !== status));
    //   // setStatusTitle((pre) => )
    // } else {
    //   setStatusTitle((prev) => [...prev, status]);
    // }

    if (statusTitle.includes(status)) {
      setStatusTitle(statusTitle.filter((el) => el !== status));
    } else {
      setStatusTitle((prev) => [...prev, status]);
    }

    if (status === "Inactive") {
      if (staffStatusFilter.indexOf(0) > -1) {
        setStaffStatusFilter((prev) => prev.filter((el) => el !== 0));
      } else {
        setStaffStatusFilter((prev) => [...prev, 0]);
      }
      // setStaffStatusFilter(0);
    }
    if (status === "Active") {
      if (staffStatusFilter.indexOf(1) > -1) {
        setStaffStatusFilter((prev) => prev.filter((el) => el !== 1));
      } else {
        setStaffStatusFilter((prev) => [...prev, 1]);
      }
      // setStaffStatusFilter(1);
    }
    if (status === "Pending") {
      if (staffStatusFilter.indexOf(2) > -1) {
        setStaffStatusFilter((prev) => prev.filter((el) => el !== 2));
      } else {
        setStaffStatusFilter((prev) => [...prev, 2]);
      }
      // setStaffStatusFilter(2);
    }
    if (status === "Banned") {
      if (staffStatusFilter.indexOf(9) > -1) {
        setStaffStatusFilter((prev) => prev.filter((el) => el !== 9));
      } else {
        setStaffStatusFilter((prev) => [...prev, 9]);
      }
      // setStaffStatusFilter(9);
    }
    if (status === "Deleted") {
      if (staffStatusFilter.indexOf(-1) > -1) {
        setStaffStatusFilter((prev) => prev.filter((el) => el !== -1));
      } else {
        setStaffStatusFilter((prev) => [...prev, -1]);
      }
      // setStaffStatusFilter(-1);
    }
    setActiveStaffFilter(true);
  };

  // Role filter
  const onChangeRole = (role) => {
    // if (roleTitle.indexOf(role) > -1) {
    //   roleTitle.length > 0 ||
    //     (roleTitle !== "undefined" && roleTitle.filter((el) => el !== role));
    // } else {
    //   setRoleTitle((prev) => [...prev, role]);
    // }

    if (roleTitle.includes(role)) {
      setRoleTitle(roleTitle.filter((el) => el !== role));
    } else {
      setRoleTitle((prev) => [...prev, role]);
    }

    if (role === "Owner") {
      // setStaffRoleSlugFilter("");
      if (staffRoleSlugFilter.indexOf("") > -1) {
        setStaffRoleSlugFilter(
          (prev) => prev && prev.filter((el) => el !== "")
        );
      } else {
        setStaffRoleSlugFilter((prev) => [...prev, ""]);
      }
      setOwnerRole(!isOwnerRole);
    }

    if (role === "Manager") {
      // setStaffRoleSlugFilter("manager");
      if (staffRoleSlugFilter.indexOf("manager") > -1) {
        setStaffRoleSlugFilter((prev) => prev.filter((el) => el !== "manager"));
      } else {
        setStaffRoleSlugFilter((prev) => [...prev, "manager"]);
      }
      setOwnerRole(isOwnerRole);
    }

    if (role === "Kitchen Staff") {
      // setStaffRoleSlugFilter("kitchen_staff");
      if (staffRoleSlugFilter.indexOf("kitchen_staff") > -1) {
        setStaffRoleSlugFilter((prev) =>
          prev.filter((el) => el !== "kitchen_staff")
        );
      } else {
        setStaffRoleSlugFilter((prev) => [...prev, "kitchen_staff"]);
      }
      setOwnerRole(isOwnerRole);
    }

    if (role === "Server / Host") {
      // setStaffRoleSlugFilter("waiter");
      if (staffRoleSlugFilter.indexOf("waiter") > -1) {
        setStaffRoleSlugFilter((prev) => prev.filter((el) => el !== "waiter"));
      } else {
        setStaffRoleSlugFilter((prev) => [...prev, "waiter"]);
      }
      setOwnerRole(isOwnerRole);
    }
    setActiveStaffFilter(true);
  };

  function onClickApply() {
    getStaffAll().then(() => closePanel());
  }

  function resetAll() {
    setStaffRoleSlugFilter("");
    setStaffStatusFilter("");
    setActiveStaffFilter(false);
    setRoleTitle([]);
    setStatusTitle([]);
    setOwnerRole(false);
    customAxios
      .get(`/staff?restaurant_id=${id}&page=1&page_size=10`)
      .then((res) => {
        setStaffs([
          ...res?.data?.active_staff,
          // ...res?.data?.pending_staff,
        ]);
      })
      .catch((err) => {
        console.error(err);
      });
    closePanel();
  }

  return (
    <DrawerFilterStaffContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Filter</div>
          <div className="reset-all" onClick={resetAll}>
            Reset All
          </div>
        </div>
        <div className="drawer-status--options mb-auto">
          <div className="text-bold mb-4">Status</div>
          {mode === "all"
            ? Object.values(stausMocks.statuses).map((_status, _i) => (
                <DrawerChooseItem
                  key={_i}
                  className="mb-4"
                  title={_status.title}
                  active={statusTitle.indexOf(_status.title) > -1}
                  onChooseItem={() => onChangeStatus(_status.title)}
                />
              ))
            : Object.values(stausMocks.statusesArchived).map((_status, _i) => (
                <DrawerChooseItem
                  key={_i}
                  className="mb-4"
                  title={_status.title}
                  active={statusTitle.indexOf(_status.title) > -1}
                  onChooseItem={() => onChangeStatus(_status.title)}
                />
              ))}

          <div className="text-bold mb-4 role-title">Role</div>
          {mode === "archived"
            ? Object.values(stausMocks.roleArchived).map((_role, _i) => {
                return (
                  <DrawerChooseItem
                    key={_i}
                    className="mb-4"
                    title={_role.title}
                    // active={roleTitle === _role.title}
                    active={roleTitle && roleTitle.indexOf(_role.title) > -1}
                    onChooseItem={() => onChangeRole(_role.title)}
                  />
                );
              })
            : Object.values(stausMocks.role).map((_role, _i) => {
                return (
                  <DrawerChooseItem
                    key={_i}
                    className="mb-4"
                    title={_role.title}
                    active={roleTitle && roleTitle.indexOf(_role.title) > -1}
                    onChooseItem={() => onChangeRole(_role.title)}
                  />
                );
              })}
        </div>
        <MyDrawerButton
          type={
            staffRoleSlugFilter ||
            staffStatusFilter ||
            staffStatusFilter === 0 ||
            isOwnerRole
              ? "red"
              : "gray"
          }
          className="save-btn text-bold"
          // onClick={closePanel}
          onClick={onClickApply}
        >
          Apply
        </MyDrawerButton>
        <div className="cancel-btn" onClick={closePanel}>
          Cancel
        </div>
      </DrawerContainer>
    </DrawerFilterStaffContainer>
  );
}
