import React from "react";
import PropTypes from "prop-types";
import { CardInputContainer } from "./style";

export function CardInput({
  className = "",
  htmlType = "text",
  label,
  value,
  formatToReadableValue = (initial) => initial,
  convertVisibleValueToRealValue = (transformed) => transformed,
  onChange = () => {},
  isValid,
  isTouched,
  placeholder,
}) {
  return (
    <CardInputContainer className={className}>
      <div className="card-input-wrapper d-flex">
        <div
          className={`card-input-icon ${
            isTouched ? (isValid ? "valid" : "error") : "empty"
          }`}
          style={{ marginTop: "20px", marginRight: "10px" }}
        >
          {!isTouched || isValid ? (
            <i className="fas fa-check-circle" style={isValid ? {color: 'rgb(30, 206, 109)', fontSize: '20px'} : {color: "black", fontSize: '20px'}}/>
          ) : (
            <i className="fas fa-times-circle" style={isTouched && {color: "#ed2626", fontSize: '20px'}} />
          )}
        </div>
        <div style={{ width: "100%" }}>
          <label className="card-input-label">{label}</label>
          <div className="card-input-container">
            <input
              type={htmlType}
              className="card-input-view"
              value={formatToReadableValue(value)}
              onChange={(event) =>
                onChange(convertVisibleValueToRealValue(event.target.value))
              }
              placeholder={placeholder}
              onClick={(event) => selectTextByClick(event.target)}
            />
          </div>
        </div>
      </div>
    </CardInputContainer>
  );
}

export const selectTextByClick = (target) => {
  if (target.value.length) {
    target.setSelectionRange(0, target.value.length);
  }
};
