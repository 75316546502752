import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerFilterStaffContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 41px;
    .fa-trash {
      color: #ed2626;
      font-size: 20px;
      margin-top: 6px;
    }
    .title {
      font-size: 22px;
      font-weight: 500;
      margin-left: 3rem;
    }
    .reset-all {
      font-size: 14px;
      color: ${colors.redColor};
      font-weight: 600;
      cursor: pointer;
    }
  }

  .drawer-status--options {
    .role-title {
      margin-top: 30px;
    }
  }

  .cancel-btn {
    text-align: center;
    color: ${colors.redColor};
    font-weight: 700;
    margin-top: 30px;
    cursor: pointer;
  }
`;
