import React, { Component } from "react";
import { DefaultFood } from "assets/img";
import { FaCandyCane, FaLeaf } from "react-icons/fa";

export const dayTitles = [
  { key: "Mon", content: "Monday", num: 0 },
  { key: "Tue", content: "Tuesday", num: 1 },
  { key: "Wed", content: "Wednesday", num: 2 },
  { key: "Thu", content: "Thursday", num: 3 },
  { key: "Fri", content: "Friday", num: 4 },
  { key: "Sat", content: "Saturday", num: 5 },
  { key: "Sun", content: "Sunday", num: 6 },
];

export const monthTitles = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// export const drawerDashboard = {
//   dashboardItem: "dashboardItem",
// }

export const drawersOrder = {
  filterOrders: "filterOrders",
  dateCustomer: "dateCustomer",
  reviewServer: "reviewServer",
  deleteReview: "deleteReview",
  deletedReview: "deletedReview",
  fullRefund: "fullRefund",
  partialRefund: "partialRefund",
  removeOrder: "removeOrder",
  applyDiscount: "applyDiscount",
  discountConfirm: "discountConfirm",
  deleteDiscount: "deleteDiscount",
  deletedOrderDiscount: "deletedOrderDiscount",
  addItem: "addItem",
  foodNeutritions: "foodNeutritions",
  foodItemReviews: "foodItemReviews",
  sortFoodReview: "sortFoodReview",
  foodCustomization: "foodCustomization",
  guestsCheckout: "guestsCheckout",
  lastGuestCheckout: "lastGuestCheckout",
  processPayment: "processPayment",
  orderFoodItemReview: "orderFoodItemReview",
  deleteItemReview: "deleteItemReview",
  deleteOrderItem: "deleteOrderItem",
  deletedOrderItemMessage: "deletedOrderItemMessage",
  deleteSubOrder: "deleteSubOrder",
  deleteSubOrderConfirmation: "deleteSubOrderConfirmation",
  deleteSubOrderMessage: "deleteSubOrderMessage",
  deleteOrder: "deleteOrder",
  deleteOrderConfirmation: "deleteOrderConfirmation",
  deletedOrderMessage: "deletedOrderMessage",
  editItem: "editItem",
  processExistingCardPayment: "processExistingCardPayment",
  addTip: "addTip",
  cardDetailsForm: "cardDetailsForm",
  noPaymentCashConfirmation: "noPaymentCashConfirmation",
  setOpenOrderModal: "setOpenOrderModal",
  setOpenPaidOrderModal: "setOpenPaidOrderModal",
  removeAddItem: "removeAddItem",
  removeReviewModalScreen: "removeReviewModalScreen",
  RestoreReviewModalScreen: "RestoreReviewModalScreen",
};
export const drawersRestaurants = {
  filterRestaurants: "filterRestaurants",
  setAllActive: "setAllActive",
  setAllInactive: "setAllInactive",
  drawerRestaurantDeleteScreen: "drawerRestaurantDeleteScreen",
  drawerRestaurantDeleteConfirmationScreen:
    "drawerRestaurantDeleteConfirmationScreen",

  addPromoCodeModal: "addPromoCodeModal",
  setPromoCode: "setPromoCode",
  setDiscount: "setDiscount",
  totalUseTimes: "totalUseTimes",
  useTimesPerCustomer: "useTimesPerCustomer",
  promoCodeDate: "promoCodeDate",
  viewAll: "viewAll",
  promoStatusModal: "promoStatusModal",
  setActiveModal: "setActiveModal",
  setInactiveModal: "setInactiveModal",
};
export const drawersCustomers = {
  filterCustomers: "filterCustomers",
  statusCustomers: "statusCustomers",
  deleteCustomer: "deleteCustomer",
  deleteCustomerConfirmationScreen: "deleteCustomerConfirmationScreen",
  joinedDate: "joinedDate",
  editCustomer: "editCustomer",
  setAllInactive: "setAllInactive",
  setAllActive: "setAllActive",
  addAddress: "addAddress",
  foundAddress: "foundAddress",
  editAddress: "editAddress",
  editSearchAddress: "editSearchAddress",
  deleteLocation: "deleteLocation",
};
export const drawersMenu = {
  menus: "menus",
  menuAdd: "menuAdd",
  menuEdit: "menuEdit",
  availabilities: "availabilities",
  availableDay: "availableDay",
  statusMenu: "statusMenu",
  statusFood: "statusFood",
  assignToMenu: "assignToMenu",
  deleteMenu: "deleteMenu",
  deleteConfirm: "deleteConfirm",
  moveItems: "moveItems",
  categories: "categories",
  categoryAdd: "categoryAdd",
  categoryEdit: "categoryEdit",
  itemAdd: "itemAdd",
  itemEdit: "itemEdit",
  itemDelete: "itemDelete",
  foods: "foods",
  itemType: "itemType",
  addType: "addType",
  dietaryTags: "dietaryTags",
  nutritions: "nutritions",
  customizations: "customizations",
  floor: "floor",
  floorAdd: "floorAdd",
  floorEdit: "floorEdit",
  deleteFloor: "deleteFloor",
  drawElement: "drawElement",
  addTable: "addTable",
  editTable: "editTable",
  deleteTableSide: "deleteTableSide",
  deleteTable: "deleteTable",
  statusTable: "statusTable",
  addWall: "addWall",
  editWall: "editWall",
  deleteWallSide: "deleteWallSideP",
  deleteWall: "deleteWall",

  openDrawerCategoryFirstOptions: "openDrawerCategoryFirstOptions",
  openDrawerDeleteMenuAndItem: "openDrawerDeleteMenuAndItem",
  openDrawerCategoryMoveItem: "openDrawerCategoryMoveItem",

  openDrawerAddCustomization: "openDrawerAddCustomization",
  openDrawerAddCustomizationOption: "openDrawerAddCustomizationOption",
  openDrawerEditCustomization: "openDrawerEditCustomization",
  openDrawerEditCustomizationOption: "openDrawerEditCustomizationOption",

  openDrawerDeleteFood: "openDrawerDeleteFood",

  openDrawerCheckExistsMenu: "openDrawerCheckExistsMenu",

  addItemModal: "addItemModal",
  hideNutrition: "hideNutrition",
};
export const drawersOverall = {
  editProfile: "editProfile",
  statusOverall: "statusOverall",
  verifyPhone: "verifyPhone",
  verifyEmail: "verifyEmail",
  verifyWebsite: "verifyWebsite",
  priceTier: "priceTier",
  cuisines: "cuisines",
  businessHours: "businessHours",
  availableDay: "availableDay",
  closedDays: "closedDays",
  addClosedDay: "addClosedDay",
  notes: "notes",
  address: "editAddress",
  payment: "editPayment",
  deleteCustomer: "deleteCustomer",
  statusConfirm: "statusConfirm",
  addManager: "addManager",
  foundAddress: "foundAddress",
  dashboardDrawer: "dashboardDrawer",
  locations: "locations",
  deleteNotes: "deleteNotes",
  dashboardNutritions: "dashboardNutritions",
  dashboardReviews: "dashboardReviews",
  dashboardReviewSort: "dashboardReviewSort",

  addCuisines: "addCuisines",
  deleteCuisines: "deleteCuisines",
  deleteConfirmCuisines: "deleteConfirmCuisines",
  rearrangeCuisine: "rearrangeCuisine",

  customerReviewsDelete: "customerReviewsDelete",
  customerRestoreReviews: "customerRestoreReviews",

  paymentCard: "paymentCard",
  deletePayment: "deletePayment",

  leavePage: "leavePage",

  itemRemoveReviewModalScreen: "itemRemoveReviewModalScreen",
  itemRestoreReviewModalScreen: "itemRestoreReviewModalScreen",

  drawerItemCustomization: "drawerItemCustomization",

  oopsErrorModal: "oopsErrorModal",
};
export const drawersStaff = {
  staffAdd: "staffAdd",
  staffEdit: "staffEdit",
  staffRole: "staffRole",
  staffStatus: "staffStatus",
  staffReviews: "staffReviews",
  staffInvite: "staffInvite",

  staffChooseRole: "staffChooseRole",
  staffRemove: "staffRemove",
  staffRemoveConfirm: "staffRemoveConfirm",
  staffInviteSend: "staffInviteSend",

  staffDelete: "staffDelete",
  staffDeleteConfirm: "staffDeleteConfirm",

  staffStatusActive: "staffStatusActive",
  staffStatusInactive: "staffStatusInactive",

  staffEmailStatus: "staffEmailStatus",

  staffFilterDrawer: "staffFilterDrawer",

  staffFilterArchivedDrawer: "staffFilterArchivedDrawer",

  staffReviewRemove: "staffReviewRemove",

  staffReviewRestore: "staffReviewRestore",

  staffServerHostActiveModal: "staffServerHostActiveModal",
  ChangeRoleToKitchenStaffModal: "ChangeRoleToKitchenStaffModal",
  ChangeRoleToManagerModal: "ChangeRoleToManagerModal",
  StaffStatusInactiveModalForm: "StaffStatusInactiveModalForm",
  StaffStatusInactiveGlobalModalForm: "StaffStatusInactiveGlobalModalForm",
  removeFromRestaurant: "removeFromRestaurant", 
  removeConfirmFromRestaurant: "removeConfirmFromRestaurant",

  archivedMainOption: "archivedMainOption",
  archivedStatusGlobal: "archivedStatusGlobal",

  staffDrawerFilter: "staffDrawerFilter",
  staffDrawerActive: "staffDrawerActive",
  staffDrawerInactive: "staffDrawerInactive",
  InactiveStaffDrawerFilter: "InactiveStaffDrawerFilter",
  BannedStaffDrawerFilter: "BannedStaffDrawerFilter",
  DeletedStaffDrawerFilter: "DeletedStaffDrawerFilter",
};
export const drawersMyAccount = {
  changePassword: "changePassword",
  changeEmail: "changeEmail",
  emailSuccess: "emailSuccess",
  logout: "logout",
  isRestorePassword: "isRestorePassword",
  restorePassword: "restorePassword",
  phoneNumber: "phoneNumber",
};

export const cuisines = [
  { id: 1, name: "American", photo: DefaultFood },
  { id: 2, name: "Healthy", photo: DefaultFood },
  { id: 3, name: "Burgers", photo: DefaultFood },
  { id: 4, name: "Pizza", photo: DefaultFood },
  { id: 5, name: "Chinese", photo: DefaultFood },
  { id: 6, name: "French", photo: DefaultFood },
  { id: 7, name: "Mexican", photo: DefaultFood },
  { id: 8, name: "Greek", photo: DefaultFood },
  { id: 9, name: "Thai", photo: DefaultFood },
  { id: 10, name: "Sushi", photo: DefaultFood },
  { id: 11, name: "Indian", photo: DefaultFood },
  { id: 12, name: "Dessert", photo: DefaultFood },
];

export const notes = [
  // {
  //   id: 1,
  //   img: "",
  //   name: "-",
  //   status: "-",
  //   mailVerified: "-",
  //   date: "-",
  // },
  // {
  //   id: 2,
  //   img: "",
  //   name: "-",
  //   status: "-",
  //   mailVerified: "",
  //   date: "-",
  // },
  // {
  //   id: 3,
  //   img: "",
  //   name: "-",
  //   status: "",
  //   mailVerified: "-",
  //   date: "-",
  // },
];

export const mocks = {
  kinds: {
    "0": {
      value: 0,
      title: "Food",
    },
    "1": {
      value: 1,
      title: "Drink",
    },
    "2": {
      value: 2,
      title: "Other",
    },
  },
  statuses: {
    "0": {
      value: 0,
      title: "Inactive ",
      subtitle: "(Not visible to customers)",
    },
    "1": {
      value: 1,
      title: "Active",
    },
  },
  dietaryTags: {
    "0": {
      value: 0,
      title: "Vegetarian",
    },
    "1": {
      value: 1,
      title: "Vegan",
    },
    "2": {
      value: 2,
      title: "Gluten-Free",
    },
    "3": {
      value: 3,
      title: "Dairy-Free",
    },
    "4": {
      value: 4,
      title: "Sugar-Free",
    },
  },
  dietaryTagsMenus: [
    { value: 0, name: "Vegetarian", icon: <FaLeaf color="white" size={14} /> },
    {
      value: 1,
      name: "Vegan",
      icon: <i className="fas fa-leaf-heart text-white" />,
    },
    {
      value: 2,
      name: "Gluten-Free",
      icon: <i className="fas fa-wheat text-white" />,
    },
    {
      value: 3,
      name: "Dairy-Free",
      icon: <i className="fas fa-cow text-white"></i>,
    },
    {
      value: 4,
      name: "Sugar-Free",
      icon: <FaCandyCane color="white" size={14} />,
    },
  ],
  staffRoles: {
    manager: {
      value: "manager",
      title: "Manager / Owner",
      subTitle: "Full admin access to management tools.",
    },
    kitchen_staff: {
      value: "kitchen_staff",
      title: "Kitchen Staff",
      subTitle: "Access to manage menu and view orders.",
    },
    waiter: {
      value: "waiter",
      title: "Server / Host",
      subTitle: "View orders and check in customers.",
    },
  },
};

export const nutritions = {
  calories: {
    title: "Calories",
    value: null,
    percentage: 17,
    weight_type: null,
    percentages: 210,
    digit: null,
  },
  total_fat: {
    title: "Total Fat",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: "10%",
    digit: 10,
  },
  sat_fat: {
    title: "Saturated Fat",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: "7%",
    digit: 37,
  },
  trans_fat: {
    title: "Trans Fat",
    value: null,
    percentage: 17,
    weight_type: "mg",
    percentages: "13%",
    digit: 12,
  },
  cholesterol: {
    title: "Cholesterol",
    value: null,
    percentage: 17,
    weight_type: "mg",
    percentages: "0%",
    digit: 0,
  },
  sodium: {
    title: "Sodium",
    value: null,
    percentage: 17,
    weight_type: "mg",
    percentages: "15%",
    digit: 50,
  },
  total_carbs: {
    title: "Total Carbohydrates",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: "15%",
    digit: 75,
  },
  fiber: {
    title: "Dietary Fiber",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: "13%",
    digit: 25,
  },
  sugars: {
    title: "Total Sugars",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: "10%",
    digit: 22,
  },
  added_sugars: {
    title: "Added Sugars",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: "11%",
    digit: 18,
  },
  protein: {
    title: "Protein",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: null,
    digit: 3,
  },
  extras: [
    {
      title: "Vitamin A",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Vitamin B",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Vitamin C",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Vitamin D",
      value: null,
      percentage: 17,
      weight_type: "mcg",
    },
    {
      title: "Vitamin E",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Calcium",
      value: null,
      percentage: 17,
      weight_type: "mcg",
    },
    {
      title: "Iron",
      value: null,
      percentage: 17,
      weight_type: "mg",
    },
    {
      title: "Potassium",
      value: null,
      percentage: 17,
      weight_type: "mg",
    },
  ],
};

export const highestStaffs = {
  today: {
    id: 9,
    first_name: "Michael",
    last_name: "Test",
    email: "williamttes@gmail.com",
    phone_number: "13852201152",
    avatar: {
      id: 2,
      file: "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
      file_name: "user--1603684259721.jpg",
      asset_type: "user",
      createdAt: "2020-10-26T03:51:02.792Z",
      updatedAt: "2020-10-26T03:51:02.792Z",
    },
    role_slug: "manager",
    status: 1,
    restaurant_id: 16,
    rating: 5.5,
  },
  "7day": {
    id: 9,
    first_name: "Will",
    last_name: "Test",
    email: "williamttes@gmail.com",
    phone_number: "13852201152",
    avatar: {
      id: 2,
      file: "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
      file_name: "user--1603684259721.jpg",
      asset_type: "user",
      createdAt: "2020-10-26T03:51:02.792Z",
      updatedAt: "2020-10-26T03:51:02.792Z",
    },
    role_slug: "manager",
    status: 1,
    restaurant_id: 16,
    rating: 5.5,
  },
  "30day": {
    id: 9,
    first_name: "Will",
    last_name: "Test",
    email: "williamttes@gmail.com",
    phone_number: "13852201152",
    avatar: {
      id: 2,
      file: "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
      file_name: "user--1603684259721.jpg",
      asset_type: "user",
      createdAt: "2020-10-26T03:51:02.792Z",
      updatedAt: "2020-10-26T03:51:02.792Z",
    },
    role_slug: "manager",
    status: 1,
    restaurant_id: 16,
    rating: 5.5,
  },
};

export const mockOrders = [
  {
    id: 5,
    token: "47WRBF",
    status: 0,
    started_at: "2020-11-20T01:57:33.906Z",
    total_price: 13.78,
    guests_count: 2,
    items_count: 2,
    restaurant_id: 16,
    restaurant_name: "Catch LA",
    restaurant_photo: {
      id: 2,
      file: "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
      file_name: "user--1603684259721.jpg",
      asset_type: "user",
      createdAt: "2020-10-26T03:51:02.792Z",
      updatedAt: "2020-10-26T03:51:02.792Z",
    },
    items: [
      {
        food_id: 5,
        food_name: "Tomato Basil Soup",
        photo: {
          id: 2,
          file:
            "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
          file_name: "user--1603684259721.jpg",
          asset_type: "user",
          createdAt: "2020-10-26T03:51:02.792Z",
          updatedAt: "2020-10-26T03:51:02.792Z",
        },
        quantity: 2,
      },
    ],
    guests: [
      {
        user_id: 18,
        first_name: "Liang",
        last_name: "Mi",
        avatar: {
          id: 1,
          file:
            "https://assetfoodieave.s3.amazonaws.com/user--1603684113870.jpg",
          file_name: "user--1603684113870.jpg",
          asset_type: "user",
          createdAt: "2020-10-26T03:48:37.132Z",
          updatedAt: "2020-10-26T03:48:37.132Z",
        },
      },
      {
        user_id: 18,
        first_name: "Liang",
        last_name: "Mi",
        avatar: {
          id: 1,
          file:
            "https://assetfoodieave.s3.amazonaws.com/user--1603684113870.jpg",
          file_name: "user--1603684113870.jpg",
          asset_type: "user",
          createdAt: "2020-10-26T03:48:37.132Z",
          updatedAt: "2020-10-26T03:48:37.132Z",
        },
      },
    ],
  },
  {
    id: 7,
    token: "KM3UAL",
    status: 2,
    started_at: "2020-12-11T13:18:23.252Z",
    total_price: 134.08,
    guests_count: 3,
    items_count: 10,
    restaurant_id: 16,
    restaurant_name: "Catch LA",
    restaurant_photo: {
      id: 2,
      file: "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
      file_name: "user--1603684259721.jpg",
      asset_type: "user",
      createdAt: "2020-10-26T03:51:02.792Z",
      updatedAt: "2020-10-26T03:51:02.792Z",
    },
    items: [
      {
        food_id: 3,
        food_name: "Cranberry Apple Pecan Chicken Salad",
        photo: null,
        quantity: 8,
      },
      {
        food_id: 5,
        food_name: "Tomato Basil Soup",
        photo: {
          id: 2,
          file:
            "https://assetfoodieave.s3.amazonaws.com/user--1603684259721.jpg",
          file_name: "user--1603684259721.jpg",
          asset_type: "user",
          createdAt: "2020-10-26T03:51:02.792Z",
          updatedAt: "2020-10-26T03:51:02.792Z",
        },
        quantity: 2,
      },
    ],
    guests: [
      {
        user_id: 18,
        first_name: "Liang",
        last_name: "Mi",
        avatar: {
          id: 1,
          file:
            "https://assetfoodieave.s3.amazonaws.com/user--1603684113870.jpg",
          file_name: "user--1603684113870.jpg",
          asset_type: "user",
          createdAt: "2020-10-26T03:48:37.132Z",
          updatedAt: "2020-10-26T03:48:37.132Z",
        },
      },
      {
        user_id: 19,
        first_name: "",
        last_name: "",
        avatar: null,
      },
      {
        user_id: 20,
        first_name: "",
        last_name: "",
        avatar: null,
      },
    ],
  },
];

export const mockPopularRestaurants = [
  // { name: "Red Robin Gourmet Burgers & Brews", value: 293 },
  // { name: "Catch LA", value: 125 },
  // { name: "Hum Hum", value: 123 },
  // { name: "3B cafe", value: 98 },
  // { name: "Red Lobster", value: 58 },
  // { name: "Tarantini", value: 293 },
  // { name: "Mama Mia", value: 125 },
  // { name: "Il Molino", value: 123 },
  // { name: "Diablo Pizza", value: 98 },
  // { name: "Druzi", value: 58 },
];

export const mockPopularFoods = [
  // { name: "Southwest Egg Rolls", value: "Red Robin Gourmet Burgers & Brews" },
  // { name: "Cranberry Apple Pecan Chicken Salad", value: "Catch LA" },
  // { name: "Carbonara", value: "Hum Hum" },
  // { name: "Bloody Mary", value: "Beef, Burgers & Beer" },
  // { name: "Pancakes", value: "Catch LA" },
  // { name: "Southwest Egg Rolls", value: "Red Robin Gourmet Burgers & Brews" },
  // { name: "Cranberry Apple Pecan Chicken Salad", value: "Catch LA" },
  // { name: "Carbonara", value: "Hum Hum" },
  // { name: "Bloody Mary", value: "Beef, Burgers & Beer" },
  // { name: "Pancakes", value: "Catch LA" },
];

export const showLimits = [
  {
    id: 25,
    name: 25,
  },
  {
    id: 50,
    name: 50,
  },
  {
    id: 100,
    name: 100,
  },
];

export const showSorts = [
  {
    id: "sort",
    name: "Sort",
  },
  {
    id: "asc",
    name: "Asc",
  },
  {
    id: "desc",
    name: "Desc",
  },
];

export const ordersAnalyticsTotalSales = [
  {
    id: 3,
    name: "Total Sales",
  },
  {
    id: 2,
    name: "Average Order",
  },
  {
    id: 1,
    name: "# of Orders",
  },
];
