  import React, { useState, useRef } from "react";
  import PropTypes from "prop-types";
  import { FaCamera } from "react-icons/fa";
  import { MyFileUploaderFormDataContainer } from "./style";
  import { ScaleLoader } from "react-spinners";
  import { assetServices } from "services/assetServices";
  import { DefaultFood } from "assets/img";

  export function MyFileUploaderFormData({
    placeholder,
    width = "100%",
    height = "100%",
    shape = "circle",
    imgSrc = "",
    overlay,
    callBackUpload = () => {},
  }) {
    const inputFileRef = useRef();
    const [uploading] = useState(false);
    const [isHover, setIsHover] = useState(false);

    const [uploadedImage, setUploadedImage] = useState(null);

    const onUpload = async (info) => {
      // console.log(info.target.files[0])
      var postData = new FormData();
      postData.append("asset", info.target.files[0]);
      // postData.append("type", "user");
      // postData.append("api_key", process.env.REACT_APP_API_KEY);
      // const result = await assetServices.uploadToS3(postData);
      // console.log({ result });
      // if (result.status == 200) {
      //   // callBackUpload(result.data);
      // }
      callBackUpload(info?.target?.files[0]);

      // new upload
      const file = info.target.files[0];
      const base64 = await convertBase64(file);
      setUploadedImage(base64);
    };

    const convertBase64 = (file) =>
      new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });

    return (
      <MyFileUploaderFormDataContainer
        width={width}
        height={height}
        shape={shape}
      >
        <div
          className="avatar-uploader"
          onClick={() => {
            inputFileRef.current.click();
          }}
        >
          <div
            className="photo"
            onMouseOverCapture={() => !isHover && setIsHover(true)}
            onMouseLeave={() => isHover && setIsHover(false)}
            style={{
              backgroundImage: `url(${uploadedImage || imgSrc || DefaultFood})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "200px",
            }}
          >
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={onUpload}
              accept="image/*"
              // accept='video/*'
            />
            {/* <img
              // src='https://assetfoodieave.s3.amazonaws.com/user--1618367569664.png'
              src={imgSrc || DefaultFood}
              className="uploaded-img rounded"
              alt="avatar"
            /> */}
            {uploading && (
              <div className="uploading-icon">
                <ScaleLoader size={15} color={"white"} />
              </div>
            )}
            {/* {console.log(isHover)}
            {console.log(overlay)} */}
            {/* {isHover && (
              <div className="photo-hover">
                {overlay || (
                  <div className="d-flex justify-content-center align-items-center">
                    <FaCamera size={24} color="white" />
                    <div>Change</div>
                  </div>
                )}
              </div>
            )} */}

            <div className="photo-hover">
              {overlay || (
                <div className="d-flex justify-content-center align-items-center">
                  <FaCamera size={24} color="white" />
                  <div>Change</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MyFileUploaderFormDataContainer>
    );
  }

  MyFileUploaderFormData.propTypes = {
    uploading: PropTypes.bool,
    callbackWithImage: PropTypes.func,
    placeholder: PropTypes.any,
    src: PropTypes.any,
    width: PropTypes.string,
    height: PropTypes.string,
    shape: PropTypes.string,
    overlay: PropTypes.node,
  };
