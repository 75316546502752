import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { MyPromoCodeSearchContainer } from "./style";
// import useOutsideClick from 'components/OutsideClickDetect';
import { FaSearch } from "react-icons/fa";
import { useOutsideClick } from "services/functions";
import useOnclickOutside from "react-cool-onclickoutside";

export default function MyPromoCodeSearchComponent({
  placeholder = "",
  options = [
    { id: 1, value: "test" },
    { id: 2, value: "option" },
    { id: 3, value: "other value" },
  ],
  onChangeSearch = () => {},
  onClearAfterSearch = () => {},
  suggestions = true,
}) {
  const ref = useRef();
  const [searchValue, setSearchValue] = useState("");
  const [isSuggestionActive, setIsSuggestionActive] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);

  useEffect(() => {
    if (searchValue === "") {
      setIsSuggestionActive(false);
    }
  }, [searchValue]);

  useOutsideClick(ref, () => {
    setIsSuggestionActive(false);
    if (searchValue === "") {
      setIsSearchButtonClicked(false);
    }
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setSearchValue(e.target.value);
    setIsSuggestionActive(true);
    onChangeSearch(e.target.value);
  };

  function handleSelect(suggestion) {
    setSearchValue(suggestion.value);
    setIsSuggestionActive(false);
    onChangeSearch(suggestion.value);
  }

  function clearSearchElement(e) {
    e.stopPropagation();
    setSearchValue("");
    onClearAfterSearch();
    setIsSuggestionActive(false);
    setIsSearchButtonClicked(false);
  }

  function expandMenu() {
    setIsSearchButtonClicked(true);
  }

  const filteredOptions = options?.filter((suggestion) =>
    suggestion?.value?.toLowerCase()?.includes(searchValue?.toLocaleLowerCase())
  );

  const renderSuggestions = () => {
    if (filteredOptions?.length) {
      return filteredOptions?.map((suggestion) => {
        return (
          <div
            className="suggestion-item"
            key={suggestion.id}
            onClick={() => handleSelect(suggestion)}
          >
            <div className="suggestion-main">{getHighlightedText(suggestion.value, searchValue, "#ed2626")}</div>
          </div>
        );
      });
    } else {
      return [];
    }
  };

  const clearRef = useOnclickOutside((e) => {
    e.stopPropagation();
    setSearchValue("");
    onClearAfterSearch();
    // setIsSuggestionActive(false);
    // setIsSearchButtonClicked(true);
  });


  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };



  return (
    <MyPromoCodeSearchContainer ref={ref} active={isSearchButtonClicked}>
      <div className="search-container" onClick={() => expandMenu()}>
        <input
          className="search-container-input"
          value={searchValue}
          onChange={handleInput}
          placeholder={placeholder || "Enter..."}
        />
        {searchValue ? (
          <div
            className="clear-search"
            style={{ cursor: "pointer" }}
            onClick={(e) => clearSearchElement(e)}
            ref={clearRef}
          >
            <i className="fas fa-times" />
          </div>
        ) : (
          <div className="clear-search">
            <FaSearch />
          </div>
        )}
      </div>
      {suggestions && isSuggestionActive === true && (
        <div
          className={`suggestions-list ${
            !filteredOptions.length ? "not-found" : ""
          }`}
        >
          {renderSuggestions()}
        </div>
      )}
    </MyPromoCodeSearchContainer>
  );
}

MyPromoCodeSearchComponent.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeSearch: PropTypes.func,
  onClearAfterSearch: PropTypes.func,
  suggestion: PropTypes.bool,
};
