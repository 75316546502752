import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerFoundManagerContainer = styled(MyDrawerRight)`
  * {
    height: auto;
  }

  .found-location-title {
    margin-bottom: 40px;
  }

  .location-input {
    background: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #555555;
    color: #fff;
    font-weight: bold;
    min-height: 40px;
  }

  input:focus-visible {
    outline: unset;
  }

  .found-address-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #555555;
    margin-top: 9px;
  }

  .click-pointer {
    cursor: pointer;
  }

  .input-icon {
    position: absolute;
    right: 33px;
    margin-top: -29px;
  }
`;
