import React, { useContext, useEffect, useState, useRef } from "react";
import { MySearchContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import useOnclickOutside from "react-cool-onclickoutside";
import { useOutsideClick } from "services/functions";

export default function MySearch({
  value = "",
  onChange = () => {},
  placeHolder = "",
  defaultShow = false,
}) {

  const [isOpen, setIsOpen] = useState(defaultShow);
  // const { isOpen, setIsOpen, setSearchInput = () => {} } = useContext(
  //   RestaurantsContext
  // );

  const openSearchFilter = (e) => {
    e.preventDefault();
    if (!isOpen) setIsOpen(true);
  };

  const closeSearchFilter = (e) => {
    e.preventDefault();
    if (isOpen) {
      setIsOpen(false);
      onChange("");
    }
  };

  // react-cool-onclickoutside
  // const ref = useOnclickOutside(() => {
  //   setSearchInput("")
  //   setIsOpen()
  // });

  const inputRef = useRef(null);
  const divRef = useRef(null);

  const searchBtnClickHandler = (e) => {
    e.preventDefault();
    openSearchFilter(e);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [inputRef]);

  useEffect(() => {
    setIsOpen(false);
    onChange("");
  }, []);

  useOutsideClick(divRef, () => {
    setIsOpen(false);
    onChange("");
  });

  return (
    <MySearchContainer
      // ref={ref}
      className={`search-pane ${isOpen ? "active" : ""}`}
      onClick={(e) => searchBtnClickHandler(e)}
      ref={divRef}
    >
      <i className="fas fa-search" />

      {isOpen && (
        <input
          className="search-input on-search-active"
          type="text"
          value={value}
          onChange={onChange}
          ref={inputRef}
          autoFocus
          placeholder={placeHolder !== "" ? placeHolder : ""}
        />
      )}
      {value && (
        <i
          className="fas fa-times on-search-active"
          onClick={(e) => closeSearchFilter(e)}
        />
      )}
    </MySearchContainer>
  );
}
