import React, { useState, useEffect } from "react";
import { TotalSalesContainer } from "./style";
import { TotalSalesChart } from "./TotalSalesChart";
import { TotalSalesDashboard } from "./TotalSalesDashboard";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";

export const TotalSales = () => {
  const [period, setPeriod] = useState("day");
  const [dateRange, setDateRange] = useState();
  const [totalSalesList, setTotalSalesList] = useState([]);
  const [graphResData, setGraphResData] = useState([]);
  const [totalSalesFilter, setTotalSalesFilter] = useState({
      id: 3,
      name: "Total Sales",
  });
  const [salesStats, setSalesStats] = useState([]);

  // console.log(totalSalesFilter)

  useEffect(() => {
    let graphUrl;
    if (dateRange) {
      graphUrl = `/analytics-orders/graph?period=${period}&filter=${totalSalesFilter?.id}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
    } else {
      graphUrl = `/analytics-orders/graph?period=${period}&filter=${totalSalesFilter?.id}`;
    }
    customAxios
      .get(graphUrl)
      .then((res) => {
        if (res?.status === 200) {
          setGraphResData(res?.data);
          let array = [];
          res.data &&
            res.data.length &&
            res.data.map((el) => {
              let obj = {
                name:
                  totalSalesFilter?.id === 1
                    ? moment(el?.time_stamp).format("MM/DD")
                    : totalSalesFilter?.id === 2
                    ? moment(el?.time_stamp).format("MM/DD")
                    : totalSalesFilter?.id === 3
                    ? moment(el?.time_stamp).format("MMM DD")
                    : 0,
                amount: el?.order_count || el?.average_price || el?.total_sales,
              };
              array.push(obj);
            });
          setTotalSalesList(array);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [period, dateRange, totalSalesFilter]);

  useEffect(() => {
    let statsUrl;
    if (dateRange) {
      statsUrl = `/analytics-orders/stats?period=${period}&start_date=${dateRange?.startDate}&end_date=${dateRange?.endDate}`;
    } else {
      statsUrl = `/analytics-orders/stats?period=${period}`;
    }
    customAxios
      .get(statsUrl)
      .then((res) => {
        if (res?.status === 200) {
          let salesArray = [
            {
              title: "Total Sales",
              value: `${res?.data?.total_sales < 0 ? "$" : "$"} ${Math.abs(
                res?.data?.total_sales
              ) || 0}`,
              percent: {
                up: res?.data?.total_sales?.change < 0 ? false : true,
                value: res?.data?.total_sales?.change / 100 || 0,
              },
            },
            {
              title: "Fees Collected",
              value: ` ${res?.data?.fees < 0 ? "$" : "$"} ${Math.abs(
                res?.data?.fees
              ) || 0}`,
              percent: {
                up: res?.data?.fees?.change < 0 ? false : true,
                value: res?.data?.fees?.change / 100 || 0,
              },
            },
            {
              title: "Average Order",
              value: `${res?.data?.average_order < 0 ? "$" : "$"} ${Math.abs(
                res?.data?.average_order
              ) || 0}`,
              percent: {
                up: res?.data?.average_order?.change < 0 ? false : true,
                value: res?.data?.average_order?.change / 100 || 0,
              },
            },
            {
              title: "# of Orders",
              value: res?.data?.orders || 0,
              percent: {
                up: res?.data?.orders?.change < 0 ? false : true,
                value: res?.data?.orders?.change / 100 || 0,
              },
            },
            {
              title: "Customers",
              value: `${res?.data?.users || 0}`,
              percent: {
                up: res?.data?.users?.change < 0 ? false : true,
                value: res?.data?.users?.change / 100 || 0,
              },
            },
            {
              title: "Restaurants",
              value: `${res?.data?.restaurant || 0}`,
              percent: {
                up: res?.data?.restaurant?.change < 0 ? false : true,
                value: res?.data?.restaurant?.change / 100 || 0,
              },
            },
          ];
          setSalesStats(salesArray);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [period, dateRange]);

  const onTimePeriodChange = (time) => {
    setPeriod(time);
  };

  return (
    <TotalSalesContainer>
      <TotalSalesChart
        totalSalesList={totalSalesList}
        originalGraphData={graphResData}
        totalSalesFilter={totalSalesFilter}
        setTotalSalesFilter={setTotalSalesFilter}
        period={period}
        dateRange={dateRange}
      />
      <TotalSalesDashboard
        sales={salesStats}
        onTimePeriodChange={onTimePeriodChange}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
    </TotalSalesContainer>
  );
};
