import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerCategoryContainer = styled(MyDrawerBottom)`
  .top-title {
    margin-bottom: unset;
  }
  .drawer-categories-wrapper {
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;
