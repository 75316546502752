import styled from "@emotion/styled";
import { colors } from "theme";

export const OrdersContainer = styled.div`
  padding: 0 24px;
  .orders-page-header {
    min-height: 88px;
    padding: 0px 35px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.08);
    .right-part {
      display: flex;
      align-items: center;
      position: relative;

      .active-dot {
        position: absolute;
        width: 11px !important;
        height: 11px !important;
        border: solid 2px #333437 !important;
        background-color: #ed2626 !important;
        border-radius: 50% !important;
        top: 7px !important;
        right: 15px !important;
      }

      .show-search-result {
        position: absolute;
        background: #27282A;
        width: 250px;
        border-radius: 20px;
        border: 1px solid #525558;
        margin-top: 6px;
        z-index: 1;
        padding: 15px 20px 0 20px;

        .restaurant-title {
          .restaurant-title-gap {
            margin-bottom: 0;
            cursor: pointer;
          }
        }
      }
      
      .filter-item {
        height: 40px;
        padding: 0px 18px;
        border-radius: 25px;
        border: solid 1px #525558;
        .filter-icon {
          font-size: 18px;
        }
      }
    }
  }
  .orders-body {
    padding: 25px 35px;
    .filter-options {
      background: #27282a;
      border-radius: 40px;
      display: flex;
      align-items: center;
      .unelected-tab {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #ffffff7d !important;
      }
      #statistic-calendar-popup-handle {
        position: relative;
        padding-right: 20px;
        .popup-wrapper {
          position: absolute;
          right: 0;
        }
      }
    }
    .orders-info-wrapper {
      display: flex;
      .order-info-card {
        height: 140px;
        border-radius: 8px;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
        background-color: ${colors.gray2};
        padding: 28px 41px;
        border-radius: 20px;
        &.order-info-right {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 20px;
        }
        .info-item {
          .info-label {
            font-size: 18px;
            margin-bottom: 7px;
            label {
              opacity: 0.6;
            }
          }
          .info-value {
            font-size: 30px;
            font-weight: bold;
          }
        }
        .percentage {
          // color: #f7a64c;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .orders-filter-details-row {
      margin-top: 28px;
      .filter-name {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.38px;
        color: #fff;
      }

      .result {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.38px;
        text-align: right;
        color: #fff;
      }
    }
    .orders-table {
      margin-top: 30px;
      a {
        color: ${colors.white};
      }
    }
    .no-orders-data-div {
      margin-top: 10%;
      img {
        width: auto;
        height: 250px;
      }
      .no-result-text {
        margin-top: 32px;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      .no-result-search-meta {
        margin-top: 16px;
        opacity: 0.6;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
  }
`;
