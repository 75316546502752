import DrawerCheckbox from "components/Elements/DrawerElements/DrawerCheckbox";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { MyTimePicker } from "components/Elements/MyTimePicker";
import { DrawerContainer } from "components/styledStyle";
import React, { useState, useContext, useEffect } from "react";
import { DrawerAvailableDayContainer } from "./style";
import { Switch } from "@material-ui/core";
import { RestaurantsContext } from "context/RestaurantsContext";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment";
import { dayTitles } from "static/staticData";

export default function DrawerAvailableDay({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postRestaurant, setPostRestaurant, selectedDayToEdit } = useContext(
    RestaurantsContext
  );
  // 0,86400
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [availableChecked, setAvailableChecked] = useState(false);
  const [allDay, setAllDay] = useState(false);

  const [updatedHours, setUpdatedHours] = useState({});

  const [selectedTime, setSelectedTime] = useState([]);

  // toggle available
  // const handleChangeAvailable = (event) => {
  //   setAvailableChecked(event.target.checked);
  // };

  // toggle allday
  // const handleChangeAllDay = (event) => {
  //   setAllDay(event.target.checked);
  // };

  // business time
  function onClickSetTime() {
    setUpdatedHours({
      day: selectedDayToEdit,
      times: [[startTime, endTime]],
      timezone: "GMT-4",
    });
    closePanel();
  }

  useEffect(() => {
    let updatedWorkingHoursArray;

    if (
      postRestaurant &&
      postRestaurant.working_hours &&
      postRestaurant.working_hours.findIndex(
        (el) => el.day === updatedHours.day
      ) > -1
    ) {
      const index = postRestaurant.working_hours.findIndex(
        (el) => el.day === updatedHours.day
      );
      updatedWorkingHoursArray = [...postRestaurant.working_hours];
      updatedWorkingHoursArray[index] = updatedHours;
    } else {
      if (postRestaurant && postRestaurant.working_hours) {
        updatedWorkingHoursArray = [
          ...postRestaurant.working_hours,
          updatedHours,
        ];
      }
    }

    setPostRestaurant({
      ...postRestaurant,
      working_hours: updatedWorkingHoursArray,
    });
  }, [updatedHours]);

  useEffect(() => {
    if (isActive) {
      const isDayWasSelected =
        postRestaurant &&
        postRestaurant?.working_hours &&
        postRestaurant?.working_hours?.length &&
        postRestaurant?.working_hours?.find(
          (selectedDay) => selectedDay?.day == selectedDayToEdit
        );

      console.log("isDayWasSelected", isDayWasSelected);
      if (
        isDayWasSelected !== undefined &&
        isDayWasSelected !== null &&
        isDayWasSelected !== 0
      ) {
        console.log("isDayWasSelected2", isDayWasSelected);
        const timeRange =
          isDayWasSelected &&
          isDayWasSelected?.times &&
          isDayWasSelected?.times[0]
            ? isDayWasSelected?.times[0]
            : 0;
        const [startTime, endTime] = timeRange || 0;
        setStartTime(startTime);
        setEndTime(endTime);
        if (startTime === 0 && endTime === 86400) {
          setAllDay(true);
        } else {
          setAllDay(false);
        }
      }
    }
  }, [selectedDayToEdit]); // allDay

  useEffect(() => {
    if (isActive) {
      setAvailableChecked(false);
    }
  }, [isActive]);

  const dayNameRenderer = () => {
    let filterData = dayTitles?.filter((el) => el?.num === selectedDayToEdit);
    return `${filterData[0]?.content}`;
  };

  // useEffect(() => {
  //   console.log(allDay)
  // }, [allDay])

  return (
    <DrawerAvailableDayContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">{dayNameRenderer()}</div>
          <div></div>
        </div>

        {/* <div className="available-switch">
          <div>Available</div>
          <div>
            <Switch
              checked={availableChecked}
              onChange={handleChangeAvailable}
            />
          </div>
        </div>
        <div className="bottom-border" /> */}

        <DrawerCheckbox
          title="Available"
          isOpen={availableChecked}
          onChange={() => setAvailableChecked(!availableChecked)}
        />

        {/* <div className="allday-switch">
          <div>All Day</div>
          <div>
            <Switch checked={allDay} onChange={handleChangeAllDay} />
          </div>
        </div>
        <div className="bottom-border" /> */}

        <DrawerCheckbox
          title="All Day"
          isOpen={allDay}
          onChange={() => {
            setAllDay(!allDay)
            if (!allDay) {
              setStartTime(0);
              setEndTime(0);
            }
          }}
        />

        {!allDay && (
          <div className="set-your-time">
            <p className="set-time-header">Set Your Time</p>
            <div className="time-picker-wrapper d-flex justify-content-between align-items-center">
              <MyTimePicker selectedTime={setStartTime} /> {/* value={startTime}  */}
              <div className="arrow-icon-container">
                <i className="fas fa-long-arrow-alt-right" />
              </div>
              <MyTimePicker selectedTime={setEndTime} /> {/* value={endTime} */}
            </div>
          </div>
        )}

        <div className="bottom-btns">
          <MyDrawerButton
            // type={startTime > 0 && endTime > 0 ? "red" : "gray"}
            type={
              (startTime === "" ||
                startTime === undefined ||
                endTime === "" ||
                endTime === undefined) &&
              !allDay
                ? "gray"
                : "red"
            }
            onClick={onClickSetTime}
            disabled={
              (startTime === "" ||
                startTime === undefined ||
                endTime === "" ||
                endTime === undefined) &&
              !allDay
            }
          >
            <b>Done</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerAvailableDayContainer>
  );
}
