import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerPartialRefundContainer = styled(MyDrawerCenterModal)`
  .drawer-bottom-content {
    text-align: center;
    h3 {
      // margin-bottom: 32px;
    }
    h6 {
      margin-bottom: 31px;
      line-height: 1.5;
      letter-spacing: 0.27px;
    }
    .modal-title {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-bottom: 24px;
    }
    .card-details {
      padding: 15px 30px 2px 26px;
      border-radius: 16px;
      background-color: #1b1c1d;
      margin-bottom: 32px;
    }
    .refund-amount {
      margin-top: 56px;
    }
    .refund-options {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: 12px;
      margin-bottom: 24px;
      .refund-option {
        flex: 1;
        text-align: center;
        border: solid 1px #5b5e61;
        display: flex;
        border-radius: 16px;
        flex-direction: column;
        justify-content: center;
        min-height: 69px;
        transition: all 0.3s ease;
        // &:first-of-type {
        //   border-left: none;
        // }
        &.selected {
          border: 2px solid ${colors.redColor};
        }
        h5 {
          color: #ffffff;
          opacity: 0.5;
        }
      }
    }
    .fixed-amount {
      margin-top: 24px;
      margin-bottom: 24px;
      min-height: 69px;
      padding: 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: solid 1px ${colors.white5};
      border-top: solid 1px ${colors.white5};
      p {
        margin-bottom: 0;
      }
      .amount-field {
        color: white;
        /* background-color: red !important; */
        /* max-width: 3rem; */
        &:before {
          display: none;
        }
        .MuiInputAdornment-root {
          .MuiTypography-root {
            color: white;
            position: relative;
            right: 4px;
          }
        }
        .MuiInputAdornment-positionStart {
          margin-right: 0;
        }
        .MuiInputBase-input {
          padding: 8px 0 4px 1px;
        }
      }
    }
    .bottom-btns {
      padding: 0 15px;
    }
  }
`;

export const Label = styled.div`
  font-family: Montserrat;
  font-size: ${(props) => (props.size ? props.size : "22px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => props.textAlign && props.textAlign};
`;
