import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { mocks } from "static/staticData";
import { DrawerDeleteCustomerContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import { CustomersContext } from "context/CustomersContext";

export default function DrawerDeleteCustomer({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    editCustomerData,
    deleteCustomerModals,
    setDeleteCustomerModals,
  } = useContext(CustomersContext);
  return (
    <DrawerDeleteCustomerContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="top-title">
            <div className="title">Delete Customer</div>
          </div>
          <div className="delete-customer-message">
            Are you sure you want to delete customer{" "}
            <b>{`${editCustomerData?.first_name} ${editCustomerData?.last_name} `}</b>
            ?
          </div>
          <div className="delete-customer-meta">This cannot be undone.</div>
          <div className="bottom-btns">
            <MyDrawerButton
              className="mb-5"
              type="red"
              onClick={() => {
                setDeleteCustomerModals({
                  ...deleteCustomerModals,
                  deleteCustomer: false,
                  deleteConfirmation: true,
                });
              }}
            >
              <b>Yes, Delete</b>
            </MyDrawerButton>
            <MyDrawerButton type="gray" onClick={closePanel}>
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteCustomerContainer>
  );
}
