import styled from "@emotion/styled";

export const DrawerCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .left-part {
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.3);
    }
    .is-open {
      color: rgba(255, 255, 255, 1);
    }
  }
  .right-part {
    .ant-switch {
      background-color: #5f626a;
    }
    .ant-switch-checked {
      background-color: #ed2626;
    }
  }
`;
