import React from "react";
import { DrawerMoveCategoryDeleteConfirmationContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerMoveCategoryDeleteConfirmation = ({
  isActive = false,
  closePanel = () => {},
  chosenCategory = {},
  selectedCatForMoveItems = {},
  finalDeleteCatWithItem = () => {},
  finalDeleteCategoryHandler = () => {},
  countCatgoryItemState,
}) => {

  return (
    <DrawerMoveCategoryDeleteConfirmationContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center text-bold">Are you sure?</h2>
        <p className="text-white text-center mt-3">
          The category <b>{countCatgoryItemState?.name}</b> and {countCatgoryItemState?.items?.length} items under it will be deleted.
        </p>
        <p className="text-white text-center">
          Any items assigned to multiple menus or categories will not be
          affected.
        </p>
        <p className="text-white text-center">This cannot be undone.</p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mb-4 text-bold"
            onClick={() => {
              finalDeleteCategoryHandler(chosenCategory);
            }}
          >
            <b>Delete Category & Items</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold"
            onClick={() => {
              // finalDeleteCatWithItem(selectedCatForMoveItems, chosenCategory);
              closePanel()
            }}
          >
            <b>Keep</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerMoveCategoryDeleteConfirmationContainer>
  );
};

export default DrawerMoveCategoryDeleteConfirmation;
