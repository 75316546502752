import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerRemoveItemContainer = styled(MyDrawerCenterModal)`
  .drawer-bottom-content {
    font-size: 15px;
    .title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 24px;
      font-size: 22px;
    }

    .item-cards {
      background: black;
      padding: 20px;
      border-radius: 20px;
      border: 1px solid #5c5c5c;
      .item-name {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .name-title {
          font-weight: bold;
        }
        .price {
          color: #ffffff;
          opacity: 0.6;
        }
      }
      .size {
        color: #ffffff;
        opacity: 0.6;
        margin-bottom: 8px;
        span {
          font-weight: bold;
        }
      }
      .instructions {
        color: #ffffff;
        opacity: 0.6;
        span {
          font-weight: bold;
        }
      }
    }

    .bottom-btns {
      .cancel-btn {
        color: ${colors.redColor};
        text-align: center;
        font-weight: 600;
        margin-top: 34px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
`;
