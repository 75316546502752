import styled from "@emotion/styled";
import { colors } from "theme";

export const CustomerProfilePageContainer = styled.div`
  .customer-profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0px 25px 0px;
    margin: 0 20px 0 5px;
    border-bottom: 1px solid #4d5053;
    .left-part {
      flex: 1 1 50%;
      padding-left: 40px;
      .backlink-to-rest {
        color: ${colors.white};
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
      .arrow-icon-container {
        i {
          color: #FFFFFF;
        }
      }
      .current-restaurant {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }

      .title-header {
        display: flex;
        align-items: center;
        gap: 20px;

        i {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 6px;
          cursor: pointer;
        }

        h2 {
          font-weight: 700;
        }
      }
    }
  }
  .customer-profile-breadcumb {
    margin-top: 30px;
    .left-part {
      flex: 1 1 50%;
      padding-left: 40px;
      .backlink-to-rest {
        color: ${colors.white};
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
      .arrow-icon-container {
        i {
          color: white;
        }
      }
      .current-user-name {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
    }
  }
  .customer-profile-body {
    padding: 25px 35px 50px;
    .customer-profile-card {
      min-height: 150px;
      width: 100%;
      background-color: ${colors.gray2};
      border-radius: 8px;
      box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
      /* margin-top: 33px; */
      &.customer-history {
        min-height: 768px;
      }
    }
    .customer-favorite-card {
      min-height: 140px;
      width: 100%;
      background-color: ${colors.gray2};
      margin-top: 33px;
    }
  }
`;
