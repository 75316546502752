import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DeclineRestaurantModalContainer = styled(MyDrawerCenterModal)`
  .restaurant-decline-div {
    position: relative;
    .img-leave-error {
      width: 375px;
      height: 211px;
      position: absolute;
      bottom: 120%;
      left: 7%;
    }
    .modal-title {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
    }
    .modal-bottom {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        width: 100%;
      }
      .decline-btn {
        margin-bottom: 10px;
      }
    }
  }
`;
