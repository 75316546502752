import React, { useState, useEffect, useContext } from "react";
import { ProfileReviewsContainer } from "./style";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { Image } from "react-bootstrap";
import { NoAvatar } from "assets/img";
import moment from "moment";
import MyPaginator from "components/Elements/MyPaginator";
import { drawersOverall } from "static/staticData";
import { CustomersContext } from "context/CustomersContext";
import EmptyReviewsImage from "assets/img/no-filter-data.png";

export default function ProfileReviews({ className = "", customerId }) {
  const [activeOption, setActiveOption] = useState("from_server");
  const [reviewsData, setReviewsData] = useState([]);
  const [reviewCount, setReviewCount] = useState({
    food: 0,
    server: 0,
  });

  const { openDrawer, openDrawers, closeDrawer } = useContext(CustomersContext);

  useEffect(() => {
    customAxios
      .get(`/customer/${customerId}/reviews`)
      .then((res) => {
        setReviewCount({
          food: res?.data?.food_reviews.rows.length,
          server: res?.data?.server_reviews.rows.length,
        });
        if (activeOption === "for_food") {
          setReviewsData(res.data.food_reviews);
        } else {
          setReviewsData(res.data.server_reviews);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [customerId, activeOption]);

  const onPageChange = () => {};

  return (
    <ProfileReviewsContainer className={`${className}`}>
      <div className="customers-reviews-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0">Reviews</h3>
      </div>

      {reviewsData?.rows?.length > 0 && (
        <div className="customers-tab-container d-flex">
          <div
            className={`tab-left tab-toggler ${
              activeOption === "from_server" ? "tab-bottom-border" : ""
            }`}
            onClick={() => setActiveOption("from_server")}
          >
            <h6>For Server {`(${reviewCount.server})`}</h6>
          </div>
          <div
            className={`tab-right tab-toggler ${
              activeOption === "for_food" ? "tab-bottom-border" : ""
            }`}
            onClick={() => setActiveOption("for_food")}
          >
            <h6>For food {`(${reviewCount.food})`}</h6>
          </div>
        </div>
      )}

      {reviewsData && reviewsData.rows && reviewsData.rows.length ? (
        <div className="customers-reviews-body d-flex flex-column">
          {reviewsData &&
            reviewsData.rows &&
            reviewsData.rows.length &&
            reviewsData.rows.map((reviewsData) => {
              return (
                <div
                  key={reviewsData.id}
                  className="customer-review-card-item d-flex"
                >
                  <div className="customer-reviews-card-avatar">
                    <Image
                      className="rounded-circle"
                      src={reviewsData.image ? reviewsData.image : NoAvatar}
                      width={33}
                    />
                  </div>
                  <div className="customer-reviews-card-info d-flex flex-column">
                    <div className="customer-reviews-username-rating d-flex justify-content-between ">
                      <h6 className="text-bold">{reviewsData.user_name}</h6>
                      <div className="customer-review-rating d-flex text-bold ">
                        <div>{reviewsData.rating}</div>&nbsp;{" "}
                        <i className="fas fa-star" />
                      </div>
                    </div>
                    <h6
                      className={`opacity6 ${
                        reviewsData.comment === "" ? "text-italic" : ""
                      }`}
                    >
                      {reviewsData.comment ? reviewsData.comment : "No comment"}
                    </h6>
                    <div className="customer-reviews-date-time d-flex justify-content-end">
                      <div>
                        {moment(reviewsData.createdat).format("LT")},
                        {moment(reviewsData.createdat).format("MM/DD/YYYY")}
                      </div>
                      <div
                        className="delete-review"
                        onClick={() =>
                          openDrawer(drawersOverall.customerReviewsDelete)
                        }
                      >
                        <i className="far fa-trash" />
                      </div>
                      {/*@TODO restore reviews icon */}
                      {/* <div
                        className="delete-review"
                        onClick={() =>
                          openDrawer(drawersOverall.customerRestoreReviews)
                        }
                      >
                        <i className="fas fa-trash-undo-alt" />
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          {reviewsData?.total_count > 4 && (
            <MyPaginator
              initialPage={0}
              totalCount={reviewsData?.total_count}
              limitShow={4}
              onPageChange={onPageChange}
            />
          )}
        </div>
      ) : (
        <div className="no-result d-flex justify-content-center align-items-center flex-column w-100">
          {/* <i className="far fa-comment" /> */}
          <img src={EmptyReviewsImage} alt="" />
          <h3>No reviews yet</h3>
        </div>
      )}
    </ProfileReviewsContainer>
  );
}
