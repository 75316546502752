import styled from "@emotion/styled";

export const TotalSalesDashboardContainer = styled.div`
  width: 40%;

  display: flex;
  flex-direction: column;

  .total-sales-dashboard-top {
    margin-left: 100px;
    margin-bottom: 70px;
    background-color: #27282a;
    border-radius: 30px;
    width: max-content;
  }

  .total-sales-facts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    margin-left: 50px;

    .total-sales-fact {
      position: relative;
      /* margin-left: 100px; */
      margin-bottom: 58px;

      &:hover {
        .from-last-period {
          display: block;
        }
      }

      &:last-child {
        /* margin-left: 128px; */
      }

      .total-sales-title {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);

        display: flex;
        align-items: center;

        .percent-state-grow {
          margin-left: 6px;
          font-size: 15px;
          font-weight: bold;

          display: flex;
          align-items: center;

          i {
            margin-right: 5px;
          }

          &.grow-up {
            color: #1fc086;

            i {
              color: #1fc086;
            }
          }

          &.grow-down {
            color: #f7a64c;

            i {
              color: #f7a64c;
            }
          }
        }
      }

      .total-sales-value {
        font-size: 30px;
        font-weight: bold;
        color: white;
      }

      .from-last-period {
        background-color: #27282a;
        border-radius: 25px;
        padding: 17px 19px;
        display: none;
        position: absolute;
        top: -63px;
        right: -45px;

        .from-last-period-title {
          font-size: 15px;
          color: white;
        }

        .from-last-period-pointer {
          position: absolute;
          left: 66px;
          transform: rotate(45deg);
          width: 20px;
          height: 20px;
          border-radius: 2px;
          background-color: #27282a;
        }
      }

      @media (max-width: 1718px) {
        &:last-child {
          margin-left: 100px;
        }
      }
    }
  }
`;
