import React, { useState, useEffect } from "react";
import { ReviewsRemoveModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const ReviewsRemoveModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteEntireOrder = () => {},
}) => {
  const [
    customerProfileNotification,
    setCustomerProfileNotification,
  ] = useState("");

  useEffect(() => {
    if (customerProfileNotification) {
      setTimeout(() => setCustomerProfileNotification(null), 2 * 1000);
    }
  }, [customerProfileNotification]);

  //@TODO delete customer reviews notificatoin
  //   useEffect(() => {
  //     setCustomerProfileNotification({
  //       message: "Review Removed.",
  //       type: "cuisine-inactive",
  //       table: `<p style="color: #8F949A">Review was removed from <b style="color: #8F949A">Catch Roof</b>.</p>`,
  //     });
  //   }, []);

  return (
    <ReviewsRemoveModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {customerProfileNotification && (
          <MyMessageBoxItemNew
            message={
              customerProfileNotification?.message
                ? customerProfileNotification.message
                : ""
            }
            table={
              customerProfileNotification?.table
                ? customerProfileNotification.table
                : ""
            }
            type={
              customerProfileNotification?.type
                ? customerProfileNotification.type
                : "success"
            }
            onClick={() => setCustomerProfileNotification(null)}
          />
        )}

        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Remove Review</div>
          <div className="delete-suborder-meta">
            You are about to remove this{" "}
            <span className="text-bold">Review</span>.
            <br /> This review will not affect the overall rating and will{" "}
            <br /> not be visible to Feasttt users anymore. Are you sure?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              onClick={() => {
                onDeleteEntireOrder();
              }}
            >
              <b>Yes, Remove Review</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-3">
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ReviewsRemoveModalContainer>
  );
};

export default ReviewsRemoveModal;
