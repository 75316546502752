import React, { useContext, useEffect, useState } from "react";
import { LocationModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { FaMapMarkerAlt } from "react-icons/fa";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { colors } from "theme";
import Geocode from "react-geocode";
import useOnclickOutside from "react-cool-onclickoutside";
const LocationModal = ({ isActive = false, closePanel = () => {} }) => {
  
  const { setPostRestaurant, postRestaurant } = useContext(RestaurantsContext);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");

  // google places autocomplete
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
    // cache: 24 * 60 * 60
  });

  useEffect(() => {
    if (postRestaurant && isActive) {
      setValue(postRestaurant.address);
      setSelectedRestaurant(postRestaurant.address);
    }
  }, [postRestaurant, isActive]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      // get address from coordinate
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.enableDebug();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setSelectedRestaurant(address);
        },
        (error) => {}
      );
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className="found-address-list cursor-pointer"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <div>
            <p className="text-bold">{main_text}</p>
            <p>{secondary_text}</p>
          </div>
          <div></div>
        </div>
      );
    });
  // end of google places autocomplete

  function textReset() {
    setValue("");
    clearSuggestions();
  }
  return (
    <LocationModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="drawer-content">
          <div className="top-title">
            <div></div>
            <div className="title">
              <b>Location</b>
            </div>
            <div></div>
          </div>
          <div className="input-label">Location</div>
          <div
            className="w-100"
            style={{
              position: "relative",
            }}
          >
            <div className="input-icon">
              <FaMapMarkerAlt size={15} color={colors.white3} />
            </div>
            <input
              type="text"
              placeholder="Search Location"
              className="w-100 location-input"
              value={value}
              onChange={handleInput}
              disabled={!ready}
            />
          </div>
          {status === "OK" && (
            <div className="location-suggestions">{renderSuggestions()}</div>
          )}
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              onClick={() => {
                setPostRestaurant({
                  ...postRestaurant,
                  address: selectedRestaurant,
                });
                closePanel();
              }}
              disabled={
                postRestaurant.address === "" && selectedRestaurant === ""
              }
            >
              <b>Save</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </LocationModalContainer>
  );
};

export default LocationModal;
