/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { colors } from "theme";
import { MyDrawerRightContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { OrdersContext } from "context/OrdersContext";

export function MyDrawerRight({
  children,
  className = "",
  isActive = false,
  // renderLeftIcon = <FaTimes size={18} color={colors.white3} />,
  // renderRightIcon = null,
  // onClickLeftIcon = () => {},
  onClickRightIcon = () => {},
  closePanel = () => {},
  containerClassName = "",
}) {
  const { openDrawers } = useContext(RestaurantsContext);
  const { openDrawers: openDrawersOrders } = useContext(OrdersContext);

  useEffect(() => {
    if (
      Object.keys(openDrawers).length > 0 ||
      Object.keys(openDrawersOrders).length > 0 ||
      isActive
    ) {
      document.body.style.overflow = "hidden";
    } else {
      // document.body.style.overflow = "unset";
      if (!document.querySelector(".drawer-opend")) {
        document.body.style.overflow = "unset";
      }
    }
  }, [openDrawers, openDrawersOrders, isActive]);

  return (
    <MyDrawerRightContainer className={isActive ? "drawer-opend" : ""}>
      <div
        className={`offcanvas offcanvas-left ${containerClassName}`}
        style={isActive ? { right: 0 } : {}}
      >
        {/* <div className="drawer-left-icon cursor" onClick={closePanel}>
          {renderLeftIcon}
        </div> */}
        {/* <div className="drawer-right-icon cursor" onClick={onClickRightIcon}>
          {renderRightIcon}
        </div> */}
        <div className={`drawer-content-wrapper ${className}`}>{children}</div>
      </div>
      <div
        className={`offcanvas-overlay`}
        style={isActive ? {} : { display: "none" }}
        onClick={closePanel}
      ></div>
    </MyDrawerRightContainer>
  );
}
