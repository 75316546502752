import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { mocks } from "static/staticData";
import { DrawerStatusFoodContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";

export default function DrawerStatusFood({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postFood, setPostFood } = useContext(RestaurantsContext);

  const onChangeStatus = (status) => {
    setPostFood({ ...postFood, status: status });
    closePanel();
  };

  return (
    <DrawerStatusFoodContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <div className="drawer-bottom-content w-100">
            <div className="top-title">
              <div className="title">Status</div>
            </div>
            <div className="drawer-status--options mb-auto">
              {Object.values(mocks.statuses)
                .reverse()
                .map((_status, _i) => (
                  <DrawerChooseItem
                    key={_i}
                    className="mb-4"
                    title={_status.title}
                    subtitle={_status?.subtitle}
                    active={postFood?.status === _status.value}
                    onChooseItem={() => onChangeStatus(_status.value)}
                  />
                ))}
            </div>
            <div className="bottom-btns">
              <MyDrawerButton type="redColor" onClick={closePanel}>
                <b>Cancel</b>
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerStatusFoodContainer>
  );
}
