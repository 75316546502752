import styled from "@emotion/styled";
import { colors } from "theme";

export const BlockOrderDetailContainer = styled.div`
  min-height: 50px;
  background-color: ${colors.gray2};
  border-radius: 20px;
  .card-block {
    .card-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* margin-bottom: 17px; */
      padding: 17px 23px;
      .pending-status {
        width: 29px;
        height: 29px;
        padding: 8px 8px 7px;
        border-radius: 41px;
        background-image: linear-gradient(314deg, #ff7d7d 95%, #ed2626 5%);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.29px;
        text-align: center;
        color: #fff;
        margin-right: 21px;
      }
      .order-ready-status {
        width: 29px;
        height: 29px;
        padding: 8px 8px 7px;
        border-radius: 41px;
        background-image: linear-gradient(322deg, #fdcb6e 86%, #f7a64c 12%);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.29px;
        text-align: center;
        color: #fff;
        margin-right: 21px;
      }
      h5 {
        margin-bottom: 0;
      }
    }
    .card-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 20px 19px 28px;
      border-radius: 13px;
      background-color: #27282a;
      margin-top: 11px;
      .card-item-content {
        flex: 1;
        .item-row {
          display: flex;
          justify-content: space-between;
          #review-sub-order-delete-pop-wrapper {
            margin-left: 27px;
            margin-top: -5px;
            position: relative;
            .popup-wrapper {
              width: max-content;
              z-index: 1;
            }
          }
        }
      }
    }
  }
`;

export const FoodItemDetailContainer = styled.div`
  .card-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 19px 28px;
    border-radius: 13px;
    background-color: rgba(255, 255, 255, 0.02);
    margin-top: 11px;
    .card-item-content {
      flex: 1;
      .item-row {
        display: flex;
        justify-content: space-between;
        #review-sub-order-delete-pop-wrapper {
          margin-left: 27px;
          margin-top: -5px;
          position: relative;
          .popup-wrapper {
            width: max-content;
            z-index: 1;
          }
        }
      }
    }
  }
`;
