import React, { useState, useContext } from "react";
import { DrawerSetDiscountModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerContainer } from "components/styledStyle";

const SetDiscountModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
}) => {
  const min = null;
  const max = 100;

  const {
    discountType,
    setDiscountType,
    discountFixedAmount,
    setDiscountFixedAmount,
    discountPercentage,
    setDiscountPercentage,
  } = useContext(RestaurantsContext);

  function handleAmountType() {
    setDiscountType(2);
  }

  function handlePercentageType() {
    setDiscountType(1);
  }

  if (discountType === 2) {
    setDiscountPercentage("");
  } else {
    setDiscountFixedAmount("");
  }

  function onSaveDiscount() {
    closePanel();
  }

  const handleChange = (e) => {
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    setDiscountFixedAmount(value);
  };

  function onClickCancel() {
    setDiscountFixedAmount("");
    setDiscountPercentage("");
    closePanel();
  }

  return (
    <DrawerSetDiscountModalContainer
      isActive={isActive}
      closePanel={closePanel}
      // top="25rem"
      // bottom="28rem"
      left="0"
      top="20%"
      position="absolute"
    >
      <DrawerContainer>
        <div className="set-discount-container">
          <div className="modal-title">Set Discount</div>

          <div className="input-area">
            <div className="amount" onClick={handleAmountType}>
              <div
                className="label-text"
                style={{ color: discountType === 2 ? "#fff" : "#757575" }}
              >
                Fixed Amount
              </div>
              <div className="d-flex">
                <span
                  style={{ color: discountType === 2 ? "#fff" : "#757575" }}
                >
                  $
                </span>
                <input
                  type="text"
                  value={discountFixedAmount || ""}
                  placeholder="0.00"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="percentage" onClick={handlePercentageType}>
              <div
                className="label-text"
                style={{ color: discountType === 2 ? "#757575" : "#fff" }}
              >
                Percentage
              </div>
              <div className="d-flex">
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={discountPercentage || ""}
                  placeholder="0"
                  onChange={(e) =>
                    setDiscountPercentage(
                      Math.max(min, Math.min(max, Number(e.target.value)))
                    )
                  }
                />
                <span
                  style={{ color: discountType === 2 ? "#757575" : "#fff" }}
                >
                  %
                </span>
              </div>
            </div>
          </div>

          <div
            className={
              discountFixedAmount || discountPercentage
                ? "save-btn-enable"
                : "save-btn-disable"
            }
            onClick={onSaveDiscount}
          >
            Done
          </div>
          <div className="cancel-btn cursor-pointer" onClick={onClickCancel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerSetDiscountModalContainer>
  );
};

export default SetDiscountModal;
