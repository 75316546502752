import styled from "@emotion/styled";
import { colors } from "theme";

export const DrawerInputWithVerifyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding-top: 10px;
  h6 {
    font-size: 14px;
    opacity: 0.4;
    line-height: 1.57;
    letter-spacing: -0.34px;
  }
  
  .drawer-input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    width: max-content;
    position: relative;
    width: -webkit-fill-available;
    .input-prefix {
      /* flex: 0 0 auto; */
      color: #fff;
      padding-right: 10px;
    }
    input {
      background-color: transparent;
      color: #fff;
      font-weight: bold;
      border: unset;
      outline: unset;
      padding-left: 0;
      // margin-right: 30px;
      flex-basis: 65%;
    }
  }

  .verify-icon {
    position: relative;
    right: 0;
    width: 27px;
    height: 27px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    position: absolute;
    right: 23px;
    bottom: 12px;
  }

  .input-icon {
    position: absolute;
    right: 0;
    width: 27px;
    height: 27px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: ${colors.gray};
    }
  }
`;
