import styled from '@emotion/styled';
import { colors } from 'theme';

export const FoodControlContainer = styled.div`
  position: absolute;
  bottom: 1px;
  display: flex;
  align-items: baseline;

  .btn-more {
    i {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .dropdown-menu {
    border-color: transparent;
    background-color: ${colors.gray5};
  }
`;