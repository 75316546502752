import React, { useContext } from "react";
import { ActiveOederActionsModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";
import { colors } from "theme";

const ActiveOrderActionsModal = ({
  isActive = false,
  closePanel = () => {},
  setOpenApplyDiscountModal = () => {},
  onClickProcessPayment = () => {},
  onClickDeleteFullOrder = () => {},
  setOpenApplyPromoCodeModal = () => {},
  setOpenAssignNewServerModal = () => {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();

  return (
    <ActiveOederActionsModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      position="fixed"
      zIndex="999"
      zIndexBackground="9"
    >
      <DrawerContainer>
        <div className="active-order-actions">
          <div
            className="clickable-row"
            onClick={() => {
              history.push(`/order-detail/${orderDetailData?.token}/add-item`);
              closePanel();
              localStorage.setItem(
                "ordersRestaurantId",
                orderDetailData?.restaurant?.id
              );
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-plus"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Add Item</div>
            </div>
          </div>

          <div
            className={`clickable-row`}
            onClick={() => {
              setOpenApplyDiscountModal((prev) => !prev);
              // closePanel();
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-badge-percent"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Apply Discount</div>
            </div>
          </div>

          <div
            className={`clickable-row`}
            onClick={() => {
              setOpenApplyPromoCodeModal((prev) => !prev);
              // closePanel();
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 20 18"
                  version="1.1"
                  style={{ marginLeft: "4px" }}
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill={colors.white}
                    fill-rule="evenodd"
                  >
                    <g
                      id="Admin-Web-App---Orders---Completed---Complimentary"
                      transform="translate(-20.000000, -352.000000)"
                    >
                      <g
                        id="Admin-Nav-Menu---Orders"
                        transform="translate(0.000000, 0.000000)"
                      >
                        <g
                          id="Group-26"
                          transform="translate(8.000000, 111.000000)"
                        >
                          <g
                            id="Group-8"
                            transform="translate(12.000000, 240.000000)"
                          >
                            <g
                              id="coupon-(1)"
                              transform="translate(0.000000, 1.000000)"
                            >
                              <path
                                d="M6.47281029,0.494501963 C7.02448979,0.005244287 7.82316139,-0.12897018 8.4943721,0.127709339 C8.51686279,0.136132264 8.53759346,0.14369767 8.5573222,0.151037163 L8.5956684,0.165602072 C8.95777739,0.307102496 9.13429686,0.521547853 14.6035497,5.37314512 C15.2215182,5.92145113 15.5762056,6.70173277 15.5762056,7.51440062 L15.5762056,16.1536096 C15.5762056,17.1715156 14.7215184,18 13.6710891,18 L1.90511654,18 C0.854726271,18 0,17.1715156 0,16.1536096 L0,7.51440062 C0,6.70173277 0.354687379,5.92145113 0.973085605,5.37314512 Z M11.2984336,7.18190501 C11.0346837,6.97101808 10.6409339,7.00234985 10.4184339,7.25902937 L4.16843608,14.4894384 C3.94718615,14.7449128 3.98343614,15.1245093 4.24843605,15.3378064 C4.36593601,15.4318017 4.50718596,15.4775943 4.64843591,15.4775943 C4.82718585,15.4775943 5.00468579,15.40288 5.12718575,15.2618871 L11.3771836,8.03147806 C11.5984335,7.77479854 11.5634336,7.39520207 11.2984336,7.18190501 Z M10.5153103,11.8623898 C9.48156067,11.8623898 8.64031096,12.6734006 8.64031096,13.669992 C8.64031096,14.6665834 9.48156067,15.4775943 10.5153103,15.4775943 C11.54906,15.4775943 12.3903097,14.6665834 12.3903097,13.669992 C12.3903097,12.6734006 11.54906,11.8623898 10.5153103,11.8623898 Z M5.03155938,7.0421171 C3.99780973,7.0421171 3.15656002,7.85312797 3.15656002,8.84971935 C3.15656002,9.84631072 3.99780973,10.6573216 5.03155938,10.6573216 C6.06530902,10.6573216 6.90655874,9.84631072 6.90655874,8.84971935 C6.90655874,7.85312797 6.06530902,7.0421171 5.03155938,7.0421171 Z M7.91859105,3.02288561 C7.56913804,2.95965719 7.2567944,3.19280022 7.2212866,3.52110598 C7.19210692,3.79804571 7.38070061,4.06865883 7.68968488,4.13079516 C8.01320039,4.18935394 8.31253623,3.9939446 8.37874714,3.68717944 C8.44105181,3.39924346 8.25280968,3.09006816 7.91859105,3.02288561 Z"
                                id="Combined-Shape"
                                // fill="#FFFFFF"
                                fill-rule="nonzero"
                                // opacity={activeItem === "promocodes" ? 1 : 0.5}
                              ></path>
                              <g
                                id="Group-620"
                                transform="translate(3.156560, 7.042117)"
                              ></g>
                              <path
                                d="M19.8529229,11.5773535 C17.9451111,6.22331094 16.885463,3.2464913 16.8704239,3.21267407 C16.5387834,2.47231032 15.8942133,1.89651369 15.1028073,1.63328161 C15.0573777,1.61840655 15.3253463,1.68114541 11.0757384,0.709144958 L15.3965572,4.54201489 C16.2555413,5.30346734 16.7481583,6.38689894 16.7481583,7.51476743 L16.7481583,14.8355565 L18.8560483,13.965648 C19.8095636,13.5679755 20.2529228,12.5003604 19.8529229,11.5773535 Z"
                                id="Path"
                                // fill="#FFFFFF"
                                fill-rule="nonzero"
                                opacity="0.5"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div>Apply Promo Code</div>
            </div>
          </div>

          <div
            className="clickable-row"
            onClick={() => {
              setOpenAssignNewServerModal((prev) => !prev);
              // closePanel();
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-concierge-bell"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Assign New Server</div>
            </div>
          </div>

          <div
            className={`clickable-row`}
            onClick={() => {
              onClickProcessPayment();
              // closePanel();
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-coins"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Process Payment</div>
            </div>
          </div>

          <div
            className="clickable-row"
            onClick={() => {
              onClickDeleteFullOrder();
              // closePanel();
            }}
          >
            <div className="d-flex justify-content-center align-content-center">
              <div className="mr-4">
                <i
                  className="fas fa-trash-alt"
                  style={{ fontSize: "18px", color: colors.white }}
                />
              </div>
              <div>Delete Entire Order</div>
            </div>
          </div>

          <MyDrawerButton
            className="active-order-action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </ActiveOederActionsModalContainer>
  );
};

export default ActiveOrderActionsModal;
