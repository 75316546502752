import styled from "@emotion/styled";

export const AnalyticsOrdersPageContainer = styled.div`
  padding-bottom: 63px;

  .live-container {
    display: flex;
    margin-bottom: 34px;

    #orders-format {
      width: 144px;
      text-align: center;
      margin-top: 5px;

      i {
        color: #ed2626;
      }
    }

    .tabs-list-restaurants {
      width: 46%;
      margin-right: 34px;
    }
    .heat-map-ui {
      .leaflet-container {
        background-color: #141414;
      }
      .leaflet-tooltip {
        background-color: #27282a;
        border: unset;
        padding: 19px 21px 21px 22px;
        border-radius: 10px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
      }
      .leaflet-marker-pane img {
        &:hover {
          border-radius: 50%;
          box-shadow: 0px 0px 18px 9px #ff5959;
        }
      }
    }
    .orders-analytics-heat-map {
      background-color: #27282a;
      border-radius: 18px;
      border-color: transparent;
      display: flex;
      align-items: center;
      flex-direction: column;

      .chart-tooltip-title {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        margin-bottom: 4px;

        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .chart-tooltip-amount-customers {
        font-size: 14px;
        font-weight: bold;
        color: white;

        display: flex;
        align-items: center;
      }
    }
  }

  .people-by-orders-and-customers-container {
    display: flex;

    .tabs-list-people-by-orders {
      width: 46%;
      margin-right: 34px;
    }

    .tabs-list-customers {
      width: 51%;
    }
  }

  .logo-div {
    img {
      border-radius: 50%;
    }
  }
`;
