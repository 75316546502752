import React from "react";
import { AverageSessionContainer } from "./style";
import moment from "moment";

export const AverageSession = ({ time = "", growth = 0 }) => {
  return (
    <AverageSessionContainer>
      <div className="average-session-top-container">
        <h4 className="average-session-top-title">Average session</h4>
        {/* <i className="fas fa-chevron-circle-up" />
        <span className="growth-percentage">{growth} %</span> */}
      </div>
      {/* <span className="average-time-session">{time !== null && moment(time).format('hh-mm-ss')}</span> */}
      <span className="average-time-session">{time !== null && time}</span>
    </AverageSessionContainer>
  );
};
