import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { CustomersContext } from "context/CustomersContext";
import { drawersCustomers } from "static/staticData";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { DrawerAddAddressContainer } from "./style";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import DrawerEditSearchAddress from "../DrawerEditSearchAddress";
import DrawerDeleteLocation from "../../DrawerDeleteLocation";

const DrawerEditAddress = ({
  isActive = false,
  closePanel = () => {},
  customerId,
  onSaveEditAddress,
  selectedLocation,
  deleteLocationHandler
}) => {
  const {
    openDrawer,
    openDrawers,
    profileData,
    setProfileData,
    closeDrawer,
    editCustomerData,
    setEditCustomerData,
  } = useContext(CustomersContext);
  const [editName, setEditName] = useState();
  const [editAddress, setEditAddress] = useState();
  const [placeId, setPlaceId] = useState();

  useEffect(() => {
    if (selectedLocation) {
      setEditName(selectedLocation.title);
      setEditAddress(selectedLocation.address);
      setPlaceId(selectedLocation.place_id);
    }
  }, [selectedLocation]);

  return (
    <DrawerAddAddressContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Edit Address</div>
          <div onClick={() => openDrawer(drawersCustomers.deleteLocation)}>
            <i className="fas fa-trash-alt cursor-pointer" style={{fontSize: '18px'}} />
          </div>
        </div>
        <div className="input-divs mb-auto mt-4">
          <div style={{ marginBottom: "50px" }}>
            <DrawerInput
              label="Address Name"
              placeholder="No name"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
            />
            <DrawerInputWithVerify
              label="Address"
              placeholder="No Address"
              value={editAddress}
              onChange={(e) => setEditAddress(e.target.value)}
              onClick={() => openDrawer(drawersCustomers.editSearchAddress)}
            />
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={() => {
              onSaveEditAddress({
                locationTitle: editName,
                locationPlaceId: placeId,
                locationAddress: editAddress,
              });
              closeDrawer(drawersCustomers.editAddress);
            }}
          >
            Save
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerEditSearchAddress
        isActive={!!openDrawers[drawersCustomers.editSearchAddress]}
        closePanel={() => closeDrawer(drawersCustomers.editSearchAddress)}
        setAddress={setEditAddress}
        address={editAddress}
        setPlaceId={setPlaceId}
        drawerTitle="Edit Address"
      />
      <DrawerDeleteLocation
        isActive={!!openDrawers[drawersCustomers.deleteLocation]}
        closePanel={() => closeDrawer(drawersCustomers.deleteLocation)}
        deleteLocationHandler={deleteLocationHandler}
      />
    </DrawerAddAddressContainer>
  );
};

export default DrawerEditAddress;
