import React from 'react';
import PropTypes from 'prop-types';
// import { showMessage } from 'services/functions';
import { NumberIncreaseContainer } from './style';
import SingleNumberIncrease from './NumberIncrease/SingleNumberIncrease';

export default function MyNumberIncrease({
  label = '',
  seats = 1,
  increaseSeats = () => {},
  decreaseSeats = () => {},
  isUserDidNotSelectShape = false,
}) {
  const warnUserHeShouldSelectTableShapeFirst = () => {}
    // showMessage('Please select table shape first', 'warning');

  const onClickIncrease = () => {
    if (isUserDidNotSelectShape) {
      warnUserHeShouldSelectTableShapeFirst();
    } else {
      increaseSeats();
    }
  };

  const onClickDecrease = () => {
    if (isUserDidNotSelectShape) {
      warnUserHeShouldSelectTableShapeFirst();
    } else {
      decreaseSeats();
    }
  };

  return (
    <NumberIncreaseContainer>
      <p className='title'>{label}</p>
      <SingleNumberIncrease
        value={seats}
        onIncrease={onClickIncrease}
        onDecrease={onClickDecrease}
        defaultValue={seats}
      />
    </NumberIncreaseContainer>
  );
}

MyNumberIncrease.propTypes = {
  label: PropTypes.string,
  seats: PropTypes.number,
  increaseSeats: PropTypes.func,
  decreaseSeats: PropTypes.func,
  isUserDidNotSelectShape: PropTypes.bool,
};
