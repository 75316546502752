import styled from "@emotion/styled";
import { colors } from "theme";

export const TableLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  /* min-height: 900px; */
  min-height: 700px;
  /* margin-top: 23px; */
  border-radius: 8px;
  background: #1b1c1d;
  .tables-header {
    width: 100%;
    height: 84px;
    background-color: ${colors.gray2};
    border-radius: 10px 10px 0 0;
    padding: 31px 40px 31px 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #4d505361;
    .left-part {
      display: flex;
      justify-content: space-between;
      .status-menu {
        display: flex;
        align-items: center;
        .status-menu-item {
          margin-left: 35px;
        }
        .status-menu-item:first-of-type {
          margin-left: 0px;
        }
      }
    }

    .edit-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ed2626;
      display: grid;
      place-items: center;
      cursor: pointer;
      color: #fff;
      margin-right: 15px;
    }

    .tab-switcher-container {
      color: #fff;
      display: -webkit-inline-box;
      align-items: center;
      border-radius: 20px;
      margin: 0px 0;
      overflow-x: auto;
      background-color: #27282a;
      /* width: 800px; */
      &::-webkit-scrollbar {
        display: block;
        height: 3px;
        width: 1px;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 20px;
        width: 70%;
      }
      &::-webkit-scrollbar-thumb {
        background-color: gainsboro;
        border-radius: 20px;
        width: 20%;
      }

      .tab-switcher-item {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
        &.active {
          border-radius: 20px;
          background: #3e4245;
          font-weight: bold;
        }
      }
    }
  }
  .tables {
    flex: 1;
    /* min-height: 700px; */
    position: relative;
    background-color: transparent;
    background-image: linear-gradient(
        0deg,
        transparent 24%,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.05) 26%,
        transparent 27%,
        transparent 74%,
        rgba(255, 255, 255, 0.05) 75%,
        rgba(255, 255, 255, 0.05) 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        90deg,
        transparent 24%,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.05) 26%,
        transparent 27%,
        transparent 74%,
        rgba(255, 255, 255, 0.05) 75%,
        rgba(255, 255, 255, 0.05) 76%,
        transparent 77%,
        transparent
      );
    height: 100%;
    background-size: 50px 50px;
    .internal-scene {
      transform-origin: center;
      width: 100%;
      height: 100%;
      margin-left: 30px;
      margin-top: 25px;
    }
    .add-table-class {
      cursor: pointer;
      position: absolute;
      right: 40px;
      bottom: 120px;
      transition: opacity 0.5s ease-in-out;
      -moz-transition: opacity 0.5s ease-in-out;
      -webkit-transition: opacity 0.5s ease-in-out;
    }
    .add-table-class:hover {
      opacity: 0.9;
    }
    .zoom-class {
      top: 20px;
      right: 40px;
      background-color: rgba(62, 66, 69, 0.1);
      position: absolute;
      flex-direction: column;
      .zoom-button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
        font-size: 16px;
        transition: background-color 0.5s ease-in-out;
        -moz-transition: background-color 0.5s ease-in-out;
        -webkit-transition: background-color 0.5s ease-in-out;
        i {
          font-weight: 100;
        }
        &.zoom-button-margin-bottom {
          margin-bottom: 3px;
        }
      }
      .zoom-button:hover {
        background-color: rgba(255, 255, 255, 0.65);
      }
    }
    .rotate-class {
      left: 30px;
      bottom: 120px;
      position: absolute;
      .rotate-button {
        cursor: pointer;
        background-color: rgba(96, 100, 108, 0.5);
        margin: 0 10px;
        color: rgba(255, 255, 255, 0.3);
        font-size: 16px;
        transition: color 0.5s ease-in-out;
        -moz-transition: color 0.5s ease-in-out;
        -webkit-transition: color 0.5s ease-in-out;
        i {
          font-weight: 100;
        }
      }
      .rotate-button:hover {
        color: #fff;
      }
    }

    .react-transform-component {
      height: 100%;
      width: 100%;
      .react-transform-element {
        height: 100%;
        width: 100%;
      }
    }
    .add-table-class {
      cursor: pointer;
      position: absolute;
      right: 40px;
      bottom: 48px;
      transition: opacity 0.5s ease-in-out;
      -moz-transition: opacity 0.5s ease-in-out;
      -webkit-transition: opacity 0.5s ease-in-out;
    }
    .add-table-class:hover {
      opacity: 0.9;
    }
    .zoom-class {
      top: 20px;
      right: 40px;
      background-color: rgba(62, 66, 69, 0.1);
      position: absolute;
      flex-direction: column;
      .zoom-button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
        font-size: 16px;
        transition: background-color 0.5s ease-in-out;
        -moz-transition: background-color 0.5s ease-in-out;
        -webkit-transition: background-color 0.5s ease-in-out;
        i {
          font-weight: 100;
        }
        &.zoom-button-margin-bottom {
          margin-bottom: 5px;
        }
      }
      .zoom-button:hover {
        background-color: rgba(255, 255, 255, 0.65);
      }
    }
    .rotate-class {
      left: 30px;
      bottom: 43px;
      position: absolute;
      .rotate-button {
        cursor: pointer;
        background-color: rgba(96, 100, 108, 0.5);
        margin: 0 10px;
        color: rgba(255, 255, 255, 0.3);
        font-size: 16px;
        transition: color 0.5s ease-in-out;
        -moz-transition: color 0.5s ease-in-out;
        -webkit-transition: color 0.5s ease-in-out;
        i {
          font-weight: 100;
        }
      }
      .rotate-button:hover {
        color: #fff;
      }
    }

    .right-part {
      .floor-select {
        background-color: red;
      }
    }
  }
`;
