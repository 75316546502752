import React, { useContext, useState, useEffect } from "react";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { useParams } from "react-router";
import { dayTitles, drawersMenu } from "static/staticData";
import { restaurantServices } from "services";
import { DrawerMenuContainer } from "./style";
import { FaChevronLeft, FaTimes, FaTrash, FaTrashAlt } from "react-icons/fa";
import { colors } from "theme";
import MenuCategories from "./MenuCategories";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerMenu({
  type = "Add",
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const {
    openDrawer,
    closeDrawer,
    postMenu,
    setPostMenu,
    fetchRestaurant,
    menuCloseDrawer,
    setMenuCloseDrawer,
    drawerEditMenus,
    setDrawerEditMenus,
    drawerMenuAdd,
    setDrawerMenuAdd,
  } = useContext(RestaurantsContext);

  const [addMenuNotification, setAddMenuNotification] = useState(null);

  useEffect(() => {
    if (addMenuNotification) {
      setTimeout(() => setAddMenuNotification(null), 2 * 1000);
    }
  }, [addMenuNotification]);

  const onDone = async () => {
    if (type === "Add") {
      const result = await restaurantServices.addNewMenu(id, postMenu);
      if (result.status === 200) {
        await fetchRestaurant(id);
        setAddMenuNotification({
          message: "Menu Added.",
          type: "",
          table: ``,
        });
      }
      if (result.errMessage === "Menu with same name not allowed") {
        menuAlreadyExists();
      }
      // closeDrawer(drawersMenu.menuAdd);
      setDrawerEditMenus({
        ...drawerEditMenus,
        menus: false,
      });
      setDrawerMenuAdd({
        ...drawerMenuAdd,
        menuAdd: false,
      });
    } else {
      const result = await restaurantServices.updateMenu(id, postMenu);
      if (result.status === 200) {
        await fetchRestaurant(id);
          setAddMenuNotification({
            message: "Menu Updated.",
            type: "",
            table: ``,
          });
      }
      // closeDrawer(drawersMenu.menuEdit);
      setMenuCloseDrawer({
        ...menuCloseDrawer,
        menuEdit: false,
      });
    }
  };

  function onOpenDrawer() {
    // openDrawer(drawersMenu.deleteMenu);
    setMenuCloseDrawer({
      ...menuCloseDrawer,
      deleteMenu: true,
    });
    // setShowItemsCount()
  }

  function menuAlreadyExists() {
    setTimeout(() => {
      setDrawerEditMenus({
        ...drawerEditMenus,
        menus: false,
      });
      openDrawer(drawersMenu.openDrawerCheckExistsMenu);
    }, 500);
  }

  return (
    <DrawerMenuContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={
        type === "Edit" ? <FaTrash size={18} color={colors.redColor} /> : null
      }
      // onClickRightIcon={() => openDrawer(drawersMenu.deleteMenu)}
    >
      {addMenuNotification && (
        <MyMessageBoxItemNew
          message={
            addMenuNotification?.message ? addMenuNotification.message : ""
          }
          table={addMenuNotification?.table ? addMenuNotification.table : ""}
          type={
            addMenuNotification?.type ? addMenuNotification.type : "success"
          }
          onClick={() => setAddMenuNotification(null)}
        />
      )}

      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">{type} Menu</div>
          <div>
            {type === "Edit" ? (
              <FaTrashAlt
                className="cursor-pointer"
                size={20}
                color={colors.white}
                onClick={onOpenDrawer}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <DrawerInput
          label="Menu Name"
          placeholder="Ex: All Day, Lunch/Dinner, Holiday Menu, etc."
          value={postMenu?.name || ""}
          onChange={(e) => setPostMenu({ ...postMenu, name: e.target.value })}
        />
        <ItemPicker
          label="Availability"
          placeholder="Set Availability"
          value={
            postMenu?.availability?.length > 0
              ? postMenu.availability
                  .map((_hour) => dayTitles[_hour.day].key)
                  .join(", ")
              : ""
          }
          onClick={() => openDrawer(drawersMenu.availabilities)}
        />

        <ItemPicker
          label="Status"
          placeholder="Choose Status"
          value={
            postMenu?.status === 1
              ? "Available"
              : postMenu?.status === 0
              ? "Not Available"
              : ""
          }
          onClick={() => openDrawer(drawersMenu.statusMenu)}
        />

        <MenuCategories type={type} />
        <div className="bottom-btns">
          <MyDrawerButton
            type={
              postMenu?.name === "" || postMenu?.name === undefined
                ? "gray"
                : "red"
            }
            className="text-bold mb-5"
            onClick={onDone}
            disabled={postMenu?.name === "" || postMenu?.name === undefined}
          >
            {type === "Edit" ? "Save" : "Add Menu"}
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold cancel-btn"
            onClick={closePanel}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerMenuContainer>
  );
}
