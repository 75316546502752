import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerIsRestorePwdContainer = styled(MyDrawerRight)`
  .restore-password-panel {
    padding: 0 25px;
    font-family: Montserrat;
    position: relative;
    height: 100%;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      padding-top: 40px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 18px;
        }
      }
      .panel-header-context {
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .panel-content-body {
      .panel-content-message {
        margin-bottom: 30px;
      }

      .enter-email-placeholder {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        input {
          box-shadow: none;
          border: none;
          background-color: transparent;
          font-size: 14px;
          color: #fff;
          position: relative;
          width: 100%;
          padding-left: 5px;
        }
        input:focus {
          outline: none;
          color: #fff;
        }
        input::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      padding: 20px 25px;
      width: 100%;
      left: 0;
      bottom: 0;

      .button-general {
        border-radius: 8px;
        height: 50px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        box-shadow: none;

        opacity: 1;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -webkit-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
      }
      .button-general:hover {
        opacity: 0.8;
      }

      .submit-button {
        background-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.4);
        margin-bottom: 10px;
        &.active {
          background-color: #ff5a5a;
          color: #ffffff;
        }
      }

      .cancel-button {
        background-color: transparent;
        color: #ff5a5a;
      }
    }

    .email-sent-message {
      position: absolute;
      left: 0;
      bottom: -280px;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 30px 30px 20px 30px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #333437;

      transition-delay: 250ms;
      transition-duration: 1s;
      transition-property: bottom;
      &.active {
        bottom: 0;
        transition-property: bottom;
      }

      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        color: #ffffff;
        margin-bottom: 25px;
        span {
          cursor: pointer;
          color: #ff5a5a;
          margin-left: 5px;

          opacity: 1;
          -moz-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          -webkit-transition: all 0.4s ease-out;
          -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
          i {
            color: #ff5a5a;
            font-size: 12px;
          }
        }
        span:hover {
          opacity: 0.8;
        }
      }

      button {
        height: 50px;
        margin-top: 20px;
        width: 100%;
        border-radius: 8px;
        background-color: #ff5a5a;
        color: #fff;
        border: none;

        opacity: 1;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -webkit-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
      }
      button:hover {
        opacity: 0.9;
      }
    }
  }
`;
