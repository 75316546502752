import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { BlockOrderSummaryContainer } from "./style";
import MyDivider from "components/Elements/MyDivider";
import { FaReceipt, FaRegCreditCard } from "react-icons/fa";
import { OrdersContext } from "context/OrdersContext";
import DrawerDeleteDiscount from "../Drawers/DrawerDeleteDiscount";
import DrawerDeletetedDiscount from "../Drawers/DrawerDeletetedDiscount";
import { drawersOrder } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import { showMessage } from "services/functions";
import RemoveDiscountModal from "../Modals/RemoveDiscountModal";
import RemovePromoCodeModal from "../Modals/RemovePromoCodeModal";

export default function BlockOrderSummary({
  className = "",
  summaryDetail = {},
  managerDiscountDetail = {},
  couponDetails = {},
}) {
  const { id } = useParams();
  const [openRemoveDiscountModal, setOpenRemoveDiscountModal] = useState(false);
  const [openRemovePromoCodeModal, setOpenRemovePromoCodeModal] = useState(
    false
  );
  const {
    orderDetailData,
    setOrderNotification,
    setOrderDetailData,
  } = useContext(OrdersContext);

  const onClickDiscountRemove = () => {
    setOpenRemoveDiscountModal(!openRemoveDiscountModal);
  };

  const onDeleteDiscount = () => {
    customAxios
      .delete(`/orders/${id}/discount`)
      .then((res) => {
        if (res?.status === 200) {
          customAxios
            .get(`/orders/${orderDetailData?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
          setOrderNotification({
            type: "remove",
            message: "Discount Removed.",
            table: `Table ${orderDetailData?.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
            #${orderDetailData?.token}
          </span>`,
          });
          onClickDiscountRemove();
        } else {
          showMessage("You can only remove before order complete", "error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const removePromoCodeHandler = () => {
    customAxios
      .delete(`/orders/${id}/coupon`)
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(`/orders/${orderDetailData?.token}`)
            .then((res) => {
              setOrderDetailData(res?.data?.order);
            })
            .catch((err) => {});
          setOrderNotification({
            type: "remove",
            message: "Promo Code Removed.",
            table: `Table ${orderDetailData?.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
            #${orderDetailData?.token}
          </span>`,
          });
          setOpenRemovePromoCodeModal(!openRemovePromoCodeModal);
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <BlockOrderSummaryContainer className={className}>
      <div className="card-block">
        <div className="card-row">
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            Summary
          </div>
        </div>
        {summaryDetail?.sub_total ? (
          <div className="card-row">
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              Subtotal
            </h5>
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              ${summaryDetail?.sub_total}
            </h5>
          </div>
        ) : (
          <></>
        )}

        {summaryDetail?.tip ? (
          <div className="card-row">
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              Tip
            </h5>
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              ${summaryDetail?.tip}
            </h5>
          </div>
        ) : (
          <></>
        )}

        {/* discount */}
        {managerDiscountDetail?.discount ? (
          <div className="card-row">
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              {managerDiscountDetail?.discount_type === 2
                ? `$${managerDiscountDetail?.discount} Discount`
                : `${managerDiscountDetail?.coupon_discount}% Discount`}
              {orderDetailData?.status !== 2 ? (
                <span
                  className="text-red ml-2 cursor"
                  onClick={onClickDiscountRemove}
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Remove
                </span>
              ) : (
                <></>
              )}
            </h5>
            <h5
              className="text-red"
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              - ${managerDiscountDetail?.discount}
            </h5>
          </div>
        ) : (
          <></>
        )}

        {/* promo code */}
        {Object.keys(couponDetails).length ? (
          <>
            <div className="card-row" style={{ marginBottom: "8px" }}>
              <h5
                style={{
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                Promo Code
                <span
                  className="text-red ml-2 cursor"
                  onClick={() => {
                    setOpenRemovePromoCodeModal(!openRemovePromoCodeModal);
                  }}
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Remove
                </span>
              </h5>
              <h5 className="text-red">
                {/* <b>- ${summaryDetail?.promo_code}</b> */}
                –${couponDetails?.discount}
              </h5>
            </div>

            <h5 className="opacity6">
              {couponDetails?.code} ({couponDetails?.coupon_discount}
              {couponDetails?.discount_type === 1 ? "%" : "$"}
              OFF)
            </h5>
          </>
        ) : (
          <></>
        )}

        {summaryDetail?.tax ? (
          <div className="card-row">
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              Tax
            </h5>
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              ${summaryDetail?.tax}
            </h5>
          </div>
        ) : (
          <></>
        )}

        {summaryDetail?.total ? (
          <div className="card-row">
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Total
            </h5>
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              {/* $ {summaryDetail?.total_payable || summaryDetail?.total_paid} */}
              $ { orderDetailData?.status !== 2 ? summaryDetail?.total_payable : summaryDetail?.total}
            </h5>
          </div>
        ) : (
          <></>
        )}
      </div>
      <MyDivider />
      <div className="card-block">
        <div className="card-row">
          <h5
            style={{
              fontSize: "15px",
              fontWeight: "normal",
            }}
            className=""
          >
            Feasttt Fee
          </h5>
          <h5
            style={{
              fontSize: "15px",
              fontWeight: "normal",
            }}
          >
            ${summaryDetail?.feast_fees}
          </h5>
        </div>
      </div>
      {orderDetailData?.payment_mode !== null ? (
        <div className="card-block summary-bottom">
          <div className="card-row mb-4">
            <h5 className="mr-auto opacity6">Payment Summary</h5>
            {orderDetailData?.payment_mode === 1 ? (
              <div className="payment-mode">
                <h5>One Check</h5>
              </div>
            ) : (
              <div className="payment-mode">
                <h5>Manual Payment</h5>
              </div>
            )}
          </div>

          {orderDetailData?.payment_mode === 3 ? (
            <>
              <div className="card-details mb-0">
                <div className="d-flex flex-column ">
                  <h5>
                    <b>No Payment Necessary</b>
                  </h5>
                  <h5 className="opacity6">
                    Approved by{" "}
                    {/* {orderDetailData?.server?.name} */}
                    {orderDetailData?.checkout_data?.checkout_by?.name}
                  </h5>
                </div>
                <h5>
                  <b>${summaryDetail?.total_paid}</b>
                </h5>
              </div>
            </>
          ) : (
            <>
              <div className="card-details mb-0">
                <div className="d-flex flex-column ">
                  <h5>
                    <b>
                      {orderDetailData?.payment_mode === 2
                        ? "Cash"
                        : orderDetailData?.checkout_data?.card
                        ? orderDetailData?.checkout_data?.card?.brand?.toUpperCase() +
                          " " +
                          orderDetailData?.checkout_data?.card?.last4
                        : ""}
                    </b>
                  </h5>
                  <h5 className="opacity6">
                    Approved by{" "}
                    {orderDetailData?.checkout_data?.checkout_by?.name}
                    {/* {orderDetailData?.server?.name} */}
                  </h5>
                </div>
                <h5 className=" color-green">
                  <b>{`$${orderDetailData?.checkout_data?.total_paid}`}</b>
                </h5>
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      <RemoveDiscountModal
        isActive={openRemoveDiscountModal}
        closePanel={() => setOpenRemoveDiscountModal(!openRemoveDiscountModal)}
        removeDiscount={onDeleteDiscount}
      />
      {/* <DrawerDeletetedDiscount
        isActive={!!openDrawers[drawersOrder.deletedOrderDiscount]}
        closePanel={() => closeDrawer(drawersOrder.deletedOrderDiscount)}
      /> */}
      <RemovePromoCodeModal
        isActive={openRemovePromoCodeModal}
        closePanel={() =>
          setOpenRemovePromoCodeModal(!openRemovePromoCodeModal)
        }
        removePromoCodeHandler={removePromoCodeHandler}
      />
    </BlockOrderSummaryContainer>
  );
}

BlockOrderSummary.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
};
