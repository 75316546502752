import styled from "@emotion/styled";
import { colors } from "theme";

export const StyledStatusItem = styled.div`
  flex: 1;
  height: 60px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.active {
    border: solid 1px #fff;
  }
`;

export const StyledIconWrapper = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${colors.white5};
  }
`;

export const StyledPopupContainer = styled.div`
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  background-color: #27282a;
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
      font-size: 14px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
`;
