import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { drawersCustomers, mocks } from "static/staticData";
import { DrawerDeleteCustomerConfirmationContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import { CustomersContext } from "context/CustomersContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams, useHistory } from "react-router-dom";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerDeleteCustomerConfirmation({
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const history = useHistory();

  const {
    closeDrawer,
    editCustomerData,
    deleteCustomerModals,
    setDeleteCustomerModals,
    setProfileData,
  } = useContext(CustomersContext);

  const [
    deleteCustomerNotification,
    setDeleteCustomerNotification,
  ] = useState();

  useEffect(() => {
    if (deleteCustomerNotification) {
      setTimeout(() => setDeleteCustomerNotification(null), 2 * 1000);
    }
  }, [deleteCustomerNotification]);

  const deleteCustomerMethod = () => {
    customAxios
      .delete(`/customer/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            deleteCustomer: false,
            deleteConfirmation: false,
            confirmationOTP: false,
          });
          setDeleteCustomerNotification({
            message: "Customer Deleted.",
            type: "remove",
            table: `${editCustomerData?.first_name} ${editCustomerData?.last_name}.`,
          });
          // setProfileData()
          closeDrawer(drawersCustomers.editCustomer);
          history.push(`/customers`);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <DrawerDeleteCustomerConfirmationContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      {deleteCustomerNotification && (
        <MyMessageBoxItemNew
          message={
            deleteCustomerNotification?.message
              ? deleteCustomerNotification?.message
              : ""
          }
          table={
            deleteCustomerNotification?.table
              ? deleteCustomerNotification?.table
              : ""
          }
          type={
            deleteCustomerNotification?.type
              ? deleteCustomerNotification?.type
              : ""
          }
          onClick={() => setDeleteCustomerNotification(null)}
        />
      )}

      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="top-title">
            <div className="title">Are you sure?</div>
          </div>
          <div className="delete-customer-message">
            Customer{" "}
            <span className="text-bold">{`${editCustomerData?.first_name} ${editCustomerData?.last_name}`}</span>{" "}
            will be deleted.
          </div>
          <div className="delete-customer-meta">This cannot be undone.</div>
          <div className="bottom-btns">
            <MyDrawerButton
              className="mb-5"
              type="red"
              // commented delete drawer
              // onClick={() => {
              //   setDeleteCustomerModals({
              //     ...deleteCustomerModals,
              //     deleteCustomer: false,
              //     deleteConfirmation: false,
              //     confirmationOTP: true,
              //   });
              // }}
              onClick={deleteCustomerMethod}
            >
              <b>Yes, Delete</b>
            </MyDrawerButton>
            <MyDrawerButton
              type="gray"
              onClick={() => {
                setDeleteCustomerModals({
                  ...deleteCustomerModals,
                  deleteCustomer: false,
                  deleteConfirmation: false,
                });
              }}
            >
              <b>No, Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteCustomerConfirmationContainer>
  );
}
