import styled from '@emotion/styled';
import { colors } from 'theme';

export const FoodChecksContainer = styled.div`
  margin-top: 30px;
  display: flex;

  .check-item {
    position: relative;
    padding: 6px 7px;
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${colors.gray5};

    &:hover {
      .check-item-name {
        display: block;
      }
    }

    .check-item-name {
      position: absolute;
      top: -76px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      background-color: ${colors.gray5};
      border-radius: 27px;
      padding: 17px 20px 15px;
      width: fit-content;
      height: 57px;

      .message-wrapper {
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .name-title {
        font-size: 14px;
        color: white;
        margin: 0 0 0 18px;
        white-space: nowrap;
      }

      &:after {
        position: absolute;
        left: 50%;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        transform: rotate(45deg) translateX(-5%) translateY(70%);
        background-color: ${colors.gray5};
        border-radius: 2px;
      }
    }
  }
`;
