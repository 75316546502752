import React, { useContext } from "react";
import { DrawerDeleteRestaurantConfirmationContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { drawersOverall, drawersRestaurants } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { restaurantServices } from "services";
import { RestaurantsContext } from "context/RestaurantsContext";
import { NoAvatar } from "assets/img";

export default function DrawerDeleteRestaurantConfirmation({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  restaurant = {},
}) {
  const {
    setFoods,
    setMenus,
    setRestaurant,
    setMenuId,
    setRestaurantDetailNotification,
    closeDrawer,
    setIsDeleteRestaurantModalOpen,
  } = useContext(RestaurantsContext);

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(restaurant.id);
    if (result?.status === 200) {
      setRestaurant(result?.data?.restaurant);
      setMenus(result?.data?.menus);
      let menuFirstData = result?.data?.menus[0];
      setMenuId(menuFirstData?.id);
      setFoods(result?.data?.foods);
    }
  };

  const onDeleteRestaurant = () => {
    customAxios
      .delete(`/restaurant/${restaurant.id}`)
      .then((res) => {
        if (res.status === 200) {
          closePanel();
          setIsDeleteRestaurantModalOpen(false);
          closeDrawer(drawersOverall.editProfile);
          getRestaurantProfile();
          setRestaurantDetailNotification({
            message: "Restaurant Deleted Successfully",
            type: "ban-success",
            table: restaurant?.title,
          });
        } else {
          closePanel();
          setIsDeleteRestaurantModalOpen(false);
          setRestaurantDetailNotification({
            message: "Restaurant have Active Orders",
            type: "ban-success",
            table: restaurant?.title,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <DrawerDeleteRestaurantConfirmationContainer
      isActive={isActive}
      closePanel={closePanel}
      containerClassName="delete-restaurant-drawer"
      top="30%"
    >
      <DrawerContainer>
        <div className="set-status-wrapper mt-auto">
          <div className="modal-image">
            <img
              src={restaurant?.photo ? restaurant?.photo?.file : NoAvatar}
              alt=""
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div style={{ marginTop: "45px" }}>
            <h2 className="text-white">Are you sure?</h2>
            <p className="text-white">
              <b>{restaurant.title}</b> will be deleted.
            </p>
            <p className="delete-restaurant-undone" style={{opacity: 0.6}}>This cannot be undone.</p>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-5 keep-btn"
                onClick={closePanel}
              >
                Keep Restaurant
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                className="delete-btn"
                onClick={onDeleteRestaurant}
              >
                Yes, delete Restaurant
              </MyDrawerButton>
            </div>
          </div>
        </div>
      </DrawerContainer>
    </DrawerDeleteRestaurantConfirmationContainer>
  );
}
