import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { MyFormatInputContainer } from "./style";
import SingleText from "components/SingleText";

export default function MyFormatInput({
  placeholder = "",
  label = "",
  className = "",
  value = "",
  onChange = () => {},
  format = "+# (###) ###-####",
  style = {},
  type = "outline",
  height = "50px",
  onBlur = () => {},
}) {
  return (
    <MyFormatInputContainer height={height} type={type} className={className}>
      {label && (
        <SingleText size={14} opacity={0.3} lineHeight={1.57} spacing="-0.34px">
          {label}
        </SingleText>
      )}
      <NumberFormat
        format={format}
        className={`single-ant-format-input`}
        value={value}
        onValueChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        style={style}
      />
    </MyFormatInputContainer>
  );
}

MyFormatInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  format: PropTypes.string,
  style: PropTypes.object,
  onBlur: PropTypes.func,
};
