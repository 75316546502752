import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerFoodContainer = styled(MyDrawerRight)`
  .add-item-input {
    input {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: -0.34px;
      color: #fff;
    }
  }
  .tab-switcher-container {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #27282a;
    margin: 20px 0;
    .tab-switcher-item {
      flex: 1 1 50%;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      cursor: pointer;
      &.active {
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .bottom-btns {
    .add-btn {
      margin: 45px 0 23px 0;
    }
  }

  .switch-refill {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
    transform: translateY(5px);
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: ${colors.green};
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
`;
