import React, { useState, createContext, useEffect } from "react";
import { restaurantServices } from "services";

const RestaurantsContext = createContext({});

export { RestaurantsContext };

export const RestaurantsContextContainer = (props) => {
  const [restaurant, setRestaurant] = useState({});
  const [postRestaurant, setPostRestaurant] = useState({});
  // const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedMenu, setSelectedMenu] = useState();
  const [postMenu, setPostMenu] = useState({});
  const [postCategory, setPostCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [postFood, setPostFood] = useState({});
  const [foods, setFoods] = useState([]);
  const [menus, setMenus] = useState([]);
  const [postFloor, setPostFloor] = useState({});
  const [postTable, setPostTable] = useState({});
  const [postWall, setPostWall] = useState({});
  const [floors, setFloors] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [postStaff, setPostStaff] = useState({});
  const [openDrawers, setOpenDrawers] = useState({});
  const [floorId, setFloorId] = useState(1);
  const [nutritionModal, setNutritionModal] = useState(undefined);
  const [selectedDayToEdit, setSelectedDayToEdit] = useState();

  const [
    isRestaurantTableLayoutVisible,
    setIsRestaurantTableLayoutVisible,
  ] = useState(true);

  const [foodId, setFoodId] = useState();

  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [
    restaurantListNotification,
    setRestaurantListNotification,
  ] = useState();

  const fetchRestaurant = async (restaurantId) => {
    const result = await restaurantServices.getRestaurantDetail(restaurantId);
    if (result.status === 200) {
      setRestaurant(result.data.restaurant);
      setMenus(result.data.menus);
      // console.table(result?.data?.restaurant, '----')
      if (selectedMenu?.id) {
        setSelectedMenu(
          result.data.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      }
    }
  };

  const fetchFloors = async (id) => {
    const result = await restaurantServices.getRestaurantFloors(id);
    if (result.status === 200) {
      setFloors(result.data);
      // console.log(result.data, 'table-list & rotaion')
    }
    return result;
  };

  const openDrawer = (drawer) => {
    // console.log(drawer)
    let temp = { ...openDrawers };
    // console.log(temp)
    if (Array.isArray(drawer)) {
      drawer.forEach((_drawer) => {
        temp = { ...temp, [_drawer]: _drawer };
      });
    } else {
      temp = { ...temp, [drawer]: drawer };
    }
    setOpenDrawers(temp);
  };

  const closeDrawer = (drawer) => {
    // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
    let temp = { ...openDrawers };
    if (Array.isArray(drawer)) {
      drawer.forEach((_drawer) => {
        delete temp[_drawer];
      });
    } else {
      delete temp[drawer];
    }
    setOpenDrawers(temp);
  };

  // -----panel-------
  // const openDrawer = (drawer) => {
  //   setOpenDrawers({ ...openDrawers, [drawer]: drawer });
  // };
  // const closeDrawer = (drawer) => {
  //   // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
  //   let temp = { ...openDrawers };
  //   delete temp[drawer];
  //   setOpenDrawers(temp);
  // };
  // -------------

  const [restaurantList, setRestaurantList] = useState([]);
  const [activeStatus, setActiveStatus] = useState();
  const [activeLength, setActiveLength] = useState();
  const [restaurantId, setRestaurantId] = useState([]);
  const [resId, setResId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [categoryId, setCategoryId] = useState();

  const [managerDetail, setManagerDetail] = useState();

  const [latlng, setLatLng] = useState();
  const [menuId, setMenuId] = useState();

  const [globalMenuId, setGlobalMenuId] = useState();
  const [selectedFloorId, setSelectedFloorId] = useState();

  const [nutritionPost, setNutritionPost] = useState({});

  const [customizationData, setCustomizationData] = useState([]);

  // post-customization add-option
  const [addOption, setAddOption] = useState([]);

  const [editCustomizationInfo, setEditCustomizationInfo] = useState({});

  // edit-customization add-option
  const [editOption, setEditOption] = useState([]);

  const [editOptionInfo, setEditOptionInfo] = useState({});

  const [cuisineGlobalData, setCuisineGlobalData] = useState([]);

  const [heightRateItem, setHeightRateItem] = useState({});

  const [selectedStaffRole, setSelectedStaffRole] = useState();

  const [notesData, setNotesData] = useState();
  const [noteId, setNoteId] = useState();

  const [availabilityDrawerType, setAvailabilityDrawerType] = useState();

  const [staffDetails, setStaffDetails] = useState();

  const [staffGlobalDetail, setStaffGlobalDetail] = useState();

  const [chooseCloseDrawer, setChooseCloseDrawer] = useState({
    foods: false,
    addType: false,
    existingItemAdded: false,
    onFailAddExistingItem: false,
  });

  const [categoryCloseDrawer, setCategoryCloseDrawer] = useState({
    categoryEdit: false,
    openDrawerCategoryFirstOptions: false,
    openDrawerCategoryMoveItem: false,
    openDrawerDeleteMenuAndItem: false,
  });

  const [menuCloseDrawer, setMenuCloseDrawer] = useState({
    menuEdit: false,
    deleteMenu: false,
    moveItems: false,
    deleteConfirm: false,
  });

  const [staffCloseDrawer, setStaffCloseDrawer] = useState({
    staffDelete: false,
    staffDeleteConfirm: false,
  });

  const [
    staffRemoveInsideEditOption,
    setStaffRemoveInsideEditOption,
  ] = useState({
    staffRemove: false,
    staffRemoveConfirm: false,
  });

  const [
    restauranProfileSelectedMenu,
    setRestaurantProfileSelectedMenu,
  ] = useState(null);

  // coupon post global state
  const [promoCode, setPromoCode] = useState();
  const [discountType, setDiscountType] = useState();
  const [discountFixedAmount, setDiscountFixedAmount] = useState();
  const [discountPercentage, setDiscountPercentage] = useState();
  const [expirationTime, setExpirationTime] = useState("10:00");
  const [expirationTimeType, setExpirationTimeType] = useState("am");
  const [totalUseTimes, setTotalUseTimes] = useState();
  const [useTimesPerCustomer, setUseTimesPerCustomer] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [selectedMenuCategories, setSelectedMenucatogories] = useState([]);

  const [updatePromoCodeDetails, setUpdatePromoCodeDetails] = useState({});

  // coupon update local state
  const [localTotalUseTimes, setLocalTotalUseTimes] = useState();
  const [localUseTimesPerCustomer, setLocalUseTimesPerCustomer] = useState();
  const [localStartDate, setLocalStartDate] = useState();
  const [localEndDate, setLocalEndDate] = useState();
  const [localPromoCode, setLocalPromoCode] = useState();

  const [couponData, setCouponData] = useState([]);
  const [promoCodeStatus, setPromoCodeStatus] = useState(1);
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [
    restaurantDetailNotification,
    setRestaurantDetailNotification,
  ] = useState();

  const [totalUseTimeToggle, setTotalUseTimeToggle] = useState(false);
  const [useTimePerCustomerToggle, setUseTimePerCustomerToggle] = useState(
    false
  );

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [activeDateToggle, setActiveDateToggle] = useState(false);

  const [restaurantStaffFilter, setRestaurantStaffFilter] = useState();
  const [restaurantStatusFilter, setRestaurantStatusFilter] = useState();

  const [foodReviewsId, setFoodReviewsId] = useState();

  const [cuisineList, setCuisineList] = useState([]);

  const [updateCuisineDetailsData, setUpdateCuisineDetailsData] = useState([]);

  const [cuisineCloseDrawer, setCuisineCloseDrawer] = useState({
    addCuisines: false,
    deleteCuisines: false,
    deleteConfirmCuisines: false,
  });

  const [cuisineImageFile, setCuisineImageFile] = useState();

  const [statusActiveNotification, setStatusActiveNotification] = useState();
  const [
    statusInactiveNotification,
    setStatusInactiveNotification,
  ] = useState();

  const [searchPromoCode, setSearchPromoCode] = useState("");

  const [finalDiscountDollarFrom, setFinalDiscountDollarFrom] = useState("");
  const [finalDiscountDollarTo, setFinalDiscountDollarTo] = useState("");

  const [finalDiscountPercentTo, setFinalDiscountPercentTo] = useState("");
  const [finalDiscountPercentFrom, setFinalDiscountPercentFrom] = useState("");

  const [restaurantEditMoreModal, setRestaurantEditMoreModal] = useState(false);
  const [banRestaurantModal, setBanRestaurantModal] = useState(false);

  const [
    isDeleteRestaurantModalOpen,
    setIsDeleteRestaurantModalOpen,
  ] = useState(false);

  const [activeNotes, setActiveNotes] = useState("");
  const [inactiveNote, setInactiveNote] = useState("");
  const [reason, setReason] = useState("");

  const [drawerEditMenus, setDrawerEditMenus] = useState({
    menus: false,
  });

  const [drawerMenuAdd, setDrawerMenuAdd] = useState({
    menuAdd: false,
  });

  const [
    drawerAlreadyExistMenuCategory,
    setDrawerAlreadyExistMenuCategory,
  ] = useState(false);

  const [isRestoreDataEnable, setRestoreDataEnable] = useState(true);
  const [reviewRestoreData, setReviewRestoreData] = useState();
  const [staffViewData, setStaffViewData] = useState({});
  const [isActiveStaffFilter, setActiveStaffFilter] = useState(false);

  const [deleteReviewId, setDeleteReviewId] = useState();
  const [restoreReviewId, setRestoreReviewId] = useState();

  const [staffRoleSlugFilter, setStaffRoleSlugFilter] = useState([]);
  const [staffStatusFilter, setStaffStatusFilter] = useState([]);

  const [staffSearchInput, setStaffSearchInput] = useState("");

  const [staffChangeRoleMainModal, setStaffChangeRoleMainModal] = useState({
    staffServerHostActiveModal: false,
  });

  const [removeFromRestaurantModal, setRemoveFromRestaurantModal] = useState({
    removeFromRestaurant: false,
  });

  const [
    removeFromRestaurantConfirmModal,
    setRemoveFromRestaurantConfirmModal,
  ] = useState({
    removeConfirmFromRestaurant: false,
  });

  const [
    staffRoleSlugArchivedFilter,
    setStaffRoleSlugArchivedFilter,
  ] = useState("");
  const [staffStatusArchivedFilter, setStaffStatusArchivedFilter] = useState(
    []
  );

  const [archivedData, setArchivedData] = useState([]);

  const [roleTitle, setRoleTitle] = useState([]);
  const [statusTitle, setStatusTitle] = useState([]);

  const [roleArchivedTitle, setRoleArchivedTitle] = useState("");
  const [statusArchivedTitle, setStatusArchivedTitle] = useState("");

  const [archivedMainModal, setArchivedMainModal] = useState({
    archivedMainOption: false,
  });

  return (
    <RestaurantsContext.Provider
      value={{
        fetchRestaurant,
        fetchFloors,
        openDrawers,
        openDrawer,
        closeDrawer,
        restaurant,
        setRestaurant,
        postRestaurant,
        setPostRestaurant,
        selectedMenu,
        setSelectedMenu,
        postMenu,
        setPostMenu,
        postFloor,
        setPostFloor,
        postCategory,
        setPostCategory,
        selectedCategory,
        setSelectedCategory,
        postFood,
        setPostFood,
        foods,
        setFoods,
        menus,
        setMenus,
        floors,
        setFloors,
        staffs,
        setStaffs,
        postStaff,
        setPostStaff,
        floorId,
        setFloorId,
        postTable,
        setPostTable,
        postWall,
        setPostWall,

        nutritionModal,
        setNutritionModal,

        restaurantList,
        setRestaurantList,
        activeStatus,
        setActiveStatus,
        activeLength,
        setActiveLength,
        restaurantId,
        setRestaurantId,
        resId,
        setResId,
        isOpen,
        setIsOpen,

        searchInput,
        setSearchInput,

        foodId,
        setFoodId,

        categoryId,
        setCategoryId,

        selectedCuisines,
        setSelectedCuisines,

        managerDetail,
        setManagerDetail,

        latlng,
        setLatLng,

        selectedDayToEdit,
        setSelectedDayToEdit,

        menuId,
        setMenuId,

        globalMenuId,
        setGlobalMenuId,

        selectedFloorId,
        setSelectedFloorId,

        nutritionPost,
        setNutritionPost,

        customizationData,
        setCustomizationData,

        addOption,
        setAddOption,

        editCustomizationInfo,
        setEditCustomizationInfo,

        editOption,
        setEditOption,

        editOptionInfo,
        setEditOptionInfo,

        cuisineGlobalData,
        setCuisineGlobalData,

        heightRateItem,
        setHeightRateItem,

        selectedStaffRole,
        setSelectedStaffRole,

        notesData,
        setNotesData,

        noteId,
        setNoteId,

        availabilityDrawerType,
        setAvailabilityDrawerType,

        staffDetails,
        setStaffDetails,

        staffGlobalDetail,
        setStaffGlobalDetail,

        chooseCloseDrawer,
        setChooseCloseDrawer,

        categoryCloseDrawer,
        setCategoryCloseDrawer,

        menuCloseDrawer,
        setMenuCloseDrawer,

        staffCloseDrawer,
        setStaffCloseDrawer,

        staffRemoveInsideEditOption,
        setStaffRemoveInsideEditOption,

        restauranProfileSelectedMenu,
        setRestaurantProfileSelectedMenu,

        promoCode,
        setPromoCode,

        discountType,
        setDiscountType,

        discountFixedAmount,
        setDiscountFixedAmount,

        discountPercentage,
        setDiscountPercentage,

        totalUseTimes,
        setTotalUseTimes,

        useTimesPerCustomer,
        setUseTimesPerCustomer,

        startDate,
        setStartDate,

        endDate,
        setEndDate,
        expirationTime,
        setExpirationTime,
        expirationTimeType,
        setExpirationTimeType,
        selectedMenuCategories,
        setSelectedMenucatogories,

        updatePromoCodeDetails,
        setUpdatePromoCodeDetails,

        // coupon local
        localTotalUseTimes,
        setLocalTotalUseTimes,
        localUseTimesPerCustomer,
        setLocalUseTimesPerCustomer,
        localStartDate,
        setLocalStartDate,
        localEndDate,
        setLocalEndDate,
        localPromoCode,
        setLocalPromoCode,

        couponData,
        setCouponData,
        promoCodeStatus,
        setPromoCodeStatus,
        selectedRestaurants,
        setSelectedRestaurants,
        restaurantListNotification,
        setRestaurantListNotification,
        restaurantDetailNotification,
        setRestaurantDetailNotification,

        totalUseTimeToggle,
        setTotalUseTimeToggle,
        useTimePerCustomerToggle,
        setUseTimePerCustomerToggle,

        state,
        setState,

        activeDateToggle,
        setActiveDateToggle,

        restaurantStaffFilter,
        setRestaurantStaffFilter,

        restaurantStatusFilter,
        setRestaurantStatusFilter,

        foodReviewsId,
        setFoodReviewsId,

        cuisineList,
        setCuisineList,

        isRestaurantTableLayoutVisible,
        setIsRestaurantTableLayoutVisible,

        updateCuisineDetailsData,
        setUpdateCuisineDetailsData,

        cuisineCloseDrawer,
        setCuisineCloseDrawer,

        cuisineImageFile,
        setCuisineImageFile,

        statusActiveNotification,
        setStatusActiveNotification,

        statusInactiveNotification,
        setStatusInactiveNotification,

        // promo code filter
        searchPromoCode,
        setSearchPromoCode,

        finalDiscountDollarFrom,
        setFinalDiscountDollarFrom,

        finalDiscountDollarTo,
        setFinalDiscountDollarTo,

        finalDiscountPercentTo,
        setFinalDiscountPercentTo,

        finalDiscountPercentFrom,
        setFinalDiscountPercentFrom,

        restaurantEditMoreModal,
        setRestaurantEditMoreModal,
        banRestaurantModal,
        setBanRestaurantModal,

        isDeleteRestaurantModalOpen,
        setIsDeleteRestaurantModalOpen,

        activeNotes,
        setActiveNotes,

        inactiveNote,
        setInactiveNote,

        reason,
        setReason,

        drawerEditMenus,
        setDrawerEditMenus,

        drawerMenuAdd,
        setDrawerMenuAdd,

        drawerAlreadyExistMenuCategory,
        setDrawerAlreadyExistMenuCategory,

        isRestoreDataEnable,
        setRestoreDataEnable,

        reviewRestoreData,
        setReviewRestoreData,

        staffViewData,
        setStaffViewData,

        isActiveStaffFilter,
        setActiveStaffFilter,

        deleteReviewId,
        setDeleteReviewId,

        restoreReviewId,
        setRestoreReviewId,

        staffRoleSlugFilter,
        setStaffRoleSlugFilter,
        staffStatusFilter,
        setStaffStatusFilter,

        staffSearchInput,
        setStaffSearchInput,

        staffChangeRoleMainModal,
        setStaffChangeRoleMainModal,

        removeFromRestaurantModal,
        setRemoveFromRestaurantModal,

        removeFromRestaurantConfirmModal,
        setRemoveFromRestaurantConfirmModal,

        staffRoleSlugArchivedFilter,
        setStaffRoleSlugArchivedFilter,

        staffStatusArchivedFilter,
        setStaffStatusArchivedFilter,

        archivedData,
        setArchivedData,

        roleTitle,
        setRoleTitle,

        statusTitle,
        setStatusTitle,

        roleArchivedTitle,
        setRoleArchivedTitle,

        statusArchivedTitle,
        setStatusArchivedTitle,

        archivedMainModal,
        setArchivedMainModal,
      }}
    >
      {props.children}
    </RestaurantsContext.Provider>
  );
};
