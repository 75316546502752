import React, { useContext, useState, useEffect } from "react";
import { DrawerDeleteRestaurantContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import DrawerDeleteRestaurantConfirmation from "./DrawerDeleteReataurantConfirmation";
import { RestaurantsContext } from "context/RestaurantsContext";
import { NoAvatar } from "assets/img";
import { colors } from "theme";

export default function DrawerDeleteRestaurant({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  restaurant = {},
}) {
  const { openDrawers, openDrawer, closeDrawer } = useContext(
    RestaurantsContext
  );

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(() => {
    if (isActive) {
      setConfirmationModalOpen(false);
    }
  }, [isActive]);

  return (
    <DrawerDeleteRestaurantContainer
      isActive={isActive}
      closePanel={closePanel}
      containerClassName="delete-restaurant-drawer"
      top="30%"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="set-status-wrapper mt-auto">
          <div className="modal-image">
            <img src={restaurant?.photo ? restaurant?.photo?.file : NoAvatar} alt="" style={{ borderRadius: "50%" }} />
          </div>
          <div style={{marginTop: '45px'}} >
            <h2 className="text-white">Delete Restaurant</h2>
            <p className="text-white">
              Are you sure you want to delete <b>{restaurant?.title}</b>?
            </p>
            <p className="delete-restaurant-undone" style={{opacity: 0.6}}>This cannot be undone.</p>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                className="mb-5 keep-btn"
                onClick={closePanel}
              >
                Keep Restaurant
              </MyDrawerButton>
              <MyDrawerButton
                type="gray"
                className="delete-btn"
                onClick={() => setConfirmationModalOpen(true)}
              >
                Delete Restaurant
              </MyDrawerButton>
            </div>
          </div>
        </div>
      </DrawerContainer>
      <DrawerDeleteRestaurantConfirmation
        isActive={confirmationModalOpen}
        closePanel={() => setConfirmationModalOpen(false)}
        restaurant={restaurant}
      />
    </DrawerDeleteRestaurantContainer>
  );
}
