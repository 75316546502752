import React, { useState, useEffect, useContext } from "react";
import { StaffProfileCardContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { NoAvatar } from "assets/img";
import MyButton from "components/Elements/MyButton";

export const StaffProfileCard = () => {
  const { restaurant, staffViewData } = useContext(RestaurantsContext);
  return (
    <StaffProfileCardContainer>
      <div className="profile-info-header d-flex justify-content-between align-items-start">
        <div className="profile-info-avatar">
          <img className="rounded-circle profile-picture" src={NoAvatar} />
          <div className="customer-profile-rating">
            <div className="rating-container">
              <div className="rating-icon">
                <i className="fas fa-star" />
              </div>
              <span className="rating-number">4.5</span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start mr-auto">
          <div className="d-flex">
            <div className="profile-info-name text-bold">
              {staffViewData?.first_name} {staffViewData?.last_name}{" "}
            </div>
            <div className="staff-status">Active</div>
          </div>
          <div className="staff-info">
            <div>
              <div className="info-label"><i className="fas fa-mobile-alt" style={{marginRight: '10px'}} />Phone</div>
              <div className="info">+1 (555) 248-0123</div>
            </div>

            <div>
              <div className="info-label"><i className="fas fa-envelope" style={{marginRight: '10px'}} />Email Address</div>
              <div className="info">mjasper@gmail.com</div>
            </div>

            <div>
              <div className="info-label" style={{textAlign: 'right'}}>Joined</div>
              <div className="info">Oct 12, 2020, 6:54 AM</div>
            </div>
          </div>
        </div>
        <MyButton type="gray" className="edit-btn">
          Edit
        </MyButton>
      </div>
    </StaffProfileCardContainer>
  );
};
