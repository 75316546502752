import styled from "@emotion/styled";
import { colors } from "theme";

export const MySelectAdminDropdownContainer = styled.div`
  position: relative;
  .label-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.34px;
    color: #fff;
  }
  .states-list-div {
    position: absolute;
    z-index: 9;
    bottom: -24rem;
    left: 0;
    padding: 12px 16px 12px 16px;
    border-radius: 20px;
    background-color: #27282a;
    width: 339px;
    height: 300px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.gray5};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.white};
      border-radius: 5px;
    }
    overflow-y: auto;
    .state-name-div {
      margin-bottom: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name-container {
        display: flex;
        align-items: center;
        gap: 10px;
        .unselected {
          width: 20px;
          height: 20px;
          border-radius: 100px;
          background-color: #1b1c1d;
        }
        .selected {
          width: 20px;
          height: 20px;
          border-radius: 100px;
          background-color: #1fc086;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 8px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.19px;
            text-align: center;
            color: #fff;
            position: relative;
            left: 4px;
            bottom: 2px;
          }
        }
        .state-name {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: -0.34px;
          color: #fff;
        }
      }
      .state-abbreviation {
        opacity: 0.4;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.34px;
        text-align: right;
        color: #fff;
      }
    }
  }
  .state-input {
    background: transparent;
    /* border-radius: 20px; */
    padding: 12px 12px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
    /* margin-top: 25px; */
    position: relative;
    bottom: 0;
    border-bottom: 1px solid #525558;
    i {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.38px;
      text-align: right;
      color: #fff;
      opacity: 0.5;
    }
    .select-text {
      opacity: 1;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: -0.34px;
      color: #ed2626;
    }
    .state-name-render-div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      .state-abbr-name {
        display: flex;
        align-items: center;
        gap: 10px !important;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.34px;
        color: #fff;
        i {
          opacity: 0.3;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: right;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
`;
