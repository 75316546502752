/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { MySelectContainer } from "./style";
import { Dropdown } from "react-bootstrap";

export default function MySelect({
  className = "",
  titleKey = "name",
  options = [],
  defaultValueId,
  onChange = () => {},
  renderExtra = () => {},
  defaultValue,
  dropdownLabel = "Show",
}) {
  // const [value, setValue] = useState({id: 0, name: "All Items"});
  const [value, setValue] = useState(null);

  useEffect(() => {
    //  console.log(value)
    if (defaultValueId) {
      setValue(defaultValueId);
    }
  }, [defaultValueId]);

  const onSelect = (option) => {
    setValue(options.find((_option) => _option.id === option.id));
    onChange(option);
  };

  return (
    <MySelectContainer className={className}>
      <Dropdown.Toggle id="dropdown-basic">
        <span className="dropdown-title">{dropdownLabel}</span>
        <span className="mr-2">
          {value
            ? value[titleKey]
            : defaultValueId
            ? options.find((_option) => _option.id == defaultValueId)
              ? options.find((_option) => _option.id == defaultValueId)[
                  titleKey
                ]
              : "Select"
            : "Select"}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((_option, _i) => (
          <Dropdown.Item
            key={_i}
            onClick={() => onSelect(_option)}
            active={value && value.id == _option.id ? true : false}
          >
            {_option.name}
          </Dropdown.Item>
        ))}
        {renderExtra() && <Dropdown.Item>{renderExtra()}</Dropdown.Item>}
      </Dropdown.Menu>
    </MySelectContainer>
  );
}
