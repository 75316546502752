import React, { useState, createContext } from "react";

const MyAccountContext = createContext({});

export { MyAccountContext };

export const MyAccountContextContainer = (props) => {
  const [myAccount, setMyAccount] = useState({});
  const [openDrawers, setOpenDrawers] = useState({});
  const openDrawer = (drawer) => {
    setOpenDrawers({ ...openDrawers, [drawer]: drawer });
  };
  const closeDrawer = (drawer) => {
    // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
    let temp = { ...openDrawers };
    delete temp[drawer];
    setOpenDrawers(temp);
  };

  const [emailField, setEmailField] = useState("");

  const [drawerChangeEmailClose, setDrawerChangeEmailClose] = useState({
    changeEmail : false,
    // emailSuccess : false,
  })

  return (
    <MyAccountContext.Provider
      value={{
        myAccount,
        setMyAccount,

        openDrawers,
        openDrawer,
        closeDrawer,

        emailField,
        setEmailField,

        drawerChangeEmailClose, 
        setDrawerChangeEmailClose,
      }}
    >
      {props.children}
    </MyAccountContext.Provider>
  );
};
