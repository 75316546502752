import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerFailAddItemInExistingContainer = styled(MyDrawerRight)`
  .added-existing-item-img {
    width: 380px;
    height: 248px;
    margin-bottom: 35px;
  }
  .added-existing-item-meta {
    .oops-text {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.42px;
      text-align: center;
      color: #fff;
      margin-bottom: 10px;
    }
    .something-went-wrong-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.27px;
      text-align: center;
      color: #fff;
      margin-bottom: 35px;
    }
  }
  .added-existing-item-btn {
    width: 100%;
  }
`;
