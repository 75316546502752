import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React from "react";
import { DrawerReviewDeletedContainer } from "./style";

export default function DrawerDeletedSubOrder({
  isActive = false,
  closePanel = () => {},
  orderDelete = false,
  onSucessFullDeleteOrder = () => {},
}) {
  return (
    <DrawerReviewDeletedContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper>
          <div className="drawer-bottom-content w-100">
            <h3>{orderDelete ? "Order Deleted" : "Sub-Order Deleted"}</h3>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                onClick={() => {
                  if (orderDelete) {
                    onSucessFullDeleteOrder();
                    closePanel();
                  } else {
                    closePanel();
                  }
                }}
              >
                Okay
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerReviewDeletedContainer>
  );
}
