import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadgeContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';

export function StatusBadge({
  className = 'status-menu-item',
  title = '',
  value = 0,
  backgroundColor = '',
}) {
  return (
    <StatusBadgeContainer className={className}>
      <SingleText
        content={title}
        weight={500}
        spacing='-0.34px'
        marginRight={6}
      >
        {title}
      </SingleText>
      <Box className='value-container' backgroundColor={backgroundColor}>
        <SingleText content={value} weight={600} spacing='-0.36px'>
          {value}
        </SingleText>
      </Box>
    </StatusBadgeContainer>
  );
}

StatusBadge.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  backgroundColor: PropTypes.string,
};
