import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerReviewContainer = styled(MyDrawerRight)`
  .reviews-wrapper {
    /* padding: 20px !important; */

    .top-title {
      padding: 20px !important;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 49px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }

    .top-item-image {
      /* position: absolute;
      top: 0; */
      position: absolute;
      top: 0;
      .fa-times {
        font-size: 28px;
        font-weight: 400;
        color: white;
        position: absolute;
        top: 3rem;
        left: 30px;
        z-index: 2;
        cursor: pointer;
      }
      img {
        filter: blur(9px);
        height: 350px;
      }
    }

    .item-image {
      img {
        height: 170px;
        position: absolute;
        top: 83px;
        border-radius: 30px;
        object-fit: cover;
        width: 350px;
        left: 21px;
      }
    }

    .item-title {
      h4 {
        margin-top: 206px;
        text-align: center;
        position: relative;
        font-weight: bold;
      }
    }

    .overlay-static-drawer {
      background-color: rgb(27, 28, 29);
      height: 47rem;
      width: 100%;
      position: absolute;
      bottom: 0px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 30px !important;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: ${colors.gray5};
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${colors.white};
        border-radius: 5px;
      }
      .raiting-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .fa-star {
          color: rgb(255, 211, 95);
          font-size: 26px;
        }
        span {
          font-size: 24px;
          font-weight: bold;
        }
        .sort-badge {
          background: #27282a;
          height: 35px;
          width: 76px;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .reviews-count {
        margin-top: 19px;
        margin-bottom: 35px;
      }

      .reviews-details {
        hr {
          border: 1px solid #2e2e2e;
          margin-bottom: 32px;
        }
        .avatar-row {
          display: flex;
          justify-content: space-between;
          .avatar-name {
            display: flex;
            gap: 10px;
            align-items: center;
            .picture {
              height: 45px;
              width: 45px;
              border-radius: 50px;
            }
          }

          .star-rating {
            display: flex;
            gap: 5px;
            .fa-star {
              color: rgb(255, 211, 95);
              font-size: 15px;
            }
          }
        }

        .description {
          color: #fff;
          margin-top: 20px;
          opacity: 0.8;
        }

        .feast-row {
          display: flex;
          gap: 30px;
          margin-top: 20px;
          margin-bottom: 25px;
          .feast-here {
            .feast-here-digit {
              margin-bottom: 10px;
            }
            .feast-here-text {
              opacity: 0.6;
            }
          }
          .total-feasts {
            .total-feast-digit {
              margin-bottom: 10px;
            }
            .total-feast-text {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
`;
