/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { MyDrawerBottomContainer } from "./style";

export function MyDrawerBottom({
  children,
  className = "",
  isActive = false,
  closePanel = () => {},
}) {
  return (
    <MyDrawerBottomContainer>
      <div
        className="offcanvas offcanvas-left"
        style={isActive ? { bottom: 0 } : {}}
      >
        {/* <div className="offcanvas offcanvas-bottom" style={isActive ? { right: 0 } : {}}> */}
        <div className={`drawer-content-wrapper ${className}`}>{children}</div>
      </div>
      <div
        className={`offcanvas-overlay`}
        style={isActive ? {} : { display: "none" }}
        onClick={() => closePanel(false)}
      ></div>
    </MyDrawerBottomContainer>
  );
}
