import React, { useState, useContext, useEffect } from "react";
import { DateRangeContainer, DrawerPromoCodeDateContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
// import { DrawerContainer } from "components/styledStyle";
import { Switch } from "@material-ui/core";
import moment from "moment";
import { colors } from "theme";
// import MyDateRange from "components/Elements/MyDateRange";

const PromoCodeDateDrawerModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
}) => {
  const {
    startDate,
    setStartDate,
    setEndDate,
    localStartDate,
    setLocalStartDate,
    localEndDate,
    setLocalEndDate,
    updatePromoCodeDetails,
    state,
    setState,
    activeDateToggle,
    setActiveDateToggle,
  } = useContext(RestaurantsContext);

  // const [activeDateToggle, setActiveDateToggle] = useState(false);

  const todayDate = moment(state[0]?.startDate).format("LL");

  const sDate = moment(state[0]?.startDate).format("LL");
  const eDate = moment(state[0]?.endDate).format("LL");

  useEffect(() => {
    if (Object.keys(updatePromoCodeDetails)?.length) {
      setState([
        {
          startDate: new Date(updatePromoCodeDetails?.start_date),
          endDate: new Date(updatePromoCodeDetails?.end_date),
          key: "selection",
        },
      ]);
    } else {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  }, [updatePromoCodeDetails]);

  function handleChangeToggle(event) {
    setActiveDateToggle(event.target.checked);
  }

  const onChangeDate = (date) => {
    if (date && date.endDate) {
      setLocalStartDate(moment(date.startDate).format("YYYY-MM-DD"));
      setLocalEndDate(moment(date.endDate).format("YYYY-MM-DD"));
    }
  };

  function handleOnClick() {
    if (activeDateToggle === true) {
      setStartDate(null);
      setEndDate(null);
      // setStartDate("");
      // setEndDate("");
    } else {
      setStartDate(localStartDate);
      setEndDate(localEndDate);
    }
    closePanel();
  }

  useEffect(() => {
    if (Object.keys(updatePromoCodeDetails)?.length) {
      if (
        updatePromoCodeDetails.start_date !== null &&
        updatePromoCodeDetails.end_date !== null
      ) {
        setActiveDateToggle(false);
      } else {
        setActiveDateToggle(true);
      }
    }
  }, [updatePromoCodeDetails.start_date, updatePromoCodeDetails.end_date]);

  function onClickCancel() {
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setActiveDateToggle(false)
    closePanel();
  }

  return (
    <DrawerPromoCodeDateContainer
      isActive={isActive}
      closePanel={closePanel}
      // top="15rem"
      // bottom="12rem"
      left="0"
      top={activeDateToggle === true ? "25%" : "5%"}
      position="absolute"
    >
      {/* <DrawerContainer> */}
      <div className="set-active-container">
        <div className="modal-title">Set Active Dates</div>

        <div className="not-limited-wrapper">
          <div className="not-limited-label">Not Limited</div>
          <div>
            <Switch checked={activeDateToggle} onChange={handleChangeToggle} />
          </div>
        </div>

        {activeDateToggle === true ? null : (
          <>
            {/* <MyDateRange months={1} onChangeDate={onChangeDate} /> */}
            <DateRangeContainer
              // className={`my-datepicker ${className}`}
              editableDateInputs={true}
              showDateDisplay={false}
              onChange={(item) => {
                // console.log(JSON.stringify(item));
                setState([item.selection]);
                onChangeDate(item.selection);
              }}
              direction="horizontal"
              months={1}
              moveRangeOnFirstSelection={false}
              rangeColors={[colors.redColor, colors.green]}
              ranges={state}
            />

            <div className="show-date">
              {todayDate
                ? todayDate
                : localStartDate && moment(localStartDate).format("LL")}
              {localStartDate === localEndDate
                ? null
                : sDate === eDate
                ? null
                : " - "}
              {sDate === eDate
                ? null
                : localStartDate === localEndDate
                ? null
                : moment(localEndDate).format("LL")}
            </div>
          </>
        )}

        <button
          type="submit"
          className="save-btn"
          onClick={handleOnClick}
          style={
            localStartDate?.length > 0
              ? { marginTop: 0 }
              : { marginTop: "30px" }
          }
        >
          Done
        </button>
        <div className="cancel-btn cursor-pointer" onClick={onClickCancel}>
          Cancel
        </div>
      </div>
      {/* </DrawerContainer> */}
    </DrawerPromoCodeDateContainer>
  );
};

export default PromoCodeDateDrawerModal;
