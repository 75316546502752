import React from "react";
import { FaHome } from "react-icons/fa";
import { ProfileCardRatedContainer } from "./style";
import { FaStar, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import foodImg from "../../../../assets/img/Carbonara.png";
import foodDefaultImg from "../../../../assets/img/default-food.jpg";

export default function ProfileCardRated({
  className = "",
  icon,
  title = "",
  value = "",
  subTitle = "",
  rating = "",
  widgetBackgroundImage = "",
}) {
  return (
    <ProfileCardRatedContainer
      className={`${className}`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
          !widgetBackgroundImage ? foodDefaultImg : widgetBackgroundImage
        })`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="favorite-content">
        <div className="favorite-title">{title}</div>
        <div className="favorite-flex">
          <div className="favorite-title">
            <span>
              {value === "Highest Rated Item" ? (
                <FaThumbsUp className="mr-3" />
              ) : (
                <FaThumbsDown className="mr-3" />
              )}
            </span>
            <span>{value}</span>
          </div>
          <div className="favorite-rating">
            <span>
              {rating === "" ? null : (
                <FaStar
                  className="ml-2"
                  style={{ color: "#fdcb6e", marginRight: "5px" }}
                />
              )}
            </span>
            <span>{rating}</span>
          </div>
        </div>
      </div>
    </ProfileCardRatedContainer>
  );
}
