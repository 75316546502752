import { createGlobalStyle } from "styled-components";
import { colors } from "theme";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Montserrat;
    margin: 0;
    height: 100%;
    color: white;
    background-color: #151617;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      /* background: gainsboro; */
      background: ${colors.gray5};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      /* background: rgba(0, 0, 0, 0.8); */
      background: ${colors.white};
      border-radius: 5px;
    }
    /* &::-webkit-scrollbar-thumb:hover {
      background: #555;
    } */
  }
  
  .cursor { cursor: pointer; }
  .cursor-grab { cursor: grab; }

  h6 {
    font-weight: 400;
  }
  /* p {
    font-size: 14px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  h5 {
    font-size: 24px !important;
  } */

  /* $h1-font-size: 26px
  $h2-font-size: 22.75px
  $h3-font-size: 19.5px
  $h4-font-size: 17.55px
  $h5-font-size: 16.25px
  $h6-font-size: 14px */

  .text-bold {
    font-weight: bold;
  }
  .text-red {
    color: ${colors.redColor};
  }
  .text-green {
    color: ${colors.green};
  }
  .text-orange {
    color: ${colors.orange};
  }
  .opacity1 {
    opacity: 1;
  }
  .opacity6 {
    opacity: 0.6;
  }

  .flex1 {
    flex: 1 !important;
  }
  .flex2 {
    flex: 2 !important;
  }

  .MuiDivider-light {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

export default GlobalStyle;
