import React, { useContext, useState, useEffect } from "react";
import Box from "components/Box";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerElementContainer } from "./style";
import { IconTable } from "assets/img";
import { Image } from "react-bootstrap";
import { drawersMenu } from "static/staticData";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import IconWall from "assets/img/wall-icon.png";

export default function DrawerElement({
  isActive = false,
  closePanel = () => {},
}) {
  const [active, setActive] = useState("");
  const { openDrawer, setPostTable } = useContext(RestaurantsContext);
  useEffect(() => {
    if (isActive) {
      setActive("");
    }
  }, [isActive]);
  return (
    <DrawerElementContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"20%"}
      left={"38%"}
      width="472px"
      // zIndex={"99999999999"}
      // zIndexBackground={999999999}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center text-bold">
          What would you like to add?
        </h2>
        <div className="drawer-options">
          <Box
            className={`drawer-option ${
              active === "table" ? "active" : "inactive"
            }`}
            marginBottom={15}
            cursor="pointer"
            onClick={() => {
              setActive("table");
              setPostTable({ status: 1, seats: 1, type: 1 });
              openDrawer(drawersMenu.addTable);
              closePanel();
            }}
          >
            <Box
              width={20}
              height={20}
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight={10}
            >
              <Image src={IconTable} width="19px" />
            </Box>
            <p>Table</p>
          </Box>
          <Box
            className={`drawer-option ${
              active === "wall" ? "active" : "inactive"
            }`}
            marginBottom={15}
            cursor="pointer"
            onClick={() => {
              setActive("wall");
              openDrawer(drawersMenu.addWall);
              closePanel();
            }}
          >
            <Box
              width={20}
              height={20}
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight={10}
            >
              <Image src={IconWall} width="19px" />
            </Box>
            <p>Wall</p>
          </Box>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton type="redColor" onClick={closePanel}>
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerElementContainer>
  );
}
