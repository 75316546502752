import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerMenuContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .drawer-input-container {
    input {
      font-weight: 500 !important;
    }
  }

  .item-picker-left h6.item-picker-label {
    opacity: 0.4 !important;
  }

  .cancel-btn {
    background: transparent !important;
    color: red !important;
  }
`;
