import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useState, useEffect } from "react";
import { DrawerAddEditClosedDayContainer } from "./style";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import moment from "moment";

export default function DrawerAddEditClosedDay({
  isActive = false,
  closePanel = () => {},
  selectedItem = {},
  onClickSaveClosedDay = () => {},
  selectedId = "",
  onClickEditClosedDay = () => {},
  selectedDateInfo = "",
  onClickDeleteClosedDayModalHandler = () => {},
}) {
  const [name, setName] = useState("");
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  useEffect(() => {
    if (selectedId !== "" && selectedItem?.name !== "") {
      setName(selectedItem.name);
      setIsRepeat(selectedItem.is_annual);
      let convertedDate = moment(selectedItem.closed_date).format("MMMM DD");
      setSelectedDate(convertedDate);
    } else {
      setName("");
      setIsRepeat(false);
      let convertedDate = moment(selectedDateInfo).format("MMMM DD");
      setSelectedDate(convertedDate);
    }
  }, [selectedId, selectedItem]);
  return (
    <DrawerAddEditClosedDayContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div className="add-edit-closed-day">
          <div className="add-edit-form-header d-flex justify-content-between align-items-center">
            <div className="add-edit-form-title">{selectedDate}</div>
            {selectedId !== "" ? (
              <i
                class="far fa-trash cursor-pointer"
                onClick={() => {
                  onClickDeleteClosedDayModalHandler();
                }}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="divider" />
          <div className="input-actions">
            <DrawerInput
              label="Name"
              placeholder="Closed"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="repeated-annualy-switch-div ">
              <div className="required-filed">
                <div className="repeated-annulay-text">Repeat Annually</div>
                <div>
                  <label className="switch-refill">
                    <input
                      type="checkbox"
                      checked={isRepeat}
                      onChange={() => setIsRepeat(!isRepeat)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="divider" />
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={name === "" ? "gray" : "red"}
            onClick={() => {
              if (selectedId !== "") {
                onClickEditClosedDay({
                  name: name,
                  isRepeat: isRepeat,
                  date: selectedItem.date,
                });
              } else {
                onClickSaveClosedDay({
                  name: name,
                  isRepeat: isRepeat,
                  date: selectedItem.date,
                });
              }
              setName("");
              setIsRepeat(false);
              setSelectedDate("");
            }}
            disabled={name === ""}
          >
            <b>Save</b>
          </MyDrawerButton>
          <MyDrawerButton type="gray" onClick={closePanel}>
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerAddEditClosedDayContainer>
  );
}
