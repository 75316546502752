import React, { useState, useEffect, useContext } from "react";
import { ItemRestoreReviewContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawersOverall } from "static/staticData";

const ItemRestoreReviewModal = ({
  isActive = false,
  closePanel = () => {},
  //   onDeleteEntireOrder = () => {},
}) => {
  const { openDrawer, openDrawers, closeDrawer } = useContext(RestaurantsContext);
  const [itemRestoreReviewNotification, setItemRestoreReviewNotification] = useState("");

  useEffect(() => {
    if (itemRestoreReviewNotification) {
      setTimeout(() => setItemRestoreReviewNotification(null), 2 * 1000);
    }
  }, [itemRestoreReviewNotification]);

  //@TODO item restore reviews notificatoin
    // useEffect(() => {
    //     setItemRestoreReviewNotification({
    //     message: "Review Restored.",
    //     type: "restore-review",
    //     table: `<p style="color: #8F949A">Item review was restored.</p>`,
    //   });
    // }, []);

  return (
    <ItemRestoreReviewContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"33%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {itemRestoreReviewNotification && (
          <MyMessageBoxItemNew
            message={
                itemRestoreReviewNotification?.message
                ? itemRestoreReviewNotification.message
                : ""
            }
            table={
                itemRestoreReviewNotification?.table ? itemRestoreReviewNotification.table : ""
            }
            type={
                itemRestoreReviewNotification?.type
                ? itemRestoreReviewNotification.type
                : "success"
            }
            onClick={() => setItemRestoreReviewNotification(null)}
          />
        )}

        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-undo-alt" />
          </div>
          <div className="delete-suborder-title">Remove Review</div>
          <div className="delete-suborder-meta">
            You are about to restore this{" "}
            <span className="text-bold">Review</span>.
            <br /> This review will affect the overall rating and will be <br />{" "}
            visible to Feasttt users. Are you sure?
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              //   onClick={() => {
              //     onDeleteEntireOrder();
              //   }}
            >
              <b>Yes, Restore Review</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-3">
              <b>No, Keep Removed</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ItemRestoreReviewContainer>
  );
};

export default ItemRestoreReviewModal;
