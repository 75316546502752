import styled from "styled-components";
import Moveable from "react-moveable";
import { SyncAlt } from "assets/img";

export const MoveableStyled = styled(Moveable)`
  .moveable-line {
    background: #ed2626 !important;
  }
  .moveable-dashed {
    background: transparent !important;
    &.moveable-horizontal {
      border-top: var(--zoompx) dashed #ed2626 !important;
    }
    &.moveable-vertical {
      border-left: var(--zoompx) dashed #ed2626 !important;
    }
  }
  .moveable-dashed:before {
    color: #ed2626 !important;
  }

  .moveable-rotation {
    .moveable-control {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      background-color: #ed2626;
      background: #ed2626 !important;
      border: none;
      margin-top: -20px;
      margin-left: -20px;
      position: relative;
    }
    .moveable-control:after {
      width: 16px;
      height: 16px;
      background-image: url(${SyncAlt}) !important;
      content: "";
      left: 12px;
      top: 12px;
      position: absolute;
    }
  }

  &.hidden-rotatable {
    .moveable-rotation-line {
      display: none;
    }
  }
`;

export const ContextMenuContainer = styled.div`
  position: absolute;
  border-radius: 12px;
  padding: 5px 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  border: solid 1px rgba(255, 255, 255, 0.08);
  background-color: #27282a;
  /* padding: 17px 25px; */
  /* padding: 9px 23px 9px 25px; */
  /* padding: 8px 5px; */

  .context-menu-item {
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 20px;
    cursor: pointer;
    font-weight: 700;
    /* &.edit {
      color: #ed2626;
      i {
        color: #ed2626;
      }
      if{}
    } */
    ${"" /* border-radius: 6px; */}
    i {
      color: #fff;
    }
    span {
      margin-left: 15px;
    }

    width: 10rem;
    padding-left: 20px;
  }

  .context-menu-item:last-child {
    margin-bottom: 0px;
  }

  .context-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;
