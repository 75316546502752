import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerStaffVerifyEmailContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
`;
