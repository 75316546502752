import styled from "@emotion/styled";
import { colors } from "theme";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const VerifyNewPhoneNumberModalContainer = styled(MyDrawerCenterModal)`
  .enter-new-number {
    position: relative;
    &:before {
      content: "";
      width: 80px;
      height: 80px;
      border: 16px solid #27282a;
      border-radius: 50%;
      background-color: #27282a;
      position: absolute;
      top: -93px;
      left: 164px;
    }
    .modal-image {
      position: absolute;
      left: 13rem;
      top: -7rem;
      .round-circle {
        width: 70px;
        height: 70px;
        border: solid 5px #27282a;
        background-color: rgba(255, 255, 255, 0.08);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 29px;
        }
      }
    }
    .modal-title-new-number {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
      // margin-top: 31px;
    }
    .phone-info-meta {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      margin-top: 16px;
      b {
        font-weight: bold;
      }
    }
    .verify-phone-number {
      margin-top: 28px;
      .verify-code-input {
        width: 100% !important;
        > div {
          display: flex;
          justify-content: space-between;
          input {
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 7px;
            background-color: ${colors.gray2};
            -webkit-transition: background-color 300ms linear;
            -ms-transition: background-color 300ms linear;
            transition: background-color 300ms linear;
            font-family: "Roboto";
            font-weight: bold;
            font-size: 36px;
            letter-spacing: 0.68px;
            color: white;
            caret-color: white;
          }
        }
        &.invalid {
          > div {
            input {
              border: 2px solid ${colors.redColor};
            }
          }
        }
        &.valid {
          > div {
            input {
              background-color: ${colors.green};
              -webkit-transition: background-color
                ${({ animationDuration }) => animationDuration}ms linear;
              -ms-transition: background-color
                ${({ animationDuration }) => animationDuration}ms linear;
              transition: background-color
                ${({ animationDuration }) => animationDuration}ms linear;
            }
          }
        }
      }
    }
    .did-not-get-it-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 32px;

      .did-not-get-it-title {
        font-size: 14px;
        font-weight: 500;
        color: white;
        letter-spacing: 0;
        margin-right: 7px;
      }

      .btn-resend {
        cursor: pointer;
        .resend-title {
          font-size: 16px;
          font-weight: bold;

          &.active {
            color: ${colors.redColor};
          }

          &.inactive {
            color: ${colors.gray5};
          }
        }
      }
    }
    .verify-actions {
      margin-top: 19px;
      text-align: center;
    }
    .account-form-item {
      padding-top: unset;
      width: 542px;
      margin-top: 27px;
      .input-label {
        label {
          opacity: 0.5;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: -0.34px;
          color: #fff;
        }
      }
      .single-ant-format-input {
        width: 100%;
        color: white;
        font-weight: 500;
        background-color: transparent;
        outline: unset;
        border: 0;
      }
    }
    .break-line {
      width: 400px;
    }
    .new-number-action-btns {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  }
`;
