import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerVerifyWebsiteContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .phone-title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .drawer-status--options {
      margin-top: 40px;
  }
`;
