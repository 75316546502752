import { customAxios } from "helpers/custom-helpers/customAxios";

const getRestaurant = async (type, payload) =>
  await customAxios.get(
    `/restaurant/list/${type}${
      payload && Object.keys(payload).length > 0
        ? `?${Object.entries(payload)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : ""
    }`
  );

const updateRestaurant = async (id, payload) =>
  await customAxios.put(`/restaurant/${id}`, payload);

const getRestaurantFloors = async (id) =>
  await customAxios.get(`/restaurant/${id}/floor?get_detail=true`);
const getRestaurantFloorDetail = async (restaurant_id, floor_id) =>
  await customAxios.get(
    `/restaurant/${restaurant_id}/floor/${floor_id}?get_detail=true`
  );

const addNewFloor = async (restaurant_id, payload) =>
  await customAxios.post(`/restaurant/${restaurant_id}/floor`, payload);
const updateFloor = async (restaurant_id, payload) =>
  await customAxios.put(
    `/restaurant/${restaurant_id}/floor/${payload.id}`,
    payload
  );
const deleteFloor = async (restaurant_id, floor_id) =>
  await customAxios.delete(`/restaurant/${restaurant_id}/floor/${floor_id}`);

const getRestaurantMenus = async (id) =>
  await customAxios.get(`/restaurant/${id}/menu`);
const getRestaurantMenuDetail = async (restaurant_id, menu_id) =>
  await customAxios.get(
    `/restaurant/${restaurant_id}/menu/${menu_id}?get_detail=true`
  );

const deleteRestaurant = async (payload) =>
  await customAxios.delete(`/restaurant`, { data: payload });

const setRestaurantStatus = async (payload) =>
  await customAxios.put(`/restaurant​/status`, payload);
const getRestaurantStats = async () =>
  await customAxios.get(`/restaurant/stats`);

const getSearchSuggestions = async ({ query, limit }) =>
  await customAxios.get(`/restaurant/suggestion?limit=${limit}&query=${query}`);
const getRestaurantDetail = async (id) =>
  await customAxios.get(`/restaurant/${id}?get_detail=true`);
const setCuisines = async (id, payload) =>
  await customAxios.put(`/admin/v1/restaurant/${id}/cuisine`, payload);
const setWorkingHours = async (id, payload) =>
  await customAxios.put(`/restaurant/${id}/working_hours`, payload);
const getClosedDays = async (id) =>
  await customAxios.get(`/restaurant/${id}/closed`);
const saveClosedDay = async (id, payload) =>
  await customAxios.post(`/restaurant/${id}/closed`, payload);
const deleteRestaurantClosedDay = async (restaurantId, closedId) =>
  await customAxios.delete(
    `/admin/v1/restaurant/${restaurantId}/closed/${closedId}`
  );
// const getStaffAll = async (id, payload) =>
//   await customAxios.get(
//     `/restaurant/${id}/staff${
//       payload && Object.keys(payload).length > 0
//         ? `?${Object.entries(payload)
//             .map(([key, value]) => `${key}=${value}`)
//             .join("&")}`
//         : ""
//     }`
//   );

const getStaffAll = async (id, payload) =>
  await customAxios.get(
    `/staff${
      payload && Object.keys(payload).length > 0
        ? `?${Object.entries(payload)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : ""
    }`
  );

const getStaffHighest = async (id) =>
  await customAxios.get(`/restaurant/${id}/staff/highest`);

const addStaff = async (id, payload) => {
  return await customAxios.post(`/restaurant/${id}/staff`, payload);
};
const updateStaff = async (id, payload) => {
  return await customAxios.put(
    `/restaurant/${id}/staff/${payload.id}`,
    payload
  );
};

const sortMenus = async (id, payload) => {
  return await customAxios.post(`/restaurant/${id}/menu/sort`, payload);
};
const addNewFoodItem = async (id, payload) => {
  return await customAxios.post(`/restaurant/${id}/food`, payload);
};
const updateFoodItem = async (id, payload) => {
  return await customAxios.put(`/restaurant/${id}/food/${payload.id}`, payload);
};

const deleteFood = async (id, payload) =>
  await customAxios.delete(`/restaurant/${id}/food/${payload.id}`);
const addFoodsToCategory = async (id, menuId, categoryId, payload) =>
  await customAxios.post(
    `/restaurant/${id}/menu/${menuId}/category/${categoryId}/food`,
    payload
  );
const addNewMenu = async (id, payload) =>
  await customAxios.post(`/restaurant/${id}/menu`, payload);
const updateMenu = async (id, payload) =>
  await customAxios.put(`/restaurant/${id}/menu/${payload.id}`, payload);
const deleteMenu = async (id, menuId, payload) =>
  await customAxios.delete(`/restaurant/${id}/menu/${menuId}`, payload);

const addNewCategory = async (id, menuId, payload) =>
  await customAxios.post(`/restaurant/${id}/menu/${menuId}/category`, payload);
const updateCategory = async (id, menuId, categoryId, payload) =>
  await customAxios.put(
    `/restaurant/${id}/menu/${menuId}/category/${categoryId}`,
    payload
  );
const getTables = async (id, payload) =>
  await customAxios.get(
    `/restaurant/${id}/table${
      payload && Object.keys(payload).length > 0
        ? `?${Object.entries(payload)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : ""
    }`
  );

const foodItemSort = async (id, menuid, categoryid, payload) => {
  return await customAxios.post(
    `/restaurant/${id}/menu/${menuid}/category/${categoryid}/food/sort`,
    payload
  );
};

const categoriesSort = (restaurantId, menuId, payload) => {
  customAxios.post(
    `/restaurant/${restaurantId}/menu/${menuId}/category/sort`,
    payload
  );
};

const deleteCategory = (restaurantId, menuId, categoryId) =>
  customAxios.delete(
    `/restaurant/${restaurantId}/menu/${menuId}/category/${categoryId}`
  );

export const restaurantServices = {
  foodItemSort,
  getRestaurant,
  updateRestaurant,
  getRestaurantFloors,
  getRestaurantFloorDetail,
  addNewFloor,
  updateFloor,
  deleteFloor,
  getRestaurantMenus,
  getRestaurantMenuDetail,
  deleteRestaurant,
  setRestaurantStatus,
  getRestaurantStats,
  getSearchSuggestions,
  getRestaurantDetail,
  setCuisines,
  setWorkingHours,
  getClosedDays,
  saveClosedDay,
  deleteRestaurantClosedDay,
  getStaffAll,
  getStaffHighest,
  addStaff,
  updateStaff,
  sortMenus,
  addNewFoodItem,
  updateFoodItem,
  deleteFood,
  addFoodsToCategory,
  addNewMenu,
  updateMenu,
  deleteMenu,
  addNewCategory,
  updateCategory,
  getTables,
  categoriesSort,
  deleteCategory,
};
