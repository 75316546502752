import styled from '@emotion/styled'
import { colors } from 'theme';

export const MyStarRateContainer = styled.div`
  width: 82px;
  height: 31px;
  border-radius: 15.5px;
  background-color: ${colors.redColor};
  p {
    font-weight: 600;
  }
`;
