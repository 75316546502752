import ItemPicker from "components/Elements/DrawerElements/ItemPicker";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { dayTitles, drawersMenu } from "static/staticData";
import DrawerAvailableDay from "../DrawerAvailableDay";
import { DrawerAvailabilitiesContainer } from "./style";

export default function DrawerAvailabilities({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer, closeDrawer, openDrawers, postMenu } = useContext(
    RestaurantsContext
  );
  const [selectedDay, setSelectedDay] = useState({});

  function convertTimeToReadableFormat(time) {
    const formattedTime = time % (12 * 60 * 60);
    const hr = Math.floor(formattedTime / (60 * 60));
    const min = (formattedTime - hr * 60 * 60) / 60;
    const dateTime = Math.floor(time / (12 * 60 * 60)) >= 1 ? "PM" : "AM";

    return `${hr < 10 ? "0" + hr : hr}:${min === 0 ? "00" : min} ${dateTime}`;
    // console.log(`${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`)
  }

  const renderAvailableDay = (_id) => {
    const wasSelectedBefore = postMenu?.availability?.find(
      (x) => x.day === _id
    );
    let dayToDisplay = "";
    if (wasSelectedBefore) {
      const timeRange = wasSelectedBefore.times[0];
      const [startTime, endTime] = timeRange;
      if (startTime === 0 && endTime === 86400) {
        dayToDisplay = "All Day";
      } else {
        dayToDisplay =
          convertTimeToReadableFormat(startTime) +
          " - " +
          convertTimeToReadableFormat(endTime);
      }
    }
    return wasSelectedBefore ? (
      dayToDisplay
    ) : (
      <span style={{ color: "#ed2626" }}>Set time</span>
    );
  };

  return (
    <DrawerAvailabilitiesContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Availability</div>
          <div></div>
        </div>
        {dayTitles.map((_dayTitle, _i) => (
          <ItemPicker
            key={_i}
            label={_dayTitle?.content}
            placeholder="Set time"
            value={renderAvailableDay(_dayTitle?.num)}
            // postMenu?.availability &&
            // postMenu?.availability.map((_x) => _x.day).includes(_dayTitle.num)
            //   ? "set"
            //   : ""

            // value="10:00AM - 10:00PM"
            onClick={() => {
              setSelectedDay(_dayTitle);
              openDrawer(drawersMenu.availableDay);
            }}
          />
        ))}
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            onClick={() => closeDrawer(drawersMenu.availabilities)}
          >
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      {/* Drawer Availabile Day */}
      <DrawerAvailableDay
        isActive={!!openDrawers[drawersMenu.availableDay]}
        closePanel={() => closeDrawer(drawersMenu.availableDay)}
        selectedDay={selectedDay}
      />
    </DrawerAvailabilitiesContainer>
  );
}
