import styled from "@emotion/styled";
import Tooltip from "react-bootstrap/Tooltip";

export const DashboardPageContainer = styled.div`
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  padding: 0 24px;
  .dashboard-page-body {
    padding: 25px 35px 50px;
    .dashboard-row {
      margin-bottom: 30px;
      .dashboard-card {
        border-radius: 8px;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
        background-color: #1b1c1d;
        min-height: 316px;
        .dashboard-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 19px 35px;
          .time-periods {
            display: flex;
            align-items: center;
            background: #27282a;
            border-radius: 30px;

            .period-filter {
              div {
                width: 91px;
              }
              padding: 8px 0px;
              width: 91px;
              text-align: center;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
              cursor: pointer;
              font-weight: 500;

              &.selected-tab {
                background-color: #3e4245;
                padding: 15px;
                border-radius: 30px;
                padding: 8px 0px;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                height: 40px;
              }
            }
          }
          #sales-calendar-popup {
            position: relative;
            padding-right: 20px;
            .popup-sales-calendar {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }

    .popular-items-container {
      width: 100%;
      display: flex;

      .popular-restaurants {
        width: 49%;
        margin-right: 36px;
      }

      .popular-items {
        width: 49%;
      }
    }

    .sales-wrapper {
      padding-top: 19px;
      padding-bottom: 6px;
      border-radius: 8px;
      box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
      background-color: #1b1c1d;
      min-height: 316px;
      height: 100%;

      #statistic-calendar-popup-handle {
        position: relative;
        padding-right: 20px;
        .popup-wrapper {
          position: absolute;
          right: 0;
        }
      }

      .sales-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        .statistic {
          font-size: 20px;
          font-weight: bold;
          color: #fff;
        }
      }

      .time-periods {
        display: flex;
        align-items: center;
        margin-left: 20px;
        background-color: #27282a;
        border-radius: 30px;

        .period-filter {
          div {
            width: 91px;
          }
          padding: 8px 0px;
          width: 91px;
          text-align: center;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          font-weight: 500;
          cursor: pointer;

          &.selected-tab {
            background-color: #3e4245;
            padding: 15px;
            border-radius: 30px;
            padding: 8px 0px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            height: 40px;
          }
        }
      }

      /* .sales-stat { 
        display: flex;
        justify-content: space-between;
        padding: 3rem 5rem 0 2rem;

        .sales-label {
          font-size: 16px;
          color: #fff;
          .shadow-text {
            opacity: 0.6;
          }
        }

        .sales-numbers {
          font-size: 30px;
          font-weight: bold;
          color: #fff;
        }
      } */
    }

    .sales-stat-container {
      .first-row {
        margin-top: 41px;
        .sales-label {
          font-size: 15px;
          color: #fff;
          .shadow-text {
            opacity: 0.6;
          }
        }

        .sales-numbers {
          font-size: 30px;
          font-weight: bold;
          color: #fff;
        }
      }

      .second-row {
        margin-top: 60px;
        .sales-label {
          font-size: 16px;
          color: #fff;
          .shadow-text {
            opacity: 0.6;
          }
        }

        .sales-numbers {
          font-size: 30px;
          font-weight: bold;
          color: #fff;
        }
      }
    }
    @media (min-width: 768px) {
      .offset-md-1 {
        margin-left: 8.33333%;
        padding: 0;
      }
    }
  }
`;

export const MyCustomTooltip = styled(Tooltip)`
  .tooltip-inner {
    // padding: 13px 17px 22px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
    border: solid 1px rgba(255, 255, 255, 0.08);
    background-color: #151617;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffffbd;
  }
`;
