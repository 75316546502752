import styled from "@emotion/styled";
import defaultFoodImage from "assets/img/default-food.jpg";

export const RatedListContainer = styled.div`
  width: 100%;
  margin: 35px 0px;
  display: flex;

  .rated-item {
    /* margin-right: 35px; */
    /* background-image: url(${defaultFoodImage}); */
    /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${defaultFoodImage}); */
    background-position: center;
    border-radius: 8px;
    /* width: 362px; */
    /* max-width: 362px; */
    width: 25%;
    min-height: 140px;
    padding: 22px 25px 22px 36px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-size: cover;

    &:first-of-type {
      .rated-name {
        margin-bottom: 20px;
      }
    }

    &:nth-of-type(2) {
      /* margin-right: 35px; */
    }

    &:last-child {
      /* margin-right: 0px; */
    }

    .rated-name {
      font-size: 24px;
      font-weight: bold;
      color: white;
      margin-bottom: 45px;
    }

    .rating-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .rating-core {
        display: flex;
        align-items: center;

        .like-dislike-icon {
          margin-right: 9px;
          i {
            font-size: 16px;
            color: white;
          }
        }

        .rating-title {
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
      }

      .concrete-rating-container {
        display: flex;
        align-items: center;

        .concrete-rating-number {
          font-size: 18px;
          font-weight: bold;
          color: white;
          margin-left: 5px;
        }

        .concrete-rating-icon {
          i {
            font-size: 18px;
            color: #fdcb6e;
          }
        }
      }
    }
  }
`;
