import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerSearchAddressContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    .fa-plus {
      color: #ed2626;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .location-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .location-input {
      margin-top: 25px;
      width: 100%;
      height: 38px;
      color: #fff;
    }

    .close-icon {
      position: relative;
      right: 12px;
      align-items: center;
      display: flex;
      cursor: pointer;
    }
  }

  .google-location-wrapper {
    ul {
      background-color: #1b1c1d;
      margin-top: 9px;
    }
    li {
      list-style: none;
      margin-bottom: 12px;
      cursor: pointer;
    }
  }

  .address-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 5px;
    padding-bottom: 11px;
    margin-left: -40px;
    margin-right: 12px;
    .address-info {
      display: flex;
      flex-direction: column;
    }
  }
`;
