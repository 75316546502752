import React, { useState, useEffect } from "react";
import moment from "moment";
// import { TotalSalesChartCoreContainer } from "./style";
import c3 from "c3";
import "c3/c3.css";
import { MyC3ChartContainer } from "./style";

//mock data
// const originalGraphData = [
//   {
//     time_stamp: "2023-07-27T00:00:00.000Z",
//     total_sales: "60.90",
//   },
//   {
//     time_stamp: "2023-07-28T00:00:00.000Z",
//     total_sales: "12.60",
//   },
//   {
//     time_stamp: "2023-08-01T00:00:00.000Z",
//     total_sales: "499.80",
//   },
//   {
//     time_stamp: "2023-08-02T00:00:00.000Z",
//     total_sales: "324.12",
//   },
//   {
//     time_stamp: "2023-08-03T00:00:00.000Z",
//     total_sales: "359.10",
//   },
//   {
//     time_stamp: "2023-08-04T00:00:00.000Z",
//     total_sales: "216.54",
//   },
//   {
//     time_stamp: "2023-08-07T00:00:00.000Z",
//     total_sales: "132.30",
//   },
//   {
//     time_stamp: "2023-08-08T00:00:00.000Z",
//     total_sales: "446.25",
//   },
//   {
//     time_stamp: "2023-08-09T00:00:00.000Z",
//     total_sales: "486.90",
//   },
//   {
//     time_stamp: "2023-08-18T00:00:00.000Z",
//     total_sales: "367.50",
//   },
//   {
//     time_stamp: "2023-08-21T00:00:00.000Z",
//     total_sales: "571.20",
//   },
//   {
//     time_stamp: "2023-08-22T00:00:00.000Z",
//     total_sales: "967.05",
//   },
//   {
//     time_stamp: "2023-08-23T00:00:00.000Z",
//     total_sales: "768.60",
//   },
//   {
//     time_stamp: "2023-08-24T00:00:00.000Z",
//     total_sales: "518.70",
//   },
//   {
//     time_stamp: "2023-08-25T00:00:00.000Z",
//     total_sales: "339.10",
//   },
// ];

function getDatesBetween(startDate, endDate) {
  const currentDate = startDate;
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

function getAllMonths(startDate) {
  const months = [];
  const currentDate = new Date();
  let currentMonth = new Date(startDate);

  while (currentMonth <= currentDate) {
    months.push(currentMonth);
    // currentMonth = startOfMonth(addMonths(currentMonth, 1));
    currentMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
  }

  return months;
}

function getHoursBetween(startDate, endDate) {
  const hours = [];
  let currentHour = new Date(startDate);

  while (currentHour <= endDate) {
    hours.push(moment(currentHour).format("YYYY-MM-DD HH:mm"));
    currentHour = moment(currentHour).add(1, "hours");
  }

  return hours;
}

const today = new Date();
const lastDateYear =
  new Date().getFullYear() -
  1 +
  "-" +
  new Date(new Date().setMonth(new Date().getMonth() + 1)).getMonth() +
  "-" +
  new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate();

// let allDates = getDatesBetween(new Date(new Date().setDate(new Date().getDate() - 6)), today);

export const TotalSalesChartCore = ({
  chartData,
  originalGraphData,
  salesFilter = 1,
  period,
  endDate,
}) => {
  // total_sales = 3
  // average_price = 2
  // order_count = 1

  const valueType =
    salesFilter == 3
      ? "total_sales"
      : salesFilter == 1
      ? "order_count"
      : "average_price";

  useEffect(() => {
    // console.log(moment(dataGraph[0].time_stamp).format("YYYY-MM-DD") === moment(allDates[0]).format("YYYY-MM-DD") )
    if (period === "week") {
      if (salesFilter == 3) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 7)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.total_sales
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).total_sales
                : 0,
            };
          })
        );
      } else if (salesFilter == 1) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 7)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.order_count
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).order_count
                : 0,
            };
          })
        );
      } else if (salesFilter == 2) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 7)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.average_price
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).average_price
                : 0,
            };
          })
        );
      }
    } else if (period === "month") {
      if (salesFilter == 3) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 30)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.total_sales
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).total_sales
                : 0,
            };
          })
        );
      } else if (salesFilter == 1) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 30)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.order_count
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).order_count
                : 0,
            };
          })
        );
      } else if (salesFilter == 2) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 30)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.average_price
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).average_price
                : 0,
            };
          })
        );
      }
    } else if (period === "year") {
      if (salesFilter == 3) {
        setFinalData(
          getAllMonths(lastDateYear).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.total_sales
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).total_sales
                : 0,
            };
          })
        );
      } else if (salesFilter == 1) {
        setFinalData(
          getAllMonths(lastDateYear).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.order_count
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).order_count
                : 0,
            };
          })
        );
      } else if (salesFilter == 2) {
        setFinalData(
          getAllMonths(lastDateYear).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.average_price
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).average_price
                : 0,
            };
          })
        );
      }
    } else if (period === "day") {
      if (salesFilter == 3) {
        setFinalData(
          getHoursBetween(
            new Date(moment().format("YYYY-MM-DD") + " " + "00:00"),
            new Date(moment().format("YYYY-MM-DD") + " " + "23:59")
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp)
                    .utc()
                    .format("YYYY-MM-DD HH:mm") ===
                  moment(item).format("YYYY-MM-DD HH:mm")
              )?.total_sales
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp)
                        .utc()
                        .format("YYYY-MM-DD HH:mm") ===
                      moment(item).format("YYYY-MM-DD HH:mm")
                  ).total_sales
                : 0,
            };
          })
        );
      } else if (salesFilter == 1) {
        setFinalData(
          getHoursBetween(
            new Date(moment().format("YYYY-MM-DD") + " " + "00:00"),
            new Date(moment().format("YYYY-MM-DD") + " " + "23:59")
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp)
                    .utc()
                    .format("YYYY-MM-DD HH:mm") ===
                  moment(item).format("YYYY-MM-DD HH:mm")
              )?.order_count
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp)
                        .utc()
                        .format("YYYY-MM-DD HH:mm") ===
                      moment(item).format("YYYY-MM-DD HH:mm")
                  ).order_count
                : 0,
            };
          })
        );
      } else if (salesFilter == 2) {
        setFinalData(
          getHoursBetween(
            new Date(moment().format("YYYY-MM-DD") + " " + "00:00"),
            new Date(moment().format("YYYY-MM-DD") + " " + "23:59")
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp)
                    .utc()
                    .format("YYYY-MM-DD HH:mm") ===
                  moment(item).format("YYYY-MM-DD HH:mm")
              )?.average_price
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp)
                        .utc()
                        .format("YYYY-MM-DD HH:mm") ===
                      moment(item).format("YYYY-MM-DD HH:mm")
                  ).average_price
                : 0,
            };
          })
        );
      }
    } else if (period === "quarter") {
      if (salesFilter == 3) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 91)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.total_sales
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).total_sales
                : 0,
            };
          })
        );
      } else if (salesFilter == 1) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 91)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.order_count
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).order_count
                : 0,
            };
          })
        );
      } else if (salesFilter == 2) {
        setFinalData(
          getDatesBetween(
            new Date(new Date().setDate(new Date().getDate() - 91)),
            today
          ).map((item) => {
            return {
              date: item,
              value: originalGraphData.find(
                (i) =>
                  moment(i.time_stamp).format("YYYY-MM-DD") ===
                  moment(item).format("YYYY-MM-DD")
              )?.average_price
                ? originalGraphData.find(
                    (i) =>
                      moment(i.time_stamp).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  ).average_price
                : 0,
            };
          })
        );
      }
    }
  }, [originalGraphData, valueType, salesFilter, period]);

  const [xAxis, setXAxis] = useState(["x"]);
  const [yAxis, setYAxis] = useState(["Total sales"]);
  const [finalData, setFinalData] = useState([]);

  // console.log("originalGraphData", originalGraphData);
  // console.log("salesFilter", salesFilter);
  // console.log("period", period);

  function formatCompactNumber(number) {
    if (number < 1000) {
      return number;
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
  }

  useEffect(() => {
    if (
      period === "week" ||
      period === "month" ||
      period === "year" ||
      period === "quarter" ||
      period === "day"
    ) {
      if (finalData && finalData.length) {
        const x = finalData.map((xGraph) => moment(xGraph.date));
        let y = finalData.map((yGraph) => yGraph?.value);

        setXAxis(["x", ...x]);
        setYAxis(["", ...y]);
      }
    }
  }, [finalData, period, originalGraphData, salesFilter]);

  // useEffect(() => {
  //   if (originalGraphData && originalGraphData?.length > 0) {
  //     const x = originalGraphData.map((xGraph) =>
  //       moment.utc(xGraph.time_stamp)
  //     );

  //     let y = [];
  //     if (originalGraphData[0].total_sales) {
  //       y = originalGraphData.map((yGraph) => yGraph?.total_sales);
  //     } else if (originalGraphData[0].average_price) {
  //       y = originalGraphData.map((yGraph) => yGraph?.average_price);
  //     } else if (originalGraphData[0].order_count) {
  //       y = originalGraphData.map((yGraph) => yGraph?.order_count);
  //     }

  //     // setXAxis(["x", ...x]);
  //     // // setYAxis(["Total sales", ...y]);
  //     // setYAxis(["", ...y]);

  //     if (
  //       period === "week" ||
  //       period === "month" ||
  //       period === "quarter" ||
  //       period === "Custom"
  //     ) {
  //       var chosen_start_date = x[1]; //chosen start of date range
  //       var chosen_end_date =
  //         period === "Custom" ? new Date(endDate) : new Date(); //chosen end of date range

  //       var new_dates = [];
  //       var new_values = [];
  //       let date;
  //       let date_obj;
  //       var prev_real_date = x[0];
  //       var following = chosen_start_date;
  //       for (var i = 0; i < x.length; i++) {
  //         date = x[i];
  //         date_obj = new Date(date);

  //         if (!moment(date_obj).isSame(following, "day")) {
  //           // Following on from an earlier real date value we need to add a zero
  //           new_dates.push(moment(following));
  //           new_values.push("0");
  //         }

  //         let earlier = new Date(date_obj);
  //         earlier.setDate(date_obj.getDate() - 1);

  //         if (
  //           !moment(prev_real_date).isSame(earlier, "day") &&
  //           !moment(following).isSame(earlier, "day")
  //         ) {
  //           // Before this real date value we need to add a zero
  //           new_dates.push(moment(earlier));
  //           new_values.push("0");
  //         }

  //         following = new Date(date_obj);
  //         following.setDate(date_obj.getDate() + 1);

  //         //Push this real date value
  //         new_dates.push(moment(date));
  //         new_values.push(y[i]);

  //         prev_real_date = date_obj;
  //       }
  //       if (moment(chosen_end_date).isAfter(date_obj)) {
  //         // Add final zeros to take us to the end date
  //         new_dates.push(moment(following));
  //         new_values.push("0");
  //         // if (moment(chosen_end_date).isAfter(following)) {
  //         //   new_dates.push(moment(chosen_end_date));
  //         //   new_values.push("0");
  //         // }
  //       }
  //       setXAxis(["x", ...new_dates]);
  //       setYAxis(["", ...new_values]);
  //     } else if (period === "year") {
  //       setXAxis(["x", ...x]);
  //       setYAxis(["", ...y]);
  //     } else if (period === "day") {
  //       var chosen_start_date = moment()
  //         .set("hour", 8)
  //         .set("minute", 0); //chosen start of date range
  //       console.log(chosen_start_date);
  //       var chosen_end_date = moment()
  //         .set("hour", 20)
  //         .set("minute", 0); //chosen end of date range
  //       const times = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  //       var new_dates = [chosen_start_date];
  //       var new_values = [];
  //       let date;
  //       let date_obj;
  //       var prev_real_date = x[0];
  //       var following = chosen_start_date;
  //       for (var i = 0; i <= 6; i++) {
  //         date = new_dates[i];
  //         date_obj = new Date(date);
  //         new_dates.push(moment(date_obj).add(2, "hours"));
  //         new_values.push("0");

  //         //Push this real date value
  //         if (moment(date_obj).isSame(prev_real_date, "hour")) {
  //           new_values.push(y[i]);
  //         }
  //       }

  //       //   if (!moment(date_obj).isSame(following, "hour")) {
  //       //     // Following on from an earlier real date value we need to add a zero
  //       //     new_dates.push(moment(following));
  //       //     new_values.push("0");
  //       //   }

  //       //   // let earlier = new Date(date_obj);
  //       //   // earlier.setDate(date_obj.subtract(2, "hours"));

  //       //   let earlier = moment(date_obj).subtract(2, "hours");

  //       //   if (
  //       //     !moment(prev_real_date).isSame(earlier, "hour") &&
  //       //     !moment(following).isSame(earlier, "hour")
  //       //   ) {
  //       //     // Before this real date value we need to add a zero
  //       //     new_dates.push(moment(earlier));
  //       //     new_values.push("0");
  //       //   }

  //       //   // following = new Date(date_obj);
  //       //   // following.setDate(date_obj.add(2, "hours"));
  //       //   following = moment(date_obj).add(2, "hours");

  //       //   //Push this real date value
  //       //   new_dates.push(moment(date));
  //       //   new_values.push(y[i]);

  //       //   prev_real_date = date_obj;
  //       // }
  //       // if (moment(chosen_end_date).isAfter(date_obj)) {
  //       //   // Add final zeros to take us to the end date
  //       //   new_dates.push(moment(following));
  //       //   new_values.push("0");
  //       //   if (moment(chosen_end_date).isAfter(following)) {
  //       //     new_dates.push(moment(chosen_end_date));
  //       //     new_values.push("0");
  //       //   }
  //       // }

  //       setXAxis(["x", ...new_dates]);
  //       setYAxis(["", ...new_values]);
  //     }
  //   } else {
  //     setXAxis(["x", new Date()]);
  //     setYAxis(["", "0"]);
  //   }
  // }, [period, originalGraphData, salesFilter]);

  useEffect(() => {
    c3.generate({
      bindto: "#chart",
      data: {
        x: "x",
        columns: [xAxis, yAxis],
        // empty: {
        //   label: {
        //     text: "No data",
        //   },
        // },
        type: "area",
      },
      zoom: {
        enabled: true,
        type: "scroll",
        rescale: true,
        extent: [1, 5],
        // onzoomend: function(){
        //   setCull(false)
        // }
      },
      point: {
        show: true,
      },
      subchart: {
        show: false,
      },
      // zoom: {
      //   enabled: true,
      //   rescale: true,
      //   onzoom: function(domain) {
      //     console.log("zoom", domain);
      //   },
      // },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            culling: {
              max: 10,
            },
            format: function(value, timestamp, opts) {
              // console.log(value);
              const monthData =
                period === "month"
                  ? moment(value).format("MMM DD")
                  : period === "quarter"
                  ? moment(value).format("DD/MM")
                  : period === "week"
                  ? moment(value).format("ddd")
                  : period === "day"
                  ? moment(value).format("h A")
                  : period === "Custom"
                  ? moment(value).format("MMM")
                  : moment(value).format("MMM");
              return monthData;
            },
          },
        },
        y: {
          tick: {
            count: 8,
            format: function(y) {
              return salesFilter !== 1
                ? "$ " + formatCompactNumber(Math.round(y))
                : formatCompactNumber(Math.round(y));
            },
          },
        },
      },
      tooltip: {
        contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
          const data = d[0];
          const val = data?.value;
          const date = data?.x;
          const monthData =
            period === "month"
              ? moment(date).format("MMM DD, YYYY")
              : period === "quarter"
              ? moment(date).format("MMM DD, YYYY")
              : period === "week"
              ? moment(date).format("dddd, MMM DD, YYYY")
              : period === "day"
              ? `Today, ${moment(date).format("MMM D </br> h:mm A")}`
              : period === "Custom"
              ? moment(date).format("MMM DD, YYYY")
              : moment(date).format("MMM, YYYY");
          return (
            '<div class="arrow_box">' +
            '<span class="month_name">' +
            "<b>" +
            `${monthData}` +
            "</b>" +
            "</span>" +
            '<span class="value">' +
            "<b>" +
            `${salesFilter === 1 ? `${val} orders` : `$${val}`}` +
            "</b>" +
            "</span>" +
            "</div>"
          );
        },
        grouped: true,
      },
      color: {
        pattern: ["#1FC086"],
      },
      padding: {
        top: 20,
        right: 20,
      },
    });
  }, [xAxis, yAxis, salesFilter]);

  return originalGraphData?.length ? (
    <MyC3ChartContainer>
      <div id="chart" />
    </MyC3ChartContainer>
  ) : (
    <div
      className="no-data"
      style={{
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "24px" }}>No data available</p>
    </div>
  );
};
