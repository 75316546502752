import React, { useState, useContext, useEffect, useRef } from "react";
import { DrawerPromoCodeModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
// import { DrawerContainer } from "components/styledStyle";
import { Switch } from "@material-ui/core";
import { FaChevronRight } from "react-icons/fa";
import { drawersRestaurants } from "static/staticData";
import SetPromoCodeModal from "../SetPromoCodeDrawerModal";
import SetDiscountModal from "../SetDiscountDrawerModal";
import TotalUseTimesDrawerModal from "../TotalUseTimesDrawerModal";
import UseTimePerCustomerDrawerModal from "../UseTimesPerCustomer";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import PromoCodeDateDrawerModal from "../PromoCodeDateDrawerModal";
import { colors } from "theme";
import moment from "moment";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { showMessage } from "services/functions";
import useOnclickOutside from "react-cool-onclickoutside";

const PromoCodeModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
  getPromoCodeFun = () => {},
  globalPromoCode = false,
}) => {
  const { id } = useParams();

  const {
    openDrawer,
    openDrawers,
    closeDrawer,

    promoCode,
    setPromoCode,
    discountType,
    setDiscountType,
    discountFixedAmount,
    setDiscountFixedAmount,
    discountPercentage,
    setDiscountPercentage,

    startDate,
    setStartDate,
    endDate,
    setEndDate,

    totalUseTimes,
    setTotalUseTimes,
    useTimesPerCustomer,
    setUseTimesPerCustomer,

    updatePromoCodeDetails,
    setUpdatePromoCodeDetails,

    expirationTime,
    setExpirationTime,
    setExpirationTimeType,
    expirationTimeType,

    // coupon local
    localTotalUseTimes,
    setLocalTotalUseTimes,
    localUseTimesPerCustomer,
    setLocalUseTimesPerCustomer,
    localStartDate,
    setLocalStartDate,
    localEndDate,
    setLocalEndDate,
    localPromoCode,
    setLocalPromoCode,
    couponData,
    setCouponData,
    promoCodeStatus,
    setPromoCodeStatus,

    totalUseTimeToggle,
    setTotalUseTimeToggle,

    useTimePerCustomerToggle,
    setUseTimePerCustomerToggle,
    state,
    setState,
    activeDateToggle,
    setActiveDateToggle,

    statusActiveNotification,
    setStatusActiveNotification,
    statusInactiveNotification,
    setStatusInactiveNotification,
  } = useContext(RestaurantsContext);

  const switchRef = useRef();

  const [promoCodeName, setPromoCodeName] = useState("");
  const [minimumOrderAmount, setMinimumOrderAmount] = useState();
  const [maximumDiscountAmount, setMaximumDiscountAmount] = useState();

  const [statusToggle, setStatusToggle] = useState(true);
  const [changeStatus, setChangeStatus] = useState(1);

  // const [statusActiveNotification, setStatusActiveNotification] = useState();
  // const [statusInactiveNotification, setStatusInactiveNotification] = useState();

  useEffect(() => {
    if (statusActiveNotification) {
      setTimeout(() => setStatusActiveNotification(null), 2 * 1000);
    }
  }, [statusActiveNotification]);

  useEffect(() => {
    if (statusInactiveNotification) {
      setTimeout(() => setStatusInactiveNotification(null), 2 * 1000);
    }
  }, [statusInactiveNotification]);

  useEffect(() => {
    if (!isActive) {
      setPromoCodeName("");
      setPromoCode("");
      setLocalPromoCode("");
      setDiscountFixedAmount("");
      setDiscountPercentage("");
      setTotalUseTimes("");
      setLocalTotalUseTimes("");
      setUseTimesPerCustomer("");
      setLocalUseTimesPerCustomer("");
      setMinimumOrderAmount("");
      setMaximumDiscountAmount("");
      setStartDate("");
      setEndDate("");
      setTotalUseTimeToggle(false);
      setUseTimePerCustomerToggle(false);
      setActiveDateToggle(false);
      setExpirationTime("10:00");
      setExpirationTimeType("am");
    }
  }, [isActive]);

  useEffect(() => {
    if (!isActive) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  }, [isActive]);

  function handleChangeToggle(event) {
    setStatusToggle(event.target.checked);
  }

  useEffect(() => {
    switchRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [isActive]);

  useEffect(() => {
    if (statusToggle) {
      setChangeStatus(1);
    } else {
      setChangeStatus(2);
    }
  }, [statusToggle, changeStatus]);

  function clearInputFields() {
    setPromoCodeName("");
    setPromoCode("");
    setLocalPromoCode("");
    setDiscountFixedAmount("");
    setDiscountPercentage("");
    setTotalUseTimes("");
    setLocalTotalUseTimes("");
    setUseTimesPerCustomer("");
    setLocalUseTimesPerCustomer("");
    setMinimumOrderAmount("");
    setMaximumDiscountAmount("");
    setStartDate("");
    setEndDate("");
    setTotalUseTimeToggle(false);
    setUseTimePerCustomerToggle(false);
    setActiveDateToggle(false);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  }

  // function cancelBtn() {
  //   clearInputFields();
  //   closePanel();
  // }

  function cancelBtn() {
    if (Object.keys(updatePromoCodeDetails)?.length > 0) {
      closePanel();
    } else {
      clearInputFields();
      closePanel();
    }
  }

  // post api
  function handleSubmit(e) {
    e.preventDefault();
    const configCouponData = {
      name: promoCodeName,
      status: changeStatus,
      code: promoCode,
      discount: discountType === 1 ? discountPercentage : discountFixedAmount,
      discount_type: discountType,
      // max_discount: maximumDiscountAmount,
      // limit_user: useTimesPerCustomer,
      // limit_overall: totalUseTimes,
      // threshold: minimumOrderAmount,
      // start_date: startDate,
      // end_date: endDate,
      // start_time: "12:00",
      end_time: expirationTime,
    };

    if (useTimesPerCustomer === true) {
      configCouponData.has_no_limit_user = useTimesPerCustomer;
    } else {
      configCouponData.limit_user = useTimesPerCustomer;
    }

    if (totalUseTimes === true) {
      configCouponData.has_no_limit_overall = totalUseTimes;
    } else {
      configCouponData.limit_overall = totalUseTimes;
    }

    if (maximumDiscountAmount !== "") {
      configCouponData.max_discount = maximumDiscountAmount;
    }
    if (minimumOrderAmount !== "") {
      configCouponData.threshold = minimumOrderAmount;
    }
    if (!globalPromoCode) {
      configCouponData.restaurant_id = id;
    }

    if (startDate !== null && endDate !== null) {
      configCouponData.start_date = startDate;
      configCouponData.end_date = endDate;
    } else {
      configCouponData.start_date = null;
      configCouponData.end_date = null;
    }

    if (activeDateToggle === true && totalUseTimeToggle === true && useTimePerCustomerToggle === true) {
      showMessage(`You can't select NO LIMIT for 3 Options. you need to put the value in at least 1 option`,"error");
    } else if (activeDateToggle === true && totalUseTimeToggle === true) {
      showMessage(`You can't select NO LIMIT for 2 Options. you need to put the value in at least 1 option`, "error");
    } else {
      customAxios
        .post(`/coupon`, configCouponData)
        .then((res) => {
          if (res.status === 200) {
            if (globalPromoCode) {
              customAxios
                .get(`/coupon?status=${promoCodeStatus}&scope=2`)
                .then((res) => {
                  setCouponData(res?.data);
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              getPromoCodeFun();
            }
            closePanel();

            changeStatus &&
              changeStatus === 1 &&
              setStatusActiveNotification({
                message: "Promo Code Active.",
                type: "promo-active",
                table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promoCodeName} • ${promoCode}</span>`,
              });

            changeStatus &&
              changeStatus === 2 &&
              setStatusActiveNotification({
                message: "Promo Code Inactive.",
                type: "promo-inactive",
                table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promoCodeName} • ${promoCode}</span>`,
              });
          } else {
            showMessage(res.errMessage, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        });
      clearInputFields();
    }
  }

  // update api
  function updatePromoCord(e) {
    e.preventDefault();
    const configUpdatePromoCode = {
      // name: promoCodeName,
      status: changeStatus,
      // start_date: moment(localStartDate).format("YYYY-MM-DD"),
      // end_date: moment(localEndDate).format("YYYY-MM-DD"),
      // end_time: expirationTime,
    };

    if (!globalPromoCode) {
      configUpdatePromoCode.restaurant_id = id;
    }

    // if (useTimesPerCustomer === true) {
    //   configUpdatePromoCode.has_no_limit_user = useTimesPerCustomer;
    // } else {
    //   configUpdatePromoCode.limit_user = useTimesPerCustomer;
    // }

    // if (totalUseTimes === true) {
    //   configUpdatePromoCode.has_no_limit_overall = totalUseTimes;
    // } else {
    //   configUpdatePromoCode.limit_overall = totalUseTimes;
    // }

    // if (startDate !== null && endDate !== null) {
    //   configUpdatePromoCode.start_date = startDate;
    //   configUpdatePromoCode.end_date = endDate;
    // } else {
    //   configUpdatePromoCode.start_date = null;
    //   configUpdatePromoCode.end_date = null;
    // }

    customAxios
      .patch(`/coupon/${updatePromoCodeDetails?.id}`, configUpdatePromoCode)
      .then((res) => {
        if (res.status === 200) {
          if (globalPromoCode) {
            customAxios
              .get(`/coupon?status=${promoCodeStatus}&scope=2`)
              .then((res) => {
                setCouponData(res?.data);
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            getPromoCodeFun();
            // customAxios.get(`/coupon?restaurant_id=${id}&status=${promoCodeStatus}`)
            //  .then((res) => setCouponData(res?.data))
            closePanel();
          }
          closePanel();

          changeStatus &&
            changeStatus === 1 &&
            setStatusActiveNotification({
              message: "Promo Code Active.",
              type: "promo-active",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promoCodeName} • ${updatePromoCodeDetails?.code}</span>`,
            });

          changeStatus &&
            changeStatus === 2 &&
            setStatusActiveNotification({
              message: "Promo Code Inactive.",
              type: "promo-inactive",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promoCodeName} • ${updatePromoCodeDetails?.code}</span>`,
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (Object.keys(updatePromoCodeDetails).length) {
      setPromoCodeName(updatePromoCodeDetails?.name);

      setTotalUseTimes(updatePromoCodeDetails?.limit_overall);
      setLocalTotalUseTimes(updatePromoCodeDetails?.limit_overall);

      setUseTimesPerCustomer(updatePromoCodeDetails?.limit_user);
      setLocalUseTimesPerCustomer(updatePromoCodeDetails?.limit_user);

      setStartDate(updatePromoCodeDetails?.start_date);
      setLocalStartDate(updatePromoCodeDetails?.start_date);
      setEndDate(updatePromoCodeDetails?.end_date);
      setLocalEndDate(updatePromoCodeDetails?.end_date);

      setMinimumOrderAmount(updatePromoCodeDetails?.threshold);
      setMaximumDiscountAmount(updatePromoCodeDetails?.max_discount);

      setLocalPromoCode(updatePromoCodeDetails?.code);

      setDiscountType(updatePromoCodeDetails?.discount_type);
      setDiscountFixedAmount(updatePromoCodeDetails?.discount);
      setDiscountPercentage(updatePromoCodeDetails?.discount);

      setExpirationTime(updatePromoCodeDetails?.end_time);

      setChangeStatus(updatePromoCodeDetails?.status);
      setStatusToggle(updatePromoCodeDetails?.status === 1 ? true : false);

      // setTotalUseTimeToggle(updatePromoCodeDetails?.limit_overall === null ? false : true)
    } else {
      setPromoCodeName("");

      setTotalUseTimes("");
      setLocalTotalUseTimes("");

      setUseTimesPerCustomer("");
      setLocalUseTimesPerCustomer("");

      setStartDate("");
      setEndDate("");
      setLocalStartDate("");
      setLocalEndDate("");

      setMinimumOrderAmount("");
      setMaximumDiscountAmount("");

      setLocalPromoCode("");

      setDiscountType("");
      setDiscountFixedAmount("");
      setDiscountPercentage("");

      setChangeStatus(1);
      setStatusToggle(true);
    }
  }, [updatePromoCodeDetails]);

  useEffect(() => {
    setExpirationTime(updatePromoCodeDetails?.end_time);
    if (updatePromoCodeDetails.end_time) {
      const time = moment(updatePromoCodeDetails.end_time, "h:mm A").format(
        "HH:mm A"
      );
      const timeType = time.split(" ");
      if (timeType[1] === "AM") {
        setExpirationTimeType("am");
      } else {
        setExpirationTimeType("pm");
      }
    }
  }, [updatePromoCodeDetails?.end_time]);

  useEffect(() => {
    if (expirationTime) {
      const hr = Number(expirationTime.split(":")[0]);
      const min = expirationTime.split(":")[1];
      if (hr >= 13 && hr <= 24) {
        setExpirationTime(
          moment(`0${hr - 12}:${min} ${expirationTimeType}`, ["h:mm A"]).format(
            "HH:mm"
          )
        );
      } else {
        setExpirationTime(
          moment(`${expirationTime} ${expirationTimeType}`, ["h:mm A"]).format(
            "HH:mm"
          )
        );
      }
    }
  }, [expirationTime, expirationTimeType]);

  return (
    <DrawerPromoCodeModalContainer
      isActive={isActive}
      closePanel={closePanel}
      // top="2rem"
      // bottom="1.8rem"
      left="40%"
      top={updatePromoCodeDetails?.id ? "35%" : "5%"}
      zIndexBackground={9999999}
    >
      {/* <DrawerContainer> */}
      <div className="promo-code-container">
        {statusActiveNotification && (
          <MyMessageBoxItemNew
            message={
              statusActiveNotification?.message
                ? statusActiveNotification?.message
                : ""
            }
            table={
              statusActiveNotification?.table
                ? statusActiveNotification?.table
                : ""
            }
            type={
              statusActiveNotification?.type
                ? statusActiveNotification?.type
                : ""
            }
            onClick={() => setStatusActiveNotification(null)}
          />
        )}

        {statusInactiveNotification && (
          <MyMessageBoxItemNew
            message={
              statusInactiveNotification?.message
                ? statusInactiveNotification?.message
                : ""
            }
            table={
              statusInactiveNotification?.table
                ? statusInactiveNotification?.table
                : ""
            }
            type={
              statusInactiveNotification?.type
                ? statusInactiveNotification?.type
                : ""
            }
            onClick={() => setStatusInactiveNotification(null)}
          />
        )}

        <div className="title">
          {Object.keys(updatePromoCodeDetails)?.length ? "Edit" : "Add"} Promo
          Code
        </div>
        {/* <div className="form-scroll"> */}
        <form
          onSubmit={
            Object.keys(updatePromoCodeDetails).length
              ? updatePromoCord
              : handleSubmit
          }
        >
          <div className="form-scroll" ref={switchRef}>
            {
              <div className="active-toggle mb-4">
                <div className="promo-apply">
                  <div className="promo-apply-text">
                    {Object.keys(updatePromoCodeDetails).length
                      ? statusToggle === true
                        ? "Active"
                        : "Inactive"
                      : changeStatus === 1
                      ? "Active"
                      : "Inactive"}
                  </div>
                  <div className="description">
                    Customers are{" "}
                    {statusToggle === true && changeStatus === 1 ? "" : "not"}{" "}
                    able to apply this promo
                  </div>
                </div>
                <div className="toggle-btn">
                  <Switch
                    checked={statusToggle}
                    onChange={handleChangeToggle}
                  />
                </div>
              </div>
            }
            {!updatePromoCodeDetails.id && (
              <>
                {/* promo-code-name */}
                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Promo Code Name </label>
                  </div>
                  <input
                    type="text"
                    value={promoCodeName || ""}
                    placeholder="Ex: Anniversary"
                    className="promo-input-optional"
                    autoComplete="off"
                    onChange={(e) => setPromoCodeName(e.target.value)}
                    disabled={
                      Object.keys(updatePromoCodeDetails).length === 0
                        ? false
                        : true
                    }
                  />
                  <div className="bottom-line" />
                </div>

                {/* promo-code */}
                <div
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    Object.keys(updatePromoCodeDetails).length
                      ? null
                      : openDrawer(drawersRestaurants.setPromoCode)
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Promo Code</label>
                    {Object.keys(updatePromoCodeDetails).length ? null : (
                      <FaChevronRight
                        size={15}
                        color={colors.gray}
                        className="arrow-icon"
                      />
                    )}
                  </div>
                  <div>
                    {localPromoCode ? (
                      <span style={{ color: colors.white }}>
                        {localPromoCode || ""}
                      </span>
                    ) : (
                      <span style={{ color: colors.redColor }}>Set Code</span>
                    )}
                  </div>
                  <div className="bottom-line" />
                </div>

                {/* discount */}
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    Object.keys(updatePromoCodeDetails).length
                      ? null
                      : openDrawer(drawersRestaurants.setDiscount)
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Discount</label>
                    {Object.keys(updatePromoCodeDetails).length ? null : (
                      <FaChevronRight
                        size={15}
                        color={colors.gray}
                        className="arrow-icon "
                      />
                    )}
                  </div>
                  <div>
                    {discountPercentage || discountFixedAmount ? (
                      <span style={{ color: "#fff" }}>
                        {discountType === 1
                          ? `${discountPercentage}%`
                          : `$ ${discountFixedAmount}`}
                      </span>
                    ) : (
                      <span style={{ color: colors.redColor }}>
                        Set Discount
                      </span>
                    )}
                  </div>
                  <div className="bottom-line" />
                </div>

                {/* active dates */}
                {/* <div
              className="mb-2 cursor-pointer"
              style={{ marginTop: "35px" }}
              onClick={() => openDrawer(drawersRestaurants.promoCodeDate)}
            >
              <div className="d-flex justify-content-between">
                <label className="input-label">Active Dates</label>
                <FaChevronRight
                  size={15}
                  color={colors.gray}
                  className="arrow-icon"
                />
              </div>
              <div>
                {JSON.stringify(startDate)} = {JSON.stringify(endDate)}
                {startDate !== undefined && endDate !== undefined ? (
                  <span style={{ color: colors?.white }}>
                    {startDate?.length >= 1 && endDate?.length >= 1
                      ? `${moment(startDate).format("YYYY-MM-DD")} - ${moment(
                          endDate
                        ).format("YYYY-MM-DD")}`
                      : "Not Limited"}
                  </span>
                ) : (
                  <span style={{ color: colors.redColor }}>
                    Set Active Dates
                  </span>
                )}
              </div>
              <div className="bottom-line" />
            </div> */}

                {/* active date */}
                <div
                  className="mb-2 cursor-pointer"
                  style={{ marginTop: "35px" }}
                  onClick={() =>
                    Object.keys(updatePromoCodeDetails).length
                      ? null
                      : openDrawer(drawersRestaurants.promoCodeDate)
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Active Dates</label>
                    {Object.keys(updatePromoCodeDetails).length ? null : (
                      <FaChevronRight
                        size={15}
                        color={colors.gray}
                        className="arrow-icon"
                      />
                    )}
                  </div>
                  <div>
                    {startDate !== "" && endDate !== "" ? (
                      <span style={{ color: colors?.white }}>
                        {/* {startDate !== null && endDate !== null
                      ? `${moment(startDate).format("YYYY-MM-DD")} - ${moment(
                          endDate
                        ).format("YYYY-MM-DD")}`
                      : "Not Limited"} */}

                        {startDate !== null && endDate !== null ? (
                          <>
                            {localStartDate &&
                              moment(localStartDate).format("ll")}
                            {localStartDate === localEndDate ? null : " - "}
                            {localStartDate === localEndDate
                              ? null
                              : moment(localEndDate).format("ll")}
                          </>
                        ) : (
                          "Not Limited"
                        )}
                      </span>
                    ) : (
                      <span style={{ color: colors.redColor }}>
                        Set Active Dates
                      </span>
                    )}
                  </div>
                  <div className="bottom-line" />
                </div>

                {/* expiration time */}
                {startDate !== "" && endDate !== "" ? (
                  <span style={{ color: colors.white }}>
                    {startDate?.length >= 1 && endDate?.length >= 1 ? (
                      <div className="mb-2">
                        <div className="d-flex justify-content-between">
                          <label className="input-label">Expiration Time</label>
                          <span style={{ position: "relative", top: "10px" }}>
                            <span
                              className={`mr-2 timer ${expirationTimeType ===
                                "am" && "active-time"}`}
                              onClick={() => setExpirationTimeType("am")}
                            >
                              am
                            </span>
                            <span
                              className={`timer ${expirationTimeType === "pm" &&
                                "active-time"}`}
                              onClick={() => setExpirationTimeType("pm")}
                            >
                              pm
                            </span>
                          </span>
                        </div>
                        <input
                          type="time"
                          placeholder="Set Expiration Time"
                          className="promo-input without_ampm"
                          autoComplete="off"
                          value={expirationTime || ""}
                          pattern="[0-9]{2}:[0-9]{2}"
                          onChange={(e) => setExpirationTime(e.target.value)}
                          disabled={
                            Object.keys(updatePromoCodeDetails).length === 0
                              ? false
                              : true
                          }
                        />
                        <div className="bottom-line" />
                      </div>
                    ) : null}
                  </span>
                ) : (
                  <div className="mb-2">
                    <div className="d-flex justify-content-between">
                      <label className="input-label">Expiration Time</label>
                      <span style={{ position: "relative", top: "10px" }}>
                        <span
                          className={`mr-2 timer ${expirationTimeType ===
                            "am" && "active-time"}`}
                          onClick={() => setExpirationTimeType("am")}
                        >
                          am
                        </span>
                        <span
                          className={`timer ${expirationTimeType === "pm" &&
                            "active-time"}`}
                          onClick={() => setExpirationTimeType("pm")}
                        >
                          pm
                        </span>
                      </span>
                    </div>
                    <input
                      type="time"
                      placeholder="Set Expiration Time"
                      className="promo-input without_ampm"
                      autoComplete="off"
                      pattern="[0-9]{2}:[0-9]{2}"
                      value={expirationTime || ""}
                      onChange={(e) => setExpirationTime(e.target.value)}
                    />
                    <div className="bottom-line" />
                  </div>
                )}

                {/* total use times */}
                <div
                  className="mb-2 cursor-pointer"
                  style={{ marginTop: "35px" }}
                  onClick={() =>
                    Object.keys(updatePromoCodeDetails).length
                      ? null
                      : openDrawer(drawersRestaurants.totalUseTimes)
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Total Use Times</label>
                    {Object.keys(updatePromoCodeDetails).length ? null : (
                      <FaChevronRight
                        size={15}
                        color={colors.gray}
                        className="arrow-icon"
                      />
                    )}
                  </div>
                  <div>
                    {Object.keys(updatePromoCodeDetails)?.length ? (
                      typeof updatePromoCodeDetails.limit_overall ===
                        "number" || totalUseTimes ? (
                        <span style={{ color: colors.white }}>
                          {/* {updatePromoCodeDetails.limit_overall} */}
                          {typeof totalUseTimes === "number" && totalUseTimes}
                          {typeof totalUseTimes === "string" && totalUseTimes}
                          {typeof totalUseTimes === "boolean" && "No Limit"}
                        </span>
                      ) : (
                        <span style={{ color: colors.white }}>No Limit</span>
                      )
                    ) : totalUseTimes ? (
                      typeof totalUseTimes === "string" ? (
                        <span style={{ color: colors.white }}>
                          {totalUseTimes}
                        </span>
                      ) : (
                        "No Limit"
                      )
                    ) : (
                      <span style={{ color: colors.redColor }}>
                        Set Total Use Times
                      </span>
                    )}
                  </div>
                  <div className="bottom-line" />
                </div>

                {/* Use Times Per Customer */}
                <div
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    Object.keys(updatePromoCodeDetails).length
                      ? null
                      : openDrawer(drawersRestaurants.useTimesPerCustomer)
                  }
                >
                  <div className="d-flex justify-content-between">
                    <label className="input-label">
                      Use Times Per Customer
                    </label>
                    {Object.keys(updatePromoCodeDetails).length ? null : (
                      <FaChevronRight
                        size={15}
                        color={colors.gray}
                        className="arrow-icon"
                      />
                    )}
                  </div>
                  <div>
                    {Object.keys(updatePromoCodeDetails)?.length ? (
                      typeof updatePromoCodeDetails.limit_user === "number" ||
                      useTimesPerCustomer ? (
                        <span style={{ color: colors.white }}>
                          {/* {updatePromoCodeDetails.limit_user} */}
                          {typeof useTimesPerCustomer === "number" &&
                            useTimesPerCustomer}
                          {typeof useTimesPerCustomer === "string" &&
                            useTimesPerCustomer}
                          {typeof useTimesPerCustomer === "boolean" &&
                            "No Limit"}
                        </span>
                      ) : (
                        <span style={{ color: colors.white }}>No Limit</span>
                      )
                    ) : useTimesPerCustomer ? (
                      typeof useTimesPerCustomer === "string" ? (
                        <span style={{ color: colors.white }}>
                          {useTimesPerCustomer}
                        </span>
                      ) : (
                        "No Limit"
                      )
                    ) : (
                      <span style={{ color: colors.redColor }}>
                        Set Use Times Per Customer
                      </span>
                    )}
                  </div>
                  <div className="bottom-line" />
                </div>

                {/* minimum order amount */}
                <div className="mb-2" style={{ marginTop: "28px" }}>
                  <div
                    style={{ fontSize: "15px" }}
                    className="text-bold text-white mb-1"
                  >
                    Optional information.
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Minimum Order Amount</label>
                    <span
                      className="mr-1"
                      style={{ opacity: 0.4 }}
                    >
                      Optional
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>{minimumOrderAmount > 0 && "$"}</span>
                    <input
                      type="text"
                      value={minimumOrderAmount || ""}
                      placeholder="Enter here"
                      className="promo-input-optional"
                      autoComplete="off"
                      onChange={(e) =>
                        setMinimumOrderAmount(e.target.value.replace(/\D/g, ""))
                      }
                      disabled={
                        Object.keys(updatePromoCodeDetails).length === 0
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className="bottom-line" />
                </div>

                {/* maximum discount amount */}
                <div>
                  <div className="d-flex justify-content-between">
                    <label className="input-label">
                      Maximum Discount Amount
                    </label>
                    <span
                      className="mr-1"
                      style={{opacity: 0.4 }}
                    >
                      Optional
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>{maximumDiscountAmount > 0 && "$"}</span>
                    <input
                      type="text"
                      value={maximumDiscountAmount || ""}
                      placeholder="Enter here"
                      className="promo-input-optional"
                      autoComplete="off"
                      onChange={(e) =>
                        setMaximumDiscountAmount(
                          e.target.value.replace(/\D/g, "")
                        )
                      }
                      disabled={
                        Object.keys(updatePromoCodeDetails).length === 0
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className="bottom-line" />
                </div>
              </>
            )}
          </div>
          <div className="btn-container">
            <div className="cancel-btn cursor-pointer" onClick={cancelBtn}>
              Cancel
            </div>
            {Object.keys(updatePromoCodeDetails).length ? (
              <button
                type="submit"
                className="btn-enable"
                // className={
                //   promoCodeName &&
                //   localPromoCode &&
                //   expirationTime?.length > 0 &&
                //   // ((totalUseTimes && totalUseTimes) || (totalUseTimes && totalUseTimes === true)) &&
                //   // ((useTimesPerCustomer && useTimesPerCustomer) || (useTimesPerCustomer && useTimesPerCustomer === true)) &&
                //   // ((startDate && startDate) || (startDate === null)) &&
                //   ((discountType === 1 && discountPercentage) || (discountType === 2 && discountFixedAmount))
                //     ? "btn-enable"
                //     : "btn-disable"
                // }
                // disabled={
                //   promoCodeName &&
                //   localPromoCode &&
                //   expirationTime?.length > 0 &&
                //   // ((totalUseTimes && totalUseTimes) || (totalUseTimes && totalUseTimes === true)) &&
                //   // ((useTimesPerCustomer && useTimesPerCustomer) || (useTimesPerCustomer && useTimesPerCustomer === true)) &&
                //   // ((startDate && startDate) || (startDate === null)) &&
                //   ((discountType === 1 && discountPercentage) ||(discountType === 2 && discountFixedAmount))
                //     ? false
                //     : true
                // }
              >
                Edit Promo Code
              </button>
            ) : (
              <button
                type="submit"
                className={
                  promoCodeName &&
                  localPromoCode &&
                  expirationTime?.length > 0 &&
                  ((totalUseTimes && totalUseTimes) ||
                    (totalUseTimes && totalUseTimes === true)) &&
                  ((useTimesPerCustomer && useTimesPerCustomer) ||
                    (useTimesPerCustomer && useTimesPerCustomer === true)) &&
                  ((startDate && startDate) || startDate === null) &&
                  ((discountType === 1 && discountPercentage) ||
                    (discountType === 2 && discountFixedAmount))
                    ? "btn-enable"
                    : "btn-disable"
                }
                disabled={
                  promoCodeName &&
                  localPromoCode &&
                  expirationTime?.length > 0 &&
                  ((totalUseTimes && totalUseTimes) ||
                    (totalUseTimes && totalUseTimes === true)) &&
                  ((useTimesPerCustomer && useTimesPerCustomer) ||
                    (useTimesPerCustomer && useTimesPerCustomer === true)) &&
                  ((startDate && startDate) || startDate === null) &&
                  ((discountType === 1 && discountPercentage) ||
                    (discountType === 2 && discountFixedAmount))
                    ? false
                    : true
                }
              >
                Add Promo Code
              </button>
            )}
          </div>
        </form>
        {/* </div> */}
      </div>
      {/* </DrawerContainer> */}

      <SetPromoCodeModal
        isActive={!!openDrawers[drawersRestaurants.setPromoCode]}
        closePanel={() => closeDrawer(drawersRestaurants.setPromoCode)}
        globalPromoCode={globalPromoCode}
      />

      <SetDiscountModal
        isActive={!!openDrawers[drawersRestaurants.setDiscount]}
        closePanel={() => closeDrawer(drawersRestaurants.setDiscount)}
      />

      <TotalUseTimesDrawerModal
        isActive={!!openDrawers[drawersRestaurants.totalUseTimes]}
        closePanel={() => closeDrawer(drawersRestaurants.totalUseTimes)}
      />

      <UseTimePerCustomerDrawerModal
        isActive={!!openDrawers[drawersRestaurants.useTimesPerCustomer]}
        closePanel={() => closeDrawer(drawersRestaurants.useTimesPerCustomer)}
      />

      <PromoCodeDateDrawerModal
        isActive={!!openDrawers[drawersRestaurants.promoCodeDate]}
        closePanel={() => closeDrawer(drawersRestaurants.promoCodeDate)}
      />
    </DrawerPromoCodeModalContainer>
  );
};

export default PromoCodeModal;
