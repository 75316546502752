import DefaultUser from "../../../../../assets/img/noAvatar.png";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import React, { useContext, useState, useEffect } from "react";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
// import { notes } from "static/staticData";
import { DrawerNotesContainer } from "./style";
import DrawerDeleteNotes from "./DrawerDeleteNotes";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
// import { NotesEmptyScreen } from "assets/img";
import NotesEmptyScreen from "assets/img/no-filter-data.png";
import moment from "moment";
import { Image } from "react-bootstrap";
// import defaultFood from "assets/img/default-food.jpg";

export default function DrawerNotes({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const { profileData, notesData, setNotesData, deleteNoteData, setDeleteNoteData } = useContext(CustomersContext);
  const [noteInput, setNoteInput] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [noteDeletedNotification, setNoteDeletedNotification] = useState("");

  // const [deleteNoteData, setDeleteNoteData] = useState({
  //   deleteModal: false,
  //   selectedNoteData: "",
  // });

  useEffect(() => {
    if (noteDeletedNotification) {
      setTimeout(() => setNoteDeletedNotification(null), 2 * 1000);
    }
  }, [noteDeletedNotification]);

  const deleteNoteHandler = () => {
    customAxios
      .delete(`/customer/${id}/note/${deleteNoteData?.selectedNoteData?.id}`)
      .then((res) => {
        if (res.status === 200) {
          setDeleteNoteData({
            deleteModal: false,
            selectedNoteData: "",
          });
          getNotesData();
        } else {
        }
        setNoteDeletedNotification({
          message: "Note Deleted.",
          type: "cuisine-inactive",
          table: `<p style="color: #8F949A"><b>${profileData?.first_name} ${profileData?.last_name} (customer).</b>.</p>`,
        });
      })
      .catch((err) => {});
  };

  const getNotesData = () => {
    customAxios
      .get(`/customer/${id}/notes`)
      .then((res) => {
        setNotesData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function handleSubmilForm(event) {
    event.preventDefault();
    const notesPayload = {
      note: noteInput,
      active:
        isChecked && profileData.status === 1
          ? false
          : isChecked && profileData.status === 0
          ? true
          : null,
    };
    customAxios
      .post(`/customer/${id}/note`, notesPayload)
      .then((res) => {
        if (res.status === 200) {
          getNotesData();
          setNoteInput("");
          setIsChecked(false);
          closePanel();
        } else {
        }
      })
      .catch((err) => {});
  }

  // console.log(profileData)
  // console.log(notesData)

  const statusRenderer = (status) => {
    switch (status) {
      case 11:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Banned
          </h6>
        );
      case 12:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Unbanned
          </h6>
        );
      case 2:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Changed to Active
          </h6>
        );
      case 1:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Changed to Inactive
          </h6>
        );
      case 0:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Deleted
          </h6>
        );
      case 3:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Email Verified
          </h6>
        );
      case 4:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Email Unverified
          </h6>
        );
      case 5:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Phone Verified
          </h6>
        );
      case 6:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Phone Unverified
          </h6>
        );
      case 7:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Website Verified
          </h6>
        );
      case 8:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Website Unverified
          </h6>
        );
      case 9:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Request Approved
          </h6>
        );
      case 10:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Request Declined
          </h6>
        );
      default:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Status not changed
          </h6>
        );
    }
  };

  return (
    <DrawerNotesContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <div className="drawer-bottom-content w-100">
          <div className="header-bg">
            <div className="d-flex  align-items-center close-drawer-icon">
              <FaChevronLeft
                size={20}
                style={{ zIndex: "9999999" }}
                className="cursor-pointer "
                onClick={closePanel}
              />
              <div className="drawer-title">Notes</div>
            </div>
            <Image
              src={
                profileData?.avatar?.file
                  ? profileData?.avatar?.file
                  : DefaultUser
              }
              className="bg-img"
            />
          </div>

          {/* <div className="top-title">
            <div>
              <FaChevronLeft
                size={20}
                onClick={closePanel}
                className="cursor-pointer"
              />
            </div>
            <div className="title">Notes</div>
            <div></div>
          </div> */}

          <div className="panel-status-container">
            <div className="profile-line"></div>
            <div className="upper-part">
              <img
                className="company-logo"
                src={
                  profileData?.avatar?.file
                    ? profileData?.avatar?.file
                    : DefaultUser
                }
                alt="user-avatar"
              />
              <p className="company-name">
                {profileData?.first_name} {profileData?.last_name}
              </p>
            </div>

            <div className="notes-count">
              <b>{notesData?.length}</b> notes
            </div>

            <div className="notes-list">
              {notesData.length ? (
                <div style={{ marginBottom: 50 }}>
                  {notesData.map((note, _i) => {
                    // const status = note.status
                    //   ? note.status === "active"
                    //     ? "Changed to Active"
                    //     : "Changed to Inactive"
                    //   : "Status not changed";
                    return (
                      <div key={_i} className="note-container-item">
                        <div className="note-image-block">
                          <img
                            src={note?.image ? note?.image : DefaultUser}
                            alt="avatar"
                          />
                        </div>
                        <div className="note-content-block">
                          <p className="commentator-name">{note?.admin_name}</p>
                          {/* <p className="commentator-status">
                            {note?.note ? note?.action : "-"}
                          </p> */}
                          {statusRenderer(note?.action)}
                          <p className="commentator-mail">
                            {note?.note ? note?.note : "-"}
                          </p>
                        </div>
                        <div className="note-end-block">
                          <p className="date-note">
                            {moment(note.createdat).format("MMM DD, YYYY")}
                          </p>
                          <div
                            className="delete-note"
                            onClick={() =>
                              setDeleteNoteData({
                                deleteModal: true,
                                selectedNoteData: note,
                              })
                            }
                          >
                            <i className="fas fa-trash-alt" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="no-note-data">
                  <img
                    className="no-notes-img"
                    src={NotesEmptyScreen}
                    alt="empty"
                  />
                  <p className="no-notes-message">No notes yet.</p>
                </div>
              )}
            </div>
          </div>

          <div className="form-section">
            <form onSubmit={handleSubmilForm}>
              <div className="notes-abuse-warning">
                {/* <div className="abuse-part">
                  <input
                    type="text"
                    placeholder="Write note here..."
                    className="w-100 note-input"
                    value={noteInput}
                    onChange={(event) => setNoteInput(event.target.value)}
                  />
                  {noteInput !== "" ? (
                    <button
                      type="submit"
                      style={{ background: "transparent", border: 0 }}
                    >
                      <i className="fas fa-share" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        background: "transparent",
                        border: 0,
                        opacity: "0.5",
                      }}
                      disabled
                    >
                      <i className="fas fa-share" />
                    </button>
                  )}
                </div> */}

                {/* <div className="change-active-status MuiIconButton-label">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    className={isChecked ? "white" : "gray"}
                    id="checkbox-round"
                    onChange={(event) => setIsChecked(event.target.checked)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                  <span style={{ marginLeft: "9px" }}>
                    Change status to{" "}
                    {profileData.status === 0 ? "Active" : "Inactive"}
                  </span>
                </div> */}

                <textarea
                  name="postContent"
                  rows={4}
                  cols={40}
                  placeholder="Write note here..."
                  value={noteInput}
                  onChange={(event) => setNoteInput(event.target.value)}
                />

                <div className="note-submit-btn">
                  {noteInput !== "" ? (
                    <button
                      type="submit"
                      style={{ background: "transparent", border: 0 }}
                    >
                      <i className="fas fa-share" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        background: "transparent",
                        border: 0,
                        opacity: "0.5",
                      }}
                      disabled
                    >
                      <i className="fas fa-share" />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </DrawerContainer>
      <DrawerDeleteNotes
        isActive={deleteNoteData.deleteModal}
        closePanel={() => {
          setDeleteNoteData({
            deleteModal: false,
            selectedNoteData: "",
          });
        }}
        deleteNoteHandler={deleteNoteHandler}
        noteDeletedNotification={noteDeletedNotification}
        setNoteDeletedNotification={setNoteDeletedNotification}
      />
    </DrawerNotesContainer>
  );
}
