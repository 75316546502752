import styled from "@emotion/styled";

export const PeriodFilterContainer = styled.div`
  display: flex;
  align-items: center;

  .period-item {
    width: 91px;
    padding: 11px 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    border-radius: 25px;

    &.checked {
      font-weight: bold;
      color: white;
      background-color: #525558;
    }
  }
`;
