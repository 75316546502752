import styled from "@emotion/styled";
import { MyDrawerLeftArrow } from "components/Elements/MyDrawerLeftArrow";

export const DrawerWallContainer = styled(MyDrawerLeftArrow)`
  .table-shape-container {
    padding: 36px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .shape-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .wall-shape {
      width: 155px;
      height: 98px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      cursor: pointer;
      &.active {
        border: solid 2px #ed2626;
        transition: 0.5s ease;
      }
      &.inactive {
        border: solid 1px rgba(255, 255, 255, 0.1);
        transition: 0.5s ease;
      }
    }
  }
  .table-information {
    margin-left: -26px;
    margin-right: -26px;
    .set {
      padding: 0 27px 0 39px;
    }
  }
`;
