import styled from "@emotion/styled";
import { colors } from "theme";

export const CardInputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 8px;

  &.card-number {
    .card-input-container {
      .card-input-view {
        font-size: 16px;
        font-weight: 600;
        ::placeholder {
          opacity: 0.3;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: -0.34px;
          color: #fff;
        }
      }
    }
  }

  label {
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    color: #fff;
    margin-bottom: 1px;
  }

  .card-input-container {
    width: 100%;
    display: flex;

    .card-input-view {
      width: 100%;
      background-color: transparent;
      outline: none;
      border-color: transparent;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: white;
      padding-left: 0px;
      padding-bottom: 11px;
    }

    .card-input-icon {
      &.valid {
        i {
          color: ${colors.green};
        }
      }

      &.empty {
        i {
          color: ${colors.black1};
        }
      }

      &.error {
        i {
          color: ${colors.redColor};
        }
      }

      i {
        font-size: 20px;
      }
    }
  }
`;
