import styled from "@emotion/styled";

export const PopularListContainer = styled.div`
  background-color: #1b1c1b;
  border-radius: 8px;
  padding-top: 19px;
  display: flex;
  flex-direction: column;

  .popular-list-top-container {
    padding: 0px 23px 28px 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .popular-list-top-title {
      font-size: 20px;
      font-weight: bold;
      color: white;
      margin-bottom: 0px;
    }
  }

  .popular {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem 0 7.5rem;
    .popular-item {
      opacity: 0.5;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }
    .popular-restaurant {
      opacity: 0.5;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }
  }

  .popular-1 {
    display: flex;
    justify-content: space-between;
    padding: 0 2.5rem 0 7.5rem;
    .popular-item {
      opacity: 0.5;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }
    .popular-restaurant {
      opacity: 0.5;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }
  }

  .popular-list-core {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    margin-top: 12px;

    .popular-list-item {
      display: flex;
      align-items: center;
      padding: 10.5px 30px 10.5px 34px;

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.dark {
        background-color: #1b1c1d;
      }

      &.darker {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .popular-list-item-number {
        width: 40px;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.58px;
        color: rgba(255, 255, 255, 0.5);
      }

      .popular-list-item-avatar {
        margin-left: 26px;
        margin-right: 25px;

        img {
          border-radius: 50px;
        }

        &.rounded {
          padding: 2.5px 0px;
          img {
            border-radius: 8px;
          }
        }
      }

      .popular-name-and-value-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .popular-list-item-name {
          font-size: 16px;
          font-weight: 500;
          color: white;
        }

        .popular-list-item-value {
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
      }
    }
  }

  .time-periods {
    display: flex;
    align-items: center;
    background: #27282a;
    border-radius: 30px;
    .period-filter {
      div {
        width: 91px;
      }
      padding: 8px 0px;
      width: 91px;
      text-align: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      font-weight: 500;
      &.selected-tab {
        background-color: #3e4245;
        border-radius: 30px;
        padding: 8px 0px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
      }
    }
    #popular-restaurant-calendar-popup {
      position: relative;
      padding-right: 20px;
      .popup-sales-calendar {
        position: absolute;
        right: 0;
      }
    }
  }
`;
