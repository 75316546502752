import styled from "@emotion/styled";
import { Dropdown } from "react-bootstrap";

export const MyDropdownContainer = styled(Dropdown)`
  .dropdown-header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 27px;
    border: solid 1px rgba(255, 255, 255, 0.08);
    color: rgba(255, 255, 255, 0.5);
    padding: 10px 20px;
    cursor: pointer;
    i {
      /* color: rgba(255, 255, 255, 0.2); */
      color: #fff;
      font-weight: 500;
    }
    .option-label-item {
      color: #fff;
    }
  }

  /* .dropdown-content-menu {
    width: 100%;
    border-radius: 25px;
    border: solid 1px rgba(255, 255, 255, 0.08);
    background-color: #151617;
    overflow: auto;

    .list-unstyled {
      a {
        padding: 10px 1.5rem;
        .option-label-item {
          font-size: 14px;
          color: rgba(255, 255, 255);
        }
      }
      a:last-child {
        margin-bottom: 0px;
      }
      a:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .rendered-dropdown {
        cursor: pointer;
        font-size: 14px;
        color: #ed2626;
        padding: 10px 1.5rem;
      }
      .rendered-dropdown:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .rendered-dropdown:active {
        background-color: unset;
      }
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: #ffffff;
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.1);
    }
  } */

  &.show {
    .dropdown-toggle {
      background-color: #27282a !important;
      border-color: transparent !important;
    }
  }
  .dropdown-toggle {
    border-radius: 25px;
    border: solid 1px transparent;
    background-color: #27282a;
    font-size: 14px;
    font-weight: normal;
    /* padding: 0 30px; */
    height: 40px;
    &:hover,
    &:focus,
    &:active {
      background-color: #27282a !important;
      border-color: transparent !important;
    }
  }
  .dropdown-menu {
    padding: 0;
    &.show {
      border-radius: 25px;
      border: solid 1px transparent;
      background-color: #27282a;
      min-width: 75px;
      width: 100%;
      padding: 16px 0;
      a:first-of-type {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      a:last-child {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      a {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        p {
          margin-bottom: 0;
        }
      }
      a:hover {
        background-color: transparent;
        color: #ed2626;
      }

      .active {
        background-color: transparent;
        color: #ed2626;
      }
    }
  }
  .dropdown-title {
    margin-right: 5px;
    color: #8f949a;
  }
`;
