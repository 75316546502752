import React, { useState, useEffect, useContext, useRef } from "react";
import { PromoCodesPageContainer } from "./style";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Badge } from "react-bootstrap";
import { FaChevronRight, FaPlus } from "react-icons/fa";
// import { useParams } from "react-router-dom";
import moment from "moment";
import EmptyImage from "assets/img/empty.png";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawersRestaurants } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import PromoCodeModal from "components/restaurantProfile/RestaurantProfileOverall/PromoCode/PromoCodeDrawerModal";
import MySearch from "components/Elements/MySearch";
import { colors } from "theme";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MyButton from "components/Elements/MyButton";
import MyPromoCodeInput from "components/Elements/MyPromoCodeInput";
import MyPromoCodeSearchComponent from "components/Elements/MyPromoCodeSearch";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import useOnclickOutside from "react-cool-onclickoutside";
import noResult from "../../../assets/img/no-filter-data.png";
import { Switch } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import PromoStatusModal from "./PromoStatusModal"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    left: "250px",
  },
}));

export function PromoCodesPage() {
  // const param = useParams();
  const filterRef = useRef();
  const classes = useStyles();
  let location = useLocation();

  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    setUpdatePromoCodeDetails,
    couponData,
    setCouponData,
    promoCodeStatus,
    setPromoCodeStatus,
    statusActiveNotification,
    setStatusActiveNotification,
  } = useContext(RestaurantsContext);

  // const [couponData, setCouponData] = useState([]);
  // const [promoCodeStatus, setPromoCodeStatus] = useState(1);

  const [selectedFilter, setSelectedFilter] = useState("");
  const [sortType, setSortType] = useState("");
  const [copiedCode, setCopiedCode] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [openDiscountDollar, setOpenDiscountDollar] = useState(false);
  const [openDiscountPercent, setOpenDiscountPercent] = useState(false);
  const [discountDollarFrom, setDiscountDollarFrom] = useState("");
  const [discountDollarTo, setDiscountDollarTo] = useState("");
  const [discountPercentFrom, setDiscountPercentFrom] = useState("");
  const [discountPercentTo, setDiscountPercentTo] = useState("");
  const [searchPromoCode, setSearchPromoCode] = useState("");

  const [finalDiscountDollarFrom, setFinalDiscountDollarFrom] = useState("");
  const [finalDiscountDollarTo, setFinalDiscountDollarTo] = useState("");

  const [finalDiscountPercentTo, setFinalDiscountPercentTo] = useState("");
  const [finalDiscountPercentFrom, setFinalDiscountPercentFrom] = useState("");

  const [filterApplied, setFilterApplied] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [nameSort, setNameSort] = useState(false);
  const [discountSort, setDiscountSort] = useState(false);

  useEffect(() => {
    getPromoCodeData();
  }, [
    promoCodeStatus,
    searchPromoCode,
    finalDiscountPercentTo,
    finalDiscountPercentFrom,
    finalDiscountDollarTo,
    finalDiscountDollarFrom,
  ]);

  useEffect(() => {
    if (statusActiveNotification) {
      setTimeout(() => setStatusActiveNotification(null), 2 * 1000);
    }
  }, [statusActiveNotification]);

  useEffect(() => {
    return () => setPromoCodeStatus(1);
  }, []);

  const onChangeSearchPromoCode = (newValue) => setSearchPromoCode(newValue);
  const onClearSearchPromoCode = () => setSearchPromoCode("");

  function getPromoCodeData() {
    setIsLoading(true);
    let url;
    if (promoCodeStatus) {
      url = `/coupon?status=${promoCodeStatus}&scope=2&query=${searchPromoCode}`;
    }
    if (finalDiscountPercentTo) {
      url = `/coupon?status=${promoCodeStatus}&scope=2&query=${searchPromoCode}&discount_percent=${finalDiscountPercentFrom},${finalDiscountPercentTo}`;
    }
    if (finalDiscountDollarTo) {
      url = `/coupon?status=${promoCodeStatus}&scope=2&query=${searchPromoCode}&discount_dollar=${finalDiscountDollarFrom},${finalDiscountDollarTo}`;
    }
    if (finalDiscountDollarTo && finalDiscountPercentTo) {
      url = `/coupon?status=${promoCodeStatus}&scope=2&query=${searchPromoCode}&discount_percent=${finalDiscountPercentFrom},${finalDiscountPercentTo}&discount_dollar=${finalDiscountDollarFrom},${finalDiscountDollarTo}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCouponData(res?.data);
        } else {
          setCouponData([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // tabs onClick method
  const handleSelect = (key) => {
    if (key === "1") {
      setPromoCodeStatus(1);
    } else if (key === "2") {
      setPromoCodeStatus(2);
    }
  };

  function updateCouponDetail(promoCodedetails) {
    setUpdatePromoCodeDetails(promoCodedetails);
    openDrawer(drawersRestaurants.promoStatusModal);
  }

  function toggleFilter(filterName) {
    if (selectedFilter === filterName) {
      setSortType(sortType === "up" ? "down" : "up");
      if (sortType === "up") {
        if (filterName === "name") {
          setCouponData(
            couponData.sort((a, b) => b.name.localeCompare(a.name))
          );
        } else if (filterName === "discount") {
          setCouponData(couponData.sort((a, b) => b.discount - a.discount));
        }
      } else {
        if (filterName === "name") {
          setCouponData(
            couponData.sort((a, b) => a.name.localeCompare(b.name))
          );
        } else if (filterName === "discount") {
          setCouponData(couponData.sort((a, b) => a.discount - b.discount));
        }
      }
    } else {
      setSelectedFilter(filterName);
      setSortType("up");
      if (filterName === "name") {
        setCouponData(couponData.sort((a, b) => a.name.localeCompare(b.name)));
      } else if (filterName === "discount") {
        setCouponData(couponData.sort((a, b) => a.discount - b.discount));
      }
    }
  }

  const copyToClipboard = (code) => {
    setCopiedCode(code);
    navigator.clipboard.writeText(code);
    setTimeout(() => {
      setCopiedCode("");
    }, 4000);
  };

  const ref = useOnclickOutside(() => {
    setOpenFilter(false);
  });

  const updateInActivePromoCode = (promocode, statusValue) => {
    let data = [...couponData];
    let findIndex = data.findIndex((el) => el?.id === promocode?.id);
    data[findIndex].status = statusValue;
    setCouponData(data);
    const configUpdatePromoCode = {
      status: statusValue,
    };
    customAxios
      .patch(`/coupon/${promocode?.id}`, configUpdatePromoCode)
      .then((res) => {
        if (res.status === 200) {
          customAxios
            .get(`/coupon?status=${promoCodeStatus}&scope=2`)
            .then((res) => {
              setCouponData(res?.data);
            })
            .catch((err) => {
              console.error(err);
            });
          if (statusValue === 1) {
            setStatusActiveNotification({
              message: "Promo Code Active.",
              type: "promo-active",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promocode?.name} • ${promocode?.code}</span>`,
            });
          } else {
            setStatusActiveNotification({
              message: "Promo Code Inactive.",
              type: "promo-inactive",
              table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${promocode?.name} • ${promocode?.code}</span>`,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function promoNameToggleFilter() {
    setNameSort(!nameSort);
    if (nameSort) {
      setCouponData(couponData.sort((a, b) => b.name.localeCompare(a.name)));
    } else {
      setCouponData(couponData.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }

  function promoDiscountToggleFilter() {
    setDiscountSort(!discountSort);
    if (discountSort) {
      setCouponData(
        couponData.sort((a, b) => b.discount.localeCompare(a.discount))
      );
    } else {
      setCouponData(
        couponData.sort((a, b) => a.discount.localeCompare(b.discount))
      );
    }
  }

  useEffect(() => {
    setDiscountDollarFrom("");
    setFinalDiscountDollarFrom("");
    setDiscountDollarTo("");
    setFinalDiscountDollarTo("");
    setDiscountPercentFrom("");
    setFinalDiscountPercentFrom("");
    setDiscountPercentTo("");
    setFinalDiscountPercentTo("");
    setFilterApplied(false);
    setOpenDiscountDollar(false);
    setOpenDiscountPercent(false);
  }, [promoCodeStatus]);

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };





  return (
    <PromoCodesPageContainer>
      {statusActiveNotification && (
        <MyMessageBoxItemNew
          message={
            statusActiveNotification?.message
              ? statusActiveNotification?.message
              : ""
          }
          table={
            statusActiveNotification?.table
              ? statusActiveNotification?.table
              : ""
          }
          type={
            statusActiveNotification?.type ? statusActiveNotification?.type : ""
          }
          onClick={() => setStatusActiveNotification(null)}
        />
      )}
      <div className="promo-code-wrapper">
        <div className="promo-code-header">
          <div className="promo-code-title">Promo Codes</div>
          {(!couponData.length && (searchPromoCode || filterApplied)) ||
          (couponData.length && (!searchPromoCode || !filterApplied)) ||
          (couponData.length && (searchPromoCode || filterApplied)) ? (
            <div className="filter-wrapper">
              <MyPromoCodeSearchComponent
                placeholder="Enter..."
                options={[
                  ...(couponData &&
                    couponData.map(({ name }, index) => ({
                      id: index,
                      value: `${name}`,
                    }))),
                ]}
                searchValue={searchPromoCode}
                setSearchValue={setSearchPromoCode}
                onChangeSearch={(value) => onChangeSearchPromoCode(value)}
                onClearAfterSearch={() => onClearSearchPromoCode()}
              />

              <div
                // className={`filter-btn-wrapper ${(discountDollarFrom ||
                //   discountDollarTo ||
                //   discountPercentFrom ||
                //   discountPercentTo) &&
                //   "active"}`}

                // onClick={() => {
                //   setOpenFilter(!openFilter);
                // }}

                className={`filter-btn-wrapper ${filterApplied && "active"}`}
                ref={ref}
              >
                {/* <i
                className="fas fa-sliders-h"
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              /> */}

                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenFilter(!openFilter);
                  }}
                >
                  <i class="fas fa-sliders-h"></i>
                </div>

                {openFilter && (
                  <div className="filter-container">
                    <div className="filter-header">
                      <span>Filter</span>
                      {(discountDollarFrom ||
                        discountDollarTo ||
                        discountPercentFrom ||
                        discountPercentTo) && (
                        <button
                          // onClick={() => {
                          //   // setOpenFilter(true);
                          //   setOpenFilter(!openFilter);
                          //   setDiscountDollarFrom("");
                          //   setDiscountDollarTo("");
                          //   setDiscountPercentFrom("");
                          //   setDiscountPercentTo("");
                          // }}
                          onClick={() => {
                            setDiscountDollarFrom("");
                            setFinalDiscountDollarFrom("");
                            setDiscountDollarTo("");
                            setFinalDiscountDollarTo("");
                            setDiscountPercentFrom("");
                            setFinalDiscountPercentFrom("");
                            setDiscountPercentTo("");
                            setFinalDiscountPercentTo("");
                            setFilterApplied(false);
                            setOpenFilter(!openFilter);
                          }}
                        >
                          Reset All
                        </button>
                      )}
                    </div>
                    <div className="filter-body">
                      <div className="filter-item">
                        <div className="filter-item-header">
                          <span
                            onClick={() =>
                              setOpenDiscountDollar(!openDiscountDollar)
                            }
                          >
                            Discount, $
                          </span>
                          <div className="filter-item-header-btn">
                            {(discountDollarFrom || discountDollarTo) && (
                              <button
                                className="clear-filter-btn"
                                // onClick={() => {
                                //   setDiscountDollarFrom("");
                                //   setDiscountDollarTo("");
                                // }}
                                onClick={() => {
                                  setDiscountDollarFrom("");
                                  setFinalDiscountDollarFrom("");
                                  setDiscountDollarTo("");
                                  setFinalDiscountDollarTo("");
                                  if (discountPercentTo) {
                                    setFilterApplied(true);
                                  } else {
                                    setFilterApplied(false);
                                  }
                                }}
                              >
                                X
                              </button>
                            )}
                            {openDiscountDollar ? (
                              <i
                                class="fas fa-angle-up"
                                onClick={() =>
                                  setOpenDiscountDollar(!openDiscountDollar)
                                }
                              ></i>
                            ) : (
                              <i
                                class="fas fa-angle-down"
                                onClick={() =>
                                  setOpenDiscountDollar(!openDiscountDollar)
                                }
                              ></i>
                            )}
                          </div>
                        </div>
                        {openDiscountDollar && (
                          <div className="filter-body">
                            <Slider
                              range
                              allowCross={false}
                              defaultValue={[
                                discountDollarFrom,
                                discountDollarTo,
                              ]}
                              value={[discountDollarFrom, discountDollarTo]}
                              onChange={(value) => {
                                setDiscountDollarFrom(value[0]);
                                setDiscountDollarTo(value[1]);
                              }}
                              max={999}
                            />
                            <div className="filter-item-body">
                              <MyPromoCodeInput
                                className=""
                                placeholder="From"
                                value={discountDollarFrom}
                                onChange={(e) =>
                                  setDiscountDollarFrom(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  )
                                }
                                style={{
                                  width: "50%",
                                  border: "none",
                                  background: "transparent",
                                  boxShadow: "none",
                                  outline: "none",
                                  height: "auto",
                                }}
                                taxes={true}
                                prefix="$"
                              />
                              <MyPromoCodeInput
                                className=""
                                placeholder="To"
                                value={discountDollarTo}
                                onChange={(e) =>
                                  setDiscountDollarTo(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  )
                                }
                                style={{
                                  width: "50%",
                                  border: "none",
                                  background: "transparent",
                                  boxShadow: "none",
                                  outline: "none",
                                  height: "auto",
                                }}
                                taxes={true}
                                prefix="$"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="filter-item">
                        <div className="filter-item-header">
                          <span
                            onClick={() =>
                              setOpenDiscountPercent(!openDiscountPercent)
                            }
                          >
                            Discount, %
                          </span>
                          <div className="filter-item-header-btn">
                            {(discountPercentFrom || discountPercentTo) && (
                              <button
                                className="clear-filter-btn"
                                onClick={() => {
                                  setDiscountPercentFrom("");
                                  setDiscountPercentTo("");
                                }}
                              >
                                X
                              </button>
                            )}
                            {openDiscountPercent ? (
                              <i
                                class="fas fa-angle-up"
                                onClick={() =>
                                  setOpenDiscountPercent(!openDiscountPercent)
                                }
                              ></i>
                            ) : (
                              <i
                                class="fas fa-angle-down"
                                onClick={() =>
                                  setOpenDiscountPercent(!openDiscountPercent)
                                }
                              ></i>
                            )}
                          </div>
                        </div>
                        {openDiscountPercent && (
                          <div className="filter-body">
                            <Slider
                              range
                              allowCross={false}
                              defaultValue={[
                                discountPercentFrom,
                                discountPercentTo,
                              ]}
                              value={[discountPercentFrom, discountPercentTo]}
                              onChange={(value) => {
                                setDiscountPercentFrom(value[0]);
                                setDiscountPercentTo(value[1]);
                              }}
                              max={100}
                            />
                            <div className="filter-item-body">
                              <MyPromoCodeInput
                                className=""
                                placeholder="From"
                                value={discountPercentFrom}
                                onChange={(e) =>
                                  setDiscountPercentFrom(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                                style={{
                                  width: "50%",
                                  border: "none",
                                  background: "transparent",
                                  boxShadow: "none",
                                  outline: "none",
                                  height: "auto",
                                }}
                                taxes={true}
                                prefix="%"
                              />
                              <MyPromoCodeInput
                                className=""
                                placeholder="To"
                                value={discountPercentTo}
                                onChange={(e) =>
                                  setDiscountPercentTo(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                                style={{
                                  width: "50%",
                                  border: "none",
                                  background: "transparent",
                                  boxShadow: "none",
                                  outline: "none",
                                  height: "auto",
                                }}
                                taxes={true}
                                prefix="%"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <MyButton
                      className="apply-btn"
                      disabled={
                        // (!discountDollarFrom && !discountDollarTo) || (!discountPercentFrom && !discountPercentTo)
                        !discountDollarTo && !discountPercentTo ? true : false
                      }
                      type={
                        // (!discountDollarFrom && !discountDollarTo) || (!discountPercentFrom && !discountPercentTo)
                        !discountDollarTo && !discountPercentTo ? "gray" : "red"
                      }
                      onClick={() => {
                        // setOpenFilter(false);
                        setOpenFilter(!openFilter);
                        setFinalDiscountDollarFrom(discountDollarFrom);
                        setFinalDiscountDollarTo(discountDollarTo);
                        setFinalDiscountPercentFrom(discountPercentFrom);
                        setFinalDiscountPercentTo(discountPercentTo);
                        setFilterApplied(true);
                      }}
                    >
                      Apply
                    </MyButton>
                    <MyButton
                      className="cancel-btn"
                      type={
                        // (!discountDollarFrom && !discountDollarTo) || (!discountPercentFrom && !discountPercentTo)
                        "transparent"
                      }
                      color="#ed2626"
                      onClick={() => {
                        setOpenFilter(false);
                      }}
                    >
                      Cancel
                    </MyButton>
                  </div>
                )}
              </div>

              <div
                className="add-promo-btn"
                onClick={() => {
                  openDrawer(drawersRestaurants.addPromoCodeModal);
                  setUpdatePromoCodeDetails("");
                }}
              >
                <FaPlus color="white" className="mr-2" /> Add Promo Code
              </div>
            </div>
          ) : null}
        </div>

        <div className="promo-codes-tab">
          <Tab.Container
            id=""
            defaultActiveKey={promoCodeStatus}
            activeKey={promoCodeStatus}
            onSelect={handleSelect}
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey={"1"}>
                  <span
                    style={
                      promoCodeStatus === 1
                        ? { color: "#fff" }
                        : { opacity: 0.5 }
                    }
                  >
                    Active
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"2"}>
                  <span
                    style={
                      promoCodeStatus === 2
                        ? { color: "#fff" }
                        : { opacity: 0.5 }
                    }
                  >
                    Inactive
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {couponData.length && (searchPromoCode || filterApplied) ? (
              <div
                className="search-applied"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "35px",
                }}
              >
                <div
                  className="applied-text"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  {/* Search Applied */}
                  {searchPromoCode && !filterApplied
                    ? `Search Applied`
                    : filterApplied && !searchPromoCode
                    ? `Filter Applied`
                    : filterApplied && searchPromoCode
                    ? `Search & Filter Applied`
                    : ""}
                </div>
                <div className="result-text" style={{ fontSize: "16px" }}>
                  <span className="text-bold">{couponData.length}</span> results
                </div>
              </div>
            ) : null}
            {/* tab section */}
            {isLoading ? (
              <Backdrop
                className={classes.backdrop}
                open={isLoading ? true : false}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <Row style={{ marginTop: "34px" }}>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey={"1"}>
                      <div>
                        {couponData &&
                        couponData?.filter((el) => el?.status === 1).length >
                          0 ? (
                          <table>
                            <thead>
                              <tr>
                                {/* <th
                                  onClick={() => toggleFilter("name")}
                                  style={{ cursor: "pointer" }}
                                >
                                  {console.log(selectedFilter)}
                                  {console.log(sortType)}
                                  Name{" "}
                                  {selectedFilter === "name" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() => promoNameToggleFilter()}
                                  style={{ cursor: "pointer" }}
                                >
                                  Name{" "}
                                  {nameSort ? (
                                    <i
                                      className="fas fa-angle-up"
                                      style={{ fontSize: "14px" }}
                                    />
                                  ) : (
                                    <i
                                      className="fas fa-angle-down"
                                      style={{ fontSize: "14px" }}
                                    />
                                  )}
                                </th>
                                <th onClick={() => toggleFilter("role_slug")}>
                                  Promo Code{" "}
                                </th>
                                <th onClick={() => toggleFilter("status")}>
                                  Active Dates{" "}
                                </th>
                                <th onClick={() => toggleFilter("rating")}>
                                  Expiration Time{" "}
                                </th>

                                <th onClick={() => toggleFilter("email")}>
                                  Total Use Times{" "}
                                </th>
                                <th
                                  onClick={() => toggleFilter("phone_number")}
                                >
                                  Use Times/Per Customer{" "}
                                </th>
                                <th onClick={() => toggleFilter("email")}>
                                  Min Order{" "}
                                </th>
                                <th onClick={() => toggleFilter("email")}>
                                  Max Discount{" "}
                                </th>
                                {/* <th
                                  onClick={() => toggleFilter("discount")}
                                  style={{ cursor: "pointer" }}
                                >
                                  Discount{" "}
                                  {selectedFilter === "discount" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() => promoDiscountToggleFilter()}
                                  style={{ cursor: "pointer" }}
                                >
                                  Discount{" "}
                                  {discountSort ? (
                                    <i
                                      className="fas fa-angle-up"
                                      style={{ fontSize: "14px" }}
                                    />
                                  ) : (
                                    <i
                                      className="fas fa-angle-down"
                                      style={{ fontSize: "14px" }}
                                    />
                                  )}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {couponData?.map((promoCode, index) => {
                                return (
                                  <tr key={`staff-${index}`}>
                                    <td>
                                      <div className="name-container">
                                        <span className="staff-avatar-title">
                                          {getHighlightedText( promoCode?.name, searchPromoCode, "#ed2626")}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-container-normal">
                                      <div className="custom-badge">
                                        {promoCode?.code}{" "}
                                        <i
                                          className="fas fa-copy ml-2"
                                          style={{
                                            color:
                                              copiedCode === promoCode?.code
                                                ? "#fff"
                                                : "#767777",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            copyToClipboard(promoCode?.code);
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      {promoCode?.end_date
                                        ? `${moment(
                                            promoCode?.start_date
                                          ).format("ll")} - ${moment(
                                            promoCode?.end_date
                                          ).format("ll")}`
                                        : "Not Limited"}
                                    </td>
                                    <td style={{ paddingLeft: "30px" }}>
                                      {promoCode?.end_date &&
                                      promoCode?.end_time
                                        ? moment(
                                            promoCode?.end_time,
                                            "h:mm A"
                                          ).format("HH:mm A")
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      {" "}
                                      {promoCode?.times_used ||
                                      promoCode?.limit_overall
                                        ? `${promoCode?.times_used}/${promoCode?.limit_overall}`
                                        : `${promoCode?.times_used}/Not Limited`}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{ paddingLeft: "40px" }}
                                    >
                                      {promoCode?.limit_user
                                        ? promoCode?.limit_user
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{ paddingLeft: "30px" }}
                                    >
                                      {promoCode?.threshold !== null ||
                                      promoCode?.threshold > 0
                                        ? `$${promoCode?.threshold}`
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{ paddingLeft: "30px" }}
                                    >
                                      {promoCode?.max_discount
                                        ? `$${promoCode?.max_discount}`
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{
                                        color: "#1fc085",
                                        fontWeight: 600,
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {" "}
                                      {promoCode?.discount_type === 1
                                        ? `${promoCode?.discount}%`
                                        : `$${promoCode?.discount}`}
                                    </td>
                                    <td style={{ opacity: 1 }}>
                                      <span
                                        style={{
                                          justifyContent: "flex-end",
                                          cursor: "pointer",
                                        }}
                                        // onClick={() => {
                                        //   updateCouponDetail(promoCode);
                                        // }}
                                      >
                                        <div className="toggle-btn">
                                        <Switch
                                          checked={
                                            promoCode?.status === 2
                                              ? false
                                              : true
                                          }
                                          onChange={() => {
                                            updateCouponDetail(promoCode);
                                            // updateInActivePromoCode(
                                            //   promoCode,
                                            //   2
                                            // );
                                          }}
                                        />
                                      </div>
                                        {/* <i
                                          className="fas fa-cog"
                                          style={{
                                            color: "#767777",
                                            fontSize: "18px",
                                          }}
                                        /> */}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <>
                            {searchPromoCode || filterApplied ? (
                              <div className="search-no-result-wrapper">
                                <div className="search-applied">
                                  <div className="applied-text">
                                    {/* Search Applied */}
                                    {searchPromoCode && !filterApplied
                                      ? `Search Applied`
                                      : filterApplied && !searchPromoCode
                                      ? `Filter Applied`
                                      : filterApplied && searchPromoCode
                                      ? `Search & Filter Applied`
                                      : ""}
                                  </div>
                                  <div className="result-text">
                                    <span className="text-bold">
                                      {couponData.length}
                                    </span>{" "}
                                    results
                                  </div>
                                </div>
                                <div className="no-result">
                                  <img src={noResult} alt="No results" />
                                  <div className="no-result-text">
                                    No results
                                  </div>
                                  <div className="broadening-title">
                                    Try broadening your search.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="empty-container">
                                <img src={EmptyImage} alt="" />
                                <div className="empty-screen">
                                  There are no promo codes to show yet.
                                </div>
                                <div
                                  className="add-promo-btn"
                                  onClick={() => {
                                    openDrawer(
                                      drawersRestaurants.addPromoCodeModal
                                    );
                                    setUpdatePromoCodeDetails("");
                                  }}
                                >
                                  <FaPlus color="white" className="mr-2" /> Add
                                  Promo Code
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={"2"}>
                      <div>
                        {couponData &&
                        couponData?.filter((el) => el?.status === 2).length >
                          0 ? (
                          <table>
                            <thead>
                              <tr>
                                {/* <th
                                  onClick={() => toggleFilter("name")}
                                  style={{ cursor: "pointer" }}
                                >
                                  Name{" "}
                                  {selectedFilter === "name" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() => promoNameToggleFilter()}
                                  style={{ cursor: "pointer" }}
                                >
                                  Name{" "}
                                  {nameSort ? (
                                    <i
                                      className="fas fa-angle-up"
                                      style={{ fontSize: "14px" }}
                                    />
                                  ) : (
                                    <i
                                      className="fas fa-angle-down"
                                      style={{ fontSize: "14px" }}
                                    />
                                  )}
                                </th>
                                <th onClick={() => toggleFilter("role_slug")}>
                                  Promo Code{" "}
                                  {selectedFilter === "role_slug" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => toggleFilter("status")}>
                                  Active Dates{" "}
                                  {selectedFilter === "status" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => toggleFilter("rating")}>
                                  Expiration Time{" "}
                                  {selectedFilter === "rating" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>

                                <th onClick={() => toggleFilter("email")}>
                                  Total Use Times{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th
                                  onClick={() => toggleFilter("phone_number")}
                                >
                                  Use Times/Per Customer{" "}
                                  {selectedFilter === "phone_number" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => toggleFilter("email")}>
                                  Min Order{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                <th onClick={() => toggleFilter("email")}>
                                  Max Discount{" "}
                                  {selectedFilter === "email" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th>
                                {/* <th
                                  onClick={() => toggleFilter("disount")}
                                  style={{ cursor: "pointer" }}
                                >
                                  Discount{" "}
                                  {selectedFilter === "discount" &&
                                    (sortType === "up" ? (
                                      <i className="fas fa-arrow-up" />
                                    ) : (
                                      <i className="fas fa-arrow-down" />
                                    ))}
                                </th> */}
                                <th
                                  onClick={() => promoDiscountToggleFilter()}
                                  style={{ cursor: "pointer" }}
                                >
                                  Discount{" "}
                                  {discountSort ? (
                                    <i
                                      className="fas fa-angle-up"
                                      style={{ fontSize: "14px" }}
                                    />
                                  ) : (
                                    <i
                                      className="fas fa-angle-down"
                                      style={{ fontSize: "14px" }}
                                    />
                                  )}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {couponData?.map((promoCode, index) => {
                                return (
                                  <tr key={`staff-${index}`}>
                                    <td>
                                      <div className="name-container">
                                        <span className="staff-avatar-title">
                                          {promoCode?.name}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.code}{" "}
                                      <i
                                        class="fas fa-copy"
                                        style={{
                                          color:
                                            copiedCode === promoCode.code
                                              ? "#fff"
                                              : "#767777",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          copyToClipboard(promoCode?.code)
                                        }
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      {promoCode?.end_date
                                        ? `${moment(
                                            promoCode?.start_date
                                          ).format("ll")} - ${moment(
                                            promoCode?.end_date
                                          ).format("ll")}`
                                        : "Not Limited"}
                                    </td>
                                    <td style={{ paddingLeft: "30px" }}>
                                      {promoCode?.end_date &&
                                      promoCode?.end_time
                                        ? moment(
                                            promoCode?.end_time,
                                            "h:mm A"
                                          ).format("HH:mm A")
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {" "}
                                      {promoCode?.times_used ||
                                      promoCode?.limit_overall
                                        ? `${promoCode?.times_used}/${promoCode?.limit_overall}`
                                        : `${promoCode?.times_used}/Not Limited`}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.limit_user}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.threshold !== null ||
                                      promoCode?.threshold > 0
                                        ? `$${promoCode?.threshold}`
                                        : "-"}
                                    </td>
                                    <td className="text-container-normal">
                                      {promoCode?.max_discount
                                        ? `$${promoCode?.max_discount}`
                                        : "-"}
                                    </td>
                                    <td
                                      className="text-container-normal"
                                      style={{
                                        color: "#1fc085",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {" "}
                                      {promoCode?.discount_type === 1
                                        ? `${promoCode?.discount}%`
                                        : `$${promoCode?.discount}`}
                                    </td>
                                    <td style={{ opacity: 1 }}>
                                      <span
                                        style={{
                                          justifyContent: "flex-end",
                                          cursor: "pointer",
                                        }}
                                        // onClick={() => {
                                        //   updateCouponDetail(promoCode);
                                        // }}
                                      >
                                        <div className="toggle-btn">
                                        <Switch
                                          checked={
                                            promoCode?.status === 2
                                              ? false
                                              : true
                                          }
                                          onChange={() => {
                                            updateCouponDetail(promoCode);
                                            // updateInActivePromoCode(
                                            //   promoCode,
                                            //   1
                                            // );
                                          }}
                                        />
                                      </div>
                                        {/* <i
                                          className="fas fa-cog"
                                          style={{
                                            color: "#767777",
                                            fontSize: "18px",
                                          }}
                                        /> */}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <>
                            {searchPromoCode || filterApplied ? (
                              <div className="search-no-result-wrapper">
                                <div className="search-applied">
                                  <div className="applied-text">
                                    {searchPromoCode && !filterApplied
                                      ? `Search Applied`
                                      : filterApplied && !searchPromoCode
                                      ? `Filter Applied`
                                      : filterApplied && searchPromoCode
                                      ? `Search & Filter Applied`
                                      : ""}
                                  </div>
                                  <div className="result-text">
                                    <span className="text-bold">
                                      {couponData.length}
                                    </span>{" "}
                                    results
                                  </div>
                                </div>
                                <div className="no-result">
                                  <img src={noResult} alt="No results" />
                                  <div className="no-result-text">
                                    No results
                                  </div>
                                  <div className="broadening-title">
                                    Try broadening your search.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="empty-container">
                                <img src={EmptyImage} alt="" />
                                <div className="empty-screen">
                                  There are no promo codes to show yet.
                                </div>
                                <div
                                  className="add-promo-btn"
                                  onClick={() => {
                                    openDrawer(
                                      drawersRestaurants.addPromoCodeModal
                                    );
                                    setUpdatePromoCodeDetails("");
                                  }}
                                >
                                  <FaPlus color="white" className="mr-2" /> Add
                                  Promo Code
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            )}
          </Tab.Container>
        </div>
      </div>

      <PromoCodeModal
        isActive={!!openDrawers[drawersRestaurants.addPromoCodeModal]}
        closePanel={() => closeDrawer(drawersRestaurants.addPromoCodeModal)}
        globalPromoCode={true}
      />
      <PromoStatusModal
        isActive={!!openDrawers[drawersRestaurants.promoStatusModal]}
        closePanel={() => closeDrawer(drawersRestaurants.promoStatusModal)}
        getPromoCodeData={getPromoCodeData}
      />
      {/* <ViewAllDrawerModal
        isActive={!!openDrawers[drawersRestaurants.viewAll]}
        closePanel={() => closeDrawer(drawersRestaurants.viewAll)}
      /> */}
    </PromoCodesPageContainer>
  );
}
