import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { drawersStaff } from "static/staticData";
import DrawerRemoveStaffConfirm from "./DrawerRemoveStaffConfirm";
import { DrawerRemoveStaffContainer } from "./style";

export default function DrawerRemoveStaff({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawers, closeDrawer, openDrawer, postStaff, staffRemoveInsideEditOption, setStaffRemoveInsideEditOption } = useContext(
    RestaurantsContext
  );

  return (
    <DrawerRemoveStaffContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left main-title">
          Remove Staff
        </h2>
        <p className="text-white text-left text-font">
          Are you sure you want to remove{" "}
          <span className="text-bold">{`${postStaff?.first_name} ${postStaff?.last_name}`}</span>
          ?
        </p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold move-item-btn"
            // onClick={() => openDrawer(drawersStaff.staffRemoveConfirm)}
            onClick={() => {
              setStaffRemoveInsideEditOption({
                ...staffRemoveInsideEditOption,
                staffRemoveConfirm: true,
              });
            }}
          >
            Remove
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold"
            // onClick={closePanel}
            onClick={() => {
              setStaffRemoveInsideEditOption({
                ...staffRemoveInsideEditOption,
                staffRemove: false,
              });
            }}
          >
            Keep
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerRemoveStaffConfirm
        // isActive={!!openDrawers[drawersStaff.staffRemoveConfirm]}
        // closePanel={() => closeDrawer(drawersStaff.staffRemoveConfirm)}
        isActive={staffRemoveInsideEditOption.staffRemoveConfirm}
        closePanel={() => {
          setStaffRemoveInsideEditOption({
            ...staffRemoveInsideEditOption,
            staffRemove: false,
            staffRemoveConfirm: false,
          });
        }}
      />
    </DrawerRemoveStaffContainer>
  );
}
