import React from "react";
import { ConfirmDeleteSubOrderModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const ConfirmSubOrderDeleteModal = ({
  isActive = false,
  closePanel = () => {},
  fetchOrderDetail = () => {},
}) => {
  return (
    <ConfirmDeleteSubOrderModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"38%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash" />
          </div>
          <div className="delete-suborder-title">Sub-Order Deleted</div>
          <div className="delete-suborder-meta">
            The pending items were removed from the system and the customer’s
            bill.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              onClick={() => {
                closePanel();
                fetchOrderDetail();
              }}
              type="red"
            >
              <b>Okay</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </ConfirmDeleteSubOrderModalContainer>
  );
};

export default ConfirmSubOrderDeleteModal;
