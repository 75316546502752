import React from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { MyPaginatorContainer } from "./style";

export default function MyPaginator({
  initialPage,
  totalCount = 0,
  limitShow = 0,
  onPageChange = () => {},
}) {
  return (
    <MyPaginatorContainer>
      <ReactPaginate
        initialPage={initialPage}
        pageCount={totalCount ? totalCount/limitShow : 1}
        previousLabel={<FaCaretLeft size={22} />}
        nextLabel={<FaCaretRight size={22} />}
        onPageChange={onPageChange}
      />
    </MyPaginatorContainer>
  );
}
