import React, { useContext } from "react";
import { DeleteSubOrderModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";

const DeleteEntireOrderModal = ({
  isActive = false,
  closePanel = () => {},
  onDeleteEntireOrder = () => {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();
  return (
    <DeleteSubOrderModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"31%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <div className="delete-suborder-modal">
          <div className="delete-icon">
            <i className="fas fa-trash-alt" />
          </div>
          <div className="delete-suborder-title">Delete Entire Order</div>
          <div className="delete-suborder-meta">
            All items will be removed from the system and the customer’s bill.
          </div>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              onClick={() => {
                onDeleteEntireOrder();
              }}
            >
              <b>Delete</b>
            </MyDrawerButton>
            <MyDrawerButton onClick={closePanel} type="gray" className="mt-4">
              <b>Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DeleteSubOrderModalContainer>
  );
};

export default DeleteEntireOrderModal;
