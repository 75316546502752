import React from "react";
import PropTypes from "prop-types";
import { FoodChecksContainer } from "./style";
import { mocks } from "static/staticData";

export function FoodChecks({ checks = [] }) {
  if (!checks?.length) {
    return null;
  }
  return (
    <FoodChecksContainer>
      {mocks.dietaryTagsMenus
        .filter((tag) => checks.includes(tag.value))
        .map(({ icon, name }, index) => (
          <div key={name + index} className="check-item">
            {icon}
            <div className="check-item-name">
              <div className="message-wrapper">
                {icon}
                <p className="name-title">{name}</p>
              </div>
            </div>
          </div>
        ))}
    </FoodChecksContainer>
  );
}

FoodChecks.propTypes = {
  checks: PropTypes.array,
};
