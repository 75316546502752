import { DrawerContainer } from "components/styledStyle";
import React, { useState } from "react";
import { DrawerItemContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import DrawerNutrition from "./Nutrition";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerFoodItemReviews from "./DrawerFoodItemReviews";
import { mocks } from "static/staticData";

export default function DrawerItem({
  isActive = false,
  closePanel = () => {},
  itemDetails,
}) {
  const [nutritionsData, setNutritionsData] = useState([]);
  const [openNeutritionsDrawer, setOpenNeutritionsDrawer] = useState(false);
  const [openFoodReviews, setOpenFoodReviews] = useState(false);

  function onClickHandleNutrition() {
    setOpenNeutritionsDrawer(true);
    customAxios
      .get(`/restaurant/${itemDetails?.restaurant_id}?get_detail=true`)
      .then((res) => {
        res &&
          res.data &&
          res.data.foods.map((_items) => {
            if (_items?.id === itemDetails?.id) {
              setNutritionsData(
                _items?.nutrition && typeof _items?.nutrition === "string"
                  ? JSON.parse(_items?.nutrition)
                  : _items?.nutrition
              );
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const dietaryTagIconRenderer = (tagId) => {
    switch (tagId) {
      case 0:
        return <i className="fas fa-leaf-heart" />;
      case 1:
        return <i className="fas fa-leaf" />;
      case 2:
        return <i className="fas fa-wheat" />;
      case 3:
        return <i className="fas fa-cow" />;
      case 4:
        return <i className="fas fa-candy-cane" />;
    }
  };

  return (
    <DrawerItemContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="items-wrapper">
          {/* background blur img */}
          <div className="top-item-image">
            <div onClick={closePanel}>
              <i className="fas fa-times"></i>
            </div>
            <img
              src={itemDetails?.image ? itemDetails?.image : defaultFood}
              alt=""
              className="w-100"
            />
          </div>

          <div className="overlay-drawer">
            {/* front-img */}
            <div className="inner-image">
              <img
                src={itemDetails?.image ? itemDetails?.image : defaultFood}
                alt=""
                className="w-100"
              />
            </div>

            <div className="bottom-space">
              {/* item-name */}
              <div className="item-name">{itemDetails?.name}</div>

              {/* description */}
              <div className="item-description">{itemDetails?.description}</div>

              {/* dietary */}
              <div className="food-tags">
                {itemDetails?.dietary ? (
                  itemDetails?.dietary.split(",")?.map((_tagId, _i) => (
                    <div
                      key={_i}
                      className="food-tags-item  badge badge-secondary"
                    >
                      {dietaryTagIconRenderer(_tagId)} &nbsp;
                      {mocks.dietaryTags[_tagId].title}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>

              {/* nutritions */}
              <div className="nutritions">
                <div className="inner-nutrition calories-wrapper">
                  <div className="numbers">
                    {itemDetails?.calories === null ? 0 : itemDetails?.calories}
                  </div>
                  <div className="nutritions-name">calories</div>
                </div>
                <div className="inner-nutrition protein-wrapper">
                  <div className="numbers">
                    {itemDetails?.protein === null ? 0 : itemDetails?.protein}
                  </div>
                  <div className="nutritions-name">protein</div>
                </div>
                <div className="inner-nutrition carbs-wrapper">
                  <div className="numbers">
                    {itemDetails?.carbs === null ? 0 : itemDetails?.carbs}
                  </div>
                  <div className="nutritions-name">carbs</div>
                </div>
              </div>

              {/* nutritions-info */}
              <div className="nutrition-title">
                <div>
                  <i className="far fa-info-circle" />
                </div>
                <div
                  className="nutrition-text"
                  onClick={onClickHandleNutrition}
                >
                  Nutrition
                </div>
              </div>

              {/* review */}
              <div
                className="rating-review-container cursor"
                onClick={() => setOpenFoodReviews(true)}
              >
                <div className="rating-review-flex">
                  <div>
                    <i className="fas fa-star mr-1" />
                    <span className="text-bold">
                      {itemDetails?.avg_rating ? itemDetails?.avg_rating : "0"}
                    </span>
                  </div>
                  <div>
                    <span className="mr-3">
                      {itemDetails?.reviews_count
                        ? itemDetails?.reviews_count
                        : 0}{" "}
                      reviews
                    </span>{" "}
                    <i className="fas fa-chevron-right" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DrawerContainer>
      <DrawerNutrition
        isActive={openNeutritionsDrawer}
        closePanel={() => setOpenNeutritionsDrawer(false)}
        nutritionsData={nutritionsData}
      />

      <DrawerFoodItemReviews
        isActive={openFoodReviews}
        closePanel={() => setOpenFoodReviews(false)}
        foodDetails={itemDetails}
      />
    </DrawerItemContainer>
  );
}
