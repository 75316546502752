import styled from "@emotion/styled";
import { colors } from "theme";

export const RestaurantProfileTablesContainer = styled.div`
  width: 100%;
  /* height: 800px; */
  border-radius: 8px;
  background: ${colors.gray4};
  .tables-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 34px;

    .backlink-to-rest {
      color: ${colors.white};
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.41px;
    }
    .arrow-icon-container {
      i {
        color: white;
      }
    }
    .current-restaurant {
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.41px;
    }

    .tables-tabs {
      display: flex;
      height: 40px;
      width: 200px;
      background-color: ${colors.white5};
      border-radius: 20px;
      margin-top: 25px;
      margin-bottom: 20px;
      .tab-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.selected {
          background-color: ${colors.gray};
          border-radius: 20px;
        }
      }
    }
    .table-btns {
      button {
        width: 170px;
      }
    }
  }

  .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 65vh;
    img {
      width: auto;
      height: 160px;
      object-fit: contain;
      margin-bottom: 30px;
      width: 528px;
      height: 300px;
      object-fit: contain;
    }
    .empty-screen {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin-bottom: 14px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      margin-bottom: 24px;
      opacity: 0.6;
    }
    .add-promo-btn {
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      background: #ed2626;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 340px;
      height: 50px;
      padding: 15px 16px 13px;
    }
  }
`;
