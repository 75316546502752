import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useState, useContext, useEffect } from "react";
import { DrawerRemoveItemContainer } from "./style";
import { OrdersContext } from "context/OrdersContext";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function DrawerRemoveAddedItem({
  isActive = false,
  closePanel = () => {},
}) {
  const { addItemsToOrder, setAddItemsToOrder } = useContext(OrdersContext);
  const [uniqueId, setUniqueId] = useState("");
  const [statusActiveNotification, setStatusActiveNotification] = useState();

  useEffect(() => {
    if (statusActiveNotification) {
      setTimeout(() => setStatusActiveNotification(null), 2 * 1000);
    }
  }, [statusActiveNotification]);

  const getUniqueId = (_item) => {
    setUniqueId(_item?.unique_id);
  };

  const removeItem = () => {
    const filterRemoveData = addItemsToOrder.filter(
      (_el) => _el?.unique_id !== uniqueId
    );
    setAddItemsToOrder(filterRemoveData);
    setStatusActiveNotification({
      message: "Item Removed.",
      type: "cuisine-inactive",
      table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">Table</span>`,
    });
    closePanel();
    setUniqueId("");
  };

  return (
    <DrawerRemoveItemContainer
      isActive={isActive}
      closePanel={closePanel}
      top="20%"
    >
      {statusActiveNotification && (
        <MyMessageBoxItemNew
          message={
            statusActiveNotification?.message
              ? statusActiveNotification?.message
              : ""
          }
          table={
            statusActiveNotification?.table
              ? statusActiveNotification?.table
              : ""
          }
          type={
            statusActiveNotification?.type ? statusActiveNotification?.type : ""
          }
          onClick={() => setStatusActiveNotification(null)}
        />
      )}
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <h3 className="title">Select Item to Remove</h3>

          {/* card data */}
          {addItemsToOrder &&
            addItemsToOrder.map((_item, index) => {
              let cal = _item?.quantity * _item?.price;
              return (
                <React.Fragment key={index}>
                  <div
                    className="item-cards mb-5 cursor-pointer"
                    onClick={() => {
                      getUniqueId(_item);
                    }}
                    style={
                      uniqueId === _item?.unique_id
                        ? { border: "2px solid #ED2626" }
                        : {}
                    }
                  >
                    <div className="item-name">
                      <div className="name-title">{_item?.title}</div>
                      <div className="price">${cal}</div>
                    </div>
                    {/* <div className="size">
                    – <span>Size:</span> XL
                  </div> */}
                    {/* <div className="instructions">
                    – <span>Special Instructions:</span> No onion, please
                  </div> */}
                  </div>
                </React.Fragment>
              );
            })}

          <div className="bottom-btns">
            <MyDrawerButton
              type={uniqueId !== "" ? "red" : "gray"}
              disabled={uniqueId !== "" ? false : true}
              className="mt-3 text-bold"
              onClick={() => removeItem()}
            >
              Remove Item
            </MyDrawerButton>
            <div className="cancel-btn" onClick={closePanel}>
              Cancel
            </div>
            {/* onClick={() => closeDrawer(drawersMenu.availabilities)} */}
          </div>
        </div>
      </DrawerContainer>
    </DrawerRemoveItemContainer>
  );
}
