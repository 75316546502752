import { Avatar, Divider } from "@material-ui/core";
import MyRating from "components/Elements/MyRating";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { FaEllipsisH, FaTrash } from "react-icons/fa";
import { drawersOrder } from "static/staticData";
import { StyledIconWrapper, StyledPopupContainer } from "theme/styles";
import { DrawerReviewServerContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import { FaTimes } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { mocks } from "static/staticData";

export default function DrawerAddItem({
  isActive = false,
  closePanel = () => {},
  foodDetails = {},
  addOrderButton = () => {},
  orderDetailData = {},
}) {
  const { openDrawer } = useContext(OrdersContext);
  const [foodPrice, setFoodPrice] = useState(0);
  // console.log("foodDetails", foodDetails);

  useEffect(() => {
    if (foodDetails) {
      setFoodPrice(foodDetails?.price);
    }
  }, [foodDetails]);

  const onClickAddition = () => {
    let add = foodPrice + foodDetails?.price;
    setFoodPrice(add);
  };

  const onClickMinus = () => {
    let minus = foodPrice - foodDetails?.price;
    if (foodDetails?.price === foodPrice) {
    } else {
      setFoodPrice(minus);
    }
  };

  const dietaryTagIconRenderer = (tagId) => {
    switch (tagId) {
      case 0:
        return <i className="fas fa-leaf-heart" />;
      case 1:
        return <i className="fas fa-leaf" />;
      case 2:
        return <i className="fas fa-wheat" />;
      case 3:
        return <i className="fas fa-cow" />;
      case 4:
        return <i className="fas fa-candy-cane" />;
    }
  };

  return (
    <DrawerReviewServerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <div className="header-bg">
          <FaTimes
            size={20}
            style={{ zIndex: "9999999" }}
            className="cursor-pointer close-drawer-icon"
            onClick={closePanel}
          />
          <Image
            src={foodDetails?.photo?.file || defaultFood}
            className="bg-img"
          />
          <div className="drawer-header-content">
            <img src={foodDetails?.photo?.file || defaultFood} />
          </div>
        </div>
        <div className="reviews-body">
          <div className="food-name">{foodDetails?.title}</div>
          <h5
            className="food-description opacity6 text-center"
            style={{ marginTop: "14px" }}
          >
            {foodDetails?.description}
          </h5>
          <div className="food-tags">
            {foodDetails?.dietary ? (
              foodDetails?.dietary?.map((_tagId, _i) => (
                <div key={_i} className="food-tags-item">
                  {dietaryTagIconRenderer(_tagId)} &nbsp;
                  {mocks.dietaryTags[_tagId].title}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div className="diet-info">
            <div className="diet-info-item diet-info-border-right">
              <div className="diet-count">{foodDetails?.calories || 0}</div>
              <div className="diet-type">Calories</div>
            </div>
            <div className="diet-info-item diet-info-border-right">
              <div className="diet-count">{foodDetails?.protein || 0}</div>
              <div className="diet-type">Protein</div>
            </div>
            <div className="diet-info-item">
              <div className="diet-count">{foodDetails?.carbs || 0}</div>
              <div className="diet-type">Carbs</div>
            </div>
          </div>
          <div
            className="neutrition-div cursor-pointer"
            onClick={() => openDrawer(drawersOrder.foodNeutritions)}
          >
            <i className="far fa-info-circle mr-2" />
            Nutrition
          </div>
          <div
            className="rating-customization-card cursor-pointer"
            style={{ marginTop: "52px" }}
            onClick={() => openDrawer(drawersOrder.foodItemReviews)}
          >
            <div className="card-div-one">
              <i className="fas fa-star" /> {foodDetails?.rating || 0}
            </div>
            <div className="card-div-two d-flex align-items-center">
              {foodDetails?.reviews_count || 0}reviews
              <i className="fas fa-chevron-right" />
            </div>
          </div>
          <div
            className="rating-customization-card cursor-pointer"
            style={{ marginTop: "15px" }}
            onClick={() => openDrawer(drawersOrder.foodCustomization)}
          >
            <div className="card-div-one">Customize</div>
            <div className="card-div-two d-flex align-items-center">
              {foodDetails?.customizations || 0}options
              <i className="fas fa-chevron-right" />
            </div>
          </div>
          <div className="increment-decrement-div text-center d-flex align-items-center justify-content-center">
            <div
              style={{ marginRight: "35px" }}
              className="click-btn-div"
              onClick={() => {
                onClickMinus(foodPrice);
              }}
            >
              <i className="fas fa-minus" />
            </div>
            <div style={{ marginRight: "35px" }} className="item-count">
              {foodPrice / foodDetails?.price}
            </div>
            <div
              className="click-btn-div"
              onClick={() => {
                onClickAddition(foodPrice);
              }}
            >
              <i className="fas fa-plus" />
            </div>
          </div>
          <div
            className="bottom-btns add-to-order-div"
            style={{ marginTop: "44px" }}
          >
            <MyDrawerButton
              type="red"
              className="mt-auto"
              onClick={() => {
                let obj = {
                  ...foodDetails,
                  quantity: foodPrice / foodDetails?.price,
                };
                addOrderButton(obj);
              }}
            >
              Add to Order{" "}
              <span className="total-food-price">${foodPrice}</span>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerReviewServerContainer>
  );
}
