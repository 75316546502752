import React, { useContext, useState } from "react";
import { RefundConfirmationModalContainer, Label } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";
const RefundConfirmationModal = ({
  isActive = false,
  closePanel = () => {},
  partialRefundData = "",
  applyPartialRefundHandler = () => {},
}) => {
  const [cardsList, setCardsList] = useState([
    {
      brand: "Visa",
      last4: "1234",
      name: "demo name",
      price: 10,
    },
  ]);

  return (
    <RefundConfirmationModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="mark-as-ready-modal">
          <div className="cash-icon">
            <i className="fas fa-money-bill-wave" />
          </div>
          <div className="delete-suborder-title">Refund Confirmation</div>
          <Label size="15px" weight="500" textAlign="center">
            Are you sure you want to issue a refund <br /> of{" "}
            <b>
              $
              {(partialRefundData !== "" &&
                Number(partialRefundData?.value)?.toFixed(2)) ||
                0.0}
            </b>{" "}
            to the customer?
          </Label>
          <div className="cards-list-info-div d-flex flex-column align-items-start">
            {cardsList?.map((el, i) => {
              return (
                <div key={i} className="card-info d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column align-items-start">
                    <Label size="15px" weight="bold">
                      {el?.brand} {el?.last4}
                    </Label>
                    <Label size="15px" weight="normal" opacity="0.6">
                      {el?.name}
                    </Label>
                  </div>
                  <Label size="15px" weight="bold" color="#1fc085">
                    ${Number(partialRefundData?.value)?.toFixed(2)}
                  </Label>
                </div>
              );
            })}
          </div>
          <Label
            size="14px"
            weight="500"
            opacity="0.6"
            marginBottom="12px"
            textAlign="center"
          >
            Customer receives refund within 3-5 business days.
          </Label>
          <Label
            size="14px"
            weight="500"
            opacity="0.6"
            marginBottom="12px"
            textAlign="center"
          >
            Money is returned to the original credit/debit card(s) used on the
            order and split between those who paid.
          </Label>
          <Label
            size="14px"
            weight="500"
            opacity="0.6"
            marginBottom="12px"
            textAlign="center"
          >
            Restaurant is responsible for issuing cash refunds.
          </Label>
          <Label
            size="14px"
            weight="500"
            opacity="0.6"
            marginBottom="12px"
            textAlign="center"
          >
            Feasttt service fees are non-refundable.
          </Label>
          <div className="delete-suborder-actions">
            <MyDrawerButton
              type="red"
              onClick={() => {
                applyPartialRefundHandler(partialRefundData);
              }}
            >
              <b>Issue Refund</b>
            </MyDrawerButton>
            <div className="cancel-btn cursor-pointer" onClick={closePanel}>
              Cancel
            </div>
          </div>
        </div>
      </DrawerContainer>
    </RefundConfirmationModalContainer>
  );
};

export default RefundConfirmationModal;
