import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import React, { memo, useContext, useState, useEffect, useRef } from "react";
import { colors } from "theme";
import { DrawerDeleteCustomerOTPContainer, StyledInput } from "./style";
import { drawersOverall, drawersCustomers } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams, useHistory } from "react-router-dom";
import DrawerCustomerDeleted from "../DrawerCustomerDeleted";
import DrawerDeleteCustomerFailed from "../DrawerDeleteCustomerFailed";
import axios from "axios";
import { getJwt } from "services/jwt";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

const DrawerDeleteCustomerOTP = ({
  isActive = false,
  closePanel = () => {},
}) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    deleteCustomerModals,
    setDeleteCustomerModals,
    deleteCustomerInput,
    setDeleteCustomerInput,
    closeDrawer,
    editCustomerData,
  } = useContext(CustomersContext);

  const firstInputRef = useRef();
  const secondInputRef = useRef();
  const thirdInputRef = useRef();
  const fourthInputRef = useRef();
  const fifthInputRef = useRef();
  const sixInputRef = useRef();

  const [deleteCustomerResponse, setDeleteCustomerResponse] = useState("");

  const [deleteCustomerNotification, setDeleteCustomerNotification] = useState()
  

  useEffect(() => {
    if (isActive) {
      firstInputRef.current.focus();
      setDeleteCustomerInput({
        first: "",
        second: "",
        third: "",
        fourth: "",
        five: "",
        six: "",
      });
    }
  }, [isActive]);

  useEffect(() => {
    if (deleteCustomerNotification) {
      setTimeout(() => setDeleteCustomerNotification(null), 2 * 1000);
    }
  }, [deleteCustomerNotification]);

  const isDisabled = () => {
    let string = `${deleteCustomerInput.first}${deleteCustomerInput.second}${deleteCustomerInput.third}${deleteCustomerInput.fourth}${deleteCustomerInput.five}${deleteCustomerInput.six}`;
    if (string === "DELETE") {
      return false;
    } else {
      return true;
    }
  };

  const deleteCustomerHandler = () => {
    axios
      .delete(`https://nodeapi.feasttt.com/admin/v1/customer/${id}`, {
        headers: { Authorization: `Bearer ${getJwt()}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            deleteConfirmed: true,
          });
          setDeleteCustomerNotification({
            message: "Customer Deleted.",
            type: "remove",
            table: `${editCustomerData?.first_name} ${editCustomerData?.last_name}.`,
          });
        } else {
        }
      })
      .catch((err) => {
        if (err.response.data) {
          setDeleteCustomerResponse(err.response.data);
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            customerHaveOrders: true,
          });
        }
      });
  };

  const onDone = () => {
    setDeleteCustomerModals({
      ...deleteCustomerModals,
      deleteCustomer: false,
      deleteConfirmation: false,
      confirmationOTP: false,
      deleteConfirmed: false,
    });
    closeDrawer(drawersCustomers.editCustomer);
    history.push(`/customers`);
  };

  const closeCustomerDeleteDrawers = () => {
    setDeleteCustomerModals({
      ...deleteCustomerModals,
      deleteCustomer: false,
      deleteConfirmation: false,
      confirmationOTP: false,
      deleteConfirmed: false,
      customerHaveOrders: false,
    });
    setDeleteCustomerResponse("");
  };

  return (
    <DrawerDeleteCustomerOTPContainer
      isActive={isActive}
      closePanel={closePanel}
    >

      {deleteCustomerNotification && (
        <MyMessageBoxItemNew
          message={
            deleteCustomerNotification?.message
              ? deleteCustomerNotification?.message
              : ""
          }
          table={
            deleteCustomerNotification?.table
              ? deleteCustomerNotification?.table
              : ""
          }
          type={
            deleteCustomerNotification?.type ? deleteCustomerNotification?.type : ""
          }
          onClick={() => setDeleteCustomerNotification(null)}
        />
      )}

      <DrawerContainer>
        <div>
          <div className="top-title">
            <div></div>
            <div className="title">Delete Customer</div>
            <div className="cursor-pointer"></div>
          </div>
          <div className="delete-customer-otp">
            Enter DELETE to confirm deleting Customer
          </div>
          <div className="customer-delete-input-box">
            <StyledInput
              value={deleteCustomerInput.first}
              onChange={(e) => {
                if (e.target.value.length <= 1) {
                  setDeleteCustomerInput({
                    ...deleteCustomerInput,
                    first: e.target.value.toUpperCase(),
                  });
                  if (e.target.value.toUpperCase() === "D") {
                    secondInputRef.current.focus();
                  }
                }
              }}
              isValid={
                deleteCustomerInput.first !== ""
                  ? deleteCustomerInput.first === "D"
                  : true
              }
              ref={firstInputRef}
            />
            <StyledInput
              value={deleteCustomerInput.second}
              onChange={(e) => {
                if (e.target.value.length <= 1) {
                  setDeleteCustomerInput({
                    ...deleteCustomerInput,
                    second: e.target.value.toUpperCase(),
                  });
                  if (e.target.value.toUpperCase() === "E") {
                    thirdInputRef.current.focus();
                  }
                }
              }}
              isValid={
                deleteCustomerInput.second !== ""
                  ? deleteCustomerInput.second === "E"
                  : true
              }
              ref={secondInputRef}
            />
            <StyledInput
              value={deleteCustomerInput.third}
              onChange={(e) => {
                if (e.target.value.length <= 1) {
                  setDeleteCustomerInput({
                    ...deleteCustomerInput,
                    third: e.target.value.toUpperCase(),
                  });
                  if (e.target.value.toUpperCase() === "L") {
                    fourthInputRef.current.focus();
                  }
                }
              }}
              isValid={
                deleteCustomerInput.third !== ""
                  ? deleteCustomerInput.third === "L"
                  : true
              }
              ref={thirdInputRef}
            />
            <StyledInput
              value={deleteCustomerInput.fourth}
              onChange={(e) => {
                if (e.target.value.length <= 1) {
                  setDeleteCustomerInput({
                    ...deleteCustomerInput,
                    fourth: e.target.value.toUpperCase(),
                  });
                  if (e.target.value.toUpperCase() === "E") {
                    fifthInputRef.current.focus();
                  }
                }
              }}
              isValid={
                deleteCustomerInput.fourth !== ""
                  ? deleteCustomerInput.fourth === "E"
                  : true
              }
              ref={fourthInputRef}
            />
            <StyledInput
              value={deleteCustomerInput.five}
              onChange={(e) => {
                if (e.target.value.length <= 1) {
                  setDeleteCustomerInput({
                    ...deleteCustomerInput,
                    five: e.target.value.toUpperCase(),
                  });
                  if (e.target.value.toUpperCase() === "T") {
                    sixInputRef.current.focus();
                  }
                }
              }}
              isValid={
                deleteCustomerInput.five !== ""
                  ? deleteCustomerInput.five === "T"
                  : true
              }
              ref={fifthInputRef}
            />
            <StyledInput
              value={deleteCustomerInput.six}
              onChange={(e) => {
                if (e.target.value.length <= 1) {
                  setDeleteCustomerInput({
                    ...deleteCustomerInput,
                    six: e.target.value.toUpperCase(),
                  });
                }
              }}
              isValid={
                deleteCustomerInput.six !== ""
                  ? deleteCustomerInput.six === "E"
                  : true
              }
              ref={sixInputRef}
            />
          </div>
        </div>
        <div className="bottom-btns" style={{ marginTop: "30px" }}>
          <MyDrawerButton
            type={isDisabled() ? "gray" : "red"}
            className="mt-auto text-bold"
            onClick={() => {
              deleteCustomerHandler();
            }}
            disabled={isDisabled()}
          >
            Delete Customer
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="text-bold"
            onClick={closePanel}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerCustomerDeleted
        isActive={deleteCustomerModals.deleteConfirmed}
        // isActive={true}
        closePanel={() => {
          setDeleteCustomerModals({
            ...deleteCustomerModals,
            deleteCustomer: false,
            deleteConfirmation: false,
            confirmationOTP: false,
            deleteConfirmed: false,
          });
        }}
        onDone={onDone}
      />
      <DrawerDeleteCustomerFailed
        isActive={deleteCustomerModals.customerHaveOrders}
        closePanel={() => {
          closeCustomerDeleteDrawers();
        }}
        deleteCustomerResponse={deleteCustomerResponse}
        onDone={() => {
          closeCustomerDeleteDrawers();
        }}
      />
    </DrawerDeleteCustomerOTPContainer>
  );
};

export default memo(DrawerDeleteCustomerOTP);
