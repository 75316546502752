import React from "react";
import PropTypes from "prop-types";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { SortableListContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export default function MyDragger({
  items = [],
  containerStyle = {},
  renderItem = () => {},
  onSortEnd = () => {},
  addNewButton = false,
  onClick = () => {},
}) {
  const SortableItem = sortableElement(({ item, order }) => {
    return renderItem(item, order);
  });

  const SortableList = sortableContainer(({ children }) => {
    return (
      <SortableListContainer id="drag-menu-options">
        {children}
      </SortableListContainer>
    );
  });

  return (
    <div style={containerStyle}>
      <SortableList
        onSortEnd={onSortEnd}
        helperClass="row-dragging"
        helperContainer={() => document.getElementById("drag-menu-options")}
        distance={20}
      >
        {items.length > 0 &&
          items.map((value, _i) => {
            return (
              <SortableItem
                key={`item-${_i}`}
                index={_i}
                order={_i}
                item={value}
              />
            );
          })}
        {addNewButton && (
          <MyDrawerButton
            type="gray"
            className="mt-auto new-btn"
            onClick={onClick}
          >
            Add New Floor
          </MyDrawerButton>
        )}
      </SortableList>
    </div>
  );
}

MyDragger.propTypes = {
  onSortEnd: PropTypes.func,
  renderItem: PropTypes.func,
  items: PropTypes.array,
  containerStyle: PropTypes.object,
  addNewButton: PropTypes.bool,
  onClick: PropTypes.func,
};
