import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaBars, FaPen, FaTrash } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { colors } from "theme";
import { DrawerAddCustomizationsOptionContainer } from "./style";
import { Switch } from "@material-ui/core";

export default function DrawerAddCustomizationsOption({
  menus = [],
  isActive = false,
  closePanel = () => {},
  selectedOptionIndex = null,
  setSelectedOptionIndex,
}) {
  const { openDrawer, addOption, setAddOption } = useContext(
    RestaurantsContext
  );

  const [postOptionName, setPostOptionName] = useState();
  const [postOptionPrice, setPostOptionPrice] = useState(0);
  const [freeOptionToggle, setFreeOptionToggle] = useState(false);

  useEffect(() => {
    if (selectedOptionIndex !== "") {
      const selectedOption = addOption[selectedOptionIndex];
      setPostOptionName(selectedOption?.name);
      if (selectedOption?.price === 0) {
        setFreeOptionToggle(true);
        setPostOptionPrice();
      } else {
        setFreeOptionToggle(false);
        setPostOptionPrice(selectedOption?.price);
      }
    } else {
      setPostOptionName();
      setPostOptionPrice(0);
      setFreeOptionToggle(false);
    }
  }, [selectedOptionIndex]);

  const freeOptionHandleChange = () => {
    setFreeOptionToggle(!freeOptionToggle);
  };

  function onClickAddOption() {
    const optionsArr = addOption ? [...addOption] : [];
    let optionData = {
      name: postOptionName,
      price: postOptionPrice !== "" ? postOptionPrice : 0,
    };
    optionsArr.push(optionData);
    setAddOption(optionsArr);

    setPostOptionName("");
    setPostOptionPrice("");
    closePanel();
  }

  const onClickEditOption = () => {
    const optionsArr = addOption ? [...addOption] : [];
    let optionData = {
      name: postOptionName,
      price: postOptionPrice !== "" ? postOptionPrice : 0,
    };
    optionsArr[selectedOptionIndex] = optionData;
    setAddOption(optionsArr);
    setSelectedOptionIndex(null);
    setPostOptionName("");
    setPostOptionPrice("");
    closePanel();
  };

  const deleteOptionHandler = () => {
    const newOptions = [
      ...addOption.filter((option, i) => i !== selectedOptionIndex),
    ];
    setAddOption(newOptions);
    setPostOptionName();
    setPostOptionPrice(0);
    setFreeOptionToggle(false);
    closePanel();
  };

  return (
    <DrawerAddCustomizationsOptionContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
      onClickRightIcon={() => openDrawer(drawersMenu.deleteMenu)}
    >
      <DrawerContainer>
        {/* <h2 className="drawer-title text-white text-center">Option</h2> */}
        <div className="top-title">
          <div className="title">
            {selectedOptionIndex !== "" ? "Option" : "Option"}{" "}
          </div>
          <div>
            {selectedOptionIndex !== "" ? (
              <FaTrash
                size={20}
                color={"white"}
                className="cursor-pointer"
                onClick={() => deleteOptionHandler(selectedOptionIndex)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="add-cutomization-option-input-area">
          <div>
            <div>Name</div>
            <input
              type="text"
              placeholder="Option Name"
              className="option-input"
              value={postOptionName || ""}
              onChange={(e) => setPostOptionName(e.target.value)}
            />
            <div className="input-bottom-line" />
          </div>

          <div className="selection-required">
            {/* <div className="input-bottom-line" /> */}
            <div className="required">
              <div>Free </div>
              {/* <div>
                <Switch
                  checked={freeOptionToggle}
                  onChange={freeOptionHandleChange}
                  // inputProps={{ "aria-label": "controlled" }}
                />
              </div> */}
              <label className="switch-refill">
                <input
                  type="checkbox"
                  checked={freeOptionToggle}
                  onChange={() => freeOptionHandleChange()}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="input-bottom-line" />
          </div>

          {freeOptionToggle === true ? null : (
            <div className="mb-4">
              <div className="mt-3">Price</div>
              <input
                type="text"
                placeholder="$ 0.00"
                className="option-input"
                value={postOptionPrice || ""}
                onChange={(e) => setPostOptionPrice(Number(e.target.value))}
              />
              <div className="input-bottom-line" />
            </div>
          )}
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            onClick={() => {
              if (selectedOptionIndex !== "") {
                onClickEditOption();
              } else {
                onClickAddOption();
              }
            }}
            disabled={postOptionName === "" || postOptionName === undefined}
          >
            <b>{selectedOptionIndex !== "" ? "Edit Option" : "Add Option"}</b>
          </MyDrawerButton>
          <div
            className="cancel-btn text-center text-bold cursor-pointer"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </div>
        </div>
      </DrawerContainer>
    </DrawerAddCustomizationsOptionContainer>
  );
}
