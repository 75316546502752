import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerIfNoCategoriesDeleteContainer = styled(MyDrawerCenterModal)`
  .drawer-title {
    font-size: 22px;
    font-weight: 700;
  }
  
  .item-content {
    font-size: 14px;
    font-weight: 500;
  }

  .keep-btn {
    margin-top: 10px;
  }

  .delete-btn {
    margin-top: 20px;
  }

  .cancel-btn {
    color: #ed2626 !important;
    font-weight: 600;
    margin-top: 20px;
  }
`;
