import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyDateRange from "components/Elements/MyDateRange";
import { DrawerContainer } from "components/styledStyle";
import { CustomersContext } from "context/CustomersContext";
import moment from "moment";
import React, { useContext, useState } from "react";
import { colors } from "theme";
import { JoinedDateRangeContainer, DrawerJoinedDateContainer } from "./style";

export default function DrawerJoinedDate({
  isActive = false,
  closePanel = () => {},
  onSelectDateRange = () => {},
}) {
  const {state, setState} = useContext(CustomersContext)
  const [jointedStartDate, setJoinedStartdate] = useState();
  const [joinedEndDate, setJoinedEndDate] = useState();
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  const onChangeDate = (date) => {
    if (date && date.endDate) {
      setJoinedStartdate(moment(date.startDate).format("YYYY-MM-DD"));
      setJoinedEndDate(moment(date.endDate).format("YYYY-MM-DD"));
    }
  };

  function handleOnClick() {
    onSelectDateRange({ startDate: jointedStartDate, endDate: joinedEndDate });
  }

  const onCancelDate = () => {
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    closePanel();
  };

  return (
    <DrawerJoinedDateContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        {/* <MyDateRange onChangeDate={onChangeDate} /> */}
        <JoinedDateRangeContainer
          editableDateInputs={true}
          showDateDisplay={false}
          onChange={(item) => {
            // console.log(JSON.stringify(item));
            setState([item.selection]);
            onChangeDate(item.selection);
          }}
          direction="horizontal"
          months={1}
          moveRangeOnFirstSelection={false}
          rangeColors={[colors.redColor, colors.green]}
          ranges={state}
        />

        <div className="bottom-btns">
          <MyDrawerButton
            className="text-bold"
            type="red"
            onClick={handleOnClick}
            // onClick={() => onSelectDateRange()}
          >
            Select
          </MyDrawerButton>
          <MyDrawerButton
            className="cancel-btn"
            type="transparent"
            onClick={() => onCancelDate()}
          >
            Cancel
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerJoinedDateContainer>
  );
}
