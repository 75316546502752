import React, { useContext } from "react";
import { AddModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { drawersMenu, drawersRestaurants } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";

const AddItem = ({
  isActive = false,
  closePanel = () => {},
  status,
  handleChooseExistingMenu = () => {},
  handleAddItem
}) => {
  const {
    openDrawer,
    setIsDeleteRestaurantModalOpen,
    restaurant,
    setPostFood,
    setCategoryId,
  } = useContext(RestaurantsContext);

  return (
    <AddModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
    >
      <DrawerContainer>
        <div className="add-item-wrapper">
          <div
            className="clickable-row"
            onClick={handleAddItem}
          >
            Create a New Item
          </div>
          <div
            className="clickable-row"
           onClick={handleChooseExistingMenu}
          >
            Choose an Existing Item
          </div>
          <MyDrawerButton
            className="action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </AddModalContainer>
  );
};

export default AddItem;
