import styled from "@emotion/styled";

export const DashboardStatsContainer = styled.div`
  padding-top: 19px;
  padding-bottom: 6px;
  border-radius: 8px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
  background-color: #1b1c1d;
  min-height: 316px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .period-filter-dashboard-stats {
    padding-left: 24px;
  }

  .dashboard-stats-core {
    width: 100%;
    margin-top: 41px;
    display: flex;
    flex-wrap: wrap;

    .dashboard-stat-item {
      width: 28%;
      margin-left: 40px;
      margin-bottom: 43px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .dashboard-stat-label {
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        letter-spacing: -0.38px;
      }
      .dashboard-stat-value {
        font-size: 30px;
        font-weight: bold;
        color: white;
        letter-spacing: -0.72px;
      }
    }
  }
`;
