import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import { drawersMenu } from "static/staticData";
import { DrawerFoodsContainer, DrawerChooseItemContainer } from "./style";
import { DefaultFood } from "assets/img";
import DrawerAddedItemsSuccess from "./DrawerAddedItemsSuccess";
import DrawerFailAddItemInExisting from "./DrawerFailAddItemInExisting";

export default function DrawerFoods({
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const {
    foods,
    selectedMenu,
    selectedCategory,
    fetchRestaurant,
    setSelectedMenu,
    setFoods,
    closeDrawer,
    chooseCloseDrawer,
    setChooseCloseDrawer,
  } = useContext(RestaurantsContext);

  const [selectedFoods, setPostFoods] = useState([]);

  const onChooseFood = (foodId) => {
    let tempFoods = selectedFoods ? [...selectedFoods] : [];
    if (tempFoods.includes(foodId)) {
      setPostFoods(tempFoods.filter((_tag) => _tag !== foodId));
    } else {
      tempFoods.push(foodId);
      setPostFoods(tempFoods);
    }
  };

  // const onDone = async () => {
  //   const result = await restaurantServices.addFoodsToCategory(
  //     id,
  //     selectedMenu.id,
  //     selectedCategory.id,
  //     {
  //       food_ids: selectedFoods,
  //     }
  //   );
  //   if (result.status === 200) {
  //     fetchRestaurant(id);
  //   }
  //   closePanel();
  // };

  function chooseExistingItemCopy() {
    // console.log(selectedCategory?.id)
    const categoryItem = [];
    categoryItem.push(selectedCategory?.id);
    const cloneDataConfig = {
      category: categoryItem,
      food_ids: selectedFoods,
    };
    customAxios
      .post(`/restaurant/${id}/food/clone`, cloneDataConfig)
      .then((res) => {
        if (res.status === 200) {
          getCategoryData();
          getRestaurantProfile();
          // closePanel()
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            // foods: false,
            // addType: false,
            existingItemAdded: true,
          });
        } else {
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            onFailAddExistingItem: true,
          });
        }
      })
      .catch((err) => {});
  }

  function getCategoryData() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
    }
  };

  return (
    <DrawerFoodsContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div></div>
          <div></div>
        </div>
        <div className="title">Choose an existing item</div>
        <div className="top-title-meta">You may select more than one.</div>
        <div className="drawer-types-wrapper mb-auto">
          {foods.map((_food, _i) => {
            return (
              <DrawerChooseItemContainer
                className={`drawer-choose--option cursor ${selectedFoods &&
                  selectedFoods.includes(_food.id) &&
                  "active"} mb-4`}
                onClick={() => onChooseFood(_food.id)}
              >
                <img
                  src={_food?.photo?.file || DefaultFood}
                  alt=""
                  style={{
                    width: "43px",
                    height: "30px",
                    borderRadius: "6px",
                  }}
                />
                <p>{_food?.title}</p>
                {_food?.subtitle && (
                  <p className="choose-subtitle">{_food?.subtitle}</p>
                )}
              </DrawerChooseItemContainer>
              // <DrawerChooseItem
              //   key={_i}
              //   title={_food.title}
              //   className="mb-4"
              //   active={selectedFoods && selectedFoods.includes(_food.id)}
              //   onChooseItem={() => onChooseFood(_food.id)}
              // />
            );
          })}
        </div>
        <div className="bottom-btns">
          {/* <MyDrawerButton type="gray" className="text-bold" onClick={onDone}>
            Done
          </MyDrawerButton> */}
          <div className="selected-count-text">
            Selected {selectedFoods?.length} &nbsp;
            {selectedFoods?.length === 1 ? "item" : "items"} .
          </div>
          <MyDrawerButton
            type="red"
            className="text-bold add-items-to-category-btn"
            onClick={chooseExistingItemCopy}
            disabled={selectedFoods?.length === 0}
          >
            Add items to category
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerAddedItemsSuccess
        isActive={chooseCloseDrawer.existingItemAdded}
        closePanel={() => {
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            foods: false,
            addType: false,
            existingItemAdded: false,
          });
          setPostFoods([]);
        }}
        selectedFoods={selectedFoods}
      />
      <DrawerFailAddItemInExisting
        isActive={chooseCloseDrawer.onFailAddExistingItem}
        // isActive={true}
        closePanel={() => {
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            foods: false,
            addType: false,
            existingItemAdded: false,
            onFailAddExistingItem: false,
          });
          setPostFoods([]);
        }}
        tryAgainClick={() => {
          setChooseCloseDrawer({
            ...chooseCloseDrawer,
            foods: true,
            addType: false,
            existingItemAdded: false,
            onFailAddExistingItem: false,
          });
        }}
      />
    </DrawerFoodsContainer>
  );
}
