import styled from "@emotion/styled";
import { colors } from "theme";

export const StaffCardContainer = styled.div`
  width: 100%;
  &:nth-of-type(even) {
    .staff-card {
      background-color: #18191a;
    }
  }
  .staff-card {
    background-color: ${colors.gray2};
    &.is-header {
      background-color: transparent;
    }
    padding: 10px 10px;
    /* margin: 8px 0; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-img {
      width: 70px;
      margin-right: 30px;
    }
    .card-edit {
      margin-right: 40px;
      width: 50px;
      .popup-wrapper {
        padding: 16px 33px;
        border-radius: 25px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
        border: solid 1px rgba(255, 255, 255, 0.08);
        background-color: #151617;
      }
    }
    .card-col {
      flex: 1;
      text-align: left;
      .rating-wrapper {
        .rating-star {
          padding: 7px 6px 6px;
          border-radius: 15.5px;
          background-color: #ed2626;
        }
      }
      .phone-number-empty {
        color: rgb(131, 131, 131);
        position: relative;
        left: 35px;
      }
    }
  }

  img {
    border-radius: 50px;
  }

  /* material-ui switch custom css */
  .MuiIconButton-label {
    color: #fff;
  }

  .MuiSwitch-track {
    background-color: #5f626a;
    opacity: unset;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ed2626 !important;
    opacity: unset;
  }
`;

export const PopupContainer = styled.div`
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
`;
