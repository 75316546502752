import styled from "@emotion/styled";

export const MyMessageBoxItemContainer = styled.div`
  .my-message-box-custom {
    z-index: 999999999999999;
    position: fixed;
    top: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .message-box-custom-inner {
      padding: 10px 15px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.85);
      border-radius: 12px;
      .fa-check-circle {
        color: green;
        font-size: 16px;
      }
      .fa-times-circle {
        color: red;
        font-size: 16px;
      }
      .fa-exclamation-circle {
        color: orange;
        font-size: 16px;
      }
      .fa-info-circle {
        color: rgb(56, 56, 207);
        font-size: 16px;
      }
      span {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
      }
    }

    .fa,
    .fas {
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      font-size: 20px;
    }
  }
`;
