import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";
export const DrawerEditFeasttFeeContainer = styled(MyDrawerCenterModal)`
  .edit-feasttt-fee-modal {
    .edit-feasttt-fee-modal-title {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.53px;
      text-align: center;
      color: #fff;
    }
    .edit-feasttt-fee-modal-meta {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.34px;
      text-align: center;
      color: #fff;
    }
    .edit-feasttt-fee-modal-input {
      margin-top: 20px;
      margin-bottom: 24px;
      .drawer-input-container[data-value] {
        position: relative;
      }
      .drawer-input-container[data-value]:after {
        content: ${(props) =>
          props.isInputValuePresent ? `attr(data-value) "%"` : "unset"};
        position: absolute;
        top: 6px; /* Borders */
        // left: 2px; /* Borders */
        font-weight: bold;
      }
      .drawer-input-container[data-value] input {
        // color: #fff; /* Optional bug avoid visual bugs */
      }
    }
  }
`;
