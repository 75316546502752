import DrawerPassword from "components/Elements/DrawerElements/DrawerPassword";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { MyAccountContext } from "context/MyAccountContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { adminServices } from "services";
import { showMessage } from "services/functions";
import { drawersMyAccount } from "static/staticData";
import DrawerRestorePassword from "../DrawerRestorePassword";
import { DrawerChangePasswordContainer } from "./style";

export default function DrawerChangePassword({
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer, openDrawers, closeDrawer } = useContext(MyAccountContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [isHidePassword, setHidePassword] = useState(true);

  const isAtLeastCharacters = () => newPassword.length >= 8;

  const isUpperCase = () => /[A-Z]/.test(newPassword);

  const isAtLeastOneNum = () => /[1-9]/.test(newPassword);

  const isPasswordsEqual = () => newPassword === newPasswordRepeat;

  const allConditionsOk = () =>
    isAtLeastCharacters() &&
    isUpperCase() &&
    isAtLeastOneNum() &&
    isPasswordsEqual();

  const canSubmit =
    allConditionsOk() && currentPassword && newPassword && newPasswordRepeat;

  const onClickSave = () => {
    if (canSubmit) {
      const { changePassword } = adminServices;
      const onSuccess = () => {
        showMessage("Password changed successfully!", "success");
        closePanel();
      };
      const onFailure = (error) => showMessage(error, "error");
      const payload = {
        current_password: currentPassword,
        new_password: newPassword,
      };
      changePassword(payload, onSuccess, onFailure);
    }
  };

  return (
    <DrawerChangePasswordContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">Change Password</h2>
        <div className="change-password-panel">
          <div className="panel-content-body">
            <div className="password-inputs">
              <DrawerPassword
                label="Current Password"
                placeholder="Enter Current Password"
                type={isHidePassword ? "password" : "text"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="password-input"
                autoComplete="new-password"
              />
              <DrawerPassword
                label="New Password"
                placeholder="Enter New Password"
                type={isHidePassword ? "password" : "text"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="password-input"
                autoComplete="new-password"
              />
              <DrawerPassword
                label="Confirm New Password"
                placeholder="Confirm New Password"
                type={isHidePassword ? "password" : "text"}
                value={newPasswordRepeat}
                onChange={(e) => setNewPasswordRepeat(e.target.value)}
                className="password-input"
                autoComplete="new-password"
              />
            </div>
            <div
              className="password-toggle"
              onClick={() => setHidePassword(!isHidePassword)}
            >
              {isHidePassword ? (
                <span>
                  <span className="password-toggle-text">Show Password</span>
                  <FaEye />
                </span>
              ) : (
                <span>
                  <span className="password-toggle-text">Hide Password</span>
                  <FaEyeSlash />
                </span>
              )}
            </div>

            <div className="password-conditions">
              <div
                className={`password-conditions-field ${
                  isAtLeastCharacters() ? "active" : ""
                }`}
              >
                <i
                  className={`far ${
                    isAtLeastCharacters()
                      ? "fa-check-circle"
                      : "fa-times-circle"
                  }`}
                />
                <span>At least 8 characters</span>
              </div>
              <div
                className={`password-conditions-field ${
                  isUpperCase() ? "active" : ""
                }`}
              >
                <i
                  className={`far ${
                    isUpperCase() ? "fa-check-circle" : "fa-times-circle"
                  }`}
                />
                <span>At least 1 upper case letter (A-Z)</span>
              </div>
              <div
                className={`password-conditions-field ${
                  isAtLeastOneNum() ? "active" : ""
                }`}
              >
                <i
                  className={`far ${
                    isAtLeastOneNum() ? "fa-check-circle" : "fa-times-circle"
                  }`}
                />
                <span>At least 1 number (0-9)</span>
              </div>
            </div>

            <div className="forgot-password">
              <span className="restore-pass-text">Forgot?</span>
              <span
                className="restore-pass"
                onClick={() => openDrawer(drawersMyAccount.restorePassword)}
              >
                Restore password <i className="fas fa-angle-right" />
              </span>
            </div>
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            className="mb-3 text-bold"
            type={canSubmit ? "red" : "gray"}
            onClick={onClickSave}
          >
            Save
          </MyDrawerButton>
          <div className="cancel-btn" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
      <DrawerRestorePassword
        isActive={!!openDrawers[drawersMyAccount.restorePassword]}
        closePanel={() => closeDrawer(drawersMyAccount.restorePassword)}
      />
    </DrawerChangePasswordContainer>
  );
}
