import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DiscountTypeValueContainer } from "./style";

export function DiscountTypeValue({
  dollars,
  setDollars,
  percents,
  setPercents,
  onChangeType,
  isFixedActive,
  isPercentageActive,
  activeOrderDetail,
}) {
  const onChangeDollars = (dollarsValue = "") => {
    const dollarsNumber = Number(dollarsValue);
    const total = Number(activeOrderDetail?.checkout_data?.total);
    if (dollarsNumber < total) {
      setDollars(dollarsNumber);
    }
  };

  const onChangePercents = (percentsValue = "") => {
    const percentsNumber = Number(percentsValue);
    if (percentsValue.length <= 3 && percentsNumber <= 100) {
      setPercents(percentsNumber);
    }
  };

  return (
    <DiscountTypeValueContainer>
      <div
        className={`type-container ${isPercentageActive() ? "active" : ""}`}
        onClick={() => onChangeType("percentage")}
      >
        <span className="type-title">Percentage</span>
        <div className="type-value-container percents">
          <input
            type="number"
            value={Math.trunc(percents)}
            onChange={(event) => onChangePercents(event.target.value)}
          />
          <span className="type-value">%</span>
        </div>
      </div>
      <div
        className={`type-container ${isFixedActive() ? "active" : ""}`}
        onClick={() => onChangeType("fixed")}
      >
        <span className="type-title">Fixed Amount</span>
        <div className="type-value-container dollars">
          <span className="type-value">$</span>
          <input
            type="number"
            value={dollars}
            onChange={(event) => onChangeDollars(event.target.value)}
            min="1"
          />
        </div>
      </div>
    </DiscountTypeValueContainer>
  );
}

DiscountTypeValue.propTypes = {
  dollars: PropTypes.number,
  setDollars: PropTypes.func,
  percents: PropTypes.number,
  setPercents: PropTypes.func,
  onChangeType: PropTypes.func,
  isFixedActive: PropTypes.func,
  isPercentageActive: PropTypes.func,
};

export const needToPay = (order) => {
  return order.items.reduce(
    (totalPrice, { total, price, addons }) =>
      totalPrice +
      total * price +
      addons.reduce(
        (addonsTotalPrice, [, currentPrice]) => currentPrice + addonsTotalPrice,
        0
      ) *
        total,
    0
  );
};
