import React, { useContext } from "react";
import { DrawerAddedItemsSuccessContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import { AddedExistingItem } from "assets/img";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";

const DrawerAddedItemsSuccess = ({
  isActive = false,
  closePanel = () => {},
  selectedFoods = [],
}) => {
  const { selectedCategory } = useContext(RestaurantsContext);
  return (
    <DrawerAddedItemsSuccessContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <img
            className="added-existing-item-img"
            src={AddedExistingItem}
            alt=""
          />
          <div className="added-existing-item-meta d-flex flex-column ">
            <div className="added-existing-item-meta-text">
              {selectedFoods?.length} items added to
            </div>
            <div className="added-existing-item-meta-text">{`"${selectedCategory?.name}"`}</div>
          </div>
          <MyDrawerButton
            type="red"
            className="added-existing-item-btn"
            onClick={closePanel}
          >
            <b>Okay</b>
          </MyDrawerButton>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerAddedItemsSuccessContainer>
  );
};

export default DrawerAddedItemsSuccess;
