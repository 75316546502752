import React, { useEffect, useState } from "react";
import { ActivityByDayContainer } from "./style";
import { AnalyticPeriod } from "../../AnalyticPeriod";
import { ActivityByDayChart } from "./ActivityByDayChart";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";

export const ActivityByDay = () => {
  const [period, setPeriod] = useState("week");
  const [dateRange, setDateRange] = useState();
  const [activityGraphData, setActivityGraphData] = useState([]);
  const [resData, setResData] = useState([]);
  useEffect(() => {
    let url;
    if (dateRange) {
      url = `/analytics-customer/activity?period=${period}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
    } else {
      url = `/analytics-customer/activity?period=${period}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        setResData(res?.data || []);
        let array = [];
        res.data &&
          res.data.map((el) => {
            let obj = {
              name: `${moment(el.time_stamp).format("ddd")}`,
              activities: el.count || 0,
            };
            array.push(obj);
          });

        setActivityGraphData(array || []);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [period, dateRange]);

  const onTimePeriodChange = (time) => {
    setPeriod(time);
  };

  return (
    <ActivityByDayContainer>
      <div className="activity-day-top">
        <h4 className="activity-top-title">Activity by Day</h4>
        <div className="chart-date">
          {/* {dateRange !== undefined &&
            `${moment(dateRange?.startDate).format(
              "dddd MMMM DD, YYYY"
            )} - ${moment(dateRange?.endDate).format("dddd MMMM DD, YYYY")}`} */}
          {dateRange?.startDate && dateRange?.endDate
            ? `${moment(dateRange.startDate).format("dddd MMMM DD, YYYY")}
                 - ${moment(dateRange.endDate).format("dddd MMMM DD, YYYY")}`
            : period === "day"
            ? moment().format("dddd MMMM DD, YYYY")
            : period === "week"
            ? `${
                moment()
                  .subtract(6, "days")
                  .format("dddd MMMM DD, YYYY")
                  .split("T")[0]
              }
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "month"
            ? `${moment()
                .subtract(30, "days")
                .format("dddd MMMM DD, YYYY")}
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "quarter"
            ? `${moment()
                .subtract(91, "days")
                .format("dddd MMMM DD, YYYY")}
                      - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "year"
            ? `${moment()
                .subtract(1, "year")
                .format("dddd MMMM DD, YYYY")}
                - ${moment().format("dddd MMMM DD, YYYY")}`
            : moment()
                .format("dddd, MMMM DD, YYYY")
                .split("T")[0]}
        </div>
        <AnalyticPeriod
          className="activity-top-period"
          onChange={onTimePeriodChange}
          setDateRange={setDateRange}
          dateRange={dateRange}
          defaultPeriodSelect={"week"}
        />
      </div>
      <ActivityByDayChart
        activityGraphData={activityGraphData}
        fullData={resData}
        period={period}
      />
    </ActivityByDayContainer>
  );
};
