import styled from "@emotion/styled";
import { colors } from "theme";

export const ProfileCardInfoContainer = styled.div`
  padding: 20px 36px;
  .profile-info-header {
    margin-bottom: 40px;
    .edit-btn {
      border-radius: 25px;
      padding: 0 18px;
    }
    .profile-info-avatar {
      margin-right: 54px;
      img {
        border-radius: 50%;
        width: 92px;
        height: 92px;
      }
      .customer-profile-rating {
        width: 59px;
        height: 23px;
        padding: 2px 9px 3px 10px;
        border-radius: 15.5px;
        background-color: #151617;
        position: absolute;
        margin: -19px 0 0 18px;
        .rating-container {
          display: flex;
          align-items: center;
          width: 40px;
          height: 18px;
          .rating-number {
            font-size: 14px;
            font-weight: bold;
            color: white;
            margin-right: 5px;
            margin-left: 5px;
          }

          .rating-icon {
            i {
              font-size: 14px;
              color: #fdcb6e;
            }
          }
        }
      }
    }
    .profile-info-name {
      margin-bottom: 15px;
    }
    .profile-info-join-date {
      margin-bottom: 15px;
      .title-input {
        opacity: 0.6;
      }
      span {
        /* padding: 6px 12px; */
        // background-color: ${colors.green};
        border-radius: 15px;
        font-weight: 600;
        /* margin-left: 13px; */
      }
      .joined-title {
        opacity: 0.6;
      }
    }

    .profile-info-location {
      .location-mark {
        color: #FFFFFF;
        opacity: 0.6;
      }
      span {
        opacity: 0.6;
        color: #ffffff;
        font-weight: 500;
      }
    }

    .customer-status {
      background-color: #27282A !important;
      /* color: #ffffff; */
      margin-left: 10px;
      padding: 3px 10px;
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .profile-info-row {
    display: flex;
    margin-bottom: 33px;
    .profile-info-col {
      flex: 1;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  .profile-info-col-content {
    margin-left: 16px;
    .profile-info-col-label {
      margin-bottom: 6px;
      opacity: 0.6;
    }
    .profile-info-col-value {
    }
  }
`;
