import React, { useContext, useEffect, useState } from "react";
import { CuisineModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const CuisineModal = ({
  isActive = false,
  closePanel = () => {},
  cuisinesData = "",
}) => {
  const {
    selectedCuisines,
    setSelectedCuisines,
    postRestaurant,
    setPostRestaurant,
  } = useContext(RestaurantsContext);

  function toggleSelectedCuisines(id) {
    if (selectedCuisines.includes(id)) {
      setSelectedCuisines([...selectedCuisines.filter((_id) => _id !== id)]);
    } else if (selectedCuisines?.length < 3) {
      const uniqueChars = selectedCuisines.filter((c, index) => {
        return selectedCuisines.indexOf(c) === index;
      });
      setSelectedCuisines([...uniqueChars, id]);
    }
  }

  const findCuisinesName = (selectedCuisines, cuisineList) => {
    return selectedCuisines
      .map((title) => cuisineList.find(({ id }) => id === title))
      .filter((cuisine) => !!cuisine)
      .map(({ name }) => name);
  };

  function handleClickDone() {
    setPostRestaurant({
      ...postRestaurant,
      cuisines: selectedCuisines,
      cuisinesName: findCuisinesName(selectedCuisines, cuisinesData),
    });
    closePanel();
  }
  return (
    <CuisineModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"5%"}
      left={"38%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="drawer-content">
          <div className="top-title">
            <div></div>
            <div className="title">
              <b>Cuisine</b>
            </div>
            <div></div>
          </div>
          <div className="panel-status-container">
            <p className="select-message opacity6">Select up to 3</p>
            <div className="cuisine-container">
              {cuisinesData.length > 0 &&
                cuisinesData
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((_cuisine, _i) => {
                    return (
                      <div
                        className={`cuisine-item ${
                          selectedCuisines.includes(_cuisine?.id)
                            ? "selected"
                            : ""
                        }`}
                        style={
                          _cuisine?.image
                            ? {
                                backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url(${_cuisine?.image})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }
                            : {}
                        }
                        key={_i}
                        onClick={() => toggleSelectedCuisines(_cuisine.id)}
                      >
                        <p className="internal-title">{_cuisine.name}</p>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              onClick={handleClickDone}
              disabled={selectedCuisines?.length === 0}
            >
              <b>Done</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </CuisineModalContainer>
  );
};

export default CuisineModal;
