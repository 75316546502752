import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const DrawerSetDiscountModalContainer = styled(MyDrawerCenterModal)`
  .set-discount-container {
    .modal-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .input-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #545556;
      border-bottom: 1px solid #545556;
      padding: 10px 0 10px 0;
      margin-bottom: 30px;
      .amount {
        font-size: 14px;
        /* .label-text {
          color: #757575;
        } */
        input {
          width: 16rem;
          background: transparent;
          border: 0;
          color: #fff;
          &:focus {
            outline: none;
          }
        }
      }
      .percentage {
        font-size: 14px;
        .label-text {
          text-align: right;
          /* color: #757575; */
        }
        input {
          width: 16rem;
          background: transparent;
          border: 0;
          color: #fff;
          width: max-content;
          text-align: right;
          &:focus {
            outline: none;
          }
        }
      }
    }

    .save-btn-disable {
      text-align: center;
      background: #3b3b3c;
      padding: 10px 0 10px 0;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 25px;
    }

    .save-btn-enable {
      text-align: center;
      background: #ed2626;
      padding: 10px 0 10px 0;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 25px;
      cursor: pointer;
    }

    .cancel-btn {
      text-align: center;
      font-weight: bold;
      padding: 10px 0 10px 0;
      color: #ed2626;
    }
  }
`;
