import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import {
  FaBars,
  FaPen,
  FaTrash,
  FaAngleRight,
  FaChevronLeft,
  FaPlus,
} from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { colors } from "theme";
import {
  DrawerAddCustomizationsContainer,
  SortableListContainer,
} from "./style";
import { Switch } from "@material-ui/core";
import DrawerAddCustomizationsOption from "./DrawerAddCustomizationOption";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Box from "components/Box";
import SingleText from "components/SingleText";
import { arrayMove } from "react-movable";
import SingleNumberIncrease from "./SingleNumberIncrease";
import { DrawerDeleteCustomization } from "./DrawerDeleteCustomization";

export default function DrawerAddCustomizations({
  menus = [],
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    addOption,
    setAddOption,
    foodId,
    setCustomizationData,
    customizationData,
    editCustomizationInfo,
    postFood,
    setPostFood,
  } = useContext(RestaurantsContext);

  // console.log(foodId);

  const [customizationName, setCustomizationName] = useState("");
  const [availableSelection, setAvailableSelection] = useState(1);
  const [requiredField, setRequiredField] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [
    openDeleteCstomisationDrawer,
    setOpenDeleteCustomisationDrawer,
  ] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (Object.keys(editCustomizationInfo).length !== 0) {
      setCustomizationName(editCustomizationInfo?.name);
      setAvailableSelection(editCustomizationInfo?.available_selection);
      setRequiredField(editCustomizationInfo?.required);
      setAddOption(editCustomizationInfo?.options);
    } else {
      setCustomizationName("");
      setAvailableSelection(1);
      setRequiredField(false);
      setAddOption([]);
    }
  }, [editCustomizationInfo]);

  const requiredHandleChange = () => {
    setRequiredField(!requiredField);
  };

  function addCustomization() {
    // console.log(addOption);
    const postCustomizationDataConfig = {
      name: customizationName,
      available_selection:
        availableSelection === undefined ? 1 : availableSelection,
      required: requiredField,
      options: addOption,
    };
    setCustomizationData([...customizationData, postCustomizationDataConfig]);
    setPostFood({
      ...postFood,
      customization: [...customizationData, postCustomizationDataConfig],
    });
    customAxios
      .post(
        `/restaurant/${id}/food/${foodId}/customization`,
        postCustomizationDataConfig
      )
      .then((res) => {
        // console.log(res.data);
        setCustomizationName("");
        setAvailableSelection(1);
        setRequiredField("");
        getCustomizationData();
        getCustomizationData();
      })
      .catch((err) => {
        console.error(err);
      });
    closePanel();
  }

  function editCustomization() {
    if (
      id &&
      editCustomizationInfo?.food_item_id &&
      editCustomizationInfo?.id
    ) {
      const putCostomizationDataConfig = {
        name: customizationName,
        available_selection: availableSelection,
        required: requiredField,
        options: addOption,
      };
      customAxios
        .put(
          `/restaurant/${id}/food/${editCustomizationInfo?.food_item_id}/customization/${editCustomizationInfo?.id}`,
          putCostomizationDataConfig
        )
        .then((res) => {
          console.log(res.data);
          getCustomizationData();
        })
        .catch((err) => {
          console.error(err);
        });
    }
    closePanel();
  }

  const deleteCustomization = () => {
    if (
      id &&
      editCustomizationInfo?.food_item_id &&
      editCustomizationInfo?.id
    ) {
      customAxios
        .delete(
          `/restaurant/${id}/food/${editCustomizationInfo?.food_item_id}/customization/${editCustomizationInfo?.id}`
        )
        .then((res) => {
          console.log(res.data);
          setOpenDeleteCustomisationDrawer(!openDeleteCstomisationDrawer);
          getCustomizationData();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      let data = [...customizationData];
      let filterData = data?.filter(
        (el) => el.name === editCustomizationInfo.name
      );
      setCustomizationData(filterData);
      setPostFood(filterData);
      setOpenDeleteCustomisationDrawer(!openDeleteCstomisationDrawer);
    }
    closePanel();
  };

  function getCustomizationData() {
    if (id && foodId) {
      customAxios
        .get(`/restaurant/${id}/food/${foodId}/customization`)
        .then((res) => {
          setCustomizationData(res?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  useEffect(() => {
    getCustomizationData();
  }, [id, foodId]);

  const editSelectedOption = (index) => {
    setSelectedOptionIndex(index);
    openDrawer(drawersMenu.openDrawerAddCustomizationOption);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAddOption(arrayMove(addOption, oldIndex, newIndex));
  };

  const SortableList = sortableContainer(({ children }) => {
    return (
      <SortableListContainer id="drag-customization-options">
        {children}
      </SortableListContainer>
    );
  });

  const DragHandle = sortableHandle(() => (
    <FaBars className="icon-drag cursor-grab" color={"white"} size={20} />
  ));

  const SortableItem = sortableElement(({ value, sortIndex }) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="15px 20px"
      border="solid 1px rgba(255, 255, 255, 0.1)"
      borderRadius="16px"
      marginBottom="10px"
      // borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
      background={"transparent"}
      className="drag-visible"
    >
      <DragHandle />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        marginRight="auto"
        marginLeft={19}
      >
        <SingleText size={14} weight={600} spacing="-0.34px">
          {value.name}
        </SingleText>
      </Box>
      <FaAngleRight
        className="cursor"
        size={20}
        color={"rgba(255, 255, 255, 0.2)"}
        onClick={() => editSelectedOption(sortIndex)}
      />
    </Box>
  ));

  return (
    <DrawerAddCustomizationsContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <div className="add-cutomization-input-area">
          {/* <h2 className="drawer-title text-white text-center">
            Add Customizations
          </h2> */}
          <div className="top-title">
            <div>
              <FaChevronLeft
                size={20}
                onClick={closePanel}
                className="cursor-pointer"
              />
            </div>
            <div className="title">
              {Object.keys(editCustomizationInfo).length !== 0
                ? "Edit Customization"
                : "Customizations"}{" "}
            </div>
            <div>
              {Object.keys(editCustomizationInfo).length !== 0 ? (
                <FaTrash
                  size={20}
                  color={"white"}
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenDeleteCustomisationDrawer(
                      !openDeleteCstomisationDrawer
                    );
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div
              style={{
                opacity: "0.5",
              }}
            >
              Customization Name
            </div>
            <input
              type="text"
              value={customizationName || ""}
              placeholder="Ex: Size, Dressing, Add-ons "
              className="customization-input"
              onChange={(e) => setCustomizationName(e.target.value)}
            />
            <div className="input-bottom-line" />
          </div>

          <div className="required-filed">
            <div>Required</div>
            <div>
              {/* <Switch
                checked={requiredField}
                onChange={requiredHandleChange}
                // inputProps={{ "aria-label": "controlled" }}
              /> */}
              <label className="switch-refill">
                <input
                  type="checkbox"
                  checked={requiredField}
                  onChange={() => requiredHandleChange()}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="selection-required">
            {/* <div className="input-bottom-line" /> */}
            <div className="selections-allowed">
              <div>Max. Selections</div>
              <SingleNumberIncrease
                value={availableSelection}
                onIncrease={() => setAvailableSelection(availableSelection + 1)}
                onDecrease={() =>
                  availableSelection > 0 &&
                  setAvailableSelection(availableSelection - 1)
                }
              />
            </div>
            <div className="input-bottom-line" />
          </div>

          <div className="mb-4">
            <p className="add-option cursor-pointer">
              <b>Add Option</b>
            </p>
            <div className="additional-add-option">
              Options help customers to customize menu items. Example: Choose
              Size, Dressing, Add-ons, etc.
            </div>
          </div>

          <SortableList
            onSortEnd={onSortEnd}
            helperClass="row-dragging"
            helperContainer={() =>
              document.getElementById("drag-customization-options")
            }
            useDragHandle
          >
            {addOption?.map((value, index) => (
              <SortableItem
                key={`item-${value.name}`}
                sortIndex={index}
                index={index}
                value={value}
              />
            ))}
          </SortableList>

          <div className="add-option-list">
            <div
              className="add-option-title cursor-pointer"
              onClick={() => {
                openDrawer(drawersMenu.openDrawerAddCustomizationOption);
                setSelectedOptionIndex("");
              }}
            >
              <b>Add Option</b>
            </div>
          </div>
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            // onClick={() => openDrawer(drawersMenu.menuAdd)}
            onClick={() => {
              if (Object.keys(editCustomizationInfo).length !== 0) {
                editCustomization();
              } else {
                addCustomization();
              }
            }}
          >
            {Object.keys(editCustomizationInfo).length !== 0
              ? "Edit Customization"
              : "Add Customization"}
          </MyDrawerButton>
          <div
            className="cancel-btn text-center text-bold cursor-pointer"
            onClick={closePanel}
          >
            Cancel
          </div>
        </div>
      </DrawerContainer>

      <DrawerAddCustomizationsOption
        isActive={!!openDrawers[drawersMenu.openDrawerAddCustomizationOption]}
        closePanel={() =>
          closeDrawer(drawersMenu.openDrawerAddCustomizationOption)
        }
        selectedOptionIndex={selectedOptionIndex}
        setSelectedOptionIndex={setSelectedOptionIndex}
      />
      <DrawerDeleteCustomization
        isActive={openDeleteCstomisationDrawer}
        closePanel={() =>
          setOpenDeleteCustomisationDrawer(!openDeleteCstomisationDrawer)
        }
        deleteCustomization={deleteCustomization}
      />
    </DrawerAddCustomizationsContainer>
  );
}
