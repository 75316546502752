import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaCandyCane, FaLeaf } from "react-icons/fa";
import { DrawerItemContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import DrawerNutrition from "../Nutrition";
import { drawersOverall } from "static/staticData";
import DrawerReviews from "../Reviews";
import { customAxios } from "helpers/custom-helpers/customAxios";
import DrawerFoodItemReviews from "../DrawerFoodItemReviews";
import { mocks } from "static/staticData";
import DrawerFoodItemCustomization from "../DrawerFoodItemCustomization";
import { useParams } from "react-router-dom";

// const DietaryTags = [
//   { value: 0, name: "Vegetarian", icon: <FaLeaf color="white" size={14} /> },
//   {
//     value: 1,
//     name: "Vegan",
//     icon: <i className="fas fa-leaf-heart text-white"></i>,
//   },
//   {
//     value: 2,
//     name: "Gluten-Free",
//     icon: <i className="fas fa-wheat text-white"></i>,
//   },
//   {
//     value: 3,
//     name: "Dairy-Free",
//     icon: <i className="fas fa-cow text-white"></i>,
//   },
//   {
//     value: 4,
//     name: "Sugar-Free",
//     icon: <FaCandyCane color="white" size={14} />,
//   },
// ];

// [
//   { icon: <i className='fas fa-candy-cane' />, name: 'Sugar-Free', value: 4 },
//   { icon: <i className='fas fa-cow' />, name: 'Dairy-Free', value: 3 },
//   { icon: <i className='fas fa-leaf' />, name: 'Vegetarian', value: 0 },
//   { icon: <i className='fas fa-leaf-heart' />, name: 'Vegan', value: 1 },
//   { icon: <i className='fas fa-wheat' />, name: 'Gluten-Free', value: 2 },
// ];

const dietaryTagIconRenderer = (tagId) => {
  switch (tagId) {
    case 0:
      return <i className="fas fa-leaf-heart" />;
    case 1:
      return <i className="fas fa-leaf" />;
    case 2:
      return <i className="fas fa-wheat" />;
    case 3:
      return <i className="fas fa-cow" />;
    case 4:
      return <i className="fas fa-candy-cane" />;
  }
};

export default function DrawerItem({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const {
    heightRateItem,
    setHeightRateItem,
    openDrawer,
    openDrawers,
    closeDrawer,
    foodReviewsId,
  } = useContext(RestaurantsContext);

  const [foodReviews, setFoodReviews] = useState([]);
  const [nutritionsData, setNutritionsData] = useState({});
  const [itemCustomize, setItemCustomize] = useState([]);

  const [staffSort, setStaffSort] = useState("");

  // useEffect(() => {
  //   if (!isActive) {
  //     setHeightRateItem({});
  //   }
  // }, [isActive]);

  useEffect(() => {
    onClickHandleReviews();
  }, [staffSort]);

  // useEffect(() => {
  //   if (heightRateItem?.id) {
  //     let _nutrition =
  //       JSON.parse(heightRateItem?.nutrition) &&
  //       JSON.parse(heightRateItem?.nutrition)?.length
  //         ? JSON.parse(heightRateItem?.nutrition)[0]
  //         : JSON.parse(heightRateItem?.nutrition);
  //     setNutritionsData(_nutrition);
  //   }
  // }, [heightRateItem]);

  function onClickHandleNutrition() {
    openDrawer(drawersOverall?.dashboardNutritions);
    // customAxios
    //   .get(`/restaurant/4?get_detail=true`)
    //   .then((res) => {
    //     res &&
    //       res.data &&
    //       res.data.foods.map((_items) => {
    //         // console.log(foodReviewsId)
    //         if (_items?.id === foodReviewsId?.id) {
    //           // console.log(_items?.nutrition);
    //           setNutritionsData(
    //             _items?.nutrition && typeof _items?.nutrition === "string"
    //               ? JSON.parse(_items?.nutrition)
    //               : _items?.nutrition
    //           );
    //         }
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  // reviews api
  function onClickHandleReviews() {
    if (foodReviewsId?.id || staffSort) {
      customAxios
        .get(`/dashboard/food/${foodReviewsId?.id}/review?sort=${staffSort}`)
        .then((res) => {
          if (res.status === 200) {
            setFoodReviews(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function customization() {
    openDrawer(drawersOverall.drawerItemCustomization);
    customAxios
      .get(
        `/restaurant/${heightRateItem?.restaurant_id}/food/${heightRateItem?.id}/customization`
      )
      .then((res) => {
        setItemCustomize(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if (staffSort === "Oldest") {
    setStaffSort(1);
  } else if (staffSort === "Highest Rated") {
    setStaffSort(3);
  } else if (staffSort === "Lowest Rated") {
    setStaffSort(2);
  } else if (staffSort === "Earliest") {
    setStaffSort(0);
  }

  // useEffect(() => {
  //  console.log(staffSort)
  // }, [staffSort])

  return (
    <DrawerItemContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="items-wrapper">
          {/* background blur img */}
          <div className="top-item-image">
            <div onClick={closePanel}>
              <i className="fas fa-times"></i>
            </div>
            <img
              src={
                heightRateItem?.photo?.file
                  ? heightRateItem?.photo?.file
                  : defaultFood
              }
              alt=""
              className="w-100"
            />
          </div>

          <div className="overlay-drawer">
            {/* front-img */}
            <div className="inner-image">
              <img
                src={
                  heightRateItem?.photo?.file
                    ? heightRateItem?.photo?.file
                    : defaultFood
                }
                alt=""
                className="w-100"
              />
            </div>

            <div className="bottom-space">
              {/* item-name */}
              <div className="item-name">{heightRateItem?.title}</div>

              {/* description */}
              <div className="item-description">
                {heightRateItem?.description}
              </div>

              {/* dietary */}

              <div className="food-tags">
                {heightRateItem?.dietary ? (
                  heightRateItem?.dietary?.map((_tagId, _i) => (
                    <div key={_i} className="food-tags-item">
                      {dietaryTagIconRenderer(_tagId)} &nbsp;
                      {mocks.dietaryTags[_tagId].title}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>

              {/* nutritions */}
              <div className="nutritions">
                <div className="inner-nutrition calories-wrapper">
                  <div className="numbers">{nutritionsData?.calories || 0}</div>
                  <div className="nutritions-name">calories</div>
                </div>
                <div className="inner-nutrition protein-wrapper">
                  <div className="numbers">{nutritionsData?.protein || 0}</div>
                  <div className="nutritions-name">protein</div>
                </div>
                <div className="inner-nutrition carbs-wrapper">
                  <div className="numbers">
                    {nutritionsData?.totalCarbohydrate || 0}
                  </div>
                  <div className="nutritions-name">carbs</div>
                </div>
              </div>

              {/* nutritions-info */}
              <div className="nutrition-title">
                <div>
                  <i className="far fa-info-circle" />
                </div>
                <div
                  className="nutrition-text"
                  // onClick={() =>
                  //   openDrawer(drawersOverall?.dashboardNutritions)
                  // }
                  onClick={onClickHandleNutrition}
                >
                  Nutrition
                </div>
              </div>

              {/* review */}
              <div
                className="rating-review-container cursor"
                onClick={() => openDrawer(drawersOverall?.dashboardReviews)}
              >
                <div className="rating-review-flex">
                  <div>
                    <i className="fas fa-star mr-1" />
                    <span className="text-bold">
                      {heightRateItem?.yum_percent
                        ? heightRateItem?.yum_percent
                        : "-"}
                    </span>
                  </div>
                  <div onClick={onClickHandleReviews}>
                    <span className="mr-3">
                      {heightRateItem?.reviews_count
                        ? heightRateItem?.reviews_count
                        : 0}{" "}
                      reviews
                    </span>{" "}
                    <i className="fas fa-chevron-right" />{" "}
                  </div>
                </div>
              </div>

              {/* customization */}
              <div className="option-container" onClick={customization}>
                <div className="option-flex">
                  <div className="customization-text">Customization</div>
                  <div>
                    <span className="mr-3">
                      {heightRateItem?.customizations
                        ? heightRateItem?.customizations
                        : 0}{" "}
                      options
                    </span>{" "}
                    <i className="fas fa-chevron-right" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DrawerContainer>
      <DrawerNutrition
        isActive={!!openDrawers[drawersOverall.dashboardNutritions]}
        closePanel={() => closeDrawer(drawersOverall.dashboardNutritions)}
        nutritionsData={nutritionsData}
      />
      {/* <DrawerReviews
        isActive={!!openDrawers[drawersOverall.dashboardReviews]}
        closePanel={() => closeDrawer(drawersOverall.dashboardReviews)}
        foodReviews={foodReviews}
      /> */}
      <DrawerFoodItemReviews
        isActive={!!openDrawers[drawersOverall.dashboardReviews]}
        closePanel={() => closeDrawer(drawersOverall.dashboardReviews)}
        foodDetails={foodReviewsId}
        foodReviews={foodReviews}
        staffSort={staffSort}
        setStaffSort={setStaffSort}
      />
      <DrawerFoodItemCustomization
        isActive={!!openDrawers[drawersOverall.drawerItemCustomization]}
        closePanel={() => closeDrawer(drawersOverall.drawerItemCustomization)}
        foodDetails={foodReviewsId}
        foodReviews={foodReviews}
        itemCustomize={itemCustomize}
      />
    </DrawerItemContainer>
  );
}
