import React from "react";
import PropTypes from "prop-types";
import { DrawerInputWithVerifyContainer } from "./style";
import { FaChevronRight, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { colors } from "theme";

export default function DrawerInputWithVerify({
  placeholder = "Ex: Caesar Salad",
  className = "",
  label = "",
  type = "text",
  value = "",
  onChange = () => {},
  onClick = () => {},
  verified = false,
  isHideArrowIcon = false,
  isVerifiedShow = false,
}) {
  return (
    <DrawerInputWithVerifyContainer className={className}>
      {label && <h6 className="mb-0">{label}</h6>}
      <div className="drawer-input-container">
        <input
          type={type}
          value={value || ""}
          placeholder={placeholder}
          onChange={onChange}
        />
        {isVerifiedShow ? (
          <div className="verify-icon" onClick={onClick}>
            {verified ? (
              <FaCheckCircle size={20} color={"#1ece6d"} />
            ) : (
              <FaTimesCircle size={20} color={"#ed2626"} />
            )}
          </div>
        ) : null}
      </div>
      {isHideArrowIcon ? null : (
        <div className="input-icon" onClick={onClick}>
          <FaChevronRight size={15} color={colors.white3} />
        </div>
      )}
    </DrawerInputWithVerifyContainer>
  );
}

DrawerInputWithVerify.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
};
