import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerChangeEmailContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .drawer-content {
    margin-top: 65px;
    h6 {
      font-size: 14px;
    }
    .email-input {
      margin-top: 30px;
    }
  }
`;
