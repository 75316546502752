import styled from "@emotion/styled";
import { colors } from "theme";

export const RestaurantProfilePageContainer = styled.div`
  .restaurant-profile-page-header {
    font-family: Montserrat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0px 25px 0px;
    margin: 0 20px 0 5px;
    border-bottom: 1px solid #4d5053;
    .left-part {
      flex: 1 1 50%;
      padding-left: 40px;
      display: flex;
      align-items: center;
      .backlink-to-rest {
        color: ${colors.white};
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
      .arrow-icon-container {
        i {
          color: white;
        }
      }
      .current-restaurant {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.41px;
      }
      .restaurant-title {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
      }
    }
    .right-part {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      background-color: #27282a;
      border-radius: 40px;
      height: 40px;

      .menu-item {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.5);

        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -webkit-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        padding: 0 70px;
        a {
          color: rgba(255, 255, 255, 0.5);
        }
        &.active-item {
          a {
            color: rgba(255, 255, 255, 1);
          }
          background-color: #ed2626;
          border-radius: 40px;
        }
        /* &.active-item:after {
          content: "";
          border-bottom: solid 3px #ed2626;
          display: block;
          width: 30px;
          margin: 0 auto;
        } */
      }
      /* .menu-item:after {
        content: "";
        border-bottom: solid 3px transparent;
        display: block;
        width: 30px;
        margin: 0 auto;
      } */
      .menu-item:hover {
        color: rgba(255, 255, 255, 1);
      }
      .menu-item:last-child {
        margin-right: 0px;
      }
    }
  }

  .restaurant-profile-page-body {
    padding-left: 5px;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb-part {
    flex: 1 1 50%;
    padding-left: 40px;
    margin: 30px 0 10px 0;
    .backlink-to-rest {
      color: ${colors.white};
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.41px;
    }
    .arrow-icon-container {
      i {
        color: white;
      }
    }
    .current-restaurant {
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.41px;
    }
    .restaurant-title {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }
  }
  .pending-restaurant-decline-approove {
    margin: 0 20px 0 5px;
    padding: 20px 20px 20px 32px;
    border-radius: 20px;
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: #27282a;
    position: fixed;
    bottom: 30px;
    width: -webkit-fill-available;
    .pending-resturant-name {
      font-family: Montserrat;
      font-size: 17px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: -0.41px;
      color: #fff;
      .span-one {
        font-weight: normal;
      }
      .span-two {
        font-weight: bold;
      }
    }
    .pending-restuarant-actions {
      gap: 50px;
      button {
        border-radius: 25px;
      }
    }
  }
`;
