import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React from "react";
import { DrawerReviewDeletedContainer } from "./style";

export default function DrawerReviewDeleted({
  isActive = false,
  closePanel = () => {},
}) {
  return (
    <DrawerReviewDeletedContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper>
          <div className="drawer-bottom-content w-100">
            <h3>Review Deleted</h3>
            <div className="bottom-btns">
              <MyDrawerButton
                type="red"
                onClick={closePanel}
              >
                Okay
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerReviewDeletedContainer>
  );
}
