import { NoAvatar } from "assets/img";
import React from "react";
import { CustomerItemContainer } from "./style";
import moment from "moment";

export const CustomerItem = (props) => {
  const {
    isSelected,
    customOnClick,
    goToProfile,
    customerListTab,
    customer,
    selectedCustomers,
    searchInput,
  } = props;

  const customHandler = (event) => {
    if (event) {
      event.stopPropagation();
      customOnClick();
    }
  };

  const getHighlightedText = (text, highlight, color) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const columnRenderHandler = () => {
    if (customerListTab === 1) {
      return (
        <>
          <div
            className="item-icon item-flex-small"
            onClick={customHandler}
            style={{
              cursor: "pointer",
            }}
          >
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.user_image || NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-big">
            {customer?.user_name === " " || customer?.user_name === ""
              ? "No Name"
              : getHighlightedText(customer?.user_name, searchInput, "#ed2626")}
          </div>
          <div className="item-status item-flex-normal ">
            <div className="active-status">Active</div>
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(customer?.joined_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="item-last-location item-flex-big">
            {customer?.last_location || "Not Set"}
          </div>
          <div className="item-sales item-flex-normal center">
            {customer?.orders}
          </div>
          <div className="item-fees item-flex-last">{`$ ${customer?.total_paid ||
            0}`}</div>
        </>
      );
    } else if (customerListTab === 0) {
      return (
        <>
          <div
            className="item-icon item-flex-small"
            onClick={customHandler}
            style={{
              cursor: "pointer",
            }}
          >
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.user_image || NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-big">
            {customer?.user_name === " " || customer?.user_name === ""
              ? "No Name"
              : getHighlightedText(customer?.user_name, searchInput, "#ed2626")}
          </div>
          <div className="item-status item-flex-normal ">
            <div className="inactive-status">Inactive</div>
          </div>
          <div className="item-joined-date item-flex-normal">
            {customer?.inactive_date !== null
              ? moment(customer?.inactive_date).format(`MMM DD, YYYY`)
              : "-"}
          </div>
          <div className="item-joined-date item-flex-big">
            {moment(customer?.joined_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="item-sales item-flex-normal center">
            {customer?.orders}
          </div>
          <div className="item-fees item-flex-last">{`$ ${customer?.total_paid ||
            0}`}</div>
        </>
      );
    } else if (customerListTab === 9) {
      return (
        <>
          <div className="item-icon item-flex-small" onClick={customHandler}>
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.user_image || NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-big">
            {customer?.user_name === " " || customer?.user_name === ""
              ? "No Name"
              : getHighlightedText(customer?.user_name, searchInput, "#ed2626")}
          </div>
          <div className="item-status item-flex-normal ">
            <div className="banned-status">Banned</div>
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(customer?.ban_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(customer?.joined_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="item-joined-date item-flex-normal">
            {customer?.admin_name}
          </div>
          <div className="item-sales item-flex-normal center">
            {customer?.orders}
          </div>
          <div className="item-fees item-flex-last">
            {/* {`$ ${customer?.total_paid}`} */}$0
          </div>
        </>
      );
    } else if (customerListTab === -1) {
      return (
        <>
          <div className="item-icon item-flex-small" onClick={customHandler}>
            <i
              className={`fas fa-check`}
              style={isSelected ? {} : { display: "none" }}
            />
            <img src={customer?.user_image || NoAvatar} alt="avatar" />
          </div>
          <div className="item-name item-flex-big">
            {customer?.user_name === " " || customer?.user_name === ""
              ? "No Name"
              : getHighlightedText(customer?.user_name, searchInput, "#ed2626")}
          </div>
          <div className="item-status item-flex-normal ">
            <div className="banned-status">Deleted</div>
          </div>
          <div className="item-joined-date item-flex-normal">
            {customer?.deleted_at !== null
              ? moment(customer?.deleted_at).format(`MMM DD, YYYY`)
              : "-"}
          </div>
          <div className="item-joined-date item-flex-normal">
            {moment(customer?.joined_date).format(`MMM DD, YYYY`)}
          </div>
          <div className="item-joined-date item-flex-normal">
            {customer?.deleted_by ||
              (customer?.user_name === " " || customer?.user_name === ""
                ? "No Name"
                : customer?.user_name)}
          </div>
          <div className="item-sales item-flex-normal center">
            {customer?.orders}
          </div>
          <div className="item-fees item-flex-last">
            {/* {`$ ${customer?.total_paid}`} */}$0
          </div>
        </>
      );
    } else {
    }
  };

  return (
    <CustomerItemContainer
      className={`customers-item selected-restaurant
      } ${isSelected ? "inner-selected" : ""}`}
      onClick={goToProfile}
      style={{
        cursor: selectedCustomers?.length ? "default" : "pointer",
      }}
    >
      {columnRenderHandler()}
    </CustomerItemContainer>
  );
};
