import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyDragger from "components/Elements/MyDragger";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { FaBars, FaPen, FaTimes } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import DrawerCategory from "../DrawerCategory";
import { DrawerCategoriesContainer } from "./style";

export default function DrawerCategories({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    selectedMenu,
    closeDrawer,
    openDrawers,
    openDrawer,
    setPostCategory,
    postCategory,
    categoryCloseDrawer, setCategoryCloseDrawer,
  } = useContext(RestaurantsContext);

  return (
    <DrawerCategoriesContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaTimes
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Edit Categories</div>
          <div></div>
        </div>
        <MyDragger
          renderItem={(item) => {
            return (
              <div className="drag-menu-item">
                <FaBars
                  className="icon-drag"
                  color="rgba(255, 255, 255, 0.3)"
                  size={20}
                />
                <div className="drag-menu-item-content">
                  <p className="drag-menu-item-name mb-0">{item.name}</p>
                </div>
                <FaPen
                  size={20}
                  cursor="pointer"
                  color="white"
                  onClick={() => {
                    setPostCategory(item);
                    // openDrawer(drawersMenu.categoryEdit);
                    setCategoryCloseDrawer({
                      ...categoryCloseDrawer,
                      categoryEdit: true,
                    })
                  }}
                />
              </div>
            );
          }}
          items={selectedMenu?.categories}
        />
        <div className="bottom-btns">
          <MyDrawerButton
            type="gray"
            className="text-bold mb-5 mt-5"
            onClick={() => {
              setPostCategory({});
              openDrawer(drawersMenu.categoryAdd);
            }}
          >
            Add New Category
          </MyDrawerButton>
          <MyDrawerButton
            type="red"
            className="text-bold mb-5"
            onClick={() => closeDrawer(drawersMenu.categories)}
          >
            Save
          </MyDrawerButton>
        </div>
      </DrawerContainer>

      {/* Drawer All Categories */}
      <DrawerCategory
        type="Add"
        isActive={!!openDrawers[drawersMenu.categoryAdd]}
        closePanel={() => closeDrawer(drawersMenu.categoryAdd)}
      />

      {/* Drawer All Categories */}
      <DrawerCategory
        type="Edit"
        isActive={categoryCloseDrawer.categoryEdit}
        closePanel={() => setCategoryCloseDrawer({
          ...categoryCloseDrawer,
          categoryEdit : false,
        })}
      />
    </DrawerCategoriesContainer>
  );
}
