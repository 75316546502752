import styled from "@emotion/styled";

export const AnalyticsHeaderContainer = styled.div`
  padding: 25px 38px;
  border-bottom: 1px solid #4d5053;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left-core {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: white;
      margin-bottom: 0px;
    }
  }

  .header-right-children {
    display: flex;
    align-items: center;

    .total-container {
      margin-left: 47px;
      display: flex;
      align-items: center;

      .total-title {
        margin-right: 13px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
      }

      .total-value {
        font-size: 30px;
        font-weight: bold;
        color: white;
      }
    }
  }
`;
