import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerPaymentCardContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    .fa-plus {
      color: #ed2626;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .payment-list {
    .payment-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      border: 1px solid rgb(72 72 72);
      padding: 20px;
      border-radius: 15px;
      cursor: pointer;
      .payment-info {
        display: flex;
        gap: 10px;
        .text-content {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .done-btn {
    border-radius: 8px;
    height: 50px;
  }
`;
