import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { DrawerFilterOrdersContainer } from "./style";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DatePicker from "components/Elements/DrawerElements/DatePicker";
import { OrdersContext } from "context/OrdersContext";
import { drawersOrder } from "static/staticData";
import { StyledStatusItem } from "theme/styles";
import { FaCheck, FaChevronLeft, FaSpinner } from "react-icons/fa";
import { restaurantServices } from "services";
import { Link } from "react-router-dom";
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { colors } from "theme";
import moment from "moment";
import MyDateRange from "components/Elements/MyDateRange";
import { PopupCalendarContainer } from "components/analytics/AnalyticPeriod/style";
import MyButton from "components/Elements/MyButton";
import useOnclickOutside from "react-cool-onclickoutside";

const filterGuestOptions = [
  {
    value: [1, 4],
    label: "1-4",
  },
  {
    value: [5, 9],
    label: "5-9",
  },
  {
    value: [10],
    label: "10+",
  },
];

// const filterItemsOptions =
export default function DrawerFilterOrders({
  isActive = false,
  closePanel = () => {},
  selectedDate,
  onDone,
  onClickResetFilter = () => {},
  isFilterApplied = false,
  setIsFilterApplied,
}) {
  const { openDrawer } = useContext(OrdersContext);
  const [activeGuest, setActiveGuest] = useState("");
  const [activeItems, setActiveItems] = useState([]);
  const [filterItemsOptions, setFilterItemsOptions] = useState([
    {
      value: [1, 4],
      label: "1-4",
      isActive: false,
    },
    {
      value: [5, 9],
      label: "5-9",
      isActive: false,
    },
    {
      value: [10, 14],
      label: "10-14",
      isActive: false,
    },
    {
      value: [15, 19],
      label: "15-19",
      isActive: false,
    },
    {
      value: [20],
      label: "20+",
      isActive: false,
    },
  ]);
  const [resaturantName, setRestaurantName] = useState("");
  const [filteredRestaurantList, setFilteredRestaurantList] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);

  const [tempDateRange, setTempDateRange] = useState();
  const [dateRange, setDateRange] = useState();
  const [overlayClick, setOverlayClick] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");

  const getRestaurantList = async () => {
    // const statusDictionary = { active: 1, inactive: 0 };
    const result = await restaurantServices.getRestaurant(1, {
      query: resaturantName,
    });
    if (result.status === 200) {
      setFilteredRestaurantList(result?.data?.restaurants);
    }
  };

  useEffect(() => {
    if (!isFilterApplied) {
      setIsApplyButtonDisabled(false);
      setRestaurantName("");
    } else {
      setIsApplyButtonDisabled(true);
    }
  }, [isFilterApplied, isActive]);

  useEffect(() => {
    if (resaturantName) {
      getRestaurantList();
    }
  }, [resaturantName]);

  // useEffect(() => {
  //   if (Object.keys(dateRange).length) {
  //     if (!isApplyButtonDisabled) {
  //       setIsApplyButtonDisabled(true);
  //     }
  //   } else {
  //     // setIsApplyButtonDisabled(false);
  //   }
  // }, [dateRange]);

  useEffect(() => {
    if (dateRange || statusFilter) {
      if (!isApplyButtonDisabled) {
        setIsApplyButtonDisabled(true);
      }
    } else {
      // setIsApplyButtonDisabled(false);
    }
  }, [dateRange, statusFilter]);

  const onClickJoinedDate = () => {
    // openDrawer(drawersOrder.dateCustomer);

    setOverlayClick(true);
    setTempDateRange({
      startDate: "",
      endDate: "",
    });
  };

  const onClickDone = async () => {
    const filterObject = {};
    if (activeGuest) {
      filterObject.guests_count = activeGuest;
    }
    if (activeItems) {
      filterObject.items_count = activeItems;
    }
    if (restaurantId) {
      filterObject.restaurant_ids = [restaurantId];
    }
    if (dateRange) {
      filterObject.start_date = dateRange.startDate;
      filterObject.end_date = dateRange.endDate;
    }
    if (statusFilter) {
      filterObject.status = statusFilter;
    }
    onDone(filterObject);
  };

  const onClickItems = (index) => {
    let data = [...filterItemsOptions];
    if (data[index].isActive === true) {
      setActiveItems([]);
      setFilterItemsOptions([
        {
          value: [1, 4],
          label: "1-4",
          isActive: false,
        },
        {
          value: [5, 9],
          label: "5-9",
          isActive: false,
        },
        {
          value: [10, 14],
          label: "10-14",
          isActive: false,
        },
        {
          value: [15, 19],
          label: "15-19",
          isActive: false,
        },
        {
          value: [20],
          label: "20+",
          isActive: false,
        },
      ]);
      if (
        dateRange?.startDate !== "" ||
        resaturantName !== "" ||
        activeGuest !== ""
      ) {
      } else {
        setIsApplyButtonDisabled(false);
      }
    } else {
      data.filter((item) => (item.isActive = false));
      data[index].isActive = !data[index].isActive;
      // let filteredData = data.filter((item) => item.isActive === true);
      setActiveItems(data[index].value);
      setFilterItemsOptions(data);
      if (!isApplyButtonDisabled) {
        setIsApplyButtonDisabled(true);
      }
    }
  };

  const resaturantNameHandler = (resaturantObj) => {
    setRestaurantId(resaturantObj.restaurant_id);
    setTimeout(() => setFilteredRestaurantList([]), 2 * 1000);
    setRestaurantName(resaturantObj.restaurant_name);
    if (!isApplyButtonDisabled) {
      setIsApplyButtonDisabled(true);
    }
  };

  // date range method
  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempDateRange({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  function onChangeApply() {
    if (tempDateRange && tempDateRange?.endDate) {
      setDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
      setOverlayClick(!overlayClick);
    }
  }

  const ref = useOnclickOutside(() => {
    setOverlayClick(false);
  });

  const onChooseActiveType = () => {
    setStatusFilter("active");
  };
  const onChooseCompleteType = () => {
    setStatusFilter("complete");
  };

  const handleCloseBtn = () => {
    setDateRange("");
    // setIsFilterApplied(false);
  }

  return (
    <DrawerFilterOrdersContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-drawer-header">
          <div>
            <FaChevronLeft
              size={20}
              color={"#60646C"}
              className="cursor-pointer"
              onClick={closePanel}
            />
          </div>
          <div className="drawer-label">Filter</div>
          <div
            className="reset-all-btn"
            onClick={() => {
              closePanel();
              setRestaurantName("");
              setActiveGuest();
              setActiveItems([]);
              setFilteredRestaurantList([]);
              let data = filterItemsOptions.map((el) => {
                return { ...el, isActive: false };
              });
              setFilterItemsOptions(data);
              onClickResetFilter();
              setDateRange("");
              setStatusFilter("");
            }}
          >
            Reset All
          </div>
        </div>
        <div className="drawer-content" style={{ marginTop: "35px" }}>
          <div className="filter-group">
            <DatePicker
              label="Date"
              placeholder="Select Date"
              onClick={onClickJoinedDate}
              value={dateRange}
              className="date-text"
              dateRange={true}
              isCloseIcon={true}
              onClickCloseBtn={handleCloseBtn}
            />

            {overlayClick && (
              <PopupCalendarContainer
                className="popup-date-filter-calendar"
                ref={ref}
              >
                <MyDateRange
                  months={2}
                  onChangeDate={onChangeDate}
                  date={dateRange}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 20px",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: "14px" }}>
                    {dateRange?.startDate
                      ? moment(dateRange?.startDate).format("MM/DD/YYYY")
                      : ""}
                    {dateRange && "  to  "}
                    {dateRange?.endDate
                      ? moment(dateRange?.endDate).format("MM/DD/YYYY")
                      : ""}
                  </span>

                  <MyButton
                    className="apply-date"
                    type={
                      tempDateRange?.endDate || dateRange?.endDate
                        ? "red"
                        : "gray"
                    }
                    onClick={onChangeApply}
                  >
                    Apply
                  </MyButton>
                </div>
              </PopupCalendarContainer>
            )}
          </div>

          <div className="location-wrapper">
            <div className="restaurant-title">
              <h6 className="location-label text-bold">Restaurant</h6>
              <h6>
                {resaturantName && (
                  <i
                    className="far fa-times"
                    onClick={() => {
                      setRestaurantName("");
                      setRestaurantId("");
                      setFilteredRestaurantList([]);
                    }}
                  />
                )}
              </h6>
            </div>

            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            <div className="location-input-wrapper">
              <input
                type="text"
                placeholder="Search"
                className="location-input"
                value={resaturantName || ""}
                onChange={(e) => setRestaurantName(e.target.value)}
              />
              {/* {resaturantName !== "" ? (
                <i
                  className="fas fa-times"
                  onClick={() => {
                    setRestaurantName("");
                    setTimeout(() => setFilteredRestaurantList([]), 2 * 1000);
                    if (
                      dateRange?.startDate !== "" ||
                      filterItemsOptions.filter((el) => el?.isActive === true)
                        .length !== 0 ||
                      activeGuest !== ""
                    ) {
                    } else {
                      setIsApplyButtonDisabled(false);
                    }
                  }}
                />
              ) : (
                <i
                  className="fas fa-search"
                  aria-hidden="true"
                  onClick={() => {}}
                />
              )} */}
              {resaturantName !== "" ? (
                ""
              ) : (
                <i
                  className="fas fa-search"
                  aria-hidden="true"
                  onClick={() => {}}
                />
              )}

              {filteredRestaurantList && filteredRestaurantList?.length ? (
                <div className="show-search-result">
                  {filteredRestaurantList.map((searchRestaurant, i) => (
                    <span
                      className="restaurant-title"
                      key={i}
                      onClick={() => resaturantNameHandler(searchRestaurant)}
                    >
                      <p className="restaurant-title-gap text-white">
                        {searchRestaurant?.restaurant_name}
                      </p>
                      <br />
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
            <span className="google-location-wrapper"></span>
          </div>

          <div className="status-filter">
            <div className="status-title">
              <h6 className="text-bold status-label">Status</h6>
              <h6>
                {statusFilter && (
                  <i
                    className="far fa-times"
                    onClick={() => {
                      setStatusFilter("");
                      setFilteredRestaurantList([]);
                    }}
                  />
                )}
              </h6>
            </div>
            <DrawerChooseItem
              className="mb-4"
              title="Active"
              icon={<FaSpinner size={18} />}
              active={statusFilter === "active" ? true : false}
              onChooseItem={() => onChooseActiveType()}
            />
            <DrawerChooseItem
              className=""
              title="Completed"
              icon={<FaCheck size={18} />}
              active={statusFilter === "complete" ? true : false}
              onChooseItem={() => onChooseCompleteType()}
            />
          </div>

          <div className="filter-group">
            <div className="guests-title">
              <h6 className="text-bold guests-label">Guests</h6>
              <h6>
                {activeGuest && (
                  <i
                    className="far fa-times"
                    onClick={() => {
                      setActiveGuest("");
                      setFilteredRestaurantList([]);
                    }}
                  />
                )}
              </h6>
            </div>
            <div className="cards-wrapper">
              {filterGuestOptions.map((_option, _i) => (
                <React.Fragment key={_i}>
                  <StyledStatusItem
                    className={`${
                      activeGuest === _option.value ? "active" : "inactive"
                    } item`}
                    onClick={() => {
                      if (_option.value === activeGuest) {
                        setActiveGuest("");
                        if (
                          dateRange?.startDate !== "" ||
                          resaturantName !== "" ||
                          filterItemsOptions.filter(
                            (el) => el?.isActive === true
                          ).length !== 0
                        ) {
                        } else {
                          setIsApplyButtonDisabled(false);
                        }
                      } else {
                        setActiveGuest(_option.value);
                        if (!isApplyButtonDisabled) {
                          setIsApplyButtonDisabled(true);
                        }
                      }
                    }}
                  >
                    {_option.label}
                  </StyledStatusItem>
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="filter-group">
            <div className="item-title">
              <h6 className="text-bold item-label">Items</h6>
              <h6>
                {activeItems?.length > 0 && (
                  <i
                    className="far fa-times"
                    onClick={() => {
                      setActiveItems([]);
                      setFilteredRestaurantList([]);
                      let data = filterItemsOptions.map((el) => {
                        return { ...el, isActive: false };
                      });
                      setFilterItemsOptions(data);
                    }}
                  />
                )}
              </h6>
            </div>
            <div className="cards-wrapper">
              {filterItemsOptions.map((_option, _i) => (
                <React.Fragment key={_i}>
                  <StyledStatusItem
                    className={`${
                      _option.isActive === true ? "active" : "inactive"
                    } item`}
                    onClick={() => {
                      onClickItems(_i);
                    }}
                  >
                    {_option.label}
                  </StyledStatusItem>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={!isApplyButtonDisabled ? "gray" : "red"}
            className="mt-auto"
            onClick={onClickDone}
            disabled={!isApplyButtonDisabled}
          >
            <b>Apply</b>
          </MyDrawerButton>
          {/* <MyDrawerButton
            type="redColor"
            className="mt-auto"
            onClick={() => {
              closePanel();
              setRestaurantName("");
              setActiveGuest();
              setActiveItems([]);
              setFilteredRestaurantList([]);
              let data = filterItemsOptions.map((el) => {
                return { ...el, isActive: false };
              });
              setFilterItemsOptions(data);
              onClickResetFilter();
              setDateRange("");
              setStatusFilter("");
            }}
          >
            <b>Reset</b>
          </MyDrawerButton> */}
          <MyDrawerButton
            type="redColor"
            className="mt-auto"
            onClick={() => closePanel()}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerFilterOrdersContainer>
  );
}
