import React from "react";
import PropTypes from "prop-types";
// import { FaChevronRight } from "react-icons/fa";
import { colors } from "theme";
import { DatePickerContainer } from "./style";
import moment from "moment";

export default function DatePicker({
  label = "",
  className = "",
  placeholder = "",
  value = "",
  onClick = () => {},
  dateRange = false,
  isCloseIcon = false,
  onClickCloseBtn = () => {}
}) {
  return (
    <DatePickerContainer className={`${className}`}>
      <div className="d-flex justify-content-between align-items-center">
        {label && <h6 className="date-picker-label">{label}</h6>}
        {isCloseIcon && <i className="far fa-times" onClick={onClickCloseBtn} />}
      </div>
      <div className={`date-picker`} onClick={onClick}>
        <h6
          className={`date-picker-value`}
          style={{
            color:
              dateRange && value !== ""
                ? value !== ""
                  ? "#fff"
                  : colors.white3
                : colors.white3,
          }}
        >
          {dateRange && value !== "" ? (
            `${moment(value.startDate).format("MMM DD, YYYY")} - ${moment(
              value.endDate
            ).format("MMM DD, YYYY")}`
          ) : value !== "" ? (
            moment(value).format("MM/DD/YYYY")
          ) : (
            <span className="placeholder-text">{placeholder}</span>
          )}
        </h6>
        {/* <FaChevronRight size={15} color={colors.white3} /> */}
        <i className="far fa-calendar-alt" />
      </div>
    </DatePickerContainer>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
};
