import React, { useState, createContext } from "react";

const DashboardContext = createContext({});

export { DashboardContext };

export const DashboardContextContainer = (props) => {
  const [openDrawers, setOpenDrawers] = useState({});

  const openDrawer = (drawer) => {
    setOpenDrawers({ ...openDrawers, [drawer]: drawer });
  };
  const closeDrawer = (drawer) => {
    // setOpenDrawers(openDrawers.filter((_name) => _name !== drawer));
    let temp = { ...openDrawers };
    delete temp[drawer];
    setOpenDrawers(temp);
  };

  const [periodPopularRestaurants, setPeriodPopularRestaurants] = useState(
    "day"
  );

  const [periodPopularItems, setPeriodPopularItems] = useState("day");

  const [widgetData, setWidgetData] = useState({});

  const [tempDateRange, setTempDateRange] = useState();

  return (
    <DashboardContext.Provider
      value={{
        openDrawers,
        openDrawer,
        closeDrawer,

        periodPopularRestaurants,
        setPeriodPopularRestaurants,

        periodPopularItems,
        setPeriodPopularItems,

        widgetData,
        setWidgetData,

        tempDateRange,
        setTempDateRange,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
