import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { ScaleLoader } from "react-spinners";
import { PhotoUploaderFormDataContainer } from "./style";
import { assetServices } from "services/assetServices";

export function PhotoUploaderFormData({
  src,
  width = "100%",
  height = "100%",
  shape = "circle",
  overlay,
  onUploadSuccess = () => {},
}) {
  const [imageUrl, setImageUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [isHover, setHowering] = useState(false);
  const inputFileRef = useRef();

  const onClickChangePhoto = () => inputFileRef.current.click();

  const [uploadedImage, setUploadedImage] = useState(null);

  const onFileInputChange = async (e) => {
    console.log(e.target.files[0]);
    const payload = createFormData(e);
    // console.log(payload)
    // setUploading(true);
    // uploadPhoto(payload);
    onUploadSuccess(e.target.files[0]);

    // new upload
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setUploadedImage(base64);
  };

  const createFormData = (e) => {
    const formData = new FormData();
    formData.append("asset", e.target.files[0]);
    formData.append("type", "restaurant");
    formData.append("api_key", process.env.REACT_APP_API_KEY);
    return formData;
  };

  const uploadPhoto = async (payload) => {
    const result = await assetServices.uploadToS3(payload);
    console.log("result", result);
    setUploading(false);
    if (result.status == 200) {
      onSuccessfullyUploaded(result.data);
    }
  };

  const onSuccessfullyUploaded = (uploadedResource) => {
    setImageUrl(uploadedResource.file);
    onUploadSuccess(uploadedResource);
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  return (
    <PhotoUploaderFormDataContainer width={width} height={height} shape={shape}>
      <div className="avatar-uploader" onClick={onClickChangePhoto}>
        <div
          className={`photo ${shape === "circle" ? "circle" : "square"}`}
          onMouseOverCapture={() => setHowering(true)}
          onMouseLeave={() => setHowering(false)}
          style={{
            backgroundImage: `url(${uploadedImage || src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <input
            className="file-image-input"
            type="file"
            ref={inputFileRef}
            onChange={onFileInputChange}
            // onChange={handleUpload}
            accept="image/*"
          />
          {/* <img
            src={src}
            alt="avatar"
            className={`user-image ${shape === "circle" ? "circle" : "square"}`}
          /> */}
          {uploading && (
            <div className="uploading-icon">
              <ScaleLoader size={15} color={"white"} />
            </div>
          )}
          {isHover && (
            <div className="photo-hover">
              {overlay || (
                <div className="change-container">
                  <span className="change-text">Change</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PhotoUploaderFormDataContainer>
  );
}

PhotoUploaderFormData.propTypes = {
  uploading: PropTypes.bool,
  onUploadSuccess: PropTypes.func,
  src: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  shape: PropTypes.string,
  overlay: PropTypes.node,
};
