import styled from "@emotion/styled";
import { colors } from "theme";

export const BlockOrderInfoContainer = styled.div`
  min-height: 50px;
  background-color: ${colors.gray2};
  margin-bottom: 25px;
  border-radius: 20px;
  padding: 0 16px !important;
  .card-block {
    padding: 30px 20px 10px;
    .order-status-header {
      margin-bottom: 20px;
      .order-status-icon-name {
        gap: 20px;
        .order-status-icon {
          width: 58px;
          height: 58px;
          padding: 17px;
          border-radius: 41px;
          padding-left: 16px;
          padding-top: 16px;
          // background: linear-gradient(
          //   322deg,
          //   rgb(253, 203, 110) 29%,
          //   rgb(247, 166, 76) 100%
          // );
        }
      }
    }
    .order-restaurant-details {
      gap: 20px;
      img {
        border-radius: 50%;
        width: 58px;
        height: 58px;
      }
    }
    .order-date-details {
      .checked-in-checked-out-div {
        gap: 55px;
      }
    }
    .guets-server-details {
    }
    .card-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
      h5 {
        margin-bottom: 0;
      }
    }
  }
`;

export const PopupOrderContainer = styled.div`
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  background-color: #27282a;
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
  .backlink-to-rest {
    color: ${colors.white};
  }
`;

export const GuestsInfoContainer = styled.div`
  width: 650px;
  height: 241px;
  padding: 29px 35px 29px 35px;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  background-color: #27282a;
  position: absolute;
  z-index: 10;
  /* bottom: 0px; */
  margin-left: -18px;
  margin-top: 15px;
  // :after {
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-width: 10px;
  //   border-style: solid;
  //   border-color: transparent #27282a transparent transparent;
  //   left: 29px;
  //   top: -17px;
  //   transform: rotate(90deg);
  // }
  .guest-profile-info-header {
    gap: 50px;
    .guestprofile-avatar {
      width: 92px;
      height: 92px;
      margin-right: 25px;
      .customer-profile-rating {
        width: 59px;
        height: 23px;
        padding: 2px 9px 3px 10px;
        border-radius: 15.5px;
        background-color: #151617;
        position: absolute;
        margin: -19px 0 0 18px;
        .rating-container {
          display: flex;
          align-items: center;
          width: 40px;
          height: 18px;
          .rating-number {
            font-size: 14px;
            font-weight: bold;
            color: white;
            margin-right: 5px;
            margin-left: 5px;
          }

          .rating-icon {
            i {
              font-size: 14px;
              color: #fdcb6e;
            }
          }
        }
      }
    }
  }
  .guest-check-out-btn {
    button {
      border-radius: 8px;
    }
  }
`;
