import React from "react";
import { ActivityByDayChartContainer } from "./style";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ChartTooltip } from "../../../ChartTooltip";
import moment from "moment";

export const ActivityByDayChart = ({ activityGraphData, fullData, period }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    let filterData =
      fullData &&
      fullData?.filter(
        (el) =>
        payload && payload[0]?.payload?.name === moment(el?.time_stamp)?.format("ddd")
      );
    if (active && payload && payload.length) {
      return (
        <ChartTooltip
          title={`${getPerioText(period)} , ${moment(
            filterData[0]?.time_stamp
          ).format("dddd (DD/YY)")}`}
          importantThing={`${payload[0]?.payload?.activities} customers`}
        />
      );
    }

    return null;
  };

  const getPerioText = (period) => {
    switch (period) {
      case "day":
        return "Today";
      case "week":
        return "Week";
      case "month":
        return "Month";
      case "quarter":
        return "Quarter";
      case "year":
        return "Year";
      default:
        return null;
    }
  };


  return (
    <ActivityByDayChartContainer>
      <ResponsiveContainer width="100%" height="100%" ba>
        <BarChart
          data={activityGraphData}
          margin={{ top: 0, right: 6, bottom: 0, left: 0 }}
        >
          <CartesianGrid vertical={false} stroke="#28292a" />
          <XAxis dataKey="name"  />
          <YAxis
            tick={{ stroke: "#545556" }}
            axisLine={false}
            tickCount={4}
            allowDecimals={false}
            tickLine={false}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Bar
            dataKey="activities"
            fill="#1fc086"
            barSize={25}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </ActivityByDayChartContainer>
  );
};
