import React, { useContext } from "react";
import { ActiveOederActionsModalContainer } from "../ActiveOrderActionsModal/style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { useHistory } from "react-router-dom";
import { OrdersContext } from "context/OrdersContext";

const PaidOrderActionsModal = ({
  isActive = false,
  closePanel = () => {},
  onClickDeleteFullOrder = () => {},
  onClickPartitialRefund = () => {},
  onClickFullRefund = () => {},
  setPartialRefundModals = () => {},
  partialRefundModals = {},
}) => {
  const { orderDetailData } = useContext(OrdersContext);
  const history = useHistory();
  return (
    <ActiveOederActionsModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"30%"}
      left={"38%"}
      // position="fixed"
      zIndex={9999}
    >
      <DrawerContainer>
        <div className="active-order-actions">
          <div
            className="clickable-row"
            onClick={() => {
              onClickPartitialRefund();
              // closePanel();
            }}
          >
            Partial Refund
          </div>
          <div
            className="clickable-row"
            onClick={() => {
              onClickFullRefund();
              // closePanel();
            }}
          >
            Full Refund
          </div>
          <div
            className="clickable-row"
            onClick={() => {
              onClickDeleteFullOrder();
              // closePanel();
            }}
          >
            <i className="fas fa-trash-alt mr-3" /> Delete Entire Order
          </div>
          <MyDrawerButton
            className="active-order-action-cancel"
            type="redColor"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </ActiveOederActionsModalContainer>
  );
};

export default PaidOrderActionsModal;
