import React, { useContext } from "react";
// import { DefaultMenu } from 'assets/images/menu'
import PropTypes from "prop-types";
// import { Draggable } from "react-beautiful-dnd";
import {
  FaPen,
  FaTrash,
  FaBars,
  FaEllipsisH,
  FaStar,
  FaUtensils,
} from "react-icons/fa";
import { MenuCardContainer, PopupContainer } from "./style";
import { colors } from "theme";
import { OverlayTrigger } from "react-bootstrap";
import { Image } from "react-bootstrap";
// import { DefaultMenu } from "assets/img";
import defaultFood from "assets/img/default-food.jpg";
import { mocks } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import { Draggable } from "react-beautiful-dnd";
import AnalyzeFood from "./AnalyzeFood";
import FoodControl from "./FoodControl";

export default function ItemCard({
  food = {},
  isDraggable = false,
  onClickEdit = () => {},
  onClickDelete = () => {},
  isActionButtonsShowing = true,
  onClickItem = () => {},
  newIndex,
  setShowFoodItem = () => {},
  setIsShowItemDrawerOpen = () => {},
}) {
  const { setFoodId } = useContext(RestaurantsContext);

  const content = (
    <PopupContainer className="popup-wrapper">
      <div className="popup-item" onClick={onClickEdit}>
        <FaPen className="popup-item-icon" color="white" />
        <span>Edit</span>
      </div>
      <div className="popup-item" onClick={onClickDelete}>
        <FaTrash className="popup-item-icon" color="white" />
        <span>Delete</span>
      </div>
    </PopupContainer>
  );

  function handleClickFoodId(foodId) {
    setFoodId(foodId);
  }

  return isDraggable ? (
    <Draggable draggableId={`${food.id}`} index={newIndex} key={food.id}>
      {(provided, snapshot) => (
        <MenuCardContainer
          id="restaurant-menu-card"
          onClick={() => {
            if (!isActionButtonsShowing) {
              onClickItem(food);
            }
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            className={
              snapshot.isDragging ? "menu-card drag-card" : ""
            }
          >
            <div className="menu-card">
              <div className="menu-card-left">
                <div className="inner-block-menu-left">
                  {isDraggable && (
                    <div className="card-col icon">
                      <div
                        className="icon-container"
                        {...provided.dragHandleProps}
                      >
                        <FaBars className="icon-drag" size={20} />
                      </div>
                    </div>
                  )}
                  <div
                    className={`card-col image-place ${
                      isDraggable ? "image-left-part" : ""
                    }`}
                  >
                    {food.photo ? (
                      <div
                        className={`food-photo ${
                          food.kind === 1 ? "contain" : ""
                        }`}
                        style={{
                          backgroundImage: `url(${food.photo.file})`,
                          backgroundSize: food.kind === 1 ? "contain" : "cover",
                        }}
                        onClick={() => {
                          setShowFoodItem(food);
                          setIsShowItemDrawerOpen((prev) => !prev);
                        }}
                      />
                    ) : (
                      <div
                        className="utensils"
                        onClick={() => {
                          setShowFoodItem(food);
                          setIsShowItemDrawerOpen((prev) => !prev);
                        }}
                      >
                        <FaUtensils
                          className="no-image-class"
                          size={70}
                          color="rgba(255,255,255,0.19)"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="name-block main-description">
                  <span className="title">{food.title}</span>
                  <AnalyzeFood
                    price={food.price}
                    rating={food.reviews_count}
                    averageRating={food.yum_percent}
                    calories={
                      food.nutrition
                        ? JSON.parse(food.nutrition)?.[0]?.calories
                        : null
                    }
                  />
                  <span className="description">
                    <p className="text-description">{food.description}</p>
                  </span>
                  <FoodControl
                    foodItem={food}
                    isActionButtonsShowing={isActionButtonsShowing}
                    handleClickFoodId={handleClickFoodId}
                    content={content}
                  />
                </div>
              </div>
            </div>
          </div>
        </MenuCardContainer>
      )}
    </Draggable>
  ) : (
    <div>
      <MenuCardContainer
        id="restaurant-menu-card"
        onClick={() => {
          if (!isActionButtonsShowing) {
            onClickItem(food);
          }
        }}
      >
        <div className="menu-card">
          <div className="menu-card-left">
            <div className="inner-block-menu-left">
              {isDraggable && (
                <div className="card-col icon" width="fit-content">
                  <div className="icon-container">
                    <FaBars className="icon-drag" size={20} />
                  </div>
                </div>
              )}
              <div
                className={`card-col image-place ${
                  isDraggable ? "image-left-part" : ""
                }`}
              >
                {food.photo ? (
                  <div
                    className={`food-photo ${food.kind === 1 ? "contain" : ""}`}
                    style={{
                      backgroundImage: `url(${food.photo.file})`,
                      backgroundSize: food.kind === 1 ? "contain" : "cover",
                    }}
                    onClick={() => {
                      setShowFoodItem(food);
                      setIsShowItemDrawerOpen((prev) => !prev);
                    }}
                  />
                ) : (
                  <div
                    className="utensils"
                    onClick={() => {
                      setShowFoodItem(food);
                      setIsShowItemDrawerOpen((prev) => !prev);
                    }}
                  >
                    <FaUtensils
                      className="no-image-class"
                      size={70}
                      color="rgba(255,255,255,0.19)"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="name-block main-description">
              <span
                className="title"
                onClick={() => {
                  setShowFoodItem(food);
                  setIsShowItemDrawerOpen((prev) => !prev);
                }}
              >
                {food.title}
              </span>
              <AnalyzeFood
                price={food.price}
                rating={food.reviews_count}
                averageRating={food.yum_percent}
                calories={
                  food?.nutrition && food?.nutrition !== 'undefined'
                    ? JSON.parse(food?.nutrition)?.[0]?.calories
                    : null
                }
              />
              <span className="description">
                <p className="text-description">{food.description}</p>
              </span>
              <FoodControl
                foodItem={food}
                isActionButtonsShowing={isActionButtonsShowing}
                handleClickFoodId={handleClickFoodId}
                content={content}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
              />
            </div>
          </div>
        </div>
        {/* <div className={"menu-card"}>
          <div className="menu-card-left">
            <div className="menu-card-img-wrapper">
              {isDraggable && (
                <div className="card-col icon">
                  <div className="icon-container">
                    <FaBars className="icon-drag" size={20} />
                  </div>
                </div>
              )}
              <div className="card-col card-img">
                <Image src={food.photo ? food.photo.file : defaultFood} />
              </div>
            </div>
            <div className="card-col card-description">
              <h6>{food?.title}</h6>
              <p className="mb-0">{food?.description}</p>
            </div>
          </div>
          <div className="card-col card-price">
            <span>{"$" + (food?.price || 0)}</span>
          </div>
          <div className="card-col card-cal">
            <span>{food?.cal || 0} cal</span>
          </div>
          <div className="card-col card-service">
            {food?.dietary ? (
              food?.dietary?.map((_tagId, _i) => (
                <span key={_i}>{mocks.dietaryTags[_tagId].title}</span>
              ))
            ) : (
              <span>No Dietary tags</span>
            )}
          </div>
          {food.yum_percent ? (
            <div className="card-col card-reviews_count">
              <span>{food.yum_percent}</span>
              <FaStar size={15} color={colors.yellow} className="ml-2" />
            </div>
          ) : (
            <div className="card-col">
              <span>No Reviews</span>
            </div>
          )}
          <div className="card-col card-edit" id="card-edit-wrapper">
            {isActionButtonsShowing ? (
              <OverlayTrigger
                container={() => document.getElementById("card-edit-wrapper")}
                placement="bottom-end"
                popperConfig={{ className: "fffffffffff" }}
                trigger="click"
                overlay={content}
                rootClose
              >
                <FaEllipsisH
                  color="white"
                  className="cursor"
                  onClick={() => handleClickFoodId(food?.id)}
                />
              </OverlayTrigger>
            ) : (
              <></>
            )}
          </div>
        </div> */}
      </MenuCardContainer>
    </div>
  );
}

ItemCard.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  newIndex: PropTypes.number,
};
