import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerApplyDiscountAreYouSureContainer = styled(MyDrawerBottom)`
  .drawer-bottom-content {
    height: 440px;
    overflow-y: auto;
    .sort-item {
      padding: 21px 35px 21px 34px;
      border-radius: 16px;
      border: solid 1px #4d5053;
      background-color: rgba(0, 0, 0, 0);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.34px;
      text-align: center;
      color: #fff;
      margin-top: 15px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: gainsboro;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    text-align: left;
  }
`;

export const Label = styled.div`
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => (props.weight ? props.weight : "bold")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  display: flex;
  align-items: center;
  gap: 2px;
  .weight-type {
    font-weight: normal !important;
  }
`;
