import React, { useState, useContext, useEffect, useRef } from "react";
import { VerifyNewPhoneNumberModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { MobileImage } from "assets/img";
import NumberFormat from "react-number-format";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import ReactCodeInput from "react-verification-code-input";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { adminServices } from "services";
import { MyAccountContext } from "context/MyAccountContext";

const VerifyNewPhoneNumberModal = ({
  isActive = false,
  closePanel = () => {},
  phoneNumber,
  setMobileModals,
  setMyAccountNotification,
  setPhoneNumber,
}) => {
  const { getMyInfo } = adminServices;
  const inputRef = useRef();
  const { myAccount, setMyAccount } = useContext(MyAccountContext);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [resendingOTP, setResendingOTP] = useState(false);
  useEffect(() => {
    if (isActive) {
      inputRef.current.__clearvalues__();
    }
  }, [isActive]);

  const sendEnteredCode = async (enteredCode) => {
    let string = phoneNumber?.replace(/[^a-zA-Z0-9]+/g, "");
    let payload = {
      phone_number: string,
      otp_code: 4444,
    };
    customAxios.patch(`/admin/me/update/phone`, payload).then((res) => {
      if (res?.status === 200) {
        setIsFilled(true);
        setIsOtpValid(true);
        setTimeout(() => {
          setMobileModals({
            enterNewNumber: false,
            verifyNumber: false,
            numberVerifiedSuccess: true,
          });
          setPhoneNumber("");
          getMyInfo()
            .then((res) => {
              if (res.ok === true) {
                setMyAccount(res.data);
              } else {
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setIsOtpValid(false);
          setIsFilled(false);
        }, 1000);
      } else {
        setIsFilled(true);
        setIsOtpValid(false);
      }
    });
  };

  const resendOtpHandler = () => {
    customAxios
      .patch(`/admin/me/update/request`, {
        update: "phone",
        phone_number: phoneNumber,
      })
      .then((res) => {
        if (res.status === 200) {
          setMyAccountNotification({
            message: "We’ve just sent SMS with confirmation code.",
            type: "active-success",
            table: `Check  <span style="font-weight:bold; color:#7d7e7f; margin-left:0">
    ${phoneNumber}
  </span>`,
          });
        } else {
        }
      });
  };

  const isValid = () => {
    return isFilled ? (isOtpValid ? "valid" : "invalid") : "";
  };

  return (
    <VerifyNewPhoneNumberModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-number">
          <div className="modal-image">
            <div className="round-circle">
              <img src={MobileImage} alt="" />
            </div>
          </div>
          <div className="modal-title-new-number">Verify new phone number</div>

          <div className="phone-info-meta">
            We sent the code to: <b>{phoneNumber}</b>
          </div>

          <div className="verify-phone-number">
            <ReactCodeInput
              className={`verify-code-input ${isValid()}`}
              fields={4}
              onComplete={sendEnteredCode}
              fieldWidth={78}
              fieldHeight={78}
              ref={inputRef}
            />
          </div>

          <div className="did-not-get-it-container">
            <span className="did-not-get-it-title">Didn’t get it?</span>
            <div
              className="btn-resend"
              onClick={() => {
                resendOtpHandler();
              }}
              aria-hidden="true"
            >
              <span
                className={`resend-title ${
                  resendingOTP ? "inactive" : "active"
                }`}
              >
                Resend
              </span>
            </div>
          </div>

          <div className="verify-actions">
            <MyDrawerButton
              type="redColor"
              onClick={() => {
                closePanel();
              }}
            >
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </VerifyNewPhoneNumberModalContainer>
  );
};

export default VerifyNewPhoneNumberModal;
