import styled from "@emotion/styled";

export const TabsListByPeriodContainer = styled.div`
  background-color: #1b1c1d;
  border-radius: 7px;
  padding-top: 23px;
  display: flex;
  flex-direction: column;

  .period-control-container {
    justify-content: center;
    margin-bottom: 28px;
    background-color: #27282a;
    border-radius: 20px;
    margin-bottom: 25px !important;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
    margin: 0 16px;
    .period-option  {
      width: calc(100% - 20px);
    }
  }

  .tabs-container {
    width: 100%;
    display: flex;
    background-color: #27282a;
    margin-bottom: 24px !important;
    border-radius: 30px;
    width: calc(100% - 32px);
    margin: 0 16px;
    .tab {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.4);
      /* background-color: rgba(0, 0, 0, 0.09); */
      /* background-color: #27282a; */
      /* border-bottom: 3px solid transparent; */
      font-weight: 500;
      &:hover {
        cursor: pointer;
      }
      &.tab-active {
        /* border-bottom-color: #ed2626; */
        background-color: #ed2626;
        color: white;
        border-radius: 30px;
      }
    }
  }

  .tabs-content-list {
    display: flex;
    flex-direction: column;
  }

  .tab-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 25px;
    opacity: 0.3;
    font-weight: 600;
    .res-label {
      margin-left: 5rem;
    }
  }
`;
