import React, { useContext } from "react";
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { mocks } from "static/staticData";
import { DrawerStatusTableContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export default function DrawerStatusTable({
  isActive = false,
  closePanel = () => {},
}) {
  const { postTable, setPostTable } = useContext(RestaurantsContext);

  const onChangeStatus = (status) => {
    setPostTable({ ...postTable, status: status });
    closePanel();
  };

  return (
    <DrawerStatusTableContainer
      isActive={isActive}
      closePanel={closePanel}
      containerClassName="status-drawer"
      top={"30%"}
      left={"38%"}
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">Status</h2>
        <div className="drawer-status--options mb-auto mt-4">
          {Object.values(mocks.statuses)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((_status, _i) => {
              return (
                <DrawerChooseItem
                  key={_i}
                  className="mb-4"
                  title={_status.title}
                  active={postTable?.status === _status.value}
                  onChooseItem={() => onChangeStatus(_status.value)}
                />
              );
            })}
        </div>
        <MyDrawerButton
          type="redColor"
          className="mt-auto"
          onClick={closePanel}
        >
          Cancel
        </MyDrawerButton>
      </DrawerContainer>
    </DrawerStatusTableContainer>
  );
}
