import React, { useState, useEffect } from "react";
import { RestaurantsSignUpsContainer } from "./style";
import { AnalyticPeriod } from "../../AnalyticPeriod";
import { RestaurantsChart } from "./RestaurantsChart";
import { RestaurantsFacts } from "./RestaurantsFacts";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";

export const RestaurantsSignUps = () => {
  const [period, setPeriod] = useState("day");
  const [dateRange, setDateRange] = useState();
  const [signUpsData, setSignUpsData] = useState();
  const [signUpsStats, setSignUpsStats] = useState([
    { title: "New Restaurants", value: 0, change: 0 },
    { title: "Menu Items", value: 0, change: 0 },
    { title: "New Staff Accounts", value: 0, change: 0 },
  ]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    let url;
    if (dateRange) {
      url = `/analytics-restaurant/stats?period=${period}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
    } else {
      url = `/analytics-restaurant/stats?period=${period}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          // console.log(res.data);
          let array = [];
          res.data &&
            res.data.graph &&
            res.data.graph.map((el) => {
              let obj = {
                name: `${moment(el?.time_stamp).format("LT")}`,
                signUps: el?.count,
              };
              array.push(obj);
            });
          setSignUpsData(array || []);
          setGraphData(res?.data?.graph || []);
          setSignUpsStats([
            {
              title: "New Restaurants",
              value: res?.data?.stats?.restaurants?.current || 0,
              change: res?.data?.stats?.restaurants?.change || 0,
            },
            {
              title: "Menu Items",
              value: res?.data?.stats?.items?.current || 0,
              change: res?.data?.stats?.items?.change || 0,
            },
            {
              title: "New Staff Accounts",
              value: res?.data?.stats?.staff?.current || 0,
              change: res?.data?.stats?.staff?.change || 0,
            },
          ]);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [period, dateRange]);

  const onTimePeriodChange = (time) => {
    setPeriod(time);
  };

  return (
    <RestaurantsSignUpsContainer>
      <div className="restaurants-sign-up-top-container">
        <h4 className="restaurants-sign-up-top-title">Restaurant Sign Ups</h4>
        <div className="chart-date">
          {/* {dateRange !== undefined &&
            `${moment(dateRange?.startDate).format(
              "dddd MMMM DD, YYYY"
            )} - ${moment(dateRange?.endDate).format("dddd MMMM DD, YYYY")}`} */}
          {dateRange?.startDate && dateRange?.endDate
            ? `${moment(dateRange.startDate).format("dddd MMMM DD, YYYY")}
                 - ${moment(dateRange.endDate).format("dddd MMMM DD, YYYY")}`
            : period === "day"
            ? moment().format("dddd MMMM DD, YYYY")
            : period === "week"
            ? `${
                moment()
                  .subtract(6, "days")
                  .format("dddd MMMM DD, YYYY")
                  .split("T")[0]
              }
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "month"
            ? `${moment()
                .subtract(30, "days")
                .format("dddd MMMM DD, YYYY")}
                 - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "quarter"
            ? `${moment()
                .subtract(91, "days")
                .format("dddd MMMM DD, YYYY")}
                      - ${moment().format("dddd MMMM DD, YYYY")}`
            : period === "year"
            ? `${moment()
                .subtract(1, "year")
                .format("dddd MMMM DD, YYYY")}
                - ${moment().format("dddd MMMM DD, YYYY")}`
            : moment()
                .format("dddd, MMMM DD, YYYY")
                .split("T")[0]}
        </div>
        <AnalyticPeriod
          className="restaurants-period"
          onChange={onTimePeriodChange}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
      <div className="restaurants-chart-container">
        <RestaurantsChart
          signUpsData={signUpsData}
          period={period}
          graphData={graphData}
        />
        <RestaurantsFacts total={signUpsStats} />
      </div>
    </RestaurantsSignUpsContainer>
  );
};
