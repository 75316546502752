import React, { useState, useContext } from "react";
// import { DefaultMenu } from 'assets/images/menu'
import PropTypes from "prop-types";
// import { Draggable } from "react-beautiful-dnd";
import { FaCheck, FaPen, FaSpinner, FaTrash } from "react-icons/fa";
import { OrderItemContainer, PopupContainer } from "./style";
import { OverlayTrigger } from "react-bootstrap";
import { colors } from "theme";
import moment from "moment";
import ItemsList from "./ItemsList";
import GuestsList from "./GuestsList";
import { OrdersContext } from "context/OrdersContext";
import { useEffect } from "react";

export default function OrderItem({
  order = {},
  header = false,
  onClickEdit = () => {},
  onClickDelete = () => {},
  selectedPage,
  searchInput,
}) {
  const {
    isOrdersStatusSortEnabled,
    isOrdersDateTimeSortEnabled,
    setIsOrdersStatusSortEnabled,
    setIsOrdersDateTimeSortEnabled,
    ordersData,
    setOrdersData,
  } = useContext(OrdersContext);

  const [isGuestsListShowing, setIsGuestsListShowing] = useState(false);
  const [isItemsListShowing, setIsItemsListShowing] = useState(false);

  const content = (
    <PopupContainer className="popup-wrapper">
      <div className="popup-item" onClick={() => onClickEdit(order)}>
        <FaPen className="popup-item-icon" color="white" />
        <span content="Edit" size={14} weight={600}>
          Edit
        </span>
      </div>
      <div className="popup-item" onClick={onClickDelete}>
        <FaTrash className="popup-item-icon" color="white" />
        <span content="Delete" size={14} weight={600}>
          Delete
        </span>
      </div>
    </PopupContainer>
  );

  const toggleStatusSort = () => {
    setIsOrdersStatusSortEnabled(!isOrdersStatusSortEnabled);
    let sortData = [...ordersData];
    if (isOrdersStatusSortEnabled) {
      sortData.sort((a, b) => (a.status === 1 || a.status === 3 ? -1 : 1));
    } else {
      sortData.sort((a, b) => (a.status === 2 ? -1 : 1));
    }
    setOrdersData(sortData);
  };

  // const toggleDateAndTimeSort = () => {
  //   setIsOrdersDateTimeSortEnabled(!isOrdersDateTimeSortEnabled);
  //   let sortData = [...ordersData];
  //   if (isOrdersDateTimeSortEnabled) {
  //     let arrayData = sortData
  //       .map((order) => {
  //         return { ...order, started_at: new Date(order.started_at) };
  //       })
  //       .sort((a, b) => b.started_at - a.started_at);
  //     setOrdersData(arrayData);
  //   } else {
  //     let arrayData = sortData
  //       .map((order) => {
  //         return { ...order, started_at: new Date(order.started_at) };
  //       })
  //       .sort((a, b) => a.started_at - b.started_at);
  //     setOrdersData(arrayData);
  //   }
  // };

  const toggleDateAndTimeSort = () => {
    let sortData = [...ordersData];
    if (isOrdersDateTimeSortEnabled) {
      sortData.sort((a, b) => new Date(b.started_at) - new Date(a.started_at));
    } else {
      sortData.sort((a, b) => new Date(a.started_at) - new Date(b.started_at));
    }
    setOrdersData(sortData);
  };

  // useEffect(() => {
  //   setIsOrdersDateTimeSortEnabled(!isOrdersDateTimeSortEnabled);
  // }, [selectedPage]);

  const getHighlightedText = (text, highlight) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <b>{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <OrderItemContainer id="restaurant-staff-card">
      <div className={`staff-card ${header && "is-header"}`}>
        <div className="card-col flex2">
          <div className="restaurent-profile">
            {header ? (
              <img
                src={order?.restaurant_photo}
                height={48}
                width={48}
                alt=""
                style={{ display: "none" }}
              />
            ) : (
              <img
                src={order?.restaurant_photo}
                height={48}
                width={48}
                alt=""
              />
            )}
            {header ? (
              <h6
                style={{
                  fontSize: "14px",
                  color: "rgb(255 255 255 / 33%)",
                }}
              >
                Restaurant
              </h6>
            ) : (
              <h6 className="restaurant-name">{order?.restaurant_name}</h6>
            )}
          </div>
        </div>
        <div className="card-col">
          {header ? (
            <h6
              className="d-flex"
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
                cursor: "pointer",
              }}
              onClick={() => toggleStatusSort()}
            >
              Status &nbsp;
              {isOrdersStatusSortEnabled ? (
                <i
                  className="far fa-angle-up cursor-pointer"
                  style={{ fontSize: "20px", color: "rgb(255 255 255 / 33%)" }}
                  // onClick={() => toggleStatusSort()}
                />
              ) : (
                <i
                  className="far fa-angle-down cursor-pointer"
                  style={{ fontSize: "20px", color: "rgb(255 255 255 / 33%)" }}
                  // onClick={() => toggleStatusSort()}
                />
              )}
            </h6>
          ) : (
            <h6 style={{ width: "30%" }}>
              {order.status === 1 || order.status === 3 ? (
                <div className="order-badge">
                  <div>{/* <FaSpinner color={colors.orange} /> */}</div>
                  <div style={{ color: colors.orange }}> Active</div>
                </div>
              ) : (
                <div className="order-badge">
                  <div>{/* <FaCheck color={colors.green} /> */}</div>
                  <div style={{ color: colors.green }}>Completed</div>
                </div>
              )}
            </h6>
          )}
        </div>
        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Order#
            </h6>
          ) : (
            <div className="order-wrapper">
              <h6 className="order-number">
                {searchInput !== "" &&
                searchInput !== null &&
                searchInput !== undefined
                  ? getHighlightedText(order.token, searchInput)
                  : order.token}
              </h6>
              <i class="far fa-angle-right" />
            </div>
          )}
        </div>
        <div className="card-col" style={{ flex: 1.5 }}>
          {header ? (
            <h6
              className="d-flex"
              onClick={() => {
                setIsOrdersDateTimeSortEnabled(!isOrdersDateTimeSortEnabled);
                toggleDateAndTimeSort();
              }}
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
                cursor: "pointer",
              }}
            >
              Date & Time &nbsp;
              {isOrdersDateTimeSortEnabled ? (
                <i
                  className="far fa-angle-up cursor-pointer"
                  style={{ fontSize: "20px", color: "rgb(255 255 255 / 33%)" }}
                />
              ) : (
                <i
                  className="far fa-angle-down cursor-pointer"
                  style={{ fontSize: "20px", color: "rgb(255 255 255 / 33%)" }}
                />
              )}
            </h6>
          ) : (
            // <h6>{moment(order.started_at).format("MM/DD/YYYY, HH:MM A")}</h6>
            <h6>{moment(order.started_at).format("MMM DD, YYYY, HH:MM A")}</h6>
          )}
        </div>
        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              # of Guests
            </h6>
          ) : (
            <h6
              style={{ position: "relative", width: "50%" }}
              onMouseOver={() => setIsGuestsListShowing(true)}
              onMouseLeave={() => setIsGuestsListShowing(false)}
            >
              {isGuestsListShowing && (
                <GuestsList
                  guestsList={order.guests.length ? order.guests : []}
                />
              )}
              {order.guests_count}
            </h6>
          )}
        </div>
        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              # of Items
            </h6>
          ) : (
            <h6
              style={{ position: "relative", width: "50%" }}
              onMouseOver={() => setIsItemsListShowing(true)}
              onMouseLeave={() => setIsItemsListShowing(false)}
            >
              {isItemsListShowing && (
                <ItemsList itemsList={order.items.length ? order.items : []} />
              )}
              {order.items_count}
            </h6>
          )}
        </div>
        {/* <div className="card-col">
          <h6>{header ? "# of Items" : order.items_count}</h6>
        </div> */}
        {/* <div className="card-col">
          <h6>{header ? "Fees" : `$ 0`}</h6>
        </div> */}
        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Total
            </h6>
          ) : (
            <h6 className="total">
              {order.total_price === null ? "-" : `$${order.total_price}`}
            </h6>
          )}
        </div>
      </div>
    </OrderItemContainer>
  );
}

OrderItem.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  selectedPage: PropTypes.number,
};
