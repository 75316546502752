import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext } from "react";
import { drawersStaff } from "static/staticData";
import DrawerDeleteStaffConfirm from "./DrawerDeleteStaffConfirm";
import { DrawerDeleteStaffContainer } from "./style";

export default function DrawerDeleteStaff({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawers,
    closeDrawer,
    openDrawer,
    setStaffCloseDrawer,
    staffCloseDrawer,
    postStaff,
  } = useContext(RestaurantsContext);

  return (
    <DrawerDeleteStaffContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left main-title">
          Remove Staff
        </h2>
        {postStaff?.serving_status === undefined ? (
          <p className="text-white text-left text-font">
            Are you sure you want to remove?
          </p>
        ) : (
          <p className="text-white text-left text-font">
            Are you sure you want to remove{" "}
            <span className="text-bold">{`${postStaff?.first_name} ${postStaff?.last_name}`}</span>
            ?
          </p>
        )}

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold move-item-btn"
            // onClick={() => openDrawer(drawersStaff.staffDeleteConfirm)}
            onClick={() => {
              setStaffCloseDrawer({
                ...staffCloseDrawer,
                staffDeleteConfirm: true,
              });
            }}
          >
            Remove
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold"
            onClick={() => {
              setStaffCloseDrawer({
                ...staffCloseDrawer,
                staffDelete: false,
              });
            }}
            // onClick={closePanel}
          >
            Keep
          </MyDrawerButton>
        </div>
      </DrawerContainer>
      <DrawerDeleteStaffConfirm
        isActive={staffCloseDrawer.staffDeleteConfirm}
        closePanel={() => {
          setStaffCloseDrawer({
            ...staffCloseDrawer,
            staffDeleteConfirm: false,
          });
        }}
      />
    </DrawerDeleteStaffContainer>
  );
}
