import React, {useContext} from "react";
import { DrawerDeleteCategoryContainer } from "./style";
// import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import { DrawerContainer } from "components/styledStyle";
import Box from "components/Box";
import SingleText from "components/SingleText";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";

const DrawerDeleteCategory = ({
  isActive,
  closePanel = () => {},
  chosenCategory = {},
  onClickDeleteCategory = () => {},
  countCatgoryItemState,
}) => {
  const { openDrawer, closeDrawer, postMenu } = useContext(RestaurantsContext);

  return (
    <DrawerDeleteCategoryContainer
      isActive={isActive}
      closePanel={closePanel}
      top="30%"
    >
      <DrawerContainer>
        {/* <BottomWithIconWrapper hasIcon={true}> */}
        <div className="drawer-bottom-content w-100">
          <Box
            is="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginBottom={30}
          >
            <SingleText size={22} weight={500} marginBottom={10}>
              Delete Category
            </SingleText>
            <SingleText
              align="center"
              size={14}
              lineHeight={1.5}
              spacing="0.27px"
            >
              Are you sure you want to delete category{" "}
              <b>{countCatgoryItemState?.name}</b>
            </SingleText>
          </Box>
          <div className="bottom-btns">
            <MyDrawerButton type="red" onClick={onClickDeleteCategory}>
              Delete
            </MyDrawerButton>
            <br />
            <MyDrawerButton className="mt-1" type="gray" onClick={closePanel}>
              Keep
            </MyDrawerButton>
          </div>
        </div>
        {/* </BottomWithIconWrapper> */}
      </DrawerContainer>{" "}
    </DrawerDeleteCategoryContainer>
  );
};

export default DrawerDeleteCategory;
