import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import MyDragger from "components/Elements/MyDragger";
import { DrawerContainer } from "components/styledStyle";
import arrayMove from "array-move";
import { RestaurantsContext } from "context/RestaurantsContext";
import { restaurantServices } from "services/restaurantServices";
import React, { useContext, useEffect } from "react";
import { FaBars, FaPen, FaTimes, FaPlus, FaChevronRight } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { DrawerMenusContainer } from "./style";
import { useParams } from "react-router";
import moment from "moment";
import { colors } from "theme";
import Box from "components/Box";
import SingleText from "components/SingleText";
import { formatBusinessHoursToday } from "services/functions";

export default function DrawerMenus({
  isActive = false,
  closePanel = () => {},
}) {
  const { id } = useParams();
  const {
    openDrawer,
    menus,
    setMenus,
    setPostMenu,
    postRestaurant,
    menuId,
    setMenuId,
    menuCloseDrawer,
    setMenuCloseDrawer,
    setAvailabilityDrawerType,
    setSelectedMenucatogories,
    drawerMenuAdd,
    setDrawerMenuAdd,
  } = useContext(RestaurantsContext);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    console.log({ oldIndex, newIndex }, arrayMove(menus, oldIndex, newIndex));
    let sortedMenus = arrayMove(menus, oldIndex, newIndex);
    setMenus(sortedMenus);
    const result = await restaurantServices.sortMenus(id, {
      sort: sortedMenus.map((_menu) => _menu.id),
    });
    if (result.status === 200) {
    }
  };

  const onClickEditMenu = (menu) => {
    // console.log(menu)
    setAvailabilityDrawerType("edit");
    setPostMenu(menu);
    setMenuId(menu?.id);
    // openDrawer(drawersMenu.menuEdit);
    setMenuCloseDrawer({
      ...menuCloseDrawer,
      menuEdit: true,
    });
    setSelectedMenucatogories(menu);
  };

  const onClickMenuAdd = () => {
    setAvailabilityDrawerType("add");
    setPostMenu(null);
    setSelectedMenucatogories(null);
    // openDrawer(drawersMenu.menuAdd);
    setDrawerMenuAdd({
      ...drawerMenuAdd,
      menuAdd: true,
    });
  };

  const getMenuTime = (schedule) => {
    if (schedule.length > 0) {
      const day = new Date().getDay() - 1;
      let finalMenuTimeRender;
      schedule.map((_time) => {
        if (_time.day === day) {
          const timeFrom = moment
            .utc(+_time.times[0][0] * 1000)
            .format("hh:mm A");
          const timeTo = moment
            .utc(+_time.times[0][1] * 1000)
            .format("hh:mm A");
          finalMenuTimeRender = `${timeFrom} - ${timeTo}`;
        }
        return;
      });
      return finalMenuTimeRender;
    } else {
      // return <p>-</p>;
    }
  };

  return (
    <DrawerMenusContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaTimes
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Edit Menus</div>
          <div>
            <FaPlus
              color={colors.redColor}
              size={20}
              onClick={onClickMenuAdd}
              className="cursor-pointer"
            />
          </div>
        </div>
        <MyDragger
          renderItem={(item) => {
            return (
              // <div className="edit-menu-list">
              //   <div className="bar-title-inline">
              //     <div>
              //       <FaBars
              //         className="icon-drag"
              //         color="rgba(255, 255, 255, 0.3)"
              //         size={20}
              //       />
              //     </div>
              //     <div>
              //       <div>{item?.name}</div>
              //       <div>{getMenuTime(item?.availability)}</div>
              //     </div>
              //   </div>
              //   <div>
              //     <FaPen
              //       size={20}
              //       cursor="pointer"
              //       color="white"
              //       onClick={() => onClickEditMenu(item)}
              //     />
              //   </div>
              // </div>
              <Box padding="7px 0" zIndex="1001" cursor="pointer">
                <Box
                  className={`menu-item ${item.status !== 1 ? "disabled" : ""}`}
                  is="div"
                >
                  <FaBars
                    className="icon-drag"
                    color="rgba(255, 255, 255, 0.1)"
                    size={20}
                  />
                  <Box
                    is="div"
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    marginRight="auto"
                    marginLeft={19}
                  >
                    <SingleText
                      size={14}
                      weight={600}
                      spacing="-0.34px"
                      marginBottom={8}
                    >
                      {item.name}
                    </SingleText>
                    <SingleText size={14} spacing="-0.34px">
                      {formatBusinessHoursToday(item.availability)}
                    </SingleText>
                  </Box>
                  <div
                    className="status-and-look"
                    onClick={() => onClickEditMenu(item)}
                  >
                    <div
                      className={`status ${item.status === 1 ? "active" : ""}`}
                    />
                    <FaChevronRight
                      size={16}
                      color="rgba(255, 255, 255, 0.3)"
                    />
                  </div>
                </Box>
              </Box>
            );
          }}
          items={menus}
          onSortEnd={(param) => onSortEnd(param)}
        />
        <div className="bottom-btns">
          {/* <MyDrawerButton
            type="gray"
            className="mt-auto text-bold"
            onClick={onClickMenuAdd}
          >
            Add Menu
          </MyDrawerButton> */}
          <MyDrawerButton type="red" className="text-bold" onClick={closePanel}>
            Done
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerMenusContainer>
  );
}
