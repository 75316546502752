import { NoAvatar } from "assets/img";
import MyButton from "components/Elements/MyButton";
import { CustomersContext } from "context/CustomersContext";
import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import {
  FaCheckCircle,
  FaMapMarkerAlt,
  FaPhone,
  FaAt,
  FaMapMarkedAlt,
  FaCreditCard,
  FaTimesCircle,
} from "react-icons/fa";
import { drawersCustomers } from "static/staticData";
import { colors } from "theme";
import { ProfileCardInfoContainer, InfoContainer } from "./style";
import moment from "moment";

export default function ProfileCardInfo({
  className = "",
  customerProfilerData,
}) {
  const { openDrawer } = useContext(CustomersContext);
  const onClickEditCustomer = () => {
    openDrawer(drawersCustomers.editCustomer);
  };

  const statusBackgroundColorRenderer = (status) => {
    console.log(status);
    switch (status) {
      case 1:
        return colors.green;
      case 0:
        return colors.gray;
      case 9:
        return "#ed2626";
      case -1:
        return "ed2626";
      default:
        return null;
    }
  };

  const statusTextRenderer = (status) => {
    switch (status) {
      case 1:
        return "Active";
      case 0:
        return "Inactive";
      case 9:
        return "Banned";
      case -1:
        return "Deleted";
      default:
        return null;
    }
  };

  return (
    <ProfileCardInfoContainer className={`${className} profile-info`}>
      <div className="profile-info-header d-flex justify-content-between align-items-start">
        <div className="profile-info-avatar">
          <Image
            src={
              customerProfilerData?.avatar
                ? `${customerProfilerData?.avatar?.file}`
                : NoAvatar
            }
            width={92}
          />
          <div className="customer-profile-rating">
            <div className="rating-container">
              <span className="rating-number">
                {customerProfilerData.avg_rating || 0}
              </span>
              <div className="rating-icon">
                <i className="fas fa-star" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start mr-auto">
          <h3 className="profile-info-name text-bold">
            {customerProfilerData &&
              `${
                customerProfilerData?.first_name
                  ? customerProfilerData?.first_name
                  : "No"
              } ${
                customerProfilerData?.last_name
                  ? customerProfilerData?.last_name
                  : "Name"
              }`}

            <span
              className="customer-status"
              style={{
                color:
                  customerProfilerData?.status == 9
                    ? "#ed2626"
                    : customerProfilerData?.status == 1
                    ? colors.green
                    : customerProfilerData?.status == 0
                    ? colors.gray
                    : customerProfilerData?.status == -1
                    ? "#ed2626"
                    : colors.green,
              }}
            >
              {statusTextRenderer(customerProfilerData?.status)}
            </span>
          </h3>
          <h6 className="profile-info-join-date">
            <span className="title-input">Joined:</span>{" "}
            {moment(customerProfilerData?.joined_date).format(
              "MMMM DD, YYYY,LT"
            )}{" "}
          </h6>
          <h6 className="profile-info-location">
            <FaMapMarkerAlt className="mr-3 location-mark" />
            <span>{customerProfilerData?.last_location}</span>
          </h6>
        </div>
        <MyButton
          className="edit-btn"
          type="gray"
          onClick={onClickEditCustomer}
        >
          Edit
        </MyButton>
      </div>
      <div className="profile-info-row">
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-mobile-alt" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Mobile Number</h6>
              <h5 className="profile-info-col-value">
                {customerProfilerData && `${customerProfilerData?.phone_number}`}
                <FaCheckCircle className="ml-3" color={colors.green} />
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-envelope" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Email Address</h6>
              <h5 className="profile-info-col-value">
                {customerProfilerData && customerProfilerData?.email
                  ? `${customerProfilerData.email}`
                  : "-"}
                {customerProfilerData &&
                customerProfilerData?.email_verified === true ? (
                  <FaCheckCircle className="ml-3" color={colors.green} />
                ) : (
                  <FaTimesCircle className="ml-3" color={colors.redColor} />
                )}
              </h5>
            </div>
          </InfoContainer>
        </div>
      </div>
      <div className="profile-info-row">
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-street-view" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Addresses on File</h6>
              <h5 className="profile-info-col-value">{`${
                customerProfilerData?.locations
                  ? `${customerProfilerData.locations} ${
                      customerProfilerData.locations === 1 ||
                      customerProfilerData.locations === 0
                        ? "address"
                        : "addresses"
                    }  `
                  : "-"
              } `}</h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <FaCreditCard />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Payment Methods</h6>
              <h5 className="profile-info-col-value">
                {customerProfilerData &&
                  `${customerProfilerData?.cards}  ${
                    customerProfilerData.cards === 1 ||
                    customerProfilerData.cards === 0
                      ? "card"
                      : "cards"
                  }`}
              </h5>
            </div>
          </InfoContainer>
        </div>
      </div>
    </ProfileCardInfoContainer>
  );
}
