import { NoAvatar } from "assets/img";
import MyButton from "components/Elements/MyButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { FaCheckCircle, FaMapMarkerAlt, FaPhone, FaAt } from "react-icons/fa";
import { drawersOverall } from "static/staticData";
import { colors } from "theme";
import {
  ProfileCardInfoContainer,
  InfoContainer,
} from "../ProfileCardInfo/style";
import moment from "moment";
import NumberFormat from "react-number-format";
import EditRestaurantModal from "./Modals/EditRestaurantModal";

export default function ProfileCardInfoForPendingStatus({
  className = "",
  profile = {},
}) {
  const [isEditRestaurantModalOpen, setIsRestaurantModalOpen] = useState(false);

  const {
    openDrawer,
    postRestaurant,
    setPostRestaurant,
    setCuisineGlobalData,
  } = useContext(RestaurantsContext);

  useEffect(() => {
    if (postRestaurant) {
      setCuisineGlobalData(postRestaurant?.cuisines);
    }
  }, [postRestaurant]);

  const onClickEditRestaurant = () => {
    setPostRestaurant(profile);
    // openDrawer(drawersOverall.editProfile);
    setIsRestaurantModalOpen(!isEditRestaurantModalOpen);
  };

  const statusRenderer = (status) => {
    switch (status) {
      case 1:
        return <span className={`active`}>Active</span>;
      case 2:
        return <span className={`inactive`}>Inactive</span>;
      case 3:
        return null;
      default:
        return null;
    }
  };

  return (
    <ProfileCardInfoContainer className={`${className} profile-info`}>
      <div className="profile-info-header d-flex justify-content-between align-items-start">
        <div className="profile-info-avatar">
          <Image
            className="rounded-circle profile-picture"
            src={profile?.photo?.file ? profile?.photo?.file : NoAvatar}
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div className="d-flex flex-column align-items-start mr-auto">
          <h3 className="profile-info-name text-bold">{profile.title}</h3>
          <h6 className="profile-info-join-date">
            Join Request: &nbsp;
            <div className="join-req">
              {profile.joined &&
                moment(profile.joined).format("MMMM DD, YYYY, HH:MM A")}
            </div>
            {statusRenderer(profile?.status)}
          </h6>
          <h6 className="profile-info-location">
            <div>
              <FaMapMarkerAlt className="mr-3" />
            </div>
            <div>{profile.address}</div>
          </h6>
        </div>
        <MyButton
          type="gray"
          className="edit-btn-for-pending-restaurant"
          onClick={onClickEditRestaurant}
        >
          Edit
        </MyButton>
      </div>

      <hr className="line" />

      <div className="profile-info-row">
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-mobile-alt" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Phone</h6>
              <h5 className="profile-info-col-value">
                <div className="mobile-number-input">
                  <div className="drawer-input-container">
                    <NumberFormat
                      format={"+# (###) ###-####"}
                      className={`single-ant-format-input`}
                      value={profile.phone_number || ""}
                      onValueChange={(e) => {}}
                      onBlur={() => {}}
                      placeholder={"Set Phone"}
                      readOnly
                    />
                  </div>
                  {/* <FaCheckCircle className="ml-3" color={colors.green} /> */}
                </div>
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-envelope" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Email Address</h6>
              <h5 className="profile-info-col-value">
                {profile.email}
                {/* <FaCheckCircle className="ml-3" color={colors.green} /> */}
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-dollar-sign" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Price Tier</h6>
              <h5 className="profile-info-col-value">
                {profile.price_level &&
                  Array.from({ length: profile.price_level }, (v, i) => "$")}
              </h5>
            </div>
          </InfoContainer>
        </div>
        <div className="profile-info-col">
          <InfoContainer>
            <h6 className="opacity6">
              <i className="fas fa-utensils" />
            </h6>
            <div className="profile-info-col-content">
              <h6 className="profile-info-col-label">Cuisine</h6>
              <h5 className="profile-info-col-value">
                {profile.cuisines &&
                  profile.cuisines.length > 0 &&
                  profile.cuisines.join(", ")}
              </h5>
            </div>
          </InfoContainer>
        </div>
      </div>
      <EditRestaurantModal
        isActive={isEditRestaurantModalOpen}
        closePanel={() => {
          setIsRestaurantModalOpen(!isEditRestaurantModalOpen);
        }}
      />
    </ProfileCardInfoContainer>
  );
}
