import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerNutritionsContainer = styled(MyDrawerRight)`
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .show-more-expand {
    color: #ed2626;
    margin-top: 20px;
  }

  .bottom-btns {
    margin-top: 5rem;
  }

  .input-label {
    opacity: 0.3;
  }

  .nutrition-input {
    width: 100%;
    background: transparent;
    color: #fff;
    border: 0;
    /* border-bottom: 1px solid #323334; */
    &:focus-visible {
      outline: unset;
      outline-offset: unset;
    }
    &::placeholder {
      color: #ed2626;
      font-weight: 500;
    }
  }

  .circle-minus-icon {
    position: relative;
    top: 15px;
  }

  .bottom-line {
    padding-bottom: 9px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .apply-btn {
    background: #ed2626;
    border: 0;
    height: 50px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
  }
`;
