import styled from "@emotion/styled";
import { colors } from "theme";

export const AuthPageContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: ${colors.gray1};
  .login-left,
  .login-right {
    min-width: 50%;
    .login-form {
      width: 350px;
      .login-with-email-icon {
        margin-bottom: 70px;
      }
      .icon-login {
        font-size: 40px;
        margin-bottom: 15px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .two-factor-title {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
      }
      .login-with-email-title {
        margin-bottom: 62px;
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
      }
      .select-one-option {
        opacity: 0.6;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.34px;
        color: #fff;
        margin-top: 10px;
      }
      .one-time-verification-msg {
        opacity: 0.6;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.34px;
        color: #fff;
        margin-top: 10px;
        b {
          font-weight: bold;
          opacity: 1;
        }
      }
      .type-the-code {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        margin-top: 25px;
        margin-bottom: 15px;
      }
      .verify-code-input {
        width: 100% !important;
        > div {
          display: flex;
          justify-content: space-between;
          input {
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 7px;
            background-color: ${colors.gray2};
            -webkit-transition: background-color 300ms linear;
            -ms-transition: background-color 300ms linear;
            transition: background-color 300ms linear;
            font-family: "Roboto";
            font-weight: bold;
            font-size: 36px;
            letter-spacing: 0.68px;
            color: white;
            caret-color: white;
            &:focus {
              border: solid 1px rgba(255, 255, 255, 1);
            }
          }
        }
        &.invalid {
          > div {
            input {
              border: 2px solid ${colors.redColor};
            }
          }
        }
        &.valid {
          > div {
            input {
              background: ${colors.green};
              -webkit-transition: background-color
                ${({ animationDuration }) => animationDuration}ms linear;
              -ms-transition: background-color
                ${({ animationDuration }) => animationDuration}ms linear;
              transition: background-color
                ${({ animationDuration }) => animationDuration}ms linear;
            }
          }
        }
      }
      .did-not-get-it-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 32px;

        .did-not-get-it-title {
          font-size: 15px;
          font-weight: 500;
          color: white;
          letter-spacing: 0;
          margin-right: 7px;
          opacity: 0.5;
        }

        .btn-resend {
          cursor: pointer;
          .resend-title {
            font-size: 16px;
            font-weight: bold;

            &.active {
              color: ${colors.redColor};
            }

            &.inactive {
              color: ${colors.gray5};
            }
          }
        }
      }
      .otp-verify-cancel-btn {
        margin-top: 28px;
        button {
          margin-left: -12px;
        }
      }
      .selection-card {
        width: 350px;
        height: 94px;
        padding: 16px;
        border-radius: 20px;
        // border: solid 1px rgba(255, 255, 255, 0.1);
        background-color: #1b1c1d;
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        .selection-icon {
          width: 70px;
          height: 50px;
          // padding: 6px 2.5px 20px 6.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 50%;
          .emoji {
            font-size: 28px;
            font-style: normal;
            line-height: 0.86;
            -webkit-letter-spacing: -0.67px;
            -moz-letter-spacing: -0.67px;
            -ms-letter-spacing: -0.67px;
            text-align: center;
            color: #fff;
          }
        }
        .card-info {
          display: flex;
          flex-direction: column;
          .card-selection-title {
            // font-family: SFProText;
            font-size: 17px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.41px;
            color: #fff;
            margin-bottom: 2px;
          }
          .card-meta {
            opacity: 0.5;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #fff;
          }
        }
      }
      .two-factor-btns {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 32px;
      }
      .login-with-email-content {
        margin: 10px 0 0 0;
        width: 100%;

        .auth-input-container {
          margin-bottom: 35px;
        }
      }
      .forgot-password {
        margin-top: 31px;
        margin-bottom: 20px;

        .restore-pass-text {
          opacity: 0.5;
          font-size: 13px;
        }
        .restore-pass {
          color: #ed2626;
          font-weight: bold;
          margin-left: 7px;
          cursor: pointer;
          font-size: 13px;

          i {
            color: #ed2626;
          }
        }
      }
    }
  }
`;
