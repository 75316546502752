import React, { useState, useContext, useEffect } from "react";
import { DrawerSetPromoCodeModalContainer } from "./style";
import { RestaurantsContext } from "context/RestaurantsContext";
import { DrawerContainer } from "components/styledStyle";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

const SetPromoCodeModal = ({
  isActive = false,
  closePanel = () => {},
  onDoneSaveProfile = () => {},
  globalPromoCode = false,
}) => {
  const { id } = useParams();

  const [isPromoAvailable, setIsPromoAvailable] = useState(false);
  const { setPromoCode, localPromoCode, setLocalPromoCode } = useContext(
    RestaurantsContext
  );
  // const [localPromoCode, setLocalPromoCode] = useState();
  const [genPromoCode, setGenPromoCode] = useState();

  const length = [6, 8, 10, 12, 16];

  function randomDigitCode() {
    return Math.floor(Math.random() * length.length);
  }

  function generatePromoCode(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setGenPromoCode(result);
    setLocalPromoCode(result);
    // console.log(result)
    // return result;
  }

  function handleOnClick() {
    setPromoCode(localPromoCode);
    checkPromoCodeAvailability();
    // closePanel()
  }

  function checkPromoCodeAvailability() {
    let url;
    if (!globalPromoCode) {
      url = `/coupon/availability?coupon_code=${localPromoCode}&restaurant_id=${id}`;
    } else {
      url = `/coupon/availability?coupon_code=${localPromoCode}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          closePanel();
        } else {
          setIsPromoAvailable(true);
          setLocalPromoCode("");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <DrawerSetPromoCodeModalContainer
      isActive={isActive}
      closePanel={closePanel}
      // top="25rem"
      // bottom="22rem"
      left="0"
      top="20%"
      position="absolute"
    >
      <DrawerContainer>
        <div className="set-promo-code-container">
          <div className="modal-title">Set Promo Code</div>
          <div className="description">
            Customers will apply code to activate discount.
            <br />
            Maximum 16 characters.
          </div>
          <div
            className={
              isPromoAvailable ? "generate-code-error" : "generate-code"
            }
          >
            <div className="enter-code">
              <input
                type="text"
                value={localPromoCode || ""}
                placeholder="Enter Code"
                onChange={(e) => setLocalPromoCode(e.target.value)}
              />
            </div>
            <div
              className="generate-random cursor-pointer"
              onClick={() => {
                randomDigitCode();
                generatePromoCode(length[randomDigitCode()]);
              }}
            >
              Generate Random
            </div>
          </div>

          {isPromoAvailable ? (
            <div className="error-message">
              Oops! This code can not be applied due system already
              <br />
              have the same promo code. Try another code, please.
            </div>
          ) : (
            <></>
          )}

          {/* {isPromoAvailable === true ? (
            <button
              type="submit"
              className={
                isPromoAvailable ? "done-btn-disable" : "done-btn-enable"
              }
              onClick={handleOnClick}
              disabled={isPromoAvailable ? true : false}
            >
              Done
            </button>
          ) : (
            <button
              type="submit"
              className={
                isPromoAvailable ? "done-btn-disable" : "done-btn-enable"
              }
              onClick={handleOnClick}
            >
              Done
            </button>
          )} */}

          <button
            type="submit"
            className={!localPromoCode ? "done-btn-disable" : "done-btn-enable"}
            onClick={handleOnClick}
            disabled={!localPromoCode  ? true : false}
          >
            Done
          </button>

          <div className="cancel-btn cursor-pointer" onClick={closePanel}>
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerSetPromoCodeModalContainer>
  );
};

export default SetPromoCodeModal;
