import React, {useContext} from "react";
import { LogoutModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import { clearJwt } from "services/jwt";
import { customAxios } from "helpers/custom-helpers/customAxios";

const PromoStatusModal = ({ isActive = false, closePanel = () => {}, getPromoCodeData }) => {
  const {
    updatePromoCodeDetails,
    setUpdatePromoCodeDetails,
    promoCodeStatus,
    setCouponData,
    setStatusActiveNotification,
  } = useContext(RestaurantsContext);



const editPromoCode = () => {

const configUpdatePromoCode = {
  status: updatePromoCodeDetails.status == 1 ? 2 : 1
}

  customAxios
  .patch(`/coupon/${updatePromoCodeDetails?.id}`, configUpdatePromoCode)
  .then((res) => {
    if (res.status === 200) {
      customAxios
        .get(`/coupon?status=${promoCodeStatus}&scope=2`)
        .then((res) => {
          setCouponData(res?.data);
          closePanel();
        })
        .catch((err) => {
          console.error(err);
        });
      if (updatePromoCodeDetails.status === 2) {
        setStatusActiveNotification({
          message: "Promo Code Active.",
          type: "promo-active",
          table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${updatePromoCodeDetails?.name} • ${updatePromoCodeDetails?.code}</span>`,
        });
      } else {
        setStatusActiveNotification({
          message: "Promo Code Inactive.",
          type: "promo-inactive",
          table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">${updatePromoCodeDetails?.name} • ${updatePromoCodeDetails?.code}</span>`,
        });
      }
    }
  })
  .catch((err) => {
    console.error(err);
  });
}







  return (
    <LogoutModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-number">
          <div className="modal-image">
            <div className="round-circle">
              <i
                className="fas fa-question"
                style={{
                  fontSize: "24px",
                  color: "#fff",
                }}
              />
            </div>
          </div>
          <div className="modal-title-new-number">Are you sure?</div>
          <div className="modal-title-new-number-meta">
          {updatePromoCodeDetails.name} will be moved to {updatePromoCodeDetails?.status == 1 ? "Inactive" : "Active"} Promo Codes.
          </div>
          <div className="new-number-action-btns">
            <MyDrawerButton type="red" onClick={() => editPromoCode()}>
              <b>Yes, move to {updatePromoCodeDetails?.status == 1 ? "Inactive" : "Active"}</b>
            </MyDrawerButton>
            <MyDrawerButton
              type="gray"
              onClick={() => {
                closePanel();
              }}
            >
              <b>Keep</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </LogoutModalContainer>
  );
};

export default PromoStatusModal;
