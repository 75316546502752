import React, { useState } from "react";
import { Label } from "./style";

const CustomizationList = ({ customizationDetails = {} }) => {
  const [isDressingOpened, setIsDressingOpend] = useState(false);

  return (
    <>
      <div
        style={{ marginTop: "30px" }}
        className="collpased-scroll d-flex justify-content-between align-items-center"
        onClick={() => {
          setIsDressingOpend(!isDressingOpened);
        }}
      >
        <Label>{customizationDetails?.name}</Label>
        <div className="angle-down-div  d-flex align-items-center">
          <Label size="14px" weight="500" opacity="0.4" marginRight="11px">
            {customizationDetails?.required ? "" : "Optional"}
          </Label>

          {isDressingOpened ? (
            <i className="fas fa-chevron-up opacity6" />
          ) : (
            <i className="fas fa-chevron-down opacity6" />
          )}
        </div>
      </div>
      {isDressingOpened ? (
        <div className="dressing-item-list d-flex flex-column w-100">
          {customizationDetails &&
            customizationDetails?.options.map((el) => (
              <div
                className="dressing-item cursor-pointer"
                style={{
                  border: el.isSelected
                    ? "solid 1px #ed2626"
                    : "solid 1px #4d5053",
                }}
              >
                {el?.name}
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default CustomizationList;
