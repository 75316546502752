import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useContext, useEffect, useState } from "react";
import { drawersMenu } from "static/staticData";
import { DrawerMoveItemsContainer, Label } from "./style";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";

export default function DrawerMoveItems({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    closeDrawer,
    menus,
    menuId,
    setSelectedMenu,
    setFoods,
    selectedMenu,
    menuCloseDrawer,
    setMenuCloseDrawer,
  } = useContext(RestaurantsContext);

  const [targetMenuId, setTargetMenuId] = useState();

  const [isDressingOpened, setIsDressingOpend] = useState(false);

  const [selected, setSelected] = useState(null);

  const [selectOptionbox, setSelectOptionbox] = useState();

  const onClickMenu = (_menu) => {
    setTargetMenuId(_menu.id);
  };

  const { id } = useParams();

  useEffect(() => {}, [id]);

  function handleMoveMenu() {
    customAxios
      .delete(
        `/restaurant/${id}/menu/${menuId}/move?menu_id=${menuId}&target_id=${targetMenuId}`
      )
      .then((res) => {
        getCategoryData();
        getRestaurantProfile();
        closePanel();
        setMenuCloseDrawer({
          ...menuCloseDrawer,
          menuEdit: false,
          deleteMenu: false,
          moveItems: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getCategoryData() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          // res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
          res?.data
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
    }
  };

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const optionSelect = (optionSelect) => {
    setSelectOptionbox(optionSelect?.id);
  };

  return (
    <DrawerMoveItemsContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left main-title">
          Move items to
        </h2>
        {menus &&
          menus.map((_menu, _i) => {
            return (
              <>
                {menuId === _menu?.id ? null : (
                  <DrawerChooseItem
                    key={_i}
                    title={_menu.name}
                    onChooseItem={() => onClickMenu(_menu)}
                    className={`mb-5 drawer-choose--option ${
                      _menu?.id === targetMenuId ? "white" : ""
                    }`}
                  />
                )}
              </>
            );
          })}
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="move-btn text-bold"
            // onClick={() => closeDrawer(drawersMenu.moveItems)}
            onClick={handleMoveMenu}
          >
            Move & Delete Menu
          </MyDrawerButton>
          <MyDrawerButton
            type="transparent"
            className="back-btn text-bold"
            // onClick={() => closeDrawer(drawersMenu.moveItems)}
            onClick={() => {
              setMenuCloseDrawer({
                ...menuCloseDrawer,
                moveItems: false,
              });
            }}
          >
            Back
          </MyDrawerButton>
        </div>

        {/*@TODO new drawer UI */}
        {/* <div className="item-customization-wraapper">
          <div>
            <i
              className="far fa-angle-left cursor-pointer"
              onClick={closePanel}
              style={{ fontSize: "30px", color: "#fff" }}
            />
          </div>
          <div className="customization-title">Move 24 Items</div>
          <div className="customization-name">
            Choose a category from <br /> another menu.
          </div>
          {
            <div className="dressing-item-list d-flex flex-column w-100">
              {menus &&
                menus.map((el, i) => {
                  return (
                    <div key={i}>
                      <div
                        style={{ marginTop: "30px" }}
                        className="collpased-scroll d-flex justify-content-between align-items-center"
                        onClick={() => {
                          setIsDressingOpend(!isDressingOpened);
                        }}
                      >
                        <Label>{el?.name}</Label>
                        <div className="angle-down-div  d-flex align-items-center">
                          <div onClick={() => toggle(i)}>
                            {selected == i ? (
                              <i
                                className="fas fa-chevron-up cursor-pointer"
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              <i
                                className="fas fa-chevron-down cursor-pointer"
                                style={{ color: "#fff" }}
                              />
                            )}
                          </div>{" "}
                        </div>
                      </div>

                      {selected == i ? (
                        <div>
                          {el &&
                            el.categories &&
                            el.categories.map((category, _i) => {
                              return (
                                <div
                                  key={_i}
                                  className="dressing-item cursor-pointer"
                                  style={{
                                    border:
                                      category?.id === selectOptionbox
                                        ? "solid 1px #ED2626"
                                        : "solid 1px #4d5053",
                                  }}
                                  onClick={() => {
                                    optionSelect(category); 
                                  }}
                                  
                                >
                                  {category?.name}
                                </div>
                              );
                            })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          }
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="move-btn text-bold"
              onClick={handleMoveMenu}
            >
              Move & Delete Menu
            </MyDrawerButton>
            <MyDrawerButton
              type="transparent"
              className="back-btn text-bold"
              onClick={() => {
                setMenuCloseDrawer({
                  ...menuCloseDrawer,
                  moveItems: false,
                });
              }}
            >
              Back
            </MyDrawerButton>
          </div>
        </div> */}
      </DrawerContainer>
    </DrawerMoveItemsContainer>
  );
}
