import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerStatusFoodContainer = styled(MyDrawerRight)`
  .drawer-bottom {
    padding-top: 33px;
    .top-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 29px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
`;
