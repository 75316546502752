import React, { useRef, useState, useEffect } from "react";
import { MySelectAdminDropdownContainer } from "./style";
import { useOutsideClick } from "services/functions";
import { customAxios } from "helpers/custom-helpers/customAxios";

export default function MySelectAdminDropdown({
  className = "",
  label = "",
  setValue,
  value,
  close,
  isActive
}) {
  const locationDropdownRef = useRef();
  const [states, setStates] = useState([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  useEffect(() => {
    customAxios.get(`admin/list`).then((res) => {
      if (res.status === 200) {
        let data = res.data.map((el) => ({
          ...el,
          name: `${el?.name1} ${el?.name2}`,
        }));
        setStates(data);
      } else {
      }
    });
  }, []);

  const addLocationHandler = (location) => {
    let data = value.filter((el) => el.id === location.id);
    if (data.length === 0) {
      let tempData = [...value];
      tempData.push(location);
      setValue(tempData);
    }
  };

  useOutsideClick(locationDropdownRef, () => {
    if (isDropDownOpen) {
      setIsDropDownOpen(false);
    }
  });

  const deleteSelectedLocation = (location) => {
    let data = [...value];
    data = data.filter((el) => el.name !== location.name);
    setValue(data);
  };

  useEffect(() => {
    if (close && isDropDownOpen) {
      setIsDropDownOpen(false);
    }
  }, [close]);

  useEffect(() => {
    if(!isActive){
      setIsDropDownOpen(false);
    }
  },[isActive])
  
  return (
    <MySelectAdminDropdownContainer
      className={className}
      ref={locationDropdownRef}
    >
      <div className="label-div">
        {label}{" "}
        {value && value.length ? (
          <i
            className="fas fa-times"
            style={{
              color: "#ed2626",
              fontSize: "14px",
              lineHeight: "1.29",
              cursor: "pointer",
            }}
            onClick={() => {
              setValue([]);
              setIsDropDownOpen(false);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="state-input">
        {value.length ? (
          <div className="state-name-render-div">
            {value.map((el) => {
              return (
                <div className="state-abbr-name">
                  {el?.name}{" "}
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      deleteSelectedLocation(el);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="select-text">Select Admin</div>
        )}
        <div>
          {isDropDownOpen ? (
            <i
              className="fas fa-angle-up"
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <i
              className="fas fa-angle-down"
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      {isDropDownOpen ? (
        <div className="states-list-div">
          {states.map((el, index) => {
            return (
              <div
                className="state-name-div"
                onClick={() => {
                  addLocationHandler(el);
                }}
                key={index}
              >
                <div className="name-container">
                  {value.some((e) => e.id === el.id) ? (
                    <div className="selected">
                      <i className="fas fa-check" />
                    </div>
                  ) : (
                    <div className="unselected"></div>
                  )}

                  <div className="state-name"> {el?.name}</div>
                </div>
                <div className="state-abbreviation"></div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </MySelectAdminDropdownContainer>
  );
}
