import React from "react";
import PropTypes from "prop-types";
import { MySwitchContainer } from "./style";
import { Switch } from "@material-ui/core";
export default function MySwitch({ checked = false, onChange = () => {} }) {
  const onToggleSwitch = (e) => {
    onChange(e.target.checked);
  };
  return (
    <MySwitchContainer>
      <Switch value={checked} checked={checked} onChange={onToggleSwitch} />
    </MySwitchContainer>
  );
}

MySwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
