import React, { useContext } from "react";
import { DrawerCustomersContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import DrawerDeleteCustomersConfirmation from "./DrawerDeleteCustomersConfirmation";
import { CustomersContext } from "context/CustomersContext";
import { drawersCustomers } from "static/staticData";

export default function DrawerDeleteCustomers({
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
}) {
  const { openDrawers, openDrawer, closeDrawer } = useContext(CustomersContext);

  return (
    <DrawerCustomersContainer
      isActive={isActive}
      closePanel={closePanel}
      containerClassName="delete-customer-drawer"
    >
      <DrawerContainer nopadding style={{ paddingBottom: 0 }}>
        <h2 className="drawer-title text-white text-center">Delete Customer</h2>
        <div className="set-status-wrapper mt-auto">
          <h2 className="text-white text-left">Delete Customer</h2>
          <p className="text-white text-left">
            Are you sure you want to delete <b>selected Customer</b>?
          </p>
          <p className="delete-customer-undone">This cannot be undone.</p>
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mb-5 keep-btn"
              onClick={closePanel}
            >
              Keep Customer
            </MyDrawerButton>
            <MyDrawerButton
              type="gray"
              className="delete-btn"
              onClick={() =>
                openDrawer(drawersCustomers.deleteCustomerConfirmationScreen)
              }
            >
              Delete Customer
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
      <DrawerDeleteCustomersConfirmation
        isActive={
          !!openDrawers[drawersCustomers.deleteCustomerConfirmationScreen]
        }
        closePanel={() =>
          closeDrawer(drawersCustomers.deleteCustomerConfirmationScreen)
        }
        onDone={() => {
          closeDrawer(drawersCustomers.deleteCustomerConfirmationScreen);
          onDone();
        }}
      />
    </DrawerCustomersContainer>
  );
}
