/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from "react";
import { RestaurantProfileTablesContainer } from "./style";
import { Link, useHistory, useParams } from "react-router-dom";
import query from "query-string";
import MyButton from "components/Elements/MyButton";
import { TableLayout } from "./TableLayout";
import { TableList } from "./TableList";
import { restaurantServices } from "services";
import { RestaurantsContext } from "context/RestaurantsContext";
import NoFilterData from "assets/img/empty-tables.png";
import { drawersMenu } from "static/staticData";
import DrawerTable from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerTable";
import DrawerElement from "components/restaurantProfile/RestaurantProfileMenu/Drawers/Floor/DrawerElement";
import { floor } from "lodash";

export function RestaurantProfileTables() {
  const {
    isRestaurantTableLayoutVisible,
    setIsRestaurantTableLayoutVisible,
    openDrawer,
    openDrawers,
    closeDrawer,
    setPostTable,
    restaurant,
    fetchFloors,
    setFloorId,
  } = useContext(RestaurantsContext);

  const [isdDrawerElementOpen, setIsDrawerElementOpen] = useState(false);
  const history = useHistory();
  // const mode = query.parse(history.location.search).mode;
  const { id } = useParams();
  const [tables, setTables] = useState([]);
  const [editable, setEditable] = useState(false);

  const getFloors = async (id) => {
    const result = await fetchFloors(id);
    if (result.data) {
      setFloorId(result?.data[0]?.id);
    }
  };

  const getTables = async () => {
    const result = await restaurantServices.getTables(id, {
      page: 1,
      page_size: "",
      sort: 3,
    });
    if (result.status === 200) {
      setTables(result.data);
      // console.log(result.data)
    }
  };

  useEffect(() => {
    // if (isRestaurantTableLayoutVisible) {
    getTables();
    getFloors(id);
    setIsRestaurantTableLayoutVisible(true);
    // }
  }, [id.tables]);

  useEffect(() => {
    if (editable) {
      setIsRestaurantTableLayoutVisible(true);
    }
  }, [editable]);

  useEffect(() => {
    if (
      `/restaurant-detail/${id}?view=tables` ===
      window.location.pathname + window.location.search
    ) {
      history.push(`/restaurant-detail/${id}?view=tables&mode=layout`);
    }
  }, []);

  function redirectEditTable() {
    history.push(`/restaurant-detail/${id}?view=tables&mode=layout`);
  }

  return (
    <RestaurantProfileTablesContainer>
      {tables && tables.total_count > 0 && (
        <div className="tables-navigation">
          {restaurant?.status === 3 ? null : (
            <div className="nav-line-to-back">
              <Link to="/restaurants" className="backlink-to-rest">
                Restaurants
              </Link>
              <span className="arrow-icon-container mx-3">
                <i className="fas fa-angle-right" />
              </span>
              <span className="current-restaurant">{restaurant?.title}</span>
            </div>
          )}

          <div
            style={{
              display: restaurant?.status === 3 ? "contents" : "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {!editable && (
              <div className="tables-tabs">
                <div
                  className={`tab-item ${isRestaurantTableLayoutVisible &&
                    "selected"}`}
                  onClick={() => {
                    history.push(
                      `/restaurant-detail/${id}?view=tables&mode=layout`
                    );
                    setIsRestaurantTableLayoutVisible(true);
                  }}
                >
                  Layout
                </div>
                <div
                  className={`tab-item ${!isRestaurantTableLayoutVisible &&
                    "selected"}`}
                  onClick={() => {
                    history.push(
                      `/restaurant-detail/${id}?view=tables&mode=list`
                    );
                    setIsRestaurantTableLayoutVisible(false);
                  }}
                >
                  List
                </div>
              </div>
            )}
            {editable ? (
              <div className="table-btns">
                <MyButton
                  className="btn-add-item mr-3"
                  type="gray"
                  onClick={() => setEditable(false)}
                >
                  Cancel
                </MyButton>
                <MyButton
                  className="btn-add-item mr-3"
                  type="red"
                  onClick={() => setEditable(false)}
                >
                  Done
                </MyButton>
              </div>
            ) : (
              <div className="table-btns">
                {/* eslint-disable no-unused-expressions */}
                <MyButton
                  className="btn-add-item mr-3"
                  type="gray"
                  onClick={() => {
                    setEditable(true), redirectEditTable();
                  }}
                >
                  Edit Tables
                </MyButton>
              </div>
            )}
          </div>
        </div>
      )}
      {tables && tables?.total_count > 0 ? (
        isRestaurantTableLayoutVisible ? (
          <TableLayout editBtn={editable} tablesData={tables}/>
        ) : (
          <TableList />
        )
      ) : (
        <div className="empty-container">
          <img src={NoFilterData} alt="" />
          <div className="empty-screen">There are no table yet.</div>
          <p>Add first table.</p>
          <div
            className="add-promo-btn"
            onClick={() => {
              setPostTable({ status: 1, seats: 1, type: 1 });
              openDrawer(drawersMenu.addTable);
            }}
          >
            Add Table
          </div>
        </div>
      )}
      <DrawerElement
        isActive={isdDrawerElementOpen}
        closePanel={() => {
          setIsDrawerElementOpen(false);
        }}
      />

      <DrawerTable
        type="Add"
        isActive={!!openDrawers[drawersMenu.addTable]}
        closePanel={() => closeDrawer(drawersMenu.addTable)}
        setIsDrawerElementOpen={setIsDrawerElementOpen}
        getTables={getTables}
      />
    </RestaurantProfileTablesContainer>
  );
}
