import React, { useEffect } from "react";
import { DrawerEditFeasttFeeContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerInputContainer } from "components/Elements/DrawerElements/DrawerInput/style";

const DrawerEditFeasttFee = ({
  isActive = false,
  closePanel = () => {},
  restaurantStats = {},
  onChangeEditFeastttValue = () => {},
  editFeastttFeeValue = {},
  editFeastttFeeOnSave = () => {},
}) => {
  return (
    <DrawerEditFeasttFeeContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"37%"}
      position="fixed"
      isInputValuePresent={editFeastttFeeValue ? true : false}
    >
      <DrawerContainer>
        <div className="edit-feasttt-fee-modal">
          <div className="edit-feasttt-fee-modal-title">Edit Feasttt Fee</div>
          <div
            className="edit-feasttt-fee-modal-meta"
            style={{ marginTop: "8px" }}
          >
            The rate will be applied to all new Feasttt restaurants.
          </div>
          <div className="edit-feasttt-fee-modal-input">
            <DrawerInputContainer>
              <h6 className="mb-0">Feasttt Fees</h6>
              <div
                className="drawer-input-container"
                data-value={editFeastttFeeValue ? editFeastttFeeValue : ""}
              >
                <input
                  value={editFeastttFeeValue || ""}
                  placeholder="Feasttt Fees"
                  onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value < 100) {
                      onChangeEditFeastttValue(e.target.value);
                    }
                  }}
                />
              </div>
            </DrawerInputContainer>
            {/* <DrawerInput
              label="Feasttt Fees"
              placeholder="Feasttt Fees"
              value={editFeastttFeeValue}
              onChange={(e) => {
                onChangeEditFeastttValue(parseInt(e.target.value));
              }}
            /> */}
          </div>
          <MyDrawerButton
            type="red"
            className="mb-2 w-100"
            onClick={() => {
              editFeastttFeeOnSave();
            }}
            disabled={editFeastttFeeValue === ""}
          >
            <b>Save</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className="mb-2 w-100"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerEditFeasttFeeContainer>
  );
};

export default DrawerEditFeasttFee;
