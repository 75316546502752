import styled from "@emotion/styled";
import { Button } from "react-bootstrap";
import { colors } from "theme";

export const MyButtonContainer = styled(Button)`
  height: 40px;
  border-radius: 25px;
  padding: 0px 30px;
  background-color: ${(props) =>
    props.type === "gray"
      ? colors.gray
      : props.type === "transparent"
      ? "transparent"
      : props.type === "gray-cuisine"
      ? "rgba(255,255,255,0.1)"
      : colors.redColor} !important;
  border-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => 
    props.type === "gray-cuisine"
      ? "rgba(255,255,255,0.4)" :
      props.type === "transparent" ? "#ed2626"
      : "#fff"} !important;
`;
