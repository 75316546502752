import React, { useEffect } from "react";
import { EnterNewPhoneNumberContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { MobileImage } from "assets/img";
import NumberFormat from "react-number-format";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const EnterNewPhoneNumberModal = ({
  isActive = false,
  closePanel = () => {},
  phoneNumber,
  setPhoneNumber,
  onDone,
}) => {
  useEffect(() => {
    if (isActive) {
      setPhoneNumber("");
    }
  }, [isActive]);
  return (
    <EnterNewPhoneNumberContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-number">
          <div className="modal-image">
            <div className="round-circle">
              <img src={MobileImage} alt="" />
            </div>
          </div>
          <div className="modal-title-new-number">Enter new phone number</div>
          <div className="account-form-item">
            <div className="input-label">
              <label>Phone Number</label>
            </div>
            <div>
              <NumberFormat
                format={"+1 (###) ###-####"}
                className={`single-ant-format-input`}
                value={phoneNumber || ""}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onBlur={() => {}}
                placeholder="Add phone number"
              />
              <div className="break-line">
                <hr
                  style={{
                    borderTop: "1px solid #323334",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="new-number-action-btns">
            <MyDrawerButton
              type={phoneNumber ? "red" : "gray"}
              disabled={phoneNumber ? false : true}
              onClick={onDone}
            >
              <b>Continue</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </EnterNewPhoneNumberContainer>
  );
};

export default EnterNewPhoneNumberModal;
