/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { colors } from "theme";
import { MyDrawerRightContainer } from "./style";

export function MyDrawerLeftArrow({
  children,
  className = "",
  isActive = false,
  renderLeftIcon = <FaChevronLeft size={18} color={colors.white} />,
  renderRightIcon = null,
  // onClickLeftIcon = () => {},
  onClickRightIcon = () => {},
  closePanel = () => {},
}) {
  return (
    <MyDrawerRightContainer>
      <div
        className="offcanvas offcanvas-left"
        style={isActive ? { right: 0 } : {}}
      >
        <div className="drawer-left-icon cursor" onClick={closePanel}>
          {renderLeftIcon}
        </div>
        <div className="drawer-right-icon cursor" onClick={onClickRightIcon}>
          {renderRightIcon}
        </div>
        <div className={`drawer-content-wrapper ${className}`}>{children}</div>
      </div>
      <div
        className={`offcanvas-overlay`}
        style={isActive ? {} : { display: "none" }}
        onClick={closePanel}
      ></div>
    </MyDrawerRightContainer>
  );
}
