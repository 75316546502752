import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerDeletetedDiscountContainer = styled(MyDrawerRight)`
  .drawer-bottom-content {
    text-align: center;
    h3 {
      margin-bottom: 50px;
    }
  }
`;
