import React, { useEffect } from "react";
import { DrawerChangeFeasttFeeContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DrawerChangeFeasttFee = ({
  isActive = false,
  closePanel = () => {},
  restaurantStats = {},
  editFeastttFeeValue = {},
  changeAllRestaurantHandler = () => {},
  changeOnlyForNewRestaurantHandler = () => {},
}) => {
  return (
    <DrawerChangeFeasttFeeContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"37%"}
      position="fixed"
    >
      <DrawerContainer>
        <div className="change-feasttt-fee-modal">
          <div className="delete-icon">
            <i className="fas fa-coins" />
          </div>
          <div className="change-feasttt-fee-modal-title">
            Change Feasttt Fee
          </div>
          <div className="change-feasttt-fee-modal-meta">
            You are about change the Feasttt Fee rate &nbsp;
            <span
              style={{
                fontWeight: "normal",
              }}
            >
              from &nbsp;
            </span>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {restaurantStats?.feast_fees || ""}% &nbsp;
            </span>
            to &nbsp;
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {editFeastttFeeValue}% &nbsp;
            </span>
            for all new restaurants.
          </div>
          <div className="change-feasttt-fee-modal-meta">
            Do you want to change the Feasttt Fee rate for all old restaurants
            also?
          </div>
          <MyDrawerButton
            type="red"
            className="mb-6 w-100"
            onClick={() => {
              changeOnlyForNewRestaurantHandler();
            }}
          >
            <b>Change ONLY for new restaurants</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="mb-6 w-100"
            onClick={() => {
              changeAllRestaurantHandler();
            }}
          >
            <b>Change for ALL restaurants</b>
          </MyDrawerButton>
          <MyDrawerButton
            type="redColor"
            className=" w-100"
            onClick={closePanel}
          >
            <b>Cancel</b>
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerChangeFeasttFeeContainer>
  );
};

export default DrawerChangeFeasttFee;
