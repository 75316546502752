import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerFullRefundContainer } from "./style";

export default function DrawerFullRefund({
  isActive = false,
  closePanel = () => {},
  orderDetails = {},
  fullRefundHandler = () => {},
  onClickContinueFullRefund = () => {},
}) {
  // const { openDrawer } = useContext(OrdersContext);

  // const onIssueRefund = () => {
  //   fullRefundHandler();
  //   // openDrawer(drawersOrder.deletedReview);
  // };
  return (
    <DrawerFullRefundContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"31%"}
      left={"38%"}
      position="fixed"
      width="472px"
      zIndex={99999}
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <div className="cash-icon">
            <i className="fas fa-money-bill-wave" />
          </div>
          <h3 className="mb-3 modal-title">Issue Full Refund</h3>
          <h6 className="mb-2 text-white font-weight-bold">
            Are you sure you want to issue a full refund <br/> of{" "}
            <b>${Number(orderDetails?.checkout_data?.total).toFixed(2)}</b> to
            the customer?
          </h6>
          <h6 className="opacity6 mb-2 mt-5">
            Customer receives refund within 3-5 business days.
          </h6>
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mb-2"
              onClick={onClickContinueFullRefund}
            >
              <b>Continue</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerFullRefundContainer>
  );
}
