import { DrawerContainer } from "components/styledStyle";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { CustomersContext } from "context/CustomersContext";
import { drawersCustomers } from "static/staticData";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { DrawerAddAddressContainer } from "./style";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import DrawerSearchAddress from "../DrawerSearchAddress";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const DrawerAddAddress = ({
  isActive = false,
  closePanel = () => {},
  customerId,
  onSaveAddress,
}) => {
  const {
    openDrawer,
    openDrawers,
    profileData,
    setProfileData,
    closeDrawer,
    editCustomerData,
    setEditCustomerData,
  } = useContext(CustomersContext);
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [placeId, setPlaceId] = useState();

  const [openLocationDropDown, setOpenLocationDropDown] = useState(false);

  // google api suggestions
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    setValue("");
    setOpenLocationDropDown(false);
  });

  const handleSelect = ({ description, place_id }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setPlaceId(place_id);
    setValue(description, false);
    setAddress(description);
    clearSuggestions();
    closeDrawer(drawersCustomers.foundAddress);
    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      console.log("📍 Coordinates: ", { lat, lng });
    });
    setOpenLocationDropDown(false);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <Fragment>
          <div
            className="location-list cursor-pointer"
            key={place_id}
            onClick={handleSelect(suggestion)}
          >
            <strong>{main_text}</strong>, <span>{secondary_text}</span>
          </div>
        </Fragment>
      );
    });
  // end of google api suggestions

  return (
    <DrawerAddAddressContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Add Address</div>
          <div></div>
        </div>
        <div className="input-divs mb-auto mt-4">
          <div style={{ marginBottom: "50px" }}>
            <DrawerInput
              label="Address Name"
              placeholder="Ex: Home, Office, Village, etc."
              value={name}
              className="address-name-input"
              onChange={(e) => setName(e.target.value)}
            />
            {/* <DrawerInputWithVerify
              label="Address"
              placeholder="Search Location"
              value={address}
              className="address-input"
              onChange={(e) => setAddress(e.target.value)}
              onClick={() => openDrawer(drawersCustomers.foundAddress)}
            /> */}

            <div className="input-divs mb-auto mt-4">
              <label>Address</label>
              <div className="location-input-wrapper">
                <DrawerInput
                  className="location-input"
                  placeholder="Search Location"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onClick={() => {
                    setOpenLocationDropDown(!openLocationDropDown);
                  }}
                />
                {value?.length > 0 ? (
                  <i
                    className="fa fa-times close-icon"
                    aria-hidden="true"
                    onClick={() => {
                      clearSuggestions();
                      setValue("");
                      setOpenLocationDropDown(false);
                    }}
                  />
                ) : (
                  <i className="far fa-search" />
                )}
              </div>
              {openLocationDropDown ? (
                <div ref={ref} className="address-box">
                  {status === "OK" && <ul>{renderSuggestions()}</ul>}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={name?.length > 0 && address?.length > 0 ? "red" : "gray"}
            className="mt-auto text-bold"
            onClick={() => {
              onSaveAddress({
                locationTitle: name,
                locationPlaceId: placeId,
                locationAddress: address,
              });
              closeDrawer(drawersCustomers.addAddress);
            }}
            disabled={name?.length > 0 && address?.length > 0 ? false : true}
          >
            Add Address
          </MyDrawerButton>
          <div className="cancel-btn" onClick={closePanel}>Cancel</div>
        </div>
      </DrawerContainer>
      <DrawerSearchAddress
        isActive={!!openDrawers[drawersCustomers.foundAddress]}
        closePanel={() => closeDrawer(drawersCustomers.foundAddress)}
        setAddress={setAddress}
        address={address}
        setPlaceId={setPlaceId}
      />
    </DrawerAddAddressContainer>
  );
};

export default DrawerAddAddress;
