/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { drawersOrder, showLimits, showSorts } from "static/staticData";
import { OrdersContainer } from "./style";
import MyPaginator from "components/Elements/MyPaginator";
import OrderItem from "./OrderItem";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaSlidersH,
  FaSpinner,
} from "react-icons/fa";
import { colors } from "theme";
import MyButton from "components/Elements/MyButton";
import DrawerFilterOrders from "./Drawers/DrawerFilterOrders";
import { OrdersContext } from "context/OrdersContext";
import DrawerDateCustomer from "./Drawers/DrawerDateCustomer";
import { Link } from "react-router-dom";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MySelect from "components/Elements/MySelect";
import moment from "moment";
import MySearch from "components/Elements/MySearch";
import { NoFilterData } from "assets/img";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import orderShape from "assets/img/order-shape.png";
import activeOrderShape from "assets/img/active-order-shape.png";
import MyDateRange from "components/Elements/MyDateRange";
import { PopupCalendarContainer } from "components/analytics/AnalyticPeriod/style";
import useOnclickOutside from "react-cool-onclickoutside";

const filterBtns = [
  {
    value: "day",
    label: "Today",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "month",
    label: "Month",
  },
  {
    value: "total",
    label: "Total",
  },
];

export const OrdersPage = ({ restaurantId }) => {
  const history = useHistory();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    ordersData,
    setOrdersData,
    setOrdersRestaurantId,
  } = useContext(OrdersContext);

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  const [activeOption, setActiveOption] = useState("day");

  const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);
  const [selectedPage, setSelectedPage] = useState(1);

  const [selectedDate, setSelectedDate] = useState("");

  // orders api
  // const [ordersData, setOrdersData] = useState([]);
  const [activeOrder, setActiveOrder] = useState();
  const [statsData, setStatsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [selectedSort, setSelectedSort] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isSearchFilterApplied, setIsSearchFilterApplied] = useState(false);
  const [filterParamsState, setFilterParamsState] = useState({});

  const [filteredOrderList, setFilteredOrderList] = useState([]);

  const [tempDateRange, setTempDateRange] = useState();
  const [overlayClick, setOverlayClick] = useState(false);
  const [dateRange, setDateRange] = useState();

  function ordersMethod() {
    let url;
    url = `/orders?page=${selectedPage}&page_size=${selectedLimitToShow}`;

    if (
      searchInput !== "" &&
      searchInput !== null &&
      searchInput !== undefined &&
      value !== ""
    ) {
      url += `&search_token=${searchInput?.toUpperCase()}`;
    }

    if (filterParamsState?.start_date) {
      filterParamsState.date_range = [
        // moment(selectedDate?.startDate).format("YYYY/MM/DD"),
        // moment(selectedDate?.endDate).format("YYYY/MM/DD"),
        moment(filterParamsState.start_date).format("YYYY/MM/DD"),
        moment(filterParamsState?.end_date).format("YYYY/MM/DD"),
      ];
    }
    if (filterParamsState?.date_range) {
      url += `&start_date=${filterParamsState?.date_range[0]}&end_date=${filterParamsState?.date_range[1]} `;
    }
    if (filterParamsState?.restaurant_ids) {
      url += `&restaurant_ids=${filterParamsState?.restaurant_ids}`;
    }
    if (
      filterParamsState?.guests_count &&
      filterParamsState?.guests_count.length
    ) {
      if (
        filterParamsState?.guests_count &&
        filterParamsState?.guests_count[1]
      ) {
        url += `&guests_count=${filterParamsState?.guests_count[0]},${filterParamsState?.guests_count[1]}`;
      } else {
        url += `&guests_count=${filterParamsState?.guests_count[0]}`;
      }
    }
    if (
      filterParamsState?.items_count &&
      filterParamsState?.items_count?.length
    ) {
      if (filterParamsState?.items_count && filterParamsState?.items_count[1]) {
        url += `&items_count=${filterParamsState?.items_count[0]},${filterParamsState?.items_count[1]}`;
      } else {
        url += `&items_count=${filterParamsState?.items_count[0]}`;
      }
    }
    if (
      filterParamsState?.restaurantName &&
      filterParamsState?.restaurantName
    ) {
      url += `&query=${filterParamsState?.restaurantName}`;
    }
    if (Object.keys(filterParamsState).length) {
      setIsFilterApplied(true);
    }
    if (filterParamsState?.status) {
      url += `&status=${filterParamsState?.status}`;
    }

    customAxios
      .get(url)
      .then((res) => {
        setTotalCount(res?.data?.total_count);
        setFilteredOrderList(res?.data?.orders);
        let activeOrdersData = res?.data?.orders
          ?.filter((el) => el?.status !== 2)
          ?.sort((a, b) => new Date(b?.started_at) - new Date(a?.started_at));
        let paidOrders = res?.data?.orders
          ?.filter((el) => el?.status === 2)
          ?.sort((a, b) => new Date(b?.started_at) - new Date(a?.started_at));
        let finalArry = activeOrdersData.concat(paidOrders);
        setOrdersData(finalArry);
        if (
          searchInput !== "" &&
          searchInput !== null &&
          searchInput !== undefined &&
          value !== ""
        ) {
          setIsSearchFilterApplied(true);
        } else {
          setIsSearchFilterApplied(false);
        }
        if (Object.keys(filterParamsState).length) {
          closeDrawer(drawersOrder?.filterOrders);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    customAxios
      .get(
        `/orders/stats${
          activeOption === "total" ? "" : `?period=${activeOption}`
        }`
      )
      .then((res) => {
        const { active_orders } = res?.data;
        setActiveOrder(active_orders || 0);
        let array = [];
        let averageOrderObj = {
          title: "average_order",
          current: res?.data?.stats?.average_order?.current || 0,
          change: res?.data?.stats?.average_order?.change || 0,
        };
        array.push(averageOrderObj);
        let ordersObj = {
          title: "total_orders",
          current: res?.data?.stats?.orders?.current || 0,
          change: res?.data?.stats?.orders?.change || 0,
        };
        array.push(ordersObj);
        setStatsData(array);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [activeOption]);

  useEffect(() => {
    ordersMethod();
  }, [
    selectedLimitToShow,
    selectedPage,
    selectedSort,
    filterParamsState,
    value,
  ]);

  useEffect(() => {
    setSelectedPage(1);
  }, [selectedLimitToShow]);

  // onSelectDateRange

  const onPageChange = async (e) => {
    setSelectedPage(e.selected + 1);
  };

  // const onSelectDateRange = (date) => {
  //   setSelectedDate(date);
  //   closeDrawer(drawersOrder?.dateCustomer);
  // };

  const onDone = (filterParams) => {
    setFilterParamsState(filterParams);
  };

  const toggleStatusSort = () => {};

  const onClickResetFilter = () => {
    setSelectedDate("");
    setIsFilterApplied(false);
    setFilterParamsState({});
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <b className="text-bold">{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  // date range method
  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempDateRange({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  function onChangeApply() {
    if (tempDateRange && tempDateRange?.endDate) {
      setDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
      setOverlayClick(!overlayClick);
    }
  }

  const ref = useOnclickOutside(() => {
    setOverlayClick(false);
  });

  // useEffect(() => {
  //  console.log(isFilterApplied)
  // }, [isFilterApplied])

  return (
    <OrdersContainer>
      <div className="orders-page-header d-flex justify-content-between align-items-center">
        <div className="left-part">
          <h5
            style={{
              fontSize: "24px",
            }}
            className="text-bold"
          >
            Orders
          </h5>
        </div>
        <div className="right-part">
          <div className="show-limit d-flex align-items-center mr-4">
            {/* <MySelect
              className="ml-3"
              options={showSorts}
              defaultValueId="sort"
              onChange={(option) => {
                setSelectedSort(option?.id);
              }}
            /> */}
            <div className="ml-3">
              <MySearch
                value={searchInput}
                onChange={(e) => {
                  if (e?.target?.value === undefined) {
                    setSearchInput("");
                  } else {
                    setSearchInput(e?.target?.value);
                  }
                }}
                placeHolder="Search Order #"
                defaultShow={true}
              />

              {searchInput !== "" && filteredOrderList?.length !== 0 ? (
                <div className="show-search-result">
                  {filteredOrderList.map((searchRestaurant, i) => {
                    return (
                      <>
                        <span
                          className="restaurant-title"
                          key={i}
                          onClick={(e) => {
                            history.push(
                              `order-detail/${searchRestaurant?.token}`
                            );
                          }}
                        >
                          <p className="restaurant-title-gap">
                            <div className="text-white">
                              {searchInput !== "" &&
                                searchInput !== null &&
                                searchInput !== undefined &&
                                getHighlightedText(
                                  searchRestaurant?.token,
                                  searchInput
                                )}
                            </div>
                          </p>
                          <br />
                        </span>
                      </>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>

          <div className="show-limit d-flex align-items-center mr-4">
            {/* <div
              className="show-message"
              style={{
                color: "#ffffff7d",
              }}
            >
              Show
            </div> */}
            <MySelect
              className=""
              options={showLimits}
              defaultValueId={25}
              onChange={(option) => {
                setSelectedLimitToShow(option?.name);
              }}
            />
          </div>

          <div
            className="filter-item d-flex align-items-center justify-content-center cursor"
            onClick={() => openDrawer(drawersOrder?.filterOrders)}
          >
            <FaSlidersH className="filter-icon" />
            {isFilterApplied && <div className="active-dot" />}
          </div>
        </div>
      </div>
      <div className="orders-body">
        <div className="row orders-info-wrapper">
          <div className="col-3">
            <div
              className="order-info-card"
              style={{
                backgroundImage: `url(${activeOrderShape})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "100%",
              }}
            >
              <div className="info-item">
                <div className="info-label">
                  <FaSpinner />
                  <label className="ml-2">Active Orders</label>
                </div>
                <h2 className="info-value">{activeOrder}</h2>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div
              className="order-info-card order-info-right"
              style={{
                backgroundImage: `url(${orderShape})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "100%",
              }}
            >
              {statsData &&
                statsData?.length &&
                statsData?.map((_info, _i) => {
                  if (_info !== "active_orders") {
                    return (
                      <div className="info-item" key={_i}>
                        <div className="info-label">
                          {(_info?.title === "average_order" && (
                            <label className="mr-2">Average Order</label>
                          )) ||
                            (_info?.title === "total_orders" && (
                              <label className="mr-2">Orders</label>
                            ))}
                          {/* {_info ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )} */}
                          {(_info?.title === "average_order" && (
                            <>
                              {Math.abs(_info?.change) < 0 ? (
                                <FaArrowCircleDown color={colors.orange} />
                              ) : (
                                <FaArrowCircleUp
                                  color={colors.green}
                                  className="mr-1"
                                />
                              )}
                            </>
                          )) ||
                            (_info?.title === "total_orders" && (
                              <>
                                {Math.abs(_info?.change) < 0 ? (
                                  <FaArrowCircleDown color={colors.orange} />
                                ) : (
                                  <FaArrowCircleUp
                                    color={colors.green}
                                    className="mr-1"
                                  />
                                )}
                              </>
                            ))}

                          {(_info?.title === "average_order" && (
                            <span
                              style={{
                                color:
                                  Math.abs(_info?.change) < 0
                                    ? colors.orange
                                    : colors.green,
                              }}
                              className="percentage"
                            >
                              {Math.abs(_info?.change)}%
                            </span>
                          )) ||
                            (_info?.title === "total_orders" && (
                              <span
                                style={{
                                  color:
                                    Math.abs(_info?.change) < 0
                                      ? colors.orange
                                      : colors.green,
                                }}
                                className="percentage"
                              >
                                {Math.abs(_info?.change)}%
                              </span>
                            ))}
                        </div>
                        <h2 className="info-value">
                          {(_info?.title === "average_order" &&
                            `$ ${
                              _info?.current === 0
                                ? _info?.current.toFixed(2)
                                : _info?.current
                            }`) ||
                            (_info?.title === "total_orders" &&
                              `${_info?.current}`)}
                        </h2>
                      </div>
                    );
                  }
                })}

              <div className="filter-options">
                {filterBtns?.map((_btn, _i) => (
                  <MyButton
                    key={_i}
                    type={activeOption === _btn?.value ? "gray" : "transparent"}
                    onClick={() => setActiveOption(_btn?.value)}
                    className={
                      activeOption === _btn?.value ? "gray" : "unelected-tab"
                    }
                  >
                    {_btn?.label}
                  </MyButton>
                ))}

                <div id="statistic-calendar-popup-handle">
                  <span
                    className="checked ml-4"
                    onClick={() => {
                      setOverlayClick(true);
                      setTempDateRange({
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    <i className="fas fa-calendar-alt cursor" />
                  </span>
                  {overlayClick && (
                    <PopupCalendarContainer className="popup-wrapper" ref={ref}>
                      <MyDateRange
                        months={2}
                        onChangeDate={onChangeDate}
                        date={dateRange}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 20px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: "14px" }}>
                          {dateRange?.startDate
                            ? moment(dateRange?.startDate).format("MM/DD/YYYY")
                            : ""}
                          {dateRange && "  to  "}
                          {dateRange?.endDate
                            ? moment(dateRange?.endDate).format("MM/DD/YYYY")
                            : ""}
                        </span>

                        <MyButton
                          className="apply-date"
                          type={
                            tempDateRange?.endDate || dateRange?.endDate
                              ? "red"
                              : "gray"
                          }
                          onClick={onChangeApply}
                        >
                          Apply
                        </MyButton>
                      </div>
                    </PopupCalendarContainer>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isSearchFilterApplied || isFilterApplied ? (
          <div className="orders-filter-details-row d-flex justify-content-between align-items-center">
            <div className="filter-name">
              {isSearchFilterApplied && isFilterApplied
                ? "Filter & Search Applied"
                : isSearchFilterApplied
                ? "Search Applied"
                : isFilterApplied
                ? "Filter Applied"
                : ""}{" "}
            </div>
            <div className="result">
              <b>{ordersData?.length || 0}</b>{" "}
              {ordersData?.length === 0 ? "result" : "results"}
            </div>
          </div>
        ) : (
          <></>
        )}
        {ordersData && ordersData?.length > 0 ? (
          <div className="orders-table">
            <OrderItem header={true} />
            {ordersData?.length > 0 &&
              ordersData
                .filter((el) => el?.status >= 0)
                .map((_order, _i) => {
                  setOrdersRestaurantId(_order?.restaurant_id);
                  return (
                    <Link to={`/order-detail/${_order?.token}`} key={_i}>
                      <OrderItem
                        order={_order}
                        onClickEdit={(e) => console.log(e)}
                        toggleStatusSort={toggleStatusSort}
                        selectedPage={selectedPage}
                        searchInput={searchInput}
                      />
                    </Link>
                  );
                })}

            {ordersData.length > 0 && (
              <div className="mt-5">
                <MyPaginator
                  initialPage={0}
                  totalCount={totalCount}
                  limitShow={selectedLimitToShow}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            {isSearchFilterApplied || isFilterApplied ? (
              <div className="no-orders-data-div d-flex flex-column justify-content-center align-items-center">
                <img src={NoFilterData} alt="" />
                <div className="no-result-text">No results</div>
                <div className="no-result-search-meta">
                  Try broadening your search.
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      {/* Drawer Filter Order */}
      <DrawerFilterOrders
        isActive={!!openDrawers[drawersOrder.filterOrders]}
        closePanel={() => closeDrawer(drawersOrder.filterOrders)}
        selectedDate={selectedDate}
        onDone={onDone}
        onClickResetFilter={onClickResetFilter}
        ordersMethod={ordersMethod}
        isFilterApplied={isFilterApplied}
        setIsFilterApplied={setIsFilterApplied}
      />

      <DrawerDateCustomer
        isActive={!!openDrawers[drawersOrder.dateCustomer]}
        closePanel={() => closeDrawer(drawersOrder.dateCustomer)}
        // onSelectDateRange={onSelectDateRange}
      />
    </OrdersContainer>
  );
};
