/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { drawersStaff } from "static/staticData";
import { AllStaffContainer } from "./style";
import MyPaginator from "components/Elements/MyPaginator";
import StaffCard from "./StaffCard";
import { RestaurantsContext } from "context/RestaurantsContext";
import NoFilterData from "assets/img/no-filter-data.png";
import AllStaffList from "./AllStaffList";

export const AllStaff = ({ staffs, selectedLimitToShow, currentPage, setCurrentPage, totalCount }) => {
  const {
    openDrawer,
    postStaff,
    setPostStaff,
    staffCloseDrawer,
    setStaffCloseDrawer,
  } = useContext(RestaurantsContext);
  // const [selectedLimitToShow, setSelectedLimitToShow] = useState(25);

  const onPageChange = async (e) => {
    setCurrentPage(e.selected + 1);
  };

  const onClickEditStaff = (staff) => {
    setPostStaff(staff);
    openDrawer(drawersStaff.staffEdit);
  };

  const onClickDeleteStaff = (staff) => {
    setPostStaff(staff);
    // openDrawer(drawersStaff.staffDelete);
    setStaffCloseDrawer({
      ...staffCloseDrawer,
      staffDelete: true,
    });
  };

  // console.log(staffs)

  return (
    <AllStaffContainer>
      {staffs && staffs?.length > 0 ? (
        <div className="orders-table">
          <AllStaffList 
            header={true} 
          />
          {staffs?.length > 0 &&
            staffs.map((_staff, _i) => {
              // console.log(_staff)
              return (
                <>
                  <AllStaffList
                    staff={_staff}
                    onClickEdit={onClickEditStaff}
                    onClickDelete={onClickDeleteStaff}
                  />
                </>
              );
            })}

          {staffs?.length > 0 && (
            <div className="mt-5">
              <MyPaginator
                initialPage={currentPage}
                totalCount={totalCount}
                limitShow={selectedLimitToShow}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {/* {isSearchFilterApplied || isFilterApplied ? ( */}
          <div className="no-orders-data-div d-flex flex-column justify-content-center align-items-center">
            <img src={NoFilterData} alt="" />
            <div className="no-result-text">No results</div>
            <div className="no-result-search-meta">
              Try broadening your search.
            </div>
          </div>
          {/* ) : (
            <></>
          )} */}
        </>
      )}
    </AllStaffContainer>
  );
};
