import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { ScaleLoader } from "react-spinners";
import { PhotoUploaderContainer } from "./style";
import { assetServices } from "services/assetServices";

export function PhotoUploader({
  src,
  width = "100%",
  height = "100%",
  shape = "circle",
  overlay,
  onUploadSuccess = () => {},
}) {
  const [imageUrl, setImageUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [isHover, setHowering] = useState(false);
  const inputFileRef = useRef();

  const onClickChangePhoto = () => inputFileRef.current.click();

  const onFileInputChange = async (e) => {
    console.log(e.target.files)
    const payload = createFormData(e);
    setUploading(true);
    uploadPhoto(payload);
    // onUploadSuccess(e.target.files[0])
  };

  const createFormData = (e) => {
    const formData = new FormData();
    formData.append("asset", e.target.files[0]);
    formData.append("type", "restaurant");
    formData.append("api_key", process.env.REACT_APP_API_KEY);
    return formData;
  };

  const uploadPhoto = async (payload) => {
    const result = await assetServices.uploadToS3(payload);
    console.log("result", result);
    setUploading(false);
    if (result.status == 200) {
      onSuccessfullyUploaded(result.data);
    }
  };

  const onSuccessfullyUploaded = (uploadedResource) => {
    setImageUrl(uploadedResource.file);
    onUploadSuccess(uploadedResource);
  };

  return (
    <PhotoUploaderContainer width={width} height={height} shape={shape}>
      <div className="avatar-uploader" onClick={onClickChangePhoto}>
        <div
          className={`photo ${shape === "circle" ? "circle" : "square"}`}
          onMouseOverCapture={() => setHowering(true)}
          onMouseLeave={() => setHowering(false)}
        >
          <input
            className="file-image-input"
            type="file"
            ref={inputFileRef}
            onChange={onFileInputChange}
            accept="image/*"
          />
          <img
            src={src}
            alt="avatar"
            className={`user-image ${shape === "circle" ? "circle" : "square"}`}
          />
          {uploading && (
            <div className="uploading-icon">
              <ScaleLoader size={15} color={"white"} />
            </div>
          )}
          {isHover && (
            <div className="photo-hover">
              {overlay || (
                <div className="change-container">
                  <span className="change-text">Change</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PhotoUploaderContainer>
  );
}

PhotoUploader.propTypes = {
  uploading: PropTypes.bool,
  onUploadSuccess: PropTypes.func,
  src: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  shape: PropTypes.string,
  overlay: PropTypes.node,
};
