import styled from "@emotion/styled";

export const SessionsBarsContainer = styled.div`
  width: 46%;
  margin-right: 22px;
  padding-top: 33px;
  display: flex;
  flex-direction: column;

  .session-bar-item {
    width: 100%;
    margin-top: 17px;
    display: flex;
    flex-direction: column;

    &.orange-line {
      .session-bar-core {
        &:after {
          background-color: #f7a64c;
        }
      }
    }

    &.gray-brown-line {
      .session-bar-core {
        &:after {
          background-color: #d6a67c;
        }
      }
    }

    &.green-line {
      .session-bar-core {
        &:after {
          background-color: #1fc086;
        }
      }
    }

    &.light-green-line {
      .session-bar-core {
        &:after {
          background-color: #9ad4bf;
        }
      }
    }

    .session-bar-top {
      width: 100%;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;

      .session-minutes {
        font-size: 14px;
        font-weight: 500;
        color: white;
      }

      .session-audience-percentage {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .session-bar-core {
      position: relative;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.12);

      &:after {
        content: "";
        display: block;
        height: 4px;
        width: var(--width);
        border-radius: 2px;
        position: absolute;
        top: 0;
      }
    }
  }
`;
