import React, { useEffect, useState, useRef, useMemo } from "react";
import { AnalyticsCustomersPageContainer } from "./style";
import { AnalyticsHeader, CountryChart } from "components/analytics";
import { LiveNow, TabsListByPeriod } from "components/analytics/orders";
import {
  CustomerSignUps,
  ActivityByDay,
  Sessions,
} from "components/analytics/customers";
import { customAxios } from "helpers/custom-helpers/customAxios";

import noAvatar from "assets/img/noAvatar.png";

import {
  LayersControl,
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  Tooltip,
} from "react-leaflet";
import mapData from "./ges.json";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { colors } from "theme";
import { useHistory } from "react-router-dom";

export const AnalyticsCustomersPage = ({ active }) => {
  const history = useHistory();
  const [total, setTotal] = useState([]);
  const [ratingsPeriod, setRatingsPeriod] = useState("day");
  const [ratingsDateRange, setRatingsDateRange] = useState();
  const [selectedRatingTab, setSelectedRatingTab] = useState("Highest Rated");
  const [ratingsData, setRatingsData] = useState();
  const [map, setMap] = useState(null);
  const [customersHeatData, setCustomersHeatData] = useState([]);
  let data = [
    [40, -100],
    [38, -89],
    [39, -89],
    [32, -89],
    [31, -89],
  ];

  const newicon = new L.icon({
    iconUrl: require("./../../../assets/img/Simple_orange_circle.svg.png"),
    iconSize: [20, 20],
  });

  const iconStyle = (count) => {
    return new L.icon({
      iconUrl: require("./../../../assets/img/Simple_orange_circle.svg.png"),
      iconSize: sizeRender(count),
    });
  };

  const sizeRender = (count) => {
    if (count > 0 && count <= 5) {
      return [10, 10];
    } else if (count > 5 && count <= 10) {
      return [15, 15];
    } else if (count > 10 && count <= 15) {
      return [20, 20];
    } else {
      return [25, 25];
    }
  };

  function geoJsonStyle(feature) {
    return {
      fillColor: "#1b1c1d",
      weight: 2,
      opacity: 1,
      color: "#1b1c1d", //Outline color
      fillOpacity: 1,
      height: "500px",
    };
  }

  const geoJsonRef = useRef();
  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[40, -100]}
        zoom={4}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{ height: "500px" }}
        // dragging={false}
        // doubleClickZoom={false}
        attributionControl={false}
        // zoomControl={false}
        minZoom={4}
        maxZoom={10}
      >
        <GeoJSON ref={geoJsonRef} data={mapData} style={geoJsonStyle}>
          {customersHeatData
            .filter((el) => el?.location !== "" && el?.location !== null)
            .map((el, index) => {
              return (
                <Marker
                  position={[el?.lat, el?.lng]}
                  key={index}
                  icon={iconStyle(el?.live_customers)}
                >
                  <Tooltip>
                    <div className="customers-analytics-heat-map">
                      <span className="chart-tooltip-title">
                        <i
                          className="fas fa-map-marker-alt country-chart-city-pin"
                          style={{ color: "#ed2626" }}
                        />
                        {el?.location}
                      </span>
                      <span className="chart-tooltip-amount-customers">
                        <i
                          className="fas fa-circle country-chart-customers-circle"
                          style={{ color: colors.green }}
                        />
                        &nbsp; {el?.live_customers}{" "}
                        {el?.live_customers === 1 ? "customer" : "customers"}
                      </span>
                    </div>
                  </Tooltip>
                </Marker>
              );
            })}
        </GeoJSON>
      </MapContainer>
    ),
    [customersHeatData]
  );
  window.dispatchEvent(new Event("resize"));

  const _mocked_Facts = [{ title: "", value: "1,564" }];

  const _mocked_CustomerSignUps = [
    { title: "New Customers", value: "12,742" },
    { title: "Active", value: "12,001" },
    { title: "Inactive", value: "741" },
  ];

  useEffect(() => {
    customAxios
      .get(`/analytics-customer`)
      .then((res) => {
        if (res?.status === 200) {
          setTotal([
            { title: "Total Customers", value: res?.data?.total_customers },
          ]);
        } else {
          setTotal([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    customAxios
      .get(`/analytics-customer/heatmap`)
      .then((res) => {
        if (res?.status === 200) {
          // console.log("res customer heatmap", res);
          setCustomersHeatData(res?.data);
        } else {
          setCustomersHeatData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let url;
    if (ratingsDateRange) {
      url = `/analytics-customer/rated-customers?period=${ratingsPeriod}&start_date=${ratingsDateRange.startDate}&end_date=${ratingsDateRange.endDate}`;
    } else {
      url = `/analytics-customer/rated-customers?period=${ratingsPeriod}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          if (selectedRatingTab && selectedRatingTab === "Highest Rated") {
            setRatingsData(res?.data?.highest_rated);
          } else {
            setRatingsData(res?.data?.lowest_rated);
          }
        } else {
          setRatingsData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [ratingsPeriod, ratingsDateRange, selectedRatingTab]);

  const onRatingTimePeriodChange = (time) => {
    setRatingsPeriod(time);
  };

  const onChangeActiveTab = (tab) => {
    setSelectedRatingTab(tab);
  };

  const getRoleAmount = () => {
    return customersHeatData.reduce(
      (prev, curr) => {
        return {
          ...prev,
          live_customers: prev?.live_customers + curr?.live_customers,
        };
      },
      { live_customers: 0 }
    );
  };

  return (
    <>
      {/* {active ? ( */}
      <AnalyticsCustomersPageContainer>
        <AnalyticsHeader title="Customer Analytics">
          {/* {total.map(({ title, value }) => (
            <div className="total-container" key={title + value}>
              <span className="total-title">{title}</span>
              <span className="total-value">{value}</span>
            </div>
          ))} */}
        </AnalyticsHeader>
        <div style={{ padding: "0 40px 0 55px" }}>
          <CustomerSignUps totalCustomerSignUps={_mocked_CustomerSignUps} />
          <div className="activity-and-sessions-container">
            <ActivityByDay />
            <Sessions />
          </div>
          <div className="customers-live-container">
            <TabsListByPeriod
              className="special-list-customers"
              tabs={["Highest Rated", "Lowest Rated"]}
              onTimePeriodChange={onRatingTimePeriodChange}
              setDateRange={setRatingsDateRange}
              dateRange={ratingsDateRange}
              onChangeActiveTab={onChangeActiveTab}
              label1="Customer Name"
              label2="Orders"
            >
              {ratingsData &&
                ratingsData.map(({ name, rating, image, user_id }, index) => (
                  <div
                    className={`orders-stats-list-item ${
                      (index + 1) % 2 === 0 ? "darker" : "dark"
                    }`}
                    key={name}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push(`/customer-detail/${user_id}`);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <div className="stat-index">{index > 9 ? `${index + 1}` : `0${index + 1}`}</div>
                    <div className="stat-logo">
                      <img
                        src={image || noAvatar}
                        width="31"
                        height="31"
                        alt="restaurant-logo"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <span className="stat-title">{name}</span>
                    <div className="cost-container">
                      <span className="cost-title">
                      <i className="fas fa-star" /> {rating || 0} 
                      </span>
                    </div>
                  </div>
                ))}
            </TabsListByPeriod>
            <LiveNow
              isCustomerAnalytics={true}
              topTitle="Customers Live Now"
              facts={_mocked_Facts}
              rolesCount={getRoleAmount()}
            >
              {/* <CountryChart isCustomersCountryChart /> */}
              <div className="heat-map-ui">{displayMap}</div>
            </LiveNow>
          </div>
        </div>
      </AnalyticsCustomersPageContainer>
      {/* ) : (
        ""
      )} */}
    </>
  );
};
