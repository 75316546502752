import React from "react";
import { TotalSalesDashboardContainer } from "./style";
import { AnalyticPeriod } from "components/analytics/AnalyticPeriod";

export const TotalSalesDashboard = ({
  sales,
  onTimePeriodChange = () => {},
  setDateRange = () => {},
  dateRange,
}) => {
  return (
    <TotalSalesDashboardContainer>
      <div className="total-sales-dashboard-top">
        <AnalyticPeriod
          onChange={onTimePeriodChange}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
      <div className="total-sales-facts">
        {sales.map(({ title, value, percent }, index) => (
          <div className="total-sales-fact" key={title + value + index}>
            <span className="total-sales-title">
              {title}{" "}
              {/* <div
                className={`percent-state-grow ${
                  percent.up ? "grow-up" : "grow-down"
                }`}
              >
                {percent.up ? (
                  <i className="fas fa-chevron-circle-up" />
                ) : (
                  <i className="fas fa-chevron-circle-down" />
                )}
                {percent.value}%
              </div> */}
            </span>
            <div className="total-sales-value">{value}</div>
            <div className="from-last-period">
              <span className="from-last-period-title">From last period</span>
              <div className="from-last-period-pointer" />
            </div>
          </div>
        ))}
      </div>
    </TotalSalesDashboardContainer>
  );
};
