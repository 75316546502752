import styled from "@emotion/styled";
import { DateRange } from "react-date-range";
import { colors } from "theme";

export const MyDateRangeContainer = styled(DateRange)`
  width: 100%;
  background-color: transparent;
  color: white;
  .rdrMonthAndYearWrapper {
    .rdrPprevButton,
    .rdrNextButton {
      background: transparent;
    }
    .rdrPprevButton {
      i {
        border-color: transparent #ffffff transparent transparent;
      }
    }
    .rdrNextButton {
      i {
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  .rdrMonths {
    .rdrMonth {
      width: 100%;
      .rdrDays {
        .rdrDay {
          .rdrDayNumber {
            span {
              color: #fff;
            }
          }
          &.rdrDayDisabled {
            background-color: transparent;
            &:after {
              background: ${colors.redColor};
              content: "";
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translate(-50%, 0);
              width: 4px;
              height: 4px;
              border-radius: 50%;
            }
          }
          &.rdrDayToday {
            .rdrDayNumber {
              span:after {
                background: ${colors.green};
              }
            }
          }
        }
      }
    }
  }
  .rdrMonthAndYearPickers select {
    color: #fff !important;
    background-color: #27282a;
  }
`;
