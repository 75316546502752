import React, { useState } from "react";
import { DrawerFilterOrdersContainer, Label } from "./style";

const CustomizationList = ({
  countDressing,
  onClickDressingItem,
  customDetail,
  customIndex,
}) => {
  const [isDressingOpened, setIsDressingOpend] = useState(false);

  const selectedCustomOptionsCount = (customDetail) => {
    let filter = customDetail?.options?.filter((el) => el?.isSelected === true);
    return filter?.length || 0;
  };

  return (
    <div className="drawer-content">
      <div
        style={{ marginTop: "30px" }}
        className="collpased-scroll d-flex justify-content-between align-items-center"
        onClick={() => {
          setIsDressingOpend(!isDressingOpened);
        }}
      >
        <Label>{customDetail?.name}</Label>
        <div className="angle-down-div  d-flex align-items-center">
          <Label size="14px" weight="500" opacity="0.4" marginRight="11px">
            Optional
          </Label>
          {selectedCustomOptionsCount(customDetail) !== 0 ? (
            <div
              style={{
                backgroundColor: "#ed2626",
                borderRadius: "4px",
                padding: "2px 8px",
                margin: "0 11px 0 11px",
                color: "#fff",
              }}
            >
              <b>{selectedCustomOptionsCount(customDetail)}</b>
            </div>
          ) : (
            <></>
          )}
          {countDressing() > 0 ? (
            <div className="selected-count-div">
              <Label size="14px" weight="500">
                {countDressing()}
              </Label>
            </div>
          ) : (
            <></>
          )}
          {isDressingOpened ? (
            <i className="fas fa-chevron-up opacity6" />
          ) : (
            <i className="fas fa-chevron-down opacity6" />
          )}
        </div>
      </div>
      {isDressingOpened ? (
        <div className="dressing-item-list d-flex flex-column w-100">
          {customDetail &&
            customDetail.options &&
            customDetail.options.length &&
            customDetail.options.map((optionsEl) => {
              return (
                <div
                  className="dressing-item cursor-pointer"
                  onClick={() => onClickDressingItem(optionsEl, customIndex)}
                  style={{
                    border: optionsEl.isSelected
                      ? "solid 1px #ed2626"
                      : "solid 1px #4d5053",
                  }}
                >
                  {optionsEl?.name}
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomizationList;
