import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { Calendar } from "react-date-range";
import { colors } from "theme";

export const DrawerDateCustomerContainer = styled(MyDrawerBottom)`
  background: #27282a !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  .add-day-title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }
`;

export const MyDateContainer = styled(Calendar)`
  width: 100%;
  /* background-color: transparent; */
  background-color: #27282a;
  color: white;
  .rdrMonthAndYearWrapper {
    .rdrPprevButton,
    .rdrNextButton {
      background: transparent;
    }
    .rdrPprevButton {
      i {
        border-color: transparent #ffffff transparent transparent;
      }
    }
    .rdrNextButton {
      i {
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  .rdrSelected,
  .rdrDayWeekend,
  .rdrDayEndOfWeek {
    color: ${colors.redColor} !important;
  }
  .rdrDayHovered,
  .rdrDayActive {
    color: ${colors.redColor} !important;
  }
  .rdrDay {
    .rdrDayNumber {
      span {
        color: #fff;
      }
    }
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      color: ${colors.redColor} !important;
    }
    :hover {
      color: ${colors.redColor} !important;
    }
    &.rdrDayDisabled {
      background-color: transparent;
      &:after {
        background: ${colors.redColor};
        content: "";
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }
    }
    &.rdrDayToday {
      .rdrDayNumber {
        span:after {
          background: ${colors.redColor};
        }
      }
    }
  }
  .rdrMonthAndYearPickers select {
    color: #ffffff;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 14px;
  }
`;
