import { NoAvatar } from "assets/img";
import React, { useEffect, useState, useContext } from "react";
import { Image } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { ProfileCardNotesContainer } from "./style";
import { customAxios } from "helpers/custom-helpers/customAxios";
import moment from "moment";
import { drawersOverall } from "static/staticData";
import { CustomersContext } from "context/CustomersContext";
import EmptyNotesImage from "assets/img/no-filter-data.png";
import { useParams } from "react-router-dom";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";

export default function ProfileCardNotes({ className = "", customerId }) {
  const { id } = useParams();

  const {
    openDrawer,
    profileData,
    notesData,
    setNotesData,

    deleteNoteData,
    setDeleteNoteData,
  } = useContext(CustomersContext);

  // const [notesData, setNotesData] = useState([]);

  useEffect(() => {
    getNotesData();
  }, [customerId]);

  function getNotesData() {
    customAxios
      .get(`/customer/${customerId}/notes`)
      .then((res) => {
        setNotesData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const statusRenderer = (status) => {
    switch (status) {
      case 11:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Banned
          </h6>
        );
      case 12:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Unbanned
          </h6>
        );
      case 2:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Changed to Active
          </h6>
        );
      case 1:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Changed to Inactive
          </h6>
        );
      case 0:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Deleted
          </h6>
        );
      case 3:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Email Verified
          </h6>
        );
      case 4:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Email Unverified
          </h6>
        );
      case 5:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Phone Verified
          </h6>
        );
      case 6:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Phone Unverified
          </h6>
        );
      case 7:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Website Verified
          </h6>
        );
      case 8:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Website Unverified
          </h6>
        );
      case 9:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Request Approved
          </h6>
        );
      case 10:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#ed2626",
              fontSize: "13px",
            }}
          >
            Request Declined
          </h6>
        );
      default:
        return (
          <h6
            className="opacity6"
            style={{
              color: "#fff",
              opacity: "0.6",
              fontSize: "13px",
            }}
          >
            Status not changed
          </h6>
        );
    }
  };

  return (
    <ProfileCardNotesContainer className={`${className}`}>
      <div
        className="notes-card-header d-flex justify-content-between align-items-center"
        style={
          notesData?.length > 0
            ? { borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }
            : { borderBottom: "unset" }
        }
      >
        <h3 className="mb-0 text-bold">
          Notes ({notesData && notesData.length && notesData.length})
        </h3>
        {notesData?.length > 0 && (
          <FaAngleRight
            className="cursor-pointer"
            size={18}
            onClick={() => openDrawer(drawersOverall.notes)}
          />
        )}
      </div>
      <div className="notes-card-body d-flex flex-column">
        {notesData && notesData.length ? (
          <>
            {notesData.map((_note, _i) => (
              <>
                {_i < 5 ? (
                  <div className="notes-card-item d-flex justify-content-between align-items-start">
                    <div className="notes-card-avatar">
                      <Image
                        src={_note.image ? _note.image : NoAvatar}
                        width={33}
                        height={33}
                      />
                    </div>
                    <div className="d-flex flex-column align-items-start ml-4 mr-auto">
                      <h6 className="text-bold">{_note.admin_name}</h6>
                      {/* <h6 className="opacity6">
                        {_note.action ? _note.action : "-"}
                      </h6> */}
                      {statusRenderer(_note.action)}
                      <h6>{_note.note ? _note.note : "-"}</h6>
                    </div>
                    <div className="note-date">
                      <h6 className="mb-0 opacity6">
                        {moment(_note.createdat).format("MM/DD/YY")}
                      </h6>
                      <i
                        className="fas fa-trash-alt"
                        // onClick={() => deleteNoteHandler(_note)}
                        // onClick={deleteNotesModal}
                        onClick={() =>
                          setDeleteNoteData({
                            deleteModal: true,
                            selectedNoteData: _note,
                          })
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </>
        ) : (
          <div className="no-result d-flex flex-column justify-content-center align-items-center w-100 h-100">
            {/* <i className="far fa-comment-alt" /> */}
            <img src={EmptyNotesImage} alt="" />
            <h3>No notes yet</h3>
          </div>
        )}
      </div>
    </ProfileCardNotesContainer>
  );
}
