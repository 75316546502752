import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const CuisineModalContainer = styled(MyDrawerCenterModal)`
  .drawer-content {
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      .select-message {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 28px;
        text-align: center;
      }
      .cuisine-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        height: 500px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: ${colors.gray5};
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: ${colors.white};
          border-radius: 5px;
        }
        .cuisine-item {
          cursor: pointer;
          width: 180px;
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background-color: rgba(0, 0, 0, 0.6);
          &.selected {
            border: solid 2px #ed2626;
          }
          .internal-title {
            font-size: 15px;
            font-weight: 600;
            color: #fff;
            margin-top: 13px;
          }
        }
      }
    }

    .button-container {
      margin-top: 300px;
      padding-bottom: 25px;
      .done-button {
        height: 50px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
        border: none;
        &.active {
          background-color: #ed2626;
          color: #fff;
        }
      }
    }

    .top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
    }

    .done-btn {
      position: absolute;
      top: 67rem;
      width: 85%;
    }
    .bottom-btns {
      margin-top: 28px;
      padding-top: unset;
    }
  }
`;
