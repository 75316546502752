import styled from "@emotion/styled";
import { colors } from "theme";

export const ProfileReviewsContainer = styled.div`
  .customers-reviews-header {
    min-height: 70px;
    padding: 0 36px;
  }
  .customers-tab-container {
    margin-bottom: 17px;
    background-color: #27282a;
    border-radius: 50px;
    margin-left: 30px;
    margin-right: 30px;
    height: 46px;
    .tab-toggler {
      width: 50%;
      min-height: 55px;
      /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .tab-bottom-border {
      background-color: ${colors.redColor};
      border-radius: 50px;
      min-height: 46px !important;
      h6 {
        margin-top: 8px;
      }
    }
  }
  .customers-reviews-body {
    flex: 1;
    .customer-review-card-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 14px 32px;
      .customer-reviews-card-avatar {
        margin-right: 14px;
      }
    }
    .customer-reviews-card-info {
      width: 100%;
      .customer-reviews-username-rating {
        margin-bottom: 14px;
      }
      .customer-review-rating {
        color: #ffd35f;
        div:first-of-type {
          font-size: 18px;
          margin-top: -5px;
        }
        i {
          color: #ffd35f;
        }
      }
    }
    .customer-reviews-date-time {
      opacity: 0.5;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.29px;
      color: #fff;
      .delete-review {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .text-italic {
    font-style: italic;
  }
  .no-result {
    margin-top: 13rem;
    img {
      height: 180px;
    }
    h3 {
      margin-top: 20px;
    }
    /* .fa-comment {
      width: 70px;
      height: 70px;
      margin: 207px 13px 27px 2px;
      font-size: 70px;
      font-weight: 300;
      color: #fff;
      opacity: 0.6;
    } */
  }
`;
