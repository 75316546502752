import styled from "@emotion/styled";

export const TotalSalesContainer = styled.div`
  width: 100%;
  // height: 490px;
  background-color: #1b1c1d;
  border-radius: 7px;
  margin-top: 27px;
  margin-bottom: 32px;
  padding-top: 17px;
  display: flex;
`;
