import React, { useState } from "react";
import { ProfileHistoryListContainer, Label } from "../style";
import moment from "moment";
import GuestsList from "./GuestsList";
import ItemsList from "./ItemsList";
import { colors } from "theme";
import {useHistory} from "react-router-dom"

const ProfileHistoryList = ({ history }) => {
  const navigate = useHistory()
  const [isGuestsListShowing, setIsGuestsListShowing] = useState(false);
  const [isItemsListShowing, setIsItemsListShowing] = useState(false);
  return (
    <ProfileHistoryListContainer>
      <Label
        flex="0 0 28%"
        fontSize="14px"
        className="restaurant-name text-bold"
      >
        {history.restaurant_name}
      </Label>
      <Label
        flex="0 0 13%"
        fontSize="14px"
        style={{ color: colors.redColor, fontWeight: 500 }}
      >
        <div className="cursor-pointer" onClick={() => {
          navigate.push(`/order-detail/${history.token}`);
        }}>
          {history.token}
          <i
            className="far fa-angle-right ml-2"
            style={{ color: colors.redColor, fontSize: "13px" }}
          />
        </div>
      </Label>
      <Label flex="0 0 13%" fontSize="14px" style={{ opacity: 0.6 }}>
        {/* {`${moment(history.started_at).format("DD.MM")} /
          ${moment(history.started_at).format("LT")}`} */}
        {moment(history.started_at).format("MMM DD")},{" "}
        {moment(history.started_at).format("LT")}
      </Label>
      <Label
        flex="0 0 13%"
        fontSize="14px"
        style={{ color: colors.redColor, fontWeight: 500 }}
      >
        <div className="cursor-pointer">
          {history.server_name}
          {/* <i
            className="far fa-angle-right ml-2"
            style={{ color: colors.redColor, fontSize: "13px" }}
          /> */}
        </div>
      </Label>
      <Label
        flex="0 0 13%"
        fontSize="14px"
        style={{ paddingLeft: "40px" }}
        onMouseOver={() => setIsGuestsListShowing(true)}
        onMouseLeave={() => setIsGuestsListShowing(false)}
        cursor="pointer"
      >
        {isGuestsListShowing && (
          <GuestsList
            guestsList={history.guests.length ? history.guests : []}
          />
        )}
        {history.guests.length}
      </Label>
      <Label
        flex="0 0 13%"
        fontSize="14px"
        style={{ paddingLeft: "30px" }}
        onMouseOver={() => setIsItemsListShowing(true)}
        onMouseLeave={() => setIsItemsListShowing(false)}
        cursor="pointer"
      >
        {isItemsListShowing && (
          <ItemsList itemsList={history.items.length ? history.items : []} />
        )}
        {history.items.length}
      </Label>
      <Label
        flex="1 1 auto"
        fontSize="14px"
        textAlign="center"
        className="text-bold"
      >
        {`$${history.total_price}`}
      </Label>
    </ProfileHistoryListContainer>
  );
};

export default ProfileHistoryList;
