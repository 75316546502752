import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";
import { colors } from "theme";

export const DrawerCustomerDeletedContainer = styled(MyDrawerBottom)`
  .deleted-customer-modal {
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    color: #fff;
    margin-bottom: 20px;
    text-align: start;
  }
  .deleted-customer-modal-meta {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.27px;
    color: #fff;
    margin-bottom: 52px;
    text-align: start;
  }
`;
