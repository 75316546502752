import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { drawersOverall } from "static/staticData";
import { DrawerReviewServerContainer } from "./style";
import defaultFood from "assets/img/default-food.jpg";
import noAvatar from "assets/img/noAvatar.png";
import { FaStar } from "react-icons/fa";
import NoItem from "assets/img/no-filter-data.png";
import moment from "moment";
import { colors } from "theme";
import { RestaurantsContext } from "context/RestaurantsContext";
import MyDropDown from "components/Elements/MyDropDown";

export default function DrawerFoodItemReviews({
  isActive = false,
  closePanel = () => {},
  foodDetails = {},
  foodReviews,
  staffSort,
  setStaffSort = () => {}
}) {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
  } = useContext(RestaurantsContext);

  return (
    <DrawerReviewServerContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <div className="header-bg">
          <div className="d-flex  align-items-center close-drawer-icon">
            {/* <FaTimes
              size={20}
              style={{ zIndex: "9999999" }}
              className="cursor-pointer "
              onClick={closePanel}
            /> */}
            <i
              className="far fa-angle-left cursor-pointer"
              onClick={closePanel}
              style={{ fontSize: "30px", color: colors.white }}
            />
            <div className="drawer-title">Reviews</div>
          </div>
          <Image src={foodDetails?.image || defaultFood} className="bg-img" />
          <div className="drawer-header-content-review-food">
            <img src={foodDetails?.image || defaultFood} />
            <div className="food-name">{foodDetails?.name}</div>
            <div className="rating">
              <FaStar color={colors.yellow} size={18} className="mr-2" />{" "}
              <b>
                {foodReviews?.total?.rating
                  ? `${foodReviews?.total?.rating}`
                  : "– –"}
              </b>
            </div>
          </div>
        </div>
        <div className="reviews-body">
          {foodReviews && foodReviews.reviews && foodReviews.reviews.length ? (
            <div className="r-header">
              <div className="sort-overall-review-count">
                {foodReviews?.reviews?.length || 0}
                <span className="sort-overall-title">&nbsp; reviews</span>
              </div>

              <div className="reviews-body-header d-flex justify-content-between align-items-center">
                <MyDropDown
                  className="menu-header-select"
                  options={[
                    "Earliest",
                    "Oldest",
                    "Highest Rated",
                    "Lowest Rated",
                  ].map((item) => {
                    return { label: item, value: item };
                  })}
                  // value={staffSort && { label: staffSort, value: staffSort }}
                  onChange={(selectedSortType) => {
                    if (staffSort === selectedSortType) {
                      setStaffSort();
                    } else {
                      setStaffSort(selectedSortType);
                    }
                  }}
                  width={115}
                  iconClosed={<i className="fas fa-angle-down" />}
                  placeholder="Sort"
                />
              </div>
            </div>
          ) : (
            <div className="empty-header">
              <div className="sort-overall-review-count">
                0 <span className="sort-overall-title">reviews</span>
              </div>
            </div>
          )}

          {foodReviews && foodReviews.reviews && foodReviews.reviews.length ? (
            <div className="d-flex flex-column review-list">
              {foodReviews &&
                foodReviews.reviews &&
                foodReviews.reviews.map((el, _i) => {
                  return (
                    <div className="review-list-item" key={_i}>
                      <div className="profile-info d-flex justify-content-between alignitems-center">
                        <div className="d-flex align-items-center image-div">
                          <img
                            src={el?.author?.avatar || noAvatar}
                            alt=""
                            style={{ borderRadius: "50%" }}
                          />
                          <div className="name-time-div">
                            <div className="review-added-user-name">
                              {el?.author?.first_name} {el?.author?.last_name}
                            </div>
                            <div className="feast-text">
                              <b>1</b> Feasts Here • <b>4</b> Feasts Total
                              {/* {moment(el?.createdat)
                                  .startOf("day")
                                  .fromNow()} */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ position: "relative", bottom: "15px" }}
                        >
                          <div>
                            <FaStar
                              className="text-orange mx-1 mr-3"
                              style={{ fontSize: "18px", marginBottom: "4px" }}
                            />
                          </div>
                          <div>
                            <b>{`${el?.rating}`}</b>
                          </div>
                        </div>
                      </div>

                      <div className="review-meta">{el?.comment}</div>

                      <div className="feastt-review-numericals">
                        <div className="feastt-review-numericals-item show-more cursor-pointer">
                          Show more
                        </div>
                        <div className="feastt-review-numericals-item date">
                          <span className="mr-2">
                            {moment(el?.date*1000).format("MMM D YYYY")}
                          </span>{" "}
                          <i
                            className="fas fa-trash-alt cursor-pointer"
                            onClick={() =>
                              openDrawer(
                                drawersOverall.itemRemoveReviewModalScreen
                              )
                            }
                          />
                          {/* <i
                            className="fas fa-trash-undo-alt cursor-pointer"
                            onClick={() =>
                              openDrawer(
                                drawersOverall.itemRestoreReviewModalScreen
                              )
                            }
                          /> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty-item-image">
              <img src={NoItem} alt="" />
              <p className="empty-review">No reviews yet</p>
            </div>
          )}
        </div>
      </DrawerContainer>
    </DrawerReviewServerContainer>
  );
}
