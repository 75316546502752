import React, { useEffect } from "react";
import { EnterEmailModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const EnterEmailModal = ({
  isActive = false,
  closePanel = () => {},
  setEmailAddressInput,
  emailAddressInput,
  onDone,
}) => {
  const isButtonDisabled = () => {
    let emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = emailCheck.test(emailAddressInput);
    return isValid ? false : true;
  };
  useEffect(() => {
    if (isActive) {
      setEmailAddressInput("");
    }
  }, [isActive]);
  return (
    <EnterEmailModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-email">
          <div className="modal-title-new-email">Email Address</div>
          <div className="enter-email-input-div">
            <input
              type="text"
              value={emailAddressInput || ""}
              placeholder="Enter email address"
              onChange={(e) => {
                setEmailAddressInput(e.target.value);
              }}
            />
          </div>
          <div className="new-email-action-btns">
            <MyDrawerButton
              type={isButtonDisabled() ? "gray" : "red"}
              onClick={onDone}
              disabled={isButtonDisabled()}
            >
              <b>Continue</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </EnterEmailModalContainer>
  );
};

export default EnterEmailModal;
