import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";

export const UseTimePerCustomerDrawerModalContainer = styled(
  MyDrawerCenterModal
)`
  .total-use-times-container {
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px !important;
      font-weight: bold;
      margin-bottom: 28px;
    }

    .active-toggle {
      background-color: #1b1c1d;
      padding: 10px 5px 10px 20px;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .promo-apply {
        .promo-apply-text {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .toggle-btn {
      }
    }

    .total-time-input {
      input {
        background: transparent;
        width: 100%;
        border: 0;
        text-align: center;
        border-top: 1px solid #60626a;
        border-bottom: 1px solid #60626a;
        padding: 15px 0 15px 0;
        color: #fff;
        &:focus-visible {
          outline: unset;
          outline-offset: unset;
        }
        &::placeholder {
          color: #60626a;
          font-weight: 500;
        }
      }
    }

    .btn-container {
      margin-top: 20px;
      .done-gray {
        padding: 15px 0 15px 0;
        text-align: center;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.2);
        cursor: pointer;
      }
      .done-red {
        padding: 15px 0 15px 0;
        text-align: center;
        background: #ed2626;
        border-radius: 10px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
      }
      .cancel-btn {
        text-align: center;
        color: #ed2626;
        margin-top: 20px;
        font-weight: bold;
      }
    }
  }

  /* material-ui switch custom css */
  .MuiIconButton-label {
    color: #fff;
  }

  .MuiSwitch-track {
    background-color: #5f626a;
    opacity: unset;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: rgb(30, 206, 109) !important;
    opacity: unset;
  }
`;
