import React, { useContext, useEffect, useState } from "react";
import { RatedListContainer } from "./style";
import { DashboardContext } from "context/DashboardContext";
import defaultFoodImage from "assets/img/default-food.jpg";
import { Link } from "react-router-dom";
import { RestaurantsContext } from "context/RestaurantsContext";
import DrawerItem from "app/pages/DashboardPage/Drawers/DrawerItem";
import { drawersOverall } from "static/staticData";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useHistory } from "react-router-dom";

export const RatedList = ({ restaurants }) => {
  const history = useHistory();
  const { widgetData } = useContext(DashboardContext);

  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    heightRateItem,
    setHeightRateItem,
  } = useContext(RestaurantsContext);

  function getFoodData() {
    const url = `/restaurant/${widgetData?.highest_rated?.restaurant_id}?get_detail=true`;
    customAxios
      .get(url)
      .then((res) => {
        res &&
          res.data &&
          res.data.foods.filter((_item) => {
            if (_item?.id === widgetData?.highest_rated?.food_item_id) {
              // console.log(_item)
              setHeightRateItem(_item);
            }
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getFoodDataLowest() {
    const url = `/restaurant/${widgetData?.lowest_rated?.restaurant_id}?get_detail=true`;
    customAxios
      .get(url)
      .then((res) => {
        res &&
          res.data &&
          res.data.foods.filter((_item) => {
            if (_item?.id === widgetData?.lowest_rated?.food_item_id) {
              // console.log(_item)
              setHeightRateItem(_item);
            }
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function hightestRated() {
    getFoodData();
    openDrawer(drawersOverall?.dashboardDrawer);
  }

  function lowestRated() {
    getFoodDataLowest();
    openDrawer(drawersOverall?.dashboardDrawer);
  }

  return (
    <RatedListContainer>
      <div
        className="rated-item cursor-pointer"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${
            widgetData?.most_visited?.image
              ? widgetData?.most_visited?.image
              : defaultFoodImage
          })`,
          marginRight: "21px",
        }}
        onClick={() => {
          history.push(
            `/restaurant-detail/${widgetData?.most_visited?.id}?view=overall`
          );
        }}
      >
        <h4 className="rated-name cursor-pointer">
          <Link
            to={`/restaurant-detail/${widgetData?.most_visited?.id}?view=overall`}
            className="text-white"
          >
            {widgetData?.most_visited?.name}
          </Link>
        </h4>
        <div className="rating-container">
          <div className="rating-core">
            <div className="like-dislike-icon">
              <i className="fas fa-thumbs-up" />
            </div>
            <span className="rating-title">Most visited</span>
          </div>
        </div>
      </div>

      <div
        className="rated-item cursor-pointer"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${
            widgetData?.least_visited?.image
              ? widgetData?.least_visited?.image
              : defaultFoodImage
          })`,
        }}
        onClick={() => {
          history.push(
            `/restaurant-detail/${widgetData?.least_visited?.id}?view=overall`
          );
        }}
      >
        <h4 className="rated-name cursor-pointer">
          <Link
            to={`/restaurant-detail/${widgetData?.least_visited?.id}?view=overall`}
            className="text-white"
          >
            {widgetData?.least_visited?.name}
          </Link>
        </h4>
        <div className="rating-container">
          <div className="rating-core">
            <div className="like-dislike-icon">
              <i className="fas fa-thumbs-down" />
            </div>
            <span className="rating-title">Least visited</span>
          </div>
        </div>
      </div>

      <div
        className="rated-item cursor-pointer"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${
            widgetData?.highest_rated?.image
              ? widgetData?.highest_rated?.image
              : defaultFoodImage
          })`,
          marginRight: "25px",
          marginLeft: "28px",
        }}
        // onClick={hightestRated}
        onClick={() => {
          history.push(
            `/restaurant-detail/${widgetData?.highest_rated?.restaurant_id}?view=overall`
          );
        }}
      >
        <h4 className="rated-name cursor-pointer">
          {widgetData?.highest_rated?.name}
        </h4>
        <div className="rating-container">
          <div className="rating-core">
            <div className="like-dislike-icon">
              <i className="fas fa-thumbs-up" />
            </div>
            <span className="rating-title">Highest Rated</span>
          </div>
          <div className="concrete-rating-container">
            <div className="concrete-rating-icon">
              {widgetData?.highest_rated?.rating && (
                <i className="fas fa-star" />
              )}
            </div>
            <span className="concrete-rating-number">
              {widgetData?.highest_rated?.rating}
            </span>
          </div>
        </div>
      </div>

      <div
        className="rated-item cursor-pointer"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${
            widgetData?.lowest_rated?.image
              ? widgetData?.lowest_rated?.image
              : defaultFoodImage
          })`,
        }}
        // onClick={lowestRated}
        onClick={() => {
          history.push(
            `/restaurant-detail/${widgetData?.lowest_rated?.restaurant_id}?view=overall`
          );
        }}
      >
        <h4 className="rated-name cursor-pointer">
          {widgetData?.lowest_rated?.name}
        </h4>
        <div className="rating-container">
          <div className="rating-core">
            <div className="like-dislike-icon">
              <i className="fas fa-thumbs-down" />
            </div>
            <span className="rating-title">Lowest Rated</span>
          </div>
          <div className="concrete-rating-container">
            <div className="concrete-rating-icon">
              {widgetData?.lowest_rated?.rating && (
                <i className="fas fa-star" />
              )}
            </div>
            <span className="concrete-rating-number">
              {widgetData?.lowest_rated?.rating}
            </span>
          </div>
        </div>
      </div>

      <DrawerItem
        isActive={!!openDrawers[drawersOverall.dashboardDrawer]}
        closePanel={() => closeDrawer(drawersOverall.dashboardDrawer)}
      />
    </RatedListContainer>
  );
};
