import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { OrdersContext } from "context/OrdersContext";
import React, { useContext, useState, useEffect } from "react";
import { DrawerDeleteReviewContainer, Label } from "./style";
import DrawerAddTip from "../DrawerAddTip";

export default function DrawerProcessPaymentAreYouSureContainer({
  isActive = false,
  closePanel = () => {},
  paymentBycard = () => {},
  paymentByAddCard = () => {},
}) {
  const {
    orderDetailData,
    selectedCardDetails,
    paymentDrawers,
    setPaymentDrawers,
  } = useContext(OrdersContext);

  const [addedTip, setAddedTip] = useState("");

  useEffect(() => {
    if (isActive) {
      setAddedTip("");
    }
  }, [isActive]);

  const addTip = () => {
    setPaymentDrawers({ ...paymentDrawers, addTip: true });
  };

  const tipAmountRenderer = (total, tip) => {
    if (tip.type === "fixed") {
      return `$${total.toFixed(2)} + ${tip.value.toFixed(2)} Tip `;
    } else if (tip.type === "percentage") {
      let offValue = total - (total * tip.value) / 100;
      return `$${total.toFixed(2)} + ${offValue.toFixed(2)} Tip `;
    } else {
    }
  };
  const cardBrandNameRender = (CreditCardNumber) => {
    var re = {
      Electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      Maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      Dankort: /^(5019)\d+$/,
      Interpayment: /^(636)\d+$/,
      Unionpay: /^(62|88)\d+$/,
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      Mastercard: /^5[1-5][0-9]{14}$/,
      Amex: /^3[47][0-9]{13}$/,
      Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      Jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    };

    for (var key in re) {
      if (re[key].test(CreditCardNumber)) {
        return key;
      }
    }
  };

  const totalTipAmount = (total, tip) => {
    let tipValue;
    if (tip.type === "fixed") {
      tipValue = tip.value;
    } else if (tip.type === "percentage") {
      tipValue = total - (total * tip.value) / 100;
    } else {
    }
    if (tip !== "") {
      return `$${Number(total) + Number(tipValue)}`;
    } else {
      return `$${Number(total)}`;
    }
  };

  return (
    <DrawerDeleteReviewContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"0%"}
      left={"0%"}
      position="absolute"
      tipModalShown={paymentDrawers.addTip}
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <div className="delete-icon">
            <i className="fas fa-money-bill-wave-alt" />
          </div>
          <h3 style={{ marginBottom: "24px" }} className=" modal-title mt-5">
            Process Payment
          </h3>
          <div className="mt-2 selected-card-details d-flex justify-content-center align-items-center flex-column">
            <h5 className="opacity4"></h5>
            <Label
              size="15px"
              weight="normal"
              color="#fff"
              opacity="0.6"
              marginBottom="12px"
            >
              Amount to be charged:
            </Label>
            <Label size="36px" weight="bold" color="#fff" marginBottom="12px">
              {totalTipAmount(orderDetailData?.checkout_data?.total, addedTip)}
            </Label>
            {addedTip && addedTip !== "" ? (
              <div className="add-edit-tip-container" onClick={addTip}>
                <Label size="14px" weight="normal" color="#fff">
                  {tipAmountRenderer(
                    orderDetailData?.checkout_data?.total,
                    addedTip
                  )}
                </Label>
                <Label size="14px" weight="600" color="#ed2626" cursor>
                  Edit Tip
                </Label>
              </div>
            ) : (
              <div className="add-edit-tip-container" onClick={addTip}>
                <Label size="14px" weight="600" color="#ed2626" cursor>
                  Add a Tip
                </Label>
              </div>
            )}
          </div>
          <>
            {selectedCardDetails?.listType === 1 ? (
              <div className="selected-card-details d-flex justify-content-between align-items-center">
                <Label size="14px" weight="600" color="#fff">
                  {selectedCardDetails?.brand} {selectedCardDetails?.last4}
                </Label>
                <Label size="14px" weight="normal" color="#fff">
                  Expires &nbsp;
                  {`${
                    selectedCardDetails?.exp_month
                  } / ${selectedCardDetails?.exp_year?.toString().slice(-2)}`}
                </Label>
              </div>
            ) : (
              <div className="selected-card-details d-flex justify-content-between align-items-center">
                <Label size="14px" weight="600" color="#fff">
                  {cardBrandNameRender(selectedCardDetails?.number)} &nbsp;
                  {selectedCardDetails?.number?.toString().slice(-4)}
                </Label>
                <Label size="14px" weight="normal" color="#fff">
                  Expires &nbsp;
                  {`${
                    selectedCardDetails?.exp_month
                  } / ${selectedCardDetails?.exp_year?.toString().slice(-2)}`}
                </Label>
              </div>
            )}
          </>
          {/* {selectedCardDetails?.listType === 1 ||
          selectedCardDetails?.listType === 2 ? (
            <div className="selected-card-details d-flex justify-content-between align-items-center">
              <Label size="14px" weight="600" color="#fff">
                {selectedCardDetails?.brand} {selectedCardDetails?.last4}
              </Label>
              <Label size="14px" weight="normal" color="#fff">
                Expires &nbsp;
                {`${
                  selectedCardDetails?.exp_month
                } / ${selectedCardDetails?.exp_year?.toString().slice(-2)}`}
              </Label>
            </div>
          ) : (
            <></>
          )} */}
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mb-3"
              onClick={() => {
                if (selectedCardDetails.listType === 1) {
                  paymentBycard(selectedCardDetails, addedTip);
                } else if (selectedCardDetails.listType === 2) {
                  paymentByAddCard(selectedCardDetails, addedTip);
                }
              }}
            >
              <b>Process Payment</b>
            </MyDrawerButton>
            <MyDrawerButton type="gray" onClick={closePanel}>
              <b>Back</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
      <DrawerAddTip
        isActive={paymentDrawers.addTip}
        closePanel={() =>
          setPaymentDrawers({ ...paymentDrawers, addTip: false })
        }
        onAddTip={setAddedTip}
        tip={addedTip || {}}
      />
    </DrawerDeleteReviewContainer>
  );
}
