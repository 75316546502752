import { customAxios } from "helpers/custom-helpers/customAxios";
import { api } from "./apiDescriptor";

const handleOptions = (payload) => {
  return payload && Object.keys(payload).length > 0
    ? "?" +
        Object.entries(payload)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")
    : "";
};

const getCustomer = async (payload, status) => {
  const url = api.customer.getCustomer(handleOptions(payload), status);
  const result = await customAxios.get(url);
  return result;
  // await customAxios.get(api.customer.getCustomer(handleOptions(payload)));
};

// refactored version of getCustomer
const customer = async (payload, onOk, onFail) => {
  const { status, statusText, data } = await customAxios.get(
    api.customer.getCustomer(handleOptions(payload))
  );
  if (status === 200 && statusText === "OK") {
    onOk(data);
  } else {
    onFail();
  }
};

const getCustomerById = async (id) => await customAxios.get(`/customer/${id}`);

const updateCustomerById = async (id, customer) => {
  const url = `${api.customer.updateCustomerById}${id}`;
  const result = await customAxios.put(url, customer);
  console.log(result);
};

const getCustomerCardsById = async (customerId) => {
  const url = api.customer.getCustomerCardsByCustomerId(customerId);
  const { status, statusText, data } = await customAxios.get(url);

  if (status === 200 && statusText === "OK") {
    return data;
  } else {
    return [];
  }
};

const getCustomerLocationsById = async (customerId) => {
  const url = api.customer.getCustomerLocationsByCustomerId(customerId);
  const { status, statusText, data } = await customAxios.get(url);
  if (status === 200 && statusText === "OK") {
    return data;
  } else {
    return [];
  }
};

const getCustomerCardById = async (customerId, cardId, onOk, onFail) => {
  const url = api.customer.getCustomerCardByCustomerId(customerId, cardId);
  const { status, statusText, data } = await customAxios.get(url);
  if (status === 200 && statusText === "OK") {
    onOk(data);
  } else {
    onFail();
  }
};

const createCustomerCard = async (
  customerId,
  newCard,
  onOk = () => {},
  onFail = () => {}
) => {
  const url = api.customer.createCard(customerId);
  const { status, statusText, ...c } = await customAxios.post(url, newCard);
  if (status === 200 && statusText === "OK") {
    onOk(c);
  } else {
    onFail(c);
  }
};

const updateCustomerCard = async (
  customerId,
  cardId,
  updatedCard,
  onOk,
  onFail
) => {
  const url = api.customer.updateCustomerCard(customerId, cardId);
  const { status, statusText, response } = await customAxios.put(
    url,
    updatedCard
  );
  if (status === 200 && statusText === "OK") {
    onOk();
  } else {
    onFail(response.data.message);
  }
};

const deleteCustomerCard = async (customerId, cardId) => {
  const url = api.customer.deleteCustomerCard(customerId, cardId);
  const { status, statusText } = await customAxios.delete(url);
  if (status === 200 && statusText === "OK") {
    return true;
  } else {
    return false;
  }
};

const createCustomerLocation = async (
  customerId,
  newLocation,
  onOk,
  onFail
) => {
  const url = api.customer.createCustomerLocation(customerId);
  const { status, statusText, response } = await customAxios.post(
    url,
    newLocation
  );

  if (status === 200 && statusText === "OK") {
    onOk();
  } else {
    onFail(response.data.message);
  }
};

const updateCustomerLocation = async (
  customerId,
  locationId,
  updatedLocation,
  onOk,
  onFail
) => {
  const url = api.customer.updateCustomerLocation(customerId, locationId);
  const { status, statusText, response } = await customAxios.put(
    url,
    updatedLocation
  );
  if (status === 200 && statusText === "OK") {
    onOk();
  } else {
    onFail(response.data.message);
  }
};

const deleteCustomerLocation = async (customerId, locationId, onOk, onFail) => {
  const url = api.customer.deleteLocation(customerId, locationId);
  const { status, statusText } = await customAxios.delete(url);
  if (status === 200 && statusText === "OK") {
    return onOk(locationId);
  } else {
    return onFail();
  }
};

const deleteCustomer = async (payload, onOk = () => {}) => {
  const { status, statusText } = await customAxios.delete("/customer", {
    data: { customer_ids: payload },
  });
  if (status === 200 && statusText === "OK") {
    onOk(payload);
  }
};

const setCustomerStatus = async (
  { payload = [], status = 0, note },
  onOk = () => {}
) => {
  const { status: requestStatus, statusText } = await customAxios.put(
    "/customer/status",
    {
      customer_ids: payload,
      status,
      note,
    }
  );
  if (requestStatus === 200 && statusText === "OK") {
    onOk(payload);
  }
};
const getCustomersStats = async () => await customAxios.get("/customer/stats");
const getSearchSuggestions = async ({ query, limit }) =>
  await customAxios.get(`/customer/suggestion?limit=${limit}&query=${query}`);

export const customerServices = {
  getCustomer,
  customer,
  getCustomerById,
  updateCustomerById,
  getCustomerCardsById,
  getCustomerLocationsById,
  getCustomerCardById,
  createCustomerCard,
  updateCustomerCard,
  deleteCustomerCard,
  createCustomerLocation,
  updateCustomerLocation,
  deleteCustomerLocation,
  deleteCustomer,
  setCustomerStatus,
  getCustomersStats,
  getSearchSuggestions,
};
