import styled from "@emotion/styled";
import { MyDrawerCenterModal } from "components/Elements/MyDrawerCenterModal";
import { colors } from "theme";

export const DrawerCheckExistsCategoryContainer = styled(MyDrawerCenterModal)`
  text-align: center;

  .img-wrapper {
    position: absolute;
    bottom: 100%;
    width: 100%;
    margin-left: -27px;
  }

  .cancel-btn {
    margin-top: 10px;
  }

  .confirmation-delete {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
  }

  .bottom-btns {
    width: 100%;
    background: ${colors.redColor};
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
  }
`;
