import React, { useContext } from "react";
import { DrawerContainer } from "components/styledStyle";
import { DrawerPaymentCardContainer } from "./style";
import { CustomersContext } from "context/CustomersContext";
import { FaChevronLeft } from "react-icons/fa";
import MyButton from "components/Elements/MyButton";
import DeletePaymentModal from "./DrawerDeletePaymentModal";
import { drawersOverall } from "static/staticData";

export default function DrawerPaymentCard({
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    profileData,
    editCustomerData,
  } = useContext(CustomersContext);

  // console.log(editCustomerData);

  return (
    <DrawerPaymentCardContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Payment Methods</div>
          <div></div>
        </div>
        <div className="payment-list mb-auto mt-4">
          {[{}].map((card, index) => {
            return (
              <div key={index} className="payment-list-item" onClick={() => {}}>
                <div className="payment-info">
                  <div className="text-bold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      style={{ height: "20px", fill: "#60646C" }}
                    >
                      <path d="M470.1 231.3s7.6 37.2 9.3 45H446c3.3-8.9 16-43.5 16-43.5-.2.3 3.3-9.1 5.3-14.9l2.8 13.4zM576 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48zM152.5 331.2L215.7 176h-42.5l-39.3 106-4.3-21.5-14-71.4c-2.3-9.9-9.4-12.7-18.2-13.1H32.7l-.7 3.1c15.8 4 29.9 9.8 42.2 17.1l35.8 135h42.5zm94.4.2L272.1 176h-40.2l-25.1 155.4h40.1zm139.9-50.8c.2-17.7-10.6-31.2-33.7-42.3-14.1-7.1-22.7-11.9-22.7-19.2.2-6.6 7.3-13.4 23.1-13.4 13.1-.3 22.7 2.8 29.9 5.9l3.6 1.7 5.5-33.6c-7.9-3.1-20.5-6.6-36-6.6-39.7 0-67.6 21.2-67.8 51.4-.3 22.3 20 34.7 35.2 42.2 15.5 7.6 20.8 12.6 20.8 19.3-.2 10.4-12.6 15.2-24.1 15.2-16 0-24.6-2.5-37.7-8.3l-5.3-2.5-5.6 34.9c9.4 4.3 26.8 8.1 44.8 8.3 42.2.1 69.7-20.8 70-53zM528 331.4L495.6 176h-31.1c-9.6 0-16.9 2.8-21 12.9l-59.7 142.5H426s6.9-19.2 8.4-23.3H486c1.2 5.5 4.8 23.3 4.8 23.3H528z" />
                    </svg>
                  </div>
                  <div className="text-content">Visa 9348</div>
                </div>

                <div className="payment-info">
                  <div className="opacity6 text-bold d-flex text-content">
                    Ex 12/20
                  </div>
                  <div
                    className="opacity6"
                    onClick={() => openDrawer(drawersOverall.deletePayment)}
                  >
                    <i className="fas fa-trash-alt" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <MyButton className="done-btn" type={"red"} onClick={closePanel}>
          Done
        </MyButton>
      </DrawerContainer>
      <DeletePaymentModal
        // isActive={true}
        isActive={!!openDrawers[drawersOverall.deletePayment]}
        closePanel={() => closeDrawer(drawersOverall.deletePayment)}
      />
    </DrawerPaymentCardContainer>
  );
}
