import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "helpers";

export function ScrollTop() {
  return (
    <div
      id="kt_scrolltop"
      className="scrolltop"
      style={{ background: "#ed2626", opacity: 1 }}
    >
      <span className="svg-icon">
        <i className="far fa-chevron-up" style={{ color: "#fff" }} />
      </span>{" "}
    </div>
  );
}
