import styled from "@emotion/styled";
import { MyDrawerBottom } from "components/Elements/MyDrawerBottom";

export const DrawerDeleteStaffConfirmContainer = styled(MyDrawerBottom)`
  .main-title {
    margin-top: 10px;
  }

  .text-font {
    font-size: 14px;
    margin-bottom: 31px;
  }

  .bottom-btns {
    padding: 0 !important;
    .move-item-btn {
      margin-bottom: 20px;
    }
    .cancel-btn {
      color: #ed2626 !important;
      font-weight: 600;
      margin-top: 20px;
    }
  }
`;
