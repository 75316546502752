import MyButton from "components/Elements/MyButton";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { colors } from "theme";
import { ProfileCardStatContainer } from "./style";
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";

export default function RestaurantProfileCardStat({ className = "", profile }) {
  const { id } = useParams();
  const [restaurantSales, setRestaurantSales] = useState({});
  const [period, setPeriod] = useState("day");

  useEffect(() => {
    if (profile?.status !== 3 && profile?.status !== undefined) {
      restaurantSalesData();
    }
  }, [period, profile?.status]);

  function restaurantSalesData() {
    customAxios
      .get(`/restaurant/${id}/sales?period=${period}`)
      .then((res) => {
        setRestaurantSales(res?.data);
      })
      .catch((err) => {});
  }

  return (
    <ProfileCardStatContainer className={`${className}`}>
      <div className="sales-wrapper">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: "0 37px",
            alignItems: "center",
          }}
        >
          <h3 className="sales-title">Statistics</h3>
          <div className="time-periods">
            <div
              className={`period-filter pointer ${
                period === "day" ? "selected-tab" : ""
              }`}
              onClick={() => setPeriod("day")}
            >
              Today
            </div>
            <div
              className={`period-filter pointer ${
                period === "week" ? "selected-tab" : ""
              }`}
              onClick={() => setPeriod("week")}
            >
              Week
            </div>
            <div
              className={`period-filter pointer ${
                period === "month" ? "selected-tab" : ""
              }`}
              onClick={() => setPeriod("month")}
            >
              Month
            </div>
            <div
              className={`period-filter pointer ${
                period === "total" ? "selected-tab" : ""
              }`}
              onClick={() => setPeriod("total")}
            >
              Total
            </div>
          </div>
        </div>

        <Container className="sales-container">
          <Row className="first-row">
            <Col>
              <div>
                <div className="sales-label">
                  <span className="shadow-text">Total Sales</span>{" "}
                  {restaurantSales?.total_sales?.change === undefined ? null : (
                    <>
                      {/* <span>
                        {restaurantSales?.total_sales?.change >= 0 ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )}{" "}
                      </span> */}
                      {/* <span
                        style={{
                          color:
                            restaurantSales?.total_sales?.change >= 0
                              ? "#1fc086"
                              : "#f7a64c",
                        }}
                      >
                        <b>{Math.abs(restaurantSales?.total_sales?.change)}%</b>
                      </span> */}
                    </>
                  )}
                </div>
                <div className="sales-numbers">
                  $
                  {restaurantSales?.total_sales?.current === null
                    ? 0
                    : restaurantSales?.total_sales?.current}
                </div>
              </div>
            </Col>

            <Col>
              <div>
                <div className="sales-label">
                  <span className="shadow-text">Average Order</span>{" "}
                  {restaurantSales?.average_order?.change ===
                  undefined ? null : (
                    <>
                      {/* <span>
                        {restaurantSales?.average_order?.change >= 0 ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )}{" "}
                      </span> */}
                      {/* <span
                        style={{
                          color:
                            restaurantSales?.average_order?.change >= 0
                              ? "#1fc086"
                              : "#f7a64c",
                        }}
                      >
                        <b>
                          {Math.abs(restaurantSales?.average_order?.change)}%
                        </b>
                      </span> */}
                    </>
                  )}
                </div>
                <div className="sales-numbers">
                  $
                  {restaurantSales?.average_order?.current === null
                    ? 0
                    : restaurantSales?.average_order?.current}
                </div>
              </div>
            </Col>

            <Col style={{ paddingLeft: "4%" }}>
              <div>
                <div className="sales-label">
                  <span className="shadow-text">Items Sold</span>{" "}
                  {restaurantSales?.items_sold?.change === undefined ? null : (
                    <>
                      {/* <span>
                        {restaurantSales?.items_sold?.change >= 0 ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )}{" "}
                      </span>
                      <span
                        style={{
                          color:
                            restaurantSales?.items_sold?.change >= 0
                              ? "#1fc086"
                              : "#f7a64c",
                        }}
                      >
                        <b>{Math.abs(restaurantSales?.items_sold?.change)}%</b>
                      </span> */}
                    </>
                  )}
                </div>
                <div className="sales-numbers">
                  {restaurantSales?.items_sold?.current === null
                    ? 0
                    : restaurantSales?.items_sold?.current}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="second-row">
            <Col>
              <div>
                <div className="sales-label">
                  <span className="shadow-text">Orders</span>{" "}
                  {restaurantSales?.total_orders?.change ===
                  undefined ? null : (
                    <>
                      {/* <span>
                        {restaurantSales?.total_orders?.change >= 0 ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )}{" "}
                      </span>
                      <span
                        style={{
                          color:
                            restaurantSales?.total_orders?.change >= 0
                              ? "#1fc086"
                              : "#f7a64c",
                        }}
                      >
                        <b>
                          {Math.abs(restaurantSales?.total_orders?.change)}%
                        </b>
                      </span> */}
                    </>
                  )}
                </div>
                <div className="sales-numbers">
                  {restaurantSales?.total_orders?.current === null
                    ? 0
                    : restaurantSales?.total_orders?.current}
                </div>
              </div>
            </Col>

            <Col>
              <div>
                <div className="sales-label">
                  <span className="shadow-text">Guests</span>{" "}
                  {restaurantSales?.guest?.change === undefined ? null : (
                    <>
                      {/* <span>
                        {restaurantSales?.guest?.change >= 0 ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )}{" "}
                      </span>
                      <span
                        style={{
                          color:
                            restaurantSales?.guest?.change >= 0
                              ? "#1fc086"
                              : "#f7a64c",
                        }}
                      >
                        <b>{Math.abs(restaurantSales?.guest?.change)}%</b>
                      </span> */}
                    </>
                  )}
                </div>
                <div className="sales-numbers">
                  {restaurantSales?.guest?.current === null
                    ? 0
                    : restaurantSales?.guest?.current}
                </div>
              </div>
            </Col>

            <Col style={{ paddingLeft: "4%" }}>
              <div>
                <div className="sales-label">
                  <span className="shadow-text">Staff</span>{" "}
                  {restaurantSales?.staff?.change === undefined ? null : (
                    <>
                      {/* <span>
                        {restaurantSales?.staff?.change >= 0 ? (
                          <FaArrowCircleUp color={colors.green} />
                        ) : (
                          <FaArrowCircleDown color={colors.orange} />
                        )}{" "}
                      </span>
                      <span
                        style={{
                          color:
                            restaurantSales?.staff?.change >= 0
                              ? "#1fc086"
                              : "#f7a64c",
                        }}
                      >
                        <b>{Math.abs(restaurantSales?.staff?.change)}%</b>
                      </span> */}
                    </>
                  )}
                </div>
                <div className="sales-numbers">
                  {restaurantSales?.staff?.current === null
                    ? 0
                    : restaurantSales?.staff?.current}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ProfileCardStatContainer>
  );
}

const StatItem = ({ title = "", value, percent = 0 }) => {
  return (
    <div className="profile-stat-row">
      <h5 className="profile-stat-row-label">
        <span className="opacity6">{title}</span>
        <span className="percent text-green">
          <FaArrowCircleUp className="mr-1" color={colors.green} />
          {percent}%
        </span>
      </h5>
      <h1 className="profile-stat-row-value">{value}</h1>
    </div>
  );
};
