import React, { useState } from "react";
import { DiscardChangesModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { MobileImage } from "assets/img";
import NumberFormat from "react-number-format";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DiscardChangesModal = ({
  isActive = false,
  closePanel = () => {},
  onDone,
}) => {
  return (
    <DiscardChangesModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="enter-new-number">
          <div className="modal-image">
            <div className="round-circle">
              <i
                className="fas fa-question"
                style={{
                  fontSize: "24px",
                  color: "#fff",
                }}
              />
            </div>
          </div>
          <div className="modal-title-new-number">Are you sure?</div>
          <div className="modal-title-new-number-meta">
            You are about to leave this page. All unsaved changes will be lost.
            Are you sure?
          </div>
          <div className="new-number-action-btns">
            <MyDrawerButton type="red" onClick={closePanel}>
              <b>Continue Editing</b>
            </MyDrawerButton>
            <MyDrawerButton type="gray" onClick={onDone}>
              <b>Discard Changes</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DiscardChangesModalContainer>
  );
};

export default DiscardChangesModal;
