import styled from "@emotion/styled";
import { colors } from "theme";

export const PromoCodesPageContainer = styled.div`
  .promo-code-wrapper {
    padding: 52px 58px 0 58px;

    .promo-code-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .promo-code-title {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 26px;
      }

      .filter-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        .promo-search {
          background: #27282a;
          border-radius: 40px;
          border: 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          padding: 12px 18px;
          cursor: pointer;
        }
        .search-pane {
          cursor: pointer;
          margin-right: 15px;
          height: 40px;
          border-radius: 25px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          -webkit-transition: border-color 500ms ease-out;
          -moz-transition: border-color 500ms ease-out;
          -o-transition: border-color 500ms ease-out;
          transition: border-color 500ms ease-out;

          &:hover {
            border: none;
          }
        }

        .add-promo-btn {
          background: #ed2626;
          border: 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-radius: 25px;
          padding: 11px 24px;
          color: #fff;
          height: 40px;
        }

        .cancel-btn {
          width: 100%;
          border-radius: 11px;
          background: transparent;
          color: #ed2626;
          margin-top: 10px;

          &:hover {
            color: #ed2626;
          }
        }

        .search-container {
          padding: 5px 9px !important;
          background: #27282a;
          border: 0;

          .clear-search {
            opacity: 1;
          }
        }

        .filter-btn-wrapper {
          width: 50px;
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 25px;
          justify-content: center;
          background-color: #27282a;
          position: relative;
          i {
            cursor: pointer;
          }
        }

        .active {
          &::after {
            content: "";
            position: absolute;
            top: 20%;
            right: 30%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ed2626;
            z-index: 99;
          }
        }

        .filter-container {
          position: absolute;
          top: 110%;
          right: 0;
          width: 250px;
          height: max-content;
          padding: 20px 15px 15px;
          border-radius: 25px;
          border: solid 1px #27282a;
          background-color: #27282a;
          z-index: 99;

          .filter-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              opacity: 0.4;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
            }

            button {
              background: transparent;
              border: 0;
              font-size: 14px;
              font-weight: 600;
              color: #ed2626;
            }
          }
          .filter-body {
            margin: 24px 0;

            .filter-item {
              margin-bottom: 24px;
              color: #fff;
              font-size: 14px;
              font-weight: 600;

              .filter-item-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .clear-filter-btn {
                  color: #ed2626;
                  background: transparent;
                  border: none;
                  font-size: 14px;
                  font-weight: 700;
                }

                i {
                  cursor: pointer;
                  opacity: 0.4;
                  font-size: 16px;
                }
              }

              .rc-slider-track {
                background-color: #ed2626 !important;
              }

              .rc-slider-handle {
                border: 5px solid #ed2626 !important;
              }

              .rc-slider-handle-dragging {
                box-shadow: 0 0 0 5px #ed2626;
              }
              .filter-item-body {
                margin-top: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 45px;
              }
            }
          }

          .apply-btn {
            width: 100%;
            border-radius: 11px;
          }
        }
      }
    }

    .promo-codes-tab {
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 12px;
        thead {
          color: rgba(255, 255, 255, 0.3);
          tr {
            th {
              /* cursor: pointer; */
              font-size: 14px;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.5);
              padding: 10px 0;
              i {
                margin-left: 5px;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.5);
                font-size: 11px;
              }
            }
            th:first-of-type {
              padding-left: 31px;
            }
            th:last-child {
              padding-right: 30px;
              text-align: end;
            }
          }
        }
        tbody {
          background-color: #151617;
          tr {
            background-color: #1b1c1d;
            margin-bottom: 12px;
            border-radius: 8px;

            /* material-ui switch custom css */
            .MuiIconButton-label {
              color: #fff;
            }

            .MuiSwitch-track {
              background-color: #5f626a;
              opacity: unset;

              position: absolute;
              width: 45px;
              height: 25px;
              top: 7px;
              left: 6px;
              border-radius: 20px;
            }

            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
              background-color: rgb(30, 206, 109) !important;
              opacity: unset;
            }

            td {
              padding: 20px 0;
              // width: 12.5%;
              color: #fff;

              &:first-of-type {
                width: 14%;
              }

              &:nth-of-type(2) {
                width: 13%;
              }

              &:nth-of-type(3) {
                width: 14%;
              }

              &:nth-of-type(4) {
                width: 11%;
              }

              &:nth-of-type(5) {
                width: 10%;
              }

              &:nth-of-type(6) {
                width: 13%;
              }
              &:nth-of-type(7) {
                width: 7%;
              }
              &:nth-of-type(8) {
                width: 9%;
              }
              &:nth-of-type(9) {
                width: 7%;
              }
              &:nth-of-type(10) {
                width: 2%;
              }

              &.text-container-big {
                font-size: 16px;
                font-weight: 500;
                color: #ffffff;
              }
              &.text-container-normal {
                font-size: 14px;
                font-weight: normal;
                color: #ffffff;
                .custom-badge {
                  color: #fff;
                  padding: 3px 10px 3px 10px;
                  width: max-content;
                  border-radius: 30px;
                  background-color: #242526;
                }
              }
              .staff-avatar {
                width: 50px;
                height: 50px;
                border-radius: 49px;
              }

              .name-container {
                display: flex;
                align-items: center;

                .staff-avatar-title {
                  font-size: 14px;
                  font-weight: bold;
                  color: white;
                }
              }

              .status-title {
                font-size: 14px;
              }

              .ratings {
                display: flex;
                align-items: center;

                .ratings-icon {
                  color: ${colors.rating};
                }
              }
            }
            td:first-of-type {
              padding-left: 30px;
            }
            td:last-child {
              padding-right: 40px;
              text-align: end;
            }
          }
          tr:first-of-type {
            td:first-of-type {
              border-top-left-radius: 8px;
            }
            td:last-child {
              border-top-right-radius: 8px;
            }
          }
          tr:last-child {
            td:first-of-type {
              border-bottom-left-radius: 8px;
            }
            td:last-child {
              border-bottom-right-radius: 8px;
            }
          }
          tr:nth-of-type(odd) {
            td {
              background-color: #1b1c1d;
            }
          }
          tr:nth-of-type(even) {
            td {
              background-color: rgba(0, 0, 0, 0.08);
            }
          }
        }
      }

      .search-no-result-wrapper {
        .search-applied {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .applied-text {
            font-size: 16px;
            font-weight: 600;
          }
          .result-text {
            font-size: 16px;
          }
        }
        .no-result {
          margin-top: 140px;
          text-align: center;
          .no-result-text {
            margin-top: 32px;
            font-size: 24px;
            font-weight: bold;
          }
          .broadening-title {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 500;
            opacity: 0.6;
          }
        }
      }

      .add-promo-btn {
        background: #ed2626;
        border-radius: 40px;
        border: 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 10px 10px;
        cursor: pointer;
      }

      .view-all {
        background: #60646c;
        border-radius: 40px;
        border: 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 10px 10px;
        cursor: pointer;
      }

      .nav {
        background: #27282a;
        border-radius: 30px;
        width: 25rem !important;
        display: flex;
        justify-content: space-between;
      }

      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #ed2626;
        text-align: center;
        border-radius: 30px;
        width: 12rem !important;
      }

      a.nav-link {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: 600;
        text-align: center !important;
        width: 12rem !important;
      }

      .nav.nav-pills .show > .nav-link,
      .nav.nav-pills .nav-link.hover {
        color: unset;
      }

      nav .show > .nav-link,
      .nav .nav-link:hover:not(.disabled),
      .nav .nav-link.active {
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
        color: #fff;
      }

      .nav.nav-pills .nav-link {
        color: #b5b5c3;
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
        position: relative;
        font-weight: bold;
        color: white;
        padding-left: 50px;
      }

      .add-promo-container {
        background-color: #27282a;
        padding: 20px;
        border-radius: 20px;
        height: 15.6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        height: 168.5px;
      }

      .custom-card-container {
        background-color: #1b1c1d;
        padding: 20px;
        border-radius: 20px;
        .whole-flex {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .flex-title-batch {
            display: flex;
            gap: 20px;

            .title {
              font-size: 17px;
              font-weight: bold;
              color: #fff;
            }
            .badge {
              background: #3e3e3e;
              border-radius: 12px;
              display: flex;
              align-items: center;
            }
          }
        }

        .date-time-container {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .date {
            opacity: 0.6;
            font-size: 14px;
          }
          .time {
            opacity: 0.6;
            font-size: 14px;
          }
        }

        .requirement {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .order {
            opacity: 0.6;
            font-size: 14px;
          }
          .used {
            opacity: 0.6;
            font-size: 14px;
          }
        }

        .percentage {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .percentage-digit {
            color: #1fc085;
            font-size: 17px;
            font-weight: bold;
          }
          .value {
            opacity: 0.6;
            font-size: 14px;

            .amount {
              font-weight: bold;
            }
          }
        }
      }

      .empty-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 68vh;
        img {
          width: auto;
          height: 160px;
          object-fit: contain;
          margin-bottom: 30px;
          width: 528px;
          height: 300px;
          object-fit: contain;
        }
        .empty-screen {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          margin-bottom: 24px;
        }
        .add-promo-btn {
          border-radius: 10px;
          padding: 15px 65px 15px 65px;
        }
      }
    }
  }
`;
