import React from "react";
import { StatItemContainer } from "./style";

export default function StatItem({
  title = "",
  subTitle = "",
  icon = "",
  value = 0,
  subIcon = "",
  defaultFeasttFeeModalHandler = () => {},
  image = "",
}) {
  return (
    <StatItemContainer
      className="d-flex flex-column justify-content-between"
      width={subIcon && "300px"}
      backGroundImage={image}
      bottom={title === "Active Restaurants" ? true : false}
    >
      <div className=" d-flex justify-content-between align-items-center">
        <p className="stat-item-header">
          {icon}
          <span className="stat-item-message">{title}</span>
        </p>
        {subIcon ? (
          <p
            className="stat-item-footer-period text-white-50 opacity6 cursor-pointer"
            onClick={() => {
              defaultFeasttFeeModalHandler();
            }}
          >
            {subIcon}
          </p>
        ) : (
          <p className="stat-item-footer-period text-white-50">{subTitle}</p>
        )}
      </div>
      <div className="stat-item-footer d-flex justify-content-between align-items-center">
        <p className="stat-item-footer-counter mb-0">
          {value}
          {subIcon && "%"}
        </p>
      </div>
    </StatItemContainer>
  );
}
