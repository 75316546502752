import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerAddedItemsSuccessContainer = styled(MyDrawerRight)`
  .added-existing-item-img {
    width: 380px;
    height: 305px;
    margin-bottom: 20px;
  }
  .added-existing-item-meta {
    .added-existing-item-meta-text {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: 0.42px;
      text-align: center;
      color: #fff;
    }
    margin-bottom: 20px;
  }
  .added-existing-item-btn {
    width: 100%;
  }
`;
