import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import React, { useState, useEffect, useContext } from "react";
import { DrawerDeleteStaffConfirmContainer } from "./style";
import { useParams } from "react-router-dom";
import { showMessage } from "services/functions";

export default function DrawerDeleteStaffConfirm({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const {
    postStaff,
    staffDetails,
    setStaffCloseDrawer,
    staffCloseDrawer,
    setStaffs,
  } = useContext(RestaurantsContext);

  const { id } = useParams();

  useEffect(() => {}, [id]);

  function handleClickStaffRemove() {
    if (postStaff?.is_owner === true) {
      showMessage("You cant remove Owner", "error");
    } else {
      customAxios
        .delete(`/restaurant/${id}/staff/${postStaff?.id}`)
        .then((res) => {
          if (res.status === 200) {
            getStaffDataList();
            setStaffCloseDrawer({
              ...staffCloseDrawer,
              staffDelete: false,
              staffDeleteConfirm: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  // pending staff remove
  function removePendingStaff() {
    customAxios
      .delete(`/restaurant/${id}/invitation/${postStaff?.id}`)
      .then((res) => {
        if (res.status === 200) {
          setStaffCloseDrawer({
            ...staffCloseDrawer,
            staffDelete: false,
            staffDeleteConfirm: false,
          });
          getStaffDataList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function checkStatus(checkStatus) {
    if (checkStatus === undefined) {
      removePendingStaff();
    } else {
      handleClickStaffRemove();
    }
  }

  function getStaffDataList() {
    customAxios
      .get(`/restaurant/${id}/staff`)
      .then((res) => {
        setStaffs([
          ...res?.data?.current_staff?.staff,
          // ...res?.data?.pending_staff?.staff,
        ]);
      })
      .catch((err) => console.error(err));
  }

  return (
    <DrawerDeleteStaffConfirmContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left main-title">
          Are you sure?
        </h2>
        {postStaff?.serving_status === undefined ? (
          <p className="text-white text-left text-font">
            <span className="text-bold">
              You will no longer be able to access their account.
            </span>
          </p>
        ) : (
          <p className="text-white text-left text-font">
            <span className="text-bold">{`${postStaff?.first_name} ${postStaff?.last_name}`}</span>{" "}
            will no longer be able to access their account.
          </p>
        )}

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="text-bold move-item-btn"
            // onClick={handleClickStaffRemove}
            onClick={() => checkStatus(postStaff?.serving_status)}
          >
            Yes, remove account
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold"
            onClick={() => {
              setStaffCloseDrawer({
                ...staffCloseDrawer,
                staffDeleteConfirm: false,
              });
            }}
          >
            Keep
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDeleteStaffConfirmContainer>
  );
}
