  import React, { useContext, useState, useEffect } from "react";
  import { DrawerNutritionsContainer } from "./style";
  import { DrawerContainer } from "components/styledStyle";
  import { FaChevronLeft, FaMinusCircle } from "react-icons/fa";
  import { colors } from "theme";
  import { RestaurantsContext } from "context/RestaurantsContext";
  import { drawersMenu } from "static/staticData";

  export default function DrawerNutritions({
    profile = {},
    isActive = false,
    closePanel = () => {},
  }) {
    const {
      nutritionPost,
      setNutritionPost,
      setPostFood,
      postFood,
      openDrawers,
      openDrawer,
      closeDrawer,
    } = useContext(RestaurantsContext);
    const [isExpandInput, setIsExpandInput] = useState(false);

    const [nutrition, setNutrition] = useState({
      calories: "",
      totalFat: "",
      saturatedFat: "",
      transFat: "",
      sodium: "",
      totalCarbohydrate: "",
      dietaryFiber: "",
      totalSugars: "",
      addedSugars: "",
      protein: "",
      vitaminA: "",
      vitaminB: "",
      vitaminC: "",
      vitaminD: "",
      vitaminE: "",
      calcium: "",
      iron: "",
      potassium: "",
      cholesterol: "",
    });

    useEffect(() => {
      if (postFood && postFood?.nutrition) {
        let data = JSON.parse(postFood?.nutrition);
        if (data) {
          let editNeutritionData = {
            calories: data.calories || "",
            totalFat: data.totalFat || "",
            saturatedFat: data.saturatedFat || "",
            transFat: data.transFat || "",
            sodium: data.sodium || "",
            totalCarbohydrate: data.totalCarbohydrate || "",
            dietaryFiber: data.dietaryFiber || "",
            totalSugars: data.totalSugars || "",
            addedSugars: data.addedSugars || "",
            protein: data.protein || "",
            vitaminA: data.vitaminA || "",
            vitaminB: data.vitaminB || "",
            vitaminC: data.vitaminC || "",
            vitaminD: data.vitaminD || "",
            vitaminE: data.vitaminE || "",
            calcium: data.calcium || "",
            iron: data.iron || "",
            potassium: data.potassium || "",
            cholesterol: data.cholesterol || "",
          };
          setNutrition(editNeutritionData);
        }
      } else {
        let editNeutritionData = {
          calories: "",
          totalFat: "",
          saturatedFat: "",
          transFat: "",
          sodium: "",
          totalCarbohydrate: "",
          dietaryFiber: "",
          totalSugars: "",
          addedSugars: "",
          protein: "",
          vitaminA: "",
          vitaminB: "",
          vitaminC: "",
          vitaminD: "",
          vitaminE: "",
          calcium: "",
          iron: "",
          potassium: "",
          cholesterol: "",
        };
        setNutrition(editNeutritionData);
      }
    }, [postFood]);

    const handleChange = (e) => {
      if (e.target.name === "calories" && e.target.value.length <= 8) {
        const name = e.target.name;
        const value = e.target.value.replace(/[^0-9.]/gi, "");
        setNutrition((prev) => {
          return { ...prev, [name]: value };
        });
      } else if (e.target.value.length <= 8) {
        const name = e.target.name;
        const value = e.target.value;
        setNutrition((prev) => {
          return { ...prev, [name]: value };
        });
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      // console.log(nutrition);
      // setPostFood({...postFood, nutrition: nutrition})
      setPostFood({ ...postFood, nutrition: JSON.stringify(nutrition) });
      closePanel();
      // setNutritionPost(nutrition)
    };

    const onMinusHandle = () => {
    openDrawer(drawersMenu.hideNutrition)
    }

    return (
      <DrawerNutritionsContainer isActive={isActive} closePanel={closePanel}>
        <DrawerContainer>
          <div className="top-title">
            <div>
              <FaChevronLeft
                size={20}
                onClick={closePanel}
                className="cursor-pointer"
              />
            </div>
            <div className="title">Nutrition</div>
            <div></div>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Calories</label>
                  {nutrition?.calories !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="calories"
                  placeholder="Add Calories"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.calories || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Total Fat</label>
                  {nutrition?.totalFat !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="totalFat"
                  placeholder="Add Total Fat"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.totalFat || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Saturated Fat</label>
                  {nutrition?.saturatedFat !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="saturatedFat"
                  placeholder="Add Saturated Fat"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.saturatedFat || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Trans Fat</label>
                  {nutrition?.transFat !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="transFat"
                  placeholder="Add Trans Fat"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.transFat || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Cholesterol</label>
                  {nutrition?.cholesterol !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="cholesterol"
                  placeholder="Add Cholesterol"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.cholesterol || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Sodium</label>
                  {nutrition?.sodium !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="sodium"
                  placeholder="Add Sodium"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.sodium || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Total Carbohydrate</label>
                  {nutrition?.totalCarbohydrate !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="totalCarbohydrate"
                  placeholder="Add Total Carbohydrate"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.totalCarbohydrate || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Dietary Fiber</label>
                  {nutrition?.dietaryFiber !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="dietaryFiber"
                  placeholder="Add Dietary Fiber"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.dietaryFiber || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Total Sugars</label>
                  {nutrition?.totalSugars !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="totalSugars"
                  placeholder="Add Total Sugars"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.totalSugars || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Added Sugars</label>
                  {nutrition?.addedSugars !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="addedSugars"
                  placeholder="Add Added Sugars"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.addedSugars || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="input-label">Protein</label>
                  {nutrition?.protein !== "" ? (
                    <FaMinusCircle
                      size={15}
                      color={"#ffffff"}
                      className="circle-minus-icon cursor-pointer"
                      onClick={onMinusHandle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="text"
                  name="protein"
                  placeholder="Add Protein"
                  className="nutrition-input"
                  autoComplete="off"
                  value={nutrition?.protein || ""}
                  onChange={handleChange}
                />
                <div className="bottom-line" />
              </div>
            </div>

            {/* expand input */}
            {isExpandInput === true ? (
              <div className="expand-input">
                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Vitamin A</label>
                    {nutrition?.vitaminA !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="vitaminA"
                    placeholder="Add Vitamin A"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.vitaminA || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Vitamin B</label>
                    {nutrition?.vitaminB !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="vitaminB"
                    placeholder="Add Vitamin B"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.vitaminB || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Vitamin C</label>
                    {nutrition?.vitaminC !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="vitaminC"
                    placeholder="Add Vitamin C"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.vitaminC || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Vitamin D</label>
                    {nutrition?.vitaminD !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="vitaminD"
                    placeholder="Add Vitamin D"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.vitaminD || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Vitamin E</label>
                    {nutrition?.vitaminE !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="vitaminE"
                    placeholder="Add Vitamin E"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.vitaminE || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Calcium</label>
                    {nutrition?.calcium !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="calcium"
                    placeholder="Add Calcium"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.calcium || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Iron</label>
                    {nutrition?.iron !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="iron"
                    placeholder="Add Iron"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.iron || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>

                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="input-label">Potassium</label>
                    {nutrition?.potassium !== "" ? (
                      <FaMinusCircle
                        size={15}
                        color={"#ffffff"}
                        className="circle-minus-icon cursor-pointer"
                        onClick={onMinusHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <input
                    type="text"
                    name="potassium"
                    placeholder="Add Potassium"
                    className="nutrition-input"
                    autoComplete="off"
                    value={nutrition?.potassium || ""}
                    onChange={handleChange}
                  />
                  <div className="bottom-line" />
                </div>
              </div>
            ) : null}

            <p
              className="text-center text-bold show-more-expand cursor-pointer"
              onClick={() => setIsExpandInput(!isExpandInput)}
            >
              {isExpandInput === true ? "Show less" : "Show more"}
            </p>

            <div className="text-center">
              <div className="bottom-btns">
                <button type="submit" className="apply-btn">
                  Apply
                </button>
              </div>
            </div>

            <p className="text-center text-bold show-more-expand cursor-pointer">
              Reset
            </p>
          </form>
        </DrawerContainer>
      </DrawerNutritionsContainer>
    );
  }
