import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React, { useEffect, useState, useContext } from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerPartialRefundContainer } from "./style";
import { usePaymentAdditional } from "./usePaymentAdditional";
import { OrdersContext } from "context/OrdersContext";

const useStyles = makeStyles((theme) => ({
  textField: {
    maxWidth: 40,
  },
}));

export default function DrawerAddTip({
  isActive = false,
  closePanel = () => {},
  partialDiscountHandler,
  onAddTip,
  tip = {},
  isTipAvailable,
}) {
  const classes = useStyles();
  const { openDrawer, orderDetailData } = useContext(OrdersContext);
  const [dollars, setDollars] = useState("");
  const [percents, setPercents] = useState("");

  const [isCreateMode, setIsCreateMode] = useState(true);

  const tipTypes = {
    first: {
      value: "percentage",
      initial: 15,
    },
    second: {
      value: "fixed",
      initial: 10,
    },
  };

  const {
    isOther,
    enableOtherMode,
    disableOtherMode,

    paymentOption: tipOption,
    onChangePaymentOption: onChangeTipOption,
    paymentType: tipType,
    onChangePaymentType: onChangeTipType,
    isFirstType: isPercentageActive,
    isSecondType: isFixedActive,

    paymentAdditionalNowAvailable,
  } = usePaymentAdditional({
    paymentTypes: tipTypes,
    initialValueFromFirstType: "",
  });

  const onChangePercentageOption = (option) => {
    disableOtherMode();
    onChangeTipType(tipTypes.first.value);
    onChangeTipOption(option);
    setPercents(option);
  };

  const onSwitchToOtherAndSetPercentage = () => {
    enableOtherMode();
    onChangeTipType(tipTypes.first.value);
  };

  const add = () => {
    paymentAdditionalNowAvailable();
    onAddTip({
      type: tipType,
      value: isOther ? (isPercentageActive() ? percents : dollars) : tipOption,
      money: dollars,
    });
    closePanel();
  };

  useEffect(() => {
    if (isPercentageActive()) {
      // changing dollars when changing percents
      setDollars(Number(computeTip(percents, true)));
    } else if (isFixedActive()) {
      // changing percents when changing dollars
      setPercents(Number(computeTip(dollars, false)));
    }
  }, [dollars, percents, isFixedActive, isPercentageActive]);

  useEffect(() => {
    const setupExistingTip = () => {
      onChangeTipType(tip.type);
      if (tip.type === tipTypes.second.value) {
        setDollars(Number(tip.value));
        enableOtherMode();
      } else {
        if ([10, 15, 20].includes(Number(tip.value))) {
          onChangeTipOption(Number(tip.value));
          setPercents(Number(tip.value));
        } else {
          setPercents(tip.value);
          enableOtherMode();
        }
      }
    };

    if (isActive) {
      if (isCreateMode && isTipAvailable) {
        setupExistingTip();
        setIsCreateMode(false);
      }
    } else {
      setIsCreateMode(true);
    }
  }, [
    enableOtherMode,
    isCreateMode,
    isTipAvailable,
    onChangeTipOption,
    onChangeTipType,
    tip,
    tipTypes,
    isActive,
  ]);

  useEffect(() => {
    if (tip !== "" && Object.keys(tip).length !== 0) {
    } else {
      onChangeTipOption("");
      setDollars("");
      setPercents("");
    }
  }, [isActive]);

  const computeTip = (currentUserTip, isPercent = false) => {
    const totalToPay = orderDetailData?.checkout_data?.total;
    if (isPercent) {
      const dollars = (totalToPay * (currentUserTip / 100)).toFixed(2);
      return dollars;
    } else {
      const percents = ((currentUserTip / totalToPay) * 100).toFixed(2);
      return percents;
    }
  };

  const isButonDisabledHandler = () => {
    if (tipOption === "" && dollars === 0) {
      return true;
    } else if (tipOption !== "" && dollars === 0) {
      return false;
    } else if (tipOption === "" && dollars !== 0) {
      return false;
    } else if (tipOption !== "" && dollars !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <DrawerPartialRefundContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"0%"}
      left={"0%"}
      position="absolute"
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <h3 className="modal-title">Add a Tip</h3>
          <div className="tip-meta">100% of the tip goes to the server.</div>
          <div className="tip-options">
            {[10, 15, 20, 25].map((option) => (
              <div
                className={`tip-option ${
                  option === tipOption && isPercentageActive() && !isOther
                    ? "active"
                    : ""
                }`}
                onClick={() => onChangePercentageOption(option)}
                key={option}
              >
                <span className="tip-option-title">{option}%</span>
              </div>
            ))}
            <div
              className={`tip-option ${isOther ? "active" : ""}`}
              onClick={onSwitchToOtherAndSetPercentage}
            >
              <span className="tip-option-title">Other</span>
            </div>
          </div>
          {isOther ? (
            <div className="type-tip-container">
              <div
                className={`tip-type-container fixed ${
                  isFixedActive() ? "active" : ""
                }`}
                onClick={() => onChangeTipType(tipTypes.second.value)}
              >
                <span className="tip-type-title">Fixed Amount</span>
                <div className="tip-type-value-container">
                  <span className="tip-type-value">$</span>
                  <input
                    type="number"
                    value={dollars}
                    onChange={(event) => setDollars(Number(event.target.value))}
                    min="1"
                    maxLength={5}
                  />
                </div>
              </div>
              <div
                className={`tip-type-container percentage ${
                  isPercentageActive() ? "active" : ""
                }`}
                onClick={() => onChangeTipType(tipTypes.first.value)}
              >
                <span className="tip-type-title">Percentage</span>
                <div className="tip-type-value-container percents">
                  <input
                    type="number"
                    value={Math.trunc(percents)}
                    onChange={(event) =>
                      setPercents(Number(event.target.value))
                    }
                    min={1}
                  />
                  <span className="tip-type-value">%</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="total-tip-container">
            {percents || dollars ? (
              <span className="tip-title">
                {isPercentageActive()
                  ? `${isOther ? Math.trunc(percents) : tipOption}%`
                  : `$${dollars.toFixed(2)}`}{" "}
                will be added as a tip.
              </span>
            ) : (
              <span className="tip-title">Select a tip above.</span>
            )}

            <div className="tip-notice">
              Customer will see this tip on their bill.
            </div>
          </div>
          <div className="bottom-btns">
            <MyDrawerButton
              type="red"
              className="mb-3"
              onClick={add}
              disabled={isButonDisabledHandler()}
            >
              <b>Add Tip</b>
            </MyDrawerButton>
            <MyDrawerButton
              type="redColor"
              onClick={() => {
                closePanel();
                onChangeTipOption("");
                setDollars("");
                setPercents("");
              }}
            >
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerPartialRefundContainer>
  );
}
