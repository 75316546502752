import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { mocks } from "static/staticData";
import { DrawerDietaryTagsContainer, DrawerChooseItemContainer } from "./style";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";

export default function DrawerDietaryTags({
  profile = {},
  isActive = false,
  closePanel = () => {},
}) {
  const { postFood, setPostFood } = useContext(RestaurantsContext);
  const [dietaryTagsList, setDietaryTagsList] = useState();

  useEffect(() => {
    if (postFood) {
      setDietaryTagsList(postFood?.dietary);
    }
  }, [postFood]);

  const onChooseDietaryTag = (dietaryTag) => {
    let tempTags = dietaryTagsList ? [...dietaryTagsList] : [];
    if (tempTags.includes(dietaryTag)) {
      let fArray = tempTags.filter((_tag) => _tag !== dietaryTag);
      setDietaryTagsList(fArray);
    } else {
      tempTags.push(dietaryTag);
      setDietaryTagsList(tempTags);
    }
  };

  const onSetDietaryTags = () => {
    setPostFood({
      ...postFood,
      dietary: dietaryTagsList,
    });
    closePanel();
  };
  return (
    <DrawerDietaryTagsContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <div className="drawer-bottom-content w-100">
            <div className="top-title">
              <div className="title">Dietary Tags</div>
            </div>
            <div className="drawer-types-wrapper mb-auto">
              {Object.values(mocks.dietaryTagsMenus).map((_dietaryTag, _i) => (
                <DrawerChooseItemContainer
                  key={_i}
                  className={`drawer-choose--option cursor ${dietaryTagsList?.includes(
                    _dietaryTag.value
                  ) && "active"} mb-5`}
                  onClick={() => onChooseDietaryTag(_dietaryTag.value)}
                >
                  <p>
                    {_dietaryTag?.icon} &nbsp;{_dietaryTag?.name}
                  </p>
                  {_dietaryTag?.subtitle && (
                    <p className="choose-subtitle">{_dietaryTag?.subtitle}</p>
                  )}
                </DrawerChooseItemContainer>
              ))}
            </div>
            <div className="bottom-btns">
              <MyDrawerButton
                type={
                  dietaryTagsList === undefined || dietaryTagsList?.length === 0
                    ? "gray"
                    : "red"
                }
                className="text-bold mb-3 mt-5"
                onClick={onSetDietaryTags}
                disabled={
                  dietaryTagsList === undefined || dietaryTagsList?.length === 0
                }
              >
                <b>Done</b>
              </MyDrawerButton>
              <MyDrawerButton
                type="redColor"
                className="text-bold"
                onClick={closePanel}
              >
                <b>Cancel</b>
              </MyDrawerButton>
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerDietaryTagsContainer>
  );
}
