import styled from "@emotion/styled";
import { colors } from "theme";

export const TableItemContainer = styled.div`
  width: 100%;
  &:nth-of-type(odd) {
    .table-item {
      background-color: #18191a;
    }
  }
  &:last-child {
    .table-item {
      border-radius: 10px;
    }
  }
  .table-item {
    padding: 23px 30px;
    min-height: 65px;
    background-color: ${colors.gray2};
    &.is-header {
      background-color: transparent;
    }
    /* margin: 8px 0; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-col {
      flex: 1;
      text-align: left;
      .rating-wrapper {
        .rating-star {
          padding: 7px 6px 6px;
          border-radius: 15.5px;
          background-color: #ed2626;
        }
      }
      .active {
        font-size: 14px;
        font-weight: bold;
        color: #1fc086;
      }

      .inactive {
        font-size: 14px;
        font-weight: bold;
        color: rgb(96, 100, 108);
      }
    }
  }
`;
