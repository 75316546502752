import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerReviewServerContainer = styled(MyDrawerRight)`
  /* .header-bg {
    position: relative;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .close-drawer-icon {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      position: absolute;
      left: 23px;
      top: 23px;
      z-index: 999;
      .drawer-title {
        margin-left: 100px;
      }
    }
    .bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(5px);
      transform: scale(2);
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 30px;
      bottom: 0;
      background-color: #1b1c1d;
      border-radius: 30px 30px 0 0;
      z-index: 1;
    }
    .drawer-header-content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .food-name {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.58px;
        text-align: center;
        color: #fff;
        margin-top: 222px;
        z-index: 999;
      }
      img {
        width: 343px;
        height: 158px;
        border-radius: 30px;
        position: absolute;
        // margin-bottom: 42px;
      }
    }
  }
  .reviews-body {
    flex: 1;
    .empty-header {
      padding: 0 26px 0 26px;
      .sort-overall-review-count {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        text-align: left;
        margin-top: 19px;
        margin-bottom: 26px;
        color: #fff;
        .sort-overall-title {
          font-weight: normal !important;
        }
      }
    }
    .r-header {
      padding: 0 26px 0 26px;
      border-bottom: 1px solid #4d5053;
      .reviews-overall-rating {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.62px;
        color: #ffffff;
        .fa-star {
          color: #fdcb6e;
          font-size: 26px;
        }
      }
      .sort-dropdown {
        width: fit-content;
        height: 40px;
        padding: 11px 21px 11px 23px;
        border-radius: 25px;
        background-color: #27282a;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        .fa-angle-down {
          margin-left: 20px;
        }
      }
      .sort-overall-review-count {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        text-align: left;
        margin-top: 19px;
        margin-bottom: 26px;
        color: #fff;
        .sort-overall-title {
          font-weight: normal !important;
        }
      }
    }
    .review-list {
      // margin-top: 35px;
      .review-list-item {
        margin-top: 35px;

        padding: 0 26px 32px 26px;
        border-bottom: 1px solid #4d5053;
        .profile-info {
          .image-div {
            img {
              width: 50px;
              height: 52px;
            }
            .name-time-div {
              margin-left: 15px;
              .review-added-user-name {
                font-family: Montserrat;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.36px;
                color: #fff;
              }
              .review-added-time {
                font-family: Montserrat;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.36px;
                color: #fff;
              }
            }
          }
          .name-time-div {
          }
        }
        .review-meta {
          opacity: 0.8;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.36px;
          color: #fff;
          margin: 22px 0;
        }
        .feastt-review-numericals {
          gap: 15px;
          position: relative;
          .delete-action-icon {
            position: relative;
            .review-delet-pop-up {
              position: relative;
              .delete-pop-up {
                position: absolute;
                padding: 20px 30px;
                border-radius: 15px;
                box-shadow: 0 2px 8px 0 rgb(0 0 0 / 26%);
                background-color: #27282a;
                left: -11rem;
                width: max-content;
              }
            }
          }

          .feastt-review-numericals-item {
            .review-numericals-item-count {
              font-family: Montserrat;
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.43px;
              color: #fff;
            }
            .review-numericals-item-title {
              opacity: 0.6;
              font-family: Montserrat;
              font-size: 15px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.36px;
              color: #fff;
            }
          }
        }
      }
    }
    .empty-item-image {
      img {
        width: 213px;
        height: 192px;
        margin-top: 46%;
        margin-left: 22%;
      }
    }
  }

  .add-to-order-div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: right;
    color: #fff;
    .total-food-price {
      position: relative;
      left: 77px;
    }
  } */




  /* ----new css code----- */
  .header-bg {
    position: relative;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .close-drawer-icon {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      position: absolute;
      left: 23px;
      top: 23px;
      z-index: 999;
      .drawer-title {
        margin-left: 100px;
      }
    }
    .bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(5px);
      transform: scale(2);
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 110px;
      bottom: 0;
      background-color: #1b1c1d;
      border-radius: 30px 30px 0 0;
      z-index: 1;
    }
    .drawer-header-content-review-food {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .food-name {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: -0.58px;
        text-align: center;
        color: #fff;
        margin-top: 24rem;
        z-index: 999;
        position: relative;
        top: 25px;
      }
      img {
        width: 26rem;
        height: 18rem;
        border-radius: 8px;
        position: absolute;
        margin-top: 10px;
      }
      .rating {
        background: #343434;
        padding: 3px 7px !important;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        margin: 0 auto;
        margin-top: 40px;
      }
    }
  }
  .reviews-body {
    flex: 1;
    .empty-header {
      padding: 0 26px 0 26px !important;
      .rating {
        background: #343434;
        padding: 3px 7px !important;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        margin: 0 auto;
        margin-top: 10px;
      }
      .sort-overall-review-count {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.34px;
        text-align: left;
        margin-top: 19px;
        margin-bottom: 26px;
        color: #fff;
        .sort-overall-title {
          font-weight: normal !important;
        }
      }
    }
    .r-header {
      padding: 0 26px 0 26px !important;
      border-bottom: 1px solid #4d5053;
      display: flex;
      justify-content: space-between;
      padding: 10px 25px !important;
      .reviews-overall-rating {
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.62px;
        color: #ffffff;
        .fa-star {
          color: #fdcb6e;
          font-size: 26px;
        }
      }
      .sort-dropdown {
        width: fit-content;
        height: 40px;
        padding: 11px 21px 11px 23px !important;
        border-radius: 25px;
        background-color: #27282a;
        font-size: 14px;
        text-align: left;
        color: #fff;
        .fa-angle-down {
          margin-left: 20px;
        }
      }
      .sort-overall-review-count {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        text-align: left;
        margin-top: 19px;
        margin-bottom: 26px;
        color: #fff;
        .sort-overall-title {
          font-weight: normal !important;
        }
      }
      .reviews-body-header {
        .menu-header-select {
          .dropdown-header-menu {
            background-color: #27282a;
            border: 1px solid transparent;
            padding: 12px 20px !important;
          }
          .dropdown-content-menu {
            /* line-height: 30px; */
            padding: 10px 7px !important;
          }
        }
      }
    }
    .review-list {
      // margin-top: 35px;
      .review-list-item {
        margin-top: 15px;

        padding: 0 26px 15px 26px !important;
        border-bottom: 1px solid #4d5053;
        .profile-info {
          .image-div {
            img {
              width: 50px;
              height: 52px;
            }
            .name-time-div {
              margin-left: 15px;
              .review-added-user-name {
                font-family: Montserrat;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: -0.36px;
                color: #fff;
              }
              .feast-text {
                font-family: Montserrat;
                font-size: 15px;
                letter-spacing: -0.36px;
                color: #fff;
              }
            }
          }
          .name-time-div {
          }
        }
        .review-meta {
          opacity: 0.8;
          font-family: Montserrat;
          font-size: 15px;
          letter-spacing: -0.36px;
          color: #fff;
          margin: 22px 0;
        }
        .feastt-review-numericals {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          .feastt-review-numericals-item {
            .review-numericals-item-count {
              font-family: Montserrat;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: -0.43px;
              color: #fff;
            }
            .review-numericals-item-title {
              opacity: 0.6;
              font-size: 15px;
              letter-spacing: -0.36px;
              color: #fff;
            }
          }
          .show-more {
            font-weight: 700;
          }
          .date {
            span {
              opacity: 0.6 !important;
            }
          }
        }
      }
    }
    .empty-item-image {
      img {
        width: 213px;
        height: 192px;
        margin-top: 22%;
        margin-left: 22%;
      }
      .empty-review {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        opacity: 0.6;
        margin-top: 20px;
      }
    }
  }
  .add-to-order-div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: right;
    color: #fff;
    .total-food-price {
      position: relative;
      left: 77px;
    }
  }
`;
