import styled from "@emotion/styled";
import { colors } from "theme";

export const StaffProfileCardContainer = styled.div`
  .profile-info-header {
    margin-top: 10px;
    .profile-info-avatar {
      margin-right: 54px;

      .profile-picture {
        width: 92px;
        height: 92px;
      }

      .customer-profile-rating {
        width: max-content;
        height: 23px;
        padding: 2px 9px 3px 10px;
        border-radius: 8px;
        background-color: #27282a;
        position: absolute;
        margin: -19px 0 0 14px;
        .rating-container {
          display: flex;
          align-items: center;
          width: max-content;
          height: 18px;
          .rating-number {
            font-size: 14px;
            font-weight: bold;
            color: white;
            margin-right: 5px;
            margin-left: 5px;
          }

          .rating-icon {
            i {
              font-size: 12px;
              color: #fdcb6e;
            }
          }
        }
      }
    }

    .staff-status {
      background-color: #2e2f2f;
      border-radius: 8px;
      padding: 4px 10px;
      color: ${colors.green};
      margin-left: 10px;
    }

    .profile-info-name {
      font-size: 20px;
      font-weight: 700;
    }

    .staff-info {
      display: flex;
      gap: 7rem;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      .info-label {
        font-size: 15px;
        font-weight: 400;
        color: ${colors.gray};
      }
      .info {
        font-size: 15px;
        font-weight: 400;
      }
    }

    .edit-btn {
      border-radius: 16px !important;
      padding: 0px 12px;
      width: 53px;
      background-color: #60646c !important;
      height: 32px;
    }
    .edit-btn-for-pending-restaurant {
      border-radius: 30px !important;
      padding: 10px 16px;
    }
  }
`;
