import React, { useState } from "react";
import { DeclineRestaurantModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const DeclineRestaurantModal = ({
  isActive = false,
  closePanel = () => {},
  onDecline = () => {},
}) => {
  const [note, setNote] = useState("");
  const [noteToRestaurant, setNoteToRestaurant] = useState("");

  const clearInput = () => {
    setNote("")
    setNoteToRestaurant("")
  }

  return (
    <DeclineRestaurantModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"35%"}
      left={"37%"}
      position="absolute"
      width="472px"
    >
      <DrawerContainer>
        <div className="restaurant-decline-div">
          <div className="modal-title">
            <b>Decline Restaurant</b>
          </div>
          <div className="decline-input">
            <DrawerInput
              label="Describe the reason"
              placeholder="Type here"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div className="decline-input">
            <DrawerInput
              label="Note to restaurant"
              placeholder="Type here"
              value={noteToRestaurant || ""}
              onChange={(e) => setNoteToRestaurant(e.target.value)}
            />
          </div>
          <div className="modal-bottom">
            <MyDrawerButton
              type={note?.length > 0 || noteToRestaurant?.length > 0 ? "red" : "gray"}
              className="decline-btn"
              onClick={() => {
                onDecline(note, noteToRestaurant);
                clearInput();
              }}
              disabled={note?.length > 0 || noteToRestaurant?.length > 0 ? false : true  }
            >
              <b>Decline Restaurant</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={closePanel}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DeclineRestaurantModalContainer>
  );
};

export default DeclineRestaurantModal;
