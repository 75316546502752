import React, { useContext, useEffect, useState } from "react";
import { RemoveConfirmContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { RestaurantsContext } from "context/RestaurantsContext";
// import { customAxios } from "helpers/custom-helpers/customAxios";
// import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import EmptyStaff from "assets/img/noAvatar.png";
import DrawerChooseItem from "components/Elements/DrawerElements/DrawerChooseItem";
import { drawersStaff } from "static/staticData";
import MyButton from "components/Elements/MyButton";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

const RemoveConfirmModal = ({
  isActive = false,
  closePanel = () => {},
  getStaffAll = () => {},
  // onDeleteEntireOrder = () => {},
}) => {
  const { id } = useParams();

  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    staffViewData,
    setStaffs,

    staffChangeRoleMainModal,
    setStaffChangeRoleMainModal,

    restaurant,
  } = useContext(RestaurantsContext);

  const [removeRestoreNotification, setRemoveRestoreNotification] = useState();

  useEffect(() => {
    if (removeRestoreNotification) {
      setTimeout(() => setRemoveRestoreNotification(null), 2 * 1000);
    }
  }, [removeRestoreNotification]);

  const removeRestaurantStaff = () => {
    customAxios
      // .delete(`/restaurant/${id}/staff/${staffViewData?.id}`)
      .delete(`/staff/${staffViewData?.id}?restaurant_id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          setRemoveRestoreNotification({
            message: "Staff Removed.",
            type: "remove",
            table: `<p style="color: #8F949A"><b>${staffViewData?.first_name} ${staffViewData?.last_name} (Server/Host) was removed from <b style="color: #8F949A">Catch LA</b>.</p>`,
          });
          setStaffChangeRoleMainModal({
            ...staffChangeRoleMainModal,
            staffServerHostActiveModal: false,
          });
          closePanel();
          getStaffAll();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <RemoveConfirmContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      width="472px"
      zIndex={"99999999999"}
      zIndexBackground={999999999}
    >
      <DrawerContainer>
        {removeRestoreNotification && (
          <MyMessageBoxItemNew
            message={
              removeRestoreNotification?.message
                ? removeRestoreNotification.message
                : ""
            }
            table={
              removeRestoreNotification?.table
                ? removeRestoreNotification.table
                : ""
            }
            type={
              removeRestoreNotification?.type
                ? removeRestoreNotification.type
                : "success"
            }
            onClick={() => setRemoveRestoreNotification(null)}
          />
        )}

        <div className="server-host-active-modal">
          <div className="staff-profile">
            <img
              className="rounded-circle"
              src={staffViewData?.avatar ? staffViewData?.avatar : EmptyStaff}
              alt=""
            />
          </div>
          <div className="staff-title">Remove Restaurant Staff</div>
          <div className="staff-subtitle">
            <b>
              {staffViewData?.first_name === undefined &&
              staffViewData?.last_name === undefined
                ? "No Name"
                : `${staffViewData?.first_name} ${staffViewData?.last_name}`} {' '}
              (Server/Host)
            </b>{" "}
            will no longer be able to <br /> access their account in the{" "}
            <b>{restaurant?.title}</b>?
          </div>
          <div className="actions">
            <MyButton
              className="remove-btn"
              type="red"
              onClick={removeRestaurantStaff}
            >
              Remove Restaurant Staff
            </MyButton>
            <MyButton className="cancel-btn" type="gray" onClick={closePanel}>
              No, Keep
            </MyButton>
          </div>
        </div>
      </DrawerContainer>
    </RemoveConfirmContainer>
  );
};

export default RemoveConfirmModal;
