import React, { useContext, useEffect, useState } from "react";
import { ArchivedMainModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import EmptyStaff from "assets/img/noAvatar.png";
import { drawersStaff } from "static/staticData";
import { colors } from "theme";
import { useParams } from "react-router-dom";

const ArchivedMainModal = ({
  isActive = false,
  closePanel = () => {},
  // onDeleteEntireOrder = () => {},
}) => {
  const {
    openDrawers,
    openDrawer,
    closeDrawer,
    staffViewData,
    postStaff,
    setPostStaff,

    // staffChangeRoleMainModal,
    // setStaffChangeRoleMainModal,

    // removeFromRestaurantModal,
    // setRemoveFromRestaurantModal,
  } = useContext(RestaurantsContext);

  const { id } = useParams();

  return (
    <ArchivedMainModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      width="472px"
    >
      <DrawerContainer>
        <div className="server-host-active-modal">
          <div className="staff-profile">
            <img
              className="rounded-circle"
              src={staffViewData?.avatar ? staffViewData?.avatar : EmptyStaff}
              alt=""
            />
          </div>
          <div className="staff-title">
            {staffViewData?.first_name === undefined &&
            staffViewData?.last_name === undefined
              ? "No Name"
              : `${staffViewData?.first_name} ${staffViewData?.last_name}`}
          </div>

          <div className="drawer-title">
            <span style={{ color: colors.green }}>-</span>
            {/* {((staffViewData.serving_status === true ||
              staffViewData.serving_status == null) &&
              staffViewData?.status === 1 && (
                <span style={{ color: colors.green }}>Active</span>
              )) ||
              (staffViewData?.serving_status === undefined && (
                <span style={{ color: "rgb(247, 166, 76)" }}>Pending</span>
              )) ||
              (staffViewData?.status === 9 && (
                <span style={{ color: colors.redColor }}>Banned</span>
              )) ||
              (staffViewData?.status === -1 && (
                <span style={{ color: colors.redColor }}>Deleted</span>
              )) ||
              (staffViewData?.serving_status === false &&
                staffViewData?.status === 1 && (
                  <span style={{ color: colors.gray }}>Inactive</span>
                ))} */}
          </div>

          {/* options */}
          <div className="staff-option">
            <div
              className="box"
              style={{ padding: "20px 20px" }}
              onClick={() => {}}
            >
              View Profile
            </div>

            <div
              className="box"
              style={{ padding: "10px 20px" }}
              onClick={() => openDrawer(drawersStaff.archivedStatusGlobal)}
            >
              Change status to{" "}
              <b>{staffViewData?.status === 1 ? "inactive" : "Active"}</b>
              <div style={{ color: "gray" }}>(Global)</div>
            </div>

            <div className="box" style={{ padding: "20px 20px" }}>
              <span>
                <i className="fas fa-trash-undo-alt"></i>
              </span>{" "}
              Return to Restaurant
            </div>
          </div>

          <div className="actions">
            <div onClick={closePanel}>Cancel</div>
          </div>
        </div>
      </DrawerContainer>
    </ArchivedMainModalContainer>
  );
};

export default ArchivedMainModal;
