import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DrawerApplyDiscountAreYouSureContainer, Label } from "./style";
import SingleText from "components/SingleText";
import { DrawerContainer } from "components/styledStyle";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

export function DrawerNeutritions({ isActive, closePanel, foodDetails }) {
  const onApply = async () => {};

  const [neutritionsData, setNeutritionsData] = useState({});
  const [isShowMoreEnabled, setIsShowMoreEnabled] = useState(false);
  const [moreArray, setMoreArray] = useState([]);

  useEffect(() => {
    if (foodDetails) {
      let myobj = JSON.parse(foodDetails?.nutrition);
      setNeutritionsData(myobj);
      showLessBtnClick();
    }
  }, [foodDetails]);

  // console.log("neutritionsData", neutritionsData);

  const showMoreBtnClick = () => {
    let array = [
      "Calories",
      "Total Fat",
      "Cholesterol",
      "Sodium",
      "Total Carbohydrate",
      "Dietary Fiber",
      "Total Sugars",
      "Protein",
    ];
    if (neutritionsData !== null) {
      let finalAray = [];
      Object.keys(neutritionsData).map((el, _i) => {
        let filterArray = array.filter(
          (list) => list === neutritionsData[el].title
        );
        if (filterArray && filterArray.length === 0) {
          if (neutritionsData[el]?.percentage !== null) {
            finalAray.push(neutritionsData[el]);
          }
        }
      });
      setIsShowMoreEnabled(true);
      setMoreArray(finalAray);
    }
  };

  const showLessBtnClick = () => {
    setIsShowMoreEnabled(false);
    setMoreArray([]);
  };

  return (
    <DrawerApplyDiscountAreYouSureContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer className="p-0">
        <BottomWithIconWrapper hasIcon={false}>
          <div className="drawer-bottom-content w-100">
            <div className="top-title d-flex justify-content-between align-items-center">
              <h3>Nutrition</h3>
              <i
                className="fas fa-times red-color cursor-pointer"
                onClick={closePanel}
              />
            </div>
            <div className="neutritions-data d-flex justify-content-between align-items-center">
              <Label>
                Calories &nbsp;
                <span className="weight-type">
                  {neutritionsData?.calories?.value || 0}
                  {neutritionsData?.calories?.weight_type}
                </span>
              </Label>
              <Label>{neutritionsData?.calories?.percentage || 0}%</Label>
            </div>
            <div className="neutritions-data d-flex justify-content-between align-items-center">
              <Label>
                Total Fat &nbsp;
                <span className="weight-type">
                  {neutritionsData?.total_fat?.value || 0}
                  {neutritionsData?.total_fat?.weight_type}
                </span>
              </Label>
              <Label>{neutritionsData?.total_fat?.percentage || 0}%</Label>
            </div>
            <div className="neutritions-data d-flex justify-content-between align-items-center">
              <Label>
                Cholesterol &nbsp;
                <span className="weight-type">
                  {neutritionsData?.cholesterol?.value || 0}
                  {neutritionsData?.cholesterol?.weight_type}
                </span>
              </Label>
              <Label>{neutritionsData?.cholesterol?.percentage || 0}%</Label>
            </div>
            <div className="neutritions-data d-flex justify-content-between align-items-center">
              <Label>
                Sodium &nbsp;
                <span className="weight-type">
                  {neutritionsData?.sodium?.value || 0}
                  {neutritionsData?.sodium?.weight_type}
                </span>
              </Label>
              <Label>{neutritionsData?.sodium?.percentage || 0}%</Label>
            </div>
            <div className="neutritions-data">
              <div className="d-flex justify-content-between align-items-center ">
                <Label>
                  Total Carbohydrate &nbsp;
                  <span className="weight-type">
                    {neutritionsData?.total_carbs?.value || 0}
                    {neutritionsData?.total_carbs?.weight_type}
                  </span>
                </Label>
                <Label>{neutritionsData?.total_carbs?.percentage || 0}%</Label>
              </div>
              <div className="pl-10 d-flex justify-content-between align-items-center ">
                <Label weight="500">
                  Dietary Fiber &nbsp;
                  <span className="weight-type">
                    {neutritionsData?.fiber?.value || 0}
                    {neutritionsData?.fiber?.weight_type}
                  </span>
                </Label>
                <Label>{neutritionsData?.fiber?.percentage || 0}%</Label>
              </div>
              <div className="pl-10 d-flex justify-content-between align-items-center ">
                <Label weight="500">
                  Total Sugars &nbsp;
                  <span className="weight-type">
                    {neutritionsData?.total_sugars?.value || 0}
                    {neutritionsData?.total_sugars?.weight_type}
                  </span>
                </Label>
                <Label>{neutritionsData?.total_sugars?.percentage || 0}%</Label>
              </div>
              {/* <div></div> */}
            </div>
            <div className="neutritions-data d-flex justify-content-between align-items-center">
              <Label>
                Protein &nbsp;
                <span className="weight-type">
                  {neutritionsData?.protein?.value || 0}
                  {neutritionsData?.protein?.weight_type}
                </span>
              </Label>
              <Label>{neutritionsData?.protein?.percentage || 0}%</Label>
            </div>
            {isShowMoreEnabled ? (
              <>
                {moreArray &&
                  moreArray.map((el) => {
                    if (el?.list) {
                      return (
                        <div className="neutritions-data">
                          <div className="d-flex justify-content-between align-items-center ">
                            <Label>Extras</Label>
                            <Label></Label>
                          </div>
                          {el &&
                            el.list &&
                            el.list
                              .filter((value) => value?.percentage !== null)
                              .map((extrasList) => (
                                <div className="pl-10 d-flex justify-content-between align-items-center ">
                                  <Label weight="500">
                                    {extrasList?.title} &nbsp;
                                    <span className="weight-type">
                                      {extrasList?.value || 0}
                                      {extrasList?.weight_type}
                                    </span>
                                  </Label>
                                  <Label>{extrasList?.percentage || 0}%</Label>
                                </div>
                              ))}
                        </div>
                      );
                    } else {
                      return (
                        <>
                          {el?.title ? (
                            <div className="neutritions-data d-flex justify-content-between align-items-center">
                              <Label>
                                {el?.title || "-"} &nbsp;
                                <span className="weight-type">
                                  {el?.value || 0}
                                  {el?.weight_type}
                                </span>
                              </Label>
                              <Label>{el?.percentage || 0}%</Label>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    }
                  })}
              </>
            ) : (
              <></>
            )}
            <div style={{ marginTop: "18px" }}>
              <Label size="12px" weight="normal" color="#fff" opacity="0.3">
                The % Daily Value (DV) tells you how much a nutrient in a
                serving of food contributes to a daily diet. 2,000 calories a
                day is used for general nutrition advice.
              </Label>
            </div>
            <div
              className="bottom-btns div-padding "
              style={{ marginTop: "30px", paddingBottom: "10px" }}
            >
              {isShowMoreEnabled ? (
                <MyDrawerButton
                  type="redColor"
                  onClick={() => {
                    showLessBtnClick();
                  }}
                >
                  Show less
                </MyDrawerButton>
              ) : (
                <MyDrawerButton
                  type="redColor"
                  onClick={() => {
                    showMoreBtnClick();
                  }}
                >
                  Show more
                </MyDrawerButton>
              )}
            </div>
          </div>
        </BottomWithIconWrapper>
      </DrawerContainer>
    </DrawerApplyDiscountAreYouSureContainer>
  );
}

DrawerNeutritions.propTypes = {
  visible: PropTypes.bool,
  closePanel: PropTypes.func,
  discount: PropTypes.object,
  discountTypes: PropTypes.object,
  applyDiscountForActiveOrder: PropTypes.func,
};
