import React, { useState } from "react";
import { FoodItemDetailsAndActionsContainer } from "./style";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import IconCheck from "components/Elements/IconCheck";
import { FaChevronRight, FaTrash, FaPencilAlt, FaCheck } from "react-icons/fa";

const FoodItem = ({
  orderDetails = {},
  orders = {},
  foodType = "drink",
  foodIndex = null,
  onClickEditFoodItem = () => {},
  onClickDeleteFoodItem = () => {},
  subOrderNumber = "",
  markAsReadyClick = () => {},
  markAsDeliveredClick = () => {},
  onClickReadyItem = () => {},
  rollBackReadyItemToKitchen = () => {},
}) => {
  const [isActionsEnabled, setIsActionsEnabled] = useState(false);
  const readyButtonRenderer = (order) => {
    let orderReady = order?.every((el) => el?.status === 2);
    if (orderReady) {
      return "Ready";
    } else {
      return "Pending";
    }
  };

  const orderDeliveredConfirmation = (order) => {
    let orderDelivered = order?.every((el) => el?.status === 3);
    return orderDelivered ? true : false;
  };

  const markAsDeliveredTextRender = () => {
    switch (foodType) {
      case "drink":
        return "Mark Drinks as Delivered";
      case "quick_food":
        return "Mark Quick Foods as Delivered";
      case "entrees":
        return "Mark Entrées as Delivered";
      default:
        return null;
    }
  };

  const markAsReadyTextRender = () => {
    switch (foodType) {
      case "drink":
        return "Mark Drinks as Ready";
      case "quick_food":
        return "Mark Quick Foods as Ready";
      case "entrees":
        return "Mark Entrées as Ready";
      default:
        return null;
    }
  };

  return (
    <FoodItemDetailsAndActionsContainer>
      {isActionsEnabled ? (
        <div className="card-item-actions">
          <div
            className="toggle-action-icon-div"
            onClick={() => setIsActionsEnabled(!isActionsEnabled)}
          >
            <FaChevronRight color="white" />
          </div>
          {orderDeliveredConfirmation(orders) ? (
            <>
              <div
                className="delete-action-icon-div-for-delivered"
                onClick={() => {
                  onClickDeleteFoodItem({
                    ...orderDetails,
                    subOrderNumber: subOrderNumber,
                  });
                  setIsActionsEnabled(!isActionsEnabled);
                }}
              >
                <FaTrash color="white" />
              </div>
              <div
                className="edit-action-icon-div-for-delivered"
                onClick={() => {
                  onClickEditFoodItem(orderDetails);
                  setIsActionsEnabled(!isActionsEnabled);
                }}
              >
                <FaPencilAlt color="white" />
              </div>
            </>
          ) : (
            <>
              {orderDetails?.status === 2 ? (
                <div
                  className="undo-action-icon-div"
                  onClick={() => {
                    rollBackReadyItemToKitchen(orderDetails, foodType);
                    setIsActionsEnabled(false);
                  }}
                >
                  <b>Undo</b>
                </div>
              ) : (
                <>
                  <div
                    className="delete-action-icon-div"
                    onClick={() => {
                      onClickDeleteFoodItem({
                        ...orderDetails,
                        subOrderNumber: subOrderNumber,
                      });
                      setIsActionsEnabled(!isActionsEnabled);
                    }}
                  >
                    <FaTrash color="white" />
                  </div>
                  <div
                    className="edit-action-icon-div"
                    onClick={() => {
                      onClickEditFoodItem(orderDetails);
                      setIsActionsEnabled(!isActionsEnabled);
                    }}
                  >
                    <FaPencilAlt color="white" />
                  </div>
                  <div
                    className="check-action-icon-div"
                    onClick={() => {
                      onClickReadyItem(orderDetails, foodType);
                      setIsActionsEnabled(false);
                    }}
                  >
                    <FaCheck color="white" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div className="card-item">
            {orderDetails?.status === 3 || orderDetails?.status === 2 ? (
              <IconCheck className="mr-5" />
            ) : (
              <></>
            )}
            <h5
              className={orderDetails?.status === 3 ? "opacity6 mr-4" : "mr-4"}
            >
              {orderDetails?.quantity || 0}
            </h5>
            <div className="card-item-content">
              <div className="item-row">
                <h5
                  className={
                    orderDetails?.status === 3 && "opacity6 margin-top-3"
                  }
                >
                  {orderDetails?.name || "-"}
                </h5>
                <h5
                  className="opacity6 d-flex"
                  style={{ position: "relative" }}
                >
                  {orderDetails.price ? `$${(orderDetails.price * orderDetails.quantity).toFixed(2)}` : "Free"} &nbsp;
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={() => setIsActionsEnabled(!isActionsEnabled)}
                  />
                  {/* {orderStatusRenderer(order) === "Completed" ? (
                            <></>
                          ) : (
                            <div id="review-sub-order-delete-pop-wrapper">
                              <OverlayTrigger
                                container={() =>
                                  document.getElementById(
                                    "review-sub-order-delete-pop-wrapper"
                                  )
                                }
                                placement="bottom-end"
                                popperConfig={{ className: "fffffffffff" }}
                                trigger={"click"}
                                overlay={orderPopUpRenderer(el)}
                                rootClose
                              >
                                <StyledIconWrapper>
                                  <FaEllipsisH color="white" className="cursor" />
                                </StyledIconWrapper>
                              </OverlayTrigger>
                            </div>
                          )} */}
                </h5>
              </div>
              {orderDetails.customizations &&
                orderDetails.customizations.length &&
                orderDetails.customizations.map((item) => {
                  return (
                    <div className="item-row">
                      <h5 className="opacity6">
                        {`– ${item.name}: ${item.options
                          .map((_i) => _i.name)
                          .join(",")}`}
                      </h5>
                    </div>
                  );
                })}
              {orderDetails?.instructions && (
                <div className="item-row">
                  <h5 className="opacity6">
                    – Special Instructions: {orderDetails?.instructions || ""}
                  </h5>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {orders?.length - 1 === foodIndex ? (
        <>
          {orderDeliveredConfirmation(orders) ? (
            <></>
          ) : (
            <MyDrawerButton
              type={readyButtonRenderer(orders) === "Ready" ? "red" : "gray"}
              className="card-button"
              onClick={() => {
                if (readyButtonRenderer(orders) === "Ready") {
                  markAsDeliveredClick(orders, subOrderNumber);
                } else {
                  markAsReadyClick(orders, subOrderNumber);
                }
              }}
            >
              <b>
                {readyButtonRenderer(orders) === "Ready"
                  ? markAsDeliveredTextRender()
                  : markAsReadyTextRender()}
              </b>
            </MyDrawerButton>
          )}
        </>
      ) : (
        <></>
      )}
    </FoodItemDetailsAndActionsContainer>
  );
};

export default FoodItem;
