import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import BottomWithIconWrapper from "components/Elements/DrawerElements/BottomWithIconWrapper";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
// import { OrdersContext } from "context/OrdersContext";
import React, { useEffect, useState } from "react";
// import { drawersOrder } from "static/staticData";
import { DrawerPartialRefundContainer, Label } from "./style";

const useStyles = makeStyles((theme) => ({
  textField: {
    maxWidth: 40,
  },
}));

export default function DrawerPartialRefund({
  isActive = false,
  closePanel = () => {},
  orderDetails,
  partialDiscountHandler,
}) {
  const classes = useStyles();
  // const { openDrawer } = useContext(OrdersContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [amount, setAmount] = useState("");
  const [percent, setPercent] = useState();
  const [options, setOptions] = useState([]);

  const [isFixedAmountFocused, setIsFixedAmountFocused] = useState(false);
  const [isPercentageFocused, setPercentageFocused] = useState(false);

  useEffect(() => {
    isActive &&
      setOptions([
        {
          id: "10",
          value: (10 / 100) * orderDetails?.checkout_data?.total,
          title: "10%",
          subTitle: `$ ${(10 / 100) * orderDetails?.checkout_data?.total}`,
        },
        {
          id: "25",
          value: (25 / 100) * orderDetails?.checkout_data?.total,
          title: "25%",
          subTitle: `$ ${(25 / 100) * orderDetails?.checkout_data?.total}`,
        },
        {
          id: "50",
          value: (50 / 100) * orderDetails?.checkout_data?.total,
          title: "50%",
          subTitle: `$ ${(50 / 100) * orderDetails?.checkout_data?.total}`,
        },
        {
          id: "other",
          value: "other",
          title: "Other",
        },
      ]);
    setSelectedOption("");
    setPercent("");
    setAmount("");
  }, [orderDetails, isActive]);

  const onIssueRefund = () => {
    let data = [...options];
    data[3].value = amount;
    let index = data.findIndex((el) => el.id === selectedOption);
    partialDiscountHandler(data[index]);
    setOptions(data);
    // openDrawer(drawersOrder.deletedReview);
  };

  const onClickOption = (_option) => {
    setSelectedOption(_option.id);
    if (_option.id !== "other") setAmount(_option.value);
  };

  const onChangeAmountHandler = (value) => {
    if (value > parseInt(orderDetails?.checkout_data?.total)) {
      setAmount(value);
      // let percentage = (value * orderDetails?.checkout_data?.total) / 100;
      // setPercent(percentage);
    } else {
      setAmount(value);
      // let percentage = (value * orderDetails?.checkout_data?.total) / 100;
      // setPercent(percentage);
    }
  };

  const onChangePercentage = (value) => {
    setPercent(value);
    let amountValue = (value / 100) * orderDetails?.checkout_data?.total;
    setAmount(amountValue);
  };

  return (
    <DrawerPartialRefundContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"15%"}
      left={"38%"}
      position="fixed"
      width="472px"
    >
      <DrawerContainer>
        <div className="drawer-bottom-content w-100">
          <h3 className="modal-title">Partial Refund</h3>
          <div className="card-details">
            <Label
              size="15px"
              weight="normal"
              color="#fff"
              opacity="0.6"
              marginBottom="12px"
            >
              Amount eligible for refund:
            </Label>
            <Label size="36px" weight="bold" color="#fff" marginBottom="12px">
              $
              {Number(
                orderDetails?.checkout_data?.total -
                  (orderDetails?.checkout_data?.tip || 0)
              ).toFixed(2)}
            </Label>
            <Label
              size="15px"
              weight="bold"
              color="#fff"
              opacity="0.6"
              marginBottom="12px"
            >
              {`$${orderDetails?.checkout_data?.total} - ${orderDetails
                ?.checkout_data?.tip || 0} Tip`}
            </Label>
            <Label
              size="15px"
              weight="normal"
              color="#fff"
              opacity="0.6"
              marginBottom="12px"
            >
              Tips are non-refundable for partial refunds.
            </Label>
          </div>
          <div className="refund-options">
            {options.map((_option, _i) => (
              <div
                key={_i}
                className={`refund-option cursor ${selectedOption ===
                  _option.id && "selected"}`}
                onClick={() => onClickOption(_option)}
              >
                <h5 className="text-bold mb-0">{_option.title}</h5>
                {/* {_option.subTitle && (
                  <p className="mb-0 mt-2">{_option.subTitle}</p>
                )} */}
              </div>
            ))}
          </div>

          {selectedOption === "other" && (
            <div className="fixed-amount">
              <div className="d-flex justify-content-between">
                <p
                  className={isPercentageFocused ? "" : "opacity6"}
                  style={{
                    color: `${
                      Number(percent) === 0 && percent !== ""
                        ? "#ed2626"
                        : "#fff"
                    }`,
                  }}
                >
                  Percentage
                </p>
                <p
                  style={{
                    color: `${
                      amount >
                      Number(
                        orderDetails?.checkout_data?.total -
                          (orderDetails?.checkout_data?.tip || 0)
                      )
                        ? "#ed2626"
                        : "#fff"
                    }`,
                  }}
                  className={isFixedAmountFocused ? "" : "opacity6"}
                >
                  Fixed Amount
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <Input
                  className={`amount-field ${classes.textField}`}
                  placeholder="0"
                  value={percent}
                  defaultValue={percent}
                  onChange={(e) => onChangePercentage(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  onFocus={() => {
                    setIsFixedAmountFocused(false);
                    setPercentageFocused(true);
                    setAmount("");
                  }}
                  onBlur={() => {
                    setPercentageFocused(false);
                  }}
                />
                <Input
                  className={`amount-field ${classes.textField}`}
                  placeholder="0.00"
                  value={amount || ""}
                  defaultValue={amount}
                  // onChange={(e) => setAmount(e.target.value)}
                  onChange={(e) => onChangeAmountHandler(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  onFocus={() => {
                    setIsFixedAmountFocused(true);
                    setPercentageFocused(false);
                    setPercent("");
                  }}
                  onBlur={() => {
                    setIsFixedAmountFocused(false);
                  }}
                />
              </div>
            </div>
          )}

          <Label
            size="15px"
            weight="500"
            color={`${
              amount >
                Number(
                  orderDetails?.checkout_data?.total -
                    (orderDetails?.checkout_data?.tip || 0)
                ) ||
              (Number(percent) === 0 && isPercentageFocused)
                ? "#ed2626"
                : "#fff"
            }`}
            marginBottom="12px"
          >
            {amount >
              Number(
                orderDetails?.checkout_data?.total -
                  (orderDetails?.checkout_data?.tip || 0)
              ) ||
            (Number(percent) === 0 && isPercentageFocused) ? (
              <>
                {Number(percent) === 0 && isPercentageFocused ? (
                  <>{percent === "" ? "" : "The minimum percentage is 1%"}</>
                ) : (
                  <>
                    {" "}
                    The maximum amount is $
                    {Number(
                      orderDetails?.checkout_data?.total -
                        (orderDetails?.checkout_data?.tip || 0)
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {amount !== "" && amount !== 0
                  ? `$${Number(amount).toFixed(2)} will be refunded.`
                  : "Select a percentage above"}
              </>
            )}
          </Label>
          <Label
            size="14px"
            opacity="0.6"
            weight="500"
            color="#fff"
            marginBottom="12px"
          >
            Customer receives refund within 3-5 business days.
          </Label>
          <div className="bottom-btns">
            <MyDrawerButton
              type={selectedOption === "" ? "gray" : "red"}
              disabled={
                selectedOption === "" ||
                amount >
                  Number(
                    orderDetails?.checkout_data?.total -
                      (orderDetails?.checkout_data?.tip || 0)
                  ) ||
                (Number(percent) === 0 && isPercentageFocused)
              }
              className="mb-3"
              onClick={onIssueRefund}
            >
              <b>Continue</b>
            </MyDrawerButton>
            <MyDrawerButton type="redColor" onClick={() => closePanel()}>
              <b>Cancel</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </DrawerPartialRefundContainer>
  );
}
