import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, { useContext, useEffect, useState } from "react";
import { FaBars, FaPen, FaTrash } from "react-icons/fa";
import { drawersMenu } from "static/staticData";
import { colors } from "theme";
import { DrawerEditCustomizationsContainer } from "./style";
import { Switch } from "@material-ui/core";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";
import DrawerEditCustomizationsOption from "./DrawerEditCustomizationOption";

export default function DrawerEditCustomizations({
  menus = [],
  isActive = false,
  closePanel = () => {},
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    setCustomizationData,
    foodId,
    editCustomizationInfo,
    editOption,
    setEditOption,
    setEditOptionInfo,
  } = useContext(RestaurantsContext);

  const { id } = useParams();

  const [customizationName, setCustomizationName] = useState("");
  const [availableSelection, setAvailableSelection] = useState(0);
  const [requiredField, setRequiredField] = useState(false);

  useEffect(() => {
    if (editCustomizationInfo) {
      setCustomizationName(editCustomizationInfo?.name);
      setAvailableSelection(editCustomizationInfo?.available_selection);
      setRequiredField(editCustomizationInfo?.required);
      setEditOption(editCustomizationInfo?.options)
    }
  }, [editCustomizationInfo]);

  function availableSelectionPlus() {
    setAvailableSelection(availableSelection + 1);
  }
  function availableSelectionMinus() {
    if (availableSelection < 1) {
      return null;
    }
    setAvailableSelection(availableSelection - 1);
  }

  const requiredHandleChange = (event) => {
    setRequiredField(event.target.checked);
  };

  // eslint-disable no-unused-expressions
  function handleClickEditOption(editInfo) {
  //  console.log(editInfo)
   setEditOptionInfo(editInfo)
  }

  function editCustomization() {
    if (
      id &&
      editCustomizationInfo?.food_item_id &&
      editCustomizationInfo?.id
    ) {
      const putCostomizationDataConfig = {
        name: customizationName,
        available_selection: availableSelection,
        required: requiredField,
        options: editOption,
      };
      customAxios
        .put(
          `/restaurant/${id}/food/${editCustomizationInfo?.food_item_id}/customization/${editCustomizationInfo?.id}`,
          putCostomizationDataConfig
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    closePanel();
  }

  useEffect(() => {
    getCustomizationData();
  }, [id, foodId]);

  function getCustomizationData() {
    if (id && foodId) {
      customAxios
        .get(`/restaurant/${id}/food/${foodId}/customization`)
        .then((res) => {
          setCustomizationData(res?.data);
          // console.log(res?.data)
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  // console.log(editCustomizationInfo);
  // console.log(editCustomizationInfo?.options)

  return (
    <DrawerEditCustomizationsContainer
      isActive={isActive}
      closePanel={closePanel}
      renderRightIcon={<FaTrash size={18} color={colors.redColor} />}
      onClickRightIcon={() => openDrawer(drawersMenu.deleteMenu)}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-center">
          Edit Customizations
        </h2>
        <div className="edit-cutomization-input-area">
          <div className="mb-4">
            <div>Name</div>
            <input
              type="text"
              value={customizationName || ""}
              placeholder="Ex: Size, Dressing, Add-ons "
              className="customization-input"
              onChange={(e) => setCustomizationName(e.target.value)}
            />
            <div className="input-bottom-line" />
          </div>

          {/* dynamic edit-option */}
          {editOption &&
            editOption.map((optionItem, index) => {
              // console.log(optionItem?.customization_option_id);
              return (
                <div className="mb-4" key={index}>
                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex justify-content-between">
                      <div className="mr-3">
                        <FaBars className="cursor-grab" />
                      </div>
                      <div className="">{optionItem?.name}</div>
                    </div>
                    {/* eslint-disable no-unused-expressions */}
                    <div className="">
                      <FaPen className="cursor-pointer" onClick={() => {openDrawer(drawersMenu.openDrawerEditCustomizationOption), handleClickEditOption(optionItem)} } />
                    </div>
                  </div>
                  <div className="input-bottom-line" />
                </div>
              );
            })}

          {/* edit arr */}
          {/* {editCustomizationInfo?.optios?.map((editOption, index) => {
            console.log(editOption);
          })} */}

          <div className="mb-4">
            <p
              className="add-option cursor-pointer"
              onClick={() =>
                openDrawer(drawersMenu.openDrawerEditCustomizationOption)
              }
            >
              Add Option
            </p>
            <div className="input-bottom-line" />
          </div>

          <div className="selection-required">
            <div className="input-bottom-line" />
            <div className="selections-allowed">
              <div>Selections Allowed</div>
              <div className="selection-plus-minus">
                <div
                  className="minus cursor-pointer"
                  onClick={availableSelectionMinus}
                >
                  -
                </div>
                <div className="digit">{availableSelection}</div>
                <div
                  className="plus cursor-pointer"
                  onClick={availableSelectionPlus}
                >
                  +
                </div>
              </div>
            </div>
            <div className="input-bottom-line" />

            <div className="required">
              <div>Required</div>
              <div>
                <Switch
                  checked={requiredField}
                  onChange={requiredHandleChange}
                  // inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className="input-bottom-line" />
          </div>
        </div>

        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mt-auto text-bold"
            // onClick={() => openDrawer(drawersMenu.menuAdd)}
            onClick={editCustomization}
          >
            Edit Customization
          </MyDrawerButton>
          <div
            className="cancel-btn text-center text-bold cursor-pointer"
            onClick={closePanel}
          >
            Cancel
          </div>
        </div>
      </DrawerContainer>

      <DrawerEditCustomizationsOption
        isActive={!!openDrawers[drawersMenu.openDrawerEditCustomizationOption]}
        closePanel={() =>
          closeDrawer(drawersMenu.openDrawerEditCustomizationOption)
        }
      />
    </DrawerEditCustomizationsContainer>
  );
}
