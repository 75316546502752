import styled from "@emotion/styled";
// import { ResponsiveContainer } from "recharts";

// export const MyChartContainer = styled(ResponsiveContainer)`
//   .apexcharts-tooltip {
//     background: #1b1c1d;
//     color: #fff;
//     border-radius: 20px;
//     border: 1px solid #acacac;
//     .arrow_box {
//       display: grid;
//       padding: 10px 15px;
//       place-items: center;
//     }
//   }
//   .apexcharts-canvas.apexcharts-zoomable .hovering-zoom {
//     cursor: pointer !important;
//   }

//   .apexcharts-data-labels {
//     display: contents !important;
//   }

//   .apexcharts-svg {
//     overflow: visible;
//   }

//   .chart-container {
//     padding: 2rem;
//     padding-right: 2.7rem;
//   }
// `;

export const MyC3ChartContainer = styled.div`
  width: 100%;

  .arrow_box {
    display: grid;
    padding: 10px 15px;
    place-items: center;
    background-color: #27282a;
    border-radius: 20px;
    text-align: center;
    .month_name {
      color: #ffffff;
      opacity: 0.5;
    }
    .value {
      color: #ffffff;
    }
  }

  #chart .c3-tooltip .name {
    display: block;
    font-weight: normal;
  }

  .c3-axis {
    fill: #a9a9a9;
  }

  .c3-tooltip td {
    color: #222;
  }

  .c3-legend-item {
    display: none;
  }

  tspan {
    font: bold 10px sans-serif;
    /* text-anchor: end; */
    opacity: 0.3;
    fill: #ffffff;
  }

  /* disable vertical line on hover */
  .c3-xgrid-focus {
    display: none;
  }

  #chart .c3-circle {
    stroke: black;
    stroke-width: 2;
    /* fill: white !important; */
  }

  /* box-shadow: 0px 0px 18px 9px #1FC086; */

  .c3-line {
    stroke-width: 2px;
  }

  /* #chart .tick line {
    display: none;
  } */
`;
