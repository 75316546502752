import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";

export const DrawerCuisineContainer = styled(MyDrawerRight)`
  .panel-header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .back-arrow {
      width: 50px;
      cursor: pointer;
      position: absolute;
      left: 0;
      i {
        color: #ffffff;
        font-size: 22px;
      }
    }
    .panel-header-context {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .panel-status-container {
    margin-bottom: 20px;
    .select-message {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }
    .cuisine-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15rem;
      .cuisine-item {
        cursor: pointer;
        margin-bottom: 15px;
        width: 160px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.6);
        &.selected {
          border: solid 2px #ed2626;
        }
        .internal-title {
          font-size: 15px;
          font-weight: 600;
          color: #fff;
          margin-top: 13px;
        }
      }
    }
  }

  .button-container {
    margin-top: 300px;
    padding-bottom: 25px;
    .done-button {
      height: 50px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.4);
      border: none;
      &.active {
        background-color: #ed2626;
        color: #fff;
      }
    }
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 49px;
    .title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .done-btn {
    position: absolute;
    top: 67rem;
    width: 85%;
  }
`;
