import styled from "@emotion/styled";
import { MyDrawerRight } from "components/Elements/MyDrawerRight";
import { colors } from "theme";

export const DrawerRestorePasswordContainer = styled(MyDrawerRight)`
  .password-note {
    margin-top: 63px;
  }

  .bottom-btns {
    .cancel-btn {
      color: ${colors.redColor};
      font-weight: 600;
      text-align: center;
      margin-top: 33px;
      cursor: pointer;
    }
  }
`;
