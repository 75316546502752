import React, { useState, useContext, useEffect, useRef } from "react";
import {
  ActiveOrderAddItemPageContainer,
  ActiveOrderAddItemPageHeaderContainer,
  AddItemBodyContainer,
  AddItemCategoriesAndDropDown,
  AddItemCategoriesAndItemList,
  CategoriesContainer,
  MealTimeDropDown,
} from "./style";
import { Link, useParams, useHistory } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import MyDropDown from "components/Elements/MyDropDown";
import ItemCard from "./ItemCard";
import { RestaurantsContext } from "context/RestaurantsContext";
import { OrdersContext } from "context/OrdersContext";
import { FaPen, FaPlus, FaSlidersH } from "react-icons/fa";
import MyButton from "components/Elements/MyButton";
import MySearch from "components/Elements/MySearch";
import { restaurantServices } from "services";
import RestaurantMenuSelect from "components/RestaurantMenuSelect";
import { drawersOrder } from "static/staticData";
import DrawerAddItem from "../Drawers/DrawerAddItem";
import { DrawerNeutritions } from "../Drawers/DrawerNeutritions";
import DrawerFoodItemReviews from "../Drawers/DrawerFoodItemReviews";
import DrawerFoodCustomization from "../Drawers/DrawerFoodCustomization";
import { customAxios } from "helpers/custom-helpers/customAxios";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import DrawerRemoveAddedItem from "../Drawers/DrawerRemoveAddItem";
import shortid from "shortid";
import { useOutsideClick } from "services/functions";

export const _mocked_Food_Checks = [
  { icon: <i className="fas fa-candy-cane" />, name: "Sugar-Free", value: 4 },
  { icon: <i className="fas fa-cow" />, name: "Dairy-Free", value: 3 },
  { icon: <i className="fas fa-leaf" />, name: "Vegetarian", value: 0 },
  { icon: <i className="fas fa-leaf-heart" />, name: "Vegan", value: 1 },
  { icon: <i className="fas fa-wheat" />, name: "Gluten-Free", value: 2 },
];

export const ActiveOrderAddItem = () => {
  const { id } = useParams();
  const ref = useRef();
  const history = useHistory();
  const {
    orderDetailData,
    setOrderDetailData,
    openDrawers,
    openDrawer,
    closeDrawer,
    addItemsToOrder,
    setAddItemsToOrder,
    setOrderNotification,
    ordersRestaurantId,
  } = useContext(OrdersContext);

  const [foods, setFoods] = useState([]);
  const [menus, setMenus] = useState([]);
  const [menuId, setMenuId] = useState("");
  const [itemCategories, setItemCategories] = useState([]);
  const [fortFilterItemCategories, setForFilterItemCatogories] = useState([]);
  const [ratingType, setRatingType] = useState("Rating");
  const [foodType, setFoodType] = useState([]);
  const [openFoodType, setOpenFoodType] = useState(false);

  const [foodDetails, setFoodDetails] = useState("");

  const [search, setSearch] = useState("");

  const [SelectedItemCategories, setSelectedItemCategories] = useState("");

  // const getRestaurantProfile = async (id) => {
  //   const result = await restaurantServices.getRestaurantDetail(id);
  //   // if (result.status === 200) {
  //     console.log(result);
  //     // setMenus(result?.data?.menus);
  //     // let menuFirstData = result?.data?.menus[0];
  //     // setMenuId(menuFirstData?.id);
  //     // setFoods(result?.data?.foods);
  //   }
  // };

  function getRestaurantProfile() {
    customAxios
      .get(
        `/restaurant/${localStorage.getItem(
          "ordersRestaurantId"
        )}?get_detail=true`
      )
      .then((result) => {
        if (result?.status === 200) {
          setMenus(result?.data?.menus);
          let menuFirstData = result?.data?.menus[0];
          setMenuId(menuFirstData?.id);
          setFoods(result?.data?.foods);
        }
      });
  }

  useEffect(() => {
    if (ratingType !== "Rating" || foodType.length) {
      let filterData;
      let finalData = fortFilterItemCategories?.map((_category, _i) => {
        filterData = _category?.items?.filter((_item) => {
          const foodItemList = foods?.find((_food) => _food?.id === _item);
          return ratingType !== "Rating"
            ? foodItemList?.yum_percent >= ratingType
            : foodType?.length > 0
            ? foodItemList?.dietary?.some((i) => foodType?.includes(i))
            : _item;
        });
        return { ..._category, items: filterData };
      });
      setItemCategories(finalData);
    } else {
    }
  }, [ratingType, foodType]);

  useEffect(() => {
    getRestaurantProfile(
      localStorage.getItem("ordersRestaurantId") ||
        orderDetailData?.restaurant?.id
    );
  }, [orderDetailData]);

  const getMenuDetailById = async (menuId) => {
    if (menuId) {
      const result = await restaurantServices.getRestaurantMenuDetail(
        localStorage.getItem("ordersRestaurantId") ||
          orderDetailData?.restaurant?.id,
        menuId
      );
      if (result.status === 200) {
        setItemCategories(result?.data?.categories);
        setSelectedItemCategories(result?.data?.categories[0].id);
      }
    }
  };

  useEffect(() => {
    getMenuDetailById(menuId);
  }, [menuId]);

  const scroll = (i) => {
    let element = document.getElementById(`list-category-${i}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSwitchMenu = (menu) => {
    setMenuId(menu?.id);
  };

  const onClickItem = (food) => {
    openDrawer(drawersOrder.foodCustomization);
    // openDrawer(drawersOrder.addItem);
    setFoodDetails(food);
  };

  const customizationDone = (dressingList, foodPrice, foodDetails) => {
    let optionIds = [];
    dressingList.map((el) => {
      el.options.map((dl) => {
        if (dl.isSelected) {
          optionIds.push(dl.customization_option_id);
        }
      });
    });

    let addItemObj = {
      order_token: id,
      food_item_id: foodDetails?.id,
      quantity: foodPrice / foodDetails?.price,
      instructions: "",
      is_common: false,
      customizations: optionIds,
      price: foodDetails?.price,

      title: foodDetails?.title,
      unique_id: shortid.generate(),
    };

    let data = [...addItemsToOrder];
    data.push(addItemObj);
    setAddItemsToOrder(data);
    closeDrawer(drawersOrder.foodCustomization);
  };

  const addOrderButton = (details) => {
    closeDrawer(drawersOrder.addItem);
    let addItemObj = {
      order_token: id,
      food_item_id: details?.id,
      quantity: details?.quantity,
      instructions: "",
      is_common: false,
      customizations: [],
      price: details?.price,
    };
    let data = [...addItemsToOrder];
    data.push(addItemObj);
    setAddItemsToOrder(data);
  };

  const decreaseQuantity = (food) => {
    const data = addItemsToOrder?.filter((item) => {
      return item?.food_item_id === food?.id;
    });
    const otherItems = addItemsToOrder?.filter((item) => {
      return item?.food_item_id !== food?.id;
    });
    data.splice(-1);
    const finalArray = otherItems?.concat(data);
    setAddItemsToOrder(finalArray);
  };

  const removeItemFromOrder = (food) => {
    const data = addItemsToOrder?.filter(
      (item) => item?.food_item_id !== food?.id
    );
    setAddItemsToOrder(data);
  };

  const increaseQuantity = (food) => {
    setFoodDetails(food);
    // const data = addItemsToOrder.map((item) => {
    //   if (item.food_item_id === food.id) {
    //     return { ...item, quantity: item.quantity + 1 };
    //   }
    //   return item;
    // });
    // setAddItemsToOrder(data);
    openDrawer(drawersOrder.foodCustomization);
  };

  const addSelectedItems = () => {
    // addItemsToOrder.filter((el) => {
    //   delete el.price;
    // });
    customAxios
      .post(`/orders/${id}`, addItemsToOrder)
      .then((res) => {
        if (res.status === 200) {
          history.push(`/order-detail/${orderDetailData?.token}`);
          setOrderNotification({
            message: `<span style="font-weight:bold; color: #fff;margin-left:0">
            ${addItemsToOrder.reduce(
              (acc, item) => acc + item?.quantity,
              0
            )} items
          </span>
          for <span style="font-weight: bold; color: #fff;margin-left:0">
            $${addItemsToOrder.reduce(
              (acc, item) => acc + item?.price * item?.quantity,
              0
            )}
          </span>
          have been successfully added to the order. `,
            table: `Table ${orderDetailData?.table_number} • <span style="font-weight:bold; color:#ed2626; margin-left:0">
          #${orderDetailData?.token}
        </span>`,
          });
          setAddItemsToOrder([]);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const quantityMeasureRender = (foodId) => {
    let filterData = addItemsToOrder?.filter(
      (el) => el?.food_item_id === foodId
    );
    return filterData?.reduce((total, obj) => obj?.quantity + total, 0);
  };

  // -------
  const checkIsSelected = (id) => foodType.includes(id);

  const addFoodType = (item) => {
    if (!checkIsSelected(item)) {
      setFoodType([...foodType, item]);
    } else {
      setFoodType(foodType?.filter((i) => i !== item));
    }
  };

  useOutsideClick(ref, () => {
    if (openFoodType) setOpenFoodType(false);
  });


  return (
    <ActiveOrderAddItemPageContainer>
      <ActiveOrderAddItemPageHeaderContainer className="d-flex justify-content-between align-items-center">
        <div className="header-title">Add Item</div>
        <div>
          <Link
            to={`/order-detail/${id}`}
            onClick={() => {
              setAddItemsToOrder([]);
            }}
          >
            Cancel
          </Link>
          <MyDrawerButton
            className="add-item-btn"
            type={addItemsToOrder?.length > 0 ? "red" : "gray"}
            onClick={addSelectedItems}
            disabled={addItemsToOrder?.length === 0}
          >
            {addItemsToOrder?.length > 0
              ? `Add Items (${addItemsToOrder?.reduce(
                  (acc, item) => acc + item?.quantity,
                  0
                )})`
              : "Add Item"}
            <span
              style={{
                marginLeft: "10px",
              }}
            >
              {addItemsToOrder?.length > 0
                ? `$${addItemsToOrder?.reduce(
                    (acc, item) => acc + item?.price * item?.quantity,
                    0
                  )}`
                : false}
            </span>
          </MyDrawerButton>
        </div>
      </ActiveOrderAddItemPageHeaderContainer>
      <AddItemBodyContainer>
        <CategoriesContainer>
          <div className="category-scroll">
            {itemCategories && itemCategories?.length ? (
              <>
                {itemCategories
                  .sort((a, b) => a?.name.localeCompare(b?.name))
                  .map((_category, _i) => {
                    return (
                      <div
                        key={_i}
                        className="category-badge-wrapper"
                        onClick={() => {
                          scroll(_i);
                          setSelectedItemCategories(_category?.id);
                        }}
                      >
                        <Badge
                          bg="primary"
                          style={{
                            backgroundColor:
                              SelectedItemCategories === _category?.id
                                ? "#3E4245"
                                : "#27282A",
                            padding: "13px 24px",
                            fontSize: "14px",
                            cursor: "pointer",
                            borderRadius: "25px",
                            // marginRight: "16px",
                            color: "#fff",
                          }}
                        >
                          {_category?.name}
                        </Badge>
                      </div>
                    );
                  })}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="d-flex ml-auto">
            {/* search */}
            <div>
              <MySearch
              // value={}
              // onChange={() => {}}
              />
            </div>

            {/* dietary filter */}
            <div
              style={{
                position: "relative",
                width: "200px",
                height: "43px",
                borderRadius: "25px",
                background: "#27282a",
                cursor: "pointer",
                margin: "0 10px",
              }}
              className="dietary-select"
              ref={ref}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 20px",
                  color: "#fff",
                  alignItems: "center",
                  overflowX: "auto",
                  overflowY: "auto",
                }}
                onClick={() => {
                  setOpenFoodType(!openFoodType);
                }}
              >
                <span style={{ overflow: "auto" }}>
                  {/* {foodType.length > 0 && foodType.length <= 2
                    ? foodType.map((item) => check(item)).join(', ')
                    : 'Dietary'} */}
                  Dietary
                </span>
                {!openFoodType ? (
                  <i className="fas fa-angle-down" />
                ) : (
                  <i className="fas fa-angle-up" />
                )}
              </div>
              {openFoodType && (
                <div
                  className="options-dietary"
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: "-5%",
                    top: "110%",
                    borderRadius: "25px",
                    border: "solid 1px rgba(255, 255, 255, 0.08)",
                    background: "#151617",
                    height: "max-content",
                    zIndex: 99,
                    minWidth: "10rem",
                    fontSize: "1rem",
                    color: "#fff",
                    textAlign: "left",
                    padding: "10px 0",
                  }}
                >
                  {_mocked_Food_Checks &&
                    _mocked_Food_Checks.map((item, index) => (
                      <div
                        className={`${
                          foodType.includes(item.value)
                            ? "option-dietary-item active"
                            : "option-dietary-item "
                        }`}
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginLeft: 0,
                          alignItems: "center",
                          cursor: "pointer",
                          marginBottom: "3px",
                        }}
                        onClick={() => addFoodType(item.value)}
                      >
                        {item.icon} <span> {item.name}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>

            {/* rating filter */}
            <div>
              <MyDropDown
                className="menu-header-select"
                options={[3, 3.5, 4, 4.5, 5].map((item) => ({
                  label: item,
                  value: item,
                }))}
                value={ratingType && { label: ratingType, value: ratingType }}
                // value={{ label: ratingType, value: ratingType }}
                onChange={(selectedRatingType) => {
                  if (ratingType === selectedRatingType) {
                    setRatingType();
                  } else {
                    setRatingType(selectedRatingType);
                  }
                }}
                // width={115}
                iconClosed={<i className="fas fa-angle-down" />}
                placeholder="Rating"
              />
            </div>

            <MealTimeDropDown className="d-flex align-items-center justify-content-end">
              <RestaurantMenuSelect
                className="ml-3 meal-time-dropdown"
                options={[...menus]}
                defaultValueId={menuId}
                onChange={(option) => onSwitchMenu(option)}
              />
            </MealTimeDropDown>
          </div>
        </CategoriesContainer>

        <AddItemCategoriesAndItemList>
          {itemCategories && itemCategories?.length === 0 ? (
            <div>
              <div className="text-center">
                <h4>There’re no items yet</h4>
              </div>
            </div>
          ) : (
            <div>
              {itemCategories &&
                itemCategories?.length &&
                itemCategories?.map((_category, _i) => {
                  return (
                    <div key={_i} className="menu-category">
                      <div
                        className="category-header d-flex justify-content-between mb-4"
                        id={`list-category-${_i}`}
                      >
                        <h5 className="mb-0">{_category?.name}</h5>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {_category?.items &&
                          _category?.items?.length > 0 &&
                          _category?.items.map((_itemId, _i) => {
                            return (
                              <ItemCard
                                key={_i}
                                food={
                                  foods &&
                                  foods.find((_food) => _food.id === _itemId)
                                }
                                isDraggable={false}
                                onClickDelete={() => {}}
                                onClickEdit={() => {}}
                                isActionButtonsShowing={false}
                                added={
                                  addItemsToOrder?.findIndex(
                                    (item) => item?.food_item_id === _itemId
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                quantity={quantityMeasureRender(_itemId)}
                                decreaseQuantity={decreaseQuantity}
                                increaseQuantity={increaseQuantity}
                                deleteItem={removeItemFromOrder}
                                onClickItem={onClickItem}
                                addItemsToOrder={addItemsToOrder}
                              />
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </AddItemCategoriesAndItemList>
      </AddItemBodyContainer>
      <DrawerAddItem
        isActive={!!openDrawers[drawersOrder.addItem]}
        closePanel={() => closeDrawer(drawersOrder.addItem)}
        foodDetails={foodDetails}
        addOrderButton={addOrderButton}
      />
      <DrawerFoodItemReviews
        isActive={!!openDrawers[drawersOrder.foodItemReviews]}
        closePanel={() => closeDrawer(drawersOrder.foodItemReviews)}
        foodDetails={foodDetails}
      />
      <DrawerNeutritions
        isActive={!!openDrawers[drawersOrder.foodNeutritions]}
        closePanel={() => closeDrawer(drawersOrder.foodNeutritions)}
        foodDetails={foodDetails}
      />
      <DrawerFoodCustomization
        isActive={!!openDrawers[drawersOrder.foodCustomization]}
        closePanel={() => closeDrawer(drawersOrder.foodCustomization)}
        foodDetails={foodDetails}
        onClickDone={customizationDone}
      />
      <DrawerRemoveAddedItem
        isActive={!!openDrawers[drawersOrder.removeAddItem]}
        closePanel={() => closeDrawer(drawersOrder.removeAddItem)}
      />
    </ActiveOrderAddItemPageContainer>
  );
};
