import React, { useState } from "react";
import PropTypes from "prop-types";
import { FoodControlContainer } from "./style";
import { FoodChecks } from "./FoodChecks";
import { FaEllipsisH } from "react-icons/fa";
import { OverlayTrigger } from "react-bootstrap";
import EditItem from "../../Drawers/EditItemModal";

export default function FoodControl({
  foodItem = {},
  isActionButtonsShowing = true,
  handleClickFoodId = () => {},
  content,
  isActionButtonShowing = true,
  onClickEdit = () => {},
  onClickDelete = () => {},
}) {
  const [isEditable, setIsEditable] = useState(false);
  return (
    <FoodControlContainer>
      <FoodChecks checks={foodItem?.dietary || []} />
      <div className="card-col card-edit" id="card-edit-wrapper">
        {isActionButtonsShowing && isActionButtonShowing ? (
    <FaEllipsisH
    color="white"
    className="cursor"
    onClick={() => {
      handleClickFoodId(foodItem?.id)
      setIsEditable(true)
    }} 
  />

          // <OverlayTrigger
          //   container={() => document.getElementById("card-edit-wrapper")}
          //   placement="bottom-end"
          //   popperConfig={{ className: "fffffffffff" }}
          //   trigger="click"
          //   overlay={content}
          //   rootClose
          // >
          //   <FaEllipsisH
          //     color="white"
          //     className="cursor"
          //     onClick={() => handleClickFoodId(foodItem?.id)}
          //   />
          // </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
      <EditItem isActive={isEditable} closePanel={() => {
        setIsEditable(false)
        handleClickFoodId()
      }}
      name={foodItem.title}
      onClickEdit={onClickEdit}
      onClickDelete={onClickDelete}
      />
    </FoodControlContainer>
  );
}

FoodControl.propTypes = {
  foodItem: PropTypes.object,
};
