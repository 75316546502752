import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { RestaurantsContext } from "context/RestaurantsContext";
import React, {useEffect, useContext } from "react";
import { drawersMenu } from "static/staticData";
import { DrawerDeleteMenuAndItemContainer } from "./style";
import { useParams } from "react-router-dom";
import { restaurantServices } from "services";
import { customAxios } from "helpers/custom-helpers/customAxios";

export default function DrawerDeleteMenuAndItem({
  profile = {},
  isActive = false,
  closePanel = () => {},
  onDone = () => {},
  text,
}) {
  const {
    closeDrawer,
    postMenu,
    openDrawers,
    globalMenuId,
    postCategory,
    setSelectedMenu,
    selectedMenu,
    setFoods,
    categoryCloseDrawer, setCategoryCloseDrawer,
  } = useContext(RestaurantsContext);

  //   const onConfirmDeleteMenu = async () => {
  //     console.log({ postMenu });
  //     await onDone();
  //   };

  const { id } = useParams();

  useEffect(() => {}, [id]);

  function handleDeleteItem() {
    customAxios
      .delete(
        `/restaurant/${id}/menu/${globalMenuId}/category/${postCategory?.id}`
      )
      .then((res) => {
        // getCategoryData();
        getRestaurantProfile();
        getMenu();
        // closePanel();
        setCategoryCloseDrawer({
          ...categoryCloseDrawer,
          categoryEdit: false,
          openDrawerCategoryFirstOptions: false,
          openDrawerDeleteMenuAndItem: false,
        })
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getRestaurantProfile = async () => {
    const result = await restaurantServices.getRestaurantDetail(id);
    if (result.status === 200) {
      setFoods(result.data.foods);
    }
  };

  function getMenu() {
    customAxios
      .get(`/restaurant/${id}?get_detail=true`)
      .then((res) => {
        setSelectedMenu(
          res?.data?.menus.find((_menu) => _menu.id === selectedMenu.id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <DrawerDeleteMenuAndItemContainer
      isActive={isActive}
      closePanel={closePanel}
    >
      <DrawerContainer>
        <h2 className="drawer-title text-white text-left">Delete Category</h2>
        <p className="text-white text-left">
          Are you sure you want to delete <strong>{postCategory?.name}</strong>?
        </p>
        <div className="bottom-btns">
          <MyDrawerButton
            type="red"
            className="mb-4 text-bold"
            // onClick={() => closeDrawer(drawersMenu.deleteConfirm)}
            onClick={() => closePanel()}
          >
            Keep
          </MyDrawerButton>
          <MyDrawerButton
            type="gray"
            className="text-bold"
            // onClick={onConfirmDeleteMenu}
            onClick={handleDeleteItem}
          >
            Delete
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerDeleteMenuAndItemContainer>
  );
}
