import React from "react";
import { PaymentErrorModalContainer } from "./style";
import { PaymentFailed } from "assets/img";
import SingleText from "components/SingleText";
import SingleButton from "components/SingleButton";
import { colors } from "theme";
import { DrawerContainer } from "components/styledStyle";

const PaymentErrorModal = ({ isActive = false, closePanel = () => {} }) => {
  return (
    <PaymentErrorModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"25%"}
      left={"38%"}
      position="fixed"
      zIndex="9999"
      // zIndexBackground="9999"
    >
      <DrawerContainer className="p-0">
        <div className="drawer-content">
          <img src={PaymentFailed} alt="" width={"100%"} />
          <div
            style={{
              padding: "0 23px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SingleText
              size={22}
              lineHeight="33px"
              spacing="0"
              marginLeft={7}
              marginBottom={10}
              marginTop={20}
            >
              <b>Oops!</b>
            </SingleText>
            <SingleText
              spacing="0.27px"
              marginBottom={35}
              color={"rgba(255,255,255,0.8"}
              align="center"
              lineHeight="1.6"
            >
              <b>Process payment</b> is unavailable while customer has a{" "}
              <b>pending Order.</b>
            </SingleText>
            <SingleButton
              title="Okay"
              margin="auto 0px 0px 0px"
              btnColor={colors.redColor}
              onClick={closePanel}
            />
          </div>
        </div>
      </DrawerContainer>
    </PaymentErrorModalContainer>
  );
};

export default PaymentErrorModal;
