import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  FaCheck,
  FaEllipsisH,
  FaPen,
  FaSpinner,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { ArchivedListContainer, PopupContainer } from "./style";
import { OverlayTrigger } from "react-bootstrap";
import { colors } from "theme";
import moment from "moment";
// import ItemsList from "./ItemsList";
// import GuestsList from "./GuestsList";
import { OrdersContext } from "context/OrdersContext";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { drawersStaff } from "static/staticData";
import { RestaurantsContext } from "context/RestaurantsContext";
import EmptyStaff from "assets/img/noAvatar.png";

export default function ArchivedList({
  staff = {},
  header = false,
  onClickEdit = () => {},
  onClickDelete = () => {},
  selectedPage,
}) {
  const {
    openDrawer,
    openDrawers,
    closeDrawer,
    staffSearchInput,
    setStaffViewData,
    archivedMainModal,
    setArchivedMainModal,
  } = useContext(RestaurantsContext);

  const [isGuestsListShowing, setIsGuestsListShowing] = useState(false);
  const [isItemsListShowing, setIsItemsListShowing] = useState(false);

  const content = (
    <PopupContainer className="popup-wrapper">
      <div className="popup-item" onClick={() => onClickEdit(staff)}>
        <FaPen className="popup-item-icon" color="white" />
        <span content="Edit" size={14} weight={600}>
          Edit
        </span>
      </div>

      <div className="popup-item" onClick={() => onClickDelete(staff)}>
        <FaTrash className="popup-item-icon" color="white" />
        <span content="Delete" size={14} weight={600}>
          Delete
        </span>
      </div>
    </PopupContainer>
  );

  const contentPending = (
    <PopupContainer className="popup-wrapper">
      <div className="popup-item" onClick={() => onClickDelete(staff)}>
        <FaTrash className="popup-item-icon" color="white" />
        <span content="Delete" size={14} weight={600}>
          Delete
        </span>
      </div>
    </PopupContainer>
  );

  // phone number format
  function normalize(phone) {
    phone = phone?.replace(/[^\d]/g, "");
    if (phone?.length == 11) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else {
      return (
        <span style={{ color: "rgb(131 131 131)", marginLeft: "40px" }}>-</span>
      );
    }
    // return null;
  }

  function staffName() {
    const { staff_name } = staff;
    if (staff_name === undefined) {
      return <span style={{ color: "rgb(131 131 131)" }}>No Name</span>;
    } else {
      return getHighlightedText(staff_name, staffSearchInput,'#ed2626');
    }
  }

  const onClickThreeDotMenu = (staff) => {
    // console.log(staff)
    setArchivedMainModal({
      ...archivedMainModal,
      archivedMainOption: true,
    });
    setStaffViewData(staff);
  };


  const getHighlightedText = (text, highlight, color) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : "#fff", fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };




  return (
    <ArchivedListContainer id="restaurant-staff-card">
      <div className={`staff-card ${header && "is-header"}`}>
        <div className="card-col" style={{ flex: 1.2 }}>
          <div className="restaurent-profile">
            {header ? (
              <img
                src={staff?.staff_avatar ? staff?.staff_avatar : EmptyStaff}
                height={48}
                width={48}
                alt=""
                style={{ display: "none" }}
              />
            ) : (
              <img
                src={staff?.staff_avatar ? staff?.staff_avatar : EmptyStaff}
                height={48}
                width={48}
                alt=""
              />
            )}
            {header ? (
              <h6
                style={{
                  fontSize: "14px",
                  color: "rgb(255 255 255 / 33%)",
                }}
              >
                Name
              </h6>
            ) : (
              <h6 className="restaurant-name">{staffName()}</h6>
            )}
          </div>
        </div>

        <div className="card-col" style={{ flex: 1 }}>
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Role
            </h6>
          ) : (
            <h6>
              {(staff.is_owner && "Owner") ||
                (!staff.is_owner &&
                  staff?.role_slug === "manager" &&
                  "Manager") ||
                (staff?.role_slug === "kitchen_staff" && "Kitchen Staff") ||
                (staff?.role_slug === "waiter" && "Server / Host")}
            </h6>
          )}
        </div>

        <div className="card-col" style={{ flex: 1.2 }}>
          {header ? (
            <h6
              className="d-flex"
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
                cursor: "pointer",
              }}
            >
              Joined (current restaurant)
            </h6>
          ) : (
            <h6>{moment(staff.joined_date).format("MMM DD, YYYY")}</h6>
          )}
        </div>

        <div className="card-col">
          {header ? (
            <h6
              className="d-flex"
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
                cursor: "pointer",
              }}
            >
              Status
            </h6>
          ) : (
            <h6 style={{ width: "30%" }}>
              {/* {staff.status === 1 || staff.status === 3 ? (
                <div className="order-badge">
                  <div><FaSpinner color={colors.orange} /></div>
                  <div style={{ color: colors.orange }}> Active</div>
                </div>
              ) : (
                <div className="order-badge">
                  <div><FaCheck color={colors.green} /></div>
                  <div style={{ color: colors.green }}>Completed</div>
                </div>
              )} */}
              <div className="order-badge">
                <div style={{ color: colors.redColor }}>Deleted</div>
              </div>
            </h6>
          )}
        </div>

        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Rating
            </h6>
          ) : (
            <h6 style={{ position: "relative", width: "50%" }}>
              {staff?.rating ? (
                <div className="rating-wrapper">
                  <div>
                    <FaStar size={20} color="#FDCB6E" className="cursor" />
                  </div>
                  <div className="text-bold">{staff?.rating || "-"}</div>
                  <div
                    className="view cursor-pointer"
                    onClick={() => openDrawer(drawersStaff.staffReviews)}
                  >
                    <div className="view-text">View</div>
                    <div>
                      <i className="far fa-angle-right" />
                    </div>
                  </div>
                </div>
              ) : (
                "-"
              )}
            </h6>
          )}
        </div>

        <div className="card-col">
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Removed By
            </h6>
          ) : (
            <h6 style={{ position: "relative", width: "50%" }}>
             {staff.deleted_by || "-"}
            </h6>
          )}
        </div>

        <div className="card-col" style={{ flex: 1.1 }}>
          {header ? (
            <h6
              style={{
                fontSize: "14px",
                color: "rgb(255 255 255 / 33%)",
              }}
            >
              Removed
            </h6>
          ) : (
            <h6 className="total">
             {moment(staff.removed_date).format("MMM DD, YYYY")}
            </h6>
          )}
        </div>

        {/* <div className="card-edit" id="card-edit-wrapper">
          {!header && (
            <OverlayTrigger
              container={() => document.getElementById("card-edit-wrapper")}
              placement="bottom-end"
              popperConfig={{ className: "fffffffffff" }}
              trigger="click"
              overlay={
                staff?.serving_status === undefined ? contentPending : content
              }
              // overlay={content}
              rootClose
            >
              <FaEllipsisH color="white" className="cursor" />
            </OverlayTrigger>
          )}
        </div> */}

        <div className="card-edit" id="card-edit-wrapper">
          {!header && (
            <FaEllipsisH
              color="white"
              className="cursor"
              onClick={() => onClickThreeDotMenu(staff)}
            />
          )}
        </div>
      </div>
    </ArchivedListContainer>
  );
}

ArchivedList.propTypes = {
  menu: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  selectedPage: PropTypes.number,
};
