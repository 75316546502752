import React, { useContext, useEffect, useState } from "react";
import { DashboardPageContainer, MyCustomTooltip } from "./style";
// import { Col } from "react-bootstrap";
// import { Row } from "react-bootstrap";
import MyButton from "components/Elements/MyButton";
import MyChart from "components/Elements/MyChart/indexnew";
import MySelect from "components/Elements/MySelect";
import {
  DashboardHeader,
  DashboardStats,
  PeriodFilter,
  PopularList,
  RatedList,
} from "components/dashboard";
import { customAxios } from "helpers/custom-helpers/customAxios";
// import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { colors } from "theme";
import { DashboardContext } from "context/DashboardContext";
import { RestaurantsContext } from "context/RestaurantsContext";
import { drawerDashboard, drawersOverall } from "static/staticData";
import DrawerItem from "./Drawers/DrawerItem";
import { StyledIconWrapper } from "theme/styles";
import MyDateRange from "components/Elements/MyDateRange";
import { PopupCalendarContainer } from "components/analytics/AnalyticPeriod/style";
import moment from "moment";
import useOnclickOutside from "react-cool-onclickoutside";
import { Container, Popover, OverlayTrigger, Row, Col } from "react-bootstrap";
import ItemRestoreReviewModal from "./Drawers/RestoreReviewModal";
import ItemRemoveReviewsModal from "./Drawers/RemoveReviewModal";

export function DashboardPage() {
  const { openDrawer, openDrawers, closeDrawer } = useContext(
    RestaurantsContext
  );
  const [period, setPeriod] = useState("day");
  const [graphPeriod, setGraphPeriod] = useState("day");
  const [salesData, setSalesData] = useState({});
  const [graphData, setGraphData] = useState([]);
  // const [widgetData, setWidgetData] = useState({});

  const {
    periodPopularRestaurants,
    periodPopularItems,
    widgetData,
    setWidgetData,
    tempDateRange,
    setTempDateRange,
    setPeriodPopularRestaurants,
    setPeriodPopularItems,
  } = useContext(DashboardContext);

  const [popularRestaurants, setPopularRestaurants] = useState([]);

  const [popularItems, setPopularItems] = useState([]);
  const [filterDropdown, setFilterDropDown] = useState({
    id: 3,
    name: "Total Sales",
  });

  // const [tempDateRange, setTempDateRange] = useState();

  const [dateRange, setDateRange] = useState();
  const [salesGraphDateRange, setSalesGraphDateRange] = useState();
  const [
    popularRestaurantDateRange,
    setPopularRestaurantDateRange,
  ] = useState();
  const [popularItemDateRange, setPopularItemDateRange] = useState();

  const [overlayClick, setOverlayClick] = useState(false);
  const [salesGraphCalendar, setSalesGraphCalendar] = useState(false);
  const [popularRestaurantCalendar, setPopularRestaurantCalendar] = useState(
    false
  );
  const [popularItemCalendar, setPopularItemCalendar] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    graphMethod();
  }, [graphPeriod, filterDropdown, salesGraphDateRange]);

  useEffect(() => {
    salesMethod();
  }, [dateRange, period]);

  useEffect(() => {
    widgetMethod();
    setPeriodPopularRestaurants("day");
    setPeriodPopularItems("day");
  }, []);

  useEffect(() => {
    popularRestaurantsMethod();
  }, [periodPopularRestaurants, popularRestaurantDateRange]);

  useEffect(() => {
    popularItemsMethod();
  }, [periodPopularItems, popularItemDateRange]);

  // graph api
  function graphMethod() {
    let url;
    if (salesGraphDateRange) {
      url = `/dashboard/graph?period=${graphPeriod}&filter=${filterDropdown?.id}&start_date=${salesGraphDateRange?.startDate}&end_date=${salesGraphDateRange?.endDate}`;
    } else {
      url = `/dashboard/graph?period=${graphPeriod}&filter=${filterDropdown?.id}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        setGraphData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // sales api
  function salesMethod() {
    let url;
    if (dateRange) {
      url = `/dashboard/sales?period=${period}&start_date=${dateRange?.startDate}&end_date=${dateRange?.endDate}`;
    } else {
      url = `/dashboard/sales?period=${period}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        setSalesData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // widgets api
  function widgetMethod() {
    customAxios
      .get(`/dashboard/widgets`)
      .then((res) => {
        setWidgetData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // popular restaurants api
  function popularRestaurantsMethod() {
    let url;
    if (popularRestaurantDateRange) {
      url = `/dashboard/popular-restaurant?period=${periodPopularRestaurants}&start_date=${popularRestaurantDateRange?.startDate}&end_date=${popularRestaurantDateRange?.endDate}`;
    } else {
      url = `/dashboard/popular-restaurant?period=${periodPopularRestaurants}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        setPopularRestaurants(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // popular items api
  function popularItemsMethod() {
    let url;
    if (popularItemDateRange) {
      url = `/dashboard/popular-item?period=${periodPopularItems}&start_date=${popularItemDateRange?.startDate}&end_date=${popularItemDateRange?.endDate}`;
    } else {
      url = `/dashboard/popular-item?period=${periodPopularItems}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        setPopularItems(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // date range method
  const onChangeDate = (date) => {
    if (date && date?.endDate) {
      setTempDateRange({
        startDate: moment(date?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const ref = useOnclickOutside(() => {
    setOverlayClick(false);
    setSalesGraphCalendar(false);
  });

  function onChangeApply() {
    if (tempDateRange && tempDateRange?.endDate) {
      setDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
      setOverlayClick(!overlayClick);
    }
  }

  function onChangeSalesGraph() {
    if (tempDateRange && tempDateRange?.endDate) {
      setSalesGraphDateRange({
        startDate: moment(tempDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(tempDateRange?.endDate).format("YYYY-MM-DD"),
      });
    }
    setSalesGraphCalendar(!salesGraphCalendar);
  }

  return (
    <DashboardPageContainer>
      <DashboardHeader />
      <div className="dashboard-page-body">
        <Row className="dashboard-row">
          <Col md={6}>
            <div className="dashboard-card d-flex flex-column justify-content-between">
              <div className="dashboard-card-header">
                <div className="graph-drop-down">
                  <MySelect
                    className="ml-auto"
                    options={[
                      { id: 3, name: "Total Sales" },
                      { id: 2, name: "Average Order" },
                      { id: 1, name: "# of Orders" },
                    ]}
                    defaultValue={filterDropdown}
                    defaultValueId={filterDropdown}
                    onChange={setFilterDropDown}
                    dropdownLabel=""
                  />
                </div>
                <div className="time-periods">
                  <div
                    className={`period-filter pointer ${
                      graphPeriod === "day" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setGraphPeriod("day");
                      setSalesGraphDateRange();
                    }}
                  >
                    Today
                  </div>
                  <div
                    className={`period-filter pointer ${
                      graphPeriod === "week" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setGraphPeriod("week");
                      setSalesGraphDateRange();
                    }}
                  >
                    Week
                  </div>
                  <div
                    className={`period-filter pointer ${
                      graphPeriod === "month" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setGraphPeriod("month");
                      setSalesGraphDateRange();
                    }}
                  >
                    Month
                  </div>
                  <div
                    className={`period-filter pointer ${
                      graphPeriod === "year" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setGraphPeriod("year");
                      setSalesGraphDateRange();
                    }}
                  >
                    Total
                  </div>
                  <div id="sales-calendar-popup">
                    <span
                      className="checked ml-3"
                      onClick={() => {
                        setSalesGraphCalendar(true);
                        setTempDateRange({
                          startDate: "",
                          endDate: "",
                        });
                      }}
                    >
                      <i className="fas fa-calendar-alt cursor" />
                    </span>
                    {salesGraphCalendar && (
                      <PopupCalendarContainer
                        className="popup-sales-calendar"
                        ref={ref}
                      >
                        <MyDateRange
                          months={2}
                          onChangeDate={onChangeDate}
                          date={salesGraphDateRange}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 20px",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>
                            {salesGraphDateRange?.startDate
                              ? moment(salesGraphDateRange?.startDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                            {salesGraphDateRange && "  to  "}
                            {salesGraphDateRange?.endDate
                              ? moment(salesGraphDateRange?.endDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </span>

                          <MyButton
                            className="apply-date"
                            type={
                              tempDateRange?.endDate ||
                              salesGraphDateRange?.endDate
                                ? "red"
                                : "gray"
                            }
                            onClick={onChangeSalesGraph}
                          >
                            Apply
                          </MyButton>
                        </div>
                      </PopupCalendarContainer>
                    )}
                  </div>
                </div>
              </div>
              <h6
                style={{ padding: "0 35px", fontSize: "14px", fontWeight: 500 }}
              >
                {salesGraphDateRange?.startDate && salesGraphDateRange?.endDate
                  ? `${moment(salesGraphDateRange.startDate).format(
                      "dddd MMMM DD, YYYY"
                    )}
                 - ${moment(salesGraphDateRange.endDate).format(
                   "dddd MMMM DD, YYYY"
                 )}`
                  : graphPeriod === "day"
                  ? moment().format("dddd MMMM DD, YYYY")
                  : graphPeriod === "week"
                  ? `${
                      moment()
                        .subtract(6, "days")
                        .format("dddd MMMM DD, YYYY")
                        .split("T")[0]
                    }
                 - ${moment().format("dddd MMMM DD, YYYY")}`
                  : graphPeriod === "month"
                  ? `${moment()
                      .subtract(30, "days")
                      .format("dddd MMMM DD, YYYY")}
                 - ${moment().format("dddd MMMM DD, YYYY")}`
                  : graphPeriod === "year"
                  ? `${moment()
                      .subtract(1, "year")
                      .format("dddd MMMM DD, YYYY")}
                - ${moment().format("dddd MMMM DD, YYYY")}`
                  : moment()
                      .format("dddd, MMMM DD, YYYY")
                      .split("T")[0]}
              </h6>
              <div
                className="dashboard-card-body"
                style={{ padding: "0 20px" }}
              >
                <MyChart
                  graphData={graphData}
                  timeLine={filterDropdown?.id}
                  graphPeriod={
                    salesGraphDateRange?.startDate &&
                    salesGraphDateRange?.endDate
                      ? "Custom"
                      : graphPeriod
                  }
                  endDate={salesGraphDateRange?.endDate}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="sales-wrapper">
              <div className="sales-flex">
                <div className="statistic">Statistic</div>
                <div className="time-periods">
                  <div
                    className={`period-filter pointer ${
                      period === "day" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setPeriod("day");
                      setDateRange();
                    }}
                  >
                    Today
                  </div>
                  <div
                    className={`period-filter pointer ${
                      period === "week" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setPeriod("week");
                      setDateRange();
                    }}
                  >
                    Week
                  </div>
                  <div
                    className={`period-filter pointer ${
                      period === "month" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setPeriod("month");
                      setDateRange();
                    }}
                  >
                    Month
                  </div>
                  <div
                    className={`period-filter pointer ${
                      period === "total" ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      setPeriod("total");
                      setDateRange();
                    }}
                  >
                    Total
                  </div>
                  <div id="statistic-calendar-popup-handle">
                    <span
                      className="checked ml-4"
                      onClick={() => {
                        setOverlayClick(true);
                        setTempDateRange({
                          startDate: "",
                          endDate: "",
                        });
                      }}
                    >
                      <i className="fas fa-calendar-alt cursor" />
                    </span>
                    {overlayClick && (
                      <PopupCalendarContainer
                        className="popup-wrapper"
                        ref={ref}
                      >
                        <MyDateRange
                          months={2}
                          onChangeDate={onChangeDate}
                          date={dateRange}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 20px",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>
                            {dateRange?.startDate
                              ? moment(dateRange?.startDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                            {dateRange && "  to  "}
                            {dateRange?.endDate
                              ? moment(dateRange?.endDate).format("MM/DD/YYYY")
                              : ""}
                          </span>

                          <MyButton
                            className="apply-date"
                            type={
                              tempDateRange?.endDate || dateRange?.endDate
                                ? "red"
                                : "gray"
                            }
                            onClick={onChangeApply}
                          >
                            Apply
                          </MyButton>
                        </div>
                      </PopupCalendarContainer>
                    )}
                  </div>
                </div>
              </div>

              <Container className="sales-stat-container">
                <Row className="first-row">
                  <Col>
                    <div>
                      <div className="sales-label">
                        <span className="shadow-text">Total Sales</span>{" "}
                        {/* {salesData?.total_sales === undefined ? null : (
                          <>
                            <span>
                              {salesData?.total_sales >= 0 ? (
                                <FaArrowCircleUp color={colors.green} />
                              ) : (
                                <FaArrowCircleDown color={colors.orange} />
                              )}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  salesData?.total_sales >= 0
                                    ? "#1fc086"
                                    : "#f7a64c",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <MyCustomTooltip id={`tooltip-top`}>
                                    from last period
                                  </MyCustomTooltip>
                                }
                              >
                                <b>
                                   {Math.abs(salesData?.total_sales)}%
                                </b>
                              </OverlayTrigger>
                            </span>
                          </>
                        )} */}
                      </div>
                      <div className="sales-numbers">
                        $
                        {salesData?.total_sales === null
                          ? 0
                          : salesData?.total_sales}
                      </div>
                    </div>
                  </Col>
                  <Col md={{ offset: 1 }}>
                    <div>
                      <div className="sales-label">
                        <span className="shadow-text">Fees Paid</span>{" "}
                        {/* {salesData?.average_order === undefined ? null : (
                          <>
                            <span>
                              {salesData?.average_order >= 0 ? (
                                <FaArrowCircleUp color={colors.green} />
                              ) : (
                                <FaArrowCircleDown color={colors.orange} />
                              )}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  salesData?.average_order >= 0
                                    ? "#1fc086"
                                    : "#f7a64c",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <MyCustomTooltip id={`tooltip-top`}>
                                    from last period
                                  </MyCustomTooltip>
                                }
                              >
                                <b>
                                  {Math.abs(salesData?.average_order)}%
                                </b>
                              </OverlayTrigger>
                            </span>
                          </>
                        )} */}
                      </div>
                      <div className="sales-numbers">
                        ${salesData?.fees === null ? 0 : salesData?.fees}
                      </div>
                    </div>
                  </Col>
                  <Col md={{ offset: 1 }}>
                    <div>
                      <div className="sales-label">
                        <span className="shadow-text">Average Order</span>{" "}
                        {/* {salesData?.orders === undefined ? null : (
                          <>
                            <span>
                              {salesData?.orders >= 0 ? (
                                <FaArrowCircleUp color={colors.green} />
                              ) : (
                                <FaArrowCircleDown color={colors.orange} />
                              )}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  salesData?.orders >= 0
                                    ? "#1fc086"
                                    : "#f7a64c",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <MyCustomTooltip id={`tooltip-top`}>
                                    from last period
                                  </MyCustomTooltip>
                                }
                              >
                                <b>{Math.abs(salesData?.orders)}%</b>
                              </OverlayTrigger>
                            </span>
                          </>
                        )} */}
                      </div>
                      <div className="sales-numbers">
                        $
                        {salesData?.average_order === null
                          ? 0
                          : salesData?.average_order}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="second-row">
                  <Col>
                    <div>
                      <div className="sales-label">
                        <span className="shadow-text">Orders</span>{" "}
                        {/* {salesData?.orders === undefined ? null : (
                          <>
                            <span>
                              {salesData?.orders >= 0 ? (
                                <FaArrowCircleUp color={colors.green} />
                              ) : (
                                <FaArrowCircleDown color={colors.orange} />
                              )}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  salesData?.orders >= 0
                                    ? "#1fc086"
                                    : "#f7a64c",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <MyCustomTooltip id={`tooltip-top`}>
                                    from last period
                                  </MyCustomTooltip>
                                }
                              >
                                <b>{Math.abs(salesData?.orders)}%</b>
                              </OverlayTrigger>
                            </span>
                          </>
                        )} */}
                      </div>
                      <div className="sales-numbers">
                        {salesData?.orders === null ? 0 : salesData?.orders}
                      </div>
                    </div>
                  </Col>
                  <Col md={{ offset: 1 }}>
                    <div className="sales-stat">
                      <div className="sales-label">
                        <span className="shadow-text">Restaurants</span>{" "}
                        {/* {salesData?.restaurant === undefined ? null : (
                          <>
                            <span>
                              {salesData?.restaurant >= 0 ? (
                                <FaArrowCircleUp color={colors.green} />
                              ) : (
                                <FaArrowCircleDown color={colors.orange} />
                              )}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  salesData?.restaurant >= 0
                                    ? "#1fc086"
                                    : "#f7a64c",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <MyCustomTooltip id={`tooltip-top`}>
                                    from last period
                                  </MyCustomTooltip>
                                }
                              >
                                <b>
                                  {Math.abs(salesData?.restaurant)}%
                                </b>
                              </OverlayTrigger>
                            </span>
                          </>
                        )} */}
                      </div>
                      <div className="sales-numbers">
                        {salesData?.restaurant === null
                          ? 0
                          : salesData?.restaurant}
                      </div>
                    </div>
                  </Col>
                  <Col md={{ offset: 1 }}>
                    <div>
                      <div className="sales-label">
                        <span className="shadow-text">Customers</span>{" "}
                        {/* {salesData?.users === undefined ? null : (
                          <>
                            <span>
                              {salesData?.users >= 0 ? (
                                <FaArrowCircleUp color={colors.green} />
                              ) : (
                                <FaArrowCircleDown color={colors.orange} />
                              )}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  salesData?.users >= 0
                                    ? "#1fc086"
                                    : "#f7a64c",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <MyCustomTooltip id={`tooltip-top`}>
                                    from last period
                                  </MyCustomTooltip>
                                }
                              >
                                <b>{Math.abs(salesData?.users)}%</b>
                              </OverlayTrigger>
                            </span>
                          </>
                        )} */}
                      </div>
                      <div className="sales-numbers">
                        {salesData?.users === null ? 0 : salesData?.users}
                      </div>
                    </div>
                  </Col>
                  {/* <Col md={{ offset: 1 }}></Col> */}
                </Row>
              </Container>
            </div>
          </Col>
        </Row>

        <RatedList restaurants={widgetData} />

        <div className="popular-items-container">
          <PopularList
            className="popular-restaurants"
            title="Popular Restaurants"
            list={popularRestaurants}
            amountFormatter={(value) => `${value} orders`}
            popularRestaurantCalendar={popularRestaurantCalendar}
            setPopularRestaurantCalendar={setPopularRestaurantCalendar}
            setPopularRestaurantDateRange={setPopularRestaurantDateRange}
            popularRestaurantDateRange={popularRestaurantDateRange}
          />
          <PopularList
            className="popular-items"
            title="Popular Items"
            list={popularItems}
            isFood
            popularItemCalendar={popularItemCalendar}
            setPopularItemCalendar={setPopularItemCalendar}
            setPopularItemDateRange={setPopularItemDateRange}
            popularItemDateRange={popularItemDateRange}
          />
        </div>
      </div>
      <DrawerItem
        isActive={!!openDrawers[drawersOverall.dashboardDrawer]}
        closePanel={() => closeDrawer(drawersOverall.dashboardDrawer)}
      />

      <ItemRemoveReviewsModal
        isActive={!!openDrawers[drawersOverall.itemRemoveReviewModalScreen]}
        closePanel={() =>
          closeDrawer(drawersOverall.itemRemoveReviewModalScreen)
        }
      />

      <ItemRestoreReviewModal
        isActive={!!openDrawers[drawersOverall.itemRestoreReviewModalScreen]}
        closePanel={() =>
          closeDrawer(drawersOverall.itemRestoreReviewModalScreen)
        }
      />
    </DashboardPageContainer>
  );
}
