import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useEffect, useState } from "react";
import { CustomersContext } from "context/CustomersContext";
import { drawersCustomers } from "static/staticData";
import { FaChevronLeft } from "react-icons/fa";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { DrawerSearchAddressContainer } from "./style";
import DrawerInputWithVerify from "components/Elements/DrawerElements/DrawerInputWithVerify";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const DrawerSearchAddress = ({
  isActive = false,
  closePanel = () => {},
  customerId,
  drawerTitle = "Found Address",
  setAddress,
  address,
  setPlaceId,
}) => {
  const {
    openDrawer,
    profileData,
    setProfileData,
    closeDrawer,
    editCustomerData,
    setEditCustomerData,
  } = useContext(CustomersContext);
  const [search, setSearch] = useState();

  // **google api suggestions**
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
       componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    // setValue("");
  });

  const handleSelect = ({ description, place_id }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setPlaceId(place_id);
    setValue(description, false);
    setAddress(description);
    clearSuggestions();
    closeDrawer(drawersCustomers.foundAddress);
    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      console.log("📍 Coordinates: ", { lat, lng });
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        // <li key={place_id} onClick={handleSelect(suggestion)}>
        //   <strong>{main_text}</strong> <small>{secondary_text}</small>
        // </li>
        <div
          className="address-list-item cursor-pointer"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <div className="address-info">
            <h6 className="text-bold">{main_text}</h6>
            <h6 className="opacity6">{secondary_text}</h6>
          </div>
          <h6>
            <i className="fas fa-angle-right" />
          </h6>
        </div>
      );
    });
  // **end of google api suggestions**

  return (
    <DrawerSearchAddressContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">--{drawerTitle}</div>
          <div></div>
        </div>
        <div className="input-divs mb-auto mt-4">
          <div className="location-input-wrapper">
            <DrawerInput
              className="location-input"
              placeholder="Enter an address"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <i
              className="fa fa-times close-icon"
              aria-hidden="true"
              onClick={() => {
                clearSuggestions();
                setValue("");
              }}
            />
          </div>
          <span ref={ref} className="google-location-wrapper">
            {status === "OK" && <ul>{renderSuggestions()}</ul>}
          </span>
        </div>
      </DrawerContainer>
    </DrawerSearchAddressContainer>
  );
};

export default DrawerSearchAddress;
