import styled from "@emotion/styled";
import { colors } from "theme";

export const StaffAnalyticItemContainer = styled.div`
  width: 450px;
  height: 110px;
  background-color: ${colors.gray2};
  border-radius: 20px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 24px;
  background-image: ${(props) => (props.image ? `url(${props.image})` : "")};
  background-position: ${(props) => (props.bottom ? "bottom" : "top")};
  background-repeat: no-repeat;
  background-size: cover;
  .stat-item-header {
    font-size: 14px !important;
    color: ${colors.white};
    font-weight: 500;
    letter-spacing: -0.38px;
    i {
      color: ${colors.white};
      margin-right: 10px;
    }
  }
  .stat-item-footer-counter {
    font-size: 36px !important;
    font-weight: bold;
    letter-spacing: -0.34px;
  }
`;
