import React from "react";
import { OrdersByHourChartContainer } from "./style";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ChartTooltip } from "../../../ChartTooltip";

export const OrdersByHourChart = ({ chartData }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <ChartTooltip
          title={payload[0]?.payload?.name}
          importantThing={`${payload[0]?.payload?.orders} orders`}
        />
      );
    }
    return null;
  };
  return (
    <OrdersByHourChartContainer>
      <ResponsiveContainer width="100%" height="100%" ba>
        <BarChart
          data={chartData}
          barGap={9}
          margin={{ top: 0, right: 6, bottom: 0, left: 0 }}
        >
          <CartesianGrid vertical={false} stroke="#28292a" />
          <XAxis dataKey="name" axisLine={false} tickCount={24} interval={1} />
          <YAxis
            tick={{ stroke: "#545556" }}
            allowDecimals={false}
            axisLine={false}
            tickCount={4}
            tickLine={false}
          />
          <Tooltip 
           content={<CustomTooltip />} 
           cursor={false} 
          />
          <Bar
            dataKey="orders"
            fill="#1fc086"
            barSize={25}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </OrdersByHourChartContainer>
  );
};
