import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import React, { useContext, useState, useEffect } from "react";
import { DrawerRemoveItemContainer } from "./style";
import MyMessageBoxItemNew from "components/Elements/MessageBoxItemNew";
import { RestaurantsContext } from "context/RestaurantsContext";
import { customAxios } from "helpers/custom-helpers/customAxios";
import { useParams } from "react-router-dom";

export default function DrawerStatusStaffConfirm({
  isActive = false,
  closePanel = () => {},
  onClickSetStatus = () => {},
  status,
}) {
  const { id } = useParams();

  const { postStaff } = useContext(RestaurantsContext);
  const [staffReason, setStaffReason] = useState("");

  const { first_name, last_name, role_slug } = postStaff;

  const [activeStaffNotification, setActiveStaffNotification] = useState();
  const [inactiveStaffNotification, setInactiveStaffNotification] = useState();

  useEffect(() => {
    if (activeStaffNotification) {
      setTimeout(() => setActiveStaffNotification(null), 2 * 1000);
    }
  }, [activeStaffNotification]);

  useEffect(() => {
    if (inactiveStaffNotification) {
      setTimeout(() => setInactiveStaffNotification(null), 2 * 1000);
    }
  }, [inactiveStaffNotification]);

  function clearInput() {
    setStaffReason("");
  }

  function onClickStaffActive() {
    const activeDataConfig = {
      staff_id: postStaff?.id,
      is_suspended: false,
    };
    customAxios
      .patch(`/restaurant/${id}/staff/suspend`, activeDataConfig)
      .then((res) => {
        if (res.status === 200) {
          clearInput();
          setActiveStaffNotification({
            message: "Status changed to Active.",
            type: "active-success",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">
            ${first_name && first_name} ${last_name && last_name} 
            ${(role_slug === "waiter" && " (Server)") ||
              (role_slug === "kitchen_staff" && "(Kitchen Staff)") ||
              (role_slug === "manager" && "(Manager)")}
            </span> status changed to Active.`,
          });
          closePanel();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function onClickStaffInactive() {
    const inactiveDataConfig = {
      staff_id: postStaff?.id,
      note_to_staff: staffReason,
      is_suspended: true,
    };
    customAxios
      .patch(`/restaurant/${id}/staff/suspend`, inactiveDataConfig)
      .then((res) => {
        if (res.status === 200) {
          clearInput();
          setInactiveStaffNotification({
            message: "Status changed to Inactive.",
            type: "inactive-success",
            table: `<span style="font-weight:bold; color:#7d7e7f; margin-left:0">
            ${first_name && first_name} ${last_name && last_name} 
            ${(role_slug === "waiter" && " (Server)") ||
              (role_slug === "kitchen_staff" && "(Kitchen Staff)") ||
              (role_slug === "manager" && "(Manager)")}
            </span> status changed to Inactive.`,
          });
          closePanel();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <DrawerRemoveItemContainer
      isActive={isActive}
      closePanel={closePanel}
      renderLeftIcon={() => {}}
      left="40%"
      top="20%"
    >
      <DrawerContainer>
        {activeStaffNotification && (
          <MyMessageBoxItemNew
            message={
              activeStaffNotification?.message
                ? activeStaffNotification?.message
                : ""
            }
            table={
              activeStaffNotification?.table
                ? activeStaffNotification?.table
                : ""
            }
            type={
              activeStaffNotification?.type ? activeStaffNotification?.type : ""
            }
            onClick={() => setActiveStaffNotification(null)}
          />
        )}

        {inactiveStaffNotification && (
          <MyMessageBoxItemNew
            message={
              inactiveStaffNotification?.message
                ? inactiveStaffNotification?.message
                : ""
            }
            table={
              inactiveStaffNotification?.table
                ? inactiveStaffNotification?.table
                : ""
            }
            type={
              inactiveStaffNotification?.type
                ? inactiveStaffNotification?.type
                : ""
            }
            onClick={() => setInactiveStaffNotification(null)}
          />
        )}

        <div className="set-staff-container">
          <div className="modal-title">
            {postStaff?.serving_status === true ? "Set Active Status" : "Set Inctive Status"}
          </div>

          {postStaff?.serving_status === true ? (
            <div className="active-staff">
              <span>
                {`${first_name && first_name} ${last_name && last_name}`}{" "}
                {`${(role_slug === "waiter" && " (Server)") ||
                  (role_slug === "kitchen_staff" && "(Kitchen Staff)") ||
                  (role_slug === "manager" && "(Manager)")}`}
              </span>{" "}
              status will be changed to Active.
              <br />
              Are you sure?
            </div>
          ) : (
            <div className="input-area">
              <div className="d-flex justify-content-between">
                <div className="label-text">Describe the Reason</div>
                <div className="label-text">
                  <i>Mandatory • Visible to customer</i>
                </div>
              </div>
              <input
                className="w-100"
                type="text"
                value={staffReason || ""}
                placeholder="Type here"
                onChange={(event) => setStaffReason(event.target.value)}
              />
            </div>
          )}

          {postStaff?.serving_status === true ? (
            <MyDrawerButton
              type="red"
              className="status-btn"
              // onClick={() => onClickSetStatus(status)}
              onClick={onClickStaffActive}
            >
              Set Active
            </MyDrawerButton>
          ) : (
            <MyDrawerButton
              type={staffReason ? "red" : "gray"}
              className="status-btn"
              // onClick={() => onClickSetStatus(status)}
              onClick={onClickStaffInactive}
              disabled={staffReason ? false : true}
            >
              Set Inactive
            </MyDrawerButton>
          )}

          <div
            className="cancel-btn cursor-pointer"
            onClick={() => closePanel()}
          >
            Cancel
          </div>
        </div>
      </DrawerContainer>
    </DrawerRemoveItemContainer>
  );
}
