import styled from "@emotion/styled";
import { colors } from "theme";

export const BlockOrderDetailContainer = styled.div`
  min-height: 50px;
  background-color: ${colors.gray2};
  border-radius: 20px;
  .card-block {
    .card-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pending-status {
        width: 29px;
        height: 29px;
        padding: 8px 8px 7px;
        border-radius: 41px;
        background-image: linear-gradient(314deg, #ff7d7d 95%, #ed2626 5%);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.29px;
        text-align: center;
        color: #fff;
        margin-right: 21px;
      }
      .order-ready-status {
        width: 29px;
        height: 29px;
        padding: 8px 8px 7px;
        border-radius: 41px;
        background-image: linear-gradient(322deg, #fdcb6e 86%, #f7a64c 12%);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.29px;
        text-align: center;
        color: #fff;
        margin-right: 21px;
      }
      h5 {
        margin-bottom: 0;
      }
    }
    .card-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 20px 16px 28px;
      border-radius: 20px;
      background-color: #27282a;
      margin-bottom: 11px;
      .margin-top-3 {
        // margin-top: 1px;
      }
      .card-item-content {
        flex: 1;
        .item-row {
          display: flex;
          justify-content: space-between;
          #review-sub-order-delete-pop-wrapper {
            margin-left: 27px;
            margin-top: -5px;
            position: relative;
            .popup-wrapper {
              width: max-content;
              z-index: 1;
            }
          }
        }
      }
    }
    .card-button {
      width: 100%;
      margin: 12px 0;
    }
  }
`;

export const FoodItemDetailsAndActionsContainer = styled.div`
  .card-button {
    width: 100%;
    margin: 12px 0;
  }
  .card-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 19px 28px;
    border-radius: 13px;
    background-color: #27282a;
    margin-bottom: 11px;
    .card-item-content {
      flex: 1;
      .item-row {
        display: flex;
        justify-content: space-between;
        #review-sub-order-delete-pop-wrapper {
          margin-left: 27px;
          margin-top: -5px;
          position: relative;
          .popup-wrapper {
            width: max-content;
            z-index: 1;
          }
        }
      }
    }
  }
  .card-item-actions {
    border-radius: 13px;
    background-color: rgba(255, 255, 255, 0.02);
    display: flex;
    margin-bottom: 11px;

    .toggle-action-icon-div {
      width: 164px;
      padding: 20px;
      background-color: #27282a;
      text-align: center;
      border-radius: 13px 0 0 13px;
      cursor: pointer;
    }
    .delete-action-icon-div {
      width: 164px;
      padding: 20px;
      background-color: #ed2626;
      text-align: center;
      cursor: pointer;
    }
    .edit-action-icon-div {
      width: 164px;
      padding: 20px;
      background-color: #27282a;
      text-align: center;
      cursor: pointer;
    }
    .check-action-icon-div {
      width: 305px;
      padding: 20px;
      background-color: #1fc086;
      text-align: center;
      border-radius: 0 13px 13px 0;
      cursor: pointer;
    }
    .undo-action-icon-div {
      width: 629px;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 20px;
      text-align: center;
      border-radius: 0 13px 13px 0;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
    .delete-action-icon-div-for-delivered {
      width: 312px;
      padding: 20px;
      background-color: #ed2626;
      text-align: center;
      cursor: pointer;
    }
    .edit-action-icon-div-for-delivered {
      width: 312px;
      padding: 20px;
      background-color: #27282a;
      text-align: center;
      cursor: pointer;
      border-radius: 0 13px 13px 0;
    }
  }
`;
