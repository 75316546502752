/* eslint-disable no-useless-escape */
import React, { useContext, useState, useEffect } from "react";
import DrawerInput from "components/Elements/DrawerElements/DrawerInput";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";
import { DrawerContainer } from "components/styledStyle";
import { MyAccountContext } from "context/MyAccountContext";
import { drawersMyAccount } from "static/staticData";
import { DrawerChangePhoneNumberContainer } from "./style";
// import { isValidEmail } from "services/functions";
import { FaChevronLeft } from "react-icons/fa";

export default function DrawerChangePhoneNumber({
  isActive = false,
  closePanel = () => {},
}) {
  const { openDrawer, myAccount, setMyAccount } = useContext(MyAccountContext);
  const [phoneField, setPhoneField] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);

  

  useEffect(() => {
    setCanSubmit(phoneField && isValidPhoneNumber(phoneField) );
  }, [phoneField]);

  function isValidPhoneNumber(phoneFieldValid) {
    return phoneFieldValid.length > 10
  }

  const phoneValueChange = (e) => {
    e.preventDefault();
    setPhoneField(e.target.value);
  };

  const onClickContinue = () => {
    if (!canSubmit) {
      return;
    }
    // setMyAccount({ ...myAccount, emailRequestedToChange: phoneField });
    // openDrawer(drawersMyAccount.emailSuccess);
  };

  return (
    <DrawerChangePhoneNumberContainer isActive={isActive} closePanel={closePanel}>
      <DrawerContainer>
        <div className="top-title">
          <div>
            <FaChevronLeft
              size={20}
              onClick={closePanel}
              className="cursor-pointer"
            />
          </div>
          <div className="title">Phone Number</div>
          <div></div>
        </div>
        <div className="drawer-content">
          <h6 className="text-bold">Update phone number.</h6>
          <div className="email-input">
            <DrawerInput
              placeholder="Enter phone number"
              value={phoneField}
              onChange={(e) => phoneValueChange(e)}
            />
          </div>
        </div>
        <div className="bottom-btns">
          <MyDrawerButton
            type={canSubmit ? "red" : "gray"}
            onClick={onClickContinue}
            className="text-bold"
          >
            Continue
          </MyDrawerButton>
        </div>
      </DrawerContainer>
    </DrawerChangePhoneNumberContainer>
  );
}
