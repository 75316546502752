import React from "react";
import { CashPaymentModalContainer } from "./style";
import { DrawerContainer } from "components/styledStyle";
import MyDrawerButton from "components/Elements/DrawerElements/MyDrawerButton";

const NoPaymentNecessaryModal = ({
  isActive = false,
  closePanel = () => {},
  withoutPaymentProcess = () => {},
}) => {
  return (
    <CashPaymentModalContainer
      isActive={isActive}
      closePanel={closePanel}
      top={"0%"}
      left={"0%"}
      position="absolute"
    >
      <DrawerContainer>
        <div className="remove-item-modal">
          <div className="delete-icon">
            <i className="fas fa-money-bill-wave" />
          </div>
          <div className="delete-suborder-title">No Payment Necessary</div>
          <div className="delete-suborder-meta1 mb-2">
            Are you sure you want to complete this order without collecting a
            payment?
          </div>
          <div className="delete-suborder-meta opacity6">
            Feasttt charges a service fee for every order. For no payment
            necessary transactions, the fee is automatically deducted from the
            overall restaurant earnings.
          </div>
          <div className="delete-suborder-actions d-flex flex-column">
            <MyDrawerButton
              type="red"
              onClick={() => {
                withoutPaymentProcess();
              }}
            >
              <b>Complete Order</b>
            </MyDrawerButton>
            <MyDrawerButton
              onClick={() => {
                closePanel();
              }}
              type="gray"
            >
              <b>Back</b>
            </MyDrawerButton>
          </div>
        </div>
      </DrawerContainer>
    </CashPaymentModalContainer>
  );
};

export default NoPaymentNecessaryModal;
