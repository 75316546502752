import styled from "@emotion/styled";

export const ProfileCardStatContainer = styled.div`
  padding: 20px 36px;
  .statistic-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      color: #ffffff;
      font-weight: 700;
    }
    .date-btn {
      padding: 10px 15px;
      border-radius: 20px;
      background-color: #27282a;
      cursor: pointer;
      i {
        margin-left: 10px;
        font-size: 13px;
      }
    }
  }

  #sales-calendar-popup {
    position: relative;
    padding-right: 20px;
    margin-top: 5px;
    .popup-sales-calendar {
      position: absolute;
      /* right: 0; */
      right: 110px;
    }
  }

  .custom-calender {
    background: #27282a;
    width: 115px;
    /* height: 409px; */
    position: absolute;
    right: 25%;
    z-index: 2;
    border-radius: 20px;
    margin-top: 5px;
    .text-label {
      padding: 25px 0;
      font-size: 14px;
      .btn.btn-primary {
        background: transparent !important;
      }
      div {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }

  .profile-stat-row {
    margin-top: 43px;
    .profile-stat-row-label {
      display: flex;
      margin-bottom: 10px;
      .percent {
        margin-left: 11px;
        display: flex;
      }
    }
    .profile-stat-row-value {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .toggle-options {
    display: flex;
    justify-content: center;
    align-items: center;
    .un-selected-time-period {
      color: #ffffff7d;
    }
  }
`;
